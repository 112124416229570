import React from 'react';
import { Grid, Typography } from '@mui/material';

import IdsDialog from '../../../components/IdsDialog';
import IdsFormFileSelectField from '../../../components/ids-forms/IdsFormFileSelectField';

import IdsFormTextField from '../../../components/ids-forms/IdsFormTextField';
import IdsCreateForm from '../../../components/ids-forms/IdsCreateForm';
import IdsFormAutocompleteField from '../../../components/ids-forms/IdsFormAutocompleteField';

import MultiValueAutocompleteOption from '../../../components/ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';

import SingleValueAutocompleteOption from '../../../components/ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';

import { IMetadataTypeValue } from '../../../constants/media';

import IdsFormSwitchField from '../../../components/ids-forms/IdsFormSwitchField';

import styles from './styles.module.css';
import { validationSchema } from './validation';

export interface IFormValues {
  name: string;
  description: string;
  category: string;
  tags: string[];
  availableInCapture: boolean;
  availableInInfoTab: boolean;
  file: FileList | null;
}

export interface INewDocumentModalProps {
  open: boolean;
  onOpen: () => any;
  onClose: () => any;
  onSubmit: (value: IFormValues) => any;
  onCancel?: () => any;

  optionsTags: IMetadataTypeValue[];
  optionsCategories: IMetadataTypeValue[];
}

export const NewDocumentModal: React.FC<INewDocumentModalProps> = ({
  open,
  onOpen,
  onClose,
  onSubmit,
  optionsCategories,
  optionsTags,
}) => {
  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='New Document'
      dialogKey='create-new-document'
      maxWidth='md'
      fullWidth
      noSearchParam
    >
      <Grid container spacing={2} direction='column' className={styles['wrapper']}>
        <Grid item xs='auto'>
          <Typography variant='body1'>
            Allowed formats for upload file are .zip, .xls(x), .doc(x), .pdf, .cad, .dxf, .dwf,
            .dwg, .rvt, .las, .xyz, .ppt
          </Typography>
        </Grid>
        <Grid item xs='auto' className={styles['maxUploadLengthTextContainer']}>
          <Typography variant='body1'>Maximum file upload time: 30 minutes</Typography>
        </Grid>
        <Grid item xs='auto'>
          <IdsCreateForm
            initialValues={{
              name: '',
              description: '',
              category: undefined,
              tags: [],
              availableInCapture: true,
              availableInInfoTab: true,
              file: null,
            }}
            validationSchema={validationSchema}
            successMessage='New document was created'
            errorHandler={() => 'Some error happen with creating new document'}
            onSubmit={onSubmit}
            onCancel={onClose}
          >
            <Grid container spacing={2} direction='column'>
              {/* Field: Document name */}
              <Grid item xs='auto'>
                <IdsFormTextField name='name' label='Document name' required />
              </Grid>
              {/* Field: Document description */}
              <Grid item xs='auto'>
                <IdsFormTextField name='description' label='Document description' required />
              </Grid>
              {/* Field: Document category */}
              <Grid item xs='auto'>
                <IdsFormAutocompleteField
                  name='category'
                  label='Document category'
                  required
                  options={optionsCategories}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  renderOption={(props, o, { selected }) => (
                    <SingleValueAutocompleteOption
                      selected={selected}
                      label={o.name}
                      {...props}
                      key={o.id}
                    />
                  )}
                />
              </Grid>
              {/* Field: Document tags */}
              <Grid item xs='auto'>
                <IdsFormAutocompleteField
                  name='tags'
                  multiple
                  disableCloseOnSelect
                  label='Document tags'
                  options={optionsTags}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  renderOption={(props, o, { selected }) => (
                    <MultiValueAutocompleteOption
                      selected={selected}
                      label={o.name}
                      {...props}
                      key={o.id}
                    />
                  )}
                />
              </Grid>
              {/* Field: Document availableInCapture */}
              <Grid item xs='auto'>
                <IdsFormSwitchField
                  name='availableInCapture'
                  label='Document available in capture'
                  required
                />
              </Grid>
              {/* Field: Document availableInInfoTab */}
              <Grid item xs='auto'>
                <IdsFormSwitchField
                  name='availableInInfoTab'
                  label='Document available in info tab'
                  required
                />
              </Grid>
              {/* Field: Document file */}
              <Grid item xs='auto' className={styles['fileInputContainer']}>
                <IdsFormFileSelectField
                  name='file'
                  accept='.zip,.xls,.xlsx,.doc,.docx,.pdf,.cad,.dxf,.dwf,.dwg,.rvt,.las,.xyz,.ppt,.pptx'
                  label='New document file'
                  required
                />
              </Grid>
            </Grid>
          </IdsCreateForm>
        </Grid>
      </Grid>
    </IdsDialog>
  );
};

export default NewDocumentModal;
