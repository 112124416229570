import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useRecoilValue } from 'recoil';

import IdsSearchableList from '../../../components/ids-lists/IdsSearchableList';
import {
  ICreateDocumentPayload,
  useCreateDocumentVersion,
  useUpdateDocument,
} from '../../../services/DocumentsService';
import { IMetadataType } from '../../../constants/media';
import { activeOrganizationState } from '../../../atoms/organizations';
import { sortNodeItemsByDate } from '../../../utils/genericSorting';

import NewDocumentVersionModal, {
  IFormValues as IFormValuesNewDocumentVersion,
} from '../NewDocumentVersionModal';
import { IFormValues as IFormValuesEditDocumentVersion } from '../ViewEditDocumentModal/EditDocumentVersion';
import ViewEditDocumentModal, { ViewEditDocumentModalMode } from '../ViewEditDocumentModal';
import NewDocumentModal, { IFormValues as IFormValuesNewDocument } from '../NewDocumentModal';
import { DocumentParentTypes, IDocument, IDocumentVersion } from '../types';
import DocumentListItem from '../DocumentListItem';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnCreateParentEntityDocument extends Omit<ICreateDocumentPayload, 'parentId'> {}

export interface IDocumentsListProps {
  documents: IDocument[];
  metadataTypes: IMetadataType[];
  // Omit parentId not to avoid overweight this component.
  onCreateParentEntityDocument: (values: IOnCreateParentEntityDocument) => Promise<any>;
  disableProgramDocumentEdit?: boolean;
}

const searchDocumentItem = (documentItem: IDocument, query: string) => {
  const searchProps = [
    documentItem?.node?.name,
    documentItem?.node?.metadata?.map(it => it.value).join(', '),
  ];

  return !!searchProps.filter(prop => prop?.toLowerCase().includes(query.toLowerCase())).length;
};

const DocumentsList: React.FC<IDocumentsListProps> = ({
  documents,
  metadataTypes,
  onCreateParentEntityDocument,
  disableProgramDocumentEdit,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);

  const [newDocumentVersionModalOpened, setNewDocumentVersionModalOpened] = useState(false);
  const [newDocumentModalOpened, setNewDocumentModalOpened] = useState(false);
  const [documentModalOpened, setDocumentModalOpened] = useState(false);
  const [documentModalMode, setDocumentModalMode] = useState(ViewEditDocumentModalMode.VIEW);
  const [documentModalDocument, setDocumentModalDocument] = useState<IDocument | null>(null);
  const [documentModalDocumentVersion, setDocumentModalDocumentVersion] =
    useState<IDocumentVersion | null>(null);

  // All possibly available DocumentTag metadata which can be selected
  const availableDocumentTags = useMemo(
    () => metadataTypes.find((it: any) => it.type === 'DocumentTag')?.values || [],
    [metadataTypes],
  );

  // All possibly available DocumentCategory metadata which can be selected
  const availableDocumentCategories = useMemo(
    () => metadataTypes.find((it: any) => it.type === 'DocumentCategory')?.values || [],
    [metadataTypes],
  );

  const { mutateAsync: updateDocumentMutation, isLoading: isLoadingUpdateDocument } =
    useUpdateDocument();

  const { mutateAsync: createDocumentVersionMutation } = useCreateDocumentVersion();

  // Submit: Document Edit action
  const onSubmitDocumentEdit = useCallback(
    async (values: IFormValuesEditDocumentVersion) => {
      await updateDocumentMutation({
        organizationId: activeOrg.id,
        id: documentModalDocument!.node?.id,
        availableInCapture: documentModalDocument!.node?.availableInCapture,
        availableInInfoTab: documentModalDocument!.node?.availableInInfoTab,
        name: values.documentName,
        description: values.description,
        tags: values.tags,
      });
      setDocumentModalOpened(false);
    },
    [documentModalDocument, updateDocumentMutation, activeOrg.id],
  );

  // After clicking 'Upload document' button
  const onUploadNewDocumentClick = useCallback(() => {
    setNewDocumentModalOpened(true);
  }, []);

  // After clicking 'Edit' in Menu of DocumentListItem
  const onClickEditDocument = useCallback((documentItem: IDocument) => {
    const { documentVersions } = documentItem?.node || {};
    // Receiving latest document version item
    const latestDocumentVersion = documentVersions[0];
    setDocumentModalMode(ViewEditDocumentModalMode.EDIT);
    setDocumentModalDocument(documentItem);
    setDocumentModalDocumentVersion(latestDocumentVersion);
    setDocumentModalOpened(true);
  }, []);

  // After clicking on DocumentVersionListItem
  const onClickDocumentVersion = useCallback(
    (documentItem: IDocument, documentVersionItem: IDocumentVersion) => {
      setDocumentModalMode(ViewEditDocumentModalMode.VIEW);
      setDocumentModalDocument(documentItem);
      setDocumentModalDocumentVersion(documentVersionItem);
      setDocumentModalOpened(true);
    },
    [],
  );

  // After clicking 'Edit' in Menu of DocumentVersionListItem
  const onClickEditDocumentVersion = useCallback(
    (documentItem: IDocument, documentVersionItem: IDocumentVersion) => {
      setDocumentModalMode(ViewEditDocumentModalMode.EDIT);
      setDocumentModalDocument(documentItem);
      setDocumentModalDocumentVersion(documentVersionItem);
      setDocumentModalOpened(true);
    },
    [],
  );

  // After clicking 'New Document Version' in Menu of DocumentListItem
  const onClickNewDocumentVersion = useCallback((documentItem: IDocument) => {
    setNewDocumentVersionModalOpened(true);
    setDocumentModalDocument(documentItem);
  }, []);

  // Using Switch to toggle 'availableInInfoTab'
  const onChangeInfoTabAccess = useCallback(
    async (value: boolean, documentItem: IDocument) => {
      await updateDocumentMutation({
        organizationId: activeOrg.id,
        id: documentItem?.node?.id,
        availableInInfoTab: value,
      });
    },
    [activeOrg.id, updateDocumentMutation],
  );

  // Using Switch to toggle 'availableInCapture'
  const onChangeCaptureTabAccess = useCallback(
    async (value: boolean, documentItem: IDocument) => {
      await updateDocumentMutation({
        organizationId: activeOrg.id,
        id: documentItem?.node?.id,
        availableInCapture: value,
      });
    },
    [activeOrg.id, updateDocumentMutation],
  );

  // Submit: New Document Version action
  const onSubmitNewDocumentVersion = useCallback(
    async (values: IFormValuesNewDocumentVersion) => {
      const {
        file: files,

        // TODO: There is probably no need in this properties
        // anymore, because 'description' and 'tags' is now
        // fields of 'Document' and not 'DocumentVersion'

        // description,
        // tags,
      } = values;
      const file = files ? files[0] : null;
      if (!file) return null;

      await createDocumentVersionMutation({
        organizationId: activeOrg.id,
        id: documentModalDocument!.node?.id,
        filename: file.name,
        file,
      });
      setNewDocumentVersionModalOpened(false);
    },
    [createDocumentVersionMutation, documentModalDocument, activeOrg.id],
  );

  // Submit: New Document action
  const onSubmitNewDocument = useCallback(
    async (values: IFormValuesNewDocument) => {
      const {
        file: files,
        description,
        tags,
        name,
        category,
        availableInCapture,
        availableInInfoTab,
      } = values;
      const file = files ? files[0] : null;
      if (!file) return null;

      await onCreateParentEntityDocument({
        organizationId: activeOrg.id,
        filename: file.name,
        file,

        description,
        name,
        tags,
        category,

        availableInCapture,
        availableInInfoTab,
      });
      setNewDocumentModalOpened(false);
    },
    [onCreateParentEntityDocument, activeOrg.id],
  );

  return (
    <>
      <IdsSearchableList
        items={documents}
        renderItem={(documentItem: IDocument) => (
          <DocumentListItem
            key={documentItem.node.id}
            documentItem={documentItem}
            // Document actions
            onClickEdit={onClickEditDocument}
            // Document Version actions
            onClickDocumentVersion={onClickDocumentVersion}
            onClickEditDocumentVersion={onClickEditDocumentVersion}
            onClickNewDocumentVersion={onClickNewDocumentVersion}
            // Switches
            onChangeCaptureTabAccess={onChangeCaptureTabAccess}
            onChangeInfoTabAccess={onChangeInfoTabAccess}
            switchesDisabled={
              isLoadingUpdateDocument ||
              (disableProgramDocumentEdit &&
                documentItem.node.parentType === DocumentParentTypes.PROGRAM)
            }
            disableProgramDocumentEdit={disableProgramDocumentEdit}
          />
        )}
        searchPlaceholder={'Search documents'}
        searchItem={searchDocumentItem}
        sortItems={sortNodeItemsByDate}
        filterMode='select'
        actions={
          <Box display='flex' alignItems='center' height='100%'>
            <Button color='secondary' variant='outlined' onClick={onUploadNewDocumentClick}>
              Upload Document
            </Button>
          </Box>
        }
        // TODO: Rework IdsSearchableList to TypeScript
        header={undefined}
        filters={undefined}
        noItemsMessage={undefined}
        noItemsForFilterMessage={undefined}
        loading={undefined}
        error={undefined}
      />

      {/* View and Edit capabilities for Document */}
      <ViewEditDocumentModal
        open={documentModalOpened}
        onClose={() => setDocumentModalOpened(false)}
        onOpen={() => setDocumentModalOpened(true)}
        // EDIT/VIEW and DOCUMENT/DOCUMENT_VERSION
        mode={documentModalMode}
        // Selected items data
        document={documentModalDocument}
        documentVersion={documentModalDocumentVersion}
        onSubmitDocumentEdit={onSubmitDocumentEdit}
        optionsTags={availableDocumentTags}
      />

      {/* Uploading new Document */}
      <NewDocumentModal
        open={newDocumentModalOpened}
        onOpen={() => setNewDocumentModalOpened(true)}
        onClose={() => setNewDocumentModalOpened(false)}
        onSubmit={onSubmitNewDocument}
        optionsCategories={availableDocumentCategories}
        optionsTags={availableDocumentTags}
      />

      {/* Uploading new Document Version */}
      <NewDocumentVersionModal
        open={newDocumentVersionModalOpened}
        documentItem={documentModalDocument}
        onOpen={() => setNewDocumentVersionModalOpened(true)}
        onClose={() => setNewDocumentVersionModalOpened(false)}
        onCancel={() => setNewDocumentVersionModalOpened(false)}
        onSubmit={onSubmitNewDocumentVersion}
      />
    </>
  );
};

export default DocumentsList;
