import React from 'react';

import IdsFormSelectField from '../../../../../../../../../../../components/ids-forms/IdsFormSelectField';
import { IAssignmentQuestion } from '../types';

export interface IEditableMultiChoiceAnswerProps {
  question: IAssignmentQuestion;
}

const EditableMultiChoiceAnswer: React.FC<IEditableMultiChoiceAnswerProps> = ({ question }) => {
  const { id, assignmentQuestionOptions } = question;

  return (
    <IdsFormSelectField
      name={id}
      multiple
      label=''
      options={assignmentQuestionOptions.map(item => ({
        value: item.title,
        label: item.title,
      }))}
    />
  );
};

export default EditableMultiChoiceAnswer;
