import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, Container } from '@mui/material';

import { useAssignmentKeys } from '../../services/AssignmentsService';
import useGraphQuery from '../../hooks/useGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';

import Page from '../../components/Page';

import { getOrgAssignmentRoute } from '../../utils/routes';

import { activeTenantState } from '../../atoms/tenants';

import { AssignmentListItemFrag } from './AssignmentListItem';
import AssignmentListHeader from './AssignmentListHeader';
import AssignmentsList from './AssignmentsList';

const styles = {
  root: (theme: any) => ({
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  }),
};

const AssignmentListQuery = gql`
  query OrgAssignments($id: ID!, $take: Int, $after: String) {
    organization(id: $id) {
      assignments(take: $take, after: $after) {
        edges {
          cursor
          node {
            id
            title
            active
            ...AssignmentListItemFrag
          }
        }
      }
    }
  }
  ${AssignmentListItemFrag}
`;

const Assignments = () => {
  const assignmentKeys = useAssignmentKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);

  const useAssignments = (take: number, after: string | null) =>
    useGraphQuery(
      [...assignmentKeys.orgList(organization.id), `take-${take}`, `after-${after}`],
      AssignmentListQuery,
      { id: organization.id, take, after },
    );

  return (
    <Page title='Assignments'>
      <Box sx={styles.root}>
        <Container maxWidth={false}>
          <AssignmentListHeader />
          <AssignmentsList
            useGetAssignments={useAssignments}
            selectConnection={data => data?.organization.assignments}
            getTo={assignmentId =>
              getOrgAssignmentRoute({
                subdomain: activeTenant?.subdomain,
                orgId: organization.id,
                assignmentId,
              })
            }
          />
        </Container>
      </Box>
    </Page>
  );
};

export default Assignments;
