import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabsProps, TabProps } from '@mui/material';

export interface ITab {
  key: string;
  label: TabProps['label'];
  sx?: TabProps['sx'];
}

export interface IIdsTabsProps extends Omit<TabsProps, 'onChange'> {
  tabKey?: string;
  tabs: ITab[];
  onChange: (value: string) => any;
}

/**
 * Renders tabs with a [Tab](https://mui.com/api/tab/)
 * rendered as a [Link](https://v5.reactrouter.com/web/api/Link).
 * The active tab is tracked in the url as a
 * search param, allowing deep linking to a tab.
 *
 * Props:
 * - `tabKey` Search param key used to track the active tab.
 *   - `default: 'tab'`
 * - `tabs` Tab config objects specifying what tabs to display.
 * - `onChange(tabKey)` Callback used whenever the tab changes.
 *   - This is also called on initial render.
 * - [Tabs](https://mui.com/api/tabs/) props
 */
const IdsTabs: React.FC<IIdsTabsProps> = ({ tabKey = 'tab', tabs, onChange, ...rest }) => {
  const [searchParams] = useSearchParams();
  const searchParamsObj = React.useMemo(
    () => Object.fromEntries([...searchParams]),
    [searchParams],
  );
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState(searchParams?.get(tabKey));

  const getTab = React.useMemo(() => (key: string) => tabs.find(t => t.key === key), [tabs]);

  const [activeTab, setActiveTab] = useState(() =>
    tabs?.length ? (activeTabKey && getTab(activeTabKey)) || tabs[0] : null,
  );

  useEffect(() => {
    const newActiveTabKey = searchParams.get(tabKey);
    if (newActiveTabKey && newActiveTabKey !== activeTabKey) {
      setActiveTabKey(newActiveTabKey);

      const newTab = getTab(newActiveTabKey);
      if (newTab && activeTab && newTab.key !== activeTab.key) {
        setActiveTab(newTab);
      }
    }
  }, [searchParams, tabKey, activeTabKey, getTab, activeTab]);

  useEffect(() => {
    if (onChange && activeTab) {
      onChange(activeTab.key);
    }
  }, [activeTab, onChange]);

  const navigateToTab = (tab: ITab) => {
    // Maintain other search params and
    // replace the tabKey param with the new tab
    const tabRoute = `?${new URLSearchParams({
      ...searchParamsObj,
      [tabKey]: tab.key,
    })}`;
    navigate(tabRoute, { replace: true });
  };

  if (!tabs?.length || !activeTab) return null;

  return (
    <>
      <Tabs
        scrollButtons='auto'
        textColor='secondary'
        indicatorColor='secondary'
        value={activeTab.key}
        variant='scrollable'
        {...rest}
      >
        {tabs.map(tab => {
          const { key, label, ...rest } = tab;

          return (
            <Tab
              key={key}
              value={key}
              label={label}
              // Using onClick instead of rendering
              // as link since a tab is not expected to be a link for the user
              onClick={() => navigateToTab(tab)}
              {...rest}
            />
          );
        })}
      </Tabs>
    </>
  );
};

export default IdsTabs;
