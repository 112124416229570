import { useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../atoms/organizations';
import { activeTenantState } from '../atoms/tenants';

const usePermissions = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const myUserRoleOrg = useMemo(() => activeOrg?.myUserRole, [activeOrg]);
  const myUserRoleTenant = useMemo(() => activeTenant?.myUserRole, [activeTenant]);
  const myRoles = useMemo(
    () => [myUserRoleOrg, myUserRoleTenant],
    [myUserRoleOrg, myUserRoleTenant],
  );

  const userHasOneOfRoles = useCallback(
    roles =>
      roles.some(role =>
        role.name
          ? myRoles.includes(role.name) // support getting a role def object
          : myRoles.includes(role),
      ), // support just getting the role name
    [myRoles],
  );

  const userHasPermission = useCallback(
    (action, type) => {
      const hasOrgPermission = activeOrg?.myPermissions[action].some(i => i === type);

      if (!hasOrgPermission && activeTenant?.myPermissions && activeTenant.myPermissions[action]) {
        return activeTenant.myPermissions[action].some(i => i === type);
      }

      return hasOrgPermission;
    },
    [activeOrg, activeTenant],
  );

  return {
    userHasPermission,
    permissions: activeOrg?.myPermissions,
    userHasOneOfRoles,
  };
};

export default usePermissions;
