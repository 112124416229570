import React from 'react';
import PropTypes from 'prop-types';

import IdsDialog from '../../../components/IdsDialog';

import CreateProjectForm from './CreateProjectForm';

function CreateProjectDialog({ open, onOpen, onClose }) {
  return (
    <>
      <IdsDialog
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        title='Create New Project'
        dialogKey='create'
      >
        <CreateProjectForm onSubmitSuccess={onClose} onCancel={onClose} />
      </IdsDialog>
    </>
  );
}

CreateProjectDialog.defaultProps = {
  open: false,
};

CreateProjectDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default CreateProjectDialog;
