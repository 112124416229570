import { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { IDocument, IDocumentVersion } from '../types';
import IdsDataAttribute from '../../../components/IdsDataAttribute';

import { ExpandIcon } from '../../../theme/icons';
import DocumentVersionListItem from '../DocumentVersionListItem';

import { getDocumentCategory, getDocumentTags } from '../utils';
import useGetDocumentLatestVersion from '../useGetDocumentLatestVersion';

import styles from './styles.module.css';

export interface IDocumentViewProps {
  document?: IDocument | null;
  onClickDocumentVersion?: (document: IDocument, documentVersion: IDocumentVersion) => any;
  onClickEditDocumentVersion?: (document: IDocument, documentVersion: IDocumentVersion) => any;
}

export const DocumentView: React.FC<IDocumentViewProps> = ({
  document,
  onClickDocumentVersion,
  onClickEditDocumentVersion,
}) => {
  const { name, metadata, documentVersions, description } = document?.node || {};

  const latestVersion = useGetDocumentLatestVersion(document);

  const { firstName, lastName } = latestVersion?.user || {};

  const category = useMemo(() => getDocumentCategory(metadata)?.value, [metadata]);

  const tags = useMemo(
    () =>
      getDocumentTags(metadata)
        ?.map(it => it.value)
        .join(', '),
    [metadata],
  );

  if (!document) return null;

  return (
    <Grid container spacing={2} direction='column' className={styles['wrapper']}>
      {/* 1?. Latest document filename */}
      {/* 2. Document name */}
      <IdsDataAttribute label='Name' value={name} />
      {/* 3. Author name */}
      {Boolean(firstName && lastName) && (
        <IdsDataAttribute label='Author' value={`${firstName} ${lastName}`} />
      )}
      {/* 4. Document category */}
      {category && <IdsDataAttribute label='Category' value={category} />}
      {/* 5. Description */}
      {description && <IdsDataAttribute label='Description' value={description} />}
      {/* 6. Document tags (where to get them...?) */}
      <IdsDataAttribute label='Tags' value={tags} />
      {/* 7?. Document versions */}
      <Box className={styles['documentVersionsContainer']}>
        {Array.isArray(documentVersions) && documentVersions.length > 1 ? (
          <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandIcon />}>Document versions</AccordionSummary>
            <AccordionDetails>
              {documentVersions
                ?.slice()
                .reverse() // Document versions are returned form GQL in ascending order, reversed here to display in descending order
                .map(it => (
                  <DocumentVersionListItem
                    key={`$${document.node.id}-${it.id}`}
                    document={document}
                    documentVersion={it}
                    onClick={onClickDocumentVersion}
                    onClickEdit={onClickEditDocumentVersion}
                  />
                ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Typography variant='h5'>No document versions</Typography>
        )}
      </Box>
    </Grid>
  );
};

export default DocumentView;
