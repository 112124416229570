import { useMemo } from 'react';

import { Box, TextField } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';

import IdsAutocomplete from '../../../components/ids-inputs/IdsAutocomplete';
import IdsSelect from '../../../components/ids-inputs/IdsSelect';
import { CustomFieldInputType } from '../../../services/CustomFieldsService';
import IdsCheckboxList from '../../../components/ids-inputs/IdsCheckboxList';
import IdsRadioList from '../../../components/ids-inputs/IdsRadioList';
import IdsMultiSelect from '../../../components/ids-inputs/IdsMultiSelect';

const valuesToOptions = (item: ICustomFieldValue) => ({ value: item.id, label: item.name });

export interface ICustomFieldValue {
  id: string;
  name: string;
}

export interface ICustomFieldProps {
  frontendType: CustomFieldInputType;

  /**
   * Can be omitted - then label will not be rendered.
   *
   * 'checkbox' frontendType will have label inline
   */
  frontendLabel?: string;
  multipleValuesAllowed: boolean;
  required: boolean;
  values: ICustomFieldValue[];
  customValueAllowed: boolean;
  className?: string;

  value: any;
  error?: string;
  touched?: boolean;
  onChange: (value: any) => any;
}

export const CustomField: React.FC<ICustomFieldProps> = ({
  value,
  touched,
  error,
  onChange,

  frontendType,
  frontendLabel,

  values,
  className,

  multipleValuesAllowed,
  required,
}) => {
  const inputRendered = useMemo(() => {
    const errorValue = Boolean(touched && error) || undefined;
    const helperTextValue = (touched && error) || null;
    switch (frontendType) {
      case CustomFieldInputType.autocomplete:
        return (
          <IdsAutocomplete
            value={value === undefined ? null : value}
            onChange={(_, v) => onChange(v)}
            options={values}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            multiple={multipleValuesAllowed}
            required={required}
            textFieldProps={{
              helperText: helperTextValue,
              error: errorValue,
              sx: {
                '& .MuiFormHelperText-root': {
                  marginLeft: 0,
                },
              },
            }}
          />
        );

      case CustomFieldInputType.select:
        if (multipleValuesAllowed) {
          return (
            <IdsMultiSelect
              value={value}
              onChange={onChange}
              options={values.map(valuesToOptions)}
              required={required}
              error={errorValue}
              helperText={helperTextValue}
            />
          );
        }
        return (
          <IdsSelect
            value={value}
            onChange={e => onChange(e.target.value)}
            options={values.map(valuesToOptions)}
            required={required}
            error={errorValue}
            helperText={helperTextValue}
          />
        );

      case CustomFieldInputType.checkbox:
        return (
          <IdsCheckboxList
            label={frontendLabel}
            required={required}
            value={value}
            onChange={onChange}
            options={values.map(valuesToOptions)}
            error={errorValue}
            helperText={helperTextValue}
            multiple={multipleValuesAllowed}
          />
        );

      case CustomFieldInputType.radio:
        return (
          <IdsRadioList
            label={frontendLabel}
            required={required}
            value={value}
            onChange={onChange}
            options={values.map(valuesToOptions)}
            error={errorValue}
            helperText={helperTextValue}
          />
        );

      case CustomFieldInputType.text:
        return (
          <TextField
            value={value}
            onChange={e => onChange(e.target.value)}
            size='small'
            fullWidth
            error={errorValue}
            helperText={helperTextValue}
            // @TODO: Right now customValues - unsupported
            disabled
          />
        );

      case CustomFieldInputType.datepicker:
        return (
          <DatePicker
            value={value}
            onChange={onChange}
            renderInput={params => (
              <TextField
                fullWidth
                size='small'
                required={required}
                error={errorValue}
                helperText={helperTextValue}
                {...params}
                // @TODO: Right now customValues - unsupported
                disabled
              />
            )}
          />
        );

      default:
        throw new Error(`[CustomField] Non-supported 'frontendType' passed: "${frontendType}"`);
    }
  }, [
    error,
    frontendLabel,
    frontendType,
    multipleValuesAllowed,
    onChange,
    required,
    touched,
    value,
    values,
  ]);

  return <Box className={className}>{inputRendered}</Box>;
};

export default CustomField;
