import React from 'react';

import Page from '../../components/Page';
import ComingSoonRoute from '../../components/ComingSoonRoute';

function Media() {
  return (
    <Page title='Media'>
      <ComingSoonRoute />
    </Page>
  );
}

export default Media;
