import { gql } from 'graphql-request';
import { useMemo } from 'react';

import useGraphQuery from '../hooks/useGraphQuery';

import { useOrganizationKeys } from './OrganizationsService';

export const useCollectionKeys = () => {
  const organizationKeys = useOrganizationKeys();

  return useMemo(() => {
    const collectionKeys = {
      all: ['collections'],
      lists: () => [...collectionKeys.all, 'list'],
      orgList: (orgId: string) => [...collectionKeys.lists(), organizationKeys.detail(orgId)],
      route: (id: string) => [...collectionKeys.all, id],
      detail: (id: string) => [...collectionKeys.route(id), 'detail'],
    };

    return collectionKeys;
  }, [organizationKeys]);
};

const CollectionsList = gql`
  query CollectionsList($organizationId: ID!) {
    organization(id: $organizationId) {
      collections {
        edges {
          node {
            id
            title
            creator {
              firstName
              lastName
              id
            }
            public
            createdAt
          }
        }
      }
    }
  }
`;

export type Collection = {
  node: {
    id: string;
    title: string;
    public: boolean;
    createdAt: string;
    creator: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
};

export interface ICollectionsListQueryData {
  organization: {
    collections: {
      edges: Collection[];
    };
  };
}

export const useGetCollectionsList = (organizationId: string) => {
  const collectionKeys = useCollectionKeys();

  return useGraphQuery<ICollectionsListQueryData>(
    collectionKeys.orgList(organizationId),
    CollectionsList,
    {
      organizationId,
    },
  );
};
