import { Component } from 'react';

import airbrake from '../utils/airbrake';
import SomethingWentWrong from '../views/SomethingWentWrong';

class ErrorBoundary extends Component {
  airbrake;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    // Send error to Airbrake
    airbrake.notify({
      error: error,
      params: { info: info },
    });
  }
  render() {
    return this.state.hasError ? <SomethingWentWrong /> : this.props.children;
  }
}

export default ErrorBoundary;
