import { Button, Grid, SvgIcon } from '@mui/material';
import React from 'react';

import { AddIcon } from '../../../theme/icons';

export interface IActionsProps {
  label: React.ReactNode;
  onClick: () => void;
  canClick: boolean;
}

const Action: React.FC<IActionsProps> = ({ label, canClick, onClick }) => {
  return (
    <Grid item>
      <Button
        color='secondary'
        variant='contained'
        onClick={onClick}
        style={{ ...(!canClick && { visibility: 'hidden' }) }}
      >
        <SvgIcon fontSize='small' sx={{ mr: 2 }}>
          <AddIcon />
        </SvgIcon>
        {label}
      </Button>
    </Grid>
  );
};

export default Action;
