import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Chip, ChipTypeMap } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/ban-types
type MuiChipProps = ChipTypeMap<{}, 'div'>['props'];

export interface IEntityChipProps extends MuiChipProps {
  entityRoute?: string;
  searchParams?: string;
  className?: string;
}

const EntityChip: React.FC<IEntityChipProps> = ({
  icon,
  label,
  entityRoute,
  searchParams,
  sx,
  ...rest
}) => {
  return (
    <Chip
      variant='outlined'
      size='small'
      icon={icon}
      label={label}
      component={RouterLink}
      to={{
        pathname: entityRoute,
        search: searchParams,
      }}
      clickable
      sx={{
        ...(icon && { paddingLeft: 0.5 }), // Give the icon some space
        ...sx,
      }}
      {...rest}
    />
  );
};

export default EntityChip;
