import React, { ForwardedRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';

import { Virtuoso, Components } from 'react-virtuoso';

import { activeOrganizationState } from '../../../atoms/organizations';

import { useGetOrgPrograms } from '../../../services/ProgramsService';
import useFilterContext from '../../../hooks/useFilterContext';
import usePrevious from '../../../hooks/usePrevious';

import { EntityType } from '../../../constants/entities';
import { SearchIcon } from '../../../theme/icons';
import { TOP_BAR_HEIGHT } from '../../TopBar';

import LocationProgramFilter from './LocationProgramFilter';
import styles from './LocationSearch.module.css';
import MinMaxButton from './MinMaxButton';

const SEARCH_FILTER = 'search';

const listComponents: Components = {
  List: React.forwardRef(({ children, ...rest }, ref) => (
    <List {...rest} ref={ref as ForwardedRef<HTMLUListElement>}>
      {children}
    </List>
  )),
};

const listItemSx = { p: 0 };
const listItemButtonSx = { p: 1 };
const secondaryTypographyProps = { noWrap: false };

export interface ILocationSearchProps {
  showExpanded?: boolean;
  visible?: boolean;

  onExpandedChange: (value: boolean) => void;
  selectLocation: (location: Record<string, any>, zoomLevel: number) => void;
}

const LocationSearch: React.FC<ILocationSearchProps> = ({
  showExpanded,
  selectLocation,
  visible,
  onExpandedChange,
}) => {
  const organization = useRecoilValue(activeOrganizationState);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [expanded, setExpanded] = useState(showExpanded ?? true);
  const wasExpanded = usePrevious(expanded);
  const { addFilter, removeFilter, useTypeFilteredData } = useFilterContext();
  const filteredLocations = useTypeFilteredData(EntityType.Location);

  const { data: programs, isLoading } = useGetOrgPrograms(organization.id, { enabled: visible });

  const programOptions = useMemo(
    () => programs?.map(p => ({ label: p.node.name, value: p.node.id })),
    [programs],
  );

  const searchLocations = useCallback(
    event => {
      const newSearchTerm = event?.target?.value?.toLowerCase() || '';
      setSearchTerm(newSearchTerm);

      if (!newSearchTerm?.length) {
        if (searchTerm.length) {
          // Just deleted the search
          removeFilter(SEARCH_FILTER);
        }
        return; // Don't do anything if search is empty
      }

      // Search is updated, update the filter
      addFilter(
        {
          name: SEARCH_FILTER,
          label: newSearchTerm,
          filterItem: locationValue => locationValue.toLowerCase().includes(newSearchTerm),
          targets: [
            {
              type: EntityType.Location,
              selectFilterData: item =>
                `${item.node.name} ${item.addressLine1} ${item.addressLine2}`,
            },
          ],
        },
        {
          // Set filteringDown when search term increased in length to prevent reapplying filter to base data
          filteringDown: newSearchTerm.length > searchTerm?.length,
        },
      );
    },
    [searchTerm, addFilter, removeFilter],
  );

  const _selectLocation = useCallback(
    location => {
      if (selectLocation) {
        selectLocation(location.node, 8);
      }
    },
    [selectLocation],
  );

  useEffect(() => {
    if (onExpandedChange && expanded !== wasExpanded) {
      onExpandedChange(expanded);
    }
  }, [expanded, wasExpanded, onExpandedChange]);

  const expandLocationsList = useCallback(() => {
    setExpanded(true);
  }, [setExpanded]);

  const onChangeProgramFilter = useCallback(
    value => {
      expandLocationsList();
      setSelectedPrograms(value);
    },
    [expandLocationsList],
  );

  const inlineStyles = useMemo(() => {
    return {
      container: {
        height: expanded ? `calc(100% - ${TOP_BAR_HEIGHT + 20}px)` : 'initial',
        top: TOP_BAR_HEIGHT + 10,
      },
    };
  }, [expanded]);

  const renderListItemContent = useCallback(
    (index: number) => {
      const item = filteredLocations[index];
      return (
        <ListItem key={item.node.id} className={styles.searchResult} sx={listItemSx}>
          <ListItemButton
            className={styles.searchResultButton}
            sx={listItemButtonSx}
            onClick={() => _selectLocation(item)}
          >
            <ListItemText
              primary={item.node.name}
              secondary={`${item.addressLine1}${item.addressLine2 ? `, ${item.addressLine2}` : ''}`}
              secondaryTypographyProps={secondaryTypographyProps}
            />
          </ListItemButton>
        </ListItem>
      );
    },
    [_selectLocation, filteredLocations],
  );

  const inputProps = useMemo(() => {
    return {
      endAdornment: (
        <InputAdornment className={styles.searchIcon} position='end'>
          <IconButton onClick={searchLocations}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      ),
    };
  }, [searchLocations]);

  if (!visible) {
    return null;
  }

  return (
    <Box>
      <Box
        className={styles.searchContainer}
        style={inlineStyles.container}
        bgcolor='background.paper'
      >
        <MinMaxButton expanded={expanded} setExpanded={setExpanded} />
        <TextField
          onChange={searchLocations}
          value={searchTerm}
          onFocus={expandLocationsList}
          InputProps={inputProps}
          fullWidth={true}
          size='small'
          placeholder='Search Locations'
        />

        <p className={styles.searchTotalResults}>
          Showing <strong>{filteredLocations.length}</strong> Locations
        </p>

        <LocationProgramFilter
          label='Program Filter'
          key='programFilter'
          options={programOptions}
          loading={isLoading}
          onChange={onChangeProgramFilter}
          value={selectedPrograms}
          onFocus={expandLocationsList}
        />

        {/* Rendering list of filtered locations */}
        {filteredLocations && filteredLocations.length > 0 && expanded && (
          <div className={styles.scrollbarResultsContainer}>
            <Virtuoso
              components={listComponents}
              totalCount={filteredLocations.length}
              itemContent={renderListItemContent}
            />
          </div>
        )}

        {filteredLocations && filteredLocations.length === 0 && (
          <Typography>There were no results matching your search.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default LocationSearch;
