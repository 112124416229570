import { useCallback, useEffect, useMemo, useRef } from 'react';

import { getUUID } from '../utils/helpers';

import useInterval from './useInterval';

export interface IUseUploadQueueChannelProps {
  onStatusChange?: (isMaster: boolean) => void;
}

enum MessageType {
  IsMasterSet = 'IsMasterSet',
  MasterIsSet = 'MasterIsSet',
  // think about another message (claiming master to establish that master is being claimed)
  // could use uuid in initial connection to track tabs and communicate with them directly
  // MasterIsNotSet = 'MasterIsNotSet', // cannot support this message if multiple tabs, need to rely on timeout
  // will need other message types here to detect when master is no longer set... periodically check?
}

interface IMessage {
  type: MessageType;
  senderId: string;
}

interface IMasterIsSetMessage extends IMessage {
  slaveIds: string[];
}

const id = getUUID();
const channel = new BroadcastChannel('ids-upload-queue');

const postMessage = <T extends IMessage>(message: T) => {
  channel.postMessage(message);
};

const useUploadQueueChannel = ({ onStatusChange }: IUseUploadQueueChannelProps) => {
  const pollingMasterId = useRef<string | null>(null);
  const masterId = useRef<string | undefined>();
  const slaveIds = useRef<string[]>([]);

  const parse = useCallback((msg: IMessage) => {
    switch (msg.type) {
      case MessageType.IsMasterSet:
        if (masterId.current) {
          if (!slaveIds.current.includes(msg.senderId)) {
            slaveIds.current.push(msg.senderId);
          }
          postMessage<IMasterIsSetMessage>({
            type: MessageType.MasterIsSet,
            senderId: id,
            slaveIds: slaveIds.current,
          });
        }
        break;
      case MessageType.MasterIsSet:
        const tmsg = msg as IMasterIsSetMessage;
        masterId.current = msg.senderId;
        pollingMasterId.current = msg.senderId;
        if (
          slaveIds.current.length !== tmsg.slaveIds.length ||
          tmsg.slaveIds.some(sId => !slaveIds.current.includes(sId))
        ) {
          slaveIds.current = tmsg.slaveIds;
        }
        break;
    }
  }, []);

  useEffect(() => {
    channel.onmessage = event => {
      parse(event.data as IMessage);
    };

    return () => {
      channel.onmessage = null;
    };
  }, [parse]);

  const pollIsMaster = useCallback(async () => {
    if (masterId.current === id) return;

    pollingMasterId.current = null;

    channel.postMessage({ type: MessageType.IsMasterSet, senderId: id });

    setTimeout(() => {
      if (!pollingMasterId.current) {
        const prevMasterId = masterId.current;
        masterId.current = slaveIds.current.length ? slaveIds.current[0] : id;

        const isMaster = masterId.current === id;

        // Only notify when first becomes a slave or master (master will never be demoted to a slave)
        if (onStatusChange && (!prevMasterId || isMaster)) {
          onStatusChange(isMaster);
        }
      }
    }, 1000);
  }, [onStatusChange]);

  useInterval(pollIsMaster, 5000);

  return useMemo(
    () => ({
      isMaster: () => masterId.current === id,
    }),
    [],
  );
};

export default useUploadQueueChannel;
