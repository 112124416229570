import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

import { activeProjectState } from '../../../../../atoms/projects';
import { useGetLocationMetadataTypes } from '../../../../../services/LocationService';
import { IMetadataType, MediaMetadataType } from '../../../../../constants/media';

import { MetadataOptionsType, MetadataType } from './types';

const defaultMetadataTypes: IMetadataType[] = [];

/**
 * Returns:
 * {
 *   Level: [{value: "1", label: "Roof"}, {value: "2", label: "Ground"}, ...],
 *   Area: [{value: "3", label: "Interior"}, {value: "4", label: "Exterior"}, ...],
 *   ....
 * }
 */
const useMetadataOptions = () => {
  const activeProject = useRecoilValue(activeProjectState);

  const { data: locationMetadataTypes } = useGetLocationMetadataTypes(activeProject!.locationId);

  const metadataOptions: MetadataOptionsType | null = useMemo(() => {
    if (!locationMetadataTypes) {
      return null;
    }

    const mediaMetadataTypes = Object.values(MediaMetadataType);
    const defaultAccumulator = mediaMetadataTypes.reduce((accumulator, item) => {
      // @TODO: PhotoTag is not supported yet
      if (item !== MediaMetadataType.PhotoTag) {
        accumulator[item] = [];
      }

      return accumulator;
    }, {} as MetadataOptionsType);

    return locationMetadataTypes?.location.metadataTypes.reduce((accumulator, item) => {
      if (mediaMetadataTypes.includes(item.type)) {
        accumulator[item.type as MetadataType] = item.values.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      }

      return accumulator;
    }, defaultAccumulator);
  }, [locationMetadataTypes]);

  const metadataTypes = locationMetadataTypes?.location.metadataTypes || defaultMetadataTypes;

  return {
    metadataOptions,
    metadataTypes,
  };
};

export default useMetadataOptions;
