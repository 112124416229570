import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconLayer, PathLayer } from '@deck.gl/layers';

import polylabel from 'polylabel';

import OutlinedPolygonLayer from '../components/mapping/layers/OutlinedPolygonLayer';

import { buildToolLayer } from '../components/mapping/layers/tools';
import { getDistanceFromLatLonInFeet, getAreaOfLatLonPointsInFeet } from '../utils/geospatial';
import { TOOLS_ID } from '../components/mapping/LocationMapMenu';
import { getRGBColor, rgbStrToArray } from '../utils/colors';

import useDeckEventManagerCallback from './useDeckEventManagerCallback';
import useImmersiveViewer from './useImmersiveViewer';

const pointIconMapping = {
  point: { x: 0, y: 0, width: 158, height: 159, mask: true },
};

const defaultColor = [255, 10, 255];

const MIN_POINTS = 2;

const useMapAreaTool = (toolId, color) => {
  const {
    useViewport,
    setController,
    setLayer,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  } = useImmersiveViewer();
  const viewport = useViewport();
  const [enabled, setEnabled] = useState(false);
  const [placingPoints, setPlacingPoints] = useState(true);
  const [adjusting, setAdjusting] = useState(false);
  const [points, setPoints] = useState([]);
  const [hoveringPoint, setHoveringPoint] = useState();
  const [toolTarget, setToolTarget] = useState();

  const polygonId = useMemo(() => `${toolId}-polygon`, [toolId]);
  const pathNextId = useMemo(() => `${toolId}-path-next`, [toolId]);
  const pathPointsId = useMemo(() => `${toolId}-path-points`, [toolId]);
  const pathPointsNextId = useMemo(() => `${toolId}-path-points-next`, [toolId]);

  const rgbArrColor = useMemo(() => {
    const rgb = getRGBColor(color);
    return rgb ? rgbStrToArray(rgb) : defaultColor;
  }, [color]);

  const pointObjs = useMemo(
    () => points.map(coord => ({ longitude: coord[0], latitude: coord[1] })),
    [points],
  );

  const reset = useCallback(() => {
    setPoints([]);
    setPlacingPoints(true);
  }, [setPoints, setPlacingPoints]);

  const disable = useCallback(() => {
    setItemState(TOOLS_ID, { active: null });
    reset();
  }, [setItemState, reset]);

  useEffect(() => {
    if (!toolTarget) {
      return;
    }

    // Change cursor while measuring
    toolTarget.style.cursor = placingPoints && enabled ? 'pointer' : 'inherit';
  }, [placingPoints, toolTarget, enabled]);

  const onMapHover = useCallback(
    event => {
      const { offsetCenter, target } = event;
      const coordinate = viewport.unproject([offsetCenter.x, offsetCenter.y]);
      setHoveringPoint(coordinate);

      if (target !== toolTarget) {
        if (toolTarget) {
          toolTarget.style.cursor = 'inherit'; // reset the previous target's cursor styling
        }
        setToolTarget(target);
      }
    },
    [setHoveringPoint, viewport, setToolTarget, toolTarget],
  );

  useDeckEventManagerCallback('pointermove', onMapHover, enabled && placingPoints);

  const onMapClick = useCallback(
    event => {
      // still need to check this even though handler gets disabled,
      // event fires again before it gets disabled when the last point is clicked
      // point click and map click are handled separately
      if (enabled && placingPoints) {
        const { offsetCenter, leftButton, rightButton } = event;

        if (leftButton) {
          // new point added
          const coordinate = viewport.unproject([offsetCenter.x, offsetCenter.y]);
          setPoints([...points, coordinate]);
        } else if (rightButton) {
          if (points.length >= MIN_POINTS) {
            // Min points reached, end placement
            setPlacingPoints(false);
          } else {
            // Did not reach minium points, cancel placement
            disable();
          }
        }
      }
    },
    [setPoints, viewport, points, placingPoints, enabled, disable],
  );

  useDeckEventManagerCallback('click', onMapClick, enabled && placingPoints);

  // Handle external tool disable
  const handleStateUpdate = newState => {
    const { active } = newState;
    setEnabled(active === toolId);
  };

  useEffect(() => {
    const initToolsState = getItemState(TOOLS_ID);
    if (initToolsState) {
      // Handle inital state if set before mount
      handleStateUpdate(initToolsState);
    }

    const listenerId = `${toolId}-listener`;
    addItemStateListener(TOOLS_ID, listenerId, handleStateUpdate);

    return () => {
      removeItemStateListener(TOOLS_ID, listenerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  // Build base measurement layer
  useEffect(() => {
    setLayer(
      polygonId,
      buildToolLayer(
        new OutlinedPolygonLayer({
          id: polygonId,
          data: [points],
          visible: enabled,
          getColor: rgbArrColor,
        }),
      ),
    );
  }, [polygonId, points, setLayer, enabled, rgbArrColor]);

  const onPointClick = useCallback(
    (info, event) => {
      const {
        object: { index, point },
      } = info;
      const { tapCount } = event;

      const firstPoint = index === 0;
      const doubleClick = tapCount === 2;

      // Close the loop if the first point is clicked or a point is double clicked (first click places it, second click ends placement)
      if (placingPoints && points.length >= MIN_POINTS && (firstPoint || doubleClick)) {
        if (!doubleClick) {
          setPoints([...points, point]);
        }
        setPlacingPoints(false);
      }
    },
    [points, setPoints, setPlacingPoints, placingPoints],
  );

  const onPointDragStart = useCallback(() => {
    setController({ dragPan: false });
    setAdjusting(true);
  }, [setAdjusting, setController]);

  const onPointDrag = useCallback(
    (info, event) => {
      const {
        coordinate,
        object: { index },
        viewport,
      } = info;

      if (!viewport) {
        // Need viewport to handle drag
        return;
      }

      const newPoints = [...points];

      const lastIndex = newPoints.length - 1;

      // Dragging first or last point
      if (index === 0 || index === lastIndex) {
        const firstPoint = newPoints[0];
        const lastPoint = newPoints[lastIndex];

        // Closed loop, should drag both points
        if (firstPoint[0] === lastPoint[0] && firstPoint[1] === lastPoint[1]) {
          newPoints[index === 0 ? lastIndex : 0] = coordinate;
        }
      }

      newPoints[index] = coordinate;
      setPoints(newPoints);
    },
    [points, setPoints],
  );

  const onPointDragEnd = useCallback(() => {
    setController({ dragPan: true });
    setAdjusting(false);
  }, [setAdjusting, setController]);

  // Build tool points
  useEffect(() => {
    const _points = points.map((p, i) => ({
      index: i,
      point: p,
    }));

    setLayer(
      pathPointsId,
      buildToolLayer(
        new IconLayer({
          id: pathPointsId,
          pickable: true,
          data: _points,
          getPosition: d => d.point,
          iconAtlas: '/static/icons/circle-icon.png',
          iconMapping: pointIconMapping,
          getIcon: d => 'point',
          getSize: 12.5,
          getColor: rgbArrColor,
          onClick: onPointClick,
          autoHighlight: true,
          highlightColor: rgbArrColor.map(c => c * 0.75), // Show slightly darker version of color
          onDragStart: onPointDragStart,
          onDrag: onPointDrag,
          onDragEnd: onPointDragEnd,
          visible: enabled,
        }),
      ),
    );
  }, [
    points,
    setLayer,
    pathPointsId,
    onPointClick,
    placingPoints,
    onPointDragStart,
    onPointDrag,
    onPointDragEnd,
    enabled,
    rgbArrColor,
  ]);

  // Build tool next line and point layers
  useEffect(() => {
    const path = points.length ? [points[points.length - 1]] : points;
    if (hoveringPoint && path.length) {
      path.push(hoveringPoint);
    }

    setLayer(
      pathNextId,
      buildToolLayer(
        new PathLayer({
          id: pathNextId,
          data: [path],
          visible: placingPoints && !adjusting && enabled,
          widthScale: 4,
          widthMinPixels: 4,
          widthUnits: 'pixels',
          getPath: d => d,
          getColor: [...rgbArrColor, 75],
        }),
      ),
    );

    setLayer(
      pathPointsNextId,
      buildToolLayer(
        new IconLayer({
          id: pathPointsNextId,
          data: [hoveringPoint],
          visible: placingPoints && !adjusting && enabled,
          getPosition: d => d,
          iconAtlas: '/static/icons/circle-icon.png',
          iconMapping: pointIconMapping,
          getIcon: d => 'point',
          getSize: 10,
          getColor: [...rgbArrColor, 150],
        }),
      ),
    );
  }, [
    points,
    pathNextId,
    pathPointsNextId,
    hoveringPoint,
    setLayer,
    placingPoints,
    adjusting,
    enabled,
    rgbArrColor,
  ]);

  const distance = useMemo(() => {
    let total = 0;
    for (let i = 1; i < points.length; i++) {
      const p1 = points[i - 1];
      const p2 = points[i];
      total += getDistanceFromLatLonInFeet(p1[1], p1[0], p2[1], p2[0]);
    }
    return total;
  }, [points]);

  const area = useMemo(() => getAreaOfLatLonPointsInFeet(points), [points]);

  const center = useMemo(() => {
    if (!points.length) return null;
    const poleOfInaccesibility = polylabel([points], 0.000001);
    return {
      longitude: poleOfInaccesibility[0],
      latitude: poleOfInaccesibility[1],
    };
  }, [points]);

  return {
    enabled,
    placingPoints,
    points: pointObjs,
    distance,
    area,
    center,
    reset,
  };
};

export default useMapAreaTool;
