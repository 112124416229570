import { useCallback, useEffect } from 'react';

import moment from 'moment';

import { useRecoilState } from 'recoil';

import { Box, FormControl, Slider } from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';

import useTimelineFilter from '../../../../hooks/filtering/useTimelineFilter';
import usePrevious from '../../../../hooks/usePrevious';

import { infoPanelActivePaneState } from '../../../../atoms/immersiveViewer';
import { panelOptions } from '../../../../context/LocationMapContext';
import {
  timelineGroupingState,
  timelineMarksState,
  timelineValueState,
} from '../../../../atoms/timeline';
import IdsSelect from '../../../ids-inputs/IdsSelect';

import styles from './TimelineFilter.module.css';

const GROUPING_OPTIONS = [
  {
    label: 'Dates',
    value: 'Dates',
  },
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Yearly',
    value: 'Yearly',
  },
];

const DATE_LABEL_FORMATS = {
  Dates: 'MM/DD/YYYY',
  Weekly: 'YYYY [Week] W',
  Monthly: 'YYYY [-] MMM',
  Yearly: 'YYYY',
};

const calculateHeight = () => window.innerHeight - 800;

const TimelineFilter = () => {
  const [timelineGrouping, setTimelineGrouping] = useRecoilState(timelineGroupingState);
  const prevTimelineGrouping = usePrevious(timelineGrouping);
  const [timelineMarks, setTimelineMarks] = useRecoilState(timelineMarksState);
  const [timelineValue, setTimelineValue] = useRecoilState(timelineValueState);
  const [activePane, setActivePane] = useRecoilState(infoPanelActivePaneState);

  const resetTimelineState = useCallback(() => {
    setTimelineMarks([{ label: 'No Dates Available', value: 0 }]);
  }, [setTimelineMarks]);

  const { dates, startDate, endDate, filterByDateRange } = useTimelineFilter(resetTimelineState);
  const prevDates = usePrevious(dates);
  const prevStartDate = usePrevious(startDate);
  const prevEndDate = usePrevious(endDate);

  useEffect(() => {
    // If the timelineValue is set and the dates have not changed, do not recompute timeline value
    // This allows the timeline value to be changed from the slider onChange event without recomputing for the unchanged dates
    // (start and end dates aren't set until the change committed event)
    if (timelineValue && startDate === prevStartDate && endDate === prevEndDate) return;

    const labelFormat = DATE_LABEL_FORMATS[timelineGrouping];

    // Dates are grouped into timeline marks by label. Date value is derived from the corresponding mark
    const getDateTimelineValue = date => {
      const label = moment(date).format(labelFormat);
      const mark = timelineMarks?.find(m => m.label === label);
      return mark ? mark.value : null;
    };

    const startDateVal = getDateTimelineValue(startDate);
    const endDateVal = getDateTimelineValue(endDate);

    // Only update timeline value if different from computed value
    if (!timelineValue || timelineValue[0] !== startDateVal || timelineValue[1] !== endDateVal) {
      setTimelineValue(startDateVal !== null && endDateVal !== null && [startDateVal, endDateVal]);
    }
  }, [
    startDate,
    prevStartDate,
    endDate,
    prevEndDate,
    setTimelineValue,
    timelineGrouping,
    timelineMarks,
    timelineValue,
  ]);

  const handleGroupingChange = useCallback(
    event => {
      const groupBy = event?.target?.value;
      setTimelineGrouping(groupBy);
    },
    [setTimelineGrouping],
  );

  const computeTimelineMarks = useCallback(
    dates => {
      if (!dates.length) {
        setTimelineMarks([{ label: 'No Dates Available', value: 0 }]);
        return;
      }

      const labelFormat = DATE_LABEL_FORMATS[timelineGrouping];

      // Reformat date labels based on new groupBy
      const dateLabelLookup = dates.reduce((labelLookup, date, i) => {
        const label = moment(date).format(labelFormat);

        if (!labelLookup[label]) {
          labelLookup[label] = { label, dates: [] };
        }

        labelLookup[label].dates.push(date);
        return labelLookup;
      }, {});

      const newTimelineMarks = Object.values(dateLabelLookup).map((timelineMark, i) => ({
        ...timelineMark,
        value: i,
      }));
      setTimelineMarks(newTimelineMarks);
    },
    [setTimelineMarks, timelineGrouping],
  );

  const handleTimelineChange = useCallback(
    (event, value) => {
      const newStartDate = timelineMarks[value[0]].dates[0];
      const newEndDateMark = timelineMarks[value[1]];
      const newEndDate = newEndDateMark.dates[newEndDateMark.dates.length - 1];
      filterByDateRange(newStartDate, newEndDate);

      if (activePane) {
        // TODO: RESTRICT to only call when filter is added, not removed
        setActivePane(panelOptions.FILTERS);
      }
    },
    [timelineMarks, filterByDateRange, activePane, setActivePane],
  );

  useEffect(() => {
    if (dates !== prevDates || timelineGrouping !== prevTimelineGrouping) {
      computeTimelineMarks(dates); // reprocess timeline marks for dates change or grouping change
    }
  }, [dates, prevDates, timelineGrouping, prevTimelineGrouping, computeTimelineMarks]);

  if (!timelineMarks || !timelineValue) return <p>There are no dates available.</p>;

  return (
    <>
      <FormControl size='small'>
        <IdsSelect
          className={styles.programSelect}
          value={timelineGrouping}
          label='Grouped By'
          onChange={handleGroupingChange}
          options={GROUPING_OPTIONS}
        />
      </FormControl>
      {timelineMarks.length === 1 ? (
        <Box>
          <small>There is only 1 date:</small>
          <br />
          {timelineMarks[0].label}
        </Box>
      ) : (
        <Box height={calculateHeight}>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box className={styles.timelineContainer}>
              <Box height={timelineMarks.length * 16}>
                <Slider
                  min={0}
                  max={timelineMarks.length - 1}
                  step={1}
                  getAriaLabel={() => 'Dates'}
                  orientation='vertical'
                  value={timelineValue}
                  valueLabelDisplay='off'
                  onChange={e => setTimelineValue(e.target.value)}
                  onChangeCommitted={handleTimelineChange}
                  marks={timelineMarks}
                />
              </Box>
            </Box>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  );
};

export default TimelineFilter;
