import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IdsMultiSelect from '../../IdsMultiSelect';
import { MEDIA_TYPES, MediaType } from '../../../../constants/media';

const sx = {
  checkbox: {
    padding: '3px',
  },
};

export const IMAGE_TYPE_OPTIONS = [
  {
    label: MEDIA_TYPES[MediaType.ProjectPhoto].label,
    value: MEDIA_TYPES[MediaType.ProjectPhoto].type,
  },
  {
    label: MEDIA_TYPES[MediaType.HDPhoto].label,
    value: MEDIA_TYPES[MediaType.HDPhoto].type,
  },
  {
    label: MEDIA_TYPES[MediaType.PanoramicPhoto].label,
    value: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
  },
];

const IdsMediaTypeMultiSelect = ({ value, onChange, ...rest }) => {
  const [controlled, setControlled] = useState(false);
  const [_types, setTypes] = useState(value || IMAGE_TYPE_OPTIONS.map(o => o.value)); // Default to all types

  useEffect(() => {
    if (value !== undefined) {
      setControlled(true);
    }

    if (onChange) {
      onChange(_types);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  useEffect(() => {
    if (!controlled || !value) {
      return;
    }

    setTypes(value);
  }, [controlled, value, setTypes]);

  const handleChange = useCallback(
    newTypes => {
      if (!controlled) {
        // Track types internally
        setTypes(newTypes);
      }

      if (onChange) {
        onChange(newTypes);
      }
    },
    [controlled, onChange, setTypes],
  );

  return (
    <IdsMultiSelect
      {...rest}
      label='Media Type'
      options={IMAGE_TYPE_OPTIONS}
      value={_types}
      onChange={handleChange}
      optionProps={{
        checkbox: {
          sx: sx.checkbox,
        },
      }}
      MenuProps={{
        MenuListProps: {
          dense: true,
          disablePadding: true,
        },
      }}
    />
  );
};

IdsMediaTypeMultiSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string), // Manually control selected types
  onChange: PropTypes.func,
};

export default IdsMediaTypeMultiSelect;
