import { useCallback } from 'react';

import { MEDIA_TYPE_LAYER_IDS } from '../constants/layerIds';

import useImmersiveViewer from './useImmersiveViewer';
import useMediaFromUrn from './useMediaFromUrn';
import useItemsOverrideFilter from './filtering/useItemsOverrideFilter';

const useHighlightMediaMarker = () => {
  const { getLayerMetadata, setLayerMetadata } = useImmersiveViewer();
  const { getMediaTypeFromUrn } = useMediaFromUrn();
  const { addOverrideItem, removeOverrideItem } = useItemsOverrideFilter();

  const transformHighlightedMediaIds = useCallback(
    (mediaType, transform) => {
      const layerId = MEDIA_TYPE_LAYER_IDS[mediaType];
      const metadata = getLayerMetadata(layerId);
      const { highlightedMediaIds } = metadata;
      const newHighlightedMediaIds = transform(highlightedMediaIds);

      if (!newHighlightedMediaIds) {
        return;
      }

      metadata.highlightedMediaIds = newHighlightedMediaIds;
      setLayerMetadata(layerId, metadata);
    },
    [getLayerMetadata, setLayerMetadata],
  );

  // mediaId must be in the urn format to determine the media type
  const highlightMediaMarker = useCallback(
    mediaUrnId => {
      const mediaType = getMediaTypeFromUrn(mediaUrnId);

      // Only add if not already highlighted
      const addMediaId = highlightedMediaIds =>
        !highlightedMediaIds.includes(mediaUrnId) ? [...highlightedMediaIds, mediaUrnId] : null;

      transformHighlightedMediaIds(mediaType, addMediaId);
      addOverrideItem(mediaUrnId, mediaType);
    },
    [getMediaTypeFromUrn, transformHighlightedMediaIds, addOverrideItem],
  );

  // mediaId must be in the urn format to determine the media type
  const unhighlightMediaMarker = useCallback(
    (mediaUrnId, removeFromOverrideFilter = true) => {
      const mediaType = getMediaTypeFromUrn(mediaUrnId);

      // Only set if already highlighted
      const removeMediaId = highlightedMediaIds =>
        highlightedMediaIds.includes(mediaUrnId)
          ? highlightedMediaIds.filter(id => id !== mediaUrnId)
          : null;

      transformHighlightedMediaIds(mediaType, removeMediaId);

      if (removeFromOverrideFilter) {
        removeOverrideItem(mediaUrnId, mediaType);
      }
    },
    [getMediaTypeFromUrn, transformHighlightedMediaIds, removeOverrideItem],
  );

  return {
    highlightMediaMarker,
    unhighlightMediaMarker,
  };
};

export default useHighlightMediaMarker;
