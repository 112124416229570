import React from 'react';

import { IIdsCheckboxProps } from '../../../../ids-inputs/IdsCheckbox';
import { IOrganizationAssignmentsListEdge } from '../../../../../services/AssignmentsService';
import AssignmentListItem from '../../../../../views/Assignments/AssignmentListItem';

export interface IAssignmentAutocompleteOptionProps extends HTMLLiElementProps {
  option: {
    node: IOrganizationAssignmentsListEdge['node'];
  };
  selected: boolean;
  checkboxProps?: Omit<IIdsCheckboxProps, 'checked' | 'label'>;
}

/**
 * Just a wrapper to make the lit item component compatible with the
 * autocomplete dropdown list.
 */
const AssignmentAutocompleteOption: React.FC<IAssignmentAutocompleteOptionProps> = ({
  option,
  selected,
  checkboxProps,
  ...rest
}) => {
  return (
    <li {...rest}>
      <AssignmentListItem assignmentItem={option.node} />
    </li>
  );
};

export default AssignmentAutocompleteOption;
