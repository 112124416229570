import React, { useCallback, useMemo, useState } from 'react';

import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../../services/LocationService';
import { PositionAction } from '../../../../services/types';
import { useUpdateOverlayPositionByOne } from '../../../../services/OverlaysService';

import { activeLocationState } from '../../../../atoms/locations';
import { activeOrganizationState } from '../../../../atoms/organizations';

import { EntityType } from '../../../../constants/entities';

import OverlaysList, { OverlaysListFrag } from '../OverlaysList';
import EditOverlayDialog from '../OverlaysList/EditOverlayDialog';
import useDeleteOverlayAction from '../OverlaysList/useDeleteOverlayAction';

const VectorOverlaysQuery = gql`
  query VectorOverlays($orgId: ID, $locationId: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      vectorOverlays {
        ...OverlaysListFrag
      }
    }
  }
  ${OverlaysListFrag}
`;

function VectorOverlaysTab() {
  const locationKeys = useLocationKeys();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOverlay, setSelectedOverlay] = useState(null);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const { mutateAsync: updateOverlayPositionByOneMutation } = useUpdateOverlayPositionByOne(
    activeLocation.id,
  );

  const { data, error, isLoading } = useOrgGraphQuery(
    [...locationKeys.detail(activeLocation.id), 'vectorOverlays'],
    VectorOverlaysQuery,
    { locationId: activeLocation.id },
  );

  const overlays = useMemo(() => {
    return data?.location.vectorOverlays || [];
  }, [data]);

  const maxPositions = overlays.length;

  const changeOverlayPosition = useCallback(
    async (urnId, action, currentPosition) => {
      if (
        (action === PositionAction.Increment && currentPosition === maxPositions) ||
        (action === PositionAction.Decrement && currentPosition === 1)
      ) {
        return;
      }
      await updateOverlayPositionByOneMutation({
        id: urnId,
        organizationId: activeOrg.id,
        action: action,
        currentPosition: currentPosition,
      });
    },
    [activeOrg.id, updateOverlayPositionByOneMutation, maxPositions],
  );

  const toggleEditDialogState = useCallback(() => {
    setEditDialogOpen(prev => !prev);
  }, [setEditDialogOpen]);

  const editOverlay = useCallback(
    urnId => {
      const thisOverlay = overlays.find(overlay => overlay.id === urnId);
      setSelectedOverlay(thisOverlay);
      setEditDialogOpen(true);
    },
    [overlays],
  );

  return (
    <>
      <OverlaysList
        overlays={overlays}
        entityType={EntityType.VectorOverlay}
        onChangeOverlayPosition={changeOverlayPosition}
        onEditOverlay={editOverlay}
        useDeleteOverlayAction={useDeleteOverlayAction}
        searchPlaceholder='Search vector overlays'
        noItemsMessage='No vector overlays'
        noItemsForFilterMessage='No vector overlays match your search'
        loading={isLoading}
        error={error && 'Vector overlays could not be loaded'}
      />
      <EditOverlayDialog
        open={editDialogOpen}
        onOpen={toggleEditDialogState}
        onClose={toggleEditDialogState}
        overlay={selectedOverlay}
        maxPositions={maxPositions}
      />
    </>
  );
}

export default VectorOverlaysTab;
