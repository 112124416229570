import * as Yup from 'yup';

export const latitudeValidation = Yup.number()
  .typeError('Must be valid latitude')
  .moreThan(-90, 'Must be greater than -90')
  .lessThan(90, 'Must be less than 90');

export const longitudeValidation = Yup.number()
  .typeError('Must be a valid longitude')
  .moreThan(-180, 'Must be greater than -180')
  .lessThan(180, 'Must be less than 180');

export const altitudeValidation = Yup.number().typeError('Must be a valid altitude');

export const headingValidation = Yup.number()
  .typeError('Must be a number')
  .min(0, 'Must be 0 or greater')
  .lessThan(360, 'Must be less than 360');
