import { useMutation } from 'react-query';
import { gql } from '@apollo/client';

import RuntimeConfig from '../RuntimeConfig';
import axios from '../utils/axios';
import queryClient from '../utils/query';
import { MediaType } from '../constants/media';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';

import { IBasicImageFieldsInput } from './types';

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

/**
 * Gets the url to the krpano xml for the specified project photo.
 * @param {*} id type specific project photo id, not the general media urn id
 */
export function getProjectPhotoXMLUrl(id: string) {
  return `${RuntimeConfig.apiBaseUrl}/api/v2/user_photos/${id}/krpano.xml`;
}

interface IUpdateProjectPhotoPayload {
  id: string;
  projectId?: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  photoCategoryId?: string;
  photoTypeId?: string;
  capturedAt?: string;
  photoAreaId?: string;
  photoLevelId?: string;
  notes?: string;
  photoTags?: number[];
  newPhotoTags?: string[];
}

const updateProjectPhoto = ({
  id,
  projectId,
  latitude,
  longitude,
  description,
  photoCategoryId,
  photoTypeId,
  capturedAt,
  photoAreaId,
  photoLevelId,
  notes,
  photoTags,
  newPhotoTags,
}: IUpdateProjectPhotoPayload) => {
  return axios.put(`/api/v2/user_photos/${id}`, {
    user_photo: {
      project_id: projectId,
      latitude,
      longitude,
      description,
      photo_category_id: photoCategoryId,
      photo_type_id: photoTypeId,
      captured_at: capturedAt,
      photo_area_id: photoAreaId,
      photo_level_id: photoLevelId,
      notes,
      photo_tags: photoTags,
      new_photo_tags: newPhotoTags,
    },
  });
};

export function useUpdateProjectPhoto() {
  return useMutation(updateProjectPhoto, {
    onError,
    onSuccess: (_: any, { id }: IUpdateProjectPhotoPayload) => {
      queryClient.invalidateQueries({
        // Invalidate all queries for project photos
        predicate: query =>
          query.queryKey.includes(MediaType.ProjectPhoto) || query.queryKey.includes(id),
        refetchActive: false,
      });
    },
  });
}

export const useDeleteProjectPhoto = () => {
  return useMutation(
    (id: string) => {
      return axios.delete(`/api/v2/user_photos/${id}`);
    },
    {
      onError,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(MediaType.ProjectPhoto),
          refetchActive: false,
        });
      },
    },
  );
};

const UpdateProjectPhotoMutation = gql`
  mutation UpdateProjectPhoto(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateProjectPhotoInput!
  ) {
    updateProjectPhoto(where: $where, input: $input) {
      image {
        metadata {
          type
          id
          value
          creatorId
        }
      }
      errors {
        message
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUpdateProjectPhotoInput extends IBasicImageFieldsInput {}

const updateProjectPhotoMutation = ({
  id,
  organizationId,
  input,
}: {
  id: string;
  organizationId: string;
  input: IUpdateProjectPhotoInput;
}) => {
  return buildGraphMutationFn(UpdateProjectPhotoMutation)({
    input: {
      ...input,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export const useUpdateProjectPhotoMutation = () => {
  return useMutation(updateProjectPhotoMutation, {
    onError,
    onSuccess: async (_: any, { id }) => {
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes(MediaType.ProjectPhoto) || query.queryKey.includes(id),
        refetchActive: false,
      });
    },
  });
};
