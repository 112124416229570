import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { UserIcon } from '../../theme/icons';

import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgUserRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface IUserChipProps extends IEntityChipProps {
  userId?: string;
  name?: string;
}

const UserChip: React.FC<IUserChipProps> = ({ userId, name, ...rest }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);

  const label = name || `User #${userId}`;

  return (
    <EntityChip
      icon={<UserIcon size={16} />}
      label={label}
      entityRoute={getOrgUserRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        userId,
      })}
      {...rest}
    />
  );
};

export default UserChip;
