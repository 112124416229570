import React, { useMemo } from 'react';
import { Card, Container, Divider } from '@mui/material';

import { useRecoilValue } from 'recoil';

import globalSx from '../../../theme/globalSx';
import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import IdsTabsRenderer from '../../../components/IdsTabsRenderer';
import { activeOrganizationState } from '../../../atoms/organizations';
// import usePermissions from '../../../hooks/usePermissions';
// import { USER_ROLES } from '../../../constants/users';

// import OrganizationDetailActions from './OrganizationDetailActions';
import OrgInfoTab from './OrgInfoTab';
// import OrgActivityFeedTab from './OrgActivityFeedTab';
import ExampleImagesTab from './ExampleImagesTab';
// import MappingTreesTab from './MappingTreesTab';
// import MetadataTab from './MetadataTab';

import styles from './OrganizationDetails.module.css';

function OrganizationDetails() {
  const { name } = useRecoilValue(activeOrganizationState);
  // const { userHasOneOfRoles } = usePermissions();

  const tabs = useMemo(() => {
    const _tabs = [
      {
        key: 'info',
        label: 'Info',
        content: <OrgInfoTab />,
      },
      // {
      //   key: 'activity_feed',
      //   label: 'Activity Feed',
      //   content: <OrgActivityFeedTab />,
      // },
      {
        key: 'example_images',
        label: 'Example Images',
        content: <ExampleImagesTab />,
      },
      // {
      //   key: 'mapping_trees',
      //   label: 'Mapping Trees',
      //   content: <MappingTreesTab />,
      // },
    ];

    // TODO: remove this special check once custom fields are ready for public
    // if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN])) {
    //   _tabs.push({
    //     key: 'metadata',
    //     label: 'Metadata',
    //     content: <MetadataTab canCreateMetadataTypes={true} />,
    //   });
    // }

    return _tabs;
    // }, [userHasOneOfRoles]);
  }, []);

  return (
    <Page title={name} className={styles['page']}>
      <Container maxWidth={false} className={styles['pageContainer']}>
        {/* <NestedOrgViewHeader currentCrumb={name} actions={<OrganizationDetailActions />} /> */}
        <NestedOrgViewHeader currentCrumb={name} />
        <Card sx={globalSx.pageContent} className={styles['card']}>
          <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
        </Card>
      </Container>
    </Page>
  );
}

export default OrganizationDetails;
