// Design Documentation: https://immersiondata.atlassian.net/wiki/spaces/IDS/pages/527237121/Iconography+Design+Process#Importing-Marker-Icons-Into-DeckGL-Layers

import svgToDataURL from '../../../../utils/svgToDataURL';

const defaultIconProps = {
  height: 256,
  width: 256,
  anchorY: 256,
};

const buildProjectPhotoMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <path class="e" d="m177.05,145.01v-76.3c0-5.99-4.9-10.9-10.9-10.9h-76.3c-5.99,0-10.9,4.9-10.9,10.9v76.3c0,5.99,4.9,10.9,10.9,10.9h76.3c5.99,0,10.9-4.9,10.9-10.9Zm-68.12-29.97l13.62,16.4,19.07-24.58,24.52,32.7h-76.3l19.07-24.52Z"/>
    </g>
  </svg>
`;

export const getProjectPhotoMarkerIcon = (backgroundColor, iconColor) => ({
  id: `project-photo-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(buildProjectPhotoMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDottedProjectPhotoMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .e {
          fill: ${backgroundColor};
        }

        .f {
          fill: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="e" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="f" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Marker Bubbles">
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
    <g id="d" data-name="Filled Icons">
      <path class="f" d="m177.05,145.01v-76.3c0-5.99-4.9-10.9-10.9-10.9h-76.3c-5.99,0-10.9,4.9-10.9,10.9v76.3c0,5.99,4.9,10.9,10.9,10.9h76.3c5.99,0,10.9-4.9,10.9-10.9Zm-68.12-29.97l13.62,16.4,19.07-24.58,24.52,32.7h-76.3l19.07-24.52Z"/>
    </g>
  </svg>
`;

export const getProjectPhotoMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `project-photo-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDottedProjectPhotoMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const buildHDPhotoMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <g>
      <path class="e" d="m166.15,59.22h-76.3c-6.05,0-10.9,4.9-10.9,10.9v76.3c0,5.99,4.85,10.9,10.9,10.9h76.3c5.99,0,10.9-4.9,10.9-10.9v-76.3c0-5.99-4.9-10.9-10.9-10.9Zm-43.6,65.4h-8.17v-10.9h-10.9v10.9h-8.17v-32.7h8.17v13.62h10.9v-13.62h8.17v32.7Zm10.9-32.7h21.8c3,0,5.45,2.45,5.45,5.45v21.8c0,3-2.45,5.45-5.45,5.45h-21.8v-32.7Zm8.17,24.52h10.9v-16.35h-10.9v16.35Z"/>
      </g>
    </g>
  </svg>
`;

export const getHDPhotoMarkerIcon = (backgroundColor, iconColor) => ({
  id: `hd-photo-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(buildHDPhotoMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDottedHDPhotoMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .e {
          fill: ${backgroundColor};
        }

        .f {
          fill: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="e" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="f" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Marker Bubbles">
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
    <g id="d" data-name="Filled Icons">
      <path class="f" d="m166.15,59.22h-76.3c-6.05,0-10.9,4.9-10.9,10.9v76.3c0,5.99,4.85,10.9,10.9,10.9h76.3c5.99,0,10.9-4.9,10.9-10.9v-76.3c0-5.99-4.9-10.9-10.9-10.9Zm-43.6,65.4h-8.17v-10.9h-10.9v10.9h-8.17v-32.7h8.17v13.62h10.9v-13.62h8.17v32.7Zm10.9-32.7h21.8c3,0,5.45,2.45,5.45,5.45v21.8c0,3-2.45,5.45-5.45,5.45h-21.8v-32.7Zm8.17,24.52h10.9v-16.35h-10.9v16.35Z"/>
    </g>
  </svg>
`;

export const getHDPhotoMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `hd-photo-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDottedHDPhotoMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const buildPanoramaMarkerIcon = (backgroundColor, iconColor, opacity) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
          fill-opacity: ${opacity};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <path class="e" d="m128,64.89c-37.24,0-66.42,15.25-66.42,34.71,0,14.97,17.73,27.97,44.39,32.78v10.28c0,3.41,2.76,6.18,6.16,6.18,1.65,0,3.21-.66,4.35-1.84l17.69-17.69c2.45-2.45,2.45-6.29,0-8.74l-17.7-17.7c-1.16-1.15-2.69-1.79-4.3-1.79-3.42,0-6.2,2.78-6.2,6.2v5.99c-17.77-3.87-25.71-11.29-25.71-13.66,0-3.99,16.93-16.03,47.73-16.03s47.73,12.04,47.73,16.03c0,2.05-5.72,7.86-18.97,11.98-3.83,1.22-6.4,4.76-6.4,8.82,0,6.09,6.2,10.77,12.01,8.95,20.07-6.24,32.04-17.36,32.04-29.75,0-19.46-29.17-34.71-66.42-34.71Z"/>
    </g>
  </svg>
`;

export const getPanoramaMarkerIcon = (backgroundColor, iconColor, opacity) => ({
  id: `panorama-${backgroundColor}-${iconColor}-${opacity}`,
  url: svgToDataURL(buildPanoramaMarkerIcon(backgroundColor, iconColor, opacity)),
  ...defaultIconProps,
});

const buildDottedPanoramaMarkerIcon = (backgroundColor, iconColor, opacity, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .e {
          fill: ${backgroundColor};
          fill-opacity: ${opacity};
        }

        .f {
          fill: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="e" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="f" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Marker Bubbles">
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
    <g id="d" data-name="Filled Icons">
      <path class="f" d="m128,64.89c-37.24,0-66.42,15.25-66.42,34.71,0,14.97,17.73,27.97,44.39,32.78v10.28c0,3.41,2.76,6.18,6.16,6.18,1.65,0,3.21-.66,4.35-1.84l17.69-17.69c2.45-2.45,2.45-6.29,0-8.74l-17.7-17.7c-1.16-1.15-2.69-1.79-4.3-1.79-3.42,0-6.2,2.78-6.2,6.2v5.99c-17.77-3.87-25.71-11.29-25.71-13.66,0-3.99,16.93-16.03,47.73-16.03s47.73,12.04,47.73,16.03c0,2.05-5.72,7.86-18.97,11.98-3.83,1.22-6.4,4.76-6.4,8.82,0,6.09,6.2,10.77,12.01,8.95,20.07-6.24,32.04-17.36,32.04-29.75,0-19.46-29.17-34.71-66.42-34.71Z"/>
    </g>
  </svg>
`;

export const getPanoramaMarkerDottedIcon = (backgroundColor, iconColor, opacity, dotColor) => ({
  id: `panorama-${backgroundColor}-${iconColor}-${opacity}-${dotColor}`,
  url: svgToDataURL(buildDottedPanoramaMarkerIcon(backgroundColor, iconColor, opacity, dotColor)),
  ...defaultIconProps,
});

const buildVideoMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <path class="e" d="m158.25,97.79v-21.17c0-3.33-2.72-6.05-6.05-6.05h-72.6c-3.33,0-6.05,2.72-6.05,6.05v60.5c0,3.33,2.72,6.05,6.05,6.05h72.6c3.33,0,6.05-2.72,6.05-6.05v-21.17l13.85,13.85c3.81,3.81,10.34,1.09,10.34-4.3v-37.33c0-5.38-6.53-8.11-10.34-4.3l-13.85,13.91Z"/>
    </g>
  </svg>
`;

export const getVideoMarkerIcon = (backgroundColor, iconColor) => ({
  id: `video-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(buildVideoMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDottedVideoMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .e {
          fill: ${backgroundColor};
        }

        .f {
          fill: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="e" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="f" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Marker Bubbles">
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
    <g id="d" data-name="Filled Icons">
      <path class="f" d="m158.25,97.79v-21.17c0-3.33-2.72-6.05-6.05-6.05h-72.6c-3.33,0-6.05,2.72-6.05,6.05v60.5c0,3.33,2.72,6.05,6.05,6.05h72.6c3.33,0,6.05-2.72,6.05-6.05v-21.17l13.85,13.85c3.81,3.81,10.34,1.09,10.34-4.3v-37.33c0-5.38-6.53-8.11-10.34-4.3l-13.85,13.91Z"/>
    </g>
  </svg>
`;

export const getVideoMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `video-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDottedVideoMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const build3DModelMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <g>
        <path class="e" d="m130.91,45.42c-1.8-1.04-4.03-1.04-5.82,0l-48.09,27.76,5.83,3.36,5.82,3.36,39.36,22.72,39.36-22.72,5.82-3.36,5.83-3.36-48.09-27.76Z"/>
        <path class="e" d="m177.54,84.11l-5.82,3.36-39.35,22.72v58.89l48.09-27.76c1.8-1.04,2.91-2.96,2.91-5.04v-55.52l-5.83,3.36Z"/>
        <path class="e" d="m84.28,87.48l-5.82-3.36-5.82-3.36v55.52c0,2.08,1.11,4,2.91,5.04l48.09,27.76v-58.89l-39.35-22.72Z"/>
      </g>
    </g>
  </svg>
`;

export const get3DModelMarkerIcon = (backgroundColor, iconColor) => ({
  id: `3d-model-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(build3DModelMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDotted3DModelMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .e {
          fill: ${backgroundColor};
        }

        .f {
          fill: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="e" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="f" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Marker Bubbles">
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
    <g id="d" data-name="Filled Icons">
      <g>
        <path class="f" d="m130.91,45.42c-1.8-1.04-4.03-1.04-5.82,0l-48.09,27.76,5.83,3.36,5.82,3.36,39.36,22.72,39.36-22.72,5.82-3.36,5.83-3.36-48.09-27.76Z"/>
        <path class="f" d="m177.54,84.11l-5.82,3.36-39.35,22.72v58.89l48.09-27.76c1.8-1.04,2.91-2.96,2.91-5.04v-55.52l-5.83,3.36Z"/>
        <path class="f" d="m84.28,87.48l-5.82-3.36-5.82-3.36v55.52c0,2.08,1.11,4,2.91,5.04l48.09,27.76v-58.89l-39.35-22.72Z"/>
      </g>
    </g>
  </svg>
`;

export const get3DModelMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `3d-model-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDotted3DModelMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const build3DVRMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <g>
        <path class="e" d="m60.04,115.4c0,15.22,19.98,28.07,47.57,32.42v8.54c0,3.06,3.67,4.55,5.78,2.38l13.77-13.77c1.36-1.36,1.36-3.47,0-4.83l-13.77-13.77c-2.11-2.11-5.78-.61-5.78,2.45v5.14c-21.41-3.81-33.98-12.91-33.98-18.55,0-4.28,7.28-10.66,20.26-15.14v-14.24c-20.23,5.89-33.85,16.84-33.85,29.38Z"/>
        <path class="e" d="m162.11,86.02v14.24c12.97,4.48,20.26,10.87,20.26,15.14s-8.16,11.42-22.56,15.9c-2.79.88-4.62,3.47-4.62,6.39,0,4.55,4.42,7.88,8.7,6.52,19.23-5.98,32.08-16.65,32.08-28.81s-13.62-23.49-33.85-29.38Z"/>
        <g>
          <path class="e" d="m129.98,54.44c-1.11-.64-2.49-.64-3.6,0l-29.75,17.18,3.6,2.08,3.6,2.08,24.35,14.06,24.35-14.06,3.6-2.08,3.6-2.08-29.75-17.18Z"/>
          <path class="e" d="m158.83,78.38l-3.6,2.08-24.34,14.05v36.43l29.75-17.17c1.12-.64,1.8-1.83,1.8-3.12v-34.35l-3.6,2.08Z"/>
          <path class="e" d="m101.13,80.46l-3.6-2.08-3.6-2.08v34.35c0,1.29.69,2.48,1.8,3.12l29.75,17.17v-36.43l-24.34-14.05Z"/>
        </g>
      </g>
    </g>
  </svg>
`;

export const get3DVRMarkerIcon = (backgroundColor, iconColor) => ({
  id: `3dvr-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(build3DVRMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDotted3DVRMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .e {
          fill: ${backgroundColor};
        }

        .f {
          fill: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="e" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="f" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Marker Bubbles">
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
    <g id="d" data-name="Filled Icons">
      <g>
        <path class="f" d="m60.04,115.4c0,15.22,19.98,28.07,47.57,32.42v8.54c0,3.06,3.67,4.55,5.78,2.38l13.77-13.77c1.36-1.36,1.36-3.47,0-4.83l-13.77-13.77c-2.11-2.11-5.78-.61-5.78,2.45v5.14c-21.41-3.81-33.98-12.91-33.98-18.55,0-4.28,7.28-10.66,20.26-15.14v-14.24c-20.23,5.89-33.85,16.84-33.85,29.38Z"/>
        <path class="f" d="m162.11,86.02v14.24c12.97,4.48,20.26,10.87,20.26,15.14s-8.16,11.42-22.56,15.9c-2.79.88-4.62,3.47-4.62,6.39,0,4.55,4.42,7.88,8.7,6.52,19.23-5.98,32.08-16.65,32.08-28.81s-13.62-23.49-33.85-29.38Z"/>
        <g>
          <path class="f" d="m129.98,54.44c-1.11-.64-2.49-.64-3.6,0l-29.75,17.18,3.6,2.08,3.6,2.08,24.35,14.06,24.35-14.06,3.6-2.08,3.6-2.08-29.75-17.18Z"/>
          <path class="f" d="m158.83,78.38l-3.6,2.08-24.34,14.05v36.43l29.75-17.17c1.12-.64,1.8-1.83,1.8-3.12v-34.35l-3.6,2.08Z"/>
          <path class="f" d="m101.13,80.46l-3.6-2.08-3.6-2.08v34.35c0,1.29.69,2.48,1.8,3.12l29.75,17.17v-36.43l-24.34-14.05Z"/>
        </g>
      </g>
    </g>
  </svg>
`;

export const get3DVRMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `3dvr-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDotted3DVRMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const buildPOIMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <path class="e" d="m128,42.2c-35.6,0-64.49,28.89-64.49,64.49s28.89,64.49,64.49,64.49,64.49-28.89,64.49-64.49-28.89-64.49-64.49-64.49Zm6.45,96.73h-12.9v-38.69h12.9v38.69Zm0-51.59h-12.9v-12.9h12.9v12.9Z"/>
    </g>
  </svg>
`;

export const getPOIMarkerIcon = (backgroundColor, iconColor) => ({
  id: `poi-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(buildPOIMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDottedPOIMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }

        .f {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <path class="e" d="m128,42.2c-35.6,0-64.49,28.89-64.49,64.49s28.89,64.49,64.49,64.49,64.49-28.89,64.49-64.49-28.89-64.49-64.49-64.49Zm6.45,96.73h-12.9v-38.69h12.9v38.69Zm0-51.59h-12.9v-12.9h12.9v12.9Z"/>
      <circle class="f" cx="205.86" cy="39.01" r="32.54"/>
    </g>
  </svg>
`;

export const getPOIMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `poi-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDottedPOIMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const buildAssetMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }

        .f {
          stroke: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <g style="transform-origin: 0.479px -1.514px;" transform="matrix(4.099144, 0, 0, 4.099144, 59.781167, 37.671246)">
        <g>
          <g transform="matrix(1, 0, 0, 1, 0, -0.051724)">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(0.309017, 0.951057, -0.951057, 0.309017, 11.765682, 8.482483)" style="transform-origin: 16.999px 3.5195px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(-0.809017, 0.587785, -0.587785, -0.809017, 7.277653, 22.309904)" style="transform-origin: 16.999px 3.5195px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(-0.309017, 0.951057, 0.951057, 0.309017, -11.754488, 8.481863)" style="transform-origin: 16.999px 3.52px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(0.809017, 0.587785, 0.587785, -0.809017, -7.266464, 22.309277)" style="transform-origin: 16.999px 3.52px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <path class="e" d="M 26.196 15.784 C 26.209 20.863 22.102 24.99 17.023 25.003 C 11.944 25.016 7.817 20.909 7.804 15.83 C 7.791 10.751 11.898 6.624 16.977 6.611 C 22.056 6.598 26.183 10.705 26.196 15.784 Z M 20.002 20.261 L 21.969 20.261 L 17.85 9.394 L 16.159 9.394 L 12.053 20.261 L 14.011 20.261 L 14.894 17.846 L 19.107 17.846 L 20.002 20.261 Z M 17.019 11.7 L 18.588 16.325 L 15.436 16.325 L 17.019 11.7 Z" style="stroke-width: 1.63017px;"></path>
        </g>
      </g>
    </g>
  </svg>
`;

export const getAssetMarkerIcon = (backgroundColor, iconColor) => ({
  id: `asset-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(buildAssetMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});

const buildDottedAssetMarkerIcon = (backgroundColor, iconColor, dotColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }

        .f {
          stroke: ${iconColor};
        }

        .g {
          fill: ${dotColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <g id="c" data-name="Filled Icons">
      <g style="transform-origin: 0.479px -1.514px;" transform="matrix(4.099144, 0, 0, 4.099144, 59.781167, 37.671246)">
        <g>
          <g transform="matrix(1, 0, 0, 1, 0, -0.051724)">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(0.309017, 0.951057, -0.951057, 0.309017, 11.765682, 8.482483)" style="transform-origin: 16.999px 3.5195px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(-0.809017, 0.587785, -0.587785, -0.809017, 7.277653, 22.309904)" style="transform-origin: 16.999px 3.5195px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(-0.309017, 0.951057, 0.951057, 0.309017, -11.754488, 8.481863)" style="transform-origin: 16.999px 3.52px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <g transform="matrix(0.809017, 0.587785, 0.587785, -0.809017, -7.266464, 22.309277)" style="transform-origin: 16.999px 3.52px;">
            <ellipse class="e f" style="stroke-width: 1.63017px;" cx="-16.999" cy="-2.068" rx="2.524" ry="2.524" transform="matrix(-1, 0, 0, -1, 0, 0)"></ellipse>
            <line class="f" style="stroke-width: 1.63017px; transform-origin: 16.977px 6.049px;" x1="16.985" y1="4.604" x2="16.994" y2="7.495"></line>
          </g>
          <path class="e" d="M 26.196 15.784 C 26.209 20.863 22.102 24.99 17.023 25.003 C 11.944 25.016 7.817 20.909 7.804 15.83 C 7.791 10.751 11.898 6.624 16.977 6.611 C 22.056 6.598 26.183 10.705 26.196 15.784 Z M 20.002 20.261 L 21.969 20.261 L 17.85 9.394 L 16.159 9.394 L 12.053 20.261 L 14.011 20.261 L 14.894 17.846 L 19.107 17.846 L 20.002 20.261 Z M 17.019 11.7 L 18.588 16.325 L 15.436 16.325 L 17.019 11.7 Z" style="stroke-width: 1.63017px;"></path>
        </g>
      </g>
      <circle class="g" cx="205.86" cy="39.01" r="32.54"/>
    </g>
  </svg>
`;

export const getAssetMarkerDottedIcon = (backgroundColor, iconColor, dotColor) => ({
  id: `asset-${backgroundColor}-${iconColor}-${dotColor}`,
  url: svgToDataURL(buildDottedAssetMarkerIcon(backgroundColor, iconColor, dotColor)),
  ...defaultIconProps,
});

const buildAlignmentMarkerIcon = (backgroundColor, iconColor) => `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
    <defs>
      <style>
        .d {
          fill: ${backgroundColor};
        }

        .e {
          fill: ${iconColor};
        }
      </style>
    </defs>
    <g id="b" data-name="Markers">
      <g>
        <path class="d" d="m128,22.05c-46.13,0-83.65,37.53-83.65,83.65,0,2.14.03,4.1.09,6.02.64,20.22,8.97,39.33,23.45,53.82l60.11,60.13,60.11-60.13c14.48-14.49,22.81-33.6,23.45-53.82.06-1.91.09-3.88.09-6.01,0-46.13-37.53-83.65-83.65-83.65Z"/>
        <path class="e" d="m128,2.05C70.85,2.05,24.35,48.54,24.35,105.7c0,2.35.03,4.52.1,6.65.81,25.32,11.21,49.23,29.3,67.32l74.25,74.28,74.25-74.28c18.09-18.09,28.49-42,29.3-67.33.07-2.13.1-4.3.1-6.65,0-57.15-46.5-103.65-103.65-103.65Zm83.56,109.67c-.64,20.22-8.97,39.33-23.45,53.82l-60.11,60.13-60.11-60.13c-14.48-14.49-22.81-33.6-23.45-53.82-.06-1.92-.09-3.88-.09-6.02,0-46.13,37.53-83.65,83.65-83.65s83.65,37.53,83.65,83.65c0,2.14-.03,4.1-.09,6.01Z"/>
      </g>
    </g>
    <path class="e" d="M 151.109 64.689 L 139.552 76.182 L 127.994 64.689 L 116.437 76.182 L 104.881 64.689 L 127.994 41.576 L 151.109 64.689 Z M 170.175 129.983 L 158.682 118.427 L 170.175 106.869 L 158.682 95.312 L 170.175 83.755 L 193.288 106.869 L 170.175 129.983 Z M 104.881 149.049 L 116.437 137.558 L 127.994 149.049 L 139.552 137.558 L 151.109 149.049 L 127.994 172.163 L 104.881 149.049 Z M 85.815 83.755 L 97.306 95.312 L 85.815 106.869 L 97.306 118.427 L 85.815 129.983 L 62.701 106.869 L 85.815 83.755 Z M 147.582 106.869 C 147.582 117.688 138.812 126.458 127.994 126.458 C 117.174 126.458 108.406 117.688 108.406 106.869 C 108.406 96.052 117.174 87.282 127.994 87.282 C 138.812 87.282 147.582 96.052 147.582 106.869 Z"></path>
  </svg>
`;

export const getAlignmentMarkerIcon = (backgroundColor, iconColor) => ({
  id: `alignment-${backgroundColor}-${iconColor}`,
  url: svgToDataURL(buildAlignmentMarkerIcon(backgroundColor, iconColor)),
  ...defaultIconProps,
});
