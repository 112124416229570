import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import IdsMultiSelect from '../../IdsMultiSelect';

const sx = {
  checkbox: {
    padding: '3px',
  },
};

const MediaMetadataMultiSelect = ({ metadataItems, value, onChange, sortOptions, ...rest }) => {
  const metadataOptions = useMemo(() => {
    const options =
      metadataItems?.map(i => ({
        label: i.value,
        value: i.id,
      })) || [];
    return sortOptions && options.length ? options.sort(sortOptions) : options;
  }, [metadataItems, sortOptions]);

  const handleChange = useCallback(
    newItemIds => {
      let selected = metadataOptions
        .filter(o => newItemIds.some(e => e === o.value))
        .map(item => item.value);
      selected = selected.toString().replace(/,/g, ', '); // insert space after each comma
      if (onChange) {
        onChange(newItemIds, selected);
      }
    },
    [onChange, metadataOptions],
  );

  return (
    <IdsMultiSelect
      {...rest}
      options={metadataOptions}
      value={value}
      onChange={handleChange}
      optionProps={{
        checkbox: {
          sx: sx.checkbox,
        },
      }}
      MenuProps={{
        MenuListProps: {
          dense: true,
          disablePadding: true,
        },
      }}
    />
  );
};

MediaMetadataMultiSelect.propTypes = {
  metadataItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  sortOptions: PropTypes.func,
};

export default MediaMetadataMultiSelect;
