import 'react-perfect-scrollbar/dist/css/styles.css';
import { render } from 'react-dom';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { SettingsProvider } from './context/SettingsContext';
import { restoreSettings } from './utils/settings';
import reportWebVitals from './reportWebVitals';
import App from './App';
import SomethingWentWrong from './views/SomethingWentWrong';

const rootElement = document.getElementById('root');

const settings = restoreSettings();

/**
 * @TODO: This is necessary to avoid warnings in browser console.
 * These flags will not be needed in future, so remove when possible.
 */
const future = {
  v7_startTransition: true,
  v7_relativeSplatPath: true,
  v7_normalizeFormMethod: true,
  v7_fetcherPersist: true,
  v7_partialHydration: true,
  v7_skipActionStatusRevalidation: true,
  v7_skipActionErrorRevalidation: true,
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='*' element={<App />} errorElement={<SomethingWentWrong />} />,
  ),
  {
    future,
  },
);

render(
  <SettingsProvider settings={settings}>
    <RouterProvider future={future} router={router} />
  </SettingsProvider>,
  rootElement,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
