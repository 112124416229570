import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { gql } from 'graphql-request';

import IdsDataAttribute from '../../../../components/IdsDataAttribute';

import styles from './LocationInfoTab.module.css';

export const LocationInfoTabFrag = gql`
  fragment LocationInfoTabFrag on Location {
    position {
      latitude
      longitude
    }
    heading
  }
`;

const LocationInfoTab = ({ location }) => {
  const { position, heading } = location;

  return (
    <Grid container spacing={1} direction='column' className={styles.container}>
      <IdsDataAttribute label='Latitude' value={position?.latitude} />
      <IdsDataAttribute label='Longitude' value={position?.longitude} />
      <IdsDataAttribute label='Heading' value={heading} />
    </Grid>
  );
};

LocationInfoTab.propTypes = {
  location: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    heading: PropTypes.number,
  }),
};

export default LocationInfoTab;
