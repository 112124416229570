import { useCallback, useMemo, useState } from 'react';

export enum BooleanStateSummary {
  ALL_TRUE = 'all_true',
  MIXED = 'mixed',
  ALL_FALSE = 'all_false',
}

/** Handles state management for tracking a boolean value for items by id. If the id is not set, defaults to false. */
const useItemsBooleanState = <TId extends keyof any = string>() => {
  const [itemIdBooleanStateMap, setItemIdBooleanStateMap] = useState<Record<TId, boolean>>(
    {} as Record<TId, boolean>,
  );

  const selectedItemIds = useMemo(() => {
    return Object.keys(itemIdBooleanStateMap) as TId[];
  }, [itemIdBooleanStateMap]);

  const totalTrue = useMemo(() => {
    return Object.keys(itemIdBooleanStateMap).length;
  }, [itemIdBooleanStateMap]);

  const getItemState = useCallback(
    (id: TId) => {
      return !!itemIdBooleanStateMap[id];
    },
    [itemIdBooleanStateMap],
  );

  const getNumberOfItemsTrue = useCallback(
    (ids: TId[]) => {
      return ids.reduce((sum, id) => {
        return getItemState(id) ? ++sum : sum;
      }, 0);
    },
    [getItemState],
  );

  /** Get a summary of the boolean values of the provided ids. */
  const getItemsStateSummary = useCallback(
    (ids: TId[]) => {
      if (!Object.keys(itemIdBooleanStateMap).length) return BooleanStateSummary.ALL_FALSE;

      let numTrue = 0;
      let numFalse = 0;

      for (let i = 0; i < ids.length; i++) {
        if (getItemState(ids[i])) {
          if (numFalse > 0) return BooleanStateSummary.MIXED;
          numTrue++;
        } else {
          if (numTrue > 0) return BooleanStateSummary.MIXED;
          numFalse++;
        }
      }

      return numTrue > 0 ? BooleanStateSummary.ALL_TRUE : BooleanStateSummary.ALL_FALSE;
    },
    [itemIdBooleanStateMap, getItemState],
  );

  const setItemState = useCallback((id: TId, state: boolean) => {
    setItemIdBooleanStateMap(prev => {
      const newSelectedItemIdMap = { ...prev };
      if (!state) {
        delete newSelectedItemIdMap[id];
      } else {
        newSelectedItemIdMap[id] = state;
      }
      return newSelectedItemIdMap;
    });
  }, []);

  const setItemsToTrue = useCallback((ids: TId[]) => {
    setItemIdBooleanStateMap(prev =>
      ids.reduce(
        (newSelectedItemIdMap, id) => {
          newSelectedItemIdMap[id] = true;
          return newSelectedItemIdMap;
        },
        { ...prev },
      ),
    );
  }, []);

  const setItemsToFalse = useCallback((ids: TId[]) => {
    setItemIdBooleanStateMap(prev =>
      ids.reduce(
        (newSelectedItemIdMap, id) => {
          delete newSelectedItemIdMap[id];
          return newSelectedItemIdMap;
        },
        { ...prev },
      ),
    );
  }, []);

  const setAllItemsToFalse = useCallback(() => {
    setItemIdBooleanStateMap({} as Record<TId, boolean>);
  }, []);

  return useMemo(
    () => ({
      selectedItemIds,
      totalTrue,
      getItemState,
      getNumberOfItemsTrue,
      getItemsStateSummary,
      setItemState,
      setItemsToTrue,
      setItemsToFalse,
      setAllItemsToFalse,
    }),
    [
      selectedItemIds,
      totalTrue,
      getItemState,
      getNumberOfItemsTrue,
      getItemsStateSummary,
      setItemState,
      setItemsToTrue,
      setItemsToFalse,
      setAllItemsToFalse,
    ],
  );
};

export type ItemsBooleanState<TId extends keyof any = string> = ReturnType<
  typeof useItemsBooleanState<TId>
>;

export default useItemsBooleanState;
