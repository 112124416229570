import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteProps, StandardTextFieldProps } from '@mui/material';

import ListboxComponent from './ListboxComponent';
import SearchTextField from './SearchTextField';

type AutocompletePropsOmitted = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'>;

// TODO: Improve typings if it's needed.
// (remove 'any' and replace with type inferring)
export interface IIdsAutocompleteProps extends AutocompletePropsOmitted {
  label?: string;
  getOptionValue?: (option: any) => any;
  required?: boolean;
  textFieldProps?: StandardTextFieldProps;

  /**
   * Threshold of options.length for enabling list with virtualization (default - 20)
   */
  virtualizationThreshold?: number;
}

const IdsAutocomplete: React.FC<IIdsAutocompleteProps> = ({
  label,
  options,
  getOptionLabel = option => option,
  // Mimic Mui Autocomplete options structure:
  // https://mui.com/material-ui/react-autocomplete/#options-structure
  getOptionValue = option => option.label || option,
  onChange,
  onInputChange,
  required,
  loading,
  textFieldProps,
  // Value from calling getOptionValue on the selected option(s)
  value: optionValue,
  multiple,
  virtualizationThreshold = 20,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const _isOptionEqualToValue = useCallback(
    (option, value) => option && value && getOptionValue(option) === getOptionValue(value),
    [getOptionValue],
  );

  // Translate option value into the selected option(s)
  const _value = useMemo(
    () =>
      multiple
        ? optionValue
            ?.map((val: any) => options?.find(o => getOptionValue(o) === val))
            // Filter out any values that do not correspond with an option
            ?.filter((o: any) => !!o) || []
        : (optionValue && (options?.find(o => getOptionValue(o) === optionValue) || null)) || null,
    [options, getOptionValue, optionValue, multiple],
  );

  const _onChange = useCallback(
    (event, value, reason) => {
      const newValue = multiple
        ? value.map((o: any) => getOptionValue(o))
        : value && getOptionValue(value);
      if (onChange) {
        onChange(event, newValue, reason);
      }
    },
    [getOptionValue, onChange, multiple],
  );

  const _onInputChange = useCallback(
    (event, value, reason) => {
      setInputValue(value || ''); // Input value must be a string

      if (onInputChange) {
        onInputChange(event, value, reason);
      }
    },
    [setInputValue, onInputChange],
  );

  interface IOptionProps {
    props: HTMLAttributes<HTMLLIElement>;
    option: any;
  }

  const Option: React.FC<IOptionProps> = ({ props, option }) => {
    const value = getOptionValue(option);
    const label = getOptionLabel(option);
    return (
      <li {...props} key={value}>
        {label}
      </li>
    );
  };

  const virtualizationProps = useMemo(() => {
    if (options.length >= virtualizationThreshold) {
      return {
        ListboxComponent,
      };
    }
    return {};
  }, [options.length, virtualizationThreshold]);

  return (
    <Autocomplete
      // Need to override default behavior of using the label as the option key
      renderOption={(props, o) => <Option key={getOptionValue(o)} props={props} option={o} />}
      size='small'
      fullWidth
      autoComplete={true}
      {...rest} // Props below this shouldn't be overridden
      disableListWrap
      {...virtualizationProps}
      value={_value}
      onChange={_onChange}
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={_isOptionEqualToValue}
      loading={loading}
      inputValue={inputValue}
      onInputChange={_onInputChange}
      renderInput={params => (
        <SearchTextField
          params={params}
          loading={loading}
          required={required}
          label={label}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default IdsAutocomplete;
