import { ITypeMetadata } from '../context/FilterContext';

import { MEDIA_TYPES, MediaType } from './media';
import { MEDIA_TYPE_ENABLED_KEYS } from './urlStateKeys';

const defaultTypeMetadata: Record<string, ITypeMetadata> = {
  [MediaType.ProjectPhoto]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.ProjectPhoto].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.ProjectPhoto],
  },
  [MediaType.HDPhoto]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.HDPhoto].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.HDPhoto],
  },
  [MediaType.PanoramicPhoto]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.PanoramicPhoto].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.PanoramicPhoto],
  },
  [MediaType.Video]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.Video].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.Video],
  },
  [MediaType.ThreeDModel]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.ThreeDModel].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.ThreeDModel],
  },
  [MediaType.ThreeDVR]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.ThreeDVR].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.ThreeDVR],
  },
  [MediaType.PointOfInterest]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.PointOfInterest].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.PointOfInterest],
  },
  [MediaType.AreaOfInterest]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.AreaOfInterest].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.AreaOfInterest],
  },
  [MediaType.Asset]: {
    itemsDefaultEnabled: true,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.Asset].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.Asset],
  },
  [MediaType.RasterOverlay]: {
    itemsDefaultEnabled: false,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.RasterOverlay].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.RasterOverlay],
  },
  [MediaType.VectorOverlay]: {
    itemsDefaultEnabled: false,
    enabled: true,
    selectId: d => MEDIA_TYPES[MediaType.VectorOverlay].selectItem(d).id,
    urlKey: MEDIA_TYPE_ENABLED_KEYS[MediaType.VectorOverlay],
  },
};

export const getDefaultTypeMetadata = (types: string[]): Record<string, ITypeMetadata> => {
  return types.reduce((typeMetadata, type) => {
    if (defaultTypeMetadata[type]) {
      typeMetadata[type] = defaultTypeMetadata[type];
    }
    return typeMetadata;
  }, {} as Record<string, ITypeMetadata>);
};
