import React from 'react';
import { ListItemText } from '@mui/material';

import IdsListItem from '../../../../components/ids-lists/IdsListItem';
import { IAsset } from '../../../../services/types';

export interface IAssetListItemProps {
  item: IAsset;
}

const AssetListItem: React.FC<IAssetListItemProps> = ({ item }) => {
  const { id, name, type } = item;

  return (
    <IdsListItem key={id}>
      <ListItemText primary={name} primaryTypographyProps={{ variant: 'h6', noWrap: true }} />
      <ListItemText secondary={type} secondaryTypographyProps={{ noWrap: true }} />
    </IdsListItem>
  );
};

export default AssetListItem;
