import { useMutation } from 'react-query';
import { useMemo } from 'react';
import { gql } from 'graphql-request';

import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import { uploadFileBinary } from '../hooks/useUploadFile';
import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import { VIEW_TYPES } from '../constants/viewTypes';
import invalidateQueriesContainingKey from '../utils/invalidateQueriesContainingKey';

export const useDocumentsKeys = () => {
  const queryIdKey = useQueryKeyId();

  return useMemo(() => {
    const documentsKeys = {
      all: ['documents', ...queryIdKey],
      organizationDocuments: (orgId: string) => [
        ...documentsKeys.all,
        `organization-documents-${orgId}`,
      ],
      programDocuments: (programId: string, orgId: string) => [
        ...documentsKeys.organizationDocuments(orgId),
        `program-documents-${programId}`,
      ],
      projectDocuments: (projectId: string, orgId: string) => [
        ...documentsKeys.organizationDocuments(orgId),
        `project-documents-${projectId}`,
      ],
      tenantProjectDocuments: (projectId: string) => [
        ...documentsKeys.all,
        `project-documents-${projectId}`,
      ],
    };

    return documentsKeys;
  }, [queryIdKey]);
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

export enum DocumentDeleteMode {
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_VERSION = 'DOCUMENT_VERSION',
}

export type DocumentDeleteModeTypes = keyof typeof DocumentDeleteMode;

const DeleteDocumentMutation = gql`
  mutation DeleteDocument($where: WhereUniqueIdOrganizationInput!) {
    deleteDocument(where: $where) {
      errors {
        message
        field
      }
      success
    }
  }
`;

const DeleteDocumentVersionMutation = gql`
  mutation DeleteDocumentVersion($where: WhereUniqueIdOrganizationInput!) {
    deleteDocumentVersion(where: $where) {
      errors {
        message
        field
      }
    }
  }
`;

interface IDeleteDocumentPayload {
  organizationId: string;
  id: string;
  deleteMode: DocumentDeleteModeTypes;
}

const deleteDocumentOrVersion = ({
  organizationId,
  id,
  deleteMode = 'DOCUMENT',
}: IDeleteDocumentPayload) => {
  const gqlDocument =
    deleteMode === 'DOCUMENT' ? DeleteDocumentMutation : DeleteDocumentVersionMutation;

  return buildGraphMutationFn(gqlDocument)({
    where: {
      id,
      organizationId,
    },
  });
};

export function useDeleteDocumentOrVersion() {
  const documentsKeys = useDocumentsKeys();

  return useMutation(deleteDocumentOrVersion, {
    onError: onError,
    onSuccess: async () => {
      // Only invalidate project queries in the context of this project
      await invalidateQueriesContainingKey(documentsKeys.all, {
        refetchActive: true,
      });
    },
  });
}

const UpdateDocumentMutation = gql`
  mutation UpdateDocument($where: WhereUniqueIdOrganizationInput!, $input: UpdateDocumentInput!) {
    updateDocument(where: $where, input: $input) {
      errors {
        field
        message
      }
      document {
        id
        name
        availableInCapture
        availableInInfoTab
        createdAt
        description
        documentCategoryId
        parentId
        parentType
        updatedAt
        metadata {
          value
          type
          id
        }
      }
    }
  }
`;

interface IUpdateDocumentPayload {
  organizationId: string;
  id: string;

  availableInCapture?: boolean;
  availableInInfoTab?: boolean;
  description?: string;
  name?: string;
  tags?: string[];
}

const updateDocument = ({
  organizationId,
  id,
  availableInCapture,
  availableInInfoTab,
  description,
  name,
  tags,
}: IUpdateDocumentPayload) => {
  return buildGraphMutationFn(UpdateDocumentMutation)({
    input: {
      availableInCapture,
      availableInInfoTab,
      description,
      name,
      metadata: tags?.map(it => ({ id: it, type: 'DocumentTag' })),
    },
    where: {
      id,
      organizationId,
    },
  });
};

export const useUpdateDocument = () => {
  const documentsKeys = useDocumentsKeys();

  return useMutation(updateDocument, {
    onError,
    onSuccess: async () => {
      await invalidateQueriesContainingKey(documentsKeys.all, {
        refetchActive: true,
      });
    },
  });
};

const CreateDocumentVersionMutation = gql`
  mutation CreateDocumentVersion(
    $where: WhereUniqueIdOrganizationInput!
    $input: CreateDocumentVersionInput!
  ) {
    createDocumentVersion(where: $where, input: $input) {
      uploadUrl
    }
  }
`;

interface ICreateDocumentVersionPayload {
  organizationId: string;
  id: string;
  filename: string;
  file: File;
}

const createDocumentVersion = async ({
  organizationId,
  id,
  filename,
  file,
}: ICreateDocumentVersionPayload) => {
  if (!file) throw new Error('File was not passed!');

  const result = await buildGraphMutationFn(CreateDocumentVersionMutation)({
    input: {
      filename,
    },
    where: {
      id,
      organizationId,
    },
  });

  const { createDocumentVersion } = result || {};
  const { uploadUrl } = createDocumentVersion || {};

  if (!uploadUrl) throw new Error("Upload URL doesn't exist");
  await uploadFileBinary(uploadUrl, file);

  return result;
};

export const useCreateDocumentVersion = () => {
  const documentsKeys = useDocumentsKeys();

  return useMutation(createDocumentVersion, {
    onError,
    onSuccess: async () => {
      await invalidateQueriesContainingKey(documentsKeys.all, {
        refetchActive: true,
      });
    },
  });
};

// Create New Project Document
const CreateProjectDocumentMutation = gql`
  mutation CreateProjectDocument($where: WhereOrganizationInput!, $input: CreateDocumentInput!) {
    createProjectDocument(where: $where, input: $input) {
      errors {
        message
        field
      }
      uploadUrl
    }
  }
`;

export interface ICreateDocumentPayload {
  name: string;
  description: string;
  availableInInfoTab: boolean;
  availableInCapture: boolean;
  tags: string[];
  category: string;
  parentId: string;

  organizationId: string;
  filename: string;
  file: File;
}

const createProjectDocument = async ({
  name,
  description,
  availableInCapture = true,
  availableInInfoTab = true,
  tags,
  category,
  parentId,

  organizationId,
  filename,
  file,
}: ICreateDocumentPayload) => {
  if (!file) throw new Error('File was not passed!');

  const result = await buildGraphMutationFn(CreateProjectDocumentMutation)({
    input: {
      filename,
      name,
      description,
      availableInInfoTab,
      availableInCapture,
      metadata: [
        {
          id: category,
          type: 'DocumentCategory',
        },
        ...tags?.map(it => ({ id: it, type: 'DocumentTag' })),
      ],
      parentId,
    },
    where: {
      organizationId,
    },
  });

  const { createProjectDocument } = result || {};
  const { uploadUrl } = createProjectDocument || {};

  if (!uploadUrl) throw new Error("Upload URL doesn't exist");
  await uploadFileBinary(uploadUrl, file);

  return result;
};

export const useCreateProjectDocument = () => {
  const documentsKeys = useDocumentsKeys();

  return useMutation(createProjectDocument, {
    onError,
    onSuccess: async () => {
      await invalidateQueriesContainingKey(documentsKeys.all, {
        refetchActive: true,
      });
    },
  });
};

// Create New Program Document
const CreateProgramDocumentMutation = gql`
  mutation CreateProgramDocument($where: WhereOrganizationInput!, $input: CreateDocumentInput!) {
    createProgramDocument(where: $where, input: $input) {
      errors {
        message
        field
      }
      uploadUrl
    }
  }
`;

const createProgramDocument = async ({
  name,
  description,
  availableInCapture = true,
  availableInInfoTab = true,
  tags,
  category,
  parentId,

  organizationId,
  filename,
  file,
}: ICreateDocumentPayload) => {
  if (!file) throw new Error('File was not passed!');

  const result = await buildGraphMutationFn(CreateProgramDocumentMutation)({
    input: {
      filename,
      name,
      description,
      availableInInfoTab,
      availableInCapture,
      metadata: [
        {
          id: category,
          type: 'DocumentCategory',
        },
        ...tags?.map(it => ({ id: it, type: 'DocumentTag' })),
      ],
      parentId,
    },
    where: {
      organizationId,
    },
  });

  const { createProgramDocument } = result || {};
  const { uploadUrl } = createProgramDocument || {};

  if (!uploadUrl) throw new Error("Upload URL doesn't exist");
  await uploadFileBinary(uploadUrl, file);

  return result;
};

export const useCreateProgramDocument = () => {
  const documentsKeys = useDocumentsKeys();

  return useMutation(createProgramDocument, {
    onError,
    onSuccess: async () => {
      await invalidateQueriesContainingKey(documentsKeys.all, {
        refetchActive: true,
      });
    },
  });
};

// ==========

export const DocumentListItemFrag = gql`
  fragment DocumentListItemFrag on Document {
    id
    name
    description
    createdAt
    updatedAt
    metadata {
      id
      value
      type
    }
    parentId
    parentType
    documentVersions {
      id
      filename
      url
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
      }
    }
    availableInInfoTab
    availableInCapture
  }
`;

const ProjectDocumentsQuery = gql`
  query ProjectDocuments($orgId: ID, $id: ID!, $tenantId: ID, $where: DocumentsWhereInput) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      projectDocuments(where: $where) {
        edges {
          node {
            id
            ...DocumentListItemFrag
          }
        }
      }
    }
  }
  ${DocumentListItemFrag}
`;

type ProjectDocumentsWhere = {
  viewType: keyof typeof VIEW_TYPES;
};

export const useGetProjectDocuments = (projectId: string, where: ProjectDocumentsWhere) => {
  const documentsKeys = useDocumentsKeys();

  return useOrgGraphQuery(
    [...documentsKeys.tenantProjectDocuments(projectId), ...Object.values(where)],
    ProjectDocumentsQuery,
    {
      id: projectId,
      where,
    },
  );
};

const ProgramDocumentsQuery = gql`
  query ProgramDocuments($orgId: ID, $tenantId: ID, $programId: ID!) {
    program(organizationId: $orgId, id: $programId, tenantId: $tenantId) {
      programDocuments {
        edges {
          node {
            id
            ...DocumentListItemFrag
          }
        }
      }
    }
  }
  ${DocumentListItemFrag}
`;

export const useGetProgramDocuments = (programId: string, orgId: string) => {
  const documentsKeys = useDocumentsKeys();

  return useOrgGraphQuery(documentsKeys.programDocuments(programId, orgId), ProgramDocumentsQuery, {
    programId,
  });
};
