import { useCallback } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

import style from './SomethingWentWrong.module.css';

const SomethingWentWrong = () => {
  const reload = useCallback(() => window.location.reload(), []);

  return (
    <Container maxWidth={false} className={style.container}>
      <Box>
        <img className={style.image} alt='There was an error' src='/static/binoculars.png' />
        <Typography className={style.title}>Something went wrong!</Typography>
        <Box className={style.buttonContainer}>
          <Button onClick={reload} className={style.button}>
            Reload Page
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SomethingWentWrong;
