import { CircularProgress as CircularProgressOriginal, CircularProgressProps } from '@mui/material';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICircularProgressProps extends CircularProgressProps {}

const iconSize = 24;

const CircularProgress: React.FC<ICircularProgressProps> = ({ size, ...rest }) => {
  return <CircularProgressOriginal size={size ?? iconSize} {...rest} />;
};

export default CircularProgress;
