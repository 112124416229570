import PropTypes from 'prop-types';
import { CompositeLayer } from 'deck.gl';

class DraggableLayer extends CompositeLayer {
  initializeState() {
    const { data } = this.props;

    const { longitude, latitude } = this.context.deck.viewManager.viewState;
    const draggable = {
      longitude: longitude,
      latitude: latitude,

      // Override defaults with data if provided
      ...(data && data.length && { ...data[0] }),
    };

    const drag = {
      translationOffset: null,
    };

    this.setState({
      draggable,
      drag,
    });
  }

  updateState({ props, oldProps, changeFlags }) {
    super.updateState({ props, oldProps, changeFlags });

    const { data: oldData } = oldProps;
    const { data } = props;

    if (data && data.length && oldData && oldData.length) {
      const oldDraggable = oldData[0];
      const draggable = data[0];

      const longitudeUpdated = oldDraggable.longitude !== draggable.longitude;
      const latitudeUpdated = oldDraggable.latitude !== draggable.latitude;

      const { draggable: stateDraggable } = this.state;
      this.setState({
        draggable: {
          ...stateDraggable,

          // Only override state data if prop data changed (allows for internal data manipulation)
          ...(longitudeUpdated && { longitude: draggable.longitude }),
          ...(latitudeUpdated && { latitude: draggable.latitude }),
        },
      });
    }
  }

  onDragStateChange(stateUpdate) {
    const { onDragStateChange } = this.props;

    if (onDragStateChange) {
      onDragStateChange(stateUpdate);
    }
  }

  _onTransform(transformations) {
    const { onTransform } = this.props;
    if (onTransform) {
      onTransform(transformations);
    }
  }

  onDragStart(info, event) {
    // TODO: add logic to distinguish between right and left mouse buttons

    const { draggable, drag } = this.state;
    const { coordinate } = info;
    this.setState({
      drag: {
        ...drag,
        translationOffset: {
          longitude: draggable.longitude - coordinate[0],
          latitude: draggable.latitude - coordinate[1],
        },
      },
    });

    this.onDragStateChange({ isDragging: true });
    return true; // Mark event as handled
  }

  onDrag(info, event) {
    if (!info.viewport) {
      // Need viewport to manipulate layers
      return {};
    }

    const { draggable, drag } = this.state;
    const { coordinate: endCoordinate } = info;

    // The translation offset makes the tool translation start from transform tools init position
    // rather than from the mouse position when the drag started
    // In other words, it improves UX
    const newLongitude = endCoordinate[0] + drag.translationOffset.longitude;
    const newLatitude = endCoordinate[1] + drag.translationOffset.latitude;

    this.setState({
      draggable: {
        ...draggable,
        longitude: newLongitude,
        latitude: newLatitude,
      },
    });
  }

  onDragEnd(info, event) {
    const { onDragged } = this.props;
    const { draggable } = this.state;

    this.onDragStateChange({ isDragging: false });

    if (onDragged) {
      onDragged([draggable.longitude, draggable.latitude]);
    }
  }

  renderLayers() {
    const { renderSubLayers } = this.props;
    const { draggable } = this.state;

    if (!renderSubLayers) {
      throw new Error('renderSubLayers prop is required');
    }

    return renderSubLayers([draggable.longitude, draggable.latitude]);
  }
}

DraggableLayer.layerName = 'DraggableLayer';
DraggableLayer.defaultProps = {
  // define expected props: https://deck.gl/docs/developer-guide/custom-layers/composite-layers
};

// onTransform: callback for when the tool is used to perform some transformation on the object
// data shape: { longitude, latitude, altitude, orientation, scale }

DraggableLayer.propTypes = {
  renderSubLayers: PropTypes.func.isRequired, // renderSubLayers(position: [longitude, latitude]) : [Layer]

  onDragStateChange: PropTypes.func, // onDragStateChange({ isDragging: bool })

  // Called when a drag ends
  onDragged: PropTypes.func, // onDragged(position: [longitude, latitude])
};

export default DraggableLayer;
