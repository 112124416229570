import React, { useCallback, useMemo } from 'react';

import { Card, Container } from '@mui/material';

import { useRecoilValue } from 'recoil';

import { useNavigate } from 'react-router-dom';

import Page from '../../../components/Page';
import { activeOrganizationState } from '../../../atoms/organizations';
import { activeProjectState } from '../../../atoms/projects';
import { useGetEditProjectData, useUpdateProject } from '../../../services/ProjectService';

import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';

import globalSx from '../../../theme/globalSx';

import LoadingScreen from '../../../components/LoadingScreen';

import { PROJECT_STATUSES } from '../../../constants/projects';

import { getOrgProjectRoute, getOrgProjectsRoute } from '../../../utils/routes';

import { activeTenantState } from '../../../atoms/tenants';

import EditProjectForm from './EditProjectForm';

import styles from './ProjectEdit.module.css';

function Project() {
  const navigate = useNavigate();
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);

  // All the data needed for ProjectEdit page from API
  const { data, isLoading, isError } = useGetEditProjectData(activeProject.id);

  // Mutation for updating project
  const updateProjectMutation = useUpdateProject(activeProject.id, activeOrg.id, activeTenant.id);

  const { project, organization } = data || {};

  // Initial values for the form
  const initialValues = useMemo(() => {
    if (!project) {
      return {};
    }

    return {
      name: project.name,
      location: project.location?.id,
      description: project.description,
      startDate: project.startDate,
      completionDate: project.completionDate,
      status: PROJECT_STATUSES[project.status],
      users: project.users.edges.map(it => it.node.user.id),
    };
  }, [project]);

  const organizationUsers = useMemo(() => {
    if (!organization) {
      return [];
    }

    return organization.users.edges.map(it => ({
      id: it.node.user.id,
      email: it.node.user.email,
      firstName: it.node.user.firstName,
      lastName: it.node.user.lastName,
    }));
  }, [organization]);

  const navigateToProjectDetails = useCallback(() => {
    navigate(
      getOrgProjectRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        projectId: activeProject.id,
      }),
    );
  }, [navigate, activeOrg, activeProject, activeTenant]);

  const onProjectSave = useCallback(
    async values => {
      if (!values) return;
      // 1. Mutate
      await updateProjectMutation.mutateAsync({
        name: values.name,
        description: values.description,
        start_date: values.startDate,
        completion_date: values.completionDate,
        status: values.status,
        user_ids: values.users,
      });
      // 2. Navigate to Project Details
      navigateToProjectDetails();
    },
    [updateProjectMutation, navigateToProjectDetails],
  );

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'Projects',
        route: getOrgProjectsRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
        }),
      },
      {
        label: activeProject.name || 'Details',
        route: getOrgProjectRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          projectId: activeProject.id,
        }),
      },
    ],
    [activeOrg, activeProject, activeTenant],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page title={`Edit ${project?.name} Project`}>
      <Container maxWidth={false}>
        <NestedOrgViewHeader breadcrumbs={breadcrumbs} currentCrumb='Edit' />

        <Card sx={globalSx.pageContent} className={styles.contentContainer}>
          <EditProjectForm
            values={initialValues}
            onSubmit={onProjectSave}
            onCancel={navigateToProjectDetails}
            // Users
            userOptions={organizationUsers}
            loadingUsers={isLoading}
            loadingUsersError={isError}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default Project;
