import { useCallback } from 'react';

import useConfirmedActionWithFeedback from '../../hooks/useConfirmedActionWithFeedback';
import { useSetUserActivationState } from '../../services/UsersService';

// Requests confirmation to toggle the user's activation state and provides
// success or failure feedback to the user
const useToggleUserActivationStateAction = () => {
  const setActivationStateMutation = useSetUserActivationState();
  const { done, loading, handleAction } = useConfirmedActionWithFeedback();

  const toggleUserActivationState = useCallback(
    user => {
      if (!user) throw new Error('Cannot change activation state of user, ', user);

      const active = user.activationState === 'active';
      const actionName = active ? 'deactivate' : 'activate';

      const userName = user && `${user.firstName} ${user.lastName}`;
      const title = `Are you sure you want to ${actionName} this user?`;
      const description = active
        ? `${userName} will no longer have access to the IDS platform.`
        : `${userName} will regain access to the IDS platform.`;

      handleAction(
        async () =>
          await setActivationStateMutation.mutateAsync({
            userId: user.id,
            activationState: active ? 'inactive' : 'active',
          }),
        title,
        description,
        `User ${actionName}d`,
        `User could not be ${actionName}d`,
      );
    },
    [setActivationStateMutation, handleAction],
  );

  return {
    done,
    loading,
    toggleUserActivationState,
  };
};

export default useToggleUserActivationStateAction;
