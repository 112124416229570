import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';

import usePrevious from '../hooks/usePrevious';
import useFilterContext from '../hooks/useFilterContext';
import useObservableStates, { useObservableItemValue } from '../hooks/useObservableStates';

const filteredBySnackbarOptions = {
  variant: 'warning',
};

const ItemFilterNotifier = ({ useNotifierState }) => {
  const notifierState = useNotifierState();
  const prevNotifierState = usePrevious(notifierState);
  const {
    getItemFilterRecord,
    typeMetadata,
    addItemFilterRecordListener,
    removeItemFilterRecordListener,
  } = useFilterContext();
  const activeMediaChangeCleanup = useRef(null);
  const [getActiveMediaTypeEnabled, setGetActiveMediaTypeEnabled] = useState();
  const [activeMediaTypeEnabled, setActiveMediaTypeEnabled] = useState(true);
  const prevActiveMediaTypeEnabled = usePrevious(activeMediaTypeEnabled);
  const snackbarKey = useRef();
  const [filteredByMsg, setFilteredByMsg] = useState();
  const prevFilteredByMsg = usePrevious(filteredByMsg);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const hideFilterFeedback = useCallback(() => {
    if (snackbarKey.current) {
      closeSnackbar(snackbarKey.current); // Close previous filtered by feedback, it is out of date
    }
  }, [closeSnackbar]);

  const handleStateChange = useCallback(
    state => {
      // Perform cleanup for previous active media
      if (activeMediaChangeCleanup.current) {
        activeMediaChangeCleanup.current();
      }

      // Reset state to provide fresh filtered by feedback for new active media
      hideFilterFeedback();
      snackbarKey.current = null;
      setActiveMediaTypeEnabled(true);
      setFilteredByMsg(null);

      if (!state || !state.id || !state.type) {
        return;
      }

      const { id, type, getFilteredByMessage } = state;

      // Handle changes to the active media's filter records and media type to provide feedback to the user
      const handleFilteredByChange = filteredBy => {
        const _getFilteredByMsg = () =>
          getFilteredByMessage
            ? getFilteredByMessage(filteredBy)
            : 'The active item does not match the currently applied filters';
        setFilteredByMsg(!!filteredBy?.label ? _getFilteredByMsg() : null);
      };

      const currentFilteredBy = getItemFilterRecord(type, id);
      if (currentFilteredBy) {
        handleFilteredByChange(currentFilteredBy);
      }

      const listenerId = addItemFilterRecordListener(type, id, null, handleFilteredByChange);

      // Create function that can be used to determine if the active media's type is enabled
      setGetActiveMediaTypeEnabled(() => _typeMetadata => _typeMetadata[type].enabled);

      // Create cleanup method to be called when the active media changes
      activeMediaChangeCleanup.current = () => {
        removeItemFilterRecordListener(type, id, listenerId);
      };
    },
    [
      hideFilterFeedback,
      setActiveMediaTypeEnabled,
      setFilteredByMsg,
      addItemFilterRecordListener,
      removeItemFilterRecordListener,
      getItemFilterRecord,
      setGetActiveMediaTypeEnabled,
    ],
  );

  useEffect(() => {
    if (
      notifierState &&
      notifierState !== prevNotifierState &&
      (notifierState.id !== prevNotifierState?.id || notifierState.type !== prevNotifierState?.type)
    ) {
      handleStateChange(notifierState); // New item
    }
  }, [notifierState, prevNotifierState, handleStateChange]);

  const showFilteredByFeedback = useCallback(
    msg => {
      hideFilterFeedback(); // Close previous filtered by feedback, it is out of date
      snackbarKey.current = enqueueSnackbar(msg, filteredBySnackbarOptions);
    },
    [enqueueSnackbar, hideFilterFeedback],
  );

  useEffect(() => {
    if (getActiveMediaTypeEnabled) {
      setActiveMediaTypeEnabled(getActiveMediaTypeEnabled(typeMetadata));
    }
  }, [getActiveMediaTypeEnabled, typeMetadata, setActiveMediaTypeEnabled]);

  // A snackbar notification should only be shown to the user once while the current active media is filtered out.
  // If the type is disabled first, user should only see that message, if a filter is applied first, user should only see that message.
  // This is reset once the image is not filtered out in any way.
  useEffect(() => {
    // If the active media's type is disabled and a filteredByMsg has not already been shown, notify user
    if (!snackbarKey.current && !activeMediaTypeEnabled && prevActiveMediaTypeEnabled) {
      // Media type was just disabled
      showFilteredByFeedback(
        notifierState?.typeDisabledMessage || "The active item's type is disabled",
        filteredBySnackbarOptions,
      );
      return;
    }

    // If the active media's filtered by message changed and media type disabled notification was not shown, notify user
    if (!snackbarKey.current && filteredByMsg && filteredByMsg !== prevFilteredByMsg) {
      showFilteredByFeedback(filteredByMsg, filteredBySnackbarOptions);
      return;
    }

    // Active media is no longer filtered out and there is an active snackbar indicating otherwise
    if (activeMediaTypeEnabled && !filteredByMsg && snackbarKey.current) {
      closeSnackbar(snackbarKey.current);
      snackbarKey.current = null;
    }
  }, [
    filteredByMsg,
    prevFilteredByMsg,
    showFilteredByFeedback,
    activeMediaTypeEnabled,
    prevActiveMediaTypeEnabled,
    closeSnackbar,
    notifierState?.typeDisabledMessage,
  ]);

  return null;
};

const ItemFilterNotificationContext = createContext();

const notifierStateId = 'notifier';
const defaultNotifierState = {
  id: null,
  type: null,
  getFilteredByMessage: null,
  typeDisabledMessage: null,
};

export function ItemFilterNotificationProvider({ children }) {
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();
  const useNotifierState = () =>
    useObservableItemValue({
      id: 'notifier',
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    });

  useEffect(() => {
    setItemState(notifierStateId, defaultNotifierState); // setup default state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setItemForNotifications = useCallback(
    (id, type, getFilteredByMessage, typeDisabledMessage) => {
      setItemState(notifierStateId, {
        id,
        type,
        getFilteredByMessage,
        typeDisabledMessage,
      });
    },
    [setItemState],
  );

  const removeItemForNotifications = useCallback(() => {
    setItemForNotifications(null, null); // passing falsy value for id and type removes the item from being tracked for filter notifications
  }, [setItemForNotifications]);

  return (
    <ItemFilterNotificationContext.Provider
      value={{ setItemForNotifications, removeItemForNotifications }}
    >
      {/* ItemFilterNotifier is implemented as a child component to prevent its state changes from causing rerenders of the context provider */}
      <ItemFilterNotifier useNotifierState={useNotifierState} />
      {children}
    </ItemFilterNotificationContext.Provider>
  );
}

ItemFilterNotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ItemFilterNotificationConsumer = ItemFilterNotificationContext.Consumer;

export default ItemFilterNotificationContext;
