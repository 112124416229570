import { useState, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { Typography, Box } from '@mui/material';

import { activeLocationState } from '../../../../../../atoms/locations';

import IdsEditDialogForm, {
  IIdsEditDialogFormProps,
} from '../../../../../../components/ids-forms/IdsEditDialogForm';
import IdsFormTextField from '../../../../../../components/ids-forms/IdsFormTextField';
import IdsFormSelectField from '../../../../../../components/ids-forms/IdsFormSelectField';
import IdsSwitch from '../../../../../../components/ids-inputs/IdsSwitch';
import IdsFormColorField from '../../../../../../components/ids-forms/IdsFormColorField';

import { activeOrganizationState } from '../../../../../../atoms/organizations';
import { editOverlayValidationSchema } from '../../overlayValidation';
import { getMediaTypeFromUrn } from '../../../../../../utils/media';
import { IMetadataTypeValue, MEDIA_TYPES, MediaType } from '../../../../../../constants/media';
import { EntityType } from '../../../../../../constants/entities';
import {
  useUpdateOverlay,
  IOverlayUpdateInputValues,
} from '../../../../../../services/OverlaysService';
import { useGetLocationMetadataTypes } from '../../../../../../services/LocationService';
import useMetadataItems from '../../../../../../hooks/useMetadataItems';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../../constants/media';

import { Overlay } from '..';

import { IIdsDialogProps } from '../../../../../../components/IdsDialog';

interface IEditOverlayFormProps extends Pick<IIdsEditDialogFormProps, 'onCancel'> {
  onSubmitSuccess: IIdsDialogProps['onClose'];
  overlay: Overlay;
  maxPositions: number;
}

interface IOverlayValuesProps {
  name: string;
  position: number;
  color?: string;
  default?: boolean;
  level: string;
}

const EditOverlayForm: React.FC<IEditOverlayFormProps> = ({
  onSubmitSuccess,
  onCancel,
  overlay,
  maxPositions,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState)!;
  const { mutateAsync: updateOverlayMutation } = useUpdateOverlay(activeLocation.id);

  const { data } = useGetLocationMetadataTypes(activeLocation.id);

  const { allLevels } = useMetadataItems(data?.location.metadataTypes || []);

  const LEVEL_OPTIONS = allLevels.map((k: IMetadataTypeValue) => ({
    label: k.name,
    value: k.id,
  }));

  const initialValues = useMemo<IOverlayValuesProps>(() => {
    return {
      name: overlay.name,
      position: overlay.position,
      default: overlay?.default,
      level: overlay.metadata[0].id,
      color: overlay?.color,
    };
  }, [overlay]);

  const [isDefault, setDefault] = useState(initialValues.default);

  const mediaType = getMediaTypeFromUrn(overlay.id) as MediaType;
  const entityType = MEDIA_TYPES[mediaType].entityType;

  const onSubmit = async (values: IOverlayValuesProps) => {
    const inputValues: IOverlayUpdateInputValues = {
      name: values.name,
      position: Number(values.position),
      color: values.color,
      default: isDefault,
      metadata: [
        {
          id: values.level,
          type: MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
        },
      ],
    };

    updateOverlayMutation({
      id: overlay.id,
      organizationId: activeOrg.id,
      values: { ...inputValues },
    });

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  const tryGetNumberFieldVal = (value: any) => {
    if (!value || typeof value === 'number') {
      return value;
    }

    const numVal = parseInt(value);
    return isNaN(numVal) ? '' : numVal;
  };

  return (
    <IdsEditDialogForm
      initialValues={initialValues}
      validationSchema={editOverlayValidationSchema(maxPositions)}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Overlay updated'
      errorHandler={() => 'Overlay could not be updated'}
    >
      <IdsFormTextField name='name' label='Name' required autoFocus />
      <IdsFormTextField
        name='position'
        label={`Position (1 - ${maxPositions})`}
        transformBlurValue={tryGetNumberFieldVal}
        required
        margin='normal'
      />
      <IdsFormSelectField
        name='level'
        label='Level'
        required
        margin='none'
        options={LEVEL_OPTIONS}
      />
      {entityType === EntityType.VectorOverlay && (
        <Box my={1.5}>
          <IdsFormColorField
            name='color'
            label='Color'
            required
            onChange={event => {
              return event;
            }}
          />
        </Box>
      )}
      {entityType === EntityType.RasterOverlay && (
        <IdsSwitch
          label={
            <Typography variant='body2' color='text.secondary'>
              Default
            </Typography>
          }
          labelPlacement='top'
          onChange={event => {
            setDefault(event.target.checked);
          }}
          checked={isDefault}
        />
      )}
    </IdsEditDialogForm>
  );
};

export default EditOverlayForm;
