import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CreateOrganizationDialog from '../CreateOrganizationDialog';
import IdsListHeader from '../../../components/ids-lists/IdsListHeader';

import Actions from './Actions';

function OrganizationListHeader({ canCreate, style, ...rest }) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <IdsListHeader
      sx={{ paddingTop: 0, paddingBottom: 1 }}
      title='Organizations'
      dialogs={
        <CreateOrganizationDialog
          open={createDialogOpen}
          onOpen={() => setCreateDialogOpen(true)}
          onClose={() => setCreateDialogOpen(false)}
        />
      }
      actions={canCreate && <Actions onClick={() => setCreateDialogOpen(true)} />}
    />
  );
}

OrganizationListHeader.propTypes = {
  canCreate: PropTypes.bool,
  style: PropTypes.object,
};

export default OrganizationListHeader;
