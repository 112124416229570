import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { useAssignmentKeys } from '../../services/AssignmentsService';
import { activeAssignmentState } from '../../atoms/assignments';
import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgAssignmentsRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const AssignmentRouteQuery = gql`
  query AssignmentRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignment(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      title
    }
  }
`;

function AssignmentResponseRoute() {
  const assignmentKeys = useAssignmentKeys();

  const [assignment, setAssignment] = useRecoilState(activeAssignmentState);
  const activeTenant = useRecoilValue(activeTenantState);
  const org = useRecoilValue(activeOrganizationState);

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useAssignment = (id, options) =>
    useOrgGraphQuery(assignmentKeys.route(id), AssignmentRouteQuery, { id }, options);

  return (
    <EntityRoute
      idParam={'assignmentId'}
      currentEntity={assignment}
      setEntity={setAssignment}
      useEntity={useAssignment}
      entitySelector={data => data?.assignment}
      errorRedirectRoute={getOrgAssignmentsRoute({
        subdomain: activeTenant?.subdomain,
        orgId: org.id,
      })}
    />
  );
}

export default AssignmentResponseRoute;
