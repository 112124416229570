import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { InfoIcon } from '../../../theme/icons';
import InfoBox from '../../../components/InfoBox';
import IdsFormElement from '../../../components/ids-forms/IdsFormElement';
import IdsFormTextField from '../../../components/ids-forms/IdsFormTextField';
import { forwardGeocode } from '../../../services/GeocodingService';
import LocationConfigViewer from '../LocationConfigViewer';

const tryGetNumberFieldVal = value => {
  if (!value || typeof value === 'number') {
    return value;
  }

  const numVal = parseFloat(value);
  return isNaN(numVal) ? '' : numVal;
};

function LocationForm() {
  const [needsGeocoding, setNeedsGeocoding] = useState(false);
  const [viewState, setViewState] = useState();

  const onAddressChange = useCallback(
    (event, formikProps) => {
      setNeedsGeocoding(true);
    },
    [setNeedsGeocoding],
  );

  const onAddressBlur = useCallback(
    async (event, formikProps) => {
      if (needsGeocoding) {
        const { values, setFieldValue } = formikProps;
        const { address, city, state, zip } = values;

        // Address changed and all fields are set
        if (address && city && state && zip) {
          const coords = await forwardGeocode(`${address}, ${city}, ${state} ${zip}`);

          if (coords) {
            setViewState({ ...coords, zoom: 16 });

            // Clear lat/long fields to avoid user confusion
            setFieldValue('latitude', '');
            setFieldValue('longitude', '');
          }

          setNeedsGeocoding(false);
        }
      }
    },
    [needsGeocoding, setNeedsGeocoding],
  );

  return (
    <Grid container direction='row' spacing={4}>
      <Grid item xs={12} md={5} container direction='column' spacing={2}>
        <Grid item xs='auto'>
          <IdsFormTextField name='name' label='Name' required autoFocus />
        </Grid>
        <Grid item xs='auto'>
          <IdsFormTextField
            name='address'
            label='Address'
            onChange={onAddressChange}
            onBlur={onAddressBlur}
          />
        </Grid>
        <Grid item xs='auto' container direction='row' spacing={2}>
          <Grid item xs>
            <IdsFormTextField
              name='city'
              label='City'
              onChange={onAddressChange}
              onBlur={onAddressBlur}
            />
          </Grid>
          <Grid item xs>
            <IdsFormTextField
              name='state'
              label='State'
              onChange={onAddressChange}
              onBlur={onAddressBlur}
            />
          </Grid>
        </Grid>
        <Grid item xs='auto' container direction='row' spacing={2}>
          <Grid item xs>
            <IdsFormTextField
              name='zip'
              label='Zip'
              onChange={onAddressChange}
              onBlur={onAddressBlur}
            />
          </Grid>
          <Grid item xs>
            <IdsFormTextField name='yearBuilt' label='Year Built' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs container direction='column' spacing={2}>
        <Grid item xs='auto'>
          <InfoBox
            icon={<InfoIcon color='info' />}
            content='Click on the map to set lat/long or drag the marker to update it.'
            color='info.main'
            sx={{
              marginTop: 0,
              marginBottom: 0,
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs>
          <IdsFormElement
            render={({ values, setFieldValue }) => (
              <LocationConfigViewer
                viewState={viewState}
                latitude={values.latitude}
                longitude={values.longitude}
                onPositionChange={position => {
                  setFieldValue('latitude', position.latitude);
                  setFieldValue('longitude', position.longitude);
                }}
                heading={values.heading}
              />
            )}
          />
        </Grid>
        <Grid item xs container direction='row' columnSpacing={2}>
          <Grid item xs>
            <IdsFormTextField
              name='latitude'
              label='Latitude'
              required
              transformBlurValue={tryGetNumberFieldVal}
              sx={{ height: 80 }}
            />
          </Grid>
          <Grid item xs>
            <IdsFormTextField
              name='longitude'
              label='Longitude'
              required
              transformBlurValue={tryGetNumberFieldVal}
              sx={{ height: 80 }}
            />
          </Grid>
          <Grid item xs>
            <IdsFormTextField
              name='heading'
              label='Heading'
              transformBlurValue={tryGetNumberFieldVal}
              sx={{ height: 80 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

LocationForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default LocationForm;
