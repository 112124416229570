import { useMemo } from 'react';

import { getMediaTypeFromUrn } from '../../../utils/media';
import { MediaType } from '../../../constants/media';

const MapEntityTypes = [MediaType.PointOfInterest, MediaType.Asset] as const;
export type MapEntityType = (typeof MapEntityTypes)[number];

const isMapEntityType = (type: any): type is MapEntityType => {
  return MapEntityTypes.includes(type);
};

const useMapEntityType = (id: string) => {
  return useMemo(() => {
    const type = getMediaTypeFromUrn(id);

    if (isMapEntityType(type)) {
      return type;
    }

    throw new Error(`${type} is not supported yet.`);
  }, [id]);
};

export default useMapEntityType;
