import React from 'react';
import { SvgIcon } from '@mui/material';

const RasterOverlayIcon = props => (
  <SvgIcon {...props}>
    <path d='M 19.37 13.265 L 21 14.535 L 12 21.535 L 3 14.535 L 4.62 13.275 L 11.99 19.005 L 19.37 13.265 Z M 4.63 10.735 L 3 9.465 L 12 2.465 L 21 9.465 L 19.36 10.735 L 12 16.465 L 4.63 10.735 Z M 5.26 9.317 L 11.974 14.563 L 12.375 10.357 L 8.695 10.851 L 8.936 8.828 L 5.26 9.317 Z'></path>
  </SvgIcon>
);

export default RasterOverlayIcon;
