import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { CircularProgress } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';

import { useRecoilState } from 'recoil';
import axios from 'axios';
import { clsx } from 'clsx';

import { krPanoFov, krPanoDirection } from '../../../atoms/mediaViewer';
import usePrevious from '../../../hooks/usePrevious';
import useForceRerender from '../../../hooks/useForceRerender';

import styles from './KRPanoViewer.module.css';
import '../../../theme/globalStyles.css';

const errorXMLUrl = '/krpano/error.xml';

const ELEMENTS = {
  PORTAL: id => `krpano-portal-${id}`,
  CONTROLS: id => `krpano-controls-${id}`,
  VIEWER_TARGET: id => `krpano-viewer-target-${id}`,
  VIEWER: id => `krpano-viewer-${id}`,
};

const defaultEmbeddingParams = {
  xml: null,
  html5: 'prefer',
  consolelog: true,
  id: 'IdsKRPano',
};

export const closeKRPano = (id = defaultEmbeddingParams.id) => {
  const portal = document.getElementById(ELEMENTS.PORTAL(id));

  if (portal) {
    portal.style.visibility = 'hidden'; // Hide the portal
  }

  // eslint-disable-next-line no-undef
  removepano(ELEMENTS.VIEWER(id)); // Remove the pano to release the webgl resources for garbage collection
};

const KRPanoViewer = forwardRef(
  (
    {
      id,
      xmlUrl,
      width,
      height,
      onResize,
      embeddingParams,
      onPreLoadPano,
      onPanoLoaded,
      onClose,
      krpanoCallbacks,
      fullscreen,
      modal,
      noXMLMessage,
      style,
      className,
      children,
      ...rest
    },
    krpanoRef,
  ) => {
    const portal = useRef(document.getElementById(ELEMENTS.PORTAL(id)));
    const viewerTarget = useRef(document.getElementById(ELEMENTS.VIEWER_TARGET(id)));

    const { width: pixelWidth, height: pixelHeight, ref: viewerTargetAlias } = useResizeDetector();

    const [krpano, setKrpano] = useState(document.getElementById(ELEMENTS.VIEWER(id)));
    const [xmlUrlLoading, setXMLUrlLoading] = useState(); // the xml url currently being loaded
    const [xmlUrlLoaded, setXMLUrlLoaded] = useState();
    const prevXMLUrl = usePrevious(xmlUrl);
    const [error, setError] = useState();
    const wasFullscreen = usePrevious(fullscreen);
    const { forceRerender } = useForceRerender();

    const [fov, setFov] = useRecoilState(krPanoFov);
    const [direction, setDirection] = useRecoilState(krPanoDirection);

    const globalCallbacksVar = useMemo(() => `reactKrpano_${id}`, [id]);

    const initializeInstance = useCallback(() => {
      if (!portal.current) {
        portal.current = document.createElement('div');
        portal.current.id = ELEMENTS.PORTAL(id);
        portal.current.className = 'krpano-portal';
        document.body.appendChild(portal.current);
      }

      portal.current.style.visibility = 'visible'; // Show the portal

      if (!viewerTarget.current) {
        viewerTarget.current = document.createElement('div');
        viewerTarget.current.id = ELEMENTS.VIEWER_TARGET(id);
        viewerTarget.current.className = 'krpano-viewer-target';
        portal.current.appendChild(viewerTarget.current);
      }

      if (krpanoRef) {
        krpanoRef.current = {
          globalCallbacksVar,
          instance: krpano,
        };
      }

      // Since only ref updates are made here, need to force a rerender
      forceRerender();
    }, [id, globalCallbacksVar, krpanoRef, krpano, forceRerender]);

    useEffect(() => {
      // Initialize global js var to house any js callbacks that need to be accessible by krpano
      window[globalCallbacksVar] = {};
    }, [globalCallbacksVar]);

    useEffect(() => {
      initializeInstance();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (xmlUrl) {
        if (!prevXMLUrl && !krpano) {
          // xmlUrl newly set and not already mounted
          // Instance needs to be initialized if there was no pano loaded previously, (even if previously initialized)
          initializeInstance();
        }
      } else {
        if (prevXMLUrl) {
          // xmlUrl removed, remove active pano so noXMLMessage can be shown
          // eslint-disable-next-line no-undef
          removepano(ELEMENTS.VIEWER(id)); // Remove the pano to release the webgl resources for garbage collection
          setKrpano(null);
          setXMLUrlLoaded(null);
          setXMLUrlLoading(null);
        }
      }
    }, [
      id,
      xmlUrl,
      prevXMLUrl,
      initializeInstance,
      setKrpano,
      krpano,
      setXMLUrlLoaded,
      setXMLUrlLoading,
    ]);

    const updateKrPanoAttrs = useCallback(
      (_direction, _fov) => {
        const directionF = parseFloat(_direction);
        if (directionF !== direction) {
          setDirection(directionF);
        }

        const fovF = parseFloat(_fov);
        if (fovF !== fov) {
          setFov(fovF);
        }
      },
      [direction, setDirection, fov, setFov],
    );

    // Set up callback to update krpano attributes when the view changes
    useEffect(() => {
      window[globalCallbacksVar].updateKrPanoAttrs = updateKrPanoAttrs;
    }, [globalCallbacksVar, updateKrPanoAttrs]);

    const onReady = useCallback(
      _krpano => {
        setKrpano(_krpano);

        _krpano.set(
          'events.onviewchange',
          `js(${globalCallbacksVar}.updateKrPanoAttrs(get(view.hlookat), get(view.fov)))`,
        );

        if (embeddingParams?.onready) {
          embeddingParams.onready(_krpano);
        }

        if (krpanoRef) {
          krpanoRef.current.instance = _krpano;
        }
      },
      [setKrpano, embeddingParams, globalCallbacksVar, krpanoRef],
    );

    const onEmbedError = useCallback(
      errorMsg => {
        // eslint-disable-next-line no-console
        console.error('Error embedding krpano', errorMsg);
        setError(errorMsg);

        if (embeddingParams?.onerror) {
          embeddingParams.onerror(errorMsg);
        }
      },
      [embeddingParams],
    );

    const embedKrpano = useCallback(() => {
      if (krpano) {
        return; // krpano already embedded, do not embed again
      }

      // eslint-disable-next-line no-undef
      embedpano({
        ...defaultEmbeddingParams,
        ...embeddingParams,
        target: ELEMENTS.VIEWER_TARGET(id),
        id: ELEMENTS.VIEWER(id),
        onready: onReady,
        onerror: onEmbedError,
      });
    }, [id, embeddingParams, krpano, onReady, onEmbedError]);

    useEffect(() => {
      // When xmlUrl is not set, krpano is not embedded, need to reembed when newly set
      if (xmlUrl && xmlUrl !== prevXMLUrl) {
        embedKrpano();
      }
    }, [id, xmlUrl, prevXMLUrl, initializeInstance, embedKrpano]);

    const handlePanoLoaded = useCallback(
      panoXMLUrl => {
        if (!xmlUrl) return; // xmlUrl was unset, don't process pano load

        setXMLUrlLoaded(panoXMLUrl); // track which xml is currently loaded

        if (panoXMLUrl === xmlUrlLoading) {
          // No new pano xml started loading while this load was happening
          setXMLUrlLoading(null);
        }

        if (onPanoLoaded) {
          onPanoLoaded();
        }
      },
      [xmlUrl, setXMLUrlLoading, xmlUrlLoading, onPanoLoaded],
    );

    // Set up callback to handle a pano being loaded
    useEffect(() => {
      window[globalCallbacksVar].handlePanoLoaded = handlePanoLoaded;
    }, [globalCallbacksVar, handlePanoLoaded]);

    useEffect(() => {
      /* Do not try to load pano if:
      - krpano is not embedded
      - xmlUrl is not set
      - xmlUrl is already loading
      - xmlUrl is already loaded
    */
      if (!krpano || !xmlUrl || xmlUrl === xmlUrlLoading || xmlUrl === xmlUrlLoaded) return;

      setXMLUrlLoading(xmlUrl);

      if (onPreLoadPano) {
        onPreLoadPano();
      }

      const vars = `view.hlookat=${direction}`; // Load with previous look direction to maintain consistent viewing experience between images

      // Validate xmlUrl before attempting to load with krpano, display error xml if invalid
      axios
        .get(xmlUrl)
        .then(res => {
          // XML is valid, load xml string into krpano
          const { data: xml } = res;
          krpano.call(
            `loadxml(${xml}, ${vars}, null, null, 'js(${globalCallbacksVar}.handlePanoLoaded(${xmlUrl}))')`,
          );
        })
        .catch(err => {
          // XML is invalid, load the error xml
          krpano.call(
            `loadpano(${errorXMLUrl}, null, null, null, 'js(${globalCallbacksVar}.handlePanoLoaded(${xmlUrl}))')`,
          );
        });
    }, [
      xmlUrl,
      krpano,
      xmlUrlLoading,
      xmlUrlLoaded,
      setXMLUrlLoading,
      globalCallbacksVar,
      onPreLoadPano,
      direction,
    ]);

    useEffect(() => {
      window[globalCallbacksVar] = {
        ...window[globalCallbacksVar],
        ...krpanoCallbacks, // This needs to be second, otherwise the callbacks will always be overwritten by the first version of the callback
      };
    }, [globalCallbacksVar, krpanoCallbacks]);

    const handleViewerTargetAliasRef = useCallback(
      elem => {
        viewerTargetAlias.current = elem;
      },
      [viewerTargetAlias],
    );

    const handleResize = useCallback(
      (width, height) => {
        if (onResize) {
          onResize(width, height);
        }
      },
      [onResize],
    );

    const aliasRect = viewerTargetAlias.current?.getBoundingClientRect();
    /**
     * Converting to a boolean to avoid the effect running every render since a new
     * rect object will be returned
     */
    const aliasRectDefined = !!aliasRect;

    useEffect(() => {
      if (fullscreen) {
        portal.current.style.width = '100vw';
        portal.current.style.height = '100vh';
        portal.current.style.top = '0';
        portal.current.style.left = '0';
        portal.current.style.right = '0';
        portal.current.style.bottom = '0';
      } else if (aliasRectDefined) {
        portal.current.style.width = `${pixelWidth}px`;
        portal.current.style.height = `${pixelHeight}px`;
        portal.current.style.top = `${aliasRect.top}px`;
        portal.current.style.left = `${aliasRect.left}px`;
        portal.current.style.right = `${aliasRect.right}px`;
        portal.current.style.bottom = `${aliasRect.bottom}px`;
      }
      /**
       * Including the rect values here instead of the rect itself should theoretically prevent
       * the effect from running unless the rect values change (or one of the other deps changes)
       */
    }, [
      fullscreen,
      pixelWidth,
      pixelHeight,
      aliasRectDefined,
      aliasRect?.top,
      aliasRect?.left,
      aliasRect?.right,
      aliasRect?.bottom,
    ]);

    useEffect(() => {
      if (fullscreen) return; // when in fullscreen mode, target alias dimensions are not used
      handleResize(pixelWidth, pixelHeight);
    }, [pixelWidth, pixelHeight, handleResize, fullscreen]);

    useEffect(() => {
      if (!fullscreen) return; // when not in fullscreen mode, target alias dimensions are used

      // KRPanoViewer is fullscreen, listen to window resize event instead of tracking viewer target alias resize
      const onWindowResize = () => handleResize(window.innerWidth, window.innerHeight);
      window.addEventListener('resize', onWindowResize);
      return () => window.removeEventListener('resize', onWindowResize);
    }, [handleResize, fullscreen]);

    useEffect(() => {
      if (fullscreen === wasFullscreen) return;

      // fullscreen setting just changed, fire resize event for the dimensions now in use
      if (fullscreen) {
        handleResize(window.innerWidth, window.innerHeight);
      } else {
        handleResize(pixelWidth, pixelHeight);
      }
    }, [handleResize, fullscreen, wasFullscreen, pixelHeight, pixelWidth]);

    if (!portal.current) return null;

    const showLoader = xmlUrl !== xmlUrlLoaded && !error;

    return (
      <>
        <div
          ref={handleViewerTargetAliasRef}
          style={{ width, height, ...style }}
          className={clsx(
            styles.krpanoContainer,
            { [styles['krpanoContainer-modal']]: modal },
            className,
          )}
          {...rest}
        />
        {modal && <div className={styles.krpanoViewerModalBackdrop} onClick={onClose} />}
        {createPortal(
          <>
            {(showLoader || !xmlUrl) && (
              <div
                className={clsx('centerChildren', 'krpano-viewer-target', styles.feedbackContainer)}
              >
                {showLoader && xmlUrl && <CircularProgress size={80} className={styles.loader} />}
                {!xmlUrl && (
                  <div className={styles.noXMLMessage}>
                    <strong>{noXMLMessage}</strong>
                  </div>
                )}
              </div>
            )}
            <div id={ELEMENTS.CONTROLS(id)} className='krpano-controls'>
              {children}
            </div>
          </>,
          portal.current,
        )}
      </>
    );
  },
);

KRPanoViewer.defaultProps = {
  id: defaultEmbeddingParams.id,
  width: '100vw',
  height: '100vh',
  embeddingParams: {},
  krpanoCallbacks: {},
  noXMLMessage: 'No pano to display.',
};

KRPanoViewer.propTypes = {
  id: PropTypes.string,
  xmlUrl: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onResize: PropTypes.func, // (pixelWidth, pixelHeight) => {}
  embeddingParams: PropTypes.object,
  onPreLoadPano: PropTypes.func,
  onPanoLoaded: PropTypes.func,
  krpanoCallbacks: PropTypes.object,
  fullscreen: PropTypes.bool,
  noXMLMessage: PropTypes.string,
};

export default KRPanoViewer;
