import { BitmapLayer } from '@deck.gl/layers';
import { MVTLayer, TileLayer } from '@deck.gl/geo-layers';

import { gql } from 'graphql-request';

import buildIdsLayer from '../IdsLayer';
import { LAYER_TYPE } from '../../../../context/ImmersiveViewerContext';
import { rgbStrToArray, getRGBColor } from '../../../../utils/colors';

const BING_TO_MAPBOX_OFFSET = {
  latitude: 0.00000227685,
  longitude: 0,
};

export const OverlayLayerFrag = gql`
  fragment OverlayLayerFrag on Overlay {
    id
    ... on RasterOverlay {
      default
    }
    tilesTemplateUrl
    position
  }
`;

const OverlayTileProps = {
  opacity: 1,
  minZoom: 16, // min zoom the ids overlay is available at
  maxZoom: 24, // max zoom the ids overlay is available at
  tileSize: 256,
};

// NOTE: keycloakToken must be passed as a param instead of reading directly from keycloak instance to ensure the layer is updated when the token refreshes
const buildOverlayLayer = (overlay, keycloakToken, props) => {
  const { default: visible, tilesTemplateUrl } = overlay;

  // TODO: remove this transformation once GQL is updated to return the v2 api url instead of v1 (v2 is needed for keycloak token to work)
  const v2TilesTemplateUrl = tilesTemplateUrl.replace('v1', 'v2');

  return new TileLayer({
    data: v2TilesTemplateUrl,
    visible,
    loadOptions: {
      fetch: {
        headers: {
          // Load here instead of in OverlayTileProps in case token is not yet loaded on file load
          Authorization: `Bearer ${keycloakToken}`,
        },
      },
    },
    ...OverlayTileProps,
    ...props,
  });
};

export const getRasterOverlayLayerId = overlayId => `raster-overlay-${overlayId}`;

export const buildRasterOverlayLayer = (
  overlay,
  keycloakToken,
  tileProps,
  bitmapProps,
  idsLayerProps,
) => {
  const { id, position, shifted } = overlay;

  const buildLayer = metadata => {
    const { opacity } = metadata;

    return buildOverlayLayer(overlay, keycloakToken, {
      ...tileProps,
      id: getRasterOverlayLayerId(id),
      opacity,
      renderSubLayers: props => {
        if (props.data) {
          const {
            bbox: { west, south, east, north },
          } = props.tile;

          const bounds = shifted
            ? [west, south, east, north] // already shifted to mapbox
            : [
                // created with bing maps, apply offset to shift to mapbox placement
                west + BING_TO_MAPBOX_OFFSET.longitude,
                south + BING_TO_MAPBOX_OFFSET.latitude,
                east + BING_TO_MAPBOX_OFFSET.longitude,
                north + BING_TO_MAPBOX_OFFSET.latitude,
              ];

          return new BitmapLayer(props, {
            ...bitmapProps,
            id: `${id}${west},${south},${east},${north}`,
            data: null,
            image: props.data,
            bounds: bounds,
          });
        }
      },
    });
  };

  return buildIdsLayer(buildLayer, {
    type: LAYER_TYPE.RASTER_OVERLAY,
    position: typeof idsLayerProps?.position === 'number' ? idsLayerProps.position : position,
    metadata: {
      opacity: 1,
      ...idsLayerProps?.metadata,
    },
  });
};

export const getVectorOverlayLayerId = overlayId => `vector-overlay-${overlayId}`;

export const buildVectorOverlayLayer = (overlay, keycloakToken, mvtProps, idsLayerProps) => {
  const { id, default: visible, tilesTemplateUrl, position, color } = overlay;

  // TODO: remove this transformation once GQL is updated to return the v2 api url instead of v1 (v2 is needed for keycloak token to work)
  const v2TilesTemplateUrl = tilesTemplateUrl.replace('v1', 'v2');

  const rgbArrColor = rgbStrToArray(getRGBColor(color));

  const deckLayer = new MVTLayer({
    id: getVectorOverlayLayerId(id),
    visible,
    data: v2TilesTemplateUrl,
    loadOptions: {
      fetch: {
        headers: {
          // Load here instead of in OverlayTileProps in case token is not yet loaded on file load
          Authorization: `Bearer ${keycloakToken}`,
        },
      },
    },
    getLineColor: rgbArrColor,
    getLineWidth: 0.05,
    lineWidthMinPixels: 1,
    ...OverlayTileProps,
    ...mvtProps,
  });

  return buildIdsLayer(deckLayer, {
    type: LAYER_TYPE.VECTOR_OVERLAY,
    position: typeof idsLayerProps?.position === 'number' ? idsLayerProps.position : position,
    metadata: idsLayerProps?.metadata,
  });
};
