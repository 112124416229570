import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { AssignmentListItemFrag } from '../../../Assignments/AssignmentListItem';
import AssignmentsList from '../../../Assignments/AssignmentsList';
import { useProgramKeys } from '../../../../services/ProgramsService';
import { activeProgramState } from '../../../../atoms/programs';
import { getOrgProgramAssignmentRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';

const ProgramAssignmentListQuery = gql`
  query ProgramAssignments($orgId: ID, $tenantId: ID, $id: ID!, $take: Int, $after: String) {
    program(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      assignments(after: $after, take: $take) {
        edges {
          cursor
          node {
            id
            title
            active
            ...AssignmentListItemFrag
          }
        }
      }
    }
  }
  ${AssignmentListItemFrag}
`;

const ProgramAssignmentsTab = () => {
  const programKeys = useProgramKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);
  const program = useRecoilValue(activeProgramState);

  const useAssignments = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...programKeys.detail(program!.id), 'assignments', `take-${take}`, `after-${after}`],
      ProgramAssignmentListQuery,
      { id: program!.id, take, after },
    );

  if (!program) {
    return null;
  }

  return (
    <AssignmentsList
      useGetAssignments={useAssignments}
      selectConnection={data => data?.program.assignments}
      getTo={assignmentId =>
        getOrgProgramAssignmentRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          programId: program.id,
          assignmentId: assignmentId,
        })
      }
    />
  );
};

export default ProgramAssignmentsTab;
