import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';

import { useRecoilState } from 'recoil';

import { Box, Divider, IconButton, Grid, Paper, Typography } from '@mui/material';

import useCursorPaginatedQuery from '../../../../hooks/useCursorPaginatedQuery';
import useMetadataItems from '../../../../hooks/useMetadataItems';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../../services/LocationService';
import {
  dateAscendingSorter,
  dateDescendingSorter,
  typeSorter,
  getMetadataTypeSorter,
  getCustomFieldMetadataTypeSorter,
} from '../../../ids-lists/IdsMediaListView/commonSorters';
import {
  MEDIA_TYPES,
  MediaType,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
} from '../../../../constants/media';
import useFilterContext from '../../../../hooks/useFilterContext';
import { activeImageId, mediaViewerOpen } from '../../../../atoms/mediaViewer';
import useSetActiveMedia from '../../../../hooks/useSetActiveMedia';
import useHighlightMediaMarker from '../../../../hooks/useHighlightMediaMarker';
import { DISPLAY_TYPES, NO_METADATA_HANDLERS } from '../../../ids-filters/MediaMetadataFilter';
import LevelFilter from '../../../ids-filters/LevelFilter';
import AreaFilter from '../../../ids-filters/AreaFilter';
import TypeFilter from '../../../ids-filters/TypeFilter';
import CategoryFilter from '../../../ids-filters/CategoryFilter';
import TagsFilter from '../../../ids-filters/TagsFilter';
import FlagFilter from '../../../ids-filters/FlagFilter';
import CustomFieldMetadataFilter from '../../../ids-filters/CustomFieldMetadataFilter';
import useCustomFieldFilterVisibility from '../../../../hooks/filtering/useCustomFieldFilterVisibility';
import MapOverlay from '../../MapOverlay';
import IdsMediaListView from '../../../ids-lists/IdsMediaListView';
import FlaggableImage from '../../../FlaggableImage';
import { CloseIcon } from '../../../../theme/icons';
import { STALE_TIME_MS, REFETCH_INTERVAL_MS } from '../../../../constants/queries';
import { useLocationImageBulkEdit } from '../../../ids-lists/IdsMediaListView/commonBulkActions/useLocationImageBulkEdit';
import BulkLocationImageEditDialog from '../../../ids-forms/BulkLocationImageEditDialog';

import styles from './LocationMapMediaGallery.module.css';

export const ImageRepsFrag = gql`
  fragment ImageRepsFrag on PrismaImage {
    id
    organizationId
    reps {
      url
      name
    }
  }
`;

export const ProjectPhotosQuery = gql`
  query ProjectPhotos($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      projectPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...ImageRepsFrag
          }
        }
      }
    }
  }
  ${ImageRepsFrag}
`;

export const HDPhotosQuery = gql`
  query HDPhotos($orgId: ID, $locationId: ID!, $tenantId: ID, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      hdPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...ImageRepsFrag
          }
        }
      }
    }
  }
  ${ImageRepsFrag}
`;

export const PanoramasQuery = gql`
  query Panoramas($orgId: ID, $locationId: ID!, $tenantId: ID, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      panoramas(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...ImageRepsFrag
          }
        }
      }
    }
  }
  ${ImageRepsFrag}
`;

const getMediaThumbnailUrl = m => m.thumbnail;
const getMediaType = m => m.type;
const getMediaId = m => m.node.id;

const LocationMapMediaGallery = ({ location, open, onClose, className, ...rest }) => {
  const { id: locationId, name: locationName, metadataTypes } = location || {};

  const locationKeys = useLocationKeys();

  const [_activeImageId, setActiveImageId] = useRecoilState(activeImageId);
  // eslint-disable-next-line no-unused-vars
  const [_mediaViewerOpen, setMediaViewerOpen] = useRecoilState(mediaViewerOpen);

  const [imageSize, setImageSize] = useState();
  const [activeSorterKey, setActiveSorterKey] = useState();

  const { setActiveMediaAndHighlightOnMap } = useSetActiveMedia();
  const { unhighlightMediaMarker } = useHighlightMediaMarker();

  const { useTypeData, useTypeFilteredData, getTypeItem, setTypeItems } = useFilterContext();
  const projectPhotos = useTypeData(MediaType.ProjectPhoto);
  const hdPhotos = useTypeData(MediaType.HDPhoto);
  const panoramas = useTypeData(MediaType.PanoramicPhoto);
  const filteredProjectPhotos = useTypeFilteredData(MediaType.ProjectPhoto);
  const filteredHdPhotos = useTypeFilteredData(MediaType.HDPhoto);
  const filteredPanoramas = useTypeFilteredData(MediaType.PanoramicPhoto);

  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(metadataTypes);

  const customFieldTypes = useMemo(
    () => location?.assignableCustomFields?.map(acf => acf.type) || [],
    [location?.assignableCustomFields],
  );
  const { handleVisibilityChange, isFilterVisible } =
    useCustomFieldFilterVisibility(customFieldTypes);

  const totalUnfilteredMedia = useMemo(
    () => projectPhotos.length + hdPhotos.length + panoramas.length,
    [projectPhotos, hdPhotos, panoramas],
  );

  const { bulkEditAction, bulkEditOpen, closeBulkEdit, bulkEditImageUrns, imageTypesBulkEditing } =
    useLocationImageBulkEdit();

  // DATA LOADING ===============================================

  const handleRepsPageLoaded = useCallback(
    (edges, type) => {
      const items = [];

      edges.forEach(e => {
        const existingItem = getTypeItem(type, e.node.id);
        if (existingItem) {
          const { node: existingNode, ...existingRest } = existingItem;
          const { node, ...rest } = e;
          items.push({
            node: { ...existingNode, ...node },
            ...existingRest,
            type,
            thumbnail: node.reps.find(r => r.name === 'medium')?.url,
            ...rest,
          });
        } else {
          // Item doesn't yet exist, reps may have been fetched before the image marker details
          items.push(e);
        }
      });

      setTypeItems(items, type);
    },
    [getTypeItem, setTypeItems],
  );

  const handleProjectPhotoRepsPage = useCallback(
    edges => {
      handleRepsPageLoaded(edges, MEDIA_TYPES[MediaType.ProjectPhoto].type);
    },
    [handleRepsPageLoaded],
  );

  const useProjectPhotoReps = (take, after) =>
    useOrgGraphQuery(
      [
        ...locationKeys.map(locationId),
        MEDIA_TYPES[MediaType.ProjectPhoto].type,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      ProjectPhotosQuery,
      { locationId: locationId, take, after },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
      },
    );

  const { isLoading: projectPhotoRepsLoading } = useCursorPaginatedQuery({
    initialData: [],
    useQuery: useProjectPhotoReps,
    defaultTake: 200,
    selectConnection: d => d.location.projectPhotos,
    onPageLoad: handleProjectPhotoRepsPage,
  });

  const handleHdPhotoRepsPage = useCallback(
    edges => {
      handleRepsPageLoaded(edges, MEDIA_TYPES[MediaType.HDPhoto].type);
    },
    [handleRepsPageLoaded],
  );

  const useHDPhotoReps = (take, after) =>
    useOrgGraphQuery(
      [
        ...locationKeys.map(locationId),
        MEDIA_TYPES[MediaType.HDPhoto].type,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      HDPhotosQuery,
      { locationId: locationId, take, after },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
      },
    );

  const { isLoading: hdPhotoRepsLoading } = useCursorPaginatedQuery({
    initialData: [],
    useQuery: useHDPhotoReps,
    defaultTake: 200,
    selectConnection: d => d.location.hdPhotos,
    onPageLoad: handleHdPhotoRepsPage,
  });

  const handlePanoramaRepsPage = useCallback(
    edges => {
      handleRepsPageLoaded(edges, MEDIA_TYPES[MediaType.PanoramicPhoto].type);
    },
    [handleRepsPageLoaded],
  );

  const usePanoramaReps = (take, after) =>
    useOrgGraphQuery(
      [
        ...locationKeys.map(locationId),
        MEDIA_TYPES[MediaType.PanoramicPhoto].type,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      PanoramasQuery,
      { locationId: locationId, take, after },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
      },
    );

  const { isLoading: panoramaRepsLoading } = useCursorPaginatedQuery({
    initialData: [],
    useQuery: usePanoramaReps,
    defaultTake: 200,
    selectConnection: d => d.location.panoramas,
    onPageLoad: handlePanoramaRepsPage,
  });

  const allFilteredMedia = useMemo(
    () => [...filteredProjectPhotos, ...filteredHdPhotos, ...filteredPanoramas],
    [filteredProjectPhotos, filteredHdPhotos, filteredPanoramas],
  );

  const sorters = useMemo(() => {
    const _sorters = [
      dateAscendingSorter,
      dateDescendingSorter,
      typeSorter,
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.Level], allLevels),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.Area], allAreas),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType], allTypes),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory], allCategories),
    ];

    if (location?.assignableCustomFields) {
      const customFieldSorters = location.assignableCustomFields
        .filter(acf => isFilterVisible(acf.type))
        .map(acf => getCustomFieldMetadataTypeSorter(acf));

      _sorters.push(...customFieldSorters);
    }

    return _sorters.map(s => {
      if (!activeSorterKey) return s; // Active sorter has not been set
      return { ...s, isDefault: s.key === activeSorterKey }; // Override default setting to maintain state when closed
    });
  }, [
    activeSorterKey,
    allLevels,
    allAreas,
    allTypes,
    allCategories,
    location.assignableCustomFields,
    isFilterVisible,
  ]);

  // Filters are rendered in the array order
  const filters = useMemo(
    () => [
      {
        renderFilter: () => <FlagFilter />, // Flag filter has a predefined width, so the filterField style is not needed
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <LevelFilter
            allSortedMetadataItems={allLevels} // Match the order defined in location settings
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            type={DISPLAY_TYPES.DROPDOWN}
            className={styles.filterField}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <AreaFilter
            allSortedMetadataItems={allAreas} // Match the order defined in location settings
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            type={DISPLAY_TYPES.DROPDOWN}
            className={styles.filterField}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <TypeFilter
            allSortedMetadataItems={allTypes} // Match the order defined in location settings
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            type={DISPLAY_TYPES.DROPDOWN}
            className={styles.filterField}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <CategoryFilter
            allSortedMetadataItems={allCategories} // Match the order defined in location settings
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            type={DISPLAY_TYPES.DROPDOWN}
            className={styles.filterField}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <TagsFilter
            allSortedMetadataItems={allTags} // Match the order defined in location settings
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            type={DISPLAY_TYPES.DROPDOWN}
            className={styles.filterField}
          />
        ),
        filterExternally: true,
      },
      ...location.assignableCustomFields?.map(assignableCustomField => ({
        renderFilter: () => (
          <CustomFieldMetadataFilter
            assignableCustomField={assignableCustomField}
            onVisibilityChange={handleVisibilityChange}
            className={styles.filterField}
          />
        ),
        disableWrapper: !isFilterVisible(assignableCustomField.type),
        filterExternally: true,
      })),
    ],
    [
      allLevels,
      allAreas,
      allTypes,
      allCategories,
      allTags,
      location.assignableCustomFields,
      isFilterVisible,
      handleVisibilityChange,
    ],
  );

  const selectImage = useCallback(
    image => {
      setActiveImageId(image.node.id);
      setMediaViewerOpen(true);
      // first unhighlight last image if there is one
      if (_activeImageId) {
        unhighlightMediaMarker(_activeImageId);
      }
      setActiveMediaAndHighlightOnMap(image.node.id);
    },
    [
      _activeImageId,
      setActiveImageId,
      setActiveMediaAndHighlightOnMap,
      setMediaViewerOpen,
      unhighlightMediaMarker,
    ],
  );

  const renderImage = useCallback(
    (image, props) => (
      <FlaggableImage flagged={image.node.flagged} autoScaleCornerIcons={true} {...props} />
    ),
    [],
  );

  // IMPORTANT: need to unmount media modal when closed to avoid map performance issues having all those images mounted
  return (
    open && (
      <>
        <MapOverlay className={styles.container} {...rest}>
          <Paper className={styles.paper}>
            <Box className={styles.header}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
                flexDirection='row'
                wrap='nowrap'
              >
                <Grid item xs zeroMinWidth>
                  <Typography variant='h4'>{locationName} Media</Typography>
                </Grid>
                <Grid item xs='auto'>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <div className={styles.mediaScrollContainer}>
              <IdsMediaListView
                media={allFilteredMedia}
                noMediaSeverity={totalUnfilteredMedia ? 'warning' : 'info'}
                noMediaMessage={
                  totalUnfilteredMedia
                    ? 'No media for current filters'
                    : 'This location has no media'
                }
                getMediaThumbnailUrl={getMediaThumbnailUrl}
                getMediaType={getMediaType}
                getMediaId={getMediaId}
                renderImage={renderImage}
                filters={filters}
                sorters={sorters}
                onSortByChange={setActiveSorterKey}
                defaultImageSize={imageSize}
                onImageSizeChange={setImageSize}
                onImageClick={selectImage}
                loading={projectPhotoRepsLoading || hdPhotoRepsLoading || panoramaRepsLoading}
                bulkActions={[bulkEditAction]}
              />
            </div>
          </Paper>
        </MapOverlay>
        <BulkLocationImageEditDialog
          open={bulkEditOpen}
          onClose={closeBulkEdit}
          locationId={locationId}
          imageUrns={bulkEditImageUrns}
          allImages={allFilteredMedia}
          imageTypesEditing={imageTypesBulkEditing}
          metadataTypes={location?.metadataTypes}
        />
      </>
    )
  );
};

LocationMapMediaGallery.defaultProps = {
  open: false,
};

LocationMapMediaGallery.propTypes = {
  location: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default LocationMapMediaGallery;
