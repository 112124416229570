import { useCallback } from 'react';

import useFilterContext from '../../../../hooks/useFilterContext';

import { getMediaTypeFromUrn } from '../../../../utils/media';
import queryClient from '../../../../utils/query';

import {
  EditableMediaType,
  MUTATION_RESULT_MAPPING,
} from '../MediaViewerInfoTab/ImageInfoTable/types';
import { IFlagButtonProps } from '../ButtonsBar/FlagButton';
import { IImageInfoTableProps } from '../MediaViewerInfoTab/ImageInfoTable';

/**
 * Update cache.
 */
const useDefaultEventHandlers = () => {
  const { updateTypeItem, deleteTypeItem } = useFilterContext();

  const invalidateCache = useCallback(async (urn: string) => {
    const mediaType = getMediaTypeFromUrn(urn)!;

    await queryClient.invalidateQueries({
      predicate: query => query.queryKey.includes(urn) || query.queryKey.includes(mediaType),
      refetchActive: false,
    });
  }, []);

  const onFlagUpdate: IFlagButtonProps['onUpdate'] = useCallback(
    async (id, flagged) => {
      const mediaType = getMediaTypeFromUrn(id);

      updateTypeItem(id, mediaType!, 'flagged', flagged);

      await invalidateCache(id);
    },
    [updateTypeItem, invalidateCache],
  );

  const onImageInfoUpdate: IImageInfoTableProps['onImageInfoUpdate'] = useCallback(
    async (id, result) => {
      const mediaType: EditableMediaType = getMediaTypeFromUrn(id) as EditableMediaType;

      if (result[MUTATION_RESULT_MAPPING[mediaType]].image.metadata) {
        updateTypeItem(
          id,
          mediaType,
          'metadata',
          result[MUTATION_RESULT_MAPPING[mediaType]].image.metadata,
        );
      }

      if (result[MUTATION_RESULT_MAPPING[mediaType]].image.customFieldMetadata) {
        updateTypeItem(
          id,
          mediaType,
          'customFieldMetadata',
          result[MUTATION_RESULT_MAPPING[mediaType]].image.customFieldMetadata,
        );
      }

      if (result[MUTATION_RESULT_MAPPING[mediaType]].image.published !== undefined) {
        updateTypeItem(
          id,
          mediaType,
          'published',
          result[MUTATION_RESULT_MAPPING[mediaType]].image.published,
        );
      }

      await invalidateCache(id);
    },
    [updateTypeItem, invalidateCache],
  );

  const onTagsUpdate = useCallback(
    async (id: string, result: Record<string, any>) => {
      updateTypeItem(id, result.image.mediaType, 'metadata', result.image.metadata);

      await invalidateCache(id);
    },
    [updateTypeItem, invalidateCache],
  );

  const onDelete = useCallback(
    async (urn: string) => {
      const mediaType = getMediaTypeFromUrn(urn)!;

      deleteTypeItem(urn, mediaType);

      await invalidateCache(urn);
    },
    [deleteTypeItem, invalidateCache],
  );

  return {
    onDelete,
    onFlagUpdate,
    onTagsUpdate,
    onImageInfoUpdate,
  };
};

export default useDefaultEventHandlers;
