import React from 'react';
import { Box, TableCell } from '@mui/material';

export interface IMiddleAlignTableCellProps {
  children: React.ReactNode;
}

const MiddleAlignTableCell: React.FC<IMiddleAlignTableCellProps> = props => {
  return (
    <TableCell>
      <Box display='flex' flexDirection='row' alignItems='center'>
        {props.children}
      </Box>
    </TableCell>
  );
};

export default MiddleAlignTableCell;
