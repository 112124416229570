import React, { useCallback } from 'react';
import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

import FilledIconButton from '../../../../../../components/ids-buttons/FilledIconButton';
import MapMarker from '../../../../../../components/mapping/MapMarker';

import { DeleteOutlineIcon } from '../../../../../../theme/icons';
import { IRoutePoint } from '../../../types';

import useDeleteRoutePointAction from './useDeleteRoutePointAction';
import styles from './DeleteButtonMarker.module.css';

export interface IDeleteButtonMarkerProps {
  routePoint: IRoutePoint;
  onDelete: () => void;
}

const backgroundPropsActiveIcon = {
  className: styles.squareShape,
  sx: {
    backgroundColor: (theme: Theme) => theme.palette.error.dark,
  },
};

const backgroundPropsInactiveIcon = {
  className: styles.squareShape,
};

const iconSx = { color: grey['50'] };

const DeleteButtonMarker: React.FC<IDeleteButtonMarkerProps> = ({ routePoint, onDelete }) => {
  const { deleting, deleted, deleteRoutePoint } = useDeleteRoutePointAction();

  /**
   * This also includes cancel and feedback actions.
   */
  const handleOnClick = useCallback(async () => {
    await deleteRoutePoint(routePoint, onDelete);
  }, [routePoint, deleteRoutePoint, onDelete]);

  return (
    <MapMarker
      latitude={routePoint.node.position.latitude}
      longitude={routePoint.node.position.longitude}
      // Render it a bit above.
      pixelOffsetY={-40}
      pixelOffsetX={undefined}
      onClick={undefined}
      anchor={undefined}
      onResize={undefined}
      style={undefined}
    >
      {deleting || deleted ? (
        <FilledIconButton
          size='small'
          disabled={true}
          backgroundProps={backgroundPropsInactiveIcon}
        >
          <DeleteOutlineIcon fontSize='inherit' />
        </FilledIconButton>
      ) : (
        <FilledIconButton
          size='small'
          backgroundProps={backgroundPropsActiveIcon}
          onClick={handleOnClick}
        >
          <DeleteOutlineIcon fontSize='inherit' sx={iconSx} />
        </FilledIconButton>
      )}
    </MapMarker>
  );
};

export default DeleteButtonMarker;
