import React from 'react';
import { Box, CircularProgress, Theme } from '@mui/material';

import clsx from 'clsx';

import Logo from '../DarkIdsLogo';

import styles from './LoadingScreen.module.css';
import '../../theme/globalStyles.css';

const sx = {
  container: (theme: Theme) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  }),
};

export interface ILoadingScreenProps {
  fullscreen?: boolean;
}

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ fullscreen }) => {
  return (
    <Box
      className={clsx(styles.container, { [styles['fullscreen']]: fullscreen })}
      sx={sx.container}
    >
      <Box className={clsx('centerChildren', styles.logoContainer)}>
        <Logo className={styles.logo} />
      </Box>
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
