import React from 'react';
import {
  AccordionSummary,
  AccordionSummaryProps,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';

import { ExpandIcon } from '../../../../theme/icons';

import '../../../../theme/globalStyles.css';
import styles from './AssignmentEntityAccordion.module.css';

export interface IAssignmentEntityAccordionSummaryProps extends AccordionSummaryProps {
  primaryText: React.ReactNode;
  primaryTypographyProps?: TypographyProps;
  secondaryText: React.ReactNode;
  secondaryTypographyProps?: TypographyProps;
  secondaryContent?: React.ReactNode;
  loading?: boolean;
}

const AssignmentEntityAccordionSummary: React.FC<IAssignmentEntityAccordionSummaryProps> = ({
  primaryText,
  primaryTypographyProps,
  secondaryText,
  secondaryTypographyProps,
  secondaryContent,
  loading,
  ...rest
}) => {
  return (
    <AccordionSummary expandIcon={<ExpandIcon />} {...rest}>
      <Grid container direction='row'>
        <Grid item xs>
          <Stack direction='column'>
            <Typography variant='body2' {...primaryTypographyProps}>
              {primaryText}
            </Typography>
            <Stack direction='row' spacing={2}>
              <Typography variant='caption' color='text.secondary' {...secondaryTypographyProps}>
                {secondaryText}
              </Typography>
              {secondaryContent}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <div className={`centerChildren ${styles.loaderContainer}`}>
            {loading && <CircularProgress size={25} />}
          </div>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

export default AssignmentEntityAccordionSummary;
