import React, { useMemo } from 'react';
import { Button, Tooltip } from '@mui/material';

import MapOverlay from '../../MapOverlay';
import { FlagIcon, FlagOutlinedIcon, NotFlaggedIcon } from '../../../../theme/icons';

import useFlagFilter, { FLAG_FILTER_MODES } from '../../../../hooks/filtering/useFlagFilter';

import styles from './FlagFilterControl.module.css';

const sx = {
  control: {
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: 'background.dark',
    },
  },
  flagIcon: {
    color: 'ids.marker.markerDotFlagged',
  },
};

const FlaggedActiveIcon = () => (
  <FlagIcon className={styles.flagIconActive} sx={sx.flagIcon} data-testid='flagIconActive' />
);

const FlaggedInactiveIcon = () => (
  <FlagOutlinedIcon
    className={styles.flagIconInactive}
    sx={sx.flagIcon}
    data-testid='flagIconInactive'
  />
);

const NotFlaggedActiveIcon = () => (
  <NotFlaggedIcon className={styles.notFlagIconActive} data-testid='notFlagIconActive' />
);

const FlagFilterControl = ({ disabled = false, ...rest }) => {
  const { toggleFlagFilter, flagFilterMode } = useFlagFilter();

  const flagFilterToggle = useMemo(() => {
    const flagFilterActive = flagFilterMode === FLAG_FILTER_MODES.FLAGGED;
    const flagFilterNotFlagged = flagFilterMode === FLAG_FILTER_MODES.NOT_FLAGGED;

    return (
      <Tooltip
        title={
          flagFilterNotFlagged
            ? 'Disable flag filter'
            : flagFilterActive
            ? 'Filter for non-flagged items'
            : 'Filter for flagged items'
        }
        placement='left'
      >
        <span>
          <Button
            size='small'
            onClick={toggleFlagFilter}
            disabled={disabled}
            className={styles.flagFilterBtn}
            sx={sx.control}
          >
            {flagFilterNotFlagged ? (
              <NotFlaggedActiveIcon />
            ) : flagFilterActive ? (
              <FlaggedActiveIcon />
            ) : (
              <FlaggedInactiveIcon />
            )}
          </Button>
        </span>
      </Tooltip>
    );
  }, [toggleFlagFilter, flagFilterMode, disabled]);

  return <MapOverlay {...rest}>{flagFilterToggle}</MapOverlay>;
};

export default FlagFilterControl;
