import React from 'react';
import PropTypes from 'prop-types';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../constants/media';
import { selectedCategories } from '../../../atoms/categoryFilter';

import useActiveFilterTargets from '../../../hooks/filtering/useActiveFilterTargets';
import { useRestoreMediaMetadataFilterFromUrl } from '../../../hooks/filtering/useMediaMetadataFilter';

import MediaMetadataFilter from '../MediaMetadataFilter';
import { getCommonMetadataFilterTargets } from '../helpers';

const filterTargets = getCommonMetadataFilterTargets(MediaMetadataType.PhotoCategory);

const CategoryFilter = props => {
  const activeFilterTargets = useActiveFilterTargets(filterTargets);

  return (
    <MediaMetadataFilter
      label={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].label}
      metadataType={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].type}
      filterTargets={activeFilterTargets}
      filterStateAtom={selectedCategories}
      noMetadataToFilterByMessage='There are no categories to filter by.'
      {...props}
    />
  );
};

export const useRestoreCategoryFilterFromUrl = allValidMetadataItems => {
  const activeFilterTargets = useActiveFilterTargets(filterTargets);

  useRestoreMediaMetadataFilterFromUrl({
    metadataType: MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].type,
    filterTargets: activeFilterTargets,
    filterStateAtom: selectedCategories,
    allValidMetadataItems,
  });
};

CategoryFilter.propTypes = {
  allSortedMetadataItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default CategoryFilter;
