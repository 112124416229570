import { Chip, ListItemText, Tooltip, Typography, TypographyProps } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { gql } from 'graphql-request';

import IdsListItem, { IIdsListItemProps } from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import AssignmentChip from '../../../components/entity-chips/AssignmentChip';
import EntityParentChip, {
  EntityParentType,
} from '../../../components/entity-chips/EntityParentChip';

import { isTextTruncated } from '../../../utils/helpers';

export const AssignmentListItemFrag = gql`
  fragment AssignmentListItemFrag on Assignment {
    id
    title
    description
    parentType
    parentId
    active
  }
`;

// Restricted list of properties here
type PropertyParentType =
  | EntityParentType.Organization
  | EntityParentType.Program
  | EntityParentType.Location
  | EntityParentType.Project;

export interface IAssignmentListItemFrag {
  id: string;
  title: string;
  description: string;
  parentType: PropertyParentType;
  parentId: string;
  active: boolean;
}

const primaryTypographyProps: TypographyProps = { variant: 'h6', noWrap: true };
const secondaryTypographyProps: TypographyProps = { noWrap: true };

export interface IAssignmentListItemProps extends Pick<IIdsListItemProps, 'to'> {
  assignmentItem: IAssignmentListItemFrag;
}

const AssignmentListItem: React.FC<IAssignmentListItemProps> = ({ assignmentItem, to }) => {
  const [valueTruncated, setValueTruncated] = useState(false);

  const handleValueRef = useCallback(
    (e: HTMLSpanElement) => setValueTruncated(isTextTruncated(e)),
    [],
  );

  if (!assignmentItem) {
    return null;
  }

  const { id, title, description, parentType, parentId, active } = assignmentItem;

  const valueText = (
    <Typography noWrap variant='body2' color='text.secondary' ref={handleValueRef}>
      {description}
    </Typography>
  );

  return (
    <IdsListItem
      key={id}
      // secondaryAction={
      //   <IconButton
      //     onClick={() =>
      //       navigate(
      //         `/app/organization/${organization.id}/assignments/${id}/edit`,
      //       )
      //     }
      //     disabled
      //   >
      //     <SvgIcon fontSize='small'>
      //       <EditOutlineIcon />
      //     </SvgIcon>
      //   </IconButton>
      // }
      to={to}
    >
      <ListItemText
        primary={title}
        primaryTypographyProps={primaryTypographyProps}
        secondary={valueTruncated ? <Tooltip title={description}>{valueText}</Tooltip> : valueText}
        secondaryTypographyProps={secondaryTypographyProps}
      />
      <IdsListItemChild xs={3} lg={2} stopPropagation>
        <AssignmentChip id={id} />
      </IdsListItemChild>
      <IdsListItemChild xs={3} lg={2} stopPropagation>
        <EntityParentChip parentType={parentType} parentId={parentId} />
      </IdsListItemChild>
      <IdsListItemChild xs={2}>
        <Chip
          color={active ? 'success' : 'warning'}
          variant='outlined'
          size='small'
          label={active ? 'Active' : 'Inactive'}
        />
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default AssignmentListItem;
