import { MediaMetadataType, MediaType } from '../../../../../constants/media';
import { useUpdateProjectPhotoMutation } from '../../../../../services/ProjectPhotosService';
import { useUpdateHdPhotoMutation } from '../../../../../services/HDPhotosService';
import { useUpdatePanoramaMutation } from '../../../../../services/PanoramasService';
import {
  useUpdateHdPhotoCustomFields,
  useUpdatePanoramaCustomFields,
  useUpdateProjectPhotoCustomFields,
} from '../../../../../services/CustomFieldsService';

export interface IFormValues {
  description: string;
  published?: boolean | string;
  [MediaMetadataType.PhotoCategory]?: string;
  [MediaMetadataType.Area]?: string;
  [MediaMetadataType.Level]?: string;
  [MediaMetadataType.PhotoType]?: string;
  customFields: Record<string, any>;
}

export const EDITABLE_TYPES_MAPPING = {
  [MediaType.ProjectPhoto]: useUpdateProjectPhotoMutation,
  [MediaType.HDPhoto]: useUpdateHdPhotoMutation,
  [MediaType.PanoramicPhoto]: useUpdatePanoramaMutation,
};

export const CUSTOM_FIELDS_MUTATION_MAPPING = {
  [MediaType.ProjectPhoto]: useUpdateProjectPhotoCustomFields,
  [MediaType.HDPhoto]: useUpdateHdPhotoCustomFields,
  [MediaType.PanoramicPhoto]: useUpdatePanoramaCustomFields,
};

export const EDITABLE_TYPES = Object.keys(EDITABLE_TYPES_MAPPING);

export type EditableMediaType = keyof typeof EDITABLE_TYPES_MAPPING;

export const METADATA_TYPES_MAPPING = {
  [MediaMetadataType.PhotoType]: MediaMetadataType.PhotoType,
  [MediaMetadataType.PhotoCategory]: MediaMetadataType.PhotoCategory,
  [MediaMetadataType.Area]: MediaMetadataType.Area,
  [MediaMetadataType.Level]: MediaMetadataType.Level,
};

export type MetadataTypes = (typeof METADATA_TYPES_MAPPING)[keyof typeof METADATA_TYPES_MAPPING];

export const MUTATION_RESULT_MAPPING = {
  [MediaType.ProjectPhoto]: 'updateProjectPhoto',
  [MediaType.HDPhoto]: 'updateHdPhoto',
  [MediaType.PanoramicPhoto]: 'updatePanorama',
};

export const MEDIA_TYPE_EDITABLE_METADATA = {
  [MediaType.ProjectPhoto]: [
    MediaMetadataType.Area,
    MediaMetadataType.Level,
    MediaMetadataType.PhotoCategory,
    MediaMetadataType.PhotoType,
  ],
  [MediaType.HDPhoto]: [MediaMetadataType.Area, MediaMetadataType.Level],
  [MediaType.PanoramicPhoto]: [MediaMetadataType.Area, MediaMetadataType.Level],
};

export const isCorrectMediaType = (x: string): x is EditableMediaType => {
  return Object.keys(EDITABLE_TYPES_MAPPING).includes(x);
};
