import { useFormikContext } from 'formik';

import { useCallback, useMemo } from 'react';

import IdsFileSelect, { IIdsFileSelectProps } from '../../ids-inputs/IdsFileSelect';

// No need in onChange, onBlur, onFocus and value props
// Because this component will use formik context
type ParentType = Omit<IIdsFileSelectProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'>;

export interface IIdsFormFileSelectFieldProps extends ParentType {
  name: string;
}

export const IdsFormFileSelectField: React.FC<IIdsFormFileSelectFieldProps> = ({
  name,
  ...rest
}) => {
  const { setFieldValue, values, errors, touched } = useFormikContext<any>();

  // Formik doesn't work well with useField hook onChange function.
  // setFieldValue from formik context works as expected
  const onChange = useCallback((value: any) => setFieldValue(name, value), [name, setFieldValue]);

  const value = useMemo(() => values[name], [values, name]);

  return (
    <IdsFileSelect
      name={name}
      onChange={onChange}
      value={value}
      error={touched[name] && errors[name] ? (errors[name] as string) : undefined}
      {...rest}
    />
  );
};

export default IdsFormFileSelectField;
