import React, { useState } from 'react';

import IdsTabs, { IIdsTabsProps, ITab } from '../IdsTabs';

export interface IIdsTabsRendererTab extends ITab {
  content: React.ReactNode;
}

export interface IIdsTabsRendererProps extends Optional<IIdsTabsProps, 'onChange'> {
  tabs: IIdsTabsRendererTab[];
  contentHeader: React.ReactNode;
}

const IdsTabsRenderer: React.FC<IIdsTabsRendererProps> = ({
  tabs,
  onChange,
  contentHeader,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState(() => (tabs?.length ? tabs[0] : null));

  const onTabChange = (tabKey: string) => {
    const foundTab = tabs.find(t => t.key === tabKey)!;
    setActiveTab(foundTab);
    if (onChange) {
      onChange(tabKey);
    }
  };

  if (!tabs?.length) return null;

  return (
    <>
      <IdsTabs tabs={tabs} onChange={onTabChange} {...rest} />
      {contentHeader}
      {activeTab?.content}
    </>
  );
};

export default IdsTabsRenderer;
