import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { tryParseJSONObject } from '../utils/json';

// Char separator used to separate array url elements
// Using this char because its unlikely to be seen in a value and is nonescaped,
// meaning the url will be kept shorter than using an escaped character like a comma
const SEPARATOR = '~';

const useUrlState = () => {
  const [, setSearchParams] = useSearchParams();

  const _setSearchParams = useCallback(
    updater => {
      setSearchParams(updater(new URLSearchParams(window.location.search)), {
        replace: true,
      });
    },
    [setSearchParams],
  );

  const getUrlArrayState = useCallback(key => {
    // Handle 1 value per key or all values to one key in csv format (backwards compatibility to old syntax of 1 key to a value)
    return new URLSearchParams(window.location.search)
      .getAll(key)
      ?.flatMap(possibleCSV => possibleCSV.split(SEPARATOR));
  }, []);

  const setUrlArrayState = useCallback(
    (key, values) => {
      _setSearchParams(params => {
        params.delete(key); // delete all previous values

        if (values?.length) {
          params.set(key, values.join(SEPARATOR));
        }

        return params;
      });
    },
    [_setSearchParams],
  );

  const appendUrlArrayStateItem = useCallback(
    (key, value) => {
      const values = getUrlArrayState(key) || [];
      const index = values.findIndex(urlValue => urlValue === value);
      if (index < 0) {
        // not already set
        values.push(value);
        setUrlArrayState(key, values);
      }
    },
    [getUrlArrayState, setUrlArrayState],
  );

  const removeUrlArrayStateItem = useCallback(
    (key, value) => {
      const values = getUrlArrayState(key) || [];
      const index = values.findIndex(urlValue => urlValue === value);
      if (index >= 0) {
        values.splice(index, 1);
        setUrlArrayState(key, values);
      }
    },
    [getUrlArrayState, setUrlArrayState],
  );

  const getUrlState = useCallback(key => {
    return new URLSearchParams(window.location.search).get(key);
  }, []);

  /** Set a primitive value in the url state */
  const setUrlState = useCallback(
    (key, value) => {
      _setSearchParams(params => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
        return params;
      });
    },
    [_setSearchParams],
  );

  const getUrlObjectState = useCallback(
    key => {
      const objStr = getUrlState(key);
      return objStr ? tryParseJSONObject(objStr) : null;
    },
    [getUrlState],
  );

  const setUrlObjectState = useCallback(
    (key, value) => {
      const objStr = value && JSON.stringify(value);
      setUrlState(key, objStr);
    },
    [setUrlState],
  );

  return {
    setUrlObjectState,
    getUrlObjectState,
    setUrlArrayState,
    appendUrlArrayStateItem,
    removeUrlArrayStateItem,
    getUrlArrayState,
    setUrlState,
    getUrlState,
  };
};

export default useUrlState;
