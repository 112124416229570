import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { activeOrganizationState } from '../../atoms/organizations';
import useDeleteEntity from '../../hooks/useDeleteEntity';
import { useDeleteProject } from '../../services/ProjectService';

const useDeleteProjectAction = () => {
  const [organization] = useRecoilState(activeOrganizationState);
  const deleteProjMutation = useDeleteProject(organization.id);
  const { deleted, deleting, handleDelete } = useDeleteEntity();

  const deleteProject = useCallback(
    (project, onDelete, onCancel) => {
      handleDelete(
        async () => await deleteProjMutation.mutateAsync({ projectId: project?.id }),
        project?.name,
        'This cannot be undone, all project data will be deleted permanently.',
        'Project',
        onDelete,
        onCancel,
      );
    },
    [deleteProjMutation, handleDelete],
  );

  return {
    deleted,
    deleting,
    deleteProject,
  };
};

export default useDeleteProjectAction;
