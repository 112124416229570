import { useRecoilValue } from 'recoil';
import React, { useMemo } from 'react';

import { activeOrganizationState } from '../../../../../atoms/organizations';
import { activeAssignmentState } from '../../../../../atoms/assignments';
import { activeAssignmentResponseState } from '../../../../../atoms/assignmentResponses';

import AssignmentResponseDetailsTemplatePage from '../../../AssignmentResponseDetailsTemplatePage';
import {
  getOrgAssignmentResponsesRoute,
  getOrgAssignmentRoute,
  getOrgAssignmentsRoute,
} from '../../../../../utils/routes';
import { activeTenantState } from '../../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentResponseDetailsProps {}

const AssignmentResponseDetails: React.FC<IAssignmentResponseDetailsProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeAssignment = useRecoilValue(activeAssignmentState);
  const activeAssignmentResponse = useRecoilValue(activeAssignmentResponseState);

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'Assignments',
        route: getOrgAssignmentsRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
        }),
      },
      {
        label: activeAssignment.title,
        route: getOrgAssignmentRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          assignmentId: activeAssignment?.id,
        }),
      },
      {
        label: 'Responses',
        route: getOrgAssignmentResponsesRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          assignmentId: activeAssignment?.id,
        }),
      },
    ],
    [activeTenant?.subdomain, activeOrg.id, activeAssignment.title, activeAssignment?.id],
  );

  return (
    <AssignmentResponseDetailsTemplatePage
      assignmentId={activeAssignment.id}
      assignmentResponseId={activeAssignmentResponse.id}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default AssignmentResponseDetails;
