import { Typography } from '@mui/material';
import { gql } from 'graphql-request';

export interface IStringAnswerFragType {
  answerText?: string;
  question?: {
    id: string;
    assignmentQuestionOptions?: {
      title?: string;
      id: string;
    }[];
  };
}

export const StringAnswerFrag = gql`
  fragment StringAnswerFrag on AssignmentAnswer {
    answerText
    question {
      assignmentQuestionOptions {
        title
        id
      }
      id
    }
  }
`;

export interface IStringAnswerProps {
  answer: IStringAnswerFragType;
}

const StringAnswer: React.FC<IStringAnswerProps> = ({ answer }) => {
  const { answerText } = answer;

  return <Typography variant='body2'>{answerText}</Typography>;
};

export default StringAnswer;
