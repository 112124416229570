import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Grid, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SnackbarKey, useSnackbar } from 'notistack';

import { IProcessedSection, SettingsType } from '../types';
import {
  transformAssignmentQuestionsData,
  useGetAssignmentQuestions,
} from '../../../services/ReportsService';

export const styles = {
  input: {
    backgroundColor: '#fff',
  },
};

export interface ISettingsProps {
  assignmentId: string | null;
  setSettings: React.Dispatch<React.SetStateAction<SettingsType>>;
  setIsEmptyReport: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssignmentFiltering: React.FC<ISettingsProps> = ({
  setSettings,
  assignmentId,
  setIsEmptyReport,
}) => {
  const [expandedTasks, setExpandedTasks] = useState<Record<string, boolean>>({});
  const [showContent, setShowContent] = useState<boolean>(false);
  const [sections, setSections] = useState<IProcessedSection[] | null>(null);
  const snackbarKeyRef = useRef<SnackbarKey | null>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data, isLoading, error } = useGetAssignmentQuestions(assignmentId, {
    enabled: !!assignmentId && showContent,
  });

  useEffect(() => {
    if (data && data.assignment && data.assignment.assignmentSections) {
      const formattedData = transformAssignmentQuestionsData(
        data.assignment.assignmentSections.edges,
      );
      const filteredSections = formattedData.filter(section => section.tasks.length > 0);
      setSections(filteredSections); // Filter out sections with no tasks
    }
  }, [data]);

  useEffect(() => {
    if (sections) {
      // Check if all sections are unchecked
      const allSectionsUnchecked = sections.every(section => !section.includeToReport);
      setIsEmptyReport(allSectionsUnchecked); // Update the isEmptyReport state
    }
  }, [sections, setIsEmptyReport]);

  useEffect(() => {
    if (showContent && isLoading) {
      // Only show the snackbar if it isn't already shown
      if (snackbarKeyRef.current === null) {
        const key = enqueueSnackbar('Loading assignment data...', { variant: 'info' });
        snackbarKeyRef.current = key;
      }
    } else if (snackbarKeyRef.current !== null) {
      closeSnackbar(snackbarKeyRef.current); // Close the snackbar once loading is finished
      snackbarKeyRef.current = null;
    }
  }, [isLoading, showContent, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Failed to retrieve assignment data. Please try again.', {
        variant: 'error',
      });
    }
  }, [error, enqueueSnackbar]);

  // Toggle visibility of all sections, tasks, and questions
  const handleToggleVisibility = useCallback(() => {
    setShowContent(prev => !prev);
  }, []);

  const toggleTaskExpand = useCallback((taskId: string) => {
    setExpandedTasks((prev: Record<string, boolean>) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  }, []);

  // Helper function to compute ignore arrays from sections
  function computeIgnoreArrays(sections: IProcessedSection[]) {
    const sectionsToIgnore: number[] = [];
    const tasksToIgnore: number[] = [];
    const questionsToIgnore: number[] = [];

    sections.forEach(section => {
      if (!section.includeToReport) {
        sectionsToIgnore.push(Number(section.id));
      } else {
        section.tasks.forEach(task => {
          if (!task.includeToReport) {
            tasksToIgnore.push(Number(task.id));
          } else {
            task.questions.forEach(question => {
              if (!question.includeToReport) {
                questionsToIgnore.push(Number(question.id));
              }
            });
          }
        });
      }
    });

    return { sectionsToIgnore, tasksToIgnore, questionsToIgnore };
  }

  const toggleSection = useCallback(
    (sectionId: string) => {
      setSections(prevState => {
        const newSections = prevState!.map(section => {
          if (section.id !== sectionId) return section;
          const newIncludeToReport = !section.includeToReport;
          return {
            ...section,
            includeToReport: newIncludeToReport,
            tasks: section.tasks.map(task => ({
              ...task,
              includeToReport: newIncludeToReport,
              questions: task.questions.map(question => ({
                ...question,
                includeToReport: newIncludeToReport,
              })),
            })),
          };
        });

        // Compute ignore arrays from the updated sections state
        const { sectionsToIgnore, tasksToIgnore, questionsToIgnore } =
          computeIgnoreArrays(newSections);
        // Update settings state
        setSettings(prevSettings => ({
          ...prevSettings,
          sectionsToIgnore,
          tasksToIgnore,
          questionsToIgnore,
        }));

        return newSections;
      });
    },
    [setSettings],
  );

  const toggleTask = useCallback(
    (sectionId: string, taskId: string) => {
      setSections(prevState => {
        const newSections = prevState!.map(section => {
          if (section.id !== sectionId) return section;
          const updatedTasks = section.tasks.map(task => {
            if (task.id === taskId) {
              return {
                ...task,
                includeToReport: !task.includeToReport,
                questions: task.questions.map(question => ({
                  ...question,
                  includeToReport: !task.includeToReport,
                })),
              };
            }
            return task;
          });
          // If all tasks are unchecked, uncheck the section
          const allTasksUnchecked = updatedTasks.every(task => !task.includeToReport);
          return {
            ...section,
            tasks: updatedTasks,
            includeToReport: !allTasksUnchecked,
          };
        });

        // Compute ignore arrays from the updated sections state
        const { sectionsToIgnore, tasksToIgnore, questionsToIgnore } =
          computeIgnoreArrays(newSections);
        setSettings(prevSettings => ({
          ...prevSettings,
          sectionsToIgnore,
          tasksToIgnore,
          questionsToIgnore,
        }));

        return newSections;
      });
    },
    [setSettings],
  );

  const toggleQuestion = useCallback(
    (sectionId: string, taskId: string, questionId: string) => {
      setSections(prevState => {
        const newSections = prevState!.map(section => {
          if (section.id !== sectionId) return section;
          const updatedTasks = section.tasks.map(task => {
            if (task.id !== taskId) return task;
            const updatedQuestions = task.questions.map(question => {
              if (question.id === questionId) {
                return {
                  ...question,
                  includeToReport: !question.includeToReport,
                };
              }
              return question;
            });
            // Determine task's include state based on its questions
            const taskIncludeToReport = updatedQuestions.some(q => q.includeToReport);
            return {
              ...task,
              questions: updatedQuestions,
              includeToReport: taskIncludeToReport,
            };
          });
          // If all tasks are unchecked, uncheck the section
          const allTasksUnchecked = updatedTasks.every(task => !task.includeToReport);
          return {
            ...section,
            tasks: updatedTasks,
            includeToReport: !allTasksUnchecked,
          };
        });

        // Compute ignore arrays from the updated sections state
        const { sectionsToIgnore, tasksToIgnore, questionsToIgnore } =
          computeIgnoreArrays(newSections);
        setSettings(prevSettings => ({
          ...prevSettings,
          sectionsToIgnore,
          tasksToIgnore,
          questionsToIgnore,
        }));

        return newSections;
      });
    },
    [setSettings],
  );

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item xs>
        <Button variant='contained' onClick={handleToggleVisibility}>
          {showContent ? 'Hide Assignment Information' : 'Filter Assignment Information'}
        </Button>
      </Grid>
      {showContent && !isLoading && sections && (
        <>
          {sections.map((section: IProcessedSection) => (
            <Grid item xs container direction='column' spacing={2} key={section.id}>
              <Grid item xs>
                <span>
                  <Checkbox
                    checked={section.includeToReport}
                    onChange={() => toggleSection(section.id)}
                  />
                  {section.title}
                </span>
              </Grid>
              <Grid item xs container direction='column'>
                {section.tasks.map(task => (
                  <Grid item xs container direction='column' key={task.id} pl={2}>
                    <Grid item xs display='flex' alignItems='center'>
                      <Checkbox
                        checked={task.includeToReport}
                        onChange={() => toggleTask(section.id, task.id)}
                      />
                      {task.title}
                      <IconButton onClick={() => toggleTaskExpand(task.id)} size='small'>
                        {expandedTasks[task.id] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Grid>
                    {expandedTasks[task.id] && (
                      <Grid item xs container direction='column'>
                        {task.questions.map(question => (
                          <Grid item xs key={question.id} pl={4}>
                            <span>
                              <Checkbox
                                checked={question.includeToReport}
                                onChange={() => toggleQuestion(section.id, task.id, question.id)}
                              />
                              {question.title}
                            </span>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default AssignmentFiltering;
