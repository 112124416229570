import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRecoilValue } from 'recoil';

import { locationValidationSchema } from '../../locationValidation';
import IdsEditForm from '../../../../components/ids-forms/IdsEditForm';
import LocationForm from '../../LocationForm';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeLocationState } from '../../../../atoms/locations';
import { useUpdateLocation } from '../../../../services/LocationService';

function EditLocationForm({ initialValues, onSubmitSuccess, onCancel }) {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const updateLocationMutation = useUpdateLocation(activeLocation.id, activeOrg.id);

  const onSubmit = useCallback(
    async values => {
      await updateLocationMutation.mutateAsync({
        id: activeLocation.id,
        ...values,
      });

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    [updateLocationMutation, onSubmitSuccess, activeLocation.id],
  );

  const _initialValues = useMemo(
    () => ({
      name: initialValues.name || '',
      address: initialValues.address || '',
      city: initialValues.city || '',
      state: initialValues.state || '',
      zip: initialValues.zip || '',
      yearBuilt: initialValues.yearBuilt || '',
      latitude: initialValues.latitude || '',
      longitude: initialValues.longitude || '',
      heading: initialValues.heading || 0,
    }),
    [initialValues],
  );

  return (
    <IdsEditForm
      initialValues={_initialValues}
      validationSchema={locationValidationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Location updated'
      errorHandler={() => 'Location could not be updated'}
    >
      <LocationForm />
    </IdsEditForm>
  );
}

EditLocationForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditLocationForm;
