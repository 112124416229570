import { useMemo } from 'react';

import usePermissions from '../../../../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../../../../constants/users';

const useCanDeleteRoute = () => {
  const { userHasOneOfRoles } = usePermissions();

  return useMemo(() => {
    return userHasOneOfRoles([
      USER_ROLES.IDS_ADMIN,
      USER_ROLES.IDS_TEAM,
      USER_ROLES.TENANT_TEAM,
      USER_ROLES.TENANT_ADMIN,
    ]);
  }, [userHasOneOfRoles]);
};

export default useCanDeleteRoute;
