import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeProjectState } from '../../../../atoms/projects';
import { activeTenantState } from '../../../../atoms/tenants';
import { AssignmentListItemFrag } from '../../../Assignments/AssignmentListItem';
import AssignmentsList from '../../../Assignments/AssignmentsList';
import { useProjectKeys } from '../../../../services/ProjectService';
import { getOrgProjectAssignmentRoute } from '../../../../utils/routes';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';

const ProjectAssignmentListQuery = gql`
  query ProjectAssignments($orgId: ID, $tenantId: ID, $id: ID!, $take: Int, $after: String) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      assignments(after: $after, take: $take) {
        edges {
          cursor
          node {
            id
            title
            active
            ...AssignmentListItemFrag
          }
        }
      }
    }
  }
  ${AssignmentListItemFrag}
`;

const ProjectAssignmentsTab = () => {
  const projectKeys = useProjectKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);
  const project = useRecoilValue(activeProjectState);

  const useAssignments = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...projectKeys.detail(project!.id), 'assignments', `take-${take}`, `after-${after}`],
      ProjectAssignmentListQuery,
      { id: project!.id, take, after },
    );

  if (!project) {
    return null;
  }

  return (
    <AssignmentsList
      useGetAssignments={useAssignments}
      selectConnection={data => data?.project.assignments}
      getTo={assignmentId =>
        getOrgProjectAssignmentRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          projectId: project.id,
          assignmentId,
        })
      }
    />
  );
};

export default ProjectAssignmentsTab;
