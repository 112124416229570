import React from 'react';
import { ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import { activeOrganizationState } from '../../../atoms/organizations';
import { Collection } from '../../../services/CollectionService';

import { getOrgCollectionRoute } from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';

import CollectionListItemMenu from './CollectionListItemMenu';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICollectionListItemProps extends Collection {}

const CollectionListItem: React.FC<ICollectionListItemProps> = ({ node }) => {
  const navigate = useNavigate();
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  return (
    <IdsListItem
      key={node.id}
      primaryAction={() =>
        navigate(
          getOrgCollectionRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            collectionId: node.id,
          }),
        )
      }
      secondaryAction={<CollectionListItemMenu />}
    >
      <IdsListItemChild>
        <ListItemText
          primary={node.title}
          primaryTypographyProps={{ variant: 'h6' }}
          secondary={`${node.creator.firstName} ${node.creator.lastName}`}
        />
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default CollectionListItem;
