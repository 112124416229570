import { useCallback } from 'react';

import {
  ImageType,
  IMetadataType,
  MediaMetadataType,
  RoutePointType,
} from '../../../../constants/media';
import { IIdsLayerProps } from '../../layers/IdsLayer';

import useMapMarkerPlacementTool, {
  useMapMarkerPlacementToolManager,
} from '../../../../hooks/useMapMarkerPlacementTool';
import {
  IMapMarkerPlacementToolState,
  IMapMarkerPlacementToolStateUpdate,
} from '../../../../hooks/useMapMarkerPlacementTool';

import { isLatitudeValid, isLongitudeValid } from '../../../../utils/geospatial';

import useMarkerTypeLayerBuilders from '../hooks/useMarkerTypeLayerBuilders';

const IMAGE_MARKER_CREATION_TOOL_ID = 'image-marker-creator';

export interface IUserImageMarkerCreationTool {
  metadataTypes: IMetadataType[];
}

/**
 * This hook should be used to manage the image marker editor tool.
 * Must be used in tandem with the ImageMarkerEditor component below.
 */
export const useImageMarkerCreationTool = ({ metadataTypes }: IUserImageMarkerCreationTool) => {
  const { startMarkerPlacement, updateMarkerPlacement, enabled, finishMarkerPlacement } =
    useMapMarkerPlacementToolManager({
      toolId: IMAGE_MARKER_CREATION_TOOL_ID,
    });

  const imageTypeLayerBuilders = useMarkerTypeLayerBuilders(metadataTypes);

  const startImageMarkerCreation = useCallback(
    (
      imageType: ImageType | RoutePointType,
      onMarkerMoved: IMapMarkerPlacementToolState['onMarkerMoved'],
      onMarkerRender: IMapMarkerPlacementToolState['onMarkerRender'],
      levelId: string,
      initLatitude?: number,
      initLongitude?: number,
      frozen?: boolean,
    ) => {
      if (!imageType) return;

      // Build a special instance of the image marker layer to avoid
      // bogging down actual layer with frequent updates while editing
      const buildMarkerLayer = (
        latitude: number,
        longitude: number,
        iconClusterProps: Record<string, any>,
        idsLayerProps: IIdsLayerProps,
      ) => {
        const id = 'new-image-id';

        const data =
          isLatitudeValid(latitude) && isLongitudeValid(longitude)
            ? [
                {
                  node: {
                    id,
                    position: {
                      longitude,
                      latitude,
                    },
                    // Level metadata is needed to properly render a pano marker
                    metadata: [
                      {
                        id: levelId,
                        type: MediaMetadataType.Level,
                      },
                    ],
                  },
                },
              ]
            : [];
        const layerBuilder = imageTypeLayerBuilders[imageType];
        const fullIdsLayerProps = {
          ...idsLayerProps,
          metadata: {
            // Render markers in highlighted state
            highlightedMediaIds: [id],
          },
        };
        return layerBuilder(data, iconClusterProps, fullIdsLayerProps);
      };

      startMarkerPlacement(
        buildMarkerLayer,
        null,
        onMarkerMoved,
        onMarkerRender,
        initLatitude,
        initLongitude,
        frozen,
      );
    },
    [startMarkerPlacement, imageTypeLayerBuilders],
  );

  const updateToolState = useCallback(
    (
      latitude?: IMapMarkerPlacementToolStateUpdate['initLatitude'],
      longitude?: IMapMarkerPlacementToolStateUpdate['initLongitude'],
      frozen?: IMapMarkerPlacementToolStateUpdate['frozen'],
      onMarkerMoved?: IMapMarkerPlacementToolStateUpdate['onMarkerMoved'],
      onMarkerRender?: IMapMarkerPlacementToolStateUpdate['onMarkerRender'],
    ) => {
      const latValid = latitude === null || isLatitudeValid(latitude);
      const longValid = longitude === null || isLongitudeValid(longitude);

      updateMarkerPlacement({
        ...(latValid && { initLatitude: latitude }),
        ...(longValid && { initLongitude: longitude }),
        frozen,
        ...(onMarkerMoved && { onMarkerMoved }),
        ...(onMarkerRender && { onMarkerRender }),
      });
    },
    [updateMarkerPlacement],
  );

  return {
    startImageMarkerCreation,
    updateToolState,
    finishMarkerPlacement,
    enabled,
  };
};

/**
 * Consumer of `useMapMarkerPlacementTool` to enable the imager marker creator.
 * Consumed in an empty component to prevent frequent internal hook state
 * changes from bogging down rendering of components that use the tool.
 */
const ImageMarkerCreationTool = () => {
  useMapMarkerPlacementTool({ toolId: IMAGE_MARKER_CREATION_TOOL_ID });
  return null;
};

export default ImageMarkerCreationTool;
