// Translate filter mode to a string id for concise representation in the url
export const filterModesToId = (filterModes: Record<any, any>) => {
  return Object.values(filterModes).reduce((modeIds, mode, index) => {
    modeIds[mode] = `${index + 1}`;
    return modeIds;
  }, {});
};

export const idToFilterMode = (filterModesId: Record<any, any>) => {
  return Object.entries(filterModesId).reduce((idModes: Record<any, any>, [key, value]) => {
    idModes[value] = key;
    return idModes;
  }, {});
};
