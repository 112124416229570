import { QueryKey } from 'react-query';
import { RequestDocument } from 'graphql-request';

import { useMemo } from 'react';

import useGraphQuery, { UseGraphQueryOptions } from './useGraphQuery';
import useGraphId from './useGraphId';

/**
 * Do not pass tenantId or orgId param to graphVariables, because query ID
 * is calculated automatically based on user role.
 *
 * NOTE: Organization ID param in GQL queries must be named as orgId.
 */
const useOrgGraphQuery = <TQueryFnData = any, TError = any, TData = TQueryFnData>(
  queryKey: QueryKey,
  query: RequestDocument,
  graphVariables?: any,
  queryOptions: UseGraphQueryOptions<TQueryFnData, TError, TData> = {},
) => {
  const queryId = useGraphId();
  const modifiedGraphVariables = { ...graphVariables, ...queryId };

  return useGraphQuery<TQueryFnData, TError, TData>(
    queryKey,
    query,
    modifiedGraphVariables,
    queryOptions,
  );
};

export default useOrgGraphQuery;

export const useQueryKeyId = () => {
  const queryId = useGraphId();

  return useMemo(() => Object.entries(queryId).map(([key, value]) => `${key}-${value}`), [queryId]);
};
