import React from 'react';
import { IconButton } from '@mui/material';
import clsx from 'clsx';

import styles from '../../IdsMediaViewer.module.css';
import IdsListItemMenuItem from '../../../../ids-lists/IdsListItemMenuItem';

export interface IGenericButtonProps {
  onClick: () => void;
  className: string;
  inMenu?: boolean;
  label: React.ReactNode;
  icon: React.ReactNode;
  props: any;
}

const GenericButton: React.FC<IGenericButtonProps> = ({
  onClick,
  className,
  icon,
  props,
  label,
  inMenu,
}) => {
  return inMenu ? (
    <IdsListItemMenuItem onClick={onClick} icon={icon}>
      {label}
    </IdsListItemMenuItem>
  ) : (
    <IconButton onClick={onClick} className={clsx(styles.menuActionButton, className)} {...props}>
      {icon}
    </IconButton>
  );
};

export default GenericButton;
