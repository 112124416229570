import { useCallback, useEffect, useState } from 'react';

export type DeckEventArgs = any[];

export type DeckEventCallback = (...args: any[]) => any;

// This can be used when handling a deck.gl event like onHover
// that happens outside of react's lifecycle.
// It handles bringing the event back into the react lifecycle to allow for
// reliable use of state variables.
const useDeckEventCallback = (onEvent: DeckEventCallback) => {
  const [eventArgs, setEventArgs] = useState<DeckEventArgs | null>();

  const eventHandler = useCallback(
    (...args: DeckEventArgs) => {
      setEventArgs(args);
    },
    [setEventArgs],
  );

  useEffect(() => {
    if (eventArgs) {
      if (onEvent) {
        onEvent(...eventArgs);
      }
      setEventArgs(null);
    }
  }, [setEventArgs, eventArgs, onEvent]);

  return {
    eventHandler,
  };
};

export default useDeckEventCallback;
