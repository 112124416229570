import React, { useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, IconButton, IconButtonProps, Menu, MenuProps } from '@mui/material';

import { MoreVerticalIcon } from '../../../theme/icons';
import usePrevious from '../../../hooks/usePrevious';

export interface IIdsListItemMenuProps extends Omit<MenuProps, 'open'> {
  open?: boolean;
  containerProps?: BoxProps;
  buttonProps?: IconButtonProps;
  dataAttribute?: any;
}

const anchorOrigin: MenuProps['anchorOrigin'] = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin: MenuProps['transformOrigin'] = {
  vertical: 'top',
  horizontal: 'right',
};

const IdsListItemMenu: React.FC<IIdsListItemMenuProps> = ({
  containerProps,
  buttonProps,
  children,
  dataAttribute,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleOpen = useCallback(
    event => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(
    event => {
      event.stopPropagation();

      /**
       * This condition allows parent component to keep Menu open.
       */
      if (!rest.open) {
        setAnchorEl(null);
      }
    },
    [setAnchorEl, rest.open],
  );

  const prevRestOpen = usePrevious(rest.open);
  useEffect(() => {
    if (anchorEl && !rest.open && prevRestOpen) {
      setAnchorEl(null);
    }
  }, [rest.open, anchorEl, prevRestOpen]);

  return (
    <Box {...containerProps}>
      <IconButton {...buttonProps} onClick={handleOpen}>
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...rest}
        open={open}
      >
        <Box onClick={handleClose}>{children}</Box>
      </Menu>
    </Box>
  );
};

export default IdsListItemMenu;
