import {
  IMediaMetadata,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  MediaType,
  RoutePointType,
} from '../../constants/media';

interface IFilterTargetItemProps {
  node: {
    metadata: IMediaMetadata[];
  };
}

export const getCommonMetadataFilterTargets = (metadataType: MediaMetadataType) => {
  // Account for metadata possibly being undefined for image types where reps and the image details are fetched separately in map view
  const selectFilterData = (item: IFilterTargetItemProps) =>
    item.node.metadata?.find(d => d.type === MEDIA_METADATA_TYPES[metadataType].type);

  return [
    { type: MediaType.ProjectPhoto, selectFilterData },
    { type: MediaType.HDPhoto, selectFilterData },
    { type: MediaType.PanoramicPhoto, selectFilterData },
    { type: RoutePointType.ProjectPhoto, selectFilterData },
    { type: RoutePointType.DslrHdPhoto, selectFilterData },
    { type: RoutePointType.Panorama, selectFilterData },
    { type: RoutePointType.DslrPanorama, selectFilterData },
  ];
};
