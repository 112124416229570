import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeTenantState } from '../../../../atoms/tenants';
import { activeImageId } from '../../../../atoms/mediaViewer';
import PublishedFilter from '../../../../components/ids-filters/PublishedFilter';
import TagsFilter from '../../../../components/ids-filters/TagsFilter';
import { useDefaultEventHandlers } from '../../../../components/ids-media-viewer/IdsMediaViewer/hooks';
import { USER_ROLES } from '../../../../constants/users';
import useMetadataItems from '../../../../hooks/useMetadataItems';
import { activeLocationState } from '../../../../atoms/locations';
import MediaTypeFilter from '../../../../components/ids-filters/MediaTypeFilter';
import {
  DISPLAY_TYPES,
  NO_METADATA_HANDLERS,
} from '../../../../components/ids-filters/MediaMetadataFilter';
import LevelFilter from '../../../../components/ids-filters/LevelFilter';
import AreaFilter from '../../../../components/ids-filters/AreaFilter';
import TypeFilter from '../../../../components/ids-filters/TypeFilter';
import CategoryFilter from '../../../../components/ids-filters/CategoryFilter';
import FlagFilter from '../../../../components/ids-filters/FlagFilter';
import CustomFieldMetadataFilter from '../../../../components/ids-filters/CustomFieldMetadataFilter';
import useCustomFieldFilterVisibility from '../../../../hooks/filtering/useCustomFieldFilterVisibility';
import IdsMediaListView from '../../../../components/ids-lists/IdsMediaListView';
import BulkLocationImageEditDialog from '../../../../components/ids-forms/BulkLocationImageEditDialog';
import FlaggableImage from '../../../../components/FlaggableImage';
import IdsMediaViewer, {
  MEDIA_VIEWER_WIDTHS,
  MEDIA_VIEWER_TABS,
} from '../../../../components/ids-media-viewer/IdsMediaViewer';
import { MEDIA_VIEWER_MODES } from '../../../../constants/mediaViewer';
import {
  dateAscendingSorter,
  dateDescendingSorter,
  typeSorter,
  getMetadataTypeSorter,
  getCustomFieldMetadataTypeSorter,
} from '../../../../components/ids-lists/IdsMediaListView/commonSorters';

import { MediaType, MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';

import { FilterProvider } from '../../../../context/FilterContext';
import useFilterContext from '../../../../hooks/useFilterContext';
import { getDefaultTypeMetadata } from '../../../../constants/filtering';
import usePermissions from '../../../../hooks/usePermissions';
import invalidateQueriesContainingKey from '../../../../utils/invalidateQueriesContainingKey';
import { useOnGalleryPageLoaded } from '../../../../utils/mediaViews';
import { useLocationImageBulkEdit } from '../../../../components/ids-lists/IdsMediaListView/commonBulkActions/useLocationImageBulkEdit';

import styles from './LocationMediaTab.module.css';

import LocationMediaTabActions from './LocationMediaTabActions';
import LocationMediaTabFilterInitializers from './LocationMediaTabFilterInitializers';
import {
  LOCATION_MEDIA_TAB_PHOTOS_KEY,
  useHDPhotos,
  useHDPhotosReps,
  usePanoramas,
  usePanoramasReps,
  useProjectPhotos,
  useProjectPhotosReps,
} from './helpers';

const getMediaThumbnailUrl = m => m.thumbnail;
const getMediaType = m => m.type;
const getMediaId = m => m.node.id;

export const MediaTabFrag = gql`
  fragment MediaTabFrag on Location {
    metadataTypes {
      type
      values {
        id
        name
      }
    }
    assignableCustomFields {
      id
      label
      type
      values {
        id
        name
      }
      targets
    }
  }
`;

const LocationMediaTab = ({ location }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const [activeId, setActiveId] = useRecoilState(activeImageId);
  const activeLocation = useRecoilValue(activeLocationState);

  const [isReFetchingEnabled, setIsReFetchingEnabled] = useState(false);
  const [mediaCount, setMediaCount] = useState(0);

  const [mediaViewerOpen, setMediaViewerOpen] = useState(false);

  const [activeSorterKey, setActiveSorterKey] = useState();
  const [imageSize, setImageSize] = useState();

  const { useTypeFilteredData } = useFilterContext();
  const filteredProjectPhotos = useTypeFilteredData(MediaType.ProjectPhoto);
  const filteredHdPhotos = useTypeFilteredData(MediaType.HDPhoto);
  const filteredPanoramas = useTypeFilteredData(MediaType.PanoramicPhoto);

  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(
    location?.metadataTypes,
  );

  const customFieldTypes = useMemo(
    () => location?.assignableCustomFields?.map(acf => acf.type) || [],
    [location?.assignableCustomFields],
  );
  const { handleVisibilityChange, isFilterVisible } =
    useCustomFieldFilterVisibility(customFieldTypes);

  const { bulkEditAction, bulkEditOpen, closeBulkEdit, bulkEditImageUrns, imageTypesBulkEditing } =
    useLocationImageBulkEdit();

  // DATA LOADING ===============================================

  const { onPageLoaded, onRepsLoaded } = useOnGalleryPageLoaded();

  const handleProjectPhotosPage = useCallback(
    edges => {
      onPageLoaded(edges, MediaType.ProjectPhoto, setMediaCount);
    },
    [onPageLoaded],
  );

  const handleProjectPhotosReps = useCallback(
    edges => {
      onRepsLoaded(edges, MediaType.ProjectPhoto);
    },
    [onRepsLoaded],
  );

  const handleHdPhotosPage = useCallback(
    edges => {
      onPageLoaded(edges, MediaType.HDPhoto, setMediaCount);
    },
    [onPageLoaded],
  );

  const handleHdPhotosReps = useCallback(
    edges => {
      onRepsLoaded(edges, MediaType.HDPhoto);
    },
    [onRepsLoaded],
  );

  const handlePanoramasPage = useCallback(
    edges => {
      onPageLoaded(edges, MediaType.PanoramicPhoto, setMediaCount);
    },
    [onPageLoaded],
  );

  const handlePanoramasReps = useCallback(
    edges => {
      onRepsLoaded(edges, MediaType.PanoramicPhoto);
    },
    [onRepsLoaded],
  );

  const { isLoading: projectPhotosLoading } = useProjectPhotos(
    activeLocation.id,
    handleProjectPhotosPage,
  );
  const { isLoading: hdPhotosLoading } = useHDPhotos(activeLocation.id, handleHdPhotosPage);
  const { isLoading: panoramasLoading } = usePanoramas(activeLocation.id, handlePanoramasPage);

  useProjectPhotosReps(activeLocation.id, isReFetchingEnabled, handleProjectPhotosReps);
  useHDPhotosReps(activeLocation.id, isReFetchingEnabled, handleHdPhotosReps);
  usePanoramasReps(activeLocation.id, isReFetchingEnabled, handlePanoramasReps);

  const onUploadCompleted = useCallback(async () => {
    setIsReFetchingEnabled(false);

    await invalidateQueriesContainingKey([LOCATION_MEDIA_TAB_PHOTOS_KEY], {
      refetchActive: true,
    });
  }, []);

  useEffect(() => {
    /**
     * Enable re-fetching after the initial data load.
     */
    if (
      (projectPhotosLoading || panoramasLoading || hdPhotosLoading) === false &&
      isReFetchingEnabled === false
    ) {
      setIsReFetchingEnabled(true);
    }
  }, [hdPhotosLoading, isReFetchingEnabled, panoramasLoading, projectPhotosLoading]);

  // BASE FILTER DATA INITIALIZATIONS ==============================

  const allFilteredMedia = useMemo(
    () => [...filteredProjectPhotos, ...filteredHdPhotos, ...filteredPanoramas],
    [filteredProjectPhotos, filteredHdPhotos, filteredPanoramas],
  );

  const sorters = useMemo(() => {
    const _sorters = [
      dateAscendingSorter,
      dateDescendingSorter,
      typeSorter,
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.Level], allLevels),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.Area], allAreas),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType], allTypes),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory], allCategories),
      ...(location?.assignableCustomFields
        ?.filter(acf => isFilterVisible(acf.type))
        .map(acf => getCustomFieldMetadataTypeSorter(acf)) || []),
    ];
    return _sorters.map(s => {
      if (!activeSorterKey) return s; // Active sorter has not been set
      // Override default setting to maintain state when closed
      return { ...s, isDefault: s.key === activeSorterKey };
    });
  }, [
    activeSorterKey,
    allLevels,
    allAreas,
    allTypes,
    allCategories,
    location?.assignableCustomFields,
    isFilterVisible,
  ]);

  const { userHasOneOfRoles } = usePermissions();
  const canUsePublishedFilter = useMemo(
    () =>
      userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ]),
    [userHasOneOfRoles],
  );

  // Filters are rendered in the array order
  const filters = useMemo(
    () => [
      {
        // Flag filter has a predefined width, filterField style is not needed
        renderFilter: () => <FlagFilter resetOnUnmount={true} />,
        filterExternally: true,
      },
      {
        renderFilter: () => <MediaTypeFilter className={styles.filterField} />,
        filterExternally: true,
      },
      ...(canUsePublishedFilter
        ? [
            {
              renderFilter: () => <PublishedFilter resetOnUnmount={true} />,
              filterExternally: true,
            },
          ]
        : []),
      {
        renderFilter: () => (
          <LevelFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allLevels}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <AreaFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allAreas}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <TypeFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allTypes}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <CategoryFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allCategories}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <TagsFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allTags}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      ...(location?.assignableCustomFields?.map(assignableCustomField => ({
        renderFilter: () => (
          <CustomFieldMetadataFilter
            assignableCustomField={assignableCustomField}
            onVisibilityChange={handleVisibilityChange}
            className={styles.filterField}
          />
        ),
        disableWrapper: !isFilterVisible(assignableCustomField.type),
        filterExternally: true,
      })) || []),
    ],
    [
      allLevels,
      allAreas,
      allTypes,
      allCategories,
      allTags,
      location?.assignableCustomFields,
      isFilterVisible,
      handleVisibilityChange,
      canUsePublishedFilter,
    ],
  );

  const viewImage = useCallback(
    id => {
      setActiveId(id);
      setMediaViewerOpen(true);
    },
    [setActiveId, setMediaViewerOpen],
  );

  const closeMediaViewer = useCallback(() => {
    setActiveId(null);
    setMediaViewerOpen(false);
  }, [setActiveId, setMediaViewerOpen]);

  const renderImage = useCallback(
    (image, props) => (
      <FlaggableImage flagged={image.node.flagged} autoScaleCornerIcons={true} {...props} />
    ),
    [],
  );

  const onImageClick = useCallback(img => viewImage(img.node.id), [viewImage]);

  const { onDelete, onTagsUpdate, onFlagUpdate, onImageInfoUpdate } = useDefaultEventHandlers();

  return (
    <>
      <LocationMediaTabFilterInitializers location={location} />
      <IdsMediaListView
        media={allFilteredMedia}
        noMediaSeverity={mediaCount ? 'warning' : 'info'}
        noMediaMessage={mediaCount ? 'No media for current filters' : 'This location has no media'}
        getMediaThumbnailUrl={getMediaThumbnailUrl}
        getMediaType={getMediaType}
        getMediaId={getMediaId}
        renderImage={renderImage}
        filters={filters}
        sorters={sorters}
        onSortByChange={setActiveSorterKey}
        defaultImageSize={imageSize}
        onImageSizeChange={setImageSize}
        loading={projectPhotosLoading || panoramasLoading || hdPhotosLoading}
        onImageClick={onImageClick}
        actions={
          <LocationMediaTabActions
            onUploadCompleted={onUploadCompleted}
            hdPhotosLoading={hdPhotosLoading}
            panoramasLoading={panoramasLoading}
          />
        }
        bulkActions={[bulkEditAction]}
      />
      {activeId && (
        <IdsMediaViewer
          onDelete={onDelete}
          onFlagUpdate={onFlagUpdate}
          onImageInfoUpdate={onImageInfoUpdate}
          onTagsUpdate={onTagsUpdate}
          activeMediaId={activeId}
          initialWidth={MEDIA_VIEWER_WIDTHS.THREEQUARTER}
          fullscreen={false}
          tabs={[MEDIA_VIEWER_TABS.INFO]}
          selectMediaItemData={item => item.node}
          onActiveMediaChange={viewImage}
          onClose={closeMediaViewer}
          open={mediaViewerOpen}
          hideClose={false}
          loadAllThumbnails={false}
          loadActiveImageDetail={true}
          media={allFilteredMedia}
          mode={MEDIA_VIEWER_MODES.GALLERY}
          modal={true}
          allImageTags={allTags}
          tenantId={activeTenant.id}
          orgId={activeOrg.id}
        />
      )}
      <BulkLocationImageEditDialog
        open={bulkEditOpen}
        onClose={closeBulkEdit}
        locationId={activeLocation.id}
        imageUrns={bulkEditImageUrns}
        allImages={allFilteredMedia}
        imageTypesEditing={imageTypesBulkEditing}
        metadataTypes={location?.metadataTypes}
      />
    </>
  );
};

const types = [MediaType.ProjectPhoto, MediaType.HDPhoto, MediaType.PanoramicPhoto];
const defaultTypeMetadata = getDefaultTypeMetadata(types);

const LocationMediaTabWithContext = props => (
  <FilterProvider types={types} defaultTypeMetadata={defaultTypeMetadata}>
    <LocationMediaTab {...props} />
  </FilterProvider>
);

export default LocationMediaTabWithContext;
