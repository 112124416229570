import React from 'react';
import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';

export interface IIdsSwitchProps extends SwitchProps {
  label?: FormControlLabelProps['label'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

const IdsSwitch: React.FC<IIdsSwitchProps> = ({ label, labelPlacement = 'end', ...rest }) => (
  <FormControlLabel
    control={<Switch color='primary' {...rest} />}
    label={label}
    labelPlacement={labelPlacement}
  />
);

export default IdsSwitch;
