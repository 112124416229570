import React, { createContext, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Uppy, { UppyFile, UploadResult, Restrictions } from '@uppy/core';
import GoldenRetriever from '@uppy/golden-retriever';
import ImageEditor, { ImageEditorOptions } from '@uppy/image-editor';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DashboardProps } from '@uppy/react/src/Dashboard';
import { Alert, SelectChangeEvent } from '@mui/material';
import { useSnackbar } from 'notistack';

import IdsForm, { IIdsFormProps } from '../../../ids-forms/IdsForm';
import useIdsUploaderContext from '../../../../hooks/useIdsUploaderContext';
import IdsSelect, { ISelectOption } from '../../IdsSelect';
import {
  UploadType,
  IBaseUploaderProps,
  IIdsUploaderFormProps,
  IUploaderValue,
} from '../../../../constants/uploads';
import { UPLOADERS } from '../../../../constants/uploaders';
import useUppyEventHandler from '../../../../hooks/useUppyEventHandler';
import usePrevious from '../../../../hooks/usePrevious';
import usePermissions from '../../../../hooks/usePermissions';
import useUploadQueueContext from '../../../../hooks/useUploadQueueContext';
import { createUppy, getOrCreateUppy } from '../../../../utils/uppyInstanceManager';
import useMountedEffect from '../../../../hooks/useMountedEffect';
import { doesUppyFitRestrictions } from '../../../../utils/uppy';

import IdsUploaderSteps, { IIdsUploaderStepsProps } from './IdsUploaderSteps';
import styles from './IdsUploader.module.css';
import './style-overrides.css';

import '@uppy/image-editor/dist/style.min.css';

export const getGoldenRetrieverId = (uppyId: string) => `${uppyId}-GoldenRetriever`;
export const getImageEditorId = (uppyId: string) => `${uppyId}-ImageEditor`;

const defaultRestrictions: Restrictions = {
  maxFileSize: Number.POSITIVE_INFINITY,
  minFileSize: 0,
  maxTotalFileSize: Number.POSITIVE_INFINITY,
  maxNumberOfFiles: Number.POSITIVE_INFINITY,
  minNumberOfFiles: 0,
  allowedFileTypes: undefined,
};

const defaultImageEditorOptions: ImageEditorOptions = {
  quality: 1,
};

/** id is a unique id representing the consumer component to allow for management of queued uploads. */
export const useIdsUploaderUppy = (id: string) => {
  const [, setCounter] = useState(0);

  const handleUploadQueued = useCallback(() => {
    createUppy(id, {
      meta: {
        configuredUploadType: UploadType.None,
      },
    });

    // Force rerender with new uppy
    setCounter(prev => prev + 1);
  }, [id]);

  return {
    uppy: getOrCreateUppy(id),
    onUploadQueued: handleUploadQueued,
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISupportedUploaders extends PartialRecord<UploadType, IBaseUploaderProps> {}

export interface IIdsUploaderProps extends Omit<DashboardProps, 'uppy'> {
  fileSelectionStepLabel: string;
  supportedUploaders: ISupportedUploaders;
  uppy: Uppy;
  onUploadStarted?: () => void;
  onUploadCompleted?: () => void;
  onUploadQueued?: () => void;
  /** If true, the submission event propagation will be stopped to prevent submitting parent form as well. */
  stopSubmitPropagation?: boolean;
}

export interface IUploaderOperator extends IIdsUploaderStepsProps {
  formProps: IIdsUploaderFormProps;
  onFormSubmit?: (values: any) => void;
  /** Called when an upload is started for an upload that is not queued. */
  onUploadStarted?: () => void;
  /** Called when an upload is completed for an upload that is queued. */
  onUploadCompleted?: () => void;
  onUploadQueued: () => void;
}

export interface IIdsUploaderContextType {
  uppy: Uppy;
  fileIds: string[];
  uploadType: UploadType;
  validationSchema?: IIdsFormProps['validationSchema'];
  setValidationSchema: React.Dispatch<React.SetStateAction<IIdsFormProps['validationSchema']>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFailed: boolean;
  setUploadFailed: React.Dispatch<React.SetStateAction<boolean>>;
  queued: boolean;
  activeStep: number;
  setActiveStep: (newActiveStep: number) => void;
}

export const IdsUploaderContext = createContext<IIdsUploaderContextType | null>(null);

const UploaderOperator: React.FC<IUploaderOperator> = ({
  uploadSteps,
  formProps,
  fieldsToReinitialize,
  onFormValuesChange,
  onFormSubmit,
  onUploadStarted,
  onUploadCompleted,
  onUploadQueued,
  children,
  ...rest
}) => {
  const {
    uppy,
    uploadType,
    activeStep,
    setActiveStep,
    setUploading,
    uploadFailed,
    setUploadFailed,
    setValidationSchema,
    validationSchema,
  } = useIdsUploaderContext();
  const { enqueueUpload } = useUploadQueueContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setValidationSchema((activeStep > 0 && uploadSteps[activeStep - 1].validationSchema) || null);
  }, [activeStep, uploadSteps, setValidationSchema]);

  const handleSubmit = useCallback(
    async (values: any) => {
      if (!uppy) {
        throw new Error('Uppy not set');
      }

      if (onFormSubmit) {
        onFormSubmit(values);
      }

      const { queueUpload, goldenRetrieverOptions } = UPLOADERS[uploadType];

      if (queueUpload) {
        const goldenRetrieverId = getGoldenRetrieverId(uppy.getID());

        if (!uppy.getPlugin(goldenRetrieverId)) {
          // not already installed
          uppy.use(GoldenRetriever, {
            id: goldenRetrieverId,
            ...goldenRetrieverOptions,
          });
        }

        const eventCallbacks =
          onUploadStarted || onUploadCompleted
            ? {
                ...(onUploadStarted && { upload: onUploadStarted }),
                ...(onUploadCompleted && { complete: onUploadCompleted }),
              }
            : undefined;

        enqueueUpload(uppy, uploadType, eventCallbacks);
        onUploadQueued();
        enqueueSnackbar('Upload queued', { variant: 'info' }); // TODO: show button to view in the queue
        return;
      }

      setUploading(true);

      if (onUploadStarted) {
        onUploadStarted();
      }

      let result: UploadResult<Record<string, unknown>, Record<string, unknown>>;

      if (uploadFailed) {
        result = await uppy.retryAll();
      } else {
        result = await uppy.upload();
      }

      if (onUploadCompleted) {
        onUploadCompleted();
      }
      setUploading(false);

      uppy.resetProgress();

      if (!result) return false; // Indicate submission was canceled for IdsForm

      if (result.successful.length === uppy.getFiles().length) {
        // Reset form
        setActiveStep(0);
        uppy.getFiles().forEach(file => uppy.removeFile(file.id));
        setUploadFailed(false);
      } else {
        setUploadFailed(true);

        const responseMsg: any = result.failed.length && result.failed[0].response?.body?.message;
        const errorMsg = responseMsg?.length
          ? `Upload failed, ${
              Array.isArray(responseMsg)
                ? responseMsg[0].toLowerCase() // message is in array
                : responseMsg.toLowerCase()
            }` // message is a string
          : 'Upload failed';
        throw new Error(errorMsg);
      }
    },
    [
      uppy,
      uploadType,
      setUploading,
      uploadFailed,
      setUploadFailed,
      onFormSubmit,
      enqueueUpload,
      enqueueSnackbar,
      onUploadStarted,
      onUploadCompleted,
      onUploadQueued,
      setActiveStep,
    ],
  );

  return (
    <IdsForm
      {...formProps}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      enableReinitialize
      className={styles.fullHeight}
    >
      <IdsUploaderSteps
        uploadSteps={uploadSteps}
        fieldsToReinitialize={fieldsToReinitialize}
        onFormValuesChange={onFormValuesChange}
        {...rest}
      >
        {children}
      </IdsUploaderSteps>
    </IdsForm>
  );
};

export interface IUploaderListener {
  uploadType: UploadType;
  uploaderProps: IBaseUploaderProps;
  enabled: boolean;
  onChange: (value: IUploaderValue) => void;
}

const UploaderListener: React.FC<IUploaderListener> = ({
  uploadType,
  uploaderProps,
  enabled,
  onChange,
}) => {
  const value = UPLOADERS[uploadType].useUploader(enabled, uploaderProps);
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (!enabled || !value || value === prevValue) return;

    onChange(value);
  }, [enabled, value, prevValue, onChange]);

  return null;
};

const getAvailableUploadTypes = (
  uppy: Uppy,
  supportedUploaders: ISupportedUploaders,
  userHasPermission: () => boolean,
  userHasOneOfRoles: () => boolean,
) => {
  const supportedTypes = Object.keys(supportedUploaders) as UploadType[];
  return supportedTypes.filter(
    (uploadType: UploadType) =>
      doesUppyFitRestrictions(
        uppy,
        UPLOADERS[uploadType].uppyRestrictions,
        uppy.getFiles().length === 0, // ignore min restrictions if no files are added
      ) &&
      UPLOADERS[uploadType].isAvailable(
        uppy,
        userHasPermission,
        userHasOneOfRoles,
        supportedUploaders[uploadType] || {},
      ),
  );
};

interface IConfigurationState {
  uppyId: string;
  uploadType: UploadType;
}

const IdsUploader: React.FC<IIdsUploaderProps> = ({
  uppy,
  supportedUploaders,
  onUploadStarted,
  onUploadCompleted,
  onUploadQueued,
  stopSubmitPropagation,
  ...rest
}) => {
  const prevUppy = usePrevious(uppy);
  const [activeStep, _setActiveStep] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const scrollContainerRef = useRef<HTMLElement>();
  const [validationSchema, setValidationSchema] = useState<IIdsFormProps['validationSchema']>();
  const { userHasPermission, userHasOneOfRoles } = usePermissions();
  const [fileIds, setFileIds] = useState<string[]>(uppy.getFiles().map(f => f.id) || []);
  const [availableUploadTypes, setAvailableUploadTypes] = useState<UploadType[]>(
    uppy
      ? getAvailableUploadTypes(uppy, supportedUploaders, userHasPermission, userHasOneOfRoles)
      : [],
  );
  const [uploadType, setUploadType] = useState<UploadType>(
    availableUploadTypes.length === 1 ? availableUploadTypes[0] : UploadType.None,
  );
  const [uploaderValue, setUploaderValue] = useState<IUploaderValue | null>(null);
  const [configurationState, setConfigurationState] = useState<IConfigurationState | null>();

  const handleScrollContainer = useCallback((container: HTMLElement) => {
    scrollContainerRef.current = container;
  }, []);

  const setActiveStep = useCallback(
    (newActiveStep: number) => {
      _setActiveStep(newActiveStep);
      if (scrollContainerRef.current) {
        // reset scroll when step changes
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [_setActiveStep],
  );

  const uploaderOptions: ISelectOption[] = useMemo(
    () =>
      availableUploadTypes.map(uploadType => ({
        label: UPLOADERS[uploadType].label,
        value: uploadType,
      })),
    [availableUploadTypes],
  );

  const updateAvailableUploadTypes = useCallback(() => {
    if (!uppy) return;

    const newAvailableUploadTypes = getAvailableUploadTypes(
      uppy,
      supportedUploaders,
      userHasPermission,
      userHasOneOfRoles,
    );

    // Clear selected upload type if no longer available
    if (!newAvailableUploadTypes.includes(uploadType)) {
      if (newAvailableUploadTypes.length === 1) {
        setUploadType(newAvailableUploadTypes[0]); // Auto select upload type when there is only 1 available
      } else if (uploadType !== UploadType.None) setUploadType(UploadType.None);
    }

    setAvailableUploadTypes(newAvailableUploadTypes);
  }, [uppy, supportedUploaders, userHasPermission, userHasOneOfRoles, uploadType, setUploadType]);

  useEffect(() => {
    if (uppy !== prevUppy) {
      updateAvailableUploadTypes();

      const imageEditorId = getImageEditorId(uppy.getID());

      if (!uppy.getPlugin(imageEditorId)) {
        // image editor is not already installed
        uppy.use(ImageEditor, {
          id: imageEditorId,
          ...defaultImageEditorOptions,
        });
      }
    }
  }, [prevUppy, uppy, updateAvailableUploadTypes]);

  const handleFilesAdded = useCallback(
    (files: UppyFile[]) => {
      setFileIds(prev => [...prev, ...files.map(file => file.id)]);

      updateAvailableUploadTypes();
    },
    [updateAvailableUploadTypes],
  );

  useUppyEventHandler('files-added', handleFilesAdded, uppy);

  const handleFileRemoved = useCallback(
    (file: UppyFile) => {
      setFileIds(prevIds => {
        const index = prevIds.indexOf(file.id);
        if (index === -1) {
          return prevIds;
        }

        // Remove file id from fileIds
        const newIds = [...prevIds];
        newIds.splice(index, 1);
        return newIds;
      });

      updateAvailableUploadTypes();
    },
    [updateAvailableUploadTypes],
  );

  useUppyEventHandler('file-removed', handleFileRemoved, uppy);

  const handleUploadTypeChange = useCallback((e: SelectChangeEvent<unknown>) => {
    const newUploadType = e.target.value as UploadType;
    setUploadType(newUploadType);
  }, []);

  useEffect(() => {
    if (!uppy) return;

    const { meta } = uppy.getState();
    const configuredUploadType = (meta.configuredUploadType as UploadType) || UploadType.None;

    if (configuredUploadType === uploadType) {
      if (!configurationState) {
        // Configuration state not yet set
        setConfigurationState({
          uppyId: uppy.getID(),
          uploadType,
        });
      }

      return; // already configured for the correct type
    }

    UPLOADERS[configuredUploadType].deconfigureUppy(uppy); // deconfigure previously configured uploader

    const { configureUppy, uppyRestrictions } = UPLOADERS[uploadType];

    configureUppy(uppy);
    uppy.setMeta({
      configuredUploadType: uploadType,
    });

    uppy.setOptions({
      // Update restrictions for new uploader, even if uploader has none, otherwise previous uploader's restrictions will apply
      restrictions: {
        ...defaultRestrictions,
        ...uppyRestrictions,
      },
    });

    setConfigurationState({
      uppyId: uppy.getID(),
      uploadType,
    });
  }, [uploadType, uppy, configurationState]);

  const handleUploadQueued = useCallback(() => {
    // Reset uploader to allow for another upload to be started
    if (onUploadQueued) {
      onUploadQueued();
    }
    setActiveStep(0);
    setFileIds([]);
  }, [onUploadQueued, setActiveStep]);

  useEffect(() => {
    // Only set general restrictions if an uploader is not selected
    if (uploadType !== UploadType.None) return;

    const restrictions: Restrictions = { ...defaultRestrictions };

    const supportedUploadTypes = Object.keys(supportedUploaders) as UploadType[];

    // Merge restrictions from all uploaders to set least strict restrictions
    supportedUploadTypes.forEach(uploadType => {
      const uploader = UPLOADERS[uploadType];

      if (uploader.uppyRestrictions) {
        const {
          maxFileSize,
          minFileSize,
          maxTotalFileSize,
          maxNumberOfFiles,
          minNumberOfFiles,
          allowedFileTypes,
        } = uploader.uppyRestrictions;

        if (maxFileSize && restrictions.maxFileSize! < maxFileSize) {
          restrictions.maxFileSize = maxFileSize;
        }

        if (minFileSize && restrictions.minFileSize! > minFileSize) {
          restrictions.minFileSize = minFileSize;
        }

        if (maxTotalFileSize && restrictions.maxTotalFileSize! < maxTotalFileSize) {
          restrictions.maxTotalFileSize = maxTotalFileSize;
        }

        if (maxNumberOfFiles && restrictions.maxNumberOfFiles! < maxNumberOfFiles) {
          restrictions.maxNumberOfFiles = maxNumberOfFiles;
        }

        if (minNumberOfFiles && restrictions.minNumberOfFiles! > minNumberOfFiles) {
          restrictions.minNumberOfFiles = minNumberOfFiles;
        }

        if (allowedFileTypes) {
          if (restrictions.allowedFileTypes) {
            restrictions.allowedFileTypes.push(
              ...allowedFileTypes.filter(
                (ft: string) => !restrictions.allowedFileTypes?.includes(ft),
              ),
            );
          } else {
            restrictions.allowedFileTypes = [...allowedFileTypes]; // clone the array to prevent modifying the original
          }
        }
      }
    });

    // Set uppy restrictions
    uppy.setOptions({ restrictions });
  }, [supportedUploaders, uppy, uploadType]);

  useMountedEffect(() => {
    return () => {
      // Reset uppy on unmount if uploader does not queue uploads
      const configuredUploadType =
        (uppy.getState().meta.configuredUploadType as UploadType) || UploadType.None;

      if (!UPLOADERS[configuredUploadType].queueUpload) {
        uppy.cancelAll();
      }
    };
  });

  return (
    <IdsUploaderContext.Provider
      value={{
        uppy,
        fileIds,
        uploadType,
        validationSchema,
        setValidationSchema,
        uploading,
        setUploading,
        uploadFailed,
        setUploadFailed,
        queued: false,
        activeStep,
        setActiveStep,
      }}
    >
      {uppy && (
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          containerRef={handleScrollContainer}
          className='ids-uploader'
        >
          <UploaderListener
            uploadType={UploadType.None}
            uploaderProps={{}}
            enabled={
              uploadType === UploadType.None &&
              !!configurationState &&
              configurationState.uppyId === uppy.getID() &&
              configurationState.uploadType === UploadType.None
            }
            onChange={setUploaderValue}
          />
          {availableUploadTypes.map(t => (
            <UploaderListener
              key={t}
              uploadType={t}
              uploaderProps={supportedUploaders[t]!}
              enabled={
                uploadType === t &&
                !!configurationState &&
                configurationState.uppyId === uppy.getID() &&
                configurationState.uploadType === t
              }
              onChange={setUploaderValue}
            />
          ))}
          {uploaderValue && (
            <UploaderOperator
              key={uploadType} // For remount if upload type changes to aid in reinitialization
              uploadSteps={uploaderValue.uploadSteps}
              formProps={{
                ...uploaderValue.formProps,
                stopSubmitPropagation,
              }}
              fieldsToReinitialize={uploaderValue.fieldsToReinitialize}
              onFormValuesChange={uploaderValue.onFormValuesChange}
              onFormSubmit={uploaderValue.onFormSubmit}
              onUploadStarted={onUploadStarted}
              onUploadCompleted={onUploadCompleted}
              onUploadQueued={handleUploadQueued}
              disableSubmit={uploaderValue.disableSubmit}
              disableSubmitTooltip={uploaderValue.disableSubmitTooltip}
              {...rest}
            >
              {uploaderOptions.length > 0 ? (
                Object.keys(supportedUploaders).length > 1 && (
                  <IdsSelect
                    label='Uploader'
                    options={[
                      { label: UPLOADERS[UploadType.None].label, value: UploadType.None },
                      ...uploaderOptions,
                    ]}
                    value={uploadType}
                    onChange={handleUploadTypeChange}
                  />
                )
              ) : (
                <Alert severity='warning'>No uploader available for the selected files</Alert>
              )}
            </UploaderOperator>
          )}
        </PerfectScrollbar>
      )}
    </IdsUploaderContext.Provider>
  );
};

export const IdsUploaderConsumer = IdsUploaderContext.Consumer;

export default IdsUploader;
