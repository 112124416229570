import React from 'react';

import FilledIconButton, {
  IFilledIconButtonProps,
} from '../../components/ids-buttons/FilledIconButton';
import { ArrowLeftIcon, ArrowRightIcon } from '../../theme/icons';

import styles from './MenuIconButton.module.css';

export interface IMenuIconButtonProps extends Pick<IFilledIconButtonProps, 'onClick'> {
  expanded: boolean;
}

const MenuIconButton: React.FC<IMenuIconButtonProps> = ({ expanded, onClick }) => {
  return (
    <FilledIconButton
      onClick={onClick}
      className={expanded ? styles.menuExpanded : styles.menu}
      size='small'
    >
      {expanded ? (
        <ArrowLeftIcon className={expanded ? styles.menuIconExpanded : styles.menuIcon} />
      ) : (
        <ArrowRightIcon className={expanded ? styles.menuIconExpanded : styles.menuIcon} />
      )}
    </FilledIconButton>
  );
};

export default MenuIconButton;
