import { useCallback, useEffect, useState } from 'react';

import usePrevious from './usePrevious';

/** Hook to be used in tandem with IdsCommentsSection and react-perfect-scrollbar.
 * - Scrolls to the bottom when a comment is created
 * - Keeps scroll stuck to the bottom until manually scrolled up even with container resizing
 */
const useCommentsSectionScroller = ({
  scrollbarRef,
  scrollContainerRef,
  entityId,
  autoFocusCommentInput,
}) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const prevEntityId = usePrevious(entityId);

  useEffect(() => {
    // When the entity being commented on changes, reset the scroll state to the top
    if (prevEntityId && entityId && prevEntityId !== entityId && scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = 0;
      setScrolledToBottom(false);
    }
  }, [entityId, prevEntityId, setScrolledToBottom, scrollContainerRef]);

  useEffect(() => {
    if (autoFocusCommentInput && !scrolledToBottom) {
      setScrolledToBottom(true);
    }
  }, [autoFocusCommentInput, scrolledToBottom, setScrolledToBottom]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateScrollbar = useCallback(() => scrollbarRef.current?.updateScroll(), []);

  const onScrollToBottom = useCallback(() => {
    // Only set flag if scrollbar is visible
    if (scrollContainerRef.current.scrollHeight > scrollContainerRef.current.clientHeight) {
      setScrolledToBottom(true);
    }
  }, [setScrolledToBottom, scrollContainerRef]);

  const onScrollUp = useCallback(() => {
    setScrolledToBottom(false);
  }, [setScrolledToBottom]);

  const onScrollSync = useCallback(
    _ps => {
      _ps.update(); // perform default scroll update

      if (scrolledToBottom) {
        // Scroll to new bottom (scrollbar size changed)
        scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrolledToBottom],
  );

  const handleCommentCreated = useCallback(() => {
    setScrolledToBottom(true);
  }, [setScrolledToBottom]);

  return {
    scrollbarProps: {
      onYReachEnd: onScrollToBottom,
      onScrollUp,
      onSync: onScrollSync,
    },
    commentsSectionProps: {
      onCommentCreated: handleCommentCreated,
      onResize: updateScrollbar,
    },
  };
};

export default useCommentsSectionScroller;
