import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';

import useOverlayFilter, { getOverlayFilterName } from './useOverlayFilter';

export const getVectorOverlayFilterName = getOverlayFilterName;

const useVectorOverlayFilter = () => {
  const { enableOverlay, disableOverlay } = useOverlayFilter(FILTER_STATE_KEYS.VECTOR_OVERLAY);

  return {
    enableVectorOverlay: enableOverlay,
    disableVectorOverlay: disableOverlay,
  };
};

export default useVectorOverlayFilter;
