import React from 'react';

import { Container } from '@mui/material';

import { useRecoilState, useRecoilValue } from 'recoil';

import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import ComingSoonRoute from '../../../components/ComingSoonRoute';

import { activeOrganizationState } from '../../../atoms/organizations';
import { activeTenantState } from '../../../atoms/tenants';
import { getOrgDetailsRoute } from '../../../utils/routes';

function OrganizationEdit() {
  const activeTenant = useRecoilValue(activeTenantState);
  const [activeOrg] = useRecoilState(activeOrganizationState);

  return (
    <Page title='Edit Organization'>
      <Container maxWidth={false}>
        <NestedOrgViewHeader
          breadcrumbs={[
            {
              label: activeOrg.name,
              route: getOrgDetailsRoute({
                subdomain: activeTenant?.subdomain,
                orgId: activeOrg.id,
              }),
            },
          ]}
          currentCrumb='Edit Organization'
        />
        <ComingSoonRoute
          legacyFeatureRoute={`organizations/${activeOrg.id}/edit`}
          defaultOpen={true}
        />
      </Container>
    </Page>
  );
}

export default OrganizationEdit;
