import React, { useCallback, useMemo } from 'react';
import { Card, Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { activeTenantState } from '../../../../atoms/tenants';
import { useGetUserInTenant } from '../../../../services/TenantService';
import LoadingScreen from '../../../../components/LoadingScreen';
import IdsTabsRenderer from '../../../../components/IdsTabsRenderer';
import usePermissions from '../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../constants/users';
import ErrorPage from '../../../Error';
import globalSx from '../../../../theme/globalSx';
import {
  IUpdateUserDetailsMutationPayload,
  useUpdateUserDetails,
  useSetUserActivationState,
} from '../../../../services/UsersService';
import { IFormValues } from '../../../../features/Users/DetailsTab/UserDetailsCard/types';

import AccessTab from '../../../../features/Users/AccessTab';
import DetailsTab from '../../../../features/Users/DetailsTab';
import { getTenantUserDetailsRoute, getTenantUserEditRoute } from '../../../../utils/routes';
import { activeUserState } from '../../../../atoms/users';

export interface IDetailsProps {
  edit?: boolean;
}

const Details: React.FC<IDetailsProps> = ({ edit = false }) => {
  const { userHasOneOfRoles } = usePermissions();
  const activeTenant = useRecoilValue(activeTenantState);
  const activeUser = useRecoilValue(activeUserState);
  const navigate = useNavigate();
  const { mutateAsync: updateUserDetailsMutation } = useUpdateUserDetails();
  const { mutateAsync: setUserActivationStateMutation } = useSetUserActivationState(activeUser!.id);

  const {
    data: userInTenant,
    isLoading: isLoadingUserInTenant,
    error: errorUserInTenant,
  } = useGetUserInTenant(activeTenant!.id, activeUser!.id);

  const exitEditMode = useCallback(
    () =>
      navigate(
        getTenantUserDetailsRoute({
          subdomain: activeTenant?.subdomain,
          userId: activeUser?.id,
        }),
      ),
    [navigate, activeTenant, activeUser],
  );

  const onEditClick = useCallback(
    () =>
      navigate(
        getTenantUserEditRoute({
          subdomain: activeTenant?.subdomain,
          userId: activeUser?.id,
        }),
      ),
    [navigate, activeTenant, activeUser],
  );

  const handleEditSubmit = useCallback(
    async ({
      firstName,
      lastName,
      title,
      phone,
      multiShotEnable,
      activationState,
    }: IFormValues) => {
      const payload: IUpdateUserDetailsMutationPayload = {
        userId: activeUser!.id,
        firstName,
        lastName,
        title,
        phone,
        multiShotEnable,
      };

      const userDetailResult = await updateUserDetailsMutation(payload);

      if (userDetailResult.updateUserDetails.errors?.length) {
        throw new Error('User could not be updated');
      }

      if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN])) {
        // only ids admins can change a user's activation state
        // don't need to check for an error here, rest api call used, an error will be thrown if it fails
        await setUserActivationStateMutation({
          userId: activeUser!.id,
          activationState: activationState ? 'active' : 'inactive',
        });
      }

      exitEditMode();
    },
    [
      updateUserDetailsMutation,
      setUserActivationStateMutation,
      userHasOneOfRoles,
      activeUser,
      exitEditMode,
    ],
  );

  const tabs = useMemo(() => {
    if (!userInTenant?.userInTenant) {
      return [];
    }
    const tabs = [
      {
        key: 'details',
        label: 'Details',
        content: (
          <DetailsTab
            user={userInTenant.userInTenant}
            edit={edit}
            onCancel={exitEditMode}
            onEditClick={onEditClick}
            onEditSubmit={handleEditSubmit}
          />
        ),
      },
    ];

    if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN])) {
      tabs.push({
        key: 'access',
        label: 'Access',
        content: <AccessTab user={userInTenant?.userInTenant ?? null} />,
      });
    }

    return tabs;
  }, [userInTenant, edit, exitEditMode, onEditClick, userHasOneOfRoles, handleEditSubmit]);

  if (isLoadingUserInTenant) {
    return <LoadingScreen />;
  }

  if (errorUserInTenant || !activeUser) {
    return <ErrorPage />;
  }

  return (
    <Card sx={globalSx.pageContent}>
      <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
    </Card>
  );
};

export default Details;
