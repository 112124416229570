import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import {
  flagFilterState,
  infoPanelActivePaneState,
  infoPanelExpandedState,
} from '../../../atoms/immersiveViewer';
import { krPanoDirection, mediaViewerOpen } from '../../../atoms/mediaViewer';
import { MEDIA_TYPES } from '../../../constants/media';
import { getDefaultTypeMetadata } from '../../../constants/filtering';
import { FilterProvider } from '../../../context/FilterContext';
import { ItemFilterNotificationProvider } from '../../../context/ItemFilterNotificationContext';
import { LocationMapContextProvider } from '../../../context/LocationMapContext';
import useLocationMapContext from '../../../hooks/useLocationMapContext';
import { MapContextMenuProvider } from '../../../context/MapContextMenuContext';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';
import useMapContextMenu from '../../../hooks/useMapContextMenu';
import { useObservableItemState } from '../../../hooks/useObservableStates';
import '../../../theme/globalStyles.css';
import MapControls from '../MapControls';
import LocationMapMenu, { TOOLS_ID } from '../LocationMapMenu';
import MeasurementTool from '../tools/MeasurementTool';

import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../../theme/styles';

import styles from './LocationMap.module.css';
import LocationMapMediaGallery from './LocationMapMediaGallery';
import LocationMapMediaManager from './LocationMapMediaManager';

const FULL_HEIGHT_STYLE = { height: FULL_HEIGHT_WITHOUT_TOP_BAR };

const LocationMap = ({
  cluster,
  setClusterState,
  transitioning,
  locationDetailError,
  onClose,
  closing,
}) => {
  const { loading, location } = useLocationMapContext();
  const _mediaViewerOpen = useRecoilValue(mediaViewerOpen);
  const [mediaPanelOpen, setMediaPanelOpen] = useState(false);
  const [infoPanelExpanded, setInfoPanelExpanded] = useRecoilState(infoPanelExpandedState);
  const resetInfoPanelActivePane = useResetRecoilState(infoPanelActivePaneState);
  const resetFlagFilterState = useResetRecoilState(flagFilterState);
  const resetKrpanoDirection = useResetRecoilState(krPanoDirection);
  const { setPadding, getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useImmersiveViewer();
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const { contextMenuEnabled, setContextMenuEnabled } = useMapContextMenu();
  const [toolState, setToolState] = useObservableItemState({
    id: TOOLS_ID,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });

  const onResize = useCallback(
    width => {
      setPadding({ right: width });
    },
    [setPadding],
  );

  const { ref: sideBarRef } = useResizeDetector({
    handleHeight: false,
    onResize,
  });

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  // Perform all mount/unmount related items
  useEffect(() => {
    resetInfoPanelActivePane();
    resetKrpanoDirection();

    return function () {
      // Perform all cleanup items needed when a location closes
      resetFlagFilterState();
      setInfoPanelExpanded(true);
      setToolState({ active: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!toolState?.active && !contextMenuEnabled) {
      setContextMenuEnabled(true); // enable context menu when tools are inactive
    } else if (toolState?.active && contextMenuEnabled) {
      setContextMenuEnabled(false); // disable context menu when tools are active
    }
  }, [toolState, setContextMenuEnabled, contextMenuEnabled]);

  const toggleMediaModal = useCallback(() => {
    setMediaModalOpen(!mediaModalOpen);
  }, [setMediaModalOpen, mediaModalOpen]);

  const closeMediaModal = useCallback(() => {
    setMediaModalOpen(false);
  }, [setMediaModalOpen]);

  const mapControls = useMemo(
    () => (
      <>
        {!mediaModalOpen && (infoPanelExpanded || _mediaViewerOpen || mediaPanelOpen) && (
          <Grid
            item
            xs='auto'
            container
            direction='column'
            justifyContent='flex-end'
            style={FULL_HEIGHT_STYLE}
          >
            <Grid item xs='auto'>
              <MapControls
                className={styles['mapControls-responsive']}
                cluster={cluster}
                setClusterState={setClusterState}
              />
            </Grid>
          </Grid>
        )}
        {!infoPanelExpanded && !_mediaViewerOpen && !mediaPanelOpen && (
          <MapControls
            className={styles['mapControls-infoCollapsed']}
            cluster={cluster}
            setClusterState={setClusterState}
          />
        )}
      </>
    ),
    [mediaModalOpen, infoPanelExpanded, _mediaViewerOpen, mediaPanelOpen, cluster, setClusterState],
  );

  return (
    <>
      <Grid container direction='row' style={FULL_HEIGHT_STYLE} wrap='nowrap'>
        {!transitioning && (
          <>
            <Grid item xs style={FULL_HEIGHT_STYLE}>
              <MeasurementTool className={styles.measurementToolData} />
              {!loading && !locationDetailError && (
                <>
                  <LocationMapMenu
                    metadataTypes={location.metadataTypes}
                    assignableCustomFields={location.assignableCustomFields}
                  />
                  <LocationMapMediaGallery
                    location={location}
                    open={mediaModalOpen}
                    onClose={closeMediaModal}
                  />
                </>
              )}
            </Grid>
            {mapControls}
          </>
        )}
        {/* This needs to render even when transitioning to allow media to start loading immediately */}
        <Grid item xs='auto' style={FULL_HEIGHT_STYLE} ref={sideBarRef}>
          <LocationMapMediaManager
            cluster={cluster}
            transitioning={transitioning}
            closing={closing}
            onClose={handleClose}
            onViewAllImagery={toggleMediaModal}
            onExpandedChange={setInfoPanelExpanded}
            onMediaPanelOpenChange={setMediaPanelOpen}
          />
        </Grid>
      </Grid>
    </>
  );
};

LocationMap.defaultProps = {
  transitioning: false,
  cluster: true,
};

LocationMap.propTypes = {
  transitioning: PropTypes.bool,
  locationDetailError: PropTypes.object,
  onClose: PropTypes.func,
};

const types = Object.values(MEDIA_TYPES).map(t => t.type);
const defaultTypeMetadata = getDefaultTypeMetadata(types);

const LocationMapDetailWithContext = ({ location, loadingLocationDetail, ...rest }) => (
  <FilterProvider types={types} defaultTypeMetadata={defaultTypeMetadata}>
    <ItemFilterNotificationProvider>
      <MapContextMenuProvider>
        <LocationMapContextProvider
          location={location}
          loadingLocationDetail={loadingLocationDetail}
        >
          <LocationMap {...rest} />
        </LocationMapContextProvider>
      </MapContextMenuProvider>
    </ItemFilterNotificationProvider>
  </FilterProvider>
);

export default LocationMapDetailWithContext;
