import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../LoadingScreen';
import Error from '../../views/Error';

function QueryLoader({ useQuery, selectData, setData, render, renderLoading }) {
  const { data, error, isLoading } = useQuery();
  const [dataIsSet, setDataIsSet] = useState(false);

  useEffect(() => {
    if (data) {
      const selectedData = selectData(data);

      if (selectedData) {
        setData(selectedData);
        setDataIsSet(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return <Error />;
  }

  if (isLoading || !dataIsSet) return renderLoading();

  return render();
}

QueryLoader.defaultProps = {
  renderLoading: () => <LoadingScreen />,
};

QueryLoader.propTypes = {
  useQuery: PropTypes.func.isRequired,
  selectData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  renderLoading: PropTypes.func,
};

export default QueryLoader;
