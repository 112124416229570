import { useEffect, useRef } from 'react';

export type IntervalCallback = () => void;

const useInterval = (callback: IntervalCallback, delayMilliseconds: number) => {
  const savedCallback = useRef<IntervalCallback | undefined>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delayMilliseconds !== null) {
      const id = setInterval(tick, delayMilliseconds);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delayMilliseconds]);
};

export default useInterval;
