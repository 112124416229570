import { useFormikContext } from 'formik';

import { Box, Button } from '@mui/material';

import CancelButton from '../../ids-buttons/CancelButton';

interface IButtonProps {
  isEditMode: boolean;
  canUpdate: boolean;
  isLoading?: boolean;
  isUpdating: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Buttons: React.FC<IButtonProps> = ({
  isEditMode,
  canUpdate,
  isLoading = false,
  isUpdating,
  setEditMode,
}) => {
  const { dirty, resetForm } = useFormikContext();
  return (
    <Box display='flex' alignSelf='flex-end' gap={1}>
      {isEditMode && (
        <CancelButton
          type='button'
          onClick={() => {
            resetForm();
            setEditMode(false);
          }}
        />
      )}
      <Button
        disabled={!canUpdate || isLoading || isUpdating || (isEditMode && !dirty)}
        variant='contained'
        type={isEditMode ? 'button' : 'submit'}
        onClick={() => setEditMode(!isEditMode)}
      >
        {isEditMode ? 'Save' : 'Edit'}
      </Button>
    </Box>
  );
};
