import { ListItemText, ListItemTextProps } from '@mui/material';
import React from 'react';

import IdsListItem from '../../../ids-lists/IdsListItem';
import { IIdsListItemProps } from '../../../ids-lists/IdsListItem';

export interface ISingleValueAutocompleteOptionProps extends IIdsListItemProps {
  label: ListItemTextProps['primary'];
}

const SingleValueAutocompleteOption: React.FC<ISingleValueAutocompleteOptionProps> = ({
  label,
  ...rest
}) => (
  <IdsListItem {...rest}>
    <ListItemText primary={label} primaryTypographyProps={{ noWrap: true }} />
  </IdsListItem>
);

export default SingleValueAutocompleteOption;
