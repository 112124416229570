import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeProgramState } from '../../../../atoms/programs';

import { FILTER_MODES } from '../../../../components/ids-lists/IdsSearchableList';

import invalidateQueriesContainingKey from '../../../../utils/invalidateQueriesContainingKey';

import { useGetProgramProjects, useProgramKeys } from '../../../../services/ProgramsService';
import { useProjectKeys } from '../../../../services/ProjectService';

import ProjectListItem from '../../../Projects/ProjectListItem';
import ProjectsList from '../../../Projects/ProjectsList';

const Projects = () => {
  const activeProgram = useRecoilValue(activeProgramState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const programKeys = useProgramKeys();
  const projectKeys = useProjectKeys();

  const onDelete = useCallback(async () => {
    await invalidateQueriesContainingKey(programKeys.projects(activeProgram.id));
    await invalidateQueriesContainingKey(programKeys.detail(activeProgram.id));
    await invalidateQueriesContainingKey(projectKeys.orgProjects(activeOrg.id));
  }, [activeProgram.id, activeOrg.id, programKeys, projectKeys]);

  const { data, error, isLoading } = useGetProgramProjects(activeProgram.id);

  return (
    <ProjectsList
      projectItems={data}
      filterMode={FILTER_MODES.SELECT}
      renderProjectItem={projectItem => (
        <ProjectListItem key={projectItem.node.id} projectItem={projectItem} onDelete={onDelete} />
      )}
      loading={isLoading}
      error={error && 'Projects could not be loaded'}
    />
  );
};

export default Projects;
