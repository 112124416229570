export enum EntityType {
  Project = 'project',
  Program = 'program',
  Organization = 'organization',
  Assignment = 'assignment',
  AssignmentResponse = 'assignment_response',
  Collection = 'collection',
  Location = 'location',
  User = 'user',
  Tenant = 'tenant',
  ProjectPhoto = 'project_photo',
  HDPhoto = 'hd_photo',
  Panorama = 'panorama',
  RasterOverlay = 'raster_overlay',
  VectorOverlay = 'vector_overlay',
  ExampleImage = 'example_image',
  PointOfInterest = 'point_of_interest',
  AreaOfInterest = 'area_of_interest',
  Asset = 'asset',
  Route = 'route',
}

// TEMP: should eventually replace EntityType values with these PascalCase values when BE is converted to use uniform entity type
export enum PascalCaseEntityType {
  Project = 'Project',
  Program = 'Program',
  Organization = 'Organization',
  Assignment = 'Assignment',
  AssignmentResponse = 'AssignmentResponse',
  Collection = 'Collection',
  Location = 'Location',
  User = 'User',
  Tenant = 'Tenant',
  ProjectPhoto = 'ProjectPhoto',
  HDPhoto = 'HdPhoto',
  Panorama = 'Panorama',
  RasterOverlay = 'RasterOverlay',
  VectorOverlay = 'VectorOverlay',
  ExampleImage = 'ExampleImage',
  PointOfInterest = 'PointOfInterest',
  AreaOfInterest = 'AreaOfInterest',
  Asset = 'Asset',
  Route = 'Route',
}
