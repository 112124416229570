import { IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import { RemoveCircle } from '@mui/icons-material';

import { IRenderResultsPayload, ISearchUserOption } from '../types';

import styles from './styles.module.css';

export interface IDefaultRenderResultsProps {
  selectedUsers: string[];
  options: ISearchUserOption[];
  payload: IRenderResultsPayload;
}

/**
 * Default component for rendering list of users
 */
const DefaultRenderResults: React.FC<IDefaultRenderResultsProps> = ({
  selectedUsers,
  options,
  payload: { remove },
}) => {
  if (!selectedUsers || !selectedUsers.length) {
    return (
      <Stack justifyItems='center' alignItems='center' className={styles['noUsersContainer']}>
        <Typography variant='body1'>No users selected</Typography>
      </Stack>
    );
  }

  return (
    <List className={styles['listContainer']}>
      {selectedUsers.map((userId, ind) => {
        // Searching for user in all options
        const user = options.find(usr => usr.id === userId);

        return (
          <ListItem
            key={userId}
            style={ind === selectedUsers.length - 1 ? { borderBottom: 'none' } : undefined}
            className={styles['listItemContainer']}
          >
            <ListItemText
              primary={`${user?.firstName} ${user?.lastName}`}
              secondary={user?.email}
            />
            <IconButton edge='start' color='error' onClick={() => remove(userId)}>
              <RemoveCircle color='error' />
            </IconButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default DefaultRenderResults;
