import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

import IdsSelect, { IIdsSelectProps } from '../../../../components/ids-inputs/IdsSelect';
import IdsFormOrgAssignmentsAutocompleteField, {
  IIdsFormOrgAssignmentsAutocompleteFieldProps,
} from '../../../../components/ids-forms/common/IdsFormOrgAssignmentsAutocompleteField';

import { IOrganizationAssignmentsListEdge } from '../../../../services/AssignmentsService';
import { IAssignmentListItemFrag } from '../../../Assignments/AssignmentListItem';

import { IFormValues } from '../index';

import ProjectAutocompleteOption from './ProjectAutocompleteOption';
import ProjectsAutocompleteField, {
  IProjectsAutocompleteFieldProps,
} from './ProjectsAutocompleteField';
import styles from './FormFields.module.css';

export interface IFormFieldsProps {
  resetAssignmentData: () => void;
}

/**
 * @TODO: Remove this constraint when possible to
 * request more than 10 projects.
 */
const PROJECTS_LIMIT = 10;

const renderOption: IProjectsAutocompleteFieldProps['renderOption'] = (props, o, { selected }) => (
  <ProjectAutocompleteOption option={o} selected={selected} {...props} key={o.node.id} />
);

const assignmentsFilteringOptions = [
  {
    label: 'Active',
    value: 'active',
    filterItem: (a: { node: IAssignmentListItemFrag }) => a.node.active,
  },
  {
    label: 'Inactive',
    value: 'inactive',
    filterItem: (a: { node: IAssignmentListItemFrag }) => !a.node.active,
  },
  {
    label: 'All',
    value: 'all',
    filterItem: () => true,
  },
];

const FormFields: React.FC<IFormFieldsProps> = ({ resetAssignmentData }) => {
  const [assignment, setAssignment] = useState<IOrganizationAssignmentsListEdge | null>(null);
  const [assignmentsFiltering, setAssignmentsFiltering] = useState(
    () => assignmentsFilteringOptions[0].value,
  );

  const { values, setFieldValue, submitForm } = useFormikContext<IFormValues>();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState({
    isValidAssignment: false,
    isValidProjects: false,
  });

  const onProjectOptionsLoaded = useCallback<
    NonNullable<IProjectsAutocompleteFieldProps['onOptionsLoaded']>
  >(
    options => {
      /**
       * Empty options array === most likely default value
       */
      if (!options.length || !values.projectsId.length) {
        return;
      }

      if (values.projectsId.every(p => options.some(o => o.node.id === p))) {
        setStatus(prev => ({ ...prev, isValidProjects: true }));

        return;
      }

      setStatus(prev => ({ ...prev, isValidProjects: false }));
    },
    [values],
  );

  const onAssignmentOptionsLoaded = useCallback<
    NonNullable<IIdsFormOrgAssignmentsAutocompleteFieldProps['onOptionsLoaded']>
  >(
    options => {
      /**
       * Empty options array === most likely default value
       */
      if (!values.assignmentId || !options.length) {
        return;
      }

      const assignment = options.find(o => o.node.id === values.assignmentId);
      if (!assignment) {
        setStatus(prev => ({ ...prev, isValidAssignment: false }));

        enqueueSnackbar('Cannot create a report for this assignment', { variant: 'warning' });

        return;
      }

      setStatus(prev => ({ ...prev, isValidAssignment: true }));
      setAssignment(assignment);

      // If the loaded assignment is inactive, update the filter to "inactive"
      if (!assignment.node.active) {
        setAssignmentsFiltering('inactive');
      }
    },
    [values, enqueueSnackbar],
  );

  useEffect(() => {
    if (
      assignment &&
      values.projectsId.length > 0 &&
      status.isValidProjects &&
      status.isValidAssignment
    ) {
      submitForm();
    }
  }, [submitForm, status, assignment, values.projectsId]);

  const getOptionDisabled: IProjectsAutocompleteFieldProps['getOptionDisabled'] = useCallback(
    option => {
      if (values.projectsId.length < PROJECTS_LIMIT) {
        return false;
      }

      return !values.projectsId.includes(option.node.id);
    },
    [values],
  );

  const onChangeAssignmentsFiltering = useCallback<NonNullable<IIdsSelectProps['onChange']>>(e => {
    setAssignmentsFiltering(e.target.value as string);
  }, []);

  const filterAssignmentOptions = useCallback<
    NonNullable<IIdsFormOrgAssignmentsAutocompleteFieldProps['filterAssignmentOptions']>
  >(
    options => {
      const method = assignmentsFilteringOptions.find(
        a => a.value === assignmentsFiltering,
      )!.filterItem;

      return options.filter(method);
    },
    [assignmentsFiltering],
  );

  const handleAssignmentChange = useCallback(() => {
    setFieldValue('projectsId', []);
    setAssignment(null);
    resetAssignmentData();
  }, [setFieldValue, resetAssignmentData]);

  const handleProjectsChange = useCallback(() => {
    resetAssignmentData();
  }, [resetAssignmentData]);

  return (
    <Grid container direction='column' gap={2}>
      <Grid item xs container spacing={2} flexDirection='column'>
        <Grid item xs>
          <IdsFormOrgAssignmentsAutocompleteField
            name='assignmentId'
            label='Select Assignment'
            required
            disableWhileLoading={true}
            onChange={handleAssignmentChange}
            filterAssignmentOptions={filterAssignmentOptions}
            onOptionsLoaded={onAssignmentOptionsLoaded}
          />
        </Grid>
        {assignment && (
          <Grid item xs>
            <ProjectsAutocompleteField
              key={assignment.node.parentType}
              onOptionsLoaded={onProjectOptionsLoaded}
              assignment={assignment}
              name='projectsId'
              label={`Select Projects (${PROJECTS_LIMIT} maximum)`}
              required
              multiple={true}
              onChange={handleProjectsChange}
              getOptionDisabled={getOptionDisabled}
              renderOption={renderOption}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs='auto' container direction='column' className={styles.container}>
        <Grid item xs='auto'>
          <IdsSelect
            fullWidth={false}
            label='Showing Assignments'
            value={assignmentsFiltering}
            options={assignmentsFilteringOptions}
            onChange={onChangeAssignmentsFiltering}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFields;
