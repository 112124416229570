import { gql } from 'graphql-request';

import { ImageMarkerFrag } from '../../imageMarkerFrag';
import useOrgGraphQuery from '../../../../../hooks/useOrgGraphQuery';
import { MediaType } from '../../../../../constants/media';
import useCursorPaginatedQuery, {
  IUseCursorPaginatedQueryOptions,
} from '../../../../../hooks/useCursorPaginatedQuery';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';
import { useLocationKeys } from '../../../../../services/LocationService';

import { DEFAULT_TAKE } from '../index';

export const PanoramasQuery = gql`
  query Panoramas($orgId: ID, $locationId: ID!, $tenantId: ID, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      panoramas(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...ImageMarkerFrag
            position {
              heading
            }
          }
        }
      }
    }
  }
  ${ImageMarkerFrag}
`;

const usePanoramas = (onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad']) => {
  const locationKeys = useLocationKeys();
  const { location } = useLocationMapContext();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.map(location?.id),
        MediaType.PanoramicPhoto,
        'points',
        `take-${take}`,
        `after-${after}`,
      ],
      PanoramasQuery,
      {
        locationId: location?.id,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery({
    initialData: location?.panoramas?.edges || [],
    useQuery,
    defaultTake: DEFAULT_TAKE,
    selectConnection: d => d.location.panoramas,
    onPageLoad,
  });
};

export default usePanoramas;
