import React from 'react';
import { gql } from 'graphql-request';
import { Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';

import { ExpandIcon } from '../../../../../theme/icons';
import AssignmentTask, { AssignmentTaskFrag, IAssignmentTaskFragType } from '../AssignmentTask';

export interface IAssignmentSectionFragType {
  id: string;
  title?: string;
  description?: string;
  assignmentTasks: {
    edges: {
      node: {
        id: string;
      } & IAssignmentTaskFragType;
    }[];
  };
}

export const AssignmentSectionFrag = gql`
  fragment AssignmentSectionFrag on AssignmentSection {
    id
    title
    description
    assignmentTasks {
      edges {
        node {
          id
          ...AssignmentTaskFrag
        }
      }
    }
  }
  ${AssignmentTaskFrag}
`;

export interface IAssignmentSectionProps {
  section: IAssignmentSectionFragType;
}

const AssignmentSection: React.FC<IAssignmentSectionProps> = ({ section }) => {
  return (
    <Accordion key={section.id}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography>{`Section #${section.id}: ${section.title}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {section.assignmentTasks.edges.map(e => (
          <AssignmentTask key={e.node.id} task={e.node} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AssignmentSection;
