import React, { useEffect, useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import clsx from 'clsx';

import IdsDialogForm, { IIdsDialogFormProps } from '../../../../ids-forms/IdsDialogForm';
import CancelButton from '../../../../ids-buttons/CancelButton';
import IdsFormElement from '../../../../ids-forms/IdsFormElement';
import IdsFormTextField from '../../../../ids-forms/IdsFormTextField';
import IdsFormAutocompleteField from '../../../../ids-forms/IdsFormAutocompleteField';
import IdsFormSwitchField from '../../../../ids-forms/IdsFormSwitchField';
import SingleValueAutocompleteOption from '../../../../ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import {
  createRasterOverlayValidationSchema,
  OVERLAY_NAME_CHAR_LIMIT,
} from '../rasterOverlayValidation';
import useLocationMapMetadataOptions from '../../../../../hooks/useLocationMapMetadataOptions';
import { MediaMetadataType } from '../../../../../constants/media';
import usePrevious from '../../../../../hooks/usePrevious';
import { IOverlayData, IRasterOverlayFileMeta, RasterOverlayFile } from '..';
import styles from '../RasterOverlayTool.module.css';

import '../style-overrides.css';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const initialValues: IOverlayData = {
  overlayName: '',
  levelId: null,
  defaultEnabled: false,
};

export interface IRasterOverlayFormFieldsProps {
  uppy: Uppy;
  uppyFile: RasterOverlayFile;
  allowedFileTypes: string[];
}

export interface ICompleteOverlayData extends Omit<IOverlayData, 'levelId'> {
  levelId: string;
}

export interface IRasterOverlayFormProps
  extends Omit<IIdsDialogFormProps, 'initialValues' | 'actions' | 'onSubmit'>,
    IRasterOverlayFormFieldsProps {
  disableNextButton?: boolean;
  onFormComplete: (values: ICompleteOverlayData) => void;
  onCancel: () => void;
}

const RasterOverlayFormFields: React.FC<IRasterOverlayFormFieldsProps> = ({
  uppy,
  uppyFile,
  allowedFileTypes,
}) => {
  const { values, touched, errors, setFieldValue } = useFormikContext<IOverlayData>();
  const levelOptions = useLocationMapMetadataOptions(MediaMetadataType.Level);
  const prevUppyFile = usePrevious(uppyFile);

  useEffect(() => {
    if (uppyFile !== prevUppyFile) {
      const prevFileName = prevUppyFile?.name.substring(0, OVERLAY_NAME_CHAR_LIMIT);

      if (uppyFile) {
        // Name not set or set to previous file name
        if (!values.overlayName || (prevUppyFile && values.overlayName === prevFileName)) {
          const constrainedFileName = uppyFile.name.substring(0, OVERLAY_NAME_CHAR_LIMIT);
          setFieldValue('overlayName', constrainedFileName, true);
        }
      }
      // Was set to previous file name, reset
      else if (prevUppyFile && values.overlayName === prevFileName) {
        setFieldValue('overlayName', '', true);
      }
    }
  }, [uppyFile, prevUppyFile, values, setFieldValue]);

  return (
    <Grid
      container
      direction='column'
      rowSpacing={2}
      wrap='nowrap'
      className={clsx(styles.fullHeight, styles.formFieldsContainer)}
    >
      <Grid item xs className={styles.fullHeight}>
        <Dashboard
          uppy={uppy}
          hideUploadButton
          hideCancelButton
          showProgressDetails
          width='100%'
          height='100%'
          disableThumbnailGenerator
          hideProgressAfterFinish
          note={`Supported file types: ${allowedFileTypes.join(', ')}`}
        />
      </Grid>
      <Grid item xs='auto' container direction='row' spacing={2}>
        <Grid item xs>
          <IdsFormTextField
            name='overlayName'
            label='Name'
            helperText={
              (touched.overlayName && errors.overlayName) ||
              `${values.overlayName.length}/${OVERLAY_NAME_CHAR_LIMIT}`
            }
            required
            transformChangeValue={(value: string) =>
              // Enforce character limit
              value.length > OVERLAY_NAME_CHAR_LIMIT
                ? value.substring(0, OVERLAY_NAME_CHAR_LIMIT)
                : value
            }
            data-testid='nameInput'
          />
        </Grid>
        <Grid item xs>
          {/* TODO: think about setting level filter when this changes */}
          <IdsFormAutocompleteField
            name='levelId'
            label='Level'
            required
            options={levelOptions}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsText='No levels found'
            renderOption={(props, o) => (
              <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
            )}
            data-testid='levelInput'
          />
        </Grid>
      </Grid>
      <Grid item container xs='auto' direction='row' spacing={2} style={{ paddingTop: '8px' }}>
        <Grid item xs>
          <IdsFormSwitchField label='Default' name='defaultEnabled' />
        </Grid>
      </Grid>
    </Grid>
  );
};

const RasterOverlayForm: React.FC<IRasterOverlayFormProps> = ({
  uppy,
  uppyFile,
  onFormComplete,
  onCancel,
  disableNextButton,
  allowedFileTypes,
  ...rest
}) => {
  const _initialValues: IOverlayData = useMemo(() => {
    if (uppy && uppyFile) {
      const { overlayName, levelId, defaultEnabled } = uppy.getFile<IRasterOverlayFileMeta>(
        uppyFile.id,
      ).meta;
      return {
        defaultEnabled: defaultEnabled || initialValues.defaultEnabled,
        overlayName: overlayName || initialValues.overlayName,
        levelId: levelId || initialValues.levelId,
      };
    }
    return initialValues;
  }, [uppy, uppyFile]);

  return (
    <IdsDialogForm
      initialValues={_initialValues}
      validationSchema={createRasterOverlayValidationSchema}
      className={clsx(styles.fullHeight, styles.form)}
      actions={
        <>
          <CancelButton onClick={onCancel} />
          <IdsFormElement
            render={({ isValid, values }) => (
              <Button
                variant='contained'
                color='primary'
                disabled={!uppyFile || !isValid || disableNextButton}
                onClick={() => onFormComplete(values)}
              >
                Next
              </Button>
            )}
          />
        </>
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      {...rest}
    >
      <RasterOverlayFormFields
        uppy={uppy}
        uppyFile={uppyFile}
        allowedFileTypes={allowedFileTypes}
      />
    </IdsDialogForm>
  );
};

export default RasterOverlayForm;
