import { AssignmentQuestionType } from '../../../../../../constants/assignments';

export const EDITABLE_ANSWER_TYPES = {
  [AssignmentQuestionType.TEXT]: AssignmentQuestionType.TEXT,
  [AssignmentQuestionType.NUMBER]: AssignmentQuestionType.NUMBER,
  [AssignmentQuestionType.BARCODE]: AssignmentQuestionType.BARCODE,
  [AssignmentQuestionType.DATE]: AssignmentQuestionType.DATE,
  [AssignmentQuestionType.SINGLE_CHOICE]: AssignmentQuestionType.SINGLE_CHOICE,
  [AssignmentQuestionType.RADIO]: AssignmentQuestionType.RADIO,
  [AssignmentQuestionType.MULTIPLE_CHOICE]: AssignmentQuestionType.MULTIPLE_CHOICE,
  [AssignmentQuestionType.PHOTO_SIMPLE]: AssignmentQuestionType.PHOTO_SIMPLE,
  [AssignmentQuestionType.PHOTO_HD]: AssignmentQuestionType.PHOTO_HD,
  [AssignmentQuestionType.PHOTO_360]: AssignmentQuestionType.PHOTO_360,
};
