import React, { useCallback, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import IdsMediaViewer, {
  MEDIA_VIEWER_TABS,
  MEDIA_VIEWER_WIDTHS,
} from '../../../../../../../../../../components/ids-media-viewer/IdsMediaViewer';
import { prepareGroupedAnswers } from '../../../Actions/EditTaskResponseDialog/EditTaskResponseForm/helpers';

import { MEDIA_VIEWER_MODES } from '../../../../../../../../../../constants/mediaViewer';
import useMetadataItems from '../../../../../../../../../../hooks/useMetadataItems';

import { useGetLocationMetadataTypes } from '../../../../../../../../../../services/LocationService';
import { useUpdateTaskResponseAnswers } from '../../../../../../../../../../services/TaskResponsesService';

import { activeTenantState } from '../../../../../../../../../../atoms/tenants';
import { activeOrganizationState } from '../../../../../../../../../../atoms/organizations';

import TaskResponseContext from '../../../../../../../../../../context/TaskResponseContext';

import { IImageAnswerFragType, IImageAnswerProps } from '../index';

import { invalidateCache } from './helpers';
import styles from './ViewerWrapper.module.css';

const selectMediaItemData = (item: IImageAnswerFragType['answerPhoto']) => item;
// add info tab
const tabs = [MEDIA_VIEWER_TABS.INFO, MEDIA_VIEWER_TABS.MEDIA];

export interface IViewerWrapperProps
  extends Pick<IImageAnswerProps['answer'], 'questionId' | 'answerPhoto' | 'uuid'> {
  onClose: () => void;
}

const ViewerWrapper: React.FC<IViewerWrapperProps> = ({
  answerPhoto,
  onClose,
  uuid,
  questionId,
}) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const trContext = useContext(TaskResponseContext);
  const groupedAnswers = trContext!.groupedAnswers;
  const { taskId, guid, assignmentResponseId, id: taskResponseId } = trContext!.taskResponse;

  const { locationId, id } = answerPhoto!;

  // Includes current image and related ones
  const media = useMemo(() => {
    return Object.values(groupedAnswers).reduce(
      (accumulator: IImageAnswerProps['answer']['answerPhoto'][], item: any) => {
        if (item.question.id !== questionId) {
          return accumulator;
        }

        item.answers.forEach((answer: IImageAnswerProps['answer']) => {
          if (!answer.answerPhoto) {
            return;
          }

          accumulator.push(answer.answerPhoto);
        });

        return accumulator;
      },
      [],
    );
  }, [groupedAnswers, questionId]);

  const { data: locationMetadataTypes } = useGetLocationMetadataTypes(locationId ?? null);

  const { allTags } = useMetadataItems(locationMetadataTypes?.location.metadataTypes || []);

  const editTaskResponseAnswersMutation = useUpdateTaskResponseAnswers();

  const onDelete = useCallback(async () => {
    await invalidateCache(id);

    // Remove this answer image
    const answers = prepareGroupedAnswers(groupedAnswers).filter(a => a.uuid !== uuid);

    await editTaskResponseAnswersMutation.mutateAsync({
      assignmentResponseId,
      taskResponseId,
      data: {
        task_id: taskId,
        guid,
        answers,
      },
    });
  }, [
    id,
    uuid,
    taskId,
    guid,
    taskResponseId,
    assignmentResponseId,
    groupedAnswers,
    editTaskResponseAnswersMutation,
  ]);

  return (
    <IdsMediaViewer
      tenantId={activeTenant!.id}
      orgId={activeOrg.id}
      activeMediaId={id}
      media={media}
      tabs={tabs}
      initialWidth={MEDIA_VIEWER_WIDTHS.THREEQUARTER}
      className={styles.fixedPosition}
      onClose={onClose}
      mode={MEDIA_VIEWER_MODES.GALLERY}
      selectMediaItemData={selectMediaItemData}
      allImageTags={allTags}
      onDelete={onDelete}
      onFlagUpdate={invalidateCache}
      onImageInfoUpdate={invalidateCache}
      onTagsUpdate={invalidateCache}
      modal={true}
      loadActiveImageDetail={true}
      hideDelete={false}
      hideContextMenu={false}
      hideFullScreen={false}
      hideEdit={false}
      hideFlag={false}
      hideClose={false}
      disableFlag={false}
      loadAllThumbnails={false}
      fullscreen={false}
      // Below ones have to be specified due to propTypes and TS
      open={undefined}
      availableWidth={undefined}
      title={undefined}
      onActiveMediaChange={undefined}
      onActiveMediaLoaded={undefined}
      onMediaViewerResize={undefined}
      activeImageDetail={undefined}
      setActiveMediaAndHighlightOnMap={undefined}
      unhighlightMediaMarker={undefined}
      highlightMediaMarker={undefined}
      thumbnailCount={undefined}
      noImageMessage={undefined}
      heightMediaLoader={undefined}
      actionButtons={undefined}
    />
  );
};

export default ViewerWrapper;
