import { Typography } from '@mui/material';
import React from 'react';

import IdsFormDateField from '../../../../../../../../../../../components/ids-forms/IdsFormDateField';
import IdsFormSelectField, {
  IIdsFormSelectFieldProps,
} from '../../../../../../../../../../../components/ids-forms/IdsFormSelectField';
import IdsFormTextField from '../../../../../../../../../../../components/ids-forms/IdsFormTextField';
import { IAssignmentAnswer, IAssignmentQuestion } from '../types';
import { EDITABLE_ANSWER_TYPES } from '../../../../../editableAnswerTypes';

export interface IEditableAnswerProps {
  answer: IAssignmentAnswer | null;
  question: IAssignmentQuestion;
}

const EditableAnswer: React.FC<IEditableAnswerProps> = ({ answer, question }) => {
  const { id: questionId, questionType, assignmentQuestionOptions } = question;

  const fieldName = answer?.id ?? questionId;

  let prefilledFormField;
  switch (questionType) {
    case EDITABLE_ANSWER_TYPES.text:
    case EDITABLE_ANSWER_TYPES.number:
      prefilledFormField = (
        <IdsFormTextField name={fieldName} multiline minRows={1} maxRows={3} label='' />
      );
      break;
    case EDITABLE_ANSWER_TYPES.date:
      prefilledFormField = (
        <IdsFormDateField
          name={fieldName}
          label=''
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          PopperProps={{
            placement: 'auto',
          }}
        />
      );
      break;
    case EDITABLE_ANSWER_TYPES.single_choice:
    case EDITABLE_ANSWER_TYPES.radio:
      const options: IIdsFormSelectFieldProps['options'] = assignmentQuestionOptions.map(item => ({
        value: item.title,
        label: item.title,
      }));
      options.unshift({
        value: '',
        label: <Typography visibility='hidden'>None</Typography>,
      });
      prefilledFormField = <IdsFormSelectField name={fieldName} label='' options={options} />;
      break;
    default:
      throw new Error(`Unknown question type: ${questionType}`);
  }

  return prefilledFormField;
};

export default EditableAnswer;
