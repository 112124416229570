import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeTenantState } from '../../../../atoms/tenants';

import { getOrgReportsRoute } from '../../../../utils/routes';
import { ASSIGNMENT_ID_PARAM, PROJECT_ID_PARAM } from '../../../reporting/LoadAssignmentForm';

export interface IGenerateReportButtonProps {
  assignmentId: string;
  projectId: string;
}

const GenerateReportButton: React.FC<IGenerateReportButtonProps> = ({
  assignmentId,
  projectId,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeTenant = useRecoilValue(activeTenantState);

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    const pathname = getOrgReportsRoute({
      orgId: activeOrg.id,
      subdomain: activeTenant?.subdomain,
    });

    const search = new URLSearchParams({
      [ASSIGNMENT_ID_PARAM]: assignmentId,
      [PROJECT_ID_PARAM]: projectId,
    }).toString();

    navigate({
      pathname,
      search,
    });
  }, [navigate, assignmentId, projectId, activeOrg, activeTenant]);

  return (
    <Button color='secondary' variant='contained' onClick={onClick}>
      Generate report
    </Button>
  );
};

export default GenerateReportButton;
