import React from 'react';
import {
  AutocompleteRenderInputParams,
  CircularProgress,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';

export interface ISearchTextFieldProps extends StandardTextFieldProps {
  params: AutocompleteRenderInputParams;
  loading?: boolean;
}

const SearchTextField: React.FC<ISearchTextFieldProps> = ({
  params,
  loading,
  required,
  label,
  ...rest
}) => {
  const { InputProps, ...restParams } = params;
  return (
    <TextField
      InputProps={{
        ...InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
            {InputProps.endAdornment}
          </>
        ),
      }}
      {...restParams}
      {...rest}
      label={label}
      required={required}
    />
  );
};

export default SearchTextField;
