import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, ListItemText } from '@mui/material';

import useDeleteProgramAction from '../useDeleteProgramAction';
import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import IdsListItemMenu from '../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../components/ids-lists/IdsListItemMenuItem';
import { EditOutlineIcon, DeleteOutlineIcon } from '../../../theme/icons';

import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';

export const ProgramListItemMenu = ({ programItem, onEdit }) => {
  const { deleted, deleting, deleteProgram } = useDeleteProgramAction();
  const { userHasPermission } = usePermissions();

  const canUpdate = useMemo(
    () => userHasPermission('update', EntityType.Program),
    [userHasPermission],
  );
  const canDelete = useMemo(
    () => userHasPermission('delete', EntityType.Program),
    [userHasPermission],
  );

  return (
    (canUpdate || canDelete) && (
      <IdsListItemMenu>
        {canUpdate && (
          <IdsListItemMenuItem icon={<EditOutlineIcon />} onClick={onEdit}>
            Edit
          </IdsListItemMenuItem>
        )}
        {canDelete && (
          <IdsListItemMenuItem
            icon={<DeleteOutlineIcon />}
            onClick={() => deleteProgram(programItem.node)}
            disabled={deleting || deleted}
          >
            Delete
          </IdsListItemMenuItem>
        )}
      </IdsListItemMenu>
    )
  );
};

const ProgramListItem = ({ programItem, onOpen, onEdit }) => {
  if (!programItem?.node) {
    return null;
  }

  const { id, name, description } = programItem?.node;

  return (
    <IdsListItem
      key={id}
      primaryAction={onOpen}
      secondaryAction={<ProgramListItemMenu programItem={programItem} onEdit={onEdit} />}
    >
      <IdsListItemChild xs={12} md={3}>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'h6', noWrap: true }}
          secondary={description}
          secondaryTypographyProps={{ display: { xs: 'initial', md: 'none' } }}
        />
      </IdsListItemChild>
      <Box>
        <ListItemText
          secondary={description}
          secondaryTypographyProps={{
            display: { xs: 'none', md: 'initial' },
            noWrap: true,
          }}
        />
      </Box>
    </IdsListItem>
  );
};

ProgramListItem.propTypes = {
  programItem: PropTypes.object,
  onOpen: PropTypes.func,
  onEdit: PropTypes.func,
};

export default ProgramListItem;
