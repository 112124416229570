import React, { useEffect, useMemo, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { infoPanelExpandedState } from '../../../../atoms/immersiveViewer';

import Page from '../../../../components/Page';
import NestedOrgViewHeader from '../../../../components/NestedOrgViewHeader';
import { ImmersiveViewerInContext } from '../../../../components/mapping/ImmersiveViewer';
import { IRouteDetails } from '../../../../services/RouteService';
import { RouteDetailsProvider } from '../../../../context/RouteDetailsContext';

import RouteMapMenu from '../RouteMapMenu';
import RouteMapControls from '../RouteMapControls';
import { IRoutePoint } from '../types';
import {
  useRasterOverlays,
  useBreadcrumbs,
  usePointLayers,
  useSetTypeItems,
  useCenterMap,
  useRouteLevels,
} from '../hooks';

import RouteMapInfoPanel from '../RouteMapInfoPanel';

import styles from './RouteMapPageContent.module.css';

const immersiveViewerContainerProps = {
  height: '100%',
  width: '100%',
};

const breadcrumbsSx = { padding: 1 };

export interface IRouteMapPageContentProps {
  route: IRouteDetails;
}

const RouteMapPageContent: React.FC<IRouteMapPageContentProps> = ({ route }) => {
  const [points, setPoints] = useState<IRoutePoint[] | null>(null);

  const { routePoints } = route;

  useSetTypeItems(points);
  useRasterOverlays();

  const levelIds = useRouteLevels(route);

  usePointLayers(levelIds);
  useCenterMap(points);

  useEffect(() => {
    const points = routePoints.reduce((accumulator: IRoutePoint[], p) => {
      const isNote = p.notes.length > 0;
      const hasImageData = p.images.length > 0;

      accumulator.push({
        ...(isNote && {
          tooltip: p.notes
            .reduce((accumulator, note) => {
              return `${accumulator}\n${note.notes}`;
            }, '')
            .trim(),
        }),
        node: {
          id: p.id,
          type: p.type,
          extraImage: p.extraImage,
          metadata: p.metadata,
          isCompleted: hasImageData || isNote,
          hasImageData,
          isNote,
          position: p.position,
        },
      });

      return accumulator;
    }, []);

    setPoints(points);
  }, [routePoints]);

  const breadcrumbs = useBreadcrumbs();

  const infoPanelExpanded = useRecoilValue(infoPanelExpandedState);
  const layout = useMemo(() => {
    if (infoPanelExpanded) {
      return (
        <Grid container direction='row' flexWrap='nowrap' height='100%' p={1} columnSpacing={1}>
          <Grid item container xs>
            <Grid item xs='auto'>
              <RouteMapMenu routePoints={routePoints} />
            </Grid>
          </Grid>

          <Grid item xs='auto'>
            <RouteMapControls />
          </Grid>

          <Grid item xs='auto' overflow='auto' flexShrink={1}>
            <RouteMapInfoPanel route={route} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        direction='column'
        flexWrap='nowrap'
        height='100%'
        p={1}
        rowSpacing={1}
        wrap='nowrap'
      >
        <Grid item container xs direction='row' justifyContent='space-between' wrap='nowrap'>
          <Grid item xs='auto'>
            <RouteMapMenu routePoints={routePoints} />
          </Grid>
          <Grid item xs='auto'>
            <RouteMapInfoPanel route={route} />
          </Grid>
        </Grid>

        <Grid item container xs justifyContent='flex-end'>
          <Grid item xs='auto'>
            <RouteMapControls />
          </Grid>
        </Grid>
      </Grid>
    );
  }, [infoPanelExpanded, routePoints, route]);

  return (
    <Page title={route.name} className={styles['page']}>
      <Container maxWidth={false} className={styles['pageContainer']}>
        <NestedOrgViewHeader
          currentCrumb={route.name}
          breadcrumbs={breadcrumbs}
          preventLayoutJumping={false}
          containerSx={breadcrumbsSx}
        />

        <ImmersiveViewerInContext containerProps={immersiveViewerContainerProps}>
          <RouteDetailsProvider>{layout}</RouteDetailsProvider>
        </ImmersiveViewerInContext>
      </Container>
    </Page>
  );
};

export default RouteMapPageContent;
