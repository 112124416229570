import React, { useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useSearchParams } from 'react-router-dom';

import { LocationIcon } from '../../theme/icons';
import { activeOrganizationState } from '../../atoms/organizations';
import { getOrgMapLocationRoute } from '../../utils/routes';
import { isKeyLocationMapKey } from '../../constants/urlStateKeys';

import { activeTenantState } from '../../atoms/tenants';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface ILocationMapChipProps extends IEntityChipProps {
  locationId?: string;
}

const LocationMapChip: React.FC<ILocationMapChipProps> = ({ locationId, ...rest }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);
  const [searchParams] = useSearchParams();

  const mapSearchParams = useMemo(() => {
    if (!searchParams) {
      return;
    }

    const locationMapSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      // Keep any search params that correspond with location map filters
      // to maintain filtered state when map is opened
      if (isKeyLocationMapKey(key)) {
        locationMapSearchParams.append(key, value);
      }
    });
    return `?${locationMapSearchParams.toString()}`;
  }, [searchParams]);

  return (
    <EntityChip
      icon={<LocationIcon size={16} />}
      label='Map View'
      entityRoute={getOrgMapLocationRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        locationId,
      })}
      searchParams={mapSearchParams}
      {...rest}
    />
  );
};

export default LocationMapChip;
