import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TOOLS_ID } from '../components/mapping/LocationMapMenu';
import { LAYER_TYPE } from '../context/ImmersiveViewerContext';
import { isLongitudeValid, isLatitudeValid } from '../utils/geospatial';

import useDeckEventManagerCallback, { IDeckEvent } from './useDeckEventManagerCallback';
import { useObservableItemState, useObservableItemValue } from './useObservableStates';
import useImmersiveViewer from './useImmersiveViewer';
import usePrevious from './usePrevious';
import useUnmountedEffect from './utils/useUnmountedEffect';

export interface IMapMarkerPlacementToolProps {
  toolId: string;
}

export interface IMarkerPosition {
  latitude?: number | null;
  longitude?: number | null;
  altitude?: number | null;
}

export type MarkerPositionCallback = (position: IMarkerPosition) => void;

export interface IMapMarkerPlacementToolState {
  active: string | null;
  buildMarkerLayer?: (
    latitude: number,
    longitude: number,
    deckLayerProps: Record<string, any>,
    idsLayerProps: Record<string, any>,
  ) => any;
  onMarkerPlaced?: MarkerPositionCallback | null;
  onMarkerMoved?: MarkerPositionCallback | null;
  onMarkerRender?: MarkerPositionCallback | null;
  initLatitude?: number | null;
  initLongitude?: number | null;
  /** When `true`, tool will remain enabled, but no updates can be made. */
  frozen?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMapMarkerPlacementToolStateUpdate
  extends Omit<IMapMarkerPlacementToolState, 'active'> {}

// Used to remotely manager a marker placement tool without being
// affected by all of its local state
export const useMapMarkerPlacementToolManager = ({ toolId }: IMapMarkerPlacementToolProps) => {
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useImmersiveViewer();

  const [toolState, setToolState] = useObservableItemState<IMapMarkerPlacementToolState>({
    id: TOOLS_ID,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });

  const startMarkerPlacement = useCallback(
    (
      buildMarkerLayer: IMapMarkerPlacementToolState['buildMarkerLayer'],
      onMarkerPlaced: IMapMarkerPlacementToolState['onMarkerPlaced'],
      onMarkerMoved: IMapMarkerPlacementToolState['onMarkerMoved'],
      onMarkerRender: IMapMarkerPlacementToolState['onMarkerRender'],
      initLatitude: IMapMarkerPlacementToolState['initLatitude'] = null,
      initLongitude: IMapMarkerPlacementToolState['initLongitude'] = null,
      frozen: IMapMarkerPlacementToolState['frozen'] = false,
      toolProps = {},
    ) => {
      setToolState({
        ...toolProps,
        active: toolId,
        buildMarkerLayer,
        initLatitude,
        initLongitude,
        frozen,
        onMarkerPlaced,
        onMarkerMoved,
        onMarkerRender,
      });
    },
    [setToolState, toolId],
  );

  const updateMarkerPlacement = useCallback(
    ({
      buildMarkerLayer,
      initLatitude,
      initLongitude,
      frozen = false,
      onMarkerMoved,
      onMarkerRender,
    }: IMapMarkerPlacementToolStateUpdate) => {
      setToolState({
        ...(buildMarkerLayer && { buildMarkerLayer }),
        ...(initLatitude !== undefined && { initLatitude }),
        ...(initLongitude !== undefined && { initLongitude }),
        frozen,
        ...(onMarkerMoved && { onMarkerMoved }),
        ...(onMarkerRender && { onMarkerRender }),
      });
    },
    [setToolState],
  );

  const finishMarkerPlacement = useCallback(() => {
    setToolState({ active: null }, true);
  }, [setToolState]);

  return {
    startMarkerPlacement,
    updateMarkerPlacement,
    finishMarkerPlacement,
    enabled: toolState?.active === toolId,
  };
};

// Generic marker placement hook to be used by tool components to interact
// with live position data for the marker placement as its happening
const useMapMarkerPlacementTool = ({ toolId }: IMapMarkerPlacementToolProps) => {
  const {
    setController,
    setLayer,
    useViewport,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  } = useImmersiveViewer();
  const toolState = useObservableItemValue<IMapMarkerPlacementToolState>({
    id: TOOLS_ID,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  const prevToolState = usePrevious<IMapMarkerPlacementToolState | undefined>(toolState);
  const _onMarkerPlaced = useRef<IMapMarkerPlacementToolState['onMarkerPlaced'] | null>();
  const _onMarkerMoved = useRef<IMapMarkerPlacementToolState['onMarkerMoved'] | null>();
  const _onMarkerRender = useRef<IMapMarkerPlacementToolState['onMarkerRender'] | null>();
  const viewport = useViewport();
  const [hoveringPoint, setHoveringPoint] = useState<number[] | null>();
  const [toolTarget, setToolTarget] = useState<any | null>();
  const [position, setPosition] = useState<IMarkerPosition>({
    longitude: null,
    latitude: null,
  });

  // Using the extracted reset function
  const reset = useCallback(() => {
    setLayer(toolId, null);

    if (_onMarkerRender.current) {
      _onMarkerRender.current({ longitude: null, latitude: null });
    }
  }, [toolId, setLayer]);

  useUnmountedEffect(() => {
    reset();
  });

  const enabled = useMemo(() => toolState?.active === toolId, [toolState, toolId]);
  const prevEnabled = usePrevious(enabled);

  const positionValid = useMemo(
    () => isLongitudeValid(position.longitude) && isLatitudeValid(position.latitude),
    [position],
  );

  useEffect(() => {
    if (!toolTarget) {
      return;
    }

    // Change cursor while placing marker
    toolTarget.style.cursor = !enabled && !positionValid ? 'pointer' : 'inherit';
  }, [enabled, toolTarget, positionValid]);

  const onMapHover = useCallback(
    event => {
      const { offsetCenter, target } = event;

      if (!viewport) return;

      const coordinate = viewport.unproject([offsetCenter.x, offsetCenter.y]);
      setHoveringPoint(coordinate);

      if (target !== toolTarget) {
        setToolTarget(target);
      }
    },
    [setHoveringPoint, viewport, setToolTarget, toolTarget],
  );

  // Only track hover events if marker hasn't been placed yet
  useDeckEventManagerCallback(
    'pointermove',
    onMapHover,
    enabled && !positionValid && !toolState!.frozen,
  );

  const onMapClick = useCallback(
    (event: IDeckEvent) => {
      // still need to check this even though handler gets disabled,
      // event fires again before it gets disabled when the point is clicked
      // point click and map click are handled separately
      if (enabled && viewport) {
        const { offsetCenter } = event;
        const [longitude, latitude] = viewport.unproject([offsetCenter.x, offsetCenter.y]);
        setPosition({ longitude, latitude });

        if (_onMarkerMoved.current) {
          _onMarkerMoved.current({ longitude, latitude });
        }
      }
    },
    [viewport, enabled, setPosition],
  );

  useDeckEventManagerCallback('click', onMapClick, enabled && !toolState!.frozen);

  const onMarkerDragStart = useCallback(() => {
    setController({ dragPan: false });
  }, [setController]);

  const onMarkerDrag = useCallback(
    (info?: Record<string, any>) => {
      if (!info) return;

      const {
        coordinate: [longitude, latitude],
      } = info;
      setPosition({ longitude, latitude });
    },
    [setPosition],
  );

  const onMarkerDragEnd = useCallback(
    (info: Record<string, any>) => {
      if (!info) return;

      setController({ dragPan: true });

      if (_onMarkerMoved.current) {
        const {
          coordinate: [longitude, latitude],
        } = info;

        _onMarkerMoved.current({ longitude, latitude });
      }
    },
    [setController],
  );

  // Build tool marker
  useEffect(() => {
    const longitude = positionValid ? position.longitude : hoveringPoint && hoveringPoint[0];
    const latitude = positionValid ? position.latitude : hoveringPoint && hoveringPoint[1];

    if (
      !enabled ||
      !toolState?.buildMarkerLayer ||
      !isLongitudeValid(longitude) ||
      !isLatitudeValid(latitude)
    ) {
      reset();
      return;
    }

    const { buildMarkerLayer } = toolState;

    const markerLayer = buildMarkerLayer(
      latitude!,
      longitude!,
      {
        // layer props
        id: toolId,
        visible: enabled,
        cluster: false,
        pickable: !toolState!.frozen, // Disable interactions when frozen
        onDragStart: onMarkerDragStart,
        onDrag: onMarkerDrag,
        onDragEnd: onMarkerDragEnd,
      },
      // override default layer type to render on top of other media markers
      { type: LAYER_TYPE.TOOL }, // ids layer props
    );

    setLayer(toolId, markerLayer);

    if (_onMarkerRender.current) {
      _onMarkerRender.current({ longitude, latitude });
    }
  }, [
    hoveringPoint,
    position,
    setLayer,
    enabled,
    toolId,
    toolState,
    onMarkerDragStart,
    onMarkerDrag,
    onMarkerDragEnd,
    positionValid,
    reset,
  ]);

  const startMarkerPlacement = useCallback(() => {
    if (!toolState) return;

    const { initLongitude, initLatitude, onMarkerPlaced, onMarkerMoved, onMarkerRender } =
      toolState;

    setPosition({ longitude: initLongitude, latitude: initLatitude });
    _onMarkerPlaced.current = onMarkerPlaced;
    _onMarkerMoved.current = onMarkerMoved;
    _onMarkerRender.current = onMarkerRender;
  }, [toolState, setPosition]);

  const handleToolStateUpdate = useCallback(() => {
    if (!toolState) return;

    const { longitude, latitude } = position;
    const { initLongitude, initLatitude, onMarkerPlaced, onMarkerMoved, onMarkerRender } =
      toolState;

    const longitudeChanged = initLongitude !== prevToolState?.initLongitude;
    const latitudeChanged = initLatitude !== prevToolState?.initLatitude;

    if (longitudeChanged || latitudeChanged) {
      // Update position to use new init lat/lon
      setPosition({
        longitude: longitudeChanged ? initLongitude : longitude,
        latitude: latitudeChanged ? initLatitude : latitude,
      });
    }

    if (onMarkerPlaced !== prevToolState?.onMarkerPlaced) {
      _onMarkerPlaced.current = onMarkerPlaced;
    }

    if (onMarkerMoved !== prevToolState?.onMarkerMoved) {
      _onMarkerMoved.current = onMarkerMoved;
    }

    if (onMarkerRender !== prevToolState?.onMarkerRender) {
      _onMarkerRender.current = onMarkerRender;
    }
  }, [toolState, prevToolState, setPosition, position]);

  const finishMarkerPlacement = useCallback(() => {
    if (_onMarkerPlaced.current) {
      _onMarkerPlaced.current(position);
      _onMarkerPlaced.current = null;
    }

    _onMarkerMoved.current = null;
    _onMarkerRender.current = null;
    setHoveringPoint(null);
    setPosition({ longitude: null, latitude: null });
  }, [setHoveringPoint, setPosition, position]);

  useEffect(() => {
    if (enabled && !prevEnabled) {
      // Just enabled
      startMarkerPlacement();
    } else if (!enabled && prevEnabled) {
      // Just disabled
      finishMarkerPlacement();
    }
  }, [enabled, prevEnabled, finishMarkerPlacement, startMarkerPlacement]);

  useEffect(() => {
    if (enabled && prevEnabled && toolState !== prevToolState) {
      handleToolStateUpdate();
    }
  }, [enabled, prevEnabled, prevToolState, toolState, handleToolStateUpdate]);
};

export default useMapMarkerPlacementTool;
