import { useCallback, useEffect, useRef, useState } from 'react';
import { DeckProps } from '@deck.gl/core/typed';

import { ROUTE_POINT_TYPE_LAYER_IDS } from '../../../../../../constants/layerIds';
import { IMetadataType, RoutePointType } from '../../../../../../constants/media';

import useImmersiveViewer from '../../../../../../hooks/useImmersiveViewer';
import useFilterContext from '../../../../../../hooks/useFilterContext';

import useMarkerTypeLayerBuilders from '../../../../../../components/mapping/tools/hooks/useMarkerTypeLayerBuilders';

import { IRoutePoint, ITypedPoints } from '../../../types';
import { POINTS_REPOSITION_LAYER_ID } from '../helpers';
import { OnDragEventType } from '../types';

const filterName = 'rp-repositioning';
const selectFilterData = (item: IRoutePoint) => item.node.id;
const targets = [
  {
    type: RoutePointType.DslrHdPhoto,
    selectFilterData,
  },
  {
    type: RoutePointType.ProjectPhoto,
    selectFilterData,
  },
  {
    type: RoutePointType.Panorama,
    selectFilterData,
  },
  {
    type: RoutePointType.DslrPanorama,
    selectFilterData,
  },
];

const useRepositioningFeature = (metadataTypes: IMetadataType[]) => {
  const shouldPreventMarkerDrag = useRef((event: OnDragEventType) => !!event.rightButton);

  const { setLayer, setController, getLayerMetadata, setLayerMetadata } = useImmersiveViewer();
  const { addFilter, removeFilter } = useFilterContext();

  const markerTypeLayerBuilders = useMarkerTypeLayerBuilders(metadataTypes);

  const [pointsToReposition, setPointsToReposition] = useState<IRoutePoint[]>([]);
  const isRepositioningPoints = !!pointsToReposition.length;

  const setLayersOpacity = useCallback(
    (opacity: number) => {
      const setOpacity = (layerId: string) => {
        const metadata = getLayerMetadata(layerId);
        setLayerMetadata(layerId, {
          ...metadata,
          opacity,
        });
      };

      Object.values(ROUTE_POINT_TYPE_LAYER_IDS).forEach(setOpacity);
    },
    [getLayerMetadata, setLayerMetadata],
  );

  useEffect(() => {
    if (isRepositioningPoints) {
      setLayersOpacity(0.5);
    } else {
      setLayersOpacity(1);
    }
  }, [setLayersOpacity, isRepositioningPoints]);

  /**
   * This state is necessary for convenience and performance purposes.
   * It contains the same identifiers as pointsToReposition state.
   */
  const [repositionedPointsId, setRepositionedPointsId] = useState<string[]>([]);
  useEffect(() => {
    if (repositionedPointsId.length === 0) {
      return;
    }

    addFilter({
      name: filterName,
      label: filterName,
      invisible: true,
      filterItem: id => !repositionedPointsId.includes(id),
      targets,
    });
  }, [repositionedPointsId, addFilter]);

  const onDragStart = useCallback<NonNullable<DeckProps['onDragStart']>>(
    (info, event) => {
      // This prevents MapContextMenu from being invoked.
      event.stopImmediatePropagation();

      if (shouldPreventMarkerDrag.current(event)) {
        return true;
      }

      const { object, viewport } = info;

      if (!viewport || object.cluster) {
        // Need viewport to handle drag; do not drag clusters
        return true;
      }

      setController({ dragPan: false });

      setPointsToReposition(prev => {
        if (prev.some(p => p.node.id === object.node.id)) {
          // This point is already repositioned, skip it.
          return prev;
        }

        setRepositionedPointsId(prev => [...prev, object.node.id]);

        // This is a new point, add it.
        return [...prev, structuredClone(object)];
      });

      return true;
    },
    [setController],
  );

  const onDragEnd = useCallback<NonNullable<DeckProps['onDragEnd']>>(
    (_, event) => {
      // This prevents MapContextMenu from being invoked.
      event.stopImmediatePropagation();

      if (shouldPreventMarkerDrag.current(event)) {
        return true;
      }

      setController({ dragPan: true });

      return true;
    },
    [setController],
  );

  const onDrag = useCallback<NonNullable<DeckProps['onDrag']>>((info, event) => {
    // This prevents MapContextMenu from being invoked.
    event.stopImmediatePropagation();

    if (shouldPreventMarkerDrag.current(event)) {
      return true;
    }

    const { coordinate, object, viewport } = info;

    if (!viewport || !coordinate || object.cluster) {
      // Need viewport to handle drag; do not drag clusters
      return true;
    }

    setPointsToReposition(prev => {
      const index = prev.findIndex(p => p.node.id === object.node.id);
      if (index === -1) {
        return prev;
      }

      /**
       * NOTE: This is important to create a deep clone of the object
       * otherwise the original is changed.
       */
      prev[index] = {
        node: {
          ...(structuredClone(object) as IRoutePoint).node,
          position: {
            longitude: coordinate[0],
            latitude: coordinate[1],
          },
        },
      };

      return [...prev];
    });

    return true;
  }, []);

  const cancelRepositioning = useCallback(() => {
    removeFilter(filterName);
    setPointsToReposition([]);
    setRepositionedPointsId([]);
  }, [removeFilter]);

  /**
   * Build layers for repositioned points.
   */
  useEffect(() => {
    const accumulator: ITypedPoints = {
      [RoutePointType.DslrHdPhoto]: [],
      [RoutePointType.DslrPanorama]: [],
      [RoutePointType.Panorama]: [],
      [RoutePointType.ProjectPhoto]: [],
    };

    const typedPoints = pointsToReposition.reduce((accumulator, point) => {
      accumulator[point.node.type].push(point);

      return accumulator;
    }, accumulator);

    Object.keys(typedPoints).forEach(type => {
      const layerBuilder = markerTypeLayerBuilders[type as RoutePointType];

      setLayer(
        `${POINTS_REPOSITION_LAYER_ID}-${type}`,
        layerBuilder(
          typedPoints[type as RoutePointType],
          {
            id: `${POINTS_REPOSITION_LAYER_ID}-${type}`,
            cluster: false,
            visible: true,
            pickable: true,
            onDragStart,
            onDragEnd,
            onDrag,
          },
          null,
        ),
      );
    });
  }, [pointsToReposition, setLayer, markerTypeLayerBuilders, onDragStart, onDragEnd, onDrag]);

  return {
    onDragStart,
    onDrag,
    onDragEnd,
    cancelRepositioning,
    isRepositioningPoints,
    pointsToReposition,
    shouldPreventMarkerDrag,
  };
};

export default useRepositioningFeature;
