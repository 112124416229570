/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer } from '@mui/material';

import clsx from 'clsx';

import { TOP_BAR_HEIGHT } from '../TopBar';

import NavTree from './NavTree';
import style from './NavBar.module.css';

export const NAVBAR_EXPANDED_WIDTH = 256;

function NavBar({ headerItem, navConfig, footerItem, expanded }) {
  const [drawerClasses, setDrawerClasses] = useState();
  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      {headerItem && (
        <>
          {headerItem}
          <Divider className={clsx(expanded ? style.navTreeExpanded : style.navTree)} />
        </>
      )}

      <NavTree navConfig={navConfig} expanded={expanded} />

      {footerItem && (
        <>
          <Divider />
          {footerItem}
        </>
      )}
    </Box>
  );

  useEffect(() => {
    const drawerClass1 = expanded ? style.drawerExpanded : style.drawer;
    const drawerClass2 =
      window.innerWidth <= 460 ? style.drawerPositionMobile : style.drawerPosition;
    setDrawerClasses(`${drawerClass1} ${drawerClass2}`);

    // Outer dependencies updates will not trigger useEffect
    // TODO: Need to remove window.innerWidth from deps and
    // attach event listener for resize event
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, expanded]);

  return (
    <>
      <Box>
        <Drawer
          anchor='left'
          PaperProps={{
            className: drawerClasses,
            sx: {
              top: TOP_BAR_HEIGHT,
              overflow: 'visible',
              width: NAVBAR_EXPANDED_WIDTH,
            },
          }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
}

NavBar.propTypes = {
  headerItem: PropTypes.node,
  navConfig: PropTypes.array,
  footerItem: PropTypes.node,
  onMobileClose: PropTypes.func,
  expanded: PropTypes.bool,
};

export default NavBar;
