import * as Yup from 'yup';

export const phoneValidation = Yup.string().matches(/(\d{3}-\d{3}-\d{4})/g, 'Format: XXX-XXX-XXXX');

export const emailValidation = Yup.string().email('Must be a valid email').max(254);

export const passwordValidation = Yup.string()
  .min(8, 'Must be at least 8 characters long')
  .matches(
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
    'Must contain at least one special character, one number, and both uppercase and lowercase letters',
  );
