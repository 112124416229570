import React, { HTMLAttributes, ReactNode } from 'react';
import { Virtuoso } from 'react-virtuoso';

const style = { height: '40vh' };

/**
 * A custom listbox component for the MUI Autocomplete component.
 * Enables virtualization of the listbox items.
 */
const ListboxComponent = React.forwardRef<HTMLAttributes<HTMLElement>, any>(
  ({ children, ...listboxProps }, ref) => (
    <Virtuoso
      scrollerRef={ref}
      {...listboxProps}
      style={style}
      totalCount={(children as ReactNode[]).length}
      itemContent={index => (children as ReactNode[])[index]}
    />
  ),
);

export default ListboxComponent;
