import { useMemo } from 'react';

import { FieldType } from '../../../features/Data/EntityData/types';
import useLocationMapMetadataOptions from '../../../hooks/useLocationMapMetadataOptions';
import { MediaMetadataType } from '../../../constants/media';

import { IIdsMapEntityViewerProps } from './index';

const useEntityFields = (entity: IIdsMapEntityViewerProps['entity']) => {
  const levelOptions = useLocationMapMetadataOptions(MediaMetadataType.Level);

  const entityLevelId = useMemo(() => {
    return entity.node.metadata.find(item => item.type === MediaMetadataType.Level)?.id || null;
  }, [entity.node.metadata]);

  const entityLevelLabel = useMemo(() => {
    return entityLevelId && levelOptions
      ? levelOptions.find(level => level.id === entityLevelId)?.name
      : '';
  }, [entityLevelId, levelOptions]);

  const entityFields = useMemo(
    () =>
      entity.node.fields
        ? entity.node.fields.map(item => ({
            name: item.id,
            label: item.label,
            data: item.value,
            type: item.type,
            isEditable: false,
          }))
        : [],
    [entity.node.fields],
  );

  return useMemo(
    () => [
      {
        name: 'name',
        label: 'Name',
        data: entity.node.name,
        type: FieldType.Text,
        isEditable: true,
      },
      {
        name: 'levelId',
        label: 'Level',
        data: entityLevelLabel,
        type: FieldType.SingleChoice,
        props: {
          options: levelOptions,
          value: entityLevelId,
        },
        isEditable: true,
      },
      {
        name: 'flagged',
        label: 'Flagged',
        data: entity.node.flagged,
        type: FieldType.Checkbox,
        isEditable: true,
      },
      {
        name: 'color',
        label: 'Color',
        data: entity.node.color,
        type: FieldType.Color,
        isEditable: true,
      },
      {
        name: 'author',
        label: 'Author',
        data: `${entity.node.creator.firstName} ${entity.node.creator.lastName}`,
        type: FieldType.Text,
        isEditable: false,
      },
      {
        name: 'data',
        label: 'Information',
        data: entity.node.data,
        type: FieldType.Text,
        isEditable: true,
      },
      ...entityFields,
    ],
    [entityLevelLabel, entity.node, entityLevelId, levelOptions, entityFields],
  );
};

export default useEntityFields;
