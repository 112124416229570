import React from 'react';
import { Grid, GridProps } from '@mui/material';

const styles = {
  defaultChildGrid: {
    overflow: 'hidden',
  },
};

export interface IIdsListItemChildProps extends GridProps {
  stopPropagation?: boolean;
}

/**
 * To be used within an [IdsListItem](./index.jsx).
 * Wraps content with a [Grid](https://mui.com/components/grid/) component.
 * Props:
 * - `stopPropagation | default: false` - If set, disables `primaryAction` on parent [IdsListItem](./index.jsx)
 * - [Grid](https://mui.com/components/grid/) props
 */
const IdsListItemChild: React.FC<IIdsListItemChildProps> = ({
  children,
  stopPropagation = false,
  sx,
  onClick,
  ...rest
}) => {
  const onChildClick: React.MouseEventHandler<HTMLDivElement> = e => {
    if (onClick) {
      onClick(e);
    }

    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  const gridSx = {
    ...styles.defaultChildGrid,
    ...sx,
  };

  return (
    <Grid item xs sx={gridSx} onClick={onChildClick} {...rest}>
      {children}
    </Grid>
  );
};

export default IdsListItemChild;
