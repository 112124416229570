import React from 'react';

import { useRecoilValue } from 'recoil';

import { OrganizationIcon } from '../../theme/icons';

import { getOrgRoute } from '../../utils/routes';

import { activeTenantState } from '../../atoms/tenants';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface IOrganizationChipProps extends IEntityChipProps {
  organizationId?: string;
}

const OrganizationChip: React.FC<IOrganizationChipProps> = ({ organizationId, ...rest }) => {
  const activeTenant = useRecoilValue(activeTenantState);

  return (
    <EntityChip
      icon={<OrganizationIcon />}
      label={`Organization #${organizationId}`}
      entityRoute={getOrgRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organizationId,
      })}
      {...rest}
    />
  );
};

export default OrganizationChip;
