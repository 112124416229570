import React, { useMemo } from 'react';
import { Grid, Stack, Typography, GridProps } from '@mui/material';

import getAbbreviatedNumber from '../../../utils/getAbbreviatedNumber';
import styles from '../IdsCommentsSection.module.css';
import LinkButton, { ILinkButtonProps } from '../LinkButton';
import { IComment } from '../IdsComment/types';

export interface ICommentsSectionHeaderProps extends GridProps {
  comments: IComment[];
  hideViewAllComments?: boolean;
  hideCreateComment?: boolean;
  onCreateComment?: ILinkButtonProps['onClick'];
  onViewAllComments?: ILinkButtonProps['onClick'];
}

const CommentsSectionHeader: React.FC<ICommentsSectionHeaderProps> = ({
  comments,
  onViewAllComments,
  hideViewAllComments,
  onCreateComment,
  hideCreateComment,
  ...rest
}) => {
  const commentTotalStr = useMemo(() => {
    // Exclude soft deleted comments from comment total
    const totalComments = comments?.filter(c => !c.deleted).length || 0;
    return getAbbreviatedNumber(totalComments);
  }, [comments]);

  return (
    <Grid container direction='row' alignItems='flex-end' {...rest}>
      <Grid item xs='auto'>
        <Typography variant='body2' noWrap className={styles.commentsCount}>
          {commentTotalStr} COMMENTS
        </Typography>
      </Grid>
      <Grid item xs='auto'>
        <Stack direction='row' spacing={1}>
          {!hideViewAllComments && (
            <LinkButton onClick={onViewAllComments}>View All Comments</LinkButton>
          )}
          {!hideCreateComment && <LinkButton onClick={onCreateComment}>Create Comment</LinkButton>}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CommentsSectionHeader;
