import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import clsx from 'clsx';

import { useMemo } from 'react';

import { ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, ArrowUpIcon } from '../../../../theme/icons';
import styles from '../IdsMediaViewer.module.css';

import KRPanoControl from '../../KRPanoControl';
import { TAB_SIZES } from '../MediaViewerTabs';

import navigationArrowsStyles from './NavigationArrows.module.css';

const NavigationArrows = ({
  tabSize,
  mediaViewerTitle,
  items,
  activeMediaIndex,
  select,
  neighbors,
  immersiveMode,
  highlightMediaMarker,
  unhighlightMediaMarker,
  activeId,
}) => {
  const belowImageId = useMemo(
    () => neighbors?.find(neighbor => neighbor?.direction === 'down')?.id || null,
    [neighbors],
  );
  const aboveImageId = useMemo(
    () => neighbors?.find(neighbor => neighbor?.direction === 'up')?.id || null,
    [neighbors],
  );
  const prevImageId = useMemo(() => {
    // Show prev neighbor if provided.
    const prevNeighbor = neighbors?.find(neighbor => neighbor?.direction === 'prev')?.id;
    if (prevNeighbor) {
      return prevNeighbor;
    }

    // Otherwise just cycle through imagery.
    return immersiveMode ? items[activeMediaIndex - 1]?.node?.id : items[activeMediaIndex - 1]?.id;
  }, [neighbors, activeMediaIndex, immersiveMode, items]);
  const nextImageId = useMemo(() => {
    // Show next neighbor if provided.
    const nextNeighbor = neighbors?.find(neighbor => neighbor?.direction === 'next')?.id;
    if (nextNeighbor) {
      return nextNeighbor;
    }

    // Otherwise just cycle through imagery.
    return immersiveMode ? items[activeMediaIndex + 1]?.node?.id : items[activeMediaIndex + 1]?.id;
  }, [neighbors, activeMediaIndex, immersiveMode, items]);

  const PrevBtn = () => (
    <Grid item xs='auto'>
      <KRPanoControl>
        <Button
          onClick={() => select(prevImageId)}
          className={clsx(styles.galleryBtn, styles.galleryHorizontalBtn, styles.galleryBtnBack)}
          onMouseEnter={() =>
            prevImageId !== activeId && immersiveMode ? highlightMediaMarker(prevImageId) : null
          }
          onMouseLeave={() =>
            prevImageId !== activeId && immersiveMode ? unhighlightMediaMarker(prevImageId) : null
          }
        >
          <ArrowLeftIcon />
        </Button>
      </KRPanoControl>
    </Grid>
  );

  const NextBtn = () => (
    <Grid item xs='auto'>
      <KRPanoControl>
        <Button
          onClick={() => select(nextImageId)}
          className={clsx(styles.galleryBtn, styles.galleryHorizontalBtn, styles.galleryBtnNext)}
          onMouseEnter={() =>
            nextImageId !== activeId && immersiveMode ? highlightMediaMarker(nextImageId) : null
          }
          onMouseLeave={() =>
            nextImageId !== activeId && immersiveMode ? unhighlightMediaMarker(nextImageId) : null
          }
        >
          <ArrowRightIcon />
        </Button>
      </KRPanoControl>
    </Grid>
  );

  const UpBtn = () => (
    <KRPanoControl className={styles.galleryBtnUp}>
      <Button
        className={clsx(styles.galleryBtn, styles.galleryVerticalBtn)}
        onClick={() => select(aboveImageId)}
        onMouseEnter={() => (aboveImageId !== activeId ? highlightMediaMarker(aboveImageId) : null)}
        onMouseLeave={() =>
          aboveImageId !== activeId ? unhighlightMediaMarker(aboveImageId) : null
        }
      >
        <ArrowUpIcon />
      </Button>
    </KRPanoControl>
  );

  const DownBtn = () => (
    <KRPanoControl className={styles.galleryBtnDown}>
      <Button
        className={clsx(styles.galleryBtn, styles.galleryVerticalBtn)}
        onClick={() => select(belowImageId)}
        onMouseEnter={() => (belowImageId !== activeId ? highlightMediaMarker(belowImageId) : null)}
        onMouseLeave={() =>
          belowImageId !== activeId ? unhighlightMediaMarker(belowImageId) : null
        }
      >
        <ArrowDownIcon />
      </Button>
    </KRPanoControl>
  );

  return (
    <>
      <Grid item xs container direction='column' justifyContent='flex-start' alignItems='center'>
        <Grid item xs='auto'>
          {immersiveMode && aboveImageId && <UpBtn />}
        </Grid>
        <Grid item xs='auto' textAlign='center'>
          {tabSize !== TAB_SIZES.HIDDEN && mediaViewerTitle && (
            <Box my={1} className={navigationArrowsStyles.itemTitle}>
              <Typography variant='h5'>{mediaViewerTitle}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item xs={immersiveMode ? 'auto' : true} container direction='row' alignItems='center'>
        <Grid item xs container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs='auto'>
            {prevImageId && <PrevBtn />}
          </Grid>
        </Grid>
        <Grid item xs container direction='row' justifyContent='flex-end' alignItems='center'>
          <Grid item xs='auto'>
            {nextImageId && <NextBtn />}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs container direction='column' justifyContent='flex-end' alignItems='center'>
        <Grid item xs='auto' textAlign='center'>
          {tabSize !== TAB_SIZES.HIDDEN && items[activeMediaIndex]?.title && (
            <Box mb={1} className={navigationArrowsStyles.itemTitle}>
              <Typography variant='h5'>{items[activeMediaIndex].title}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs='auto'>
          {immersiveMode && belowImageId && <DownBtn />}
        </Grid>
      </Grid>
    </>
  );
};

export default NavigationArrows;
