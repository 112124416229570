import { useCallback } from 'react';

import useFilterContext from '../useFilterContext';
import { FilterMode } from '../../context/FilterContext';

export const FILTER_NAME = 'items-override';

const useItemsOverrideFilter = () => {
  const { addFilter, removeFilter, getFilter, typeMetadata } = useFilterContext();

  const transformOverridenIds = useCallback(
    (type, transform) => {
      const overrideFilter = getFilter(FILTER_NAME);
      const typedItems = overrideFilter?.selected || {};
      typedItems[type] = transform(typedItems[type] || []);

      const allOverriddenIds = Object.values(typedItems).flat();

      // No overridden data
      if (!allOverriddenIds.length) {
        if (overrideFilter) {
          // Filter had data
          removeFilter(FILTER_NAME);
          return;
        }
      }

      const filterTargets = Object.keys(typedItems).map(type => ({
        type,
        selectFilterData: d => typeMetadata[type].selectId(d),
      }));

      addFilter({
        name: FILTER_NAME,
        label: 'Highlighted Media Override',
        filterItem: id => allOverriddenIds.includes(id),
        selected: typedItems, // Since this filter is invisible, this field can be set to a value other than a string
        targets: filterTargets,
        mode: FilterMode.Override,
        invisible: true, // Don't render in filters list
      });
    },
    [addFilter, removeFilter, getFilter, typeMetadata],
  );

  const addOverrideItem = useCallback(
    (id, type) => {
      const overrideFilter = getFilter(FILTER_NAME);
      if (overrideFilter?.selected[type]?.includes(id)) {
        return;
      }

      const addItem = overridenIds => [...overridenIds, id];

      transformOverridenIds(type, addItem);
    },
    [getFilter, transformOverridenIds],
  );

  const removeOverrideItem = useCallback(
    (id, type) => {
      const overrideFilter = getFilter(FILTER_NAME);
      if (!overrideFilter?.selected[type]?.includes(id)) {
        return;
      }

      const removeItem = overridenIds => overridenIds.filter(oId => oId !== id);

      transformOverridenIds(type, removeItem);
    },
    [getFilter, transformOverridenIds],
  );

  const addOverrideItems = useCallback(
    (itemsToAdd, type) => {
      const overrideFilter = getFilter(FILTER_NAME);
      const alreadyAddedItems = overrideFilter?.selected[type];

      /**
       * Avoid duplicates in the filter.
       */
      const filteredItemsToAdd = alreadyAddedItems
        ? itemsToAdd.filter(id => !alreadyAddedItems.includes(id))
        : itemsToAdd;

      if (filteredItemsToAdd.length === 0) {
        /**
         * Nothing to add to the filter.
         * itemsToAdd already in the filter.
         */
        return;
      }

      const addItems = overridenIds => [...overridenIds, ...filteredItemsToAdd];

      transformOverridenIds(type, addItems);
    },
    [getFilter, transformOverridenIds],
  );

  const removeOverrideItems = useCallback(
    (itemsToRemove, type) => {
      const overrideFilter = getFilter(FILTER_NAME);
      const alreadyAddedItems = overrideFilter?.selected[type];

      if (!alreadyAddedItems) {
        /**
         * The filter is empty. Nothing to remove.
         */
        return;
      }

      const itemsToRemain = alreadyAddedItems.filter(id => !itemsToRemove.includes(id));

      if (alreadyAddedItems.length === itemsToRemain.length) {
        /**
         * Nothing to remove.
         * No intersection between itemsToRemove and alreadyAddedItems.
         */
        return;
      }

      const removeItems = overridenIds => overridenIds.filter(oId => itemsToRemain.includes(oId));

      transformOverridenIds(type, removeItems);
    },
    [getFilter, transformOverridenIds],
  );

  return {
    addOverrideItem,
    removeOverrideItem,
    addOverrideItems,
    removeOverrideItems,
  };
};

export default useItemsOverrideFilter;
