import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Paper, Table, TableBody, TableContainer, Box } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import { useRecoilValue } from 'recoil';

import { MediaMetadataType, MediaType } from '../../../../../constants/media';
import { USER_ROLES } from '../../../../../constants/users';
import IdsEditForm from '../../../../ids-forms/IdsEditForm';
import IdsFormTextField from '../../../../ids-forms/IdsFormTextField';
import IdsFormCheckboxField from '../../../../ids-forms/IdsFormCheckboxField';
import IdsFormAutocompleteField from '../../../../ids-forms/IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../../../ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import { useGetProjectRoute } from '../../../../../services/ProjectService';
import { useGetLocationMetadataTypes } from '../../../../../services/LocationService';
import { getMediaTypeFromUrn } from '../../../../../utils/media';
import queryClient from '../../../../../utils/query';
import { activeOrganizationState } from '../../../../../atoms/organizations';
import useMetadataItems from '../../../../../hooks/useMetadataItems';
import usePermissions from '../../../../../hooks/usePermissions';
import LoadingScreen from '../../../../LoadingScreen';
import ErrorScreen from '../../../../../views/Error';
import { CheckBoxIcon, CheckBoxOutlinedIcon } from '../../../../../theme/icons';

import {
  IImageAvailableCustomFieldMetadata,
  useGetImageCustomFields,
} from '../../../../../services/CustomFieldsService';

import IdsFormCustomField from '../../../../../features/CustomFields/IdsFormCustomField';

import { getInitialValue } from '../../../../../features/CustomFields/utils';

import ImageAttributeTableRow, { IImageAttributeTableRowProps } from './ImageAttributeTableRow';
import styles from './ImageInfoTable.module.css';
import { createValidate } from './validation';
import {
  CUSTOM_FIELDS_MUTATION_MAPPING,
  EDITABLE_TYPES_MAPPING,
  EditableMediaType,
  IFormValues,
  isCorrectMediaType,
  MEDIA_TYPE_EDITABLE_METADATA,
  METADATA_TYPES_MAPPING,
  MetadataTypes,
  MUTATION_RESULT_MAPPING,
} from './types';

export interface IImageInfoTableProps
  extends Pick<IImageAttributeTableRowProps, 'singleColumnLayout'> {
  activeImage: any;
  getMetadataValue: (type: MediaMetadataType) => any;
  onResize: () => any;
  toggleEditMode: () => any;
  isEditMode: boolean;
  tenantId: string | undefined;
  onImageInfoUpdate?: (id: string, result: Record<string, any>) => void;
}

const getImageMetadata = (type: MediaMetadataType, activeImage: any) => {
  return activeImage.metadata?.find((d: any) => d.type === type)?.id || null;
};

const defaultMetadata: any[] = [];

const ImageInfoTable: React.FC<IImageInfoTableProps> = ({
  activeImage,
  singleColumnLayout,
  getMetadataValue,
  onResize,
  toggleEditMode,
  isEditMode,
  tenantId,
  onImageInfoUpdate,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { userHasOneOfRoles } = usePermissions();
  const showPublishedField = useMemo(() => {
    const roles = userHasOneOfRoles([
      USER_ROLES.IDS_ADMIN,
      USER_ROLES.IDS_TEAM,
      USER_ROLES.TENANT_ADMIN,
      USER_ROLES.TENANT_TEAM,
    ]);
    if (!roles) {
      return false;
    }

    const imageType = activeImage?.id ? getMediaTypeFromUrn(activeImage.id) : false;
    if (!imageType) {
      return false;
    }

    return [MediaType.HDPhoto, MediaType.PanoramicPhoto].includes(imageType as MediaType);
  }, [userHasOneOfRoles, activeImage?.id]);

  const { ref: infoTableContainerRef } = useResizeDetector({
    onResize,
    handleHeight: false,
  });

  const {
    data: customFields,
    isLoading: isLoadingCustomFields,
    error: errorCustomFields,
  } = useGetImageCustomFields(activeImage?.id);

  /**
   * Using defaultMetadata is a workaround for useEffect's dependencies array,
   * because just "customFieldMetadata = []" creates a new const every time and
   * this triggers useEffect.
   */
  const {
    image: {
      availableCustomFieldMetadata = defaultMetadata,
      customFieldMetadata = defaultMetadata,
    } = {},
  } = customFields || {};

  const {
    data: projectRoute,
    isLoading: isLoadingProjectRoute,
    error: errorProjectRoute,
  } = useGetProjectRoute(activeImage.projectId, {
    enabled: !!activeImage.projectId && !!tenantId,
  });

  const {
    data: locationMetadataTypes,
    isLoading: isLoadingLocationMetadataTypes,
    isError: isErrorLocationMetadataTypes,
  } = useGetLocationMetadataTypes(
    projectRoute?.project.locationId ?? activeImage.locationId ?? null,
  );

  const { allLevels, allAreas, allTypes, allCategories } = useMetadataItems(
    locationMetadataTypes?.location.metadataTypes || [],
  );

  const [initialValues, setInitialValues] = useState<IFormValues>({
    [MediaMetadataType.PhotoType]: '',
    [MediaMetadataType.Level]: '',
    [MediaMetadataType.PhotoCategory]: '',
    [MediaMetadataType.Area]: '',
    description: '',
    customFields: {},
    ...(showPublishedField && { published: '' }),
  });

  useEffect(() => {
    const newInitialValues: IFormValues = {
      [MediaMetadataType.PhotoType]: getImageMetadata(MediaMetadataType.PhotoType, activeImage),
      [MediaMetadataType.Level]: getImageMetadata(MediaMetadataType.Level, activeImage),
      [MediaMetadataType.Area]: getImageMetadata(MediaMetadataType.Area, activeImage),
      [MediaMetadataType.PhotoCategory]: getImageMetadata(
        MediaMetadataType.PhotoCategory,
        activeImage,
      ),
      description: activeImage.description,
      customFields: {},
      ...(showPublishedField && { published: activeImage.published }),
    };

    // Custom fields initialization
    if (availableCustomFieldMetadata.length) {
      availableCustomFieldMetadata.forEach(customField => {
        newInitialValues.customFields[customField.id] = getInitialValue(
          customField.frontendType,
          customField.multipleValuesAllowed,
          customFieldMetadata.filter(it => it.type === customField.type),
        );
      });
    }

    setInitialValues(newInitialValues);
  }, [activeImage, customFieldMetadata, availableCustomFieldMetadata, showPublishedField]);

  const validate = useMemo(
    () => createValidate(availableCustomFieldMetadata, initialValues),
    [availableCustomFieldMetadata, initialValues],
  );

  const metadataFields = useMemo(
    () => [
      {
        label: 'Photo type',
        name: MediaMetadataType.PhotoType,
        noOptionsText: 'No photo types found',
        options: allTypes,
      },
      {
        label: 'Level',
        name: MediaMetadataType.Level,
        noOptionsText: 'No levels found',
        options: allLevels,
      },
      {
        label: 'Area',
        name: MediaMetadataType.Area,
        noOptionsText: 'No areas found',
        options: allAreas,
      },
      {
        label: 'Category',
        name: MediaMetadataType.PhotoCategory,
        noOptionsText: 'No photo types found',
        options: allCategories,
      },
    ],
    [allLevels, allAreas, allCategories, allTypes],
  );

  const urn = activeImage.id;
  const mediaType: EditableMediaType = getMediaTypeFromUrn(urn) as EditableMediaType;

  if (!isCorrectMediaType(mediaType)) {
    return <ErrorScreen />;
  }

  const updateMutation = EDITABLE_TYPES_MAPPING[mediaType]();
  const updateCustomFieldsMutation = CUSTOM_FIELDS_MUTATION_MAPPING[mediaType](urn);

  const renderMetadataRow = (
    label: string,
    name: MetadataTypes,
    options: { id: string; name: string }[],
    noOptionsText: string,
  ) => (
    <ImageAttributeTableRow
      key={name}
      label={label}
      value={
        isEditMode && MEDIA_TYPE_EDITABLE_METADATA[mediaType].includes(name) ? (
          <IdsFormAutocompleteField
            name={name}
            required
            options={options}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsText={noOptionsText}
            disableClearable={true}
            renderOption={(props, o) => (
              <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
            )}
          />
        ) : (
          getMetadataValue(name)
        )
      }
      singleColumnLayout={singleColumnLayout}
    />
  );

  const renderCustomFieldRow = (customField: IImageAvailableCustomFieldMetadata) => (
    <ImageAttributeTableRow
      label={customField.frontendLabel}
      value={
        isEditMode ? (
          <IdsFormCustomField
            name={`customFields.${customField.id}`}
            frontendLabel={customField.frontendLabel}
            frontendType={customField.frontendType}
            required={customField.required}
            values={customField.values}
            customValueAllowed={false}
            multipleValuesAllowed={customField.multipleValuesAllowed}
          />
        ) : (
          customFieldMetadata
            .filter(it => it.type === customField.type)
            .map(it => it.value)
            .join(', ')
        )
      }
      singleColumnLayout={singleColumnLayout}
    />
  );

  if (isErrorLocationMetadataTypes || errorProjectRoute || errorCustomFields) {
    return <ErrorScreen />;
  }

  if (isLoadingLocationMetadataTypes || isLoadingProjectRoute || isLoadingCustomFields) {
    return <LoadingScreen />;
  }

  const onSubmit = async (values: IFormValues) => {
    const { description, published, customFields, ...metadata } = values;

    const newMetadata = Object.entries(metadata).reduce((accumulator: any[], [key, value]) => {
      if (value) {
        accumulator.push({
          id: value,
          type: key,
        });
      }

      return accumulator;
    }, []);

    const otherTags = activeImage.metadata.reduce((accumulator: any[], item: any) => {
      if (!(item.type in METADATA_TYPES_MAPPING)) {
        accumulator.push({
          id: item.id,
          type: item.type,
        });
      }

      return accumulator;
    }, []);

    const result = await updateMutation.mutateAsync({
      id: urn,
      organizationId: activeOrg.id,
      input: {
        description,
        // check if boolean
        ...((published === true || published === false) && { published }),
        metadata: [...newMetadata, ...otherTags],
      },
    });

    if (result[MUTATION_RESULT_MAPPING[mediaType]]?.errors?.length) {
      throw new Error(result[MUTATION_RESULT_MAPPING[mediaType]].errors[0].message);
    }

    // Updating custom fields
    const customFieldsMetadata = Object.entries(values.customFields)
      // @TODO: Remove, when types besides 'select', 'autoselect', 'radio' and 'checkbox' will be available
      // (types which are by nature "custom values").
      // Right now structure of resolvers and update mutations are not ready for
      // handling custom values
      .filter(([_, value]) => Array.isArray(value) || (value && typeof value === 'string'))
      .reduce((sum, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(it => sum.push({ fieldId: key, id: it }));
        } else {
          sum.push({ fieldId: key, id: value });
        }

        return sum;
      }, [] as any);
    const responseUpdateCustomFields = await updateCustomFieldsMutation.mutateAsync({
      id: urn,
      orgId: activeOrg.id,
      customFieldMetadata: customFieldsMetadata,
    });

    if (responseUpdateCustomFields[MUTATION_RESULT_MAPPING[mediaType]]?.errors?.length) {
      throw new Error(
        responseUpdateCustomFields[MUTATION_RESULT_MAPPING[mediaType]].errors[0].message,
      );
    }

    if (onImageInfoUpdate) {
      onImageInfoUpdate(urn, result);
      onImageInfoUpdate(urn, responseUpdateCustomFields);
    }

    if (
      initialValues.description !== values.description ||
      initialValues.published !== values.published
    ) {
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes('activeImage') || query.queryKey.includes('activeImageMap'),
        refetchActive: true,
      });
    }

    toggleEditMode();
  };

  const content = (
    <TableContainer component={Paper} ref={infoTableContainerRef} className={styles.container}>
      <Table size='small'>
        <TableBody>
          <ImageAttributeTableRow
            label='Date Captured'
            value={new Date(activeImage.capturedAt).toLocaleString()}
            singleColumnLayout={singleColumnLayout}
          />
          <ImageAttributeTableRow
            label='Project'
            value={projectRoute?.project.name || ''}
            singleColumnLayout={singleColumnLayout}
          />
          {showPublishedField && (
            <ImageAttributeTableRow
              label='Published'
              value={
                isEditMode ? (
                  <IdsFormCheckboxField
                    name='published'
                    required
                    disableRipple={true}
                    sx={{ paddingY: 0 }}
                  />
                ) : (
                  <Box display='table-cell'>
                    {activeImage.published ? (
                      <CheckBoxIcon fontSize='small' sx={{ verticalAlign: 'top' }} />
                    ) : (
                      <CheckBoxOutlinedIcon fontSize='small' sx={{ verticalAlign: 'top' }} />
                    )}
                  </Box>
                )
              }
              singleColumnLayout={singleColumnLayout}
            />
          )}
          {/* Render fields: Area, Level, Category */}
          {metadataFields.map(({ label, name, noOptionsText, options }) =>
            renderMetadataRow(label, name, options, noOptionsText),
          )}

          {/* Custom fields rendering */}
          {customFieldMetadata &&
            availableCustomFieldMetadata &&
            availableCustomFieldMetadata.map(it => (
              <Fragment key={it.id}>{renderCustomFieldRow(it)}</Fragment>
            ))}

          <ImageAttributeTableRow
            label='Description'
            value={
              isEditMode ? (
                <IdsFormTextField name='description' required />
              ) : (
                activeImage?.description
              )
            }
            singleColumnLayout={singleColumnLayout}
          />
          <ImageAttributeTableRow
            label='Latitude'
            value={activeImage?.position?.latitude}
            singleColumnLayout={singleColumnLayout}
          />
          <ImageAttributeTableRow
            label='Longitude'
            value={activeImage?.position?.longitude}
            singleColumnLayout={singleColumnLayout}
          />
          <ImageAttributeTableRow
            label='Author'
            value={`${activeImage?.author?.firstName} ${activeImage?.author?.lastName}`}
            singleColumnLayout={singleColumnLayout}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (isEditMode) {
    return (
      <IdsEditForm
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        onCancel={toggleEditMode}
        successMessage='Image info updated'
        errorHandler={error => error || 'Image info could not be updated'}
        validate={validate}
      >
        {content}
      </IdsEditForm>
    );
  }

  return content;
};

export default ImageInfoTable;
