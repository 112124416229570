import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import TopBar from '../../components/TopBar';
import Page from '../../components/Page';

import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../theme/styles';

import styles from './NoTenantDashboardLayout.module.css';

export interface INoTenantDashboardLayoutProps {
  children?: React.ReactNode;
}

const NoTenantDashboardLayout: React.FC<INoTenantDashboardLayoutProps> = ({ children }) => {
  return (
    <Page title='Tenant Dashboard'>
      <Box className={styles.root}>
        <TopBar children={undefined} hideUploads={true} />
        <Box className={styles.wrapper}>
          <Box className={styles.contentContainer}>
            <Box className={styles.content} style={{ height: FULL_HEIGHT_WITHOUT_TOP_BAR }}>
              {children}
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default NoTenantDashboardLayout;
