import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useGetLocationProjects } from '../../../../services/LocationService';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeTenantState } from '../../../../atoms/tenants';
import { activeLocationState } from '../../../../atoms/locations';
import { FILTER_MODES } from '../../../../components/ids-lists/IdsSearchableList';
import { getOrgProjectEditRoute, getOrgProjectRoute } from '../../../../utils/routes';
import ProjectsList from '../../../Projects/ProjectsList';
import ProjectListItem from '../../../Projects/ProjectListItem';

function LocationProjectsTab() {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const navigate = useNavigate();

  const { data, error, isLoading } = useGetLocationProjects(activeLocation.id);

  const openProject = useCallback(
    id => {
      navigate(
        getOrgProjectRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          projectId: id,
        }),
      );
    },
    [navigate, activeOrg.id, activeTenant],
  );

  const editProject = useCallback(
    id => {
      navigate(
        getOrgProjectEditRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          projectId: id,
        }),
      );
    },
    [navigate, activeOrg.id, activeTenant],
  );

  return (
    <ProjectsList
      projectItems={data}
      renderProjectItem={projectItem => (
        <ProjectListItem
          key={projectItem.node.id}
          projectItem={projectItem}
          onOpen={() => openProject(projectItem.node.id)}
          onEdit={() => editProject(projectItem.node.id)}
          showLocationName={false}
        />
      )}
      filterMode={FILTER_MODES.SELECT}
      loading={isLoading}
      error={error && 'Projects could not be loaded'}
    />
  );
}

export default LocationProjectsTab;
