import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useAssignmentKeys } from '../../services/AssignmentsService';
import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';
import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';

import { activeProjectAssignmentState, activeProjectState } from '../../atoms/projects';

import { getOrgProjectRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const AssignmentRouteQuery = gql`
  query AssignmentRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignment(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      title
    }
  }
`;

export const ProjectAssignmentRoute: React.FC = () => {
  const assignmentKeys = useAssignmentKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrganization = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);
  const [activeProjectAssignment, setActiveProjectAssignment] = useRecoilState(
    activeProjectAssignmentState,
  );

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useProjectAssignment = (id: string | undefined, options: UseGraphQueryOptions) =>
    useOrgGraphQuery(assignmentKeys.route(id!), AssignmentRouteQuery, { id }, options);

  return (
    <EntityRoute
      idParam={'assignmentId'}
      currentEntity={activeProjectAssignment}
      setEntity={setActiveProjectAssignment}
      useEntity={useProjectAssignment}
      entitySelector={data => data?.assignment}
      errorRedirectRoute={`${getOrgProjectRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrganization.id,
        projectId: activeProject?.id,
      })}?tab=assignments`}
    />
  );
};

export default ProjectAssignmentRoute;
