import React from 'react';

import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';

import Actions from './Actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentListHeaderProps {}

const AssignmentListHeader: React.FC<IAssignmentListHeaderProps> = () => {
  return <NestedOrgViewHeader currentCrumb='Assignments' actions={<Actions />} />;
};

export default AssignmentListHeader;
