import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';

import IdsFormAutocompleteField from '../../../ids-forms/IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../../ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import IdsFormCheckboxField from '../../../ids-forms/IdsFormCheckboxField';
import IdsFormColorField from '../../../ids-forms/IdsFormColorField';
import IdsFormTextField from '../../../ids-forms/IdsFormTextField';
import useLocationMapMetadataOptions from '../../../../hooks/useLocationMapMetadataOptions';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';

import styles from './EntityOfInterestInfo.module.css';

export const labelColor = '#00407A';

const sx = {
  table: {
    [`& .${tableCellClasses.root}`]: {
      borderBottom: 'none',
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: '1rem',
      textAlign: 'left',
    },
    [`& .${tableCellClasses.root}:first-of-type`]: {
      color: labelColor,
    },
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
  flaggedCheckbox: {
    color: 'ids.marker.markerDotFlagged',
    '&.Mui-checked': { color: 'ids.marker.markerDotFlagged' },
  },
};

const EOIInfoDetails = ({
  name,
  nameFieldProps,
  data,
  dataFieldProps,
  color,
  colorFieldProps,
  firstName,
  lastName,
  isEditMode,
  levelId,
  levelFieldProps,
  flaggedFieldProps,
  children,
}) => {
  const levelOptions = useLocationMapMetadataOptions(
    MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
  );
  const levelLabel = useMemo(
    () => (levelId ? levelOptions.find(level => level.id === levelId).name : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [levelId, levelOptions.length],
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={sx.table}>
        <TableBody>
          <TableRow>
            <TableCell className={styles.fieldLabelCell}>Name</TableCell>
            {!isEditMode ? (
              <TableCell className={styles.horizontalTextOverflow} title={name}>
                {name}
              </TableCell>
            ) : (
              <TableCell>
                <IdsFormTextField name='name' label='' required {...nameFieldProps} />
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell className={styles.fieldLabelCell}>Level</TableCell>
            {!isEditMode ? (
              <TableCell>{levelLabel}</TableCell>
            ) : (
              <TableCell>
                <IdsFormAutocompleteField
                  name='levelId'
                  label=''
                  required
                  options={levelOptions}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  noOptionsText='No levels found'
                  renderOption={(props, o) => (
                    <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
                  )}
                  {...levelFieldProps}
                />
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell className={styles.fieldLabelCell}>Flagged</TableCell>
            <TableCell>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <IdsFormCheckboxField
                  className={styles.zeroVerticalPadding}
                  disabled={!isEditMode}
                  name='flagged'
                  label=''
                  required
                  {...(isEditMode && { sx: sx.flaggedCheckbox })}
                  {...flaggedFieldProps}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.fieldLabelCell}>Color</TableCell>
            <TableCell>
              {!isEditMode ? (
                <Box bgcolor={color} color={color}>
                  Color
                </Box>
              ) : (
                <Box>
                  <IdsFormColorField
                    disabled={!isEditMode}
                    name='color'
                    label=''
                    required
                    {...colorFieldProps}
                  />
                </Box>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.fieldLabelCell}>Author</TableCell>
            <TableCell>{`${firstName} ${lastName}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={clsx(styles.tableCellVerticalAlignTop, styles.fieldLabelCell)}>
              Information
            </TableCell>
            {!isEditMode ? (
              <TableCell>{data}</TableCell>
            ) : (
              <TableCell>
                <IdsFormTextField
                  name='data'
                  label=''
                  required
                  multiline
                  minRows={4}
                  maxRows={4}
                  {...dataFieldProps}
                />
              </TableCell>
            )}
          </TableRow>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

EOIInfoDetails.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  levelId: PropTypes.string,
  levelFieldProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  nameFieldProps: PropTypes.object,
  data: PropTypes.string.isRequired,
  dataFieldProps: PropTypes.object,
  color: PropTypes.string.isRequired,
  colorFieldProps: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  flaggedFieldProps: PropTypes.object,
};

export default EOIInfoDetails;
