import React from 'react';

import IdsSearchableList, { FILTER_MODES } from '../../../components/ids-lists/IdsSearchableList';
import { IAsset } from '../../../services/types';

export interface IAssetsListProps {
  items: IAsset[];
  renderItem: (item: IAsset) => React.ReactNode;
  filterMode: (typeof FILTER_MODES)[keyof typeof FILTER_MODES];
  loading: boolean;
  error: string | null;
  actions?: React.ReactNode;
}

const sortItems = (a: IAsset, b: IAsset) => {
  return a.name.localeCompare(b.name); // alphabetical order
};

const filterItems = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
];

const searchItem = (item: IAsset, query: string) => {
  const searchProps = [item.name];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

const AssetsList: React.FC<IAssetsListProps> = ({ items, renderItem, actions = null, ...rest }) => {
  return (
    <IdsSearchableList
      header={undefined}
      actions={actions}
      items={items}
      renderItem={renderItem}
      searchPlaceholder='Search assets'
      noItemsMessage='No assets'
      noItemsForFilterMessage='No assets match your search'
      sortItems={sortItems}
      filters={filterItems}
      searchItem={searchItem}
      {...rest}
    />
  );
};

export default AssetsList;
