import React, { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import IdsIFrameDialog from '../components/IdsIFrameDialog';
import { getLegacyViewerUrl } from '../utils/legacyViewer';

const LegacyViewerContext = createContext();

export function LegacyViewerProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [_route, setRoute] = useState('');
  const link = useMemo(() => getLegacyViewerUrl(_route), [_route]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const openLegacyViewer = useCallback(
    route => {
      setRoute(route);
      setOpen(true);
    },
    [setRoute, setOpen],
  );

  return (
    <LegacyViewerContext.Provider value={{ openLegacyViewer }}>
      <IdsIFrameDialog open={open} onClose={handleClose} dialogKey='viewer1' src={link} />
      {children}
    </LegacyViewerContext.Provider>
  );
}

LegacyViewerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LegacyViewerConsumer = LegacyViewerContext.Consumer;

export default LegacyViewerContext;
