import React from 'react';

import IdsDialog, { IIdsDialogProps } from '../../../../../../../../components/IdsDialog';

import CopyRouteForm, { ICopyRouteFormProps } from './CopyRouteForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICopyRouteDialogProps extends IIdsDialogProps, ICopyRouteFormProps {}

const CopyRouteDialog: React.FC<ICopyRouteDialogProps> = ({
  open,
  onOpen,
  onClose,
  item,
  ...rest
}) => {
  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='Copy Route'
      dialogKey={`copyRoute-${item.id}`}
      maxWidth='lg'
      fullWidth
      {...rest}
    >
      <CopyRouteForm item={item} handleClose={onClose} />
    </IdsDialog>
  );
};

export default CopyRouteDialog;
