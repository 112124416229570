import * as Yup from 'yup';

export const OVERLAY_NAME_CHAR_LIMIT = 20; // According to API response when name is longer

const rasterOverlayValidation = {
  overlayName: Yup.string()
    .required('Name is required')
    .max(OVERLAY_NAME_CHAR_LIMIT, 'Must be at most 20 characters'),
  levelId: Yup.string().typeError('Level is required').required('Level is required'),
  defaultEnabled: Yup.boolean().typeError('Default is required').required('Default is required'),
};

const createRasterOverlayValidation = { ...rasterOverlayValidation };

export const createRasterOverlayValidationSchema = Yup.object().shape(
  createRasterOverlayValidation,
);
