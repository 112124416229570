const getHTMLStringProps = (tagName, htmlStr) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlStr, 'text/html');

  const elem = htmlDoc.getElementsByTagName(tagName)[0];
  return elem?.getAttributeNames().reduce((embedProps, attr) => {
    embedProps[attr] = elem.getAttribute(attr);
    return embedProps;
  }, {});
};

export default getHTMLStringProps;
