import { Field, FieldProps } from 'formik';

import CustomField, { ICustomFieldProps } from '../CustomField';

export interface IIdsFormCustomFieldProps
  extends Omit<ICustomFieldProps, 'value' | 'error' | 'touched' | 'onChange'> {
  name: string;
  validate?: (value: any) => string | undefined | void;
}

export const IdsFormCustomField: React.FC<IIdsFormCustomFieldProps> = ({
  name,
  validate,
  required,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate} required={required}>
      {({ field, meta, form }: FieldProps) => {
        return (
          <CustomField
            {...rest}
            required={required}
            onChange={async v => {
              await form.setFieldTouched(name, true, true);
              const errors = await form.setFieldValue(name, v, true);
              // Formik is a bit weird sometimes...
              // It does not automatically validate for some reason...
              // So here just setting errors manually
              form.setErrors(errors as any);
            }}
            value={field.value}
            error={meta.error}
            touched={meta.touched}
          />
        );
      }}
    </Field>
  );
};

export default IdsFormCustomField;
