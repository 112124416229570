import React from 'react';
import { Grid, Typography } from '@mui/material';

import IdsDialog, { IIdsDialogProps } from '../../../../../components/IdsDialog';
import IdsCreateForm from '../../../../../components/ids-forms/IdsCreateForm';
import IdsFormFileSelectField from '../../../../../components/ids-forms/IdsFormFileSelectField';
import { useUploadAssignmentsCsv } from '../../../../../services/AssignmentsService';

import { validationSchema } from './validation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICsvUploadFormProps extends Pick<IIdsDialogProps, 'open' | 'onClose'> {}

interface IFormValues {
  file: FileList | null;
}

const CsvUploadForm: React.FC<ICsvUploadFormProps> = ({ open, onClose }) => {
  const uploadAssignmentsCsvMutation = useUploadAssignmentsCsv();

  const handleSubmit = async (values: IFormValues) => {
    const { file: files } = values;

    const file = files ? files[0] : null;
    if (!file) {
      throw new Error('CSV file is required');
    }

    try {
      await uploadAssignmentsCsvMutation.mutateAsync(file);
    } catch (e: any) {
      const { status } = e?.response || {};

      // Resource cannot be processed because of incorrect data.
      if (status === 422) {
        throw new Error('Assignment was not created. Please check your email for more details.');
      }

      throw new Error('Something went wrong');
    }

    // Close the modal in case of success.
    if (onClose) {
      onClose();
    }
  };

  return (
    <IdsDialog
      open={open}
      onClose={onClose}
      title='Create new assignments'
      dialogKey='assignments-csv-uploader'
      maxWidth='md'
      fullWidth
      noSearchParam
    >
      <Grid container spacing={2} direction='column' pr={1} pl={2} pt={2}>
        <Grid item xs='auto'>
          <Typography variant='body1'>Allowed format for upload file is .csv</Typography>
        </Grid>
        <Grid item xs='auto'>
          <Typography variant='body1'>Maximum file upload time: 30 minutes</Typography>
        </Grid>
        <Grid item xs='auto'>
          <IdsCreateForm
            initialValues={{
              file: null,
            }}
            validationSchema={validationSchema}
            successMessage='CSV file was uploaded'
            errorHandler={errorMessage => errorMessage || 'Something went wrong'}
            onSubmit={handleSubmit}
            onCancel={onClose}
          >
            <Grid container spacing={2} direction='column'>
              <Grid item xs='auto'>
                <IdsFormFileSelectField
                  name='file'
                  required
                  accept='.csv'
                  label='Assignment CSV file'
                />
              </Grid>
            </Grid>
          </IdsCreateForm>
        </Grid>
      </Grid>
    </IdsDialog>
  );
};

export default CsvUploadForm;
