import React, { useState } from 'react';
import { useLocation, matchPath, NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: theme => ({
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    color: theme.palette.text.secondary,
  }),
  buttonLeaf: theme => ({
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  }),
  icon: (theme, isActive) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    ...(isActive && { color: theme.palette.secondary.main }),
  }),
  title: (theme, depth, isActive) => ({
    marginRight: 'auto',
    ...((depth === 0 || isActive) && {
      fontWeight: theme.typography.fontWeightMedium,
    }),
    ...(isActive && { color: theme.palette.secondary.main }),
  }),
};

function NavItem({
  title,
  href,
  depth,
  children,
  visible,
  icon: Icon,
  open: openProp,
  info: Info,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);
  const location = useLocation();
  const active = matchPath(location.pathname, href, true) !== null;
  const theme = useTheme();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 9;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const linkStyle = { paddingLeft };

  if (!visible) return null;

  if (children) {
    return (
      <ListItem sx={styles.item} disableGutters key={title} {...rest}>
        <Button sx={styles.button} onClick={handleToggle} style={linkStyle}>
          {Icon && <Icon style={styles.icon(theme, active)} size='20' />}
          <span style={styles.title(theme, depth, active)}>{title}</span>
          {open ? (
            <ExpandLessIcon size='small' color='inherit' />
          ) : (
            <ExpandMoreIcon size='small' color='inherit' />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem sx={styles.itemLeaf} disableGutters key={title} {...rest}>
      <Button sx={styles.buttonLeaf} component={RouterLink} style={linkStyle} to={href}>
        {Icon && <Icon style={styles.icon(theme, active)} size='20' />}
        <span style={styles.title(theme, depth, active)}>{title}</span>
        {Info && <Info sx={styles.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
