import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/users';
import { sessionState } from '../../../atoms/session';

const ALLOWED_ROLES = [USER_ROLES.IDS_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.TENANT_ADMIN];

const useCanModifyEntity = (creatorId: string): boolean => {
  const { userHasOneOfRoles } = usePermissions();
  const session = useRecoilValue(sessionState);

  return useMemo(
    () => userHasOneOfRoles(ALLOWED_ROLES) || creatorId === session?.id,
    [userHasOneOfRoles, creatorId, session?.id],
  );
};

export default useCanModifyEntity;
