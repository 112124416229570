import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Uppy, { UppyEventMap } from '@uppy/core';
import GoldenRetriever from '@uppy/golden-retriever';

import { uploadQueueAnchorElState, uploadQueueOpenState } from '../atoms/uploadQueue';
import { IPluginOptions, UploadType, IUploadContext } from '../constants/uploads';
import { UPLOADERS } from '../constants/uploaders';
import useObservableStates, { useObservableItemValue } from '../hooks/useObservableStates';
import { closeUppy, createUppy, getUppyConsumerKey } from '../utils/uppyInstanceManager';
import { getGoldenRetrieverId } from '../components/ids-inputs/uploaders/IdsUploader';
import UploadQueue from '../components/ids-inputs/uploaders/UploadQueue';
import { UploadStatus } from '../constants/uploads';
import useUploadQueueChannel from '../hooks/useUploadQueueChannel';
import usePrevious from '../hooks/usePrevious';
import { sessionState } from '../atoms/session';

if ('serviceWorker' in navigator) {
  // sw.js should be created in the repo public folder and the contents should be extracted from the file being imported in the docs example: https://uppy.io/docs/golden-retriever/#enabling-service-worker
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/sw.js`) // path to your bundled service worker with GoldenRetriever service worker
    .then(registration => {
      // eslint-disable-next-line no-console
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.log(`Registration failed with ${error}`);
    });
}

const UPLOAD_QUEUE_ID = 'queue';
const QUEUE_STORAGE_KEY = 'ids-upload-queue';

/** How long in milliseconds the queued upload should be tracked for recovery before it is discarded. */
export const QUEUED_UPLOAD_LIFETIME_MS = 24 * 60 * 60 * 1000; // 24 hours

export interface IQueuedUpload {
  uppy: Uppy;
  uploadType: UploadType;
  status: UploadStatus;
  pluginOptions: IPluginOptions;
  fileCount: number;
  context: IUploadContext;
  creatorId: string;
  expirationTimeMS: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEventCallbacks extends Partial<UppyEventMap> {}

interface IUploadQueueState {
  queue: IQueuedUpload[];
}

interface ILocalQueuedUpload {
  uppyId: string;
  uploadType: UploadType;
  status: UploadStatus;
  consumerKey: string;
  pluginOptions: IPluginOptions;
  fileCount: number;
  context: IUploadContext;
  creatorId: string;
  expirationTimeMS: number;
}

// Get type of GoldenRetriever options (they are not exported)
type GoldenRetrieverOptions = NonNullable<ConstructorParameters<typeof GoldenRetriever>[1]>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUploadQueueGoldenRetrieverOptions
  extends Omit<GoldenRetrieverOptions, 'id' | 'expires'> {}

const getLocalQueue = () => {
  const localQueueStr = localStorage.getItem(QUEUE_STORAGE_KEY);
  return (localQueueStr ? JSON.parse(localQueueStr) : []) as ILocalQueuedUpload[];
};

const setLocalQueue = (localQueue: ILocalQueuedUpload[]) => {
  localStorage.setItem(QUEUE_STORAGE_KEY, JSON.stringify(localQueue));
};

const updateLocalQueue = (queue: IQueuedUpload[]) => {
  const localQueue: ILocalQueuedUpload[] = queue.map(
    q =>
      ({
        uppyId: q.uppy.getID(),
        uploadType: q.uploadType,
        status: q.status,
        consumerKey: getUppyConsumerKey(q.uppy),
        pluginOptions: q.pluginOptions,
        fileCount: q.fileCount,
        context: q.context,
        creatorId: q.creatorId,
        expirationTimeMS: q.expirationTimeMS,
      } as ILocalQueuedUpload),
  );
  setLocalQueue(localQueue);
};
export interface UploadQueueContextType {
  enqueueUpload: (uppy: Uppy, uploadType: UploadType, eventCallbacks?: IEventCallbacks) => void;
  setAnchorElement: (anchorEl: HTMLElement | null) => void;
  toggleUploadQueueOpen: () => void;
  openUploadQueue: () => void;
  closeUploadQueue: () => void;
  useQueue: () => IQueuedUpload[];
}

const UploadQueueContext = createContext<UploadQueueContextType | null>(null);

export interface UploadQueueProviderProps {
  children: React.ReactNode;
}

export const UploadQueueProvider: React.FC<UploadQueueProviderProps> = ({ children }) => {
  const setAnchorElement = useSetRecoilState(uploadQueueAnchorElState);
  const setOpen = useSetRecoilState(uploadQueueOpenState);
  const session = useRecoilValue(sessionState);
  const prevSession = usePrevious(session);
  const isMasterQueue = useRef(false);

  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();

  // IMPORTANT: this needs to be used here instead of in recoil or react state to keep the uppy object mutable
  const useQueue = () =>
    useObservableItemValue<IUploadQueueState>({
      id: UPLOAD_QUEUE_ID,
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    })?.queue || [];

  const getQueue = useCallback(
    () => getItemState<IUploadQueueState>(UPLOAD_QUEUE_ID)?.queue || [],
    [getItemState],
  );

  const setQueue = useCallback(
    (queue: IQueuedUpload[], updateLocal = true) => {
      setItemState<IUploadQueueState>(UPLOAD_QUEUE_ID, { queue });
      if (updateLocal && isMasterQueue.current) {
        updateLocalQueue(queue);
      }
    },
    [setItemState],
  );

  const updateQueuedUpload = useCallback(
    (queuedUpload: IQueuedUpload) => {
      const newQueue = [...getQueue()];
      const index = newQueue.findIndex(q => q.uppy.getID() === queuedUpload.uppy.getID());
      if (index >= 0) {
        newQueue[index] = queuedUpload;
        setQueue(newQueue);
      }
    },
    [getQueue, setQueue],
  );

  const toggleUploadQueueOpen = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, [setOpen]);

  const openUploadQueue = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeUploadQueue = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const _enqueueUpload = useCallback(
    (queuedUpload: IQueuedUpload, updateLocal = true, eventCallbacks?: IEventCallbacks) => {
      const _queue = getQueue();

      // Upload is already queued
      if (_queue.some(q => q.uppy.getID() === queuedUpload.uppy.getID())) return;

      const setUploadingStatus = () =>
        updateQueuedUpload({ ...queuedUpload, status: UploadStatus.Uploading });

      queuedUpload.uppy.on('upload', setUploadingStatus);
      queuedUpload.uppy.on('retry-all', setUploadingStatus);

      if (eventCallbacks) {
        Object.entries(eventCallbacks).forEach(([event, func]) => {
          queuedUpload.uppy.on(
            event as keyof UppyEventMap,
            func as UppyEventMap[keyof UppyEventMap],
          );
        });
      }

      // Upload not yet queued
      const newQueue = [..._queue];
      newQueue.push(queuedUpload);
      setQueue(newQueue, updateLocal);
    },
    [getQueue, setQueue, updateQueuedUpload],
  );

  const enqueueUpload = useCallback(
    (uppy: Uppy, uploadType: UploadType, eventCallbacks?: IEventCallbacks) => {
      if (!session?.id) return;

      const pluginOptions: IQueuedUpload['pluginOptions'] = {};

      uppy.iteratePlugins(plugin => {
        // NOTE: breaks TS type
        pluginOptions[plugin.id] = (plugin as any).opts;
      });

      const { getUploadContext } = UPLOADERS[uploadType];

      // Prevent same uppy instance from being enqueued multiple times
      if (!getQueue().some(q => q.uppy.getID() === uppy.getID())) {
        _enqueueUpload(
          {
            uppy,
            uploadType,
            status: UploadStatus.Queued,
            pluginOptions,
            fileCount: uppy.getFiles().length,
            context: (getUploadContext && getUploadContext(uppy)) || {},
            creatorId: session.id,
            expirationTimeMS: Date.now() + QUEUED_UPLOAD_LIFETIME_MS,
          },
          true,
          eventCallbacks,
        );
        openUploadQueue();
      }
    },
    [_enqueueUpload, openUploadQueue, getQueue, session?.id],
  );

  const restoreLocalQueue = useCallback(() => {
    const localQueue = getLocalQueue();

    if (!localQueue.length || !session?.id) return;

    const restoreQueuedUpload = (index: number, restoredConsumerKeys: Set<string>) => {
      if (index === localQueue.length) {
        restoredConsumerKeys.forEach(consumerKey => {
          // Create new uppy instance for each consumer with a queued upload to prevent use of the queued upload
          createUppy(consumerKey);
        });

        // Don't update local queue until all restored uploads are enqueued to avoid removing them from local queue
        updateLocalQueue(getQueue());
        return;
      }

      const {
        uppyId,
        uploadType,
        status,
        consumerKey,
        pluginOptions,
        fileCount,
        context,
        creatorId,
        expirationTimeMS,
      } = localQueue[index];

      if (creatorId !== session.id || Date.now() > expirationTimeMS) {
        // Don't restore upload, created by different user or expired
        restoreQueuedUpload(index + 1, restoredConsumerKeys);
        return;
      }

      const restoredUppy = createUppy(consumerKey, { id: uppyId });
      restoredConsumerKeys.add(consumerKey);

      restoredUppy.setOptions({
        restrictions: UPLOADERS[uploadType].uppyRestrictions,
      });

      const { goldenRetrieverOptions, restoreUppy, processRestoredUppy } = UPLOADERS[uploadType];

      if (restoreUppy) {
        restoreUppy(restoredUppy, pluginOptions); // Restore uppy configuration
      }

      const goldenRetrieverId = getGoldenRetrieverId(uppyId);
      if (!restoredUppy.getPlugin(goldenRetrieverId)) {
        restoredUppy.use(GoldenRetriever, {
          id: getGoldenRetrieverId(uppyId),
          expires: QUEUED_UPLOAD_LIFETIME_MS,
          ...goldenRetrieverOptions,
        });
      }

      const queuedUpload: IQueuedUpload = {
        uppy: restoredUppy,
        uploadType,
        status: status === UploadStatus.Uploading ? UploadStatus.Queued : status,
        pluginOptions,
        fileCount,
        context,
        creatorId,
        expirationTimeMS,
      };

      if (queuedUpload.status === UploadStatus.Queued) {
        // restored event is not in TS type, but is emitted in GoldenRetriever plugin on line 232 of the index.js file
        (restoredUppy as any).on('restored', () => {
          if (processRestoredUppy) {
            processRestoredUppy(restoredUppy);
          }

          _enqueueUpload(queuedUpload, false);

          restoreQueuedUpload(index + 1, restoredConsumerKeys);
        });
      } else {
        // Uppy blobs won't be restored, upload already finished (successfully, or unsuccessfully)
        // Uppy state won't be restored
        queuedUpload.uppy.close();

        _enqueueUpload(queuedUpload, false);

        restoreQueuedUpload(index + 1, restoredConsumerKeys);
      }
    };

    restoreQueuedUpload(0, new Set<string>());
  }, [_enqueueUpload, getQueue, session?.id]);

  useEffect(() => {
    if (!session?.id && prevSession?.id) {
      // User logged out
      const queue = getQueue();

      setQueue([]); // Clear the upload queue

      // Cancel all queued uploads
      queue.forEach(q => {
        closeUppy(q.uppy);
      });
    } else if (session?.id && !prevSession?.id && isMasterQueue.current) {
      // User logged in and is master
      restoreLocalQueue();
    }
  }, [setQueue, getQueue, restoreLocalQueue, session?.id, prevSession?.id]);

  const handleMasterStatusChange = useCallback(
    (isMaster: boolean) => {
      if (isMaster) {
        // new master
        isMasterQueue.current = true;

        if (session?.id) {
          restoreLocalQueue();
        }
      } else {
        // new slave
        isMasterQueue.current = false;
      }
    },
    [restoreLocalQueue, session?.id],
  );

  useUploadQueueChannel({ onStatusChange: handleMasterStatusChange });

  return (
    <UploadQueueContext.Provider
      value={{
        enqueueUpload,
        setAnchorElement,
        toggleUploadQueueOpen,
        openUploadQueue,
        closeUploadQueue,
        useQueue,
      }}
    >
      <UploadQueue
        useQueue={useQueue}
        getQueue={getQueue}
        setQueue={setQueue}
        updateQueuedUpload={updateQueuedUpload}
      />
      {children}
    </UploadQueueContext.Provider>
  );
};

export const UploadQueueConsumer = UploadQueueContext.Consumer;

export default UploadQueueContext;
