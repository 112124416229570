import React, { useMemo } from 'react';
import { IconButton, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';

import { getOrgDetailsRoute } from '../../../../utils/routes';
import { InfoIcon } from '../../../../theme/icons';
import { activeTenantState } from '../../../../atoms/tenants';
import { activeOrganizationState } from '../../../../atoms/organizations';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInfoIconButtonProps {}

const InfoIconButton: React.FC<IInfoIconButtonProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();

  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);

  return useMemo(() => {
    const active =
      location.pathname.indexOf(
        getOrgDetailsRoute({ subdomain: activeTenant?.subdomain, orgId: organization?.id }),
      ) > -1;

    return (
      <IconButton
        style={{
          color: active ? theme.palette.secondary.main : 'rgba(0, 0, 0, 0.54)',
        }}
        onClick={() =>
          navigate(
            getOrgDetailsRoute({
              subdomain: activeTenant?.subdomain,
              orgId: organization?.id,
            }),
          )
        }
      >
        <InfoIcon />
      </IconButton>
    );
  }, [theme, navigate, location, activeTenant, organization?.id]);
};

export default InfoIconButton;
