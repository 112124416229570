import { useRef, useEffect, useCallback } from 'react';
// import { useState } from 'react';
import React from 'react';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
// import { useTransformEffect } from 'react-zoom-pan-pinch';

import { IRasterOverlayPreview } from '..';
import CancelButton from '../../../../ids-buttons/CancelButton';
// import { FormatColorFillIcon, HighlightAltIcon } from '../../../../../theme/icons';

// import { useCordsAndS3UrlToManualTrimLambda } from '../../../../../services/RasterOverlaysService';

import styles from './RasterOverlayManualTrim.module.css';
export interface IRasterOverlayManualTrim {
  preview: IRasterOverlayPreview;
  onManualTrimComplete: (completed: boolean) => void;
  onCancel: () => void;
  autoTrimmedS3URL: string;
  originalWidth: number;
  originalHeight: number;
  downloadableURL: string;
}
// export interface ICordinates {
//   x: number | null;
//   y: number | null;
// }
// enum SelectedAction {
//   fill = 'fill',
//   drag = 'drag',
// }
const RasterOverlayManualTrim: React.FC<IRasterOverlayManualTrim> = ({
  preview,
  onManualTrimComplete,
  onCancel,
  // autoTrimmedS3URL,
  downloadableURL,
}) => {
  // const { mutateAsync: cordsAndS3UrlToManualTrimLambda } = useCordsAndS3UrlToManualTrimLambda();
  // const [selectedPixel, setSelectedPixel] = useState<ICordinates>({
  //   x: null,
  //   y: null,
  // });
  // const [selectedAction, setSelectedAction] = useState<'fill' | 'drag' | null>(null);
  // const [scale, setScale] = useState({
  //   scale: 1,
  //   positionX: 0,
  //   positionY: 0,
  // });

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  //   const updatedScale = useCallback(({ state }) => {
  //     const { scale: newScale, positionX, positionY } = state;
  // console.log(state);
  //     setScale(prev => {
  //       if (prev.scale !== newScale || prev.positionX !== positionX || prev.positionY !== positionY) {
  //         return {
  //           scale: state.scale,
  //           positionX: state.positionX,
  //           positionY: state.positionY,
  //         };
  //       }
  //       return prev;
  //     });
  //   }, []);
  //   useTransformEffect(updatedScale);
  useEffect(() => {
    const canvas = canvasRef.current;
    const img = imgRef.current;
    if (!img) {
      return;
    }
    if (img && canvas) {
      canvas.width = img.offsetWidth;
      canvas.height = img.offsetHeight;
      const context = canvas.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
      }

      //   if (img && selectedPixel.x !== null && selectedPixel.y !== null) {
      //     // Draw a small circle at the selected pixel
      //     const scaleX = img.offsetWidth / img.naturalWidth;
      //     const scaleY = img.offsetHeight / img.naturalHeight;
      //     const displayX = selectedPixel.x * scaleX;
      //     const displayY = selectedPixel.y * scaleY;
      //     if (context) {
      //       context.fillStyle = 'red';
      //       context.beginPath();
      //       context.arc(displayX, displayY, 5, 0, 2 * Math.PI);
      //       context.fill();
      //     }
      //   }
      // } [selectedPixel] goes }, here )
    }
  });
  // const drawFeedback = (displayX: number, displayY: number) => {
  //   if (!canvasRef.current) return;
  //   const ctx = canvasRef.current.getContext('2d');
  //   if (!ctx) return;
  //   ctx.fillStyle = 'red';
  //   ctx.beginPath();
  //   ctx.arc(displayX, displayY, 5, 0, 2 * Math.PI);
  //   ctx.fill();
  // };
  // const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (!imgRef.current || !canvasRef.current) return;

  //   const rect = imgRef.current.getBoundingClientRect();
  //   const x = e.clientX - rect.left;
  //   const y = e.clientY - rect.top;

  // Calculate the content coordinates by reversing the transformations
  // const xInContent = (x - scale.positionX) / scale.scale;
  // const yInContent = (y - scale.positionY) / scale.scale;

  // // Adjust for scaling between displayed image size and natural size
  // const scaleX = imgRef.current.naturalWidth / imgRef.current.offsetWidth;
  // const scaleY = imgRef.current.naturalHeight / imgRef.current.offsetHeight;

  // x = xInContent * scaleX;
  // y = yInContent * scaleY;
  // setSelectedPixel({ x, y });
  // drawFeedback(e.clientX - rect.left, e.clientY - rect.top);
  // alert(`Selected Pixel - X: ${x}, Y: ${y}`);
  // };
  // const handleChange = (_: React.MouseEvent<HTMLElement>, selectedAction: SelectedAction) => {
  //   if (selectedAction) {
  //     setSelectedAction(selectedAction);
  //   }
  // };
  const onClickHandler = useCallback(() => {
    onManualTrimComplete(true);
  }, [onManualTrimComplete]);

  return (
    <>
      <Grid container direction='row' className={styles.imgOverflow}>
        <Box className={styles.boxBg}>
          <TransformWrapper
            initialScale={0.7}
            minScale={0.375}
            maxScale={5}
            wheel={{ step: 0.2 }}
            doubleClick={{ mode: 'zoomIn' }}
            pinch={{ step: 0.2 }}
            centerZoomedOut={false}
            limitToBounds={false}
            panning={{ velocityDisabled: true }}
            disablePadding={true}
          >
            <TransformComponent>
              <Box
                // onClick={handleImageClick}
                className={styles.pngGrid}
              >
                <img
                  src={preview.url}
                  ref={imgRef}
                  alt='map preview'
                  height={preview.height}
                  width={preview.width}
                />
                <canvas
                  ref={canvasRef}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    pointerEvents: 'none',
                  }}
                />
              </Box>
            </TransformComponent>
          </TransformWrapper>
        </Box>
        <Grid
          container
          direction='column'
          justifyContent='flex-end'
          alignItems='flex-end'
          className={styles.toggleButtonGroupStyle}
        >
          {/* <ToggleButtonGroup
            color='primary'
            orientation='vertical'
            value={selectedAction}
            exclusive
            onChange={handleChange}
            aria-label='Trim type'
          >
            <ToggleButton value='drag' aria-label='Drag' className={styles.toggleButton}>
              <HighlightAltIcon
                className={styles.iconColor}
                onClick={() => setSelectedAction('drag')}
              />
            </ToggleButton>
            <ToggleButton value='fill' aria-label='Fill' className={styles.toggleButton}>
              <FormatColorFillIcon
                className={styles.iconColor}
                onClick={() => setSelectedAction('fill')}
              />
            </ToggleButton>
          </ToggleButtonGroup> */}
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
        className={styles.navButton}
      >
        <ButtonGroup variant='contained' aria-label='navigation and option group'>
          <CancelButton aria-label='Cancel' onClick={onCancel} />
          <Button
            aria-label='Download'
            variant='contained'
            color='primary'
            disabled={downloadableURL === null}
            onClick={() => window.open(downloadableURL, '_blank')}
          >
            Download
          </Button>
          {/* <Button
            aria-label='Reprocess'
            variant='contained'
            color='primary'
            disabled={selectedAction === null}
            onClick={() =>
              cordsAndS3UrlToManualTrimLambda({
                retryType: selectedAction!,
                s3Url: autoTrimmedS3URL,
                cords: JSON.stringify('Temp cords'),
              })
            }
          >
            Reprocess
          </Button> */}
          <Button aria-label='Next' variant='contained' color='primary' onClick={onClickHandler}>
            Next
          </Button>
        </ButtonGroup>
      </Grid>
    </>
  );
};

export default RasterOverlayManualTrim;
