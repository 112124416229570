import { isPhygiiSubdomain } from './phygii/isPhygiiTenant';
import isPhygii from './phygii/isPhygii';

/**
 * Create function for filling placeholders `:fieldName` in pattern.
 *
 * TODO: Enforce payload structure
 */
export const createRoutePath = <T extends Record<string, any>>(patternRoute: string) => {
  return (payload: T) => {
    let finalUrl = patternRoute;

    if ('subdomain' in payload && isPhygii() && isPhygiiSubdomain(payload.subdomain)) {
      finalUrl = finalUrl.replace('/:subdomain', '');
    }

    for (const [key, value] of Object.entries(payload)) {
      finalUrl = finalUrl.replace(`:${key}`, value);
    }
    return finalUrl;
  };
};

/**
 * Enum with all the routes (patterns)
 *
 * Typical pattern looks like this:
 *
 * "CONTEXT_FEATURE_ACTION"
 * - Context can be TENANTS/TENANT/ORGS/ORG/etc.
 * - Feature can be PROGRAMS/PROGRAM/PROJECTS/PROJECT/etc.
 * - Action can be CREATE/EDIT/etc.
 */
export enum ExplorerRoutes {
  // Global
  LOGIN = '/login',

  // ============= TENANT CONTEXT =============
  // Tenant: General
  TENANTS = '/app/tenants',
  TENANT_ORGS = '/app/:subdomain/organizations',
  TENANT_ROUTES = '/app/:subdomain/routes',
  TENANT_UPLOADS = '/app/:subdomain/uploads',
  TENANT_QUERIES = '/app/:subdomain/queries',
  TENANT_REPORTS = '/app/:subdomain/reports',
  TENANT_SETTINGS = '/app/:subdomain/settings',
  TENANT_DETAILS = '/app/:subdomain/details',
  // Tenant: Map
  TENANT_MAP = '/app/:subdomain/map',
  // Tenant: Locations
  TENANT_LOCATIONS = '/app/:subdomain/locations',
  // Tenant: Programs
  TENANT_PROGRAMS = '/app/:subdomain/programs',
  // Tenant: Projects
  TENANT_PROJECTS = '/app/:subdomain/projects',
  // Tenant: Users
  TENANT_USERS = '/app/:subdomain/users',
  TENANT_USER_EDIT = '/app/:subdomain/users/:userId/edit',
  TENANT_USER_DETAILS = '/app/:subdomain/users/:userId',
  // Tenant: Assignments
  TENANT_ASSIGNMENTS = '/app/:subdomain/assignments',
  // Tenant: Collections
  TENANT_COLLECTIONS = '/app/:subdomain/collections',

  // ============= ORGANIZATION CONTEXT =============
  // Org: General
  ORG = '/app/:subdomain/organization/:orgId',
  ORG_DETAILS = '/app/:subdomain/organization/:orgId/details',
  ORG_SETTINGS = '/app/:subdomain/organization/:orgId/settings',
  ORG_MEDIA = '/app/:subdomain/organization/:orgId/explore/media',
  ORG_UPLOADS = '/app/:subdomain/organization/:orgId/uploads',
  ORG_REPORTS = '/app/:subdomain/organization/:orgId/reports',
  ORG_ROUTES = '/app/:subdomain/organization/:orgId/routes',
  ORG_QUERIES = '/app/:subdomain/organization/:orgId/queries',
  // Org: Map
  ORG_MAP = '/app/:subdomain/organization/:orgId/explore/map',
  ORG_MAP_LOCATION = '/app/:subdomain/organization/:orgId/explore/map/:locationId',
  ORG_MAP_LOCATION_NEW_OVERLAY = '/app/:subdomain/organization/:orgId/explore/map/:locationId?createRO=true',
  // Org: Locations
  ORG_LOCATIONS = '/app/:subdomain/organization/:orgId/locations',
  ORG_LOCATION = '/app/:subdomain/organization/:orgId/locations/:locationId',
  ORG_LOCATION_EDIT = '/app/:subdomain/organization/:orgId/locations/:locationId/edit',
  ORG_LOCATION_COPY = '/app/:subdomain/organization/:orgId/locations/:locationId/copy',
  ORG_LOCATION_SETTINGS = '/app/:subdomain/organization/:orgId/locations/:locationId/settings',
  ORG_LOCATION_ASSIGNMENTS = '/app/:subdomain/organization/:orgId/locations/:locationId?tab=assignments',
  ORG_LOCATION_ASSIGNMENT = '/app/:subdomain/organization/:orgId/locations/:locationId/assignments/:assignmentId',
  ORG_LOCATION_ASSIGNMENT_RESPONSE = '/app/:subdomain/organization/:orgId/locations/:locationId/assignments/:assignmentId/responses/:assignmentResponseId',
  // Org: Programs
  ORG_PROGRAMS = '/app/:subdomain/organization/:orgId/programs',
  ORG_PROGRAM = '/app/:subdomain/organization/:orgId/programs/:programId',
  ORG_PROGRAM_EDIT = '/app/:subdomain/organization/:orgId/programs/:programId/edit',
  ORG_PROGRAM_EDIT_PROJECTS = '/app/:subdomain/organization/:orgId/programs/:programId/edit/projects',
  ORG_PROGRAM_ASSIGNMENTS = '/app/:subdomain/organization/:orgId/programs/:programId?tab=assignments',
  ORG_PROGRAM_ASSIGNMENT = '/app/:subdomain/organization/:orgId/programs/:programId/assignments/:assignmentId',
  ORG_PROGRAM_ASSIGNMENT_RESPONSE = '/app/:subdomain/organization/:orgId/programs/:programId/assignments/:assignmentId/responses/:assignmentResponseId',
  // Org: Projects
  ORG_PROJECTS = '/app/:subdomain/organization/:orgId/projects',
  ORG_PROJECT = '/app/:subdomain/organization/:orgId/projects/:projectId',
  ORG_PROJECT_REPORT = '/app/:subdomain/organization/:orgId/projects/:projectId/reports/:reportId',
  ORG_PROJECT_EDIT = '/app/:subdomain/organization/:orgId/projects/:projectId/edit',
  ORG_PROJECT_ASSIGNMENT = '/app/:subdomain/organization/:orgId/projects/:projectId/assignments/:assignmentId',
  ORG_PROJECT_ASSIGNMENTS = '/app/:subdomain/organization/:orgId/projects/:projectId?tab=assignments',
  ORG_PROJECT_ASSIGNMENT_RESPONSE = '/app/:subdomain/organization/:orgId/projects/:projectId/assignments/:assignmentId/responses/:assignmentResponseId',
  ORG_PROJECT_ROUTE = '/app/:subdomain/organization/:orgId/projects/:projectId/routes/:routeId',
  ORG_PROJECT_ROUTES = '/app/:subdomain/organization/:orgId/projects/:projectId?tab=routes',
  // Org: Users
  ORG_USERS = '/app/:subdomain/organization/:orgId/users',
  ORG_USER = '/app/:subdomain/organization/:orgId/users/:userId',
  ORG_USER_EDIT = '/app/:subdomain/organization/:orgId/users/:userId/edit',
  // Org: Assignments
  ORG_ASSIGNMENTS = '/app/:subdomain/organization/:orgId/assignments',
  ORG_ASSIGNMENT = '/app/:subdomain/organization/:orgId/assignments/:assignmentId',
  ORG_ASSIGNMENT_RESPONSES = '/app/:subdomain/organization/:orgId/assignments/:assignmentId?tab=responses',
  ORG_ASSIGNMENT_RESPONSE = '/app/:subdomain/organization/:orgId/assignments/:assignmentId/responses/:assignmentResponseId',
  // Org: Collections
  ORG_COLLECTIONS = '/app/:subdomain/organization/:orgId/collections',
  ORG_COLLECTION = '/app/:subdomain/organization/:orgId/collections/:collectionId',
}

// Types below should be used to describe shape
// of payload for filling up particular pattern used in createRoutePath calls.

/**
 * `subdomain`;
 */
interface IRequireTenant {
  subdomain?: string;
}

/**
 * `orgId`;
 */
interface IRequireOrg {
  orgId?: string;
}

/**
 * `locationId`;
 */
interface IRequireLocation {
  locationId?: string;
}

/**
 * `programId`;
 */
interface IRequireProgram {
  programId?: string;
}

/**
 * `projectId`;
 */
interface IRequireProject {
  projectId?: string;
}

/**
 * `userId`;
 */
interface IRequireUser {
  userId?: string;
}

/**
 * `assignmentId`;
 */
interface IRequireAssignment {
  assignmentId?: string;
}

/**
 * `assignmentResponseId`;
 */
interface IRequireAssignmentResponse {
  assignmentResponseId?: string;
}

/**
 * `collectionId`;
 */
interface IRequireCollection {
  collectionId?: string;
}

/**
 * `reportId`;
 */
interface IRequireReport {
  reportId?: string;
}

/**
 * `routeId`;
 */
interface IRequireRoute {
  routeId?: string;
}

// ============ ROUTES ============
// This functions should be used to fill pattern using data
// from payload and get full path to particular route.

export const getLoginRoute = createRoutePath(ExplorerRoutes.LOGIN);

export const getTenantsRoute = createRoutePath(ExplorerRoutes.TENANTS);
export const getTenantOrgsRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_ORGS);
export const getTenantAssignmentsRoute = createRoutePath<IRequireTenant>(
  ExplorerRoutes.TENANT_ASSIGNMENTS,
);
export const getTenantRoutesRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_ROUTES);
export const getTenantUploadsRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_UPLOADS);
export const getTenantMapRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_MAP);
export const getTenantReportsRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_REPORTS);
export const getTenantQueriesRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_QUERIES);
export const getTenantSettingsRoute = createRoutePath<IRequireTenant>(
  ExplorerRoutes.TENANT_SETTINGS,
);
export const getTenantDetailsRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_DETAILS);
export const getTenantLocationsRoute = createRoutePath<IRequireTenant>(
  ExplorerRoutes.TENANT_LOCATIONS,
);
export const getTenantProjectsRoute = createRoutePath<IRequireTenant>(
  ExplorerRoutes.TENANT_PROJECTS,
);
export const getTenantProgramsRoute = createRoutePath<IRequireTenant>(
  ExplorerRoutes.TENANT_PROGRAMS,
);
export const getTenantUsersRoute = createRoutePath<IRequireTenant>(ExplorerRoutes.TENANT_USERS);
export const getTenantUserDetailsRoute = createRoutePath<IRequireTenant & IRequireUser>(
  ExplorerRoutes.TENANT_USER_DETAILS,
);
export const getTenantUserEditRoute = createRoutePath<IRequireTenant & IRequireUser>(
  ExplorerRoutes.TENANT_USER_EDIT,
);
export const getTenantCollectionsRoute = createRoutePath<IRequireTenant>(
  ExplorerRoutes.TENANT_COLLECTIONS,
);

export const getOrgRoute = createRoutePath<IRequireTenant & IRequireOrg>(ExplorerRoutes.ORG);

export const getOrgMapRoute = createRoutePath<IRequireTenant & IRequireOrg>(ExplorerRoutes.ORG_MAP);

export const getOrgMediaRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_MEDIA,
);

export const getOrgRoutesRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_ROUTES,
);

export const getOrgQueriesRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_QUERIES,
);

export const getOrgDetailsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_DETAILS,
);
export const getOrgSettingsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_SETTINGS,
);

export const getOrgReportsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_REPORTS,
);

export const getOrgMapLocationRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation
>(ExplorerRoutes.ORG_MAP_LOCATION);

export const getOrgMapLocationRouteNewOverlay = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation
>(ExplorerRoutes.ORG_MAP_LOCATION_NEW_OVERLAY);

export const getOrgUploadsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_UPLOADS,
);

export const getOrgLocationsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_LOCATIONS,
);

export const getOrgLocationRoute = createRoutePath<IRequireTenant & IRequireOrg & IRequireLocation>(
  ExplorerRoutes.ORG_LOCATION,
);

export const getOrgLocationEditRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation
>(ExplorerRoutes.ORG_LOCATION_EDIT);

export const getOrgLocationAssignmentsRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation
>(ExplorerRoutes.ORG_LOCATION_ASSIGNMENTS);

export const getOrgLocationAssignmentRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation & IRequireAssignment
>(ExplorerRoutes.ORG_LOCATION_ASSIGNMENT);

export const getOrgLocationAssignmentResponseRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation & IRequireAssignment & IRequireAssignmentResponse
>(ExplorerRoutes.ORG_LOCATION_ASSIGNMENT_RESPONSE);

export const getOrgLocationCopyRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation
>(ExplorerRoutes.ORG_LOCATION_COPY);

export const getOrgLocationSettingsRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireLocation
>(ExplorerRoutes.ORG_LOCATION_SETTINGS);

export const getOrgProgramsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_PROGRAMS,
);

export const getOrgProgramRoute = createRoutePath<IRequireTenant & IRequireOrg & IRequireProgram>(
  ExplorerRoutes.ORG_PROGRAM,
);

export const getOrgProgramEditRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProgram
>(ExplorerRoutes.ORG_PROGRAM_EDIT);
export const getOrgProgramEditProjectsRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProgram
>(ExplorerRoutes.ORG_PROGRAM_EDIT_PROJECTS);

export const getOrgProgramAssignmentsRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProgram
>(ExplorerRoutes.ORG_PROGRAM_ASSIGNMENTS);

export const getOrgProgramAssignmentResponseRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProgram & IRequireAssignment & IRequireAssignmentResponse
>(ExplorerRoutes.ORG_PROGRAM_ASSIGNMENT_RESPONSE);

export const getOrgProjectsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_PROJECTS,
);

export const getOrgProjectRoute = createRoutePath<IRequireTenant & IRequireOrg & IRequireProject>(
  ExplorerRoutes.ORG_PROJECT,
);

export const getOrgProjectReportRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject & IRequireReport
>(ExplorerRoutes.ORG_PROJECT_REPORT);

export const getOrgProjectEditRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject
>(ExplorerRoutes.ORG_PROJECT_EDIT);

export const getOrgProjectAssignmentRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject & IRequireAssignment
>(ExplorerRoutes.ORG_PROJECT_ASSIGNMENT);

export const getOrgProjectAssignmentsRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject
>(ExplorerRoutes.ORG_PROJECT_ASSIGNMENTS);

export const getOrgProjectAssignmentResponseRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject & IRequireAssignment & IRequireAssignmentResponse
>(ExplorerRoutes.ORG_PROJECT_ASSIGNMENT_RESPONSE);

export const getOrgProjectRouteRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject & IRequireRoute
>(ExplorerRoutes.ORG_PROJECT_ROUTE);

export const getOrgProjectRoutesRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProject
>(ExplorerRoutes.ORG_PROJECT_ROUTES);

export const getOrgUsersRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_USERS,
);

export const getOrgUserRoute = createRoutePath<IRequireTenant & IRequireOrg & IRequireUser>(
  ExplorerRoutes.ORG_USER,
);

export const getOrgUserEditRoute = createRoutePath<IRequireTenant & IRequireOrg & IRequireUser>(
  ExplorerRoutes.ORG_USER_EDIT,
);

export const getOrgAssignmentsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_ASSIGNMENTS,
);

export const getOrgAssignmentRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireAssignment
>(ExplorerRoutes.ORG_ASSIGNMENT);

export const getOrgAssignmentResponsesRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireAssignment
>(ExplorerRoutes.ORG_ASSIGNMENT_RESPONSES);

export const getOrgAssignmentResponseRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireAssignment & IRequireAssignmentResponse
>(ExplorerRoutes.ORG_ASSIGNMENT_RESPONSE);

export const getOrgProgramAssignmentRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireProgram & IRequireAssignment
>(ExplorerRoutes.ORG_PROGRAM_ASSIGNMENT);

export const getOrgCollectionsRoute = createRoutePath<IRequireTenant & IRequireOrg>(
  ExplorerRoutes.ORG_COLLECTIONS,
);

export const getOrgCollectionRoute = createRoutePath<
  IRequireTenant & IRequireOrg & IRequireCollection
>(ExplorerRoutes.ORG_COLLECTION);
