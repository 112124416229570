import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import EntityOfInterestForm from '../../entities-of-interest/EntityOfInterestForm';

export const AOI_COLOR_OPTIONS = [
  '#9c27b0',
  '#e91e63',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#41d860',
  '#ffc107',
  '#ff9800',
  '#ff5722',
];

const flaggedColorHelperText =
  'Flagged areas of interest are red. If unflagged, this color will be used.';

const AreaOfInterestForm = ({ onColorChange, onFlaggedChange }) => {
  const { values } = useFormikContext();

  return (
    <EntityOfInterestForm
      onColorChange={onColorChange}
      onFlaggedChange={onFlaggedChange}
      colorProps={{
        colorOptions: AOI_COLOR_OPTIONS,
        helperText: values.flagged ? flaggedColorHelperText : ' ', // default to a space to reserve layout space when not flagged
      }}
    />
  );
};

AreaOfInterestForm.propTypes = {
  onColorChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
};

export default AreaOfInterestForm;
