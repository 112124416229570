import React from 'react';
import { SvgIcon } from '@mui/material';

const VideoIcon = props => (
  <SvgIcon {...props}>
    <path d='m17.48,10.36v-3.83c0-.6-.49-1.1-1.1-1.1H3.24c-.6,0-1.1.49-1.1,1.1v10.95c0,.6.49,1.1,1.1,1.1h13.14c.6,0,1.1-.49,1.1-1.1v-3.83l2.51,2.51c.69.69,1.87.2,1.87-.78v-6.76c0-.97-1.18-1.47-1.87-.78l-2.51,2.52Z' />
  </SvgIcon>
);

export default VideoIcon;
