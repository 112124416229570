import React from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';
import { capitalCase } from 'change-case';

import { DeleteIcon } from '../../../../../theme/icons';

import IdsFormSelectField from '../../../../../components/ids-forms/IdsFormSelectField';
import Error from '../../../../../views/Error';
import { activeTenantState } from '../../../../../atoms/tenants';
import { USER_ROLES } from '../../../../../constants/users';
import { IFormValues } from '..';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICardProps {
  isEditMode: boolean;
  onDelete: (args: IFormValues) => Promise<void>;
}

const ROLE_OPTIONS = [
  {
    label: capitalCase(USER_ROLES.TENANT_ADMIN.name),
    value: USER_ROLES.TENANT_ADMIN.name,
  },
  {
    label: capitalCase(USER_ROLES.TENANT_TEAM.name),
    value: USER_ROLES.TENANT_TEAM.name,
  },
];

const Card: React.FC<ICardProps> = ({ isEditMode, onDelete }) => {
  const activeTenant = useRecoilValue(activeTenantState);

  const { values } = useFormikContext<any>();

  if (!activeTenant) {
    return <Error />;
  }

  return (
    <Box>
      <Box textAlign='right' mb={2}>
        <Button disabled={!isEditMode} color='error' onClick={() => onDelete(values)}>
          <SvgIcon fontSize='small' style={{ marginRight: '8px' }}>
            <DeleteIcon />
          </SvgIcon>
          Delete
        </Button>
      </Box>
      <IdsFormSelectField
        name='tenantID'
        label='Tenant'
        required
        options={[{ label: activeTenant!.name, value: activeTenant!.id }]}
        disabled
      />
      {values['tenantID'] && (
        <Box mt={0.5}>
          <IdsFormSelectField
            name='tenantRoleName'
            label='Role'
            required
            options={ROLE_OPTIONS}
            disabled={!isEditMode}
          />
        </Box>
      )}
    </Box>
  );
};

export default Card;
