import React, { createContext, useCallback, useState } from 'react';

import useObservableStates, {
  useObservableItemValue,
  IObservableItemStateProps,
  ObservableItemStateListener,
} from '../../hooks/useObservableStates';
import useTypedDataManagers, {
  TypeItems,
  TypeDataListener,
  ITypedDataManager,
} from '../../hooks/useTypedDataManagers';
import useMountedEffect from '../../hooks/useMountedEffect';
import useUrlState from '../../hooks/useUrlState';

const FILTERS_ID = 'filters';
const FILTER_EFFECTS_ID = 'filterEffects';
const FILTER_EFFECT_ID_COUNTER = 'filterEffectIdCounter';
const FILTER_RECORDS_ID = 'itemFilterRecords';
const getItemFilterRecordId = (itemId: string, type: string) => `${itemId}-${type}-filterRecord`;

export interface IFilterTarget<TFilterData = any, TItem = any> {
  /** Target data type. */
  type: string;
  /** Function to normalize each data type item to be the expected data shape for the filter. */
  selectFilterData: (item: TItem) => TFilterData;
}

export interface ITypeMetadata<TItem = any> {
  /** With no filters applied, are data items enabled? */
  itemsDefaultEnabled: boolean;
  /** Is this type's data currently available? If false, filtered data for this type will be empty. */
  enabled: boolean;
  /** Key used for tracking enabled state in the url if provided. */
  urlKey?: string | null;
  /** Selector to access the item's id. */
  selectId: (item: TItem) => string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypeMetadataMap extends Record<string, ITypeMetadata> {}

export enum FilterMode {
  /** Filters out data. */
  Subtractive = 'subtractive',
  /** Cherry picks data to keep from filtered data, any data that fails all additive filters will be filtered out. */
  Additive = 'additive',
  /** Additive filter that ignores other filters. */
  Override = 'override',
}

export interface IFilter<TFilterData = any> {
  /** Unique name of the filter. */
  name: string;
  /** Function to supply to array.filter(filterItem) where a return value of false filters out the item. */
  filterItem: (item: TFilterData) => boolean;
  /** Data types the filter should be applied to. */
  targets: IFilterTarget<TFilterData>[];
  /** Label for the filter. */
  label: string;
  /** Human readable value of the filter. */
  selected?: any;
  mode: FilterMode;
  /** Callback fired when the filter is removed. */
  onRemove?: () => void;
  /** Callback fired when the filter is destroyed. Example: Filter provider unmounts while filter is active. */
  onDestroy?: () => void;
  /** Custom chip component to use for representing the filter in an active filter list. */
  customChip?: React.ReactNode;
  /** Flag indicating if the filter should be shown in the list of active filters. */
  invisible?: boolean;
}

export interface IAddFilterOptions {
  /** Indicates if a filter update is filtering data further. `default: false`
   * - If `true`, filter is applied to filtered data, further filtering the already filtered data
   * - If `false`, filter is applied to base data */
  filteringDown?: boolean;
  /** Indicates if the filter was loaded from the url. This is made available in filter effects to allow for different behavior when loaded form the url. */
  loadedFromUrl?: boolean;
}

export interface IFiltersState {
  filters: IFilter[];
}

export interface IFilterEffect {
  id: number;
  fn: (filter: IFilter | null, previousFilter: IFilter | undefined, loadedFromUrl: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFilterEffectsState extends Record<string, IFilterEffect[]> {}

interface IFilterEffectIdCounterState {
  idCounter: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IItemFilterRecords extends Record<string, IFilter> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypeFilterRecordsLookup extends Record<string, string> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFilterRecords extends Record<string, ITypeFilterRecordsLookup> {}

export interface IFilterContextType {
  useFilters: () => IFilter[];
  addFilter: <TFilterData = any>(
    filter: Optional<IFilter<TFilterData>, 'mode'>,
    options?: IAddFilterOptions,
  ) => void;
  reorderFilters: (filters: string[], reorderMethod: (filters: IFilter[]) => IFilter[]) => void;
  removeFilter: (name: string) => void;
  getFilter: (name: string) => IFilter | undefined;
  disableTypeData: (type: string) => void;
  enableTypeData: (type: string) => void;
  setTypeData: <TItem = any>(data: TypeItems<TItem>, type: string) => void;
  setTypeItems: <TItem = any>(items: TypeItems<TItem>, type: string) => void;
  setTypeItem: <TItem = any>(item: TItem, type: string) => void;
  /** Updates a specific `field` on the type item to be `value`. */
  updateTypeItem: (itemId: string, type: string, field: string, value: any) => void;
  deleteTypeItem: (itemId: string, type: string) => void;
  getTypeData: <TItem = any>(type: string) => TypeItems<TItem>;
  getTypeItem: <TItem = any>(type: string, id: string) => TItem | undefined;
  useTypeData: <TItem = any>(type: string) => TypeItems<TItem>;
  /** Add listener for type data changes. Returns listener id. */
  addTypeDataListener: (type: string, listener: TypeDataListener) => number | undefined;
  /** Remove listener for type data changes. Returns boolean indicating if listener was removed. */
  removeTypeDataListener: (type: string, listenerId: number) => boolean;
  getTypeFilteredData: <TItem = any>(type: string) => TypeItems<TItem>;
  useTypeFilteredData: <TItem = any>(type: string) => TypeItems<TItem>;
  typeMetadata: ITypeMetadataMap;
  getItemFilterRecord: (type: string, itemId: string) => IFilter | undefined;
  addItemFilterRecordListener: (
    type: string,
    itemId: string,
    listenerId: number | null | undefined,
    listener: ObservableItemStateListener<IFilter>,
  ) => number | undefined;
  removeItemFilterRecordListener: (type: string, itemId: string, listenerId: number) => boolean;
  addFilterEffect: (
    filterName: string,
    effect: IFilterEffect['fn'],
    effectId?: number,
  ) => number | null;
  removeFilterEffect: (filterName: string, effectId: number) => void;
}

export interface IFilterProviderProps {
  types: string[];
  defaultTypeMetadata?: ITypeMetadataMap;
}

const _useFilters = (
  getItemState: IObservableItemStateProps['getItemState'],
  setItemState: IObservableItemStateProps['setItemState'],
  addItemStateListener: IObservableItemStateProps['addItemStateListener'],
  removeItemStateListener: IObservableItemStateProps['removeItemStateListener'],
) => {
  const filtersState = useObservableItemValue<IFiltersState>({
    id: FILTERS_ID,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  return filtersState?.filters || [];
};

const FilterContext = createContext<IFilterContextType | null>(null);

export const FilterProvider: React.FC<IFilterProviderProps> = ({
  types,
  defaultTypeMetadata,
  children,
}) => {
  const typedDataManagers = useTypedDataManagers(types);
  const getTypeData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <TItem extends any = any>(type: string) => {
      const dataMgr: ITypedDataManager<TItem> | undefined = typedDataManagers[type];
      return dataMgr ? dataMgr.getData() : [];
    },
    [typedDataManagers],
  );
  const getTypeItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <TItem extends any = any>(type: string, id: string) => {
      const dataMgr: ITypedDataManager<TItem> | undefined = typedDataManagers[type];
      return dataMgr ? dataMgr.getItem(id) : undefined;
    },
    [typedDataManagers],
  );
  const useTypeData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <TItem extends any = any>(type: string) => {
      const dataMgr: ITypedDataManager<TItem> = typedDataManagers[type];
      return dataMgr ? dataMgr.useData() : [];
    },
    [typedDataManagers],
  );
  const addTypeDataListener = useCallback(
    (type, listener) => typedDataManagers[type]?.addDataListener(listener),
    [typedDataManagers],
  );
  const removeTypeDataListener = useCallback(
    (type, listenerId) => typedDataManagers[type]?.removeDataListener(listenerId),
    [typedDataManagers],
  );
  const getTypeFilteredData = useCallback(
    type => typedDataManagers[type]?.getFilteredData() || [],
    [typedDataManagers],
  );
  const useTypeFilteredData = useCallback(
    type => typedDataManagers[type]?.useFilteredData() || [],
    [typedDataManagers],
  );

  const { getUrlState, setUrlState } = useUrlState();

  const [typeMetadata, setTypeMetadata] = useState<ITypeMetadataMap>(
    (defaultTypeMetadata && {
      // Need to deep clone the defaultTypeMetadata to remove references to the original object, otherwise
      // modifications to typeMetatata change the defaults
      ...Object.entries<ITypeMetadata>(defaultTypeMetadata).reduce<ITypeMetadataMap>(
        (clonedTypeMetadata, [type, metadata]) => {
          clonedTypeMetadata[type] = { ...metadata }; // Clone the metadata to remove reference from default metadata
          return clonedTypeMetadata;
        },
        {},
      ),
    }) ||
      types.reduce<ITypeMetadataMap>((_typeMetadata, type) => {
        // Build typeMetadata based on common defaults if default is not provided
        _typeMetadata[type] = {
          itemsDefaultEnabled: true,
          enabled: true,
          urlKey: null,
          selectId: (item: any) => item.node.id,
        };
        return _typeMetadata;
      }, {}),
  );

  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();

  const useFilters = () =>
    _useFilters(getItemState, setItemState, addItemStateListener, removeItemStateListener);

  const getFilters = useCallback(() => {
    return getItemState<IFiltersState>(FILTERS_ID)?.filters || [];
  }, [getItemState]);

  const getFilter = useCallback(
    name => {
      return getFilters().find(f => f.name === name);
    },
    [getFilters],
  );

  const setFilters = useCallback(
    filters => {
      if (!filters) return;
      setItemState<IFiltersState>(FILTERS_ID, { filters });
    },
    [setItemState],
  );

  /*
   * filterEffects: {
      idCounter: 0, // incremented for each effect added, returned and used for removing an effect
      filter1Name: [
        { id: 'effect1', fn: (filter, previousFilter, loadedFromUrl) => {} },
        ...
        { id: 'effectN', fn: (filter, previousFilter, loadedFromUrl) => {} },
      ],
      ...
      filterNName: {},
    } 
  */
  const getFilterEffects = useCallback(
    name => {
      const filterEffectsLookup = getItemState<IFilterEffectsState>(FILTER_EFFECTS_ID);
      return (filterEffectsLookup && filterEffectsLookup[name]) || [];
    },
    [getItemState],
  );

  /** Add or update a filter effect
   * @returns effect id or null if failed to add effect
   */
  const addFilterEffect = useCallback(
    (filterName: string, effect: IFilterEffect['fn'], effectId?: number) => {
      if (!filterName || !effect || filterName === 'idCounter') return null;

      const { ...filterEffectsLookup } = getItemState<IFilterEffectsState>(FILTER_EFFECTS_ID) || {};
      const { idCounter } = getItemState<IFilterEffectIdCounterState>(FILTER_EFFECT_ID_COUNTER) || {
        idCounter: 0,
      };

      const _effectId = effectId || idCounter + 1;
      const effects = (filterEffectsLookup[filterName] as IFilterEffect[]) || [];
      const effectIndex = effects.findIndex(e => e.id === _effectId);
      if (effectIndex >= 0) {
        // Effect already exists
        effects[effectIndex].fn = effect; // Update the effect function
      } else {
        // New effect
        effects.push({ id: _effectId, fn: effect });
      }

      setItemState(FILTER_EFFECTS_ID, { [filterName]: effects });
      setItemState(FILTER_EFFECT_ID_COUNTER, {
        idCounter: effectId ? idCounter : idCounter + 1,
      });
      return _effectId;
    },
    [getItemState, setItemState],
  );

  /** Remove a filter effect */
  const removeFilterEffect = useCallback(
    (filterName: string, effectId: number) => {
      if (!filterName || !effectId) return;

      const effects = getFilterEffects(filterName);
      const effectIndex = effects.findIndex(e => e.id === effectId);
      effects.splice(effectIndex, 1);

      setItemState(FILTER_EFFECTS_ID, { [filterName]: effects });
    },
    [getFilterEffects, setItemState],
  );

  /*
    typeFilterRecords: {
      [type]: {
        [itemId]: itemFilterRecordId
      }
    },
    itemFilterRecordId: filter,
  */

  const getTypeFilterRecords = useCallback(
    (type: string) => {
      const records = getItemState<IFilterRecords>(FILTER_RECORDS_ID);

      const typeRecordLookup = records && records[type];
      if (!typeRecordLookup) {
        return {};
      }

      return Object.entries(typeRecordLookup).reduce<IItemFilterRecords>(
        (filterRecords, [itemId, filterRecordId]) => {
          const itemFilterRecord = getItemState<IFilter>(filterRecordId);
          if (itemFilterRecord) {
            filterRecords[itemId] = itemFilterRecord;
          }
          return filterRecords;
        },
        {} as IItemFilterRecords,
      );
    },
    [getItemState],
  );

  const setTypeFilterRecords = useCallback(
    (type: string, records: IItemFilterRecords) => {
      const filterRecordItemIds = Object.keys(records);

      const oldTypeFilterRecordLookups = getItemState<IFilterRecords>(FILTER_RECORDS_ID);

      if (oldTypeFilterRecordLookups && oldTypeFilterRecordLookups[type]) {
        // Reset filter records for items that are no longer filtered out
        Object.entries(oldTypeFilterRecordLookups[type])
          .filter(([oldItemId]) => !filterRecordItemIds.includes(oldItemId))
          .forEach(([, oldFilterRecordId]) => {
            // IMPORTANT: we need to reset the state rather than passing null to remove it so that item state listeners can be maintained
            // An item will not always have a filter record and the item state should be maintained to ensure listeners don't need to resubscribe
            setItemState(oldFilterRecordId, {}, true);
          });
      }

      // Create lookup object for all item filter records of this type
      const filterRecordLookup = filterRecordItemIds.reduce<ITypeFilterRecordsLookup>(
        (recordLookup, itemId) => {
          recordLookup[itemId] = getItemFilterRecordId(itemId, type);
          return recordLookup;
        },
        {},
      );

      // Set individual item state per filter record
      Object.entries(records).forEach(([itemId, filter]) => {
        const filterRecordId = filterRecordLookup[itemId];
        const currentFilterRecord = getItemState(filterRecordId);
        if (currentFilterRecord !== filter) {
          // Don't set filter record and trigger listeners unless filter changed
          setItemState(filterRecordId, filter);
        }
      });
      return setItemState(FILTER_RECORDS_ID, {
        [type]: filterRecordLookup,
      });
    },
    [getItemState, setItemState],
  );

  const getItemFilterRecord = useCallback(
    (type: string, itemId: string) => getTypeFilterRecords(type)[itemId],
    [getTypeFilterRecords],
  );

  const addItemFilterRecordListener = useCallback(
    (
      type: string,
      itemId: string,
      listenerId: number | null | undefined,
      listener: ObservableItemStateListener<IFilter>,
    ) => {
      const filterRecordId = getItemFilterRecordId(itemId, type);
      return addItemStateListener(filterRecordId, listenerId, listener);
    },
    [addItemStateListener],
  );

  const removeItemFilterRecordListener = useCallback(
    (type: string, itemId: string, listenerId: number) => {
      const filterRecordId = getItemFilterRecordId(itemId, type);
      return removeItemStateListener(filterRecordId, listenerId);
    },
    [removeItemStateListener],
  );

  // FILTER APPLICATION =============================================================

  const getActiveTypeFilters = useCallback(
    (type: string) => {
      return getFilters().filter(f => f.targets.some(t => t.type === type));
    },
    [getFilters],
  );

  // filters is optional param
  const applyActiveFilters = useCallback(
    (type: string, data: TypeItems, filters: IFilter[], filterRecords = {}) => {
      const metadata = typeMetadata[type];

      const filtersToApply = filters || getActiveTypeFilters(type);
      if (!filtersToApply.length) {
        // No filters to apply to the data, revert to default state
        return metadata.itemsDefaultEnabled && metadata.enabled ? data : [];
      }

      const overrideFilters: IFilter[] = [];
      const additiveFilters: IFilter[] = [];
      const subtractiveFilters: IFilter[] = [];

      const processAdditiveFilter = (filter: IFilter) => {
        if (metadata.enabled) {
          additiveFilters.push(filter);
        }
      };

      filtersToApply.forEach(f => {
        switch (f.mode) {
          case FilterMode.Override: {
            overrideFilters.push(f);
            // Still need to apply additive filter to nonoverridden data to filter out other data
            processAdditiveFilter(f);
            break;
          }
          case FilterMode.Additive: {
            processAdditiveFilter(f);
            break;
          }
          // eslint-disable-next-line no-fallthrough
          default: {
            // subtractive
            subtractiveFilters.push(f); // Run subtractive filter even if type is disabled to track filter records for overidden items
          }
        }
      });

      const _filterItem = (filter: IFilter, target: IFilterTarget, item: any) => {
        const filterData = target.selectFilterData ? target.selectFilterData(item) : item;
        return filter.filterItem(filterData);
      };

      // Data that passes override filters should not be filtered by other filters
      const overriddenData: TypeItems = [];
      let dataToFilter: TypeItems = [];

      const recordFilterRecord = (item: any, filter: IFilter) => {
        const id = metadata.selectId(item);
        filterRecords[id] = filter; // Track that this filter filtered out item
      };

      data.forEach(item => {
        for (let i = 0; i < overrideFilters.length; i++) {
          const f = overrideFilters[i];
          const target = f.targets.find(t => t.type === type)!;
          if (_filterItem(f, target, item)) {
            overriddenData.push(item); // Item passed override filter, do not pass through other filters
            return;
          }
        }
        // Item didn't pass override filter
        dataToFilter.push(item);
      });

      // Type is disabled, overridden data should be returned
      // Performing this check once here rather than for every data item in the for each loop
      if (!metadata.enabled) {
        dataToFilter = [];
      }

      // Tracks overridden items that have not yet been processed for filter record tracking
      let unprocessedOverriddenData = [...overriddenData];

      for (let i = 0; i < subtractiveFilters.length; i++) {
        const f = subtractiveFilters[i];
        const target = f.targets.find(t => t.type === type)!;
        dataToFilter = dataToFilter.filter(item => {
          const passedFilter = _filterItem(f, target, item);
          // Failed filter, record that this filter filtered item out
          if (!passedFilter) {
            recordFilterRecord(item, f);
          }
          return passedFilter;
        });

        unprocessedOverriddenData = unprocessedOverriddenData.filter(overriddenItem => {
          const passedFilter = _filterItem(f, target, overriddenItem);
          if (!passedFilter) {
            // Failed filter, record that this filter filtered item out
            recordFilterRecord(overriddenItem, f);
          }
          return passedFilter;
        });
      }

      // When additive filters are present for a type and the type data is disabled by default, anything that fails those filters is filtered out
      if (additiveFilters.length) {
        // Remove additive filter if no items pass the filter
        const additiveFilterActivity = additiveFilters.map(f => ({
          filter: f,
          hasItem: false,
        }));

        dataToFilter = dataToFilter.filter(item => {
          for (let i = 0; i < additiveFilters.length; i++) {
            const f = additiveFilters[i];
            const target = f.targets.find(t => t.type === type)!;
            if (_filterItem(f, target, item)) {
              // Mark that this filter's item(s) is still available for selection in the filtered data
              additiveFilterActivity[i].hasItem = true;
              return true;
            }
          }

          // Item did not pass any additive filters, filter out if disabled by default
          return metadata.itemsDefaultEnabled;
        });

        // Remove any active additive filter that doesn't have an item that passes it
        const filters = getFilters();
        const additiveFiltersToRemove = additiveFilterActivity.filter(
          f => !f.hasItem && f.filter.mode !== FilterMode.Override,
        );
        const newActiveFilters = filters.filter(
          f => !additiveFiltersToRemove.some(af => af.filter.name === f.name),
        );
        setFilters(newActiveFilters);
        additiveFiltersToRemove.forEach(af => {
          if (af.filter.onRemove) {
            af.filter.onRemove();
          }
        });
      }
      // When there are no additive filters for a type, all data that passes the subtractive filters is returned unless it's disabled by default
      else if (!metadata.itemsDefaultEnabled) {
        // Data items are disabled by default and no additive filters are applied to select the data
        dataToFilter = [];
      }

      // Only data items that are disabled by default track filter records
      setTypeFilterRecords(type, filterRecords);

      if (!overriddenData.length) {
        return [...dataToFilter]; // Important to create a new array here to enable reactivity to data changes
      }

      // Maintain original data order to prevent overridden items from jumping around
      const indexLookup = data.reduce((lookup, item, i) => {
        lookup[metadata.selectId(item)] = i; // Index original indices by item id
        return lookup;
      }, {});

      // Sort filtered data array to match original order
      return [...overriddenData, ...dataToFilter].sort(
        (a, b) => indexLookup[metadata.selectId(a)] - indexLookup[metadata.selectId(b)],
      );
    },
    [typeMetadata, getActiveTypeFilters, setTypeFilterRecords, getFilters, setFilters],
  );

  const applyActiveFiltersToDataType = useCallback(
    (type: string) => {
      const typeFilters = getActiveTypeFilters(type);
      const filteredData = applyActiveFilters(type, typedDataManagers[type].getData(), typeFilters);
      typedDataManagers[type].setFilteredData(filteredData, typeMetadata[type].selectId);
    },
    [getActiveTypeFilters, typedDataManagers, applyActiveFilters, typeMetadata],
  );

  const filterTypedData = useCallback(
    (filter: IFilter, type: string, filterExists: boolean, filteringDown: boolean) => {
      const activeTypeFilters = getActiveTypeFilters(type);
      const requiresReapply =
        (filterExists && !filteringDown) || // if filteringDown, filter should not be reapplied, filter is only getting stricter
        filter.mode === FilterMode.Additive ||
        filter.mode === FilterMode.Override ||
        activeTypeFilters.some(
          f => f.mode === FilterMode.Additive || f.mode === FilterMode.Override,
        );
      const dataToFilter = requiresReapply
        ? typedDataManagers[type].getData()
        : typedDataManagers[type].getFilteredData();
      const filters = requiresReapply ? activeTypeFilters : [filter];

      // If filters don't need to be reapplied, existing filter records can be maintained
      const typeFilterRecords = requiresReapply ? {} : getTypeFilterRecords(type);

      return applyActiveFilters(type, dataToFilter, filters, typeFilterRecords);
    },
    [typedDataManagers, getActiveTypeFilters, applyActiveFilters, getTypeFilterRecords],
  );

  // DATA PROCESSING ===================================================================

  const setTypeData = useCallback(
    // extends any here is a workaround of a TS limitation in tsx files: https://github.com/microsoft/TypeScript/issues/47062#issuecomment-988600140
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <TItem extends any = any>(data: TypeItems<TItem>, type: string) => {
      if (data && Array.isArray(data)) {
        const { itemsDefaultEnabled, enabled, selectId } = typeMetadata[type];
        typedDataManagers[type].setData(data, selectId);
        typedDataManagers[type].setFilteredData(
          itemsDefaultEnabled && enabled ? data : [],
          typeMetadata[type].selectId,
        );

        if (getFilters()?.length) {
          applyActiveFiltersToDataType(type);
        }
      }
    },
    [typedDataManagers, getFilters, applyActiveFiltersToDataType, typeMetadata],
  );

  /**
   * Creates or updates data items for the specified media type without replacing all data.
   */
  const setTypeItems = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <TItem extends any = any>(items: TypeItems<TItem>, type: string) => {
      if (items && Array.isArray(items)) {
        const { selectId } = typeMetadata[type];
        typedDataManagers[type].setItems(items, selectId);
        applyActiveFiltersToDataType(type);
      }
    },
    [typedDataManagers, applyActiveFiltersToDataType, typeMetadata],
  );

  const setTypeItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <TItem extends any = any>(item: TItem, type: string) => {
      if (item) {
        const { selectId } = typeMetadata[type];
        typedDataManagers[type].setItem(selectId(item), item);
        applyActiveFiltersToDataType(type);
      }
    },
    [typedDataManagers, applyActiveFiltersToDataType, typeMetadata],
  );

  const deleteTypeItem = useCallback(
    (itemId: string, type: string) => {
      const { selectId } = typeMetadata[type];
      typedDataManagers[type].setData(
        getTypeData(type).filter(item => selectId(item) !== itemId),
        selectId,
      );
      applyActiveFiltersToDataType(type);
    },
    [typeMetadata, typedDataManagers, getTypeData, applyActiveFiltersToDataType],
  );

  const updateTypeItem = useCallback(
    (itemId: string, type: string, field: string, value: any) => {
      const itemToUpdate = typedDataManagers[type].getItem(itemId);
      if (!itemToUpdate) return;

      // Clone the item to ensure memoized references are updated with new value
      const itemClone = structuredClone(itemToUpdate);

      if (itemClone.node) {
        itemClone.node[field] = value;
      } else {
        itemClone[field] = value;
      }

      typedDataManagers[type].setItem(itemId, itemClone);
      applyActiveFiltersToDataType(type);
    },
    [applyActiveFiltersToDataType, typedDataManagers],
  );

  // FILTER MANAGEMENT =========================================================

  /** Filter Shape
    @param {*} name* String
    @param {*} filterItem* fn to supply: arr.filter(filterItem) or boolean to keep all or no data (boolean not supported for additive filters)
    @param {*} targets* [{type: MEDIA_TYPE, selectFilterData(i) => i.node.capturedAt}]
    @param {*} label String
    @param {*} selected optional string value of the filter
    @param {*} mode default: `subtractive`
      - `subtractive`: filters out data
      - `additive`: cherry picks data to keep from filtered data, any data that fails all additive filters will be filtered out
      - `override`: additive filter that ignores other filters
    @param {*} onRemove optional fn to fire when removing the filter
    @param {*} onDestroy optional fn to fire when destroying the filter
    @param {*} customChip optional custom chip component to use for representing the filter
    @param {*} invisible optional flag indicating if the filter should be shown in the list of active filters

    Options shape {}
    @param {*} filteringDown optional bool indicating that the update to the filter is only further filtering the data.
    (Avoids reapplication of all active filters to the target data types.)
    @param {*} loadedFromUrl optional bool indicating that the filter was loaded from the url
  */
  const addFilter = useCallback(
    (filter: Optional<IFilter, 'mode'>, options?: IAddFilterOptions) => {
      const _filter = {
        mode: FilterMode.Subtractive, // default to subtractive
        ...filter,
      };

      const { name, filterItem, targets } = _filter;

      if (!filter || !name || !filterItem || !targets) return;

      const filters = getFilters();
      const filterIndex = filters.findIndex(f => f.name === name);
      let existingFilter: IFilter | undefined;
      const newFilters = [...filters];

      if (filterIndex < 0) {
        newFilters.push(_filter); // new filter
      } else {
        // filter exists, update it
        existingFilter = filters[filterIndex];
        newFilters.splice(filterIndex, 1, _filter);
      }

      // IMPORTANT: this setter needs to be called before applying the filter, in some cases the filters will be used
      // and the new filter needs to be present in them.
      setFilters(newFilters);

      // If a target is removed from the filter, that type needs to be refiltered to account for this filter no longer
      // applying to the type.
      const removedTargets = existingFilter?.targets.filter(
        oldTarget =>
          !_filter.targets.some((newTarget: IFilterTarget) => newTarget.type === oldTarget.type),
      );
      removedTargets?.forEach(target => {
        applyActiveFiltersToDataType(target.type);
      });

      const { filteringDown, loadedFromUrl } = options || {};

      _filter.targets.forEach((target: IFilterTarget) => {
        const filteredData = filterTypedData(
          _filter,
          target.type,
          !!existingFilter,
          !!filteringDown,
        );
        typedDataManagers[target.type].setFilteredData(
          filteredData,
          typeMetadata[target.type].selectId,
        );
      });

      // For each filter effect, pass the filter, previous filter, and indicate that the filter is active
      getFilterEffects(name).forEach(effect => effect.fn(_filter, existingFilter, !!loadedFromUrl));
    },
    [
      getFilters,
      setFilters,
      filterTypedData,
      typedDataManagers,
      applyActiveFiltersToDataType,
      getFilterEffects,
      typeMetadata,
    ],
  );

  const removeFilter = useCallback(
    (name: string) => {
      const filters = getFilters();
      const filterToRemove = filters.find(f => f.name === name);

      if (!filterToRemove) return; // The filter is not set

      const newFilters = filters.filter(f => f.name !== name);

      // IMPORTANT: this setter needs to be called before applying the filter, in some cases the filters will be used
      // and the new filter needs to be present in them.
      setFilters(newFilters);

      filterToRemove.targets.forEach(target => {
        applyActiveFiltersToDataType(target.type);
      });

      // Pass null for filter in effect to indicate it was removed, pass filterToRemove as previousFilter
      getFilterEffects(name).forEach(effect => effect.fn(null, filterToRemove, false));

      if (filterToRemove.onRemove) {
        filterToRemove.onRemove();
      }
    },
    [getFilters, applyActiveFiltersToDataType, setFilters, getFilterEffects],
  );

  const reorderFilters = useCallback(
    (filters: string[], reorderMethod: (filters: IFilter[]) => IFilter[]) => {
      const otherFilters: IFilter[] = [];
      const filtersToReorder: IFilter[] = [];

      getFilters().forEach(f => {
        if (filters.includes(f.name)) {
          filtersToReorder.push(f);
        } else {
          otherFilters.push(f);
        }
      });

      const reorderedFilters = reorderMethod(filtersToReorder);

      setFilters([...otherFilters, ...reorderedFilters]);
    },
    [setFilters, getFilters],
  );

  const setTypeEnabled = useCallback(
    (type: string, enabled: boolean) => {
      typeMetadata[type].enabled = enabled;
      setTypeMetadata({ ...typeMetadata });
      applyActiveFiltersToDataType(type);

      if (typeMetadata[type].urlKey) {
        // urlKey provided, track enabled state in url
        setUrlState(typeMetadata[type].urlKey, enabled ? null : '0'); // Only show in url when disabled
      }
    },
    [typeMetadata, setTypeMetadata, applyActiveFiltersToDataType, setUrlState],
  );

  const disableTypeData = useCallback(
    (type: string) => {
      setTypeEnabled(type, false);
    },
    [setTypeEnabled],
  );

  const enableTypeData = useCallback(
    (type: string) => {
      setTypeEnabled(type, true);
    },
    [setTypeEnabled],
  );

  useMountedEffect(() => {
    Object.entries(typeMetadata).forEach(([type, metadata]) => {
      if (metadata.urlKey) {
        // Type enabled state is tracked in url
        const disabledInUrl = getUrlState(metadata.urlKey);
        if (!metadata.enabled || disabledInUrl) {
          disableTypeData(type);
        }
      }
    });

    return () => {
      const filters = getFilters();
      filters.forEach(f => {
        if (f.onDestroy) {
          // Allow filters to do any cleanup needed before the filter is destroyed
          f.onDestroy();
        }
      });
    };
  });

  return (
    <FilterContext.Provider
      value={{
        useFilters,
        addFilter,
        reorderFilters,
        removeFilter,
        getFilter,
        disableTypeData,
        enableTypeData,
        setTypeData,
        setTypeItems,
        setTypeItem,
        getTypeData,
        getTypeItem,
        deleteTypeItem,
        useTypeData,
        addTypeDataListener,
        removeTypeDataListener,
        updateTypeItem,
        getTypeFilteredData,
        useTypeFilteredData,
        typeMetadata,
        getItemFilterRecord,
        addItemFilterRecordListener,
        removeItemFilterRecordListener,
        addFilterEffect,
        removeFilterEffect,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const FilterConsumer = FilterContext.Consumer;

export default FilterContext;
