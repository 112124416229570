import { atom } from 'recoil';

const activeImageId = atom({
  key: 'activeImageId',
  default: null,
});

const mediaViewerOpen = atom({
  key: 'mediaViewerOpen',
  default: null,
});

const krPanoDirection = atom({
  key: 'krPanoDirection',
  default: 0,
});

const krPanoFov = atom({
  key: 'krPanoFov',
  default: 90,
});

const krPanoLoaded = atom({
  key: 'krPanoLoaded',
  default: false,
});

const krPanoXmlUrl = atom({
  key: 'krPanoXmlUrl',
  default: null,
});

const krPanoCanvasVersion = atom({
  key: 'krPanoCanvasVersion',
  default: 0,
});

export {
  activeImageId,
  mediaViewerOpen,
  krPanoDirection,
  krPanoFov,
  krPanoLoaded,
  krPanoXmlUrl,
  krPanoCanvasVersion,
};
