import React from 'react';

import IdsDialog, { IIdsDialogProps } from '../../../../../../../../components/IdsDialog';

import EditRouteForm, { IEditRouteFormProps } from './EditRouteForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEditRouteDialogProps extends IIdsDialogProps, IEditRouteFormProps {}

const EditRouteDialog: React.FC<IEditRouteDialogProps> = ({
  open,
  onOpen,
  onClose,
  item,
  ...rest
}) => {
  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='Edit Route'
      dialogKey={`editRoute-${item.id}`}
      maxWidth='lg'
      fullWidth
      {...rest}
    >
      <EditRouteForm item={item} handleClose={onClose} />
    </IdsDialog>
  );
};

export default EditRouteDialog;
