import { gql } from 'graphql-request';

import useOrgGraphQuery from '../../../../../../../../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery from '../../../../../../../../hooks/useCursorPaginatedQuery';
import { UseGraphQueryOptions } from '../../../../../../../../hooks/useGraphQuery';

import { useTaskResponseKeys } from '../../../../../../../../services/TaskResponsesService';
import { useAssignmentResponseKeys } from '../../../../../../../../services/AssignmentResponsesService';

import { TaskResponseHeaderFrag } from '../../TaskResponse';

import { ITaskResponseListQueryEdge } from '../types';

const TaskResponseListQuery = gql`
  query TaskResponsesQuery(
    $orgId: ID
    $id: ID!
    $tenantId: ID
    $taskId: ID
    $take: Int
    $after: String
  ) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      assignmentTaskResponses(take: $take, after: $after, search: { taskId: $taskId }) {
        edges {
          cursor
          node {
            id
            label
            updatedAt
            completed
            ...TaskResponseHeaderFrag
          }
        }
      }
    }
  }
  ${TaskResponseHeaderFrag}
`;

const useGetTaskResponses = (
  taskId: string,
  assignmentResponseId: string,
  options: UseGraphQueryOptions,
) => {
  const taskResponseKeys = useTaskResponseKeys();
  const assignmentResponseKeys = useAssignmentResponseKeys();

  const useTaskResponses = (take: number, after?: string | null) =>
    useOrgGraphQuery(
      [
        ...assignmentResponseKeys.detail(assignmentResponseId),
        ...taskResponseKeys.taskList(taskId),
        `take-${take}`,
        `after-${after}`,
      ],
      TaskResponseListQuery,
      {
        id: assignmentResponseId,
        taskId,
        take,
        after,
      },
      options,
    );

  return useCursorPaginatedQuery<{
    cursor: string;
    node: ITaskResponseListQueryEdge;
  }>({
    useQuery: useTaskResponses,
    defaultTake: 50,
    selectConnection: data => data?.assignmentResponse.assignmentTaskResponses,
  });
};

export default useGetTaskResponses;
