import axios from 'axios';

import { isPdfFile } from '../utils/fileDownload';

// For using outside of React environment
export const uploadFileBinary = (url: string, file: File) => {
  const contentType = isPdfFile(file) ? 'application/pdf' : 'application/octet-stream';

  return axios.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
  });
};

export const useUploadFile = () => {
  return {
    uploadFileBinary,
  };
};
