import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useUserKeys } from '../../services/UsersService';
import useGraphQuery, { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import { activeUserState } from '../../atoms/users';
import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgUsersRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const UserRouteQuery = gql`
  query UserRoute($id: ID!, $orgId: ID!) {
    user(id: $id, organizationId: $orgId) {
      id
      firstName
      lastName
    }
  }
`;

const UserRoute = () => {
  const userKeys = useUserKeys();

  const [user, setUser] = useRecoilState(activeUserState);
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useUser = (userId: string | undefined, options: UseGraphQueryOptions) => {
    return useGraphQuery(
      userKeys.route(userId!),
      UserRouteQuery,
      { id: userId, orgId: activeOrg.id },
      options,
    );
  };

  return (
    <EntityRoute
      idParam='userId'
      currentEntity={user}
      setEntity={setUser}
      useEntity={useUser}
      entitySelector={data => data?.user}
      errorRedirectRoute={getOrgUsersRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
      })}
    />
  );
};

export default UserRoute;
