import React from 'react';
import { SvgIcon } from '@mui/material';

const PanoramaIcon = props => (
  <SvgIcon {...props}>
    <path d='m12,5C5.79,5,.93,7.54.93,10.79c0,2.5,2.96,4.66,7.4,5.47v1.71c0,.57.46,1.03,1.03,1.03.28,0,.54-.11.72-.31l2.95-2.95c.41-.41.41-1.05,0-1.46l-2.95-2.95c-.19-.19-.45-.3-.72-.3-.57,0-1.03.46-1.03,1.03v1c-2.96-.65-4.29-1.88-4.29-2.28,0-.67,2.82-2.67,7.96-2.67s7.96,2.01,7.96,2.67c0,.34-.95,1.31-3.16,2-.64.2-1.07.79-1.07,1.47,0,1.02,1.03,1.8,2,1.49,3.35-1.04,5.34-2.9,5.34-4.96,0-3.25-4.86-5.79-11.07-5.79Z' />
  </SvgIcon>
);

export default PanoramaIcon;
