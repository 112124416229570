import { atom } from 'recoil';

// Active Program
interface IActiveProgramState {
  id: string;
  name: string;
}

export const activeProgramState = atom<IActiveProgramState | null>({
  key: 'activeProgramState',
  default: null,
});

// Active Program Assignment
interface IActiveProgramAssignmentState {
  id: string;
  title: string;
}

export const activeProgramAssignmentState = atom<IActiveProgramAssignmentState | null>({
  key: 'activeProgramAssignmentState',
  default: null,
});

// Active Program Assignment Response
interface IActiveProgramAssignmentResponseState {
  id: string;
}

export const activeProgramAssignmentResponseState =
  atom<IActiveProgramAssignmentResponseState | null>({
    key: 'activeProgramAssignmentResponseState',
    default: null,
  });
