import { useEffect, useState } from 'react';
import { debounce } from '@mui/material';

export const SMALL_SCREEN_BREAKPOINT = 768;

export const useIsSmallScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= SMALL_SCREEN_BREAKPOINT;
};
