import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useRecoilValue } from 'recoil';

import IdsCommentsSection, {
  IIdsCommentsSectionProps,
} from '../../../components/IdsCommentsSection';
import { IComment } from '../../../components/IdsCommentsSection/IdsComment/types';
import { USER_ROLES } from '../../../constants/users';
import { sessionState } from '../../../atoms/session';
import usePermissions from '../../../hooks/usePermissions';
import { useCreateMediaComment, useUpdateMediaComment } from '../../../services/MediaService';

import useDeleteMediaCommentAction from './useDeleteMediaCommentAction';

export interface IEntityCommentsProps {
  comments: IIdsCommentsSectionProps['comments'];
  parentEntityId: string;
  orgId: string;
  onCommentCreated?: () => void;
}

const EntityComments: React.FC<IEntityCommentsProps> = ({
  comments,
  parentEntityId,
  orgId,
  onCommentCreated,
  ...rest
}) => {
  const userSession = useRecoilValue(sessionState);
  const { userHasOneOfRoles } = usePermissions();
  const createCommentMutation = useCreateMediaComment(parentEntityId);
  const updateCommentMutation = useUpdateMediaComment(parentEntityId);
  const { deleted, deleting, deleteComment } = useDeleteMediaCommentAction(parentEntityId);
  const [deletingComment, setDeletingComment] = useState<IComment | null>();
  const { enqueueSnackbar } = useSnackbar();

  const handleCommentError = useCallback(
    (error: string) => {
      enqueueSnackbar(error, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const createComment = useCallback(
    async (comment: string) => {
      const result = await createCommentMutation.mutateAsync({
        organizationId: orgId,
        entityUrn: parentEntityId,
        text: comment,
      });

      if (result?.createMediaComment.errors?.length) {
        // comment creation failed
        handleCommentError('Failed to save comment');
        return;
      }

      if (onCommentCreated) {
        onCommentCreated();
      }
    },
    [createCommentMutation, parentEntityId, orgId, handleCommentError, onCommentCreated],
  );

  const replyToComment = useCallback(
    async (parentCommentId: string, comment: string) => {
      const result = await createCommentMutation.mutateAsync({
        organizationId: orgId,
        entityUrn: parentEntityId,
        parentCommentId,
        text: comment,
      });

      if (result?.createMediaComment.errors?.length) {
        // comment creation failed
        handleCommentError('Failed to save reply');
      }
    },
    [createCommentMutation, handleCommentError, parentEntityId, orgId],
  );

  const editComment = useCallback(
    async (commentId: string, comment: string) => {
      const result = await updateCommentMutation.mutateAsync({
        id: commentId,
        organizationId: orgId,
        text: comment,
      });

      if (result?.updateMediaComment.errors?.length) {
        // comment update failed
        handleCommentError('Failed to save edited comment');
      }
    },
    [updateCommentMutation, handleCommentError, orgId],
  );

  const handleDeleteComment = useCallback(
    async (comment: IComment) => {
      setDeletingComment(comment);
      const onCancel = () => {
        setDeletingComment(null);
      };
      await deleteComment(comment, null, onCancel);
    },
    [deleteComment],
  );

  const commentIsDeleting = useCallback(
    (comment: IComment) => {
      return deletingComment && deletingComment.id === comment.id && (deleting || deleted);
    },
    [deletingComment, deleting, deleted],
  );

  const getCanReplyToComment = useCallback(
    (comment: IComment) => {
      return !commentIsDeleting(comment);
    },
    [commentIsDeleting],
  );

  const userIsCreator = useCallback(
    comment => comment.creator.id === userSession!.id,
    [userSession],
  );

  const getCanEditComment = useCallback(
    (c: IComment) =>
      (userIsCreator(c) || userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN])) &&
      !commentIsDeleting(c),
    [commentIsDeleting, userHasOneOfRoles, userIsCreator],
  );

  const getCanDeleteComment = useCallback(
    (c: IComment) =>
      (userIsCreator(c) || userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN])) &&
      !commentIsDeleting(c),
    [commentIsDeleting, userHasOneOfRoles, userIsCreator],
  );

  return (
    <IdsCommentsSection
      comments={comments}
      hideCreateComment={true}
      onCreate={createComment}
      getCanReply={getCanReplyToComment}
      onReply={replyToComment}
      getCanEdit={getCanEditComment}
      onEdit={editComment}
      getCanDelete={getCanDeleteComment}
      onDelete={handleDeleteComment}
      {...rest}
    />
  );
};

export default EntityComments;
