import React from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../atoms/organizations';
import { AssignmentDetailsTemplatePage } from '../../Assignments/AssignmentDetailsTemplatePage';
import { activeProjectAssignmentState, activeProjectState } from '../../../atoms/projects';
import { useGetProjectAssignmentResponsesListQuery } from '../../../services/AssignmentResponsesService';
import { activeTenantState } from '../../../atoms/tenants';
import {
  getOrgProjectAssignmentResponseRoute,
  getOrgProjectAssignmentsRoute,
  getOrgProjectRoute,
  getOrgProjectsRoute,
} from '../../../utils/routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProjectAssignmentDetailsPageProps {}

const ProjectAssignmentDetailsPage: React.FC<IProjectAssignmentDetailsPageProps> = () => {
  const activeProjectAssignment = useRecoilValue(activeProjectAssignmentState);
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);
  const useGetAssignmentResponses = () =>
    useGetProjectAssignmentResponsesListQuery(activeOrg.id, {
      projectId: activeProject!.id,
      assignmentId: activeProjectAssignment!.id,
    });

  if (!activeProjectAssignment || !activeProject || !activeTenant) {
    return null;
  }

  return (
    <AssignmentDetailsTemplatePage
      pageTitle='Project Assignment Details'
      assignmentId={activeProjectAssignment.id}
      useGetAssignmentResponses={useGetAssignmentResponses}
      getToAssignmentResponse={(assignmentResponseId: string) =>
        getOrgProjectAssignmentResponseRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          projectId: activeProject.id,
          assignmentId: activeProjectAssignment.id,
          assignmentResponseId,
        })
      }
      breadcrumbs={[
        {
          label: 'Projects',
          route: getOrgProjectsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        {
          label: activeProject.name,
          route: getOrgProjectRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            projectId: activeProject.id,
          }),
        },
        {
          label: 'Assignments',
          route: getOrgProjectAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            projectId: activeProject.id,
          }),
        },
      ]}
    />
  );
};

export default ProjectAssignmentDetailsPage;
