import { Link, LinkProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from '../IdsCommentsSection.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkButtonProps extends LinkProps<'button'> {}

const LinkButton: React.FC<ILinkButtonProps> = ({ className, children, ...rest }) => (
  <Link
    component='button'
    type='button'
    variant='caption'
    noWrap
    className={clsx(styles.linkBtn, className)}
    {...rest}
  >
    {children}
  </Link>
);

export default LinkButton;
