import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import {
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  MEDIA_TYPES,
  MediaType,
} from '../../../../constants/media';
import useDeleteEntity from '../../../../hooks/useDeleteEntity';
import useFilterContext from '../../../../hooks/useFilterContext';
import {
  useDeleteAreaOfInterest,
  useUpdateAreaOfInterest,
} from '../../../../services/AreaOfInterestService';
import IdsForm from '../../../ids-forms/IdsForm';
import { USER_ROLES } from '../../../../constants/users';
import { sessionState } from '../../../../atoms/session';
import usePermissions from '../../../../hooks/usePermissions';
import usePrevious from '../../../../hooks/usePrevious';

import { updateAreaOfInterestValidationSchema } from '../areaOfInterestValidation';
import EOIInfoWrapper from '../../entities-of-interest/EntityOfInterestInfo/EOIInfoWrapper';
import EOIInfoActions from '../../entities-of-interest/EntityOfInterestInfo/EOIInfoActions';

import EOIInfoTags from '../../entities-of-interest/EntityOfInterestInfo/EOIInfoTags';

import AoiInfoDetails from './AoiInfoDetails';

import styles from './AreaOfInterestInfo.module.css';

const sx = {
  backdrop: {
    zIndex: theme => theme.zIndex.drawer + 1,
  },
};

const AreaOfInterestInfo = ({ aoi, onClose, locationId }) => {
  const prevAOI = usePrevious(aoi);
  const { id } = useRecoilValue(sessionState);
  const { userHasOneOfRoles } = usePermissions();

  const deleteAoiMutation = useDeleteAreaOfInterest(locationId);
  const updateAoiMutation = useUpdateAreaOfInterest(locationId);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { setTypeItem, deleteTypeItem } = useFilterContext();
  const { deleting, handleDelete } = useDeleteEntity();

  const stateSetter = aoi => {
    const { color, flagged, name, data, metadata, distance, area } = aoi.node;
    return {
      color,
      flagged,
      name,
      data,
      // It is important to return null instead of undefined to prevent
      // errors in autocomplete form field component
      levelId:
        metadata.find(item => item.type === MEDIA_METADATA_TYPES[MediaMetadataType.Level].type)
          ?.id || null,
      tags: metadata
        .filter(item => item.type === MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type)
        .map(item => item.id),
      distance,
      area,
    };
  };

  const [state, setState] = useState(() => stateSetter(aoi));
  const [isEditMode, setIsEditMode] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (aoi !== prevAOI) {
      setState(stateSetter(aoi));
    }
  }, [aoi, prevAOI]);

  const canModify = useMemo(
    () =>
      userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.TENANT_ADMIN]) ||
      aoi.node.creator.id === id,
    [userHasOneOfRoles, aoi.node.creator.id, id],
  );

  const initialValues = useMemo(
    () => stateSetter(aoi),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aoi],
  );

  const onUpdateHandler = useCallback(
    async values => {
      setInProgress(true);

      const input = {};

      // In case flag button was clicked
      if ('flagged' in values) {
        input.flagged = values.flagged;
      }

      // In case if tags field was clicked
      if ('tags' in values) {
        input.metadata = [
          ...values.tags.map(id => ({
            id,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
          })),
        ];

        // Also need to add level tag to metadata
        if ('levelId' in values) {
          input.metadata.push({
            id: values.levelId,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
          });
        } else if (state.levelId) {
          input.metadata.push({
            id: state.levelId,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
          });
        }
      }

      // Check if the whole form was submitted
      if (Object.keys(values).length > 1) {
        input.color = values.color;
        input.name = values.name;
        input.data = values.data;
      }

      const result = await updateAoiMutation.mutateAsync({
        organizationId: activeOrg.id,
        id: aoi.node.id,
        ...input,
      });

      const updatedAOI = result.updateAreaOfInterest.areaOfInterest;
      const updatedAOIItem = {
        cursor: aoi.cursor,
        tooltip: updatedAOI.name,
        node: updatedAOI,
      };

      setTypeItem(updatedAOIItem, MEDIA_TYPES[MediaType.AreaOfInterest].type);
      setState(stateSetter(updatedAOIItem));

      setInProgress(false);
    },
    [activeOrg.id, aoi.cursor, aoi.node.id, setTypeItem, state.levelId, updateAoiMutation],
  );

  const onDeleteHandler = useCallback(async () => {
    setInProgress(true);

    await handleDelete(
      async () => {
        await deleteAoiMutation.mutateAsync({
          organizationId: activeOrg.id,
          id: aoi.node.id,
        });
      },
      aoi.node.name,
      'This area of interest will be deleted.',
      'Area of interest',
      () => {
        deleteTypeItem(aoi.node.id, MEDIA_TYPES[MediaType.AreaOfInterest].type);
        onClose(true);
      },
      () => setInProgress(false),
    );
  }, [
    activeOrg.id,
    deleteAoiMutation,
    deleteTypeItem,
    handleDelete,
    onClose,
    aoi.node.id,
    aoi.node.name,
  ]);

  return (
    <EOIInfoWrapper key={`aoi-info-${aoi.node.id}`}>
      <IdsForm
        validationSchema={updateAreaOfInterestValidationSchema}
        initialValues={initialValues}
        onSubmit={onUpdateHandler}
        errorHandler={() => 'Area of interest could not be updated.'}
        successMessage='Area of interest updated.'
      >
        <Backdrop className={styles.inProgressOverlay} sx={sx.backdrop} open={inProgress}>
          <CircularProgress />
        </Backdrop>
        <Grid container direction='column' gap={1} p={1}>
          <Grid item container xs='auto' direction='row' justifyContent='flex-end' gap={1}>
            <EOIInfoActions
              canModify={canModify}
              isDeleting={deleting}
              isFlagged={state.flagged}
              setIsEditMode={setIsEditMode}
              isEditMode={isEditMode}
              onClose={onClose}
              onDeleteHandler={onDeleteHandler}
              toggleFlagged={onUpdateHandler}
            />
          </Grid>
          <Grid item xs='auto'>
            <AoiInfoDetails
              color={state.color}
              data={state.data}
              name={state.name}
              levelId={state.levelId}
              flagged={state.flagged}
              firstName={aoi.node.creator.firstName}
              lastName={aoi.node.creator.lastName}
              distance={state.distance}
              area={state.area}
              isEditMode={isEditMode}
            />
          </Grid>
          <Grid item xs='auto'>
            <EOIInfoTags
              canModify={canModify}
              updateTags={onUpdateHandler}
              isEditMode={isEditMode}
            />
          </Grid>
        </Grid>
      </IdsForm>
    </EOIInfoWrapper>
  );
};

AreaOfInterestInfo.propTypes = {
  onClose: PropTypes.func.isRequired, // (deleted) => {}
  locationId: PropTypes.string.isRequired,
  aoi: PropTypes.shape({
    cursor: PropTypes.string.isRequired,
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      creator: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
      color: PropTypes.string.isRequired,
      flagged: PropTypes.bool.isRequired,
      data: PropTypes.string.isRequired,
      metadata: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string,
          type: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default AreaOfInterestInfo;
