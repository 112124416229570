import { atom } from 'recoil';
import { MapViewState } from 'deck.gl/typed';

import { panelOptions } from '../context/LocationMapContext';
import { CompletedFilterMode } from '../hooks/filtering/useCompletedFilter';

const viewState = atom<MapViewState | null>({
  key: 'deck-viewState',
  default: {
    longitude: -98.579482, // Default to center of US
    latitude: 39.828349, // Default to center of US
    zoom: 2,
  },
});

const clusterState = atom<boolean>({
  key: 'cluster-markers',
  default: true,
});

const tenantClusterState = atom({
  key: 'tenant-cluster-markers',
  default: true,
});

const flagFilterState = atom<string>({
  key: 'flag-filterState',
  default: 'All', // FLAG_FILTER_MODES.INACTIVE => importing the const causes a circular dependency and breaks tests
});

const publishedFilterState = atom<string>({
  key: 'published-filterState',
  default: 'All', // PUBLISHED_FILTER_MODES.INACTIVE => importing the const causes a circular dependency and breaks tests
});

const completedFilterState = atom<CompletedFilterMode>({
  key: 'completed-filterState',
  default: CompletedFilterMode.Inactive,
});

const infoPanelExpandedState = atom<boolean>({
  key: 'location-map-info-expanded-state',
  default: true,
});

const mapMenuExpandedState = atom<boolean>({
  key: 'map-menu-expanded-state',
  default: false,
});

const infoPanelActivePaneState = atom<string>({
  key: 'location-map-info-active-pane-state',
  default: panelOptions.MEDIA,
});

export {
  viewState,
  clusterState,
  tenantClusterState,
  flagFilterState,
  publishedFilterState,
  completedFilterState,
  infoPanelExpandedState,
  mapMenuExpandedState,
  infoPanelActivePaneState,
};
