import React from 'react';
import PropTypes from 'prop-types';

import { gql } from 'graphql-request';
import { capitalCase } from 'change-case';

import IdsSearchableList from '../../../components/ids-lists/IdsSearchableList';

export const userFilters = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
  {
    key: 'active',
    label: 'Active',
    filterItem: userItem => userItem.node.user.activationState === 'active',
  },
  {
    key: 'inactive',
    label: 'Inactive',
    filterItem: userItem => userItem.node.user.activationState === 'inactive',
  },
];

export const searchUserItem = (userItem, query) => {
  const searchProps = [
    userItem.node.user.email,
    `${userItem.node.user.firstName.trim()} ${userItem.node.user.lastName.trim()}`,
    capitalCase(userItem.node.roleName),
  ];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

export const sortUserItems = (a, b) => {
  const name = e => `${e.node.user.firstName} ${e.node.user.lastName}`;
  return name(a).localeCompare(name(b)); // alphabetical order
};

export const UsersListFrag = gql`
  fragment UsersListFrag on UserInRole {
    user {
      id
      firstName
      lastName
      email
      activationState
    }
    roleName
  }
`;

const UsersList = ({ userItems, renderUserItem, ...rest }) => (
  <IdsSearchableList
    items={userItems}
    renderItem={renderUserItem}
    searchPlaceholder={'Search users'}
    searchItem={searchUserItem}
    filters={userFilters}
    sortItems={sortUserItems}
    {...rest}
  />
);

UsersList.propTypes = {
  userItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderUserItem: PropTypes.func.isRequired, // (userItem) => {}
};

export default UsersList;
