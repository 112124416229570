import {
  useGetOrganizationProjects,
  useGetProjectListItemDetails,
} from '../../../../../services/ProjectService';
import { useGetProgramProjects } from '../../../../../services/ProgramsService';
import { useGetLocationProjects } from '../../../../../services/LocationService';
import { EntityParentType } from '../../../../../components/entity-chips/EntityParentChip';

import { IProjectsAutocompleteFieldProps } from './index';

export const ENTITY_PARENT_MAP = {
  [EntityParentType.Program]: useGetProgramProjects,
  [EntityParentType.Location]: useGetLocationProjects,
  [EntityParentType.Organization]: useGetOrganizationProjects,
  [EntityParentType.Project]: useGetProjectListItemDetails,
};

const useGetProjects = (assignment: IProjectsAutocompleteFieldProps['assignment']) => {
  const useAppropriateHook =
    ENTITY_PARENT_MAP[assignment.node.parentType as keyof typeof ENTITY_PARENT_MAP];

  return useAppropriateHook(assignment.node.parentId);
};

export default useGetProjects;
