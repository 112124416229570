/* eslint-disable eqeqeq */
/**
 Returns an abbreviated string representation of the number.
 - **Example**: `1234 => 1.2k`
 - *Source code adapted from here:* https://www.geeksforgeeks.org/how-to-convert-long-number-into-abbreviated-string-in-javascript/
*/
export default function getAbbreviatedNumber(n) {
  if (n < 1e3) return n; // don't abbreviate numbers less than 1000

  // thousands, millions, billions etc..
  var s = ['', 'K', 'M', 'B', 'T'];

  // dividing the value by 3.
  var sNum = Math.floor(('' + n).length / 3);

  // calculating the precised value.
  var sVal = parseFloat((sNum != 0 ? n / Math.pow(1000, sNum) : n).toPrecision(2));

  if (sVal % 1 != 0) {
    sVal = sVal.toFixed(1);
  }

  // appending the letter to precised val.
  return sVal + s[sNum];
}
