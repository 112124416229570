import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';
import { activeTenantState } from '../../atoms/tenants';
import {
  activeProjectAssignmentResponseState,
  activeProjectAssignmentState,
  activeProjectState,
} from '../../atoms/projects';
import { useAssignmentResponseKeys } from '../../services/AssignmentResponsesService';
import { getOrgProjectAssignmentRoute } from '../../utils/routes';

import EntityRoute from './EntityRoute';

const AssignmentResponseRouteQuery = gql`
  query AssignmentResponseRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
    }
  }
`;

export const ProjectAssignmentResponseRoute: React.FC = () => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrganization = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);
  const activeProjectAssignment = useRecoilValue(activeProjectAssignmentState);
  const [activeProjectAssignmentResponse, setActiveProjectAssignmentResponse] = useRecoilState(
    activeProjectAssignmentResponseState,
  );

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useProjectAssignmentResponse = (id: string | undefined, options: UseGraphQueryOptions) =>
    useOrgGraphQuery(
      assignmentResponseKeys.route(id!),
      AssignmentResponseRouteQuery,
      { id },
      options,
    );

  if (!activeProject || !activeProjectAssignment) {
    return null;
  }

  return (
    <EntityRoute
      idParam={'assignmentResponseId'}
      currentEntity={activeProjectAssignmentResponse}
      setEntity={setActiveProjectAssignmentResponse}
      useEntity={useProjectAssignmentResponse}
      entitySelector={data => data?.assignmentResponse}
      errorRedirectRoute={getOrgProjectAssignmentRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrganization.id,
        projectId: activeProject.id,
        assignmentId: activeProjectAssignment.id,
      })}
    />
  );
};

export default ProjectAssignmentResponseRoute;
