import * as Yup from 'yup';

export const userDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  title: Yup.string().optional(),
  email: Yup.string().required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  multiShotEnable: Yup.boolean(),
  activationState: Yup.boolean(),
});
