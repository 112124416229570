import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useAssignmentResponseKeys } from '../../services/AssignmentResponsesService';
import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';
import { activeTenantState } from '../../atoms/tenants';
import {
  activeProgramAssignmentResponseState,
  activeProgramAssignmentState,
  activeProgramState,
} from '../../atoms/programs';

import { getOrgProgramAssignmentRoute } from '../../utils/routes';

import EntityRoute from './EntityRoute';

const AssignmentResponseRouteQuery = gql`
  query AssignmentResponseRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
    }
  }
`;

export const ProgramAssignmentResponseRoute: React.FC = () => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrganization = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);
  const activeProgramAssignment = useRecoilValue(activeProgramAssignmentState);
  const [activeProgramAssignmentResponse, setActiveProgramAssignmentResponse] = useRecoilState(
    activeProgramAssignmentResponseState,
  );

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useProgramAssignmentResponse = (id: string | undefined, options: UseGraphQueryOptions) =>
    useOrgGraphQuery(
      assignmentResponseKeys.route(id!),
      AssignmentResponseRouteQuery,
      { id },
      options,
    );

  if (!activeProgram || !activeProgramAssignment) {
    return null;
  }

  return (
    <EntityRoute
      idParam={'assignmentResponseId'}
      currentEntity={activeProgramAssignmentResponse}
      setEntity={setActiveProgramAssignmentResponse}
      useEntity={useProgramAssignmentResponse}
      entitySelector={data => data?.assignmentResponse}
      errorRedirectRoute={getOrgProgramAssignmentRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrganization.id,
        programId: activeProgram.id,
        assignmentId: activeProgramAssignment.id,
      })}
    />
  );
};

export default ProgramAssignmentResponseRoute;
