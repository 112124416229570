import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import useRasterOverlayFilterInitializer from '../../../../components/mapping/filter-initializers/useRasterOverlayFilterInitializer';
import { useRestoreLevelFilterFromUrl } from '../../../../components/ids-filters/LevelFilter';
import { useRestoreAreaFilterFromUrl } from '../../../../components/ids-filters/AreaFilter';
import { useRestoreCategoryFilterFromUrl } from '../../../../components/ids-filters/CategoryFilter';
import { useRestoreTypeFilterFromUrl } from '../../../../components/ids-filters/TypeFilter';
import { IMetadataTypeFilterBuilder } from '../../../../components/mapping/LocationMapMenu/useMenuBuilder';

import { useRestoreCompletedFilterFromUrl } from '../../../../hooks/filtering/useCompletedFilter';
import useFilterContext from '../../../../hooks/useFilterContext';
import { useGetPointsMetadataTypes } from '../hooks';

import { activeProjectRouteState, activeProjectState } from '../../../../atoms/projects';
import { activeOrganizationState } from '../../../../atoms/organizations';

import { useGetLocationRasterOverlays } from '../../../../services/LocationService';
import { useGetRouteDetails } from '../../../../services/RouteService';

import { MediaMetadataType, MediaType } from '../../../../constants/media';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFilterInitializersProps {}

const FilterInitializers: React.FC<IFilterInitializersProps> = () => {
  const activeProject = useRecoilValue(activeProjectState);
  const activeProjectRoute = useRecoilValue(activeProjectRouteState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { setTypeData } = useFilterContext();

  const { data } = useGetLocationRasterOverlays(activeProject!.locationId);

  useEffect(() => {
    if (!data || !data.location?.rasterOverlays) {
      return;
    }

    setTypeData(data.location.rasterOverlays, MediaType.RasterOverlay);
  }, [data, setTypeData]);

  useRestoreCompletedFilterFromUrl();
  useRasterOverlayFilterInitializer();

  const { data: routeDetails } = useGetRouteDetails(
    activeProjectRoute!.id,
    activeProjectRoute!.projectId,
    activeOrg.id,
  );

  const getMetadataTypes = useGetPointsMetadataTypes();
  const [metadataTypes, setMetadataTypes] = useState<IMetadataTypeFilterBuilder>({
    [MediaMetadataType.Level]: [],
    [MediaMetadataType.Area]: [],
    [MediaMetadataType.PhotoCategory]: [],
    [MediaMetadataType.PhotoType]: [],
  });

  useEffect(() => {
    if (!routeDetails?.route) {
      return;
    }

    const { metadataTypes } = getMetadataTypes(routeDetails.route.routePoints);

    setMetadataTypes({
      [MediaMetadataType.Level]: metadataTypes[MediaMetadataType.Level],
      [MediaMetadataType.Area]: metadataTypes[MediaMetadataType.Area],
      [MediaMetadataType.PhotoCategory]: metadataTypes[MediaMetadataType.PhotoCategory],
      [MediaMetadataType.PhotoType]: metadataTypes[MediaMetadataType.PhotoType],
    });
  }, [getMetadataTypes, routeDetails]);

  useRestoreLevelFilterFromUrl(metadataTypes[MediaMetadataType.Level]);
  useRestoreAreaFilterFromUrl(metadataTypes[MediaMetadataType.Area]);
  useRestoreCategoryFilterFromUrl(metadataTypes[MediaMetadataType.PhotoCategory]);
  useRestoreTypeFilterFromUrl(metadataTypes[MediaMetadataType.PhotoType]);

  return null;
};

export default FilterInitializers;
