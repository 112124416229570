import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useRecoilState } from 'recoil';

import { locationValidationSchema } from '../../locationValidation';
import IdsCreateDialogForm from '../../../../components/ids-forms/IdsCreateDialogForm';
import LocationForm from '../../LocationForm';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { useCreateLocation } from '../../../../services/LocationService';

const initialValues = {
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  yearBuilt: '',
  latitude: '',
  longitude: '',
  heading: 0,
};

function CreateLocationForm({ onSubmitSuccess, onCancel }) {
  const [organization] = useRecoilState(activeOrganizationState);
  const createLocationMutation = useCreateLocation(organization.id);

  const onSubmit = useCallback(
    async values => {
      await createLocationMutation.mutateAsync({
        orgId: organization.id,
        ...values,
      });

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    [createLocationMutation, onSubmitSuccess, organization.id],
  );

  return (
    <IdsCreateDialogForm
      initialValues={initialValues}
      validationSchema={locationValidationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Location created'
      errorHandler={() => 'Location could not be created'}
    >
      <LocationForm />
    </IdsCreateDialogForm>
  );
}

CreateLocationForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreateLocationForm;
