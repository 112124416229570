import React, { createContext, useCallback, useEffect, useState, useRef } from 'react';

import { useSetRecoilState, useRecoilState } from 'recoil';

import keycloak, { getRedirectAfterLogoutUrl } from '../keycloak';
import useKeycloak from '../hooks/useKeycloak';
import LoadingScreen from '../components/LoadingScreen';
import { useGetProfileDetails } from '../services/ProfileService';
import ErrorPage from '../views/Error';
import queryClient from '../utils/query';
import { keycloakInitializedState } from '../atoms/keycloak';
import { sessionState } from '../atoms/session';

import axios from '../utils/axios';

const SessionLoader: React.FC = () => {
  const { authenticated } = useKeycloak();
  const setSession = useSetRecoilState(sessionState);

  const { data, isLoading, error } = useGetProfileDetails(true, authenticated);

  useEffect(() => {
    if (isLoading || error || !data) return;
    setSession(data.me);
  }, [data, isLoading, error, setSession]);

  return null;
};

const onAuthChange = () => {
  // Clear query cache for any queries made in the previous auth state
  queryClient.clear();
};

export interface IKeycloakContextType {
  keycloak: typeof keycloak;
  initialized: boolean;
  authenticated: boolean;
  token?: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IKeycloakContextProviderProps {}

const KeycloakContext = createContext<IKeycloakContextType | null>(null);

export const KeycloakProvider: React.FC<React.PropsWithChildren<IKeycloakContextProviderProps>> = ({
  children,
}) => {
  const [initialized, setInitialized] = useRecoilState(keycloakInitializedState);
  const [initError, setInitError] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState<string | undefined | null>(null);
  const redirectAfterLogoutUrl = useRef<string | null>(null);
  const setSession = useSetRecoilState(sessionState);

  const handleTokenChange = useCallback(() => {
    setToken(keycloak.token);

    // Check for custom redirect url set for logout
    const _redirectAfterLogoutUrl = getRedirectAfterLogoutUrl();
    if (_redirectAfterLogoutUrl) {
      redirectAfterLogoutUrl.current = _redirectAfterLogoutUrl;
    }

    if (keycloak.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }, []);

  const handleAuthSuccess = useCallback(() => {
    onAuthChange();
    handleTokenChange();
    setAuthenticated(true);
  }, [handleTokenChange, setAuthenticated]);

  const refreshToken = useCallback(() => {
    keycloak
      .updateToken(10)
      .then(refreshed => {
        if (refreshed) {
          handleTokenChange();
        }
      })
      .catch(_ => {
        // Failed to refresh token
        keycloak.clearToken(); // triggers onAuthLogout event
      });
  }, [handleTokenChange]);

  const handleAuthLogout = useCallback(() => {
    handleTokenChange();
    onAuthChange();
    setAuthenticated(false);
    setSession(null);

    if (redirectAfterLogoutUrl.current) {
      window.location.href = redirectAfterLogoutUrl.current;
      redirectAfterLogoutUrl.current = null;
    }
  }, [setSession, handleTokenChange]);

  useEffect(() => {
    /**
     * This condition is required to prevent re-initialization and further errors.
     */
    if (keycloak.token) {
      return;
    }

    keycloak.onAuthSuccess = handleAuthSuccess;
    keycloak.onTokenExpired = refreshToken;
    keycloak.onAuthRefreshSuccess = handleTokenChange;
    keycloak.onAuthLogout = handleAuthLogout;

    keycloak
      .init({
        onLoad: 'check-sso',
        // Note: this must be false to fix browsers removing support for 3rd party cookies: https://www.keycloak.org/docs/latest/securing_apps/index.html#browsers-with-blocked-third-party-cookies
        checkLoginIframe: false,
      })
      .then(authenticated => {
        setAuthenticated(authenticated);
        setInitialized(true);
      })
      .catch(error => {
        // Failed to initialize
        setInitError(error);
      });

    return () => {
      keycloak.onAuthSuccess = undefined;
      keycloak.onTokenExpired = undefined;
      keycloak.onAuthRefreshSuccess = undefined;
      keycloak.onAuthLogout = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialized ? (
    <KeycloakContext.Provider
      value={{
        keycloak,
        initialized,
        authenticated,
        token,
      }}
    >
      <SessionLoader />
      {children}
    </KeycloakContext.Provider>
  ) : initError ? (
    <ErrorPage />
  ) : (
    <LoadingScreen fullscreen={true} />
  );
};

export const KeycloakContextConsumer = KeycloakContext.Consumer;

export default KeycloakContext;
