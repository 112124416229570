import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { LocationIcon } from '../../theme/icons';

import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgLocationRoute } from '../../utils/routes';

import { activeTenantState } from '../../atoms/tenants';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface ILocationChipProps extends IEntityChipProps {
  tenantSubdomain?: string;
  organizationId?: string;
  locationId?: string;
  locationName?: string;
}

const LocationChip: React.FC<ILocationChipProps> = ({
  tenantSubdomain,
  organizationId,
  locationId,
  locationName,
  ...rest
}) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);

  const label = locationName || `Location #${locationId}`;

  return (
    <EntityChip
      icon={<LocationIcon size={16} />}
      label={label}
      entityRoute={getOrgLocationRoute({
        subdomain: tenantSubdomain || activeTenant?.subdomain,
        orgId: organizationId !== undefined ? organizationId : organization?.id,
        locationId: locationId,
      })}
      {...rest}
    />
  );
};

export default LocationChip;
