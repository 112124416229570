import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, SvgIcon, Button } from '@mui/material';
import { useRecoilValue } from 'recoil';

import IdsForm from '../../../../components/ids-forms/IdsForm';
import LoadingScreen from '../../../../components/LoadingScreen';
import ErrorScreen from '../../../../views/Error';
import { activeTenantState } from '../../../../atoms/tenants';
import {
  UpdateUserRoleInTenantInput,
  useGetUserInTenant,
  useUpdateUserRoleInTenant,
} from '../../../../services/TenantService';
import { AddIcon } from '../../../../theme/icons';

import { Buttons } from './buttons';

import Card from './Card';

export interface ITenantAccessFormProps {
  userId: string;
}

export interface IFormValues {
  tenantID: string;
  tenantRoleName: string;
}

const TenantAccessForm: React.FC<ITenantAccessFormProps> = ({ userId }) => {
  const [currentTenantRole, setCurrentTenantRole] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<IFormValues>({
    tenantID: '',
    tenantRoleName: '',
  });

  const [isEditMode, setEditMode] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const activeTenant = useRecoilValue(activeTenantState);
  const updateUserRoleInTenant = useUpdateUserRoleInTenant();

  const {
    data: userInTenant,
    isLoading: isLoadingUserInTenant,
    error: errorUserInTenant,
  } = useGetUserInTenant(activeTenant!.id, userId);

  /**
   * Set current tenant role.
   */
  useEffect(() => {
    if (!userInTenant) {
      return;
    }

    const roles = userInTenant.userInTenant.roles;
    const currentTenantRoleName = roles.find(
      role => role.domainType === 'Tenant' && role.domainId === activeTenant!.id,
    )?.roleName;

    setCurrentTenantRole(currentTenantRoleName ?? null);
  }, [userInTenant, activeTenant]);

  /**
   * Set initial values.
   */
  useEffect(() => {
    if (!userInTenant) {
      return;
    }

    setInitialValues({
      tenantID: activeTenant!.id,
      tenantRoleName: currentTenantRole ?? '',
    });
  }, [activeTenant, userInTenant, currentTenantRole]);

  const onSubmit = async (values: IFormValues, formikeHelpers: any) => {
    setIsUpdating(true);
    const action = currentTenantRole ? 'update' : 'add';

    const result = await updateUserRoleInTenant(userId, {
      tenantId: values.tenantID,
      roleName: values.tenantRoleName as UpdateUserRoleInTenantInput['roleName'],
      action,
    });
    setIsUpdating(false);

    if (result.updateUserRoleInTenant?.errors?.length) {
      const message = result.updateUserRoleInTenant.errors[0].message;
      formikeHelpers.resetForm();

      throw new Error(message);
    }
    setInitialValues(values);
  };

  const onDelete = async (values: IFormValues) => {
    setIsUpdating(true);
    const action = 'delete';
    const result = await updateUserRoleInTenant(userId, {
      tenantId: values.tenantID,
      roleName: values.tenantRoleName as UpdateUserRoleInTenantInput['roleName'],
      action,
    });
    setIsUpdating(false);

    if (result.updateUserRoleInTenant?.errors?.length) {
      const message = result.updateUserRoleInTenant.errors[0].message;

      throw new Error(message);
    }
  };

  if (isLoadingUserInTenant || isUpdating) {
    return <LoadingScreen />;
  }

  if (errorUserInTenant) {
    return <ErrorScreen />;
  }

  if (!isEditMode && !currentTenantRole) {
    return (
      <Grid textAlign='right' marginTop={2} marginRight={2}>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => {
            setEditMode(true);
          }}
        >
          <SvgIcon fontSize='small' style={{ marginRight: '16px' }}>
            <AddIcon />
          </SvgIcon>
          Add Tenant Role
        </Button>
      </Grid>
    );
  } else {
    return (
      <Box>
        <IdsForm
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          errorHandler={error => {
            return error || 'User could not be updated';
          }}
          successMessage='User updated'
        >
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Box component={Paper} p={2} display='flex' flexDirection='column' gap={1}>
                <Card isEditMode={isEditMode} onDelete={onDelete} />
                <Buttons
                  isEditMode={isEditMode}
                  canUpdate={true}
                  isUpdating={isUpdating}
                  setEditMode={setEditMode}
                />
              </Box>
            </Grid>
          </Grid>
        </IdsForm>
      </Box>
    );
  }
};

export default TenantAccessForm;
