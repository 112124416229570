import React from 'react';
import { Container } from '@mui/material';
import { useRecoilValue } from 'recoil';

import Page from '../../components/Page';
import NestedOrgViewHeader from '../../components/NestedOrgViewHeader';
import { activeOrganizationState } from '../../atoms/organizations';

import CollectionsList from './CollectionsList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICollectionsProps {}

const Collections: React.FC<ICollectionsProps> = () => {
  const activeOrg = useRecoilValue(activeOrganizationState);

  return (
    <Page title='Collections'>
      <Container maxWidth={false}>
        <NestedOrgViewHeader currentCrumb='Collections' breadcrumbs={[]} />
        <CollectionsList orgId={activeOrg.id} />
      </Container>
    </Page>
  );
};

export default Collections;
