import React from 'react';
import { useRecoilValue } from 'recoil';

import { createTenantUserValidationSchema } from '../../../../Users/userValidation';
import IdsCreateDialogForm from '../../../../../components/ids-forms/IdsCreateDialogForm';
import {
  UpdateUserRolesAcrossOrganizationsInput,
  useCreateUser,
  useUpdateUserRoles,
} from '../../../../../services/UsersService';
import {
  UpdateUserRoleInTenantInput,
  useTenantKeys,
  useUpdateUserRoleInTenant,
} from '../../../../../services/TenantService';
import { activeTenantState } from '../../../../../atoms/tenants';
import invalidateQueriesContainingKey from '../../../../../utils/invalidateQueriesContainingKey';

import Fields, { TENANT_ROLE_OPTIONS, IFormValues } from './Fields';

export interface ICreateUserFormProps {
  onCancel?: () => void;
  onSubmitSuccess?: () => void;
}

const initialValues = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: '',
  role: '',
  organizations: [],
};

const CreateUserForm: React.FC<ICreateUserFormProps> = ({ onSubmitSuccess, onCancel }) => {
  const tenantKeys = useTenantKeys();
  const activeTenant = useRecoilValue(activeTenantState);

  const createUserMutation = useCreateUser();
  const updateUserRoleInTenant = useUpdateUserRoleInTenant();
  const updateUserRoles = useUpdateUserRoles();

  const onSubmit = async (values: IFormValues) => {
    const { organizations, role, ...restValues } = values;

    const createUserResult = await createUserMutation.mutateAsync({
      organizationId: null,
      roleId: null,
      ...restValues,
    });

    /**
     * Check if tenant role was selected. If so, create a new user with basic
     * fields, then run mutation to update its tenant role.
     */
    if (TENANT_ROLE_OPTIONS.find(option => option.value === role)) {
      const updateUserRoleResult = await updateUserRoleInTenant(
        `${createUserResult.data.user.id}`,
        {
          tenantId: activeTenant!.id,
          roleName: values.role as UpdateUserRoleInTenantInput['roleName'],
          action: 'add',
        },
      );

      if (updateUserRoleResult.updateUserRoleInTenant?.errors?.length) {
        const message = updateUserRoleResult.updateUserRoleInTenant.errors[0].message;

        throw new Error(message);
      }
    } else {
      /**
       * Org roles were selected. Create a user with basic fields, then run
       * mutation to update its roles in the selected organizations.
       */

      const filteredOrgs = organizations
        // Skip empty entries.
        .filter(item => item.role && item.organization)
        .map(item => ({
          action: 'add',
          organizationId: item.organization,
          roleName: item.role,
          projects: [],
        }));

      const result = await updateUserRoles.mutateAsync({
        id: `${createUserResult.data.user.id}`,
        ...(activeTenant?.myUserRole && { tenantId: activeTenant.id }),
        input: {
          userRolesAcrossOrganizations:
            filteredOrgs as UpdateUserRolesAcrossOrganizationsInput['userRolesAcrossOrganizations'],
        },
      });

      if (result?.updateUserRolesAcrossOrganizations?.errors?.length) {
        const message = result.updateUserRolesAcrossOrganizations.errors[0].message;

        throw new Error(message);
      }

      await invalidateQueriesContainingKey(tenantKeys.users(activeTenant!.id), {
        refetchActive: true,
      });
    }

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  return (
    <IdsCreateDialogForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createTenantUserValidationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='User created'
      errorHandler={() => 'User could not be created'}
    >
      <Fields />
    </IdsCreateDialogForm>
  );
};

export default CreateUserForm;
