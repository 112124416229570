import { ElementType, ReactNode } from 'react';
import Uppy, { Restrictions, DefaultPluginOptions } from '@uppy/core';

import { IUploadQueueGoldenRetrieverOptions } from '../context/UploadQueueContext';
import { IIdsFormProps } from '../components/ids-forms/IdsForm';

export enum UploadType {
  None = 'None',
  SingleLocationImage = 'SingleLocationImage',
  BatchLocationImage = 'BatchLocationImage',
  TenantLogo = 'TenantLogo',
  OrgLogo = 'OrgLogo',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsUploaderFormProps
  extends Omit<IIdsFormProps, 'validationSchema' | 'onSubmit'> {}

export interface IUploadStep {
  label: string;
  formFields: React.ReactNode;
  validationSchema: IIdsFormProps['validationSchema'];
  disableNextButton?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPluginOptions extends Record<string, DefaultPluginOptions> {}

export interface IUploaderValue {
  uploadSteps: IUploadStep[];
  formProps: IIdsUploaderFormProps;
  /** Fields that should be reset to initial values if initial values change. */
  fieldsToReinitialize?: string[];
  onFormValuesChange?: (values: any) => void;
  onFormSubmit?: (values: any) => void;
  // this concept may not be needed at a generic level, kind of specific to xhr uploader...
  transformSubmissionValues?: (values: any) => any;
  disableSubmit?: boolean;
  disableSubmitTooltip?: string;
}

// Empty object means no props
export type EmptyUploaderProps = Record<string, never>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseUploaderProps extends Record<string, unknown> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUploadContext extends Record<string, unknown> {}

export interface IUploader<
  TProps extends IBaseUploaderProps = IBaseUploaderProps,
  TContext extends IUploadContext = IUploadContext,
> {
  uploadType: UploadType;
  label: string;
  getUploadLabel?: (uppy: Uppy, context: TContext) => ReactNode;
  getIcon?: (uppy: Uppy, context: TContext) => ElementType;
  getUploadContext?: (uppy: Uppy) => TContext;
  uppyRestrictions?: Restrictions;
  /** Is this uploader avialable for use? */
  isAvailable: (
    uppy: Uppy,
    userHasPermission: () => boolean,
    userHasOneOfRoles: (roles: any[]) => boolean,
    props: TProps,
  ) => boolean;
  /** IMPORTANT: The return value of this should be memoized to prevent constant rerenders. */
  useUploader: (enabled: boolean, props: TProps) => IUploaderValue | null;
  /** Should this upload be added to the upload queue or uploaded immediately? */
  queueUpload?: boolean;
  /** Options for Uppy Golden Retriever plugin. Only used if `queueUpload` is `true`. */
  goldenRetrieverOptions?: IUploadQueueGoldenRetrieverOptions;
  /** Configure uppy for use with the uploader. Example: install uploader, set options, etc.. */
  configureUppy: (uppy: Uppy) => void;
  // TODO: think about limiting which plugins are tracked for restoration to prevent storing unnecessary data
  /** Restore uppy configuration from restored uppy instance and plugin options. Restored plugins are really only needed for dynamically configured plugins. */
  restoreUppy?: (uppy: Uppy, restoredPlugins?: IPluginOptions) => void;
  processRestoredUppy?: (uppy: Uppy) => void;
  /** Reverse configurations performed in `configureUppy`. */
  deconfigureUppy: (uppy: Uppy) => void;
}

export enum UploadStatus {
  Queued = 'Queued',
  Uploading = 'Uploading',
  Successful = 'Successful',
  MixedResult = 'MixedResult',
  Failed = 'Failed',
}
