import React from 'react';

import useRasterOverlayFilterInitializer from '../../../filter-initializers/useRasterOverlayFilterInitializer';
import useVectorOverlayFilterInitializer from '../../../filter-initializers/useVectorOverlayFilterInitializer';
import { useRestoreFlagFilterFromUrl } from '../../../../../hooks/filtering/useFlagFilter';
import { useRestoreLevelFilterFromUrl } from '../../../../ids-filters/LevelFilter';
import { useRestoreAreaFilterFromUrl } from '../../../../ids-filters/AreaFilter';
import { useRestoreCategoryFilterFromUrl } from '../../../../ids-filters/CategoryFilter';
import { useRestoreTagsFilterFromUrl } from '../../../../ids-filters/TagsFilter';
import { useRestoreTypeFilterFromUrl } from '../../../../ids-filters/TypeFilter';
import { CustomFieldMetadataFilterInitializer } from '../../../../../hooks/filtering/useCustomFieldMetadataFilter';
import { useRestoreTimelineFilterFromUrl } from '../../../../../hooks/filtering/useTimelineFilter';
import { useRestoreProjectFilterFromUrl } from '../../../../../hooks/filtering/useProjectFilter';
import { useRestorePublishedFilterFromUrl } from '../../../../../hooks/filtering/usePublishedFilter';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';
import useMetadataItems from '../../../../../hooks/useMetadataItems';
import { IAssignableCustomField } from '../../../../../constants/customFields';
import { usePartialImagesFilter } from '../../../../../hooks/filtering/usePartialImagesFilter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILocationMapFilterInitializersProps {}

const LocationMapFilterInitializers: React.FC<ILocationMapFilterInitializersProps> = () => {
  const { location } = useLocationMapContext();

  useRasterOverlayFilterInitializer();
  useVectorOverlayFilterInitializer();
  useRestoreFlagFilterFromUrl();
  useRestorePublishedFilterFromUrl();
  useRestoreTimelineFilterFromUrl();

  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(
    location?.metadataTypes,
  );
  useRestoreLevelFilterFromUrl(allLevels);
  useRestoreAreaFilterFromUrl(allAreas);
  useRestoreCategoryFilterFromUrl(allCategories);
  useRestoreTagsFilterFromUrl(allTags);
  useRestoreTypeFilterFromUrl(allTypes);

  const projects = location?.projects?.edges || [];
  useRestoreProjectFilterFromUrl(projects, projects.length === location?.projects?.totalCount);

  usePartialImagesFilter();

  return (
    <>
      {location?.assignableCustomFields?.map((acf: IAssignableCustomField) => (
        <CustomFieldMetadataFilterInitializer assignableCustomField={acf} key={acf.id} />
      ))}
    </>
  );
};

export default LocationMapFilterInitializers;
