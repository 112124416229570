import React from 'react';

import Page from '../../components/Page';
import ComingSoonRoute from '../../components/ComingSoonRoute';

function Uploads() {
  return (
    <Page title='Uploads'>
      <ComingSoonRoute />
    </Page>
  );
}

export default Uploads;
