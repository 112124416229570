import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { activeProjectState } from '../../../../../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../../../../../atoms/organizations';

import IdsFormLocationProjectAutocompleteField from '../../../../../../../../../components/ids-forms/common/IdsFormLocationProjectAutocompleteField';
import IdsCreateDialogForm from '../../../../../../../../../components/ids-forms/IdsCreateDialogForm';

import { useCopyRoute } from '../../../../../../../../../services/RouteService';
import { IProjectRouteListItemData } from '../../../../../../../../../services/fragments';

import { copyRouteValidationSchema } from './copyRouteValidation';

export interface ICopyRouteFormProps {
  item: IProjectRouteListItemData;
  handleClose?: () => void;
}

interface IFormValues {
  projectId: string;
}

const errorHandler = () => 'Failed to copy route';

const CopyRouteForm: React.FC<ICopyRouteFormProps> = ({ item, handleClose }) => {
  const activeProject = useRecoilValue(activeProjectState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const copyRoute = useCopyRoute();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      const result = await copyRoute.mutateAsync({
        id: item.id,
        organizationId: activeOrg.id,
        destinationProjectId: values.projectId,
      });

      if (result?.copyRoute?.errors?.length) {
        const field = result.copyRoute.errors[0].field;
        const message = result.copyRoute.errors[0].message;

        throw new Error(`${field} ${message}`);
      }

      if (handleClose) {
        handleClose();
      }
    },
    [item.id, copyRoute, activeOrg.id, handleClose],
  );

  const initialValues = useMemo(() => {
    return {
      projectId: item.projectId,
    };
  }, [item.projectId]);

  return (
    <IdsCreateDialogForm
      initialValues={initialValues}
      validationSchema={copyRouteValidationSchema}
      successMessage='The route was copied'
      errorHandler={errorHandler}
      onSubmit={onSubmit}
      onCancel={handleClose}
    >
      <IdsFormLocationProjectAutocompleteField
        name='projectId'
        required
        locationId={activeProject!.locationId}
      />
    </IdsCreateDialogForm>
  );
};

export default CopyRouteForm;
