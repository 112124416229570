import React from 'react';
import { Paper, Table, TableBody, tableCellClasses, TableContainer } from '@mui/material';

import { Field, FieldType } from './types';
import Color from './Fields/Color';
import Checkbox from './Fields/Checkbox';
import Text from './Fields/Text';
import SingleChoice from './Fields/SingleChoice';

export interface IEntityDataProps {
  isEditMode?: boolean;
  fields: Field[];
  children?: React.ReactNode;
}

export const labelColor = '#00407A';

// @TODO: add other fields.
const renderField = (field: Field, isEditMode: boolean | undefined) => {
  switch (field.type) {
    case FieldType.Color:
      return <Color key={field.name} field={field} isEditMode={isEditMode} />;
    case FieldType.Checkbox:
      return <Checkbox key={field.name} field={field} isEditMode={isEditMode} />;
    case FieldType.SingleChoice:
      return <SingleChoice key={field.name} field={field} isEditMode={isEditMode} />;
    default:
      return <Text key={field.name} field={field} isEditMode={isEditMode} />;
  }
};

const EntityData: React.FC<IEntityDataProps> = ({ fields, isEditMode, children }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        size='small'
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            padding: 1,
            fontSize: '1rem',
            textAlign: 'left',
          },
          [`& .${tableCellClasses.root}:first-of-type`]: {
            color: labelColor,
          },
          '& .MuiFormLabel-asterisk': {
            display: 'none',
          },
        }}
      >
        <TableBody>
          {fields.map((field: Field) => renderField(field, isEditMode))}
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EntityData;
