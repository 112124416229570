import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';

import { activeOrganizationState } from '../../../../../../../atoms/organizations';
import { IDeleteRouteOutput, useDeleteRoute } from '../../../../../../../services/ProjectService';
import { IProjectRouteListItemData } from '../../../../../../../services/fragments';

import useDeleteEntity from '../../../../../../../hooks/useDeleteEntity';

const useDeleteRouteAction = (route: IProjectRouteListItemData) => {
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { deleted, deleting, handleDelete } = useDeleteEntity();
  const deleteRoute = useDeleteRoute(route.projectId);

  const handleDeleteRoute = useCallback(
    (onCancel?: () => void) => {
      return handleDelete(
        async () => {
          return await deleteRoute.mutateAsync({
            id: route.id,
            organizationId: activeOrg.id,
          });
        },
        route.name,
        'This cannot be undone, the route will be deleted permanently.',
        'Route',
        null,
        () => {
          if (onCancel) {
            onCancel();
          }
        },
        (result: IDeleteRouteOutput) => {
          return !!result?.deleteRoute?.errors?.length;
        },
      );
    },
    [handleDelete, deleteRoute, activeOrg, route],
  );

  return {
    handleDeleteRoute,
    deleting,
    deleted,
  };
};

export default useDeleteRouteAction;
