import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { activeTenantState } from '../../../../atoms/tenants';
import { activeOrganizationState } from '../../../../atoms/organizations';

import useDeleteLocationAction from '../../useDeleteLocationAction';
import usePermissions from '../../../../hooks/usePermissions';
import { EntityType } from '../../../../constants/entities';
import { getOrgLocationEditRoute, getOrgLocationsRoute } from '../../../../utils/routes';

import IdsListItemMenu from '../../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../../components/ids-lists/IdsListItemMenuItem';

import { DeleteOutlineIcon, EditOutlineIcon } from '../../../../theme/icons';
import { ILocationListItemProps } from '../index';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILocationListItemMenuProps extends ILocationListItemProps {}

const LocationListItemMenu: React.FC<ILocationListItemMenuProps> = ({ locationItem }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const navigate = useNavigate();
  const { deleted, deleting, deleteLocation } = useDeleteLocationAction();
  const { userHasPermission } = usePermissions();

  const canUpdate = useMemo(
    () => userHasPermission('update', EntityType.Location),
    [userHasPermission],
  );
  const canDelete = useMemo(
    () => userHasPermission('delete', EntityType.Location),
    [userHasPermission],
  );

  const handleDelete = useCallback(() => {
    deleteLocation(locationItem.node, () => {
      // Return to locations list if successful
      navigate(
        getOrgLocationsRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
        }),
      );
    });
  }, [activeOrg.id, locationItem, deleteLocation, navigate, activeTenant]);

  const { id } = locationItem?.node;

  const handleEdit = useCallback(() => {
    navigate(
      getOrgLocationEditRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        locationId: id,
      }),
    );
  }, [navigate, activeOrg.id, id, activeTenant]);

  return (
    (canUpdate || canDelete) && (
      <IdsListItemMenu>
        {canUpdate && (
          <IdsListItemMenuItem icon={<EditOutlineIcon />} onClick={handleEdit}>
            Edit
          </IdsListItemMenuItem>
        )}
        {canDelete && (
          <IdsListItemMenuItem
            icon={<DeleteOutlineIcon />}
            onClick={handleDelete}
            disabled={deleting || deleted}
          >
            Delete
          </IdsListItemMenuItem>
        )}
      </IdsListItemMenu>
    )
  );
};

export default LocationListItemMenu;
