import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { sessionState } from '../../../../atoms/session';
import { activeImageId as activeImageIdState } from '../../../../atoms/mediaViewer';
import {
  MEDIA_TYPE_TO_DEF,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
} from '../../../../constants/media';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import IdsMediaViewer, {
  BUTTON_TYPE,
  MEDIA_VIEWER_TABS,
  MEDIA_VIEWER_WIDTHS,
} from '../../../ids-media-viewer/IdsMediaViewer';
import { useDefaultEventHandlers } from '../../../ids-media-viewer/IdsMediaViewer/hooks';
import { EditMarkerIcon, EditMarkerSaveIcon } from '../../../../theme/icons';
import useLocationMapMetadataOptions from '../../../../hooks/useLocationMapMetadataOptions';
import useMountedEffect from '../../../../hooks/useMountedEffect';
import { useImageMarkerEditorTool } from '../../tools/ImageMarkerEditorTool';
import usePermissions from '../../../../hooks/usePermissions';
import { getMediaTypeFromUrn } from '../../../../utils/media';
import usePrevious from '../../../../hooks/usePrevious';
import { USER_ROLES } from '../../../../constants/users';
import { EntityType } from '../../../../constants/entities';

const MapMediaViewer = ({ metadataTypes, onClose, tenantId, ...rest }) => {
  const activeImageId = useRecoilValue(activeImageIdState);
  const session = useRecoilValue(sessionState);

  const prevActiveImageId = usePrevious(activeImageId);
  const [activeImage, setActiveImage] = useState();
  const { useViewState } = useImmersiveViewer();
  const viewState = useViewState();
  const allTags = useLocationMapMetadataOptions(
    MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
  );
  const { userHasOneOfRoles } = usePermissions();

  const canUpdateImage = useMemo(() => {
    if (activeImageId) {
      const mediaType = getMediaTypeFromUrn(activeImageId);
      const entityType = MEDIA_TYPE_TO_DEF[mediaType].entityType;

      // User needs update permission on the active image's type
      switch (entityType) {
        case EntityType.ProjectPhoto:
          return userHasOneOfRoles([USER_ROLES.ORG_TEAM, USER_ROLES.CONTRACTOR_MANAGER])
            ? // These roles can only update their own project photo
              session.id === activeImage?.author?.id
            : true;
        case EntityType.HDPhoto:
        case EntityType.Panorama:
          return userHasOneOfRoles([
            USER_ROLES.IDS_ADMIN,
            USER_ROLES.IDS_TEAM,
            USER_ROLES.TENANT_ADMIN,
            USER_ROLES.TENANT_TEAM,
          ]);
        default:
          return false;
      }
    }

    return false;
  }, [activeImageId, activeImage?.author?.id, userHasOneOfRoles, session.id]);

  const { startEditingImageMarker, finishEditingImageMarker, enabled } = useImageMarkerEditorTool();

  useMountedEffect(() => {
    return () => finishEditingImageMarker(true);
  });

  const handleStartEditingImageMarker = useCallback(
    activeImage => {
      startEditingImageMarker(activeImage);
    },
    [startEditingImageMarker],
  );

  useEffect(() => {
    // Image changed while editing marker, cancel editing
    if (enabled && activeImageId !== prevActiveImageId) {
      finishEditingImageMarker(true);
    }
  }, [enabled, activeImageId, prevActiveImageId, finishEditingImageMarker]);

  const handleClose = useCallback(() => {
    if (enabled) {
      finishEditingImageMarker(true);
    }

    if (onClose) {
      onClose();
    }
  }, [enabled, finishEditingImageMarker, onClose]);

  const { onDelete, onTagsUpdate, onFlagUpdate, onImageInfoUpdate } = useDefaultEventHandlers();

  return useMemo(
    () => (
      <IdsMediaViewer
        onDelete={onDelete}
        onFlagUpdate={onFlagUpdate}
        onImageInfoUpdate={onImageInfoUpdate}
        onTagsUpdate={onTagsUpdate}
        mode='immersive'
        tenantId={tenantId}
        initialWidth={MEDIA_VIEWER_WIDTHS.HALF}
        availableWidth={viewState.width}
        tabs={[MEDIA_VIEWER_TABS.MEDIA, MEDIA_VIEWER_TABS.INFO]}
        loadActiveImageDetail={false}
        loadAllThumbnails={false}
        hideFlag={false}
        disableFlag={enabled}
        thumbnailCount={100}
        noImageMessage='This image is not included in the current media filters.'
        style={{ marginTop: 12, marginRight: 12, marginLeft: 10 }}
        allImageTags={allTags}
        onActiveMediaLoaded={setActiveImage}
        {...(canUpdateImage && {
          actionButtons: [
            !enabled
              ? {
                  icon: <EditMarkerIcon />,
                  onClick: handleStartEditingImageMarker,
                  type: BUTTON_TYPE.mode,
                  label: 'Edit marker',
                }
              : {
                  icon: <EditMarkerSaveIcon color='info' />, // change color to blue, disable flag button when enabled
                  onClick: () => finishEditingImageMarker(false),
                  type: BUTTON_TYPE.mode,
                  label: 'Edit marker',
                },
          ],
        })}
        onClose={handleClose}
        {...rest}
      />
    ),
    [
      onDelete,
      onTagsUpdate,
      onFlagUpdate,
      onImageInfoUpdate,
      viewState.width,
      allTags,
      handleStartEditingImageMarker,
      finishEditingImageMarker,
      enabled,
      handleClose,
      canUpdateImage,
      rest,
      tenantId,
    ],
  );
};

MapMediaViewer.propTypes = {
  metadataTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ),
};

export default MapMediaViewer;
