import { useContext } from 'react';

import UploadQueueContext from '../context/UploadQueueContext';

export default function useUploadQueueContext() {
  const context = useContext(UploadQueueContext);

  if (!context) {
    throw new Error('useUploadQueueContext must be used within an UploadQueueProvider');
  }

  return context;
}
