import React, { useCallback } from 'react';
import { Box } from '@mui/material';

import { ArrowDownIcon, ArrowUpIcon } from '../../../../theme/icons';

import styles from './MinMaxButton.module.css';

export interface IMinMaxButtonProps {
  expanded: boolean;
  setExpanded: (f: (prev: boolean) => boolean) => void;
}

const MinMaxButton: React.FC<IMinMaxButtonProps> = ({ expanded, setExpanded }) => {
  const toggle = useCallback(() => setExpanded(prev => !prev), [setExpanded]);

  return (
    <Box onClick={toggle} className={styles.searchToggleButton} bgcolor='background.paper'>
      {expanded && <ArrowUpIcon />}
      {!expanded && <ArrowDownIcon />}
    </Box>
  );
};

export default MinMaxButton;
