import { useCallback, useEffect, useRef } from 'react';

import useFilterContext from '../useFilterContext';
import { FilterMode } from '../../context/FilterContext';
import useMediaFromUrn from '../useMediaFromUrn';
import useUrlState from '../useUrlState';
import { getMediaIdFromUrn } from '../../utils/media';

export const getOverlayFilterName = overlay => overlay.id;

export const useRestoreOverlayFilterFromUrl = (filterStateKey, overlays, enableOverlay) => {
  const urlOverlays = useRef();
  const { getUrlArrayState, removeUrlArrayStateItem } = useUrlState();

  useEffect(() => {
    urlOverlays.current = getUrlArrayState(filterStateKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!urlOverlays.current?.length || !overlays?.length) return;

    // Enable each overlay that was loaded from the url on mount
    urlOverlays.current.forEach(id => {
      const overlay = overlays.find(overlay => overlay.id.endsWith(`:${id}`));
      if (overlay) {
        enableOverlay(overlay, true);
      } else {
        // id invalid, remove from url
        removeUrlArrayStateItem(filterStateKey, id);
      }
    });

    urlOverlays.current = null;
  }, [overlays, enableOverlay, removeUrlArrayStateItem, filterStateKey]);
};

const useOverlayFilter = filterStateKey => {
  const { addFilter, removeFilter, useFilters } = useFilterContext();
  const activeFilters = useFilters();
  const { getMediaTypeFromUrn } = useMediaFromUrn();
  const { appendUrlArrayStateItem, removeUrlArrayStateItem } = useUrlState();

  const isEnabled = useCallback(
    overlay => {
      return overlay ? activeFilters.some(f => f.name === overlay.id) : false;
    },
    [activeFilters],
  );

  const enableOverlay = useCallback(
    (overlay, filterProps, loadedFromUrl = false) => {
      if (!overlay && !isEnabled(overlay)) return;

      const type = getMediaTypeFromUrn(overlay.id);
      const { id, name, default: isDefault } = overlay;

      addFilter(
        {
          name: id,
          label: name,
          filterItem: overlayItem => overlayItem.id === id,
          mode: isDefault ? FilterMode.Override : FilterMode.Additive, // Make additive so it cherry picks this overlay
          targets: [{ type }],
          onRemove: () => {
            removeUrlArrayStateItem(filterStateKey, getMediaIdFromUrn(overlay.id));
          },
          ...filterProps,
        },
        { loadedFromUrl },
      );

      // Append only the overlay media id instead of the entire urn to minimize url length
      appendUrlArrayStateItem(filterStateKey, getMediaIdFromUrn(id));
    },
    [
      addFilter,
      getMediaTypeFromUrn,
      appendUrlArrayStateItem,
      removeUrlArrayStateItem,
      filterStateKey,
      isEnabled,
    ],
  );

  const disableOverlay = useCallback(
    overlay => {
      if (!overlay) return;

      removeFilter(overlay.id);
    },
    [removeFilter],
  );

  return {
    enableOverlay,
    disableOverlay,
    isEnabled,
  };
};

export default useOverlayFilter;
