import React, { useMemo } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';

export interface ISelectOption {
  value: string;
  label: React.ReactNode;
}

// TODO: Rewrite component if it's needed to use generic type of option
export interface IIdsSelectProps extends SelectProps {
  options: ISelectOption[] | any[];
  renderOption?: (option: ISelectOption | any) => React.ReactElement;
  helperText?: React.ReactNode;
}

const defaultRenderOption = (o: ISelectOption) => (
  <MenuItem key={o.value} value={o.value}>
    {o.label}
  </MenuItem>
);

const IdsSelect: React.FC<IIdsSelectProps> = ({
  label,
  options,
  renderOption = defaultRenderOption,
  required,
  disabled,
  error,
  helperText,
  size = 'small',
  margin,
  sx,
  ...rest
}) => {
  const labelId = useMemo(() => `${label}-select-field-label`, [label]);

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={Boolean(error)}
      size={size}
      margin={margin}
      fullWidth
      sx={sx}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...rest} // Props below this shouldn't be overridden
        labelId={labelId}
        label={label}
      >
        {options?.map(o => renderOption(o))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default IdsSelect;
