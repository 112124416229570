import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import { Avatar, Grid, List, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import IdsListItem, { IIdsListItemProps } from '../../ids-lists/IdsListItem';
import IdsListItemChild from '../../ids-lists/IdsListItem/IdsListItemChild';
import IdsUserAvatar from '../../IdsUserAvatar';
import LinkButton from '../LinkButton';
import { DeleteIcon } from '../../../theme/icons';

import styles from './IdsComment.module.css';
import { IComment } from './types';

export interface IIdsCommentProps extends Pick<IIdsListItemProps, 'className' | 'divider'> {
  comment: IComment & {
    replies?: IComment[];
  };
  onReply: (comment: IComment) => void;
  onEdit: (comment: IComment) => void;
  onDelete: (comment: IComment) => void;
}

const IdsComment: React.FC<IIdsCommentProps> = ({
  comment,
  onReply,
  onEdit,
  onDelete,
  ...rest
}) => {
  const handleReply = useCallback(() => {
    onReply(comment);
  }, [onReply, comment]);

  const handleEdit = useCallback(() => {
    onEdit(comment);
  }, [onEdit, comment]);

  const handleDelete = useCallback(() => {
    onDelete(comment);
  }, [onDelete, comment]);

  const { createdAt, updatedAt, creator, text, replies, deleted, canReply, canEdit, canDelete } =
    comment;
  const { firstName, lastName } = creator;

  const creatorName = `${firstName} ${lastName}`;

  const wasEdited = useMemo(() => {
    return createdAt !== updatedAt
      ? // On rare occasions, createdAt and updatedAt are initialized to slightly different values
        // This is due to a prisma bug that is fixed in version 4: https://github.com/prisma/prisma/issues/13795
        // TODO: revert to basic equality check once graphql server is updated to use version 4.x of prisma
        Math.abs(moment(new Date(createdAt)).diff(moment(new Date(updatedAt)), 'seconds', true)) > 1
      : false;
  }, [createdAt, updatedAt]);

  // TODO: think about updating this on a timer (every minute?)
  const timestamp = useMemo(() => {
    const createdAtMoment = moment(new Date(createdAt));

    // createdAt is in the future due to server clock not being quite in sync with local machine clock
    // don't want to show 'in a few seconds message' for a new comment
    if (createdAtMoment.isAfter()) {
      return 'a few seconds ago';
    }

    return createdAtMoment.fromNow();
  }, [createdAt]);

  return (
    <IdsListItem {...rest}>
      <IdsListItemChild container direction='column'>
        {!deleted ? (
          <>
            <IdsListItemChild xs='auto'>
              <IdsListItemChild className={styles.commentAndAvatarContainer}>
                <ListItemAvatar className={styles.listItemAvatar}>
                  <IdsUserAvatar name={creatorName} className={styles.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Grid
                      container
                      columnSpacing={1}
                      direction='row'
                      justifyContent='space-between'
                    >
                      <Grid item xs='auto'>
                        <Typography variant='body2' color='text.secondary'>
                          {creatorName}
                        </Typography>
                      </Grid>
                      <Grid item xs='auto'>
                        <div className={styles.timestampContainer}>
                          <Typography variant='caption' color='text.secondary'>
                            {timestamp}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  }
                  secondary={
                    <Typography variant='body2' color='text.primary'>
                      {text}
                      {wasEdited && (
                        <Typography variant='caption' color='text.secondary'>
                          {' (edited)'}
                        </Typography>
                      )}
                    </Typography>
                  }
                  disableTypography
                />
              </IdsListItemChild>
            </IdsListItemChild>
            {(canReply || canEdit || canDelete) && (
              <IdsListItemChild
                xs='auto'
                container
                direction='row'
                columnSpacing={1}
                className={styles.actionsContainer}
              >
                {canReply && (
                  <IdsListItemChild xs='auto'>
                    <LinkButton onClick={handleReply}>Reply</LinkButton>
                  </IdsListItemChild>
                )}
                {canEdit && (
                  <IdsListItemChild xs='auto'>
                    <LinkButton onClick={handleEdit}>Edit</LinkButton>
                  </IdsListItemChild>
                )}
                {canDelete && (
                  <IdsListItemChild xs='auto'>
                    <LinkButton onClick={handleDelete}>Delete</LinkButton>
                  </IdsListItemChild>
                )}
              </IdsListItemChild>
            )}
          </>
        ) : (
          // This comment is soft deleted, show placeholder
          <IdsListItemChild xs='auto' className={styles.deletedCommentAndAvatarContainer}>
            <ListItemAvatar className={styles.listItemAvatar}>
              <Avatar className={styles.avatar}>
                <DeleteIcon fontSize='small' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary='This comment was deleted.' />
          </IdsListItemChild>
        )}

        {replies && replies.length > 0 && (
          <IdsListItemChild xs='auto'>
            <List dense disablePadding className={styles.repliesList}>
              {/* Could end up with soft deleted replies since we support nested replies in the data structure, just not visually */}
              {replies.map(
                (r, i) =>
                  !r.deleted && (
                    <IdsComment
                      key={r.id}
                      comment={r}
                      onReply={onReply}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      divider={i < replies.length - 1}
                      className={styles.replyComment}
                    />
                  ),
              )}
            </List>
          </IdsListItemChild>
        )}
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default IdsComment;
