import { useCallback, useEffect, useMemo } from 'react';
import Uppy, { UploadResult } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useRecoilValue } from 'recoil';

import queryClient from '../../../utils/query';
import keycloak from '../../../keycloak';
import { UploadType, IUploader, IUploaderValue } from '../../../constants/uploads';
import { USER_ROLES } from '../../../constants/users';
import RuntimeConfig from '../../../RuntimeConfig';
import { activeTenantState } from '../../../atoms/tenants';
import { useTenantKeys } from '../../../services/TenantService';
import useIdsUploaderContext from '../../useIdsUploaderContext';
import useUppyEventHandler from '../../useUppyEventHandler';

const getXHRUploadId = (uppyId: string) => `${uppyId}-XHRUpload`;

const useTenantLogoUploader = (enabled: boolean) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { uppy } = useIdsUploaderContext();
  const tenantKeys = useTenantKeys();

  useEffect(() => {
    if (!enabled) return;

    const xhrId = getXHRUploadId(uppy.getID());
    const xhr = uppy.getPlugin(xhrId);
    xhr?.setOptions({
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/tenants/${activeTenant!.id}/update_logo`,
    });
  }, [activeTenant, enabled, uppy]);

  const handleUploadComplete = useCallback(
    (result: UploadResult) => {
      if (!result.successful.length) return;

      queryClient.invalidateQueries(tenantKeys.details(activeTenant!.id));
      queryClient.invalidateQueries(tenantKeys.details(activeTenant!.subdomain));
    },
    [activeTenant, tenantKeys],
  );

  useUppyEventHandler('complete', handleUploadComplete, uppy, enabled);

  return useMemo<IUploaderValue | null>(
    () =>
      enabled
        ? ({
            formProps: {
              initialValues: {},
              successMessage: 'Tenant logo updated',
              errorHandler: () => 'Tenant logo could not be updated',
            },
            uploadSteps: [],
          } as IUploaderValue)
        : null,
    [enabled],
  );
};

export const TenantLogoUploader: IUploader = {
  uploadType: UploadType.TenantLogo,
  label: 'Tenant logo',
  uppyRestrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['.jpg', '.jpeg', '.png'],
    // 200 KB is the general guideline I'm seeing online
    // For some reason uppy is 5 kb off in it's calculation, so this limit is set to 205 KB
    maxFileSize: 205 * 1000,
  },
  isAvailable: (_, __, userHasOneOfRoles) => {
    return userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]);
  },
  useUploader: useTenantLogoUploader,
  configureUppy: (uppy: Uppy) => {
    const xhrId = getXHRUploadId(uppy.getID());

    if (!uppy.getPlugin(xhrId)) {
      // Plugin not already installed
      uppy.use(XHRUpload, {
        id: xhrId,
        endpoint: '',
        method: 'PUT',
        fieldName: 'logo',
        allowedMetaFields: [],
        headers: () => ({
          // Ensure the keycloak token is updated before uploading
          authorization: `Bearer ${keycloak.token}`,
          'x-client-id': 'Explorer',
        }),
      });
    }
  },
  deconfigureUppy: (uppy: Uppy) => {
    const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
    if (xhr) {
      uppy.removePlugin(xhr);
    }
  },
  queueUpload: false,
};

export default useTenantLogoUploader;
