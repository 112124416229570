import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { useMemo } from 'react';

import axios from '../utils/axios';
import queryClient from '../utils/query';
import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import { IImageRep, IMediaMetadata } from '../constants/media';

import { useAssignmentResponseKeys } from './AssignmentResponsesService';

export const useTaskResponseKeys = () => {
  const queryIdKey = useQueryKeyId();

  return useMemo(() => {
    const taskResponseKeys = {
      all: ['taskResponses', ...queryIdKey],
      lists: () => [...taskResponseKeys.all, 'list'],
      taskList: (taskId: string) => [`task-${taskId}`],
      route: (id: string) => [...taskResponseKeys.all, id],
      detail: (id: string) => [...taskResponseKeys.route(id), 'detail'],
    };

    return taskResponseKeys;
  }, [queryIdKey]);
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

export const useDeleteTaskResponse = (assignmentResponseId: string, taskResponseId: string) => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  return useMutation(
    () => {
      return axios.delete(
        `/api/v2/assignment_responses/${assignmentResponseId}/task_responses/${taskResponseId}`,
      );
    },
    {
      onError,
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [...assignmentResponseKeys.detail(assignmentResponseId)],
          {
            refetchActive: true,
          },
        );
      },
    },
  );
};

interface INewTaskResponseAnswerForPhotos {
  question_id: string;
  uuid: string;
}

interface IExistingTaskResponseAnswer {
  id: string;
  question_id: string;
  // Custom text or question option
  value: string;
}

interface INewTaskResponseAnswer {
  question_id: string;
  // Custom text or question option
  value: string;
}

interface IUpdateTaskResponseInput {
  assignmentResponseId: string;
  taskResponseId: string;

  data: {
    task_id: string;
    guid: string;
    answers: (
      | INewTaskResponseAnswerForPhotos
      | INewTaskResponseAnswer
      | IExistingTaskResponseAnswer
    )[];
  };
}

export const useUpdateTaskResponseAnswers = () => {
  const taskResponseKeys = useTaskResponseKeys();

  return useMutation(
    ({ assignmentResponseId, taskResponseId, data }: IUpdateTaskResponseInput) => {
      return axios.put(
        `/api/v2/assignment_responses/${assignmentResponseId}/task_responses/${taskResponseId}`,
        { data },
      );
    },
    {
      onError,
      onSuccess: async (_, { taskResponseId }) => {
        if (!taskResponseId) {
          return;
        }

        await queryClient.invalidateQueries([...taskResponseKeys.detail(taskResponseId)], {
          refetchActive: true,
        });
      },
    },
  );
};

export const useCreateTaskResponse = (assignmentResponseId: string) => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  return useMutation(
    ({ data }) => {
      return axios.post(`/api/v2/assignment_responses/${assignmentResponseId}/task_responses`, {
        data,
      });
    },
    {
      onError,
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [...assignmentResponseKeys.detail(assignmentResponseId)],
          {
            refetchActive: true,
          },
        );
      },
    },
  );
};

interface IImageAnswerUploaderConfigQuery {
  assignmentResponse: {
    project: {
      id: string;
      locationId: string;
    };
  };
}

const ImageAnswerUploaderConfigQuery = gql`
  query ImageAnswerUploaderConfigQuery($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      project {
        id
        locationId
      }
    }
  }
`;

export const useGetImageAnswerUploaderConfig = (assignmentResponseId: string) => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  return useOrgGraphQuery<IImageAnswerUploaderConfigQuery>(
    [...assignmentResponseKeys.route(assignmentResponseId), 'imageAnswerUploaderConfig'],
    ImageAnswerUploaderConfigQuery,
    {
      id: assignmentResponseId,
    },
  );
};

interface IExistingImageAnswerQuery {
  image: {
    metadata: Omit<IMediaMetadata, 'value'>[];
    position: {
      latitude: number;
      longitude: number;
      altitude: number | null;
      heading: number | null;
    };
  };
}

const ExistingImageAnswerQuery = gql`
  query ExistingImageAnswerQuery($orgId: ID, $urn: String!, $tenantId: ID) {
    image(organizationId: $orgId, urn: $urn, tenantId: $tenantId) {
      metadata {
        id
        type
      }
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
`;

export const useGetExistingImageAnswer = (urn?: string) =>
  useOrgGraphQuery<IExistingImageAnswerQuery>(
    [urn, 'existingImageAnswer'],
    ExistingImageAnswerQuery,
    { urn },
    { enabled: !!urn },
  );

interface INewImageAnswerQuery {
  image: {
    reps: IImageRep[];
    metadata: Omit<IMediaMetadata, 'value'>[];
    position: {
      latitude: number;
      longitude: number;
      altitude: number | null;
      heading: number | null;
    };
  };
}

const NewImageAnswerQuery = gql`
  query NewImageAnswerQuery($orgId: ID, $urn: String!, $tenantId: ID) {
    image(organizationId: $orgId, urn: $urn, tenantId: $tenantId) {
      reps {
        url
        name
      }
      metadata {
        id
        type
      }
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
`;

export const useGetNewImageAnswer = (urn?: string) =>
  useOrgGraphQuery<INewImageAnswerQuery>(
    [urn, 'newImageAnswer'],
    NewImageAnswerQuery,
    { urn },
    { enabled: !!urn },
  );
