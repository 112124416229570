import { Box, Backdrop, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';

import IdsForm from '../../../components/ids-forms/IdsForm';
import IdsFormTextField from '../../../components/ids-forms/IdsFormTextField';
import { useGetProfileDetails } from '../../../services/ProfileService';
import { useUpdateUserDetails } from '../../../services/UsersService';
import ActionButtons from '../Common/ActionButtons';

import styles from './Details.module.css';
import { detailsValidationSchema } from './detailsValidation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDetailsProps {}

interface IFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  title: string;
}

const Details: React.FC<IDetailsProps> = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: updateUserDetailsMutation } = useUpdateUserDetails();
  const { data, isLoading, error } = useGetProfileDetails();

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const [initialValues, setInitialValues] = useState<IFormValues>(() => ({
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
  }));

  useEffect(() => {
    if (!isLoading && data) {
      setInitialValues({
        firstName: data.me.firstName,
        lastName: data.me.lastName,
        phone: data.me.phone,
        title: data.me.title,
      });
    }
  }, [isLoading, data]);

  const onSubmitHandler = async (input: IFormValues) => {
    setIsUpdating(true);

    const result = await updateUserDetailsMutation({
      userId: data!.me.id,
      ...input,
    });

    setIsUpdating(false);

    if (result.updateUserDetails?.errors?.length) {
      throw new Error(result.updateUserDetails.errors[0].message);
    }
  };

  return (
    <Box position='relative' p={2}>
      <IdsForm
        enableReinitialize
        initialValues={initialValues}
        validationSchema={detailsValidationSchema}
        onSubmit={onSubmitHandler}
        errorHandler={error => {
          setIsUpdating(false);
          setIsEditMode(true);

          return error || 'Cannot update profile details.';
        }}
        successMessage='Profile details were updated.'
      >
        <Backdrop
          className={styles.backdrop}
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isUpdating}
        >
          <CircularProgress />
        </Backdrop>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box>
            <IdsFormTextField disabled={!isEditMode} name='firstName' label='First Name' required />
          </Box>
          <Box>
            <IdsFormTextField disabled={!isEditMode} name='lastName' label='Last Name' required />
          </Box>
          <Box>
            <IdsFormTextField disabled={!isEditMode} name='title' label='Title' />
          </Box>
          <Box>
            <IdsFormTextField
              disabled={!isEditMode}
              name='phone'
              label='Phone'
              inputProps={{ maxLength: 12 }}
              required
            />
          </Box>
          <ActionButtons
            isUpdating={isUpdating}
            isLoading={isLoading}
            isEditMode={isEditMode}
            setEditMode={setIsEditMode}
          />
        </Box>
      </IdsForm>
    </Box>
  );
};

export default Details;
