import * as Yup from 'yup';

import { phoneValidation } from '../../../field-validation/contactInfo';

const detailsValidation = {
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  title: Yup.string(),
  phone: phoneValidation.required('Phone is required'),
};

export const detailsValidationSchema = Yup.object().shape(detailsValidation);
