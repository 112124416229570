import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useRecoilValue } from 'recoil';
import { capitalCase } from 'change-case';

import { createOrgUserValidationSchema } from '../../userValidation';
import IdsCreateDialogForm from '../../../../components/ids-forms/IdsCreateDialogForm';
import IdsFormSelectField from '../../../../components/ids-forms/IdsFormSelectField';
import { activeOrganizationState } from '../../../../atoms/organizations';

import { useCreateUser } from '../../../../services/UsersService';
import { USER_ROLES } from '../../../../constants/users';

import BaseFields from './BaseFields';

export const ROLE_OPTIONS = Object.values(USER_ROLES)
  .filter(role => role.canAssign)
  .map(role => ({
    label: capitalCase(role.name),
    value: role.id,
  }));

const initialValues = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: '',
  role: '',
};

function CreateUserForm({ onSubmitSuccess, onCancel }) {
  const organization = useRecoilValue(activeOrganizationState);

  const createUserMutation = useCreateUser();

  const onSubmit = useCallback(
    async values => {
      await createUserMutation.mutateAsync({
        organizationId: organization.id,
        roleId: values.role,
        ...values,
      });

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    [createUserMutation, organization, onSubmitSuccess],
  );

  return (
    <IdsCreateDialogForm
      initialValues={initialValues}
      validationSchema={createOrgUserValidationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='User created'
      errorHandler={() => 'User could not be created'}
    >
      <Grid container direction='column' spacing={2}>
        {/* First name, last name, title, email, phone */}
        <BaseFields />

        <Grid item xs='auto'>
          <IdsFormSelectField name='role' label='Role' required options={ROLE_OPTIONS} />
        </Grid>
      </Grid>
    </IdsCreateDialogForm>
  );
}

CreateUserForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreateUserForm;
