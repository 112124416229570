import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Divider,
  List,
  Skeleton,
  SvgIcon,
} from '@mui/material';

import { ExpandIcon } from '../../../../../theme/icons';
import IdsCheckbox from '../../../../ids-inputs/IdsCheckbox';
import MapDocumentListItem from '../MapDocumentListItem';
import '../../../../../theme/globalStyles.css';

import { VIEW_TYPES } from '../../../../../constants/viewTypes';
import { useGetProjectDocuments } from '../../../../../services/DocumentsService';

const labelProps = {
  onClick: e => {
    e.stopPropagation();
  }, // Don't expand accordion
  componentsProps: { typography: { variant: 'body2' } },
};

const ProjectAccordion = ({ project, onChange, loading, selected }) => {
  const { data, isLoading } = useGetProjectDocuments(project?.id || '', {
    viewType: VIEW_TYPES.infoTab,
  });

  const documents = useMemo(() => data?.project.projectDocuments.edges || [], [data]);

  const _onChange = useCallback(() => {
    if (onChange) {
      onChange(!selected, project?.id);
    }
  }, [onChange, selected, project]);

  return (
    <Accordion
      key={`project-${project?.id}`}
      disableGutters={true}
      className='accordion-noBorder accordion-noBackground'
      disabled={loading || isLoading}
    >
      {/* disabled padding on summary, make not as tall, shrink text size */}
      <AccordionSummary
        expandIcon={
          <SvgIcon fontSize='small'>
            <ExpandIcon />
          </SvgIcon>
        }
        className='accordion-dense accordion-wide'
      >
        <IdsCheckbox
          label={loading ? <Skeleton width='200px' /> : project.name}
          checked={selected}
          labelProps={labelProps}
          onChange={_onChange}
          size='small'
        />
      </AccordionSummary>
      <Divider />
      <AccordionDetails className='accordion-wide'>
        {documents.length ? (
          <List dense disablePadding>
            {documents.map((d, i) => (
              <MapDocumentListItem
                key={`document-${d.node.id}`}
                documentItem={d}
                uniqId={`${project?.id}-${d.node.id}`}
                // disable divider on last item
                divider={i !== documents.length - 1}
              />
            ))}
          </List>
        ) : (
          <Alert severity='info'>This project has no documents.</Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ProjectAccordion.propTypes = {
  project: PropTypes.object,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default ProjectAccordion;
