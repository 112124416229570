import { gql } from 'graphql-request';

import { MediaType } from '../../../../constants/media';
import { REFETCH_INTERVAL_MS, STALE_TIME_MS } from '../../../../constants/queries';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery, {
  IEdgeWithCursor,
  IUseCursorPaginatedQueryOptions,
} from '../../../../hooks/useCursorPaginatedQuery';
import { useLocationKeys } from '../../../../services/LocationService';
import {
  GalleryImageFrag,
  GalleryImageRepsFrag,
  IGalleryImageDataEdge,
  IGalleryImageRepsEdge,
} from '../../../../utils/mediaViews';

export const LOCATION_MEDIA_TAB_PHOTOS_KEY = 'location-media-tab-photos-key';

const ProjectPhotosQuery = gql`
  query ProjectPhotos($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      projectPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageFrag
          }
        }
      }
    }
  }
  ${GalleryImageFrag}
`;

const ProjectPhotosRepsQuery = gql`
  query ProjectPhotosReps($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      projectPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageRepsFrag
          }
        }
      }
    }
  }
  ${GalleryImageRepsFrag}
`;

const HDPhotosQuery = gql`
  query HDPhotos($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      hdPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageFrag
          }
        }
      }
    }
  }
  ${GalleryImageFrag}
`;

const HDPhotosRepsQuery = gql`
  query HDPhotosReps($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      hdPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageRepsFrag
          }
        }
      }
    }
  }
  ${GalleryImageRepsFrag}
`;

const PanoramasQuery = gql`
  query Panoramas($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      panoramas(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageFrag
            position {
              heading
            }
          }
        }
      }
    }
  }
  ${GalleryImageFrag}
`;

const PanoramasRepsQuery = gql`
  query PanoramasReps($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      panoramas(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageRepsFrag
          }
        }
      }
    }
  }
  ${GalleryImageRepsFrag}
`;

export const useProjectPhotos = (
  locationId: string,
  onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad'],
) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.detail(locationId),
        MediaType.ProjectPhoto,
        'gallery',
        `take-${take}`,
        `after-${after}`,
        LOCATION_MEDIA_TAB_PHOTOS_KEY,
      ],
      ProjectPhotosQuery,
      {
        locationId,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageDataEdge>>({
    initialData: [],
    useQuery,
    defaultTake: 50,
    selectConnection: d => d.location.projectPhotos,
    onPageLoad,
  });
};

export const useProjectPhotosReps = (
  locationId: string,
  enabled: boolean,
  onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad'],
) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.detail(locationId),
        MediaType.ProjectPhoto,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      ProjectPhotosRepsQuery,
      {
        locationId,
        take,
        after,
      },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
        enabled,
      },
    );

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageRepsEdge>>({
    initialData: [],
    useQuery,
    defaultTake: 50,
    selectConnection: d => d.location.projectPhotos,
    onPageLoad,
  });
};

export const useHDPhotos = (
  locationId: string,
  onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad'],
) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.detail(locationId),
        MediaType.HDPhoto,
        'gallery',
        `take-${take}`,
        `after-${after}`,
        LOCATION_MEDIA_TAB_PHOTOS_KEY,
      ],
      HDPhotosQuery,
      {
        locationId,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageDataEdge>>({
    initialData: [],
    useQuery,
    defaultTake: 50,
    selectConnection: d => d.location.hdPhotos,
    onPageLoad,
  });
};

export const useHDPhotosReps = (
  locationId: string,
  enabled: boolean,
  onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad'],
) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.detail(locationId),
        MediaType.HDPhoto,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      HDPhotosRepsQuery,
      {
        locationId,
        take,
        after,
      },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
        enabled,
      },
    );

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageRepsEdge>>({
    initialData: [],
    useQuery,
    defaultTake: 50,
    selectConnection: d => d.location.hdPhotos,
    onPageLoad,
  });
};

export const usePanoramas = (
  locationId: string,
  onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad'],
) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.detail(locationId),
        MediaType.PanoramicPhoto,
        'gallery',
        `take-${take}`,
        `after-${after}`,
        LOCATION_MEDIA_TAB_PHOTOS_KEY,
      ],
      PanoramasQuery,
      {
        locationId,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageDataEdge>>({
    initialData: [],
    useQuery,
    defaultTake: 50,
    selectConnection: d => d.location.panoramas,
    onPageLoad,
  });
};

export const usePanoramasReps = (
  locationId: string,
  enabled: boolean,
  onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad'],
) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.detail(locationId),
        MediaType.PanoramicPhoto,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      PanoramasRepsQuery,
      {
        locationId,
        take,
        after,
      },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
        enabled,
      },
    );

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageRepsEdge>>({
    initialData: [],
    useQuery,
    defaultTake: 50,
    selectConnection: d => d.location.panoramas,
    onPageLoad,
  });
};
