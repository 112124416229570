import React, { useCallback, useMemo } from 'react';
import { Button, ButtonProps, Divider, Stack } from '@mui/material';
import { TRANSITION_EVENTS } from '@deck.gl/core/typed';

import MapOverlay from '../MapOverlay';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';
import { ZoomInIcon, ZoomOutIcon } from '../../../theme/icons';

import styles from './ZoomControl.module.css';

const MapOverlayNoType = MapOverlay as any;

const sx = {
  control: {
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: 'background.dark',
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IZoomButtonProps extends Omit<ButtonProps, 'size' | 'className' | 'sx'> {}

const ZoomButton: React.FC<IZoomButtonProps> = ({ onClick, disabled, children, ...rest }) => (
  <Button
    size='small'
    onClick={onClick}
    disabled={disabled}
    className={styles.zoomBtn}
    sx={sx.control}
    {...rest}
  >
    {children}
  </Button>
);

export interface IZoomControlProps {
  zoomStep?: number;
  disabled?: boolean;
}

const ZoomControl: React.FC<IZoomControlProps> = ({ zoomStep = 1, disabled = false, ...rest }) => {
  const { useViewState, setViewState } = useImmersiveViewer();
  const viewState = useViewState();

  // clamp to positive
  const _zoomStep = useMemo(() => Math.max(0, zoomStep), [zoomStep]);

  const modifyZoom = useCallback(
    (zoomChange: number) => {
      if (!viewState) return;

      const { zoom: currentZoom } = viewState;

      setViewState({
        ...viewState,
        zoom: currentZoom + zoomChange,
        transitionDuration: 200,
        transitionInterruption: TRANSITION_EVENTS.BREAK,
      });
    },
    [viewState, setViewState],
  );

  const zoomIn = useCallback(() => {
    modifyZoom(_zoomStep);
  }, [modifyZoom, _zoomStep]);

  const zoomOut = useCallback(() => {
    modifyZoom(-_zoomStep);
  }, [modifyZoom, _zoomStep]);

  const zoomBtns = useMemo(
    () => (
      <Stack direction='column' spacing={0} className={styles.container}>
        <ZoomButton onClick={zoomIn} disabled={disabled} data-testid='zoomInBtn'>
          <ZoomInIcon className={styles.zoomIcon} />
        </ZoomButton>
        <Divider />
        <ZoomButton onClick={zoomOut} disabled={disabled} data-testid='zoomOutBtn'>
          <ZoomOutIcon className={styles.zoomIcon} />
        </ZoomButton>
      </Stack>
    ),
    [zoomIn, zoomOut, disabled],
  );

  return <MapOverlayNoType {...rest}>{zoomBtns}</MapOverlayNoType>;
};

ZoomControl.defaultProps = {
  zoomStep: 1,
};

export default ZoomControl;
