import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSetRecoilState } from 'recoil';

import useFlagFilter, { FLAG_FILTER_MODES } from '../../../hooks/filtering/useFlagFilter';
import { infoPanelActivePaneState } from '../../../atoms/immersiveViewer';
import { panelOptions } from '../../../context/LocationMapContext';
import IdsSelect from '../../ids-inputs/IdsSelect';

import styles from './FlagFilter.module.css';

const FILTER_OPTIONS = Object.values(FLAG_FILTER_MODES).map(m => ({
  label: m,
  value: m,
}));

const FlagFilter = ({ resetOnUnmount, ...rest }) => {
  const { setFlagFilterMode, flagFilterMode } = useFlagFilter(resetOnUnmount);
  const setActiveInfoPane = useSetRecoilState(infoPanelActivePaneState);

  const handleChange = useCallback(
    event => {
      const newFilterMode = event.target.value;

      if (newFilterMode !== FLAG_FILTER_MODES.INACTIVE) {
        setActiveInfoPane(panelOptions.FILTERS); // Automatically open filter pane when filter is set
      }

      setFlagFilterMode(newFilterMode);
    },
    [setFlagFilterMode, setActiveInfoPane],
  );

  return (
    <IdsSelect
      label='Flag'
      options={FILTER_OPTIONS}
      value={flagFilterMode}
      onChange={handleChange}
      className={styles.select}
      {...rest}
    />
  );
};

FlagFilter.propTypes = {
  resetOnUnmount: PropTypes.bool,
};

export default FlagFilter;
