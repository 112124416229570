import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { infoPanelActivePaneState } from '../../../atoms/immersiveViewer';
import useFilterContext from '../../../hooks/useFilterContext';
import { FilterMode } from '../../../context/FilterContext';
import { panelOptions } from '../../../context/LocationMapContext';

const useOverlayMenuItems = ({
  mediaType,
  overlays,
  filteredOverlays,
  enableOverlay,
  disableOverlay,
}) => {
  const { useFilters, getItemFilterRecord } = useFilterContext();
  const activeFilters = useFilters();

  const setActivePane = useSetRecoilState(infoPanelActivePaneState);

  const computeMenuItemDisabled = useCallback(
    (type, itemId) =>
      // Menu item is disabled if filtered out by a subtractive filter
      getItemFilterRecord(type, itemId)?.mode === FilterMode.Subtractive,
    [getItemFilterRecord],
  );

  const getMenuItemTooltip = useCallback(
    (type, itemId) => {
      const filteredBy = getItemFilterRecord(type, itemId);
      return filteredBy ? `Filtered out by ${filteredBy.label.toLowerCase()} filter` : null;
    },
    [getItemFilterRecord],
  );

  const createOverlayFilter = useCallback(
    (overlay, openLayersPane = true) => {
      return show => {
        if (show) {
          if (openLayersPane) {
            // Open the info panel layers pane
            setActivePane(panelOptions.LAYERS);
          }
          enableOverlay(overlay);
        } else {
          disableOverlay(overlay);
        }
      };
    },
    [setActivePane, enableOverlay, disableOverlay],
  );

  const overlayMenuItems = useMemo(
    () =>
      overlays?.map(o => ({
        label: o.name,
        active: !!filteredOverlays.find(fo => fo.id === o.id),
        // Default layer should never be disabled, it is not affected by other layers
        disabled: !o.default && computeMenuItemDisabled(mediaType, o.id),
        // Only show tooltip if it's not default,
        // default overlay should never show that it was filtered out
        tooltip: !o.default ? getMenuItemTooltip(mediaType, o.id) : null,
        filter: createOverlayFilter(o),
        // IMPORTANT: Include activeFilters in deps to trigger a recalculation
        // of the disabled state based on the media type filter records
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      overlays,
      filteredOverlays,
      createOverlayFilter,
      computeMenuItemDisabled,
      activeFilters,
      getMenuItemTooltip,
      mediaType,
    ],
  );

  return {
    overlayMenuItems,
    createOverlayFilter,
  };
};

export default useOverlayMenuItems;
