import { useMutation } from 'react-query';
import { gql } from 'graphql-request';

import RuntimeConfig from '../RuntimeConfig';
import axios from '../utils/axios';
import queryClient from '../utils/query';
import { MediaType } from '../constants/media';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';

import { IBasicImageFieldsInput } from './types';

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

/**
 * Gets the url to the krpano xml for the specified panorama.
 * @param {*} id type specific panorama id, not the general media urn id
 */
export function getPanoramaXMLUrl(id: string) {
  return `${RuntimeConfig.apiBaseUrl}/api/v2/panoramas/${id}/krpano.xml`;
}

interface IUpdatePanoramaPayload {
  id: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  published?: boolean;
  capturedAt?: string;
  altitude?: number;
  heading?: number;
  photoLevelId?: string;
  photoAreaId?: string;
  authorName?: string;
}

const updatePanorama = ({
  id,
  latitude,
  longitude,
  description,
  capturedAt,
  altitude,
  heading,
  photoLevelId,
  photoAreaId,
  authorName,
}: IUpdatePanoramaPayload) => {
  return axios.put(`/api/v2/panoramas/${id}`, {
    panorama: {
      latitude,
      longitude,
      description,
      captured_at: capturedAt,
      altitude,
      heading,
      photo_level_id: photoLevelId,
      photo_area_id: photoAreaId,
      author_name: authorName,
    },
  });
};

export function useUpdatePanorama() {
  return useMutation(updatePanorama, {
    onError,
    onSuccess: (_: any, { id }: IUpdatePanoramaPayload) => {
      queryClient.invalidateQueries({
        // Invalidate all queries for panoramas or the updated panorama
        predicate: query =>
          query.queryKey.includes(MediaType.PanoramicPhoto) || query.queryKey.includes(id),
        refetchActive: false,
      });
    },
  });
}

export const useDeletePanorama = () => {
  return useMutation(
    (id: string) => {
      return axios.delete(`/api/v2/panoramas/${id}`);
    },
    {
      onError,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(MediaType.PanoramicPhoto),
          refetchActive: false,
        });
      },
    },
  );
};

const UpdatePanoramaMutation = gql`
  mutation UpdatePanorama($where: WhereUniqueIdOrganizationInput!, $input: UpdatePanoramaInput!) {
    updatePanorama(where: $where, input: $input) {
      image {
        published
        metadata {
          type
          id
          value
          creatorId
        }
      }
      errors {
        message
      }
    }
  }
`;

interface IUpdatePanoramaInput extends IBasicImageFieldsInput {
  altitude?: number;
  heading?: number;
  orientation?: number;
  published?: boolean;
}

const updatePanoramaMutation = ({
  id,
  organizationId,
  input,
}: {
  id: string;
  organizationId: string;
  input: IUpdatePanoramaInput;
}) => {
  return buildGraphMutationFn(UpdatePanoramaMutation)({
    input: {
      ...input,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export const useUpdatePanoramaMutation = () => {
  return useMutation(updatePanoramaMutation, {
    onError,
    onSuccess: async (_: any, { id }) => {
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes(MediaType.PanoramicPhoto) || query.queryKey.includes(id),
        refetchActive: false,
      });
    },
  });
};
