import { gql } from 'graphql-request';
import { useCallback } from 'react';

import { MediaType } from '../../../../constants/media';
import { REFETCH_INTERVAL_MS, STALE_TIME_MS } from '../../../../constants/queries';
import { useProjectKeys } from '../../../../services/ProjectService';
import useCursorPaginatedQuery, {
  IEdgeWithCursor,
} from '../../../../hooks/useCursorPaginatedQuery';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import {
  GalleryImageFrag,
  GalleryImageRepsFrag,
  IGalleryImageDataEdge,
  IGalleryImageRepsEdge,
  useOnGalleryPageLoaded,
} from '../../../../utils/mediaViews';

const ProjectPhotosQuery = gql`
  query ProjectPhotos($orgId: ID, $tenantId: ID, $projectId: ID!, $take: Int, $after: String) {
    project(organizationId: $orgId, id: $projectId, tenantId: $tenantId) {
      projectPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageFrag
          }
        }
      }
    }
  }
  ${GalleryImageFrag}
`;

const ProjectPhotosRepsQuery = gql`
  query ProjectPhotosReps($orgId: ID, $tenantId: ID, $projectId: ID!, $take: Int, $after: String) {
    project(organizationId: $orgId, id: $projectId, tenantId: $tenantId) {
      projectPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...GalleryImageRepsFrag
          }
        }
      }
    }
  }
  ${GalleryImageRepsFrag}
`;

const initialData: any[] = [];
const selectConnection = (d: any) => d.project.projectPhotos;
export const PROJECT_MEDIA_TAB_PHOTOS_KEY = 'project-media-tab-photos-key';

const useProjectPhotos = (
  projectId: string,
  setMediaCount: (func: (prevCount: number) => number) => void,
) => {
  const projectKeys = useProjectKeys();

  const { onPageLoaded } = useOnGalleryPageLoaded();
  const handleProjectPhotosPage = useCallback(
    edges => {
      onPageLoaded(edges, MediaType.ProjectPhoto, setMediaCount);
    },
    [onPageLoaded, setMediaCount],
  );

  const useQuery = (take: number, after: string | null) => {
    return useOrgGraphQuery(
      [
        ...projectKeys.detail(projectId),
        MediaType.ProjectPhoto,
        'gallery',
        `take-${take}`,
        `after-${after}`,
        PROJECT_MEDIA_TAB_PHOTOS_KEY,
      ],
      ProjectPhotosQuery,
      {
        projectId,
        take,
        after,
      },
    );
  };

  return useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageDataEdge>>({
    initialData,
    useQuery,
    defaultTake: 50,
    selectConnection,
    onPageLoad: handleProjectPhotosPage,
  });
};

export const useProjectPhotosReps = (projectId: string, enabled: boolean) => {
  const projectKeys = useProjectKeys();

  const { onRepsLoaded } = useOnGalleryPageLoaded();
  const handleProjectPhotosReps = useCallback(
    edges => {
      onRepsLoaded(edges, MediaType.ProjectPhoto);
    },
    [onRepsLoaded],
  );

  const useQuery = (take: number, after: string | null) => {
    return useOrgGraphQuery(
      [
        ...projectKeys.detail(projectId),
        MediaType.ProjectPhoto,
        'reps',
        `take-${take}`,
        `after-${after}`,
      ],
      ProjectPhotosRepsQuery,
      {
        projectId,
        take,
        after,
      },
      {
        refetchInterval: REFETCH_INTERVAL_MS,
        staleTime: STALE_TIME_MS,
        refetchIntervalInBackground: true,
        enabled,
      },
    );
  };

  useCursorPaginatedQuery<IEdgeWithCursor<IGalleryImageRepsEdge>>({
    initialData,
    useQuery,
    defaultTake: 50,
    selectConnection,
    onPageLoad: handleProjectPhotosReps,
  });
};

export default useProjectPhotos;
