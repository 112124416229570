import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Skeleton, Typography, Tooltip, Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { clsx } from 'clsx';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CloseIcon, ExpandIcon, PhotosIcon } from '../../../../theme/icons';
import FilledIconButton from '../../../ids-buttons/FilledIconButton';
import LocationImage from '../../LocationImage';
import MapOverlay from '../../MapOverlay';

import useLocationMapContext from '../../../../hooks/useLocationMapContext';
import { useIsSmallScreen } from '../../../../hooks/useIsSmallScreen';
import {
  infoPanelExpandedState,
  mapMenuExpandedState,
  infoPanelActivePaneState,
} from '../../../../atoms/immersiveViewer';
import LocationChip from '../../../entity-chips/LocationChip';

import '../../../../theme/globalStyles.css';
import ProjectPane from './ProjectPane';
import MediaPane from './MediaPane';

import FilterPane from './FilterPane';
import LayersPane from './LayersPane';
import NavigationChips from './NavigationChips';

import styles from './LocationMapInfo.module.css';

const LocationMapInfo = ({ onClose, onViewAllImagery, openMediaViewer, onExpandedChange }) => {
  const { panelOptions, loading, location } = useLocationMapContext();

  const isSmallScreen = useIsSmallScreen();
  const [infoPanelExpanded, setInfoPanelExpanded] = useRecoilState(infoPanelExpandedState);
  const setMapMenuExpanded = useSetRecoilState(mapMenuExpandedState);
  const activePane = useRecoilValue(infoPanelActivePaneState);

  const { id, name, address, projects, organizationId } = location;

  const toggleExpanded = useCallback(() => {
    setInfoPanelExpanded(!infoPanelExpanded);

    /**
     * Run the conditioned code if the info panel has just been opened.
     */
    if (!infoPanelExpanded && isSmallScreen) {
      /**
       * If the map menu is open, close it. Otherwise, do nothing.
       */
      setMapMenuExpanded(false);
    }

    if (onExpandedChange) {
      onExpandedChange(!infoPanelExpanded);
    }
  }, [
    setInfoPanelExpanded,
    infoPanelExpanded,
    onExpandedChange,
    setMapMenuExpanded,
    isSmallScreen,
  ]);

  const _projects = useMemo(() => projects?.edges || [], [projects]);

  const ActivePanel = useMemo(() => {
    switch (activePane) {
      case panelOptions.PROJECTS:
        return <ProjectPane projects={_projects} loading={loading} />;
      case panelOptions.LAYERS:
        return <LayersPane />;
      case panelOptions.FILTERS:
        return <FilterPane />;
      case panelOptions.MEDIA:
      default:
        return <MediaPane loading={loading} onViewAllImagery={onViewAllImagery} />;
    }
  }, [panelOptions, activePane, _projects, loading, onViewAllImagery]);

  const content = useMemo(() => {
    const { streetLine1, city, state, postalCode } = address || {};
    // Only show commas if the address elements are present
    const addressLine2 = `${city ? `${city}, ` : ''}${state || ''}${
      postalCode ? ` ${postalCode}` : ''
    }`;

    return (
      <>
        <LocationImage height={125} />

        <CardContent className={styles['cardContentContainer']}>
          <Box className={styles['locationInfoContainer']}>
            <Typography variant='h4' noWrap>
              {loading && !name ? <Skeleton /> : name}
            </Typography>
            <Typography variant='body2' color='text.secondary' noWrap>
              {loading && !streetLine1 && !addressLine2 ? (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <>
                  <LocationChip locationId={id} organizationId={organizationId} />
                  <br />
                  {streetLine1}
                  {streetLine1 && addressLine2 && <br />}
                  {addressLine2}
                </>
              )}
            </Typography>
            <NavigationChips projects={_projects} loading={loading} />
          </Box>

          {/* Uncomment this once asset data can be queried from GQL */}
          {/* <TotalChip
            total={assets.length}
            itemType='Asset'
            color='secondary'
            className={styles.totalChip}
            loading={loading}
          /> */}

          <PerfectScrollbar className={styles['activePanelContainer']}>
            {ActivePanel}
          </PerfectScrollbar>
        </CardContent>
      </>
    );
  }, [id, address, loading, name, _projects, ActivePanel, organizationId]);

  const expandableCard = useMemo(() => {
    return (
      <Card
        className={clsx({
          [styles['card-expanded']]: infoPanelExpanded,
          [styles['card']]: !infoPanelExpanded,
          [styles['smallScreenWidth']]: isSmallScreen && infoPanelExpanded,
          [styles['autoWidth']]: isSmallScreen && !infoPanelExpanded,
          [styles['largeScreenWidth']]: !isSmallScreen,
        })}
      >
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          className={clsx({
            [styles['hidden']]: !infoPanelExpanded,
            [styles['scrollBarContainer']]: infoPanelExpanded,
            [styles['smallScreenWidth']]: isSmallScreen,
            [styles['largeScreenWidth']]: !isSmallScreen,
          })}
        >
          {content}
        </PerfectScrollbar>
        <Grid
          container
          direction={isSmallScreen && !infoPanelExpanded ? 'column' : 'row'}
          wrap='nowrap'
          justifyContent='flex-end'
          gap={isSmallScreen && !infoPanelExpanded ? 1 : 0}
          className={clsx({
            [styles.collapsedContainer]: !infoPanelExpanded,
            [styles.floatingActionsContainer]: infoPanelExpanded,
          })}
        >
          {!isSmallScreen && (
            <Grid
              item
              xs
              zeroMinWidth
              className={clsx(styles.collapsedTitleContainer, {
                [styles.hidden]: infoPanelExpanded,
              })}
            >
              {name.length > 23 ? (
                <Tooltip title={name} placement='top'>
                  <Typography variant='h4' noWrap>
                    {name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant='h4' noWrap>
                  {name}
                </Typography>
              )}
            </Grid>
          )}

          {isSmallScreen && !infoPanelExpanded && (
            <Grid item xs='auto'>
              <FilledIconButton onClick={toggleExpanded} size='small'>
                <ExpandIcon className={clsx(styles.rotatingIcon, styles.expandSmallScreenIcon)} />
              </FilledIconButton>
            </Grid>
          )}

          <Grid item xs='auto' className={infoPanelExpanded ? styles.mediaViewerIconSpaced : ''}>
            <FilledIconButton
              backgroundProps={{ className: styles.mediaViewerIcon }}
              size='small'
              onClick={openMediaViewer}
            >
              <PhotosIcon className={clsx(styles.mediaViewerIcon)} color='primary' />
            </FilledIconButton>
          </Grid>

          <Grid item xs='auto'>
            <Box
              className={styles.actions}
              flexDirection={isSmallScreen && !infoPanelExpanded ? 'column' : 'row'}
              gap={isSmallScreen && !infoPanelExpanded ? 1 : 0}
            >
              {isSmallScreen && infoPanelExpanded && (
                <FilledIconButton
                  onClick={toggleExpanded}
                  size='small'
                  backgroundProps={{ className: styles.floatingAction }}
                >
                  <ExpandIcon
                    className={clsx(styles.rotatingIcon, styles.collapseSmallScreenIcon)}
                  />
                </FilledIconButton>
              )}

              {!isSmallScreen && (
                <FilledIconButton
                  onClick={toggleExpanded}
                  size='small'
                  backgroundProps={{ className: styles.floatingAction }}
                >
                  <ExpandIcon
                    className={clsx(styles.rotatingIcon, {
                      [styles.collapseIcon]: infoPanelExpanded,
                      [styles.expandIcon]: !infoPanelExpanded,
                    })}
                  />
                </FilledIconButton>
              )}

              <FilledIconButton
                onClick={onClose}
                backgroundProps={{
                  className: isSmallScreen && !infoPanelExpanded ? '' : styles.floatingAction,
                }}
                size='small'
              >
                <CloseIcon />
              </FilledIconButton>
            </Box>
          </Grid>
        </Grid>
      </Card>
    );
  }, [content, infoPanelExpanded, name, onClose, toggleExpanded, openMediaViewer, isSmallScreen]);

  return (
    <MapOverlay
      key={`detailLocation-${id}`}
      className={clsx(styles.container, {
        [styles.containerExpanded]: infoPanelExpanded,
      })}
    >
      {expandableCard}
    </MapOverlay>
  );
};

LocationMapInfo.defaultProps = {
  loading: false,
};

LocationMapInfo.propTypes = {
  location: PropTypes.object, // TODO: flesh this out to be more explicit about data requirements
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onViewAllImagery: PropTypes.func.isRequired,
  openMediaViewer: PropTypes.func.isRequired,
  onExpandedChange: PropTypes.func,
};

export default LocationMapInfo;
