/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, List, ListSubheader } from '@mui/material';

import clsx from 'clsx';

import NavItem from './NavItem';
import styles from './NavTree.module.css';

export function renderNavItems({ items, expanded, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, expanded, ...rest }), [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, expanded, depth = 0 }) {
  const key = item.title + depth;
  const title = !expanded ? '' : item.title;

  if (item.items) {
    const open = matchPath(pathname, item.href);

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        visible={item.visible}
        open={Boolean(open)}
        title={title}
        className={clsx(expanded ? styles.navItemExpanded : styles.navItem)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        visible={item.visible}
        title={title}
        className={clsx(expanded ? styles.navItemExpanded : styles.navItem)}
      />,
    );
  }

  return acc;
}

function NavTree({ navConfig, scrollable, expanded }) {
  const location = useLocation();

  const content = (
    <Box p={!expanded ? 0 : 2}>
      {navConfig.map((config, i) => {
        return (
          <List
            style={{ borderBottom: !expanded ? '1px solid #ccc' : 'none' }}
            key={`configItem${config.subheader || i}`}
            {...(config.subheader && {
              subheader: (
                <ListSubheader disableGutters disableSticky>
                  {expanded && config.subheader}
                </ListSubheader>
              ),
            })}
          >
            {renderNavItems({
              items: config.items,
              pathname: location.pathname,
              expanded,
            })}
          </List>
        );
      })}
    </Box>
  );

  return scrollable ? (
    <PerfectScrollbar options={{ suppressScrollX: true }}>{content}</PerfectScrollbar>
  ) : (
    content
  );
}

NavTree.defaultProps = {
  scrollable: true,
};

NavTree.propTypes = {
  navConfig: PropTypes.array,
  scrollable: PropTypes.bool,
};

export default NavTree;
