import React, { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import SaveButton from '../../../../../../components/ids-buttons/SaveButton';
import CancelButton from '../../../../../../components/ids-buttons/CancelButton';

import { MediaMetadataType, RoutePointType } from '../../../../../../constants/media';

import { useManageRoutePointsMutation } from '../../../../../../services/RouteService';
import { ILatLngAltInput, MediaMetadata } from '../../../../../../services/types';

import { activeProjectRouteState } from '../../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../../atoms/organizations';

import { IPlacedPoint, ISettings } from '../types';

export interface ICreatingButtonsProps {
  points: IPlacedPoint[];
  settings: ISettings;

  onCancel: () => void;
}

const CreatingButtons: React.FC<ICreatingButtonsProps> = ({ points, settings, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const activeProjectRoute = useRecoilValue(activeProjectRouteState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const manageRoutePoints = useManageRoutePointsMutation();

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSave = useCallback(async () => {
    setIsUpdating(true);

    const metadataValues = Object.values(MediaMetadataType);

    const metadata = Object.entries(settings).reduce((accumulator, [key, value]) => {
      if (metadataValues.includes(key as MediaMetadataType) && value !== null) {
        accumulator.push({ type: key as MediaMetadataType, id: value });
      }

      return accumulator;
    }, [] as MediaMetadata[]);

    try {
      const result = await manageRoutePoints.mutateAsync({
        id: activeProjectRoute!.id,
        organizationId: activeOrg.id,
        add: points.map(p => {
          const { altitude, ...position } = p.position as ILatLngAltInput;

          if (
            settings.imageType === RoutePointType.DslrPanorama ||
            settings.imageType === RoutePointType.Panorama
          ) {
            position.heading = settings.heading!;
          }

          return {
            customFieldMetadata: [],
            metadata: metadata,
            type: settings.imageType!,
            position: position as ILatLngAltInput,
          };
        }),
        /**
         * @TODO: Think about refactoring when implementing delete and update
         * functionalities.
         */
        delete: [],
        update: [],
      });

      /**
       * @TODO: think about moving such try-catch & enqueueSnackbar into
       * mutation's onSuccess handler.
       */
      if (result.manageRoutePoints?.errors?.length) {
        enqueueSnackbar('Failed to save points', { variant: 'error' });
      } else {
        enqueueSnackbar('Saved', { variant: 'success' });
      }
    } catch (_) {
      enqueueSnackbar('Failed to save points', { variant: 'error' });
    }

    setIsUpdating(false);

    // Clear fields
    onCancel();
  }, [
    points,
    settings,
    onCancel,
    activeOrg.id,
    activeProjectRoute,
    manageRoutePoints,
    enqueueSnackbar,
  ]);

  const isDisabled = isUpdating || points.length === 0;

  return (
    <Grid item xs container direction='row' gap={2}>
      <Grid item xs>
        <CancelButton onClick={handleCancel} disabled={isDisabled} fullWidth={true} />
      </Grid>
      <Grid item xs>
        <SaveButton onClick={handleSave} disabled={isDisabled} fullWidth={true} />
      </Grid>
    </Grid>
  );
};

export default CreatingButtons;
