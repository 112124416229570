import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import '../../../theme/globalStyles.css';
import { getProjectPhotoXMLUrl } from '../../../services/ProjectPhotosService';
import { getHdPhotoXMLUrl } from '../../../services/HDPhotosService';
import { getPanoramaXMLUrl } from '../../../services/PanoramasService';
import { MEDIA_TYPES, MediaType } from '../../../constants/media';
import { getMediaIdFromUrn } from '../../../utils/media';
import { getMediaTypeFromUrn } from '../../../utils/media';
import KRPanoViewer from '../KRPanoViewer';

const XML_URL_GETTERS = {
  [MEDIA_TYPES[MediaType.ProjectPhoto].type]: getProjectPhotoXMLUrl,
  [MEDIA_TYPES[MediaType.HDPhoto].type]: getHdPhotoXMLUrl,
  [MEDIA_TYPES[MediaType.PanoramicPhoto].type]: getPanoramaXMLUrl,
  [MEDIA_TYPES[MediaType.RasterOverlay].type]: function () {
    return;
  },
};

const KRPanoMediaLoader = ({
  urn,
  neighbors,
  width,
  height,
  onHotspotHoverStart,
  onHotspotHoverStop,
  onHotspotClick,
  ...rest
}) => {
  const krpanoRef = useRef();
  const [hotspotConfigLoaded, setHotspotConfigLoaded] = useState(false);
  const [hotspotsLoaded, setHotspotsLoaded] = useState(false);
  const [panoLoaded, setPanoLoaded] = useState(false);

  const xmlUrl = useMemo(() => {
    if (!urn) return null;

    const id = getMediaIdFromUrn(urn);
    const type = getMediaTypeFromUrn(urn);
    return XML_URL_GETTERS[type](id);
  }, [urn]);

  const loadHotspots = useMemo(
    () => getMediaTypeFromUrn(urn) === MEDIA_TYPES[MediaType.PanoramicPhoto].type,
    [urn],
  );

  const addPanoHotspot = useCallback((id, direction, zOrder) => {
    const krpano = krpanoRef.current.instance;

    krpano.call(`addhotspot(${id})`); // Create the hotspot
    // Set an attribute on the new hotspot
    const setAttribute = (key, value) => {
      krpano.set(`hotspot[${id}].${key}`, value);
    };
    // Set attributes: https://krpano.com/docu/xml/#hotspot
    setAttribute('type', 'image');
    setAttribute('direction', direction);
    setAttribute('zorder', zOrder);

    krpano.call(`hotspot[${id}].loadstyle('streetview_hs')`); // Load hotspot style

    // May need modification for krpano var calculation (orientation isn't loading in right now)
    setAttribute(
      'onclick',
      `js(${krpanoRef.current.globalCallbacksVar}.handleHotspotClick(${id}, get(view.hlookat), get(view.orientation)))`,
    );
    setAttribute('onover', `js(${krpanoRef.current.globalCallbacksVar}.onHotspotOver(${id}))`);
    setAttribute('onout', `js(${krpanoRef.current.globalCallbacksVar}.onHotspotOut(${id}))`);
  }, []);

  const handlePreLoadPano = useCallback(() => {
    if (!loadHotspots) return;

    // Reset flags
    setHotspotConfigLoaded(false);
    setPanoLoaded(false);
    setHotspotsLoaded(false);

    // Load hotspot config xml
    krpanoRef.current.instance.call(
      `loadpano(/krpano/streetview-hotspots.xml, null, MERGE|KEEPVIEW|KEEPHOTSPOTS|KEEPSCENES|KEEPIMAGE, null, js(${krpanoRef.current.globalCallbacksVar}.onHotspotConfigLoaded())))`,
    );
  }, [loadHotspots, setHotspotConfigLoaded, setPanoLoaded, setHotspotsLoaded]);

  const handlePanoLoaded = useCallback(() => {
    setPanoLoaded(true);
  }, [setPanoLoaded]);

  const onHotspotConfigLoaded = useCallback(() => {
    krpanoRef.current.instance.set('events.onviewchanged', 'update_hs();'); // This is needed for the hotspots to transform with the view change
    setHotspotConfigLoaded(true);
  }, [setHotspotConfigLoaded]);

  const handleHotspotClick = useCallback(
    (id, lookat, orientation) => {
      if (onHotspotClick) {
        onHotspotClick(id, lookat, orientation);
      }
    },
    [onHotspotClick],
  );

  const onHotspotOver = useCallback(
    id => {
      if (onHotspotHoverStart) {
        onHotspotHoverStart(id);
      }
    },
    [onHotspotHoverStart],
  );

  const onHotspotOut = useCallback(
    id => {
      if (onHotspotHoverStop) {
        onHotspotHoverStop(id);
      }
    },
    [onHotspotHoverStop],
  );

  const krpanoCallbacks = useMemo(
    () => ({
      onHotspotConfigLoaded,
      handleHotspotClick,
      onHotspotOver,
      onHotspotOut,
    }),
    [onHotspotConfigLoaded, handleHotspotClick, onHotspotOver, onHotspotOut],
  );

  useEffect(() => {
    if (!loadHotspots || !hotspotConfigLoaded || !panoLoaded) return;

    if (!hotspotsLoaded && neighbors) {
      // Hotspots for current neighbors have not been loaded
      neighbors?.forEach((n, i) => {
        addPanoHotspot(n.id, n.direction, i);
      });

      setHotspotsLoaded(true);
    }
  }, [
    loadHotspots,
    neighbors,
    hotspotConfigLoaded,
    hotspotsLoaded,
    setHotspotsLoaded,
    addPanoHotspot,
    panoLoaded,
  ]);

  return (
    <KRPanoViewer
      xmlUrl={xmlUrl}
      width={width}
      height={height}
      ref={krpanoRef}
      onPreLoadPano={handlePreLoadPano}
      onPanoLoaded={handlePanoLoaded}
      krpanoCallbacks={krpanoCallbacks}
      {...rest}
    />
  );
};

KRPanoMediaLoader.propTypes = {
  urn: PropTypes.string, // Example: urn:immersiondata:media:panorama:199650
  neighbors: PropTypes.arrayOf(PropTypes.object),
};

export default KRPanoMediaLoader;
