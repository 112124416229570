import React from 'react';
import PropTypes from 'prop-types';

import IdsDialog from '../../../components/IdsDialog';

import CreateLocationForm from './CreateLocationForm';

function CreateLocationDialog({ open, onOpen, onClose }) {
  return (
    <>
      <IdsDialog
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        title='Create New Location'
        dialogKey='create'
        maxWidth='lg'
      >
        <CreateLocationForm onSubmitSuccess={onClose} onCancel={onClose} />
      </IdsDialog>
    </>
  );
}

CreateLocationDialog.defaultProps = {
  open: false,
};

CreateLocationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default CreateLocationDialog;
