import { atom } from 'recoil';

export type ActiveLocation = {
  id: string;
  name: string;
} | null;

export const activeLocationState = atom<ActiveLocation>({
  key: 'activeLocationState',
  default: null,
});

// Active Location Assignment
interface IActiveLocationAssignmentState {
  id: string;
  title: string;
}

export const activeLocationAssignmentState = atom<IActiveLocationAssignmentState | null>({
  key: 'activeLocationAssignmentState',
  default: null,
});

// Active Location Assignment Response
interface IActiveLocationAssignmentResponseState {
  id: string;
}

export const activeLocationAssignmentResponseState =
  atom<IActiveLocationAssignmentResponseState | null>({
    key: 'activeLocationAssignmentResponseState',
    default: null,
  });
