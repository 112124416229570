import { gql } from 'graphql-request';

const MediaCommentFrag = gql`
  fragment MediaCommentFrag on MediaComment {
    id
    parentId
    createdAt
    updatedAt
    creator {
      id
      firstName
      lastName
    }
    text
    deleted
  }
`;

export default MediaCommentFrag;
