import React, { useCallback, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  Typography,
  Tooltip,
} from '@mui/material';

import useDeleteDocumentOrVersionAction from '../useDeleteDocumentOrVersionAction';
import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import IdsListItemMenu from '../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../components/ids-lists/IdsListItemMenuItem';

import {
  ExportIcon,
  EditOutlineIcon,
  DeleteOutlineIcon,
  DocumentIcon,
  AddDocument,
} from '../../../theme/icons';

import { downloadFileFromUrl } from '../../../utils/fileDownload';
import { IDocument, IDocumentVersion } from '../types';

import DocumentView from '../DocumentView';
import IdsSwitch from '../../../components/ids-inputs/IdsSwitch';
import { getDocumentCategory } from '../utils';

import { DocumentParentTypes } from '../types';
import ProgramChip from '../../../components/entity-chips/ProgramChip';
import ProjectChip from '../../../components/entity-chips/ProjectChip';
import useGetDocumentLatestVersion from '../useGetDocumentLatestVersion';
import { isTextTruncated } from '../../../utils/helpers';

const AccordionSummaryStyled = styled(AccordionSummary)({
  padding: 0,
  '& > .MuiAccordionSummary-content': {
    margin: 0,
  },
});

const nameTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
  variant: 'h6',
  noWrap: true,
};
const descriptionTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
  variant: 'body2',
  noWrap: true,
};

export interface IDocumentListItemProps {
  documentItem: IDocument;
  onClick?: (document: IDocument) => any;
  onClickDocumentVersion?: (document: IDocument, documentVersion: IDocumentVersion) => any;
  onClickEdit?: (document: IDocument) => any;
  onClickEditDocumentVersion?: (document: IDocument, documentVersion: IDocumentVersion) => any;
  onClickNewDocumentVersion?: (document: IDocument) => any;

  onChangeInfoTabAccess?: (value: boolean, document: IDocument) => any;
  onChangeCaptureTabAccess?: (value: boolean, document: IDocument) => any;

  // Do component should have disabled infoTabAccess
  // and captureTabAccess switches
  switchesDisabled?: boolean;
  disableProgramDocumentEdit?: boolean;
}

const DocumentListItem: React.FC<IDocumentListItemProps> = ({
  documentItem,
  onClick,
  onClickEdit,
  onClickDocumentVersion,
  onClickEditDocumentVersion,
  onClickNewDocumentVersion,

  onChangeInfoTabAccess,
  onChangeCaptureTabAccess,

  switchesDisabled,
  disableProgramDocumentEdit,
}) => {
  const { id, name, metadata, availableInCapture, availableInInfoTab } = documentItem?.node || {};
  const { deleted, deleting, deleteDocumentOrVersion } = useDeleteDocumentOrVersionAction();

  const latestVersion = useGetDocumentLatestVersion(documentItem);

  const description = useMemo(() => getDocumentCategory(metadata)?.value, [metadata]);

  const handleNewDocumentVersionClick = useCallback(() => {
    if (onClickNewDocumentVersion) {
      onClickNewDocumentVersion(documentItem);
    }
  }, [onClickNewDocumentVersion, documentItem]);

  const downloadDocument = useCallback(() => {
    downloadFileFromUrl(latestVersion?.filename, latestVersion?.url);
  }, [latestVersion]);

  const onEdit = useCallback(() => {
    if (!documentItem) return;
    if (onClickEdit) {
      onClickEdit(documentItem);
    }
  }, [documentItem, onClickEdit]);

  const onListItemClick = useCallback(() => {
    if (onClick) {
      onClick(documentItem);
    }
  }, [documentItem, onClick]);

  const dateProps = useMemo(() => {
    if (!latestVersion) {
      return null;
    }

    return {
      primary: new Date(latestVersion.createdAt).toLocaleDateString(),
      secondary: new Date(latestVersion.createdAt).toLocaleTimeString(),
    };
  }, [latestVersion]);

  const handleInfoTabAccess = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (onChangeInfoTabAccess) {
        onChangeInfoTabAccess(event.target.checked, documentItem);
      }
    },
    [onChangeInfoTabAccess, documentItem],
  );

  const handleCaptureAccess = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (onChangeCaptureTabAccess) {
        onChangeCaptureTabAccess(event.target.checked, documentItem);
      }
    },
    [onChangeCaptureTabAccess, documentItem],
  );

  const handleDeleteDocumentOrVersion = useCallback(() => {
    return deleteDocumentOrVersion({
      documentItem,
      deleteMode: 'DOCUMENT',
    });
  }, [deleteDocumentOrVersion, documentItem]);

  const [nameTruncated, setNameTruncated] = useState(false);
  const [descriptionTruncated, setDescriptionTruncated] = useState(false);

  const handleNameTruncated = useCallback((e: HTMLSpanElement) => {
    setNameTruncated(isTextTruncated(e));
  }, []);

  const handleDescriptionTruncated = useCallback((e: HTMLSpanElement) => {
    setDescriptionTruncated(isTextTruncated(e));
  }, []);

  if (!documentItem?.node) {
    return null;
  }

  return (
    <Accordion disableGutters>
      <AccordionSummaryStyled>
        <IdsListItem
          key={id}
          primaryAction={onListItemClick}
          secondaryAction={
            <IdsListItemMenu>
              <IdsListItemMenuItem
                disabled={
                  disableProgramDocumentEdit &&
                  documentItem.node.parentType === DocumentParentTypes.PROGRAM
                }
                icon={<AddDocument />}
                onClick={handleNewDocumentVersionClick}
              >
                New version
              </IdsListItemMenuItem>
              <IdsListItemMenuItem icon={<ExportIcon />} onClick={downloadDocument}>
                Download
              </IdsListItemMenuItem>
              <IdsListItemMenuItem
                disabled={
                  disableProgramDocumentEdit &&
                  documentItem.node.parentType === DocumentParentTypes.PROGRAM
                }
                icon={<EditOutlineIcon />}
                onClick={onEdit}
              >
                Edit
              </IdsListItemMenuItem>
              <IdsListItemMenuItem
                icon={<DeleteOutlineIcon />}
                onClick={handleDeleteDocumentOrVersion}
                disabled={
                  (disableProgramDocumentEdit &&
                    documentItem.node.parentType === DocumentParentTypes.PROGRAM) ||
                  deleting ||
                  deleted
                }
              >
                Delete
              </IdsListItemMenuItem>
            </IdsListItemMenu>
          }
        >
          <IdsListItemChild container flexDirection='row'>
            <IdsListItemChild xs='auto'>
              <ListItemIcon>
                <DocumentIcon />
              </ListItemIcon>
            </IdsListItemChild>
            <IdsListItemChild>
              <Tooltip title={name} placement='top-start' disableHoverListener={!nameTruncated}>
                <ListItemText
                  primary={
                    <Typography ref={handleNameTruncated} {...nameTypographyProps}>
                      {name}
                    </Typography>
                  }
                />
              </Tooltip>
            </IdsListItemChild>
            <IdsListItemChild>
              {documentItem.node.parentType === DocumentParentTypes.PROJECT && (
                <ProjectChip projectId={documentItem.node.parentId} />
              )}
              {documentItem.node.parentType === DocumentParentTypes.PROGRAM && (
                <ProgramChip programId={documentItem.node.parentId} />
              )}
            </IdsListItemChild>
          </IdsListItemChild>
          <IdsListItemChild xs={2}>
            {description ? (
              <Tooltip
                title={description}
                placement='top'
                disableHoverListener={!descriptionTruncated}
              >
                <ListItemText
                  primary={
                    <Typography ref={handleDescriptionTruncated} {...descriptionTypographyProps}>
                      {description}
                    </Typography>
                  }
                />
              </Tooltip>
            ) : (
              <ListItemText
                primary={<Typography {...descriptionTypographyProps}>{description}</Typography>}
              />
            )}
          </IdsListItemChild>
          <IdsListItemChild xs={2}>
            <ListItemText {...dateProps} />
          </IdsListItemChild>
          <IdsListItemChild xs={2} stopPropagation>
            <IdsSwitch
              label={
                <Typography variant='body2' color='text.secondary'>
                  Info Tab Access
                </Typography>
              }
              labelPlacement='top'
              onChange={handleInfoTabAccess}
              disabled={switchesDisabled}
              checked={availableInInfoTab}
            />
          </IdsListItemChild>
          <IdsListItemChild xs={2} stopPropagation>
            <IdsSwitch
              label={
                <Typography variant='body2' color='text.secondary'>
                  Capture Access
                </Typography>
              }
              labelPlacement='top'
              onChange={handleCaptureAccess}
              disabled={switchesDisabled}
              checked={availableInCapture}
            />
          </IdsListItemChild>
        </IdsListItem>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <DocumentView
          document={documentItem}
          onClickDocumentVersion={onClickDocumentVersion}
          onClickEditDocumentVersion={onClickEditDocumentVersion}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default DocumentListItem;
