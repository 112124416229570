import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { activeImageId, mediaViewerOpen } from '../../../atoms/mediaViewer';
import { activeOrganizationState } from '../../../atoms/organizations';
import { activeTenantState } from '../../../atoms/tenants';

import { MEDIA_VIEWER_MODES } from '../../../constants/mediaViewer';
import useMounted from '../../../hooks/useMounted';
import usePrevious from '../../../hooks/usePrevious';

import ResizeControl from '../../ResizeControl';
import KRPanoControl from '../KRPanoControl';
import KRPanoMediaLoader from '../KRPanoMediaLoader';
import { closeKRPano } from '../KRPanoViewer';

import NavigationArrows from './arrows';

import ButtonsBar from './ButtonsBar';
import { useImageDetails } from './hooks';
import styles from './IdsMediaViewer.module.css';
import MediaViewerInfoTab from './MediaViewerInfoTab';
import MediaViewerMediaTab from './MediaViewerMediaTab';
import MediaViewerTabs, { TAB_SIZES } from './MediaViewerTabs';

export const MEDIA_PARAM_KEY = 'media';

export const MEDIA_VIEWER_WIDTHS = {
  ONEQUARTER: 'oneQuarter',
  HALF: 'half',
  THREEQUARTER: 'threeQuarter',
  FULL: 'full',
};

export const MEDIA_VIEWER_WIDTH_TO_CSS = {
  [MEDIA_VIEWER_WIDTHS.ONEQUARTER]: '25vw',
  [MEDIA_VIEWER_WIDTHS.HALF]: '50vw',
  [MEDIA_VIEWER_WIDTHS.THREEQUARTER]: '75vw',
  [MEDIA_VIEWER_WIDTHS.FULL]: '100vw',
};

export const MEDIA_VIEWER_WIDTH_FACTORS = {
  [MEDIA_VIEWER_WIDTHS.ONEQUARTER]: 0.25,
  [MEDIA_VIEWER_WIDTHS.HALF]: 0.5,
  [MEDIA_VIEWER_WIDTHS.THREEQUARTER]: 0.75,
  [MEDIA_VIEWER_WIDTHS.FULL]: 1,
};

export const MEDIA_VIEWER_TABS = {
  MEDIA: 'media',
  INFO: 'info',
};

export const BUTTON_TYPE = {
  action: 'action',
  mode: 'mode',
};

function IdsMediaViewer({
  title,
  media,
  tenantId,
  orgId,
  activeMediaId,
  selectMediaItemData,
  onActiveMediaChange,
  onActiveMediaLoaded,
  onMediaViewerResize,
  loadActiveImageDetail,
  activeImageDetail,
  initialWidth,
  availableWidth,
  onClose,
  onDelete,
  onFlagUpdate,
  onImageInfoUpdate,
  onTagsUpdate,
  hideClose,
  hideContextMenu,
  hideFlag,
  hideDelete,
  hideEdit,
  disableFlag,
  fullscreen,
  mode,
  tabs,
  hideFullScreen,
  setActiveMediaAndHighlightOnMap,
  unhighlightMediaMarker,
  highlightMediaMarker,
  thumbnailCount,
  loadAllThumbnails,
  noImageMessage,
  open,
  heightMediaLoader,
  modal,
  allImageTags,
  actionButtons,
  resizingDisabled = false,
  ...rest
}) {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const [activeId, setActiveId] = useRecoilState(activeImageId);
  const [, setIsOpen] = useRecoilState(mediaViewerOpen);
  const prevActiveMediaId = usePrevious(activeId);
  const [activeMediaIndex, setActiveMediaIndex] = useState();
  const [viewerWidth, setViewerWidth] = useState(null);
  const [tabSize, setTabSize] = useState(TAB_SIZES.RIBBON);
  const [activeTabKey, setActiveTabKey] = useState(tabs?.length && tabs[0]);
  const [viewerConfiguration, setViewerConfiguration] = useState(null);
  const [viewerFullScreen, setViewerFullscreen] = useState(fullscreen);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [searchParams, setSearchParams] = useSearchParams();
  const mediaParam = useMemo(() => searchParams?.get(MEDIA_PARAM_KEY), [searchParams]);
  const mounted = useMounted();
  const wasMounted = usePrevious(mounted.current);

  const mediaTenantId = tenantId || activeTenant?.id;
  const mediaOrgId = orgId || activeOrg?.id;

  const [isInfoTabEditMode, setIsInfoTabEditMode] = useState(null);
  useEffect(() => {
    if (!tabs?.length || isInfoTabEditMode === null) {
      return;
    }

    if (isInfoTabEditMode && !viewerConfiguration && tabs.includes(MEDIA_VIEWER_TABS.INFO)) {
      /**
       * Save media viewer state before entering edit mode to re-apply it after closing the edit mode.
       */
      setViewerConfiguration({ activeTabKey, tabSize, viewerWidth });

      /**
       * Expand media viewer in case of entering into edit mode.
       */
      setActiveTabKey(MEDIA_VIEWER_TABS.INFO);
      setViewerWidth(MEDIA_VIEWER_WIDTHS.THREEQUARTER);
      setTabSize(TAB_SIZES.FULL);
    } else if (!isInfoTabEditMode && viewerConfiguration) {
      /**
       * Edit mode was closed. Re-apply previous media viewer state.
       */
      setActiveTabKey(viewerConfiguration.activeTabKey);
      setViewerWidth(viewerConfiguration.viewerWidth);
      setTabSize(viewerConfiguration.tabSize);

      setViewerConfiguration(null);
    }
  }, [isInfoTabEditMode, tabs, activeTabKey, tabSize, viewerWidth, viewerConfiguration]);

  // ////// Memos
  const _media = useMemo(() => {
    const transformMediaItem = item => {
      const itemData = selectMediaItemData(item);
      return {
        ...item,
        id: itemData.id,
        previewUrl: itemData.reps?.find(rep => rep.name === 'small')?.url,
        flagged: itemData.flagged,
      };
    };

    if (loadAllThumbnails || media.length < thumbnailCount * 2) {
      return media.map(transformMediaItem);
    } else {
      // load at most 2*thumbnailCount (each side of the active image)
      const activeIndex = media.findIndex(image => selectMediaItemData(image).id === activeId);
      const sliceStart =
        activeIndex >= thumbnailCount
          ? activeIndex -
            (media.length - activeIndex < thumbnailCount
              ? // When media.length - activeIndex is less than thumbnail count, the sliceStart needs to be pushed out to compensate for the lack of thumbnails after it
                thumbnailCount - (media.length - activeIndex) + thumbnailCount
              : thumbnailCount)
          : 0;
      const sliceEnd =
        media.length - activeIndex > thumbnailCount
          ? activeIndex +
            (activeIndex < thumbnailCount
              ? // When activeIndex is less than thumbnail count, the sliceEnd needs to be pushed out to compensate for the lack of thumbnails before it
                thumbnailCount - activeIndex + thumbnailCount
              : thumbnailCount)
          : media.length;

      const slicedMedia = media.slice(sliceStart, sliceEnd);
      return slicedMedia.map(transformMediaItem);
    }
  }, [loadAllThumbnails, media, selectMediaItemData, thumbnailCount, activeId]);

  const activeImage = useMemo(() => {
    if (!activeId) return null;
    const activeImageItem = _media.find(m => selectMediaItemData(m).id === activeId);
    return activeImageItem ? selectMediaItemData(activeImageItem) : null;
  }, [activeId, _media, selectMediaItemData]);

  // ////// Callbacks
  const handleMediaParamChange = useCallback(
    mediaId => {
      if (onActiveMediaChange) {
        onActiveMediaChange(mediaId);
      }
      setActiveId(mediaId);
    },
    [onActiveMediaChange, setActiveId],
  );

  const handleActiveMediaIdChange = useCallback(() => {
    if ((activeId && !mediaParam) || activeId !== prevActiveMediaId) {
      setActiveMediaIndex(_media.findIndex(item => item.id === activeId));

      // Update the media param in the url
      if (mediaParam !== activeId) {
        searchParams?.set(MEDIA_PARAM_KEY, activeId);
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [
    _media,
    setActiveMediaIndex,
    activeId,
    searchParams,
    setSearchParams,
    mediaParam,
    prevActiveMediaId,
  ]);

  const close = useCallback(() => {
    searchParams?.delete(MEDIA_PARAM_KEY);
    setSearchParams(searchParams, { replace: true });
    setIsOpen(false);
    closeKRPano();

    setActiveId(null);

    if (onClose) {
      onClose();
    }
  }, [searchParams, setSearchParams, setIsOpen, onClose, setActiveId]);

  const selectImage = useCallback(
    mediaId => {
      if (activeId && unhighlightMediaMarker) {
        unhighlightMediaMarker(activeId);
      }

      setActiveId(mediaId);

      if (setActiveMediaAndHighlightOnMap) {
        setActiveMediaAndHighlightOnMap(mediaId);
      }
    },
    [activeId, unhighlightMediaMarker, setActiveId, setActiveMediaAndHighlightOnMap],
  );

  const changeViewerWidth = useCallback(
    grow => {
      if (!grow) {
        if (viewerWidth === MEDIA_VIEWER_WIDTHS.THREEQUARTER) {
          setViewerWidth(MEDIA_VIEWER_WIDTHS.HALF);
        } else if (viewerWidth === MEDIA_VIEWER_WIDTHS.HALF) {
          setViewerWidth(MEDIA_VIEWER_WIDTHS.ONEQUARTER);
        }
      } else {
        if (viewerWidth === MEDIA_VIEWER_WIDTHS.HALF) {
          setViewerWidth(MEDIA_VIEWER_WIDTHS.THREEQUARTER);
        } else if (viewerWidth === MEDIA_VIEWER_WIDTHS.ONEQUARTER) {
          setViewerWidth(MEDIA_VIEWER_WIDTHS.HALF);
        }
      }
    },
    [viewerWidth, setViewerWidth],
  );

  const handleViewerResize = useCallback(
    (width, height) => {
      setDimensions({ width, height });

      if (onMediaViewerResize) {
        onMediaViewerResize(width, height);
      }
    },
    [setDimensions, onMediaViewerResize],
  );

  const handleHotspotHoverStop = useCallback(
    mediaId => {
      if (activeId !== mediaId) {
        unhighlightMediaMarker(mediaId);
      }
    },
    [unhighlightMediaMarker, activeId],
  );

  // ////// Effects

  useEffect(() => {
    setActiveMediaIndex(_media.findIndex(item => item.id === activeId));
  }, [_media, activeId]);

  useEffect(() => {
    if (!activeId && mediaParam) {
      handleMediaParamChange(mediaParam);
    }

    if (initialWidth) {
      setViewerWidth(initialWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once, notify consumer that mediaParam is set

  useEffect(() => {
    // Don't run on mount, allow mount effect to handle loading mediaParam
    if (!activeId && mediaParam && wasMounted) {
      close();
      return;
    }

    handleActiveMediaIdChange();
  }, [activeId, mediaParam, close, handleActiveMediaIdChange, wasMounted]);

  useEffect(() => {
    if (open) {
      setIsOpen(open);
    }
  }, [open, setIsOpen]);

  useEffect(() => {
    if (activeMediaId) setActiveId(activeMediaId);
  }, [activeMediaId, setActiveId]);

  const {
    data: activeImageDetailData,
    isRefetching,
    isLoading,
  } = useImageDetails(
    activeId ? activeId : activeMediaId,
    mediaTenantId,
    mode === MEDIA_VIEWER_MODES.IMMERSIVE,
    tabs.includes(MEDIA_VIEWER_TABS.INFO),
    // Don't load image details internally if loadActiveImageDetail is false, details will be loaded externally
    loadActiveImageDetail && !!mediaTenantId,
  );

  const _activeImageDetail = useMemo(
    () => (loadActiveImageDetail ? activeImageDetailData?.image : activeImageDetail?.image),
    [loadActiveImageDetail, activeImageDetail, activeImageDetailData],
  );
  const fullActiveImage = useMemo(
    () => ({
      ...activeImage,
      ..._activeImageDetail,
    }),
    [activeImage, _activeImageDetail],
  );

  /**
   * Leave only those neighbors that match current filters.
   * Neighbor image matches current filters if its ID is present in the media array.
   */
  const neighbors = useMemo(() => {
    return _activeImageDetail?.neighbors?.filter(neighbor => {
      return _media.some(mediaItem => selectMediaItemData(mediaItem).id === neighbor.id);
    });
  }, [_activeImageDetail, _media, selectMediaItemData]);

  useEffect(() => {
    if (onActiveMediaLoaded && fullActiveImage) {
      onActiveMediaLoaded(fullActiveImage);
    }
  }, [fullActiveImage, onActiveMediaLoaded]);

  useEffect(() => {
    const newMediaParam = searchParams.get(MEDIA_PARAM_KEY);
    if (mediaParam && newMediaParam !== mediaParam && newMediaParam !== 'null') {
      handleMediaParamChange(newMediaParam);
    }
  }, [searchParams, mediaParam, handleMediaParamChange]);

  useEffect(() => {
    return function () {
      closeKRPano(); // Ensure krpano is closed on unmount
      setIsOpen(false); // ensure recoil state is set to hide the modal
    };
  }, [setIsOpen]);

  const handleOnDelete = useCallback(
    id => {
      if (!hideDelete) {
        onDelete(id);
      }

      if (!hideClose) {
        close();
      }
    },
    [close, onDelete, hideDelete, hideClose],
  );

  const buttonsBar = (
    <ButtonsBar
      hideFlag={hideFlag}
      disableFlag={disableFlag || isRefetching || isLoading}
      hideDelete={hideDelete}
      hideEdit={hideEdit}
      hideContextMenu={hideContextMenu}
      hideClose={hideClose}
      hideFullScreen={hideFullScreen}
      viewerFullScreen={viewerFullScreen}
      setViewerFullscreen={setViewerFullscreen}
      isInfoTabEditMode={isInfoTabEditMode}
      setIsInfoTabEditMode={setIsInfoTabEditMode}
      activeImage={activeImage}
      fullActiveImage={fullActiveImage}
      activeId={activeId}
      activeMediaId={activeMediaId}
      actionButtons={actionButtons}
      close={close}
      onDelete={handleOnDelete}
      onFlagUpdate={onFlagUpdate}
    />
  );

  const renderMediaTab = useCallback(
    (tabSize, cssHeight) => (
      <MediaViewerMediaTab
        media={_media}
        tenantId={mediaTenantId}
        selectImage={selectImage}
        unhighlightMediaMarker={unhighlightMediaMarker}
        highlightMediaMarker={highlightMediaMarker}
        mode={mode}
        tabSize={tabSize}
        height={cssHeight}
      />
    ),
    [_media, selectImage, unhighlightMediaMarker, highlightMediaMarker, mode, mediaTenantId],
  );

  const renderInfoTab = useCallback(
    (tabSize, cssHeight) => (
      <MediaViewerInfoTab
        onTagsUpdate={onTagsUpdate}
        onImageInfoUpdate={onImageInfoUpdate}
        orgId={mediaOrgId}
        tenantId={tenantId}
        activeImage={fullActiveImage}
        tabSize={tabSize}
        height={cssHeight}
        allImageTags={allImageTags}
        isEditMode={isInfoTabEditMode}
        toggleEditMode={() => setIsInfoTabEditMode(prev => !prev)}
      />
    ),
    [
      fullActiveImage,
      allImageTags,
      isInfoTabEditMode,
      tenantId,
      mediaOrgId,
      onImageInfoUpdate,
      onTagsUpdate,
    ],
  );

  const _tabs = useMemo(
    () => [
      {
        key: MEDIA_VIEWER_TABS.MEDIA,
        label: 'Media',
        render: renderMediaTab,
        disabled: !tabs.includes(MEDIA_VIEWER_TABS.MEDIA) || !_media.length,
      },
      {
        key: MEDIA_VIEWER_TABS.INFO,
        label: 'Info',
        render: renderInfoTab,
        disabled: !tabs.includes(MEDIA_VIEWER_TABS.INFO) || !activeImage,
      },
    ],
    [renderMediaTab, renderInfoTab, tabs, activeImage, _media.length],
  );

  const navigationArrows = useMemo(
    () =>
      tabSize !== TAB_SIZES.FULL && (
        <NavigationArrows
          tabSize={tabSize}
          mediaViewerTitle={title}
          items={_media}
          activeMediaIndex={activeMediaIndex}
          select={selectImage}
          activeId={activeId ? activeId : null}
          neighbors={neighbors}
          immersiveMode={mode === MEDIA_VIEWER_MODES.IMMERSIVE}
          highlightMediaMarker={highlightMediaMarker}
          unhighlightMediaMarker={unhighlightMediaMarker}
        />
      ),
    [
      title,
      tabSize,
      _media,
      activeId,
      activeMediaIndex,
      highlightMediaMarker,
      mode,
      neighbors,
      selectImage,
      unhighlightMediaMarker,
    ],
  );

  const mediaViewer = useMemo(
    () => (
      <Box role='container' className={styles.controlsContainer}>
        {!resizingDisabled && !viewerFullScreen && mode === MEDIA_VIEWER_MODES.IMMERSIVE && (
          <KRPanoControl>
            <ResizeControl
              onGrow={() => changeViewerWidth(true)}
              canGrow={viewerWidth !== MEDIA_VIEWER_WIDTHS.THREEQUARTER}
              onShrink={() => changeViewerWidth(false)}
              canShrink={viewerWidth !== MEDIA_VIEWER_WIDTHS.ONEQUARTER}
              className={styles.resizeControl}
            />
          </KRPanoControl>
        )}

        <Grid
          container
          direction='column'
          className={clsx(
            styles.ribbonAndArrowsContainer,
            viewerFullScreen
              ? styles.ribbonAndArrowsContainerFullscreen
              : styles.ribbonAndArrowsContainerPartialScreen,
          )}
        >
          <Grid
            item
            xs
            container
            direction='column'
            justifyContent='center'
            align-items='space-between'
            className={styles.arrowsContainer}
          >
            {activeImage && !modal && navigationArrows}
          </Grid>

          <Grid item xs='auto' className={styles.tabsContainer}>
            <MediaViewerTabs
              activeTabKey={activeTabKey}
              onTabChange={setActiveTabKey}
              onTabResize={setTabSize}
              viewerHeight={dimensions.height}
              tabs={_tabs}
              tabSize={tabSize}
            />
          </Grid>
        </Grid>
      </Box>
    ),
    [
      viewerFullScreen,
      mode,
      changeViewerWidth,
      viewerWidth,
      activeImage,
      navigationArrows,
      activeTabKey,
      setActiveTabKey,
      dimensions.height,
      _tabs,
      tabSize,
      modal,
      resizingDisabled,
    ],
  );

  return (
    <KRPanoMediaLoader
      urn={activeId && activeImage ? activeId : null}
      noXMLMessage={noImageMessage}
      neighbors={neighbors}
      onHotspotHoverStart={highlightMediaMarker}
      onHotspotHoverStop={handleHotspotHoverStop}
      onHotspotClick={selectImage}
      width={
        modal
          ? MEDIA_VIEWER_WIDTH_TO_CSS[viewerWidth]
          : `${MEDIA_VIEWER_WIDTH_FACTORS[viewerWidth] * availableWidth}px`
      }
      height={heightMediaLoader || 'calc(100% - 21px)'}
      onResize={handleViewerResize}
      fullscreen={viewerFullScreen}
      onClose={close}
      modal={modal}
      {...rest}
    >
      {buttonsBar}
      {mediaViewer}
    </KRPanoMediaLoader>
  );
}

IdsMediaViewer.defaultProps = {
  mode: MEDIA_VIEWER_MODES.GALLERY,
  selectMediaItemData: m => m.node,
  hideClose: false,
  hideFlag: true,
  disableFlag: false,
  fullscreen: false,
  hideFullScreen: false,
  initialWidth: MEDIA_VIEWER_WIDTHS.FULL,
  tabs: [MEDIA_VIEWER_TABS.MEDIA],
  noImageMessage: 'There is no image selected.',
  loadActiveImageDetail: true,
  modal: false,
  allImageTags: null,
};

IdsMediaViewer.propTypes = {
  tenantId: PropTypes.string,
  orgId: PropTypes.string,
  title: PropTypes.string,
  media: PropTypes.array.isRequired,
  activeMediaId: PropTypes.string, // Expects the urn format. Example: 'urn:immersiondata:media:panorama:199648'
  selectMediaItemData: PropTypes.func,
  onActiveMediaChange: PropTypes.func, // (mediaId) => {} Expected to set the activeMediaId prop
  onActiveMediaLoaded: PropTypes.func, // (fullActiveImage) => {}
  onMediaViewerResize: PropTypes.func, // (width, height) => {}
  loadActiveImageDetail: PropTypes.bool,
  activeImageDetail: PropTypes.shape({ image: PropTypes.object }),
  initialWidth: PropTypes.string, // full, half, oneQuarter, threeQuarter
  availableWidth: PropTypes.number, // Available width in pixels for the media viewer to fill
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onFlagUpdate: PropTypes.func,
  onImageInfoUpdate: PropTypes.func,
  onTagsUpdate: PropTypes.func,
  hideClose: PropTypes.bool,
  hideFlag: PropTypes.bool,
  hideDelete: PropTypes.bool,
  disableFlag: PropTypes.bool,
  fullscreen: PropTypes.bool,
  hideFullScreen: PropTypes.bool,
  mode: PropTypes.string, // options: gallery or immersive
  tabs: PropTypes.array, // array of tabs keys to show from MEDIA_VIEWER_TABS
  thumbnailCount: PropTypes.number, // The number of thumbnails to show at one time in the media viewer media tabs from the whole media list
  loadAllThumbnails: PropTypes.bool,
  noImageMessage: PropTypes.string,
  open: PropTypes.bool,
  modal: PropTypes.bool,

  // This is for info tab -> image tags
  allImageTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),

  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
};

export default IdsMediaViewer;
