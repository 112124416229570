import React from 'react';

import { Container } from '@mui/material';

import { useRecoilValue } from 'recoil';

import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import ComingSoonRoute from '../../../components/ComingSoonRoute';
import { activeOrganizationState } from '../../../atoms/organizations';
import { activeLocationState } from '../../../atoms/locations';
import { activeTenantState } from '../../../atoms/tenants';
import { getOrgLocationRoute } from '../../../utils/routes';

function LocationCopy() {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);

  return (
    <Page title='Settings'>
      <Container maxWidth={false}>
        <NestedOrgViewHeader
          breadcrumbs={[
            {
              label: activeLocation.name,
              route: getOrgLocationRoute({
                subdomain: activeTenant?.subdomain,
                orgId: activeOrg.id,
                locationId: activeLocation.id,
              }),
            },
          ]}
          currentCrumb='Copy Location'
        />
        <ComingSoonRoute
          legacyFeatureRoute={`locations/${activeLocation.id}/copy`}
          defaultOpen={true}
        />
      </Container>
    </Page>
  );
}

export default LocationCopy;
