import React from 'react';
import { Box, Card, IconButton } from '@mui/material';

import { CloseIcon } from '../../theme/icons';

const styles = {
  card: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 8,
    boxShadow: 'none',
    border: '1px solid #ccc',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    display: 'block',
  },
  content: {
    margin: 0,
    padding: 0,
  },
};

const InfoBox = ({ title, content, icon, color = '#ccc', sx, onClose, ...props }) => {
  const _sx = {
    borderColor: color,
    marginTop: 4, // Allows for override
    marginBottom: 4, // Allows for override
    ...sx,
  };
  return (
    <Card style={styles.card} pl={4} sx={_sx} {...props}>
      <Box sx={styles.container}>
        {icon}
        <Box pl={2}>
          {title && <strong style={styles.title}>{title}</strong>}
          {typeof content === 'string' ? <p style={styles.content}>{content}</p> : content}
        </Box>
      </Box>
      {typeof onClose === 'function' && (
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      )}
    </Card>
  );
};

export default InfoBox;
