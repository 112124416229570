import { useCallback, useEffect, useState, useRef } from 'react';

import { UseQueryResult } from 'react-query';

import usePrevious from './usePrevious';

export interface IEdgeWithCursor<T = any> {
  node: T;
  cursor: string;
}

export interface ISelectLocationFn<T> {
  (data: any): { edges: T[] };
}

export interface IUseCursorPaginatedQueryOptions<T extends IEdgeWithCursor<T>> {
  initialData?: T[];
  useQuery: (take: number, after: string | null) => UseQueryResult<any, any>;
  defaultTake: number;
  selectConnection: ISelectLocationFn<T>;
  onPageLoad?: (edges: T[]) => any;
}

const useCursorPaginatedQuery = <T extends IEdgeWithCursor>({
  initialData,
  useQuery,
  defaultTake,
  selectConnection,
  onPageLoad,
}: IUseCursorPaginatedQueryOptions<T>) => {
  const [lastPageProcessed, setLastPageProcessed] = useState<string | undefined | null>();
  const [loadedData, setLoadedData] = useState(initialData?.length ? initialData : []);
  const [after, setAfter] = useState(
    initialData && initialData.length > 0 ? initialData[initialData.length - 1].cursor : null,
  );
  const [doneLoading, setDoneLoading] = useState(false);
  const postLoadPageReset = useRef(false);

  const { data: page, error, isLoading, isRefetching } = useQuery(defaultTake, after);
  const prevLoading = usePrevious(isLoading);
  const prevRefetching = usePrevious(isRefetching);
  const prevPage = usePrevious(page);

  const getPageEdges = useCallback(
    page => {
      const connection = selectConnection(page);
      return connection?.edges || [];
    },
    [selectConnection],
  );

  const handlePageLoad = useCallback(
    pageData => {
      const edges = getPageEdges(pageData);

      const newData = [...loadedData, ...edges];
      setLoadedData(newData);

      const hasMorePages = edges.length === defaultTake; // More pages

      if (hasMorePages) {
        // More data pages to load
        const lastCursor = edges[edges.length - 1].cursor;
        setAfter(lastCursor);
        setLastPageProcessed(lastCursor); // Track the last processed page
      } else {
        // No more pages
        // Reset after param to allow query
        // invalidation to invalidate first page
        // This would then cause all pages to be refetched in order
        setAfter(null);
        setDoneLoading(true);
      }

      if (onPageLoad) {
        onPageLoad(edges);
      }
    },
    [loadedData, getPageEdges, onPageLoad, setAfter, setLastPageProcessed, defaultTake],
  );

  useEffect(() => {
    if (
      doneLoading &&
      ((isRefetching && !prevRefetching) ||
        (isLoading && !prevLoading) ||
        (page !== prevPage && postLoadPageReset.current))
    ) {
      // Query was marked as done but
      // something changed and data is being refetched or
      // new data is being loaded (key change)
      setLoadedData([]);
      setLastPageProcessed(null);
      postLoadPageReset.current = false;
      setDoneLoading(false); // reset flag
    }
  }, [
    isRefetching,
    prevRefetching,
    isLoading,
    prevLoading,
    page,
    prevPage,
    doneLoading,
    setLoadedData,
    setDoneLoading,
    setLastPageProcessed,
  ]);

  useEffect(() => {
    // First page is reloaded from the cache after load finish, set flag to indicate when that happens
    // IMPORTANT: this needs to run after the reset effect to prevent that effect from being run for the page change
    if (doneLoading && page !== prevPage && !postLoadPageReset.current) {
      postLoadPageReset.current = true;
    }
  }, [doneLoading, page, prevPage]);

  useEffect(() => {
    if (doneLoading || isRefetching || !page) {
      // Only handle initial load or a refetched page
      return;
    }

    const edges = getPageEdges(page);
    const pageCursor = edges?.length > 0 ? edges[edges.length - 1].cursor : null;

    // New page loaded
    if (page && lastPageProcessed !== pageCursor) {
      handlePageLoad(page);
    }
  }, [
    isLoading,
    isRefetching,
    page,
    prevPage,
    getPageEdges,
    lastPageProcessed,
    handlePageLoad,
    setLoadedData,
    setDoneLoading,
    doneLoading,
  ]);

  return {
    data: loadedData,
    error,
    isLoading: !doneLoading,
  };
};
export default useCursorPaginatedQuery;
