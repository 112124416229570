import { Layer } from 'deck.gl/typed';

import { LAYER_TYPE } from '../../../../context/ImmersiveViewerContext';

export type DeckLayerBuilder = (metadata: any) => Layer;

export interface IIdsLayer {
  type: LAYER_TYPE;
  position: number;
  metadata: Record<string, any>;
  buildDeckLayer: DeckLayerBuilder | undefined;
  deckLayer: Layer;
  wasVisible: boolean;
  context: string;
}

export type IIdsLayerProps = Partial<Pick<IIdsLayer, 'type' | 'position' | 'metadata'>>;

/**
 * 
 * @param {*} buildDeckLayer function that takes the layer metadata and returns deck layer or the deck layer instance itself
 * @param {*} type optional: must be an ImmersiveViewerContext LAYER_TYPE
 * @param {*} position optional: number > 0 - Lower numbers render under higher numbers. Layers with position will render over those without.
 * @param {*} metadata optional: any custom metadata used to control the layer rendering
 * @returns IdsLayer object:
 * `{
      id: {
        type: '',
        position: 0,
        metadata: {},
        buildDeckLayer: (metadata) => {},
        deckLayer: {},
        wasVisible: false,
        context: '',
      }
    }`
 */
const buildIdsLayer = (
  buildDeckLayer: DeckLayerBuilder | Layer,
  options: IIdsLayerProps = {
    type: LAYER_TYPE.OTHER,
    position: 0,
    metadata: {},
  },
) => {
  const { type, position, metadata } = options;

  if (!buildDeckLayer) {
    throw new Error(
      'IdsLayer creation failed. No deck layer builder was provided to the IdsLayer.',
    );
  }

  let deckLayer: Layer;
  let _buildDeckLayer: IIdsLayer['buildDeckLayer'];
  if (typeof buildDeckLayer === 'function') {
    deckLayer = buildDeckLayer(metadata);
    _buildDeckLayer = buildDeckLayer; // Only set if it is actually a function
  } else {
    deckLayer = buildDeckLayer;
  }

  // CONFLICT: Was it correct fix?
  // let _type = type;
  // if (!Object.values(LAYER_TYPES).includes(_type)) {
  //   _type = LAYER_TYPES.OTHER;
  //   console.error('Invalid IdsLayer type provided, defaulted to LAYER_TYPES.OTHER.');
  // }
  const _type: LAYER_TYPE = type || LAYER_TYPE.OTHER;

  let _position = typeof position === 'number' ? position : 0;
  if (_position < 0) {
    _position = 0;
    // eslint-disable-next-line no-console
    console.error('Invalid IdsLayer position provided, must be > 0. Defaulted to 0.');
  }

  return {
    type: _type,
    position: _position,
    metadata,
    buildDeckLayer: _buildDeckLayer,
    deckLayer,
    wasVisible: deckLayer.props.visible,
  } as IIdsLayer;
};

export default buildIdsLayer;
