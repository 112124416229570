import { IconLayer } from '@deck.gl/layers';

import buildIdsLayer from '../IdsLayer';
import { LAYER_TYPE } from '../../../../context/ImmersiveViewerContext';
import IconClusterLayer from '../IconClusterLayer';
import IDS_COLORS from '../../../../theme/ids-colors';
import { rgbStrToArray, getLightestShadeOfColorAtSaturation } from '../../../../utils/colors';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';
import {
  projectPhotoMarkerLayerId,
  hdPhotoMarkerLayerId,
  panoramaMarkerLayerId,
  videoMarkerLayerId,
  threeDModelMarkerLayerId,
  threeDVRMarkerLayerId,
  pointOfInterestLayerId,
  assetLayerId,
} from '../../../../constants/layerIds';

import {
  getProjectPhotoMarkerIcon,
  getProjectPhotoMarkerDottedIcon,
  getHDPhotoMarkerIcon,
  getHDPhotoMarkerDottedIcon,
  getPanoramaMarkerIcon,
  getPanoramaMarkerDottedIcon,
  getVideoMarkerIcon,
  get3DModelMarkerIcon,
  get3DVRMarkerIcon,
  getPOIMarkerIcon,
  getPOIMarkerDottedIcon,
  getAssetMarkerIcon,
  getAssetMarkerDottedIcon,
  getAlignmentMarkerIcon,
} from './marker-icons';

export const white = '#ffffff';

const defaultSize = 37.5;
const highlightedSize = 45; // Size of icon when highlighted
// const highlightSize = highlightedSize * (30 / 25); // size of icon highlight
const highlightColor = white;
const iconColorSaturation = 0.25;
// const highlightIconMapping = {
//   clusterMarker: {
//     ...defaultClusterIconMapping.clusterMarker,
//     anchorY: 128 - (highlightSize * ((128 - 117.5) / 30))
//   }
// };

const MediaMarkerProps = {
  pickable: true,
  getClusterIconSize: d => 40,
  clusterRadius: 50,
  maxClusterZoom: 18,
  getTextColor: [40, 40, 40, 255],
  getHighlightSize: null,
  getHighlightColor: null,
  highlightIconMapping: null,
  getColor: null,
  iconAtlas: null,
  iconMapping: null,
};

const buildMediaMarkerLayer = (data, selectItemId, buildIconClusterProps, idsLayerProps) => {
  if (!data) {
    return null;
  }

  const buildLayer = metadata => {
    const { highlightedMediaIds, opacity } = metadata;

    const getHighlighted = d => highlightedMediaIds.includes(selectItemId(d));

    const iconClusterProps = buildIconClusterProps(getHighlighted);

    return new IconClusterLayer({
      ...MediaMarkerProps,
      getSize: d => (getHighlighted(d) ? highlightedSize : defaultSize),
      getHighlighted,
      updateTriggers: {
        // These update triggers are needed to force the getters to update
        // when highlightedMediaIds changes
        getIcon: highlightedMediaIds,
        getSize: highlightedMediaIds,
        getHighlighted: highlightedMediaIds,
        getHighlightSize: highlightedMediaIds,
        getHighlightColor: highlightedMediaIds,
      },
      ...iconClusterProps,
      data,
      opacity,
    });
  };

  return buildIdsLayer(buildLayer, {
    type: LAYER_TYPE.MEDIA_MARKER,
    ...idsLayerProps,
    metadata: {
      highlightedMediaIds: [],
      opacity: 1,
      ...idsLayerProps?.metadata,
    },
  });
};

export const capturedIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.captured,
  iconColorSaturation,
);

export const projectPhotoIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.photo_simple,
  iconColorSaturation,
);
const ProjectPhotoProps = {
  id: projectPhotoMarkerLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.photo_simple),
  getPosition: d => [
    d.node.position.longitude,
    d.node.position.latitude,
    d.node.position.altitude || 0,
  ],
};

export const buildProjectPhotoMarkerLayer = (
  projectPhotos,
  iconClusterProps,
  idsLayerProps,
  modifiers = {},
) => {
  const { getIsDotted, getDotColor, getBackgroundColor, getIconColor } = modifiers;

  return buildMediaMarkerLayer(
    projectPhotos,
    d => d.node.id,
    getHighlighted => ({
      ...ProjectPhotoProps,
      ...iconClusterProps,
      getIcon: d => {
        const isDottedMarker = getIsDotted ? getIsDotted(d) : d.node.flagged;
        const backgroundColor = getBackgroundColor
          ? getBackgroundColor(d)
          : IDS_COLORS.marker.photo_simple;

        const iconColor = getIconColor ? getIconColor(d) : projectPhotoIconColor;

        return isDottedMarker
          ? getProjectPhotoMarkerDottedIcon(
              backgroundColor,
              getHighlighted(d) ? highlightColor : iconColor,
              getDotColor ? getDotColor(d) : IDS_COLORS.marker.markerDotFlagged,
            )
          : getProjectPhotoMarkerIcon(
              backgroundColor,
              getHighlighted(d) ? highlightColor : iconColor,
            );
      },
    }),
    idsLayerProps,
  );
};

export const hdPhotoIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.photo_hd,
  iconColorSaturation,
);
const HDPhotoProps = {
  id: hdPhotoMarkerLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.photo_hd),
  getPosition: d => [
    d.node.position.longitude,
    d.node.position.latitude,
    d.node.position.altitude || 0,
  ],
};

export const buildHDPhotoMarkerLayer = (
  hdPhotos,
  iconClusterProps,
  idsLayerProps,
  modifiers = {},
) => {
  const { getIsDotted, getDotColor, getBackgroundColor, getIconColor } = modifiers;

  return buildMediaMarkerLayer(
    hdPhotos,
    d => d.node.id,
    getHighlighted => ({
      ...HDPhotoProps,
      ...iconClusterProps,
      getIcon: d => {
        const isDottedMarker = getIsDotted ? getIsDotted(d) : d.node.flagged;
        const backgroundColor = getBackgroundColor
          ? getBackgroundColor(d)
          : IDS_COLORS.marker.photo_hd;

        const iconColor = getIconColor ? getIconColor(d) : hdPhotoIconColor;

        return isDottedMarker
          ? getHDPhotoMarkerDottedIcon(
              backgroundColor,
              getHighlighted(d) ? highlightColor : iconColor,
              getDotColor ? getDotColor(d) : IDS_COLORS.marker.markerDotFlagged,
            )
          : getHDPhotoMarkerIcon(backgroundColor, getHighlighted(d) ? highlightColor : iconColor);
      },
    }),
    idsLayerProps,
  );
};

export const panoramaIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.photo_360,
  iconColorSaturation,
);
const PanoramaProps = {
  id: panoramaMarkerLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.photo_360),
  getPosition: d => [
    d.node.position.longitude,
    d.node.position.latitude,
    d.node.position.altitude || 0,
  ],
};

const MIN_OPACITY = 0.2;
const MAX_OPACITY = 1;
const OPACITY_RANGE = MAX_OPACITY - MIN_OPACITY;

export const buildPanoramaMarkerLayer = (
  panoramas,
  levelIds,
  iconClusterProps,
  idsLayerProps,
  modifiers = {},
) => {
  const opacityStep = OPACITY_RANGE / levelIds?.length; // How much opacity changes per level

  const getOpacity = d => {
    const levelId = d.node.metadata.find(
      m => m.type === MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
    )?.id;
    const opacity = levelId ? MAX_OPACITY - levelIds?.indexOf(levelId) * opacityStep : MAX_OPACITY; // lower indexes have more opacity
    return opacity;
  };

  const { getIsDotted, getDotColor, getBackgroundColor, getIconColor } = modifiers;

  return buildMediaMarkerLayer(
    panoramas,
    d => d.node.id,
    getHighlighted => ({
      ...PanoramaProps,
      ...iconClusterProps,
      getIcon: d => {
        const isDottedMarker = getIsDotted ? getIsDotted(d) : d.node.flagged;
        const dotColor = getDotColor ? getDotColor(d) : IDS_COLORS.marker.markerDotFlagged;
        const backgroundColor = getBackgroundColor
          ? getBackgroundColor(d)
          : IDS_COLORS.marker.photo_360;

        const iconColor = getIconColor ? getIconColor(d) : panoramaIconColor;

        return isDottedMarker
          ? getPanoramaMarkerDottedIcon(
              backgroundColor,
              getHighlighted(d) ? highlightColor : iconColor,
              getOpacity(d),
              dotColor,
            )
          : getPanoramaMarkerIcon(
              backgroundColor,
              getHighlighted(d) ? highlightColor : iconColor,
              getOpacity(d),
            );
      },
    }),
    idsLayerProps,
  );
};

const videoIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.video,
  iconColorSaturation,
);
const videoProps = {
  id: videoMarkerLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.video),
  getPosition: d => [d.position.longitude, d.position.latitude, d.position.altitude || 0],
};

export const buildVideoMarkerLayer = (videos, iconClusterProps, idsLayerProps) => {
  return buildMediaMarkerLayer(
    videos,
    d => d.id,
    getHighlighted => ({
      ...videoProps,
      ...iconClusterProps,
      getIcon: d =>
        getVideoMarkerIcon(
          IDS_COLORS.marker.video,
          getHighlighted(d) ? highlightColor : videoIconColor,
        ),
    }),
    idsLayerProps,
  );
};

const threeDModelIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.threeDVR,
  iconColorSaturation,
);
const threeDModelProps = {
  id: threeDModelMarkerLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.threeDModel),
  getPosition: d => [d.position.longitude, d.position.latitude, d.position.altitude || 0],
};

export const build3DModelMarkerLayer = (threeDModels, iconClusterProps, idsLayerProps) => {
  return buildMediaMarkerLayer(
    threeDModels,
    d => d.id,
    getHighlighted => ({
      ...threeDModelProps,
      ...iconClusterProps,
      getIcon: d =>
        get3DModelMarkerIcon(
          IDS_COLORS.marker.threeDModel,
          getHighlighted(d) ? highlightColor : threeDModelIconColor,
        ),
    }),
    idsLayerProps,
  );
};

const threeDVRIconColor = getLightestShadeOfColorAtSaturation(
  IDS_COLORS.marker.threeDVR,
  iconColorSaturation,
);
const ThreeDVRProps = {
  id: threeDVRMarkerLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.threeDVR),
  getPosition: d => [
    d.position.longitude,
    d.position.latitude,
    d.position.altitude || 0,
    d.position.altitude || 0,
  ],
};

export const build3DVRMarkerLayer = (threeDVRs, iconClusterProps, idsLayerProps) => {
  return buildMediaMarkerLayer(
    threeDVRs,
    d => d.id,
    getHighlighted => ({
      ...ThreeDVRProps,
      ...iconClusterProps,
      getIcon: d =>
        get3DVRMarkerIcon(
          IDS_COLORS.marker.threeDVR,
          getHighlighted(d) ? highlightColor : threeDVRIconColor,
        ),
    }),
    idsLayerProps,
  );
};

const POIProps = {
  id: pointOfInterestLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.pointOfInterest),
  getPosition: d => [
    d.node.position.longitude,
    d.node.position.latitude,
    d.node.position.altitude || 0,
  ],
};

export const buildPOIMarkerLayer = (pois, iconClusterProps, idsLayerProps) => {
  return buildMediaMarkerLayer(
    pois,
    d => d.id,
    getHighlighted => ({
      ...POIProps,
      ...iconClusterProps,
      getIcon: d =>
        d.node.flagged
          ? getPOIMarkerDottedIcon(
              d.node.color,
              getHighlighted(d)
                ? highlightColor
                : getLightestShadeOfColorAtSaturation(d.node.color, iconColorSaturation),
              IDS_COLORS.marker.markerDotFlagged,
            )
          : getPOIMarkerIcon(
              d.node.color,
              getHighlighted(d)
                ? highlightColor
                : getLightestShadeOfColorAtSaturation(d.node.color, iconColorSaturation),
            ),
    }),
    idsLayerProps,
  );
};

const AssetProps = {
  id: assetLayerId,
  getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.asset),
  getPosition: d => [
    d.node.position.longitude,
    d.node.position.latitude,
    d.node.position.altitude || 0,
  ],
};

export const buildAssetMarkerLayer = (assets, iconClusterProps, idsLayerProps) => {
  return buildMediaMarkerLayer(
    assets,
    d => d.id,
    getHighlighted => ({
      ...AssetProps,
      ...iconClusterProps,
      getIcon: d => {
        const iconColor = getHighlighted(d)
          ? highlightColor
          : getLightestShadeOfColorAtSaturation(d.node.color, iconColorSaturation);

        if (d.node.flagged) {
          return getAssetMarkerDottedIcon(
            d.node.color,
            iconColor,
            IDS_COLORS.marker.markerDotFlagged,
          );
        }

        return getAssetMarkerIcon(d.node.color, iconColor);
      },
    }),
    idsLayerProps,
  );
};

export const buildActiveMediaMarkerLayer = (iconProps, idsLayerProps) => {
  const buildLayer = metadata => {
    const { activeMedia } = metadata;

    return new IconLayer({
      id: 'active-media-markers',
      data: activeMedia,
      // Default to 0.1 altitude to ensure it appears over other icons
      getPosition: d => [d.position.longitude, d.position.latitude, d.position.altitude || 0.1],
      iconAtlas: '/static/icons/iguy.png',
      iconMapping: {
        activeMediaMarker: {
          x: 0,
          y: 0,
          width: 24,
          height: 30,
          anchorY: 30,
        },
      },
      getIcon: d => 'activeMediaMarker',
      getSize: 25,
      ...iconProps,
    });
  };

  return buildIdsLayer(buildLayer, {
    ...idsLayerProps,
    type: LAYER_TYPE.MEDIA_MARKER,
    metadata: {
      activeMedia: [],
      ...idsLayerProps?.metadata,
    },
  });
};

const alignmentMarkerColor = 'rgb(72, 196, 241)';

export const buildRasterOverlayAlignmentMarkerLayer = (markers, iconProps, idsLayerProps) => {
  const iconLayer = new IconLayer({
    id: 'raster-overlay-alignment-markers',
    data: markers,
    getPosition: d => [d.position.longitude, d.position.latitude],
    getSize: highlightedSize,
    getIcon: d => getAlignmentMarkerIcon(alignmentMarkerColor, highlightColor),
    ...iconProps,
  });

  return buildIdsLayer(iconLayer, {
    ...idsLayerProps,
    type: LAYER_TYPE.TOOL,
    metadata: {
      activeMedia: [],
      ...idsLayerProps?.metadata,
    },
  });
};
