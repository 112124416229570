import { useEffect, useRef } from 'react';

const useUnmountedEffect = (effect: () => void) => {
  const effectRef = useRef(effect);

  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    return () => {
      effectRef.current();
    };
  }, []);
};

export default useUnmountedEffect;
