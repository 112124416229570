import React, { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';

import { useRecoilValue } from 'recoil';

import { useNavigate } from 'react-router-dom';

import IdsDeleteButton from '../../../../components/ids-buttons/IdsDeleteButton';
import IdsEditButton from '../../../../components/ids-buttons/IdsEditButton';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeProjectState } from '../../../../atoms/projects';

import useDeleteProjectAction from '../../useDeleteProjectAction';
import usePermissions from '../../../../hooks/usePermissions';
import { EntityType } from '../../../../constants/entities';
import { getOrgProjectEditRoute, getOrgProjectsRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IActionsProps {}

const Actions: React.FC<IActionsProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);
  const navigate = useNavigate();
  const { deleted, deleting, deleteProject } = useDeleteProjectAction();
  const { userHasPermission } = usePermissions();

  const canDelete = useMemo(
    () => userHasPermission('delete', EntityType.Project),
    [userHasPermission],
  );
  const canUpdate = useMemo(
    () => userHasPermission('update', EntityType.Project),
    [userHasPermission],
  );

  const onEdit = useCallback(() => {
    navigate(
      getOrgProjectEditRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        projectId: activeProject!.id,
      }),
    );
  }, [activeTenant, activeOrg.id, navigate, activeProject]);

  const handleDelete = useCallback(() => {
    deleteProject(activeProject, () => {
      // Return to projects list if successful
      navigate(
        getOrgProjectsRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
        }),
      );
    });
  }, [activeOrg.id, activeProject, activeTenant, deleteProject, navigate]);

  return (
    <>
      {canDelete && (
        <Grid item>
          <IdsDeleteButton onClick={handleDelete} disabled={deleting || deleted} />
        </Grid>
      )}
      {canUpdate && (
        <Grid item>
          <IdsEditButton onClick={onEdit} />
        </Grid>
      )}
    </>
  );
};

export default Actions;
