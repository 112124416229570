import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';
import { useRecoilValue } from 'recoil';

import { USER_ROLES, UserRole, UserRoleTypeUppercase } from '../../../constants/users';
import LoadingScreen from '../../../components/LoadingScreen';

import IdsEditForm from '../../../components/ids-forms/IdsEditForm';

import { activeTenantState } from '../../../atoms/tenants';

import { useUpdateUserRoles } from '../../../services/UsersService';

import { getTenantUsersRoute } from '../../../utils/routes';

import usePermissions from '../../../hooks/usePermissions';

import TenantAccessForm from './TenantAccessForm';
import OrganizationsAccessForm from './OrganizationsAccessForm';

import { IOrganizationsTabUserFragType } from './fragments';

export interface IOrganizationsTabProps {
  user: IOrganizationsTabUserFragType;
}

export const PREFIX = 'cards';
const ORGANIZATION_DOMAIN_TYPE = 'Organization';

export type CardType = {
  role: string;
  organization: string;
  projects: string[];
};

type NewValueType = {
  action: 'add' | 'update' | 'delete';
  roleName: string;
  organizationId: string;
  projects?: { id: string }[];
};

type InitialValuesType = {
  role: string;
  organization: string;
  projects: string[];
};

const AccessTab: React.FC<IOrganizationsTabProps> = ({ user }) => {
  const { userHasOneOfRoles } = usePermissions();

  const canModify = useMemo(
    () => userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]),
    [userHasOneOfRoles],
  );

  const activeTenant = useRecoilValue(activeTenantState);
  const navigate = useNavigate();

  const updateUserRoles = useUpdateUserRoles();

  const [initialValues, setInitialValues] = useState<{
    [PREFIX]: InitialValuesType[];
  } | null>(null);

  /**
   * Set up initial values.
   */
  useEffect(() => {
    if (!user) {
      return;
    }

    /**
     * Get only those roles, that presence in the role options dropdown.
     * Also, only organizations should be processed.
     */
    const filteredRoles = user.roles.reduce((accumulator: InitialValuesType[], role) => {
      if (role.domainType === ORGANIZATION_DOMAIN_TYPE) {
        accumulator.push({
          role: role.roleName,
          organization: role.domainId,
          projects: role.projects.map(project => project.id),
        });
      }

      return accumulator;
    }, []);

    setInitialValues({
      [PREFIX]: filteredRoles,
    });
  }, [user]);

  const onSubmit = async (values: FormikValues) => {
    const newValues = values[PREFIX].reduce((accumulator: NewValueType[], card: CardType) => {
      // Skip empty cards or legacy roles
      const role = (card.role as UserRole).toUpperCase() as UserRoleTypeUppercase;
      if (card.organization === null || !USER_ROLES[role].canAssign) {
        return accumulator;
      }

      const existingOrgModified = initialValues![PREFIX].find(
        (initialValue: InitialValuesType) => card.organization === initialValue.organization,
      );

      const payloadItem: NewValueType = {
        organizationId: card.organization,
        roleName: card.role,
        action: existingOrgModified ? 'update' : 'add',
        projects: card.role === USER_ROLES.ORG_TEAM.name ? card.projects.map(id => ({ id })) : [], // projects is required by the mutation, even as an empty array
      };

      accumulator.push(payloadItem);

      return accumulator;
    }, []);

    /**
     * Calculate new initial values.
     */
    const newInitialValues = newValues.map((newValue: NewValueType) => ({
      role: newValue.roleName,
      organization: newValue.organizationId,
      projects: newValue.projects || [],
    }));

    /**
     * Get all IDs of all organizations in the form.
     */
    const cardsOrgIds = (values[PREFIX] as CardType[])
      .map(value => value.organization)
      .filter(orgId => orgId !== null);

    /**
     * Find organizations that present in initial values,
     * but not in submitted ones. That means such organizations were deleted.
     */
    initialValues![PREFIX].filter(
      initialValue => !cardsOrgIds.includes(initialValue.organization),
    ).forEach(initialValue =>
      newValues.push({
        action: 'delete',
        organizationId: initialValue.organization,
        roleName: initialValue.role,
      }),
    );

    const result = await updateUserRoles.mutateAsync({
      id: user.id,
      ...(activeTenant?.myUserRole && { tenantId: activeTenant.id }),
      input: {
        userRolesAcrossOrganizations: newValues,
      },
    });

    setInitialValues({
      [PREFIX]: newInitialValues,
    });

    if (result?.updateUserRolesAcrossOrganizations?.errors?.length) {
      const field = result.updateUserRolesAcrossOrganizations.errors[0].field;
      const message = result.updateUserRolesAcrossOrganizations.errors[0].message;

      throw new Error(`${field} ${message}`);
    }
  };

  if (initialValues === null) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography ml={2} mt={2} variant='h4'>
          Tenant
        </Typography>

        <TenantAccessForm userId={user.id} />
      </Grid>

      <Grid item xs={12}>
        <Typography ml={2} variant='h4'>
          Organizations
        </Typography>

        <IdsEditForm
          enableReinitialize
          disableSubmitButton={!canModify}
          onCancel={() => navigate(getTenantUsersRoute({ subdomain: activeTenant!.subdomain }))}
          initialValues={initialValues}
          onSubmit={onSubmit}
          errorHandler={error => error || 'Error?'}
          successMessage='User updated'
        >
          <OrganizationsAccessForm canModify={canModify} />
        </IdsEditForm>
      </Grid>
    </Grid>
  );
};

export default AccessTab;
