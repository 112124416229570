import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  List,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';

import { CloseIcon, EditOutlineIcon, ReplyIcon, SendIcon } from '../../theme/icons';

import IdsComment, { IIdsCommentProps } from './IdsComment';
import styles from './IdsCommentsSection.module.css';
import { IComment } from './IdsComment/types';
import CommentsSectionHeader, { ICommentsSectionHeaderProps } from './CommentsSectionHeader';

const sx = {
  secondaryText: {
    color: 'text.secondary',
  },
};

const sortComments = (c1: IComment, c2: IComment) =>
  Date.parse(c1.createdAt) - Date.parse(c2.createdAt);

export interface IIdsCommentsSectionProps
  extends Pick<
    ICommentsSectionHeaderProps,
    | 'comments'
    | 'onViewAllComments'
    | 'hideViewAllComments'
    | 'onCreateComment'
    | 'hideCreateComment'
  > {
  onCreate: (commentText: string) => void;
  onReply: (commentId: string, commentText: string) => void;
  onEdit: (commentId: string, commentText: string) => void;
  onDelete: IIdsCommentProps['onDelete'];
  getCanReply: (comment: IComment) => boolean;
  getCanEdit: (comment: IComment) => boolean;
  getCanDelete: (comment: IComment) => boolean;
  autoFocusCommentInput?: boolean;
  onResize?: (height: number | undefined) => void;
  className?: string;
}

const IdsCommentsSection: React.FC<IIdsCommentsSectionProps> = ({
  comments,
  onViewAllComments,
  hideViewAllComments,
  hideCreateComment,
  onCreate,
  onReply,
  onEdit,
  onDelete,
  getCanReply,
  getCanEdit,
  getCanDelete,
  autoFocusCommentInput,
  onResize,
  className,
  ...rest
}) => {
  const handleResize = useCallback(
    (height: number | undefined) => {
      if (onResize) {
        onResize(height);
      }
    },
    [onResize],
  );
  const { ref: containerRef } = useResizeDetector({
    skipOnMount: true,
    handleWidth: false,
    onResize: handleResize,
  });
  const commentInputRef = useRef<any>();
  const [commentText, setCommentText] = useState('');
  const [replyingTo, setReplyingTo] = useState<IComment | null>(null);
  const [editing, setEditing] = useState<IComment | null>(null);

  const commentTree = useMemo(() => {
    // Comment reply structure can go N levels deep, for display purposes, this structure is flattened
    // to 1 level of replies per top level comment. A reply to a reply should be shown as a reply to the top level comment.
    // Maintaing the structure is important to support any future changes for showing more levels of replies.
    const getCommentReplies = (c1: IComment) => {
      const directReplies = comments
        .filter(c2 => c2.parentId === c1.id)
        .map(c => ({
          ...c,
          canReply: getCanReply(c),
          canEdit: getCanEdit(c),
          canDelete: getCanDelete(c),
        }));
      const allReplies = [...directReplies];
      directReplies.forEach(r => {
        allReplies.push(...getCommentReplies(r));
      });
      allReplies.sort(sortComments);
      return allReplies;
    };

    // Build a tree of comments and replies (limit to 1 level deep for display)
    const _commentTree = comments
      .filter(c => !c.parentId)
      .map(c => ({
        ...c,
        canReply: getCanReply(c),
        canEdit: getCanEdit(c),
        canDelete: getCanDelete(c),
        replies: getCommentReplies(c),
      }));
    _commentTree.sort(sortComments);
    return _commentTree;
  }, [comments, getCanReply, getCanEdit, getCanDelete]);

  const viewAllComments: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    event => {
      event.stopPropagation();

      if (onViewAllComments) {
        onViewAllComments(event);
      }
    },
    [onViewAllComments],
  );

  const startComment = useCallback(() => {
    commentInputRef.current.focus();
  }, []);

  const onCommentChange = useCallback(
    event => {
      setCommentText(event.target.value);
    },
    [setCommentText],
  );

  const removeCommentContext = useCallback(() => {
    if (replyingTo) {
      setReplyingTo(null);
    }
    if (editing) {
      setEditing(null);
    }
  }, [replyingTo, setReplyingTo, editing, setEditing]);

  const replyToComment = useCallback(
    comment => {
      removeCommentContext();
      setReplyingTo(comment);
      setCommentText('');
      commentInputRef.current.focus();
    },
    [setReplyingTo, setCommentText, removeCommentContext],
  );

  const editComment = useCallback(
    comment => {
      removeCommentContext();
      setEditing(comment);
      setCommentText(comment.text);
      commentInputRef.current.focus();
    },
    [setEditing, setCommentText, removeCommentContext],
  );

  const saveComment = useCallback(() => {
    if (replyingTo) {
      onReply(replyingTo.id, commentText);
      setReplyingTo(null);
    } else if (editing) {
      onEdit(editing.id, commentText);
      setEditing(null);
    } else {
      // creating
      onCreate(commentText);
    }

    commentInputRef.current.blur(); // Remove focus from the comment input
    setCommentText(''); // Clear the input
  }, [
    replyingTo,
    onReply,
    setReplyingTo,
    editing,
    onEdit,
    setEditing,
    onCreate,
    setCommentText,
    commentText,
  ]);

  const deleteComment = useCallback(
    comment => {
      if (onDelete) {
        onDelete(comment);
      }
    },
    [onDelete],
  );

  const inputHeader = useMemo(() => {
    if (!replyingTo && !editing) return null;

    let contextIcon, action, comment: IComment;

    if (replyingTo) {
      contextIcon = <ReplyIcon fontSize='small' sx={sx.secondaryText} />;
      action = 'Replying to';
      comment = replyingTo;
    } else {
      // editing
      contextIcon = (
        <SvgIcon className={styles.editingIcon} sx={sx.secondaryText}>
          <EditOutlineIcon />
        </SvgIcon>
      );
      action = 'Editing';
      comment = editing!;
    }

    return (
      <Grid
        container
        direction='row'
        alignItems='center'
        wrap='nowrap'
        className={styles.inputHeader}
      >
        <Grid item xs='auto'>
          {contextIcon}
        </Grid>
        <Grid item xs zeroMinWidth className={styles.inputHeaderText}>
          <Typography variant='caption' color='text.secondary' noWrap>
            {action}: <i>{comment.text}</i>
          </Typography>
        </Grid>
        <Grid item xs='auto'>
          <IconButton size='small' onClick={removeCommentContext}>
            <CloseIcon fontSize='small' sx={sx.secondaryText} />
          </IconButton>
        </Grid>
      </Grid>
    );
  }, [replyingTo, editing, removeCommentContext]);

  return (
    <div {...rest} className={clsx(styles.container, className)} ref={containerRef}>
      <CommentsSectionHeader
        comments={comments}
        onViewAllComments={viewAllComments}
        hideViewAllComments={hideViewAllComments}
        onCreateComment={startComment}
        hideCreateComment={hideCreateComment}
        className={styles.commentsHeader}
      />
      <List dense disablePadding>
        {commentTree.map((comment, i) => (
          <IdsComment
            key={comment.id}
            comment={comment}
            onReply={replyToComment}
            onEdit={editComment}
            onDelete={deleteComment}
            divider={i < commentTree.length - 1}
          />
        ))}
      </List>
      <div
        className={clsx(styles.floatingFooter, {
          [styles.floatingFooterNoHeader]: !inputHeader,
        })}
      >
        {inputHeader}
        <TextField
          placeholder='Add a comment'
          value={commentText}
          onChange={onCommentChange}
          autoFocus={autoFocusCommentInput}
          variant='outlined'
          size='small'
          fullWidth
          multiline
          maxRows={4}
          inputRef={commentInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={saveComment}
                  size='small'
                  color='primary'
                  disabled={!!(!commentText?.length || (editing && commentText === editing.text))}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default IdsCommentsSection;
