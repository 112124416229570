import React from 'react';
import { FormControlLabel, Checkbox, CheckboxProps, FormControlLabelProps } from '@mui/material';

export interface IIdsCheckboxProps extends CheckboxProps {
  label?: FormControlLabelProps['label'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];

  // Omitting couple of props, so they will not be overwritten
  // by spread
  labelProps?: Omit<FormControlLabelProps, 'label' | 'control' | 'labelPlacement'>;
}

const IdsCheckbox: React.FC<IIdsCheckboxProps> = ({
  label,
  labelPlacement = 'end',
  labelProps = {},
  ...rest
}) => (
  <FormControlLabel
    control={<Checkbox color='primary' {...rest} />}
    label={label}
    labelPlacement={labelPlacement}
    {...labelProps}
  />
);

export default IdsCheckbox;
