import { gql } from 'graphql-request';

import useOrgGraphQuery from '../../../../../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery, {
  IUseCursorPaginatedQueryOptions,
} from '../../../../../hooks/useCursorPaginatedQuery';
import { useAreaOfInterestKeys } from '../../../../../services/AreaOfInterestService';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';

import { DEFAULT_TAKE } from '../index';

export const AreasOfInterestQuery = gql`
  query AreasOfInterest($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      areasOfInterest(take: $take, after: $after) {
        totalCount
        edges {
          cursor
          node {
            id
            organizationId
            projectId
            creator {
              id
              firstName
              lastName
            }
            name
            data
            color
            flagged
            position {
              latitude
              longitude
            }
            createdAt
            metadata {
              id
              type
              value
            }
            points {
              latitude
              longitude
            }
            area
            distance
          }
        }
      }
    }
  }
`;

const useAOIs = (onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad']) => {
  const areaOfInterestKeys = useAreaOfInterestKeys();
  const { location } = useLocationMapContext();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...areaOfInterestKeys.map(location?.id), 'points', `take-${take}`, `after-${after}`],
      AreasOfInterestQuery,
      {
        locationId: location?.id,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery({
    initialData: location?.areasOfInterest?.edges || [],
    useQuery,
    defaultTake: DEFAULT_TAKE,
    selectConnection: d => d.location.areasOfInterest,
    onPageLoad,
  });
};

export default useAOIs;
