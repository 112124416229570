import React, { useState } from 'react';

import { gql } from 'graphql-request';

import AssignmentResponseSection, {
  AssignmentResponseSectionFrag,
} from './AssignmentResponseSection';

export const TaskResponsesTabFrag = gql`
  fragment TaskResponsesTabFrag on AssignmentResponse {
    assignment {
      assignmentSections {
        edges {
          node {
            ...AssignmentResponseSectionFrag
          }
        }
      }
    }
  }
  ${AssignmentResponseSectionFrag}
`;

const TaskResponsesTab = ({ assignmentResponse }) => {
  const [expanded, setExpanded] = useState();

  const handleExpandedChange = id => (e, isExpanded) => {
    setExpanded(isExpanded ? id : null);
  };

  if (!assignmentResponse) {
    return null;
  }

  const { assignment } = assignmentResponse;

  return assignment.assignmentSections.edges.map(e => (
    <AssignmentResponseSection
      key={e.node.id}
      section={e.node}
      assignmentResponse={assignmentResponse}
      // Only one can be opened at a time
      expanded={expanded === e.node.id}
      onChange={handleExpandedChange(e.node.id)}
    />
  ));
};

export default TaskResponsesTab;
