import React from 'react';
import { Grid, Paper } from '@mui/material';
import { clsx } from 'clsx';
import { useRecoilValue } from 'recoil';

import MapOverlay from '../../../../components/mapping/MapOverlay';

import { IRouteDetails } from '../../../../services/RouteService';
import { infoPanelExpandedState } from '../../../../atoms/immersiveViewer';

import { useIsSmallScreen } from '../../../../hooks/useIsSmallScreen';

import DetailsPane from './DetailsPane';
import MediaPane from './MediaPane';
import ButtonsPane from './ButtonsPane';
import RouteBuilder from './RouteBuilder';
import { useAdjustMapPadding, useCanEditRoute } from './hooks';

import styles from './RouteMapInfoPanel.module.css';

const MapOverlayNoType = MapOverlay as any;

export interface IRouteMapInfoPanelProps {
  route: IRouteDetails;
}

const RouteMapInfoPanel: React.FC<IRouteMapInfoPanelProps> = ({ route }) => {
  const canEditRoute = useCanEditRoute(route);
  const infoPanelExpanded = useRecoilValue(infoPanelExpandedState);

  const isSmallScreen = useIsSmallScreen();
  const ref = useAdjustMapPadding();

  return (
    <MapOverlayNoType
      className={clsx({
        [styles.mapOverlayExpanded]: infoPanelExpanded,
        [styles.mapOverlayCollapsed]: !infoPanelExpanded,
        [styles.smallScreenWidth]: isSmallScreen,
        [styles.largeScreenWidth]: !isSmallScreen,
      })}
      ref={ref}
    >
      <Paper
        className={clsx({
          [styles.paperExpanded]: infoPanelExpanded,
          [styles.paperCollapsed]: !infoPanelExpanded,
        })}
      >
        {/* Buttons */}
        <Grid
          container
          direction={isSmallScreen && !infoPanelExpanded ? 'column' : 'row'}
          bgcolor='background.dark'
          justifyContent='flex-end'
          px={2}
          py={1}
        >
          <Grid item xs>
            <ButtonsPane />
          </Grid>
        </Grid>

        {/* Details */}
        <Grid
          item
          xs
          container
          direction='column'
          flexWrap='nowrap'
          rowSpacing={1}
          px={2}
          pb={2}
          pt={1}
          className={clsx({ [styles.contentCollapsed]: !infoPanelExpanded })}
        >
          {/* Route details and links */}
          <Grid item xs>
            <DetailsPane route={route} />
          </Grid>

          {/* Route points filters */}
          {!!route.routePoints.length && (
            <Grid item xs>
              <MediaPane />
            </Grid>
          )}

          {/* Available for editable routes only */}
          {canEditRoute && (
            <Grid item xs>
              <RouteBuilder />
            </Grid>
          )}
        </Grid>
      </Paper>
    </MapOverlayNoType>
  );
};

export default RouteMapInfoPanel;
