import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, DialogActions, DialogContent, Stack } from '@mui/material';

import IdsDialog from '../../IdsDialog';
import { ImageType, IMetadataType, MediaMetadataType } from '../../../constants/media';
import {
  IBulkUpdateLocationImagesInput,
  useBulkUpdateLocationImages,
} from '../../../services/MediaService';
import { activeOrganizationState } from '../../../atoms/organizations';
import { MediaItem } from '../../ids-lists/IdsMediaListView/types';
import IdsFormResetButton from '../IdsFormResetButton';
import IdsForm from '../IdsForm';
import CancelButton from '../../ids-buttons/CancelButton';
import SubmitButton from '../../ids-buttons/SubmitButton';

import BulkEditImageTypes from './BulkEditImageTypes';
import BulkLocationImageEditFormFields, {
  IBulkLocationImageEditFormFieldsProps,
} from './BulkLocationImageEditFormFields';
import { bulkEditValidationSchema } from './bulkEditValidation';
import { AllFormValues, ImageTypeFormValuesSelector, OriginalFieldValues } from './types';
import BulkLocationImageEditReview from './BulkLocationImageEditReview';

const sx = {
  imageTypes: { marginBottom: 1 },
};

const formErrorHandler = () => 'Images could not be updated';

export const initialValues: AllFormValues = {
  published: null,
  flagged: null,
  heading: null,
  [MediaMetadataType.PhotoCategory]: null,
  [MediaMetadataType.PhotoType]: null,
  [MediaMetadataType.Level]: null,
  [MediaMetadataType.Area]: null,
};

export interface IBulkLocationImageEditDialogProps
  extends Omit<IBulkLocationImageEditFormFieldsProps, 'imageTypesEditing'> {
  open: boolean;
  onClose: () => void;
  locationId: string;
  imageUrns: string[];
  imageTypesEditing: Record<ImageType, boolean>;
  allImages: MediaItem[];
  selectImageInitFormValues: ImageTypeFormValuesSelector;
  metadataTypes: IMetadataType[];
}

type ReviewHandlers = {
  onConfirm: () => void;
  onCancel: () => void;
};

const BulkLocationImageEditDialog: React.FC<IBulkLocationImageEditDialogProps> = ({
  open,
  onClose,
  locationId,
  imageUrns,
  imageTypesEditing,
  allImages,
  selectImageInitFormValues,
  metadataTypes,
  ...rest
}) => {
  const [reviewHandlers, setReviewHandlers] = useState<ReviewHandlers | null>(null);
  const [saving, setSaving] = useState(false);

  const { mutateAsync: bulkUpdateMutation } = useBulkUpdateLocationImages();

  const activeOrg = useRecoilValue(activeOrganizationState);

  const selectedImages = useMemo(() => {
    return allImages.filter(img => imageUrns.includes(img.node.id));
  }, [allImages, imageUrns]);

  const originalFieldValues = useMemo<OriginalFieldValues>(() => {
    const fieldValueSets = {
      flagged: new Set<boolean>(),
      [MediaMetadataType.Level]: new Set<string | undefined>(),
      [MediaMetadataType.Area]: new Set<string | undefined>(),

      [MediaMetadataType.PhotoCategory]: new Set<string | undefined>(),
      [MediaMetadataType.PhotoType]: new Set<string | undefined>(),

      published: new Set<boolean | undefined>(),

      heading: new Set<number | undefined>(),
    };

    selectedImages.forEach(img => {
      fieldValueSets.flagged.add(img.node.flagged);

      fieldValueSets[MediaMetadataType.Level].add(
        img.node.metadata.find((d: any) => d.type === MediaMetadataType.Level)?.id,
      );

      fieldValueSets[MediaMetadataType.Area].add(
        img.node.metadata.find((d: any) => d.type === MediaMetadataType.Area)?.id,
      );

      fieldValueSets[MediaMetadataType.PhotoCategory].add(
        img.node.metadata.find((d: any) => d.type === MediaMetadataType.PhotoCategory)?.id,
      );

      fieldValueSets[MediaMetadataType.PhotoType].add(
        img.node.metadata.find((d: any) => d.type === MediaMetadataType.PhotoType)?.id,
      );

      fieldValueSets.published.add(img.node.published);

      fieldValueSets.heading.add(img.node.position?.heading);
    });

    return {
      flagged: [...fieldValueSets.flagged],
      [MediaMetadataType.Level]: [...fieldValueSets[MediaMetadataType.Level]],
      [MediaMetadataType.Area]: [...fieldValueSets[MediaMetadataType.Area]],
      [MediaMetadataType.PhotoCategory]: [...fieldValueSets[MediaMetadataType.PhotoCategory]],
      [MediaMetadataType.PhotoType]: [...fieldValueSets[MediaMetadataType.PhotoType]],
      published: [...fieldValueSets.published],
      heading: [...fieldValueSets.heading],
    };
  }, [selectedImages]);

  const handleClose = useCallback(() => {
    if (saving) return;

    // Reviewing changes, cancel review
    if (reviewHandlers) return reviewHandlers.onCancel();

    // Not currently reviewing, close edit dialog
    onClose();
  }, [saving, reviewHandlers, onClose]);

  /** Displays the edit review component until user clicks confirm or cancel. */
  const requestSubmitConfirmation = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      setReviewHandlers({
        onConfirm: () => {
          setReviewHandlers(null); // Close review
          resolve();
        },
        onCancel: () => {
          setReviewHandlers(null);
          reject();
        },
      });
    });
  }, []);

  const handleSubmit = useCallback(
    async (values: AllFormValues) => {
      try {
        await requestSubmitConfirmation();
      } catch {
        // Canceled, cancel submission
        return false;
      }

      setSaving(true);

      const updatedValues: Omit<
        IBulkUpdateLocationImagesInput,
        'imageUrns' | 'imageTypesEditing' | 'locationId'
      > = {
        metadata: [],
      };

      if (values.published !== null) {
        updatedValues.published = values.published;
      }

      if (values.flagged !== null) {
        updatedValues.flagged = values.flagged;
      }

      if (values.heading !== null) {
        updatedValues.heading = values.heading;
      }

      if (values[MediaMetadataType.PhotoCategory] !== null) {
        updatedValues.metadata.push({
          id: values[MediaMetadataType.PhotoCategory],
          type: MediaMetadataType.PhotoCategory,
        });
      }

      if (values[MediaMetadataType.PhotoType] !== null) {
        updatedValues.metadata.push({
          id: values[MediaMetadataType.PhotoType],
          type: MediaMetadataType.PhotoType,
        });
      }

      if (values[MediaMetadataType.Level] !== null) {
        updatedValues.metadata.push({
          id: values[MediaMetadataType.Level],
          type: MediaMetadataType.Level,
        });
      }

      if (values[MediaMetadataType.Area] !== null) {
        // TODO: extend this to support clearing area value once supported by the mutation
        updatedValues.metadata.push({
          id: values[MediaMetadataType.Area],
          type: MediaMetadataType.Area,
        });
      }

      // Only include values that were set to some updated value. Not all fields need to be updated
      const result = await bulkUpdateMutation({
        organizationId: activeOrg.id,
        locationId,
        imageUrns,
        imageTypesEditing,
        ...updatedValues,
      });

      setSaving(false);

      if (result?.bulkUpdateImages.errors?.length) {
        const field = result.bulkUpdateImages.errors[0].field;
        const message = result.bulkUpdateImages.errors[0].message;

        throw new Error(`${field} ${message}`);
      }

      // Success
      setReviewHandlers(null); // Close review
      onClose();
    },
    [
      imageUrns,
      imageTypesEditing,
      activeOrg.id,
      locationId,
      bulkUpdateMutation,
      onClose,
      requestSubmitConfirmation,
    ],
  );

  const reviewingChanges = !!reviewHandlers;

  return (
    <>
      <IdsDialog
        title={reviewingChanges ? 'Apply these changes?' : 'Manage Selected Images'}
        open={open}
        onClose={handleClose}
        noSearchParam={true}
        maxWidth='sm'
        fullWidth
      >
        <IdsForm
          initialValues={initialValues}
          validationSchema={bulkEditValidationSchema}
          successMessage='Images updated'
          errorHandler={formErrorHandler}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            {reviewingChanges ? (
              <BulkLocationImageEditReview
                originalFieldValues={originalFieldValues}
                metadataTypes={metadataTypes}
              />
            ) : (
              <>
                <BulkEditImageTypes imageTypesEditing={imageTypesEditing} sx={sx.imageTypes} />
                <BulkLocationImageEditFormFields
                  imageTypesEditing={imageTypesEditing}
                  metadataTypes={metadataTypes}
                  {...rest}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Stack direction='row' spacing={1} justifyContent='space-between' width='100%'>
              <Stack direction='row' spacing={1}>
                {!reviewingChanges && <IdsFormResetButton />}
              </Stack>
              <Stack direction='row' spacing={1}>
                <CancelButton
                  onClick={reviewingChanges ? reviewHandlers.onCancel : handleClose}
                  disabled={saving}
                />
                {reviewingChanges ? (
                  <Button onClick={reviewHandlers.onConfirm} disabled={saving} variant='contained'>
                    Confirm
                  </Button>
                ) : (
                  <SubmitButton disableWhileClean label='Save' />
                )}
              </Stack>
            </Stack>
          </DialogActions>
        </IdsForm>
      </IdsDialog>
    </>
  );
};

export default BulkLocationImageEditDialog;
