import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { gql } from 'graphql-request';

import useOrgGraphQuery from '../../../../../hooks/useOrgGraphQuery';
import IdsCheckbox from '../../../../ids-inputs/IdsCheckbox';
import useCursorPaginatedQuery from '../../../../../hooks/useCursorPaginatedQuery';

import { useProgramKeys } from '../../../../../services/ProgramsService';
import usePrevious from '../../../../../hooks/usePrevious';

const ProgramProjectList = gql`
  query ProgramProjects($orgId: ID, $id: ID!, $tenantId: ID, $take: Int, $after: String) {
    program(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      projects(take: $take, after: $after) {
        edges {
          cursor
          node {
            locationId
          }
        }
      }
    }
  }
`;

function ProgramLocationsFilterOption({ programId, label, selected, onLocationsLoaded }) {
  const programKeys = useProgramKeys();

  const useProgramProjects = (take, after) =>
    useOrgGraphQuery(
      [
        ...programKeys.orgList(programId),
        'projects',
        'locations',
        `take-${take}`,
        `after-${after}`,
      ],
      ProgramProjectList,
      { id: programId, take, after },
    );

  const { data: projects } = useCursorPaginatedQuery({
    initialData: [],
    useQuery: useProgramProjects,
    defaultTake: 100,
    selectConnection: d => d.program?.projects,
  });

  const locations = useMemo(() => projects.map(e => e.node.locationId), [projects]);
  const prevLocations = usePrevious(locations);

  useEffect(() => {
    if (locations !== prevLocations && onLocationsLoaded) {
      onLocationsLoaded(programId, locations);
    }
  }, [locations, prevLocations, onLocationsLoaded, programId]);

  return <IdsCheckbox label={label} checked={selected} />;
}

ProgramLocationsFilterOption.propTypes = {
  programId: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onLocationsLoaded: PropTypes.func,
};

export default ProgramLocationsFilterOption;
