import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { gql } from 'graphql-request';
import { Alert, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import Page from '../../../components/Page';
import { activeCollectionState } from '../../../atoms/collections';
import { activeOrganizationState } from '../../../atoms/organizations';
import useKeycloak from '../../../hooks/useKeycloak';
import IdsImageList from '../../../components/ids-lists/IdsImageList';
import IdsNestedViewHeader from '../../../components/IdsNestedViewHeader';
import IdsMediaViewer, {
  MEDIA_PARAM_KEY,
} from '../../../components/ids-media-viewer/IdsMediaViewer';
import { LEGACY_MEDIA_TYPES, MEDIA_TYPES, MediaType } from '../../../constants/media';
import { MEDIA_VIEWER_MODES } from '../../../constants/mediaViewer';
import useGraphQuery from '../../../hooks/useGraphQuery';
import { useCollectionKeys } from '../../../services/CollectionService';
import QueryLoader from '../../../components/QueryLoader';
import LoadingScreen from '../../../components/LoadingScreen';
import { getMediaTypeFromUrn } from '../../../utils/media';
import { activeTenantState } from '../../../atoms/tenants';
import { getOrgCollectionsRoute } from '../../../utils/routes';

const getItemMediaType = item => item.item.mediaType;

const CollectionDetailQuery = gql`
  query CollectionDetails($id: ID!, $organizationId: ID) {
    collection(id: $id, organizationId: $organizationId) {
      id
      title
      public
      collectionType
      createdAt
      updatedAt
      items {
        id
        title
        itemId
        itemType
        item {
          id
          mediaType
          reps {
            name
            url
          }
        }
      }
    }
  }
`;

const styles = {
  pageContainer: {
    padding: 16,
    paddingTop: 0,

    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    backgroundColor: 'white',
    padding: 16,
    marginTop: 8,
    border: '1px solid #ccc',
    borderRadius: 8,

    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  headline: {
    marginBottom: 2,
  },
};

function Collections({ publicRoute }) {
  const collectionKeys = useCollectionKeys();

  const activeCollection = useRecoilValue(activeCollectionState);
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { authenticated } = useKeycloak();
  const [searchParams] = useSearchParams();

  const [mediaViewerActiveImage, setMediaViewerActiveImage] = useState(null);
  const [hideMediaModalClose, setHideMediaModalClose] = useState(false);
  const [collection, setCollection] = useState();
  const [mediaViewerOpen, setMediaViewerOpen] = useState(false);

  const useCollectionDetails = () =>
    useGraphQuery(collectionKeys.detail(activeCollection.id), CollectionDetailQuery, {
      id: activeCollection.id,
      organizationId: activeOrg ? activeOrg.id : null,
    });

  const publicCollection = useMemo(() => {
    return collection?.public;
  }, [collection]);

  /**
   * TODO: think about optimizing this...
   * Don't want to find the rep every rerender.
   */
  const getImageUrl = useCallback(img => {
    return img.item.reps.find(rep => rep.name === 'large').url;
  }, []);

  const viewImage = useCallback(id => {
    setMediaViewerActiveImage(id);
    setMediaViewerOpen(true);
  }, []);

  const closeMediaViewer = useCallback(() => {
    setMediaViewerActiveImage(null);
    setMediaViewerOpen(false);
  }, [setMediaViewerActiveImage, setMediaViewerOpen]);

  const breadcrumbs = [];

  if (activeOrg && activeTenant) {
    breadcrumbs.push({
      label: 'Collections',
      route: getOrgCollectionsRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
      }),
    });
  }

  useEffect(() => {
    let urlMediaId = searchParams.get(MEDIA_PARAM_KEY);
    if (!urlMediaId) {
      return;
    }

    const mediaType = getMediaTypeFromUrn(urlMediaId);
    if (!mediaType) {
      return;
    }

    if (mediaType === LEGACY_MEDIA_TYPES.userphoto) {
      urlMediaId = urlMediaId.replace(
        LEGACY_MEDIA_TYPES.userphoto,
        MEDIA_TYPES[MediaType.ProjectPhoto].urnType,
      );
    }

    setMediaViewerActiveImage(urlMediaId);
    setHideMediaModalClose(true);
    setMediaViewerOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageClick = useCallback(
    img => {
      viewImage(img.item.id);
    },
    [viewImage],
  );

  return (
    <QueryLoader
      useQuery={useCollectionDetails}
      selectData={data => data?.collection}
      setData={setCollection}
      render={() => (
        <Page title={`Collection: ${collection.title}`} style={styles.pageContainer}>
          {breadcrumbs && breadcrumbs.length > 0 && (
            <IdsNestedViewHeader breadcrumbs={breadcrumbs} currentCrumb={collection.title} />
          )}
          <div style={styles.container}>
            <Typography variant='h2' sx={styles.headline}>
              {collection.title}
            </Typography>
            {(publicCollection || authenticated) && (
              <IdsImageList
                images={collection.items}
                getImageUrl={getImageUrl}
                getMediaType={getItemMediaType}
                onImageClick={onImageClick}
              />
            )}
            {!publicCollection && !authenticated && (
              <Alert severity='warning'>This Collection is not publicly available</Alert>
            )}
            {mediaViewerActiveImage && (
              <IdsMediaViewer
                // Event handlers are not needed in this case
                title={collection.title}
                media={collection.items}
                selectMediaItemData={item => item.item}
                activeMediaId={mediaViewerActiveImage}
                onActiveMediaChange={viewImage}
                onClose={closeMediaViewer}
                fullscreen={true}
                hideContextMenu={publicCollection}
                hideFullScreen={true}
                hideDelete={true}
                hideEdit={true}
                hideClose={hideMediaModalClose}
                open={mediaViewerOpen}
                loadAllThumbnails={true}
                mode={MEDIA_VIEWER_MODES.GALLERY}
                loadActiveImageDetail={false}
                orgId={activeOrg?.id}
                tenantId={activeTenant?.id}
              />
            )}
          </div>
        </Page>
      )}
      renderLoading={() => <LoadingScreen fullscreen={publicRoute} />}
    />
  );
}

export default Collections;
