import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UploadResult } from '@uppy/core';
import { Alert, List, Menu } from '@mui/material';

import { IQueuedUpload } from '../../../../context/UploadQueueContext';
import useUploadQueueContext from '../../../../hooks/useUploadQueueContext';
import { uploadQueueAnchorElState, uploadQueueOpenState } from '../../../../atoms/uploadQueue';
import { UploadStatus } from '../../../../constants/uploads';
import usePrevious from '../../../../hooks/usePrevious';
import { closeUppy } from '../../../../utils/uppyInstanceManager';

import QueuedUpload from './QueuedUpload';

interface UploadQueueProps {
  useQueue: () => IQueuedUpload[];
  getQueue: () => IQueuedUpload[];
  setQueue: (queue: IQueuedUpload[]) => void;
  updateQueuedUpload: (queuedUpload: IQueuedUpload) => void;
}

const UploadQueue: React.FC<UploadQueueProps> = ({
  useQueue,
  getQueue,
  setQueue,
  updateQueuedUpload,
}) => {
  const queue = useQueue();
  const prevQueue = usePrevious(queue);
  const [activeIndex, setActiveIndex] = useState(0);
  const anchorEl = useRecoilValue(uploadQueueAnchorElState);
  const open = useRecoilValue(uploadQueueOpenState);
  const { closeUploadQueue } = useUploadQueueContext();

  const updateActiveIndex = useCallback(() => {
    const _queue = getQueue();

    let newActiveIndex: number = activeIndex;

    if (!_queue.length) {
      newActiveIndex = -1;
    } else {
      // Find first queued upload that needs to be uploaded
      for (let i = 0; i < _queue.length; i++) {
        // TODO: Add other rules in here about doing retries...

        // Active upload is the first upload that is either uploading or queued or the last upload in the queue
        if (
          _queue[i].status === UploadStatus.Uploading ||
          _queue[i].status === UploadStatus.Queued ||
          i === _queue.length - 1
        ) {
          newActiveIndex = i;
          break;
        }
      }
    }

    if (newActiveIndex !== activeIndex) {
      setActiveIndex(newActiveIndex);
    }

    return newActiveIndex;
  }, [getQueue, activeIndex]);

  const removeUpload = useCallback(
    (queuedUpload: IQueuedUpload) => {
      const newQueue = [...getQueue()];
      const index = newQueue.findIndex(q => q.uppy.getID() === queuedUpload.uppy.getID());
      if (index >= 0) {
        newQueue.splice(index, 1);
        setQueue(newQueue);
        updateActiveIndex();
        closeUppy(queuedUpload.uppy);
      }
    },
    [getQueue, setQueue, updateActiveIndex],
  );

  const handleUploadComplete = useCallback(
    (queuedUpload: IQueuedUpload, result: UploadResult) => {
      const checkForNetworkIssue = () => {
        if (window.navigator.onLine) {
          // Only progress to the next upload if online
          updateActiveIndex();
          return;
        }

        const handleOnline = () => {
          window.removeEventListener('online', handleOnline);

          // Retry once back online
          queuedUpload.uppy.retryAll();
        };

        window.addEventListener('online', handleOnline);
      };

      // All files in original upload or in retried upload succeeded
      if (result.successful.length && !result.failed.length) {
        updateQueuedUpload({ ...queuedUpload, status: UploadStatus.Successful });
        updateActiveIndex();
      }
      // Some file failed, but not all (some may have succeed in original upload or retried upload)
      else if (result.failed.length && result.failed.length < queuedUpload.fileCount) {
        updateQueuedUpload({ ...queuedUpload, status: UploadStatus.MixedResult });

        checkForNetworkIssue();
      }
      // Failure (no success, all failed)
      else if (result.failed.length === queuedUpload.fileCount) {
        updateQueuedUpload({ ...queuedUpload, status: UploadStatus.Failed });
        checkForNetworkIssue();
      }
    },
    [updateQueuedUpload, updateActiveIndex],
  );

  const startQueuedUpload = useCallback(
    async (queuedUpload: IQueuedUpload) => {
      updateQueuedUpload({ ...queuedUpload, status: UploadStatus.Uploading });

      const onComplete = (result: UploadResult) => {
        handleUploadComplete(queuedUpload, result);
      };
      queuedUpload.uppy.on('complete', onComplete);

      const { recoveredState } = queuedUpload.uppy.getState();
      if (recoveredState) {
        const { isSomeGhost } = queuedUpload.uppy.getObjectOfFilesPerState();
        if (!isSomeGhost) {
          // Golden retriever recovered upload state
          // Tell golden retriever to restore the upload
          queuedUpload.uppy.emit('restore-confirmed');
        }
      } else {
        // New upload, not recovered
        queuedUpload.uppy.upload();
      }
    },
    [handleUploadComplete, updateQueuedUpload],
  );

  useEffect(() => {
    if (queue !== prevQueue) {
      const active = updateActiveIndex();
      if (active >= 0 && queue[active]?.status === UploadStatus.Queued) {
        startQueuedUpload(queue[active]);
      }
    }
  }, [queue, prevQueue, updateActiveIndex, startQueuedUpload]);

  return (
    anchorEl && (
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={closeUploadQueue}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!queue.length && <Alert severity='info'>No active uploads</Alert>}
        <List dense disablePadding>
          {queue.map((q, i) => (
            <QueuedUpload
              key={q.uppy.getID()}
              queuedUpload={q}
              removeUpload={removeUpload}
              divider={i < queue.length - 1}
            />
          ))}
        </List>
      </Menu>
    )
  );
};

export default UploadQueue;
