import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import {
  CloseIcon,
  DeleteOutlineIcon,
  EditOutlineIcon,
  FlagIcon,
  FlagOutlinedIcon,
  SaveIcon,
} from '../../../../theme/icons';
import FilledIconButton from '../../../ids-buttons/FilledIconButton';

const sx = {
  flagIcon: theme => ({
    color: theme.palette.ids.marker.markerDotFlagged,
  }),
};

const EOIInfoActions = ({
  isDeleting,
  canModify,
  isFlagged,
  isEditMode,
  setIsEditMode,
  onClose,
  onDeleteHandler,
  toggleFlagged,
}) => {
  const { submitForm, isValid, dirty, setFieldValue, values, resetForm } = useFormikContext();

  return (
    <>
      {canModify && (
        <FilledIconButton disabled={isDeleting} onClick={onDeleteHandler} size='small'>
          <DeleteOutlineIcon />
        </FilledIconButton>
      )}
      {canModify && (
        <FilledIconButton
          onClick={async () => {
            await toggleFlagged({ flagged: !isFlagged });
            setFieldValue('flagged', !isFlagged);
          }}
          size='small'
        >
          {isFlagged ? <FlagIcon sx={sx.flagIcon} /> : <FlagOutlinedIcon sx={sx.flagIcon} />}
        </FilledIconButton>
      )}
      {canModify && (
        <FilledIconButton
          disabled={!isValid}
          onClick={async () => {
            setIsEditMode(!isEditMode);

            if (isEditMode && dirty) {
              await submitForm();
              resetForm({ values });
            }
          }}
          size='small'
        >
          {isEditMode ? <SaveIcon /> : <EditOutlineIcon />}
        </FilledIconButton>
      )}
      <FilledIconButton onClick={onClose} size='small'>
        <CloseIcon />
      </FilledIconButton>
    </>
  );
};

EOIInfoActions.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  canModify: PropTypes.bool.isRequired,
  isFlagged: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  toggleFlagged: PropTypes.func.isRequired,
};

export default EOIInfoActions;
