import React from 'react';
import PropTypes from 'prop-types';

import { ListItemText } from '@mui/material';
import { gql } from 'graphql-request';

import IdsListItem from '../../ids-lists/IdsListItem';

export const LocationOptionListItemFrag = gql`
  fragment LocationOptionListItemFrag on Location {
    id
    name
    address {
      streetLine1
      city
      state
      postalCode
    }
  }
`;

const LocationOptionListItem = ({ locationOption, ...rest }) => {
  if (!locationOption) {
    return null;
  }

  const { id, name, address } = locationOption;
  const { streetLine1, city, state, postalCode } = address;

  // Only show commas if the address elements are present
  const fullAddress = `${streetLine1 && `${streetLine1}, `}${
    city && `${city}, `
  }${state} ${postalCode}`;

  return (
    <IdsListItem {...rest} key={id}>
      <ListItemText
        primary={name}
        primaryTypographyProps={{ noWrap: true }}
        secondary={fullAddress}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </IdsListItem>
  );
};

LocationOptionListItem.propTypes = {
  locationOption: PropTypes.object.isRequired,
};

export default LocationOptionListItem;
