export enum LinkType {
  S3 = 'S3',
  Explorer = 'Explorer',
  Collection = 'Collection',
}

export enum DataToInclude {
  Both = 'Both',
  DataOnly = 'Data Only',
  ImagesOnly = 'Images Only',
}

export enum ImageSize {
  Original = 'Original',
  Large = 'Large',
  Medium = 'Medium',
  Thumb = 'Thumb',
}

export enum Sorting {
  Ascending = 'Ascending',
  Descending = 'Descending',
  NoSorting = 'No Sorting',
}

export enum SummaryReportType {
  TaskLevel = 'Task Level',
  TaskAndQuestions = 'Task and Questions',
}

export enum Summary {
  Grid = 'Grid',
  Table = 'Table',
  None = 'None',
}

export enum IncludeHeroImage {
  Yes = 'Yes',
  No = 'No',
}

export enum IncludeIdsBranding {
  Yes = 'Yes',
  No = 'No',
}

export enum DocumentType {
  CSV = 'CSV',
  PDF = 'PDF',
}

export enum ReportType {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export enum IncludeTableOfContents {
  Yes = 'Yes',
  No = 'No',
}

export enum IncludeWorkOrderSection {
  Yes = 'Yes',
  No = 'No',
}

export enum LogoOption {
  Tenant = 'Tenant',
  Organization = 'Organization',
  NoLogo = 'No',
}

export type SettingsType = {
  heroImage: IncludeHeroImage;
  linkType: LinkType;
  dataIncluded: DataToInclude;
  sortResponses?: Sorting;
  imageSize: ImageSize;
  summaryReportType: SummaryReportType;
  summary?: Summary;
  idsBranding: IncludeIdsBranding;
  generateTaskToC: IncludeTableOfContents;
  generateWorkOrderSection: IncludeWorkOrderSection;
  logoOption?: LogoOption;
  reportType?: ReportType;
  sectionsToIgnore: number[];
  tasksToIgnore: number[];
  questionsToIgnore: number[];
};

// Define explicit types for GraphQL response
export type AssignmentSectionEdge = {
  node: {
    id: string;
    title: string;
    assignmentTasks: {
      edges: AssignmentTaskEdge[];
    };
  };
};

export type AssignmentTaskEdge = {
  node: {
    id: string;
    title: string;
    assignmentQuestions: {
      edges: AssignmentQuestionEdge[];
    };
  };
};

export type AssignmentQuestionEdge = {
  node: {
    id: string;
    title: string;
  };
};
// It contains more fields
export interface IQuestion {
  id: string;
  title: string;
}
export interface ITask {
  id: string;
  title: string;
  questions: IQuestion[];
}

// It contains more fields
export interface ISection {
  id: string;
  title: string;
  tasks: ITask[];
}

export interface IProcessedQuestion extends IQuestion {
  includeToReport: boolean;
}
export interface IProcessedTask extends ITask {
  questions: IProcessedQuestion[];

  includeToReport: boolean;
}

export interface IProcessedSection extends ISection {
  tasks: IProcessedTask[];

  includeToReport: boolean;
}

export interface IAssignmentData {
  id: string;
  organizationId: string;
  title: string;
  projectIds: string[];
  sections: ISection[];
}
