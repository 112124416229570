import React, { useCallback, useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import { AddDocument } from '../../../../../../../../theme/icons';
import { EDITABLE_ANSWER_TYPES } from '../../editableAnswerTypes';
import { IMediaMetadata } from '../../../../../../../../constants/media';

import { IAssignmentResponseTaskHeaderFragType } from '../types';

import CreateTaskResponseDialog, {
  ICreateTaskResponseDialogProps,
} from './CreateTaskResponseDialog';

export interface IActionsProps
  extends Pick<ICreateTaskResponseDialogProps, 'assignmentResponseId'> {
  task: IAssignmentResponseTaskHeaderFragType;
}

const Actions: React.FC<IActionsProps> = ({ task, assignmentResponseId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  // Disable button if there are no possible answers.
  const disabled = useMemo(() => {
    return !task?.assignmentQuestions?.edges?.some(question => {
      if (!question.node?.questionType) {
        return false;
      }

      return question.node?.questionType in EDITABLE_ANSWER_TYPES;
    });
  }, [task?.assignmentQuestions?.edges]);

  return (
    <>
      <Tooltip title={disabled ? 'Cannot add response to this task' : 'Add new response'}>
        <span>
          <IconButton disabled={disabled} onClick={toggleIsOpen}>
            <AddDocument />
          </IconButton>
        </span>
      </Tooltip>

      {!disabled && (
        <CreateTaskResponseDialog
          taskId={task.id}
          title={task.title}
          open={isOpen}
          onClose={toggleIsOpen}
          // @TODO: Refactor interfaces to get rid of "as any" workarounds
          questions={task.assignmentQuestions!.edges as any}
          assignmentResponseId={assignmentResponseId}
          metadata={task.defaultMetadata as any}
          target={task.target as any}
          defaultTaskMetadata={task.defaultMetadata as IMediaMetadata[]}
        />
      )}
    </>
  );
};

export default Actions;
