// Load any extra data needed for viewing an active image
import { gql } from 'graphql-request';

import useOrgGraphQuery, { useQueryKeyId } from '../../../../../hooks/useOrgGraphQuery';
import { MediaViewerImageDetailFrag } from '../../../../ids-media-viewer/IdsMediaViewer/hooks/useImageDetails';

const ActiveImageDetailQuery = gql`
  query ActiveImageDetailQuery($orgId: ID, $urn: String!, $tenantId: ID) {
    image(organizationId: $orgId, urn: $urn, tenantId: $tenantId) {
      organizationId
      position {
        heading
        orientation
      }
      ...MediaViewerImageDetailFrag
    }
  }
  ${MediaViewerImageDetailFrag}
`;

const useActiveImageDetail = (activeImageId: string | null) => {
  const queryIdKey = useQueryKeyId();

  return useOrgGraphQuery(
    ['activeImageMap', activeImageId, ...queryIdKey],
    ActiveImageDetailQuery,
    { urn: activeImageId },
    { enabled: !!activeImageId },
  );
};

export default useActiveImageDetail;
