import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, Container } from '@mui/material';

import { useGetOrganizationUsers } from '../../services/UsersService';
import { activeOrganizationState } from '../../atoms/organizations';

import Page from '../../components/Page';

import { getOrgUserEditRoute, getOrgUserRoute } from '../../utils/routes';

import { activeTenantState } from '../../atoms/tenants';

import UsersList from './UsersList';
import UserListItem from './UserListItem';
import UserListHeader from './UserListHeader';

const styles = {
  root: theme => ({
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  }),
};

function Users() {
  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);
  const [users, setUsers] = useState([]);

  const { data, error, isLoading } = useGetOrganizationUsers(organization.id);

  useEffect(() => {
    if (data) {
      setUsers(data);
    }
  }, [data]);

  const navigate = useNavigate();

  const openUser = useCallback(
    id => {
      navigate(
        getOrgUserRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          userId: id,
        }),
      );
    },
    [navigate, organization.id, activeTenant],
  );

  const editUser = useCallback(
    id => {
      navigate(
        getOrgUserEditRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          userId: id,
        }),
      );
    },
    [navigate, organization.id, activeTenant],
  );

  return (
    <Page title='Users'>
      <Box sx={styles.root}>
        <Container maxWidth={false}>
          <UserListHeader />
          <UsersList
            userItems={users}
            renderUserItem={userItem => (
              <UserListItem
                key={userItem.node.user.id}
                userItem={userItem}
                onOpen={() => openUser(userItem.node.user.id)}
                onEdit={() => editUser(userItem.node.user.id)}
              />
            )}
            noItemsMessage='No users'
            noItemsForFilterMessage='No users match your search'
            loading={isLoading}
            error={error && 'Users could not be loaded'}
          />
        </Container>
      </Box>
    </Page>
  );
}

export default Users;
