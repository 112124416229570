import { gql } from 'graphql-request';
import { useMemo } from 'react';

import { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import { MEDIA_TYPES, MediaType } from '../constants/media';
import useOrgGraphQuery from '../hooks/useOrgGraphQuery';
import MediaCommentFrag from '../features/Comments/EntityComments/MediaCommentFrag';

import { IDataEntityMarker } from './types';
import { useLocationKeys } from './LocationService';

export const useAssetKeys = () => {
  const queryIdKey = useQueryKeyId();
  const locationKeys = useLocationKeys();

  return useMemo(() => {
    const assetKeys = {
      all: [MEDIA_TYPES[MediaType.Asset].type, ...queryIdKey],
      lists: () => [...assetKeys.all, 'list'],
      locationList: (locationId: string) => [
        ...assetKeys.lists(),
        ...locationKeys.detail(locationId),
      ],
      map: (locationId: string) => [...assetKeys.locationList(locationId), 'map'],
      route: (id: string) => [...assetKeys.all, id],
      detail: (id: string) => [...assetKeys.route(id), 'detail'],
    };

    return assetKeys;
  }, [queryIdKey, locationKeys]);
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILocationMapAsset extends IDataEntityMarker {}

export interface ILocationMapAssetsQuery {
  location: {
    assets: ILocationMapAsset[];
  };
}

const AssetMarkerFrag = gql`
  fragment AssetMarkerFrag on Asset {
    id
    projectId
    name
    color
    flagged
    position {
      latitude
      longitude
    }
    metadata {
      id
      type
      value
    }
    fields {
      id
      type
      label
      value
    }
    creator {
      id
      firstName
      lastName
    }
    customFieldsMetadata {
      id
      type
      value
    }
    images {
      id
    }
    comments {
      ...MediaCommentFrag
    }
  }
  ${MediaCommentFrag}
`;

export const LocationMapAssetsQuery = gql`
  query Assets($orgId: ID, $tenantId: ID, $locationId: ID!) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      assets {
        ...AssetMarkerFrag
      }
    }
  }
  ${AssetMarkerFrag}
`;

export const useLocationMapAssets = (locationId: string) => {
  const assetKeys = useAssetKeys();

  return useOrgGraphQuery<ILocationMapAssetsQuery>(
    [...assetKeys.map(locationId), 'points'],
    LocationMapAssetsQuery,
    {
      locationId: locationId,
    },
  );
};
