import { FormikHelpers } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { gql } from 'graphql-request';
import {
  CircularProgress,
  Backdrop,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from '@mui/material';

import IdsForm from '../../../../components/ids-forms/IdsForm';
import IdsFormSwitchField from '../../../../components/ids-forms/IdsFormSwitchField';
import IdsFormSelectField from '../../../../components/ids-forms/IdsFormSelectField';

import { USER_ROLES } from '../../../../constants/users';

import usePermissions from '../../../../hooks/usePermissions';

import {
  ISettingsFragType,
  TaskMetadataLocation,
  LoadResponses,
} from '../../../../services/AssignmentsService';

import { Buttons } from '../../../../components/ids-forms/IdsEditSettingsFormButtons';

import styles from './AssignmentSettings.module.css';

export const SettingsFrag = gql`
  fragment SettingsFrag on Assignment {
    repeatable
    completable
    taskMetadataLocation
    loadResponses
  }
`;

export interface IOptionValues {
  label: string;
  value: string;
}

export interface ISettingsProps {
  assignment: ISettingsFragType;
  updateAssignmentSettings: (values: ISettingsFragType) => Promise<string | null>;
}

const Settings: React.FC<ISettingsProps> = ({ assignment, updateAssignmentSettings }) => {
  const { userHasOneOfRoles } = usePermissions();
  const canUpdate = useMemo(
    () => userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]),
    [userHasOneOfRoles],
  );

  const [isEditMode, setEditMode] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [initialValues, setInitialValues] = useState<ISettingsFragType>({
    completable: false,
    repeatable: false,
    taskMetadataLocation: '',
    loadResponses: '',
    active: false,
  });

  useEffect(() => {
    if (!assignment) {
      return;
    }

    setInitialValues({
      completable: assignment.completable,
      repeatable: assignment.repeatable,
      taskMetadataLocation: assignment.taskMetadataLocation,
      loadResponses: assignment.loadResponses,
      active: assignment.active,
    });
  }, [assignment]);

  const onSubmitHandler = async (values: ISettingsFragType, formikHelpers: FormikHelpers<any>) => {
    setIsUpdating(true);

    const errorMessage = await updateAssignmentSettings(values);

    setIsUpdating(false);
    if (errorMessage) {
      formikHelpers.resetForm();
      throw new Error(errorMessage);
    } else {
      setInitialValues(values);
    }
  };

  const TASKMETADATALOCATION_OPTIONS: IOptionValues[] = [];
  Object.entries(TaskMetadataLocation).forEach(([key, value]) =>
    TASKMETADATALOCATION_OPTIONS.push({ label: key, value }),
  );

  const LOADRESPONSES_OPTIONS: IOptionValues[] = [];
  Object.entries(LoadResponses).forEach(([key, value]) =>
    LOADRESPONSES_OPTIONS.push({ label: key, value }),
  );

  return (
    <Box position='relative'>
      <IdsForm
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        errorHandler={(errorMessage, formikHelpers) => {
          formikHelpers.resetForm();
          return errorMessage;
        }}
        successMessage='Assignment settings were updated.'
      >
        <Backdrop
          className={styles.backdrop}
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isUpdating}
        >
          <CircularProgress />
        </Backdrop>
        <Box display='flex' flexDirection='column' gap={1} p={2}>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Completable
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSwitchField name='completable' disabled={!isEditMode} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Repeatable
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSwitchField name='repeatable' disabled={!isEditMode} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Task Metadata Location
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSelectField
                      name='taskMetadataLocation'
                      margin='none'
                      options={TASKMETADATALOCATION_OPTIONS}
                      disabled={!isEditMode}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Load Responses
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSelectField
                      name='loadResponses'
                      margin='none'
                      options={LOADRESPONSES_OPTIONS}
                      disabled={!isEditMode}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Active Status
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSwitchField name='active' disabled={!isEditMode} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Buttons
            isEditMode={isEditMode}
            canUpdate={canUpdate}
            isUpdating={isUpdating}
            setEditMode={setEditMode}
          />
        </Box>
      </IdsForm>
    </Box>
  );
};
export default Settings;
