import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { activeProjectRouteState } from '../../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../../atoms/organizations';

import useDeleteEntity from '../../../../../../hooks/useDeleteEntity';
import useFilterContext from '../../../../../../hooks/useFilterContext';

import {
  IManageRoutePointsOutput,
  useManageRoutePointsMutation,
} from '../../../../../../services/RouteService';
import { ROUTE_POINT_TYPES } from '../../../../../../constants/media';
import { IRoutePoint } from '../../../types';

const useDeleteRoutePointAction = () => {
  const activeProjectRoute = useRecoilValue(activeProjectRouteState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { deleteTypeItem } = useFilterContext();

  const { deleted, deleting, handleDelete } = useDeleteEntity();
  const manageRoutePoints = useManageRoutePointsMutation();

  const deleteRoutePoint = useCallback(
    (routePoint: IRoutePoint, onDelete: () => void) => {
      return handleDelete(
        async () => {
          return await manageRoutePoints.mutateAsync({
            id: activeProjectRoute!.id,
            organizationId: activeOrg.id,
            delete: [{ id: routePoint.node.id }],
            add: [],
            update: [],
          });
        },
        `${ROUTE_POINT_TYPES[routePoint.node.type].singularLabel} route point`,
        'This cannot be undone, the route point will be deleted permanently.',
        ROUTE_POINT_TYPES[routePoint.node.type].singularLabel,
        () => {
          deleteTypeItem(routePoint.node.id, routePoint.node.type);

          onDelete();
        },
        null,
        (result: IManageRoutePointsOutput) => {
          return !!result.manageRoutePoints?.errors?.length;
        },
      );
    },
    [handleDelete, activeOrg, activeProjectRoute, manageRoutePoints, deleteTypeItem],
  );

  return {
    deleteRoutePoint,
    deleting,
    deleted,
  };
};

export default useDeleteRoutePointAction;
