import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useGetTenantsList } from '../../../services/TenantService';

import TenantListItem from '../TenantListItem';

import TenantsList from '../TenantsList';
import { getTenantOrgsRoute } from '../../../utils/routes';
import TenantPage from '../TenantPage';

import IdsListHeader from '../../../components/ids-lists/IdsListHeader';

import styles from './styles.module.css';

const Tenants = () => {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetTenantsList();

  // Autonavigate to tenant context if there is only one tenant available
  useEffect(() => {
    if (!initialized && !error && !isLoading && data) {
      if (data.userTenants.length === 1) {
        navigate(
          getTenantOrgsRoute({
            subdomain: data.userTenants[0].subdomain,
          }),
        );
      }

      setInitialized(true);
    }
  }, [data, isLoading, error, initialized, navigate]);

  return (
    <TenantPage title='Tenants' maxWidth='lg' containerClassName={styles['container']} headerHidden>
      <IdsListHeader
        sx={{ paddingTop: 1, paddingBottom: 1 }}
        title='Tenants'
        dialogs={undefined}
        actions={undefined}
        secondaryActions={undefined}
      />
      <TenantsList
        tenantsItems={data?.userTenants}
        renderTenantItem={item => <TenantListItem key={item.id} tenantItem={item} />}
        loading={isLoading || !initialized}
        error={error && 'Tenants could not be loaded'}
      />
    </TenantPage>
  );
};

export default Tenants;
