import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ButtonBase, Grid, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import clsx from 'clsx';

import IdsImage from '../../../../../../../../../../../components/IdsImage';
import {
  AssignmentQuestionTypeToIcons,
  ImageQuestionTypeToMediaTypes,
} from '../../../../../../../../../../../constants/assignments';
import IdsDialogUploader from '../../../../../../../../../../../components/ids-inputs/uploaders/IdsDialogUploader';
import { useIdsUploaderUppy } from '../../../../../../../../../../../components/ids-inputs/uploaders/IdsUploader';
import { UploadType } from '../../../../../../../../../../../constants/uploads';
import {
  IInitialValues,
  ISingleLocationImageUploaderProps,
} from '../../../../../../../../../../../hooks/uploaders/useSingleLocationImageUploader';
import {
  ImageType,
  MEDIA_TYPE_TO_DEF,
  MediaType,
  MediaMetadataType,
  IMediaMetadata,
} from '../../../../../../../../../../../constants/media';
import { IAssignmentAnswer, IAssignmentQuestion } from '../types';
import { RemoveIcon } from '../../../../../../../../../../../theme/icons';
import { useGetImageAnswerUploaderConfig } from '../../../../../../../../../../../services/TaskResponsesService';
import { useGetLocationMetadataTypes } from '../../../../../../../../../../../services/LocationService';

import ExistingImageAnswer from './ExistingImageAnswer';
import NewImageAnswer from './NewImageAnswer';
import styles from './EditableImageAnswer.module.css';

const consumerKey = 'EditableImageAnswer';

const allImageTypes: ImageType[] = [
  MediaType.ProjectPhoto,
  MediaType.HDPhoto,
  MediaType.PanoramicPhoto,
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IImageAnswerValue extends Omit<Optional<IAssignmentAnswer, 'id'>, 'answerText'> {
  imageId?: string;
  imageType?: ImageType;
}

export interface IEditableImageAnswerProps {
  question: IAssignmentQuestion;
  assignmentResponseId: string;
  defaultTaskMetadata: IMediaMetadata[];
}

// think about abstracting out to ids iamge field... may be a future refactor when the use case arises

const EditableImageAnswer: React.FC<IEditableImageAnswerProps> = ({
  question,
  assignmentResponseId,
  defaultTaskMetadata,
}) => {
  const { uppy } = useIdsUploaderUppy(consumerKey);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [uploaderInitValues, setUploaderInitValues] = useState<IInitialValues>({});
  const uuidReplacing = useRef<string | null>(null);
  const { values, setFieldValue } = useFormikContext<any>();

  const { data: parentIdsData } = useGetImageAnswerUploaderConfig(assignmentResponseId);

  const uploaderConfig = useMemo(
    () =>
      parentIdsData && {
        locationId: parentIdsData.assignmentResponse.project.locationId || '',
        projectId: parentIdsData.assignmentResponse.project.id || '',
      },
    [parentIdsData],
  );

  const { data: locationMetadataTypesData } = useGetLocationMetadataTypes(
    uploaderConfig?.locationId || null,
  );

  const { questionType, id: questionId, title, repeatable } = question;

  const answer = useMemo<IImageAnswerValue | IImageAnswerValue[] | null>(
    () => values[questionId],
    [values, questionId],
  );

  const disabledImageTypes = useMemo<ImageType[]>(() => {
    const enabledImageTypes = ImageQuestionTypeToMediaTypes[questionType];
    return allImageTypes.filter(t => !enabledImageTypes?.includes(t));
  }, [questionType]);

  const defaultTaskInitialValues = useMemo<Partial<IInitialValues>>(() => {
    if (!defaultTaskMetadata || !locationMetadataTypesData) return {};

    const locationMetadataTypes = locationMetadataTypesData.location.metadataTypes;

    // Default task metadata is defined at the org level, to apply to location images,
    // those values need to be mapped to their location level counterparts by their string value.
    const orgToLocationMetadata = (orgMetadata: IMediaMetadata) =>
      locationMetadataTypes
        .find(ld => ld.type === orgMetadata.type)
        ?.values.find(v => v.name === orgMetadata.value)?.id;

    const getMetadataValue = (type: MediaMetadataType) => {
      const orgMetadata = defaultTaskMetadata.find((d: IMediaMetadata) => d.type === type);
      return orgMetadata ? orgToLocationMetadata(orgMetadata) : undefined;
    };

    const getMetadataValues = (type: MediaMetadataType) =>
      defaultTaskMetadata
        .filter((d: IMediaMetadata) => d.type === type)
        .map(orgToLocationMetadata)
        .filter((id?: string) => !!id) as string[];

    return {
      photo_category_id: getMetadataValue(MediaMetadataType.PhotoCategory),
      photo_type_id: getMetadataValue(MediaMetadataType.PhotoType),
      photo_level_id: getMetadataValue(MediaMetadataType.Level),
      photo_area_id: getMetadataValue(MediaMetadataType.Area),
      photo_tags: getMetadataValues(MediaMetadataType.PhotoTag),
    };
  }, [defaultTaskMetadata, locationMetadataTypesData]);

  const openUploader = useCallback(() => {
    setUploaderInitValues({ ...defaultTaskInitialValues });
    setUploaderOpen(true);
    uuidReplacing.current = null;
  }, [setUploaderOpen, setUploaderInitValues, defaultTaskInitialValues]);

  const replaceImageAnswer = useCallback(
    (initialValues: IInitialValues, uuid: string) => {
      setUploaderInitValues({
        ...defaultTaskInitialValues,
        ...initialValues,
      });
      setUploaderOpen(true);
      uuidReplacing.current = uuid;
    },
    [setUploaderInitValues, defaultTaskInitialValues],
  );

  const deleteImageAnswerValue = useCallback(
    (uuid: string) => {
      let newAnswer: IImageAnswerValue | IImageAnswerValue[] | undefined | null;
      if (repeatable) {
        newAnswer = [...(answer as IImageAnswerValue[])];
        const index = newAnswer.findIndex(a => a.uuid === uuid);
        newAnswer.splice(index, 1);
      } else {
        newAnswer = null;
      }

      setFieldValue(questionId, newAnswer);
    },
    [repeatable, answer, setFieldValue, questionId],
  );

  const closeUploader = useCallback(() => {
    setUploaderOpen(false);
  }, [setUploaderOpen]);

  const handleUploadComplete = useCallback(
    (
      { uuid }: any,
      { id: imageId }: any, // newImageData
      imageType: ImageType,
    ) => {
      const newAnswerValue: IImageAnswerValue = {
        uuid,
        imageId,
        imageType,
        answerPhotoType: MEDIA_TYPE_TO_DEF[imageType].imageAnswerType!,
        answerPhoto: null,
      };

      let newAnswer: IImageAnswerValue | IImageAnswerValue[] | undefined;
      if (repeatable) {
        newAnswer = [...(answer as IImageAnswerValue[])];

        if (uuidReplacing.current) {
          // replacing existing answer, remove the old one
          const index = newAnswer.findIndex(a => a.uuid === uuidReplacing.current);
          newAnswer.splice(index, 1);
        }

        newAnswer.push(newAnswerValue);
      } else {
        newAnswer = newAnswerValue;
      }

      setFieldValue(questionId, newAnswer);

      closeUploader();
    },
    [setFieldValue, questionId, closeUploader, answer, repeatable],
  );

  const createImageAnswerButton = useMemo(() => {
    const QuestionTypeIcon = AssignmentQuestionTypeToIcons[questionType]?.icon;

    return (
      <Tooltip title='Upload image' arrow>
        <ButtonBase onClick={openUploader} data-testid='createImageAnswerBtn'>
          <IdsImage
            src=''
            placeholderIcon={QuestionTypeIcon && <QuestionTypeIcon color='secondary' />}
          />
        </ButtonBase>
      </Tooltip>
    );
  }, [questionType, openUploader]);

  const renderImageAnswerValue = useCallback(
    (imageAnswerValue: IImageAnswerValue) => {
      const DeleteButton = ({ className, ...props }: any) => (
        <RemoveIcon
          onClick={(e: any) => {
            deleteImageAnswerValue(imageAnswerValue.uuid!);
            e.stopPropagation();
          }}
          className={clsx(className, styles.deleteIcon)}
          data-testid={`DeleteButton-${imageAnswerValue.uuid}`}
          {...props}
        />
      );

      return (
        <Tooltip title='Replace image' arrow>
          <div>
            {imageAnswerValue.id ? (
              <ExistingImageAnswer
                answer={{
                  ...imageAnswerValue,
                  uuid: imageAnswerValue.uuid!,
                  questionType: question.questionType,
                  questionId: question.id,
                }}
                openUploader={replaceImageAnswer}
                renderTopRightIcon={(props: any) => <DeleteButton {...props} />}
                topRightIconRequiresImage={false}
              />
            ) : (
              <NewImageAnswer
                imageId={imageAnswerValue.imageId!}
                uuid={imageAnswerValue.uuid!}
                imageType={imageAnswerValue.imageType!}
                question={question}
                openUploader={replaceImageAnswer}
                renderTopRightIcon={(props: any) => <DeleteButton {...props} />}
                topRightIconRequiresImage={false}
              />
            )}
          </div>
        </Tooltip>
      );
    },
    [replaceImageAnswer, question, deleteImageAnswerValue],
  );

  return (
    <div className={styles.container}>
      {repeatable ? (
        <Grid container direction='row' spacing={2}>
          <Grid item xs='auto'>
            {createImageAnswerButton}
          </Grid>
          {(answer as IImageAnswerValue[]).map(a => (
            <Grid item xs='auto' key={a.uuid}>
              {renderImageAnswerValue(a)}
            </Grid>
          ))}
        </Grid>
      ) : answer ? (
        renderImageAnswerValue(answer as IImageAnswerValue)
      ) : (
        createImageAnswerButton
      )}
      {!!uploaderConfig && (
        <IdsDialogUploader
          uppy={uppy}
          supportedUploaders={{
            [UploadType.SingleLocationImage]: {
              disabledImageTypes,
              defaultLocationId: uploaderConfig.locationId,
              defaultProjectId: uploaderConfig.projectId,
              initialValues: uploaderInitValues,
              onUploadComplete: handleUploadComplete,
            } as ISingleLocationImageUploaderProps,
          }}
          fileSelectionStepLabel='Select image'
          stopSubmitPropagation={true}
          dialogProps={{
            open: uploaderOpen,
            onClose: closeUploader,
            title,
            dialogKey: `upload${questionId}`,
          }}
        />
      )}
    </div>
  );
};

export default EditableImageAnswer;
