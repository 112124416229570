import { TOP_BAR_HEIGHT } from '../components/TopBar';

const styles = {
  rootPage: (theme: any) => ({
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  }),
};

// Take full view minus top bar
export const FULL_HEIGHT_WITHOUT_TOP_BAR = `calc(100dvh - ${TOP_BAR_HEIGHT + 1}px)`;

export default styles;
