import Keycloak from 'keycloak-js';

import isPhygii from './utils/phygii/isPhygii';
import RuntimeConfig from './RuntimeConfig';

const keycloak = new Keycloak({
  url: RuntimeConfig.keycloakUrl,
  realm: 'ids-platform',
  clientId: isPhygii()
    ? RuntimeConfig.phygiiKeycloakClientId
    : RuntimeConfig.explorerKeycloakClientId,
});

/** If set, will return a custom url to redirect to after logout. */
export const getRedirectAfterLogoutUrl = (): string | undefined => {
  return keycloak.tokenParsed?.redirect_after_logout_url;
};

export default keycloak;
