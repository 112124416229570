import { SingleLocationImageUploader } from '../hooks/uploaders/useSingleLocationImageUploader';
import { BatchLocationImageUploader } from '../hooks/uploaders/useBatchLocationImageUploader';
import { TenantLogoUploader } from '../hooks/uploaders/useTenantLogoUploader';
import { OrgLogoUploader } from '../hooks/uploaders/useOrgLogoUploader';

import { UploadType, IUploader } from './uploads';

const emptyUploaderValue = {
  formProps: {
    initialValues: {},
  },
  uploadSteps: [],
};

const EmptyUploader: IUploader = {
  uploadType: UploadType.None,
  label: 'None',
  getUploadContext: () => ({}),
  isAvailable: () => true,
  useUploader: (enabled: boolean) => (enabled ? emptyUploaderValue : null),
  configureUppy: () => null,
  deconfigureUppy: () => null,
};

/** Maps upload type to corresponding uploader. */
export const UPLOADERS: Record<UploadType, IUploader> = {
  [UploadType.None]: EmptyUploader,
  [UploadType.SingleLocationImage]: SingleLocationImageUploader,
  [UploadType.BatchLocationImage]: BatchLocationImageUploader,
  [UploadType.TenantLogo]: TenantLogoUploader,
  [UploadType.OrgLogo]: OrgLogoUploader,
};
