import { gql } from 'graphql-request';

import AssignmentSection, {
  AssignmentSectionFrag,
  IAssignmentSectionFragType,
} from './AssignmentSection';

export const AssignmentFrag = gql`
  fragment AssignmentFrag on Assignment {
    id
    assignmentSections {
      edges {
        node {
          id
          ...AssignmentSectionFrag
        }
      }
    }
  }
  ${AssignmentSectionFrag}
`;

export interface IAssignmentFragType {
  id: string;
  assignmentSections: {
    edges: {
      node: IAssignmentSectionFragType;
    }[];
  };
}

export interface IAssignmentProps {
  assignment: IAssignmentFragType;
}

const Assignment: React.FC<IAssignmentProps> = ({ assignment }) => {
  return (
    <>
      {assignment.assignmentSections.edges.map(e => (
        <AssignmentSection key={e.node.id} section={e.node} />
      ))}
    </>
  );
};

export default Assignment;
