import { useMutation } from 'react-query';

import queryClient from '../utils/query';
import axios from '../utils/axios';
import runtimeConfig from '../utils/RuntimeConfig';
import { NavigationSource } from '../atoms/navigationSource';

import { useLocationKeys } from './LocationService';

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};
const s3UrlToAutoTrimLambda = async (s3Url: string) => {
  return axios.post(runtimeConfig.rasterOverlayAutoTrimUrl, s3Url, {
    headers: {
      'x-auth-version': '2',
    },
  });
};
export function useS3UrlToAutoTrimLambda() {
  return useMutation(s3UrlToAutoTrimLambda, {
    onError,
  });
}
const cordsAndS3UrlToManualTrimLambda = async ({
  retryType,
  s3Url,
  cords,
}: {
  retryType: string;
  s3Url: string;
  cords: string;
}) => {
  return axios.post('[PLACE HOLDER API URL]', {
    retryType: retryType,
    s3Url: s3Url,
    cords,
  });
};
export function useCordsAndS3UrlToManualTrimLambda() {
  return useMutation(cordsAndS3UrlToManualTrimLambda, {
    onError,
  });
}
export type GCP = {
  /** x position on overlay image */
  pixel: string;
  /** y position on overlay image */
  line: string;
  /** longitude */
  easting: string;
  /** latitude */
  northing: string;
  /** altitude */
  elevation?: string;
};

export interface ICreateRasterOverlayPayload {
  locationId: string;
  s3ImagePath: string;
  gcp: GCP[];
  name: string;
  levels: string[];
  defaultEnabled?: boolean;
  navigationSource?: NavigationSource;
}

const createRasterOverlay = ({
  locationId,
  s3ImagePath,
  gcp,
  name,
  levels,
  defaultEnabled,
  navigationSource = NavigationSource.Unknown,
}: ICreateRasterOverlayPayload) => {
  return axios.post(
    `/api/v2/locations/${locationId}/raster_overlays/create_through_image`,
    {
      s3_image_path: s3ImagePath,
      gcp,
      name,
      levels,
      // Indicates that the overlay has been shifted to mapbox and does not require manual shifting from bing maps placement
      shifted: true,
      default: defaultEnabled,
    },
    {
      ...(navigationSource === NavigationSource.Capture && {
        headers: {
          'x-client-bypass-ro-permissions': 'IDS_Mobile_Capture', // tell BE to ignore usual role check when redirected here from Capture
        },
      }),
    },
  );
};

export function useCreateRasterOverlay() {
  const locationKeys = useLocationKeys();

  return useMutation(createRasterOverlay, {
    onError,
    onSuccess: (_: any, { locationId }: ICreateRasterOverlayPayload) => {
      queryClient.invalidateQueries(locationKeys.map(locationId), { refetchActive: false });
      queryClient.invalidateQueries(locationKeys.rasterOverlays(locationId), {
        refetchActive: false,
      });
    },
  });
}

export interface IResizeOverlayFilePayload {
  file: string;
  size?: number;
}

/*
  {
    "key": "ro_source_images/f579f59a54d1a39fb81fa866da83de84_1024.png",
    "url": "FULL_URL_HERE"
  }
*/
type FileResponse = {
  key: string;
  url: string;
  height: number;
  width: number;
  size: number;
};
interface IPreprocessOverlayFileResponse {
  resized: FileResponse;
  original: FileResponse;
}

export function usePreprocessOverlayFile() {
  return useMutation(({ file, size }: IResizeOverlayFilePayload) => {
    return axios.get<IPreprocessOverlayFileResponse>(runtimeConfig.rasterOverlayPreprocessUrl, {
      params: {
        file,
        size,
      },
      headers: {
        'x-auth-version': '2',
      },
    });
  });
}
