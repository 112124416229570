import React from 'react';

import TenantPage from '../TenantPage';

import TenantProgramsList from './TenantProgramsList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantProgramsProps {}

const TenantPrograms: React.FC<ITenantProgramsProps> = () => {
  return (
    <TenantPage title='Programs'>
      <TenantProgramsList />
    </TenantPage>
  );
};

export default TenantPrograms;
