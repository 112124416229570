import React from 'react';
import { Button, ButtonProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsDeleteButtonProps extends ButtonProps {}

const IdsDeleteButton: React.FC<IIdsDeleteButtonProps> = props => {
  return (
    <Button variant='contained' color='error' {...props}>
      Delete
    </Button>
  );
};

export default IdsDeleteButton;
