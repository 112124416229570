import { gql } from 'graphql-request';
import { useMutation } from 'react-query';

import axios from '../utils/axios';
import queryClient from '../utils/query';
import useGraphQuery from '../hooks/useGraphQuery';

export const profileKeys = {
  all: () => ['profile'],
  details: () => [...profileKeys.all(), 'details'],
  settings: () => [...profileKeys.all(), 'settings'],
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

const updateSettings = (values: any) => {
  return axios.put('/api/v2/users/profile_settings', {
    user_profile_settings: {
      ...values,
    },
  });
};

export const useUpdateSettings = () => {
  return useMutation(updateSettings, {
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(profileKeys.settings(), {
        refetchActive: true,
      });
    },
  });
};

const ProfileDetailsQuery = gql`
  query Me($skipDetails: Boolean!) {
    me {
      id
      firstName
      lastName
      phone @skip(if: $skipDetails)
      title @skip(if: $skipDetails)
    }
  }
`;

interface IProfileDetailsQuery {
  me: {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    title: string;
  };
}

export const useGetProfileDetails = (skipDetails = false, enabled = true) => {
  return useGraphQuery<IProfileDetailsQuery>(
    [...profileKeys.details(), skipDetails],
    ProfileDetailsQuery,
    { skipDetails },
    { enabled },
  );
};
