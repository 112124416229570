import React, { useState, useEffect, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';

import { getMediaTypeFromUrn } from '../../../../utils/media';
import IdsListItemMenu from '../../../ids-lists/IdsListItemMenu';
import { CloseIcon } from '../../../../theme/icons';
import KRPanoControl from '../../KRPanoControl';
import usePermissions from '../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../constants/users';
import { MediaType } from '../../../../constants/media';
import { sessionState } from '../../../../atoms/session';
import { EDITABLE_TYPES } from '../MediaViewerInfoTab/ImageInfoTable/types';
import { BUTTON_TYPE } from '../index';
import styles from '../IdsMediaViewer.module.css';

import { DELETABLE_TYPES } from './DeleteButton/useDeleteMedia';
import FlagButton, { IFlagButtonProps } from './FlagButton';
import DeleteButton, { IDeleteButtonProps } from './DeleteButton';
import EditButton from './EditButton';
import DownloadButton from './DownloadButton';
import GenericButton from './GenericButton';

export interface IButtonsBarProps {
  hideFlag: boolean;
  disableFlag: boolean;
  hideDelete: boolean;
  hideEdit: boolean;
  hideClose: boolean;
  hideFullScreen: boolean;
  viewerFullScreen: boolean;
  setViewerFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  isInfoTabEditMode: boolean;
  setIsInfoTabEditMode: React.Dispatch<React.SetStateAction<boolean>>;

  activeImage: any;
  fullActiveImage: any;
  activeId: any;
  activeMediaId: any;
  hideContextMenu: boolean;

  actionButtons: {
    type: keyof typeof BUTTON_TYPE;
    onClick: (fullActiveImage: any) => void;
    className: string;
    label: string;
    icon: React.ReactNode;
    props?: Record<string, any>;
  }[];

  close: () => void;
  onDelete: IDeleteButtonProps['onDelete'];
  onFlagUpdate: IFlagButtonProps['onUpdate'];
}

const EDIT_KEY = 'edit';

const ButtonsBar: React.FC<IButtonsBarProps> = ({
  isInfoTabEditMode,
  setIsInfoTabEditMode,
  viewerFullScreen,
  setViewerFullscreen,
  hideFlag,
  disableFlag,
  hideDelete,
  hideEdit,
  hideClose,
  hideFullScreen,
  hideContextMenu,
  activeId,
  activeMediaId,
  activeImage,
  fullActiveImage,
  actionButtons,
  close,
  onDelete,
  onFlagUpdate,
}) => {
  const userSession = useRecoilValue(sessionState);

  const [outsideMenuButtons, setOutsideMenuButtons] = useState<string[]>([]);

  const mediaType = getMediaTypeFromUrn(activeId ?? activeMediaId);
  /**
   * !!userSession?.id === FALSE
   * when current user is not authenticated (e.g. viewing public collection
   * in incognito mode).
   */
  const isAuthor = !!userSession?.id && fullActiveImage?.author?.id === userSession.id;

  const { userHasOneOfRoles } = usePermissions();
  const canUpdate = useMemo(() => {
    if (hideEdit) return false;

    if (mediaType === MediaType.PanoramicPhoto || mediaType === MediaType.HDPhoto) {
      return userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ]);
    }

    if (mediaType === MediaType.ProjectPhoto) {
      if (
        userHasOneOfRoles([
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_MANAGER,
          USER_ROLES.IDS_ADMIN,
          USER_ROLES.IDS_TEAM,
          USER_ROLES.TENANT_ADMIN,
          USER_ROLES.TENANT_TEAM,
        ])
      ) {
        return true;
      }

      return isAuthor;
    }

    return false;
  }, [userHasOneOfRoles, mediaType, isAuthor, hideEdit]);

  useEffect(() => {
    if (isInfoTabEditMode && !outsideMenuButtons.includes(EDIT_KEY)) {
      setOutsideMenuButtons(prev => [...prev, EDIT_KEY]);
    } else if (!isInfoTabEditMode && outsideMenuButtons.includes(EDIT_KEY)) {
      setOutsideMenuButtons(prev => prev.filter(prevKey => prevKey !== EDIT_KEY));
    }
  }, [outsideMenuButtons, isInfoTabEditMode]);

  const buttons = [
    {
      key: 'flag',
      visible: !hideFlag && activeImage?.id,
      type: BUTTON_TYPE.action,
      component: (
        <FlagButton
          onUpdate={onFlagUpdate}
          isFlagged={fullActiveImage?.flagged}
          imageId={fullActiveImage?.id}
          disabled={disableFlag}
          inMenu={!outsideMenuButtons.includes('flag')}
        />
      ),
    },
    {
      key: 'delete',
      visible: canUpdate && !hideDelete && mediaType && DELETABLE_TYPES.includes(mediaType),
      type: BUTTON_TYPE.action,
      component: (
        <DeleteButton
          onDelete={onDelete}
          urn={activeId ?? activeMediaId}
          inMenu={!outsideMenuButtons.includes('delete')}
        />
      ),
    },
    {
      key: EDIT_KEY,
      visible: canUpdate && mediaType && EDITABLE_TYPES.includes(mediaType),
      type: BUTTON_TYPE.mode,
      component: (
        <EditButton
          onClick={() => {
            setIsInfoTabEditMode(prev => !prev);
            setOutsideMenuButtons(prev => [...prev, EDIT_KEY]);
          }}
          isEditMode={isInfoTabEditMode}
          inMenu={!outsideMenuButtons.includes(EDIT_KEY)}
        />
      ),
    },
    {
      key: 'download',
      visible: true,
      type: BUTTON_TYPE.action,
      component: (
        <DownloadButton
          activeId={activeId}
          activeMediaId={activeMediaId}
          inMenu={!outsideMenuButtons.includes('download')}
        />
      ),
    },
  ];

  if (actionButtons?.length) {
    actionButtons.forEach(({ onClick, className, icon, props, type, label }, i) =>
      buttons.push({
        key: `actionBtn${i}`,
        visible: true,
        type: type || BUTTON_TYPE.action,
        component: (
          <GenericButton
            key={`actionBtn${i}`}
            onClick={() => {
              onClick(fullActiveImage);

              if (type !== BUTTON_TYPE.mode) {
                return;
              }

              setOutsideMenuButtons(prev => [...prev, `actionBtn${i}`]);
            }}
            inMenu={!outsideMenuButtons.includes(`actionBtn${i}`)}
            className={className}
            icon={icon}
            props={props}
            label={label}
          />
        ),
      }),
    );
  }

  return (
    <Box className={styles.buttonBar}>
      <KRPanoControl className={undefined}>
        {/* Dropdown menu */}
        {!hideContextMenu && (
          <>
            {/* Buttons outside the menu */}
            {outsideMenuButtons.map(buttonKey => {
              const button = buttons.find(button => button.key === buttonKey);
              if (!button || button.type !== BUTTON_TYPE.mode) {
                return null;
              }

              return (
                <Box
                  className={clsx(styles.actionButton, styles.boxActionButton)}
                  key={button.key}
                  onClick={() =>
                    setOutsideMenuButtons(prev => prev.filter(prevKey => prevKey !== button.key))
                  }
                >
                  <React.Fragment key={button.key}>{button.component}</React.Fragment>
                </Box>
              );
            })}

            <IdsListItemMenu
              buttonProps={{ disabled: !!outsideMenuButtons.length }}
              containerProps={{
                component: props => (
                  <Box
                    {...props}
                    className={clsx(styles.actionButton, styles.boxActionButton, props.className)}
                  />
                ),
              }}
            >
              {buttons.map(button => {
                if (!outsideMenuButtons.includes(button.key) && button.visible) {
                  return <React.Fragment key={button.key}>{button.component}</React.Fragment>;
                }

                return null;
              })}
            </IdsListItemMenu>
          </>
        )}

        {/* Fullscreen */}
        {!hideFullScreen && (
          <IconButton
            onClick={() => setViewerFullscreen(!viewerFullScreen)}
            className={styles.actionButton}
          >
            {!viewerFullScreen ? <Fullscreen /> : <FullscreenExit />}
          </IconButton>
        )}

        {/* Close */}
        {!hideClose && (
          <IconButton onClick={close} className={styles.actionButton}>
            <CloseIcon />
          </IconButton>
        )}
      </KRPanoControl>
    </Box>
  );
};

export default ButtonsBar;
