import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRecoilValue } from 'recoil';

import { generateReport } from '../../../services/ReportsService';
import { DocumentType, IAssignmentData, ReportType, SettingsType } from '../types';
import IdsSelect from '../../../components/ids-inputs/IdsSelect';
import { activeOrganizationState } from '../../../atoms/organizations';
import { optionsBuilder } from '../Settings/ReportSettings';

import styles from '../LoadAssignmentForm/FormFields/FormFields.module.css';
import { useGetOrganizationAssignments } from '../../../services/AssignmentsService';

export interface IReportGeneratingProps {
  settings: SettingsType;
  projectIds: string[];
  assignmentId: string;
  isEmptyReport: boolean;
}

const reportTypeOptions = optionsBuilder(ReportType);

const ReportGenerating: React.FC<IReportGeneratingProps> = ({
  settings,
  projectIds,
  assignmentId,
  isEmptyReport,
}) => {
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState(ReportType.CSV);
  const [data, setData] = useState<IAssignmentData | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const activeOrg = useRecoilValue(activeOrganizationState);

  // this takes cached data
  const {
    data: organizationAssignments,
    isLoading,
    error,
  } = useGetOrganizationAssignments(activeOrg.id, {
    enabled: !!activeOrg.id,
  });

  useEffect(() => {
    if (!organizationAssignments) {
      return;
    }

    // Find the specific assignment from the list of assignments
    const assignmentNeeded = organizationAssignments.find(
      assignment => assignment.node.id === assignmentId,
    );

    if (assignmentNeeded) {
      const formattedData: IAssignmentData = {
        id: assignmentNeeded.node.id,
        organizationId: activeOrg.id,
        title: assignmentNeeded.node.title,
        projectIds,
        sections: [],
      };
      setData(formattedData); // Set the formatted data
    }
  }, [organizationAssignments, assignmentId, projectIds, activeOrg.id]);

  useEffect(() => {
    if (!error) {
      return;
    }
    enqueueSnackbar('Failed to retrieve assignment data. Please try again.', {
      variant: 'error',
    });
  }, [error, enqueueSnackbar]);

  const handleOnClick = async (type: DocumentType) => {
    if (!data) {
      return;
    }
    if (isEmptyReport) {
      enqueueSnackbar('No sections are selected.', {
        variant: 'error',
      });
      return;
    }

    setLoading(true);
    try {
      await generateReport(
        data,
        type === DocumentType.PDF ? settings : { ...settings, reportType },
        type,
      );
      enqueueSnackbar(
        `${type} report request submitted. You will receive an email when processing is complete.`,
        { variant: 'success' },
      );
    } catch {
      enqueueSnackbar(`Failed to request ${type} report generation. Please try again.`, {
        variant: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <Grid container direction='column' gap={2}>
      <Grid item xs>
        <Button
          disabled={loading || isLoading}
          variant='contained'
          color='secondary'
          onClick={() => handleOnClick(DocumentType.PDF)}
        >
          Generate PDF Report
        </Button>
      </Grid>

      <Grid item xs>
        <hr />
      </Grid>

      <Grid item xs container direction='column'>
        <Grid item xs>
          <Typography variant='h2'>Generate a CSV Report</Typography>
          <p>
            CSV results are currently not filtered, there will be a column for every question in the
            assignment.
          </p>
        </Grid>

        <Grid item xs='auto' container direction='column' gap={2} className={styles.container}>
          <Grid item xs='auto'>
            <IdsSelect
              fullWidth={false}
              label='Report Type'
              options={reportTypeOptions}
              defaultValue={reportType}
              onChange={e => setReportType(e.target.value as ReportType)}
            />
          </Grid>

          <Grid item xs='auto'>
            <Button
              disabled={loading || isLoading}
              variant='contained'
              color='secondary'
              onClick={() => handleOnClick(DocumentType.CSV)}
            >
              Generate CSV Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportGenerating;
