import { DialogActions } from '@mui/material';
import React from 'react';
import { useFormikContext } from 'formik';

import CancelButton from '../../ids-buttons/CancelButton';
import SubmitButton from '../../ids-buttons/SubmitButton';
import IdsForm, { IIdsFormProps } from '../IdsForm';

export interface IIdsEditFormProps extends IIdsFormProps {
  onCancel?: () => any;
  disableSubmitButton?: boolean;
  disableSubmitIfClean?: boolean;
}

export const EditFormButtons: React.FC<
  Pick<IIdsEditFormProps, 'onCancel' | 'disableSubmitButton' | 'disableSubmitIfClean'>
> = ({ onCancel, disableSubmitButton, disableSubmitIfClean }) => {
  const { dirty } = useFormikContext();
  return (
    <DialogActions>
      <CancelButton onClick={onCancel} />
      <SubmitButton
        disabled={disableSubmitButton || (!dirty && disableSubmitIfClean)}
        label='Save'
      />
    </DialogActions>
  );
};

const IdsEditForm: React.FC<IIdsEditFormProps> = ({
  children,
  onCancel,
  disableSubmitButton,
  disableSubmitIfClean = false,
  ...rest
}) => {
  return (
    <IdsForm {...rest}>
      {children}
      <EditFormButtons
        disableSubmitButton={disableSubmitButton}
        onCancel={onCancel}
        disableSubmitIfClean={disableSubmitIfClean}
      />
    </IdsForm>
  );
};

export default IdsEditForm;
