import React from 'react';
import { useRecoilValue } from 'recoil';

import IdsSearchableList from '../../../../components/ids-lists/IdsSearchableList';
import { searchLocationItem, sortLocationItems } from '../../../Locations';
import TenantLocationListItem from '../TenantLocationsListItem';
import { ITenantLocationItem, useGetTenantLocations } from '../../../../services/TenantService';
import { activeTenantState } from '../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantLocationsListProps {}

const renderItem = (location: ITenantLocationItem) => (
  <TenantLocationListItem key={location.node.id} locationItem={location} />
);

const TenantLocationsList: React.FC<ITenantLocationsListProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { data, error, isLoading } = useGetTenantLocations(activeTenant!.id);

  return (
    <IdsSearchableList
      items={data}
      renderItem={renderItem}
      searchPlaceholder='Search locations'
      searchItem={searchLocationItem}
      sortItems={sortLocationItems}
      noItemsMessage='No locations'
      noItemsForFilterMessage='No locations match your search'
      loading={isLoading}
      error={error && 'Locations could not be loaded'}
      header={undefined}
      filters={undefined}
      filterMode={undefined}
      actions={undefined}
    />
  );
};

export default TenantLocationsList;
