import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { clsx } from 'clsx';

import { ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, ArrowDownIcon } from '../../theme/icons';

import styles from './ResizeControl.module.css';

export const RESIZE_DIRECTIONS = {
  /** Grow to the left. */
  LEFT: 'left',
  /** Grow to the right. */
  RIGHT: 'right',
  /** Grow up. */
  UP: 'up',
  /** Grow down. */
  DOWN: 'down',
};

const ORIENTATIONS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const DIRECTION_TO_ORIENTATION = {
  [RESIZE_DIRECTIONS.LEFT]: ORIENTATIONS.VERTICAL,
  [RESIZE_DIRECTIONS.RIGHT]: ORIENTATIONS.VERTICAL,
  [RESIZE_DIRECTIONS.UP]: ORIENTATIONS.HORIZONTAL,
  [RESIZE_DIRECTIONS.DOWN]: ORIENTATIONS.HORIZONTAL,
};

const ORIENTATION_ICONS = {
  [ORIENTATIONS.VERTICAL]: [ArrowLeftIcon, ArrowRightIcon],
  [ORIENTATIONS.HORIZONTAL]: [ArrowUpIcon, ArrowDownIcon],
};

const ORIENTATION_CLASS_NAMES = {
  [ORIENTATIONS.VERTICAL]: [styles.leftBtn, styles.rightBtn],
  [ORIENTATIONS.HORIZONTAL]: [styles.upBtn, styles.downBtn],
};

const ResizeControl = ({ direction, canGrow, onGrow, canShrink, onShrink, className, ...rest }) => {
  const orientation = useMemo(() => DIRECTION_TO_ORIENTATION[direction], [direction]);

  const arrowClassNames = useMemo(() => ORIENTATION_CLASS_NAMES[orientation], [orientation]);

  const firstArrowAction = useMemo(() => {
    return direction === RESIZE_DIRECTIONS.LEFT || direction === RESIZE_DIRECTIONS.UP
      ? onGrow
      : onShrink;
  }, [direction, onGrow, onShrink]);

  const firstActionDisabled = useMemo(() => {
    return direction === RESIZE_DIRECTIONS.LEFT || direction === RESIZE_DIRECTIONS.UP
      ? !canGrow
      : !canShrink;
  }, [direction, canGrow, canShrink]);

  const secondArrowAction = useMemo(() => {
    return direction === RESIZE_DIRECTIONS.RIGHT || direction === RESIZE_DIRECTIONS.DOWN
      ? onGrow
      : onShrink;
  }, [direction, onGrow, onShrink]);

  const secondActionDisabled = useMemo(() => {
    return direction === RESIZE_DIRECTIONS.RIGHT || direction === RESIZE_DIRECTIONS.DOWN
      ? !canGrow
      : !canShrink;
  }, [direction, canGrow, canShrink]);

  const arrowIcons = useMemo(() => ORIENTATION_ICONS[orientation], [orientation]);
  const FirstArrowIcon = useMemo(() => arrowIcons[0], [arrowIcons]);
  const SecondArrowIcon = useMemo(() => arrowIcons[1], [arrowIcons]);

  return (
    <div
      className={clsx(styles.container, className, {
        [styles.horizontalContainer]: orientation === ORIENTATIONS.HORIZONTAL,
        [styles.verticalContainer]: orientation === ORIENTATIONS.VERTICAL,
      })}
      {...rest}
    >
      <Button
        className={clsx(styles.arrowBtn, arrowClassNames[0])}
        onClick={firstArrowAction}
        disabled={firstActionDisabled}
      >
        <FirstArrowIcon className={styles.arrows} />
      </Button>
      <Button
        className={clsx(styles.arrowBtn, arrowClassNames[1])}
        onClick={secondArrowAction}
        disabled={secondActionDisabled}
      >
        <SecondArrowIcon className={styles.arrows} />
      </Button>
    </div>
  );
};

ResizeControl.defaultProps = {
  direction: RESIZE_DIRECTIONS.LEFT,
  canGrow: true,
  canShrink: true,
};

ResizeControl.propTypes = {
  direction: PropTypes.string,
  canGrow: PropTypes.bool,
  onGrow: PropTypes.func,
  canShrink: PropTypes.bool,
  onShrink: PropTypes.func,
};

export default ResizeControl;
