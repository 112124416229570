import { blueGrey, common } from '@mui/material/colors';

import { THEMES } from '../../constants';
import { softShadows, strongShadows } from '../shadows';
import typography from '../typography';
import IDS_COLORS from '../ids-colors';

const explorerThemeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      // action: {
      //   active: blueGrey[600]
      // },
      background: {
        default: '#f4f6f8',
        dark: '#f4f6f8',
        paper: common.white,
      },
      primary: {
        main: '#2F3D52',
        contrastText: '#fff',
      },
      secondary: {
        main: '#21B5F5',
        contrastText: '#fff',
      },
      // text: {
      //   primary: blueGrey[900],
      //   secondary: blueGrey[600]
      // }
    },
    shadows: softShadows,
  },
  {
    name: THEMES.DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: '#efefef',
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: {
        main: '#a67dff',
      },
      secondary: {
        main: '#a67dff',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
];

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
  palette: {
    ids: {
      ...IDS_COLORS,
    },
  },
};

export { explorerThemeConfigs, baseConfig };
