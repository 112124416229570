import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { activeProjectRouteState } from '../../../atoms/projects';
import { activeOrganizationState } from '../../../atoms/organizations';
import { navigationExpanded } from '../../../atoms/navigation';

import { MediaType, RoutePointType } from '../../../constants/media';
import { getDefaultTypeMetadata } from '../../../constants/filtering';

import { ImmersiveViewerProvider } from '../../../context/ImmersiveViewerContext';
import { MapContextMenuProvider } from '../../../context/MapContextMenuContext';
import { FilterProvider } from '../../../context/FilterContext';
import { ItemFilterNotificationProvider } from '../../../context/ItemFilterNotificationContext';
import { LayerEventsProvider } from '../../../context/LayerEventsContext';

import QueryLoader from '../../../components/QueryLoader';
import { IRouteDetailsData, useGetRouteDetails } from '../../../services/RouteService';

import RouteMapPageContent, { IRouteMapPageContentProps } from './RouteMapPageContent';
import FilterInitializers from './FilterInitializers';
import { getDefaultRoutePointTypeMetadata } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRouteDetailsPageWrapperProps {}

/**
 * Just a wrapper to load route details.
 */
const RouteDetailsPageWrapper = () => {
  const activeProjectRoute = useRecoilValue(activeProjectRouteState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const setNavExpanded = useSetRecoilState(navigationExpanded);
  useEffect(() => {
    setNavExpanded(false);
  }, [setNavExpanded]);

  const [route, setRoute] = useState<IRouteMapPageContentProps['route'] | null>(null);

  const useQuery = () =>
    useGetRouteDetails(activeProjectRoute!.id, activeProjectRoute!.projectId, activeOrg.id);

  return (
    <QueryLoader
      useQuery={useQuery}
      setData={setRoute}
      selectData={(data: IRouteDetailsData) => data.route}
      render={() => {
        return (
          <LayerEventsProvider events={null}>
            <RouteMapPageContent key={route!.id} route={route!} />
          </LayerEventsProvider>
        );
      }}
    />
  );
};

const FILTER_TYPES = [
  MediaType.RasterOverlay,
  RoutePointType.ProjectPhoto,
  RoutePointType.DslrHdPhoto,
  RoutePointType.Panorama,
  RoutePointType.DslrPanorama,
];
const DEFAULT_TYPE_METADATA = {
  ...getDefaultTypeMetadata([MediaType.RasterOverlay]),
  ...getDefaultRoutePointTypeMetadata([
    RoutePointType.ProjectPhoto,
    RoutePointType.DslrHdPhoto,
    RoutePointType.Panorama,
    RoutePointType.DslrPanorama,
  ]),
};

/**
 * Initializes all the necessary contexts.
 */
const RouteDetailsPageContext: React.FC<IRouteDetailsPageWrapperProps> = () => {
  return (
    <ImmersiveViewerProvider>
      <FilterProvider types={FILTER_TYPES} defaultTypeMetadata={DEFAULT_TYPE_METADATA}>
        <MapContextMenuProvider>
          <FilterInitializers />
          <ItemFilterNotificationProvider>
            <RouteDetailsPageWrapper />
          </ItemFilterNotificationProvider>
        </MapContextMenuProvider>
      </FilterProvider>
    </ImmersiveViewerProvider>
  );
};

export default RouteDetailsPageContext;
