import { ITenant } from '../../services/TenantService';

export const PHYGII_TENANT_SUBDOMAIN = 'phygii';

const isPhygiiTenant = (tenant: ITenant) => {
  return tenant.subdomain === PHYGII_TENANT_SUBDOMAIN;
};

const isPhygiiSubdomain = (subdomain: string) => {
  return subdomain === PHYGII_TENANT_SUBDOMAIN;
};

export { isPhygiiSubdomain };

export default isPhygiiTenant;
