import { DeckProps } from '@deck.gl/core/typed';

import { MediaMetadataType, RoutePointType } from '../../../../../constants/media';
import { IMarkerPosition } from '../../../../../hooks/useMapMarkerPlacementTool';

/**
 * @TODO: Makes sense to refactor these types and interfaces to get rid of
 * PhotoTag todo comments. Most likely it will not be added in the near future.
 */

export interface IMetadata {
  [MediaMetadataType.Level]: string | null;
  [MediaMetadataType.Area]: string | null;

  // @TODO: add it when possible
  // [MediaMetadataType.PhotoTag]: string | null;

  // Only for project photos
  [MediaMetadataType.PhotoCategory]?: string | null;
  [MediaMetadataType.PhotoType]?: string | null;
}

// @TODO: get rid of this type when PhotoTag is added
export type MetadataType = keyof IMetadata;

/**
 * @TODO: add a separate field for metadata.
 */
export interface ISettings extends IMetadata {
  imageType: RoutePointType | null;

  // Only for panoramas
  heading?: number;
}

export interface IOption {
  value: string;
  label: string;
}

export type MetadataOptionsType = {
  [key in MetadataType]: IOption[];
};

export interface IPlacedPoint {
  position: IMarkerPosition;
}

export enum Mode {
  AddOnly = 'AddOnly',
  EditOnly = 'EditOnly',
  AddAndEdit = 'AddAndEdit',
}

export type OnClickEventType = Parameters<NonNullable<DeckProps['onClick']>>[1];
export type OnDragEventType = Parameters<NonNullable<DeckProps['onDrag']>>[1];
