import { atom } from 'recoil';

const timelineMarksState = atom({
  key: 'timelineMarks',
  default: null,
});

const timelineGroupingState = atom({
  key: 'timelineGrouping',
  default: 'Dates',
});

const timelineValueState = atom({
  key: 'timelineValue',
  default: null,
});

const startDateState = atom({
  key: 'startDate',
  default: null,
});

const endDateState = atom({
  key: 'endDate',
  default: null,
});

export {
  startDateState,
  endDateState,
  timelineMarksState,
  timelineGroupingState,
  timelineValueState,
};
