import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

import { activeOrganizationState } from '../atoms/organizations';
import { activeTenantState } from '../atoms/tenants';

const useGraphId = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  return useMemo(() => {
    if (activeOrg?.id) {
      return {
        orgId: activeOrg?.id,
      };
    }

    return {
      tenantId: activeTenant?.id,
    };
  }, [activeOrg?.id, activeTenant?.id]);
};

export default useGraphId;
