import React, { useCallback, useState } from 'react';
import { UploadResult } from '@uppy/core';
import { Dashboard, StatusBar } from '@uppy/react';
import {
  ChipProps,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
  ListItemProps,
} from '@mui/material';

import { IQueuedUpload } from '../../../../../context/UploadQueueContext';
import { UploadStatus } from '../../../../../constants/uploads';
import { UPLOADERS } from '../../../../../constants/uploaders';
import TotalChip from '../../../../../components/TotalChip';
import { CancelIcon } from '../../../../../theme/icons';
import IdsDialog from '../../../../IdsDialog';
import useConfirmation from '../../../../../hooks/useConfirmation';
import useUppyEventHandler from '../../../../../hooks/useUppyEventHandler';

import styles from './QueuedUpload.module.css';
import './style-overrides.css';

const UPLOAD_STATUS_CHIP_COLORS: Record<UploadStatus, ChipProps['color']> = {
  [UploadStatus.Queued]: 'default',
  [UploadStatus.Uploading]: 'info',
  [UploadStatus.Successful]: 'success',
  [UploadStatus.MixedResult]: 'warning',
  [UploadStatus.Failed]: 'error',
};

interface IQueuedUploadProps extends ListItemProps {
  queuedUpload: IQueuedUpload;
  removeUpload: (queuedUpload: IQueuedUpload) => void;
  divider?: boolean;
}

const QueuedUpload: React.FC<IQueuedUploadProps> = ({
  queuedUpload,
  removeUpload,
  divider = true,
  ...rest
}) => {
  const { uploadType, uppy, status, fileCount, context } = queuedUpload;

  const [uploadDashboardOpen, setUploadDashboardOpen] = useState(false);
  const [someGhostFiles, setSomeGhostFiles] = useState(uppy.getObjectOfFilesPerState().isSomeGhost);
  const { requestConfirmation } = useConfirmation();

  const { getUploadLabel, label, getIcon } = UPLOADERS[uploadType] || {};

  const IconType = getIcon && getIcon(uppy, context);
  const uploadLabel = (getUploadLabel && getUploadLabel(uppy, context)) || (
    <ListItemText primary={label} />
  );

  const openUploadDashboard = useCallback(() => {
    setUploadDashboardOpen(true);
  }, []);

  const toggleUploadDashboard = useCallback(() => {
    setUploadDashboardOpen(prev => !prev);
  }, []);

  const closeUploadDashboard = useCallback(() => {
    setUploadDashboardOpen(false);
  }, []);

  const handleRemoveUpload = useCallback(() => {
    // Don't require confirmation if the upload already succeeded or if uppy has no files (failed recovery)
    if (status === UploadStatus.Successful || !queuedUpload.uppy.getFiles().length) {
      removeUpload(queuedUpload);
      return;
    }

    requestConfirmation({
      title: 'Are you sure you want to cancel the upload?',
      description: 'The files will not be uploaded to the platform.',
      confirmButtonProps: {
        color: 'error',
      },
      confirmButtonLabel: 'Cancel upload',
      onConfirm: () => removeUpload(queuedUpload),
      cancelButtonLabel: 'Continue upload',
    });
  }, [status, removeUpload, queuedUpload, requestConfirmation]);

  const handleUploadComplete = useCallback(
    (result: UploadResult) => {
      if (result.successful.length === uppy.getFiles().length) {
        closeUploadDashboard();
      }
    },
    [uppy, closeUploadDashboard],
  );

  useUppyEventHandler('complete', handleUploadComplete, uppy);

  const updateSomeGhostState = useCallback(() => {
    setSomeGhostFiles(uppy.getObjectOfFilesPerState().isSomeGhost);
  }, [uppy]);

  useUppyEventHandler('files-added', updateSomeGhostState, uppy);

  const uploadable = uppy.getFiles().length && status !== UploadStatus.Successful;

  let totalChipTooltip: string | undefined;

  if (uploadable) {
    totalChipTooltip = someGhostFiles ? 'Reselect files' : 'Review upload';
  } else {
    totalChipTooltip =
      status === UploadStatus.Successful ? 'Upload complete' : 'Upload cannot be reviewed';
  }

  return (
    <>
      <ListItem dense divider={divider} key={uppy.getID()} {...rest}>
        <Grid container direction='column' wrap='nowrap'>
          <Grid
            item
            xs='auto'
            container
            direction='row'
            spacing={1}
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            <Grid item xs='auto'>
              <Tooltip title={totalChipTooltip} placement='left' arrow>
                <span>
                  <TotalChip
                    avatar={
                      IconType && (
                        <SvgIcon
                          sx={{
                            color: theme => {
                              const getColor = () => {
                                if (someGhostFiles) return theme.palette.warning.light;
                                if (status === UploadStatus.Queued)
                                  return theme.palette.text.secondary;
                                if (status === UploadStatus.Uploading)
                                  return theme.palette.info.light;
                                if (status === UploadStatus.Successful)
                                  return theme.palette.success.light;
                                if (status === UploadStatus.MixedResult)
                                  return theme.palette.warning.light;
                                if (status === UploadStatus.Failed)
                                  return theme.palette.error.light;
                                return theme.palette.text.secondary;
                              };
                              return `${getColor()} !important`; // !important is needed to override style from Chip component
                            },
                          }}
                        >
                          <IconType />
                        </SvgIcon>
                      )
                    }
                    loading={false}
                    active={false}
                    total={fileCount}
                    itemType='File'
                    pluralize={true}
                    size='small'
                    color={someGhostFiles ? 'warning' : UPLOAD_STATUS_CHIP_COLORS[status]}
                    {...(uploadable && {
                      clickable: true,
                      onClick: toggleUploadDashboard,
                    })}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs>
              {uploadLabel}
            </Grid>
            <Grid item xs='auto'>
              <Tooltip title={status === UploadStatus.Successful ? 'Dismiss' : 'Cancel'}>
                <IconButton onClick={handleRemoveUpload} size='small'>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {someGhostFiles && (
            <Grid item xs='auto'>
              <Typography variant='caption'>
                Upload recovery failed, file reselection required
              </Typography>
            </Grid>
          )}
          <Grid item xs='auto'>
            {[UploadStatus.Uploading, UploadStatus.MixedResult, UploadStatus.Failed].includes(
              status,
            ) &&
              !someGhostFiles && (
                <StatusBar
                  uppy={queuedUpload.uppy}
                  hideAfterFinish={false}
                  showProgressDetails={true}
                  hidePauseResumeButton={true}
                  hideUploadButton={true}
                  hideCancelButton={true}
                />
              )}
          </Grid>
        </Grid>
      </ListItem>
      <IdsDialog
        title={
          [UploadStatus.Failed, UploadStatus.MixedResult].includes(status)
            ? 'Retry Upload'
            : 'Review Upload'
        }
        open={uploadDashboardOpen}
        onOpen={openUploadDashboard}
        onClose={closeUploadDashboard}
        dialogKey={`upload-${uppy.getID()}`}
        maxWidth='lg'
        fullWidth
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
        classes={{
          paper: styles.dialog,
        }}
        className='queuedUploader'
      >
        {/* TODO: may need to use idsdialoguploader here instead to properly handle adding files, etc... */}
        {/* if files are added during ghost file reselection, then that is a problem */}
        <Dashboard
          uppy={uppy}
          hideCancelButton
          // Hide the retry button here, only allow retrying through queue
          // This simplifies upload retry handling
          hideRetryButton
          showRemoveButtonAfterComplete={false}
          width='100%'
          height='100%'
        />
      </IdsDialog>

      {/* <IdsDialogUploader
        uppy={uppy}
        supportedUploaders={{
          [uploadType]: {},
        }}
        fileSelectionStepLabel={queuedUpload.hasGhostFiles ? 'Reselect lost files' : 'Review files'}
        dialogProps={{
          title: [UploadStatus.Failed, UploadStatus.MixedResult].includes(status) ? 'Retry Upload' : 'Review Upload',
          open: uploaderOpen,
          onOpen: openUploader,
          onClose: closeUploader,
          dialogKey: `upload-${uppy.getID()}`,
        }}
      /> */}
    </>
  );
};

export default QueuedUpload;
