import React, { useMemo } from 'react';

import MapMarker from '../MapMarker';

import styles from './FOVRadar.module.css';

const FOVRadar = ({ direction, angle, ...rest }) => {
  const offsetDir = useMemo(() => {
    return direction - angle / 2;
  }, [direction, angle]);

  return (
    <MapMarker anchor='center' className={styles.container} disabledEvents={[]} {...rest}>
      <div className={styles.radar}>
        <div className={styles.radar__main_dial} style={{ transform: `rotate(${offsetDir}deg)` }}>
          <div className={styles.radar__window}>
            <div
              className={styles.radar__background}
              style={{ transform: `rotate(${angle}deg)` }}
            />
          </div>
          <div className={styles.radar__dot} />
        </div>
      </div>
    </MapMarker>
  );
};

FOVRadar.defaultProps = {
  direction: 0,
  angle: 0,
};

export default FOVRadar;
