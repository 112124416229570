import { useMutation } from 'react-query';
import { useMemo } from 'react';
import { gql } from 'graphql-request';

import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import { UseGraphQueryOptions } from '../hooks/useGraphQuery';

import keycloak from '../keycloak';
import axios from '../utils/axios';
import queryClient from '../utils/query';

import {
  IAssignmentData,
  IProcessedSection,
  AssignmentSectionEdge,
  DocumentType,
  SettingsType,
  IncludeHeroImage,
  IncludeWorkOrderSection,
  IncludeIdsBranding,
  IncludeTableOfContents,
  Sorting,
  LogoOption,
  Summary,
} from '../views/reporting/types';

import { useProjectKeys } from './ProjectService';

const AssignmentSettingsQuery = gql`
  query AssignmentReportSettingsQuery($assignmentId: ID!, $orgId: ID, $tenantId: ID) {
    assignment(id: $assignmentId, organizationId: $orgId, tenantId: $tenantId) {
      assignmentSections {
        edges {
          node {
            id
            title
            assignmentTasks {
              edges {
                node {
                  id
                  title
                  assignmentQuestions {
                    edges {
                      node {
                        id
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useReportKeys = () => {
  const queryIdKey = useQueryKeyId();

  return useMemo(() => {
    const reportKeys = {
      all: ['reports', ...queryIdKey],
      assignment: (assignmentId: string) => [...reportKeys.all, 'assignment', assignmentId],
    };
    return reportKeys;
  }, [queryIdKey]);
};

export const useGetAssignmentQuestions = (
  assignmentId: string | null,
  options?: UseGraphQueryOptions,
) => {
  const assignmentDataKeys = useReportKeys();

  return useOrgGraphQuery(
    !!assignmentId ? assignmentDataKeys.assignment(assignmentId) : '',
    AssignmentSettingsQuery,
    { assignmentId },
    {
      enabled: options?.enabled,
      ...options,
    },
  );
};

export const transformAssignmentQuestionsData = (
  assignmentSections: AssignmentSectionEdge[],
): IProcessedSection[] => {
  return assignmentSections.map(
    (sectionEdge: AssignmentSectionEdge): IProcessedSection => ({
      id: sectionEdge.node.id,
      title: sectionEdge.node.title,
      tasks: sectionEdge.node.assignmentTasks.edges.map(taskEdge => ({
        id: taskEdge.node.id,
        title: taskEdge.node.title,
        includeToReport: true,
        questions: taskEdge.node.assignmentQuestions.edges.map(questionEdge => ({
          id: questionEdge.node.id,
          title: questionEdge.node.title,
          includeToReport: true,
        })),
      })),
      includeToReport: true,
    }),
  );
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

export function useDeleteReport(projectId: string) {
  const projectKeys = useProjectKeys();

  return useMutation(
    ({ reportId }: { reportId: string }) => {
      return axios.delete(`/api/v2/projects/${projectId}/reports/${reportId}`);
    },
    {
      onError,
      onSuccess: async () => {
        // Only invalidate project queries in the context of this project
        await queryClient.invalidateQueries(projectKeys.detail(projectId), {
          refetchActive: true,
        });
      },
    },
  );
}

const DOCUMENT_TYPE_URL = {
  [DocumentType.CSV]: `${process.env.REACT_APP_REPORTING_API}/generate-csv-report`,
  [DocumentType.PDF]: `${process.env.REACT_APP_REPORTING_API}/generate-pdf-report`,
};

// generate a report request
export const generateReport = async (
  assignment: IAssignmentData,
  settings: SettingsType,
  type: DocumentType,
) => {
  const { id: assignmentId, projectIds, organizationId } = assignment;

  // convert projectIds to array of numbers
  const numericProjectIds = Array.isArray(projectIds)
    ? projectIds.map(id => Number(id))
    : projectIds
    ? [Number(projectIds)]
    : [];

  // Convert from Yes/No values to true/false
  const booleanSettings = {
    heroImage: settings.heroImage === IncludeHeroImage.Yes,
    generateWorkOrderSection: settings.generateWorkOrderSection === IncludeWorkOrderSection.Yes,
    idsBranding: settings.idsBranding === IncludeIdsBranding.Yes,
    generateTaskToC: settings.generateTaskToC === IncludeTableOfContents.Yes,
  };

  const payload = {
    assignmentId: assignmentId,
    projectIds: numericProjectIds,
    organizationId: organizationId,
    ...settings,
    ...booleanSettings,
  };

  // Remove the `sortResponses` if it is the default value 'No Sorting'
  if (settings.sortResponses === Sorting.NoSorting) {
    delete payload.sortResponses;
  }

  // Remove the `logoOption` if it is the default value 'No Logo'
  if (settings.logoOption === LogoOption.NoLogo) {
    delete payload.logoOption;
  }

  // Remove the `includeSummary` if it is the default value 'None'
  if (settings.summary === Summary.None) {
    delete payload.summary;
  }

  await axios.post(DOCUMENT_TYPE_URL[type], payload, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      'x-auth-version': '2',
    },
  });
};
