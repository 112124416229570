import React from 'react';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { IRouteDetails } from '../../../../../services/RouteService';
import RouteStatusChip from '../../../Detail/RoutesTab/RoutesList/RouteListItem/RouteStatusChip';

import ProjectChip from '../../../../../components/entity-chips/ProjectChip';
import LocationChip from '../../../../../components/entity-chips/LocationChip';

import { activeProjectState } from '../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../atoms/organizations';

import { useRouteProgress } from '../../hooks';

export interface IDetailsPaneProps {
  route: IRouteDetails;
}

const DetailsPane: React.FC<IDetailsPaneProps> = ({ route }) => {
  const activeProject = useRecoilValue(activeProjectState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { description, name, routePoints, status } = route;
  const { label, percentage } = useRouteProgress(routePoints);

  return (
    <Grid container direction='column' gap={1}>
      <Grid item xs>
        <Typography variant='h4'>{name}</Typography>
      </Grid>

      {!!description && (
        <Grid item xs>
          <Typography variant='body2' color='text.secondary'>
            {description}
          </Typography>
        </Grid>
      )}

      <Grid
        item
        xs
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        gap={2}
      >
        <Grid item xs='auto'>
          <Typography variant='body2' color='text.primary'>
            Progress
          </Typography>
        </Grid>
        <Grid item xs>
          <LinearProgress variant='determinate' value={percentage} color='secondary' />
        </Grid>
        <Grid item xs='auto'>
          <Typography variant='body2' color='text.primary'>
            {label}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs='auto'>
        <RouteStatusChip status={status} />
      </Grid>

      {/* Chips */}
      <Grid item xs container direction='row' justifyContent='space-between'>
        <Grid item xs='auto'>
          <LocationChip locationId={activeProject!.locationId} organizationId={activeOrg.id} />
        </Grid>

        <Grid item xs='auto'>
          <ProjectChip projectId={activeProject!.id} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsPane;
