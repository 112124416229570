import React from 'react';
import { Typography } from '@mui/material';

import IdsCheckbox, { IIdsCheckboxProps } from '../../../ids-inputs/IdsCheckbox';

export interface IMultiValueAutocompleteOptionProps extends HTMLLiElementProps {
  label: React.ReactNode;
  selected: boolean;
  checkboxProps?: Omit<IIdsCheckboxProps, 'checked' | 'label'>;
}

const MultiValueAutocompleteOption: React.FC<IMultiValueAutocompleteOptionProps> = ({
  label,
  selected,
  checkboxProps,
  ...rest
}) => (
  <li {...rest}>
    <IdsCheckbox
      label={<Typography variant='body2'>{label}</Typography>}
      checked={selected}
      size='small'
      {...checkboxProps}
    />
  </li>
);

export default MultiValueAutocompleteOption;
