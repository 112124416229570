import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash.get';

import IdsAutocomplete, { IIdsAutocompleteProps } from '../../ids-inputs/IdsAutocomplete';

// No need in onBlur, onFocus and value props
// Because this component will use formik context
export interface IIdsFormAutocompleteFieldProps
  extends Omit<IIdsAutocompleteProps, 'onBlur' | 'onFocus' | 'value'> {
  name: string;
}

const IdsFormAutocompleteField: React.FC<IIdsFormAutocompleteFieldProps> = ({
  name,
  label,
  options,
  getOptionLabel,
  getOptionValue,
  onChange,
  onInputChange,
  required,
  loading,
  id,
  ...rest
}) => {
  const { values, errors, touched, setFieldValue, handleBlur, validateOnChange } =
    useFormikContext<any>();

  const value = get(values, name);
  const touch = get(touched, name);
  const error = get(errors, name);

  const _onChange = useCallback(
    (event, selectedOptionValue, reason) => {
      setFieldValue(name, selectedOptionValue, validateOnChange);

      if (onChange) {
        onChange(event, selectedOptionValue, reason);
      }
    },
    [name, onChange, setFieldValue, validateOnChange],
  );

  const _onInputChange = useCallback(
    (event, value, reason) => {
      if (onInputChange) {
        onInputChange(event, value, reason);
      }
    },
    [onInputChange],
  );

  return (
    <IdsAutocomplete
      id={id || name} // Default to using name as id to make more easily testable (links input to label)
      label={label}
      required={required}
      loading={loading}
      // Cannot have value be undefined or MUI will think component is uncontrolled
      value={value === undefined ? null : value}
      options={options}
      onChange={_onChange}
      onInputChange={_onInputChange}
      onBlur={handleBlur}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      textFieldProps={{
        helperText: touched && errors ? touch && error : null,
        error: touched && errors ? Boolean(touch && error) : undefined,
        name,
      }}
      {...rest}
    />
  );
};

export default IdsFormAutocompleteField;
