import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AssetIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <g
      style={{ transformOrigin: '0.479477px -1.51419px' }}
      transform='matrix(0.726173, 0, 0, 0.726173, -0.479466, 1.51419)'
    >
      <ellipse
        style={{ stroke: 'currentColor', strokeWidth: '1.63017px' }}
        cx='17'
        cy='15.807'
        rx='8.381'
        ry='8.381'
      ></ellipse>
      <path
        d='M 19.107 17.846 L 14.894 17.846 L 14.011 20.261 L 12.053 20.261 L 16.159 9.394 L 17.85 9.394 L 21.969 20.261 L 20.002 20.261 L 19.107 17.846 Z M 15.436 16.325 L 18.588 16.325 L 17.019 11.7 L 15.436 16.325 Z'
        style={{ fill: 'rgb(255, 255, 255)' }}
      ></path>
      <g transform='matrix(1, 0, 0, 1, 0, -0.051724)'>
        <ellipse
          style={{ stroke: 'currentColor', strokeWidth: '1.63017px' }}
          cx='-16.999'
          cy='-2.068'
          rx='2.524'
          ry='2.524'
          transform='matrix(-1, 0, 0, -1, 0, 0)'
        ></ellipse>
        <line
          style={{
            fill: 'rgb(216, 216, 216)',
            stroke: 'currentColor',
            strokeWidth: '1.63017px',
            transformOrigin: '16.977px 6.049px',
          }}
          x1='16.985'
          y1='4.604'
          x2='16.994'
          y2='7.495'
        ></line>
      </g>
      <g
        transform='matrix(0.309017, 0.951057, -0.951057, 0.309017, 11.76568, 8.482483)'
        style={{ transformOrigin: '16.999px 3.5195px' }}
      >
        <ellipse
          style={{ stroke: 'currentColor', strokeWidth: '1.63017px' }}
          cx='-16.999'
          cy='-2.068'
          rx='2.524'
          ry='2.524'
          transform='matrix(-1, 0, 0, -1, 0, 0)'
        ></ellipse>
        <line
          style={{
            fill: 'rgb(216, 216, 216)',
            stroke: 'currentColor',
            strokeWidth: '1.63017px',
            transformOrigin: '16.977px 6.049px',
          }}
          x1='16.985'
          y1='4.604'
          x2='16.994'
          y2='7.495'
        ></line>
      </g>
      <g
        transform='matrix(-0.809017, 0.587785, -0.587785, -0.809017, 7.277653, 22.309904)'
        style={{ transformOrigin: '16.999px 3.5195px' }}
      >
        <ellipse
          style={{ stroke: 'currentColor', strokeWidth: '1.63017px' }}
          cx='-16.999'
          cy='-2.068'
          rx='2.524'
          ry='2.524'
          transform='matrix(-1, 0, 0, -1, 0, 0)'
        ></ellipse>
        <line
          style={{
            fill: 'rgb(216, 216, 216)',
            stroke: 'currentColor',
            strokeWidth: '1.63017px',
            transformOrigin: '16.977px 6.049px',
          }}
          x1='16.985'
          y1='4.604'
          x2='16.994'
          y2='7.495'
        ></line>
      </g>
      <g
        transform='matrix(-0.309017, 0.951057, 0.951057, 0.309017, -11.754487, 8.481862)'
        style={{ transformOrigin: '16.999px 3.52px' }}
      >
        <ellipse
          style={{ strokeWidth: '1.63017px', stroke: 'currentColor' }}
          cx='-16.999'
          cy='-2.068'
          rx='2.524'
          ry='2.524'
          transform='matrix(-1, 0, 0, -1, 0, 0)'
        ></ellipse>
        <line
          style={{
            fill: 'rgb(216, 216, 216)',
            strokeWidth: '1.63017px',
            transformOrigin: '16.977px 6.049px',
            stroke: 'currentColor',
          }}
          x1='16.985'
          y1='4.604'
          x2='16.994'
          y2='7.495'
        ></line>
      </g>
      <g
        transform='matrix(0.809017, 0.587785, 0.587785, -0.809017, -7.266464, 22.309277)'
        style={{ transformOrigin: '16.999px 3.52px' }}
      >
        <ellipse
          style={{ strokeWidth: '1.63017px', stroke: 'currentColor' }}
          cx='-16.999'
          cy='-2.068'
          rx='2.524'
          ry='2.524'
          transform='matrix(-1, 0, 0, -1, 0, 0)'
        ></ellipse>
        <line
          style={{
            fill: 'rgb(216, 216, 216)',
            strokeWidth: '1.63017px',
            transformOrigin: '16.977px 6.049px',
            stroke: 'currentColor',
          }}
          x1='16.985'
          y1='4.604'
          x2='16.994'
          y2='7.495'
        ></line>
      </g>
    </g>
  </SvgIcon>
);

export default AssetIcon;
