import React, { useCallback } from 'react';
import { SvgIcon, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import { useObservableItemState } from '../../../../hooks/useObservableStates';

import styles from './MapMenuItemToggles.module.css';

const sx = {
  toggleBtnGroup: {
    backgroundColor: 'background.paper',
  },
};

export interface IActiveToggleState {
  active?: string | null;
}

export interface IMapMenuItemToggle {
  key: string;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export interface IMapMenuItemTogglesProps {
  itemId: string;
  toggles: IMapMenuItemToggle[];
}

const MapMenuItemToggles: React.FC<IMapMenuItemTogglesProps> = ({ itemId, toggles }) => {
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useImmersiveViewer();
  const [activeToggleState, setActiveToggleState] = useObservableItemState<IActiveToggleState>({
    id: itemId,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });

  const handleToggleChange = useCallback(
    (__: any, nextActiveToggle: string) => {
      setActiveToggleState({ active: nextActiveToggle });
    },
    [setActiveToggleState],
  );

  return (
    <ToggleButtonGroup
      orientation='vertical'
      value={activeToggleState?.active}
      exclusive
      onChange={handleToggleChange}
      fullWidth
      size='small'
      className={styles.toggleButtonGroup}
      sx={sx.toggleBtnGroup}
    >
      {toggles.map((toggle: IMapMenuItemToggle) => (
        <ToggleButton
          key={toggle.key}
          value={toggle.key}
          onClick={toggle.onClick}
          className={styles.toggleButton}
        >
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            className={styles.toggleContentContainer}
          >
            {toggle.icon && <SvgIcon>{toggle.icon}</SvgIcon>}
            <span>{toggle.label}</span>
          </Stack>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default MapMenuItemToggles;
