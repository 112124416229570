import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, TextLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';

export const defaultClusterIconMapping = {
  clusterMarker: {
    x: 384,
    y: 512,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
};

class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, changeFlags }) {
    const { cluster } = props;
    const { hasClustered, dataChangedWhileDeclustered } = this.state;

    if (cluster) {
      // Only rebuild index if data changed, getHighlighted update trigger changed, clustering was enabled for first time, or data changed while declustered
      // getHighlighted updateTrigger needs to be accounted for since markers are excluded from clustering if highlighted
      const rebuildIndex =
        changeFlags.dataChanged ||
        changeFlags.updateTriggersChanged.getHighlighted ||
        !hasClustered ||
        dataChangedWhileDeclustered;

      if (rebuildIndex) {
        const index = new Supercluster({
          maxZoom: props.maxClusterZoom,
          radius: props.clusterRadius,
        });
        const { getPosition, getHighlighted } = props;

        // Don't cluster highlighted data points
        const dataToCluster = props.data.filter(d => !getHighlighted(d));

        index.load(
          dataToCluster.map(d => ({
            geometry: {
              // getPosition prop supports a value or function
              coordinates: typeof getPosition === 'function' ? getPosition(d) : getPosition,
            },
            properties: d,
          })),
        );
        this.setState({
          index,
          hasClustered: true,
          dataChangedWhileDeclustered: false,
        });
      }

      const zoom = Math.floor(this.context.viewport.zoom);
      if (rebuildIndex || zoom !== this.state.z) {
        this.setState({
          data: this.state.index.getClusters([-180, -85, 180, 85], zoom),
          z: zoom,
        });
      }
    } else if (changeFlags.dataChanged) {
      // Track if data change occurs while declustered so cluster index can be rebuilt if clustering is reenabled
      this.setState({ dataChangedWhileDeclustered: true });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info?.object?.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== 'hover') {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map(f => f.properties);

        const { index } = this.state;
        pickedObject.clusterExpansionZoom = index.getClusterExpansionZoom(pickedObject.cluster_id);
        pickedObject.coordinates = {
          longitude: info.object.geometry.coordinates[0],
          latitude: info.object.geometry.coordinates[1],
          altitude:
            info.object.geometry.coordinates.length === 3 ? info.object.geometry.coordinates[2] : 0,
        };
      }
      info.object = pickedObject;
    }
    return info;
  }

  onClick(info, event) {
    if (event.rightButton && !this.props.handleRightButton) return; // don't fire event for right clicks

    const { onClick, onClusterClick } = this.props;
    const { object } = info;

    if (object.cluster) {
      const { coordinates, clusterExpansionZoom } = object;
      this.context.deck.setProps({
        viewState: {
          ...this.context.deck.viewManager.viewState,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          zoom: clusterExpansionZoom, // Zoom to expand cluster
          transitionDuration: 50,
        },
      });

      if (onClusterClick) {
        onClusterClick(info, event);
      }
    } else if (onClick) {
      onClick(info, event);
    }
  }

  onHover(info, event) {
    const { onHover } = this.props;

    if (onHover) {
      onHover(info, event);
    }
  }

  renderLayers() {
    const { data: clusteredData } = this.state;
    const {
      data: propData,
      getPosition,
      iconAtlas,
      iconMapping,
      getIcon,
      getSize,
      getColor,
      getHighlighted,
      getId,
      highlightIconAtlas,
      highlightIconMapping,
      getHighlightIcon,
      getHighlightSize,
      getHighlightColor,
      cluster,
      clusterIconAtlas,
      clusterIconMapping,
      getClusterIcon,
      onClusterClick,
      getClusterIconSize,
      getClusterIconColor,
      getClusterHeight,
      getTextColor,
      getPixelYOffset,
      updateTriggers,
      pickable,
      ...rest
    } = this.props;

    const sublayers = [];
    const clusters = [];
    const points = [];

    const highlightPoints = propData.filter(getHighlighted); // highlight points are not clustered, use as base of icon points
    points.push(...highlightPoints);

    // Sort
    clusteredData?.forEach(d => {
      if (d.properties.point_count > 1) {
        // cluster
        clusters.push(d);
      } else {
        points.push(d.properties);
      }
    });

    // FUTURE TODO: set up getPosition as a transition prop to animate when de/clustering
    // won't work if in separate layers though...

    // Render non-clustered highlight data items
    // sublayers.push(
    //   new IconLayer(this.getSubLayerProps({
    //     ...rest,
    //     id: 'item-highlight-icons',
    //     data: highlightPoints,
    //     getPosition,
    //     iconAtlas: highlightIconAtlas,
    //     iconMapping: highlightIconMapping,
    //     getIcon: getHighlightIcon,
    //     getSize: getHighlightSize,
    //     getColor: getHighlightColor,
    //     updateTriggers: {
    //       getIcon: updateTriggers.getHighlightIcon,
    //       getSize: updateTriggers.getHighlightSize,
    //       getColor: updateTriggers.getHighlightColor,
    //     },
    //   }))
    // );

    // Render non-clustered data items
    sublayers.push(
      new IconLayer(
        this.getSubLayerProps({
          ...rest,
          id: 'item-icons',
          data: cluster ? points : propData,
          getPosition,
          iconAtlas: iconAtlas,
          iconMapping: iconMapping,
          getIcon: getIcon,
          getSize: getSize,
          getColor: getColor,
          updateTriggers: {
            getIcon: updateTriggers.getIcon,
            getSize: updateTriggers.getSize,
            getColor: updateTriggers.getColor,
          },
          pickable,
        }),
      ),
    );

    const getClusterPosition = d => [
      d.geometry.coordinates[0],
      d.geometry.coordinates[1],
      getClusterHeight(d),
      // Raise height minimally to prevent z-fighting with raster overlays at the same height
      // May only be an issue in 3D??
      // getClusterHeight(d) + 0.1
    ];

    // Render cluster icons
    sublayers.push(
      new IconLayer(
        this.getSubLayerProps({
          visible: cluster,
          id: 'cluster-icons',
          data: clusters,
          getPosition: getClusterPosition,
          iconAtlas: clusterIconAtlas,
          iconMapping: clusterIconMapping,
          getIcon: getClusterIcon,
          getSize: getClusterIconSize,
          getColor: getClusterIconColor,
          pickable,
          updateTriggers: {
            getIcon: updateTriggers.getClusterIcon,
            getSize: updateTriggers.getClusterIconSize,
            getColor: updateTriggers.getClusterIconColor,
          },
        }),
      ),
    );

    // Render cluster labels
    sublayers.push(
      new TextLayer(
        this.getSubLayerProps({
          visible: cluster,
          id: 'cluster-labels',
          data: clusters, // only render label for clustered data
          getPosition: getClusterPosition,
          fontFamily: 'roboto',
          getText: d => d.properties.point_count_abbreviated.toString(),
          getSize: d => getClusterIconSize(d) * 0.35,
          getColor: getTextColor,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          getPixelOffset: d => [0, getClusterIconSize(d) * getPixelYOffset(d)],
          pickable,
          updateTriggers: {
            getPosition: updateTriggers.getClusterHeight,
            getSize: updateTriggers.getClusterIconSize,
            getTextColor: updateTriggers.getTextColor,
            getPixelOffset: updateTriggers.getPixelYOffset,
          },
        }),
      ),
    );

    return sublayers;
  }
}

IconClusterLayer.defaultProps = {
  // Icon properties
  iconAtlas: '/static/icons/cluster-icon-atlas.png', // Defining this as an accessor broke the layer, need just the value
  iconMapping: {
    type: 'object',
    value: defaultClusterIconMapping,
    async: true,
  },
  // Icon accessors
  getIcon: { type: 'accessor', value: d => 'clusterMarker' },
  getSize: { type: 'accessor', value: d => 30 },
  getColor: { type: 'accessor', value: [2, 136, 209, 255] },
  getHighlighted: { type: 'accessor', value: d => false },
  getId: { type: 'accessor', value: d => d.node.id },
  // Highlight icon properties
  highlightIconAtlas: '/static/icons/cluster-icon-atlas.png', // Defining this as an accessor broke the layer, need just the value
  highlightIconMapping: defaultClusterIconMapping,
  // Highlight icon accessors
  getHighlightIcon: { type: 'accessor', value: d => 'clusterMarker' },
  getHighlightSize: { type: 'accessor', value: d => 36 },
  getHighlightColor: { type: 'accessor', value: [255, 255, 255, 255] },
  // Cluster properties
  cluster: true,
  clusterIconAtlas: '/static/icons/cluster-icon-atlas.png',
  clusterIconMapping: {
    type: 'object',
    value: defaultClusterIconMapping,
    async: true,
  },
  maxClusterZoom: { type: 'number', value: 19 },
  clusterRadius: { type: 'number', value: 40 }, // Cluster radius in pixels
  onClusterClick: {
    type: 'function',
    value: null,
    compare: false,
    optional: true,
  },
  // Cluster accessors
  getClusterIcon: { type: 'accessor', value: d => 'clusterMarker' },
  getClusterIconSize: { type: 'accessor', value: d => 50 },
  getClusterIconColor: { type: 'accessor', value: d => [2, 136, 209, 255] },
  getClusterHeight: { type: 'accessor', value: d => 0 },
  // Cluster text accessors
  getTextColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getPixelYOffset: { type: 'accessor', value: d => -0.49 },
};

IconClusterLayer.layerName = 'IconClusterLayer';

export default IconClusterLayer;
