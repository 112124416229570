import React from 'react';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';

import IdsSearchableList from '../../../components/ids-lists/IdsSearchableList';

export const searchProjectItem = (projectItem, query) => {
  const searchProps = [
    projectItem.node.name,
    projectItem.node.description,
    projectItem.node.location.name,
  ];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

export const projectFilters = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
  {
    key: 'new',
    label: 'New',
    filterItem: projectItem => projectItem.node.status === 'NEW',
  },
  {
    key: 'in_progress',
    label: 'In Progress',
    filterItem: projectItem => projectItem.node.status === 'IN_PROGRESS',
  },
  {
    key: 'published',
    label: 'Published',
    filterItem: projectItem => projectItem.node.status === 'PUBLISHED',
  },
  {
    key: 'completed',
    label: 'Completed',
    filterItem: projectItem => projectItem.node.status === 'COMPLETED',
  },
];

export const sortProjectItems = (a, b) => {
  return a.node.name.localeCompare(b.node.name); // alphabetical order
};

export const ProjectsListFrag = gql`
  fragment ProjectsListFrag on Project {
    id
    name
    description
    status
    programId
    location {
      id
      name
    }
  }
`;

const ProjectsList = ({ projectItems, renderProjectItem, ...rest }) => (
  <IdsSearchableList
    items={projectItems}
    renderItem={renderProjectItem}
    searchPlaceholder={'Search projects'}
    searchItem={searchProjectItem}
    filters={projectFilters}
    sortItems={sortProjectItems}
    noItemsMessage='No projects'
    noItemsForFilterMessage='No projects match your search'
    {...rest}
  />
);

ProjectsList.propTypes = {
  projectItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderProjectItem: PropTypes.func.isRequired, // (projectItem) => {}
};

export default ProjectsList;
