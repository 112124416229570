import React from 'react';
import { Paper } from '@mui/material';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import MapOverlay from '../../../mapping/MapOverlay';
import styles from '../styles.module.css';

export interface IMapEntityWrapperProps {
  mediaViewerWidth: number | null;
  hasImage: boolean;
  children: React.ReactNode;
}

const MapOverlayFixed = MapOverlay as any;

const MapEntityWrapper: React.FC<IMapEntityWrapperProps> = ({
  mediaViewerWidth,
  hasImage,
  children,
}) => {
  return (
    <MapOverlayFixed
      {...(hasImage && mediaViewerWidth ? { width: `${mediaViewerWidth}px` } : null)}
      className={clsx({
        [styles.container]: !hasImage,
        [styles.containerForMediaViewer]: hasImage,
      })}
    >
      <Paper
        className={clsx({
          [styles.paper]: !hasImage,
          [styles.paperForMediaViewer]: hasImage,
        })}
      >
        <PerfectScrollbar>{children}</PerfectScrollbar>
      </Paper>
    </MapOverlayFixed>
  );
};

export default MapEntityWrapper;
