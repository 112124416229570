import React from 'react';
import { Grid, Typography, Box, Tooltip } from '@mui/material';

import IdsTabsRenderer, { IIdsTabsRendererTab } from '../../../../../components/IdsTabsRenderer';
import { InfoIcon } from '../../../../../theme/icons';

import EditingPane from '../EditingPane';
import { Mode } from '../EditingPane/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRouteBuilderProps {}

const tabs: IIdsTabsRendererTab[] = [
  {
    key: 'addAndEdit',
    label: (
      <Box display='flex' flexDirection='row' alignItems='center'>
        Add & Edit &nbsp;
        <Tooltip title='Left click to add markers, right click to edit' placement='bottom'>
          <InfoIcon fontSize='small' />
        </Tooltip>
      </Box>
    ),
    content: <EditingPane mode={Mode.AddAndEdit} />,
    sx: {
      p: 0,
    },
  },
  {
    key: 'addOnly',
    label: 'Add Only',
    content: <EditingPane mode={Mode.AddOnly} />,
    sx: {
      p: 0,
    },
  },
  {
    key: 'editOnly',
    label: 'Edit Only',
    content: <EditingPane mode={Mode.EditOnly} />,
    sx: {
      p: 0,
    },
  },
];

/**
 * @TODO: Refactor EditingPane into something more straightforward when all
 * Route Builder features are implemented.
 */
const RouteBuilder: React.FC<IRouteBuilderProps> = () => {
  return (
    <Grid container direction='column'>
      <Grid item xs>
        <Typography variant='h4' textAlign='center'>
          Route Builder
        </Typography>
      </Grid>
      <Grid item xs>
        <IdsTabsRenderer tabs={tabs} contentHeader={<Box height={16} />} variant='fullWidth' />
      </Grid>
    </Grid>
  );
};

export default RouteBuilder;
