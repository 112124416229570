import React from 'react';
import { Button, ButtonProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsEditButtonProps extends ButtonProps {}

const IdsEditButton: React.FC<IIdsEditButtonProps> = props => {
  return (
    <Button variant='contained' {...props}>
      Edit
    </Button>
  );
};

export default IdsEditButton;
