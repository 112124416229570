import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import IdsForm from '../../../components/ids-forms/IdsForm';
import AssignmentDataSchema from '../validationSchema';

import FormFields, { IFormFieldsProps } from './FormFields';
export interface ILoadAssignmentFormProps extends Pick<IFormFieldsProps, 'resetAssignmentData'> {
  onAssignmentSelected: (assignmentId: string, projectIds: string[]) => void;
}

export const ASSIGNMENT_ID_PARAM = 'assignmentId';
export const PROJECT_ID_PARAM = 'projectId';

export interface IFormValues {
  projectsId: string[];
  assignmentId: string;
}

const defaultInitialValues = {
  projectsId: [],
  assignmentId: '',
};

const handleLoadProjectAssignmentError = (error: any) => {
  if (typeof error === 'string') {
    return error;
  }

  return error.message || 'There was an error';
};

const LoadAssignmentForm: React.FC<ILoadAssignmentFormProps> = ({
  resetAssignmentData,
  onAssignmentSelected,
}) => {
  const [searchParams] = useSearchParams();

  const handleSubmit = (values: IFormValues) => {
    const { assignmentId, projectsId } = values;

    if (assignmentId && projectsId && projectsId.length > 0) {
      onAssignmentSelected(assignmentId, projectsId);
    }
  };

  const [initialValues, setInitialValues] = useState<IFormValues>({
    ...defaultInitialValues,
  });

  useEffect(() => {
    const assignmentId = searchParams.get(ASSIGNMENT_ID_PARAM);
    const projectId = searchParams.get(PROJECT_ID_PARAM);

    if (assignmentId) {
      setInitialValues(() => ({
        assignmentId,
        projectsId: projectId ? [projectId] : [],
      }));
    }
  }, [searchParams]);

  return (
    <IdsForm
      onSubmit={handleSubmit}
      errorHandler={handleLoadProjectAssignmentError}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={AssignmentDataSchema}
    >
      <FormFields resetAssignmentData={resetAssignmentData} />
    </IdsForm>
  );
};

export default LoadAssignmentForm;
