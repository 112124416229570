import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import useMapContextMenu from '../../../../hooks/useMapContextMenu';
import useDeckEventManagerCallback from '../../../../hooks/useDeckEventManagerCallback';
import { LAYER_TYPE } from '../../../../context/ImmersiveViewerContext';
import { buildPOIMarkerLayer } from '../../layers/media-markers';
import { TOOLS_ID } from '../../LocationMapMenu';
import { PointOfInterestIcon } from '../../../../theme/icons';
import { useObservableItemState } from '../../../../hooks/useObservableStates';
import CreatePointOfInterestDialog from '../../points-of-interest/CreatePointOfInterestDialog';
import IDS_COLORS from '../../../../theme/ids-colors';

const poiPointId = 'poi-tool-new-point';

export const POI_TOOL_ID = 'poi-tool';

const PointOfInterestTool = ({ className, ...rest }) => {
  const {
    setLayer,
    useViewport,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  } = useImmersiveViewer();
  const [toolState, setToolState] = useObservableItemState({
    id: TOOLS_ID,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  const viewport = useViewport();
  const { setMenuItem } = useMapContextMenu();
  const [hoveringPoint, setHoveringPoint] = useState();
  const [toolTarget, setToolTarget] = useState();
  const [position, setPosition] = useState({ longitude: null, latitude: null });
  const [pointFromMenu, setPointFromMenu] = useState(false);
  const [color, setColor] = useState(IDS_COLORS.marker.pointOfInterest);
  const [flagged, setFlagged] = useState(true);

  const pointPlaced = useMemo(() => !!(position?.longitude && position.latitude), [position]);
  const enabled = useMemo(() => toolState?.active === POI_TOOL_ID, [toolState]);

  const createPOIFromMenu = useCallback(
    ([longitude, latitude]) => {
      setToolState({ active: POI_TOOL_ID });
      setPointFromMenu(true);
      setPosition({ longitude, latitude });
    },
    [setToolState, setPosition],
  );

  useEffect(() => {
    setMenuItem(POI_TOOL_ID, {
      icon: <PointOfInterestIcon />,
      label: 'New point of interest',
      onClick: createPOIFromMenu,
    });

    return () => {
      setMenuItem(POI_TOOL_ID, null);
    };
  }, [setMenuItem, createPOIFromMenu]);

  useEffect(() => {
    if (!toolTarget) {
      return;
    }

    // Change cursor while placing marker
    toolTarget.style.cursor = !pointPlaced ? 'pointer' : 'inherit';
  }, [pointPlaced, toolTarget]);

  const onMapHover = useCallback(
    event => {
      const { offsetCenter, target } = event;
      const coordinate = viewport.unproject([offsetCenter.x, offsetCenter.y]);
      setHoveringPoint(coordinate);

      if (target !== toolTarget) {
        setToolTarget(target);
      }
    },
    [setHoveringPoint, viewport, setToolTarget, toolTarget],
  );

  useDeckEventManagerCallback('pointermove', onMapHover, enabled && !pointPlaced);

  const onMapClick = useCallback(
    event => {
      // still need to check this even though handler gets disabled,
      // event fires again before it gets disabled when the point is clicked
      // point click and map click are handled separately
      if (enabled && !pointPlaced) {
        const { offsetCenter } = event;
        const [longitude, latitude] = viewport.unproject([offsetCenter.x, offsetCenter.y]);
        setPosition({ longitude, latitude });
        setPointFromMenu(false);
      }
    },
    [viewport, pointPlaced, enabled, setPointFromMenu, setPosition],
  );

  useDeckEventManagerCallback('click', onMapClick, enabled && !pointPlaced);

  // Build tool poi marker
  useEffect(() => {
    const data =
      hoveringPoint || pointPlaced
        ? [
            {
              node: {
                color,
                flagged,
                position: {
                  longitude: position?.longitude || hoveringPoint[0],
                  latitude: position?.latitude || hoveringPoint[1],
                },
              },
            },
          ]
        : [];

    setLayer(
      poiPointId,
      buildPOIMarkerLayer(
        data,
        {
          id: poiPointId,
          visible: enabled,
          cluster: false,
          getColor: [0, 0, 0, pointPlaced ? 255 : 200], // make semi-transparent before placement (rgb components are ignored since icon is not set to mask)
        },
        { type: LAYER_TYPE.TOOL },
      ),
    ); // override default layer type to ensure the marker is rendered on top of all other media markers
  }, [hoveringPoint, pointPlaced, position, setLayer, enabled, color, flagged]);

  const close = useCallback(
    reason => {
      if (enabled) {
        // When the point was not from the context menu, tool should return to point selection (the tool remains active)
        if (pointFromMenu || reason === 'created') {
          // always close the tool after creating a POI
          setToolState({ active: null });
          setHoveringPoint(null);
        }

        setColor(IDS_COLORS.marker.pointOfInterest); // Reset to default color
        setPosition({ longitude: null, latitude: null });
      }
    },
    [enabled, pointFromMenu, setToolState, setHoveringPoint, setPosition],
  );

  return (
    <CreatePointOfInterestDialog
      open={pointPlaced}
      onClose={close}
      position={position}
      onColorChange={setColor}
      onFlaggedChange={setFlagged}
    />
  );
};

export default PointOfInterestTool;
