import React, { ChangeEvent } from 'react';

import { FormikProps, useField } from 'formik';

import IdsCheckbox, { IIdsCheckboxProps } from '../../ids-inputs/IdsCheckbox';
import IdsFormElement from '../IdsFormElement';

// No need in onChange, onBlur, onFocus and value props
// Because this component will use formik context
export interface IIdsFormCheckboxFieldProps
  extends Omit<IIdsCheckboxProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'> {
  name: string;

  // Custom event handlers which additionally receive formikProps
  onChange?: (event: ChangeEvent<HTMLInputElement>, formikProps?: FormikProps<any>) => void;
}

const IdsFormCheckboxField: React.FC<IIdsFormCheckboxFieldProps> = ({
  name,
  onChange,
  ...rest
}) => {
  const [{ onChange: formikOnChange, ...restField }] = useField({
    name,
    type: 'checkbox',
  });

  return (
    <IdsFormElement
      render={formikProps => {
        return (
          <IdsCheckbox
            onChange={event => {
              formikOnChange(event);
              if (onChange) {
                onChange(event, formikProps);
              }
            }}
            {...restField}
            {...rest}
          />
        );
      }}
    />
  );
};

export default IdsFormCheckboxField;
