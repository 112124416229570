import { gql } from 'graphql-request';
import { useMemo } from 'react';

import useGraphQuery, { UseGraphQueryOptions } from '../hooks/useGraphQuery';
import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import { ProjectsListFrag } from '../views/Projects/ProjectsList';
import { PROJECT_STATUSES } from '../constants/projects';
import { ITenantListItemFragType, TenantListItemFrag } from '../views/Tenant/TenantListItem';
import { UserRole, UserRoleType } from '../constants/users';

import {
  IOrganizationsTabUserFragType,
  OrganizationTabUserFrag,
} from '../features/Users/AccessTab/fragments';
import {
  IUserDetailsCardFragType,
  UserDetailsCardFrag,
} from '../features/Users/DetailsTab/UserDetailsCard';
import invalidateQueriesContainingKey from '../utils/invalidateQueriesContainingKey';
import { LocationMarkerFrag } from '../components/mapping/layers/locations';

import { useUserKeys } from './UsersService';
import { ILocationListItem, LocationListItemFrag } from './fragments';
import { ILocationMarkerFrag } from './types';

export type PermissionsAction = 'create' | 'delete' | 'update' | 'read';

export const useTenantKeys = () => {
  const userKeys = useUserKeys();

  return useMemo(() => {
    const tenantKeys = {
      all: ['tenants'],
      lists: () => [...tenantKeys.all, 'list'],
      route: (id: string) => [...tenantKeys.all, id],
      details: (id: string) => [...tenantKeys.route(id), 'detail'],
      projects: (id: string) => [`tenant-projects-${id}`],
      programs: (id: string) => [`tenant-programs-${id}`],
      locations: (id: string) => [`tenant-locations-${id}`],
      users: (id: string) => [...userKeys.lists(), `tenant-users-${id}`],
      documents: (id: string) => [`tenant-documents-${id}`],
      metadata: (id: string) => [`tenant-metadata-${id}`],
      organizations: (id: string) => [`tenant-organizations-${id}`],
      userInTenantDetails: (tenantID: string, userInTenantId: string) => [
        `tenant-${tenantID}`,
        ...userKeys.detail(userInTenantId),
      ],
    };

    return tenantKeys;
  }, [userKeys]);
};

const TenantDetailsFrag = gql`
  fragment TenantDetailsFrag on Tenant {
    myUserRole
    myPermissions
    id
    name
    subdomain
    logo {
      height
      width
      name
      url
    }
  }
`;

export interface ITenant {
  myUserRole: UserRoleType;
  myPermissions: Record<PermissionsAction, string[]>;
  id: string;
  name: string;
  subdomain: string;
  logo: {
    height: number;
    width: number;
    name: string;
    url: string;
  };
}

export interface ITenantUserDetailsBySubdomain {
  tenantBySubdomain: ITenant;
}

const TenantDetailsBySubdomainQuery = gql`
  query TenantDetailsBySubdomain($subdomain: String!) {
    tenantBySubdomain(subdomain: $subdomain) {
      ...TenantDetailsFrag
    }
  }
  ${TenantDetailsFrag}
`;

export const useGetTenantDetailsBySubdomain = (subdomain: string | undefined) => {
  const tenantKeys = useTenantKeys();

  return useGraphQuery<ITenantUserDetailsBySubdomain>(
    subdomain ? tenantKeys.details(subdomain) : '',
    TenantDetailsBySubdomainQuery,
    { subdomain },
    { enabled: !!subdomain },
  );
};

export interface IOrganizationItem {
  id: string;
  name: string;
}

const OrganizationFrag = gql`
  fragment OrganizationFrag on Organization {
    id
    name
  }
`;

const TenantProgramsQuery = gql`
  query TenantProgramsQuery($tenantId: ID!, $take: Int, $after: String) {
    tenant(id: $tenantId) {
      programs(take: $take, after: $after) {
        edges {
          node {
            id
            name
            organization {
              ...OrganizationFrag
            }
          }
          cursor
        }
      }
    }
  }
  ${OrganizationFrag}
`;

export interface ITenantProgramItem {
  node: {
    id: string;
    name: string;
    organization: IOrganizationItem;
  };
}

export const useGetTenantPrograms = (tenantId: string) => {
  const tenantKeys = useTenantKeys();

  const usePrograms = (take: number, after: string | null) =>
    useGraphQuery<ITenantProgramItem[]>(
      [...tenantKeys.programs(tenantId), `take-${take}`, `after-${after}`],
      TenantProgramsQuery,
      { tenantId, take, after },
    );

  return useCursorPaginatedQuery({
    useQuery: usePrograms,
    defaultTake: 500,
    selectConnection: data => data.tenant.programs,
  });
};

const TenantProjectsQuery = gql`
  query TenantProjectsQuery($tenantId: ID!, $take: Int, $after: String) {
    tenant(id: $tenantId) {
      projects(take: $take, after: $after) {
        edges {
          node {
            ...ProjectsListFrag
            organization {
              ...OrganizationFrag
            }
          }
          cursor
        }
      }
    }
  }
  ${ProjectsListFrag}
  ${OrganizationFrag}
`;

export interface ITenantProjectItem {
  node: {
    id: string;
    name: string;
    location: {
      name: string;
    };
    status: keyof typeof PROJECT_STATUSES;
    organization: IOrganizationItem;
  };
}

export const useGetTenantProjects = (tenantId: string) => {
  const tenantKeys = useTenantKeys();

  const useProjects = (take: number, after: string | null) =>
    useGraphQuery<ITenantProjectItem[]>(
      [...tenantKeys.projects(tenantId), `take-${take}`, `after-${after}`],
      TenantProjectsQuery,
      { tenantId, take, after },
    );

  return useCursorPaginatedQuery({
    useQuery: useProjects,
    defaultTake: 500,
    selectConnection: data => data.tenant.projects,
  });
};

const TenantLocationsQuery = gql`
  query TenantLocationsQuery($tenantId: ID!, $take: Int, $after: String) {
    tenant(id: $tenantId) {
      locations(take: $take, after: $after) {
        edges {
          node {
            ...LocationListItemFrag
            ...LocationMarkerFrag
            organization {
              ...OrganizationFrag
            }
          }
          cursor
        }
      }
    }
  }
  ${LocationListItemFrag}
  ${LocationMarkerFrag}
  ${OrganizationFrag}
`;

export interface ITenantLocationItem {
  node: {
    organization: IOrganizationItem;
  } & ILocationListItem &
    ILocationMarkerFrag;
}

interface ITenantLocationItemEdge extends ITenantLocationItem {
  cursor: string;
}

export const useGetTenantLocations = (tenantId: string) => {
  const tenantKeys = useTenantKeys();

  const useLocations = (take: number, after: string | null) =>
    useGraphQuery<ITenantLocationItem[]>(
      [...tenantKeys.locations(tenantId), `take-${take}`, `after-${after}`],
      TenantLocationsQuery,
      { tenantId, take, after },
    );

  return useCursorPaginatedQuery<ITenantLocationItemEdge>({
    useQuery: useLocations,
    defaultTake: 500,
    selectConnection: data => data.tenant.locations,
  });
};

const TenantUsersQuery = gql`
  query TenantUsersQuery($tenantId: ID!, $take: Int, $after: String) {
    tenant(id: $tenantId) {
      users(take: $take, after: $after) {
        edges {
          node {
            id
            firstName
            lastName
            email
            activationState
            initialOrganizationId
            roles {
              roleName
              domainId
              domainType
            }
          }
          cursor
        }
      }
    }
  }
`;

export interface ITenantUserItem {
  node: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    activationState: string;
    initialOrganizationId: string;
    roles: {
      roleName: string;
      domainId: string;
      domainType: string;
    }[];
  };
}

export const useGetTenantUsers = (tenantId: string) => {
  const tenantKeys = useTenantKeys();

  const useUsers = (take: number, after: string | null) =>
    useGraphQuery<ITenantUserItem[]>(
      [...tenantKeys.users(tenantId), `take-${take}`, `after-${after}`],
      TenantUsersQuery,
      { tenantId, take, after },
    );

  return useCursorPaginatedQuery({
    useQuery: useUsers,
    defaultTake: 500,
    selectConnection: data => data.tenant.users,
  });
};

const TenantsListQuery = gql`
  query UserTenants {
    userTenants {
      ...TenantListItemFrag
    }
  }
  ${TenantListItemFrag}
`;

export interface ITenantsListQueryData {
  userTenants: ITenantListItemFragType[];
}

export const useGetTenantsList = () => {
  const tenantKeys = useTenantKeys();

  return useGraphQuery<ITenantsListQueryData>([...tenantKeys.all], TenantsListQuery);
};

const TenantOrganizationsQuery = gql`
  query TenantOrganizationsQuery($tenantId: ID!, $after: String, $take: Int) {
    tenant(id: $tenantId) {
      organizations(after: $after, take: $take) {
        edges {
          node {
            ...OrganizationFrag
          }
          cursor
        }
      }
    }
  }
  ${OrganizationFrag}
`;

export interface ITenantOrganizationsData {
  node: IOrganizationItem;
}

export const useGetTenantOrganizations = (tenantId: string, options?: UseGraphQueryOptions) => {
  const tenantKeys = useTenantKeys();

  const useOrganizations = (take: number, after: string | null) =>
    useGraphQuery<ITenantOrganizationsData[]>(
      [...tenantKeys.organizations(tenantId), `take-${take}`, `after-${after}`],
      TenantOrganizationsQuery,
      { tenantId, take, after },
      options,
    );

  return useCursorPaginatedQuery({
    useQuery: useOrganizations,
    defaultTake: 500,
    selectConnection: data => data.tenant.organizations,
  });
};

const UserInTenantQuery = gql`
  query UserInTenant($userInTenantId: ID!, $tenantId: ID!) {
    userInTenant(id: $userInTenantId, tenantId: $tenantId) {
      id
      ...UserDetailsCardFrag
      ...OrganizationTabUserFrag
    }
  }
  ${UserDetailsCardFrag}
  ${OrganizationTabUserFrag}
`;

export interface IUserInTenantData {
  userInTenant: IUserDetailsCardFragType & IOrganizationsTabUserFragType;
}

export const useGetUserInTenant = (
  tenantId: string | null,
  userInTenantId: string | null,
  options?: UseGraphQueryOptions,
) => {
  const tenantKeys = useTenantKeys();

  return useGraphQuery<IUserInTenantData>(
    !!tenantId && !!userInTenantId ? tenantKeys.userInTenantDetails(tenantId, userInTenantId) : '',
    UserInTenantQuery,
    { tenantId, userInTenantId },
    {
      enabled: !!tenantId && !!userInTenantId,
      ...options,
    },
  );
};

const UpdateUserRoleMutation = gql`
  mutation UpdateUserRoleInTenant(
    $where: WhereUniqueIdInput!
    $input: UpdateUserRoleInTenantInput!
  ) {
    updateUserRoleInTenant(where: $where, input: $input) {
      errors {
        message
      }
    }
  }
`;

export interface UpdateUserRoleInTenantInput {
  tenantId: string;
  action: 'add' | 'update' | 'delete';
  roleName: UserRole.tenant_team | UserRole.tenant_admin;
}

export const useUpdateUserRoleInTenant = () => {
  const tenantKeys = useTenantKeys();
  const userKeys = useUserKeys();

  return async (id: string, rest: UpdateUserRoleInTenantInput) => {
    const result = buildGraphMutationFn(UpdateUserRoleMutation)({
      input: {
        ...rest,
      },
      where: {
        id,
      },
    });

    await invalidateQueriesContainingKey(tenantKeys.users(rest.tenantId), {
      refetchActive: true,
    });
    await invalidateQueriesContainingKey(tenantKeys.userInTenantDetails(rest.tenantId, id), {
      refetchActive: true,
    });
    await invalidateQueriesContainingKey(userKeys.detail(id), {
      refetchActive: true,
    });

    return result;
  };
};
