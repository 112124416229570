import React from 'react';

import IdsDialog from '../../../../../../../../../components/IdsDialog';

import EditTaskResponseForm from './EditTaskResponseForm';

const EditTaskResponseDialog = ({
  open,
  onClose,
  title,
  groupedAnswers,
  taskResponseId,
  assignmentResponseId,
  guid,
  taskId,
  defaultTaskMetadata,
}) => {
  return (
    <IdsDialog
      open={open}
      onClose={onClose}
      title={`Edit ${title || 'Response'}`}
      dialogKey='response-editing'
      fullWidth
    >
      <EditTaskResponseForm
        onCancel={onClose}
        groupedAnswers={groupedAnswers}
        taskResponseId={taskResponseId}
        assignmentResponseId={assignmentResponseId}
        guid={guid}
        taskId={taskId}
        defaultTaskMetadata={defaultTaskMetadata}
      />
    </IdsDialog>
  );
};

export default EditTaskResponseDialog;
