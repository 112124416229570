import { useCallback } from 'react';

import { ACTIVE_MEDIA_MARKER_LAYER_ID } from '../constants/layerIds';

import { MEDIA_TYPE_TO_DEF } from '../constants/media';

import useImmersiveViewer from './useImmersiveViewer';
import useFilterContext from './useFilterContext';
import useHighlightMediaMarker from './useHighlightMediaMarker';
import useMediaFromUrn from './useMediaFromUrn';

import useItemFilterNotifier from './useItemFilterNotifier';
import useMapFitData from './useMapFitData';

const useSetActiveMedia = () => {
  const { getLayerMetadata, setLayerMetadata, setViewState } = useImmersiveViewer();
  const { getTypeData } = useFilterContext();
  const { getMediaTypeFromUrn } = useMediaFromUrn();
  const { highlightMediaMarker } = useHighlightMediaMarker();
  const { setItemForNotifications, removeItemForNotifications } = useItemFilterNotifier();
  const { fitMapToData } = useMapFitData();

  const setActiveMedia = useCallback(
    mediaUrnId => {
      if (!mediaUrnId) {
        // unset active media
        setLayerMetadata(ACTIVE_MEDIA_MARKER_LAYER_ID, { activeMedia: [] });
        removeItemForNotifications();
        return;
      }

      const mediaType = getMediaTypeFromUrn(mediaUrnId);
      const typeMedia = getTypeData(mediaType);
      const { selectItem, genericDescriptor } = MEDIA_TYPE_TO_DEF[mediaType];
      const media = typeMedia.find(m => selectItem(m).id === mediaUrnId);

      if (!media) {
        return;
      }

      const mediaItem = selectItem(media);

      setLayerMetadata(ACTIVE_MEDIA_MARKER_LAYER_ID, {
        activeMedia: [{ ...media, position: mediaItem.position }],
      });

      setViewState(previousViewState => {
        const baseViewState = {
          ...previousViewState,
          transitionDuration: 300,
        };

        if (mediaItem.points) {
          // media item has collection of points, fit view to points
          return {
            ...baseViewState,
            ...fitMapToData(
              mediaItem.points,
              p => p.latitude,
              p => p.longitude,
              previousViewState.zoom,
              previousViewState,
            ),
          };
        } else {
          return {
            ...baseViewState,
            latitude: mediaItem.position.latitude,
            longitude: mediaItem.position.longitude,
          };
        }
      });

      setItemForNotifications(
        mediaUrnId,
        mediaType,
        filteredBy =>
          !!filteredBy?.label
            ? `The active ${genericDescriptor} does not match the currently applied filters`
            : null,
        'The active media type is disabled',
      );
    },
    [
      getMediaTypeFromUrn,
      setLayerMetadata,
      getTypeData,
      setViewState,
      setItemForNotifications,
      removeItemForNotifications,
      fitMapToData,
    ],
  );

  const updateActiveMediaPosition = useCallback(
    position => {
      const { activeMedia } = getLayerMetadata(ACTIVE_MEDIA_MARKER_LAYER_ID);

      if (activeMedia?.length) {
        setLayerMetadata(ACTIVE_MEDIA_MARKER_LAYER_ID, {
          activeMedia: [
            {
              ...activeMedia[0],
              position: {
                // maintain existing position attribute not provided
                ...activeMedia[0].position,
                ...position,
              },
            },
          ],
        });
      }
    },
    [getLayerMetadata, setLayerMetadata],
  );

  const setActiveMediaAndHighlightOnMap = useCallback(
    mediaUrnId => {
      setActiveMedia(mediaUrnId);
      highlightMediaMarker(mediaUrnId);
    },
    [highlightMediaMarker, setActiveMedia],
  );

  return {
    setActiveMedia,
    setActiveMediaAndHighlightOnMap,
    updateActiveMediaPosition,
  };
};

export default useSetActiveMedia;
