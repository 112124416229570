import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Backdrop, Box, CircularProgress, Grid, Paper } from '@mui/material';

import { sessionState } from '../../../atoms/session';
import { activeOrganizationState } from '../../../atoms/organizations';
import { activeTenantState } from '../../../atoms/tenants';

import useFilterContext from '../../../hooks/useFilterContext';
import usePermissions from '../../../hooks/usePermissions';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';
import useLocationMapMetadataOptions from '../../../hooks/useLocationMapMetadataOptions';
import useHighlightMediaMarker from '../../../hooks/useHighlightMediaMarker';
import useImageData from '../../mapping/LocationMap/LocationMapMediaManager/hooks/useImageData';

import { MEDIA_TYPES, MediaMetadataType, MediaType } from '../../../constants/media';
import { USER_ROLES } from '../../../constants/users';
import { MEDIA_VIEWER_MODES } from '../../../constants/mediaViewer';

import IdsMediaViewer, {
  MEDIA_VIEWER_TABS,
  MEDIA_VIEWER_WIDTHS,
} from '../../ids-media-viewer/IdsMediaViewer';
import { useDefaultEventHandlers } from '../../ids-media-viewer/IdsMediaViewer/hooks';
import { IDataEntityMarker } from '../../../services/types';
import EntityData from '../../../features/Data/EntityData';
import CommentsSection from '../../../features/Comments/EntityComments';
import MapEntityTags from '../../../features/Tags/MapEntityTags';
import { IFilledIconButtonProps } from '../../ids-buttons/FilledIconButton';
import IdsForm from '../../ids-forms/IdsForm';

import Actions, { ENTITY_UPDATE_MUTATION, getUpdatedEntityResult } from './Actions';
import useMapEntityType from './useMapEntityType';
import useEntityFields from './useEntityFields';
import useInitialValues from './useInitialValues';
import useOverrideMediaParam from './useOverrideMediaParam';
import MapEntityWrapper from './MapEntityWrapper';
import styles from './styles.module.css';

export const labelColor = '#00407A';

const selectMediaItemData = (item: any) => item.node;

const sx = {
  backdrop: {
    zIndex: (theme: any) => theme.zIndex.drawer + 1,
  },
};

export interface IIdsMapEntityViewerProps {
  onClose: (isDeleted: boolean) => void | IFilledIconButtonProps['onClick'];
  locationId: string;
  entity: {
    cursor: string;
    node: IDataEntityMarker & {
      // POI's data.
      data?: string;
    };
  };
}

const tabs = [MEDIA_VIEWER_TABS.MEDIA, MEDIA_VIEWER_TABS.INFO];
const style = { marginTop: 12, marginRight: 12, marginLeft: 10 };

export const IdsMapEntityViewer: React.FC<IIdsMapEntityViewerProps> = ({
  onClose,
  locationId,
  entity,
}) => {
  useOverrideMediaParam(entity.node.id);

  const entityType = useMapEntityType(entity.node.id);
  const [isEditMode, setIsEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activeImageUrn, setActiveImageUrn] = useState<string | null>(null);

  const [mediaViewerWidth, setMediaViewerWidth] = useState<number | null>(null);

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const userSession = useRecoilValue(sessionState);
  const { userHasOneOfRoles } = usePermissions();
  const canModifyTags = useMemo(
    () => {
      const notAsset = entityType !== MediaType.Asset;
      const isAuthor = entity.node.creator.id === userSession!.id;
      const isAdmin = userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.ORG_ADMIN,
        USER_ROLES.TENANT_ADMIN,
      ]);

      return (isAuthor || isAdmin) && notAsset;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userHasOneOfRoles, entity.node.creator.id, userSession!.id, entityType],
  );

  const { setTypeItem } = useFilterContext();
  const onTagsUpdateHandler = useCallback(
    (updatedEntity: any) => {
      setSaving(true);

      const newCachedEntity = {
        cursor: entity.cursor,
        tooltip: updatedEntity.name,
        node: updatedEntity,
      };

      setTypeItem(newCachedEntity, entityType);

      setSaving(false);
    },
    [setTypeItem, entityType, entity.cursor],
  );

  const onCloseMediaViewer = useCallback(() => {
    setActiveImageUrn(null);

    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  const updateEntityMutation = ENTITY_UPDATE_MUTATION[entityType](locationId);
  const onSubmit = useCallback(
    async (values: any) => {
      setSaving(true);

      const { levelId, ...input } = values;

      /**
       * Omit level tags and keep other tags because the mutation
       * just overrides metadata.
       */
      input.metadata = [
        ...entity.node.metadata
          .filter(({ type }) => type !== MediaMetadataType.Level)
          .map(({ id }) => ({
            id,
            type: MediaMetadataType.PhotoTag,
          })),
      ];

      if (levelId) {
        input.metadata.push({
          id: levelId,
          type: MediaMetadataType.Level,
        });
      }

      const mutationResult = await updateEntityMutation.mutateAsync({
        organizationId: activeOrg.id,
        id: entity.node.id,
        ...input,
      });

      const updatedEntity = getUpdatedEntityResult(entityType, mutationResult);
      const newItem = {
        cursor: entity.cursor,
        tooltip: updatedEntity.name,
        node: updatedEntity,
      };

      setTypeItem(newItem, entityType);

      setSaving(false);
    },
    [entity, entityType, setTypeItem, updateEntityMutation, activeOrg.id],
  );

  const { unhighlightMediaMarker, highlightMediaMarker } = useHighlightMediaMarker();
  const { useViewState } = useImmersiveViewer();
  const viewState = useViewState();

  /**
   * @TODO: Implement url tracking for the images.
   */
  useEffect(() => {
    if (entity.node.images && entity.node.images.length > 0) {
      setActiveImageUrn(entity.node.images[0].id);

      if (highlightMediaMarker) {
        highlightMediaMarker(entity.node.images[0].id);
      }
    }
  }, [setActiveImageUrn, entity.node.images, highlightMediaMarker]);

  const allImageTags = useLocationMapMetadataOptions(MediaMetadataType.PhotoTag);

  const entityDataFields = useEntityFields(entity);
  const initialValues = useInitialValues(entity);
  const errorHandler = useCallback(
    () => `${MEDIA_TYPES[entityType].singularLabel} could not be updated.`,
    [entityType],
  );

  /**
   * @TODO: Remove this when Asset and POI GQL resolvers
   * are extended to provide image details like metadata.
   *
   * WORKAROUND STARTS!
   */
  const imageData = useImageData();
  const filteredImageData = useMemo(() => {
    if (!entity.node.images || entity.node.images.length === 0) {
      return [];
    }

    const imageIds = entity.node.images.flatMap(item => item.id);
    /**
     * Remain only those images that exist in the current entity.
     */
    return imageData.filter(item => imageIds.includes(item.node.id));
  }, [imageData, entity.node.images]);
  /**
   * WORKAROUND ENDS!
   */

  const unhighlightEntityMediaMarker = useCallback(
    (mediaUrnId: string) => {
      if (entity.node.images?.length) {
        /**
         * Remove from the override filter only those images that
         * are not associated with the active entity.
         */
        const removeFromOverrideFilter = !entity.node.images.some(item => item.id === mediaUrnId);

        return unhighlightMediaMarker(mediaUrnId, removeFromOverrideFilter);
      }

      return unhighlightMediaMarker(mediaUrnId);
    },
    [entity.node.images, unhighlightMediaMarker],
  );

  const { onDelete, onTagsUpdate, onFlagUpdate, onImageInfoUpdate } = useDefaultEventHandlers();

  return (
    <>
      {activeImageUrn && (
        <IdsMediaViewer
          onDelete={onDelete}
          onFlagUpdate={onFlagUpdate}
          onImageInfoUpdate={onImageInfoUpdate}
          onTagsUpdate={onTagsUpdate}
          tenantId={activeTenant!.id}
          orgId={activeOrg.id}
          mode={MEDIA_VIEWER_MODES.IMMERSIVE}
          heightMediaLoader='60%'
          initialWidth={MEDIA_VIEWER_WIDTHS.HALF}
          availableWidth={(viewState as any).width}
          tabs={tabs}
          thumbnailCount={100}
          noImageMessage='This image is not included in the current media filters.'
          style={style}
          allImageTags={allImageTags}
          media={filteredImageData}
          activeMediaId={activeImageUrn}
          selectMediaItemData={selectMediaItemData}
          onClose={onCloseMediaViewer}
          onMediaViewerResize={setMediaViewerWidth}
          hideFullScreen={true}
          hideDelete={true}
          hideEdit={true}
          loadActiveImageDetail={true}
          loadAllThumbnails={true}
          hideFlag={true}
          modal={false}
          fullscreen={false}
          hideClose={false}
          title={undefined}
          onActiveMediaChange={undefined}
          onActiveMediaLoaded={undefined}
          activeImageDetail={undefined}
          hideContextMenu={undefined}
          disableFlag={undefined}
          setActiveMediaAndHighlightOnMap={undefined}
          unhighlightMediaMarker={unhighlightEntityMediaMarker}
          highlightMediaMarker={highlightMediaMarker}
          open={undefined}
          actionButtons={undefined}
        />
      )}

      <MapEntityWrapper mediaViewerWidth={mediaViewerWidth} hasImage={!!activeImageUrn}>
        <Backdrop className={styles.savingOverlay} sx={sx.backdrop} open={saving}>
          <CircularProgress />
        </Backdrop>
        <Grid container direction='column' gap={1} p={1}>
          <IdsForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
            errorHandler={errorHandler}
            successMessage={`${MEDIA_TYPES[entityType].singularLabel} updated.`}
          >
            <Grid container item direction='column' gap={1}>
              {/* Actions */}
              <Grid item container xs='auto' direction='row' justifyContent='flex-end' gap={1}>
                <Actions
                  onClose={onCloseMediaViewer}
                  entity={entity}
                  locationId={locationId}
                  isEditMode={isEditMode}
                  setIsEditMode={setIsEditMode}
                  saving={saving}
                  setSaving={setSaving}
                />
              </Grid>

              {/* Data */}
              <Grid item xs='auto'>
                <EntityData fields={entityDataFields} isEditMode={isEditMode} />
              </Grid>
            </Grid>
          </IdsForm>

          {/* Tags */}
          <Grid item xs='auto'>
            <Paper>
              <Box px={1} py={1}>
                <Box color={labelColor} textAlign='left'>
                  Tags
                </Box>
                <MapEntityTags
                  disabled={!canModifyTags}
                  orgId={activeOrg.id}
                  locationId={locationId}
                  entityId={entity.node.id}
                  creatorId={entity.node.creator.id}
                  onUpdate={onTagsUpdateHandler}
                  entityTags={entity.node.metadata}
                  metadataType={MediaMetadataType.PhotoTag}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Comments */}
          {/* @TODO: remove this condition when Asset commenting feature is available in GQL */}
          {entityType !== MediaType.Asset && (
            <Grid item xs='auto'>
              <Paper>
                <Box px={0} py={0}>
                  <CommentsSection
                    comments={entity.node.comments || []}
                    parentEntityId={entity.node.id}
                    orgId={activeOrg.id}
                  />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </MapEntityWrapper>
    </>
  );
};

export default IdsMapEntityViewer;
