import React from 'react';

import { ITenantListItemFragType } from '../TenantListItem';

import IdsSearchableList from '../../../components/ids-lists/IdsSearchableList';

export const searchTenantItem = (item: ITenantListItemFragType, query: string) => {
  const searchProps = [item.id, item.subdomain, item.myUserRole, item.name];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

export const sortTenantsItems = (a: ITenantListItemFragType, b: ITenantListItemFragType) => {
  return a.name.localeCompare(b.name); // alphabetical order
};

export interface ITenantsListProps {
  tenantsItems: ITenantListItemFragType[] | undefined;
  renderTenantItem: (item: ITenantListItemFragType) => React.ReactElement;
  [key: string]: any;
}

const TenantsList: React.FC<ITenantsListProps> = ({ tenantsItems, renderTenantItem, ...rest }) => (
  <IdsSearchableList
    header={undefined}
    filterMode={undefined}
    actions={undefined}
    loading={undefined}
    error={undefined}
    items={tenantsItems}
    renderItem={renderTenantItem}
    searchPlaceholder={'Search Tenants'}
    searchItem={searchTenantItem}
    filters={undefined}
    sortItems={sortTenantsItems}
    noItemsMessage='No Tenants'
    noItemsForFilterMessage='No Tenants match your search'
    {...rest}
  />
);

export default TenantsList;
