import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import useConfirmation from './useConfirmation';
import useMounted from './useMounted';

// Requests confirmation to perform the specified action, performs it, and provides feedback
// on the success or failure of the action
const useConfirmedActionWithFeedback = () => {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { requestConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();

  // Calling state setters here ensure that if this action takes a long time and the component unmounts,
  // the setter won't be called on an unmounted component
  const setState = useCallback(
    setter => {
      if (mounted.current) {
        setter();
      }
    },
    [mounted],
  );

  const handleAction = useCallback(
    async (
      doAction,
      title,
      description,
      successMessage,
      failureMessage,
      getError = () => undefined,
      onSuccess,
      onCancel,
      options,
    ) => {
      setDone(false); // reset the done flag

      const onConfirm = async () => {
        if (!doAction) return;

        setState(() => setLoading(true));

        const handleError = error => {
          setState(() => setDone(false));
          enqueueSnackbar(failureMessage || 'Something went wrong', {
            variant: 'error',
          });
        };

        try {
          let result;
          if (doAction.constructor.name === 'AsyncFunction') {
            // async function
            result = await doAction();
          } else {
            result = doAction();
          }

          const error = getError(result);
          if (error) {
            // action didn't throw an error, but it failed, an error was returned
            handleError(error);
          } else {
            setState(() => setDone(true));
            enqueueSnackbar(successMessage || 'Success', {
              variant: 'success',
            });

            if (onSuccess) {
              onSuccess();
            }
          }
        } catch (error) {
          handleError(error);
        }

        setState(() => setLoading(false));
      };

      requestConfirmation({
        title: title,
        description: description,
        confirmButtonLabel: options?.confirmButtonLabel,
        confirmButtonProps: options?.confirmButtonProps,
        onConfirm: onConfirm,
        onCancel: onCancel,
      });
    },
    [requestConfirmation, setLoading, enqueueSnackbar, setState],
  );

  return {
    done: done,
    loading: loading,
    handleAction,
  };
};

export default useConfirmedActionWithFeedback;
