import React from 'react';

import IdsListItemMenu from '../../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../../components/ids-lists/IdsListItemMenuItem';
import { DeleteOutlineIcon, EditOutlineIcon } from '../../../../theme/icons';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICollectionListItemMenuProps {}

const CollectionListItemMenu: React.FC<ICollectionListItemMenuProps> = () => {
  return (
    <IdsListItemMenu>
      <IdsListItemMenuItem disabled={true} icon={<EditOutlineIcon />}>
        Edit
      </IdsListItemMenuItem>
      <IdsListItemMenuItem disabled={true} icon={<DeleteOutlineIcon />}>
        Delete
      </IdsListItemMenuItem>
    </IdsListItemMenu>
  );
};

export default CollectionListItemMenu;
