import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';

import useImmersiveViewer from '../../../hooks/useImmersiveViewer';
import MapOverlay from '../MapOverlay';

import styles from './MapMarker.module.css';

const ANCHOR_OFFSETS = {
  bottom: (width, height) => [-width / 2, -height],
  center: (width, height) => [-width / 2, -height / 2],
  // support other anchor positions as needed
};

/**
 * **NOTE:** Child components should be memoized to avoid rerendering constantly when view state changes.
 */
const MapMarker = ({
  longitude,
  latitude,
  pixelOffsetX,
  pixelOffsetY,
  anchor,
  onClick,
  onResize,
  style,
  children,
  ...rest
}) => {
  const handleResize = useCallback(
    (width, height) => {
      if (onResize) {
        onResize(width, height);
      }
    },
    [onResize],
  );

  // Need to keep the height and width updated so marker can be placed correctly
  const { width, height, ref: containerRef } = useResizeDetector({ onResize: handleResize });
  const { useViewport } = useImmersiveViewer();
  const viewport = useViewport();

  const rawScreenPos = useMemo(() => {
    return viewport ? viewport.project([longitude, latitude]) : [0, 0];
  }, [longitude, latitude, viewport]);

  const containerWidth = width || 0;
  const containerHeight = height || 0;
  const anchorOffset = ANCHOR_OFFSETS[anchor](containerWidth, containerHeight);

  // Future TODO: calculate change in offset based on pitch (maybe use projection with altitude?)
  const offsetScreenPos = rawScreenPos?.length && [
    rawScreenPos[0] + anchorOffset[0] + pixelOffsetX,
    rawScreenPos[1] + anchorOffset[1] + pixelOffsetY,
  ];

  return (
    <MapOverlay
      {...rest}
      className={styles.mapMarkerContainer}
      style={{
        ...(offsetScreenPos?.length && {
          left: offsetScreenPos[0],
          top: offsetScreenPos[1],
        }),
        ...(onClick && { cursor: 'pointer' }), // Indicate elem is clickable
        ...style,
      }}
      ref={containerRef}
      onClick={onClick}
    >
      {children}
    </MapOverlay>
  );
};

MapMarker.defaultProps = {
  pixelOffsetX: 0,
  pixelOffsetY: 0,
  anchor: 'bottom',
};

MapMarker.propTypes = {
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  pixelOffsetX: PropTypes.number,
  pixelOffsetY: PropTypes.number,
  anchor: PropTypes.string,
  onClick: PropTypes.func,
  onResize: PropTypes.func,
  style: PropTypes.object,
};

export default MapMarker;
