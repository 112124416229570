import React from 'react';

import IdsSubmitButton, { IIdsSubmitButtonProps } from '../../ids-forms/IdsSubmitButton';

export interface ISubmitButtonProps extends IIdsSubmitButtonProps {
  label?: string;
}

const SubmitButton: React.FC<ISubmitButtonProps> = props => {
  const label = props.label || 'Submit';
  return (
    <IdsSubmitButton variant='contained' color='primary' {...props}>
      {label}
    </IdsSubmitButton>
  );
};

export default SubmitButton;
