/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import { gql } from 'graphql-request';

import { USER_ROLES } from '../../../constants/users';
import useOrgGraphQuery from '../../../hooks/useOrgGraphQuery';
import { getOrgDetailsRoute, getOrgMapRoute } from '../../../utils/routes';
import usePermissions from '../../../hooks/usePermissions';

import { InfoIcon } from '../../../theme/icons';
import NavBar from '../../../components/NavBar';
import { renderNavItems } from '../../../components/NavBar/NavTree';
import { useOrganizationKeys } from '../../../services/OrganizationsService';
import { navigationExpanded } from '../../../atoms/navigation';
import { activeOrganizationState } from '../../../atoms/organizations';
import { activeTenantState } from '../../../atoms/tenants';
import usePrevious from '../../../hooks/usePrevious';

import MenuIconButton from '../../MenuIconButton';

import InfoIconButton from './InfoIconButton';
import NavHeader from './NavHeader';
import { useBuildNavConfig } from './useBuildNavConfig';

const UserOrganizationsQuery = gql`
  query Organizations($tenantId: ID) {
    organizations(tenantId: $tenantId) {
      totalCount
    }
  }
`;

function DashboardNavBar() {
  const organizationKeys = useOrganizationKeys();

  const { userHasOneOfRoles } = usePermissions();

  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);
  const { data } = useOrgGraphQuery(
    [...organizationKeys.lists(activeTenant.id), 'totalCount'],
    UserOrganizationsQuery,
  );
  const orgId = organization?.id;

  const buildNavConfig = useBuildNavConfig();
  const [navConfig, setNavConfig] = useState(
    buildNavConfig(activeTenant?.subdomain, orgId, data?.organizations.totalCount),
  );
  const [navExpanded, setNavExpanded] = useRecoilState(navigationExpanded);
  const toggleExpanded = useCallback(() => setNavExpanded(prev => !prev), [setNavExpanded]);

  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);

  useEffect(() => {
    const isMapOpen = pathname =>
      matchPath(
        `${getOrgMapRoute({
          subdomain: activeTenant.subdomain,
          orgId,
        })}/*`,
        pathname,
      );

    // Don't run auto expand logic if location is the same or if the map was already open
    if (location.pathname === prevPathname || (prevPathname && isMapOpen(prevPathname))) return;

    if (navExpanded && isMapOpen(location.pathname)) {
      // auto expand the side bar if the map is open
      setNavExpanded(false);
    }
  }, [location.pathname, prevPathname, activeTenant.subdomain, orgId, navExpanded, setNavExpanded]);

  const footerNavConfigItem = useMemo(
    () => ({
      items: [
        {
          title: 'Organization Details',
          icon: InfoIcon,
          href: getOrgDetailsRoute({
            subdomain: activeTenant?.subdomain,
            orgId,
          }),
          visible: true,
        },
      ],
      expanded: navExpanded,
    }),
    [navExpanded, orgId, activeTenant],
  );

  useEffect(() => {
    if (data?.organizations) {
      setNavConfig(buildNavConfig(activeTenant?.subdomain, orgId, data.organizations.totalCount));
    }
  }, [data, orgId, activeTenant, buildNavConfig]);

  const headerItem = navExpanded ? (
    <NavHeader expanded={navExpanded} onClick={toggleExpanded} />
  ) : (
    <MenuIconButton expanded={navExpanded} onClick={toggleExpanded} />
  );

  const footerItem = useMemo(() => {
    const userHasAccess = userHasOneOfRoles([
      USER_ROLES.IDS_ADMIN,
      USER_ROLES.IDS_TEAM,
      USER_ROLES.ORG_ADMIN,
      USER_ROLES.TENANT_ADMIN,
      USER_ROLES.TENANT_TEAM,
    ]);

    return (
      userHasAccess && (
        <>
          {navExpanded ? (
            <Box pl={2} pr={2} pt={1} pb={1}>
              {renderNavItems(footerNavConfigItem)}
            </Box>
          ) : (
            <InfoIconButton />
          )}
        </>
      )
    );
  }, [userHasOneOfRoles, footerNavConfigItem, navExpanded]);

  return (
    <NavBar
      navConfig={navConfig}
      headerItem={headerItem}
      footerItem={footerItem}
      expanded={navExpanded}
      style={{ zIndex: 1111 }}
    />
  );
}

export default DashboardNavBar;
