import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { useGetAllOrganizations } from '../../services/OrganizationsService';
import { getOrgDetailsRoute, getOrgMapRoute } from '../../utils/routes';
import { sortAlphabetically } from '../../utils/genericSorting';
import { USER_ROLES } from '../../constants/users';
import Page from '../../components/Page';
import TopBar from '../../components/TopBar/index';
import IdsSearchableList from '../../components/ids-lists/IdsSearchableList';
import LoadingScreen from '../../components/LoadingScreen';
import { activeTenantState } from '../../atoms/tenants';
import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../theme/styles';
import usePermissions from '../../hooks/usePermissions';

import OrganizationListHeader from './OrganizationListHeader';
import OrganizationListItem from './OrganizationListItem';

import styles from './Organizations.module.css';

const orgManagementRoles = [
  USER_ROLES.IDS_ADMIN.name,
  USER_ROLES.IDS_TEAM.name,
  USER_ROLES.TENANT_ADMIN.name,
  USER_ROLES.TENANT_TEAM.name,
  USER_ROLES.ORG_ADMIN.name,
];

const sortOrganizations = (a, b) => sortAlphabetically(a.node.name, b.node.name);

const searchOrganizationItem = (item, query) => {
  const searchProps = [item.node.name];

  return !!searchProps.filter(prop => prop?.toLowerCase()?.includes(query.toLowerCase()))?.length;
};

function Organizations() {
  const navigate = useNavigate();
  const activeTenant = useRecoilValue(activeTenantState);
  const { userHasOneOfRoles } = usePermissions();

  const { data: organizations, error, isLoading } = useGetAllOrganizations(activeTenant.id);

  const canCreateOrg = useMemo(
    () => !isLoading && userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]),
    [isLoading, userHasOneOfRoles],
  );

  // If user only has access to 1 org, skip rendering the list page
  const pageContents = useMemo(() => {
    if (
      organizations?.length === 1 &&
      !userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ])
    ) {
      navigate(
        getOrgMapRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organizations[0].node.id,
        }),
      );
      return null;
    } else if (!isLoading) {
      return (
        <Container
          maxWidth={false}
          style={{
            // marginTop: TOP_BAR_HEIGHT,
            marginTop: 16,
            height: FULL_HEIGHT_WITHOUT_TOP_BAR,
          }}
        >
          <OrganizationListHeader canCreate={canCreateOrg} />
          <IdsSearchableList
            data-q='organization'
            items={organizations}
            renderItem={orgItem => {
              // Need to compute this per org to handle different roles per org
              const canManageOrg = orgManagementRoles.includes(orgItem.node.myUserRole);
              return (
                <OrganizationListItem
                  key={orgItem.node.id}
                  orgItem={orgItem}
                  onOpen={() =>
                    navigate(
                      getOrgMapRoute({
                        subdomain: activeTenant?.subdomain,
                        orgId: orgItem.node.id,
                      }),
                    )
                  }
                  onManage={
                    canManageOrg
                      ? () =>
                          navigate(
                            getOrgDetailsRoute({
                              subdomain: activeTenant?.subdomain,
                              orgId: orgItem.node.id,
                            }),
                          )
                      : null
                  }
                />
              );
            }}
            searchPlaceholder='Search organizations'
            searchItem={searchOrganizationItem}
            sortItems={sortOrganizations}
            noItemsMessage='You do not have access to any organizations in the IDS Explorer'
            noItemsForFilterMessage='No organizations match your search'
            loading={isLoading}
            error={error && 'Organizations could not be loaded'}
          />
        </Container>
      );
    } else {
      return <LoadingScreen fullscreen={true} />;
    }
  }, [error, isLoading, navigate, organizations, canCreateOrg, activeTenant, userHasOneOfRoles]);

  return (
    <Page title='Organizations' data-q='page'>
      <TopBar className={styles.topBar} />
      {pageContents}
    </Page>
  );
}

export default Organizations;
