import React, { useCallback, useMemo } from 'react';

import { MenuItem, MenuItemProps, Typography } from '@mui/material';

import IdsSelect, { IIdsSelectProps, ISelectOption } from '../IdsSelect';
import IdsCheckbox, { IIdsCheckboxProps } from '../IdsCheckbox';

export interface IIdsMultiSelectProps extends Omit<IIdsSelectProps, 'renderOption' | 'onChange'> {
  disableSelectAll?: boolean;
  selectAllLabel?: string;
  value: ISelectOption['value'][];
  onChange: (value: IIdsMultiSelectProps['value']) => any;
  optionProps?: {
    menuItem?: Omit<MenuItemProps, 'value'>;
    checkbox?: Omit<IIdsCheckboxProps, 'label' | 'checked' | 'indeterminate'>;
  };
}

const IdsMultiSelect: React.FC<IIdsMultiSelectProps> = ({
  options,
  optionProps,
  value = [],
  onChange,
  disableSelectAll = false,
  selectAllLabel = 'All',
  size = 'small',
  ...rest
}) => {
  const SELECT_ALL_OPTION = useMemo(
    () => ({
      label: selectAllLabel,
      value: 'select_all_option',
    }),
    [selectAllLabel],
  );

  const _options = useMemo(() => {
    const ops = [...options];

    if (!disableSelectAll) {
      ops.unshift(SELECT_ALL_OPTION); // Include select all option
    }

    return ops;
  }, [options, disableSelectAll, SELECT_ALL_OPTION]);

  const labelsValue = useMemo(() => {
    return value
      .map(v => options.find(o => o.value === v)) // Get value options
      .filter(o => o?.label) // Filter out any invalid options
      .map(o => o.label); // Map to the label
  }, [value, options]);

  const _onChange = useCallback(
    event => {
      const { value: selectedLabels } = event.target;

      // On autofill we get a stringified value
      const labelArray =
        typeof selectedLabels === 'string' ? selectedLabels.split(',') : selectedLabels;

      const _allWereSelected = labelsValue.length === options.length;

      // Select component doesn't have a concept of
      // option values, need to turn back into an array
      // of option values instead of option labels

      // (.filter) Filter out select all option
      // (.map) Map remaining options to their values
      let valArray = (labelArray as string[])
        .filter(l => l !== SELECT_ALL_OPTION.label)
        .map(l => options.find(o => o.label === l).value);

      if (!disableSelectAll) {
        // Note, this option is not saved in the values, so
        // it being set could be selecting or unselecting
        const allOptionSelected = labelArray.includes(SELECT_ALL_OPTION.label);

        // All option just selected or all options selected manually
        if ((!_allWereSelected && allOptionSelected) || labelArray.length === options.length) {
          valArray = options.map(o => o.value);
        }
        // All option just deselected or all options were deselected manually
        else if ((_allWereSelected && allOptionSelected) || !valArray.length) {
          valArray = [];
        }
      }

      if (onChange) {
        onChange(valArray);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, labelsValue, value, options, SELECT_ALL_OPTION.label, disableSelectAll],
  );

  const renderValue = useCallback(
    arrayVal => {
      return arrayVal.length === options.length ? 'All' : arrayVal.join(', ');
    },
    [options.length],
  );

  const renderOption = useCallback(
    option => {
      const isSelectAll = option.value === SELECT_ALL_OPTION.value;

      return (
        <MenuItem key={option.value} value={option.label} {...optionProps?.menuItem}>
          <IdsCheckbox
            label={<Typography variant='body2'>{option.label}</Typography>}
            checked={
              // selected option
              labelsValue.indexOf(option.label) > -1 ||
              // All option and all options are selected
              (isSelectAll && labelsValue.length === options.length)
            }
            indeterminate={Boolean(
              // Select all option and at least one other option is selected
              isSelectAll && labelsValue.length && labelsValue.length < options.length,
            )}
            size={size}
            {...optionProps?.checkbox}
          />
        </MenuItem>
      );
    },
    [labelsValue, options, SELECT_ALL_OPTION.value, size, optionProps],
  );

  return (
    <IdsSelect
      multiple
      options={_options}
      onChange={_onChange}
      value={labelsValue}
      renderValue={renderValue}
      renderOption={renderOption}
      size={size}
      {...rest}
    />
  );
};

export default IdsMultiSelect;
