import React from 'react';
import PropTypes from 'prop-types';

import IdsDialog from '../../../components/IdsDialog';
import CreateOrganizationForm from '../CreateOrganizationForm';

function CreateOrganizationDialog({ open, onOpen, onClose }) {
  return (
    <>
      <IdsDialog
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        title='Create New Organization'
        dialogKey='create'
      >
        <CreateOrganizationForm onSubmitSuccess={onClose} onCancel={onClose} />
      </IdsDialog>
    </>
  );
}

CreateOrganizationDialog.defaultProps = {
  open: false,
};

CreateOrganizationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default CreateOrganizationDialog;
