import React, { useCallback, useMemo, useState, ChangeEvent, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Alert,
  Box,
  Paper,
  Divider,
  InputAdornment,
  List,
  SvgIcon,
  TextField,
  TextFieldProps,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';

import { SearchIcon } from '../../../theme/icons';
import IdsSelect, { IIdsSelectProps, ISelectOption } from '../../ids-inputs/IdsSelect';

import '../../../theme/globalStyles.css';
import styles from './IdsSearchableListV2.module.css';
import Paginator from './Paginator';

export const DEFAULT_PAGE_SIZE = 25;

type Sorter = {
  label: string;
  key: string;
  sortItems: (a: any, b: any) => number;
};

type Filter = {
  key: string;
  label: IIdsSelectProps['label'];
  multiple: IIdsSelectProps['multiple'];
  options: {
    label: ISelectOption['label'];
    key: ISelectOption['value'];
    default?: boolean;
    filterItem: (item: any) => boolean;
  }[];
};

type ActiveFilter = {
  key: string;
  value: string;
  filterItems: ((item: any) => boolean)[];
};

export interface IIdsSearchableListV2Props {
  items: any[];
  renderItem: (item: any) => React.ReactNode;
  searchPlaceholder: TextFieldProps['placeholder'];
  noItemsMessage: React.ReactNode;
  noItemsForFilterMessage: React.ReactNode;
  actions?: React.ReactNode;
  searchItem: (item: any, query: string) => boolean;
  sorters: Sorter[];
  filters: Filter[];
  loading?: boolean;
  error?: React.ReactNode;
}

const applyPagination = (
  items: IIdsSearchableListV2Props['items'],
  page: number,
  limit: number,
) => {
  return items.slice(page * limit, page * limit + limit);
};

/**
 * Use this component if you need to render multiple filters
 * and "sort by" field.
 */
const IdsSearchableListV2: React.FC<IIdsSearchableListV2Props> = ({
  items,
  renderItem,
  searchPlaceholder,
  searchItem,
  filters,
  sorters,
  actions,
  noItemsMessage,
  noItemsForFilterMessage,
  loading,
  error,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  /**
   * One ActiveFilter represents one select field.
   */
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);

  useEffect(() => {
    const defaultActiveFilters: ActiveFilter[] = [];
    filters.forEach(filter => {
      if (!filter.multiple) {
        filter.options.find(option => {
          if (option.default) {
            defaultActiveFilters.push({
              key: filter.key,
              value: option.key,
              filterItems: [option.filterItem],
            });
            return true;
          }
          return false;
        });
      } else {
        const multipleValues: any = [];
        const multipleItems: any[] = [];

        filter.options.forEach(option => {
          if (option.default) {
            multipleValues.push(option.key);
            multipleItems.push(option.filterItem);
          }
        });
        if (multipleValues.length) {
          defaultActiveFilters.push({
            key: filter.key,
            value: multipleValues,
            filterItems: multipleItems,
          });
        }
      }
    });
    setActiveFilters(defaultActiveFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [sorter, setSorter] = useState(sorters[0]);

  const handleSorterControlChange = (event: SelectChangeEvent<unknown>) => {
    const newSorter = sorters.find(s => s.key === (event.target.value as string));

    if (newSorter) {
      setSorter(newSorter);
    }
  };

  const handleQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setQuery(event.target.value);
    },
    [setQuery],
  );

  const handlePageChange = useCallback((_, newPage: number) => setPage(newPage), [setPage]);

  const sortedItems = useMemo(() => {
    return [...items.sort(sorter.sortItems)];
  }, [items, sorter]);

  // Apply filters to sorted items
  const filteredItems = useMemo(() => {
    const filtersListFlat = activeFilters.map(item => item.filterItems);

    // Each sorted item should pass through the filters list.
    const newFilteredItems = sortedItems.filter(sortedItem => {
      if (query && !searchItem(sortedItem, query)) {
        return false;
      }

      /**
       * Filters list - array of arrays. Sorted items should pass
       * at least one filter inside each subarray.
       */
      return filtersListFlat.every(groupOfFilters =>
        groupOfFilters.some((filter: any) => filter(sortedItem)),
      );
    });

    if (newFilteredItems.length < page * DEFAULT_PAGE_SIZE + 1) {
      const newPage = Math.floor(newFilteredItems.length / DEFAULT_PAGE_SIZE);
      setPage(newPage);
    }

    return newFilteredItems;
  }, [sortedItems, page, setPage, activeFilters, searchItem, query]);

  const paginatedItems = useMemo(
    () => applyPagination(filteredItems, page, DEFAULT_PAGE_SIZE),
    [filteredItems, page],
  );

  const singleChoiceFilter = (filter: Filter) => {
    // Find this select field value among active filters.
    const value = activeFilters.find(activeFilter => activeFilter.key === filter.key)?.value || '';

    const optionsMapped = filter.options.map(option => ({
      label: option.label,
      value: option.key,
    }));

    // Get active filters except this one.
    const newActiveFilters: ActiveFilter[] = activeFilters.filter(
      activeFilter => activeFilter.key !== filter.key,
    );

    return (
      <IdsSelect
        label={filter.label}
        options={optionsMapped}
        value={value}
        onChange={(event: SelectChangeEvent<unknown>) => {
          /**
           * Empty value means the filter was unset.
           * It should not be added to active filters.
           */
          if (event.target.value) {
            const filterItems = filter.options.find(
              option => option.key === event.target.value,
            )?.filterItem;

            if (filterItems) {
              newActiveFilters.push({
                key: filter.key,
                value: event.target.value as string,
                filterItems: [filterItems],
              });
            }
          }

          setActiveFilters(newActiveFilters);
        }}
        size='medium'
        className={styles.selectFilter}
      />
    );
  };

  const multipleChoiceFilter = (filter: Filter) => {
    const optionsMapped = filter.options.map(option => ({
      label: option.label,
      value: option.key,
    }));

    // Find this select field value among active filters.
    const value = activeFilters.find(activeFilter => activeFilter.key === filter.key)?.value || [];

    // Get active filters except this one.
    const newActiveFilters: ActiveFilter[] = activeFilters.filter(
      activeFilter => activeFilter.key !== filter.key,
    );

    return (
      <IdsSelect
        label={filter.label}
        multiple
        options={optionsMapped}
        value={value}
        renderValue={selected => (selected as ActiveFilter['value'][]).join(', ')}
        renderOption={(option: (typeof optionsMapped)[number]) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={(value as ActiveFilter['value'][]).indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        )}
        onChange={(event: SelectChangeEvent<unknown>) => {
          /**
           * any type is a workaround here.
           * Actual value type is string[].
           */
          const value: any = event.target.value;

          /**
           * Empty value means the filter was unset.
           * It should not be added to active filters.
           */
          if (value.length) {
            newActiveFilters.push({
              key: filter.key,
              value: value,
              filterItems: filter.options.reduce((accumulator: any[], item) => {
                if (value.includes(item.key)) {
                  accumulator.push(item.filterItem);
                }

                return accumulator;
              }, []),
            });
          }

          setActiveFilters(newActiveFilters);
        }}
        size='medium'
        className={styles.selectFilter}
      />
    );
  };

  const renderedFilters = filters.map((filter, index) => (
    <Box key={index}>
      {filter.multiple ? multipleChoiceFilter(filter) : singleChoiceFilter(filter)}
    </Box>
  ));

  const sortersMapped = useMemo(
    () =>
      sorters.map(s => ({
        label: s.label,
        value: s.key,
      })),
    [sorters],
  );
  const renderedSorter = (
    <Box>
      <IdsSelect
        label='Sort by'
        options={sortersMapped}
        value={sorter.key}
        onChange={handleSorterControlChange}
        size='medium'
        className={styles.selectFilter}
      />
    </Box>
  );

  const paginator = (
    <Paginator
      loading={loading}
      count={filteredItems.length}
      onPageChange={handlePageChange}
      page={page}
    />
  );

  return (
    <Box component={Paper} {...rest}>
      <PerfectScrollbar>
        <Box
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          gap={2}
          className={styles.listHeaderContainer}
        >
          <Box>
            <TextField
              className={styles.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon fontSize='small' color='action'>
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={searchPlaceholder}
              value={query}
              variant='outlined'
            />
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='space-between' flexWrap='nowrap'>
            <Box display='flex' flexWrap='nowrap' flexDirection='row' gap={1}>
              {renderedFilters}
              {renderedSorter}
              {actions && <Box>{actions}</Box>}
            </Box>
            <Box mr={1} />
          </Box>
        </Box>
      </PerfectScrollbar>
      <Divider />
      {paginator}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          {error || (!loading && (!paginatedItems || paginatedItems.length === 0)) ? (
            <Alert severity={error ? 'error' : items?.length ? 'warning' : 'info'}>
              {error || (items?.length ? noItemsForFilterMessage : noItemsMessage)}
            </Alert>
          ) : (
            <List className={styles.list}>{paginatedItems.map(item => renderItem(item))}</List>
          )}
        </Box>
      </PerfectScrollbar>
      {paginator}
    </Box>
  );
};

export default IdsSearchableListV2;
