import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { ImageType, MediaType } from '../../../../../constants/media';
import { EntityType } from '../../../../../constants/entities';
import usePermissions from '../../../../../hooks/usePermissions';
import LocationImageUploader, {
  ILocationImageBatchUploaderProps,
} from '../../../../../components/ids-inputs/uploaders/LocationImageUploader';
import { activeProjectState } from '../../../../../atoms/projects';

import styles from '../ProjectMediaTab.module.css';

const consumerId = 'ProjectImageUploader'; // Important: this needs to be a const to prevent constant rerenders

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IActionsProps
  extends Pick<ILocationImageBatchUploaderProps, 'onUploadStarted' | 'onUploadCompleted'> {}

const Actions: React.FC<IActionsProps> = ({ onUploadStarted, onUploadCompleted }) => {
  const { userHasPermission } = usePermissions();
  const activeProject = useRecoilValue(activeProjectState);
  const [uploaderOpen, setUploaderOpen] = useState(false);

  const handleUploaderOpen = useCallback(() => {
    setUploaderOpen(true);
  }, [setUploaderOpen]);

  const handleUploaderClose = useCallback(() => {
    setUploaderOpen(false);
  }, [setUploaderOpen]);

  const canCreateProjectPhoto = useMemo(
    () => userHasPermission('create', EntityType.ProjectPhoto),
    [userHasPermission],
  );

  const disabledImageTypes = useMemo<ImageType[]>(
    () => [MediaType.HDPhoto, MediaType.PanoramicPhoto],
    [],
  );

  if (!canCreateProjectPhoto) {
    return null;
  }

  return (
    <>
      <div className={styles.actionsContainer}>
        <Button color='secondary' variant='outlined' onClick={handleUploaderOpen}>
          Upload
        </Button>
      </div>
      <LocationImageUploader
        consumerId={consumerId}
        open={uploaderOpen}
        onOpen={handleUploaderOpen}
        onClose={handleUploaderClose}
        defaultLocationId={activeProject!.locationId}
        defaultProjectId={activeProject!.id}
        disabledImageTypes={disabledImageTypes}
        onUploadCompleted={onUploadCompleted}
        onUploadStarted={onUploadStarted}
      />
    </>
  );
};

export default Actions;
