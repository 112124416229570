import { useCallback } from 'react';
import { gql } from 'graphql-request';

import { IEdge, IImageRep, ImageType, IMediaMetadata } from '../constants/media';
import { ICustomFieldMetadata } from '../constants/customFields';
import useFilterContext from '../hooks/useFilterContext';

export const GalleryImageFrag = gql`
  fragment GalleryImageFrag on PrismaImage {
    id
    capturedAt
    published
    metadata {
      type
      id
      value
    }
    customFieldMetadata {
      type
      id
      value
    }
    reps {
      url
      name
    }
    flagged
  }
`;

export const GalleryImageRepsFrag = gql`
  fragment GalleryImageRepsFrag on PrismaImage {
    id
    reps {
      url
      name
    }
  }
`;

export interface IGalleryImageDataEdge extends IEdge {
  node: {
    id: string;
    reps: IImageRep[];
    metadata: IMediaMetadata[];
    flagged?: boolean;
    published?: boolean;
    capturedAt?: string;
    customFieldMetadata: ICustomFieldMetadata[];
  };
  type: ImageType;
  thumbnail: string;
  position?: {
    heading: number | null;
  };
}

export interface IGalleryImageRepsEdge extends IEdge {
  node: {
    id: string;
    reps: IImageRep[];
  };
}

export const prepareBaseMediaItemData = (
  item: IGalleryImageDataEdge | IGalleryImageRepsEdge,
  type: ImageType,
) => {
  return {
    ...item,
    type,
    thumbnail: item.node.reps.find(r => r.name === 'medium')?.url,
  };
};

export const prepareBaseMediaData = (
  data: IGalleryImageDataEdge[] | IGalleryImageRepsEdge[],
  type: ImageType,
) => {
  return data.map(item => prepareBaseMediaItemData(item, type));
};

export const useOnGalleryPageLoaded = () => {
  const { setTypeItems, getTypeItem } = useFilterContext();

  const onPageLoaded = useCallback(
    (
      edges: IGalleryImageDataEdge[],
      type: ImageType,
      setMediaCount: (func: (prevCount: number) => number) => void,
    ) => {
      setTypeItems(prepareBaseMediaData(edges, type), type);
      setMediaCount(prevCount => prevCount + edges.length);
    },
    [setTypeItems],
  );

  const onRepsLoaded = useCallback(
    (edges: IGalleryImageRepsEdge[], type: ImageType) => {
      const items: IGalleryImageRepsEdge[] = [];

      edges.forEach(e => {
        const existingItem = getTypeItem(type, e.node.id);

        if (existingItem) {
          const { node: existingNode, ...existingRest } = existingItem;
          existingItem.node.reps = e.node.reps;

          items.push({
            ...existingRest,
            node: {
              ...existingNode,
              reps: e.node.reps,
            },
          });
        }
      });

      setTypeItems(prepareBaseMediaData(items, type), type);
    },
    [setTypeItems, getTypeItem],
  );

  return {
    onPageLoaded,
    onRepsLoaded,
  };
};
