import Uppy, { UppyOptions, UppyFile } from '@uppy/core';

import { getUUID } from './helpers';

// Track uppy instances by consumer. The component that consumes the uppy instance is considered the consumer.
const uppyInstanceManager: Record<string, Uppy[]> = {};

export const getUppyConsumerKey = (uppy: Uppy) => {
  const { meta } = uppy.getState();
  return (meta.consumerKey as string) || null;
};

export const createUppy = (consumerKey: string, options?: Optional<UppyOptions, 'id'>) => {
  const uppy = new Uppy({
    id: getUUID(),
    allowMultipleUploadBatches: false,
    ...options,
    meta: {
      ...options?.meta,
      consumerKey,
    },
  });

  const onBeforeFileAdded = (file: UppyFile) => {
    const files = uppy.getFiles();
    const { recoveredState } = uppy.getState();

    // Only allow duplicate files if reselecting a ghost file
    // Only allow reselection of ghost files in the recovery scenario (cannot add new files)
    const duplicateFile = files.find(f => f.id === file.id);

    // Need the !! at the beginning to ensure this is a boolean (doesn't work with just truthy or falsy value)
    return !!(recoveredState ? (duplicateFile as any)?.isGhost : !duplicateFile);
  };

  uppy.setOptions({ onBeforeFileAdded });

  if (!uppyInstanceManager[consumerKey]) {
    uppyInstanceManager[consumerKey] = [];
  }

  uppyInstanceManager[consumerKey].push(uppy);

  return uppy;
};

export const getUppy = (consumerKey: string) => {
  const consumerInstances = uppyInstanceManager[consumerKey];
  return consumerInstances ? consumerInstances[consumerInstances.length - 1] : null;
};

export const getOrCreateUppy = (consumerKey: string, options?: Optional<UppyOptions, 'id'>) => {
  return getUppy(consumerKey) ?? createUppy(consumerKey, options);
};

export const closeUppy = (uppy: Uppy) => {
  const consumerKey = getUppyConsumerKey(uppy);
  if (!consumerKey) {
    uppy.close();
    return;
  }

  const index = uppyInstanceManager[consumerKey]?.findIndex(u => u.getID() === uppy.getID());
  if (index !== undefined && index >= 0) {
    uppyInstanceManager[consumerKey][index].close();
    uppyInstanceManager[consumerKey].splice(index, 1); // Remove the uppy instance
  }
};
