import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';

import MapMarker from '../MapMarker';
import { CloseIcon } from '../../../theme/icons';
import FilledIconButton from '../../ids-buttons/FilledIconButton';
import LocationImage from '../LocationImage';

import styles from './LocationPreview.module.css';

const sxStyles = {
  locationImgContainer: {
    backgroundColor: 'background.dark',
  },
  arrowDown: theme => ({
    borderTop: `10px solid ${theme.palette.background.paper}`,
  }),
};

const EMPTY_FN = () => {};

const LocationPreview = ({
  location,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onClose,
  loading,
  ...rest
}) => {
  const handleClick = useCallback(
    e => {
      if (onClick) {
        onClick(location);
      }
    },
    [onClick, location],
  );

  const handleClose = useCallback(
    e => {
      e.stopPropagation(); // Prevent click event from firing
      if (onClose) {
        onClose(e);
      }
    },
    [onClose],
  );

  if (!loading && !location) return null;

  const { id, name, position, address } = location;
  const { streetLine1, city, state, postalCode } = address || {};

  // Only show commas if the address elements are present
  const addressLine2 = `${city ? `${city}, ` : ''}${state || ''}${
    postalCode ? ` ${postalCode}` : ''
  }`;

  return (
    <MapMarker
      key={`previewLocation-${id}`}
      longitude={position.longitude}
      latitude={position.latitude}
      pixelOffsetY={-37}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      <Card className={styles.previewCard}>
        <CardActionArea onClick={handleClick} disabled={loading} data-testid='previewClickAction'>
          <LocationImage height={75} />
          <CardContent>
            <Typography variant='h6' noWrap>
              {loading && !name ? <Skeleton data-testid='name-loader' /> : name}
            </Typography>
            <Typography variant='body2' color='text.secondary' noWrap>
              {loading && !streetLine1 ? (
                <Skeleton data-testid='streetLine1-loader' />
              ) : (
                streetLine1
              )}
            </Typography>
            <Grid
              container
              direction='row'
              columnSpacing={1}
              justifyContent='space-between'
              wrap='nowrap'
            >
              <Grid item xs zeroMinWidth>
                <Typography variant='body2' color='text.secondary' noWrap>
                  {loading && !addressLine2 ? (
                    <Skeleton data-testid='addressLine2-loader' />
                  ) : (
                    addressLine2
                  )}
                </Typography>
              </Grid>
              <Grid item xs='auto'>
                {/* Set onClick to empty function so it has clickable styling */}
                <Chip
                  label='Explore'
                  size='small'
                  color='info'
                  onClick={EMPTY_FN}
                  className={styles.tipChip}
                />
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      <FilledIconButton
        onClick={handleClose}
        backgroundProps={{ className: styles.closeButtonContainer }}
        className={styles.closeButton}
      >
        <CloseIcon className={styles.closeIcon} />
      </FilledIconButton>
      <div className={styles.arrowContainer}>
        <Box sx={sxStyles.arrowDown} className={styles.arrowDown} />
      </div>
    </MapMarker>
  );
};

LocationPreview.defaultProps = {
  loading: false,
};

LocationPreview.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.shape({
      streetLine1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    position: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
};

export default LocationPreview;
