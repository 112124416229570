import { InvalidateQueryFilters, InvalidateOptions } from 'react-query';

import queryClient from './query';

const invalidateQueriesContainingKey = <TPageData = any>(
  matchingKey: Array<any>,
  filters?: InvalidateQueryFilters<TPageData>,
  options?: InvalidateOptions,
) => {
  return queryClient.invalidateQueries(
    {
      predicate: query => {
        if (!Array.isArray(query.queryKey)) return false;

        let matchingElemIndex = 0;
        for (let i = 0; i < query.queryKey.length && matchingElemIndex < matchingKey.length; i++) {
          if (matchingKey[matchingElemIndex] !== query.queryKey[i]) {
            if (matchingElemIndex > 0) {
              // Match was started but did not fully match, reset index
              matchingElemIndex = 0;
            }
          } else {
            // Matches
            if (++matchingElemIndex === matchingKey.length) {
              return true; // Full match
            }
          }
        }
        return false;
      },
      ...filters,
    },
    options,
  );
};

export default invalidateQueriesContainingKey;
