import React from 'react';

import Page from '../../../components/Page';

import TenantLocationsMap from './TenantLocationsMap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantMapProps {}

const TenantMap: React.FC<ITenantMapProps> = () => {
  return (
    <Page title='Tenant Map'>
      <TenantLocationsMap />
    </Page>
  );
};

export default TenantMap;
