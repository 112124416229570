import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent } from '@mui/material';

import IdsDialog from '../IdsDialog';
import LoadingScreen from '../LoadingScreen';

const style = {
  width: '100%',
  height: 'calc(100vh - 151px)',
  overflow: 'hidden',
  border: 0,
};

function IdsIFrameDialog({ src, iframeProps, loading, ...rest }) {
  return (
    <IdsDialog fullWidth={true} maxWidth='xl' {...rest}>
      {!loading ? (
        <DialogContent>
          <iframe {...iframeProps} title='IDS External Tool' src={src} style={style} />
        </DialogContent>
      ) : (
        <LoadingScreen fullscreen={true} />
      )}
    </IdsDialog>
  );
}

IdsIFrameDialog.propTypes = {
  src: PropTypes.string,
  iframeProps: PropTypes.object,
  loading: PropTypes.bool,
};

export default IdsIFrameDialog;
