import { gql } from 'graphql-request';
import React, { useCallback, useState } from 'react';

import {
  AssignmentQuestionTypeToIcons,
  ImageAnswerTypeToMediaType,
  AssignmentQuestionTypes,
} from '../../../../../../../../../constants/assignments';
import IdsImage, { IIdsImageProps } from '../../../../../../../../../components/IdsImage';

import ViewerWrapper from './ViewerWrapper';

export interface IImageAnswerFragType {
  questionType: AssignmentQuestionTypes;
  questionId: string;
  answerPhotoType: string | null;
  answerPhoto: {
    id: string;
    locationId?: string;
    reps: { url: string; name?: string }[];
  } | null;
  uuid: string | null;
}

export const ImageAnswerFrag = gql`
  fragment ImageAnswerFrag on AssignmentAnswer {
    questionType
    answerPhoto {
      id
      locationId
      reps {
        url
        name
      }
    }
    answerPhotoType
    uuid
  }
`;

export interface IImageAnswerProps extends IIdsImageProps {
  answer: IImageAnswerFragType;
}

const ImageAnswer: React.FC<IImageAnswerProps> = ({ answer, onClick, ...rest }) => {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const { answerPhoto, answerPhotoType, questionType } = answer;
  const url = answerPhoto?.reps.find(r => r.name === 'small')?.url || '';

  // Question type should be used to determine the image type
  // if the answer is not uploaded
  // Answer photo type should be used if the answer
  // is uploaded, since for DSLR questions, it can be an HD photo or 360
  const TypeIcon = answerPhotoType
    ? ImageAnswerTypeToMediaType[answerPhotoType]?.icon
    : AssignmentQuestionTypeToIcons[questionType]?.icon;

  const handleImageClick: IIdsImageProps['onClick'] = useCallback(
    event => {
      if (answerPhoto?.id) {
        setIsImageOpen(true);
      }

      if (onClick) {
        onClick(event);
      }
    },
    [onClick, answerPhoto?.id],
  );

  const onClose = useCallback(() => {
    setIsImageOpen(false);
  }, []);

  return (
    <>
      <IdsImage
        src={url}
        placeholderIcon={TypeIcon && <TypeIcon />}
        renderBottomLeftIcon={TypeIcon && (props => <TypeIcon {...props} />)}
        onClick={handleImageClick}
        {...rest}
      />
      {isImageOpen && (
        <ViewerWrapper
          questionId={answer.questionId}
          answerPhoto={answerPhoto!}
          onClose={onClose}
          uuid={answer.uuid!}
        />
      )}
    </>
  );
};

export default ImageAnswer;
