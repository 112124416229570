import React, { useCallback } from 'react';
import { ButtonBase } from '@mui/material';

import { IInitialValues } from '../../../../../../../../../../../../hooks/uploaders/useSingleLocationImageUploader';
import ImageAnswer, {
  IImageAnswerFragType,
  IImageAnswerProps,
} from '../../../../../Answer/ImageAnswer';
import {
  IMediaMetadata,
  MediaMetadataType,
} from '../../../../../../../../../../../../constants/media';
import { useGetExistingImageAnswer } from '../../../../../../../../../../../../services/TaskResponsesService';

export interface IExistingImageAnswerProps extends Omit<IImageAnswerProps, 'answer'> {
  answer: IImageAnswerFragType;
  openUploader: (initialValues: IInitialValues, uuid: string) => void;
}

const ExistingImageAnswer: React.FC<IExistingImageAnswerProps> = ({
  answer,
  openUploader,
  ...rest
}) => {
  const { data } = useGetExistingImageAnswer(answer.answerPhoto?.id);

  const handleClick = useCallback(() => {
    if (!data) {
      // Answer image hasn't been uploaded, replace with no initial values set
      openUploader({}, answer.uuid!);
      return;
    }

    // Answer image has been uploaded, initialize replacement image with previous image's data
    const { metadata, position } = data.image;
    const { latitude, longitude, altitude, heading } = position;

    const getMetadataValue = (type: MediaMetadataType) =>
      metadata.find((d: Omit<IMediaMetadata, 'value'>) => d.type === type)?.id;

    const getMetadataValues = (type: MediaMetadataType) =>
      metadata
        .filter((d: Omit<IMediaMetadata, 'value'>) => d.type === type)
        .map((d: Omit<IMediaMetadata, 'value'>) => d.id);

    openUploader(
      {
        latitude,
        longitude,
        altitude: altitude !== null ? altitude : undefined,
        heading: heading !== null ? heading : undefined,
        photo_category_id: getMetadataValue(MediaMetadataType.PhotoCategory),
        photo_type_id: getMetadataValue(MediaMetadataType.PhotoType),
        photo_level_id: getMetadataValue(MediaMetadataType.Level),
        photo_area_id: getMetadataValue(MediaMetadataType.Area),
        photo_tags: getMetadataValues(MediaMetadataType.PhotoTag),
      },
      answer.uuid!,
    );
  }, [openUploader, data, answer]);

  return (
    <ButtonBase onClick={handleClick}>
      <ImageAnswer answer={answer} {...rest} />
    </ButtonBase>
  );
};

export default ExistingImageAnswer;
