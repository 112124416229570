import React, { useCallback, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Divider,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material';

import { clsx } from 'clsx';

import MapOverlay from '../../MapOverlay';
import { ExpandIcon, MeasureIcon } from '../../../../theme/icons';
import useMapContextMenu from '../../../../hooks/useMapContextMenu';
import useMapAreaTool from '../../../../hooks/useMapAreaTool';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import { useObservableItemState } from '../../../../hooks/useObservableStates';
import { TOOLS_ID } from '../../LocationMapMenu';

import styles from './MeasurementTool.module.css';

export const MEASUREMENT_TOOL_ID = 'measurement-tool';

const formatMeasurement = number => {
  const truncatedStr = number.toFixed(2);
  const truncated = parseFloat(truncatedStr);
  return truncated.toLocaleString();
};

const MeasurementTool = ({ className, ...rest }) => {
  const { enabled, distance, area, reset } = useMapAreaTool(MEASUREMENT_TOOL_ID);
  const { setMenuItem } = useMapContextMenu();
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useImmersiveViewer();
  const [, setToolState] = useObservableItemState({
    id: TOOLS_ID,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });

  const startMeasurement = useCallback(() => {
    setToolState({ active: MEASUREMENT_TOOL_ID });
  }, [setToolState]);

  useEffect(() => {
    setMenuItem(MEASUREMENT_TOOL_ID, {
      icon: <MeasureIcon />,
      label: 'Measure',
      onClick: startMeasurement,
    });

    return () => {
      setMenuItem(MEASUREMENT_TOOL_ID, null);
    };
  }, [setMenuItem, startMeasurement]);

  if (!enabled) {
    return null;
  }

  return (
    <MapOverlay className={clsx(styles.container, className)} {...rest}>
      <Paper elevation={3} className={styles.paper}>
        <Accordion
          defaultExpanded={true}
          disableGutters={true}
          className='accordion-noBorder accordion-noBackground'
        >
          <AccordionSummary
            expandIcon={
              <SvgIcon fontSize='medium'>
                <ExpandIcon />
              </SvgIcon>
            }
            className={clsx('accordion-dense', styles.accordionSummary)}
          >
            <div className={styles.title}>
              <Typography variant='subtitle2'>Measurement Data</Typography>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={styles.accordionDetails}>
            <Typography variant='caption' noWrap className={styles.dataText}>
              Total area: {formatMeasurement(area)} ft<sup>2</sup>
            </Typography>
            <br />
            <Typography variant='caption' noWrap className={styles.dataText}>
              Total distance: {formatMeasurement(distance)} ft
            </Typography>
            <br />
            <div className={styles.resetBtnContainer}>
              <Chip
                size='small'
                label='Reset'
                onClick={reset}
                className={styles.resetBtn}
                color='info'
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </MapOverlay>
  );
};

export default MeasurementTool;
