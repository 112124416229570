import React from 'react';

import TenantPage from '../TenantPage';

import TenantLocationsList from './TenantLocationsList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantLocationsProps {}

const TenantLocations: React.FC<ITenantLocationsProps> = () => {
  return (
    <TenantPage title='Locations'>
      <TenantLocationsList />
    </TenantPage>
  );
};

export default TenantLocations;
