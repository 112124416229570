import React from 'react';
import { ListItemIcon, MenuItem, MenuItemProps, SvgIcon, SvgIconProps } from '@mui/material';

export interface IIdsListItemMenuItemProps extends MenuItemProps {
  icon?: React.ReactNode;
  svgIconProps?: SvgIconProps;
}

const IdsListItemMenuItem: React.FC<IIdsListItemMenuItemProps> = ({
  icon,
  svgIconProps,
  children,
  ...rest
}) => {
  return (
    <MenuItem {...rest}>
      {icon && (
        <ListItemIcon>
          <SvgIcon fontSize='small' {...svgIconProps}>
            {icon}
          </SvgIcon>
        </ListItemIcon>
      )}
      {children}
    </MenuItem>
  );
};

export default IdsListItemMenuItem;
