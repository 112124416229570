import { useCallback, useEffect, useMemo, useState } from 'react';

import { IMetadataType, MediaMetadataType } from '../../../../../../constants/media';

import { useImageMarkerCreationTool } from '../../../../../../components/mapping/tools/ImageMarkerCreationTool';
import useMarkerTypeLayerBuilders from '../../../../../../components/mapping/tools/hooks/useMarkerTypeLayerBuilders';

import { IMarkerPosition } from '../../../../../../hooks/useMapMarkerPlacementTool';
import useImmersiveViewer from '../../../../../../hooks/useImmersiveViewer';

import { iconClusterPlacementProps, POINTS_PLACEMENT_LAYER_ID } from '../helpers';
import { IPlacedPoint, ISettings } from '../types';

const useCreatingFeature = (settings: ISettings, metadataTypes: IMetadataType[]) => {
  const { setLayer } = useImmersiveViewer();

  const [pointsToCreate, setPointsToCreate] = useState<IPlacedPoint[]>([]);
  const isPlacingPoints = !!pointsToCreate.length;

  const { startImageMarkerCreation, updateToolState, finishMarkerPlacement } =
    useImageMarkerCreationTool({ metadataTypes });
  const imageTypeLayerBuilders = useMarkerTypeLayerBuilders(metadataTypes);

  /**
   * Ready to place points when all setting fields have values.
   *
   * s === 0 is for 360 images heading
   */
  const canPlacePoints = useMemo(() => {
    return Object.entries(settings).every(([setting, value]) => {
      if ((setting as keyof ISettings) === MediaMetadataType.Area) return true; // Area is optional
      return !!value || value === 0; // All other settings are required
    });
  }, [settings]);

  const handleMarkerMoved = useCallback(
    ({ latitude, longitude }: IMarkerPosition) => {
      const newPoint = {
        position: {
          latitude,
          longitude,
        },
      };

      setPointsToCreate(prev => [...prev, newPoint]);

      finishMarkerPlacement();
    },
    [finishMarkerPlacement],
  );

  useEffect(() => {
    updateToolState(null, null, false, handleMarkerMoved, null);
  }, [updateToolState, handleMarkerMoved]);

  const imageType = settings.imageType;
  const levelMetadata = settings[MediaMetadataType.Level];

  const startMarkerPlacement = useCallback(() => {
    startImageMarkerCreation(imageType!, handleMarkerMoved, null, levelMetadata!);
  }, [startImageMarkerCreation, imageType, levelMetadata, handleMarkerMoved]);

  useEffect(() => {
    finishMarkerPlacement();
  }, [pointsToCreate, finishMarkerPlacement]);

  useEffect(() => {
    if (!canPlacePoints) {
      return;
    }

    startMarkerPlacement();
  }, [pointsToCreate, startMarkerPlacement, canPlacePoints]);

  const cancelCreating = useCallback(() => {
    setPointsToCreate([]);

    if (imageType) {
      const layerBuilder = imageTypeLayerBuilders[imageType];

      setLayer(POINTS_PLACEMENT_LAYER_ID, layerBuilder([], iconClusterPlacementProps, null));
    }

    finishMarkerPlacement();
  }, [setLayer, imageTypeLayerBuilders, imageType, finishMarkerPlacement]);

  useEffect(() => {
    if (!canPlacePoints) {
      return;
    }

    const data = pointsToCreate.map((p, index) => {
      return {
        node: {
          id: index,
          type: imageType,
          metadata: [
            {
              id: levelMetadata,
              type: MediaMetadataType.Level,
            },
          ],
          ...p,
        },
      };
    });

    const layerBuilder = imageTypeLayerBuilders[imageType!];

    setLayer(POINTS_PLACEMENT_LAYER_ID, layerBuilder(data, iconClusterPlacementProps, null));
  }, [pointsToCreate, setLayer, imageType, levelMetadata, imageTypeLayerBuilders, canPlacePoints]);

  return {
    canPlacePoints,
    isPlacingPoints,
    cancelCreating,
    pointsToCreate,
  };
};

export default useCreatingFeature;
