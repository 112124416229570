import React from 'react';
import { useRecoilValue } from 'recoil';

import { PHYGII_LOGO_VARIANTS_URL } from '../../constants/phygiiLogo';
import { IDS_LOGO_VARIANTS_URL } from '../../constants/idsLogo';
import isPhygii from '../../utils/phygii/isPhygii';

import { activeTenantState } from '../../atoms/tenants';
import IdsImage, { IIdsImageProps } from '../IdsImage';

import styles from './TenantLogo.module.css';

export interface ITenantLogoProps extends IIdsImageProps {
  fallbackLogo: keyof typeof IDS_LOGO_VARIANTS_URL | keyof typeof PHYGII_LOGO_VARIANTS_URL;
}

const TenantLogo: React.FC<ITenantLogoProps> = ({ height, width, fallbackLogo, ...props }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const phygii = isPhygii();

  const altFallback = phygii ? 'Phygii logo' : 'IDS logo';
  const srcFallback = phygii
    ? PHYGII_LOGO_VARIANTS_URL[fallbackLogo]
    : IDS_LOGO_VARIANTS_URL[fallbackLogo];

  return (
    <IdsImage
      alt={activeTenant?.logo?.url ? `${activeTenant.name} logo` : altFallback}
      src={activeTenant?.logo?.url || srcFallback}
      height={height}
      width={width}
      className={styles.logo}
      {...props}
    />
  );
};

export default TenantLogo;
