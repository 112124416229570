import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';

import { activeTenantState } from '../../atoms/tenants';
import {
  ITenantUserDetailsBySubdomain,
  useGetTenantDetailsBySubdomain,
} from '../../services/TenantService';

import { getTenantsRoute } from '../../utils/routes';
import isPhygiiTenant, { PHYGII_TENANT_SUBDOMAIN } from '../../utils/phygii/isPhygiiTenant';
import isPhygii from '../../utils/phygii/isPhygii';
import useNavigateToPhygii from '../../hooks/navigation/useNavigateToPhygii';
import useNavigateToExplorer from '../../hooks/navigation/useNavigateToExplorer';

import EntityRoute from './EntityRoute';

export const IDS_TENANT_SUBDOMAIN = 'immersiondata';

const entitySelector = (data?: ITenantUserDetailsBySubdomain) => data?.tenantBySubdomain;

export const TenantRoute: React.FC = () => {
  const [activeTenant, setActiveTenant] = useRecoilState(activeTenantState);

  const navigateToPhygii = useNavigateToPhygii();
  const navigateToExplorer = useNavigateToExplorer();

  useEffect(() => {
    if (!activeTenant) {
      return;
    }

    const phygii = isPhygii();
    const phygiiTenant = isPhygiiTenant(activeTenant);

    if (!phygii && phygiiTenant) {
      navigateToPhygii();

      return;
    }

    if (phygii && !phygiiTenant) {
      navigateToExplorer();
    }
  }, [activeTenant, navigateToPhygii, navigateToExplorer]);

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useTenant = (subdomain: string | undefined) => useGetTenantDetailsBySubdomain(subdomain);

  // Active tenant subdomain OR one of predefined domains
  const defaultParamValue =
    activeTenant?.subdomain ?? (isPhygii() ? PHYGII_TENANT_SUBDOMAIN : IDS_TENANT_SUBDOMAIN);

  return (
    <EntityRoute
      idParam='subdomain'
      defaultParamValue={defaultParamValue}
      currentEntityFieldToCheck='subdomain'
      currentEntity={activeTenant}
      setEntity={setActiveTenant}
      useEntity={useTenant}
      entitySelector={entitySelector}
      errorRedirectRoute={getTenantsRoute({})}
    />
  );
};

export default TenantRoute;
