import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useSortable, UseSortableArguments } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { DragIndicatorIcon } from '../../../../../../theme/icons';

export interface IDraggableItemWrapperProps {
  children: React.ReactNode;
  props: {
    id: UseSortableArguments['id'];
  };
}

const DraggableItemWrapper: React.FC<IDraggableItemWrapperProps> = ({ children, props }) => {
  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: props.id,
    data: props,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Box display='flex' flexDirection='row'>
        <Box>
          <IconButton size='small' {...attributes} {...listeners}>
            <DragIndicatorIcon />
          </IconButton>
        </Box>
        <Box width='100%'>{children}</Box>
      </Box>
    </Box>
  );
};

export default DraggableItemWrapper;
