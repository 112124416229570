import React, { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';

import CreateRouteDialog from './CreateRouteDialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IActionsProps {}

const Actions: React.FC<IActionsProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen(prev => !prev), []);

  return (
    <Box display='flex' alignItems='center' height='100%'>
      <Button color='secondary' variant='outlined' onClick={toggleIsOpen}>
        New Route
      </Button>

      <CreateRouteDialog open={isOpen} onClose={toggleIsOpen} />
    </Box>
  );
};

export default Actions;
