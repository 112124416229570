import { Container } from '@mui/material';

import { useAssignmentDetailsData } from '../../../services/AssignmentsService';

import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';

import { AssignmentResponseDetails } from '../AssignmentResponseDetails';
import ErrorPage from '../../Error';
import LoadingScreen from '../../../components/LoadingScreen';

import { ICrumb } from '../../../components/IdsBreadcrumbs';
import { useAssignmentResponseDetailsData } from '../../../services/AssignmentResponsesService';

export interface IAssignmentResponseDetailsTemplatePageProps {
  assignmentId: string;
  assignmentResponseId: string;

  pageTitle?: string;
  breadcrumbs: ICrumb[];
}

export const AssignmentResponseDetailsTemplatePage: React.FC<
  IAssignmentResponseDetailsTemplatePageProps
> = ({
  assignmentId,
  assignmentResponseId,
  pageTitle = 'Assignment Response Details',
  breadcrumbs,
}) => {
  const {
    data: assignmentData,
    isLoading: isLoadingAssignment,
    error: errorAssignment,
  } = useAssignmentDetailsData(assignmentId);

  const {
    data: assignmentResponseData,
    isLoading: isLoadingAssignmentResponse,
    error: errorAssignmentResponse,
  } = useAssignmentResponseDetailsData(assignmentResponseId);

  const isLoadingData = isLoadingAssignment || isLoadingAssignmentResponse;
  const error = errorAssignment || errorAssignmentResponse;

  if (error) {
    return <ErrorPage />;
  }

  if (isLoadingData) {
    return <LoadingScreen />;
  }

  if (!assignmentData || !assignmentResponseData) {
    return null;
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={false}>
        <NestedOrgViewHeader
          breadcrumbs={breadcrumbs}
          currentCrumb={assignmentResponseData.assignmentResponse.id}
        />
        <AssignmentResponseDetails
          assignment={assignmentData.assignment}
          assignmentResponse={assignmentResponseData.assignmentResponse}
        />
      </Container>
    </Page>
  );
};

export default AssignmentResponseDetailsTemplatePage;
