import { useCallback } from 'react';

import useObservableStates from './useObservableStates';

const CONTEXT_ID = 'contextStack';
const DEFAULT_CONTEXT = 'default-context';

interface IContextStackState {
  contextStack: string[];
}

export type ContextCallback = (context: string) => void;

const useContextStack = () => {
  const { getItemState, setItemState } = useObservableStates();

  const getCurrentContext = useCallback(() => {
    const contextStack = getItemState<IContextStackState>(CONTEXT_ID)?.contextStack;
    return contextStack?.length ? contextStack[contextStack.length - 1] : DEFAULT_CONTEXT;
  }, [getItemState]);

  // Destroys this context and any child contexts and sets
  // the current context to the immediate parent
  const destroyContext = useCallback(
    (context: string, onContextDestroy?: ContextCallback) => {
      const contextState = getItemState<IContextStackState>(CONTEXT_ID);

      // No context or context state not set
      if (!context || !contextState?.contextStack?.length) {
        return;
      }

      const contextStack = contextState.contextStack;
      const contextIndex = contextStack.findIndex(c => c === context);

      // Destroy all nested contexts
      for (let i = contextStack.length - 1; i >= contextIndex; i--) {
        const contextToDestroy = contextStack.pop();

        if (contextToDestroy && onContextDestroy) {
          onContextDestroy(contextToDestroy);
        }
      }

      // Update the layer context
      setItemState(CONTEXT_ID, { contextStack });
    },
    [getItemState, setItemState],
  );

  /** Sets the context the current context.
   * - Context is tracked in a stack.
   * - If a new context is set without first destroying the old context,
   *   it will be created as nested context.
   * - If a parent context is set, all nested contexts will be destroyed.
   */
  const setCurrentContext = useCallback(
    (context: string) => {
      const contextState = getItemState<IContextStackState>(CONTEXT_ID);
      if (!contextState) {
        setItemState(CONTEXT_ID, { contextStack: [context] });
        return;
      }

      const contextStack = contextState.contextStack;
      const contextIndex = contextStack.findIndex(c => c === context);

      // New context
      if (contextIndex === -1) {
        contextStack.push(context);
        setItemState<IContextStackState>(CONTEXT_ID, { contextStack });
        return;
      }

      // Existing context

      // Context is not the current context
      if (contextIndex < contextStack.length - 1) {
        // Destroy nested context
        destroyContext(contextStack[contextIndex + 1]);
      }
    },
    [getItemState, setItemState, destroyContext],
  );

  return {
    getCurrentContext,
    setCurrentContext,
    destroyContext,
  };
};

export default useContextStack;
