import React from 'react';
import { IconButton } from '@mui/material';

import IdsListItemMenuItem from '../../../../ids-lists/IdsListItemMenuItem';
import { EditOutlineIcon } from '../../../../../theme/icons';
import buttonStyles from '../../IdsMediaViewer.module.css';

export interface IEditButtonProps {
  onClick: () => void;
  isEditMode: boolean;
  inMenu?: boolean;
}

const EditButton: React.FC<IEditButtonProps> = ({ onClick, isEditMode, inMenu }) => {
  const icon = isEditMode ? <EditOutlineIcon color='warning' /> : <EditOutlineIcon />;

  return inMenu ? (
    <IdsListItemMenuItem icon={icon} onClick={onClick}>
      Edit
    </IdsListItemMenuItem>
  ) : (
    <IconButton className={buttonStyles.menuActionButton} onClick={onClick}>
      {icon}
    </IconButton>
  );
};

export default EditButton;
