import React from 'react';
import PropTypes from 'prop-types';

import {
  MEDIA_METADATA_TYPES,
  MEDIA_TYPES,
  MediaMetadataType,
  MediaType,
} from '../../../constants/media';
import { selectedAreas } from '../../../atoms/areaFilter';

import useActiveFilterTargets from '../../../hooks/filtering/useActiveFilterTargets';
import { useRestoreMediaMetadataFilterFromUrl } from '../../../hooks/filtering/useMediaMetadataFilter';

import MediaMetadataFilter from '../MediaMetadataFilter';
import { getCommonMetadataFilterTargets } from '../helpers';

const selectFilterData = item =>
  item.node.metadata.find(d => d.type === MEDIA_METADATA_TYPES[MediaMetadataType.Area].type);

const filterTargets = [
  ...getCommonMetadataFilterTargets(MediaMetadataType.Area),
  { type: MEDIA_TYPES[MediaType.Asset].type, selectFilterData },
  { type: MEDIA_TYPES[MediaType.PointOfInterest].type, selectFilterData },
];

const AreaFilter = props => {
  const activeFilterTargets = useActiveFilterTargets(filterTargets);

  return (
    <MediaMetadataFilter
      label={MEDIA_METADATA_TYPES[MediaMetadataType.Area].label}
      metadataType={MEDIA_METADATA_TYPES[MediaMetadataType.Area].type}
      filterTargets={activeFilterTargets}
      filterStateAtom={selectedAreas}
      noMetadataToFilterByMessage='There are no areas to filter by.'
      {...props}
    />
  );
};

export const useRestoreAreaFilterFromUrl = allValidMetadataItems => {
  const activeFilterTargets = useActiveFilterTargets(filterTargets);

  useRestoreMediaMetadataFilterFromUrl({
    metadataType: MEDIA_METADATA_TYPES[MediaMetadataType.Area].type,
    filterTargets: activeFilterTargets,
    filterStateAtom: selectedAreas,
    allValidMetadataItems,
  });
};

AreaFilter.propTypes = {
  allSortedMetadataItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default AreaFilter;
