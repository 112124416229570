import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { activeOrganizationState } from '../../atoms/organizations';
import { activeProjectState, activeProjectRouteState } from '../../atoms/projects';
import { activeTenantState } from '../../atoms/tenants';

import { useGetRouteRoute } from '../../services/RouteService';

import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import { getOrgProjectRoute } from '../../utils/routes';

import EntityRoute from './EntityRoute';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProjectRouteRouteProps {}

/**
 * It is not a typo.
 */
const ProjectRouteRoute: React.FC<IProjectRouteRouteProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);

  const [route, setRoute] = useRecoilState(activeProjectRouteState);

  const useEntity = (id: string | undefined, options: UseGraphQueryOptions) =>
    useGetRouteRoute(id ?? null, activeProject!.id, activeOrg!.id, options);

  return (
    <EntityRoute
      idParam='routeId'
      currentEntity={route}
      setEntity={setRoute}
      useEntity={useEntity}
      entitySelector={data => data?.route}
      errorRedirectRoute={`${getOrgProjectRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        projectId: activeProject!.id,
      })}?tab=routes`}
    />
  );
};

export default ProjectRouteRoute;
