import { useMutation } from 'react-query';

import getGraphQLClient from '../graphQL/config';

export const buildGraphMutationFn = mutation => async graphVariables => {
  return await getGraphQLClient().request(mutation, graphVariables);
};

export const useGraphMutation = (mutation, mutationOptions) => {
  return useMutation(buildGraphMutationFn(mutation), mutationOptions);
};

export default useGraphMutation;
