import React from 'react';

import ImageMarkerForm, { IImageMarkerFormProps } from '../ImageMarkerForm';
import { MediaType } from '../../../../../../constants/media';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProjectPhotoMarkerFormProps extends Omit<IImageMarkerFormProps, 'imageType'> {}

const ProjectPhotoMarkerForm: React.FC<IProjectPhotoMarkerFormProps> = props => {
  return <ImageMarkerForm imageType={MediaType.ProjectPhoto} {...props} />;
};

export default ProjectPhotoMarkerForm;
