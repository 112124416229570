import React, { useMemo } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlTypeMap,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { OverridableComponent } from '@mui/material/OverridableComponent';

import styles from './styles.module.css';

export interface ISelectOption {
  value: string;
  label: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type FormControlPropsType = OverridableComponent<FormControlTypeMap<{}, 'div'>>;

// TODO: Rewrite component if it's needed to use generic type of option
export interface IIdsRadioListProps extends Omit<FormControlPropsType, 'value' | 'onChange'> {
  options: ISelectOption[];
  renderOption?: (option: ISelectOption | any) => React.ReactElement;
  helperText?: React.ReactNode;
  value: string | null;
  onChange: (value: string | null) => any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium';
  margin?: 'dense' | 'normal' | 'none' | undefined;
  sx?: any;
}

const IdsRadioList: React.FC<IIdsRadioListProps> = ({
  label,
  options,
  required,
  disabled,
  error,
  helperText,
  size = 'small',
  margin,
  sx,

  value,
  onChange,

  ...rest
}) => {
  const labelId = useMemo(() => `${label}-radio-list-field-label`, [label]);

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={Boolean(error)}
      size={size}
      margin={margin}
      fullWidth
      sx={sx}
      {...rest}
    >
      <FormLabel id={labelId} className={styles['formLabel']}>
        {label}
      </FormLabel>
      <RadioGroup value={value} onChange={e => onChange(e.target.value)}>
        {options.map(option => (
          <FormControlLabel
            control={<Radio checked={option.value === value} />}
            label={option.label}
            value={option.value}
            labelPlacement='end'
          />
        ))}
      </RadioGroup>
      <FormHelperText className={styles['helperText']}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default IdsRadioList;
