import * as Yup from 'yup';

import { ROUTE_DESCRIPTION_LIMIT } from '../../../../Actions/CreateRouteDialog/CreateRouteForm/validationSchema';

const editRouteValidation = {
  name: Yup.string().nullable().required('Name is required'),
  description: Yup.string().max(ROUTE_DESCRIPTION_LIMIT),
  notes: Yup.string(),
};

export const editRouteValidationSchema = Yup.object().shape(editRouteValidation);
