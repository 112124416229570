import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../../../theme/styles';
import { activeTenantState } from '../../../../atoms/tenants';
import { tenantClusterState } from '../../../../atoms/immersiveViewer';
import { useGetTenantLocations } from '../../../../services/TenantService';
import { getOrgMapLocationRoute, getTenantMapRoute } from '../../../../utils/routes';
import Error from '../../../Error';
import CoreMap from '../../../../components/mapping/CoreMap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantLocationsMapProps {}

const containerProps = {
  height: FULL_HEIGHT_WITHOUT_TOP_BAR,
};

const TenantLocationsMap: React.FC<ITenantLocationsMapProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [cluster, setClusterState] = useRecoilState(tenantClusterState);

  const { data: locations, error, isLoading } = useGetTenantLocations(activeTenant!.id);

  const getMapRouteFn = useCallback(
    () =>
      getTenantMapRoute({
        subdomain: activeTenant?.subdomain,
      }),
    [activeTenant],
  );
  const getMapLocationRouteFn = useCallback(
    ({ locationId, organizationId }: { locationId: string; organizationId: string }) =>
      getOrgMapLocationRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organizationId,
        locationId,
      }),
    [activeTenant],
  );

  if (error) {
    return <Error />;
  }

  return (
    <CoreMap
      locations={locations}
      loading={isLoading}
      cluster={cluster}
      setClusterState={setClusterState}
      containerProps={containerProps}
      getMapRoute={getMapRouteFn}
      getMapLocationRoute={getMapLocationRouteFn}
      locationSearchEnabled={false}
    />
  );
};

export default TenantLocationsMap;
