import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { FlyToInterpolator, TRANSITION_EVENTS } from 'deck.gl';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import useOrgGraphQuery from '../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../services/LocationService';
import queryClient from '../../../utils/query';

import QueryLoader from '../../QueryLoader';
import { FilterProvider } from '../../../context/FilterContext';
import useFilterContext from '../../../hooks/useFilterContext';
import { ImmersiveViewerInContext } from '../ImmersiveViewer';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';
import { ImmersiveViewerProvider } from '../../../context/ImmersiveViewerContext';
import { buildLocationMarkerLayer, LocationMarkerFrag } from '../layers/locations';
import LocationPreview from '../LocationPreview';
import LocationMap from '../LocationMap';
import useMapFitData from '../../../hooks/useMapFitData';
import useDeckEventCallback from '../../../hooks/useDeckEventCallback';

import LocationSearch from '../LocationSearch';
import MapControls from '../MapControls';

import usePrevious from '../../../hooks/usePrevious';
import { EntityType } from '../../../constants/entities';

import useLocationDetails from './useLocationDetails';
import styles from './CoreMap.module.css';

const LOCATION_PREVIEW_SOURCES = {
  CLICK: 'click',
  HOVER: 'hover',
};

const viewStateInterpolator = new FlyToInterpolator({ speed: 2 });

const defaultViewState = {
  zoom: 1,
  pitch: 0,
  maxPitch: 0,
  bearing: 0,
};

export const CoreMapFrag = gql`
  fragment CoreMapFrag on Location {
    id
    name
    organizationId
    address {
      streetLine1
      city
      state
      postalCode
    }
    ...LocationMarkerFrag
  }
  ${LocationMarkerFrag}
`;

// This isn't currently needed, will need when loading image count chips
const LocationPreviewQuery = gql`
  query LocationPreview($orgId: ID, $id: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
    }
  }
`;

const CoreMap = ({
  locations,
  containerProps,
  deckProps,
  loading,
  cluster,
  setClusterState,
  getMapLocationRoute,
  getMapRoute,
  locationSearchEnabled = true,
  ...rest
}) => {
  const locationKeys = useLocationKeys();

  const { locationId } = useParams();
  const [closing, setClosing] = useState(false);
  const navigate = useNavigate();
  const { setTypeData, useTypeFilteredData } = useFilterContext();
  const filteredLocations = useTypeFilteredData(EntityType.Location);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const { useViewState, setViewState, setLayer } = useImmersiveViewer();
  const viewState = useViewState();
  const { fitMapToData } = useMapFitData();
  const [fittedToLocations, setFittedToLocations] = useState(false);

  // Is the preview element being hovered
  const [hoveringPreview, setHoveringPreview] = useState(false);
  const [previewLocation, setPreviewLocation] = useState();

  // Opened from a hover or click?
  const [previewLocSource, setPreviewLocSource] = useState();
  const [activeLocation, setActiveLocation] = useState();

  // View state stransitioning?
  const [transitioning, setTransitioning] = useState();
  const prevLocations = usePrevious(locations);
  const [searchExpanded, setSearchExpanded] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (prevLocations !== locations) {
      const transformedLocations = locations.map(l => {
        const { city, state, postalCode } = l.node.address;
        return {
          ...l,
          addressLine1: l.node.address.streetLine1,
          addressLine2: `${city ? `${city}, ` : ''}${state || ''}${
            postalCode ? ` ${postalCode}` : ''
          }`,
        };
      });
      setTypeData(transformedLocations, EntityType.Location);

      if (!loading && !locationsLoaded) {
        // Indicate that all locations have been loaded into the filter context
        setLocationsLoaded(true);
      }
    }
  }, [prevLocations, locations, setTypeData, loading, locationsLoaded, setLocationsLoaded]);

  const useLocationPreview = () =>
    useOrgGraphQuery([...locationKeys.map(previewLocation.id), 'preview'], LocationPreviewQuery, {
      id: previewLocation.id,
    });

  const {
    data: locDetailData,
    isLoading: locDetailLoading,
    error: locDetailErr,
  } = useLocationDetails(activeLocation?.id);

  const fullLocationDetail = useMemo(
    () => ({
      ...activeLocation,
      ...locDetailData?.location,
    }),
    [activeLocation, locDetailData],
  );

  const onLocationMarkerHover = useCallback(
    info => {
      const { object } = info;

      if (activeLocation) {
        return;
      }

      if (!object?.node) {
        // Stopped hovering the location marker
        // and not hovering on the location preview
        if (!hoveringPreview && previewLocSource === LOCATION_PREVIEW_SOURCES.HOVER) {
          setPreviewLocation(null);
          setPreviewLocSource(null);
        }
        return;
      }

      const { node: location } = object;

      // Open the hover location preview
      // if not already open and if there isn't
      // another preview that was opened by a click
      if (
        !previewLocation ||
        (previewLocation &&
          location.id !== previewLocation.id &&
          previewLocSource !== LOCATION_PREVIEW_SOURCES.CLICK)
      ) {
        setPreviewLocation(location);
        setPreviewLocSource(LOCATION_PREVIEW_SOURCES.HOVER);
      }
    },
    [
      hoveringPreview,
      previewLocation,
      setPreviewLocation,
      setPreviewLocSource,
      previewLocSource,
      activeLocation,
    ],
  );

  const fitToLocations = useCallback(
    (useInterpolator = true) => {
      const fittedViewState = fitMapToData(
        locations,
        l => l.node.position.latitude,
        l => l.node.position.longitude,
        3.5, // Don't want to zoom in super far
        viewState,
      );

      if (fittedViewState) {
        const smoothFittedViewState = {
          ...fittedViewState,
          transitionDuration: 300,
          ...(useInterpolator && {
            transitionInterpolator: viewStateInterpolator,
          }),
        };
        setViewState(smoothFittedViewState);
        setFittedToLocations(true); // Prevent map from refitting to locations
      }
    },
    [locations, fitMapToData, setFittedToLocations, viewState, setViewState],
  );

  // Fit the map to the locations
  useEffect(() => {
    if (loading || fittedToLocations || activeLocation) return;
    fitToLocations();
  }, [fittedToLocations, fitToLocations, loading, activeLocation]);

  const { eventHandler: handleLocationMarkerHover } = useDeckEventCallback(onLocationMarkerHover);

  const trySelectLocationPreview = useCallback(
    (location, zoomLevel) => {
      // Only center on marker if no active location or
      // if it marks the active location (recenters)
      if (!activeLocation || (activeLocation && location.id === activeLocation.id)) {
        setViewState({
          ...viewState,
          latitude: location.position.latitude,
          longitude: location.position.longitude,
          transitionDuration: 100,
          zoom: zoomLevel || viewState.zoom,
        });
      }

      if (!activeLocation) {
        // Change source to click to prevent disappearing
        // after hover if preview already set
        setPreviewLocSource(LOCATION_PREVIEW_SOURCES.CLICK);

        // Location's preview is not already open and
        // location's details are active
        if (!previewLocation || previewLocation.id !== location.id) {
          setPreviewLocation(location);
        }
      }
    },
    [
      setPreviewLocation,
      viewState,
      setViewState,
      previewLocation,
      activeLocation,
      setPreviewLocSource,
    ],
  );

  const handleLocationMarkerClick = useCallback(
    info => {
      const {
        object: { node: location },
      } = info;
      trySelectLocationPreview(location);
    },
    [trySelectLocationPreview],
  );

  const onLocationPreviewLoad = useCallback(
    location => {
      setPreviewLocation({
        ...previewLocation,
        ...location,
      });
    },
    [previewLocation, setPreviewLocation],
  );

  const closeLocationPreview = useCallback(() => {
    setPreviewLocation(null);
    setHoveringPreview(false);
    setPreviewLocSource(null);
  }, [setPreviewLocation, setHoveringPreview, setPreviewLocSource]);

  // Opens location details for a location
  const selectLocation = useCallback(
    location => {
      setClosing(false);
      setActiveLocation({ ...location });

      const targetZoom = 17;
      const zoomTransition = Math.abs(targetZoom - viewState.zoom);
      const zoomLevelsPerSecond = 5;

      setViewState({
        ...viewState,
        latitude: location.position.latitude,
        longitude: location.position.longitude,

        // can eventually use fitMapToData to the map markers...
        // maybe not, data won't be loaded yet
        zoom: targetZoom,

        // Adjust zoom based on how far it needs to transition
        transitionDuration: (zoomTransition / zoomLevelsPerSecond) * 1000,
        onTransitionStart: () => {
          setTransitioning(true);
        },
        // Allow interruptions to account for location detail load error closing the location
        transitionInterruption: TRANSITION_EVENTS.BREAK,
        onTransitionInterrupt: () => {
          setTransitioning(false);
        },
        onTransitionEnd: () => {
          setTransitioning(false);
        },
        transitionInterpolator: viewStateInterpolator,
      });

      closeLocationPreview();
    },
    [closeLocationPreview, setActiveLocation, viewState, setViewState, setTransitioning],
  );

  const goToLocation = useCallback(() => {
    navigate(
      getMapLocationRoute({
        organizationId: previewLocation.organizationId,
        locationId: previewLocation.id,
      }),
    );
  }, [navigate, previewLocation, getMapLocationRoute]);

  const closeLocationMap = useCallback(() => {
    setClosing(true);
    setActiveLocation(null);

    // IMPORTANT: this fitToLocations call needs to pass false for
    // useInterpolator if the custom flyToInterpolator is used,
    // in rare cases, the map will crash when
    // the location is closed if the user pans the map first.
    // Example: Location 13377 in org 174
    // This seems to only happen for orgs with 1 location.
    fitToLocations(false);

    navigate(getMapRoute());
  }, [setActiveLocation, fitToLocations, navigate, getMapRoute]);

  useEffect(() => {
    if (!activeLocation || locDetailLoading || !locDetailErr) return;

    // Error occurred loading location details, close the location
    closeLocationMap();

    // Invalidate query to allow retrying
    queryClient.invalidateQueries(locationKeys.map(activeLocation.id), {
      refetchActive: false,
    });

    enqueueSnackbar('Failed to load location, please try again', { variant: 'error' });
  }, [
    activeLocation,
    locDetailLoading,
    locDetailErr,
    locationKeys,
    closeLocationMap,
    enqueueSnackbar,
  ]);

  // when filteredLocations is updated
  // if there is an activeLocationId find that location and select it
  useEffect(() => {
    // location id was set, but it was unset and location
    // is still active, close it. This can happen with browser back button
    if (!locationId && activeLocation) {
      closeLocationMap();
    }

    if (filteredLocations.length && locationId && !activeLocation) {
      const location = filteredLocations.find(l => l.node.id === locationId);
      if (location) {
        selectLocation(location.node);
        return;
      }

      if (locationsLoaded) {
        // Deep linking failed, all locations are loaded and it cannot be found
        navigate(getMapRoute());
      }
    }
  }, [
    filteredLocations,
    locationId,
    selectLocation,
    activeLocation,
    navigate,
    locationsLoaded,
    closeLocationMap,
    getMapRoute,
  ]);

  const renderLocationPreview = useCallback(
    loading => (
      <LocationPreview
        location={previewLocation}
        onClick={goToLocation}
        onMouseEnter={() => {
          setHoveringPreview(true);
        }}
        onMouseLeave={() => {
          setHoveringPreview(false);
        }}
        onClose={closeLocationPreview}
        loading={loading}
      />
    ),
    [previewLocation, goToLocation, setHoveringPreview, closeLocationPreview],
  );

  const locationMap = useMemo(
    () => (
      <LocationMap
        location={fullLocationDetail}
        onClose={closeLocationMap}
        transitioning={transitioning}
        loadingLocationDetail={locDetailLoading}
        locationDetailError={locDetailErr}
        cluster={cluster}
        setClusterState={setClusterState}
        closing={closing}
      />
    ),
    [
      transitioning,
      locDetailLoading,
      locDetailErr,
      closeLocationMap,
      cluster,
      setClusterState,
      closing,
      fullLocationDetail,
    ],
  );

  const locationMarkerLayer = useMemo(
    () =>
      buildLocationMarkerLayer(filteredLocations, {
        cluster,
        onClick: handleLocationMarkerClick,
        onHover: handleLocationMarkerHover,

        // only visible when there is not an active location
        visible: !activeLocation,
      }),
    [
      filteredLocations,
      cluster,
      handleLocationMarkerClick,
      handleLocationMarkerHover,
      activeLocation,
    ],
  );

  useEffect(() => {
    setLayer('location-markers', locationMarkerLayer);
  }, [locationMarkerLayer, setLayer]);

  const activeLocationMarkerLayer = useMemo(
    () =>
      buildLocationMarkerLayer(activeLocation ? [{ node: activeLocation }] : [], {
        cluster: false,
        onClick: handleLocationMarkerClick,

        // only visible when there is an active location
        visible: !!activeLocation,
        id: 'active-location-marker',
      }),
    [handleLocationMarkerClick, activeLocation],
  );

  useEffect(() => {
    setLayer('active-location-marker', activeLocationMarkerLayer);
  }, [activeLocationMarkerLayer, setLayer]);

  const locationsSearch = useMemo(() => {
    if (locationSearchEnabled) {
      return (
        <LocationSearch
          data={locations}
          selectLocation={trySelectLocationPreview}
          visible={!activeLocation}
          onExpandedChange={setSearchExpanded}
        />
      );
    }
    return null;
  }, [
    locations,
    trySelectLocationPreview,
    activeLocation,
    setSearchExpanded,
    locationSearchEnabled,
  ]);

  return (
    <>
      <ImmersiveViewerInContext containerProps={containerProps} deckProps={deckProps} {...rest}>
        {previewLocation && (
          <QueryLoader
            useQuery={useLocationPreview}
            selectData={data => data?.location}
            setData={onLocationPreviewLoad}
            renderLoading={() => renderLocationPreview(true)}
            render={() => renderLocationPreview(false)}
          />
        )}
        {activeLocation && locationMap}
        {!activeLocation && (
          <MapControls
            cluster={cluster}
            setClusterState={setClusterState}
            className={clsx(
              styles.mapControls,
              locationSearchEnabled && searchExpanded
                ? styles['mapControls-searchExpanded']
                : styles['mapControls-searchCollapsed'],
            )}
            hideFlagFilter={true}
          />
        )}
      </ImmersiveViewerInContext>
      {locationSearchEnabled && locationsSearch}
    </>
  );
};

CoreMap.defaultProps = {
  loading: false,
};

CoreMap.propTypes = {
  locations: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

const types = [EntityType.Location];

const CoreLocationsMapWithContext = props => (
  <FilterProvider types={types}>
    <ImmersiveViewerProvider defaultViewState={defaultViewState}>
      <CoreMap {...props} />
    </ImmersiveViewerProvider>
  </FilterProvider>
);

export default CoreLocationsMapWithContext;
