import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import IdsSelect, { IIdsSelectProps } from '../../ids-inputs/IdsSelect';
import usePublishedFilter, {
  PUBLISHED_FILTER_MODES,
} from '../../../hooks/filtering/usePublishedFilter';
import { infoPanelActivePaneState } from '../../../atoms/immersiveViewer';
import { panelOptions } from '../../../context/LocationMapContext';

import styles from './PublishedFilter.module.css';

export interface IPublishedFilterProps extends Omit<IIdsSelectProps, 'options'> {
  resetOnUnmount: boolean;

  options?: IIdsSelectProps['options'];
}

const FILTER_OPTIONS = Object.values(PUBLISHED_FILTER_MODES).map(m => ({
  label: m,
  value: m,
}));

const PublishedFilter: React.FC<IPublishedFilterProps> = ({ resetOnUnmount, ...rest }) => {
  const { setPublishedFilterMode, publishedFilterMode } = usePublishedFilter(resetOnUnmount);
  const setActiveInfoPane = useSetRecoilState(infoPanelActivePaneState);

  const handleChange: IIdsSelectProps['onChange'] = useCallback(
    event => {
      const newFilterMode = event.target.value;

      if (newFilterMode !== PUBLISHED_FILTER_MODES.INACTIVE) {
        setActiveInfoPane(panelOptions.FILTERS); // Automatically open filter pane when filter is set
      }

      setPublishedFilterMode(newFilterMode);
    },
    [setPublishedFilterMode, setActiveInfoPane],
  );

  return (
    <IdsSelect
      label='Published'
      options={FILTER_OPTIONS}
      value={publishedFilterMode}
      onChange={handleChange}
      className={styles.select}
      {...rest}
    />
  );
};

export default PublishedFilter;
