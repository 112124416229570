import React from 'react';

import { useRecoilValue } from 'recoil';

import { ProjectIcon, SMALL_ICON_SIZE } from '../../theme/icons';

import { activeOrganizationState } from '../../atoms/organizations';

import { activeTenantState } from '../../atoms/tenants';
import { getOrgProjectRoute } from '../../utils/routes';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface IProjectChipProps extends IEntityChipProps {
  projectId?: string;
  projectName?: string;
}

const ProjectChip: React.FC<IProjectChipProps> = ({ projectId, projectName, ...rest }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);

  const label = projectName || `Project #${projectId}`;

  return (
    <EntityChip
      icon={<ProjectIcon size={SMALL_ICON_SIZE} />}
      label={label}
      entityRoute={getOrgProjectRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        projectId,
      })}
      {...rest}
    />
  );
};

export default ProjectChip;
