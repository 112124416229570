import React from 'react';
import { Chip, ChipProps, Divider, Grid, Typography } from '@mui/material';

import { ArrowForwardIcon } from '../../../../theme/icons';

export interface IFieldChangeSummaryProps {
  title: string;
  updatedValue: React.ReactNode;
  updatedValueChipProps?: Omit<ChipProps, 'label'>;
  originalValues?: React.ReactNode[];
  hideDivider?: boolean;
}

const FieldChangeSummary: React.FC<IFieldChangeSummaryProps> = ({
  title,
  updatedValue,
  updatedValueChipProps,
  originalValues,
  hideDivider,
}) => {
  if (updatedValue === null || updatedValue === undefined) return null;

  return (
    <>
      <Grid container direction='column' rowSpacing={1}>
        <Grid item xs>
          <Typography variant='subtitle2'>{title}</Typography>
        </Grid>
        <Grid item xs container direction='row' spacing={1}>
          {originalValues && (
            <>
              {originalValues.map((value, i) => (
                <Grid key={`${value}-${i}`} item xs='auto'>
                  <Chip label={value} size='small' />
                </Grid>
              ))}
              <Grid item xs='auto'>
                <ArrowForwardIcon color='action' />
              </Grid>
            </>
          )}
          <Grid item xs='auto'>
            <Grid item xs='auto'>
              <Chip label={updatedValue} size='small' color='info' {...updatedValueChipProps} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!hideDivider && <Divider />}
    </>
  );
};

export default FieldChangeSummary;
