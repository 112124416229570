import React, { useMemo, useState } from 'react';
import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';

import { useRecoilValue } from 'recoil';

import { gql } from 'graphql-request';

import { useProjectKeys } from '../../../services/ProjectService';

import globalSx from '../../../theme/globalSx';
import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import IdsTabsRenderer from '../../../components/IdsTabsRenderer';
import LocationChip from '../../../components/entity-chips/LocationChip';
import ProgramChip from '../../../components/entity-chips/ProgramChip';
import ProjectStatusChip from '../ProjectStatusChip';

import QueryLoader from '../../../components/QueryLoader';
import { activeOrganizationState } from '../../../atoms/organizations';
import { activeProjectState } from '../../../atoms/projects';
import useOrgGraphQuery from '../../../hooks/useOrgGraphQuery';
import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/users';

import { getOrgProjectsRoute } from '../../../utils/routes';

import { activeTenantState } from '../../../atoms/tenants';

import Actions from './Actions';
// import ProjectReportsTab from './ReportsTab';
// import MediaFileTypesTab from './MediaFileTypesTab';
import ProjectDocumentsTab from './DocumentsTab';
// import ActivityFeedTab from './ActivityFeedTab';
import RoutesTab from './RoutesTab';
import ProjectMediaTab, { MediaTabFrag } from './MediaTab';
import UsersTab from './UsersTab';
import ProjectAssignmentsTab from './AssignmentsTab';
import styles from './ProjectDetail.module.css';

const ProjectDetailQuery = gql`
  query ProjectDetails($orgId: ID, $id: ID!, $tenantId: ID) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      name
      description
      status
      startDate
      completionDate
      programId
      location {
        id
        name
        ...MediaTabFrag
      }
      program {
        name
      }
    }
  }
  ${MediaTabFrag}
`;

function ProjectDetail() {
  const projectKeys = useProjectKeys();

  const { userHasOneOfRoles } = usePermissions();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);
  const [project, setProject] = useState();

  const useProject = () =>
    useOrgGraphQuery(projectKeys.detail(activeProject.id), ProjectDetailQuery, {
      id: activeProject.id,
    });

  const tabs = useMemo(() => {
    const tabs = [
      {
        key: 'media',
        label: 'Media',
        content: <ProjectMediaTab project={project} />,
      },
      {
        key: 'users',
        label: 'Users',
        content: <UsersTab />,
      },
      // {
      //   key: 'activity_feed',
      //   label: 'Activity Feed',
      //   content: <ActivityFeedTab />,
      // },
      {
        key: 'documents',
        label: 'Documents',
        content: <ProjectDocumentsTab />,
      },
      // {
      //   key: 'media_file_types',
      //   label: 'Media File Types',
      //   content: <MediaFileTypesTab />,
      // },
      // {
      //   key: 'reports',
      //   label: 'Reports',
      //   content: <ProjectReportsTab />,
      // },
      {
        key: 'assignments',
        label: 'Assignments',
        content: <ProjectAssignmentsTab />,
      },
    ];

    if (
      userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_TEAM,
        USER_ROLES.TENANT_ADMIN,
      ])
    ) {
      tabs.push({
        key: 'routes',
        label: 'Routes',
        content: <RoutesTab />,
      });
    }

    return tabs;
  }, [project, userHasOneOfRoles]);

  return (
    <QueryLoader
      useQuery={useProject}
      selectData={data => data?.project}
      setData={setProject}
      render={() => {
        const { name, description, status, startDate, completionDate, location } = project;
        const formattedStartDate = new Date(startDate).toLocaleDateString();
        const formattedCompletionDate = new Date(completionDate).toLocaleDateString();

        return (
          <Page title={name} className={styles['page']}>
            <Container maxWidth={false} className={styles['pageContainer']}>
              <NestedOrgViewHeader
                breadcrumbs={[
                  {
                    label: 'Projects',
                    route: getOrgProjectsRoute({
                      subdomain: activeTenant?.subdomain,
                      orgId: activeOrg.id,
                    }),
                  },
                ]}
                currentCrumb={name}
                actions={<Actions />}
              />
              <Card sx={globalSx.pageContent} className={styles['card']}>
                <Box p={2}>
                  <Grid container flexDirection='column' spacing={1}>
                    <Grid item xs>
                      <Typography variant='h4' color='text.primary'>
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant='body2' color='text.primary'>
                        {description}
                      </Typography>
                    </Grid>
                    <Grid item xs='auto'>
                      <Typography variant='body2' color='text.secondary'>
                        {formattedStartDate} - {formattedCompletionDate}
                      </Typography>
                    </Grid>
                    <Grid item xs container flexDirection='row' spacing={2}>
                      <Grid item xs='auto'>
                        <LocationChip locationId={location.id} locationName={location.name} />
                      </Grid>
                      {project.programId && (
                        <Grid item xs='auto'>
                          <ProgramChip
                            programId={project.programId}
                            programName={project.program.name}
                          />
                        </Grid>
                      )}
                      <Grid item xs='auto'>
                        <ProjectStatusChip status={status} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
              </Card>
            </Container>
          </Page>
        );
      }}
    />
  );
}

export default ProjectDetail;
