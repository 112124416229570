import { useRecoilState, useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../atoms/organizations';

import AssignmentResponseDetailsTemplatePage from '../../Assignments/AssignmentResponseDetailsTemplatePage';
import {
  activeProjectAssignmentResponseState,
  activeProjectAssignmentState,
  activeProjectState,
} from '../../../atoms/projects';
import {
  getOrgProjectAssignmentRoute,
  getOrgProjectAssignmentsRoute,
  getOrgProjectRoute,
  getOrgProjectsRoute,
} from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';

const ProjectAssignmentResponseDetails = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [activeOrg] = useRecoilState(activeOrganizationState);
  const [activeProject] = useRecoilState(activeProjectState);
  const [activeAssignment] = useRecoilState(activeProjectAssignmentState);
  const [activeAssignmentResponse] = useRecoilState(activeProjectAssignmentResponseState);

  if (!activeProject || !activeAssignment || !activeAssignmentResponse || !activeTenant) {
    return null;
  }

  return (
    <AssignmentResponseDetailsTemplatePage
      assignmentId={activeAssignment.id}
      assignmentResponseId={activeAssignmentResponse.id}
      breadcrumbs={[
        {
          label: 'Projects',
          route: getOrgProjectsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        {
          label: activeProject.name,
          route: getOrgProjectRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            projectId: activeProject.id,
          }),
        },
        {
          label: 'Assignments',
          route: getOrgProjectAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            projectId: activeProject.id,
          }),
        },
        {
          label: activeAssignment.title,
          route: getOrgProjectAssignmentRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            projectId: activeProject.id,
            assignmentId: activeAssignment.id,
          }),
        },
      ]}
    />
  );
};

export default ProjectAssignmentResponseDetails;
