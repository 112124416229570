import { Box, Card, Container, Divider } from '@mui/material';
import React from 'react';

import IdsTabsRenderer from '../../components/IdsTabsRenderer';
import Page from '../../components/Page';
import TopBar, { TOP_BAR_HEIGHT } from '../../components/TopBar';
import globalSx from '../../theme/globalSx';

import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../theme/styles';

import Details from './Details';
import Settings from './Settings';

const Profile = () => {
  const tabs = [
    {
      key: 'details',
      label: 'Details',
      content: <Details />,
    },
    {
      key: 'settings',
      label: 'Settings',
      content: <Settings />,
    },
  ];

  return (
    <Page title='Profile'>
      <TopBar />

      <Box py={3}>
        <Container
          maxWidth='sm'
          style={{
            marginTop: TOP_BAR_HEIGHT,
            height: FULL_HEIGHT_WITHOUT_TOP_BAR,
          }}
        >
          <Card sx={globalSx.pageContent}>
            <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default Profile;
