import React from 'react';

import { MediaType } from '../../../constants/media';

export type MediaItem = Record<string, any>;

export interface ITransformedMediaItem extends MediaItem {
  id: string;
  mediaType?: MediaType;
  thumbnailUrl?: string;
}

export enum ImageSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export type MediaItemFilterFn = (
  item: ITransformedMediaItem,
  index: number,
  array: ITransformedMediaItem[],
) => boolean;

interface IBaseFilter {
  renderFilter: () => React.ReactNode;
  /** Return true if filter is passed, false otherwise */
  filterMediaItem?: MediaItemFilterFn;
  /** If render order is different from order filters should be applied in (filterOrder: 0 would be applied first) */
  filterOrder?: number;
  /** If true, filtering logic occurs outside this component, component only renders the filter */
  filterExternally?: boolean;
  disableWrapper?: boolean;
}

interface IInternalFilter extends IBaseFilter {
  filterExternally?: false;
  filterMediaItem: MediaItemFilterFn;
}

interface IExternalFilter extends IBaseFilter {
  filterExternally: true;
  filterMediaItem: undefined;
}

export type Filter = IInternalFilter | IExternalFilter;

export interface IMediaGroup {
  key: string;
  label: string;
  media: ITransformedMediaItem[];
  chunkedMedia: ITransformedMediaItem[][];
}

export interface ISorter {
  key: string;
  label: string;
  /** Return key for group this mediaItem should be grouped into */
  selectGroupingKey: (item: ITransformedMediaItem) => string;
  /** Return label to display for the group */
  resolveGroupLabel: (groupKey: string) => string;
  /** Array sorting method for sorting media groups */
  sortGroups: (groupA: IMediaGroup, groupB: IMediaGroup) => number;
  /** Array sorting method for sorting media within a group */
  sortGroupMedia: (itemA: ITransformedMediaItem, itemB: ITransformedMediaItem) => number;
  /** Determines if this sorter will be active by default */
  isDefault: boolean;
}

export type BulkActionHandler = (selectedItemIds: string[]) => void;

export interface IBulkAction {
  icon: React.ReactNode;
  label: string;
  onClick: BulkActionHandler;
  disabled?: boolean;
}
