import { Box } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../../atoms/organizations';
import { activeProgramState } from '../../../../../atoms/programs';
import IdsEditForm from '../../../../../components/ids-forms/IdsEditForm';
import IdsFormTextField from '../../../../../components/ids-forms/IdsFormTextField';
import IdsFormTransferListField from '../../../../../components/ids-forms/IdsFormTransferListField';
import LoadingScreen from '../../../../../components/LoadingScreen';
import {
  useGetProgramDetails,
  useGetProgramProjects,
  useUpdateProgram,
} from '../../../../../services/ProgramsService';
import { useGetOrganizationProjects } from '../../../../../services/ProjectService';
import { updateProgramValidationSchema } from '../../../programValidation';
import Error from '../../../../Error';

export interface IEditProgramFormProps {
  onCancel: () => any;
}

interface IFormValues {
  name: string;
  description: string;
  project_ids: string[];
}

interface IProjectOptions {
  id: string;
  name: string;
  location: string;
}

const formErrorHandler = () => 'The program cannot be updated';

const EditProgramForm: React.FC<IEditProgramFormProps> = ({ onCancel }) => {
  const [projectOptions, setProjectOptions] = useState<IProjectOptions[] | null>(null);
  const [programDetails, setProgramDetails] = useState<IFormValues | null>(null);

  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);

  const updateProgramMutation = useUpdateProgram(activeProgram!.id, activeOrg.id);

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    error: isErrorProjects,
  } = useGetOrganizationProjects(activeOrg.id);

  const {
    data: programData,
    isLoading: isLoadingProgram,
    isError: isErrorProgram,
  } = useGetProgramDetails(activeProgram!.id);

  const {
    data: programProjects,
    error: programProjectsError,
    isLoading: isLoadingProgramProjects,
  } = useGetProgramProjects(activeProgram!.id);

  useEffect(() => {
    if (isLoadingProjects) {
      return;
    }

    if (projectsData) {
      const projectOptionsFormatted = projectsData.reduce(
        (accumulator: IProjectOptions[], project) => {
          if (project.node.programId === activeProgram!.id || !project.node.programId) {
            accumulator.push({
              id: project.node.id,
              name: project.node.name,
              location: project.node.location.name,
            });
          }

          return accumulator;
        },
        [],
      );

      /**
       * The options should be sorted with the same dimension
       * that they are grouped by.
       */
      const projectOptionsSorted = projectOptionsFormatted.sort(
        (a, b) => -b.location.localeCompare(a.location),
      );

      setProjectOptions(projectOptionsSorted);
    }
  }, [projectsData, isLoadingProjects, activeProgram]);

  useEffect(() => {
    if (programData && programProjects && !isLoadingProgramProjects) {
      setProgramDetails({
        name: programData.program.name,
        description: programData.program.description,
        project_ids: programProjects.map(p => p.node.id),
      });
    }
  }, [programData, programProjects, isLoadingProgramProjects]);

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      await updateProgramMutation.mutateAsync(values);

      onCancel();
    },
    [onCancel, updateProgramMutation],
  );

  if (isErrorProjects || isErrorProgram || programProjectsError) {
    return <Error />;
  }

  if (
    isLoadingProjects ||
    isLoadingProgram ||
    isLoadingProgramProjects ||
    projectOptions === null ||
    programDetails === null
  ) {
    return <LoadingScreen />;
  }

  return (
    <IdsEditForm
      enableReinitialize
      initialValues={programDetails}
      validationSchema={updateProgramValidationSchema}
      successMessage='The program was updated'
      errorHandler={formErrorHandler}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <Box px={1} pt={1}>
        <IdsFormTextField name='name' label='Name' required autoFocus />
        <IdsFormTextField
          name='description'
          label='Description'
          required
          multiline
          minRows={3}
          maxRows={6}
          margin='normal'
        />
        <IdsFormTransferListField
          optionsLabel='Search unselected projects'
          valuesLabel='Search selected projects'
          name='project_ids'
          options={projectOptions}
          getPrimaryLabel={o => o.name}
          getSecondaryLabel={o => o.location}
          getOptionValue={o => o.id}
        />
      </Box>
    </IdsEditForm>
  );
};

export default EditProgramForm;
