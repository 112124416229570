import React, { MouseEvent, useCallback } from 'react';

import { useFormikContext } from 'formik';

import IdsToggleButtons, { IIdsToggleButtonsProps } from '../../../ids-inputs/IdsToggleButtons';

export interface IIdsFormToggleButtonsFieldProps
  extends Omit<IIdsToggleButtonsProps, 'value' | 'onChange'> {
  name: string;
  onChange?: (value: boolean | null) => void;
}

const IdsFormToggleButtonsField: React.FC<IIdsFormToggleButtonsFieldProps> = ({
  name,
  onChange,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, boolean>>();

  const handleChange = useCallback(
    (_: MouseEvent<HTMLElement>, value: boolean | null) => {
      setFieldValue(name, value);
      onChange?.(value);
    },
    [setFieldValue, name, onChange],
  );

  return <IdsToggleButtons onChange={handleChange} value={values && values[name]} {...rest} />;
};

export default IdsFormToggleButtonsField;
