/**
 * The purpose of this file is to avoid circular dependencies and failures
 * because of them ("cannot access before initialization" error).
 */

import { gql } from 'graphql-request';

import { RouteStatus } from './RouteService';

export interface IProjectRouteListItemData {
  id: string;
  name: string;
  description?: string;
  position: string;
  projectId: string;
  published: boolean;
  status: RouteStatus;
  notes?: string;
  routePoints: {
    id: string;
    images: {
      id: string;
    }[];
    notes: {
      id: string;
    }[];
  }[];
}

export const ProjectRouteListItemFrag = gql`
  fragment ProjectRouteListItemFrag on Route {
    id
    name
    description
    position
    status
    projectId
    published
    notes
    routePoints {
      id
      images {
        id
      }
      notes {
        id
      }
    }
  }
`;

export interface ILocationListItem {
  id: string;
  name: string;
  address?: {
    streetLine1?: string;
    city?: string;
    state?: string;
    postalCode?: string;
  };
}

export const LocationListItemFrag = gql`
  fragment LocationListItemFrag on Location {
    id
    name
    address {
      streetLine1
      city
      state
      postalCode
    }
  }
`;
