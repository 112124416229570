import React from 'react';

import IdsDialog, { IIdsDialogProps } from '../../../../../../../components/IdsDialog';

import CreateRouteForm from './CreateRouteForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateRouteDialogProps
  extends Pick<IIdsDialogProps, 'open' | 'onOpen' | 'onClose'> {}

const CreateRouteDialog: React.FC<ICreateRouteDialogProps> = ({ open, onOpen, onClose }) => {
  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='Create Route'
      dialogKey='createRoute'
      maxWidth='lg'
    >
      <CreateRouteForm onCancel={onClose} />
    </IdsDialog>
  );
};

export default CreateRouteDialog;
