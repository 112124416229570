import ComingSoonRoute from '../../../components/ComingSoonRoute';
import Page from '../../../components/Page';

function TenantAssignments() {
  return (
    <Page title='Assignments'>
      <ComingSoonRoute legacyFeatureRoute={null} />
    </Page>
  );
}
export default TenantAssignments;
