import { useMemo } from 'react';

import { IDocument } from './types';

const useGetDocumentLatestVersion = (document?: IDocument | null) => {
  return useMemo(() => {
    const versions = document?.node.documentVersions;

    return versions?.length ? versions[versions.length - 1] : null;
  }, [document]);
};

export default useGetDocumentLatestVersion;
