import { CompositeLayer } from '@deck.gl/core';
import { PathLayer, SolidPolygonLayer } from '@deck.gl/layers';

const getBaseColor = color => {
  return color.slice(0, 3); // Only return rgb
};

const isFunc = accessor => typeof accessor === 'function';

class OutlinedPolygonLayer extends CompositeLayer {
  renderLayers() {
    const {
      data,
      getPoints,
      getColor,
      getFillOpacity,
      getLineOpacity,
      getWidth,
      getId,
      updateTriggers,
    } = this.props;

    // Extract base color (RGB) from getColor accessor and set alpha based on where the color is being used
    const _getColor = getAlpha => {
      const getAlphaIsFunc = isFunc(getAlpha);
      const getColorIsFunc = isFunc(getColor);

      if (getAlphaIsFunc) {
        return getColorIsFunc
          ? d => [...getBaseColor(getColor(d)), getAlpha(d)]
          : d => [...getBaseColor(getColor), getAlpha(d)];
      } else {
        return getColorIsFunc
          ? d => [...getBaseColor(getColor(d)), getAlpha]
          : [...getBaseColor(getColor), getAlpha];
      }
    };

    const layers = [];

    // Create separate polygon and path layers for each polygon so the polygon and its path are at the same z-index
    data.forEach(polygon => {
      const polygonId = getId(polygon); // Use getId accessor to ensure the same id is used per polygon, even if the order changes

      layers.push(
        new SolidPolygonLayer(
          this.getSubLayerProps({
            id: `area-${polygonId}`,
            data: [polygon],
            stroked: false,
            filled: true,
            getPolygon: getPoints,
            getFillColor: _getColor(getFillOpacity),
            updateTriggers: {
              getPolygon: updateTriggers.getPoints,
              getFillColor: [updateTriggers.getColor, updateTriggers.getFillOpacity],
            },
          }),
        ),
      );

      layers.push(
        new PathLayer(
          this.getSubLayerProps({
            id: `path-${polygonId}`,
            data: [polygon],
            widthMinPixels: 4,
            widthUnits: 'pixels',
            getWidth,
            getPath: getPoints,
            getColor: _getColor(getLineOpacity),
            updateTriggers: {
              getWidth: updateTriggers.getPosition,
              getPath: updateTriggers.getPoints,
              getColor: [updateTriggers.getColor, updateTriggers.getLineOpacity],
            },
          }),
        ),
      );
    });

    return layers;
  }
}

OutlinedPolygonLayer.layerName = 'MeasurementLayer';
OutlinedPolygonLayer.defaultProps = {
  getPoints: { type: 'accessor', value: d => d },
  getColor: { type: 'accessor', value: [255, 10, 255] },
  getWidth: { type: 'accessor', value: 4 },
  getFillOpacity: { type: 'accessor', value: 50 },
  getLineOpacity: { type: 'accessor', value: 150 },
  getId: { type: 'accessor', value: d => d },
};

export default OutlinedPolygonLayer;
