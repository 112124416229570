import { gql } from 'graphql-request';

import MediaCommentFrag from '../../../../../features/Comments/EntityComments/MediaCommentFrag';
import useOrgGraphQuery from '../../../../../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery, {
  IUseCursorPaginatedQueryOptions,
} from '../../../../../hooks/useCursorPaginatedQuery';
import { usePointOfInterestKeys } from '../../../../../services/PointOfInterestService';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';

import { DEFAULT_TAKE } from '../index';

export const PointsOfInterestQuery = gql`
  query PointsOfInterest($orgId: ID, $tenantId: ID, $locationId: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      pointsOfInterest(take: $take, after: $after) {
        edges {
          cursor
          node {
            id
            organizationId
            projectId
            creator {
              id
              firstName
              lastName
            }
            name
            data
            color
            flagged
            position {
              latitude
              longitude
            }
            createdAt
            metadata {
              id
              type
              value
            }
            fields {
              id
              type
              label
              value
            }
            images {
              id
            }
            comments {
              ...MediaCommentFrag
            }
          }
        }
      }
    }
  }
  ${MediaCommentFrag}
`;

const usePOIs = (onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad']) => {
  const pointOfInterestKeys = usePointOfInterestKeys();
  const { location } = useLocationMapContext();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...pointOfInterestKeys.map(location?.id), 'points', `take-${take}`, `after-${after}`],
      PointsOfInterestQuery,
      {
        locationId: location?.id,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery({
    initialData: location?.pointsOfInterest?.edges || [],
    useQuery,
    defaultTake: DEFAULT_TAKE,
    selectConnection: d => d.location.pointsOfInterest,
    onPageLoad,
  });
};

export default usePOIs;
