import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useAssignmentKeys } from '../../services/AssignmentsService';
import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';

import { activeLocationAssignmentState, activeLocationState } from '../../atoms/locations';

import { getOrgLocationAssignmentsRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const AssignmentRouteQuery = gql`
  query AssignmentRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignment(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      title
    }
  }
`;

export const LocationAssignmentRoute: React.FC = () => {
  const assignmentKeys = useAssignmentKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrganization = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const [activeLocationAssignment, setActiveLocationAssignment] = useRecoilState(
    activeLocationAssignmentState,
  );

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useLocationAssignment = (id: string | undefined, options: UseGraphQueryOptions) =>
    useOrgGraphQuery(assignmentKeys.route(id!), AssignmentRouteQuery, { id }, options);

  if (!activeLocation) {
    return null;
  }

  return (
    <EntityRoute
      idParam={'assignmentId'}
      currentEntity={activeLocationAssignment}
      setEntity={setActiveLocationAssignment}
      useEntity={useLocationAssignment}
      entitySelector={data => data?.assignment}
      errorRedirectRoute={getOrgLocationAssignmentsRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrganization.id,
        locationId: activeLocation.id,
      })}
    />
  );
};

export default LocationAssignmentRoute;
