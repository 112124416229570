import React from 'react';

import ImageMarkerForm, { IImageMarkerFormProps } from '../ImageMarkerForm';
import { MediaType } from '../../../../../../constants/media';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHDPhotoMarkerFormProps extends Omit<IImageMarkerFormProps, 'imageType'> {}

const HDPhotoMarkerForm: React.FC<IHDPhotoMarkerFormProps> = props => {
  return <ImageMarkerForm imageType={MediaType.HDPhoto} {...props} />;
};

export default HDPhotoMarkerForm;
