import axios from 'axios';

import RuntimeConfig from '../RuntimeConfig';

const mapBoxInstance = axios.create({ baseURL: 'https://api.mapbox.com' });

/**
 * IMPORTANT!! To avoid violating [Mapbox Terms of Service](https://docs.mapbox.com/api/search/geocoding/#mapboxplaces):
 * - Cannot store the geocoding result
 * - Can only use result for rendering
 * - Request must be triggered by user interaction
 *
 * The approach taken with this service is to use the result to move the map view,
 * but to require the user to actually make a selection on the map. This way,
 * the geocode result itself is not stored, it is just used to guide a selection.
 *
 * For example usage, see the [CreateLocationForm](../views/Locations/CreateLocationDialog/CreateLocationForm/index.jsx)
 */
export async function forwardGeocode(searchText) {
  if (!searchText || typeof searchText !== 'string') {
    return null;
  }

  // Perform any search text cleaning before sending request based on endpoint reqs: https://docs.mapbox.com/api/search/geocoding/#forward-geocoding
  const trimmedSearch = searchText.trim().replaceAll(';', ''); // No semicolons

  // Max of 20 words and numbers
  const words = trimmedSearch.split(' ');
  const limitedWords = words.length <= 20 ? words : words.slice(0, 21);

  // Max of 256 characters
  const cleanSearch = limitedWords.join(' ').substring(0, 256);

  const params = new URLSearchParams({
    access_token: RuntimeConfig.mapboxToken,
    types: 'address',
    limit: 1,
  });

  const res = await mapBoxInstance.get(
    `/geocoding/v5/mapbox.places/${cleanSearch}.json?${params.toString()}`,
  );

  const coords = res.data?.features[0].center;
  return coords ? { latitude: coords[1], longitude: coords[0] } : null;
}
