import { IconLayer } from '@deck.gl/layers/typed';

interface IInvertedIconLayerExtraProps {
  invertColor: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
class InvertedIconLayer<TData = any, TExtraProps = {}> extends IconLayer<
  TData,
  IInvertedIconLayerExtraProps & TExtraProps
> {
  initializeState() {
    super.initializeState();
  }

  getShaders(): any {
    const shaders = super.getShaders();

    shaders.inject = {
      'fs:#decl': `
        uniform bool invertColor;
      `,
      'fs:DECKGL_FILTER_COLOR': `
        if (invertColor) {
          color.r = 1.0 - color.r;
          color.g = 1.0 - color.g;
          color.b = 1.0 - color.b;
        }
      `,
    };
    return shaders;
  }

  draw(params: any) {
    // Allow color inversion to be toggle on and off (default is on)
    params.uniforms.invertColor = this.props.invertColor ?? true;
    super.draw(params);
  }
}

InvertedIconLayer.layerName = 'InvertedIconLayer';

export default InvertedIconLayer;
