import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRecoilValue } from 'recoil';

import { activeProjectRouteState } from '../../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../../atoms/organizations';
import { MediaMetadataType } from '../../../../../../constants/media';

import CancelButton from '../../../../../../components/ids-buttons/CancelButton';
import SaveButton from '../../../../../../components/ids-buttons/SaveButton';

import { useManageRoutePointsMutation } from '../../../../../../services/RouteService';
import { MediaMetadata } from '../../../../../../services/types';

import { isHeadingValid } from '../../../../../../utils/geospatial';

import { IRoutePoint } from '../../../types';
import { ISettings } from '../types';

export interface IEditingButtonsProps {
  point: IRoutePoint;
  settings: ISettings;

  onCancel: () => void;
}

const metadataTypes = Object.values(MediaMetadataType);

const EditingButtons: React.FC<IEditingButtonsProps> = ({ point, settings, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    const headingChange =
      isHeadingValid(point.node.position.heading) &&
      point.node.position.heading !== settings.heading;

    if (headingChange) {
      setHasChanges(true);

      return;
    }

    const metadataChange = metadataTypes.some(m => {
      if (!(m in settings)) {
        // @TODO: PhotoTag is not supported yet, so ignore it.
        return false;
      }

      const pointMetadata = point.node.metadata.find(pm => pm.type === m);

      /**
       * Optional metadata, e.g. Area.
       * Consider it is changed if it has a value.
       */
      if (!pointMetadata) {
        return !!settings[m as keyof ISettings];
      }

      return settings[m as keyof ISettings] !== pointMetadata.id;
    });

    setHasChanges(metadataChange);
  }, [point, settings]);

  const activeProjectRoute = useRecoilValue(activeProjectRouteState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const manageRoutePoints = useManageRoutePointsMutation();

  const handleSave = useCallback(async () => {
    setIsUpdating(true);

    const metadataValues = Object.values(MediaMetadataType);
    /**
     * This is to save updated metadata.
     */
    const metadata: MediaMetadata[] = Object.entries(settings).reduce(
      (accumulator, [key, value]) => {
        if (metadataValues.includes(key as MediaMetadataType) && value !== null) {
          accumulator.push({ type: key as MediaMetadataType, id: value });
        }

        return accumulator;
      },
      [] as MediaMetadata[],
    );

    /**
     * This is to keep the rest metadata.
     */
    point.node.metadata.forEach(pm => {
      if (metadata.some(m => m.type === pm.type)) {
        return;
      }

      metadata.push({ id: pm.id, type: pm.type });
    });

    try {
      const result = await manageRoutePoints.mutateAsync({
        id: activeProjectRoute!.id,
        organizationId: activeOrg.id,
        update: [
          {
            id: point.node.id,
            customFieldMetadata: [],
            metadata: metadata,
            position: {
              ...point.node.position,
              ...(isHeadingValid(point.node.position.heading) && { heading: settings.heading }),
            },
          },
        ],
        delete: [],
        add: [],
      });

      if (result.manageRoutePoints?.errors?.length) {
        enqueueSnackbar('Failed to update point', { variant: 'error' });
      } else {
        enqueueSnackbar('Updated', { variant: 'success' });

        handleCancel();

        return;
      }
    } catch (_) {
      enqueueSnackbar('Failed to update point', { variant: 'error' });
    }

    setIsUpdating(false);
  }, [
    activeProjectRoute,
    activeOrg.id,
    manageRoutePoints,
    enqueueSnackbar,
    point,
    settings,
    handleCancel,
  ]);

  return (
    <Grid item xs container direction='row' gap={2}>
      <Grid item xs>
        <CancelButton onClick={handleCancel} disabled={isUpdating} fullWidth={true} />
      </Grid>
      <Grid item xs>
        <SaveButton
          onClick={handleSave}
          disabled={isUpdating || !hasChanges}
          label='Update'
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

export default EditingButtons;
