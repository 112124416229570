import { gql } from 'graphql-request';

import { ImageMarkerFrag } from '../../../mapping/LocationMap/imageMarkerFrag';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';

import { MediaViewerInfoTabFrag } from '../MediaViewerInfoTab';

export const MediaViewerImageDetailFrag = gql`
  fragment MediaViewerImageDetailFrag on PrismaImage {
    neighbors {
      direction
      id
    }
    ...MediaViewerInfoTabFrag
  }
  ${MediaViewerInfoTabFrag}
`;

const ImageDetailQuery = gql`
  query ImageDetail(
    $orgId: ID
    $tenantId: ID
    $urn: String!
    $immersiveMode: Boolean!
    $infoTab: Boolean!
  ) {
    image(organizationId: $orgId, urn: $urn, tenantId: $tenantId) {
      ...MediaViewerInfoTabFrag @skip(if: $immersiveMode) @include(if: $infoTab)
      ...ImageMarkerFrag @skip(if: $immersiveMode) @include(if: $infoTab)
      ...MediaViewerImageDetailFrag @include(if: $immersiveMode)
    }
  }
  ${MediaViewerImageDetailFrag}
  ${ImageMarkerFrag}
`;

const useImageDetails = (
  urn: string,
  mediaTenantId: string,
  immersiveMode: boolean,
  infoTab: boolean,
  enabled: boolean,
) => {
  return useOrgGraphQuery(
    ['activeImage', urn, mediaTenantId],
    ImageDetailQuery,
    {
      urn,
      immersiveMode,
      infoTab,
    },
    { enabled },
  );
};

export default useImageDetails;
