import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecoilState } from 'recoil';
import { Box, CardMedia } from '@mui/material';

import { activeOrganizationState } from '../../../atoms/organizations';
import LocationImagePlaceholder from '../LocationImagePlaceholder';

import styles from './LocationImage.module.css';

const sxStyles = {
  locationImgContainer: {
    backgroundColor: 'background.dark',
  },
};

const LocationImage = ({ height, ...rest }) => {
  const [activeOrg] = useRecoilState(activeOrganizationState);
  const [hasImg, setHasImg] = useState(false);
  const { sx, ..._rest } = rest;

  const handleImgLoad = useCallback(() => {
    setHasImg(true);
  }, [setHasImg]);

  return (
    <Box sx={{ ...sxStyles.locationImgContainer, ...sx }} {..._rest}>
      <CardMedia
        component='img'
        height={height}
        image={activeOrg?.logo?.url} // or icon with gray background
        onLoad={handleImgLoad}
        // Hides the component until image is loaded
        style={{ ...(!hasImg && { display: 'none' }) }}
        className={styles.locationImg}
      />
      <LocationImagePlaceholder
        className={styles.locationImagePlaceholder}
        style={{
          ...(hasImg && { display: 'none' }),
          height,
        }}
      />
    </Box>
  );
};

LocationImage.defaultProps = {
  height: 75,
};

LocationImage.propTypes = {
  height: PropTypes.number,
};

export default LocationImage;
