import React from 'react';
import PropTypes from 'prop-types';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../constants/media';
import { selectedTypes } from '../../../atoms/typeFilter';

import useActiveFilterTargets from '../../../hooks/filtering/useActiveFilterTargets';
import { useRestoreMediaMetadataFilterFromUrl } from '../../../hooks/filtering/useMediaMetadataFilter';

import MediaMetadataFilter from '../MediaMetadataFilter';
import { getCommonMetadataFilterTargets } from '../helpers';

const filterTargets = getCommonMetadataFilterTargets(MediaMetadataType.PhotoType);

const TypeFilter = props => {
  const activeFilterTargets = useActiveFilterTargets(filterTargets);

  return (
    <MediaMetadataFilter
      label={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].label}
      metadataType={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].type}
      filterTargets={activeFilterTargets}
      filterStateAtom={selectedTypes}
      noMetadataToFilterByMessage='There are no types to filter by.'
      {...props}
    />
  );
};

export const useRestoreTypeFilterFromUrl = allValidMetadataItems => {
  const activeFilterTargets = useActiveFilterTargets(filterTargets);

  useRestoreMediaMetadataFilterFromUrl({
    metadataType: MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].type,
    filterTargets: activeFilterTargets,
    filterStateAtom: selectedTypes,
    allValidMetadataItems,
  });
};

TypeFilter.propTypes = {
  allSortedMetadataItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default TypeFilter;
