import React, { useCallback, useEffect, useState } from 'react';

import useMapAreaTool from '../../../../hooks/useMapAreaTool';
import useMapContextMenu from '../../../../hooks/useMapContextMenu';
import { AreaOfInterestIcon } from '../../../../theme/icons';
import { TOOLS_ID } from '../../LocationMapMenu';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import CreateAreaOfInterestDialog from '../../areas-of-interest/CreateAreaOfInterestDialog';
import IDS_COLORS from '../../../../theme/ids-colors';

export const AOI_TOOL_ID = 'aoi-tool';

const DEFAULT_COLOR = IDS_COLORS.marker.areaOfInterest;
const DEFAULT_FLAGGED = false;

const AreaOfInterestTool = ({ projects, ...rest }) => {
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [flagged, setFlagged] = useState(DEFAULT_FLAGGED);
  const [startedFromCtxMenu, setStartedFromCtxMenu] = useState(false);
  const { setItemState } = useImmersiveViewer();
  const { enabled, placingPoints, points, distance, area, center, reset } = useMapAreaTool(
    AOI_TOOL_ID,
    flagged ? IDS_COLORS.marker.markerDotFlagged : color,
  );
  const { setMenuItem } = useMapContextMenu();

  const startAOI = useCallback(() => {
    setItemState(TOOLS_ID, { active: AOI_TOOL_ID });
  }, [setItemState]);

  const startAOIFromMenu = useCallback(() => {
    startAOI();
    setStartedFromCtxMenu(true);
  }, [startAOI, setStartedFromCtxMenu]);

  useEffect(() => {
    setMenuItem(AOI_TOOL_ID, {
      icon: <AreaOfInterestIcon />,
      label: 'New area of interest',
      onClick: startAOIFromMenu,
    });

    return () => {
      setMenuItem(AOI_TOOL_ID, null);
    };
  }, [setMenuItem, startAOIFromMenu]);

  const _reset = useCallback(() => {
    reset();
    setColor(DEFAULT_COLOR);
    setFlagged(DEFAULT_FLAGGED);
    setStartedFromCtxMenu(false);
  }, [reset, setColor, setFlagged]);

  const close = useCallback(
    reason => {
      if (enabled) {
        if (startedFromCtxMenu || reason === 'created') {
          setItemState(TOOLS_ID, { active: null });
        }
        _reset();
      }
    },
    [enabled, setItemState, _reset, startedFromCtxMenu],
  );

  useEffect(() => {
    if (!enabled) {
      _reset();
    }
  }, [enabled, _reset]);

  return (
    <CreateAreaOfInterestDialog
      open={!!(!placingPoints && points.length)}
      onClose={close}
      points={points}
      distance={distance}
      area={area}
      center={center}
      onColorChange={setColor}
      onFlaggedChange={setFlagged}
      projects={projects}
    />
  );
};

export default AreaOfInterestTool;
