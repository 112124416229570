import OutlinedPolygonLayer from '../OutlinedPolygonLayer';
import buildIdsLayer from '../IdsLayer';
import { LAYER_TYPE } from '../../../../context/ImmersiveViewerContext';
import { areaOfInterestLayerId } from '../../../../constants/layerIds';
import IDS_COLORS from '../../../../theme/ids-colors';
import { getRGBColor, rgbStrToArray } from '../../../../utils/colors';

const defaultAOIColor = rgbStrToArray(IDS_COLORS.marker.areaOfInterest);
const flaggedColor = rgbStrToArray(IDS_COLORS.marker.markerDotFlagged);
const defaultFillOpacity = 50;
const highlightedFillOpacity = 150;
const defaultLineOpacity = 150;
const highlightedLineOpacity = 200;
const defaultLineWidth = 4;
const highlightedLineWidth = 6;

const AOIProps = {
  id: areaOfInterestLayerId,
  getId: d => d.node.id,
  getPoints: d => d.deckPoints,
  getColor: d => {
    if (d.node.flagged) {
      return flaggedColor;
    }

    const rgb = getRGBColor(d.node.color);
    return rgb ? rgbStrToArray(rgb) : defaultAOIColor;
  },
};

export const buildAreaOfInterestLayer = (aois, outlinedPolygonProps, idsLayerProps) => {
  if (!aois) {
    return null;
  }

  // Transform data before passing into layer to prevent accessors from having to do this
  const transformedAOIs = aois.map(d => ({
    ...d,
    deckPoints: d.node.points.map(point => [point.longitude, point.latitude, point.altitude || 0]),
  }));

  const buildLayer = metadata => {
    const { highlightedMediaIds } = metadata;

    const getHighlighted = d => highlightedMediaIds.includes(d.node.id);

    return new OutlinedPolygonLayer({
      ...AOIProps,
      ...outlinedPolygonProps,
      id: areaOfInterestLayerId,
      data: transformedAOIs,
      getFillOpacity: d => (getHighlighted(d) ? highlightedFillOpacity : defaultFillOpacity),
      getLineOpacity: d => (getHighlighted(d) ? highlightedLineOpacity : defaultLineOpacity),
      getWidth: d => (getHighlighted(d) ? highlightedLineWidth : defaultLineWidth),
      updateTriggers: {
        getFillOpacity: highlightedMediaIds,
        getLineOpacity: highlightedMediaIds,
        getWidth: highlightedMediaIds,
      },
    });
  };

  return buildIdsLayer(buildLayer, {
    ...idsLayerProps,
    type: LAYER_TYPE.AREA,
    metadata: {
      highlightedMediaIds: [],
      ...idsLayerProps?.metadata,
    },
  });
};
