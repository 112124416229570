import { useMemo } from 'react';

import IdsDialog from '../../../components/IdsDialog';
import { IDocument, IDocumentVersion } from '../types';

import { IMetadataTypeValue } from '../../../constants/media';

import ViewDocumentVersion from './ViewDocumentVersion';
import EditDocumentVersion, { IEditDocumentVersionProps } from './EditDocumentVersion';

export enum ViewEditDocumentModalMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export interface IViewEditDocumentModalProps {
  open: boolean;
  onOpen: () => any;
  onClose: () => any;
  mode: ViewEditDocumentModalMode;
  document?: IDocument | null;
  documentVersion?: IDocumentVersion | null;
  onSubmit?: () => any;
  onCancel?: () => any;
  onSubmitDocumentEdit: IEditDocumentVersionProps['onSubmitDocumentEdit'];

  optionsTags: IMetadataTypeValue[];
}

/**
 * Modal for Viewing/Editing Document or Document Version item
 *
 * (Right now editing document = editing document version)
 */
export const ViewEditDocumentModal: React.FC<IViewEditDocumentModalProps> = ({
  open,
  onOpen,
  onClose,
  mode,
  document,
  documentVersion,
  onSubmitDocumentEdit,

  optionsTags,
}) => {
  const isLatestDocumentVersion = useMemo(
    () => documentVersion?.id === document?.node.documentVersions[0]?.id,
    [document, documentVersion],
  );

  const title = useMemo(() => {
    const text =
      mode === ViewEditDocumentModalMode.VIEW
        ? 'Document version details'
        : 'Edit document version';
    return isLatestDocumentVersion ? `${text} (latest version)` : text;
  }, [isLatestDocumentVersion, mode]);

  const content = useMemo(() => {
    if (mode === ViewEditDocumentModalMode.VIEW) {
      return <ViewDocumentVersion document={document} documentVersion={documentVersion} />;
    }

    return (
      <EditDocumentVersion
        document={document}
        documentVersion={documentVersion}
        onCancel={onClose}
        onSubmitDocumentEdit={onSubmitDocumentEdit}
        optionsTags={optionsTags}
      />
    );
  }, [mode, document, documentVersion, onClose, onSubmitDocumentEdit, optionsTags]);

  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title={title}
      dialogKey='edit-document'
      maxWidth='md'
      fullWidth
      noSearchParam
    >
      {content}
    </IdsDialog>
  );
};

export default ViewEditDocumentModal;
