import React, { useState, memo } from 'react';

import QueryLoader from '../../../components/QueryLoader';
import IdsSearchableListV2 from '../../../components/ids-lists/IdsSearchableListV2';
import {
  ICollectionsListQueryData,
  Collection,
  useGetCollectionsList,
} from '../../../services/CollectionService';
import CollectionListItem from '../CollectionListItem';
import { sortAlphabetically } from '../../../utils/genericSorting';

export interface ICollectionsListProps {
  orgId: string;
}

const searchItem = (collection: Collection, query: string) => {
  const searchProps = [
    collection.node.title,
    collection.node.creator.firstName,
    collection.node.creator.lastName,
  ];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

const initialFilters = [
  {
    label: 'Privacy',
    key: 'privacy',
    multiple: false,
    options: [
      {
        label: 'All',
        key: 'all',
        filterItem: () => true,
      },
      {
        label: 'Public',
        key: 'public',
        filterItem: (collection: Collection) => collection.node.public,
      },
      {
        label: 'Private',
        key: 'private',
        filterItem: (collection: Collection) => !collection.node.public,
      },
    ],
  },
];

const sorters = [
  {
    key: 'title',
    label: 'Title',
    sortItems: (a: Collection, b: Collection) => sortAlphabetically(a.node.title, b.node.title),
  },
  {
    key: 'creator',
    label: 'Creator',
    sortItems: (a: Collection, b: Collection) => {
      const aName = `${a.node.creator.firstName} ${a.node.creator.lastName}`;
      const bName = `${b.node.creator.firstName} ${b.node.creator.lastName}`;

      return sortAlphabetically(aName, bName);
    },
  },
  {
    key: 'date_ascending',
    label: 'Date Ascending',
    // Unary plus is a workaround for TS.
    sortItems: (a: Collection, b: Collection) =>
      +new Date(a.node.createdAt) - +new Date(b.node.createdAt),
  },
  {
    key: 'date_descending',
    label: 'Date Descending',
    // Unary plus is a workaround for TS.
    sortItems: (a: Collection, b: Collection) =>
      +new Date(b.node.createdAt) - +new Date(a.node.createdAt),
  },
];

const CollectionsList: React.FC<ICollectionsListProps> = memo(({ orgId }) => {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [filters, setFilters] = useState(() => initialFilters);
  const useGetCollectionsListQuery = () => useGetCollectionsList(orgId);

  return (
    <QueryLoader
      useQuery={useGetCollectionsListQuery}
      selectData={data => data.organization.collections.edges}
      setData={(data: ICollectionsListQueryData['organization']['collections']['edges']) => {
        const names = data.reduce((accumulator: any[], item) => {
          const name = `${item.node.creator.firstName} ${item.node.creator.lastName}`;
          if (!accumulator.find((a: any) => a.label === name)) {
            accumulator.push({
              label: name,
              key: name,
              filterItem: (collection: Collection) => {
                const itemName = `${collection.node.creator.firstName} ${collection.node.creator.lastName}`;

                return itemName === name;
              },
            });
          }

          return accumulator;
        }, []);

        setFilters([
          ...filters,
          {
            label: 'Creator',
            key: 'creator',
            multiple: true,
            options: [...names],
          },
        ]);
        setCollections(data);
      }}
      render={() => (
        <IdsSearchableListV2
          items={collections}
          renderItem={(item: Collection) => (
            <CollectionListItem key={item.node.id} node={item.node} />
          )}
          searchPlaceholder='Search collections'
          noItemsMessage='No collections'
          noItemsForFilterMessage='No collections match your search'
          searchItem={searchItem}
          filters={filters}
          sorters={sorters}
        />
      )}
    />
  );
});

export default CollectionsList;
