import React from 'react';
import { Grid, GridProps, Skeleton, Typography } from '@mui/material';

const valueToReadableString = (val?: boolean | string | null) => {
  if (val === null || val === undefined) {
    return 'Empty Field';
  }

  if (typeof val === 'boolean') {
    return val ? 'Yes' : 'No';
  }

  return val;
};

export interface IIdsDataAttributeProps extends GridProps {
  label?: string;
  value?: boolean | string | null | React.ReactNode;
  disableTypography?: boolean;
  loading?: boolean;
}

const IdsDataAttribute: React.FC<IIdsDataAttributeProps> = ({
  label,
  value,
  disableTypography,
  loading,
  ...rest
}) => {
  const renderedValue = disableTypography ? (
    value
  ) : (
    <Typography>{valueToReadableString(value as boolean | string | null)}</Typography>
  );

  return (
    <Grid container spacing={2} direction='row' item {...rest}>
      <Grid item xs={3}>
        <Typography variant='h5'>{label}</Typography>
      </Grid>
      <Grid item xs>
        {loading ? <Skeleton>{renderedValue}</Skeleton> : renderedValue}
      </Grid>
    </Grid>
  );
};

export default IdsDataAttribute;
