import React from 'react';
import { Paper } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { clsx } from 'clsx';

import MapOverlay from '../../MapOverlay';

import styles from './EntityOfInterestInfo.module.css';

const EOIInfoWrapper = ({ children, className, ...rest }) => (
  <MapOverlay className={clsx(styles.container, className)} {...rest}>
    <Paper className={styles.paper}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>{children}</PerfectScrollbar>
    </Paper>
  </MapOverlay>
);

export default EOIInfoWrapper;
