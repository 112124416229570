import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';

import { IFieldComponentProps } from '../../types';
import LabelCell from '../../LabelCell';
import IdsFormColorField from '../../../../../components/ids-forms/IdsFormColorField';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICheckboxProps extends IFieldComponentProps {}

const Color: React.FC<ICheckboxProps> = ({ field, isEditMode }) => {
  return (
    <TableRow>
      <LabelCell label={field.label} />
      <TableCell>
        {isEditMode && field.isEditable ? (
          <Box>
            <IdsFormColorField disabled={!isEditMode} name={field.name} label='' required />
          </Box>
        ) : (
          <Box bgcolor={field.data} color={field.data}>
            &nbsp;
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Color;
