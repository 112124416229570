import { useCallback, useEffect, useState } from 'react';

import useImmersiveViewer from './useImmersiveViewer';
import useDeckEventCallback, { DeckEventCallback } from './useDeckEventCallback';

export type ClientXY = {
  x: number;
  y: number;
};

/** mjolnir.js docs:
 * https://uber-web.github.io/mjolnir.js/docs/api-reference/event
 * */
export interface IDeckEvent {
  type: string;
  center: ClientXY;
  offsetCenter: ClientXY;
  target: any;
  srcEvent: any;
  preventDefault: () => void;
  stopPropagation: () => void;
  stopImmediatePropagation: () => void;
  key?: number;
  leftButton?: boolean;
  rightButton?: boolean;
  pointerType?: string;
  delta?: number;
}

// Used for handling deck.gl's EventManager events directly:
// https://uber-web.github.io/mjolnir.js/docs/api-reference/event-manager
// Some special handling needs to be done to bring it into the react lifecycle
const useDeckEventManagerCallback = (
  eventName: string,
  onEvent: DeckEventCallback,
  enabled = true,
) => {
  const { deckRef } = useImmersiveViewer();

  // NOTE:
  // This syntax is a workaround for accessing eventManager, which is protected
  const eventManager = deckRef?.deck ? deckRef?.deck['eventManager'] : null;
  const { eventHandler } = useDeckEventCallback(onEvent);
  const [listening, setListening] = useState(false);

  const subscribe = useCallback(() => {
    if (!listening && eventManager) {
      eventManager.on(eventName, eventHandler);
      setListening(true);
    }
  }, [eventManager, eventName, eventHandler, setListening, listening]);

  const unsubscribe = useCallback(() => {
    if (listening && eventManager) {
      eventManager.off(eventName, eventHandler);
      setListening(false);
    }
  }, [eventManager, eventName, eventHandler, setListening, listening]);

  useEffect(() => {
    if (enabled) {
      subscribe();
      return unsubscribe; // Return unsubscribe as cleanup
    }

    unsubscribe();
  }, [enabled, subscribe, unsubscribe]);
};

export default useDeckEventManagerCallback;
