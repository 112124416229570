// IMPORTANT: these colors are in the rgb format to support their use in deck.gl
const LIGHT_BLUE = 'rgb(75, 184, 253)';
const LIGHT_GREEN = 'rgb(65, 216, 96)';
const DARK_GREEN = 'rgb(41, 133, 60)';
const RED = 'rgb(245, 7, 7)';

const photo_hd = 'rgb(194, 24, 253)';
const photo_simple = 'rgb(247, 175, 80)';
const photo_360 = LIGHT_BLUE;
const video = DARK_GREEN;
const threeDModel = DARK_GREEN;
const threeDVR = DARK_GREEN;
const pointOfInterest = LIGHT_GREEN;
const areaOfInterest = LIGHT_GREEN;
const asset = LIGHT_GREEN;
const markerDotFlagged = RED;
const dslr_360 = 'rgb(3, 99, 255)';
const routePointCompleted = LIGHT_GREEN;
const routePointNotCompleted = RED;
const routePointNote = 'rgb(130, 130, 130)';
const captured = LIGHT_GREEN;

const IDS_COLORS = {
  marker: {
    photo_hd,
    photo_simple,
    photo_360,
    video,
    threeDModel,
    threeDVR,
    pointOfInterest,
    areaOfInterest,
    asset,
    markerDotFlagged,
    dslr_360,
    routePointCompleted,
    routePointNotCompleted,
    routePointNote,
    captured,
  },
};

export default IDS_COLORS;
