import React, { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';

import IdsImageButton, { IIdsImageButtonProps } from '../../ids-buttons/IdsImageButton';
import { MEDIA_TYPE_TO_DEF, MediaType } from '../../../constants/media';

import IdsDynamicGrid from '../virtualized/IdsDynamicGrid';
import { IIdsImageProps } from '../../IdsImage';

export type IdsImageListImage = any;

export interface IIdsImageListItemProps
  extends Omit<IIdsImageButtonProps, 'imageProps' | 'renderImage' | 'onClick' | 'onDoubleClick'> {
  imageProps?: Omit<IIdsImageProps, 'width' | 'height' | 'onClick' | 'onDoubleClick'>;
  image: IdsImageListImage;
  getImageUrl: (image: IdsImageListImage) => string;
  renderImage?: (image: IdsImageListImage, props: IIdsImageProps) => React.ReactElement;
  imageSize: number;
  onClick?: (image: IdsImageListImage, event: React.MouseEvent<HTMLButtonElement>) => void;
  onDoubleClick?: (image: IdsImageListImage, event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const IdsImageListItem: React.FC<IIdsImageListItemProps> = ({
  image,
  getImageUrl,
  imageSize,
  onClick,
  onDoubleClick,
  renderImage,
  imageProps,
}) => {
  const handleImageClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(image, event);
    },
    [image, onClick],
  );

  const handleImageDoubleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onDoubleClick?.(image, event);
    },
    [image, onDoubleClick],
  );

  // Use the renderImage override if provided, otherwise set to undefined
  const _renderImage = useMemo(
    () => (renderImage ? (props: IIdsImageProps) => renderImage(image, props) : undefined),
    [image, renderImage],
  );

  return (
    <Grid key={image.id} item>
      <IdsImageButton
        renderImage={_renderImage}
        imageProps={{
          ...imageProps,
          src: getImageUrl(image),
          width: imageSize,
          height: imageSize,
        }}
        onClick={handleImageClick}
        onDoubleClick={handleImageDoubleClick}
        disabled={!onClick && !onDoubleClick}
      />
    </Grid>
  );
};

export interface IIdsImageListProps
  extends Pick<IIdsImageListItemProps, 'renderImage' | 'imageProps' | 'getImageUrl' | 'imageSize'> {
  images: IdsImageListImage[];
  getMediaType: (image: IdsImageListImage) => MediaType;
  onImageClick: IIdsImageListItemProps['onClick'];
  style?: React.CSSProperties;
}

const IdsImageList: React.FC<IIdsImageListProps> = ({
  images,
  getImageUrl = img => img.url.square,
  getMediaType,
  imageSize = 100,
  onImageClick,
  renderImage,
  imageProps,
  style = {},
  ...rest
}) => {
  const renderItem = useCallback(
    item => {
      const TypeIcon = getMediaType ? MEDIA_TYPE_TO_DEF[getMediaType(item)]?.icon || null : null;
      return (
        <IdsImageListItem
          image={item}
          getImageUrl={getImageUrl}
          imageSize={imageSize}
          onClick={onImageClick}
          renderImage={renderImage}
          imageProps={{
            ...imageProps,
            placeholderIcon: TypeIcon ? <TypeIcon /> : undefined,
            renderBottomLeftIcon: TypeIcon ? props => <TypeIcon {...props} /> : undefined,
          }}
        />
      );
    },
    [getImageUrl, getMediaType, imageProps, imageSize, onImageClick, renderImage],
  );

  return (
    <Grid item container spacing={2} direction='row' style={{ flex: 1, ...style }} {...rest}>
      <IdsDynamicGrid items={images} renderItem={renderItem} />
    </Grid>
  );
};

export default React.memo(IdsImageList);
