import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import IdsCheckbox from '../../../../ids-inputs/IdsCheckbox';
import getAbbreviatedNumber from '../../../../../utils/getAbbreviatedNumber';

import useFilterContext from '../../../../../hooks/useFilterContext';

import styles from './MediaMarkerCheckbox.module.css';

const MediaMarkerCheckbox = ({ mediaTypeDef, total, color, loading, loadedCount, ...rest }) => {
  const { disableTypeData, enableTypeData, typeMetadata } = useFilterContext();
  const totalStr = getAbbreviatedNumber(total);
  const _loading = loading && loadedCount < total;
  const _label = `${mediaTypeDef.label} (${totalStr})`;
  const _color = _loading ? 'action.disabled' : color;
  const checked = typeMetadata[mediaTypeDef.type].enabled;

  const Icon = mediaTypeDef.icon;
  const OutlinedIcon = mediaTypeDef.outlinedIcon;

  const toggleSelection = useCallback(
    e => {
      if (e.target.checked) {
        enableTypeData(mediaTypeDef.type);
      } else {
        disableTypeData(mediaTypeDef.type);
      }
    },
    [mediaTypeDef.type, enableTypeData, disableTypeData],
  );

  return (
    <IdsCheckbox
      label={checked ? <strong>{_label}</strong> : _label}
      disabled={_loading}
      onChange={toggleSelection}
      labelProps={{
        componentsProps: {
          typography: {
            variant: 'body2',
            color: 'text.secondary',
            className: styles.label,
          },
        },
      }}
      size='small'
      sx={{ color: _color, '&.Mui-checked': { color: _color } }}
      checked={checked}
      icon={<OutlinedIcon fontSize='medium' />}
      checkedIcon={<Icon fontSize='medium' />}
      {...rest}
    />
  );
};

MediaMarkerCheckbox.defaultProps = {
  color: 'primary',
  loadedCount: 0,
};

MediaMarkerCheckbox.propTypes = {
  mediaTypeDef: PropTypes.object.isRequired, // One of the constant MEDIA_TYPE definition objects
  total: PropTypes.number.isRequired,
  color: PropTypes.string,
  loading: PropTypes.bool,
  loadedCount: PropTypes.number,
};

export default MediaMarkerCheckbox;
