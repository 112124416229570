import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import usePermissions from '../hooks/usePermissions';
import useKeycloak from '../hooks/useKeycloak';
import { EntityType } from '../constants/entities';
import { UserRolesMapItem } from '../constants/users';
import { PermissionsAction } from '../services/TenantService';

export interface IAuthGuardProps {
  permissionType?: PermissionsAction;
  entityType?: EntityType;
  roles?: UserRolesMapItem[];
  sessionRequired?: boolean;
  errorRedirectPath?: string;
}

const AuthGuard: React.FC<IAuthGuardProps> = ({
  permissionType,
  entityType,
  roles,
  sessionRequired,
  children,
  errorRedirectPath,
}) => {
  const { userHasPermission, userHasOneOfRoles } = usePermissions();

  const { keycloak, initialized, authenticated } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!initialized) return;

    if (sessionRequired && !authenticated && location.pathname !== '/') {
      keycloak.login();
      return;
    }

    // If permission and entityType or role are provided
    // validate user's authorization to access
    if (
      (permissionType && entityType && !userHasPermission(permissionType, entityType)) ||
      (roles && !userHasOneOfRoles(roles))
    ) {
      // Return to previous page (or back to expected page)
      navigate((errorRedirectPath ? errorRedirectPath : -1) as string);
      enqueueSnackbar('You do not have access to that page.', {
        variant: 'error',
      });
      return;
    }
  }, [
    location,
    keycloak,
    initialized,
    authenticated,
    sessionRequired,
    permissionType,
    entityType,
    enqueueSnackbar,
    navigate,
    userHasPermission,
    userHasOneOfRoles,
    roles,
    errorRedirectPath,
  ]);

  return (
    // Wrap in a fragment to ensure the return type is React.ReactElement (this matches the typings expected in the Route component where this is commonly used)
    <>{sessionRequired ? authenticated && children : children}</>
  );
};

export default AuthGuard;
