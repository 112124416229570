import React, { useEffect, useState } from 'react';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';

import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import { activeProjectState } from '../../../../atoms/projects';
import { useUserKeys } from '../../../../services/UsersService';

import UsersList from '../../../Users/UsersList';
import UserListItem, { UserInRoleListItemFrag } from '../../../Users/UserListItem';

const ProjectUsersQuery = gql`
  query ProjectUsers($orgId: ID, $id: ID!, $tenantId: ID) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      users {
        edges {
          cursor
          node {
            ...UserInRoleListItemFrag
          }
        }
      }
    }
  }
  ${UserInRoleListItemFrag}
`;

const ProjectUsersTab = () => {
  const userKeys = useUserKeys();

  const activeProject = useRecoilValue(activeProjectState);
  const [users, setUsers] = useState([]);

  const { data, error, isLoading } = useOrgGraphQuery(userKeys.lists(), ProjectUsersQuery, {
    id: activeProject.id,
  });

  useEffect(() => {
    if (data) {
      setUsers(data.project.users.edges);
    }
  }, [data]);

  return (
    <UsersList
      userItems={users}
      renderUserItem={userItem => (
        <UserListItem key={userItem.node.user.id} userItem={userItem} disableMenu />
      )}
      filterMode='select'
      noItemsMessage='No users'
      noItemsForFilterMessage='No users match your search'
      loading={isLoading}
      error={error && 'Users could not be loaded'}
    />
  );
};

export default ProjectUsersTab;
