import React, { useCallback, useMemo } from 'react';
import { Box, Button, Paper, SvgIcon } from '@mui/material';
import { capitalCase } from 'change-case';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import Error from '../../../../views/Error';
import IdsFormSelectField from '../../../../components/ids-forms/IdsFormSelectField';
import { USER_ROLES, UserRole, UserRoleTypeUppercase } from '../../../../constants/users';
import LoadingScreen from '../../../../components/LoadingScreen';
import { useGetOrganizationProjects } from '../../../../services/ProjectService';
import { activeOrganizationState } from '../../../../atoms/organizations';
import IdsFormTransferListField from '../../../../components/ids-forms/IdsFormTransferListField';
import { DeleteIcon } from '../../../../theme/icons';
import useConfirmation from '../../../../hooks/useConfirmation';
import { activeUserState } from '../../../../atoms/users';

/** User roles that require the user to be assigned to individual projects. */
const PROJECT_LEVEL_ROLES = [
  UserRole.org_team,
  UserRole.contractor_manager,
  UserRole.contractor_team,
  UserRole.client_manager,
  UserRole.client_team,
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICardProps {}

export const ROLE_OPTIONS = Object.values(USER_ROLES)
  .filter(
    role =>
      role.canAssign &&
      role.id !== null &&
      ![USER_ROLES.IDS_ADMIN.name, USER_ROLES.IDS_TEAM.name].includes(role.name),
  )
  .map(role => ({
    label: capitalCase(role.name),
    value: role.name,
  }));

const Card: React.FC<ICardProps> = () => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeUser = useRecoilValue(activeUserState);
  const { requestConfirmation } = useConfirmation();
  const { values, setFieldValue, submitForm } = useFormikContext<any>();
  const { role } = values;

  const {
    data: projects,
    isLoading: isLoadingProjects,
    error: errorProjects,
  } = useGetOrganizationProjects(activeOrg.id);

  const legacyRoleSelected = useMemo(
    () => role && !USER_ROLES[role.toUpperCase() as UserRoleTypeUppercase].canAssign,
    [role],
  );

  const options = useMemo(() => {
    if (!role || !legacyRoleSelected) {
      return ROLE_OPTIONS;
    }

    // Current role cannot be assigned, need to manually list it to show the current role
    return [{ label: capitalCase(role), value: role }, ...ROLE_OPTIONS];
  }, [role, legacyRoleSelected]);

  const removeFromOrg = useCallback(() => {
    const name = activeUser ? `${activeUser.firstName} ${activeUser.lastName}` : 'User';

    requestConfirmation({
      title: 'Are you sure you want to revoke access to this organization?',
      description: `${name} will no longer have access to this organization.`,
      confirmButtonProps: {
        color: 'error',
      },
      onConfirm: () => {
        setFieldValue('role', ''); // clear the role
        submitForm();
      },
      confirmButtonLabel: 'Revoke',
    });
  }, [setFieldValue, submitForm, requestConfirmation, activeUser]);

  return (
    <Box component={Paper} p={2}>
      {/* Fields for this card */}
      <Box>
        <Box textAlign='right' mb={1}>
          <Button color='error' onClick={removeFromOrg}>
            <SvgIcon fontSize='small' style={{ marginRight: '8px' }}>
              <DeleteIcon />
            </SvgIcon>
            Revoke access
          </Button>
        </Box>

        {/* Role */}
        <IdsFormSelectField
          name='role'
          label='Role'
          required
          options={options}
          error={legacyRoleSelected}
          helperText={legacyRoleSelected ? 'Legacy role selected, editing not supported' : null}
          sx={{ paddingBottom: 2 }}
        />

        {/*
          Render projects field if organization was selected.
          Some roles do not need this field.
        */}
        {PROJECT_LEVEL_ROLES.includes(role) && (
          <Box mt={1}>
            {errorProjects && <Error />}
            {isLoadingProjects && <LoadingScreen />}
            {!errorProjects && !isLoadingProjects && (
              <IdsFormTransferListField
                optionsLabel='Search unselected projects'
                valuesLabel='Search selected projects'
                name='projects'
                options={projects}
                getPrimaryLabel={o => o.node.name}
                getSecondaryLabel={o => o.node.location.name}
                getOptionValue={o => o.node.id}
                disabled={legacyRoleSelected}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Card;
