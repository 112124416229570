import { Button, SvgIcon, ButtonProps } from '@mui/material';
import React from 'react';

import { AddIcon } from '../../../../theme/icons';

export interface IActionsProps {
  onClick: ButtonProps['onClick'];
}

const Actions: React.FC<IActionsProps> = ({ onClick }) => {
  return (
    <Button color='secondary' variant='contained' onClick={onClick}>
      <SvgIcon fontSize='small' sx={{ mr: 1 }}>
        <AddIcon />
      </SvgIcon>
      New Organization
    </Button>
  );
};

export default Actions;
