import { useMemo } from 'react';

import { IFilterTarget } from '../../context/FilterContext';
import useFilterContext from '../useFilterContext';

/** Determine active filter targets based on which supported target types are active. */
const useActiveFilterTargets = (supportedFilterTargets: IFilterTarget[]) => {
  const { typeMetadata } = useFilterContext();
  const activeFilterTargets = useMemo<IFilterTarget[]>(
    () => supportedFilterTargets.filter(t => Object.keys(typeMetadata).includes(t.type)),
    [supportedFilterTargets, typeMetadata],
  );
  return activeFilterTargets;
};

export default useActiveFilterTargets;
