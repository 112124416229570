import React from 'react';
import PropTypes from 'prop-types';
import { Chip, CircularProgress, Typography } from '@mui/material';

import getAbbreviatedNumber from '../../utils/getAbbreviatedNumber';

import styles from './TotalChip.module.css';

const TotalChip = ({ active, total, itemType, pluralize, loading, ...rest }) => {
  const totalStr = getAbbreviatedNumber(total);
  const label = loading
    ? pluralize
      ? `${itemType}s`
      : itemType
    : `${totalStr} ${pluralize ? `${itemType}${total !== 1 ? 's' : ''}` : itemType}`;
  return (
    <Chip
      size='small'
      label={
        <Typography variant='overline' className={styles['totalChip-label']}>
          {label}
        </Typography>
      }
      variant={active ? 'filled' : 'outlined'}
      icon={loading ? <CircularProgress size='0.8rem' /> : null}
      {...rest}
    />
  );
};

TotalChip.defaultProps = {
  pluralize: true,
};

TotalChip.propTypes = {
  total: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  pluralize: PropTypes.bool,
};

export default TotalChip;
