import React from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import NestedOrgViewHeader from '../../../../components/NestedOrgViewHeader';
import { activeProgramState } from '../../../../atoms/programs';
import { activeOrganizationState } from '../../../../atoms/organizations';
import Page from '../../../../components/Page';

import { getOrgProgramRoute, getOrgProgramsRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';

import EditProgramForm from './EditProgramForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProgramEditProps {}

const ProgramEdit: React.FC<IProgramEditProps> = () => {
  const navigate = useNavigate();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);

  if (!activeProgram) {
    return null;
  }

  return (
    <Page title='Edit Program'>
      <Container maxWidth={false}>
        <NestedOrgViewHeader
          breadcrumbs={[
            {
              label: 'Programs',
              route: getOrgProgramsRoute({
                subdomain: activeTenant?.subdomain,
                orgId: activeOrg.id,
              }),
            },
            {
              label: activeProgram.name,
              route: getOrgProgramRoute({
                subdomain: activeTenant?.subdomain,
                orgId: activeOrg.id,
                programId: activeProgram.id,
              }),
            },
          ]}
          currentCrumb='Edit'
        />

        <Box p={0} component={Paper}>
          <Box p={1}>
            <Typography variant='h4'> Edit {activeProgram.name}</Typography>
          </Box>
          <EditProgramForm
            onCancel={() =>
              navigate(
                getOrgProgramRoute({
                  subdomain: activeTenant?.subdomain,
                  orgId: activeOrg.id,
                  programId: activeProgram.id,
                }),
              )
            }
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ProgramEdit;
