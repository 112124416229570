import React from 'react';
import { SvgIcon } from '@mui/material';

const PanoramaOutlinedIcon = props => (
  <SvgIcon {...props}>
    <path d='m9.36,19c-.57,0-1.03-.46-1.03-1.03v-1.71c-4.45-.8-7.4-2.97-7.4-5.47,0-3.25,4.86-5.79,11.07-5.79s11.07,2.54,11.07,5.79c0,2.07-2,3.92-5.34,4.96-.97.3-2-.48-2-1.49,0-.68.43-1.27,1.07-1.47,2.21-.69,3.16-1.66,3.16-2,0-.67-2.82-2.67-7.96-2.67s-7.96,2.01-7.96,2.67c0,.4,1.32,1.63,4.29,2.28v-1c0-.57.46-1.03,1.03-1.03.27,0,.52.11.72.3l2.95,2.95c.41.41.41,1.05,0,1.46l-2.95,2.95c-.19.2-.45.31-.72.31Zm2.64-13c-5.46,0-10.07,2.19-10.07,4.79,0,2.03,2.87,3.9,6.98,4.55l.42.07v2.56l3-2.93-2.95-2.99-.05,2.23-.59-.1c-3.25-.58-5.7-2.03-5.7-3.38,0-1.5,3.49-3.67,8.96-3.67s8.96,2.17,8.96,3.67c0,1.05-1.55,2.23-3.86,2.95-.22.07-.37.28-.37.52,0,.4.39.64.7.54,2.86-.89,4.64-2.43,4.64-4.01,0-2.59-4.61-4.79-10.07-4.79Z' />
  </SvgIcon>
);

export default PanoramaOutlinedIcon;
