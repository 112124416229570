import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemButton,
  ListItemText,
  SvgIcon,
  ListItem,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import IdsIFrameDialog from '../../../../IdsIFrameDialog';
import CircularProgress from '../../../../progress/CircularProgress';

import { getAndDownload, isPdfUrl, downloadFileFromUrl } from '../../../../../utils/fileDownload';
import { isTextTruncated } from '../../../../../utils/helpers';

import { ExportIcon, DocumentIcon } from '../../../../../theme/icons';

import styles from './MapDocumentListItem.module.css';

const primaryTypographyProps = { variant: 'body2', noWrap: true };

// documentItem
/*
  {
    node: {
      documentVersions: [{ filename: string, url: string }],
      id: string,
      name?: string,
    }
  }
*/
const MapDocumentListItem = ({ documentItem, uniqId, ...rest }) => {
  const { name } = documentItem?.node || {};
  const [isDownloading, setIsDownloading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const latestVersion = useMemo(() => {
    if (!documentItem?.node.documentVersions?.length) {
      return null;
    }

    return documentItem.node.documentVersions[documentItem.node.documentVersions.length - 1];
  }, [documentItem]);

  const isPdf = useMemo(() => {
    if (!latestVersion) {
      return false;
    }

    return latestVersion?.url && isPdfUrl(latestVersion.url);
  }, [latestVersion]);

  /**
   * Using document name.
   *
   * If no document name - using latest document version filename.
   */
  const documentName = useMemo(() => {
    return name || latestVersion?.filename;
  }, [latestVersion, name]);

  const downloadDocument = useCallback(
    async event => {
      event.stopPropagation();
      setIsDownloading(true);

      if (!latestVersion || !latestVersion.filename || !latestVersion.url) {
        enqueueSnackbar('Cannot download this file', { variant: 'warning' });

        return;
      }

      try {
        if (isPdf) {
          await getAndDownload(latestVersion.url, latestVersion.filename);
        } else {
          downloadFileFromUrl(latestVersion.filename, latestVersion.url);
        }
      } catch (_) {
        enqueueSnackbar('Failed to download this file', { variant: 'error' });
      }

      setIsDownloading(false);
    },
    [latestVersion, enqueueSnackbar, isPdf],
  );

  const viewDocument = useCallback(
    event => {
      event.stopPropagation();

      if (isPdf) {
        setIsDialogOpen(true);
      } else {
        enqueueSnackbar('Cannot display non PDF file', { variant: 'warning' });
      }
    },
    [enqueueSnackbar, isPdf],
  );

  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const [nameTruncated, setNameTruncated] = useState(false);
  const handleNameTruncated = useCallback(e => {
    setNameTruncated(isTextTruncated(e));
  }, []);

  if (!documentItem?.node) {
    return null;
  }

  return (
    <ListItem disablePadding {...rest}>
      <ListItemButton disabled={!latestVersion} onClick={viewDocument}>
        <SvgIcon className={styles.documentIcon}>
          <DocumentIcon />
        </SvgIcon>
        <Tooltip title={documentName} placement='top-start' disableHoverListener={!nameTruncated}>
          <ListItemText
            primary={
              <Typography ref={handleNameTruncated} {...primaryTypographyProps}>
                {documentName}
              </Typography>
            }
          />
        </Tooltip>
        <IconButton
          fontSize='small'
          className={styles.downloadIcon}
          onClick={downloadDocument}
          disabled={isDownloading}
        >
          {isDownloading ? <CircularProgress /> : <ExportIcon />}
        </IconButton>
      </ListItemButton>

      {/* View document */}
      <IdsIFrameDialog
        dialogKey={uniqId}
        title={latestVersion?.filename}
        src={latestVersion?.url}
        open={isDialogOpen}
        loading={!latestVersion}
        onClose={onCloseDialog}
      />
    </ListItem>
  );
};

MapDocumentListItem.propTypes = {
  documentItem: PropTypes.object.isRequired,
  /**
   * It is important to provide IdsIFrameDialog component with some uniq id value.
   *
   * Otherwise, duplicated IDs (e.g. document ID, because the same document may belong to different projects)
   * cause an infinite loop of IdsIFrameDialog opening and closing.
   */
  uniqId: PropTypes.string.isRequired,
};

export default MapDocumentListItem;
