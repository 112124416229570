import * as Yup from 'yup';

export const editOverlayValidationSchema = (maxPosition: number) => {
  const editOverlayValidation = {
    name: Yup.string().required('Name is required'),
    position: Yup.number()
      .required('Position is required')
      .min(0, 'Must be positive')
      .max(maxPosition, `Max position is ${maxPosition}`),
  };
  return Yup.object().shape(editOverlayValidation);
};
