import {
  Box,
  Button,
  ButtonTypeMap,
  FormControl,
  FormControlPropsSizeOverrides,
  FormHelperText,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ChangeEvent, useMemo, useRef } from 'react';
import { HTMLAttributes, useCallback } from 'react';

import { CloseIcon } from '../../../theme/icons';

import styles from './styles.module.css';

// eslint-disable-next-line @typescript-eslint/ban-types
type MuiButtonProps = ButtonTypeMap<{}, 'button'>['props'];

export interface IIdsFileSelectProps extends MuiButtonProps {
  name: string;
  accept: string;
  multiple?: boolean;
  value: FileList | null;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  label: string;
  size?: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides>;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  onChange: (data: FileList | null) => any;
}

export const IdsFileSelect: React.FC<IIdsFileSelectProps> = ({
  name,
  accept,
  multiple,
  value,
  onChange,
  inputProps,
  required = false,
  disabled = false,
  size = 'small',
  label,
  error,
  ...rest
}) => {
  const labelId = useMemo(() => `${label}-select-field-label`, [label]);

  // Necessary to perform cleanups
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.files) {
        onChange(event.currentTarget.files);
      }
    },
    [onChange],
  );

  const handleClickReset = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onChange(null);
  }, [onChange]);

  const valueStringified = useMemo(() => {
    if (value) {
      return Array.from(value)
        .map(it => it.name)
        .join(', ');
    }
    return null;
  }, [value]);

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={Boolean(error)}
      size={size}
      fullWidth
    >
      <InputLabel id={labelId} className={styles['inputLabel']}>
        {label}
      </InputLabel>
      <Box className={styles['fileFieldContainer']}>
        <Button variant='contained' component='label' {...rest}>
          Choose file
          <input
            ref={fileInputRef}
            hidden
            name={name}
            accept={accept}
            type='file'
            multiple={multiple}
            onChange={handleChange}
            {...inputProps}
          />
        </Button>
        {valueStringified && (
          <Box className={styles['valueContainer']}>
            <Typography variant='body1'>{valueStringified}</Typography>
            <IconButton onClick={handleClickReset} className={styles['closeIcon']}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
        )}
      </Box>
      <FormHelperText className={styles['helperTextError']}>{error}</FormHelperText>
    </FormControl>
  );
};

export default IdsFileSelect;
