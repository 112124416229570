import { useCallback, useMemo } from 'react';

import { buildProjectPhotoMarkerLayer } from '../../layers/media-markers';
import { buildHDPhotoMarkerLayer } from '../../layers/media-markers';
import { buildPanoramaMarkerLayer } from '../../layers/media-markers';
import { IMarkerModifiers } from '../../layers/media-markers/types';

import useMetadataItems from '../../../../hooks/useMetadataItems';

import {
  IMetadataType,
  IMetadataTypeValue,
  MediaType,
  RoutePointType,
} from '../../../../constants/media';

import IDS_COLORS from '../../../../theme/ids-colors';

/**
 * @TODO: get rid of any types.
 */
const useMarkerTypeLayerBuilders = (metadataTypes: IMetadataType[]) => {
  const { allLevels } = useMetadataItems(metadataTypes);

  const buildPanoramaMarkerLayerWithLevels = useCallback(
    (
      data: any[],
      iconClusterProps?: Record<string, any> | null,
      idsLayerProps?: Record<string, any> | null,
      modifiers?: IMarkerModifiers,
    ) => {
      const levelIds = allLevels.map((l: IMetadataTypeValue) => l.id);
      return buildPanoramaMarkerLayer(data, levelIds, iconClusterProps, idsLayerProps, modifiers);
    },
    [allLevels],
  );

  const buildDslrPanoramaMarkerLayerWithLevels = useCallback(
    (
      data: any[],
      iconClusterProps?: Record<string, any> | null,
      idsLayerProps?: Record<string, any> | null,
    ) => {
      const modifiers = {
        getBackgroundColor: () => IDS_COLORS.marker.dslr_360,
      };

      return buildPanoramaMarkerLayerWithLevels(data, iconClusterProps, idsLayerProps, modifiers);
    },
    [buildPanoramaMarkerLayerWithLevels],
  );

  return useMemo(
    () => ({
      [MediaType.ProjectPhoto]: buildProjectPhotoMarkerLayer,
      [MediaType.HDPhoto]: buildHDPhotoMarkerLayer,
      [MediaType.PanoramicPhoto]: buildPanoramaMarkerLayerWithLevels,
      [RoutePointType.ProjectPhoto]: buildProjectPhotoMarkerLayer,
      [RoutePointType.DslrHdPhoto]: buildHDPhotoMarkerLayer,
      [RoutePointType.Panorama]: buildPanoramaMarkerLayerWithLevels,
      [RoutePointType.DslrPanorama]: buildDslrPanoramaMarkerLayerWithLevels,
    }),
    [buildPanoramaMarkerLayerWithLevels, buildDslrPanoramaMarkerLayerWithLevels],
  );
};

export default useMarkerTypeLayerBuilders;
