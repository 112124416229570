import React, { useCallback } from 'react';

import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useLocationKeys } from '../../services/LocationService';
import useGraphQuery from '../../hooks/useGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';

import Page from '../../components/Page';
import CoreMap, { CoreMapFrag } from '../../components/mapping/CoreMap';
import useCursorPaginatedQuery from '../../hooks/useCursorPaginatedQuery';
import { clusterState } from '../../atoms/immersiveViewer';
import { getOrgMapLocationRoute, getOrgMapRoute } from '../../utils/routes';
import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../theme/styles';
import { activeTenantState } from '../../atoms/tenants';

const LocationListQuery = gql`
  query OrgMapLocations($orgId: ID!, $take: Int, $after: String) {
    locations(organizationId: $orgId, take: $take, after: $after) {
      edges {
        cursor
        node {
          id
          ...CoreMapFrag
        }
      }
    }
  }
  ${CoreMapFrag}
`;

const Map = () => {
  const locationKeys = useLocationKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);
  const [cluster, setClusterState] = useRecoilState(clusterState);

  const useLocations = (take, after) =>
    useGraphQuery(
      [...locationKeys.orgList(organization.id), 'map-markers', `take-${take}`, `after-${after}`],
      LocationListQuery,
      { orgId: organization.id, take, after },
    );

  const { data: locations, isLoading } = useCursorPaginatedQuery({
    useQuery: useLocations,
    defaultTake: 300,
    selectConnection: d => d.locations,
  });

  const getMapRouteFn = useCallback(
    () =>
      getOrgMapRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
      }),
    [organization, activeTenant],
  );
  const getMapLocationRouteFn = useCallback(
    ({ locationId }) =>
      getOrgMapLocationRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        locationId,
      }),
    [organization, activeTenant],
  );

  return (
    <Page title='Map'>
      <CoreMap
        loading={isLoading}
        locations={locations}
        containerProps={{
          height: FULL_HEIGHT_WITHOUT_TOP_BAR,
        }}
        cluster={cluster}
        setClusterState={setClusterState}
        getMapRoute={getMapRouteFn}
        getMapLocationRoute={getMapLocationRouteFn}
      />
    </Page>
  );
};

export default Map;
