import React from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Container } from '@mui/material';

import { ILocationListItemEdge, useGetOrganizationLocations } from '../../services/LocationService';
import { activeOrganizationState } from '../../atoms/organizations';

import Page from '../../components/Page';
import IdsSearchableList from '../../components/ids-lists/IdsSearchableList';

import styles from '../../theme/styles';

import LocationListItem from './LocationListItem';
import LocationListHeader from './LocationListHeader';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILocationsProps {}

export const searchLocationItem = (locationItem: ILocationListItemEdge, query: string) => {
  const searchProps = [
    locationItem.node.name,
    locationItem.node.address?.city,
    locationItem.node.address?.state,
  ];

  return !!searchProps.filter(prop => prop?.toLowerCase().includes(query.toLowerCase())).length;
};

export const sortLocationItems = (a: ILocationListItemEdge, b: ILocationListItemEdge) => {
  return a.node.name.localeCompare(b.node.name); // alphabetical order
};

const renderItem = (locationItem: ILocationListItemEdge) => {
  return <LocationListItem key={locationItem.node.id} locationItem={locationItem} />;
};

const Locations: React.FC<ILocationsProps> = () => {
  const organization = useRecoilValue(activeOrganizationState);
  const { data, isLoading, error } = useGetOrganizationLocations(organization.id);

  return (
    <Page title='Locations'>
      <Box sx={styles.rootPage}>
        <Container maxWidth={false}>
          <LocationListHeader />
          <IdsSearchableList
            items={data}
            renderItem={renderItem}
            searchPlaceholder={'Search locations'}
            searchItem={searchLocationItem}
            sortItems={sortLocationItems}
            noItemsMessage='No locations'
            noItemsForFilterMessage='No locations match your search'
            loading={isLoading}
            error={error && 'Locations could not be loaded'}
            header={undefined}
            filters={undefined}
            filterMode={undefined}
            actions={undefined}
          />
        </Container>
      </Box>
    </Page>
  );
};

export default Locations;
