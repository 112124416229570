import React from 'react';
import { Chip } from '@mui/material';
import { capitalCase } from 'change-case';

export interface IActivationStateChipProps {
  activationState: string;
}

const ActivationStateChip: React.FC<IActivationStateChipProps> = ({ activationState }) => {
  return (
    <Chip
      color={activationState === 'active' ? 'success' : 'warning'}
      variant='outlined'
      size='small'
      label={capitalCase(activationState)}
    />
  );
};

export default ActivationStateChip;
