import { useContext } from 'react';

import KeycloakContext from '../context/KeycloakContext';

export default function useKeycloak() {
  const context = useContext(KeycloakContext);

  if (!context) {
    throw new Error('useKeycloak must be used within a KeycloakProvider component');
  }

  return context;
}
