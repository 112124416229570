import React, { useMemo } from 'react';
import { Button, Tooltip } from '@mui/material';

import {
  CheckBoxIcon,
  CheckBoxOutlinedIcon,
  IndeterminateCheckBoxIcon,
} from '../../../../theme/icons';
import useCompletedFilter, {
  CompletedFilterMode,
} from '../../../../hooks/filtering/useCompletedFilter';

import MapOverlay from '../../MapOverlay';

import styles from './CompletedFilterControl.module.css';

const MapOverlayNoType = MapOverlay as any;

const sx = {
  control: {
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: 'background.dark',
    },
  },
  completedIcon: {
    color: 'ids.marker.routePointCompleted',
  },
  notCompletedIcon: {
    color: 'ids.marker.routePointNotCompleted',
  },
};

const CompletedActiveIcon = () => <CheckBoxIcon fontSize='small' sx={sx.completedIcon} />;
const NotCompletedActiveIcon = () => (
  <IndeterminateCheckBoxIcon fontSize='small' sx={sx.notCompletedIcon} />
);
const CompletedInactiveIcon = () => <CheckBoxOutlinedIcon fontSize='small' />;

export interface ICompletedFilterControlProps {
  disabled?: boolean;
}

const CompletedFilterControl: React.FC<ICompletedFilterControlProps> = ({ disabled }) => {
  const { toggleCompletedFilter, completedFilterMode } = useCompletedFilter(true);

  const filterToggle = useMemo(() => {
    const title =
      completedFilterMode === CompletedFilterMode.NotCompleted
        ? 'Disable completed filter'
        : completedFilterMode === CompletedFilterMode.Completed
        ? 'Filter for non-completed items'
        : 'Filter for completed items';

    return (
      <Tooltip placement='left' title={title}>
        <span>
          <Button
            disabled={disabled}
            onClick={toggleCompletedFilter}
            size='small'
            className={styles.completedFilterBtn}
            sx={sx.control}
          >
            {completedFilterMode === CompletedFilterMode.Completed ? (
              <CompletedActiveIcon />
            ) : completedFilterMode === CompletedFilterMode.NotCompleted ? (
              <NotCompletedActiveIcon />
            ) : (
              <CompletedInactiveIcon />
            )}
          </Button>
        </span>
      </Tooltip>
    );
  }, [disabled, completedFilterMode, toggleCompletedFilter]);

  return <MapOverlayNoType>{filterToggle}</MapOverlayNoType>;
};

export default CompletedFilterControl;
