import React, { useCallback, useMemo } from 'react';
import { Card, Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import LoadingScreen from '../../../../components/LoadingScreen';
import IdsTabsRenderer from '../../../../components/IdsTabsRenderer';
import ErrorPage from '../../../Error';
import globalSx from '../../../../theme/globalSx';

import OrganizationTab from '../../../../features/Users/OrganizationTab';
import DetailsTab from '../../../../features/Users/DetailsTab';
import { IFormValues } from '../../../../features/Users/DetailsTab/UserDetailsCard/types';
import {
  useGetOrgUserDetails,
  IUpdateUserDetailsMutationPayload,
  useUpdateUserDetails,
  useSetUserActivationState,
} from '../../../../services/UsersService';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { getOrgUserEditRoute, getOrgUserRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';
import { activeUserState } from '../../../../atoms/users';
import usePermissions from '../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../constants/users';

export interface IUserDetailsProps {
  edit?: boolean;
}

const UserDetails: React.FC<IUserDetailsProps> = ({ edit = false }) => {
  const { userHasOneOfRoles } = usePermissions();
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeUser = useRecoilValue(activeUserState);
  const navigate = useNavigate();
  const { mutateAsync: updateUserDetailsMutation } = useUpdateUserDetails();
  const { mutateAsync: setUserActivationStateMutation } = useSetUserActivationState(activeUser!.id);

  const {
    data: userInOrg,
    isLoading: isLoadingUserInOrg,
    error: errorUserInOrg,
  } = useGetOrgUserDetails(activeOrg!.id, activeUser!.id);

  const exitEditMode = useCallback(
    () =>
      navigate(
        getOrgUserRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          userId: activeUser?.id,
        }),
      ),
    [navigate, activeTenant, activeOrg, activeUser],
  );

  const onEditClick = useCallback(
    () =>
      navigate(
        getOrgUserEditRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          userId: activeUser?.id,
        }),
      ),
    [navigate, activeTenant, activeOrg, activeUser],
  );

  const handleEditSubmit = useCallback(
    async ({
      firstName,
      lastName,
      title,
      phone,
      multiShotEnable,
      activationState,
    }: IFormValues) => {
      const payload: IUpdateUserDetailsMutationPayload = {
        userId: activeUser!.id,
        firstName,
        lastName,
        title,
        phone,
        multiShotEnable,
      };

      const userDetailResult = await updateUserDetailsMutation(payload);

      if (userDetailResult.updateUserDetails.errors?.length) {
        throw new Error('User could not be updated');
      }

      if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN])) {
        // only ids admins can change a user's activation state
        // don't need to check for an error here, rest api call used, an error will be thrown if it fails
        await setUserActivationStateMutation({
          userId: activeUser!.id,
          activationState: activationState ? 'active' : 'inactive',
        });
      }

      exitEditMode();
    },
    [
      updateUserDetailsMutation,
      setUserActivationStateMutation,
      userHasOneOfRoles,
      activeUser,
      exitEditMode,
    ],
  );

  const tabs = useMemo(() => {
    if (!userInOrg?.user) {
      return [];
    }
    const tabs = [
      {
        key: 'details',
        label: 'Details',
        content: (
          <DetailsTab
            user={userInOrg.user}
            edit={edit}
            onCancel={exitEditMode}
            onEditClick={onEditClick}
            onEditSubmit={handleEditSubmit}
          />
        ),
      },
    ];

    // Viewing/Editing org specific user info is only for admins
    if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN, USER_ROLES.ORG_ADMIN])) {
      tabs.push({
        key: 'organization',
        label: 'Organization',
        content: <OrganizationTab user={userInOrg.user} />,
      });
    }
    return tabs;
  }, [userInOrg, edit, exitEditMode, onEditClick, handleEditSubmit, userHasOneOfRoles]);

  if (isLoadingUserInOrg) {
    return <LoadingScreen />;
  }

  if (errorUserInOrg || !activeUser) {
    return <ErrorPage />;
  }

  return (
    <Card sx={globalSx.pageContent}>
      <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
    </Card>
  );
};

export default UserDetails;
