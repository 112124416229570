import IdsDialog, { IIdsDialogProps } from '../../../../../components/IdsDialog';
import { IMediaMetadata } from '../../../../../constants/media';

import EditOverlayForm from './EditOverlayForm';

export type Overlay = {
  id: string;
  name: string;
  position: number;
  default?: boolean;
  metadata: IMediaMetadata[];
  color?: string;
};

interface IEditOverlayDialogProps extends Pick<IIdsDialogProps, 'open' | 'onOpen' | 'onClose'> {
  overlay: Overlay;
  maxPositions: number;
}

const EditOverlayDialog: React.FC<IEditOverlayDialogProps> = ({
  open,
  onOpen,
  onClose,
  overlay,
  maxPositions,
}) => {
  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='Edit Overlay'
      maxWidth='lg'
      noSearchParam
    >
      <EditOverlayForm
        onSubmitSuccess={onClose}
        onCancel={onClose}
        overlay={overlay}
        maxPositions={maxPositions}
      />
    </IdsDialog>
  );
};

EditOverlayDialog.defaultProps = {
  open: false,
};

export default EditOverlayDialog;
