import React from 'react';

import { CircularProgress, Grid, Chip, Typography } from '@mui/material';

import { MEDIA_TYPES, MediaType } from '../../../../../constants/media';
import MediaMarkerCheckbox from '../MediaMarkerCheckbox';
import LinearProgressWithLabel from '../../../../progress/LinearProgressWithLabel';
import styles from '../LocationMapInfo.module.css';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';

const MediaPane = ({ loading, onViewAllImagery }) => {
  const {
    totalProjectPhotos,
    totalPanoramas,
    totalHdPhotos,
    totalMedia,
    totalPointsOfInterest,
    totalAreasOfInterest,
    totalAssets,
    projectPhotosLoaded,
    hdPhotosLoaded,
    panoramasLoaded,
    videosLoaded,
    threeDModelsLoaded,
    threeDVRsLoaded,
    pointsOfInterestLoaded,
    areasOfInterestLoaded,
    assetsLoaded,
  } = useLocationMapContext();

  const mediaLoaded =
    projectPhotosLoaded +
    hdPhotosLoaded +
    panoramasLoaded +
    videosLoaded +
    threeDModelsLoaded +
    threeDVRsLoaded +
    pointsOfInterestLoaded +
    areasOfInterestLoaded +
    assetsLoaded;
  const loadPercentage =
    (mediaLoaded / (totalMedia + totalPointsOfInterest + totalAreasOfInterest + totalAssets)) * 100;

  return (
    <Grid container>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        columnSpacing={4}
        flexDirection='row'
        wrap='nowrap'
        className={styles.mediaHeader}
      >
        <Grid item xs='auto' zeroMinWidth container direction='column'>
          <Grid item xs container direction='row' spacing={2}>
            <Grid item xs='auto' zeroMinWidth>
              <Typography variant='h4'>Media</Typography>
            </Grid>
            {loading && loadPercentage < 100 && (
              <Grid item xs>
                <LinearProgressWithLabel variant='determinate' value={loadPercentage} />
              </Grid>
            )}
          </Grid>
          <Grid item xs='auto' zeroMinWidth>
            <Typography variant='caption'>
              Click a media type icon to toggle markers on or off
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.HDPhoto]}
          total={totalHdPhotos}
          loadedCount={hdPhotosLoaded}
          color='ids.marker.photo_hd'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.ProjectPhoto]}
          total={totalProjectPhotos}
          loadedCount={projectPhotosLoaded}
          color='ids.marker.photo_simple'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.PanoramicPhoto]}
          total={totalPanoramas}
          loadedCount={panoramasLoaded}
          color='ids.marker.photo_360'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.Video]}
          total={videosLoaded}
          loadedCount={videosLoaded}
          color='ids.marker.video'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.ThreeDModel]}
          total={threeDModelsLoaded}
          loadedCount={threeDModelsLoaded}
          color='ids.marker.threeDModel'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.ThreeDVR]}
          total={threeDVRsLoaded}
          loadedCount={threeDVRsLoaded}
          color='ids.marker.threeDVR'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.PointOfInterest]}
          total={totalPointsOfInterest}
          loadedCount={pointsOfInterestLoaded}
          color='ids.marker.pointOfInterest'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.AreaOfInterest]}
          total={totalAreasOfInterest}
          loadedCount={areasOfInterestLoaded}
          color='ids.marker.areaOfInterest'
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaMarkerCheckbox
          mediaTypeDef={MEDIA_TYPES[MediaType.Asset]}
          total={totalAssets}
          loadedCount={assetsLoaded}
          color='ids.marker.asset'
          loading={loading}
        />
      </Grid>
      <Grid container item xs={12} justifyContent='flex-end'>
        <Chip
          size='small'
          label='View All Imagery'
          onClick={onViewAllImagery}
          className={styles.viewAllImageryBtn}
          color='secondary'
          disabled={loading}
          icon={loading ? <CircularProgress size='0.8rem' /> : null}
        />
      </Grid>
    </Grid>
  );
};

export default MediaPane;
