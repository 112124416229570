import React, { Fragment, useCallback } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  SvgIcon,
  Typography,
  Alert,
} from '@mui/material';

import ProjectAccordion from '../ProjectAccordion';
import { ExpandIcon } from '../../../../../theme/icons';
import useProjectFilter from '../../../../../hooks/filtering/useProjectFilter';

const ProjectPane = ({ projects, loading }) => {
  const { filterByProjects, selectedProjectIds } = useProjectFilter();

  const handleProjectFilter = useCallback(
    selectedProjects => {
      let selected = selectedProjects.map(id => projects.find(p => p.node.id === id).node.name);
      selected = selected.toString().replace(/,/g, ', '); // insert space after each comma

      filterByProjects(selectedProjects, selected);
    },
    [projects, filterByProjects],
  );

  const handleProjectChange = useCallback(
    (selected, projectId) => {
      let newSelectedProjects;
      if (selected) {
        // add project
        newSelectedProjects = selectedProjectIds ? [...selectedProjectIds, projectId] : [projectId];
      } else {
        // remove project
        newSelectedProjects = [...selectedProjectIds];
        newSelectedProjects.splice(
          selectedProjectIds.findIndex(p => p === projectId),
          1,
        );
      }

      handleProjectFilter(newSelectedProjects);
    },
    [selectedProjectIds, handleProjectFilter],
  );

  return (
    <>
      {projects?.length ? (
        <Accordion
          defaultExpanded={true}
          disableGutters={true}
          className='accordion-noBorder accordion-noBackground'
        >
          <AccordionSummary
            expandIcon={
              <SvgIcon fontSize='medium'>
                <ExpandIcon />
              </SvgIcon>
            }
            className='accordion-wide'
          >
            <Typography variant='h4'>Projects</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className='accordion-wide'>
            {projects.map((e, i) => (
              <Fragment key={`project-${e.node.id}`}>
                <ProjectAccordion
                  key={`project-accordion-${e.node.id}`}
                  selected={selectedProjectIds?.includes(e.node.id)}
                  loading={loading}
                  onChange={handleProjectChange}
                  project={e.node}
                />
                {i !== projects.length - 1 && <Divider key={`project-divider-${e.node.id}`} />}
              </Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Alert severity='info'>There are currently no projects at this location.</Alert>
      )}
    </>
  );
};

export default ProjectPane;
