import { gql } from 'graphql-request';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeTenantState } from '../../../../atoms/tenants';
import { activeProjectState } from '../../../../atoms/projects';
import { activeImageId } from '../../../../atoms/mediaViewer';
import TagsFilter from '../../../../components/ids-filters/TagsFilter';
import { useDefaultEventHandlers } from '../../../../components/ids-media-viewer/IdsMediaViewer/hooks';
import { MEDIA_VIEWER_MODES } from '../../../../constants/mediaViewer';
import useMetadataItems from '../../../../hooks/useMetadataItems';
import {
  DISPLAY_TYPES,
  NO_METADATA_HANDLERS,
} from '../../../../components/ids-filters/MediaMetadataFilter';
import LevelFilter from '../../../../components/ids-filters/LevelFilter';
import AreaFilter from '../../../../components/ids-filters/AreaFilter';
import TypeFilter from '../../../../components/ids-filters/TypeFilter';
import CategoryFilter from '../../../../components/ids-filters/CategoryFilter';
import FlagFilter from '../../../../components/ids-filters/FlagFilter';
import CustomFieldMetadataFilter from '../../../../components/ids-filters/CustomFieldMetadataFilter';
import useCustomFieldFilterVisibility from '../../../../hooks/filtering/useCustomFieldFilterVisibility';
import IdsMediaListView from '../../../../components/ids-lists/IdsMediaListView';
import FlaggableImage from '../../../../components/FlaggableImage';
import IdsMediaViewer, {
  MEDIA_VIEWER_WIDTHS,
  MEDIA_VIEWER_TABS,
} from '../../../../components/ids-media-viewer/IdsMediaViewer';
import {
  dateAscendingSorter,
  dateDescendingSorter,
  getMetadataTypeSorter,
  getCustomFieldMetadataTypeSorter,
} from '../../../../components/ids-lists/IdsMediaListView/commonSorters';

import { MediaType, MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';
import { FilterProvider } from '../../../../context/FilterContext';
import useFilterContext from '../../../../hooks/useFilterContext';
import { getDefaultTypeMetadata } from '../../../../constants/filtering';
import invalidateQueriesContainingKey from '../../../../utils/invalidateQueriesContainingKey';
import { useLocationImageBulkEdit } from '../../../../components/ids-lists/IdsMediaListView/commonBulkActions/useLocationImageBulkEdit';
import BulkLocationImageEditDialog from '../../../../components/ids-forms/BulkLocationImageEditDialog';

import styles from './ProjectMediaTab.module.css';
import Actions from './Actions';
import ProjectMediaTabFilterInitializers from './ProjectMediaTabFilterInitializers';
import useProjectPhotos, {
  PROJECT_MEDIA_TAB_PHOTOS_KEY,
  useProjectPhotosReps,
} from './useProjectPhotos';

const getMediaThumbnailUrl = m => m.thumbnail;
const getMediaType = m => m.type;
const getMediaId = m => m.node.id;

export const MediaTabFrag = gql`
  fragment MediaTabFrag on Location {
    metadataTypes {
      type
      values {
        id
        name
      }
    }
    assignableCustomFields {
      id
      label
      type
      values {
        id
        name
      }
      targets
    }
  }
`;

const ProjectMediaTab = ({ project }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);
  const [activeId, setActiveId] = useRecoilState(activeImageId);

  const [isReFetchingEnabled, setIsReFetchingEnabled] = useState(false);

  const [mediaViewerOpen, setMediaViewerOpen] = useState(false);

  const [activeSorterKey, setActiveSorterKey] = useState();
  const [imageSize, setImageSize] = useState();

  const { useTypeFilteredData } = useFilterContext();
  const filteredProjectPhotos = useTypeFilteredData(MediaType.ProjectPhoto);

  const projectLocation = useMemo(() => project?.location, [project]);

  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(
    projectLocation.metadataTypes,
  );

  const customFieldTypes = useMemo(
    () => projectLocation?.assignableCustomFields?.map(acf => acf.type) || [],
    [projectLocation?.assignableCustomFields],
  );
  const { handleVisibilityChange, isFilterVisible } =
    useCustomFieldFilterVisibility(customFieldTypes);

  const { bulkEditAction, bulkEditOpen, closeBulkEdit, bulkEditImageUrns, imageTypesBulkEditing } =
    useLocationImageBulkEdit();

  // DATA LOADING ===============================================
  const [mediaCount, setMediaCount] = useState(0);

  /**
   * It is important to invalidate the cache, because the cache contains
   * data without fields that are loaded by condition @skip! As a result,
   * when you go back to the media tab, an error occurs in the filter context.
   * This happens because the context processes data without required fields!
   */
  useEffect(() => {
    return () => {
      invalidateQueriesContainingKey([PROJECT_MEDIA_TAB_PHOTOS_KEY], {
        refetchActive: false,
      });
    };
  }, []);

  /**
   * After a new image is uploaded, it is important to refetch not only project photos reps,
   * but also metadata. If the app receives the newly uploaded photo without metadata field,
   * it crashes.
   */
  const { isLoading } = useProjectPhotos(activeProject.id, setMediaCount);
  useProjectPhotosReps(activeProject.id, isReFetchingEnabled);

  useEffect(() => {
    /**
     * Enable re-fetching after the initial data load.
     */
    if (isLoading === false && isReFetchingEnabled === false) {
      setIsReFetchingEnabled(true);
    }
  }, [isReFetchingEnabled, isLoading]);

  // BASE FILTER DATA INITIALIZATIONS =========================================

  const allFilteredMedia = useMemo(() => [...filteredProjectPhotos], [filteredProjectPhotos]);

  const sorters = useMemo(() => {
    const _sorters = [
      dateAscendingSorter,
      dateDescendingSorter,
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.Level], allLevels),
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.Area], allAreas),
      {
        ...getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType], allTypes),
        isDefault: true,
      },
      getMetadataTypeSorter(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory], allCategories),
      ...(projectLocation?.assignableCustomFields
        ?.filter(acf => isFilterVisible(acf.type))
        .map(acf => getCustomFieldMetadataTypeSorter(acf)) || []),
    ];
    return _sorters.map(s => {
      if (!activeSorterKey) return s; // Active sorter has not been set
      // Override default setting to maintain state when closed
      return { ...s, isDefault: s.key === activeSorterKey };
    });
  }, [
    activeSorterKey,
    allLevels,
    allAreas,
    allTypes,
    allCategories,
    projectLocation?.assignableCustomFields,
    isFilterVisible,
  ]);

  // Filters are rendered in the array order
  const filters = useMemo(
    () => [
      {
        // Flag filter has a predefined width, filterField style is not needed
        renderFilter: () => <FlagFilter resetOnUnmount={true} />,
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <LevelFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allLevels}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <AreaFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allAreas}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <TypeFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allTypes}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <CategoryFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allCategories}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      {
        renderFilter: () => (
          <TagsFilter
            // Match the order defined in location settings
            allSortedMetadataItems={allTags}
            noMetadataHandler={NO_METADATA_HANDLERS.DISABLE}
            className={styles.filterField}
            type={DISPLAY_TYPES.DROPDOWN}
          />
        ),
        filterExternally: true,
      },
      ...(projectLocation?.assignableCustomFields?.map(assignableCustomField => ({
        renderFilter: () => (
          <CustomFieldMetadataFilter
            assignableCustomField={assignableCustomField}
            onVisibilityChange={handleVisibilityChange}
            className={styles.filterField}
          />
        ),
        disableWrapper: !isFilterVisible(assignableCustomField.type),
        filterExternally: true,
      })) || []),
    ],
    [
      allLevels,
      allAreas,
      allTypes,
      allCategories,
      allTags,
      projectLocation?.assignableCustomFields,
      isFilterVisible,
      handleVisibilityChange,
    ],
  );

  const viewImage = useCallback(
    id => {
      setActiveId(id);
      setMediaViewerOpen(true);
    },
    [setActiveId],
  );

  const closeMediaViewer = useCallback(() => {
    setActiveId(null);
    setMediaViewerOpen(false);
  }, [setActiveId, setMediaViewerOpen]);

  const renderImage = useCallback(
    (image, props) => (
      <FlaggableImage flagged={image.node.flagged} autoScaleCornerIcons={true} {...props} />
    ),
    [],
  );

  const onImageClick = useCallback(img => viewImage(img.node.id), [viewImage]);

  const onUploadCompleted = useCallback(async () => {
    setIsReFetchingEnabled(false);

    await invalidateQueriesContainingKey([PROJECT_MEDIA_TAB_PHOTOS_KEY], {
      refetchActive: true,
    });
  }, []);

  const { onDelete, onTagsUpdate, onFlagUpdate, onImageInfoUpdate } = useDefaultEventHandlers();

  return (
    <>
      <ProjectMediaTabFilterInitializers location={projectLocation} />
      <IdsMediaListView
        media={allFilteredMedia}
        noMediaSeverity={mediaCount ? 'warning' : 'info'}
        noMediaMessage={mediaCount ? 'No media for current filters' : 'This project has no media'}
        getMediaThumbnailUrl={getMediaThumbnailUrl}
        getMediaType={getMediaType}
        getMediaId={getMediaId}
        renderImage={renderImage}
        filters={filters}
        sorters={sorters}
        onSortByChange={setActiveSorterKey}
        defaultImageSize={imageSize}
        onImageSizeChange={setImageSize}
        loading={isLoading}
        onImageClick={onImageClick}
        actions={<Actions onUploadCompleted={onUploadCompleted} />}
        bulkActions={[bulkEditAction]}
      />
      {activeId && (
        <IdsMediaViewer
          onDelete={onDelete}
          onFlagUpdate={onFlagUpdate}
          onImageInfoUpdate={onImageInfoUpdate}
          onTagsUpdate={onTagsUpdate}
          activeMediaId={activeId}
          initialWidth={MEDIA_VIEWER_WIDTHS.THREEQUARTER}
          fullscreen={false}
          tabs={[MEDIA_VIEWER_TABS.INFO]}
          selectMediaItemData={item => item.node}
          onActiveMediaChange={viewImage}
          onClose={closeMediaViewer}
          open={mediaViewerOpen}
          hideClose={false}
          loadAllThumbnails={false}
          loadActiveImageDetail={true}
          media={allFilteredMedia}
          mode={MEDIA_VIEWER_MODES.GALLERY}
          modal={true}
          allImageTags={allTags}
          tenantId={activeTenant.id}
          orgId={activeOrg.id}
        />
      )}
      <BulkLocationImageEditDialog
        open={bulkEditOpen}
        onClose={closeBulkEdit}
        locationId={projectLocation.id}
        imageUrns={bulkEditImageUrns}
        allImages={allFilteredMedia}
        imageTypesEditing={imageTypesBulkEditing}
        metadataTypes={projectLocation.metadataTypes}
      />
    </>
  );
};

const types = [MediaType.ProjectPhoto, MediaType.HDPhoto, MediaType.PanoramicPhoto];
const defaultTypeMetadata = getDefaultTypeMetadata(types);

const ProjectMediaTabWithContext = props => (
  <FilterProvider types={types} defaultTypeMetadata={defaultTypeMetadata}>
    <ProjectMediaTab {...props} />
  </FilterProvider>
);

export default ProjectMediaTabWithContext;
