import { useEffect } from 'react';

import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import { useOrderedRasterOverlays } from '../../../../utils/overlays';

const useRasterOverlays = () => {
  const { setLayers } = useImmersiveViewer();

  const orderedRasterOverlays = useOrderedRasterOverlays();

  useEffect(() => {
    if (!orderedRasterOverlays) {
      return;
    }

    setLayers(orderedRasterOverlays);
  }, [orderedRasterOverlays, setLayers]);
};

export default useRasterOverlays;
