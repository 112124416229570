import { useCallback, useEffect, useMemo, useState } from 'react';
import Uppy, { UppyFile } from '@uppy/core';
import XHRUpload, { XHRUploadOptions } from '@uppy/xhr-upload';
import { Box, Divider, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import exifr from 'exifr';

import RuntimeConfig from '../../../RuntimeConfig';
import { activeTenantState } from '../../../atoms/tenants';
import { activeOrganizationState } from '../../../atoms/organizations';
import keycloak from '../../../keycloak';
import useIdsUploaderContext from '../../useIdsUploaderContext';
import {
  IBaseUploaderProps,
  IPluginOptions,
  IUploadStep,
  IUploader,
  IUploaderValue,
  UploadType,
} from '../../../constants/uploads';
import useUppyEventHandler from '../../useUppyEventHandler';
import { ImageType, MediaType, IMetadataType, MEDIA_TYPES } from '../../../constants/media';
import IdsImageTypeSelect from '../../../components/ids-inputs/common/IdsImageTypeSelect';
import {
  useGetLocationMetadataTypes,
  useGetLocationName,
  useGetLocationPosition,
} from '../../../services/LocationService';
import usePermissions from '../../usePermissions';
import usePrevious from '../../usePrevious';
import { USER_ROLES } from '../../../constants/users';
import ProjectPhotoForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/batch/ProjectPhotoForm';
import { projectPhotoValidationSchema } from '../../../components/ids-inputs/uploaders/LocationImageUploader/batch/ProjectPhotoForm/projectPhotoValidation';
import HDPhotoForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/batch/HDPhotoForm';
import { hdPhotoValidationSchema } from '../../../components/ids-inputs/uploaders/LocationImageUploader/batch/HDPhotoForm/hdPhotoValidation';
import PanoramicPhotoForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/batch/PanoramicPhotoForm';
import { panoramicPhotoValidationSchema } from '../../../components/ids-inputs/uploaders/LocationImageUploader/batch/PanoramicPhotoForm/panoramicPhotoValidation';
import { isLatitudeValid, isLongitudeValid } from '../../../utils/geospatial';
import { getUUID } from '../../../utils/helpers';
import { PhotosIcon } from '../../../theme/icons';
import LocationChip from '../../../components/entity-chips/LocationChip';

import styles from './UseBatchLocationImageUploader.module.css';

const getXHRUploadId = (uppyId: string) => `${uppyId}-XHRUpload`;

const XClientId = 'Explorer';

export interface IImageDataFormProps {
  metadataTypes: IMetadataType[];
  hideLocation?: boolean;
  hideProject?: boolean;
}

type UploadResponse = UppyFile['response'];

export interface IImageTypeUploaderConfig {
  xhrUploadOptions: XHRUploadOptions;
  formProps: IUploaderValue['formProps'];
  imageDataStep: {
    formFields: React.FC<IImageDataFormProps>;
    validationSchema: IUploadStep['validationSchema'];
  };
  selectNewImageData: (response: UploadResponse) => any;
}

const IMAGE_TYPE_PROPS: Record<ImageType, IImageTypeUploaderConfig> = {
  [MediaType.ProjectPhoto]: {
    xhrUploadOptions: {
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/user_photos`,
      allowedMetaFields: [
        'location_id',
        'project_id',
        'latitude',
        'longitude',
        'description',
        'photo_category_id',
        'photo_type_id',
        'captured_at',
        'photo_level_id',
        'photo_area_id',
        'uuid',
        'photo_tags',
      ],
    },
    formProps: {
      initialValues: {
        location_id: null,
        project_id: null,
        photo_category_id: null,
        photo_type_id: null,
        photo_level_id: null,
        photo_area_id: null,
        photo_tags: [],
      },
    },
    imageDataStep: {
      formFields: ProjectPhotoForm,
      validationSchema: projectPhotoValidationSchema,
    },
    selectNewImageData: (response: UploadResponse) => response?.body.user_photo,
  },
  [MediaType.HDPhoto]: {
    xhrUploadOptions: {
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/hd_photos`,
      allowedMetaFields: [
        'location_id',
        'project_id',
        'latitude',
        'longitude',
        'description',
        'captured_at',
        'photo_level_id',
        'photo_area_id',
        'uuid',
        // 'photo_tags',
      ],
    },
    formProps: {
      initialValues: {
        location_id: null,
        project_id: null,
        photo_level_id: null,
        photo_area_id: null,
        // photo_tags: [],
      },
    },
    imageDataStep: {
      formFields: HDPhotoForm,
      validationSchema: hdPhotoValidationSchema,
    },
    selectNewImageData: (response: UploadResponse) => response?.body.hd_photo,
  },
  [MediaType.PanoramicPhoto]: {
    xhrUploadOptions: {
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/panoramas`,
      allowedMetaFields: [
        'location_id',
        'project_id',
        'latitude',
        'longitude',
        'description',
        'captured_at',
        'altitude',
        'heading',
        'photo_level_id',
        'photo_area_id',
        'uuid',
        // 'photo_tags',
      ],
    },
    formProps: {
      initialValues: {
        location_id: null,
        project_id: null,
        photo_level_id: null,
        photo_area_id: null,
        // photo_tags: [],
      },
    },
    imageDataStep: {
      formFields: PanoramicPhotoForm,
      validationSchema: panoramicPhotoValidationSchema,
    },
    selectNewImageData: (response: UploadResponse) => response?.body.panorama,
  },
};

// think about doing an intersection type here instead...
export interface IInitialValues extends Record<string, any> {
  // snake case is used here to match the request/form shape for ease of use
  photo_category_id?: string;
  photo_type_id?: string;
  photo_level_id?: string;
  photo_area_id?: string;
  photo_tags?: string[];
}

export interface IUppyMeta extends Omit<IInitialValues, 'photo_tags'> {
  // Array brackets are needed here to form in form data used in XHR Uploader
  'photo_tags[]'?: IInitialValues['photo_tags'];
  imageType?: ImageType;
  tenantSubdomain?: string;
  orgId?: string;
  locationName?: string;
  location_id?: string;
  project_id?: string;
}

export interface ILocationImageFileMeta {
  description?: string;
  captured_at?: string;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  heading?: number;
  uuid?: string;
}

export interface IBatchLocationImageUploaderProps extends IBaseUploaderProps {
  defaultLocationId?: string;
  defaultProjectId?: string;
  initialValues?: IInitialValues;
  disabledImageTypes?: ImageType[];
}

const useBatchLocationImageUploader = (
  enabled: boolean,
  {
    defaultLocationId,
    defaultProjectId,
    initialValues,
    disabledImageTypes,
  }: IBatchLocationImageUploaderProps,
) => {
  const prevEnabled = usePrevious(enabled);
  const [imageType, setImageType] = useState<ImageType | undefined>();
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { uppy } = useIdsUploaderContext();
  const { userHasOneOfRoles } = usePermissions();
  const [sharedInitialValues, setSharedInitialValues] = useState<Partial<IInitialValues>>({});
  const [locationId, setLocationId] = useState<string | null>(defaultLocationId || null);
  const [exifParseBatches, setFilesParsingExif] = useState(0);

  const { data: locationNameData } = useGetLocationName(locationId);
  const { data: locationPositionData } = useGetLocationPosition(locationId);
  const { data: locationMetadataTypesData } = useGetLocationMetadataTypes(locationId);
  const metadataTypes = useMemo<IMetadataType[]>(
    () => locationMetadataTypesData?.location.metadataTypes || [],
    [locationMetadataTypesData],
  );

  const uploaderConfig = useMemo<IImageTypeUploaderConfig | undefined>(
    () => imageType && IMAGE_TYPE_PROPS[imageType],
    [imageType],
  );

  useEffect(() => {
    if (!enabled || !uppy || !uploaderConfig) return;

    const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
    xhr?.setOptions({
      ...uploaderConfig.xhrUploadOptions,
    });
  }, [enabled, uppy, uploaderConfig]);

  const handleFilesAdded = useCallback(
    (files: UppyFile<ILocationImageFileMeta>[]) => {
      files.forEach(file => {
        // Track how many files are being parsed since parsing finishes at different times
        setFilesParsingExif(prev => prev + 1);

        uppy!.setFileMeta<ILocationImageFileMeta>(file.id, {
          uuid: getUUID(),
          description: file.name,
        });

        exifr
          .parse(file.data, {
            pick: ['DateTimeOriginal', 'CreateDate', 'GPSAltitude', 'GPSAltitudeRef'],
          })
          .then((exif: any) => {
            if (!exif) {
              uppy!.setFileMeta<ILocationImageFileMeta>(file.id, {
                captured_at: new Date().toUTCString(),
                altitude: 0,
              });
              return;
            }

            const { DateTimeOriginal, CreateDate, GPSAltitude } = exif;

            uppy!.setFileMeta<ILocationImageFileMeta>(file.id, {
              captured_at: DateTimeOriginal || CreateDate || new Date().toUTCString(),
              // check, are we using meters or ft in our BE?
              altitude:
                typeof GPSAltitude === 'number' && !Number.isNaN(GPSAltitude)
                  ? Math.round(GPSAltitude) // must be an integer
                  : 0,
            });
          })
          .finally(() => {
            // Need to chain exif parsing to prevent file meta overrwrite from race condition
            // TODO: populate lat/lon with location data on submit (if not loaded here) (location has been set at that point)
            exifr.gps(file.data).then((gps: any) => {
              if (gps) {
                uppy!.setFileMeta<ILocationImageFileMeta>(file.id, {
                  latitude: gps.latitude,
                  longitude: gps.longitude,
                });
              }
            });

            setFilesParsingExif(prev => prev - 1);
          });
      });
    },
    [uppy],
  );

  useUppyEventHandler('files-added', handleFilesAdded, uppy, enabled);

  useEffect(() => {
    if (!enabled || prevEnabled) return;

    const files = uppy.getFiles();

    if (files.length) {
      // Handle files added before mount
      handleFilesAdded(files);
    }
  }, [enabled, prevEnabled, uppy, handleFilesAdded]);

  const handleFormSubmit = useCallback(
    (values: IInitialValues) => {
      uppy!.getFiles<ILocationImageFileMeta>().forEach(file => {
        const { latitude, longitude } = file.meta;

        const metaUpdate: ILocationImageFileMeta = {};

        // File failed to load lat/lon, default to location position
        if (!isLatitudeValid(latitude) || !isLongitudeValid(longitude)) {
          metaUpdate.latitude = locationPositionData?.location.position.latitude;
          metaUpdate.longitude = locationPositionData?.location.position.longitude;
        }

        metaUpdate.heading = locationPositionData?.location.heading;

        uppy!.setFileMeta<ILocationImageFileMeta>(file.id, metaUpdate);
      });

      if (!uploaderConfig) {
        throw new Error('Upload preprocessing failed: configuration not set.');
      }

      const xhr = uppy!.getPlugin(getXHRUploadId(uppy!.getID()));
      if (!xhr) {
        throw new Error('Upload preprocessing failed: XHRUploader is not initialized.');
      }

      const { xhrUploadOptions } = uploaderConfig;

      const meta: IUppyMeta = { ...values };

      // Remove any null or undefined form fields, results in BE errors if left
      Object.keys(meta).forEach((field: string) => {
        if (meta[field] === null || meta[field] === undefined) {
          delete meta[field];
        }
      });

      if (xhrUploadOptions) {
        const arrayMetaFields = xhrUploadOptions.allowedMetaFields?.filter(field => {
          const value = meta[field];
          return value && Array.isArray(value);
        });

        // All file specific fields should be included
        const newAllowedMetaFields: string[] = [
          'description',
          'captured_at',
          'latitude',
          'longitude',
          'altitude',
          'heading',
          'uuid',
        ].filter(f =>
          xhrUploadOptions.allowedMetaFields
            ? xhrUploadOptions.allowedMetaFields.includes(f)
            : true,
        );

        if (xhrUploadOptions.allowedMetaFields) {
          xhrUploadOptions.allowedMetaFields.forEach(field => {
            if (arrayMetaFields?.includes(field)) {
              // Meta fields are submitted as FormData where the array
              // field names must be appended with []. XHRUploader doesn't
              // auto append that, update allowed meta fields and upload meta
              // to properly send array form data.
              const arrayField = `${field}[]`;
              newAllowedMetaFields.push(arrayField);

              meta[arrayField] = meta[field];
              delete meta[field];
            }
            // Only allow meta field if it is not undefined or null
            else if (meta[field] !== undefined && meta[field] !== null) {
              newAllowedMetaFields.push(field);
            }
          });

          xhr.setOptions({
            allowedMetaFields: newAllowedMetaFields,
          });
        }

        meta.imageType = imageType; // Track this for uploader icon
        meta.tenantSubdomain = activeTenant!.subdomain;
        meta.orgId = activeOrg!.id; // Track this for upload location chip
        meta.locationName = locationNameData?.location.name;

        uppy!.setMeta<IUppyMeta>(meta);
      }
    },
    [
      uppy,
      uploaderConfig,
      locationPositionData,
      imageType,
      activeOrg,
      locationNameData,
      activeTenant,
    ],
  );

  const fullInitialValues = useMemo(() => {
    if (!imageType) return {};

    const fullSharedInitialValues = {
      // not setting these in sharedInitialValues to allow for removed init values from being persisted when spreading
      // prev shared initial values
      ...sharedInitialValues,
      ...initialValues,
    };

    const typeInitialValues = { ...IMAGE_TYPE_PROPS[imageType].formProps.initialValues };
    return Object.keys(typeInitialValues).reduce((result, field) => {
      // Only use shared initial values that apply to the current image type fields
      if (field in result && fullSharedInitialValues[field]) {
        result[field] = fullSharedInitialValues[field];
      }

      return result;
    }, typeInitialValues);
  }, [imageType, sharedInitialValues, initialValues]);

  const uploadSteps = useMemo<IUploadStep[]>(() => {
    if (!enabled) return [];

    const disabledTypes: ImageType[] = [];

    if (disabledImageTypes) {
      // support external image type disabling
      disabledTypes.push(...disabledImageTypes);
    }

    // Disable hds and panos for non IDS users
    if (
      !userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ])
    ) {
      if (!disabledTypes.includes(MediaType.HDPhoto)) {
        disabledTypes.push(MediaType.HDPhoto);
      }
      if (!disabledTypes.includes(MediaType.PanoramicPhoto)) {
        disabledTypes.push(MediaType.PanoramicPhoto);
      }
    }

    let fields = null;

    if (uploaderConfig) {
      const ImageDataForm = uploaderConfig?.imageDataStep.formFields;
      fields = (
        <>
          <Divider className={styles.divider} />
          <ImageDataForm
            metadataTypes={metadataTypes}
            hideLocation={!!defaultLocationId} // hide location if default is provided
            hideProject={!!defaultProjectId} // hide project if default is provided
          />
        </>
      );
    }

    return [
      {
        label: 'Enter common image data',
        formFields: (
          <Box pt={1}>
            <Stack direction='row' spacing={2} alignItems='center'>
              <IdsImageTypeSelect
                onChange={setImageType}
                value={imageType}
                disabledTypes={disabledTypes}
              />
            </Stack>
            {fields}
          </Box>
        ),
        validationSchema: uploaderConfig?.imageDataStep.validationSchema,
        disableNextButton: !imageType,
      },
    ];
  }, [
    enabled,
    imageType,
    uploaderConfig,
    disabledImageTypes,
    userHasOneOfRoles,
    defaultLocationId,
    defaultProjectId,
    metadataTypes,
  ]);

  const handleFormValuesChange = useCallback(
    (values: any) => {
      const { location_id } = values;
      setLocationId(location_id);
    },
    [setLocationId],
  );

  useEffect(() => {
    setSharedInitialValues((prev: any) => ({
      ...prev,
      location_id: defaultLocationId,
      project_id: defaultProjectId,
    }));
  }, [defaultLocationId, defaultProjectId, setSharedInitialValues]);

  return useMemo<IUploaderValue | null>(
    () =>
      enabled
        ? ({
            formProps: {
              initialValues: fullInitialValues,
            },
            uploadSteps,
            onFormValuesChange: handleFormValuesChange,
            onFormSubmit: handleFormSubmit,
            disableSubmit: exifParseBatches > 0,
            disableSubmitTooltip: 'Parsing image exif data',
          } as IUploaderValue)
        : null,
    [
      enabled,
      fullInitialValues,
      uploadSteps,
      handleFormValuesChange,
      handleFormSubmit,
      exifParseBatches,
    ],
  );
};

const IMAGE_TYPES = [
  MediaType.ProjectPhoto,
  MediaType.HDPhoto,
  MediaType.PanoramicPhoto,
] as ImageType[];

const defaultXHROptions: XHRUploadOptions = {
  endpoint: '',
  bundle: false,
  timeout: 0, // disable timeout to handle slow BE processing
  headers: () => ({
    // Ensure the keycloak token is updated before uploading
    authorization: `Bearer ${keycloak.token}`,
    'x-client-id': XClientId,
  }),
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBatchLocationImageUploadContext
  extends Pick<
    IUppyMeta,
    'imageType' | 'location_id' | 'locationName' | 'tenantSubdomain' | 'orgId'
  > {}

export const BatchLocationImageUploader: IUploader<
  IBatchLocationImageUploaderProps,
  IBatchLocationImageUploadContext
> = {
  uploadType: UploadType.BatchLocationImage,
  label: 'Multiple images',
  getUploadLabel: (_: Uppy, context: IBatchLocationImageUploadContext) => {
    const { location_id, locationName, tenantSubdomain, orgId } = context;
    return (
      location_id && (
        <LocationChip
          tenantSubdomain={tenantSubdomain} // This needs to be passed in explicitly to handle upload from different tenant
          organizationId={orgId}
          locationId={location_id}
          locationName={locationName}
        />
      )
    );
  },
  getIcon: (_: Uppy, context: IBatchLocationImageUploadContext) => {
    const { imageType } = context;
    return (imageType && MEDIA_TYPES[imageType].icon) || PhotosIcon;
  },
  getUploadContext: (uppy: Uppy) => {
    const { imageType, location_id, locationName, tenantSubdomain, orgId } = uppy.getState()
      .meta as IUppyMeta;
    return { imageType, location_id, locationName, tenantSubdomain, orgId };
  },
  uppyRestrictions: {
    minNumberOfFiles: 2,
    allowedFileTypes: ['.jpg', '.jpeg'],
  },
  isAvailable: (_, __, userHasOneOfRoles, { disabledImageTypes }) => {
    const enabledImageTypes = IMAGE_TYPES.filter(t => !disabledImageTypes?.includes(t));

    if (!enabledImageTypes.length) return false;

    // Only hds and/or panos are enabled and user doesn't have permission to create them
    if (
      !userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ]) &&
      !enabledImageTypes.includes(MediaType.ProjectPhoto)
    ) {
      return false;
    }

    return true;
  },
  useUploader: useBatchLocationImageUploader,
  configureUppy: (uppy: Uppy) => {
    const xhrId = getXHRUploadId(uppy.getID());

    if (!uppy.getPlugin(xhrId)) {
      // Plugin not already installed
      uppy.use(XHRUpload, {
        id: xhrId,
        ...defaultXHROptions,
      });
    }
  },
  restoreUppy: (uppy: Uppy, restoredPlugins?: IPluginOptions) => {
    const id = uppy.getID();

    const xhrId = getXHRUploadId(id);
    const restoredOptions = restoredPlugins ? restoredPlugins[xhrId] : null;

    const xhrOptions = {
      id: xhrId,
      ...defaultXHROptions,
      ...restoredOptions,
    };

    const xhr = uppy.getPlugin(xhrId);
    if (xhr) {
      // Plugin already installed, update options
      xhr.setOptions(xhrOptions);
    } else {
      // Plugin not yet installed, install it
      uppy.use(XHRUpload, xhrOptions);
    }
  },
  processRestoredUppy: (uppy: Uppy) => {
    const files = uppy.getFiles();
    files.forEach(f => {
      if (!f.progress?.uploadStarted || f.progress?.uploadComplete) return;

      uppy.setFileMeta(f.id, { uuid: getUUID() });
    });

    // TODO: send delete image requests for all image uuids that were replaced to ensure that partially uploaded images aren't uploaded...
    // also, may need to reset progress on those files that are in flight to prevent partial upload of some bytes
  },
  deconfigureUppy: (uppy: Uppy) => {
    const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
    if (xhr) {
      uppy.removePlugin(xhr);
    }
  },
  queueUpload: true,
  goldenRetrieverOptions: {
    serviceWorker: true,
    // expires: some number, // discuss with business team, how long should this be??
  },
};

export default useBatchLocationImageUploader;
