/**
 * Prepares answers and images for legacy API format.
 *
 * @TODO: implement types
 */
export const prepareGroupedAnswers = (groupedAnswers: any) => {
  const result: any[] = [];

  Object.values(groupedAnswers).forEach(({ question, answers }: any) => {
    result.push(
      ...answers.map((answer: any) => {
        if (answer.uuid) {
          return {
            question_id: question.id,
            uuid: answer.uuid,
          };
        } else {
          return {
            id: answer.id,
            question_id: question.id,
            value: answer.answerText,
          };
        }
      }),
    );
  });

  return result;
};
