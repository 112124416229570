import React from 'react';
import { useRecoilValue } from 'recoil';

import TenantProgramListItem from '../TenantProgramListItem';
import IdsSearchableList from '../../../../components/ids-lists/IdsSearchableList';
import { searchProgramItem, sortPrograms } from '../../../Programs';
import { useGetTenantPrograms, ITenantProgramItem } from '../../../../services/TenantService';
import { activeTenantState } from '../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantProgramsListProps {}

const TenantProgramsList: React.FC<ITenantProgramsListProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { data, error, isLoading } = useGetTenantPrograms(activeTenant!.id);

  return (
    <IdsSearchableList
      items={data}
      renderItem={(programItem: ITenantProgramItem) => (
        <TenantProgramListItem key={programItem.node.id} programItem={programItem} />
      )}
      searchPlaceholder='Search programs'
      searchItem={searchProgramItem}
      sortItems={sortPrograms}
      noItemsMessage='No programs'
      noItemsForFilterMessage='No programs match your search'
      loading={isLoading}
      error={error && 'Projects could not be loaded'}
      header={undefined}
      filters={undefined}
      filterMode={undefined}
      actions={undefined}
    />
  );
};

export default TenantProgramsList;
