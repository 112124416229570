import React, { useCallback, useMemo, useState } from 'react';

import Action from '../../../components/NestedOrgViewHeader/Action';
import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/users';
import TenantPage from '../TenantPage';

import TenantUsersList from './TenantUsersList';
import CreateUserDialog from './CreateUserDialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantUsersProps {}

const TenantUsers: React.FC<ITenantUsersProps> = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialogState = useCallback(() => setIsDialogOpen(prev => !prev), []);

  const { userHasOneOfRoles } = usePermissions();
  const isAdmin = useMemo(() => {
    return userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]);
  }, [userHasOneOfRoles]);

  return (
    <TenantPage
      title='Users'
      actions={<Action label='New User' onClick={toggleDialogState} canClick={isAdmin} />}
      dialogs={
        <CreateUserDialog
          open={isDialogOpen}
          onOpen={toggleDialogState}
          onClose={toggleDialogState}
        />
      }
    >
      <TenantUsersList />
    </TenantPage>
  );
};

export default TenantUsers;
