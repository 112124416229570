import React, { useEffect, useMemo } from 'react';

import MapMenuItemToggles, { IActiveToggleState } from '../MapMenuItem/MapMenuItemToggles';
import {
  AreaOfInterestIcon,
  FilterIconFilled,
  LayersIcon,
  MeasureIcon,
  PointOfInterestIcon,
  ToolIcon,
  RasterOverlayIcon,
} from '../../../theme/icons';
import { MEASUREMENT_TOOL_ID } from '../tools/MeasurementTool';
import { POI_TOOL_ID } from '../tools/PointOfInterestTool';
import { AOI_TOOL_ID } from '../tools/AreaOfInterestTool';
import { RO_TOOL_ID } from '../tools/RasterOverlayTool';
import useMetadataItems from '../../../hooks/useMetadataItems';
import MapMenu from '../MapMenu';
import { IMetadataType } from '../../../constants/media';
import { IAssignableCustomField } from '../../../constants/customFields';
import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/users';

import { useLayersBuilder, useFiltersBuilder } from './useMenuBuilder';

export const TOOLS_ID = 'tools';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IToolState extends IActiveToggleState {}

export interface ILocationMapMenuProps {
  metadataTypes: IMetadataType[];
  assignableCustomFields: IAssignableCustomField[];
}

const LocationMapMenu: React.FC<ILocationMapMenuProps> = ({
  metadataTypes,
  assignableCustomFields,
}) => {
  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(metadataTypes);

  const filterAccordions = useFiltersBuilder(
    allLevels,
    allAreas,
    allTypes,
    allCategories,
    allTags,
    assignableCustomFields,
  );
  const {
    items: layerAccordions,
    createRasterOverlayFilter,
    createVectorOverlayFilter,
    rasterOverlays,
    vectorOverlays,
  } = useLayersBuilder();

  const { userHasOneOfRoles } = usePermissions();

  const canCreateRasterOverlay = useMemo(
    () =>
      userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.ORG_ADMIN,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ]),
    [userHasOneOfRoles],
  );

  useEffect(() => {
    // Initialize default overlay
    const defaultOverlay = rasterOverlays?.find((o: any) => o.default);
    if (defaultOverlay) {
      createRasterOverlayFilter(defaultOverlay, false)(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rasterOverlays]);

  useEffect(() => {
    // Initialize default overlay
    const defaultOverlay = vectorOverlays?.find((o: any) => o.default);
    if (defaultOverlay) {
      createVectorOverlayFilter(defaultOverlay, false)(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vectorOverlays]);

  const menu = useMemo(
    () => [
      {
        sectionTitle: 'Layers',
        icon: <LayersIcon />,
        accordions: layerAccordions,
      },
      {
        sectionTitle: 'Filters',
        icon: <FilterIconFilled />,
        accordions: filterAccordions,
      },
      {
        sectionTitle: 'Tools',
        icon: <ToolIcon />,
        custom: (
          <MapMenuItemToggles
            itemId={TOOLS_ID}
            toggles={[
              {
                key: MEASUREMENT_TOOL_ID,
                label: 'Measure',
                icon: <MeasureIcon />,
              },
              {
                key: POI_TOOL_ID,
                label: 'Point of Interest',
                icon: <PointOfInterestIcon />,
              },
              {
                key: AOI_TOOL_ID,
                label: 'Area of Interest',
                icon: <AreaOfInterestIcon />,
              },
              ...(canCreateRasterOverlay
                ? [
                    {
                      key: RO_TOOL_ID,
                      label: 'Raster Overlay',
                      icon: <RasterOverlayIcon />,
                    },
                  ]
                : []),
            ]}
          />
        ),
      },
    ],
    [layerAccordions, filterAccordions, canCreateRasterOverlay],
  );

  return <MapMenu menu={menu} />;
};

export default LocationMapMenu;
