import React from 'react';
import { SvgIcon } from '@mui/material';

const NotFlaggedIcon = props => (
  <SvgIcon {...props}>
    <g transform='matrix(0.83091, 0, 0, 0.850099, 18.120728, -15.24381)'>
      <g transform='matrix(1, 0, 0, 1, -29.451672, -55.295326)'>
        <g>
          <path d='M 12.962 77.792 L 30.429 97.027 L 28.748 98.528 L 20.003 88.999 L 17.274 88.999 L 17.274 95.999 L 15.274 95.999 L 15.274 83.847 L 11.173 79.378 Z M 30.274 80.999 L 30.274 90.999 L 27.543 90.999 L 16.301 78.999 L 24.274 78.999 L 24.674 80.999 Z' />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default NotFlaggedIcon;
