import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, debounce, Chip } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../../atoms/organizations';
import { sessionState } from '../../../../../atoms/session';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../constants/media';
import { USER_ROLES } from '../../../../../constants/users';
import usePermissions from '../../../../../hooks/usePermissions';
import { useUpdateImageMetadata } from '../../../../../services/MediaService';
import MultiValueAutocompleteOption from '../../../../ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';
import IdsAutocomplete from '../../../../ids-inputs/IdsAutocomplete';

const getTagId = item => item.id;
const filterMetadata = (accumulator, item) => {
  if (item.type === MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type) {
    accumulator.push({
      id: item.id,
      name: item.value,
      creatorId: item.creatorId,
    });
  }

  return accumulator;
};

const ImageTags = ({
  imageId,
  metadata,
  allImageTags,
  setScrollingOffset,
  scrollContainerRef,
  onTagsUpdate,
}) => {
  const [metadataPhotoTags, setMetadataPhotoTags] = useState([]);

  const { userHasOneOfRoles } = usePermissions();
  const isAdmin = useMemo(
    () => userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.TENANT_ADMIN]),
    [userHasOneOfRoles],
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const { id: userId } = useRecoilValue(sessionState);
  const { enqueueSnackbar } = useSnackbar();

  const fixedOptions = useMemo(
    () =>
      isAdmin
        ? []
        : metadataPhotoTags.reduce((accumulator, tag) => {
            if (tag.creatorId !== userId) {
              accumulator.push(tag.id);
            }

            return accumulator;
          }, []),
    [isAdmin, metadataPhotoTags, userId],
  );
  const [selectedTags, setSelectedTags] = useState([]);

  const activeOrg = useRecoilValue(activeOrganizationState);
  const updateMetadataMutation = useUpdateImageMetadata();

  const updateMetadataPhotoTags = async value => {
    setIsUpdating(true);

    /**
     * updateImage mutation only supports metadata types that are applicable to all image types.
     * Need to exclude phototag though to get the new value.
     * NOTE: the BE does not acknowledge area as being a general image metadata type, even though it is.
     * TODO: update this to include area once the BE mutation is fixed.
     */
    const otherGeneralImageMetadataTypes = [MediaMetadataType.Level];
    const filteredMetadata = metadata.reduce((accumulator, item) => {
      if (otherGeneralImageMetadataTypes.includes(item.type)) {
        accumulator.push({
          id: item.id,
          type: item.type,
        });
      }

      return accumulator;
    }, []);

    const newMetadata = value
      .map(id => ({ id, type: MediaMetadataType.PhotoTag }))
      .concat(filteredMetadata);

    /**
     * @type {
     *   {
     *     updateImage: {
     *       errors: ?Array.<{
     *         message: String,
     *       }>,
     *       image: {
     *         mediaType: String,
     *         metadata: Array,
     *       }
     *     }
     *   }
     * }
     */
    const { updateImage: result } = await updateMetadataMutation.mutateAsync({
      organizationId: activeOrg.id,
      id: imageId,
      metadata: newMetadata,
    });

    if (result.errors) {
      enqueueSnackbar(result.errors[0].message || 'Something went wrong', {
        variant: 'error',
      });

      /**
       * Roll back the state of the input field in case of error.
       */
      setSelectedTags(metadataPhotoTags.map(getTagId));
    } else {
      /**
       * Update the state of the input field in case of success.
       */
      setMetadataPhotoTags(result.image.metadata?.reduce(filterMetadata, []) || []);

      onTagsUpdate(imageId, result);
    }

    setIsUpdating(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTagsUpdater = useCallback(debounce(updateMetadataPhotoTags, 500), [
    activeOrg.id,
    onTagsUpdate,
    enqueueSnackbar,
    imageId,
    metadataPhotoTags,
    updateMetadataMutation,
  ]);

  useEffect(() => {
    setMetadataPhotoTags(metadata.reduce(filterMetadata, []));
  }, [metadata]);

  useEffect(() => {
    setSelectedTags(metadataPhotoTags.map(getTagId));
  }, [metadataPhotoTags]);

  const boxRef = useRef();

  return (
    <Box mx={1} mb={1}>
      <Typography py={0.5} pl={1} variant='h6'>
        Image Tags
      </Typography>
      <Box variant='outlined' p={1} ref={boxRef}>
        <IdsAutocomplete
          onFocus={() => {
            const possibleScrollingCapacity =
              scrollContainerRef.current.clientHeight - boxRef.current.clientHeight;

            if (scrollContainerRef.current.scrollTop > possibleScrollingCapacity) {
              boxRef.current.scrollIntoView();
            }

            setScrollingOffset(scrollContainerRef.current.scrollTop);
          }}
          onBlur={() => setScrollingOffset(-1)}
          disabled={isUpdating}
          options={allImageTags}
          value={selectedTags}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id}
          renderOption={(props, o, { selected }) => (
            <MultiValueAutocompleteOption
              label={o.name}
              selected={selected}
              {...props}
              key={o.id}
            />
          )}
          getOptionDisabled={option => fixedOptions.includes(option.id)}
          disableClearable={true}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                disabled={fixedOptions.includes(option.id)}
              />
            ))
          }
          noOptionsText='No tags found'
          disableCloseOnSelect
          multiple
          limitTags={4}
          onChange={(_, value, reason) => {
            setIsValueChanged(true);

            const filteredValue = [
              ...fixedOptions,
              ...value.filter(optionId => !fixedOptions.includes(optionId)),
            ];

            setSelectedTags(filteredValue);

            if ((reason === 'removeOption' || reason === 'clear') && !isOpen) {
              debouncedTagsUpdater(filteredValue);
            }
          }}
          onClose={() => {
            if (isValueChanged) {
              debouncedTagsUpdater(selectedTags);

              setIsValueChanged(false);
            }

            setIsOpen(false);
          }}
          onOpen={() => setIsOpen(true)}
          componentsProps={{
            popper: {
              style: { zIndex: 200 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

ImageTags.prototype = {
  imageId: PropTypes.string.isRequired,
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      creatorId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  allImageTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  setScrollingOffset: PropTypes.func.isRequired,
  scrollContainerRef: PropTypes.element.isRequired,
  onTagsUpdate: PropTypes.func,
};

export default ImageTags;
