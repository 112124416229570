import * as Yup from 'yup';

import {
  latitudeValidation,
  longitudeValidation,
} from '../../../../../../field-validation/geospatial';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../../constants/media';

export const projectPhotoNoMarkerValidation = {
  location_id: Yup.string().nullable().required('Location is required'),
  project_id: Yup.string().nullable().required('Project is required'),
  description: Yup.string().required('Description is required'),
  photo_category_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].label} is required`),
  photo_type_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].label} is required`),
  captured_at: Yup.date()
    .typeError('Must be a valid date')
    .nullable()
    .required('Captured date is required'),
  photo_level_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.Level].label} is required`),
  photo_area_id: Yup.string().nullable(),
  photo_tags: Yup.array(Yup.string()),
};

export const projectPhotoNoMarkerValidationSchema = Yup.object().shape(
  projectPhotoNoMarkerValidation,
);

export const projectPhotoValidation = {
  ...projectPhotoNoMarkerValidation,
  latitude: latitudeValidation.required('Latitude is required'),
  longitude: longitudeValidation.required('Longitude is required'),
};

export const projectPhotoValidationSchema = Yup.object().shape(projectPhotoValidation);
