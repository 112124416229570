import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import OptionsList, { IOptionsListProps } from './OptionsList';
import Buttons, { intersection } from './Buttons';

export interface IIdsTransferListProps
  extends Omit<IOptionsListProps, 'checked' | 'setChecked' | 'handleToggle' | 'label'> {
  onChange?: (values: IOptionsListProps['values']) => any;
  optionsLabel?: string;
  valuesLabel?: string;
}

const IdsTransferList: React.FC<IIdsTransferListProps> = ({
  disabled,
  options,
  getPrimaryLabel,
  getSecondaryLabel,
  getOptionValue,
  values,
  onChange,
  valuesLabel,
  optionsLabel,
}) => {
  const [checked, setChecked] = useState<IOptionsListProps['values']>([]);
  // All options except initial values (they go to the right list).
  const [left, setLeft] = useState<IOptionsListProps['values']>(() => {
    return options.reduce((accumulator, option) => {
      const optionValue = getOptionValue(option);

      if (!values.includes(optionValue)) {
        accumulator.push(optionValue);
      }

      return accumulator;
    }, []);
  });
  // Initial values.
  const [right, setRight] = useState<IOptionsListProps['values']>(values);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => onChange && onChange(right), [right, onChange]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
      sx={{ flexDirection: { xs: 'column', md: 'row' } }}
    >
      <Grid item md={true} width='100%'>
        <OptionsList
          label={optionsLabel}
          disabled={disabled}
          options={options}
          values={left}
          getPrimaryLabel={getPrimaryLabel}
          getSecondaryLabel={getSecondaryLabel}
          getOptionValue={getOptionValue}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
      <Grid item xs='auto' width='100%'>
        <Grid container direction='column' alignItems='center'>
          <Buttons
            disabled={disabled}
            left={left}
            right={right}
            leftChecked={leftChecked}
            rightChecked={rightChecked}
            setLeft={setLeft}
            setRight={setRight}
            setChecked={setChecked}
            checked={checked}
          />
        </Grid>
      </Grid>
      <Grid item md={true} width='100%'>
        <OptionsList
          label={valuesLabel}
          disabled={disabled}
          options={options}
          values={right}
          getPrimaryLabel={getPrimaryLabel}
          getSecondaryLabel={getSecondaryLabel}
          getOptionValue={getOptionValue}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
    </Grid>
  );
};

export default IdsTransferList;
