import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../../../../../../atoms/organizations';

import { IProjectRouteListItemData } from '../../../../../../../../../services/fragments';
import { useUpdateRoute } from '../../../../../../../../../services/RouteService';

import IdsEditDialogForm from '../../../../../../../../../components/ids-forms/IdsEditDialogForm';
import IdsFormTextField from '../../../../../../../../../components/ids-forms/IdsFormTextField';

import RouteDescriptionFormField from '../../../../Actions/CreateRouteDialog/CreateRouteForm/RouteDescriptionFormField';

import { editRouteValidationSchema } from './editRouteValidation';

export interface IEditRouteFormProps {
  item: IProjectRouteListItemData;
  handleClose?: () => void;
}

const errorHandler = () => 'Failed to update route';

interface IFormValues {
  name: string;
  description: string;
  notes: string;
}

const EditRouteForm: React.FC<IEditRouteFormProps> = ({ item, handleClose }) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const updateRoute = useUpdateRoute();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      /**
       * Optional fields description and notes will be saved as empty string.
       */
      const result = await updateRoute.mutateAsync({
        id: item.id,
        organizationId: activeOrg.id,
        ...values,
      });

      if (result?.updateRoute?.errors?.length) {
        const field = result.updateRoute.errors[0].field;
        const message = result.updateRoute.errors[0].message;

        throw new Error(`${field} ${message}`);
      }

      if (handleClose) {
        handleClose();
      }
    },
    [updateRoute, handleClose, activeOrg.id, item.id],
  );

  /**
   * Notes and description are optional, but an empty string
   * is necessary to prevent MUI warnings.
   */
  const initialValues = useMemo(() => {
    return {
      name: item.name,
      description: item.description || '',
      notes: item.notes || '',
    };
  }, [item.name, item.notes, item.description]);

  return (
    <IdsEditDialogForm
      initialValues={initialValues}
      validationSchema={editRouteValidationSchema}
      successMessage='The route was updated'
      errorHandler={errorHandler}
      onSubmit={onSubmit}
      onCancel={handleClose}
    >
      <IdsFormTextField name='name' label='Name' required autoFocus />
      <RouteDescriptionFormField name='description' label='Description' multiline margin='normal' />
      <IdsFormTextField name='notes' label='Notes' multiline />
    </IdsEditDialogForm>
  );
};

export default EditRouteForm;
