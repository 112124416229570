import * as Yup from 'yup';

const pointOfInterestValidation = {
  name: Yup.string().required('Name is required'),
  levelId: Yup.string().typeError('Level is required').required('Level is required'),
  data: Yup.string().required('Information is required'),
  color: Yup.string().required('Color is required'),
  tags: Yup.array().of(Yup.string()),
};

const createPointOfInterestValidation = {
  ...pointOfInterestValidation,
  projectId: Yup.string().typeError('Project is required').required('Project is required'),
};

export const createPointOfInterestValidationSchema = Yup.object().shape(
  createPointOfInterestValidation,
);
export const updatePointOfInterestValidationSchema = Yup.object().shape(pointOfInterestValidation);
