import React from 'react';
import { useRecoilValue } from 'recoil';

import TenantProjectListItem from '../TenantProjectListItem';
import IdsSearchableList, {
  FILTER_MODES,
} from '../../../../components/ids-lists/IdsSearchableList';
import {
  projectFilters,
  searchProjectItem,
  sortProjectItems,
} from '../../../Projects/ProjectsList';
import { ITenantProjectItem, useGetTenantProjects } from '../../../../services/TenantService';
import { activeTenantState } from '../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantProjectsListProps {}

const TenantProjectsList: React.FC<ITenantProjectsListProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { data, error, isLoading } = useGetTenantProjects(activeTenant!.id);

  return (
    <IdsSearchableList
      items={data}
      renderItem={(projectItem: ITenantProjectItem) => (
        <TenantProjectListItem key={projectItem.node.id} projectItem={projectItem} />
      )}
      searchPlaceholder='Search projects'
      searchItem={searchProjectItem}
      sortItems={sortProjectItems}
      filters={projectFilters}
      filterMode={FILTER_MODES.TAB}
      noItemsMessage='No projects'
      noItemsForFilterMessage='No projects match your search'
      loading={isLoading}
      error={error && 'Projects could not be loaded'}
      header={undefined}
      actions={undefined}
    />
  );
};

export default TenantProjectsList;
