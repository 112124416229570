import React, { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { Box, Skeleton, SvgIcon, SvgIconProps, SxProps, Theme } from '@mui/material';

import useMounted from '../../hooks/useMounted';

import { PhotoIcon } from '../../theme/icons';
import '../../theme/globalStyles.css';

import styles from './IdsImage.module.css';

const sxStyles = {
  placeholderContainer: {
    backgroundColor: 'background.dark',
  },
};

const inlineStyles = {
  zeroSpacing: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
};

interface IRenderIcon {
  className?: string;
  [key: string]: any;
}

export type RenderIconFunc = (payload: IRenderIcon, hasImage: boolean) => React.ReactElement;

// Received this type from <img /> tag declaration
type ImageTagProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export interface IIdsImageProps extends Omit<ImageTagProps, 'placeholder'> {
  height?: string | number;
  width?: string | number;
  sx?: SxProps<Theme>;

  renderBottomLeftIcon?: RenderIconFunc;
  renderBottomRightIcon?: RenderIconFunc;
  renderTopRightIcon?: RenderIconFunc;
  renderTopLeftIcon?: RenderIconFunc;

  bottomLeftIconRequiresImage?: boolean;
  bottomRightIconRequiresImage?: boolean;
  topLeftIconRequiresImage?: boolean;
  topRightIconRequiresImage?: boolean;
  autoScaleCornerIcons?: boolean;

  loader?: React.ReactElement;

  placeholder?: ImageTagProps['placeholder'] | React.ReactElement;
  placeholderIcon?: React.ReactElement;
  placeholderProps?: SvgIconProps;

  containerProps?: HTMLAttributes<HTMLDivElement>;

  reloadTimeoutMs?: number | null;
  reloadAttempts?: number | null;
}

/**
 * All margin or padding styles should be set via `containerProps`.
 * All other margin or padding styles will be ignored.
 */
const IdsImage: React.FC<IIdsImageProps> = ({
  src,
  renderBottomLeftIcon,
  bottomLeftIconRequiresImage = true,
  renderBottomRightIcon,
  bottomRightIconRequiresImage = true,
  renderTopLeftIcon,
  topLeftIconRequiresImage = true,
  renderTopRightIcon,
  topRightIconRequiresImage = true,
  autoScaleCornerIcons = false,
  loader,
  placeholderIcon,
  placeholder,
  placeholderProps = {},
  containerProps = {},
  ...rest
}) => {
  const [hasImg, setHasImg] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    onLoad,
    onError,
    height = 75,
    width = 75,
    sx,
    className,
    style,
    onClick,
    onDoubleClick,
    onMouseEnter,
    onMouseLeave,
    reloadTimeoutMs,
    reloadAttempts,
    ..._rest
  } = rest;
  const { className: placeholderClassName, ...restPlaceholderProps } = placeholderProps;
  const { className: containerClassName, ...restContainerProps } = containerProps;

  const [attempts, setAttempts] = useState(reloadAttempts ?? 5);
  useEffect(() => {
    // Reset attempts if the props are changed
    setAttempts(reloadAttempts ?? 5);
  }, [src, reloadAttempts]);

  const handleImgLoad = useCallback(
    e => {
      setHasImg(true);
      setLoading(false);

      if (onLoad) {
        onLoad(e);
      }
    },
    [setHasImg, setLoading, onLoad],
  );

  const mounted = useMounted();

  const handleImgError = useCallback(
    e => {
      setLoading(false);

      // sometimes the image load event seems to fire and then
      // the error fires, ensure hasImg is false
      setHasImg(false);

      let timeoutId: ReturnType<typeof setTimeout>;
      if (attempts && mounted.current) {
        timeoutId = setTimeout(() => setAttempts(i => i - 1), reloadTimeoutMs ?? 2000);
      }

      if (onError && !attempts && mounted.current) {
        onError(e);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    },
    [setLoading, onError, setHasImg, attempts, reloadTimeoutMs, mounted],
  );

  const cornerIconClassName = useMemo(
    () =>
      clsx(styles.cornerIcon, hasImg ? styles.cornerIconWithImg : styles.cornerIconNoImg, {
        [styles.cornerIconAutoScaled]: autoScaleCornerIcons && !hasImg,
      }),
    [hasImg, autoScaleCornerIcons],
  );

  const showBottomLeftIcon =
    (bottomLeftIconRequiresImage ? hasImg : true) && !!renderBottomLeftIcon;
  const showBottomRightIcon =
    (bottomRightIconRequiresImage ? hasImg : true) && !!renderBottomRightIcon;
  const showTopLeftIcon = (topLeftIconRequiresImage ? hasImg : true) && !!renderTopLeftIcon;
  const showTopRightIcon = (topRightIconRequiresImage ? hasImg : true) && !!renderTopRightIcon;

  return (
    <div className={clsx(styles.container, containerClassName)} {...restContainerProps}>
      <img
        alt=''
        src={src}
        key={`${src}-${attempts}`} // remount image if src changes
        {..._rest}
        height={height}
        width={width}
        className={clsx(className, {
          [styles.errorImg]: !hasImg,
          [styles.cursorPointer]: !!onClick || !!onDoubleClick,
        })}
        onLoad={handleImgLoad}
        onError={handleImgError}
        // Prevent margin or padding styles from being applied to
        // keep corner icons aligned consistently
        style={{ ...style, ...inlineStyles.zeroSpacing }}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {showBottomLeftIcon &&
        renderBottomLeftIcon(
          {
            className: clsx(cornerIconClassName, styles.bottomLeftIcon),
          },
          hasImg,
        )}
      {showBottomRightIcon &&
        renderBottomRightIcon(
          {
            className: clsx(cornerIconClassName, styles.bottomRightIcon),
          },
          hasImg,
        )}
      {showTopLeftIcon &&
        renderTopLeftIcon(
          {
            className: clsx(cornerIconClassName, styles.topLeftIcon),
          },
          hasImg,
        )}
      {showTopRightIcon &&
        renderTopRightIcon(
          {
            className: clsx(cornerIconClassName, styles.topRightIcon),
          },
          hasImg,
        )}
      {!hasImg && (
        <Box
          className={clsx('centerChildren', styles.placeholderContainer, className)}
          sx={{ ...sxStyles.placeholderContainer, ...sx }}
          // Prevent margin or padding styles from being
          // applied to keep corner icons aligned consistently
          style={{ ...style, ...inlineStyles.zeroSpacing }}
          height={height}
          width={width}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {loading
            ? loader || (
                <Skeleton
                  variant='rectangular'
                  className={styles.loadingSkeleton}
                  data-testid='default-loader'
                />
              )
            : placeholder || (
                <SvgIcon
                  className={clsx(placeholderClassName, styles.errorPlaceholder)}
                  color='disabled'
                  {...restPlaceholderProps}
                  data-testid='default-placeholder'
                >
                  {placeholderIcon || <PhotoIcon />}
                </SvgIcon>
              )}
        </Box>
      )}
    </div>
  );
};

export default IdsImage;
