import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';
import get from 'lodash.get';

import useLocationMapMetadataOptions from '../../../hooks/useLocationMapMetadataOptions';
import { MediaMetadataType, MediaType } from '../../../constants/media';
import { useUpdatePointOfInterest } from '../../../services/PointOfInterestService';
import { IDataEntityMarker } from '../../../services/types';

import useMapEntityType, {
  MapEntityType,
} from '../../../components/ids-entities-viewer/IdsMapEntityViewer/useMapEntityType';
import useCanModifyEntity from '../../../components/ids-entities-viewer/IdsMapEntityViewer/useCanModifyEntity';
import IdsAutocomplete from '../../../components/ids-inputs/IdsAutocomplete';
import MultiValueAutocompleteOption from '../../../components/ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';

export interface IMapEntityTagsProps {
  disabled?: boolean;
  onUpdate?: (updatedEntity: any) => void;
  locationId: string;
  entityId: string;
  creatorId: string;
  orgId: string;
  entityTags: IDataEntityMarker['metadata'];
  metadataType: MediaMetadataType;
}

const getMutationByType = (type: MapEntityType) => {
  switch (type) {
    case MediaType.PointOfInterest:
      return useUpdatePointOfInterest;
    case MediaType.Asset:
      // @TODO: replace with a real mutation when implemented
      return () => ({ mutateAsync: () => ({ updateAsset: { asset: true } }) });
    default:
      throw new Error(`Mutation not implemented: ${type}`);
  }
};

const getResultPathByType = (type: MapEntityType) => {
  switch (type) {
    case MediaType.PointOfInterest:
      return 'updatePointOfInterest.pointOfInterest';
    case MediaType.Asset:
      // @TODO: replace with a real path when implemented
      return 'updateAsset.asset';
    default:
      throw new Error(`Path not implemented: ${type}`);
  }
};

const getValue = (
  entityTags: IMapEntityTagsProps['entityTags'],
  metadataType: IMapEntityTagsProps['metadataType'],
) => {
  return entityTags.filter(item => item.type === metadataType).map(item => item.id);
};

const MapEntityTags: React.FC<IMapEntityTagsProps> = ({
  onUpdate,
  disabled,
  locationId,
  entityTags,
  orgId,
  entityId,
  creatorId,
  metadataType,
}) => {
  const canModify = useCanModifyEntity(creatorId);

  const entityType = useMapEntityType(entityId);

  const [updating, setUpdating] = useState(false);
  const [value, setValue] = useState<string[]>(() => {
    return getValue(entityTags, metadataType);
  });
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  const updateEntityMutation = getMutationByType(entityType)(locationId);
  const options = useLocationMapMetadataOptions(metadataType);

  const onUpdateHandler = useCallback(
    async (values: string[]) => {
      setUpdating(true);

      const metadata = [
        // Keep other tags that are not displayed in this form
        ...entityTags
          .filter(({ type }) => type !== metadataType)
          .map(({ id, type }) => ({ id, type })),

        // Save new selected tags
        ...values.map(id => ({
          id,
          type: metadataType,
        })),
      ];

      const result = await updateEntityMutation.mutateAsync({
        organizationId: orgId,
        id: entityId,
        metadata,
      });

      const updatedEntity = get(result, getResultPathByType(entityType));

      if (onUpdate) {
        onUpdate(updatedEntity);
      }

      setIsValueChanged(false);
      setUpdating(false);
    },
    [orgId, entityId, updateEntityMutation, onUpdate, metadataType, entityType, entityTags],
  );

  const debouncedHandler = useCallback(
    debounce(onUpdateHandler, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUpdateHandler],
  );

  useEffect(() => {
    setValue(getValue(entityTags, metadataType));
  }, [entityTags, metadataType]);

  return (
    <IdsAutocomplete
      disabled={disabled || updating || !canModify}
      options={options}
      value={value}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.id}
      noOptionsText='No tags found'
      renderOption={(props, o, { selected }) => (
        <MultiValueAutocompleteOption label={o.name} selected={selected} {...props} key={o.id} />
      )}
      disableCloseOnSelect
      multiple
      limitTags={4}
      onChange={async (_, value, reason) => {
        setIsValueChanged(true);
        setValue(value);

        /**
         * Trigger the update mutation if:
         * - all values were deleted by "clear button"
         * - or a value was deleted by "remove option button" if options menu not opened
         */
        if (reason === 'clear' || (reason === 'removeOption' && !isPopupOpened)) {
          await debouncedHandler(value);
        }
      }}
      onOpen={() => setIsPopupOpened(true)}
      onClose={async () => {
        setIsPopupOpened(false);

        /**
         * Trigger the update mutation to save all changes before closing options menu
         */
        if (isValueChanged) {
          await debouncedHandler(value);
        }
      }}
    />
  );
};

export default MapEntityTags;
