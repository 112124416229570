import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import IdsFormAutocompleteField, {
  IIdsFormAutocompleteFieldProps,
} from '../../IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../IdsFormAutocompleteField/SingleValueAutocompleteOption';
import {
  useGetLocationProjectOptions,
  ILocationProjectOptionsQueryData_ProjectEdge,
} from '../../../../services/ProjectService';
import usePrevious from '../../../../hooks/usePrevious';

export interface IIdsFormLocationProjectAutocompleteFieldProps
  extends Omit<IIdsFormAutocompleteFieldProps, 'options'> {
  locationId?: string;
  disableWhileLoading?: boolean;
}

const IdsFormLocationProjectAutocompleteField: React.FC<
  IIdsFormLocationProjectAutocompleteFieldProps
> = ({ locationId, name, disabled, disableWhileLoading, ...rest }) => {
  const { setFieldValue, values } = useFormikContext<any>();

  const {
    data: options,
    isLoading,
    error,
  } = useGetLocationProjectOptions(locationId, { enabled: !!locationId });

  const prevLoading = usePrevious(isLoading);
  const prevOptions = usePrevious(options);

  // Clear value if location changes
  useEffect(() => {
    // Project options just loaded and value is not one of the options
    const justFinishedLoading = !isLoading && prevLoading;
    const optionsChanged = options !== prevOptions;
    const hasInvalidValue =
      values[name] &&
      !options.some(
        (p: ILocationProjectOptionsQueryData_ProjectEdge) => p.node.id === values[name],
      );

    if (justFinishedLoading && optionsChanged && hasInvalidValue) {
      setFieldValue(name, null);
    }
  }, [setFieldValue, name, options, prevOptions, values, isLoading, prevLoading]);

  useEffect(() => {
    if (options.length === 1) {
      // If only 1 option, auto select
      setFieldValue(name, options[0].node.id, true);
    }
  }, [name, options, setFieldValue]);

  return (
    <IdsFormAutocompleteField
      name={name}
      label='Project'
      loading={isLoading}
      disabled={disabled || (disableWhileLoading && isLoading)}
      options={options}
      getOptionLabel={option => option.node.name}
      getOptionValue={option => option.node.id}
      noOptionsText={error ? 'Projects failed to load' : 'No projects found'}
      renderOption={(props, o) => (
        <SingleValueAutocompleteOption label={o.node.name} {...props} key={o.node.id} />
      )}
      {...rest}
    />
  );
};

export default IdsFormLocationProjectAutocompleteField;
