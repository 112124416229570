import React from 'react';

import { Box, ListItemText } from '@mui/material';

import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import IdsListItemMenu from '../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../components/ids-lists/IdsListItemMenuItem';
import { InfoIcon } from '../../../theme/icons';

export const OrgListItemMenu = ({ onManage }) =>
  onManage && (
    <IdsListItemMenu>
      <IdsListItemMenuItem icon={<InfoIcon />} onClick={onManage}>
        Manage
      </IdsListItemMenuItem>
    </IdsListItemMenu>
  );

function OrganizationListItem({ orgItem, onOpen, onManage }) {
  return (
    <IdsListItem
      key={`org${orgItem.node.id}`}
      primaryAction={onOpen}
      secondaryAction={<OrgListItemMenu onManage={onManage} />}
    >
      <IdsListItemChild>
        <Box>
          <ListItemText>{orgItem.node.name}</ListItemText>
        </Box>
      </IdsListItemChild>
    </IdsListItem>
  );
}

export default OrganizationListItem;
