import { useCallback } from 'react';

import { MEDIA_TYPES } from '../constants/media';
import { getUrnType } from '../utils/media';
import { URN_TO_MEDIA_TYPE } from '../utils/media';

import useFilterContext from './useFilterContext';

const useMediaFromUrn = () => {
  const { getTypeData } = useFilterContext();

  const getMediaTypeFromUrn = useCallback(
    urn => {
      if (!urn) return null;

      const urnType = getUrnType(urn);

      if (urnType !== 'embedmodel') {
        return URN_TO_MEDIA_TYPE[urnType];
      }

      // embedmodel is split into 3 media types (need to find which type this is a part of)
      const embedModelTypes = Object.values(MEDIA_TYPES).filter(
        typeDef => typeDef.urnType === 'embedmodel',
      );
      const embedModelData = embedModelTypes.reduce((data, typeDef) => {
        data.push(...getTypeData(typeDef.type));
        return data;
      }, []);
      const model = embedModelData.find(m => m.id === urn);
      return model?.type;
    },
    [getTypeData],
  );

  const getEmbedItemFromUrn = useCallback(
    urn => {
      if (!urn) return null;

      const urnType = getUrnType(urn);

      if (urnType !== 'embedmodel') {
        return URN_TO_MEDIA_TYPE[urnType];
      }

      // embedmodel is split into 3 media types (need to find which type this is a part of)
      const embedModelTypes = Object.values(MEDIA_TYPES).filter(
        typeDef => typeDef.urnType === 'embedmodel',
      );
      const embedModelData = embedModelTypes.reduce((data, typeDef) => {
        data.push(...getTypeData(typeDef.type));
        return data;
      }, []);
      const model = embedModelData.find(m => m.id === urn);
      return model;
    },
    [getTypeData],
  );

  return { getMediaTypeFromUrn, getEmbedItemFromUrn };
};

export default useMediaFromUrn;
