import React from 'react';
import PropTypes from 'prop-types';

import IdsDialog from '../../../components/IdsDialog';

import CreateUserForm from './CreateUserForm';

function CreateUserDialog({ open, onOpen, onClose }) {
  return (
    <>
      <IdsDialog
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        title='Create New User'
        dialogKey='create'
      >
        <CreateUserForm onSubmitSuccess={onClose} onCancel={onClose} />
      </IdsDialog>
    </>
  );
}

CreateUserDialog.defaultProps = {
  open: false,
};

CreateUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default CreateUserDialog;
