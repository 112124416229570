import React, { createContext, useState } from 'react';

interface IRouteDetailsContext {
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const RouteDetailsContext = createContext<IRouteDetailsContext | null>(null);

export interface IRouteDetailsProviderProps {
  children: React.ReactNode;
}

export const RouteDetailsProvider: React.FC<IRouteDetailsProviderProps> = ({ children }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <RouteDetailsContext.Provider value={{ isEditMode, setIsEditMode }}>
      {children}
    </RouteDetailsContext.Provider>
  );
};

export default RouteDetailsContext;
