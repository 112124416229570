import React from 'react';
import { Chip } from '@mui/material';
import { capitalCase } from 'change-case';

export const STATUS_CHIP_COLOR = {
  NEW: 'error',
  IN_PROGRESS: 'warning',
  PUBLISHED: 'success',
  COMPLETED: 'info',
};

const ProjectStatusChip = ({ status, count = null }) => (
  <Chip
    color={STATUS_CHIP_COLOR[status]}
    variant='outlined'
    size='small'
    label={count !== null ? `${count} ${capitalCase(status)}` : capitalCase(status)}
  />
);

export default ProjectStatusChip;
