import React from 'react';
import { SvgIcon } from '@mui/material';

const ThreeDVRIcon = props => (
  <SvgIcon {...props}>
    <path d='m.33,13.45c0,2.61,3.43,4.82,8.17,5.57v1.47c0,.53.63.78.99.41l2.36-2.36c.23-.23.23-.6,0-.83l-2.36-2.36c-.36-.36-.99-.11-.99.42v.88c-3.68-.65-5.83-2.22-5.83-3.19,0-.73,1.25-1.83,3.48-2.6v-2.44C2.67,9.41.33,11.29.33,13.45Z' />
    <path d='m17.86,8.4v2.44c2.23.77,3.48,1.87,3.48,2.6s-1.4,1.96-3.87,2.73c-.48.15-.79.6-.79,1.1,0,.78.76,1.35,1.49,1.12,3.3-1.03,5.51-2.86,5.51-4.95s-2.34-4.03-5.81-5.04Z' />
    <g>
      <path d='m12.34,2.98c-.19-.11-.43-.11-.62,0l-5.11,2.95.62.36.62.36,4.18,2.41,4.18-2.41.62-.36.62-.36-5.11-2.95Z' />
      <path d='m17.29,7.09l-.62.36-4.18,2.41v6.26l5.11-2.95c.19-.11.31-.31.31-.54v-5.9l-.62.36Z' />
      <path d='m7.39,7.45l-.62-.36-.62-.36v5.9c0,.22.12.43.31.54l5.11,2.95v-6.26l-4.18-2.41Z' />
    </g>
  </SvgIcon>
);

export default ThreeDVRIcon;
