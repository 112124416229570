import React, { useCallback, useState } from 'react';
import {
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Popover,
  Box,
  OutlinedInputProps,
  PopoverOrigin,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { CirclePicker, ColorState } from 'react-color';

import styles from './IdsColorSelect.module.css';

export const COLOR_OPTIONS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#41d860',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export enum ColorFormat {
  HEX = 'HEX',
  RGB = 'RGB',
}

export type ColorFormatsType = keyof typeof ColorFormat | ColorFormat;

const anchorOrigin: PopoverOrigin = { horizontal: 'left', vertical: 'bottom' };

export interface IIdsColorSelectProps extends Omit<OutlinedInputProps, 'onChange'> {
  focused?: boolean;
  value?: string;
  onChange?: (colorState: ColorState) => any;
  colorFormat?: ColorFormatsType;
  colorOptions?: string[];
  helperText?: string;
}

const IdsColorSelect: React.FC<IIdsColorSelectProps> = ({
  value,
  onChange,
  label,
  error,
  focused,
  required,
  colorFormat = ColorFormat.HEX,
  colorOptions = COLOR_OPTIONS,
  fullWidth = true,
  helperText = '',
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);

  const handleAnchorRef = useCallback(elem => setAnchorEl(elem), [setAnchorEl]);

  const openColorPicker = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeColorPicker = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = useCallback(
    colorObj => {
      const { hex, rgb } = colorObj;
      const color =
        colorFormat === ColorFormat.HEX ? hex : `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;

      closeColorPicker();

      if (onChange) {
        onChange(color);
      }
    },
    [closeColorPicker, onChange, colorFormat],
  );

  const id = `color-input-${label}`;
  const ArrowBtnIcon = open ? ArrowDropDown : ArrowDropUp;

  return (
    <>
      <FormControl fullWidth={fullWidth}>
        <InputLabel error={error} focused={focused} required={required} htmlFor={id}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={id}
          onClick={openColorPicker}
          startAdornment={
            <Box bgcolor={value} className={styles.colorDisplay} data-testid='color-display' />
          }
          endAdornment={
            <IconButton size='small' className={styles.arrowBtn}>
              <ArrowBtnIcon />
            </IconButton>
          }
          ref={handleAnchorRef}
          label={label}
          required={required}
          readOnly
          className={styles.outlinedInput}
          {...rest}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
      <Popover
        open={open}
        onClose={closeColorPicker}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
      >
        <Box margin={2} data-testid='color-picker'>
          <CirclePicker
            color={value || '#FFFFFF'}
            onChangeComplete={handleChange}
            colors={colorOptions}
          />
        </Box>
      </Popover>
    </>
  );
};

export default IdsColorSelect;
