import React from 'react';
import PropTypes from 'prop-types';

import IdsDialog from '../../../components/IdsDialog';

import CreateProgramForm from './CreateProgramForm';

function CreateProgramDialog({ open, onOpen, onClose }) {
  return (
    <>
      <IdsDialog
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        title='Create New Program'
        dialogKey='create'
      >
        <CreateProgramForm onSubmitSuccess={onClose} onCancel={onClose} />
      </IdsDialog>
    </>
  );
}

CreateProgramDialog.defaultProps = {
  open: false,
};

CreateProgramDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default CreateProgramDialog;
