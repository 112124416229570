import React, { forwardRef, useCallback, useMemo } from 'react';
import { Box, BoxProps } from '@mui/material';
import DeckGL, { DeckGLProps, DeckGLRef, MapViewState, PickingInfo, Viewport } from 'deck.gl/typed';
import { MapView } from '@deck.gl/core/typed';
import { Map } from 'react-map-gl';

import RuntimeConfig from '../../../RuntimeConfig';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';

import styles from './ImmersiveViewer.module.css';

const MapViewNoType = MapView as any;

export type Padding = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export interface IImmersiveViewerProps {
  viewState?: MapViewState | null;
  onViewStateChange?: (viewState: MapViewState) => MapViewState | undefined;
  onViewportChange?: (viewport: Viewport) => void;
  deckProps?: DeckGLProps & React.RefAttributes<DeckGLRef>;
  padding?: Padding;
  containerProps?: Omit<BoxProps, 'ref' | 'className' | 'onContextMenu'>;
  children?: React.ReactNode;
}

const inlineStyles = {
  deck: {
    position: 'relative',
    overflow: 'hidden',
  },
};

const defaultViewState: MapViewState = {
  longitude: -98.579482, // Default to center of US
  latitude: 39.828349, // Default to center of US
  zoom: 2,
  minZoom: 2,
  maxZoom: 24,
  pitch: 0,
  minPitch: 0,
  // This should change depending on the zoom level / altitude
  // (maybe manipulate this separately??)
  maxPitch: 0,
  bearing: 0,
};

const defaultController: DeckGLProps['controller'] = {
  // Disabling doubleclick support makes click handlers more responsive
  doubleClickZoom: false,
};

const defaultPadding: Padding = {
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

export type InteractiveState = {
  isDragging: boolean;
  isHovering: boolean;
};

const getCursor = ({ isDragging, isHovering }: InteractiveState) =>
  isDragging ? 'grabbing' : isHovering ? 'pointer' : 'grab';

const getTooltip = ({ object }: PickingInfo) =>
  object?.tooltip && {
    text: object.tooltip,
    className: styles.tooltip,
  };

// Future thought, think about react-map-gl layer interleaving to support
// streetview with custom raster overlays

/**
 ***Requires** an `ImmersiveViewerProvider` to wrap this or an ancestor component.
 */
export const ImmersiveViewerInContext = forwardRef<any, Partial<IImmersiveViewerProps>>(
  ({ onViewStateChange, deckProps, ...rest }, ref) => {
    const {
      handleDeckRef,
      handleContainerRef,
      useViewState,
      setViewState,
      setViewport,
      useController,
      usePadding,
      useDeckLayers,
    } = useImmersiveViewer();
    const viewState = useViewState();
    const controller = useController();
    const padding = usePadding();
    const deckLayers = useDeckLayers();

    const handleViewStateChange = useCallback(
      (newViewState: MapViewState) => {
        setViewState(newViewState);

        if (onViewStateChange) {
          return onViewStateChange(newViewState);
        }
      },
      [onViewStateChange, setViewState],
    );

    const _handleContainerRef: React.RefCallback<HTMLDivElement> = useCallback(
      (elem: HTMLDivElement | null) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(elem);
          } else {
            ref.current = elem;
          }
        }

        handleContainerRef(elem);
      },
      [ref, handleContainerRef],
    );

    return (
      <ImmersiveViewer
        {...rest}
        ref={_handleContainerRef}
        viewState={viewState}
        onViewStateChange={handleViewStateChange}
        onViewportChange={setViewport}
        deckProps={{
          controller,
          layers: deckLayers,
          ref: handleDeckRef,
          ...deckProps,
        }}
        padding={padding}
      />
    );
  },
);

const ImmersiveViewer = forwardRef<HTMLDivElement, IImmersiveViewerProps>(
  (
    {
      viewState,
      onViewStateChange,
      onViewportChange,
      padding,
      deckProps: { controller, ...otherDeckProps } = {},
      containerProps,
      children,
    },
    ref,
  ) => {
    const handleViewStateChange = useCallback(
      newViewState => {
        if (onViewStateChange) {
          return onViewStateChange(newViewState);
        }
      },
      [onViewStateChange],
    );

    const handleViewportChange = useCallback(
      newViewport => {
        if (onViewportChange) {
          onViewportChange(newViewport);
        }
      },
      [onViewportChange],
    );

    const handleCanvasResize = useCallback(
      ({ width, height }) => {
        // This is needed to handle some edge cases where the viewState's dimensions aren't updated
        // when the canvas resizes. If the viewState isn't manually updated here, the deck layers are misaligned
        // until the next viewState update.
        handleViewStateChange({
          ...viewState,
          width,
          height,
        });
      },
      [handleViewStateChange, viewState],
    );

    const fullViewState = useMemo(
      () => ({
        ...defaultViewState,
        ...viewState,
      }),
      [viewState],
    );

    const positionSet = useMemo(
      () => !!fullViewState.longitude && !!fullViewState.latitude,
      [fullViewState],
    );

    const _controller = useMemo(
      () =>
        typeof controller === 'object'
          ? { ...defaultController, ...controller }
          : !controller && controller !== false
          ? defaultController
          : controller,
      [controller],
    );

    const _padding = useMemo(
      () => ({
        ...defaultPadding,
        ...padding,
      }),
      [padding],
    );

    return (
      <Box
        {...containerProps}
        ref={ref}
        className={styles.viewerContainer}
        onContextMenu={(e: any) => e.preventDefault()}
      >
        {/* Prevent context menu from appearing when manipulating the viewer */}
        {positionSet && (
          <DeckGL
            id='deck-gl-component'
            viewState={fullViewState}
            onViewStateChange={(e: any) => handleViewStateChange(e.viewState)}
            onResize={handleCanvasResize}
            controller={_controller}
            getCursor={getCursor}
            getTooltip={getTooltip}
            style={inlineStyles.deck}
            {...otherDeckProps}
          >
            <MapViewNoType id='viewerMap' padding={_padding}>
              <Map
                mapboxAccessToken={RuntimeConfig.mapboxToken}
                mapStyle={RuntimeConfig.mapboxStyleUrl}
                reuseMaps
                // deckgl doesn't seem to pass on these props of the view state to mapbox, so they need to be set explicitly here
                minZoom={fullViewState.minZoom}
                maxZoom={fullViewState.maxZoom}
                minPitch={fullViewState.minPitch}
                maxPitch={fullViewState.maxPitch}
              />
              {({ viewport }: { viewport: Viewport }) => {
                handleViewportChange(viewport);
              }}
            </MapViewNoType>
            {children}
          </DeckGL>
        )}
      </Box>
    );
  },
);

export default ImmersiveViewer;
