import { atom } from 'recoil';

export const uploadQueueAnchorElState = atom<HTMLElement | null>({
  key: 'uploadQueueAnchorEl',
  default: null,
});

export const uploadQueueOpenState = atom<boolean>({
  key: 'uploadQueueOpen',
  default: false,
});
