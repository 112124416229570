import { FormikContextType, useFormikContext } from 'formik';

export interface IIdsFormElementProps {
  render: (formikContext: FormikContextType<any>) => React.ReactElement;
}

const IdsFormElement: React.FC<IIdsFormElementProps> = ({ render }) => {
  const formikCtx = useFormikContext();
  return render(formikCtx);
};

export default IdsFormElement;
