import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { IDocument, IDocumentVersion } from '../../types';
import IdsDataAttribute from '../../../../components/IdsDataAttribute';

import { getDocumentCategory, getDocumentTags } from '../../utils';

import styles from './styles.module.css';

export interface IViewDocumentVersionProps {
  document?: IDocument | null;
  documentVersion?: IDocumentVersion | null;
}

export const ViewDocumentVersion: React.FC<IViewDocumentVersionProps> = ({
  document,
  documentVersion,
}) => {
  const { name, metadata, description } = document?.node || {};

  const { filename, user } = documentVersion || {};
  const { firstName, lastName } = user || {};

  const category = useMemo(() => getDocumentCategory(metadata)?.value, [metadata]);

  const tags = useMemo(
    () =>
      getDocumentTags(metadata)
        ?.map(it => it.value)
        .join(', '),
    [metadata],
  );

  if (!document || !documentVersion) return null;

  return (
    <Grid container spacing={2} direction='column' className={styles['wrapper']}>
      {/* 1. Document version filename */}
      <IdsDataAttribute label='Filename' value={filename} />
      {/* 2. Document name */}
      <IdsDataAttribute label='Name' value={name} />
      {/* 3. Author name */}
      {Boolean(firstName && lastName) && (
        <IdsDataAttribute label='Author' value={`${firstName} ${lastName}`} />
      )}
      {/* 4. Document category */}
      {category && <IdsDataAttribute label='Category' value={category} />}
      {/* 5. Description */}
      {description && <IdsDataAttribute label='Description' value={description} />}
      {/* 6. Document tags */}
      <IdsDataAttribute label='Tags' value={tags} />
    </Grid>
  );
};

export default ViewDocumentVersion;
