import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { Box, Container } from '@mui/material';

import { useGetOrgPrograms } from '../../services/ProgramsService';
import { activeOrganizationState } from '../../atoms/organizations';

import Page from '../../components/Page';
import IdsSearchableList from '../../components/ids-lists/IdsSearchableList';
import styles from '../../theme/styles';

import { activeTenantState } from '../../atoms/tenants';

import { getOrgProgramEditRoute, getOrgProgramRoute } from '../../utils/routes';

import ProgramListItem from './ProgramListItem';
import ProgramListHeader from './ProgramListHeader';

export const searchProgramItem = (programItem, query) => {
  const searchProps = [programItem.node.name];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

export const sortPrograms = (a, b) => {
  return b.node.id - a.node.id; // Reverse order of creation
};

function Programs() {
  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);

  const { data: programs, error, isLoading } = useGetOrgPrograms(organization.id);

  const navigate = useNavigate();

  const openProgram = useCallback(
    id => {
      navigate(
        getOrgProgramRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          programId: id,
        }),
      );
    },
    [navigate, organization.id, activeTenant],
  );

  const editProgram = useCallback(
    id => {
      navigate(
        getOrgProgramEditRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          programId: id,
        }),
      );
    },
    [navigate, organization.id, activeTenant],
  );

  return (
    <Page title='Programs'>
      <Box sx={styles.rootPage}>
        <Container maxWidth={false}>
          <ProgramListHeader />
          <IdsSearchableList
            items={programs}
            renderItem={programItem => (
              <ProgramListItem
                key={programItem.node.id}
                programItem={programItem}
                onOpen={() => openProgram(programItem.node.id)}
                onEdit={() => editProgram(programItem.node.id)}
              />
            )}
            searchPlaceholder={'Search programs'}
            searchItem={searchProgramItem}
            sortItems={sortPrograms}
            noItemsMessage='No programs'
            noItemsForFilterMessage='No programs match your search'
            loading={isLoading}
            error={error && 'Programs could not be loaded'}
          />
        </Container>
      </Box>
    </Page>
  );
}

export default Programs;
