import { getMediaTypeFromUrn } from '../../../../../../../../../../utils/media';
import queryClient from '../../../../../../../../../../utils/query';

/**
 * Invalidates all possible cache related to this image.
 */
export const invalidateCache = async (imageId: string) => {
  const mediaType = getMediaTypeFromUrn(imageId)!;

  await queryClient.invalidateQueries({
    predicate: query => query.queryKey.includes(imageId) || query.queryKey.includes(mediaType),
    refetchActive: true,
  });
};
