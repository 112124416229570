import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Box, Button } from '@mui/material';

import CancelButton from '../../../../components/ids-buttons/CancelButton';

interface IButtonProps {
  isEditMode: boolean;
  canUpdate: boolean;
  isLoading?: boolean;
  isUpdating: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Buttons: React.FC<IButtonProps> = ({
  isEditMode,
  canUpdate,
  isLoading = false,
  isUpdating,
  setEditMode,
}) => {
  const { dirty, resetForm, isSubmitting } = useFormikContext();
  useEffect(() => {
    if (isSubmitting) {
      setEditMode(false);
    }
  }, [isSubmitting, setEditMode]);

  return (
    <Box display='flex' alignSelf='flex-end' gap={1}>
      {isEditMode && (
        <CancelButton
          type='button'
          onClick={() => {
            resetForm();
            setEditMode(false);
          }}
        />
      )}
      {isEditMode ? (
        <Button disabled={!dirty} variant='contained' type={'submit'}>
          Save
        </Button>
      ) : (
        <Button
          disabled={!canUpdate || isLoading || isUpdating}
          variant='contained'
          type={'button'}
          onClick={() => {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      )}
    </Box>
  );
};
