import * as Yup from 'yup';

import { emailValidation, phoneValidation } from '../../field-validation/contactInfo';
import { USER_ROLES } from '../../constants/users';

const createUserValidation = {
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  title: Yup.string(),
  email: emailValidation.required('Email is required'),
  phone: phoneValidation.required('Phone is required'),
};

const createOrgUserValidation = {
  ...createUserValidation,
  role: Yup.number()
    .oneOf(Object.values(USER_ROLES).map(role => role.id))
    .required('Role is required'),
};

const createTenantUserValidation = {
  ...createUserValidation,
  role: Yup.string()
    .oneOf(
      Object.values(USER_ROLES)
        .filter(role => role.name.includes('tenant') || role.canAssign)
        .map(role => role.name),
    )
    .required('Role is required'),
};

export const createOrgUserValidationSchema = Yup.object().shape(createOrgUserValidation);
export const createTenantUserValidationSchema = Yup.object().shape(createTenantUserValidation);
