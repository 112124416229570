// Note:
// The better place for this fragment and type is component file but
// for some reason there are unitialization error happening.
// So right now it's placed here.

import { gql } from 'graphql-request';

export const OrganizationTabUserFrag = gql`
  fragment OrganizationTabUserFrag on User {
    id
    roles {
      projects {
        id
      }
      roleName
      domainId
      domainType
    }
  }
`;

export interface IOrganizationsTabUserFragType {
  id: string;
  roles: {
    roleName: string;
    domainId: string;
    domainType: string;
    projects: {
      id: string;
    }[];
  }[];
}
