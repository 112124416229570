import React, { MouseEvent } from 'react';
import { Box, Stack, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

export interface IToggleProps {
  label: string;
  activeIcon?: React.ReactNode;
  inactiveIcon?: React.ReactNode;
}

export interface IIdsToggleButtonsProps
  extends Omit<ToggleButtonGroupProps, 'value' | 'exclusive' | 'onChange'> {
  value: boolean | null;
  onChange: (event: MouseEvent<HTMLElement>, value: boolean | null) => void;
  toggleOnProps: IToggleProps;
  toggleOffProps: IToggleProps;
}

const IdsToggleButtons: React.FC<IIdsToggleButtonsProps> = ({
  value,
  onChange,
  toggleOnProps,
  toggleOffProps,
  ...rest
}) => {
  return (
    <ToggleButtonGroup exclusive value={value} onChange={onChange} {...rest}>
      <ToggleButton value={true}>
        <Stack direction='row' spacing={0.5}>
          {value === true || !toggleOnProps.inactiveIcon
            ? toggleOnProps.activeIcon
            : toggleOnProps.inactiveIcon}
          <Box>{toggleOnProps.label}</Box>
        </Stack>
      </ToggleButton>
      <ToggleButton value={false}>
        <Stack direction='row' spacing={0.5}>
          {value === false || !toggleOffProps.inactiveIcon
            ? toggleOffProps.activeIcon
            : toggleOffProps.inactiveIcon}
          <Box>{toggleOffProps.label}</Box>
        </Stack>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default IdsToggleButtons;
