import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Button, Grid, SvgIcon } from '@mui/material';

import { PREFIX } from '../index';
import { AddIcon } from '../../../../theme/icons';

import Card from './Card';

export interface IOrganizationsAccessFormProps {
  canModify: boolean;
}

const OrganizationsAccessForm: React.FC<IOrganizationsAccessFormProps> = ({ canModify }) => {
  const { values } = useFormikContext<any>();

  return (
    <Grid container spacing={2} p={2}>
      <FieldArray name={PREFIX}>
        {({ remove, push }) => (
          <>
            {/* Cards */}
            {values[PREFIX].length > 0 &&
              values[PREFIX].map((_: any, index: number) => (
                <Grid item xs={12} key={`${index}-${values[PREFIX][index].organization}`}>
                  <Card index={index} remove={() => remove(index)} canModify={canModify} />
                </Grid>
              ))}

            {/* Button to add cards */}
            <Grid item xs={12}>
              <Button
                disabled={!canModify}
                onClick={() => push({ role: '', organization: null, projects: [] })}
              >
                <SvgIcon fontSize='small' sx={{ mr: 1 }}>
                  <AddIcon />
                </SvgIcon>
                Add new organization
              </Button>
            </Grid>
          </>
        )}
      </FieldArray>
    </Grid>
  );
};

export default OrganizationsAccessForm;
