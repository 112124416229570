import IDS_COLORS from '../../../theme/ids-colors';

import { ROUTE_POINT_TYPE_ENABLED_KEYS } from '../../../constants/urlStateKeys';
import { ROUTE_POINT_TYPES, RoutePointType } from '../../../constants/media';

import {
  capturedIconColor,
  hdPhotoIconColor,
  panoramaIconColor,
  projectPhotoIconColor,
  white,
} from '../../../components/mapping/layers/media-markers';

import { rgbStrToArray } from '../../../utils/colors';
import { ITypeMetadata } from '../../../context/FilterContext';

import { ILocationPosition } from '../../../services/LocationService';

import { IRoutePoint } from './types';

/**
 * Red dot if:
 * - A point with note data
 * - A point with both image and note data
 */
const getIsDotted = (item: IRoutePoint) => {
  return item.node.isNote;
};

const getDotColor = () => {
  return IDS_COLORS.marker.markerDotFlagged;
};

/**
 * Green background if:
 * - A point with image data
 * - A point with both image and note data
 */
const getBackgroundColor = (item: IRoutePoint) => {
  if (item.node.hasImageData) {
    return IDS_COLORS.marker.captured;
  }

  const colorName = ROUTE_POINT_TYPES[item.node.type].color!.split('.')[2];

  return IDS_COLORS.marker[colorName as keyof typeof IDS_COLORS.marker];
};

const getIconColor = (item: IRoutePoint) => {
  if (item.node.hasImageData) {
    return capturedIconColor;
  }

  switch (item.node.type) {
    case RoutePointType.DslrHdPhoto:
      return hdPhotoIconColor;
    case RoutePointType.ProjectPhoto:
      return projectPhotoIconColor;
    case RoutePointType.DslrPanorama:
    case RoutePointType.Panorama:
      return panoramaIconColor;
    default:
      return white;
  }
};

export const modifiers = {
  getIsDotted,
  getDotColor,
  getIconColor,
  getBackgroundColor,
};

export const getDslrPanoramaClusterProps = (props: Record<string, any>) => {
  return {
    ...props,
    id: RoutePointType.DslrPanorama,
    getClusterIconColor: rgbStrToArray(IDS_COLORS.marker.dslr_360),
  };
};

export const selectPointLat = (d: IRoutePoint) => d.node.position.latitude;
export const selectPointLng = (d: IRoutePoint) => d.node.position.longitude;

export const selectLocationLat = (l: ILocationPosition) => l.location.position.latitude;
export const selectLocationLng = (l: ILocationPosition) => l.location.position.longitude;

export const getDefaultRoutePointTypeMetadata = (types: RoutePointType[]) => {
  return types.reduce((accumulator, type) => {
    if (!accumulator[type]) {
      accumulator[type] = {
        itemsDefaultEnabled: true,
        enabled: true,
        selectId: (i: IRoutePoint) => i.node.id,
        urlKey: ROUTE_POINT_TYPE_ENABLED_KEYS[type],
      };
    }

    return accumulator;
  }, {} as Record<RoutePointType, ITypeMetadata>);
};
