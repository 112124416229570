import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

import KRPanoControl from '../../KRPanoControl';
import ResizeControl, { RESIZE_DIRECTIONS } from '../../../ResizeControl';

import styles from './MediaViewerTabs.module.css';

const sx = {
  contentContainer: {
    backgroundColor: 'background.paper',
  },
  inactiveTabBtn: {
    backgroundColor: 'background.dark',
    '&:hover': {
      backgroundColor: 'background.paper',
    },
  },
  activeTabBtn: {
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: 'background.paper',
    },
  },
  hoverableActiveTabBtn: {
    '&:hover': {
      backgroundColor: 'background.dark',
    },
  },
};

export const TAB_SIZES = {
  HIDDEN: 'hidden',
  RIBBON: 'ribbon',
  EXPANDED: 'expanded',
  FULL: 'full',
};

// Numbers are used as percentages, strings are used as css values
const TAB_SIZE_HEIGHTS = {
  [TAB_SIZES.HIDDEN]: '0px',
  [TAB_SIZES.RIBBON]: '102.5px',
  [TAB_SIZES.EXPANDED]: '284px',
  [TAB_SIZES.FULL]: viewerHeight => `${viewerHeight - 64}px`, // leave some space around the button bar
};

const INDEXED_SIZES = Object.values(TAB_SIZES).reduce((indexes, size, i) => {
  indexes[size] = i;
  return indexes;
}, {});

export const MediaViewerTabsContext = createContext();

export const MediaViewerTabs = ({
  tabs,
  activeTabKey,
  onTabChange,
  onTabResize,
  viewerHeight,
  tabSize,
}) => {
  const tabsObj = useMemo(
    () =>
      tabs?.reduce((obj, t) => {
        obj[t.key] = t;
        return obj;
      }, {}),
    [tabs],
  );

  const [size, setSize] = useState(tabSize || TAB_SIZES.RIBBON);
  const [previousSize, setPreviousSize] = useState(TAB_SIZES.RIBBON);
  const canGrow = useMemo(
    () => INDEXED_SIZES[size] < Object.values(INDEXED_SIZES).length - 1,
    [size],
  );
  const canShrink = useMemo(() => INDEXED_SIZES[size] > 0, [size]);

  const cssHeight = useMemo(() => {
    const rawHeight = TAB_SIZE_HEIGHTS[size];
    if (typeof rawHeight === 'function') {
      // Calculate pixel height from height percentage
      return rawHeight(viewerHeight);
    }
    return rawHeight; // rawHeight is already a css height string
  }, [viewerHeight, size]);

  const _setSize = useCallback(
    newSize => {
      setPreviousSize(size);
      setSize(newSize);

      if (onTabResize) {
        onTabResize(newSize);
      }
    },
    [size, setSize, setPreviousSize, onTabResize],
  );

  const handleGrow = useCallback(() => {
    if (canGrow) {
      setPreviousSize();
      _setSize(Object.values(TAB_SIZES)[INDEXED_SIZES[size] + 1]);
    }
  }, [size, _setSize, canGrow]);

  const handleShrink = useCallback(() => {
    if (canShrink) {
      _setSize(Object.values(TAB_SIZES)[INDEXED_SIZES[size] - 1]);
    }
  }, [size, _setSize, canShrink]);

  useEffect(() => {
    onTabChange(tabs[0].key); // Default to the first tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const activeTab = tabs.find(t => t.key === activeTabKey);

    // consider no active tab to be disabled so new tab can be selected
    const activeTabDisabled = activeTab ? activeTab.disabled : true;

    if (activeTabDisabled) {
      const newActiveTabKey = tabs.find(t => !t.disabled)?.key;
      if (newActiveTabKey) {
        onTabChange(newActiveTabKey); // Switch to a tab that's not disabled
      }
    }
  }, [tabs, activeTabKey, onTabChange]);

  useEffect(() => {
    if (tabSize) {
      setSize(tabSize);
    }
  }, [tabSize]);

  const handleTabClick = useCallback(
    tabKey => {
      const hidden = size === TAB_SIZES.HIDDEN;

      if (tabKey !== activeTabKey) {
        // inactive tab clicked
        onTabChange(tabKey);
      } else if (!hidden) {
        // active tab clicked
        _setSize(TAB_SIZES.HIDDEN); // shortcut to hide it
        return;
      }

      if (hidden) {
        // Tab click expands the tab when hidden
        _setSize(previousSize); // Restore the previous size before being hidden
      }
    },
    [activeTabKey, onTabChange, size, previousSize, _setSize],
  );

  const content = useMemo(() => {
    if (!activeTabKey) return null;
    return tabsObj[activeTabKey].render(size, cssHeight);
  }, [activeTabKey, tabsObj, size, cssHeight]);

  const enabledTabs = useMemo(() => tabs?.filter(t => !t.disabled) || [], [tabs]);

  const hidden = size === TAB_SIZES.HIDDEN;

  return (
    <MediaViewerTabsContext.Provider value={{ setSize: _setSize, size }}>
      {enabledTabs.length > 0 && (
        <div
          className={clsx(styles.container, {
            [styles.tabContainerOffset]: hidden,
          })}
        >
          {enabledTabs.map(t => (
            <KRPanoControl key={t.key} className={styles.tabContainer}>
              <Button
                onClick={() => handleTabClick(t.key)}
                className={styles.tab}
                disableRipple={t.key === activeTabKey && !hidden}
                sx={
                  t.key === activeTabKey
                    ? // When hidden, the active tab can expand the panel, so it should show hover feedback
                      // If not hidden, the active tab doesn't do anything when clicked
                      size === TAB_SIZES.HIDDEN
                      ? { ...sx.activeTabBtn, ...sx.hoverableActiveTabBtn }
                      : sx.activeTabBtn
                    : sx.inactiveTabBtn
                }
              >
                {t.label}
              </Button>
            </KRPanoControl>
          ))}
          {size !== TAB_SIZES.HIDDEN && ( // When hidden, the view can be expanded by clicking the tab button
            <KRPanoControl className={styles.resizeControlContainer}>
              <ResizeControl
                direction={RESIZE_DIRECTIONS.UP}
                onGrow={handleGrow}
                canGrow={canGrow}
                onShrink={handleShrink}
                canShrink={canShrink}
              />
            </KRPanoControl>
          )}

          <Box
            id='media-viewer-tab-content'
            sx={sx.contentContainer}
            className={styles.contentContainer}
            style={{ height: cssHeight }}
          >
            <KRPanoControl>{content}</KRPanoControl>
          </Box>
        </div>
      )}
    </MediaViewerTabsContext.Provider>
  );
};

MediaViewerTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired, // (tabSize, cssHeight) => { return <Content/> }
      disabled: PropTypes.bool, // If disabled the tab will not be shown
    }),
  ).isRequired,
  activeTabKey: PropTypes.string,
  tabSize: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  onTabResize: PropTypes.func,
};

export const MediaViewerTabsConsumer = MediaViewerTabsContext.Consumer;

export default MediaViewerTabs;
