import { atom } from 'recoil';

import { IRouteRouteData } from '../services/RouteService';

// Active Project
interface IActiveProjectState {
  id: string;
  locationId: string;
  name: string;
}

export const activeProjectState = atom<IActiveProjectState | null>({
  key: 'activeProjectState',
  default: null,
});

// Active Project Assignment
interface IActiveProjectAssignmentState {
  id: string;
  title: string;
}

export const activeProjectAssignmentState = atom<IActiveProjectAssignmentState | null>({
  key: 'activeProjectAssignmentState',
  default: null,
});

// Active Project Assignment Response
interface IActiveProjectAssignmentResponseState {
  id: string;
}

export const activeProjectAssignmentResponseState =
  atom<IActiveProjectAssignmentResponseState | null>({
    key: 'activeProjectAssignmentResponseState',
    default: null,
  });

export const activeProjectRouteState = atom<IRouteRouteData['route'] | null>({
  key: 'activeProjectRouteState',
  default: null,
});
