import * as Yup from 'yup';

import {
  streetValidation,
  cityValidation,
  stateValidation,
  zipValidation,
} from '../../field-validation/address';
import {
  latitudeValidation,
  longitudeValidation,
  headingValidation,
} from '../../field-validation/geospatial';

export const locationValidation = {
  name: Yup.string().required('Name is required'),
  address: streetValidation,
  city: cityValidation,
  state: stateValidation.max(4, 'Maximum of 4 characters'),
  zip: zipValidation,
  yearBuilt: Yup.number().typeError('Must be a number').positive('Must be a positive number'),
  latitude: latitudeValidation.required('Latitude is required'),
  longitude: longitudeValidation.required('Longitude is required'),
  heading: headingValidation,
};

export const locationValidationSchema = Yup.object().shape(locationValidation);
