import { useCallback } from 'react';

import { RoutePointType } from '../../../../constants/media';
import {
  IMetadataTypeFilterBuilder,
  METADATA_TYPE_TO_FILTER,
} from '../../../../components/mapping/LocationMapMenu/useMenuBuilder';
import { IRoutePointDetails } from '../../../../services/RouteService';

const useGetPointsMetadataTypes = () => {
  return useCallback((routePoints: IRoutePointDetails[]) => {
    const pointTypes: RoutePointType[] = [];

    const metadataTypes = routePoints.reduce((accumulator, point) => {
      point.metadata.forEach(m => {
        // Skip metadata that is not supported.
        if (!(m.type in METADATA_TYPE_TO_FILTER)) {
          return;
        }

        if (!accumulator[m.type]) {
          accumulator[m.type] = [{ id: m.id, name: m.value }];

          return;
        }

        if (!accumulator[m.type]!.find(item => item.id === m.id)) {
          accumulator[m.type]!.push({ id: m.id, name: m.value });
        }
      });

      if (!pointTypes.includes(point.type)) {
        pointTypes.push(point.type);
      }

      return accumulator;
    }, {} as IMetadataTypeFilterBuilder);

    return {
      pointTypes,
      metadataTypes,
    };
  }, []);
};

export default useGetPointsMetadataTypes;
