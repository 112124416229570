import AreaOfInterestIcon from './ids-icons/AreaOfInterestIcon';
import AreaOfInterestOutlinedIcon from './ids-icons/AreaOfInterestOutlinedIcon';
import AssetIcon from './ids-icons/AssetIcon';
import AssetOutlinedIcon from './ids-icons/AssetOutlinedIcon';
import PanoramaIcon from './ids-icons/PanoramaIcon';
import PanoramaOutlinedIcon from './ids-icons/PanoramaOutlinedIcon';
import VideoIcon from './ids-icons/VideoIcon';
import VideoOutlinedIcon from './ids-icons/VideoOutlinedIcon';
import ThreeDIcon from './ids-icons/ThreeDIcon';
import ThreeDOutlinedIcon from './ids-icons/ThreeDOutlinedIcon';
import ThreeDVRIcon from './ids-icons/ThreeDVRIcon';
import ThreeVROutlinedIcon from './ids-icons/ThreeDVROutlinedIcon';
import NotFlaggedIcon from './ids-icons/NotFlaggedIcon';
import RasterOverlayIcon from './ids-icons/RasterOverlayIcon';

export {
  AreaOfInterestIcon,
  AreaOfInterestOutlinedIcon,
  AssetIcon,
  AssetOutlinedIcon,
  PanoramaIcon,
  PanoramaOutlinedIcon,
  VideoIcon,
  VideoOutlinedIcon,
  ThreeDIcon,
  ThreeDOutlinedIcon,
  ThreeDVRIcon,
  ThreeVROutlinedIcon,
  NotFlaggedIcon,
  RasterOverlayIcon,
};

export {
  ToggleRight as ActivateIcon,
  PlusCircle as AddIcon,
  BarChart as BarChartIcon,
  Clipboard as AssignmentIcon,
  ToggleLeft as DeactivateIcon,
  Download as ExportIcon,
  FileText as DocumentIcon,
  Filter as FilterIcon,
  Upload as ImportIcon,
  MapPin as LocationIcon,
  Map as MapIcon,
  MapPin as MapPinIcon,
  Menu as MenuIcon,
  Folder as ProgramIcon,
  Calendar as ProjectIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
} from 'react-feather';

export {
  ArrowCircleDown as ArrowCircleDownIcon,
  ArrowCircleUp as ArrowCircleUpIcon,
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
  ArrowForward as ArrowForwardIcon,
  KeyboardDoubleArrowLeft as DoubleArrowLeft,
  KeyboardDoubleArrowRight as DoubleArrowRight,
  KeyboardArrowUp as ArrowUpIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Cancel as CancelIcon,
  Close as CloseIcon,
  PermMedia as CollectionIcon,
  Place as MapMarkerIcon,
  Delete as DeleteIcon,
  DeleteOutline as DeleteOutlineIcon,
  EditLocationAltOutlined as EditMarkerIcon,
  WhereToVote as EditMarkerSaveIcon,
  EditOutlined as EditOutlineIcon,
  Error as ErrorIcon,
  ExpandMore as ExpandIcon,
  Info as InfoIcon,
  InfoOutlined as InfoOutlinedIcon,
  FilterAlt as FilterIconFilled,
  FilterAltOff as FilterIconFilledOff,
  Flag as FlagIcon,
  FlagOutlined as FlagOutlinedIcon,
  Hd as HDPhotoIcon,
  HdOutlined as HDPhotoOutlinedIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  LayersClear as LayersIconClear,
  Lock as LockIcon,
  LockOpen as UnlockIcon,
  SquareFoot as MeasureIcon,
  MoreVert as MoreVerticalIcon,
  NavigateNext as NavigateNextIcon,
  OpenInNew as OpenInNewIcon,
  Reply as ReplyIcon,
  Save as SaveIcon,
  Send as SendIcon,
  PhotoOutlined as PhotoIcon,
  PhotoLibrary as PhotosIcon,
  PhotoSizeSelectActual as PhotoSizeSelectActualIcon,
  PhotoSizeSelectLarge as PhotoSizeSelectLargeIcon,
  Photo as ProjectPhotoIcon,
  PhotoOutlined as ProjectPhotoOutlinedIcon,
  RemoveCircle as RemoveIcon,
  Replay as ResetIcon,
  RouteOutlined as RouteIcon,
  AccountTreeOutlined as OrganizationIcon,
  Info as PointOfInterestIcon,
  InfoOutlined as PointOfInterestOutlinedIcon,
  Publish as PublishIcon,
  CheckCircle as SuccessIcon,
  Build as ToolIcon,
  CloudUpload as UploadIcon,
  CloudUploadOutlined as UploadOutlinedIcon,
  InvertColors as XRayModeOnIcon,
  InvertColorsOff as XRayModeOffIcon,
  Add as ZoomInIcon,
  Remove as ZoomOutIcon,
  PostAdd as AddDocument,
  Help as HelpIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlinedIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  HelpOutline as HelpOutlineIcon,
  ContentCopy as ContentCopyIcon,
  DragIndicator as DragIndicatorIcon,
  FormatColorFill as FormatColorFillIcon,
  HighlightAlt as HighlightAltIcon,
} from '@mui/icons-material';

export const SMALL_ICON_SIZE = 16;
