import React, { useMemo, useState } from 'react';
import { Button, Grid, SvgIcon } from '@mui/material';

import { AddIcon } from '../../../theme/icons';
import CreateProjectDialog from '../CreateProjectDialog';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';
import '../../../theme/globalStyles.css';

function ProjectListHeader() {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { userHasPermission } = usePermissions();

  const canCreate = useMemo(
    () => userHasPermission('create', EntityType.Project),
    [userHasPermission],
  );

  return (
    <NestedOrgViewHeader
      currentCrumb='Projects'
      actions={
        <>
          {canCreate && (
            <Grid item>
              <Button
                color='secondary'
                variant='contained'
                onClick={() => {
                  setCreateDialogOpen(true);
                }}
              >
                <SvgIcon fontSize='small' className='buttonIcon'>
                  <AddIcon />
                </SvgIcon>
                New Project
              </Button>
            </Grid>
          )}
        </>
      }
      dialogs={
        <CreateProjectDialog
          open={createDialogOpen}
          onOpen={() => {
            setCreateDialogOpen(true);
          }}
          onClose={() => {
            setCreateDialogOpen(false);
          }}
        />
      }
    />
  );
}

export default ProjectListHeader;
