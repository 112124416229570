import { useMemo } from 'react';

import { IProjectRouteListItemData } from '../../../../services/fragments';

/**
 * Accepts "simplified" route points, so it can be reused in the routes list
 * and route details page.
 */
const useRouteProgress = (routePoints: IProjectRouteListItemData['routePoints']) => {
  return useMemo(() => {
    /**
     * If there is a RouteImage or a note for a routePoint,
     * then it is considered completed.
     */
    const completedPoints = routePoints.filter(
      point => point.images.length > 0 || point.notes.length > 0,
    ).length;

    const percentage = completedPoints / routePoints.length;

    return {
      label: `${completedPoints} / ${routePoints.length}`,
      // Check for NaN or Infinite.
      percentage: percentage ? Math.trunc(percentage * 100) : 0,
    };
  }, [routePoints]);
};

export default useRouteProgress;
