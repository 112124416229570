import React from 'react';

import IdsNestedViewHeader from '../../../../components/IdsNestedViewHeader';
import { HomeIcon } from '../../../../theme/icons';
import { IIdsNestedViewHeaderProps } from '../../../../components/IdsNestedViewHeader';
import { getTenantsRoute } from '../../../../utils/routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantViewHeaderProps
  extends Optional<IIdsNestedViewHeaderProps, 'breadcrumbs'> {}

const TenantViewHeader: React.FC<ITenantViewHeaderProps> = ({
  breadcrumbs = [],
  currentCrumb,
  ...rest
}) => {
  return (
    <IdsNestedViewHeader
      breadcrumbs={[{ label: <HomeIcon />, route: getTenantsRoute({}) }, ...breadcrumbs]}
      currentCrumb={currentCrumb}
      {...rest}
    />
  );
};

export default TenantViewHeader;
