import { useMemo } from 'react';

import { gql } from 'graphql-request';

import { useMutation } from 'react-query';

import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import queryClient from '../utils/query';
import { MediaType } from '../constants/media';

export const useCustomFieldsKeys = () => {
  const queryIdKey = useQueryKeyId();

  return useMemo(() => {
    const customFieldsKeys = {
      all: ['custom_fields', ...queryIdKey],
      image: (urn: string) => [...customFieldsKeys.all, 'image', urn],
    };

    return customFieldsKeys;
  }, [queryIdKey]);
};

const GetImageCustomFieldsQuery = gql`
  query GetImageCustomFieldsQuery($urn: String!, $orgId: ID, $tenantId: ID) {
    image(urn: $urn, organizationId: $orgId, tenantId: $tenantId) {
      availableCustomFieldMetadata {
        id
        type
        customValueAllowed
        frontendLabel
        frontendType
        multipleValuesAllowed
        required
        values {
          name
          id
        }
      }
      customFieldMetadata {
        value
        type
        id
      }
    }
  }
`;

export enum CustomFieldInputType {
  autocomplete = 'autocomplete',
  select = 'select',
  checkbox = 'checkbox',
  radio = 'radio',
  text = 'text',
  textarea = 'textarea',
  datepicker = 'datepicker',
}
export type CustomFieldInputTypes = keyof typeof CustomFieldInputType | CustomFieldInputType;

export interface IImageAvailableCustomFieldMetadata {
  id: string;
  type: string;
  customValuesAllowed: boolean;
  frontendLabel: string;
  frontendType: CustomFieldInputType;
  multipleValuesAllowed: boolean;
  required: boolean;
  values: { name: string; id: string }[];
}

export interface IImageCustomFieldMetadata {
  id: string;
  value: string;
  type: string;
}

export interface IGetImageCustomFieldsQueryResponse {
  image: {
    availableCustomFieldMetadata: IImageAvailableCustomFieldMetadata[];
    customFieldMetadata: IImageCustomFieldMetadata[];
  };
}

export const useGetImageCustomFields = (urn: string) => {
  const customFieldsKeys = useCustomFieldsKeys();

  return useOrgGraphQuery<IGetImageCustomFieldsQueryResponse>(
    customFieldsKeys.image(urn),
    GetImageCustomFieldsQuery,
    {
      urn,
    },
  );
};

// Update media customfields
interface ICustomFieldsMetadata {
  fieldId: string;
  id: string;
}

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

const UpdateMediaCustomFieldsProjectPhoto = gql`
  mutation UpdateProjectPhoto(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateProjectPhotoInput!
  ) {
    updateProjectPhoto(where: $where, input: $input) {
      image {
        id
        customFieldMetadata {
          id
          type
          value
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
const UpdateMediaCustomFieldsHdPhoto = gql`
  mutation UpdateHdPhoto($where: WhereUniqueIdOrganizationInput!, $input: UpdateHdPhotoInput!) {
    updateHdPhoto(where: $where, input: $input) {
      image {
        id
        customFieldMetadata {
          id
          type
          value
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
const UpdateMediaCustomFieldsPanorama = gql`
  mutation UpdatePanorama($where: WhereUniqueIdOrganizationInput!, $input: UpdatePanoramaInput!) {
    updatePanorama(where: $where, input: $input) {
      image {
        id
        customFieldMetadata {
          id
          type
          value
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

interface IUpdateMediaCustomFieldsPayload {
  id: number | string;
  orgId: number | string;
  customFieldMetadata: ICustomFieldsMetadata[];
}

const updateHdPhotoCustomFields = ({
  id,
  orgId,
  customFieldMetadata,
}: IUpdateMediaCustomFieldsPayload) => {
  return buildGraphMutationFn(UpdateMediaCustomFieldsHdPhoto)({
    where: {
      id,
      organizationId: orgId,
    },
    input: {
      customFieldMetadata,
    },
  });
};

const updateProjectPhotoCustomFields = ({
  id,
  orgId,
  customFieldMetadata,
}: IUpdateMediaCustomFieldsPayload) => {
  return buildGraphMutationFn(UpdateMediaCustomFieldsProjectPhoto)({
    where: {
      id,
      organizationId: orgId,
    },
    input: {
      customFieldMetadata,
    },
  });
};

const updatePanoramaCustomFields = ({
  id,
  orgId,
  customFieldMetadata,
}: IUpdateMediaCustomFieldsPayload) => {
  return buildGraphMutationFn(UpdateMediaCustomFieldsPanorama)({
    where: {
      id,
      organizationId: orgId,
    },
    input: {
      customFieldMetadata,
    },
  });
};

export function useUpdateHdPhotoCustomFields(imageId: string) {
  const customFieldsKeys = useCustomFieldsKeys();
  return useMutation(updateHdPhotoCustomFields, {
    onError,
    onSuccess: async (_, { id }) => {
      queryClient.invalidateQueries(customFieldsKeys.image(imageId), {
        refetchActive: true,
      });
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes(MediaType.HDPhoto) || query.queryKey.includes(id.toString()),
        refetchActive: false,
      });
    },
  });
}

export function useUpdateProjectPhotoCustomFields(imageId: string) {
  const customFieldsKeys = useCustomFieldsKeys();
  return useMutation(updateProjectPhotoCustomFields, {
    onError,
    onSuccess: async (_, { id }) => {
      queryClient.invalidateQueries(customFieldsKeys.image(imageId), {
        refetchActive: true,
      });
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes(MediaType.ProjectPhoto) || query.queryKey.includes(id.toString()),
        refetchActive: false,
      });
    },
  });
}

export function useUpdatePanoramaCustomFields(imageId: string) {
  const customFieldsKeys = useCustomFieldsKeys();

  return useMutation(updatePanoramaCustomFields, {
    onError,
    onSuccess: async (_, { id }) => {
      queryClient.invalidateQueries(customFieldsKeys.image(imageId), {
        refetchActive: true,
      });
      await queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes(MediaType.PanoramicPhoto) ||
          query.queryKey.includes(id.toString()),
        refetchActive: false,
      });
    },
  });
}
