import React from 'react';
import { Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getOrgMapRoute, getOrgRoute } from '../../../../utils/routes';

import { activeTenantState } from '../../../../atoms/tenants';
import { activeOrganizationState } from '../../../../atoms/organizations';

import ActiveOrgLogo from '../../../../components/ActiveOrgLogo';

import MenuIconButton, { IMenuIconButtonProps } from '../../../MenuIconButton';

import styles from './NavHeader.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INavHeaderProps extends IMenuIconButtonProps {}

const NavHeader: React.FC<INavHeaderProps> = ({ expanded, onClick }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  return (
    <Box p={2}>
      <Box display='flex' justifyContent='center' alignItems='center' alignContent='center'>
        <RouterLink
          to={getOrgMapRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg?.id,
          })}
        >
          {activeOrg && <ActiveOrgLogo fallbackLogo='dark' className={styles.orgLogo} />}
        </RouterLink>
        <MenuIconButton expanded={expanded} onClick={onClick} />
      </Box>
      <Box mt={2} textAlign='center'>
        <Link
          component={RouterLink}
          to={getOrgRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg?.id,
          })}
          variant='h5'
          color='textPrimary'
          underline='none'
        >
          {!activeOrg ? '...' : activeOrg.name}
        </Link>
      </Box>
    </Box>
  );
};

export default NavHeader;
