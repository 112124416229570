import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { gql } from 'graphql-request';

import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import useGraphQuery from '../hooks/useGraphQuery';
import { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import axios from '../utils/axios';
import queryClient from '../utils/query';

export const useOrganizationKeys = () => {
  const queryIdKey = useQueryKeyId();

  return useMemo(() => {
    const organizationKeys = {
      all: ['organizations', ...queryIdKey],
      lists: tenantId => [...organizationKeys.all, 'list', tenantId],
      route: id => [...organizationKeys.all, id],
      detail: id => [...organizationKeys.route(id), 'detail'],
    };

    return organizationKeys;
  }, [queryIdKey]);
};

const onError = (error, variables, context) => {
  context?.mutation?.reset();
};

const createOrganization = ({ name, tenant_id }) => {
  return axios.post('/api/v2/organizations', {
    organization: {
      name,
      tenant_id,
      kind: 'client',
    },
  });
};

export function useCreateOrganization(tenantId, onSuccess) {
  const organizationKeys = useOrganizationKeys();

  return useMutation(createOrganization, {
    onError,
    onSuccess: async () => {
      if (onSuccess) {
        onSuccess();
      }

      await queryClient.invalidateQueries(organizationKeys.lists(tenantId), {
        refetchActive: true,
      });
    },
  });
}

const UpdateOrganizationMutation = gql`
  mutation UpdateOrganization($where: WhereOrganizationInput!, $input: UpdateOrganizationInput!) {
    updateOrganization(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const updateOrganization = ({ organizationId, ...rest }) => {
  return buildGraphMutationFn(UpdateOrganizationMutation)({
    input: {
      ...rest,
    },
    where: {
      organizationId,
    },
  });
};

export const useUpdateOrganization = organizationId => {
  const organizationKeys = useOrganizationKeys();

  return useMutation(updateOrganization, {
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(organizationKeys.detail(organizationId), {
        refetchActive: true,
      });
    },
  });
};

const OrganizationCustomFields = gql`
  query CustomFields($organizationId: ID!) {
    organization(id: $organizationId) {
      customFields {
        name
        frontendLabel
        values {
          id
          value
          assignableId
          assignableType
          isEnabled
        }
      }
    }
  }
`;

/**
 * @return {
 *   {
 *     data: {
 *       organization: {
 *         customFields: Object[]
 *       }
 *     }
 *   }
 * }
 */
export const useGetOrganizationCustomFields = orgId => {
  return useGraphQuery(`custom-fields-${orgId}`, OrganizationCustomFields, {
    organizationId: orgId,
  });
};

const OrganizationMetadataTypes = gql`
  query MetadataTypes($organizationId: ID!) {
    organization(id: $organizationId) {
      metadataTypes {
        type
        label
        values {
          id
          name
        }
      }
    }
  }
`;

export const useGetOrganizationMetadataTypes = orgId => {
  return useGraphQuery(`metadata-types-${orgId}`, OrganizationMetadataTypes, {
    organizationId: orgId,
  });
};

const OrganizationsListQuery = gql`
  query Organizations($take: Int, $after: String, $tenantId: ID) {
    organizations(take: $take, after: $after, tenantId: $tenantId) {
      edges {
        cursor
        node {
          name
          id
          kind
          isActive
          myUserRole
        }
      }
    }
  }
`;

export const useGetAllOrganizations = tenantId => {
  const organizationKeys = useOrganizationKeys();

  const useOrganizations = (take, after) =>
    useGraphQuery(
      [...organizationKeys.lists(tenantId), `take-${take}`, `after-${after}`],
      OrganizationsListQuery,
      { take, after, tenantId },
    );

  return useCursorPaginatedQuery({
    useQuery: useOrganizations,
    defaultTake: 50,
    selectConnection: data => data?.organizations,
  });
};
