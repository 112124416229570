import { gql } from 'graphql-request';

import buildIdsLayer from '../IdsLayer';
import { LAYER_TYPE } from '../../../../context/ImmersiveViewerContext';
import IconClusterLayer from '../IconClusterLayer';

export const LocationMarkerFrag = gql`
  fragment LocationMarkerFrag on Location {
    position {
      latitude
      longitude
    }
    heading
  }
`;

const markerIconMapping = {
  locationMarker: {
    x: 0,
    y: 0,
    width: 128,
    height: 128,
    anchorY: 128,
    mask: true,
  },
};

const LocationMarkerProps = {
  id: 'location-marker-icon',
  pickable: true,
  getPosition: d => [d.node.position.longitude, d.node.position.latitude],
  iconAtlas: '/static/icons/pin-icon-atlas.png',
  iconMapping: markerIconMapping,
  getIcon: d => 'locationMarker',
  getSize: 40,
  getColor: [2, 136, 209, 255],
  getClusterIconSize: d => 40,
  clusterRadius: 50,
  maxClusterZoom: 13,
  getClusterIconColor: [2, 136, 209, 255],
  getTextColor: [40, 40, 40, 255],
  // Prevents transparent pixels from being discarded, creating a hole in the icon that can be clicked through
  alphaCutoff: 0,
};

export const buildLocationMarkerLayer = (locations, iconClusterProps, idsLayerProps) => {
  if (!locations) {
    return null;
  }

  const deckLayer = new IconClusterLayer({
    ...LocationMarkerProps,
    data: locations,
    ...iconClusterProps,
  });

  return buildIdsLayer(deckLayer, {
    ...idsLayerProps,
    type: LAYER_TYPE.LOCATION_MARKER,
  });
};
