import { ElementType } from 'react';

import {
  ProjectPhotoIcon,
  ProjectPhotoOutlinedIcon,
  HDPhotoIcon,
  HDPhotoOutlinedIcon,
  PanoramaIcon,
  PanoramaOutlinedIcon,
  VideoIcon,
  VideoOutlinedIcon,
  ThreeDIcon,
  ThreeDOutlinedIcon,
  ThreeDVRIcon,
  ThreeVROutlinedIcon,
  PointOfInterestIcon,
  PointOfInterestOutlinedIcon,
  AreaOfInterestIcon,
  AreaOfInterestOutlinedIcon,
  AssetIcon,
  AssetOutlinedIcon,
} from '../theme/icons';

import { EntityType } from './entities';

export interface IEdge {
  node: INode;
}

export type INode = Record<string, any>;

export enum MediaType {
  ProjectPhoto = 'PROJECT_PHOTO',
  HDPhoto = 'FLAT_IMAGE',
  PanoramicPhoto = 'THREE_SIXTY_IMAGE',
  RasterOverlay = 'RASTER_OVERLAY',
  VectorOverlay = 'VECTOR_OVERLAY',
  Video = 'Video',
  ThreeDModel = '3D Model',
  ThreeDVR = 'VR Showcase',
  PointOfInterest = 'POINT_OF_INTEREST',
  AreaOfInterest = 'AREA_OF_INTEREST',
  Asset = 'ASSET',
}

export enum RoutePointType {
  DslrHdPhoto = 'DslrHdPhoto',
  DslrPanorama = 'DslrPanorama',
  Panorama = 'Panorama',
  ProjectPhoto = 'ProjectPhoto',
}

export type ImageType = MediaType.ProjectPhoto | MediaType.HDPhoto | MediaType.PanoramicPhoto;

export interface IMediaTypeDef {
  type: MediaType;
  urnType: string;
  selectItem: ((d: IEdge) => INode) | ((d: INode) => INode);
  entityType?: EntityType;
  imageAnswerType?: string;
  label: string;
  singularLabel: string;
  singularLabelLowerCase: string;
  genericDescriptor: string;
  icon?: ElementType;
  outlinedIcon?: ElementType;
  color?: string;
}

export const MEDIA_TYPES: Record<MediaType, IMediaTypeDef> = {
  [MediaType.ProjectPhoto]: {
    type: MediaType.ProjectPhoto,
    urnType: 'projectphoto',
    entityType: EntityType.ProjectPhoto,
    imageAnswerType: 'UserPhoto',
    selectItem: (d: IEdge) => d.node,
    label: 'Project Photos',
    singularLabel: 'Project Photo',
    singularLabelLowerCase: 'project photo',
    genericDescriptor: 'image',
    icon: ProjectPhotoIcon,
    outlinedIcon: ProjectPhotoOutlinedIcon,
    color: 'ids.marker.photo_simple',
  },
  [MediaType.HDPhoto]: {
    type: MediaType.HDPhoto,
    urnType: 'photo',
    entityType: EntityType.HDPhoto,
    imageAnswerType: 'Photo',
    selectItem: (d: IEdge) => d.node,
    label: 'HD Images',
    singularLabel: 'HD Image',
    singularLabelLowerCase: 'HD image',
    genericDescriptor: 'image',
    icon: HDPhotoIcon,
    outlinedIcon: HDPhotoOutlinedIcon,
    color: 'ids.marker.photo_hd',
  },
  [MediaType.PanoramicPhoto]: {
    type: MediaType.PanoramicPhoto,
    urnType: 'panorama',
    entityType: EntityType.Panorama,
    imageAnswerType: 'Panorama',
    selectItem: (d: IEdge) => d.node,
    label: '360 Images',
    singularLabel: '360 Image',
    singularLabelLowerCase: '360 image',
    genericDescriptor: 'image',
    icon: PanoramaIcon,
    outlinedIcon: PanoramaOutlinedIcon,
    color: 'ids.marker.photo_360',
  },
  [MediaType.RasterOverlay]: {
    type: MediaType.RasterOverlay,
    entityType: EntityType.RasterOverlay,
    urnType: 'rasteroverlay',
    label: 'Raster Overlays',
    singularLabel: 'Raster Overlay',
    singularLabelLowerCase: 'raster overlay',
    genericDescriptor: 'overlay',
    selectItem: (d: INode) => d,
  },
  [MediaType.VectorOverlay]: {
    type: MediaType.VectorOverlay,
    entityType: EntityType.VectorOverlay,
    urnType: 'vectoroverlay',
    label: 'Vector Overlays',
    singularLabel: 'Vector Overlay',
    singularLabelLowerCase: 'vector overlay',
    genericDescriptor: 'overlay',
    selectItem: (d: INode) => d,
  },
  [MediaType.Video]: {
    type: MediaType.Video,
    urnType: 'embedmodel',
    selectItem: (d: INode) => d,
    label: 'Videos',
    singularLabel: 'Video',
    singularLabelLowerCase: 'video',
    genericDescriptor: 'video',
    icon: VideoIcon,
    outlinedIcon: VideoOutlinedIcon,
    color: 'ids.marker.video',
  },
  [MediaType.ThreeDModel]: {
    type: MediaType.ThreeDModel,
    urnType: 'embedmodel',
    selectItem: (d: INode) => d,
    label: '3D Models',
    singularLabel: '3D Model',
    singularLabelLowerCase: '3D model',
    genericDescriptor: '3d model',
    icon: ThreeDIcon,
    outlinedIcon: ThreeDOutlinedIcon,
    color: 'ids.marker.threeDModel',
  },
  [MediaType.ThreeDVR]: {
    type: MediaType.ThreeDVR,
    urnType: 'embedmodel',
    selectItem: (d: INode) => d,
    label: '3DVR Tours',
    singularLabel: '3DVR Tour',
    singularLabelLowerCase: '3DVR tour',
    genericDescriptor: '3DVR tour',
    icon: ThreeDVRIcon,
    outlinedIcon: ThreeVROutlinedIcon,
    color: 'ids.marker.threeDVR',
  },
  [MediaType.PointOfInterest]: {
    type: MediaType.PointOfInterest,
    urnType: 'pointofinterest',
    selectItem: (d: IEdge) => d.node,
    label: 'Points of Interest',
    singularLabel: 'Point of Interest',
    singularLabelLowerCase: 'point of interest',
    genericDescriptor: 'point of interest',
    icon: PointOfInterestIcon,
    outlinedIcon: PointOfInterestOutlinedIcon,
    color: 'ids.marker.pointOfInterest',
  },
  [MediaType.AreaOfInterest]: {
    type: MediaType.AreaOfInterest,
    urnType: 'areaofinterest',
    selectItem: (d: IEdge) => d.node,
    label: 'Areas of Interest',
    singularLabel: 'Area of Interest',
    singularLabelLowerCase: 'area of interest',
    genericDescriptor: 'area of interest',
    icon: AreaOfInterestIcon,
    outlinedIcon: AreaOfInterestOutlinedIcon,
    color: 'ids.marker.areaOfInterest',
  },
  [MediaType.Asset]: {
    type: MediaType.Asset,
    urnType: 'asset',
    selectItem: (d: IEdge) => d.node,
    label: 'Assets',
    singularLabel: 'Asset',
    singularLabelLowerCase: 'asset',
    genericDescriptor: 'asset',
    icon: AssetIcon,
    outlinedIcon: AssetOutlinedIcon,
    color: 'ids.marker.asset',
  },
};

export const ROUTE_POINT_TYPES = {
  [RoutePointType.ProjectPhoto]: {
    ...MEDIA_TYPES[MediaType.ProjectPhoto],
    type: RoutePointType.ProjectPhoto,
  },
  [RoutePointType.DslrHdPhoto]: {
    ...MEDIA_TYPES[MediaType.HDPhoto],
    type: RoutePointType.DslrHdPhoto,
  },
  [RoutePointType.Panorama]: {
    ...MEDIA_TYPES[MediaType.PanoramicPhoto],
    type: RoutePointType.Panorama,
  },
  [RoutePointType.DslrPanorama]: {
    ...MEDIA_TYPES[MediaType.PanoramicPhoto],
    type: RoutePointType.DslrPanorama,
    label: 'DSLR 360 Images',
    singularLabel: 'DSLR 360 Image',
    singularLabelLowerCase: 'DSLR 360 image',
    color: 'ids.marker.dslr_360',
  },
};

/**
  Maps media type to the type definition object.
*/
export const MEDIA_TYPE_TO_DEF: Record<MediaType, IMediaTypeDef> = Object.values(
  MEDIA_TYPES,
).reduce((typeToDef: Record<MediaType, IMediaTypeDef>, typeDef: IMediaTypeDef) => {
  typeToDef[typeDef.type] = typeDef;
  return typeToDef;
}, {} as Record<MediaType, IMediaTypeDef>);

export enum MediaMetadataType {
  Area = 'PhotoArea',
  Level = 'Level',
  PhotoCategory = 'PhotoCategory',
  PhotoType = 'PhotoType',
  PhotoTag = 'PhotoTag',
}

export enum LegacyMediaMetadataType {
  ActionCode = 'ActionCode',
  Group = 'Group',
}

export interface IMediaMetadataTypeDef {
  type: MediaMetadataType;
  label: string;
}

export const MEDIA_METADATA_TYPES: Record<MediaMetadataType, IMediaMetadataTypeDef> = {
  [MediaMetadataType.Area]: {
    type: MediaMetadataType.Area,
    label: 'Area',
  },
  [MediaMetadataType.Level]: {
    type: MediaMetadataType.Level,
    label: 'Level',
  },
  [MediaMetadataType.PhotoCategory]: {
    type: MediaMetadataType.PhotoCategory,
    label: 'Category',
  },
  [MediaMetadataType.PhotoType]: {
    type: MediaMetadataType.PhotoType,
    label: 'Type',
  },
  [MediaMetadataType.PhotoTag]: {
    type: MediaMetadataType.PhotoTag,
    label: 'Tag',
  },
};

/**
 * This is necessary for backwards compatibility.
 */
export enum LEGACY_MEDIA_TYPES {
  userphoto = 'userphoto',
}

export interface IMetadataTypeValue {
  id: string;
  name: string;
}

export interface IMetadataType {
  type: MediaMetadataType;
  values: IMetadataTypeValue[];
}

export interface IMediaMetadata {
  id: string;
  type: MediaMetadataType;
  value: string;
}

export interface IImageRep {
  url: string;
  name: string;
}
