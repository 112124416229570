import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import {
  AssignmentDetailsFrag,
  IAssignmentDetailsFragType,
} from '../views/Assignments/AssignmentDetails';
import {
  AssignmentListItemFrag,
  IAssignmentListItemFrag,
} from '../views/Assignments/AssignmentListItem';
import useGraphQuery, { UseGraphQueryOptions } from '../hooks/useGraphQuery';
import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import queryClient from '../utils/query';
import axios from '../utils/axios';

import { useOrganizationKeys } from './OrganizationsService';

export const useAssignmentKeys = () => {
  const queryIdKey = useQueryKeyId();
  const organizationKeys = useOrganizationKeys();

  return useMemo(() => {
    const assignmentKeys = {
      all: ['assignments', queryIdKey],
      lists: () => [...assignmentKeys.all, 'list'],
      orgList: (orgId: string) => [...assignmentKeys.lists(), ...organizationKeys.detail(orgId)],
      route: (id: string) => [...assignmentKeys.all, id],
      detail: (id: string) => [...assignmentKeys.route(id), 'detail'],
    };

    return assignmentKeys;
  }, [queryIdKey, organizationKeys]);
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

// Assignment Details
const AssignmentDetailQuery = gql`
  query AssignmentDetail($orgId: ID, $id: ID!, $tenantId: ID) {
    assignment(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      ...AssignmentDetailsFrag
    }
  }
  ${AssignmentDetailsFrag}
`;
interface IAssignmentDetailQueryType {
  assignment: IAssignmentDetailsFragType;
}
export const useAssignmentDetailsData = (assignmentId: string) => {
  const assignmentKeys = useAssignmentKeys();

  return useOrgGraphQuery<IAssignmentDetailQueryType>(
    assignmentKeys.detail(assignmentId),
    AssignmentDetailQuery,
    { id: assignmentId },
  );
};
// TODO: setAssignmentStatus may be an outdated name
const updateAssignmentSettingsMutation = gql`
  mutation setAssignmentStatus(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateAssignmentInput!
  ) {
    updateAssignment(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export interface ISettingsFragType {
  repeatable?: boolean;
  completable?: boolean;
  taskMetadataLocation?: string;
  loadResponses?: string;
  active?: boolean;
}

const updateAssignmentSettings = ({
  id,
  organizationId,
  updateValues,
}: {
  id: string;
  organizationId: string;
  updateValues: ISettingsFragType;
}) => {
  return buildGraphMutationFn(updateAssignmentSettingsMutation)({
    input: {
      ...updateValues,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export function useUpdateAssignmentSettings(id: string) {
  const assignmentKeys = useAssignmentKeys();

  return useMutation(updateAssignmentSettings, {
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries(assignmentKeys.lists(), {
        refetchActive: true,
      });
      queryClient.invalidateQueries(assignmentKeys.detail(id), {
        refetchActive: true,
      });
    },
  });
}

export enum TaskMetadataLocation {
  Top = 'top',
  Bottom = 'bottom',
  Hidden = 'hidden',
}

export enum LoadResponses {
  All = 'all',
  Own = 'own',
}

const uploadAssignmentsCsv = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post('/api/v2/assignments/upload_csv', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const useUploadAssignmentsCsv = () => {
  const assignmentKeys = useAssignmentKeys();

  return useMutation(uploadAssignmentsCsv, {
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(assignmentKeys.lists(), {
        refetchActive: true,
      });
    },
  });
};

export const getAssignmentDetailsCsv = async (assignmentId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_VIEWER_BASE_URL}/api/v2/assignments/${assignmentId}/export_csv`,
  );

  return response.data;
};

const OrganizationAssignmentsListQuery = gql`
  query OrgAssignmentsList($organizationId: ID!, $after: String, $take: Int) {
    organization(id: $organizationId) {
      assignments(after: $after, take: $take) {
        edges {
          node {
            ...AssignmentListItemFrag
          }
          cursor
        }
      }
    }
  }
  ${AssignmentListItemFrag}
`;

export interface IOrganizationAssignmentsListEdge {
  cursor: string;
  node: IAssignmentListItemFrag;
}

export const useGetOrganizationAssignments = (
  orgId: string | null,
  options: UseGraphQueryOptions,
) => {
  const keys = useAssignmentKeys();

  const useQuery = (take: number, after: string | null) =>
    useGraphQuery(
      orgId ? [...keys.orgList(orgId), `take-${take}`, `after-${after}`] : '',
      OrganizationAssignmentsListQuery,
      { organizationId: orgId, take, after },
      { enabled: !!orgId, ...options },
    );

  return useCursorPaginatedQuery<IOrganizationAssignmentsListEdge>({
    useQuery,
    defaultTake: 50,
    selectConnection: data => data?.organization?.assignments,
  });
};
