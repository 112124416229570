import React, { useCallback } from 'react';

import IdsDialog from '../../../IdsDialog';

import CreateAreaOfInterestForm from './CreateAreaOfInterestForm';

const CreateAreaOfInterestDialog = ({
  open,
  onOpen,
  onClose,
  points,
  distance,
  area,
  center,
  ...rest
}) => {
  const handleCreated = useCallback(() => {
    if (onClose) {
      onClose('created'); // indicate reason for closing
    }
  }, [onClose]);

  const handleCanceled = useCallback(() => {
    if (onClose) {
      onClose('canceled'); // indicate reason for closing
    }
  }, [onClose]);

  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='Create New Area of Interest'
      dialogKey='createAOI'
      fullWidth
    >
      <CreateAreaOfInterestForm
        onSubmitSuccess={handleCreated}
        onCancel={handleCanceled}
        points={points}
        distance={distance}
        area={area}
        center={center}
        {...rest}
      />
    </IdsDialog>
  );
};

export default CreateAreaOfInterestDialog;
