import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box } from '@mui/system';

import { CloseIcon } from '../../theme/icons';

const styles = {
  button: {
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  buttonGroup: {
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: 100,
  },
};

export interface IIdsDialogProps extends DialogProps {
  onOpen?: () => any;
  hideClose?: boolean;
  dialogKey?: string;

  // If true, open state will not be tracked in the url
  noSearchParam?: boolean;
  onClose?: (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  fullscreenControl?: boolean;
  changeFullScreen?: () => any;
}

const IdsDialog: React.FC<IIdsDialogProps> = ({
  open = false,
  onOpen,
  onClose,
  hideClose = false,
  title,
  dialogKey = 'dialog',
  children,
  noSearchParam = false,
  fullscreenControl = false,
  fullScreen = false,
  changeFullScreen,
  ...rest
}) => {
  const [fullScreenValue, setFullScreenValue] = useState(fullScreen);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openParam, setOpenParam] = useState(searchParams?.get(dialogKey));

  const openDialog = useCallback(() => {
    searchParams?.set(dialogKey, 'true');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, dialogKey, setSearchParams]);

  const closeDialog = useCallback(() => {
    searchParams?.delete(dialogKey);
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, dialogKey, setSearchParams]);

  const _onClose = useCallback(() => {
    if (noSearchParam) {
      closeDialog();
    }

    if (onClose) {
      onClose();
    }
  }, [closeDialog, onClose, noSearchParam]);

  useEffect(() => {
    if (noSearchParam) return;

    if (!open && openParam && onOpen) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once, set to open if openParam is set

  useEffect(() => {
    if (noSearchParam) return;

    if (!open && openParam) {
      closeDialog();
    } else if (open && !openParam) {
      openDialog();
    }
  }, [open, openParam, closeDialog, openDialog, noSearchParam]);

  useEffect(() => {
    if (noSearchParam) return;

    const newOpenParam = searchParams.get(dialogKey);
    if (newOpenParam !== openParam) {
      setOpenParam(newOpenParam);
    }
  }, [searchParams, dialogKey, openParam, noSearchParam]);

  const CloseButton = () => (
    <IconButton onClick={_onClose} sx={styles.button}>
      <CloseIcon />
    </IconButton>
  );
  const FullscreenButton = () => (
    // this icon needs to change based on if dialog is fullscreen
    <IconButton
      onClick={() => {
        setFullScreenValue(!fullScreenValue);
        if (changeFullScreen) {
          changeFullScreen();
        }
      }}
      sx={styles.button}
    >
      {fullScreen ? <Fullscreen /> : <FullscreenExit />}
    </IconButton>
  );
  const ButtonBar = () => {
    if (!hideClose) {
      return (
        <>
          {fullscreenControl && <FullscreenButton />}
          <CloseButton />
        </>
      );
    }

    if (fullscreenControl) {
      return <FullscreenButton />;
    }

    return null;
  };

  const Title = () => {
    return title ? (
      <DialogTitle variant='h3' sx={{ pr: 12 }}>
        {title}
      </DialogTitle>
    ) : null;
  };

  return (
    <Dialog open={open} onClose={_onClose} fullScreen={fullScreenValue} {...rest}>
      <Box sx={styles.buttonGroup}>
        <ButtonBar />
      </Box>
      <Title />
      {children}
    </Dialog>
  );
};

export default IdsDialog;
