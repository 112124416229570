import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import { NavigateNextIcon } from '../../theme/icons';

import styles from './IdsBreadcrumbs.module.css';

export interface ICrumb {
  route: LinkProps['to'];
  label: LinkProps['children'];
}

export interface IIdsCrumbProps {
  crumb?: ICrumb;
}

export const IdsCrumb: React.FC<IIdsCrumbProps> = ({ crumb }) => {
  if (crumb) {
    return (
      <Link
        to={crumb.route}
        component={RouterLink}
        underline='hover'
        color='inherit'
        className={styles.crumb}
      >
        {crumb.label}
      </Link>
    );
  }

  return null;
};

export interface IIdsBreadcrumbsProps {
  breadcrumbs: ICrumb[];
  currentCrumb: string;
}

const IdsBreadcrumbs: React.FC<IIdsBreadcrumbsProps> = ({ breadcrumbs, currentCrumb }) => {
  if (!breadcrumbs?.length || !currentCrumb) {
    return null;
  }

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
      {breadcrumbs.map((c, i) => (
        <IdsCrumb key={`crumb-${i}`} crumb={c} />
      ))}
      <Typography color='text.primary' variant='h4'>
        {currentCrumb}
      </Typography>
    </Breadcrumbs>
  );
};

export default IdsBreadcrumbs;
