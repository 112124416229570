import exifr from 'exifr';
export interface IGetImageMetaResult {
  width?: number;
  height?: number;
}

export const getImageMeta = (url: string): Promise<IGetImageMetaResult> => {
  return new Promise((resolve, reject) => {
    exifr
      .parse(url, true) // pass true here to parse all exif, dimensions don't load otherwise
      .then((exif: any) => {
        const exifWidth = exif?.ImageWidth || exif?.ExifImageWidth;
        const exifHeight = exif?.ImageHeight || exif?.ExifImageHeight;

        if (exifWidth && exifHeight) {
          resolve({
            width: exifWidth,
            height: exifHeight,
          });
          return;
        }

        // Image does not have exif dimension data, try loading into memory to determine dimensions
        const image = new Image();
        image.src = url;
        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.onerror = () => {
          // Some images are too large to be loaded using this method, and will fail (ideally they have exif data, but not always)
          reject(new Error('Failed to load image dimensions'));
        };
      });
  });
};
