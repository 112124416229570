import React, { useCallback, useState, useMemo } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import { activeLocationState } from '../../../../atoms/locations';
import { activeOrganizationState } from '../../../../atoms/organizations';
import usePermissions from '../../../../hooks/usePermissions';
import useFilterContext from '../../../../hooks/useFilterContext';

import LocationImageUploader, {
  ILocationImageBatchUploaderProps,
} from '../../../../components/ids-inputs/uploaders/LocationImageUploader';
import CircularProgress from '../../../../components/progress/CircularProgress';

import { usePublishAllLocationImages } from '../../../../services/LocationService';

import { EntityType } from '../../../../constants/entities';
import { MediaType } from '../../../../constants/media';

import styles from './LocationMediaTab.module.css';

const getIsNodePublished = (edge: { node: { published?: boolean } }) => !!edge.node.published;

interface LocationMediaTabActionsProps
  extends Pick<ILocationImageBatchUploaderProps, 'onUploadStarted' | 'onUploadCompleted'> {
  hdPhotosLoading: boolean;
  panoramasLoading: boolean;
}

const LocationMediaTabActions: React.FC<LocationMediaTabActionsProps> = ({
  onUploadStarted,
  onUploadCompleted,
  hdPhotosLoading,
  panoramasLoading,
}) => {
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const activeLocation = useRecoilValue(activeLocationState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { userHasPermission } = usePermissions();
  const usePublishAllImages = usePublishAllLocationImages();
  const { useTypeData } = useFilterContext();

  const hdPhotos = useTypeData(MediaType.HDPhoto);
  const panoramas = useTypeData(MediaType.PanoramicPhoto);

  const canUpdate = useMemo(() => {
    const canUpdatePanorama = userHasPermission('update', EntityType.Panorama);
    const canUpdateHdPhoto = userHasPermission('update', EntityType.HDPhoto);

    return canUpdatePanorama && canUpdateHdPhoto;
  }, [userHasPermission]);

  const areAllImagesPublished = useMemo(() => {
    const allHdPhotosPublished = hdPhotos?.every(getIsNodePublished) ?? false;
    const allPanoramasPublished = panoramas?.every(getIsNodePublished) ?? false;

    return allHdPhotosPublished && allPanoramasPublished;
  }, [hdPhotos, panoramas]);

  const { enqueueSnackbar } = useSnackbar();

  const handlePublishAll = useCallback(async () => {
    setIsPublishing(true);
    let allPublishedSuccessfully = true;
    try {
      const result = await usePublishAllImages.mutateAsync({
        id: activeLocation!.id,
        organizationId: activeOrg.id,
      });
      if (result?.usePublishAllImages?.errors?.length) {
        allPublishedSuccessfully = false;
      }
    } catch (e) {
      allPublishedSuccessfully = false;
    }

    if (allPublishedSuccessfully) {
      enqueueSnackbar('All images published successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Some images failed to publish', { variant: 'error' });
    }

    setIsPublishing(false);
  }, [activeOrg, activeLocation, enqueueSnackbar, usePublishAllImages]);

  const isLoading = hdPhotosLoading || panoramasLoading;
  const isButtonDisabled = isLoading || isPublishing || areAllImagesPublished;

  const handleUploaderOpen = useCallback(() => {
    setUploaderOpen(true);
  }, [setUploaderOpen]);

  const handleUploaderClose = useCallback(() => {
    setUploaderOpen(false);
  }, [setUploaderOpen]);

  return (
    <>
      <div className={styles.actionsContainer}>
        <Button color='secondary' variant='outlined' onClick={handleUploaderOpen}>
          Upload
        </Button>
        {canUpdate && (
          <Tooltip
            title={
              areAllImagesPublished
                ? 'All images have been published'
                : 'Publish all unpublished images'
            }
          >
            <span>
              <Button
                className={styles.publishButton}
                color='secondary'
                variant='contained'
                onClick={handlePublishAll}
                disabled={isButtonDisabled}
                startIcon={isPublishing ? <CircularProgress color='inherit' /> : null}
              >
                {isPublishing ? 'Publishing...' : 'Publish All Images'}
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
      <LocationImageUploader
        open={uploaderOpen}
        onOpen={handleUploaderOpen}
        onClose={handleUploaderClose}
        defaultLocationId={activeLocation!.id}
        onUploadCompleted={onUploadCompleted}
        onUploadStarted={onUploadStarted}
      />
    </>
  );
};

export default LocationMediaTabActions;
