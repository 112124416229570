/**
 * Anything added here should also be added to /app/public/config.js, so it can
 * be overwritten at deployment time and made available on the window object.
 */
const runtimeConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || window.runtimeConfig?.apiBaseUrl,
  bingMapsKey: process.env.REACT_APP_BING_MAPS_KEY || window.runtimeConfig?.bingMapsKey,
  graphQLBaseUrl: process.env.REACT_APP_GRAPHQL_BASE_URL || window.runtimeConfig?.graphQLBaseUrl,
  viewerBaseUrl: process.env.REACT_APP_VIEWER_BASE_URL || window.runtimeConfig?.viewerBaseUrl,
  airbrakeProjectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID
    ? parseInt(process.env.REACT_APP_AIRBRAKE_PROJECT_ID)
    : window.runtimeConfig?.airbrakeProjectId,
  airbrakeProjectKey:
    process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || window.runtimeConfig?.airbrakeProjectKey,
  airbrakeEnvironment:
    process.env.REACT_APP_AIRBRAKE_ENVIRONMENT || window.runtimeConfig?.airbrakeEnvironment,
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL || window.runtimeConfig?.keycloakUrl,
  explorerKeycloakClientId:
    process.env.REACT_APP_EXPLORER_KEYCLOAK_CLIENT_ID ||
    window.runtimeConfig?.explorerKeycloakClientId,
  phygiiKeycloakClientId:
    process.env.REACT_APP_PHYGII_KEYCLOAK_CLIENT_ID || window.runtimeConfig?.phygiiKeycloakClientId,
  rasterOverlayResizeSize:
    process.env.REACT_APP_RASTER_OVERLAY_RESIZE_SIZE ||
    window.runtimeConfig?.rasterOverlayResizeSize,
  rasterOverlayPreprocessUrl:
    process.env.REACT_APP_RASTER_OVERLAY_PREPROCESS_URL ||
    window.runtimeConfig?.REACT_APP_RASTER_OVERLAY_PREPROCESS_URL,
  rasterOverlayAutoTrimUrl:
    process.env.REACT_APP_RASTER_OVERLAY_AUTO_TRIM_URL ||
    window.runtimeConfig?.REACT_APP_RASTER_OVERLAY_AUTO_TRIM_URL,
  captureNavigationSourceKey:
    process.env.REACT_APP_CAPTURE_NAVIGATION_SOURCE_KEY ||
    window.runtimeConfig?.REACT_APP_CAPTURE_NAVIGATION_SOURCE_KEY,
  phygiiHostname: process.env.REACT_APP_PHYGII_HOSTNAME || window.runtimeConfig?.phygiiHostname,
  explorerHostname:
    process.env.REACT_APP_EXPLORER_HOSTNAME || window.runtimeConfig?.explorerHostname,
};

window.runtimeConfig = runtimeConfig;
export default runtimeConfig;
