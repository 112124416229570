import { atom } from 'recoil';

export enum NavigationSource {
  Unknown = 'Unknown',
  Capture = 'Capture',
}

const navigationSourceState = atom<NavigationSource>({
  key: 'navigationSource',
  default: NavigationSource.Unknown,
});

export { navigationSourceState };
