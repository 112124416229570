import { useEffect } from 'react';

import useFilterContext from '../../../../hooks/useFilterContext';
import { RoutePointType } from '../../../../constants/media';

import { IRoutePoint } from '../types';

const useSetTypeItems = (points: IRoutePoint[] | null) => {
  const { setTypeItems } = useFilterContext();

  useEffect(() => {
    if (!points) {
      return;
    }

    const typedPoints = points.reduce((accumulator, point) => {
      if (!accumulator[point.node.type]) {
        accumulator[point.node.type] = [point];
      } else {
        accumulator[point.node.type].push(point);
      }

      return accumulator;
    }, {} as Record<RoutePointType, IRoutePoint[]>);

    Object.keys(typedPoints).forEach(type => {
      setTypeItems(typedPoints[type as RoutePointType], type);
    });
  }, [points, setTypeItems]);
};

export default useSetTypeItems;
