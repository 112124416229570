import React, { useMemo, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { To } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  getAssignmentDetailsCsv,
  useAssignmentDetailsData,
} from '../../../services/AssignmentsService';
import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import LoadingScreen from '../../../components/LoadingScreen';
import { ICrumb } from '../../../components/IdsBreadcrumbs';
import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/users';
import ErrorPage from '../../Error';

import { AssignmentDetails, IAssignmentDetailsProps } from '../AssignmentDetails';
import { DownloadFileAsBlob } from '../../../utils/fileDownload';

export interface IAssignmentDetailsTemplatePageProps
  extends Pick<IAssignmentDetailsProps, 'useGetAssignmentResponses'> {
  assignmentId: string;
  pageTitle?: string;
  breadcrumbs: ICrumb[];
  getToAssignmentResponse: (assignmentResponseId: string) => To;
}

export const AssignmentDetailsTemplatePage: React.FC<IAssignmentDetailsTemplatePageProps> = ({
  assignmentId,
  pageTitle = 'Assignment Details',
  breadcrumbs,
  getToAssignmentResponse,
  useGetAssignmentResponses,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { userHasOneOfRoles } = usePermissions();
  const canDownloadCsv = useMemo(() => {
    return userHasOneOfRoles([
      USER_ROLES.ORG_ADMIN,
      USER_ROLES.IDS_ADMIN,
      USER_ROLES.TENANT_ADMIN,
      USER_ROLES.IDS_TEAM,
      USER_ROLES.TENANT_TEAM,
    ]);
  }, [userHasOneOfRoles]);

  const { data, isLoading, error } = useAssignmentDetailsData(assignmentId);

  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const handleDownloadCsvClick = async () => {
    setIsLoadingCsv(true);

    try {
      const fileData = await getAssignmentDetailsCsv(data!.assignment.id);
      const fileName = `${data!.assignment.title}_${data!.assignment.id}.csv`;

      DownloadFileAsBlob(fileData, fileName);
    } catch (_) {
      enqueueSnackbar('Failed to download CSV', { variant: 'error' });
    }

    setIsLoadingCsv(false);
  };

  if (error) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!data) {
    return null;
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={false}>
        <NestedOrgViewHeader
          breadcrumbs={breadcrumbs}
          currentCrumb={data.assignment.title}
          actions={
            canDownloadCsv ? (
              <Grid container pt={1} pl={1}>
                <Grid item>
                  <Button
                    variant='contained'
                    disabled={isLoadingCsv}
                    color='secondary'
                    onClick={handleDownloadCsvClick}
                  >
                    Download CSV
                  </Button>
                </Grid>
              </Grid>
            ) : null
          }
        />
        <AssignmentDetails
          assignment={data.assignment}
          getToAssignmentResponse={getToAssignmentResponse}
          useGetAssignmentResponses={useGetAssignmentResponses}
        />
      </Container>
    </Page>
  );
};

export default AssignmentDetailsTemplatePage;
