import React from 'react';

import { IIdsDialogProps } from '../../../IdsDialog';
import { IBatchLocationImageUploaderProps } from '../../../../hooks/uploaders/useBatchLocationImageUploader';
import {
  ISingleLocationImageUploaderProps,
  IInitialValues,
} from '../../../../hooks/uploaders/useSingleLocationImageUploader';
import { UploadType } from '../../../../constants/uploads';
import { ImageType } from '../../../../constants/media';
import IdsDialogUploader, { IIdsDialogUploader } from '../IdsDialogUploader';
import { useIdsUploaderUppy } from '../IdsUploader';

const defaultConsumerId = 'LocationImageUploader';

export interface ILocationImageBatchUploaderProps extends IIdsDialogProps {
  defaultLocationId?: string;
  defaultProjectId?: string;
  initialValues?: IInitialValues;
  disabledImageTypes?: ImageType[];
  consumerId?: string;
  onUploadStarted?: IIdsDialogUploader['onUploadStarted'];
  onUploadCompleted?: IIdsDialogUploader['onUploadCompleted'];
}

const LocationImageUploader: React.FC<ILocationImageBatchUploaderProps> = ({
  defaultLocationId,
  defaultProjectId,
  initialValues,
  disabledImageTypes,
  consumerId = defaultConsumerId,
  onUploadStarted,
  onUploadCompleted,
  ...rest
}) => {
  const { uppy, onUploadQueued } = useIdsUploaderUppy(consumerId);

  return (
    <IdsDialogUploader
      fileSelectionStepLabel='Select images'
      supportedUploaders={{
        [UploadType.BatchLocationImage]: {
          defaultLocationId,
          defaultProjectId,
          initialValues,
          disabledImageTypes,
        } as IBatchLocationImageUploaderProps,
        [UploadType.SingleLocationImage]: {
          defaultLocationId,
          defaultProjectId,
          initialValues,
          disabledImageTypes,
        } as ISingleLocationImageUploaderProps,
      }}
      onUploadQueued={onUploadQueued}
      onUploadStarted={onUploadStarted}
      onUploadCompleted={onUploadCompleted}
      uppy={uppy}
      dialogProps={{
        title: 'Upload Imagery',
        ...rest,
      }}
    ></IdsDialogUploader>
  );
};

export default LocationImageUploader;
