import { useCallback } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import useImmersiveViewer from '../../../../../hooks/useImmersiveViewer';

const useAdjustMapPadding = () => {
  const { setPadding } = useImmersiveViewer();

  const onResize = useCallback(
    (width: number | undefined) => {
      setPadding({ right: width });
    },
    [setPadding],
  );

  const { ref } = useResizeDetector({
    handleHeight: false,
    onResize,
  });

  return ref;
};

export default useAdjustMapPadding;
