import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { useMemo } from 'react';

import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import useGraphQuery, { UseGraphQueryOptions } from '../hooks/useGraphQuery';
import axios from '../utils/axios';
import queryClient from '../utils/query';
import invalidateQueriesContainingKey from '../utils/invalidateQueriesContainingKey';
import { ProjectListItemFrag } from '../views/Projects/ProjectListItem';
import { ProjectsListFrag } from '../views/Projects/ProjectsList';

import { useOrganizationKeys } from './OrganizationsService';
import { IProjectFrag, useProjectKeys } from './ProjectService';

export const useProgramKeys = () => {
  const queryIdKey = useQueryKeyId();
  const organizationKeys = useOrganizationKeys();

  return useMemo(() => {
    const programKeys = {
      all: ['programs', ...queryIdKey],
      lists: () => [...programKeys.all, 'list'],
      orgList: (orgId: string) => [...programKeys.lists(), ...organizationKeys.detail(orgId)],
      route: (id: string) => [...programKeys.all, id],
      detail: (id: string) => [...programKeys.route(id), 'detail'],
      projects: (id: string) => [...programKeys.route(id), 'projects'],
      orgPrograms: (id: string) => [...programKeys.all, `organization-${id}`],
    };

    return programKeys;
  }, [queryIdKey, organizationKeys]);
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

const ProgramDetailsQuery = gql`
  query ProgramDetails($orgId: ID, $tenantId: ID, $programId: ID!) {
    program(organizationId: $orgId, id: $programId, tenantId: $tenantId) {
      name
      description
    }
  }
`;

export interface IProgramDetails {
  program: {
    name: string;
    description: string;
  };
}

export const useGetProgramDetails = (programId: string) => {
  const programKeys = useProgramKeys();

  return useOrgGraphQuery<IProgramDetails>(programKeys.detail(programId), ProgramDetailsQuery, {
    programId,
  });
};

const ProgramProjectsQuery = gql`
  query ProgramProjects($orgId: ID, $tenantId: ID, $programId: ID!, $after: String, $take: Int) {
    program(organizationId: $orgId, id: $programId, tenantId: $tenantId) {
      projects(after: $after, take: $take) {
        edges {
          cursor
          node {
            id
            name
            ...ProjectsListFrag
            ...ProjectListItemFrag
          }
        }
      }
    }
  }
  ${ProjectsListFrag}
  ${ProjectListItemFrag}
`;

export interface IProgramProjectsEdge {
  cursor: string;
  node: IProjectFrag;
}

export const useGetProgramProjects = (programId: string, options?: UseGraphQueryOptions) => {
  const programKeys = useProgramKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...programKeys.projects(programId), `take-${take}`, `after-${after}`],
      ProgramProjectsQuery,
      {
        programId,
        take,
        after,
      },
      options,
    );

  return useCursorPaginatedQuery<IProgramProjectsEdge>({
    useQuery,
    defaultTake: 100,
    selectConnection: data => data.program.projects,
  });
};

const createProgram = ({ orgId, name, description }: Record<string, string>) => {
  return axios.post('/api/v2/programs', {
    program: {
      name,
      description,
      organization_id: orgId,
      project_ids: [],
    },
  });
};

export function useCreateProgram(orgId: string) {
  const programKeys = useProgramKeys();

  return useMutation(createProgram, {
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries(programKeys.orgList(orgId), {
        refetchActive: true,
      });
    },
  });
}

export function useDeleteProgram(orgId: string) {
  const programKeys = useProgramKeys();

  return useMutation(
    ({ programId }) => {
      return axios.delete(`/api/v2/programs/${programId}`);
    },
    {
      onError,
      onSuccess: () => {
        queryClient.invalidateQueries(programKeys.orgList(orgId), {
          refetchActive: true,
        });
      },
    },
  );
}

export const useUpdateProgram = (programId: string, orgId: string) => {
  const programKeys = useProgramKeys();
  const projectKeys = useProjectKeys();

  return useMutation(
    values => {
      return axios.put(`/api/v2/programs/${programId}`, {
        program: {
          ...values,
        },
      });
    },
    {
      onError,
      onSuccess: async () => {
        await invalidateQueriesContainingKey(programKeys.route(programId), {
          refetchActive: true,
        });

        await invalidateQueriesContainingKey(programKeys.orgList(orgId), {
          refetchActive: true,
        });

        await invalidateQueriesContainingKey(projectKeys.orgProjects(orgId), {
          refetchActive: true,
        });
      },
    },
  );
};

const OrgProgramsQuery = gql`
  query OrgPrograms($id: ID!, $take: Int, $after: String) {
    organization(id: $id) {
      programs(take: $take, after: $after) {
        edges {
          cursor
          node {
            id
            name
            description
          }
        }
      }
    }
  }
`;

interface IOrgProgram {
  id: string;
  name: string;
  description: string;
}

export interface IOrgProgramsEdge {
  cursor: string;
  node: IOrgProgram;
}

export const useGetOrgPrograms = (orgId: string, options?: UseGraphQueryOptions) => {
  const programKeys = useProgramKeys();

  const useQuery = (take: number, after: string | null) =>
    useGraphQuery(
      [...programKeys.orgList(orgId), `take-${take}`, `after-${after}`],
      OrgProgramsQuery,
      { id: orgId, take, after },
      options,
    );

  return useCursorPaginatedQuery<IOrgProgramsEdge>({
    initialData: [],
    useQuery,
    defaultTake: 100,
    selectConnection: d => d.organization?.programs,
  });
};
