import { useMemo } from 'react';

import { MediaMetadataType, IMetadataType, IMetadataTypeValue } from '../constants/media';

import useLocationMapContext from './useLocationMapContext';

const useLocationMapMetadataOptions = (metadataType: MediaMetadataType) => {
  const { location } = useLocationMapContext();

  return useMemo<IMetadataTypeValue[]>(
    () =>
      location?.metadataTypes?.find((t: IMetadataType) => t.type === metadataType)?.values || [],
    [location?.metadataTypes, metadataType],
  );
};

export default useLocationMapMetadataOptions;
