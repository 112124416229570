import React from 'react';
import { useFormikContext } from 'formik';

import IdsFormTextField, {
  IIdsFormTextFieldProps,
} from '../../../../../../../../../components/ids-forms/IdsFormTextField';

import { ROUTE_DESCRIPTION_LIMIT } from '../validationSchema';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRouteDescriptionFormFieldProps
  extends Omit<IIdsFormTextFieldProps, 'helperText'> {}

const RouteDescriptionFormField: React.FC<IRouteDescriptionFormFieldProps> = ({
  name,
  ...rest
}) => {
  const { values, touched, errors } = useFormikContext<Record<string, string>>();

  const helperText =
    (touched[name] && errors[name]) || `${values[name].length}/${ROUTE_DESCRIPTION_LIMIT}`;

  return <IdsFormTextField name={name} {...rest} helperText={helperText} />;
};

export default RouteDescriptionFormField;
