import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getOrgProjectRouteRoute } from '../../../../../../../utils/routes';

import { activeTenantState } from '../../../../../../../atoms/tenants';
import { activeOrganizationState } from '../../../../../../../atoms/organizations';
import { activeProjectState } from '../../../../../../../atoms/projects';

const useNavigateToRouteDetails = (routeId: string) => {
  const navigate = useNavigate();

  const activeProject = useRecoilValue(activeProjectState);
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const route = useMemo(
    () =>
      getOrgProjectRouteRoute({
        projectId: activeProject!.id,
        subdomain: activeTenant!.subdomain,
        orgId: activeOrg.id,
        routeId,
      }),
    [activeProject, activeTenant, activeOrg, routeId],
  );

  const navigateToRoute = useCallback(() => navigate(route), [navigate, route]);

  return {
    route,
    navigateToRoute,
  };
};

export default useNavigateToRouteDetails;
