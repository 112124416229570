import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  forwardRef,
  useCallback,
} from 'react';
import {
  Checkbox,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Paper,
  TextField,
} from '@mui/material';

import { Components, Virtuoso } from 'react-virtuoso';

import styles from './OptionsList.module.css';

const listComponents: Components<string, string> = {
  List: forwardRef(({ style, children }, ref) => (
    <List style={{ padding: 0, ...style, margin: 0 }} component='div' dense ref={ref}>
      {children}
    </List>
  )),
  Item: ({ children, ...props }) => <div {...props}>{children}</div>,
};

const searchMethod = (
  getPrimaryLabel: IOptionsListProps['getPrimaryLabel'],
  getSecondaryLabel: IOptionsListProps['getSecondaryLabel'],
  option: any,
  search: string,
) => {
  const lowerCaseSearch = search.toLowerCase();

  if (getPrimaryLabel(option).toLowerCase().includes(lowerCaseSearch)) {
    return true;
  } else if (
    getSecondaryLabel &&
    getSecondaryLabel(option).toLowerCase().includes(lowerCaseSearch)
  ) {
    return true;
  }

  return false;
};

export interface IOptionsListProps {
  label?: string;
  disabled?: boolean;
  options: any[];
  values: string[];
  checked: IOptionsListProps['values'];
  setChecked: Dispatch<SetStateAction<IOptionsListProps['values']>>;
  getPrimaryLabel: (item: any) => string;
  getSecondaryLabel?: (item: any) => string;
  getOptionValue: (item: any) => string;
}

const OptionsList: React.FC<IOptionsListProps> = ({
  label,
  disabled,
  options,
  values,
  getPrimaryLabel,
  getSecondaryLabel,
  getOptionValue,
  checked,
  setChecked,
}) => {
  const [filteredValues, setFilteredValues] = useState(() => values);
  useEffect(() => {
    setFilteredValues(values);
  }, [values]);

  const handleToggle = useCallback(
    (value: string) => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked, setChecked],
  );

  const search = (search: string) => {
    if (!search) {
      setFilteredValues(values);
    } else {
      setFilteredValues(
        options
          .filter(option => {
            if (!values.includes(getOptionValue(option))) {
              return false;
            }

            return searchMethod(getPrimaryLabel, getSecondaryLabel, option, search);
          })
          .map(option => getOptionValue(option)),
      );
    }
  };

  const renderItemContent = useCallback(
    (index: number) => {
      const item = filteredValues[index];
      const optionItem = options.find(option => getOptionValue(option) === item);
      const textProps: Record<string, any> = {
        primary: getPrimaryLabel(optionItem),
      };
      if (getSecondaryLabel) {
        textProps['secondary'] = getSecondaryLabel(optionItem);
      }
      return (
        <ListItemButton key={item} disabled={disabled} onClick={() => handleToggle(item)}>
          <ListItemIcon>
            <Checkbox checked={checked.indexOf(item) !== -1} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText {...textProps} />
        </ListItemButton>
      );
    },
    [
      checked,
      disabled,
      filteredValues,
      getOptionValue,
      getPrimaryLabel,
      getSecondaryLabel,
      handleToggle,
      options,
    ],
  );

  return (
    <>
      <TextField
        disabled={disabled}
        label={label || 'Search'}
        type='search'
        variant='filled'
        fullWidth
        onChange={event => search(event.target.value)}
        InputProps={{
          sx: { boxShadow: 1 },
        }}
      />
      <Paper className={styles['transferListOptions']}>
        <Virtuoso
          data={filteredValues}
          components={listComponents}
          itemContent={renderItemContent}
        />
      </Paper>
    </>
  );
};

export default OptionsList;
