import { getMediaTypeFromUrn } from '../../../../utils/media';
import { MediaType } from '../../../../constants/media';

type GroupedImages = {
  [MediaType.ProjectPhoto]?: string[];
  [MediaType.HDPhoto]?: string[];
  [MediaType.PanoramicPhoto]?: string[];
};

/**
 * @return {
 *   FLAT_IMAGE: [id, id ,id],
 *   PROJECT_PHOTO: [id, id],
 *   THREE_SIXTY_IMAGE: [id],
 * }
 */
const accumulateActiveAssetImages = (accumulator: GroupedImages, imageId: string) => {
  const imageType: keyof GroupedImages = getMediaTypeFromUrn(imageId) as keyof GroupedImages;

  if (accumulator[imageType]) {
    accumulator[imageType]!.push(imageId);
  } else {
    accumulator[imageType] = [imageId];
  }

  return accumulator;
};

export { accumulateActiveAssetImages };
