import React from 'react';

import OrganizationChip from './OrganizationChip';
import ProgramChip from './ProgramChip';
import ProjectChip from './ProjectChip';
import LocationChip from './LocationChip';
import UserChip from './UserChip';
import EntityChip, { IEntityChipProps } from './EntityChip';

export enum EntityParentType {
  Organization = 'Organization',
  Program = 'Program',
  Project = 'Project',
  User = 'User',
  Location = 'Location',
}

export type EntityParentTypes = keyof typeof EntityParentType;

type EntityParentConfigsType = Record<
  EntityParentTypes | EntityParentType,
  (parentId: string, props: IEntityChipProps) => JSX.Element
>;

const ENTITY_PARENT_MAP: EntityParentConfigsType = {
  Organization: (parentId, props) => <OrganizationChip organizationId={parentId} {...props} />,
  Program: (parentId, props) => <ProgramChip programId={parentId} {...props} />,
  Project: (parentId, props) => <ProjectChip projectId={parentId} {...props} />,
  User: (parentId, props) => <UserChip userId={parentId} {...props} />,
  Location: (parentId, props) => <LocationChip locationId={parentId} {...props} />,
};

export interface IEntityParentChipProps extends IEntityChipProps {
  parentType: EntityParentTypes | EntityParentType;
  parentId?: string;
}

const EntityParentChip: React.FC<IEntityParentChipProps> = ({ parentType, parentId, ...rest }) => {
  if (Object.keys(ENTITY_PARENT_MAP).includes(parentType)) {
    return ENTITY_PARENT_MAP[parentType](parentId || '', rest);
  }

  return <EntityChip {...rest} />;
};

export default EntityParentChip;
