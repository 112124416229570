import ComingSoonRoute from '../../../components/ComingSoonRoute';
import Page from '../../../components/Page';

function TenantUploads() {
  return (
    <Page title='Uploads'>
      <ComingSoonRoute legacyFeatureRoute={null} />
    </Page>
  );
}
export default TenantUploads;
