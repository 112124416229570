import React from 'react';
import { gql } from 'graphql-request';
import {
  Accordion,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { capitalCase } from 'change-case';

import clsx from 'clsx';

import { ExpandIcon } from '../../../../../theme/icons';
import IdsDataAttribute from '../../../../../components/IdsDataAttribute';
import AssignmentQuestion, {
  AssignmentQuestionFrag,
  IAssignmentQuestionFragType,
} from '../AssignmentQuestion';

import styles from './AssignmentTask.module.css';

export interface IAssignmentTaskFragType {
  id: string;
  title?: string;
  required?: boolean;
  repeatable?: boolean;
  repeatableLabel?: string;
  completable?: boolean;
  target: {
    targetType: string;
    coordinates?: {
      latitude?: number;
      longitude?: number;
    };
    objectMappingTree: {
      title?: string;
    };
  };
  taskInformation: {
    description?: string;
    exampleImages: {
      id: string;
      title?: string;
      caption?: string;
      image: { url: string };
    }[];
  };
  defaultMetadata: {
    id: string;
    type: string;
    value: string;
  }[];
  assignmentQuestions: {
    edges: {
      node: {
        id: string;
      } & IAssignmentQuestionFragType;
    }[];
  };
}

export const AssignmentTaskFrag = gql`
  fragment AssignmentTaskFrag on AssignmentTask {
    id
    title
    required
    repeatable
    repeatableLabel
    completable
    target {
      targetType
      coordinates {
        latitude
        longitude
      }
      objectMappingTree {
        title
      }
    }
    taskInformation {
      description
      exampleImages {
        id
        title
        caption
        image {
          url
        }
      }
    }
    defaultMetadata {
      id
      type
      value
    }
    assignmentQuestions {
      edges {
        node {
          id
          ...AssignmentQuestionFrag
        }
      }
    }
  }
  ${AssignmentQuestionFrag}
`;

export interface IExampleImagesProps {
  exampleImages: IAssignmentTaskFragType['taskInformation']['exampleImages'];
}

const ExampleImages: React.FC<IExampleImagesProps> = ({ exampleImages }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography>{'Example Images'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ImageList cols={5} gap={20}>
          {exampleImages.map(e => (
            <ImageListItem key={e.id} sx={{ minWidth: 0 }}>
              {' '}
              {/* minWidth: 0 is needed to allow subtitle to truncate */}
              <img src={e.image.url} alt={e.title} loading='lazy' />
              <ImageListItemBar
                title={e.title}
                subtitle={e.caption}
                // position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </AccordionDetails>
    </Accordion>
  );
};

export interface IMetadataProps {
  data: {
    value: string;
    // TODO: Move type to reusable enum
    type: string;
  };
}

const Metadata: React.FC<IMetadataProps> = ({ data }) => {
  return <IdsDataAttribute label={capitalCase(data.type)} value={data.value} />;
};

export interface ITargetProps {
  target: IAssignmentTaskFragType['target'];
}

const Target: React.FC<ITargetProps> = ({ target }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography>{'Target'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction='column'>
          <IdsDataAttribute label='Type' value={capitalCase(target.targetType)} />
          <IdsDataAttribute label='Title' value={target.objectMappingTree.title} />
          <Grid item>
            {target?.coordinates && target.coordinates.latitude && target.coordinates.longitude && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandIcon />}>
                  <Typography>{'Coordinates'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <IdsDataAttribute label='Latitude' value={target.coordinates.latitude} />
                  <IdsDataAttribute label='Longitude' value={target.coordinates.latitude} />
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export interface IAssignmentTaskProps {
  task: IAssignmentTaskFragType;
}

const AssignmentTask: React.FC<IAssignmentTaskProps> = ({ task }) => {
  return (
    <Accordion key={task.id}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography>{`Task #${task.id}: ${task.title}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction='column'>
          <IdsDataAttribute label='Description' value={task.taskInformation.description} />
          <IdsDataAttribute label='Required' value={task.required} />
          <IdsDataAttribute label='Repeatable' value={task.repeatable} />
          <IdsDataAttribute label='Repeatable Label' value={task.repeatableLabel} />
          <IdsDataAttribute label='Completable' value={task.completable} />
          {task.defaultMetadata && (
            <Grid item>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandIcon />}>
                  <Typography>{'Default Metadata'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction='column'>
                    {task.defaultMetadata.map(item => (
                      <Metadata key={item.id} data={{ type: item.type, value: item.value }} />
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          <Grid item>
            <Target target={task.target} />
          </Grid>
          {/* Doesn't work well on a big screen */}
          {task.taskInformation.exampleImages && task.taskInformation.exampleImages.length > 0 && (
            <Grid item>
              <ExampleImages exampleImages={task.taskInformation.exampleImages} />
            </Grid>
          )}
          <Grid item>
            {task.assignmentQuestions.edges.map((e, index) => {
              const isPreviousConditional =
                index > 0 ? task.assignmentQuestions.edges[index - 1].node.conditional : false;
              const isNextConditional =
                index < task.assignmentQuestions.edges.length - 1
                  ? task.assignmentQuestions.edges[index + 1].node.conditional
                  : false;

              const conditionalSpacing = clsx({
                [styles.conditionalQuestion]: e.node.conditional,
                [styles.questionAfterConditional]: !e.node.conditional && isNextConditional,
                [styles.questionBeforeConditional]: !e.node.conditional && isPreviousConditional,
                [styles.questionBeforeNotConditional]: e.node.conditional && !isPreviousConditional,
              });

              return (
                <AssignmentQuestion
                  key={e.node.id}
                  question={e.node}
                  conditionalSpacing={conditionalSpacing}
                />
              );
            })}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AssignmentTask;
