import { useCallback } from 'react';

import useDeleteEntity from '../../../../../../../../hooks/useDeleteEntity';
import { useDeleteTaskResponse } from '../../../../../../../../services/TaskResponsesService';

const useDeleteTaskResponseAction = (
  assignmentResponseId: string,
  taskResponseId: string,
  title: string,
) => {
  const { deleted, deleting, handleDelete } = useDeleteEntity();
  const useDeleteTaskResponseMutation = useDeleteTaskResponse(assignmentResponseId, taskResponseId);

  const deleteResponse = useCallback(
    async (onDelete?: (data: any) => any, onCancel?: () => any) => {
      await handleDelete(
        async () => await useDeleteTaskResponseMutation.mutateAsync({}),
        title || `Task Response ${taskResponseId}`,
        'This cannot be undone, all task response data will be deleted permanently.',
        'Task Response',
        onDelete,
        onCancel,
      );
    },
    [useDeleteTaskResponseMutation, handleDelete, title, taskResponseId],
  );

  return {
    deleted,
    deleting,
    deleteResponse,
  };
};

export default useDeleteTaskResponseAction;
