import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../../atoms/organizations';
import { activeProgramState } from '../../../../../atoms/programs';
import IdsCreateForm from '../../../../../components/ids-forms/IdsCreateForm';
import IdsFormDateField from '../../../../../components/ids-forms/IdsFormDateField';
import IdsFormSelectField from '../../../../../components/ids-forms/IdsFormSelectField';
import IdsFormTextField from '../../../../../components/ids-forms/IdsFormTextField';
import IdsFormTransferListField from '../../../../../components/ids-forms/IdsFormTransferListField';
import QueryLoader from '../../../../../components/QueryLoader';
import { PROJECT_STATUSES } from '../../../../../constants/projects';
import { useGetAllLocationOptions } from '../../../../../services/LocationService';
import { useBulkCreateProjects } from '../../../../../services/ProjectService';
import { STATUS_OPTIONS } from '../../../../Projects/ProjectForm';
import { bulkCreateProjectValidationSchema } from '../../../../Projects/projectValidation';

const initialValues = {
  name: '',
  locations: [],
  description: '',
  startDate: null,
  completionDate: null,
  status: PROJECT_STATUSES.NEW,
};

const BulkCreateProjectsForm = ({ onCancel, onCreate }) => {
  const [locationOptions, setLocationOptions] = useState(null);

  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);

  const useLocationOptions = () => useGetAllLocationOptions(activeOrg.id);

  const bulkCreateProjectsMutation = useBulkCreateProjects(activeProgram.id);

  const onSubmit = useCallback(
    async values => {
      await bulkCreateProjectsMutation.mutateAsync({
        organization_id: activeOrg.id,
        location_ids: values.locations,
        program_id: activeProgram.id,
        project: {
          name: values.name,
          description: values.description,
          start_date: values.startDate,
          completion_date: values.completionDate,
          status: values.status,
        },
      });

      if (onCreate) {
        onCreate();
      }

      onCancel();
    },
    [onCreate, onCancel, bulkCreateProjectsMutation, activeOrg.id, activeProgram.id],
  );

  return (
    <QueryLoader
      useQuery={useLocationOptions}
      selectData={data => (data?.length && data?.map(item => item.node)) || null}
      setData={setLocationOptions}
      render={() => (
        <IdsCreateForm
          initialValues={initialValues}
          validationSchema={bulkCreateProjectValidationSchema}
          successMessage='The projects were created'
          errorHandler={() => 'The projects cannot be created'}
          onSubmit={onSubmit}
          onCancel={onCancel}
        >
          <Box px={1} pt={1}>
            <IdsFormTextField name='name' label='Name' required autoFocus />
            <Box mt={1}>
              <IdsFormTransferListField
                optionsLabel='Search unselected locations'
                valuesLabel='Search selected locations'
                name='locations'
                required
                options={locationOptions}
                getPrimaryLabel={o => o.name}
                getOptionValue={o => o.id}
              />
            </Box>
            <IdsFormTextField
              name='description'
              label='Description'
              required
              multiline
              minRows={3}
              maxRows={6}
              margin='normal'
            />
            <Box display='flex' flexDirection='row' gap={2} my={2}>
              <IdsFormDateField name='startDate' label='Start Date' required />
              <IdsFormDateField name='completionDate' label='Completion Date' required />
            </Box>
            <IdsFormSelectField name='status' label='Status' required options={STATUS_OPTIONS} />
          </Box>
        </IdsCreateForm>
      )}
    />
  );
};

export default BulkCreateProjectsForm;
