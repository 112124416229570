import React from 'react';
import PropTypes from 'prop-types';

import { MediaType, MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../constants/media';
import { getCommonMetadataFilterTargets } from '../helpers';
import MediaMetadataFilter from '../MediaMetadataFilter';
import { selectedLevels } from '../../../atoms/levelFilter';
import useActiveFilterTargets from '../../../hooks/filtering/useActiveFilterTargets';
import { useRestoreMediaMetadataFilterFromUrl } from '../../../hooks/filtering/useMediaMetadataFilter';

const findMetadataType = d => d.type === MEDIA_METADATA_TYPES[MediaMetadataType.Level].type;
const selectEdgeFilterData = edge => edge.node.metadata.find(findMetadataType);
const selectItemFilterData = item => item.metadata.find(findMetadataType);

const commonTargets = getCommonMetadataFilterTargets(MediaMetadataType.Level);

const supportedFilterTargets = [
  ...commonTargets,
  {
    type: MediaType.Video,
    selectFilterData: selectItemFilterData,
  },
  {
    type: MediaType.ThreeDModel,
    selectFilterData: selectItemFilterData,
  },
  {
    type: MediaType.ThreeDVR,
    selectFilterData: selectItemFilterData,
  },
  {
    type: MediaType.PointOfInterest,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MediaType.Asset,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MediaType.AreaOfInterest,
    selectFilterData: selectEdgeFilterData,
  },
];

const LevelFilter = props => {
  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);

  return (
    <MediaMetadataFilter
      label={MEDIA_METADATA_TYPES[MediaMetadataType.Level].label}
      metadataType={MEDIA_METADATA_TYPES[MediaMetadataType.Level].type}
      filterTargets={activeFilterTargets}
      filterStateAtom={selectedLevels}
      noMetadataToFilterByMessage='There are no levels to filter by.'
      {...props}
    />
  );
};

export const useRestoreLevelFilterFromUrl = allValidMetadataItems => {
  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);

  useRestoreMediaMetadataFilterFromUrl({
    metadataType: MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
    filterTargets: activeFilterTargets,
    filterStateAtom: selectedLevels,
    allValidMetadataItems,
  });
};

LevelFilter.propTypes = {
  allSortedMetadataItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default LevelFilter;
