import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useFormikContext } from 'formik';

import { AllFormValues } from '../BulkLocationImageEditDialog/types';
import { ResetIcon } from '../../../theme/icons';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsFormResetButtonProps
  extends Omit<ButtonProps, 'onClick' | 'variant' | 'startIcon'> {}

const IdsFormResetButton: React.FC<IIdsFormResetButtonProps> = ({ ...rest }) => {
  const { initialValues, resetForm } = useFormikContext<AllFormValues>();

  const reset = useCallback(() => {
    resetForm({ values: initialValues });
  }, [resetForm, initialValues]);

  return (
    <Button {...rest} variant='text' startIcon={<ResetIcon />} onClick={reset}>
      Reset
    </Button>
  );
};

export default IdsFormResetButton;
