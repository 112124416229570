import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import IdsSelect, { IIdsSelectProps, ISelectOption } from '../../IdsSelect';
import { ImageType, MEDIA_TYPES, MediaType } from '../../../../constants/media';

export interface IMediaTypeSelectOption extends ISelectOption {
  value: ImageType;
}

export const IMAGE_TYPE_OPTIONS: IMediaTypeSelectOption[] = [
  {
    label: MEDIA_TYPES[MediaType.ProjectPhoto].singularLabel,
    value: MediaType.ProjectPhoto,
  },
  {
    label: MEDIA_TYPES[MediaType.HDPhoto].singularLabel,
    value: MediaType.HDPhoto,
  },
  {
    label: MEDIA_TYPES[MediaType.PanoramicPhoto].singularLabel,
    value: MediaType.PanoramicPhoto,
  },
];

export interface IIdsImageTypeSelectProps extends Omit<IIdsSelectProps, 'onChange' | 'options'> {
  value?: ImageType;
  onChange?: (type?: ImageType) => void;
  disabledTypes?: ImageType[];
}

const IdsImageTypeSelect: React.FC<IIdsImageTypeSelectProps> = ({
  value,
  onChange,
  disabledTypes,
  ...rest
}) => {
  const [controlled, setControlled] = useState(false);
  const [type, setType] = useState<ImageType | undefined>(value);

  const options = useMemo(
    () =>
      disabledTypes
        ? // disabledTypes can be used to limit which types can be selected
          IMAGE_TYPE_OPTIONS.filter(o => !disabledTypes.includes(o.value))
        : IMAGE_TYPE_OPTIONS,
    [disabledTypes],
  );

  useEffect(() => {
    if (value !== undefined) {
      setControlled(true);
    }

    if (onChange) {
      onChange(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  useEffect(() => {
    if (options.length === 1) {
      setType(options[0].value);

      if (onChange) {
        onChange(options[0].value);
      }
    }
  }, [options, setType, onChange]);

  useEffect(() => {
    if (!controlled || !value) {
      return;
    }

    setType(value);
  }, [controlled, value, setType]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newType = event.target.value as ImageType | undefined;

      if (!controlled) {
        // Track type internally
        setType(newType);
      }

      if (onChange) {
        onChange(newType);
      }
    },
    [controlled, onChange, setType],
  );

  return (
    <IdsSelect
      {...rest}
      label='Image Type'
      options={options}
      value={type || ''}
      onChange={handleChange}
      disabled={options.length === 1}
    />
  );
};

export default IdsImageTypeSelect;
