import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import useDeleteEntity from '../../hooks/useDeleteEntity';
import {
  DocumentDeleteModeTypes,
  useDeleteDocumentOrVersion,
} from '../../services/DocumentsService';
import { activeOrganizationState } from '../../atoms/organizations';

import { IDocument, IDocumentVersion } from './types';

export interface IDeleteDocumentFn {
  documentItem: IDocument;
  documentVersion?: IDocumentVersion;
  deleteMode?: DocumentDeleteModeTypes;
  onDelete?: () => any;
  onCancel?: () => any;
}

const useDeleteDocumentOrVersionAction = () => {
  const [organization] = useRecoilState(activeOrganizationState);
  const { mutateAsync: deleteDocumentOrVersionMutation } = useDeleteDocumentOrVersion();
  const { deleted, deleting, handleDelete } = useDeleteEntity();

  const deleteDocumentOrVersion = useCallback(
    ({
      documentItem,
      documentVersion,
      deleteMode = 'DOCUMENT',
      onDelete,
      onCancel,
    }: IDeleteDocumentFn) => {
      let entityType = 'Document';
      let entityId = documentItem?.node?.id;
      // Name is field of document
      let entityName = documentItem.node.name;
      let deleteDescription = 'This cannot be undone, the document will be deleted permanently.';

      if (deleteMode === 'DOCUMENT_VERSION') {
        entityType = 'Document Version';
        entityId = documentVersion!.id;
        entityName = `${documentItem.node.name} document version`;
        deleteDescription =
          'This cannot be undone, the document version will be deleted permanently.';
      }

      handleDelete(
        async () =>
          await deleteDocumentOrVersionMutation({
            organizationId: organization.id,
            id: entityId,
            deleteMode,
          }),
        entityName,
        deleteDescription,
        entityType,
        onDelete,
        onCancel,
      );
    },
    [deleteDocumentOrVersionMutation, handleDelete, organization],
  );

  return {
    deleted,
    deleting,
    deleteDocumentOrVersion,
  };
};

export default useDeleteDocumentOrVersionAction;
