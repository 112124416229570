import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';
import {
  Alert,
  Box,
  CircularProgress,
  Checkbox,
  Divider,
  Grid,
  Slider,
  SliderProps,
  Stack,
  Typography,
  AlertColor,
  useTheme,
} from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';

import useBulkSelection from '../../../hooks/useBulkSelection';
import BulkActionsChip from '../../BulkActionsChip';
import useItemsBooleanState, { BooleanStateSummary } from '../../../hooks/useItemsBooleanState';
import { IdsImageListItem, IIdsImageListItemProps } from '../IdsImageList';
import IdsSelect from '../../ids-inputs/IdsSelect';
import { PhotoSizeSelectActualIcon, PhotoSizeSelectLargeIcon } from '../../../theme/icons';
import { MEDIA_TYPE_TO_DEF, MediaType } from '../../../constants/media';
import { chunkArray } from '../../../utils/helpers';
import '../../../theme/globalStyles.css';

import {
  MediaItem,
  ImageSize,
  Filter,
  ISorter,
  IMediaGroup,
  IBulkAction,
  ITransformedMediaItem,
} from './types';
import styles from './IdsMediaListView.module.css';

const DEFAULT_GROUP_KEY = 'defaultGroup';
const MEDIA_ITEMS_GAP = 16;

/**
 * Receive index relative to current group.
 *
 * `react-virtuoso` returns raw index of item inside the group. But we don't have individual index, but insead a row. That function takes into account previous chunks to calculate exact index.
 * @param sortedMediaGroups - sorted media groups array with `chunkedMedia` propeties inside
 * @param currentGroupIndex - current group index passed from `react-virtuoso`
 * @param currentIndex - current index passed from `react-virtuoso`
 * @returns normalized index, taking into account previous chunks
 */
const getChunkedMediaIndex = (
  sortedMediaGroups: IMediaGroup[],
  currentGroupIndex: number,
  currentIndex: number,
) => {
  let result = currentIndex;
  for (let i = 0; i < currentGroupIndex; i += 1) {
    result -= sortedMediaGroups[i].chunkedMedia?.length;
  }
  return result;
};

// 20px - reserved for scrollbar
const getGroupMediaColumnsCount = (
  containerWidth: number,
  itemWidth: number,
  gap = MEDIA_ITEMS_GAP,
) => {
  // Taking into account 20px and paddings on left and right side
  const netContainerWidth = Math.max(1, containerWidth - 20 - gap * 2);

  // Calculating how many elements can be in same row, taking into account gap between items
  // First element doesn't need gap in front of it, so gap added to width of container before deviding
  const columns = Math.max(1, Math.floor((netContainerWidth + gap) / (itemWidth + gap)));

  return columns;
};

const IMAGE_SIZES: Record<ImageSize, number> = {
  [ImageSize.S]: 50,
  [ImageSize.M]: 125,
  [ImageSize.L]: 200,
};

type ImagePixelSize = (typeof IMAGE_SIZES)[keyof typeof IMAGE_SIZES];

const IMAGE_SIZES_REVERSE_LOOKUP: Record<ImagePixelSize, ImageSize> = {
  [IMAGE_SIZES[ImageSize.S]]: ImageSize.S,
  [IMAGE_SIZES[ImageSize.M]]: ImageSize.M,
  [IMAGE_SIZES[ImageSize.L]]: ImageSize.L,
};

const IMAGE_SIZE_MARKS: SliderProps['marks'] = [
  { label: 'S', value: IMAGE_SIZES.S },
  { label: 'M', value: IMAGE_SIZES.M },
  { label: 'L', value: IMAGE_SIZES.L },
];

// Deprecated due to virtuoso integration. TODO: Consider removing if virtualization is always desired
// export const MediaGroup = ({
//   group,
//   getImageUrl,
//   getMediaType,
//   renderImage,
//   imageSize,
//   disableTopMargin,
//   onImageClick,
//   imageProps,
//   mediaSelectionState,
// }) => (
//   <Grid item container>
//     {group.key !== DEFAULT_GROUP_KEY && (
//       <>
//         <Grid item xs='auto'>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           className={styles.mediaGroupDivider}
//           style={{ ...(!disableTopMargin && { marginTop: 2 }) }}
//         >
//           <Typography variant='overline' fontSize='0.9rem'>
//             {group.label}
//           </Typography>
//         </Grid>
//       </>
//     )}
//     <IdsImageList
//       images={group.media}
//       getImageUrl={getImageUrl}
//       getMediaType={getMediaType}
//       renderImage={renderImage}
//       imageSize={imageSize}
//       onImageClick={onImageClick}
//       className={styles.mediaGroupImages}
//       imageProps={imageProps}
//     />
//   </Grid>
// );

export interface IIdsMediaListViewProps {
  media: MediaItem[];
  getMediaId: (item: MediaItem) => string;
  getMediaType?: (item: MediaItem) => MediaType;
  getMediaThumbnailUrl?: (item: MediaItem) => string;
  noMediaMessage?: string;
  noMediaSeverity?: AlertColor;
  renderImage?: IIdsImageListItemProps['renderImage'];
  onImageClick?: IIdsImageListItemProps['onClick'];
  imageProps?: IIdsImageListItemProps['imageProps'];
  filters?: Filter[];
  sorters?: ISorter[];
  onSortByChange?: (sortByKey: string) => void;
  actions?: React.ReactNode;
  bulkActions?: IBulkAction[];
  onImageSizeChange?: (newImageSize: ImageSize) => void;
  defaultImageSize?: ImageSize;
  loading?: boolean;
}

// Define default props as a const to avoid creating new refs every render
const defaultProps = {
  getMediaThumbnailUrl: (item: MediaItem) => item.url.square,
  filters: [],
  sorters: [],
};

const IdsMediaListView: React.FC<IIdsMediaListViewProps> = ({
  media,
  getMediaId,
  getMediaType,
  getMediaThumbnailUrl = defaultProps.getMediaThumbnailUrl,
  noMediaMessage,
  noMediaSeverity,
  renderImage,
  onImageClick,
  imageProps,
  filters = defaultProps.filters,
  sorters = defaultProps.sorters,
  onSortByChange,
  actions,
  bulkActions,
  onImageSizeChange,
  defaultImageSize,
  loading = false,
}) => {
  const { ref: groupsContainerRef, width = 0 } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 100,
  });

  const theme = useTheme();

  const [imageSize, setImageSize] = useState(
    (defaultImageSize && IMAGE_SIZES[defaultImageSize]) || IMAGE_SIZES.M,
  );
  const columns = useMemo(
    () => getGroupMediaColumnsCount(width, imageSize, MEDIA_ITEMS_GAP),
    [imageSize, width],
  );

  const [sortBy, setSortBy] = useState(
    sorters.find(s => s.isDefault)?.key || // Sorter marked as default
      (sorters.length && sorters[0].key) || // First sorter
      undefined,
  );

  const mediaHoverState = useItemsBooleanState();
  const mediaSelectionState = useItemsBooleanState();

  const bulkSelectionEnabled = useMemo(() => {
    return !!bulkActions?.some(a => !a.disabled);
  }, [bulkActions]);

  const handleSortByChange = useCallback(
    event => {
      const newSortBy = event.target.value;
      setSortBy(newSortBy);

      if (onSortByChange) {
        onSortByChange(newSortBy);
      }
    },
    [setSortBy, onSortByChange],
  );

  const handleImageSizeChange = useCallback(
    event => {
      const newImageSize = event.target.value;
      setImageSize(newImageSize);

      if (onImageSizeChange) {
        // Return the string representation of the size
        onImageSizeChange(IMAGE_SIZES_REVERSE_LOOKUP[newImageSize]);
      }
    },
    [setImageSize, onImageSizeChange],
  );

  // Filtering logic is applied in the array order, or by filterOrder if provided
  const orderedFilters = useMemo(
    () =>
      filters.sort((f1, f2) => {
        // Prioritize filters with a filter order over those without
        if (typeof f1.filterOrder === 'number') {
          // Checking typeof since 0 is falsy
          return typeof f2.filterOrder === 'number' ? f1.filterOrder - f2.filterOrder : -1;
        }
        return typeof f2.filterOrder === 'number' ? 1 : 0;
      }),
    [filters],
  );

  const transformedMedia = useMemo<ITransformedMediaItem[]>(() => {
    return media.map(item => ({
      id: getMediaId(item),
      mediaType: getMediaType?.(item),
      thumbnailUrl: getMediaThumbnailUrl?.(item),
      ...item,
    }));
  }, [media, getMediaId, getMediaType, getMediaThumbnailUrl]);

  const filteredMedia = useMemo<ITransformedMediaItem[]>(() => {
    if (!orderedFilters.length) {
      return transformedMedia;
    }

    const filterMedia = (
      _media: ITransformedMediaItem[],
      filterIndex: number,
    ): ITransformedMediaItem[] => {
      if (filterIndex === orderedFilters.length) {
        // End of filters, filtering is done
        return _media;
      }
      const filter = orderedFilters[filterIndex];
      const mediaToFilter = filter.filterExternally
        ? _media // Media is filtered externally
        : _media.filter(filter.filterMediaItem); // Apply the filter
      return filterMedia(mediaToFilter, ++filterIndex);
    };
    return filterMedia(transformedMedia, 0);
  }, [transformedMedia, orderedFilters]);

  const filteredMediaIds = useMemo(() => {
    return filteredMedia.map((item: ITransformedMediaItem) => item.id);
  }, [filteredMedia]);

  const filteredMediaSelectionStateSummary = useMemo(() => {
    return mediaSelectionState.getItemsStateSummary(filteredMediaIds);
  }, [mediaSelectionState, filteredMediaIds]);

  const totalVisibleMediaItemsSelected = useMemo(() => {
    return mediaSelectionState.getNumberOfItemsTrue(filteredMediaIds);
  }, [filteredMediaIds, mediaSelectionState]);

  const sortedMediaGroups = useMemo<IMediaGroup[]>(() => {
    if (!filteredMedia?.length) return [];

    const sorter = sorters.find(s => s.key === sortBy);

    if (!sorter) return [];

    const groups = filteredMedia.reduce<Record<string, IMediaGroup>>((result, media) => {
      const groupKey = sorter ? sorter.selectGroupingKey(media) : DEFAULT_GROUP_KEY;

      // Group media by the grouping key
      if (!result[groupKey]) {
        // initialize media group
        result[groupKey] = {
          key: groupKey,
          label: sorter ? sorter.resolveGroupLabel(groupKey) : DEFAULT_GROUP_KEY, // Resolve key into readable label
          media: [],
          chunkedMedia: [],
        };
      }

      result[groupKey].media.push(media); // Add media to the group
      return result;
    }, {});

    // Transform groups object into array of groups
    const groupArray = Object.keys(groups).map(groupKey => groups[groupKey]);

    // Sort groups
    if (sorters?.length) {
      groupArray.sort(sorter.sortGroups);
    }

    // No sorters, one default group
    return groupArray.map(g => {
      const sortedGroup = { ...g };

      // Sort group's media
      if (sorter?.sortGroupMedia) {
        sortedGroup.media.sort(sorter.sortGroupMedia);
      }

      // Chunk media into separate rows (columns calculated based on available width)
      sortedGroup.chunkedMedia = chunkArray(sortedGroup.media, columns);
      return sortedGroup;
    });
  }, [filteredMedia, sorters, sortBy, columns]);

  const sortedMediaLookup = useMemo(() => {
    /** Lookup media id at global index of media across sorted media groups. */
    const indexToId: Record<number, string> = {};
    /** Lookup global index of media across sorted media groups by id. */
    const idToIndex: Record<string, number> = {};

    let indexOffset = 0;

    sortedMediaGroups.forEach(({ media }) => {
      media.forEach(({ id }, i) => {
        const index = indexOffset + i; // Offset index to calculate global index across groups
        indexToId[index] = id;
        idToIndex[id] = index;
      });

      indexOffset += media.length;
    });

    return {
      indexToId,
      idToIndex,
    };
  }, [sortedMediaGroups]);

  const bulkSelection = useBulkSelection({
    selectionState: mediaSelectionState,
    itemLookup: sortedMediaLookup,
  });

  // Possible future enhancement: make top bar containing filters and sorters sticky once scrolled down or provide scroll to top button once scrolled past
  // use bottom offset once the topbar top offset is 0 to determine the height of the media container needed to stick the top bar in place
  // use sticky hook scroll event handler is not getting fired

  const groupCounts = useMemo(
    () => sortedMediaGroups.map(it => it.chunkedMedia.length),
    [sortedMediaGroups],
  );

  const handleMasterCheckboxClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    event => {
      // Stop propagation to support deselection on list background click
      event.stopPropagation();

      bulkSelection.handleMasterCheckboxClick(filteredMediaIds, filteredMediaSelectionStateSummary);
    },
    [filteredMediaSelectionStateSummary, filteredMediaIds, bulkSelection],
  );

  const renderItem = useCallback(
    (item: ITransformedMediaItem) => {
      const { id, mediaType } = item;
      const TypeIcon = mediaType ? MEDIA_TYPE_TO_DEF[mediaType]?.icon || null : null;
      const isSelected = mediaSelectionState.getItemState(id);
      const isHovered = mediaHoverState.getItemState(id);

      return (
        <IdsImageListItem
          image={item}
          getImageUrl={getMediaThumbnailUrl}
          imageSize={imageSize}
          onClick={
            bulkSelectionEnabled
              ? (_, event) => {
                  // Stop propagation to support deselection on list background click
                  event.stopPropagation();

                  bulkSelection.handleItemClick(id, {
                    shiftKey: event.shiftKey,
                    ctrlKey: event.ctrlKey,
                    cmdKey: event.metaKey,
                  });
                }
              : undefined
          }
          onDoubleClick={onImageClick}
          renderImage={renderImage}
          imageProps={{
            ...imageProps,
            placeholderIcon: TypeIcon ? <TypeIcon /> : undefined,
            renderBottomLeftIcon: TypeIcon ? props => <TypeIcon {...props} /> : undefined,
            containerProps: {
              className: styles.selectedImageContainer,
              style: {
                borderColor: isSelected ? theme.palette.secondary.main : 'transparent', // Maintain transparent border when not selected to avoid the layout jumping when de/selected
              },
            },
            ...(bulkSelectionEnabled &&
              (isSelected || isHovered) && {
                renderTopLeftIcon: (props, hasImg) => (
                  <Checkbox
                    {...props}
                    checked={isSelected}
                    onChange={event => {
                      // Stop propagation to support deselection on list background click
                      event.stopPropagation();

                      bulkSelection.handleItemCheckboxChange(
                        id,
                        event.target.checked,
                        (event.nativeEvent as any).shiftKey, // This property exists, just missing in the TS type
                      );
                    }}
                    onMouseEnter={() => {
                      mediaHoverState.setItemState(id, true);
                    }}
                    onMouseLeave={() => {
                      mediaHoverState.setItemState(id, false);
                    }}
                    onClick={event => event.stopPropagation()}
                    style={{
                      ...(hasImg && { margin: '-9px' }), // needed to move the checkbox to be aligned correctly (handles offset of the touchable ripple)
                    }}
                    color='secondary'
                  />
                ),
                topLeftIconRequiresImage: false,
              }),
            onMouseEnter: () => {
              mediaHoverState.setItemState(id, true);
            },
            onMouseLeave: () => {
              mediaHoverState.setItemState(id, false);
            },
          }}
        />
      );
    },
    [
      getMediaThumbnailUrl,
      imageProps,
      imageSize,
      onImageClick,
      renderImage,
      mediaSelectionState,
      mediaHoverState,
      bulkSelectionEnabled,
      theme,
      bulkSelection,
    ],
  );

  const renderGroupedVirtuosoGroupContent = useCallback(
    (groupIndex: number) => {
      if (sortedMediaGroups[groupIndex].key !== DEFAULT_GROUP_KEY) {
        const groupMediaIds = sortedMediaGroups[groupIndex].media.map(item => getMediaId(item));
        const selectionStateSummary = mediaSelectionState.getItemsStateSummary(groupMediaIds);

        const handleGroupCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          // Stop propagation to support deselection on list background click
          event.stopPropagation();

          bulkSelection.handleMasterCheckboxClick(groupMediaIds, selectionStateSummary);
        };

        return (
          <div style={{ paddingLeft: MEDIA_ITEMS_GAP }} className={styles['groupContentContainer']}>
            <Grid container direction='row' alignItems='center'>
              {bulkSelectionEnabled && (
                <Grid item xs='auto'>
                  <Checkbox
                    checked={selectionStateSummary !== BooleanStateSummary.ALL_FALSE}
                    indeterminate={selectionStateSummary === BooleanStateSummary.MIXED}
                    onClick={handleGroupCheckboxClick}
                    color='secondary'
                    className={styles.groupCheckbox}
                  />
                </Grid>
              )}
              <Grid item xs>
                <Typography variant='overline' fontSize='0.9rem'>
                  {sortedMediaGroups[groupIndex].label}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      }
      return <div className={styles['groupContentFiller']} />;
    },
    [bulkSelectionEnabled, getMediaId, mediaSelectionState, sortedMediaGroups, bulkSelection],
  );

  const enhancedBulkActions = useMemo(() => {
    return (
      bulkActions?.map(({ onClick, ...rest }) => ({
        ...rest,
        onClick: () => onClick(mediaSelectionState.selectedItemIds),
      })) || []
    );
  }, [bulkActions, mediaSelectionState]);

  const renderGroupedVirtuosoItemContent = useCallback(
    (index: number, groupIndex: number) => {
      // Need to calculate 'index' of row based on `index` - `previous groups indexes` (length of chunked arrays)
      const chunkedMediaIndex = getChunkedMediaIndex(sortedMediaGroups, groupIndex, index);

      return (
        <Grid
          item
          container
          gap={2}
          style={{
            paddingBottom: MEDIA_ITEMS_GAP,
            minHeight: 10,
            paddingLeft: MEDIA_ITEMS_GAP,
          }}
        >
          {sortedMediaGroups[groupIndex].chunkedMedia[chunkedMediaIndex]?.map(it => (
            <Fragment key={it?.id}>{renderItem(it)}</Fragment>
          ))}
        </Grid>
      );
    },
    [renderItem, sortedMediaGroups],
  );

  return (
    <Box className={styles.container}>
      <Grid container direction='row' spacing={2} className={styles.topBar}>
        {filters.map((filter, i) => {
          const renderedFilter = filter.renderFilter();
          return filter.disableWrapper ? (
            renderedFilter
          ) : (
            <Grid key={`filter-${i}`} item xs='auto'>
              {renderedFilter}
            </Grid>
          );
        })}
        {sorters && sorters.length > 0 && (
          <Grid item xs='auto'>
            <IdsSelect
              label='Sort By'
              options={sorters.map(s => ({ label: s.label, value: s.key }))}
              value={sortBy}
              onChange={handleSortByChange}
              className={styles.sortByField}
              disabled={sorters.length === 0}
            />
          </Grid>
        )}
        <Grid item xs container spacing={2} direction='row' justifyContent='flex-end'>
          {loading && (
            <Grid item xs='auto' className='centerChildren'>
              <CircularProgress size={30} className={styles.loader} />
            </Grid>
          )}
          {actions && (
            <Grid item xs>
              {actions}
            </Grid>
          )}
          <Grid item xs='auto'>
            <Stack
              spacing={2}
              direction='row'
              alignItems='center'
              className={styles.imageSizeSliderContainer}
            >
              <PhotoSizeSelectLargeIcon />
              <Slider
                size='small'
                min={IMAGE_SIZES.S}
                max={IMAGE_SIZES.L}
                value={imageSize}
                marks={IMAGE_SIZE_MARKS}
                step={null}
                onChange={handleImageSizeChange}
                className={styles.imageSizeSlider}
              />
              <PhotoSizeSelectActualIcon />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        direction='column'
        onClick={bulkSelectionEnabled ? mediaSelectionState.setAllItemsToFalse : undefined}
        mt={0.5}
        className={styles.listContainer}
      >
        {bulkSelectionEnabled && (!!media.length || mediaSelectionState.totalTrue > 0) && (
          <Grid container direction='row' alignItems='center' columnGap={1} pl={0.5}>
            <Grid item xs='auto'>
              <Checkbox
                checked={filteredMediaSelectionStateSummary !== BooleanStateSummary.ALL_FALSE}
                indeterminate={filteredMediaSelectionStateSummary === BooleanStateSummary.MIXED}
                onClick={handleMasterCheckboxClick}
                color='secondary'
              />
            </Grid>
            <Grid item xs='auto'>
              {bulkActions?.length && mediaSelectionState.totalTrue > 0 && (
                <BulkActionsChip
                  onDeselectAll={mediaSelectionState.setAllItemsToFalse}
                  totalSelected={mediaSelectionState.totalTrue}
                  totalSelectedVisible={totalVisibleMediaItemsSelected}
                  bulkActions={enhancedBulkActions}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          flexDirection='column'
          className={styles.mediaContainer}
          ref={groupsContainerRef}
        >
          {sortedMediaGroups.length ? (
            <Grid item className={styles['groupContainer']}>
              <GroupedVirtuoso
                groupCounts={groupCounts}
                groupContent={renderGroupedVirtuosoGroupContent}
                itemContent={renderGroupedVirtuosoItemContent}
              />
            </Grid>
          ) : (
            // Don't show the noMediaMessage if loading
            (!loading || (media && media.length > 0)) && (
              <Grid item>
                <Alert severity={noMediaSeverity || (media?.length ? 'warning' : 'info')}>
                  {media?.length
                    ? 'No media for current filters'
                    : noMediaMessage || 'No media to display'}
                </Alert>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default IdsMediaListView;
