import { atom } from 'recoil';

export interface ISessionState {
  id: string;
  firstName: string;
  lastName: string;
}

const sessionState = atom<ISessionState | null>({
  key: 'sessionState',
  default: null,
});

export { sessionState };
