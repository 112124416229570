import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { IconButton, ListItemText, Stack } from '@mui/material';

import { gql } from 'graphql-request';

import IdsListItem from '../../../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../../../components/ids-lists/IdsListItem/IdsListItemChild';
import IdsListItemMenu from '../../../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../../../components/ids-lists/IdsListItemMenuItem';
import { PositionAction } from '../../../../../services/types';
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  EditOutlineIcon,
  DeleteOutlineIcon,
} from '../../../../../theme/icons';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../constants/media';
import getFileNameFromUrl from '../../../../../utils/getFileNameFromUrl';
import usePermissions from '../../../../../hooks/usePermissions';
import DefaultStatusChip from '../DefaultStatusChip';

export const OverlayListItemFrag = gql`
  fragment OverlayListItemFrag on Overlay {
    id
    name
    position
    archiveUrl
    metadata {
      id
      type
      value
    }
    createdAt
    ... on RasterOverlay {
      default
    }
    ... on VectorOverlay {
      color
    }
  }
`;

export const OverlayListItemMenu = ({ overlay, entityType, onEdit, useDeleteOverlayAction }) => {
  const { deleteOverlay } = useDeleteOverlayAction();
  const { userHasPermission } = usePermissions();

  const canUpdate = useMemo(
    () => userHasPermission('update', entityType),
    [userHasPermission, entityType],
  );
  const canDelete = useMemo(
    () => userHasPermission('delete', entityType),
    [userHasPermission, entityType],
  );

  const handleEdit = useCallback(() => {
    onEdit(overlay.id);
  }, [overlay.id, onEdit]);

  const handleDelete = useCallback(() => {
    deleteOverlay(overlay);
  }, [deleteOverlay, overlay]);

  return (
    (canUpdate || canDelete) && (
      <IdsListItemMenu>
        {canUpdate && (
          <IdsListItemMenuItem icon={<EditOutlineIcon />} onClick={handleEdit}>
            Edit
          </IdsListItemMenuItem>
        )}
        {canDelete && (
          <IdsListItemMenuItem icon={<DeleteOutlineIcon />} onClick={handleDelete}>
            Delete
          </IdsListItemMenuItem>
        )}
      </IdsListItemMenu>
    )
  );
};

const OverlayListItem = ({
  overlay,
  entityType,
  onChangePosition,
  onEdit,
  useDeleteOverlayAction,
}) => {
  const { userHasPermission } = usePermissions();
  const canUpdate = useMemo(
    () => userHasPermission('update', entityType),
    [userHasPermission, entityType],
  );

  const shiftPositionByOne = useCallback(
    action => {
      onChangePosition(overlay.id, action, overlay.position);
    },
    [overlay, onChangePosition],
  );

  const level = useMemo(
    () =>
      overlay.metadata.find(m => m.type === MEDIA_METADATA_TYPES[MediaMetadataType.Level].type)
        ?.value,
    [overlay.metadata],
  );
  const fileName = useMemo(
    () => getFileNameFromUrl(overlay.archiveUrl),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { id, name, default: isDefault, createdAt } = overlay;

  return (
    <IdsListItem
      key={id}
      secondaryAction={
        <OverlayListItemMenu
          overlay={overlay}
          entityType={entityType}
          onEdit={onEdit}
          useDeleteOverlayAction={useDeleteOverlayAction}
        />
      }
    >
      {canUpdate && (
        <IdsListItemChild xs='auto'>
          <Stack direction='column'>
            <IconButton size='small' onClick={() => shiftPositionByOne(PositionAction.Decrement)}>
              {/* closer to top */}
              <ArrowCircleUpIcon />
            </IconButton>
            <IconButton size='small' onClick={() => shiftPositionByOne(PositionAction.Increment)}>
              {/* closer to bottom */}
              <ArrowCircleDownIcon />
            </IconButton>
          </Stack>
        </IdsListItemChild>
      )}
      <IdsListItemChild xs>
        <ListItemText
          primary={name}
          secondary={fileName && fileName !== 'null' ? fileName : ''}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </IdsListItemChild>
      {isDefault && (
        <IdsListItemChild xs={2}>
          <DefaultStatusChip />
        </IdsListItemChild>
      )}
      <IdsListItemChild xs={2}>
        <ListItemText
          primary='Level'
          primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
          secondary={level}
          secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
        />
      </IdsListItemChild>
      <IdsListItemChild xs={2}>
        <ListItemText
          primary={new Date(createdAt).toLocaleDateString()}
          secondary={new Date(createdAt).toLocaleTimeString()}
        />
      </IdsListItemChild>
    </IdsListItem>
  );
};

OverlayListItem.propTypes = {
  overlayItem: PropTypes.object.isRequired,
};

export default OverlayListItem;
