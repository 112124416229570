import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ButtonBase, Grid, Tooltip } from '@mui/material';

import { activeTenantState } from '../../../../atoms/tenants';
import IdsDataAttribute from '../../../../components/IdsDataAttribute';
import TenantLogo from '../../../../components/TenantLogo';
import { useIdsUploaderUppy } from '../../../../components/ids-inputs/uploaders/IdsUploader';
import IdsDialogUploader from '../../../../components/ids-inputs/uploaders/IdsDialogUploader';
import { UploadType } from '../../../../constants/uploads';
import usePermissions from '../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../constants/users';

import styles from './TenantDetailsTab.module.css';

const tenantLogoUploaderId = 'TenantLogoUploader';

const TenantDetailsTab = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { uppy } = useIdsUploaderUppy(tenantLogoUploaderId);
  const [logoUploaderOpen, setLogoUploaderOpen] = useState(false);
  const { userHasOneOfRoles } = usePermissions();

  const canEdit = userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]);

  const openLogoUploader = useCallback(() => {
    setLogoUploaderOpen(true);
  }, []);

  const closeLogoUploader = useCallback(() => {
    setLogoUploaderOpen(false);
  }, []);

  if (!activeTenant) {
    return null;
  }

  const logo = (
    <TenantLogo
      fallbackLogo='light'
      width={75}
      height={75}
      containerProps={{
        className: styles.logo,
      }}
    />
  );

  return (
    <>
      <Grid container spacing={1} direction='column' className={styles.container}>
        <IdsDataAttribute label='Subdomain' value={activeTenant.subdomain} />
        <IdsDataAttribute label='Name' value={activeTenant.name} />
        <IdsDataAttribute
          label='Logo'
          disableTypography={true}
          value={
            canEdit ? (
              <Tooltip title='Edit' arrow>
                <div className={styles.fitContent}>
                  <ButtonBase onClick={openLogoUploader}>{logo}</ButtonBase>
                </div>
              </Tooltip>
            ) : (
              logo
            )
          }
        />
      </Grid>
      <IdsDialogUploader
        uppy={uppy}
        supportedUploaders={{
          [UploadType.TenantLogo]: {},
        }}
        fileSelectionStepLabel='Select tenant logo'
        dialogProps={{
          title: 'Upload Tenant Logo',
          open: logoUploaderOpen,
          onClose: closeLogoUploader,
          noSearchParam: true,
        }}
      />
    </>
  );
};

export default TenantDetailsTab;
