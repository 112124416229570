import { useMemo } from 'react';

import { USER_ROLES } from '../../../../../../../constants/users';
import { RouteStatus } from '../../../../../../../services/RouteService';
import usePermissions from '../../../../../../../hooks/usePermissions';

const usePublishPermissions = (
  routePointsLength: number,
  routeStatus: RouteStatus,
  published: boolean,
) => {
  const { userHasOneOfRoles } = usePermissions();

  return useMemo(() => {
    const isAdmin = userHasOneOfRoles([
      USER_ROLES.IDS_ADMIN,
      USER_ROLES.IDS_TEAM,
      USER_ROLES.TENANT_TEAM,
      USER_ROLES.TENANT_ADMIN,
    ]);

    return {
      mayPublish: isAdmin && !published && routePointsLength > 0,
      mayUnPublish: isAdmin && published && routeStatus === RouteStatus.new,
    };
  }, [routePointsLength, routeStatus, published, userHasOneOfRoles]);
};

export default usePublishPermissions;
