import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import {
  IUpdateUserRoleInOrgMutationPayload,
  useUpdateUserRoleInOrg,
} from '../../../services/UsersService';
import { getOrgUsersRoute } from '../../../utils/routes';

import { IOrganizationsTabUserFragType } from '../AccessTab/fragments';

import IdsEditForm from '../../../components/ids-forms/IdsEditForm';

import LoadingScreen from '../../../components/LoadingScreen';

import { activeTenantState } from '../../../atoms/tenants';

import { activeOrganizationState } from '../../../atoms/organizations';

import { UserRole, UserRoleTypeUppercase, USER_ROLES } from '../../../constants/users';

import Card from './Card';
import { IFormValues } from './types';

export interface IOrganizationTabProps {
  user: IOrganizationsTabUserFragType;
}

const OrganizationTab: React.FC<IOrganizationTabProps> = ({ user }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: updateUserRoleInOrgMutation } = useUpdateUserRoleInOrg(user.id);

  const userRoleEntry = user.roles.find(
    it => it.domainType === 'Organization' && it.domainId === activeOrg.id,
  );

  // Initial form values (role, projects)
  const initialValues = useMemo(
    () => ({
      role: userRoleEntry?.roleName,
      projects: userRoleEntry?.projects.map(it => it.id) || [],
    }),
    [userRoleEntry],
  );

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      // Legacy role selected, not supported in BE
      if (
        values.role &&
        !USER_ROLES[values.role.toUpperCase() as UserRoleTypeUppercase].canAssign
      ) {
        return;
      }

      const payload: IUpdateUserRoleInOrgMutationPayload = {
        orgId: activeOrg.id,
        userId: user.id,
        roleName: values.role,
        // projects is required by the mutation to at least be an empty array
        projects: (values.role === UserRole.org_team && values.projects?.map(id => ({ id }))) || [],
        action: values.role ? 'update' : 'delete',
      };

      const { updateUserRoleInOrganization } = await updateUserRoleInOrgMutation(payload);

      if (updateUserRoleInOrganization?.errors?.length) {
        throw new Error(updateUserRoleInOrganization.errors[0].message);
      }

      if (!values.role) {
        enqueueSnackbar('User access was removed', {
          variant: 'success',
        });

        navigate(
          getOrgUsersRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        );
      }
    },
    [
      user,
      activeOrg,
      updateUserRoleInOrgMutation,
      activeTenant?.subdomain,
      enqueueSnackbar,
      navigate,
    ],
  );

  if (initialValues === null) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <IdsEditForm
        onCancel={() =>
          navigate(
            getOrgUsersRoute({
              subdomain: activeTenant?.subdomain,
              orgId: activeOrg.id,
            }),
          )
        }
        initialValues={initialValues}
        onSubmit={onSubmit}
        errorHandler={error => error || 'User could not be updated'}
        successMessage='User updated'
      >
        <Card />
      </IdsEditForm>
    </Box>
  );
};

export default OrganizationTab;
