import React, { createContext, useMemo, useState } from 'react';
import { DeckProps } from '@deck.gl/core/typed';

export interface IEvents
  extends Pick<DeckProps, 'onClick' | 'onHover' | 'onDrag' | 'onDragStart' | 'onDragEnd'> {
  pickable?: boolean;
  handleRightButton?: boolean;
}

export interface ILayerEventsContext {
  value: IEvents | null;
  setValue: (events: IEvents | null) => void;
}

const defaultValue = {
  value: null,
  setValue: () => {
    // nonempty function body to bypass eslint check
    return;
  },
};

const LayerEventsContext = createContext<ILayerEventsContext>(defaultValue);

export interface ILayerEventsProviderProps {
  events: IEvents | null;
  children: React.ReactNode;
}

export const LayerEventsProvider: React.FC<ILayerEventsProviderProps> = ({ events, children }) => {
  const [value, setValue] = useState<ILayerEventsContext['value']>(events);

  const memoizedValue = useMemo(() => {
    return { value, setValue };
  }, [value]);

  return (
    <LayerEventsContext.Provider value={memoizedValue}>{children}</LayerEventsContext.Provider>
  );
};

export default LayerEventsContext;
