import React from 'react';
import { useSnackbar } from 'notistack';
import { Formik, Form, FormikProps, FormikHelpers, FormikConfig } from 'formik';

const defaultErrorMsg = 'Something went wrong';

export interface IIdsFormProps extends FormikConfig<any> {
  successMessage?: string;
  cancelMessage?: string;
  errorHandler?: (error: string, formikHelpers: FormikHelpers<any>) => string;
  /** Return false if request was canceled.
   * `cancelMessage` will be shown if set.*/
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => Promise<boolean | void> | void;
  id?: string;
  /** If true, the submission event propagation will be stopped to prevent submitting parent form as well. */
  stopSubmitPropagation?: boolean;
  className?: string;
}

const IdsForm: React.FC<IIdsFormProps> = ({
  children,
  successMessage,
  cancelMessage,
  errorHandler,
  onSubmit,
  id,
  stopSubmitPropagation,
  className,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    try {
      const result = await onSubmit(values, formikHelpers);

      if (result === false) {
        // canceled
        if (cancelMessage) {
          enqueueSnackbar(cancelMessage, { variant: 'warning' });
        }
      } else {
        // success
        if (successMessage) {
          enqueueSnackbar(successMessage, { variant: 'success' });
        }
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);

      handleError(
        (errorHandler && errorHandler(err.message, formikHelpers)) || defaultErrorMsg,
        formikHelpers,
      );
    }
    return false;
  };

  const handleError = (message = defaultErrorMsg, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setStatus({ success: false });
    formikHelpers.setSubmitting(false);
    enqueueSnackbar(message, { variant: 'error' });
  };

  async function silentValidationCatch(
    validate: FormikProps<any>['validateForm'],
    handleSubmit: FormikProps<any>['handleSubmit'],
    event: React.FormEvent<HTMLFormElement>,
  ) {
    const invalid = await validate();
    if (Object.keys(invalid).length > 0) {
      // eslint-disable-next-line no-console
      console.error('Form errors: ', invalid);
    }

    handleSubmit(event);
  }

  return (
    <Formik onSubmit={submit} {...rest}>
      {({ validateForm, handleSubmit }) => (
        <Form
          id={id}
          noValidate
          onSubmit={event => {
            event.preventDefault();
            silentValidationCatch(validateForm, handleSubmit, event);
            if (stopSubmitPropagation) {
              event.stopPropagation();
            }
          }}
          className={className}
        >
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default IdsForm;
