import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { gql } from 'graphql-request';
import clsx from 'clsx';

import CommentsSection from '../../../../features/Comments/EntityComments';
import MediaCommentFrag from '../../../../features/Comments/EntityComments/MediaCommentFrag';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';
import useCommentsSectionScroller from '../../../../hooks/useCommentsSectionScroller';
import CommentsSectionHeader from '../../../IdsCommentsSection/CommentsSectionHeader';
import useMediaViewerTabsContext from '../MediaViewerTabs/useMediaViewerTabsContext';
import { TAB_SIZES } from '../MediaViewerTabs';

import ImageInfoTable from './ImageInfoTable';
import ImageTags from './ImageTags';
import styles from './MediaViewerInfoTab.module.css';

export const MediaViewerInfoTabFrag = gql`
  fragment MediaViewerInfoTabFrag on PrismaImage {
    description
    published
    author {
      id
      firstName
      lastName
    }
    comments {
      ...MediaCommentFrag
    }
  }
  ${MediaCommentFrag}
`;

const SCROLLBAR_OPTIONS = { suppressScrollX: true };

/** IMPORTANT: This width value was manually retrieved via dev tools to figure out the minimum width the info
table takes up with two columns of data attributes. This should be updated if that min width is changed by adjusting padding,
margin, width, font size, etc to ensure proper styling. It is used to maximize the space available for the image tags section
while still wrapping the second column down below the first in smaller width scenarios.
*/
export const MIN_TWO_COLUMN_WIDTH = 447.34375;

const MediaViewerInfoTab = ({
  onTagsUpdate,
  onImageInfoUpdate,
  activeImage,
  tabSize,
  height,
  allImageTags,
  isEditMode,
  toggleEditMode,
  tenantId,
  orgId,
}) => {
  const [infoTableWidth, setInfoTableWidth] = useState(0);
  const scrollbarRef = useRef();
  const scrollContainerRef = useRef();
  const { size, setSize } = useMediaViewerTabsContext();
  const [autoFocusCommentInput, setAutoFocusCommentInput] = useState(false);
  const { scrollbarProps, commentsSectionProps } = useCommentsSectionScroller({
    scrollbarRef,
    scrollContainerRef,
    entityId: activeImage?.id,
    autoFocusCommentInput,
  });

  useEffect(() => {
    // reset autofocus flag when comment section is hidden
    if (size === TAB_SIZES.RIBBON || size === TAB_SIZES.HIDDEN) {
      setAutoFocusCommentInput(false);
    }
    setAutoFocusCommentInput(false);
  }, [setAutoFocusCommentInput, size]);

  const handleScrollContainerRef = useCallback(container => {
    scrollContainerRef.current = container;
  }, []);

  const getMetadataValue = useCallback(
    type => {
      if (activeImage?.metadata) {
        return activeImage.metadata.find(d => d.type === type)?.value || '-';
      }
      return null;
    },
    [activeImage],
  );

  const viewAllComments = useCallback(() => {
    setSize(TAB_SIZES.FULL);
  }, [setSize]);

  const startComment = useCallback(() => {
    setSize(TAB_SIZES.EXPANDED);
    setAutoFocusCommentInput(true);
  }, [setSize, setAutoFocusCommentInput]);

  const imageBaseInfo = useMemo(
    () =>
      !!activeImage && (
        <Grid container rowSpacing={1} p={1}>
          <Grid
            item
            xs={12}
            container
            direction='row'
            columnSpacing={1}
            justifyContent='space-between'
            wrap='nowrap'
          >
            <Grid item xs zeroMinWidth>
              <Typography variant='body2' color='text.secondary' noWrap>
                Date Captured:
              </Typography>
              <Typography variant='body2' noWrap>
                {new Date(activeImage.capturedAt).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant='body2' color='text.secondary' noWrap>
                Level:
              </Typography>
              <Typography variant='body2' noWrap>
                {getMetadataValue(MEDIA_METADATA_TYPES[MediaMetadataType.Level].type)}
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant='body2' color='text.secondary' noWrap>
                Area:
              </Typography>
              <Typography variant='body2' noWrap>
                {getMetadataValue(MEDIA_METADATA_TYPES[MediaMetadataType.Area].type)}
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant='body2' color='text.secondary'>
                Category:
              </Typography>
              <Typography variant='body2' noWrap>
                {getMetadataValue(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].type)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <CommentsSectionHeader
              comments={activeImage.comments}
              onViewAllComments={viewAllComments}
              onCreateComment={startComment}
              className={styles.commentsHeader}
            />
          </Grid>
        </Grid>
      ),
    [activeImage, getMetadataValue, viewAllComments, startComment],
  );

  const [scrollingOffset, setScrollingOffset] = useState(-1);

  const singleColumnLayout = useMemo(() => infoTableWidth < MIN_TWO_COLUMN_WIDTH, [infoTableWidth]);

  const imageFullInfo = useMemo(() => {
    return activeImage?.id ? (
      <ImageInfoTable
        onImageInfoUpdate={onImageInfoUpdate}
        activeImage={activeImage}
        getMetadataValue={getMetadataValue}
        singleColumnLayout={singleColumnLayout}
        onResize={setInfoTableWidth}
        isEditMode={isEditMode}
        toggleEditMode={toggleEditMode}
        tenantId={tenantId}
      />
    ) : null;
  }, [
    onImageInfoUpdate,
    activeImage,
    getMetadataValue,
    singleColumnLayout,
    setInfoTableWidth,
    isEditMode,
    toggleEditMode,
    tenantId,
  ]);

  return (
    <div className={styles.infoPanelContent} style={{ height }}>
      {(tabSize === TAB_SIZES.EXPANDED || tabSize === TAB_SIZES.FULL) && (
        <PerfectScrollbar
          className={clsx({ [styles['no-scrollbar']]: !!scrollingOffset })}
          options={SCROLLBAR_OPTIONS}
          ref={scrollbarRef}
          containerRef={handleScrollContainerRef}
          {...scrollbarProps}
          onScrollY={container => {
            if (scrollingOffset !== -1) {
              container.scrollTop = scrollingOffset;
            }
          }}
        >
          <Grid container direction='row' pt={1}>
            <Grid item xs container direction='row'>
              <Grid item xs className={clsx(styles.imageInfoContainer)}>
                {imageFullInfo}
              </Grid>
              <Grid item xs className={clsx(styles.imageTagsContainer)}>
                {activeImage?.id && (
                  <ImageTags
                    onTagsUpdate={onTagsUpdate}
                    imageId={activeImage.id}
                    metadata={activeImage?.metadata || []}
                    allImageTags={allImageTags}
                    setScrollingOffset={setScrollingOffset}
                    scrollContainerRef={scrollContainerRef}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <CommentsSection
                orgId={orgId}
                parentEntityId={activeImage?.id}
                comments={activeImage?.comments || []}
                onViewAllComments={viewAllComments}
                hideViewAllComments={size === TAB_SIZES.FULL}
                autoFocusCommentInput={autoFocusCommentInput}
                {...commentsSectionProps}
              />
            </Grid>
          </Grid>
        </PerfectScrollbar>
      )}
      {tabSize === TAB_SIZES.RIBBON && imageBaseInfo}
    </div>
  );
};

export default MediaViewerInfoTab;
