import { useCallback, useEffect } from 'react';

import { IFilter } from '../../context/FilterContext';

import useFilterContext from '../useFilterContext';
import usePrevious from '../usePrevious';

import useActiveFilterTargets from './useActiveFilterTargets';

export enum BooleanFilterMode {
  Inactive = 'Inactive',
  True = 'True',
  False = 'False',
}

const FILTER_FUNCTIONS: PartialRecord<BooleanFilterMode, IFilter<boolean>['filterItem']> = {
  [BooleanFilterMode.True]: bool => bool,
  [BooleanFilterMode.False]: bool => !bool,
};

export interface IUseBooleanFilterProps {
  options: Omit<Optional<IFilter<boolean>, 'mode'>, 'filterItem' | 'selected'> & {
    onApply?: () => void;
  };
  booleanFilterMode: BooleanFilterMode;
  setBooleanFilterMode: (newMode: BooleanFilterMode) => void;
  filterModeLabels?: Record<BooleanFilterMode, string>;
}

const useBooleanFilter = ({
  options,
  booleanFilterMode,
  setBooleanFilterMode: _setBooleanFilterMode,
  filterModeLabels,
}: IUseBooleanFilterProps) => {
  const prevFilterMode = usePrevious(booleanFilterMode);

  const { addFilter, removeFilter } = useFilterContext();

  const activeFilterTargets = useActiveFilterTargets(options.targets);

  const removeBooleanFilter = useCallback(() => {
    removeFilter(options.name);
  }, [removeFilter, options.name]);

  const applyFilter = useCallback(
    (filterMode: BooleanFilterMode) => {
      const { onRemove, onDestroy, onApply } = options;

      addFilter({
        ...options,
        filterItem: FILTER_FUNCTIONS[filterMode]!,
        selected: filterModeLabels ? filterModeLabels[filterMode] : filterMode,
        onRemove: () => {
          _setBooleanFilterMode(BooleanFilterMode.Inactive);

          if (onRemove) {
            onRemove();
          }
        },
        onDestroy: () => {
          if (onDestroy) {
            onDestroy();
          }
        },
        targets: activeFilterTargets,
      });

      if (onApply) {
        onApply();
      }
    },
    [addFilter, activeFilterTargets, _setBooleanFilterMode, options, filterModeLabels],
  );

  const applyFilterMode = useCallback(
    (filterMode: BooleanFilterMode) => {
      if (filterMode === BooleanFilterMode.Inactive) {
        removeBooleanFilter();

        return;
      }

      applyFilter(filterMode);
    },
    [applyFilter, removeBooleanFilter],
  );

  const setBooleanFilterMode = useCallback(
    (mode: BooleanFilterMode) => {
      if (!mode || mode === booleanFilterMode) {
        return;
      }

      _setBooleanFilterMode(mode);
    },
    [booleanFilterMode, _setBooleanFilterMode],
  );

  const toggleBooleanFilter = useCallback(() => {
    if (booleanFilterMode === BooleanFilterMode.Inactive) {
      setBooleanFilterMode(BooleanFilterMode.True);
    } else if (booleanFilterMode === BooleanFilterMode.True) {
      setBooleanFilterMode(BooleanFilterMode.False);
    } else if (booleanFilterMode === BooleanFilterMode.False) {
      setBooleanFilterMode(BooleanFilterMode.Inactive);
    }
  }, [booleanFilterMode, setBooleanFilterMode]);

  useEffect(() => {
    if (booleanFilterMode !== prevFilterMode) {
      applyFilterMode(booleanFilterMode);
    }
  }, [booleanFilterMode, prevFilterMode, applyFilterMode]);

  return {
    setBooleanFilterMode,
    booleanFilterMode,
    toggleBooleanFilter,
  };
};

export default useBooleanFilter;
