import React from 'react';

import { IProjectRouteListItemData } from '../../../../../services/fragments';
import IdsSearchableList from '../../../../../components/ids-lists/IdsSearchableList';

import Actions from './Actions';
import RouteListItem from './RouteListItem';

const searchItem = (item: IProjectRouteListItemData, query: string) => {
  const searchProps = [item.name, item.description];

  return !!searchProps.filter(prop => prop?.toLowerCase().includes(query.toLowerCase())).length;
};

/**
 * @TODO: Remove type casting here and change the corresponding interface
 * declaration once position field type is changed to int.
 */
const sortItems = (a: IProjectRouteListItemData, b: IProjectRouteListItemData) => {
  // Show highest position at the top
  return +a.position - +b.position;
};

export interface IRoutesListProps {
  items: IProjectRouteListItemData[];
  isLoading?: boolean;
  error?: string;
}

const RoutesList: React.FC<IRoutesListProps> = ({ items, isLoading, error, ...rest }) => {
  const maxPositions = items.length;

  return (
    <IdsSearchableList
      header={undefined}
      filters={undefined}
      filterMode={undefined}
      searchPlaceholder='Search routes'
      noItemsMessage='No routes'
      noItemsForFilterMessage='No routes match your search'
      loading={isLoading}
      error={error && 'Routes could not be loaded'}
      items={items}
      searchItem={searchItem}
      sortItems={sortItems}
      renderItem={(route: IProjectRouteListItemData) => (
        <RouteListItem
          key={route.id}
          disabled={isLoading}
          maxPositions={maxPositions}
          item={route}
        />
      )}
      actions={<Actions />}
      {...rest}
    />
  );
};

export default RoutesList;
