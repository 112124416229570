import { useMemo } from 'react';

import { IRouteDetails, RouteStatus } from '../../../../../services/RouteService';
import usePermissions from '../../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../../constants/users';

const useCanEditRoute = (route: IRouteDetails) => {
  const { userHasOneOfRoles } = usePermissions();

  return useMemo(() => {
    return (
      route.status === RouteStatus.new &&
      !route.published &&
      userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN])
    );
  }, [route, userHasOneOfRoles]);
};

export default useCanEditRoute;
