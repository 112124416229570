import React, { useMemo } from 'react';
// import { Box, Button } from '@mui/material';

import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import useGraphQuery from '../../../../hooks/useGraphQuery';
import { useOrganizationKeys } from '../../../../services/OrganizationsService';
import { activeOrganizationState } from '../../../../atoms/organizations';
import IdsMediaListView from '../../../../components/ids-lists/IdsMediaListView';

import styles from './ExampleImagesTab.module.css';
// import usePermissions from '../../../../hooks/usePermissions';
// import { EntityType } from '../../../../constants/entities';

const getMediaThumbnailUrl = eImg => eImg.image?.url;

const OrgDetailExampleImagesTabQuery = gql`
  query OrganizationDetails($id: ID!) {
    organization(id: $id) {
      exampleImages {
        id
        image {
          url
        }
      }
    }
  }
`;

const ExampleImagesTab = () => {
  const organizationKeys = useOrganizationKeys();

  const activeOrg = useRecoilValue(activeOrganizationState);

  const { data, isLoading, error } = useGraphQuery(
    [...organizationKeys.detail(activeOrg.id), 'exampleImagesTab'],
    OrgDetailExampleImagesTabQuery,
    { id: activeOrg.id },
  );

  const exampleImages = useMemo(
    () => (!isLoading && !error ? data?.organization.exampleImages : []),
    [data, isLoading, error],
  );

  // const { openLegacyViewer } = useLegacyViewer();

  // const handleImgClick = useCallback(
  //   exampleImg => {
  //     openLegacyViewer(`organizations/${activeOrg.id}/example_images/${exampleImg.id}`);
  //   },
  //   [openLegacyViewer, activeOrg.id],
  // );

  const imageProps = useMemo(
    () => ({
      className: styles.exampleImage,
    }),
    [],
  );

  return (
    <IdsMediaListView
      media={exampleImages}
      getMediaId={item => item.id}
      noMediaMessage="This organization doesn't have any example images."
      getMediaThumbnailUrl={getMediaThumbnailUrl}
      // onImageClick={handleImgClick}
      imageProps={imageProps}
      // actions={<ExampleImagesTabActions />}
    />
  );
};

export default ExampleImagesTab;
