import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import IdsDialog from '../../../IdsDialog';

import CreatePointOfInterestForm from './CreatePointOfInterestForm';

function CreatePointOfInterestDialog({ open, onOpen, onClose, position, ...rest }) {
  const handleCreated = useCallback(() => {
    if (onClose) {
      onClose('created'); // indicate reason for closing
    }
  }, [onClose]);

  const handleCanceled = useCallback(() => {
    if (onClose) {
      onClose('canceled'); // indicate reason for closing
    }
  }, [onClose]);

  return (
    <>
      <IdsDialog
        open={open}
        onOpen={onOpen}
        onClose={handleCanceled}
        title='Create New Point of Interest'
        dialogKey='createPOI'
        fullWidth
      >
        <CreatePointOfInterestForm
          onSubmitSuccess={handleCreated}
          onCancel={handleCanceled}
          position={position}
          {...rest}
        />
      </IdsDialog>
    </>
  );
}

CreatePointOfInterestDialog.defaultProps = {
  open: false,
};

CreatePointOfInterestDialog.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func, // (reason) => {}; reason: (created, canceled)
  title: PropTypes.string,
  position: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

export default CreatePointOfInterestDialog;
