import React, { useCallback, useMemo, useState } from 'react';
import { Button, Grid, SvgIcon } from '@mui/material';

import { AddIcon } from '../../../theme/icons';
import CreateLocationDialog from '../CreateLocationDialog';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';
import '../../../theme/globalStyles.css';

function LocationListHeader({ ...rest }) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { userHasPermission } = usePermissions();

  const canCreate = useMemo(
    () => userHasPermission('create', EntityType.Location),
    [userHasPermission],
  );

  const openCreateDialog = useCallback(() => {
    setCreateDialogOpen(true);
  }, [setCreateDialogOpen]);

  const closeCreateDialog = useCallback(() => {
    setCreateDialogOpen(false);
  }, [setCreateDialogOpen]);

  return (
    <NestedOrgViewHeader
      currentCrumb='Locations'
      actions={
        <>
          {canCreate && (
            <Grid item>
              <Button color='secondary' variant='contained' onClick={openCreateDialog}>
                <SvgIcon fontSize='small' className='buttonIcon'>
                  <AddIcon />
                </SvgIcon>
                New Location
              </Button>
            </Grid>
          )}
        </>
      }
      dialogs={
        <CreateLocationDialog
          open={createDialogOpen}
          onOpen={openCreateDialog}
          onClose={closeCreateDialog}
        />
      }
      {...rest}
    />
  );
}

export default LocationListHeader;
