import React from 'react';
import { Stack, StackProps } from '@mui/material';

import ZoomControl from '../ZoomControl';
import ClusterControl from '../ClusterControl';
import FlagFilterControl from '../filters/FlagFilterControl';

export interface IMapControlsProps extends StackProps {
  hideFlagFilter?: boolean;
  hideClusterControl?: boolean;
  disabled?: boolean;
  cluster?: boolean;
  setClusterState?: (value: boolean) => any;
}

const MapControls: React.FC<IMapControlsProps> = ({
  hideFlagFilter = false,
  hideClusterControl = false,
  disabled = false,
  cluster,
  setClusterState,
  ...rest
}) => {
  return (
    <Stack direction='column' spacing={1} alignItems='flex-end' {...rest}>
      {!hideFlagFilter && <FlagFilterControl disabled={disabled} />}
      {!hideClusterControl && (
        <ClusterControl disabled={disabled} cluster={cluster} setClusterState={setClusterState} />
      )}
      <ZoomControl disabled={disabled} />
    </Stack>
  );
};

export default MapControls;
