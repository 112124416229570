import React, { useMemo } from 'react';
import { Grid } from '@mui/material';

import { useRecoilState } from 'recoil';

import TotalChip from '../../../../TotalChip';
import useFilterContext from '../../../../../hooks/useFilterContext';
import { FilterMode } from '../../../../../context/FilterContext';
import styles from '../LocationMapInfo.module.css';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';

import { infoPanelActivePaneState } from '../../../../../atoms/immersiveViewer';

import style from './NavigationChips.module.css';

const NavigationChips = ({ loading, projects }) => {
  const { useFilters } = useFilterContext();
  const { panelOptions, totalMedia } = useLocationMapContext();
  const [activePane, setActivePane] = useRecoilState(infoPanelActivePaneState);
  const activeFilters = useFilters();

  const subtractiveFilters = useMemo(
    () => activeFilters.filter(f => f.mode === FilterMode.Subtractive && !f.invisible) || [],
    [activeFilters],
  );
  const layerFilters = useMemo(
    () =>
      activeFilters.filter(
        f => (f.mode === FilterMode.Additive || f.mode === FilterMode.Override) && !f.invisible,
      ) || [],
    [activeFilters],
  );

  return (
    <Grid container direction='column' className={style.container}>
      <Grid item>
        <TotalChip
          total={projects.length}
          itemType='Project'
          className={styles.totalChip}
          loading={!projects.length && loading}
          active={activePane === panelOptions.PROJECTS}
          onClick={() => setActivePane(panelOptions.PROJECTS)}
          color='primary'
        />
      </Grid>
      <Grid item container direction='row' justifyContent='space-between'>
        <TotalChip
          total={totalMedia}
          itemType='Media'
          pluralize={false}
          className={styles.totalChip}
          loading={!totalMedia && loading}
          active={activePane === panelOptions.MEDIA}
          onClick={() => setActivePane(panelOptions.MEDIA)}
          color='primary'
        />
        <TotalChip
          total={layerFilters.length}
          itemType='Layer'
          className={styles.totalChip}
          loading={!layerFilters.length && loading}
          active={activePane === panelOptions.LAYERS}
          onClick={() => setActivePane(panelOptions.LAYERS)}
          color='primary'
        />
        <TotalChip
          total={subtractiveFilters.length}
          itemType='Filter'
          className={styles.totalChip}
          loading={false}
          active={activePane === panelOptions.FILTERS}
          onClick={() => setActivePane(panelOptions.FILTERS)}
          color='primary'
        />
      </Grid>

      {/* {totalRasterOverlays > 0 ? 
                <Grid>
                    <TotalChip
                        total={totalRasterOverlays}
                        itemType='Overlay'
                        className={styles.totalChip}
                        loading={!projects.length && loading}
                        active={activePane === panelOptions.OVERLAYS}
                        onClick={() => setActivePane(panelOptions.OVERLAYS)}
                        color='primary'
                    />
                </Grid> 
                : <></>
            } */}
    </Grid>
  );
};

export default NavigationChips;
