import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { activeTenantState } from '../../../atoms/tenants';
import IdsCreateDialogForm from '../../../components/ids-forms/IdsCreateDialogForm';
import IdsFormTextField from '../../../components/ids-forms/IdsFormTextField';
import { useCreateOrganization } from '../../../services/OrganizationsService';

import { createOrganizationValidationSchema } from './validationSchema';

const initialValues = {
  name: '',
};

function CreateOrganizationForm({ onSubmitSuccess, onCancel }) {
  const activeTenant = useRecoilValue(activeTenantState);
  const createOrganizationMutation = useCreateOrganization(activeTenant?.id, onSubmitSuccess);

  const onSubmit = useCallback(
    async values => {
      await createOrganizationMutation.mutateAsync({
        name: values.name,
        tenant_id: activeTenant.id,
      });
    },
    [createOrganizationMutation, activeTenant.id],
  );

  return (
    <IdsCreateDialogForm
      sx={{ pl: 4, pr: 4 }}
      initialValues={initialValues}
      validationSchema={createOrganizationValidationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Organization created'
      errorHandler={() => 'Organization could not be created'}
    >
      <IdsFormTextField name='name' label='Name' required autoFocus sx={{ mt: 0, mb: 2 }} />
    </IdsCreateDialogForm>
  );
}

CreateOrganizationForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreateOrganizationForm;
