import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material';

import { activeOrganizationState } from '../../../atoms/organizations';
import useDeleteEntity from '../../../hooks/useDeleteEntity';
import { IDeleteMediaCommentResult, useDeleteMediaComment } from '../../../services/MediaService';

const useDeleteMediaCommentAction = (mediaEntityId: string) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const deleteMediaCommentMutation = useDeleteMediaComment(mediaEntityId);
  const { deleted, deleting, handleDelete } = useDeleteEntity();
  const theme = useTheme();

  const deleteComment = useCallback(
    async (comment, onDelete, onCancel) => {
      await handleDelete(
        async () =>
          await deleteMediaCommentMutation.mutateAsync({
            id: comment.id,
            organizationId: activeOrg.id,
          }),
        'this comment',
        <>
          <span style={{ color: theme.palette.text.secondary }}>
            <i>{comment.text}</i>
          </span>
          <br />
          <br />
          This cannot be undone, the comment will be deleted permanently.
        </>,
        'Comment',
        onDelete,
        onCancel,
        (result: IDeleteMediaCommentResult) =>
          !!result.deleteMediaComment.errors?.length || !result.deleteMediaComment.success, // determines if the graph result is an error
      );
    },
    [deleteMediaCommentMutation, handleDelete, activeOrg.id, theme.palette.text.secondary],
  );

  return {
    deleted,
    deleting,
    deleteComment,
  };
};

export default useDeleteMediaCommentAction;
