import { v4 } from 'uuid';

export const getUUID = () => v4();

/**
 * Produces chunks of initial array, based on chunkSize.
 * @param items - array of items to chunk
 * @param chunkSize - chunk size
 */
export const chunkArray = <T>(items: T[], chunkSize: number) => {
  const chunks = [];

  for (let i = 0; i < items.length; i += chunkSize) {
    chunks.push(items.slice(i, i + chunkSize));
  }

  return chunks;
};

export const isTextTruncated = (e: HTMLElement) => e && e.offsetWidth < e.scrollWidth;

/**
 * @param obj - { prop1: { prop2: { prop3: 'hello' } } }
 * @param path - "prop1.prop2.prop3"
 *
 * Returns object nested prop value by path.
 * accessObject(obj, path) --> "hello"
 */
export const accessObject = (obj: Record<string, any>, path: string) => {
  return path.split('.').reduce((o, i) => o[i], obj);
};

/**
 * Parses contents of string and returns a RegExp obj representing the
 * stored expression.
 * This method was pulled from the following StackOverflow post:
 * https://stackoverflow.com/a/55258958
 */
export const stringToRegex = (str: string) => {
  // Main regex
  const main = str.match(/\/(.+)\/.*/);
  if (!main) {
    throw new Error('Invalid regex pattern');
  }

  // Regex options
  const options = str.match(/\/.+\/(.*)/);
  if (!options) {
    throw new Error('Invalid regex options');
  }

  // Compiled regex
  return new RegExp(main[1], options[1]);
};
