import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../atoms/organizations';

import AssignmentResponseDetailsTemplatePage from '../../Assignments/AssignmentResponseDetailsTemplatePage';
import {
  activeLocationAssignmentResponseState,
  activeLocationAssignmentState,
  activeLocationState,
} from '../../../atoms/locations';
import { activeTenantState } from '../../../atoms/tenants';
import {
  getOrgLocationAssignmentRoute,
  getOrgLocationAssignmentsRoute,
  getOrgLocationRoute,
  getOrgLocationsRoute,
} from '../../../utils/routes';

const LocationAssignmentResponseDetails = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const activeAssignment = useRecoilValue(activeLocationAssignmentState);
  const activeAssignmentResponse = useRecoilValue(activeLocationAssignmentResponseState);

  if (!activeLocation || !activeAssignment || !activeAssignmentResponse) {
    return null;
  }

  return (
    <AssignmentResponseDetailsTemplatePage
      assignmentId={activeAssignment.id}
      assignmentResponseId={activeAssignmentResponse.id}
      breadcrumbs={[
        {
          label: 'Locations',
          route: getOrgLocationsRoute({
            subdomain: activeTenant?.id,
            orgId: activeOrg.id,
          }),
        },
        {
          label: activeLocation.name,
          route: getOrgLocationRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            locationId: activeLocation.id,
          }),
        },
        {
          label: 'Assignments',
          route: getOrgLocationAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            locationId: activeLocation.id,
          }),
        },
        {
          label: activeAssignment.title,
          route: getOrgLocationAssignmentRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            locationId: activeLocation.id,
            assignmentId: activeAssignment.id,
          }),
        },
      ]}
    />
  );
};

export default LocationAssignmentResponseDetails;
