import { IMediaMetadata, LegacyMediaMetadataType, MediaMetadataType } from '../constants/media';
import { ICustomFieldMetadata } from '../constants/customFields';
import { IEntityCommentsProps } from '../features/Comments/EntityComments';
import { FieldType } from '../features/Data/EntityData/types';

export type MediaMetadata = {
  id: string;
  type: LegacyMediaMetadataType | MediaMetadataType;
};

export interface ICustomFieldMetadataItemInput {
  id: string;
  fieldId: string;
}

export interface IBasicImageFieldsInput {
  latitude?: number;
  longitude?: number;
  description?: string;
  metadata?: MediaMetadata[];
}

export interface IWhereOrganizationInput {
  organizationId: string;
}

export interface IWhereUniqueIdOrganizationInput {
  organizationId: string;
  id: string;
}

export interface IEntityField {
  id: string;
  type: FieldType;
  label: string;
  value: string;
}

export interface IDataEntityMarker {
  id: string;
  projectId: string;
  creator: {
    id: string;
    firstName: string;
    lastName: string;
  };
  name: string;
  color: string;
  flagged: boolean;
  position: {
    latitude: number;
    longitude: number;
  };
  comments?: IEntityCommentsProps['comments'];
  metadata: IMediaMetadata[];
  customFieldsMetadata: ICustomFieldMetadata[];
  fields?: IEntityField[];
  images?: {
    id: string;
  }[];
}

export interface IAsset {
  id: string;
  name: string;
  type: string;
}

export enum PositionAction {
  Increment = 'increment',
  Decrement = 'decrement',
}

export interface ILatLngAltInput {
  altitude?: number;
  heading?: number;
  orientation?: number;
  latitude: number;
  longitude: number;
}

export interface UserError {
  field: string;
  message: string;
}

export interface ILocationMarkerFrag {
  heading?: number;
  position: {
    latitude: number;
    longitude: number;
  };
}
