import { useCallback, useState } from 'react';

const useForceRerender = () => {
  const [, setForceRerender] = useState(Date.now());

  const forceRerender = useCallback(() => {
    setForceRerender(Date.now());
  }, [setForceRerender]);

  return { forceRerender };
};

export default useForceRerender;
