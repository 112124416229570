import { useMemo } from 'react';

import { IRouteDetails } from '../../../../services/RouteService';

const useRouteLevels = (route?: IRouteDetails) => {
  return useMemo(() => {
    return route?.levels.map(l => l.id) || [];
  }, [route]);
};

export default useRouteLevels;
