import React, { useMemo } from 'react';
import { gql } from 'graphql-request';

import { Box, Button } from '@mui/material';

import IdsSearchableList from '../../../../components/ids-lists/IdsSearchableList';

import usePermissions from '../../../../hooks/usePermissions';

import OverlayListItem, { OverlayListItemFrag } from './OverlayListItem';

const searchOverlayItem = (overlayItem, query) => {
  const searchProps = [overlayItem.name, overlayItem.archiveUrl, overlayItem.createdAt];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

const sortOverlayItems = (a, b) => {
  // Show highest position at the top
  return a.position - b.position;
};

export const OverlaysListFrag = gql`
  fragment OverlaysListFrag on Overlay {
    id
    name
    position
    archiveUrl
    createdAt
    ...OverlayListItemFrag
  }
  ${OverlayListItemFrag}
`;

function OverlaysList({
  overlays,
  entityType,
  onCreate,
  onCreateOverlay,
  onChangeOverlayPosition,
  onEditOverlay,
  useDeleteOverlayAction,
  onSetOverlayDefault,
  ...rest
}) {
  const { userHasPermission } = usePermissions();

  const canCreate = useMemo(
    () => userHasPermission('create', entityType),
    [userHasPermission, entityType],
  );

  return (
    <IdsSearchableList
      items={overlays}
      renderItem={overlay => (
        <OverlayListItem
          key={overlay.id}
          overlay={overlay}
          entityType={entityType}
          onChangePosition={onChangeOverlayPosition}
          onEdit={onEditOverlay}
          useDeleteOverlayAction={useDeleteOverlayAction}
          onSetDefault={onSetOverlayDefault}
        />
      )}
      searchItem={searchOverlayItem}
      sortItems={sortOverlayItems}
      actions={
        canCreate &&
        onCreateOverlay && (
          <Box display='flex' alignItems='center' height='100%'>
            <Button color='secondary' variant='outlined' onClick={onCreateOverlay}>
              New Overlay
            </Button>
          </Box>
        )
      }
      {...rest}
    />
  );
}

export default OverlaysList;
