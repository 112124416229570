import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { activeProjectState } from '../../../../atoms/projects';
import { useGetLocationPosition } from '../../../../services/LocationService';

import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import useMapFitData from '../../../../hooks/useMapFitData';

import { selectPointLat, selectPointLng, selectLocationLat, selectLocationLng } from '../helpers';
import { IRoutePoint } from '../types';

const useCenterMap = (points: IRoutePoint[] | null) => {
  const activeProject = useRecoilValue(activeProjectState);

  const [isInitialized, setIsInitialized] = useState(false);

  const { useViewState, setViewState } = useImmersiveViewer();
  const viewState = useViewState();
  const { fitMapToData } = useMapFitData();

  const { data } = useGetLocationPosition(activeProject!.locationId, {
    enabled: points?.length === 0,
  });

  /**
   * Center map according to route points.
   * If there are no points, use location center.
   */
  useEffect(() => {
    if (isInitialized || !points || points.length === 0) {
      return;
    }

    const fittedViewState = fitMapToData(points, selectPointLat, selectPointLng, 17, {
      ...viewState,
      maxPitch: 0,
    });

    if (!fittedViewState) {
      return;
    }

    setViewState(fittedViewState);

    setIsInitialized(true);
  }, [points, fitMapToData, viewState, setViewState, isInitialized]);

  /**
   * Fallback algorithm to center map according to location if there are
   * no points.
   */
  useEffect(() => {
    if (isInitialized || !data || (points && points.length > 0)) {
      return;
    }

    const fittedViewState = fitMapToData([data], selectLocationLat, selectLocationLng, 17, {
      ...viewState,
      maxPitch: 0,
    });

    if (!fittedViewState) {
      return;
    }

    setViewState(fittedViewState);

    setIsInitialized(true);
  }, [data, points, fitMapToData, viewState, setViewState, isInitialized]);
};

export default useCenterMap;
