import React from 'react';
import { useRecoilValue } from 'recoil';

import { activeProjectState } from '../../../../atoms/projects';
import { useGetProjectRoutes } from '../../../../services/ProjectService';

import RoutesList from './RoutesList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoutesTabProps {}

const RoutesTab: React.FC<IRoutesTabProps> = () => {
  const activeProject = useRecoilValue(activeProjectState);

  const { data, isLoading, error } = useGetProjectRoutes(activeProject!.id);

  return (
    <RoutesList
      items={data?.project?.routes || []}
      isLoading={isLoading}
      error={error && 'Routes could not be loaded'}
    />
  );
};

export default RoutesTab;
