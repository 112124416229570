import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { useMemo } from 'react';

import useGraphQuery, { UseGraphQueryOptions } from '../hooks/useGraphQuery';
import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import axios from '../utils/axios';
import queryClient from '../utils/query';
import { OverlayLayerFrag } from '../components/mapping/layers/overlays';
import { LocationMarkerFrag } from '../components/mapping/layers/locations';
import { IMetadataType, IMediaMetadata, INode, MediaType } from '../constants/media';
import { ProjectsListFrag } from '../views/Projects/ProjectsList';

import { IProjectFrag, useProjectKeys } from './ProjectService';
import { useOrganizationKeys } from './OrganizationsService';
import { IAsset, ILocationMarkerFrag } from './types';
import { ILocationListItem, LocationListItemFrag } from './fragments';

export const useLocationKeys = () => {
  const queryIdKey = useQueryKeyId();
  const organizationKeys = useOrganizationKeys();

  return useMemo(() => {
    const locationKeys = {
      all: ['locations', ...queryIdKey],
      lists: () => [...locationKeys.all, 'list'],
      orgList: (orgId: string) => [...locationKeys.lists(), ...organizationKeys.detail(orgId)],
      orgListFiltered: (orgId: string, filterProps: any) => [
        ...locationKeys.orgList(orgId),
        filterProps || {},
      ],
      route: (id: string) => [...locationKeys.all, id],
      detail: (id: string) => [...locationKeys.route(id), 'detail'],
      map: (id: string) => [...locationKeys.detail(id), 'map'],
      rasterOverlays: (id: string) => [...locationKeys.detail(id), 'rasterOverlays'],
      projects: () => [...locationKeys.lists(), 'projects'],
      orgLocations: (id: string) => [...locationKeys.all, `organization-${id}`],
      position: (id: string) => [...locationKeys.detail(id), 'position'],
      assets: (id: string) => [...locationKeys.detail(id), 'assets'],
    };

    return locationKeys;
  }, [queryIdKey, organizationKeys]);
};

const onError = (_: any, __: any, context: any) => {
  context.mutation.reset();
};

const RasterOverlaysFrag = gql`
  fragment RasterOverlaysFrag on Location {
    rasterOverlays {
      id
      name
      metadata {
        id
        type
        value
      }
      ...OverlayLayerFrag
    }
  }
  ${OverlayLayerFrag}
`;

const createLocation = ({
  orgId,
  name,
  address,
  city,
  state,
  zip,
  yearBuilt,
  latitude,
  longitude,
  heading,
}: Record<string, any>) => {
  return axios.post('/api/v2/locations', {
    organization_id: orgId,
    location: {
      name,
      address,
      city,
      state,
      zip,
      year_built: yearBuilt,
      latitude,
      longitude,
      heading,
    },
  });
};

export function useCreateLocation(orgId: string) {
  const locationKeys = useLocationKeys();

  return useMutation(createLocation, {
    onError,
    onSuccess: () => {
      // Only invalidate location list queries in the context of the org
      queryClient.invalidateQueries(locationKeys.orgList(orgId), {
        refetchActive: true,
      });
    },
  });
}

const updateLocation = ({
  id,
  name,
  address,
  city,
  state,
  zip,
  yearBuilt,
  latitude,
  longitude,
  heading,
}: Record<string, any>) => {
  return axios.put(`/api/v2/locations/${id}`, {
    location: {
      name,
      address,
      city,
      state,
      zip,
      year_built: yearBuilt,
      latitude,
      longitude,
      heading,
    },
  });
};

export function useUpdateLocation(id: string, orgId: string) {
  const locationKeys = useLocationKeys();

  return useMutation(updateLocation, {
    onError,
    onSuccess: () => {
      // Invalidate any detail queries for the location
      queryClient.invalidateQueries(locationKeys.detail(id), {
        refetchActive: true,
      });

      // Only invalidate location list queries in the context of the org
      queryClient.invalidateQueries(locationKeys.orgList(orgId), {
        refetchActive: true,
      });
    },
  });
}

export function useDeleteLocation(orgId: string) {
  const locationKeys = useLocationKeys();

  return useMutation(
    ({ locationId }: { locationId: string }) => {
      return axios.delete(`/api/v2/locations/${locationId}`);
    },
    {
      onError,
      onSuccess: () => {
        // Only invalidate location list queries in the context of the org
        queryClient.invalidateQueries(locationKeys.orgList(orgId), {
          refetchActive: true,
        });
      },
    },
  );
}

export interface ILocationListItemEdge {
  node: ILocationListItem;
  cursor: string;
}

export interface ILocationOptionsQuery {
  organization: {
    locations: {
      edges: ILocationListItemEdge[];
    };
  };
}

const LocationOptionsQuery = gql`
  query OrgLocations($id: ID!, $take: Int, $after: String) {
    organization(id: $id) {
      locations(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...LocationListItemFrag
          }
        }
      }
    }
  }
  ${LocationListItemFrag}
`;

export const useGetAllLocationOptions = (
  organizationId: string,
  onPageLoad?: (edges: ILocationListItemEdge[]) => void,
) => {
  const locationKeys = useLocationKeys();
  const useQuery = (take: number, after: string | null) =>
    useGraphQuery<ILocationOptionsQuery>(
      [...locationKeys.orgList(organizationId), 'options', `take${take}`, `after${after}`],
      LocationOptionsQuery,
      {
        id: organizationId,
        take,
        after,
      },
    );
  return useCursorPaginatedQuery({
    initialData: [] as ILocationListItemEdge[],
    useQuery,
    defaultTake: 600,
    selectConnection: (d: ILocationOptionsQuery) => d.organization.locations,
    onPageLoad,
  });
};

const LocationListQuery = gql`
  query OrgLocations($id: ID!, $take: Int, $after: String) {
    locations(organizationId: $id, take: $take, after: $after) {
      edges {
        cursor
        node {
          ...LocationListItemFrag
        }
      }
    }
  }
  ${LocationListItemFrag}
`;

export const useGetOrganizationLocations = (orgId: string, options?: UseGraphQueryOptions) => {
  const locationKeys = useLocationKeys();

  const useQuery = (take: number, after: string | null) =>
    useGraphQuery(
      [...locationKeys.orgList(orgId), `take-${take}`, `after-${after}`],
      LocationListQuery,
      { id: orgId, take, after },
      options,
    );

  return useCursorPaginatedQuery<ILocationListItemEdge>({
    useQuery,
    defaultTake: 300,
    selectConnection: data => data?.locations,
  });
};

export interface IRasterOverlay extends INode {
  id: string;
  name: string;
  metadata: IMediaMetadata[];
  default: boolean;
  tilesTemplateUrl: string;
  position: number;
}

interface ILocationImageMarkerFormQuery {
  location: {
    position: {
      latitude: number;
      longitude: number;
    };
    rasterOverlays: IRasterOverlay[];
  };
}

const LocationImageMarkerFormQuery = gql`
  query LocationImageMarkerForm($tenantId: ID, $orgId: ID, $id: ID!) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      position {
        latitude
        longitude
      }
      ...RasterOverlaysFrag
    }
  }
  ${RasterOverlaysFrag}
`;

export const useGetLocationImageMarkerForm = (locationId: string) => {
  const locationKeys = useLocationKeys();

  return useOrgGraphQuery<ILocationImageMarkerFormQuery>(
    [...locationKeys.map(locationId), 'image-marker-form'],
    LocationImageMarkerFormQuery,
    { id: locationId },
    { enabled: !!locationId },
  );
};

interface ILocationMetadataTypesQuery {
  location: {
    metadataTypes: IMetadataType[];
  };
}

const LocationMetadataTypes = gql`
  query LocationMetadataTypes($orgId: ID, $locationId: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      metadataTypes {
        type
        values {
          id
          name
        }
      }
    }
  }
`;

export const useGetLocationMetadataTypes = (locationId: string | null) => {
  const locationKeys = useLocationKeys();

  return useOrgGraphQuery<ILocationMetadataTypesQuery>(
    [locationKeys.detail(locationId || ''), 'metadataTypes'],
    LocationMetadataTypes,
    {
      locationId,
    },
    {
      enabled: !!locationId,
    },
  );
};

interface ILocationNameQuery {
  location: {
    name: string;
  };
}

const LocationNameQuery = gql`
  query LocationNameQuery($tenantId: ID, $orgId: ID, $id: ID!) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      name
    }
  }
`;

export const useGetLocationName = (locationId?: string | null) => {
  const locationKeys = useLocationKeys();

  return useOrgGraphQuery<ILocationNameQuery>(
    [...locationKeys.detail(locationId || ''), 'name'],
    LocationNameQuery,
    { id: locationId },
    { enabled: !!locationId },
  );
};

const ProjectListQuery = gql`
  query LocationProjects($orgId: ID, $locationId: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      projects {
        edges {
          cursor
          node {
            ...ProjectsListFrag
          }
        }
      }
    }
  }
  ${ProjectsListFrag}
`;

export interface ILocationProjectsEdge {
  cursor: string;
  node: IProjectFrag;
}

export const useGetLocationProjects = (locationId: string, options?: UseGraphQueryOptions) => {
  const projectKeys = useProjectKeys();

  const useProjects = () =>
    useOrgGraphQuery(
      projectKeys.locationList(locationId),
      ProjectListQuery,
      { locationId },
      options,
    );

  return useCursorPaginatedQuery<ILocationProjectsEdge>({
    useQuery: useProjects,
    defaultTake: 100,
    selectConnection: data => data?.location.projects,
  });
};

const LocationAssetsQuery = gql`
  query LocationAssets($locationId: ID!, $orgId: ID, $tenantId: ID) {
    location(id: $locationId, organizationId: $orgId, tenantId: $tenantId) {
      assets {
        id
        name
        type
      }
    }
  }
`;

export interface ILocationAssetsData {
  location: {
    assets: IAsset[];
  };
}

export const useGetLocationAssets = (locationId: string) => {
  const locationKeys = useLocationKeys();

  return useOrgGraphQuery<ILocationAssetsData>(
    locationKeys.assets(locationId),
    LocationAssetsQuery,
    { locationId },
  );
};

const LocationRasterOverlaysQuery = gql`
  query LocationRasterOverlays($tenantId: ID, $orgId: ID, $id: ID!) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      ...RasterOverlaysFrag
    }
  }
  ${RasterOverlaysFrag}
`;

interface ILocationRasterOverlayData {
  location: {
    rasterOverlays?: IRasterOverlay[];
  };
}

export const useGetLocationRasterOverlays = (
  locationId: string | null,
  options?: UseGraphQueryOptions,
) => {
  const locationKeys = useLocationKeys();

  return useOrgGraphQuery<ILocationRasterOverlayData>(
    locationId ? [...locationKeys.map(locationId), 'raster-overlays'] : '',
    LocationRasterOverlaysQuery,
    { id: locationId },
    {
      enabled: !!locationId,
      ...options,
    },
  );
};

const LocationPositionQuery = gql`
  query LocationPosition($locationId: ID!, $orgId: ID, $tenantId: ID) {
    location(id: $locationId, organizationId: $orgId, tenantId: $tenantId) {
      ...LocationMarkerFrag
    }
  }
  ${LocationMarkerFrag}
`;

export interface ILocationPosition {
  location: ILocationMarkerFrag;
}

export const useGetLocationPosition = (
  locationId: string | null,
  options?: UseGraphQueryOptions,
) => {
  const locationKeys = useLocationKeys();

  return useOrgGraphQuery<ILocationPosition>(
    locationId ? locationKeys.position(locationId) : '',
    LocationPositionQuery,
    { locationId },
    {
      enabled: !!locationId,
      ...options,
    },
  );
};

const PublishAllLocationImagesMutation = gql`
  mutation PublishAllLocationImages($id: ID!, $organizationId: ID!) {
    publishAllLocationImages(where: { id: $id, organizationId: $organizationId }) {
      success
      errors {
        message
        field
      }
    }
  }
`;

export interface IPublishAllLocationImagesPayload {
  id: string;
  organizationId: string;
}

const publishAllLocationImagesMutation = (payload: IPublishAllLocationImagesPayload) => {
  const { id, organizationId } = payload;
  return buildGraphMutationFn(PublishAllLocationImagesMutation)({
    id,
    organizationId,
  });
};

export const usePublishAllLocationImages = () => {
  return useMutation(publishAllLocationImagesMutation, {
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(MediaType.HDPhoto),
        refetchActive: true,
      });

      await queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(MediaType.PanoramicPhoto),
        refetchActive: true,
      });
    },
  });
};
