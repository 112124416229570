import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { navigationSourceState } from '../../atoms/navigationSource';
import RuntimeConfig from '../../RuntimeConfig';
import { NavigationSource } from '../../atoms/navigationSource';
import useKeycloak from '../../hooks/useKeycloak';

const NAV_SOURCE_KEY_PARAM = 'ns-key';

const KEY_TO_SOURCE: Record<string, NavigationSource> = {
  [RuntimeConfig.captureNavigationSourceKey]: NavigationSource.Capture,
};

const NavigationSourceLoader: React.FC = () => {
  const { authenticated } = useKeycloak();
  const [, setSearchParams] = useSearchParams();
  const setNavigationSource = useSetRecoilState(navigationSourceState);
  const loaded = useRef(false);

  // Look for navigation source secret key param
  useEffect(() => {
    if (loaded.current || !authenticated) return;

    const searchParams = new URLSearchParams(window.location.search);
    const navSourceKey = searchParams.get(NAV_SOURCE_KEY_PARAM);
    if (!navSourceKey) return;

    const source = KEY_TO_SOURCE[navSourceKey];
    if (source) {
      setNavigationSource(source);
    }

    // Remove the nav source key once loaded
    searchParams.delete(NAV_SOURCE_KEY_PARAM);
    setSearchParams(searchParams, { replace: true });

    // Prevent reloading navigation source when deps change
    loaded.current = true;
  }, [authenticated, setSearchParams, setNavigationSource]);

  return null;
};

export default NavigationSourceLoader;
