import React from 'react';
import { SvgIcon } from '@mui/material';

const ThreeDIcon = props => (
  <SvgIcon {...props}>
    <path d='m12.5,1.31c-.31-.18-.69-.18-1,0L3.24,6.08l1,.58,1,.58,6.76,3.9,6.76-3.9,1-.58,1-.58L12.5,1.31Z' />
    <path d='m20.51,7.96l-1,.58-6.76,3.9v10.11l8.26-4.77c.31-.18.5-.51.5-.87V7.38l-1,.58Z' />
    <path d='m4.49,8.53l-1-.58-1-.58v9.53c0,.36.19.69.5.87l8.26,4.77v-10.11l-6.76-3.9Z' />
  </SvgIcon>
);

export default ThreeDIcon;
