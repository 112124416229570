import ComingSoonRoute from '../../../components/ComingSoonRoute';
import Page from '../../../components/Page';

function TenantSettings() {
  return (
    <Page title='Settings'>
      <ComingSoonRoute legacyFeatureRoute={null} />
    </Page>
  );
}
export default TenantSettings;
