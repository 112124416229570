import useVectorOverlayFilter from '../../../hooks/filtering/useVectorOverlayFilter';
import useFilterContext from '../../../hooks/useFilterContext';
import { MEDIA_TYPES, MediaType } from '../../../constants/media';
import { FILTER_STATE_KEYS } from '../../../constants/urlStateKeys';
import { useRestoreOverlayFilterFromUrl } from '../../../hooks/filtering/useOverlayFilter';

const useVectorOverlayFilterInitializer = () => {
  const { useTypeData } = useFilterContext();
  const { enableVectorOverlay } = useVectorOverlayFilter();
  const vectorOverlays = useTypeData(MEDIA_TYPES[MediaType.VectorOverlay].type);
  useRestoreOverlayFilterFromUrl(
    FILTER_STATE_KEYS.VECTOR_OVERLAY,
    vectorOverlays,
    enableVectorOverlay,
  );
};

export default useVectorOverlayFilterInitializer;
