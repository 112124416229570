import { useCallback, useEffect } from 'react';

const useBeforeUnloadConfirmation = (enabled = true) => {
  const onBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();

    return true;
  }, []);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [onBeforeUnload, enabled]);
};

export default useBeforeUnloadConfirmation;
