import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import TopBar from '../../components/TopBar';
import Page from '../../components/Page';
import { navigationExpanded } from '../../atoms/navigation';

import { FULL_HEIGHT_WITHOUT_TOP_BAR } from '../../theme/styles';

import DashboardNavBar from './DashboardNavBar';
import styles from './DashboardLayout.module.css';

function DashboardLayout({ children }) {
  const [navExpanded] = useRecoilState(navigationExpanded);

  return (
    <Page title='Dashboard'>
      <Box className={styles.root}>
        <TopBar />
        <DashboardNavBar />
        <Box
          className={clsx(
            !navExpanded || window.innerWidth <= 460 ? styles.wrapper : styles.wrapperExpanded,
          )}
        >
          <Box className={styles.contentContainer}>
            <Box className={styles.content} style={{ height: FULL_HEIGHT_WITHOUT_TOP_BAR }}>
              {children}
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
