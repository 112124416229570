import React from 'react';

import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../services/LocationService';
import { activeLocationState } from '../../atoms/locations';
import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgLocationsRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const LocationRouteQuery = gql`
  query LocationRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      name
    }
  }
`;

function LocationRoute() {
  const locationKeys = useLocationKeys();

  const [location, setLocation] = useRecoilState(activeLocationState);
  const activeTenant = useRecoilValue(activeTenantState);
  const org = useRecoilValue(activeOrganizationState);

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useLocation = (id, options) =>
    useOrgGraphQuery(locationKeys.route(id), LocationRouteQuery, { id }, options);

  return (
    <EntityRoute
      idParam={'locationId'}
      currentEntity={location}
      setEntity={setLocation}
      useEntity={useLocation}
      entitySelector={data => data?.location}
      errorRedirectRoute={getOrgLocationsRoute({
        subdomain: activeTenant?.subdomain,
        orgId: org.id,
      })}
    />
  );
}

export default LocationRoute;
