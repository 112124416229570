import React, { useMemo } from 'react';
import { Alert, Box, Paper, IconButton, Typography, PaperProps } from '@mui/material';

import useFilterContext from '../../../../hooks/useFilterContext';
import { CancelIcon } from '../../../../theme/icons';
import { IFilter } from '../../../../context/FilterContext';

import styles from './FiltersList.module.css';

export interface IFiltersListProps {
  noFiltersMessage: string;
  filters: IFilter[];
}

const paperSx: PaperProps['sx'] = {
  backgroundColor: theme => theme.palette.grey['300'],
};

const FiltersList: React.FC<IFiltersListProps> = ({ filters, noFiltersMessage }) => {
  const { removeFilter } = useFilterContext();

  const visibleFilters = useMemo(() => filters.filter(f => !f.invisible), [filters]);

  return visibleFilters.length ? (
    <Box my={1}>
      {visibleFilters.map(f => (
        <Box key={f.name} className={styles.filterChipContainer}>
          {f.customChip ? (
            f.customChip
          ) : (
            <Paper elevation={3} className={styles.filterChip} sx={paperSx}>
              <Box className={styles.header}>
                <Box className={styles.leftContainer}>
                  {f.label}
                  <Box className={styles.content}>
                    <Typography variant='body2'>{f.selected}</Typography>
                  </Box>
                </Box>
                <IconButton
                  className={styles.deleteButton}
                  onClick={() => removeFilter(f.name)}
                  size='small'
                >
                  <CancelIcon className={styles.deleteIcon} />
                </IconButton>
              </Box>
            </Paper>
          )}
        </Box>
      ))}
    </Box>
  ) : (
    <Alert severity='warning'>{noFiltersMessage}</Alert>
  );
};

export default FiltersList;
