import React from 'react';
import { TableRow } from '@mui/material';

import MiddleAlignTableCell from '../../MiddleAlignTableCell';
import { IFieldComponentProps } from '../../types';
import { CheckBoxIcon, CheckBoxOutlinedIcon } from '../../../../../theme/icons';
import LabelCell from '../../LabelCell';
import IdsFormCheckboxField from '../../../../../components/ids-forms/IdsFormCheckboxField';

import styles from './Checkbox.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICheckboxProps extends IFieldComponentProps {}

const sx = {
  color: 'ids.marker.markerDotFlagged',
  '&.Mui-checked': { color: 'ids.marker.markerDotFlagged' },
};

const Checkbox: React.FC<ICheckboxProps> = ({ field, isEditMode }) => {
  return (
    <TableRow>
      <LabelCell label={field.label} />
      <MiddleAlignTableCell>
        {isEditMode && field.isEditable ? (
          <IdsFormCheckboxField
            className={styles.zeroPadding}
            name={field.name}
            label=''
            required
            sx={sx}
            labelProps={{ sx: { margin: 0 } }}
          />
        ) : field.data ? (
          <CheckBoxIcon fontSize='small' />
        ) : (
          <CheckBoxOutlinedIcon fontSize='small' />
        )}
      </MiddleAlignTableCell>
    </TableRow>
  );
};

export default Checkbox;
