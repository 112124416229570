import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { useFormikContext } from 'formik';

type DatePickerPropsType = DatePickerProps<unknown, Date>;

// No need in onChange, onBlur, onFocus and value props
// Because this component will use formik context
type ParentType = Omit<
  DatePickerPropsType,
  'onChange' | 'onBlur' | 'onFocus' | 'value' | 'renderInput'
>;

export interface IIdsFormDateFieldProps extends ParentType {
  name: string;
  required?: boolean;
  onChange?: DatePickerPropsType['onChange'];
  renderInput?: DatePickerPropsType['renderInput'];
}

const IdsFormDateField: React.FC<IIdsFormDateFieldProps> = ({
  name,
  onChange,
  required,
  ...rest
}) => {
  const { values, errors, touched, setFieldValue, handleBlur, validateOnChange } =
    useFormikContext<any>();

  const _onChange = useCallback(
    (newValue, keyboardInputValue) => {
      setFieldValue(name, newValue, validateOnChange);

      if (onChange) {
        onChange(newValue, keyboardInputValue);
      }
    },
    [name, onChange, setFieldValue, validateOnChange],
  );

  return (
    <DatePicker
      {...rest} // Props below this shouldn't be overridden
      value={values[name]}
      onChange={_onChange}
      renderInput={params => (
        <TextField
          name={name}
          fullWidth
          size='small'
          required={required}
          {...params} // Props below this shouldn't be overridden
          helperText={touched[name] && errors[name]}
          error={Boolean(touched[name] && errors[name])}
          onBlur={handleBlur}
        />
      )}
    />
  );
};

export default IdsFormDateField;
