import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useAssignmentKeys } from '../../services/AssignmentsService';
import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';
import { activeTenantState } from '../../atoms/tenants';
import { activeProgramAssignmentState, activeProgramState } from '../../atoms/programs';

import { getOrgProgramRoute } from '../../utils/routes';

import EntityRoute from './EntityRoute';

const AssignmentRouteQuery = gql`
  query AssignmentRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignment(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      title
    }
  }
`;

export const ProgramAssignmentRoute: React.FC = () => {
  const assignmentKeys = useAssignmentKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrganization = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);
  const [activeProgramAssignment, setActiveProgramAssignment] = useRecoilState(
    activeProgramAssignmentState,
  );

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useProgramAssignment = (id: string | undefined, options: UseGraphQueryOptions) =>
    useOrgGraphQuery(assignmentKeys.route(id!), AssignmentRouteQuery, { id }, options);

  if (!activeProgram) {
    return null;
  }

  return (
    <EntityRoute
      idParam={'assignmentId'}
      currentEntity={activeProgramAssignment}
      setEntity={setActiveProgramAssignment}
      useEntity={useProgramAssignment}
      entitySelector={data => data?.assignment}
      errorRedirectRoute={`${getOrgProgramRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrganization.id,
        programId: activeProgram.id,
      })}?tab=assignments`}
    />
  );
};

export default ProgramAssignmentRoute;
