import { useCallback } from 'react';

import { fitBounds } from '@math.gl/web-mercator';

import { getGeoCoordinateBounds } from '../utils/geospatial';

import useImmersiveViewer from './useImmersiveViewer';

// Fit the map viewport to the data (center and zoom)
const useMapFitData = () => {
  const { containerRef } = useImmersiveViewer();

  const fitMapToData = useCallback(
    (data, selectLat, selectLng, maxZoom, viewState) => {
      if (!data?.length || !containerRef) {
        return null;
      }

      if (data.length === 1) {
        // Only 1 item, just zoom to the item
        return {
          ...viewState,
          latitude: selectLat(data[0]),
          longitude: selectLng(data[0]),
          zoom: maxZoom,
        };
      }

      // Dimensions of the map
      const { height, width } = containerRef.getBoundingClientRect();

      // Map requires dimensions > 0 for fitBounds to work
      if (height === 0 || width === 0) {
        return null;
      }

      // Bounding coordinates
      const { min, max } = getGeoCoordinateBounds(data, selectLat, selectLng);

      const minDimension = Math.min(width, height);
      const padding = minDimension * 0.1;

      // Get viewport that bounds the locations
      const boundingViewport = fitBounds({
        width,
        height,
        bounds: [
          [min.longitude, min.latitude],
          [max.longitude, max.latitude],
        ],
        maxZoom: maxZoom,
        padding,
      });

      return {
        ...viewState,
        latitude: boundingViewport.latitude,
        longitude: boundingViewport.longitude,
        zoom: boundingViewport.zoom,
      };
    },
    [containerRef],
  );

  return {
    fitMapToData,
  };
};

export default useMapFitData;
