import React, { useCallback, useEffect, useState } from 'react';
import Uppy, { UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { useSnackbar } from 'notistack';
import type { SnackbarKey } from 'notistack';
import { IconButton } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import IdsDialog from '../../../IdsDialog';
import { TOOLS_ID, IToolState } from '../../LocationMapMenu';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import { useObservableItemState } from '../../../../hooks/useObservableStates';
import useKeycloak from '../../../../hooks/useKeycloak';
import RuntimeConfig from '../../../../RuntimeConfig';
import {
  GCP,
  useCreateRasterOverlay,
  usePreprocessOverlayFile,
  useS3UrlToAutoTrimLambda,
  // useCordsAndS3UrlToManualTrimLambda,
} from '../../../../services/RasterOverlaysService';
import useLocationMapContext from '../../../../hooks/useLocationMapContext';
import useUppyEventHandler from '../../../../hooks/useUppyEventHandler';
import usePermissions from '../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../constants/users';
import { CancelIcon } from '../../../../theme/icons';
import useConfirmation from '../../../../hooks/useConfirmation';
import usePrevious from '../../../../hooks/usePrevious';
import { infoPanelExpandedState } from '../../../../atoms/immersiveViewer';
import { getImageMeta } from '../../../../utils/image';
import { navigationSourceState } from '../../../../atoms/navigationSource';
import { NavigationSource } from '../../../../atoms/navigationSource';
import useUrlState from '../../../../hooks/useUrlState';
import { doesUppyFitRestrictions } from '../../../../utils/uppy';

import RasterOverlayPlacement, {
  OverlayAlignmentMarkers,
  OverlayCorners,
} from './RasterOverlayPlacement';
import RasterOverlayForm, { ICompleteOverlayData } from './RasterOverlayForm';

import styles from './RasterOverlayTool.module.css';
import RasterOverlayManualTrim from './RasterOverlayManualTrim';

const REDIRECT_PARAM = 'createRO-redir';

export const RO_TOOL_ID = 'ro-tool';

const uppyId = 'raster-overlay-upload';
const awsS3Id = `${uppyId}-AwsS3`;

// DO NOT SPECIFY THESE IN UPPY RESTRICTIONS, WILL BREAK MOBILE ABILITY TO TAKE A PHOTO
const allowedFileTypes = ['.jpeg', '.jpg', '.png', '.pdf']; // add '.tif' and '.tiff' in the future for tiff support

export const uppy = new Uppy({
  id: uppyId,
  restrictions: {
    maxNumberOfFiles: 1,
  },
}).use(AwsS3, {
  id: awsS3Id,
  limit: 5,
  shouldUseMultipart() {
    return true; // Must be multipart for upload to work
  },
  companionUrl: `${RuntimeConfig.apiBaseUrl}/api/v2`,
  allowedMetaFields: [],
});

export interface IRasterOverlayPreview {
  url: string;
  width: number;
  height: number;
}

export const getImageDimensions = async (
  url: string,
): Promise<{ width: number | null; height: number | null }> => {
  try {
    // Try to get dimensions from EXIF data or loading image into memory using getImageMeta
    const dimensions = await getImageMeta(url);
    if (dimensions?.width && dimensions?.height) {
      return {
        width: dimensions.width,
        height: dimensions.height,
      };
    }
  } catch (error) {
    // silently catch the error
  }
  return {
    width: null,
    height: null,
  };
};

// Function to calculate resized dimensions accounting for new constraints
export const calculateResizedDimensions = (
  originalWidth: number | null,
  originalHeight: number | null,
  maxDimension: number,
) => {
  const width = originalWidth ?? maxDimension;
  const height = originalHeight ?? maxDimension;
  let resizeWidth, resizeHeight;

  if (width > height) {
    // Set the largest dimension to smallest of maxDimension or original dimension
    resizeWidth = Math.min(width, maxDimension);
    resizeHeight = Math.round((height / width) * resizeWidth);
  } else {
    // Same logic but for height being the largest dimension
    resizeHeight = Math.min(height, maxDimension);
    resizeWidth = Math.round((width / height) * resizeHeight);
  }
  return { resizeWidth, resizeHeight };
};
export interface IOverlayData {
  overlayName: string;
  levelId: string | null;
  defaultEnabled?: boolean;
}

export interface IRasterOverlayFileMeta extends Partial<IOverlayData> {
  width?: number;
  height?: number;
  corners?: OverlayCorners;
  markers?: OverlayAlignmentMarkers;
  originalImageKey?: string;
}

export type RasterOverlayFile = UppyFile<IRasterOverlayFileMeta> | undefined | null;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRasterOverlayToolProps {}

/**
 * The raster overlay tool is a replacement for the legacy [overlay editor](https://bitbucket.org/immersiondata/overlay-editor/src/master/).
 * Designed for use within an ImmersiveViewerContext provider.
 *
 * #### Design:
 * * Activate by setting the active tool state defined in LocationMapMenu in the immersive viewer context item state to RO_TOOL_ID, exported here.
 * * Dialog is used for uppy file selection, upload, and form fields. Overlay upload is automatically started once selected using the uppy AwsS3 plugin.
 * * Overlay is aligned on the map using markers that can be dragged or repositioned on the overlay by clicking to control the anchor points.
 * * The original image is used for alignment on the map unless its size in bytes exceeds the size set in the env var, `REACT_APP_RASTER_OVERLAY_RESIZE_BYTE_MIN` or
 * it has a dimension that is larger than the env var, `REACT_APP_RESIZE_PX_DIMENSION_MIN`.
 * If the original image exceeds the resize threshold, then overlay is resized using a resize lambda function after uploading is complete.
 * The resized overlay dimensions are set using the `REACT_APP_RASTER_OVERLAY_RESIZE_SIZE` env var. This var specifies the pixel height of the thumbnail to be generated.
 * * After alignment, a request is sent to the Rails api endpoint, `/api/v2/locations/:locationId/raster_overlays/create_through_image`,
 * to create the raster overlay object in the IDS platform. This kicks off the BE processing of the overlay where it is tiled for use on the FE.
 */
const RasterOverlayTool: React.FC<IRasterOverlayToolProps> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { token } = useKeycloak();
  const { userHasOneOfRoles } = usePermissions();
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useImmersiveViewer();
  const [toolState, setToolState] = useObservableItemState<IToolState>({
    id: TOOLS_ID,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  const [uppyFile, setUppyFile] = useState<RasterOverlayFile>();
  const [preview, setPreview] = useState<IRasterOverlayPreview | null>(null);
  const [formComplete, setFormComplete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isTrimCompleted, setIsTrimCompleted] = useState(false);
  const [downloadableUrl, setDownloadableUrl] = useState<string | null>();
  const createROMutation = useCreateRasterOverlay();
  const { mutateAsync: s3UrlToAutoTrimLambdaMutation } = useS3UrlToAutoTrimLambda();
  const { mutateAsync: preprocessOverlayFileMutation } = usePreprocessOverlayFile();

  const { location } = useLocationMapContext();
  const { requestConfirmation } = useConfirmation();
  const [mapInfoExpanded, setMapInfoExpanded] = useRecoilState(infoPanelExpandedState);
  const navigationSource = useRecoilValue(navigationSourceState);
  const { getUrlState } = useUrlState();

  const enabled = toolState?.active === RO_TOOL_ID;
  const prevEnabled = usePrevious(enabled);

  const closeSnackBarAction = useCallback(
    (snackbarKey: SnackbarKey) => (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <CancelIcon className={styles.closeSnackbarIcon} />
      </IconButton>
    ),
    [closeSnackbar],
  );

  // Keep keycloak token up-to-date in uploader
  useEffect(() => {
    const awsS3 = uppy.getPlugin(awsS3Id);
    awsS3?.setOptions({
      companionHeaders: {
        authorization: `Bearer ${token}`,
        'uppy-auth-token': token,
      },
    });
  }, [token]);

  const handleFileAdded = useCallback(
    (file: UppyFile<IRasterOverlayFileMeta>) => {
      if (!doesUppyFitRestrictions(uppy, { allowedFileTypes })) {
        uppy.removeFile(file.id);
        enqueueSnackbar(
          `File type not supported. Supported file types: ${allowedFileTypes.join(', ')}`,
          { variant: 'warning' },
        );
        return;
      }
      setUppyFile(file);
      uppy.upload();
    },
    [enqueueSnackbar],
  );
  useUppyEventHandler('file-added', handleFileAdded, uppy);

  const handleFileRemoved = useCallback(() => {
    setUppyFile(null);
    setPreview(null);
    setFormComplete(false);
    setUploading(false);
  }, []);
  useUppyEventHandler('file-removed', handleFileRemoved, uppy);

  const handleUploadStart = useCallback(() => {
    setUploading(true);
  }, []);
  useUppyEventHandler('upload', handleUploadStart, uppy);

  const handleFormComplete = useCallback(
    (values: ICompleteOverlayData) => {
      setFormComplete(true);
      const overlayData: IOverlayData = {
        overlayName: values.overlayName,
        levelId: values.levelId,
        defaultEnabled: values.defaultEnabled,
      };

      uppy.setFileMeta<IRasterOverlayFileMeta>(uppyFile!.id, overlayData);
    },
    [uppyFile],
  );
  const handleTrimComplete = useCallback(() => {
    setIsTrimCompleted(true);
  }, []);

  const handleEditOverlay = useCallback(
    (markers: OverlayAlignmentMarkers) => {
      uppy.setFileMeta(uppyFile!.id, { markers });
      setFormComplete(false);
    },
    [uppyFile],
  );

  const handleUploadError = useCallback(
    (_: any, error: any) => {
      const errorMsg = error.isNetworkError ? 'possible firewall or ISP issue' : error;
      enqueueSnackbar(`Raster overlay upload failed: ${errorMsg}`, {
        variant: 'error',
        persist: true, // Persist on screen to ensure user sees feedback in case of being AFK
        action: closeSnackBarAction,
      });
    },
    [closeSnackBarAction, enqueueSnackbar],
  );

  useUppyEventHandler('upload-error', handleUploadError, uppy);

  const open = useCallback(() => {
    // Prevent deep linking to the open state of the tool dialog to activate it when user doesn't have permission
    if (
      // Redirected here from Capture, bypass normal role check to allow all users to create overlays
      navigationSource === NavigationSource.Capture ||
      userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.ORG_ADMIN,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ])
    ) {
      setToolState({ active: RO_TOOL_ID });
    }
  }, [setToolState, userHasOneOfRoles, navigationSource]);

  const resetTool = useCallback(() => {
    if (uppyFile) {
      uppy.removeFile(uppyFile?.id);
    }
    setIsTrimCompleted(false);
    setUploading(false);
    setToolState({ active: null }, true); // deactivate the tool

    /* Whenever tool is reset and redirect url is provided, redirect to that url
      This is used for external app linking to this tool and expecting the user
      to return. */
    const redirUrl = getUrlState(REDIRECT_PARAM);
    if (redirUrl) {
      // Wait 3 seconds before redirecting to give the user time to read the success message
      setTimeout(() => {
        // IMPORTANT: window.location.assign is the only method for opening the url
        // that seems to work with opening an app deep link
        window.location.assign(redirUrl);
      }, 3000);
    }
  }, [uppyFile, setToolState, getUrlState]);

  const handleCloseClick = useCallback(() => {
    if (!uppyFile && !uploading) {
      resetTool();
      uppy.cancelAll();
      return;
    }

    requestConfirmation({
      title: 'Are you sure you want to cancel overlay creation?',
      description: 'The raster overlay will not be created. Data will be lost.',
      confirmButtonProps: {
        color: 'error',
      },
      confirmButtonLabel: 'Cancel creation',
      onConfirm: () => {
        resetTool();
        uppy.cancelAll();
      },
      cancelButtonLabel: 'Continue creation',
      onCancel: () => {
        if (!enabled) {
          setToolState({ active: RO_TOOL_ID });
        }
      },
    });
  }, [uppyFile, uploading, requestConfirmation, resetTool, enabled, setToolState]);

  const createOverlay = useCallback(
    async (corners: OverlayCorners, _markers: OverlayAlignmentMarkers) => {
      setIsSaving(true); // Indicate save is in progress
      try {
        const uppyFileRef = uppy.getFile<IRasterOverlayFileMeta>(uppyFile!.id);
        const { width, height, overlayName, defaultEnabled, levelId } = uppyFileRef.meta;

        if (width === undefined || height === undefined) {
          throw new Error('dimension(s) failed to load');
        }
        if (!overlayName || !levelId || !corners) {
          throw new Error('missing required data');
        }
        const cornerToGCP = (corner: Coordinate, x: number, y: number) =>
          ({
            pixel: x.toString(),
            line: y.toString(),
            easting: corner.longitude.toString(),
            northing: corner.latitude.toString(),
          } as GCP);
        const gcp: GCP[] = [
          cornerToGCP(corners.topRight, width, 0),
          cornerToGCP(corners.topLeft, 0, 0),
          cornerToGCP(corners.bottomLeft, 0, height),
          cornerToGCP(corners.bottomRight, width, height),
        ];
        await createROMutation.mutateAsync({
          locationId: location.id,
          s3ImagePath: uppyFileRef.meta.originalImageKey!,
          gcp,
          name: overlayName,
          levels: [levelId],
          defaultEnabled,
          navigationSource,
        });
        enqueueSnackbar(
          'Raster overlay created, an email will be sent when processing is complete',
          {
            variant: 'success',
            persist: true, // Persist on screen to ensure user sees feedback in case of being AFK
            action: closeSnackBarAction,
          },
        );
        // Reset tool state for future use
        uppy.removeFile(uppyFile!.id);
        setToolState({ active: null }, true); // deactivate the tool
      } catch (error: any) {
        enqueueSnackbar(`Raster overlay could not be created: ${error.message}`, {
          variant: 'error',
          persist: true,
          action: closeSnackBarAction,
        });
      }
    },
    [
      closeSnackBarAction,
      createROMutation,
      location,
      setToolState,
      uppyFile,
      navigationSource,
      enqueueSnackbar,
    ],
  );

  const processUploadedOverlay = useCallback(
    async (fileIds: string[]) => {
      try {
        const uppyUploadedFile = uppy.getFile<IRasterOverlayFileMeta>(fileIds[0]);
        if (!uppyUploadedFile) return; // File type not supported, file was removed

        const uploadUrl = uppyUploadedFile?.response?.uploadURL;

        if (!uploadUrl) {
          throw new Error('url not found');
        }
        // Example: /ro_source_images/3d3fe9444294c61866f444877fa06f93.png
        let s3ImagePath = uploadUrl ? new URL(uploadUrl).pathname : null;
        if (!s3ImagePath) {
          enqueueSnackbar('Error uploading, try again later.', {
            variant: 'error',
            persist: true,
            action: closeSnackBarAction,
          });
          setUploading(false);
          return;
        }
        let urlToPreprocess: string = uploadUrl;

        if (s3ImagePath.endsWith('.pdf')) {
          const s3UrlToAutoTrimLambdaResponse = await s3UrlToAutoTrimLambdaMutation(s3ImagePath);
          if (!s3UrlToAutoTrimLambdaResponse.data) {
            enqueueSnackbar('Error in processing uploaded file, try again later.', {
              variant: 'error',
              persist: true,
              action: closeSnackBarAction,
            });
            setUploading(false);
            return;
          }
          s3ImagePath = s3UrlToAutoTrimLambdaResponse.data.s3Url;
          urlToPreprocess = s3UrlToAutoTrimLambdaResponse.data.downloadableUrl;
          setDownloadableUrl(urlToPreprocess);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, __, fileName] = (s3ImagePath || '').split('/');

        const maxDimension = Number(RuntimeConfig.rasterOverlayResizeSize);

        const dimensions = await getImageDimensions(urlToPreprocess);

        // Calculate the resized dimensions
        const resizedDimensions = calculateResizedDimensions(
          dimensions.width,
          dimensions.height,
          maxDimension,
        );
        const finalResizedDimension = Math.max(
          resizedDimensions.resizeWidth,
          resizedDimensions.resizeHeight,
        );

        const preprocessResponse = await preprocessOverlayFileMutation({
          file: fileName,
          size: finalResizedDimension,
        });

        if (preprocessResponse?.data?.original && preprocessResponse?.data?.resized) {
          uppy.setFileMeta(fileIds[0], {
            width: preprocessResponse.data.original.width,
            height: preprocessResponse.data.original.height,
            originalImageKey: preprocessResponse.data.original.key,
          });
          await uppy.upload();
          setPreview({
            url: preprocessResponse.data.resized.url,
            width: preprocessResponse.data.resized.width,
            height: preprocessResponse.data.resized.height,
          });
        } else {
          enqueueSnackbar('Resizing failed, try again later.', {
            variant: 'error',
            persist: true,
            action: closeSnackBarAction,
          });
          setUploading(false);
          return;
        }

        enqueueSnackbar('File uploaded', {
          variant: 'success',
          persist: true, // Persist on screen to ensure user sees feedback in case of being AFK
          action: closeSnackBarAction,
        });
      } catch (error: any) {
        enqueueSnackbar(`File could not be uploaded: ${error.message}`, {
          variant: 'error',
          persist: true, // Persist on screen to ensure user sees feedback in case of being AFK
          action: closeSnackBarAction,
        });
      }
      setIsSaving(false);
      setUploading(false);
    },
    [
      closeSnackBarAction,
      preprocessOverlayFileMutation,
      s3UrlToAutoTrimLambdaMutation,
      enqueueSnackbar,
    ],
  );

  // Create raster overlay once file is uploaded
  useEffect(() => {
    uppy.addPostProcessor(processUploadedOverlay);
    return () => uppy.removePostProcessor(processUploadedOverlay);
  }, [processUploadedOverlay]);

  useEffect(() => {
    if (!enabled && prevEnabled) {
      // Just disabled
      if (uppyFile || uploading) {
        handleCloseClick();
      } else {
        resetTool();
      }
    }
  }, [enabled, prevEnabled, handleCloseClick, resetTool, uppyFile, uploading]);

  useEffect(() => {
    // Just enabled and map info is expanded
    if (
      enabled &&
      !prevEnabled && // just enabled
      mapInfoExpanded && // map info is expanded
      navigationSource === NavigationSource.Capture // navigated here from capture
    ) {
      setMapInfoExpanded(false); // collapse the info panel while tool is active due to small mobile screen
    }
  }, [enabled, prevEnabled, mapInfoExpanded, setMapInfoExpanded, navigationSource]);

  const {
    originalImageKey = '',
    height = 0,
    width = 0,
  } = uppyFile ? uppy.getFile<IRasterOverlayFileMeta>(uppyFile!.id).meta : {};

  return (
    <>
      <IdsDialog
        open={enabled && !isTrimCompleted}
        onOpen={open}
        onClose={handleCloseClick}
        title='Create New Raster Overlay'
        dialogKey='createRO'
        maxWidth='md'
        fullWidth
        id='raster-overlay-uploader'
        classes={{
          paper: styles.dialog,
        }}
      >
        {(enabled || uploading || !preview) && !formComplete ? (
          <RasterOverlayForm
            uppy={uppy}
            uppyFile={uppyFile}
            onFormComplete={handleFormComplete}
            onCancel={handleCloseClick}
            disableNextButton={!preview || uploading}
            allowedFileTypes={allowedFileTypes}
          />
        ) : (
          preview &&
          formComplete && (
            <RasterOverlayManualTrim
              preview={preview}
              onManualTrimComplete={handleTrimComplete}
              onCancel={handleCloseClick}
              downloadableURL={downloadableUrl!}
              autoTrimmedS3URL={originalImageKey!}
              originalHeight={height!}
              originalWidth={width!}
            />
          )
        )}
      </IdsDialog>
      {(enabled || uploading) && isTrimCompleted && preview && (
        <RasterOverlayPlacement
          preview={preview!}
          onEdit={handleEditOverlay}
          onSave={createOverlay}
          onClose={handleCloseClick}
          uppy={uppy}
          uppyFile={uppyFile}
          uploading={uploading}
          isSaving={isSaving}
        />
      )}
    </>
  );
};

export default RasterOverlayTool;
