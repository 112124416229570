import React, { useCallback } from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

export interface IOverlayToggleButtonProps extends Omit<IconButtonProps, 'onClick'> {
  active: boolean;
  activeIcon: React.ReactNode;
  activeTooltip: string;
  inactiveIcon: React.ReactNode;
  inactiveTooltip: string;
  setActive: (active: boolean) => void;
}

const OverlayToggleButton: React.FC<IOverlayToggleButtonProps> = ({
  active,
  activeIcon,
  activeTooltip,
  inactiveIcon,
  inactiveTooltip,
  setActive,
  ...rest
}) => {
  const toggle = useCallback(() => {
    setActive(!active);
  }, [active, setActive]);

  return (
    <Tooltip title={active ? activeTooltip : inactiveTooltip} placement='top'>
      <IconButton onClick={toggle} size='small' {...rest}>
        {active ? activeIcon : inactiveIcon}
      </IconButton>
    </Tooltip>
  );
};

export default OverlayToggleButton;
