import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { IOrgUserDetailsQueryData } from '../../../services/UsersService';
import QueryLoader from '../../../components/QueryLoader';
import OrgPage from '../../../components/OrgPage';
import Error from '../../Error';

import { useGetOrgUserDetails } from '../../../services/UsersService';
import { activeOrganizationState } from '../../../atoms/organizations';

import { getOrgUsersRoute } from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';

import { activeUserState } from '../../../atoms/users';

import Details from './Details';

export interface IOrgUserDetailsProps {
  edit?: boolean;
}

const OrgUserDetails: React.FC<IOrgUserDetailsProps> = ({ edit = false }) => {
  const [userData, setUserData] = useState<IOrgUserDetailsQueryData['user'] | null>(null);

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeUser = useRecoilValue(activeUserState);
  const useUserInOrg = () => useGetOrgUserDetails(activeOrg!.id, activeUser!.id);

  if (!activeUser) {
    return <Error />;
  }

  return (
    <QueryLoader
      useQuery={useUserInOrg}
      selectData={data => data.user}
      setData={setUserData}
      render={() => (
        <OrgPage
          title={`${userData!.firstName} ${userData!.lastName}`}
          breadcrumbs={[
            {
              label: 'Users',
              route: getOrgUsersRoute({
                subdomain: activeTenant?.subdomain,
                orgId: activeOrg.id,
              }),
            },
          ]}
        >
          <Details edit={edit} />
        </OrgPage>
      )}
    />
  );
};

export default OrgUserDetails;
