import React from 'react';
import { Box, ListItemText, ListItemTextProps } from '@mui/material';

import { useRecoilValue } from 'recoil';

import EntityChip from '../../../../components/entity-chips/EntityChip';
import IdsListItem from '../../../../components/ids-lists/IdsListItem';
import { LocationIcon } from '../../../../theme/icons';
import { getOrgLocationRoute, getOrgMapLocationRoute } from '../../../../utils/routes';

import { activeTenantState } from '../../../../atoms/tenants';
import { ITenantLocationItem } from '../../../../services/TenantService';

export interface ITenantLocationListItemProps {
  locationItem: ITenantLocationItem;
}

const primaryTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
  variant: 'h6',
  noWrap: true,
};
const secondaryTypographyProps: ListItemTextProps['secondaryTypographyProps'] = { noWrap: true };
const nameSecondaryTypographyProps: ListItemTextProps['secondaryTypographyProps'] = {
  display: { xs: 'none', md: 'initial' },
  noWrap: true,
};

const TenantLocationListItem: React.FC<ITenantLocationListItemProps> = ({ locationItem }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { id, name, address, organization } = locationItem.node;
  const { streetLine1, city, state } = address || {};

  // Only show commas if the address elements are present
  const fullAddress = `${streetLine1 && `${streetLine1}, `}${city && `${city}, `}${state}`;

  return (
    <IdsListItem
      key={id}
      to={getOrgLocationRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        locationId: id,
      })}
    >
      <ListItemText
        primary={name}
        primaryTypographyProps={primaryTypographyProps}
        secondary={fullAddress}
        secondaryTypographyProps={secondaryTypographyProps}
      />
      <Box>
        <ListItemText
          secondary={organization.name}
          secondaryTypographyProps={nameSecondaryTypographyProps}
        />
      </Box>
      <Box>
        <EntityChip
          icon={<LocationIcon size={16} />}
          label='Map View'
          entityRoute={getOrgMapLocationRoute({
            subdomain: activeTenant?.subdomain,
            orgId: organization.id,
            locationId: id,
          })}
        />
      </Box>
    </IdsListItem>
  );
};

export default TenantLocationListItem;
