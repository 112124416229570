import { useRecoilState, useRecoilValue } from 'recoil';

import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import { activeUserState } from '../../atoms/users';
import { getTenantUsersRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';
import { useGetUserInTenant } from '../../services/TenantService';

import EntityRoute from './EntityRoute';

const TenantUserRoute = () => {
  const [user, setUser] = useRecoilState(activeUserState);
  const activeTenant = useRecoilValue(activeTenantState);

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useUser = (userId: string | undefined, options: UseGraphQueryOptions) =>
    useGetUserInTenant(activeTenant!.id, userId ?? null, options);

  return (
    <EntityRoute
      idParam='userId'
      currentEntity={user}
      setEntity={setUser}
      useEntity={useUser}
      entitySelector={data => data?.userInTenant}
      errorRedirectRoute={getTenantUsersRoute({
        subdomain: activeTenant?.subdomain,
      })}
    />
  );
};

export default TenantUserRoute;
