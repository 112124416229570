import { useEffect } from 'react';
import Uppy, { UppyEventMap, IndexedObject } from '@uppy/core';

function useUppyEventHandler<K extends keyof UppyEventMap>(
  event: K,
  callback: UppyEventMap[K],
  uppy?: Uppy,
  enabled?: boolean,
): void;
function useUppyEventHandler<K extends keyof UppyEventMap, TMeta extends IndexedObject<any>>(
  event: K,
  callback: UppyEventMap<TMeta>[K],
  uppy?: Uppy,
  enabled?: boolean,
): void;
function useUppyEventHandler<K extends keyof UppyEventMap, TMeta extends IndexedObject<any>>(
  event: K,
  callback: UppyEventMap[K] | UppyEventMap<TMeta>[K],
  uppy?: Uppy,
  enabled = true,
) {
  useEffect(() => {
    if (!uppy || !enabled) return;

    uppy.on(event, callback);
    return () => {
      uppy.off(event, callback);
    };
  }, [uppy, event, callback, enabled]);
}

export default useUppyEventHandler;
