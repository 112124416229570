import React, { useCallback, useMemo, useState } from 'react';
import { IconButton, ListItemText, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import IdsSwitch from '../../../../../../components/ids-inputs/IdsSwitch';
import IdsListItem from '../../../../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../../../../components/ids-lists/IdsListItem/IdsListItemChild';
import CircularProgress from '../../../../../../components/progress/CircularProgress';

import { useUpdateProjectRoutePosition } from '../../../../../../services/ProjectService';
import { IProjectRouteListItemData } from '../../../../../../services/fragments';
import { useTogglePublishRoute } from '../../../../../../services/RouteService';
import { PositionAction } from '../../../../../../services/types';

import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '../../../../../../theme/icons';
import { useRouteProgress } from '../../../../RouteDetailsPage/hooks';
import { activeOrganizationState } from '../../../../../../atoms/organizations';

import usePublishPermissions from './hooks/usePublishPermissions';
import RouteListItemMenu from './RouteListItemMenu';
import RouteStatusChip from './RouteStatusChip';
import { useNavigateToRouteDetails } from './hooks';
import { primaryTypographyProps, secondaryTypographyProps, PublishedLabel } from './helpers';

/**
 * @TODO: A while ago that was necessary to differentiate actions to display
 * different animations on different actions.
 *
 * Most likely this is not necessary anymore. Investigate and refactor.
 */
export type UpdateAction = PositionAction | 'delete' | true | null;

export interface IRouteListItemProps {
  item: IProjectRouteListItemData;
  disabled?: boolean;
  maxPositions: number;
}

const RouteListItem: React.FC<IRouteListItemProps> = ({ item, disabled, maxPositions }) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { enqueueSnackbar } = useSnackbar();

  const { route } = useNavigateToRouteDetails(item.id);

  const [isUpdating, setIsUpdating] = useState<UpdateAction>(null);

  const { id, name, description, status, routePoints, position, projectId, published } = item;
  const positionNumber = +position;
  const { label: routeProgress } = useRouteProgress(routePoints);

  const updateProjectRoutePosition = useUpdateProjectRoutePosition(projectId);

  const shiftPositionByOne = useCallback(
    async (action: PositionAction) => {
      setIsUpdating(action);

      const newPosition =
        action === PositionAction.Increment ? positionNumber + 1 : positionNumber - 1;

      if (newPosition < 1 || newPosition > maxPositions) {
        return;
      }

      try {
        const result = await updateProjectRoutePosition.mutateAsync({
          id,
          organizationId: activeOrg.id,
          position: `${newPosition}`,
        });

        if (result?.updateRoutePosition?.errors?.length) {
          const field = result.updateRoutePosition.errors[0].field;
          const message = result.updateRoutePosition.errors[0].message;

          enqueueSnackbar(`${field} ${message}`, { variant: 'error' });
        }
      } catch (_) {
        enqueueSnackbar('Cannot update the route.', { variant: 'error' });
      } finally {
        setIsUpdating(null);
      }
    },
    [updateProjectRoutePosition, enqueueSnackbar, positionNumber, activeOrg.id, maxPositions, id],
  );

  const decrementPosition = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      await shiftPositionByOne(PositionAction.Decrement);
    },
    [shiftPositionByOne],
  );

  const incrementPosition = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      await shiftPositionByOne(PositionAction.Increment);
    },
    [shiftPositionByOne],
  );

  const { mayPublish, mayUnPublish } = usePublishPermissions(routePoints.length, status, published);

  const { mutateAsync: togglePublish } = useTogglePublishRoute();
  const handlePublishChange = useCallback(async () => {
    setIsUpdating(true);

    try {
      const result = await togglePublish({
        id,
        organizationId: activeOrg.id,
        published: !published,
      });

      if (result.publishRoute?.errors?.length) {
        const field = result.publishRoute.errors[0].field;
        const message = result.publishRoute.errors[0].message;

        enqueueSnackbar(`${field} ${message}`, { variant: 'error' });
      }
    } catch (_) {
      enqueueSnackbar('Failed to update published status', { variant: 'error' });
    } finally {
      setIsUpdating(null);
    }
  }, [togglePublish, published, activeOrg, id, enqueueSnackbar]);

  const listItemProps = useMemo(() => {
    if (isUpdating === 'delete') {
      return {
        /**
         * "to" is necessary to convert list item into button to make
         * "buttonProps" work
         */
        to: route,
        buttonProps: {
          disabled: true,
        },
      };
    }

    if (isUpdating) {
      return {};
    }

    return {
      to: route,
    };
  }, [isUpdating, route]);

  return (
    <IdsListItem
      key={id}
      secondaryAction={<RouteListItemMenu item={item} setIsUpdating={setIsUpdating} />}
      {...listItemProps}
    >
      {/* Arrows */}
      <IdsListItemChild xs='auto'>
        <Stack direction='column'>
          <IconButton
            disabled={disabled || !!isUpdating || positionNumber === 1}
            size='small'
            onClick={decrementPosition}
          >
            {/* Closer to top */}
            {isUpdating && isUpdating === PositionAction.Decrement ? (
              <CircularProgress />
            ) : (
              <ArrowCircleUpIcon />
            )}
          </IconButton>
          <IconButton
            disabled={disabled || !!isUpdating || positionNumber === maxPositions}
            size='small'
            onClick={incrementPosition}
          >
            {/* Closer to bottom */}
            {isUpdating && isUpdating === PositionAction.Increment ? (
              <CircularProgress />
            ) : (
              <ArrowCircleDownIcon />
            )}
          </IconButton>
        </Stack>
      </IdsListItemChild>

      {/* Name */}
      <IdsListItemChild xs>
        <ListItemText primary={name} />
      </IdsListItemChild>

      {/* Description */}
      <IdsListItemChild xs>
        <ListItemText primary={description ?? '-'} />
      </IdsListItemChild>

      {/* Status */}
      <IdsListItemChild xs container direction='row' alignItems='center' justifyContent='center'>
        <RouteStatusChip status={status} />
      </IdsListItemChild>

      <IdsListItemChild xs stopPropagation>
        <IdsSwitch
          label={PublishedLabel}
          labelPlacement='top'
          disabled={disabled || !!isUpdating || !(mayPublish || mayUnPublish)}
          checked={published}
          onChange={handlePublishChange}
        />
      </IdsListItemChild>

      {/* Points progress */}
      <IdsListItemChild xs>
        <ListItemText
          primary='Points Completed'
          secondary={routeProgress}
          primaryTypographyProps={primaryTypographyProps}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default RouteListItem;
