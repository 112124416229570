import { Box, FormControl, FormHelperText, Theme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash.get';

import IdsTransferList, { IIdsTransferListProps } from '../../ids-inputs/IdsTransferList';

export interface IIdsFormTransferListFieldProps extends Omit<IIdsTransferListProps, 'values'> {
  name: string;
  values?: IIdsTransferListProps['values'];
}

const IdsFormTransferListField: React.FC<IIdsFormTransferListFieldProps> = ({
  disabled,
  options,
  values,
  name,
  getPrimaryLabel,
  getSecondaryLabel,
  getOptionValue,
  optionsLabel,
  valuesLabel,
}) => {
  const { setFieldValue, values: formikValues, errors, touched } = useFormikContext<any>();

  const value = get(formikValues, name);
  const touch = get(touched, name);
  const error = get(errors, name);

  const onChangeHandler = useCallback(values => {
    setFieldValue(name, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sx = useMemo(
    () => ({
      border: (theme: Theme) =>
        touch && error
          ? {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.error.main,
              borderRadius: theme.shape.borderRadius,
            }
          : {
              borderWidth: 0,
            },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FormControl disabled={disabled} error={Boolean(touch && error)} fullWidth>
      <Box sx={sx}>
        <IdsTransferList
          disabled={disabled}
          getOptionValue={getOptionValue}
          getPrimaryLabel={getPrimaryLabel}
          getSecondaryLabel={getSecondaryLabel}
          options={options}
          values={values ?? value}
          onChange={onChangeHandler}
          optionsLabel={optionsLabel}
          valuesLabel={valuesLabel}
        />
      </Box>
      {touch && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default IdsFormTransferListField;
