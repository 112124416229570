import React from 'react';
import { Box, Container, ContainerProps } from '@mui/material';

import Page from '../../../components/Page';
import styles from '../../../theme/styles';

import { IIdsBreadcrumbsProps } from '../../../components/IdsBreadcrumbs';

import TenantViewHeader, { ITenantViewHeaderProps } from './TenantViewHeader';

export interface ITenantPage
  extends Pick<ITenantViewHeaderProps, 'breadcrumbs' | 'actions' | 'dialogs'> {
  title: IIdsBreadcrumbsProps['currentCrumb'];
  children: React.ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  containerClassName?: string;
  headerHidden?: boolean;
}

const TenantPage: React.FC<ITenantPage> = ({
  title,
  breadcrumbs,
  children,
  maxWidth = false,
  containerClassName,
  headerHidden = false,
  actions,
  dialogs,
}) => {
  return (
    <Page title={title}>
      <Box sx={styles.rootPage}>
        <Container maxWidth={maxWidth} className={containerClassName}>
          {!headerHidden && (
            <TenantViewHeader
              currentCrumb={title}
              breadcrumbs={breadcrumbs}
              actions={actions}
              dialogs={dialogs}
            />
          )}
          {children}
        </Container>
      </Box>
    </Page>
  );
};

export default TenantPage;
