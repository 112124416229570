import Uppy, { Restrictions, UppyFile } from '@uppy/core';

export const doesUppyFitRestrictions = (
  uppy: Uppy,
  restrictions?: Restrictions,
  ignoreMinRestrictions = false,
) => {
  if (!restrictions) return true;

  const files = uppy.getFiles();

  const {
    maxFileSize,
    minFileSize,
    maxTotalFileSize,
    maxNumberOfFiles,
    minNumberOfFiles,
    allowedFileTypes,
  } = restrictions;

  const isRestrictionSet = <T>(restriction: T | null | undefined) =>
    restriction !== null && restriction !== undefined;

  if (isRestrictionSet(maxFileSize) && files.some(f => f.size > maxFileSize!)) return false;

  if (
    !ignoreMinRestrictions &&
    isRestrictionSet(minFileSize) &&
    files.some(f => f.size < minFileSize!)
  )
    return false;

  if (
    isRestrictionSet(maxTotalFileSize) &&
    files.reduce((totalSize, f) => {
      totalSize += f.size;
      return totalSize;
    }, 0) > maxTotalFileSize!
  )
    return false;

  if (isRestrictionSet(maxNumberOfFiles) && files.length > maxNumberOfFiles!) return false;

  if (
    !ignoreMinRestrictions &&
    isRestrictionSet(minNumberOfFiles) &&
    files.length < minNumberOfFiles!
  )
    return false;

  // Validate allowed file types
  const isValidType = (type: string) => {
    const lowercaseType = type.toLowerCase(); // Ensure uppercase variants of file types are supported
    return (
      allowedFileTypes!.includes(lowercaseType) ||
      allowedFileTypes!.includes(lowercaseType.split('/')[0] + '/*')
    );
  };

  if (
    isRestrictionSet(allowedFileTypes) &&
    // allow for mime type or file extensions to be set in allowedFileTypes
    files.some(
      (file: UppyFile) =>
        file.type && !isValidType(file.type) && !isValidType(`.${file.extension}`),
    )
  ) {
    return false;
  }

  return true;
};
