import { ListItemTextProps, Typography } from '@mui/material';

export const PublishedLabel = (
  <Typography variant='body2' color='text.secondary'>
    Published
  </Typography>
);

export const primaryTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
  variant: 'body2',
  color: 'text.secondary',
};
export const secondaryTypographyProps: ListItemTextProps['secondaryTypographyProps'] = {
  color: 'text.primary',
  noWrap: true,
};
