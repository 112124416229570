import React, { createContext } from 'react';

export interface ITaskResponseContext {
  taskResponse: any;
  groupedAnswers: any;
}

const TaskResponseContext = createContext<ITaskResponseContext | null>(null);

export interface ITaskResponseProviderProps extends ITaskResponseContext {
  children: React.ReactNode;
}

export const TaskResponseProvider: React.FC<ITaskResponseProviderProps> = ({
  children,
  taskResponse,
  groupedAnswers,
}) => {
  return (
    <TaskResponseContext.Provider value={{ taskResponse, groupedAnswers }}>
      {children}
    </TaskResponseContext.Provider>
  );
};

export default TaskResponseContext;
