import { useRestoreFlagFilterFromUrl } from '../../../../hooks/filtering/useFlagFilter';
import { useRestorePublishedFilterFromUrl } from '../../../../hooks/filtering/usePublishedFilter';
import { useRestoreLevelFilterFromUrl } from '../../../../components/ids-filters/LevelFilter';
import { useRestoreAreaFilterFromUrl } from '../../../../components/ids-filters/AreaFilter';
import { useRestoreCategoryFilterFromUrl } from '../../../../components/ids-filters/CategoryFilter';
import { useRestoreTagsFilterFromUrl } from '../../../../components/ids-filters/TagsFilter';
import { useRestoreTypeFilterFromUrl } from '../../../../components/ids-filters/TypeFilter';
import useMetadataItems from '../../../../hooks/useMetadataItems';
import { IMetadataType } from '../../../../constants/media';
import { CustomFieldMetadataFilterInitializer } from '../../../../hooks/filtering/useCustomFieldMetadataFilter';
import { IAssignableCustomField } from '../../../../constants/customFields';

export interface ILocationMediaTabFilterInitializers {
  location?: {
    metadataTypes: IMetadataType[];
    assignableCustomFields: IAssignableCustomField[];
  };
}

const LocationMediaTabFilterInitializers: React.FC<ILocationMediaTabFilterInitializers> = ({
  location,
}) => {
  useRestoreFlagFilterFromUrl();
  useRestorePublishedFilterFromUrl();

  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(
    location?.metadataTypes || [],
  );
  useRestoreLevelFilterFromUrl(allLevels);
  useRestoreAreaFilterFromUrl(allAreas);
  useRestoreCategoryFilterFromUrl(allCategories);
  useRestoreTagsFilterFromUrl(allTags);
  useRestoreTypeFilterFromUrl(allTypes);

  return (
    <>
      {location?.assignableCustomFields?.map((acf: IAssignableCustomField) => (
        <CustomFieldMetadataFilterInitializer assignableCustomField={acf} key={acf.id} />
      ))}
    </>
  );
};

export default LocationMediaTabFilterInitializers;
