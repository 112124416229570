import React from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Container } from '@mui/material';

import { useGetOrganizationProjects } from '../../services/ProjectService';
import Page from '../../components/Page';
import styles from '../../theme/styles';
import { activeOrganizationState } from '../../atoms/organizations';

import ProjectListItem from './ProjectListItem';
import ProjectListHeader from './ProjectListHeader';
import ProjectsList from './ProjectsList';

function Projects() {
  const organization = useRecoilValue(activeOrganizationState);

  const { data: projects, error, isLoading } = useGetOrganizationProjects(organization.id);

  return (
    <Page title='Projects'>
      <Box sx={styles.rootPage}>
        <Container maxWidth={false}>
          <ProjectListHeader />
          <ProjectsList
            projectItems={projects}
            renderProjectItem={projectItem => (
              <ProjectListItem key={projectItem.node.id} projectItem={projectItem} />
            )}
            loading={isLoading}
            error={error && 'Projects could not be loaded'}
          />
        </Container>
      </Box>
    </Page>
  );
}

export default Projects;
