import { useMemo } from 'react';

import { MediaType } from '../../../../../constants/media';
import useFilterContext from '../../../../../hooks/useFilterContext';

const useImageData = () => {
  const { useTypeFilteredData } = useFilterContext();

  const filteredProjectPhotos = useTypeFilteredData(MediaType.ProjectPhoto);
  const filteredHdPhotos = useTypeFilteredData(MediaType.HDPhoto);
  const filteredPanoramas = useTypeFilteredData(MediaType.PanoramicPhoto);

  return useMemo(
    () => [...filteredProjectPhotos, ...filteredHdPhotos, ...filteredPanoramas],
    [filteredProjectPhotos, filteredHdPhotos, filteredPanoramas],
  );
};

export default useImageData;
