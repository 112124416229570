import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalCase } from 'change-case';
import PropTypes from 'prop-types';

import { useRecoilValue } from 'recoil';

import { gql } from 'graphql-request';

import { Box, ListItemAvatar, ListItemText } from '@mui/material';

import useToggleUserActivationStateAction from '../useToggleUserActivationStateAction';
import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import IdsListItemMenu from '../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../components/ids-lists/IdsListItemMenuItem';
import IdsUserAvatar from '../../../components/IdsUserAvatar';
import { activeOrganizationState } from '../../../atoms/organizations';
import { ActivateIcon, DeactivateIcon, EditOutlineIcon } from '../../../theme/icons';
import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';
import { getOrgUserEditRoute, getOrgUserRoute } from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';
import ActivationStateChip from '../ActivationStateChip';

export const UserInRoleListItemFrag = gql`
  fragment UserInRoleListItemFrag on UserInRole {
    user {
      id
      firstName
      lastName
      email
      activationState
    }
    roleName
  }
`;

export const UserListItemMenu = ({ userItem }) => {
  const { loading, toggleUserActivationState } = useToggleUserActivationStateAction();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { userHasPermission } = usePermissions();

  const canCreate = useMemo(
    () => userHasPermission('create', EntityType.User),
    [userHasPermission],
  );
  const canUpdate = useMemo(
    () => userHasPermission('update', EntityType.User),
    [userHasPermission],
  );
  const canDelete = useMemo(
    () => userHasPermission('delete', EntityType.User),
    [userHasPermission],
  );

  const navigate = useNavigate();

  const editUser = useCallback(() => {
    navigate(
      getOrgUserEditRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        userId: userItem.node.user.id,
      }),
    );
  }, [navigate, userItem.node.user.id, activeOrg.id, activeTenant]);

  const { user } = userItem?.node;
  const isActive = user.activationState === 'active';

  const canToggleActivationState = isActive ? canDelete : canCreate;

  return (
    (canUpdate || canToggleActivationState) && (
      <IdsListItemMenu>
        {canUpdate && (
          <IdsListItemMenuItem icon={<EditOutlineIcon />} onClick={editUser}>
            Edit
          </IdsListItemMenuItem>
        )}
        {canToggleActivationState && (
          <IdsListItemMenuItem
            icon={isActive ? <DeactivateIcon /> : <ActivateIcon />}
            onClick={() => toggleUserActivationState(user)}
            disabled={loading}
          >
            {isActive ? 'Deactivate' : 'Activate'}
          </IdsListItemMenuItem>
        )}
      </IdsListItemMenu>
    )
  );
};

const UserListItem = ({ userItem, disableMenu }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const navigate = useNavigate();

  const openUser = useCallback(() => {
    navigate(
      getOrgUserRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        userId: userItem.node.user.id,
      }),
    );
  }, [navigate, userItem.node.user.id, activeOrg.id, activeTenant]);

  if (!userItem?.node) {
    return null;
  }

  const { roleName: snakecase_roleName, user } = userItem?.node;
  const fullName = `${user.firstName} ${user.lastName}`;
  const roleName = capitalCase(snakecase_roleName);

  return (
    <IdsListItem
      key={user.id}
      primaryAction={openUser}
      secondaryAction={!disableMenu && <UserListItemMenu userItem={userItem} />}
    >
      <IdsListItemChild xs md={6}>
        <Box display='flex' alignItems='center'>
          <ListItemAvatar>
            <IdsUserAvatar name={fullName} />
          </ListItemAvatar>
          <ListItemText
            primary={fullName}
            primaryTypographyProps={{ variant: 'h6' }}
            secondary={user.email}
          />
        </Box>
      </IdsListItemChild>
      <IdsListItemChild container xs={3} md>
        <IdsListItemChild xs={12} md>
          <Box>
            <ListItemText primary={roleName} primaryTypographyProps={{ variant: 'body2' }} />
          </Box>
        </IdsListItemChild>
        <IdsListItemChild xs={12} md>
          <ActivationStateChip activationState={user.activationState} />
        </IdsListItemChild>
      </IdsListItemChild>
    </IdsListItem>
  );
};

UserListItem.defaultProps = {
  disableMenu: false,
  onEdit: () => {},
};

UserListItem.propTypes = {
  userItem: PropTypes.object.isRequired,
  disableMenu: PropTypes.bool,
};

export default UserListItem;
