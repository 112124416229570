import { useMutation } from 'react-query';

import { gql } from 'graphql-request';

import queryClient from '../utils/query';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import { IMediaMetadata } from '../constants/media';

import { useLocationKeys } from './LocationService';
import { PositionAction } from './types';

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

const DeleteOverlayMutation = gql`
  mutation DeleteOverlay($where: WhereUniqueIdOrganizationInput!) {
    deleteOverlay(where: $where) {
      errors {
        message
        field
      }
      success
    }
  }
`;

const deleteOverlay = ({ id, organizationId }: { id: number; organizationId: number }) => {
  return buildGraphMutationFn(DeleteOverlayMutation)({
    where: {
      id,
      organizationId,
    },
  });
};

export function useDeleteOverlay(locationId: string) {
  const locationKeys = useLocationKeys();

  return useMutation(deleteOverlay, {
    onError,
    onSuccess: () => {
      // Only invalidate project queries in the context of this project
      queryClient.invalidateQueries(locationKeys.detail(locationId), {
        refetchActive: true,
      });
    },
  });
}

const ChangePositionMutation = gql`
  mutation ChangeOverlayPosition(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateOverlayInput
  ) {
    updateOverlay(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const changeOverlayPositionByOne = ({
  id,
  organizationId,
  action,
  currentPosition,
}: {
  id: string;
  organizationId: string;
  action: PositionAction;
  currentPosition: number;
}) => {
  const newPosition =
    action === PositionAction.Increment ? currentPosition + 1 : currentPosition - 1;
  return buildGraphMutationFn(ChangePositionMutation)({
    input: {
      position: newPosition,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export function useUpdateOverlayPositionByOne(locationId: string) {
  const locationKeys = useLocationKeys();

  return useMutation(changeOverlayPositionByOne, {
    onError,
    onSuccess: () => {
      // Only invalidate project queries in the context of this project
      queryClient.invalidateQueries(locationKeys.detail(locationId), {
        refetchActive: true,
      });
    },
  });
}

export const UpdateOverlayMutation = gql`
  mutation UpdateOverlay($where: WhereUniqueIdOrganizationInput!, $input: UpdateOverlayInput) {
    updateOverlay(where: $where, input: $input) {
      overlay {
        name
        position
        id
        metadata {
          id
          type
          value
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export interface IOverlayUpdateInputValues {
  name: string;
  position: number;
  color: string | undefined;
  default: boolean | undefined;
  metadata: Pick<IMediaMetadata, 'id' | 'type'>[];
}

const updateOverlay = ({
  id,
  organizationId,
  values,
}: {
  id: string;
  organizationId: string;
  values: IOverlayUpdateInputValues;
}) => {
  return buildGraphMutationFn(UpdateOverlayMutation)({
    input: {
      ...values,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export function useUpdateOverlay(locationId: string) {
  const locationKeys = useLocationKeys();

  return useMutation(updateOverlay, {
    onError,
    onSuccess: () => {
      // Only invalidate project queries in the context of this project
      queryClient.invalidateQueries(locationKeys.detail(locationId), {
        refetchActive: true,
      });
    },
  });
}
