import area from '@turf/area';
import distance from '@turf/distance';
import { point, polygon, convertLength, convertArea } from '@turf/helpers';

function isNumberValid(value) {
  if (typeof value !== 'number') {
    return false;
  }

  const numVal = parseFloat(value);
  return !isNaN(numVal);
}

export function isLatitudeValid(latitude) {
  return isNumberValid(latitude) && -90 < latitude && latitude < 90;
}

export function isLongitudeValid(longitude) {
  return isNumberValid(longitude) && -180 < longitude && longitude < 180;
}

export function isHeadingValid(heading) {
  return isNumberValid(heading) && 0 <= heading && heading < 360;
}

export function getGeoCoordinatesCenter(
  data,
  selectLatitude = coord => coord.latitude,
  selectLongitude = coord => coord.longitude,
) {
  if (!data?.length) {
    return null;
  }

  const { min, max } = getGeoCoordinateBounds(data, selectLatitude, selectLongitude);

  return {
    latitude: max.latitude - (max.latitude - min.latitude) / 2,
    longitude: max.longitude - (max.longitude - min.longitude) / 2,
  };
}

// Get the min and max lat and lng coordinates in the data
export function getGeoCoordinateBounds(
  data,
  selectLatitude = coord => coord.latitude,
  selectLongitude = coord => coord.longitude,
) {
  if (!data?.length) {
    return null;
  }

  let minLat, maxLat, minLng, maxLng;

  data.forEach(coord => {
    const lat = selectLatitude(coord);
    const lng = selectLongitude(coord);

    if (!minLat || lat < minLat) {
      minLat = lat;
    }

    if (!maxLat || lat > maxLat) {
      maxLat = lat;
    }

    if (!minLng || lng < minLng) {
      minLng = lng;
    }

    if (!maxLng || lng > maxLng) {
      maxLng = lng;
    }
  });

  return {
    min: {
      latitude: minLat,
      longitude: minLng,
    },
    max: {
      latitude: maxLat,
      longitude: maxLng,
    },
  };
}

export function getDistanceFromLatLonInKilometers(lat1, lon1, lat2, lon2) {
  const from = point([lon1, lat1]);
  const to = point([lon2, lat2]);
  return distance(from, to);
}

export function getDistanceFromLatLonInFeet(lat1, lon1, lat2, lon2) {
  const distanceKm = getDistanceFromLatLonInKilometers(lat1, lon1, lat2, lon2);
  return convertLength(distanceKm, 'kilometers', 'feet');
}

export function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
  const distanceKm = getDistanceFromLatLonInKilometers(lat1, lon1, lat2, lon2);
  return convertLength(distanceKm, 'kilometers', 'meters');
}

export function getAreaOfLatLonPointsInFeet(points) {
  if (!points || points.length < 3) {
    return 0;
  }

  const _points = [...points];
  const firstPoint = _points[0];
  const lastPoint = _points[_points.length - 1];

  // Make sure first and last points are equivalent
  if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
    _points.push(firstPoint);
  }

  const _polygon = polygon([_points]);
  const areaM = area(_polygon);
  return convertArea(areaM, 'meters', 'feet');
}
