import React from 'react';
import { DialogContent, DialogActions, DialogActionsProps } from '@mui/material';

import IdsForm, { IIdsFormProps } from '../IdsForm';

export interface IIdsDialogFormProps extends IIdsFormProps {
  actions: DialogActionsProps['children'];
}

const IdsDialogForm: React.FC<IIdsDialogFormProps> = ({ children, actions, ...rest }) => {
  return (
    <IdsForm {...rest}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </IdsForm>
  );
};

export default IdsDialogForm;
