import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { activeProgramState } from '../../../../atoms/programs';
import QueryLoader from '../../../../components/QueryLoader';
import { useGetProgramProjects } from '../../../../services/ProgramsService';
import ProjectStatusChip, { STATUS_CHIP_COLOR } from '../../../Projects/ProjectStatusChip';

const ProjectProgress = () => {
  const [progress, setProgress] = useState(null);
  const activeProgram = useRecoilValue(activeProgramState);
  const setProgramProgress = data => {
    const progress = {};
    // Gather statuses
    data.forEach(project => {
      if (project.node.status in progress) {
        progress[project.node.status]++;
      } else {
        progress[project.node.status] = 1;
      }
    });

    // Add missing statuses with count === 0 to progress
    const allStatuses = Object.keys(STATUS_CHIP_COLOR);
    allStatuses.forEach(status => {
      if (!(status in progress)) {
        progress[status] = 0;
      }
    });

    setProgress(progress);
  };

  const useProgramProgressQuery = () => useGetProgramProjects(activeProgram.id);

  return (
    <QueryLoader
      useQuery={useProgramProgressQuery}
      selectData={data => data}
      setData={setProgramProgress}
      render={() => {
        return Object.keys(progress).map(statusName => (
          <ProjectStatusChip key={statusName} status={statusName} count={progress[statusName]} />
        ));
      }}
    />
  );
};

export default ProjectProgress;
