import { IIdsAutocompleteProps } from '../../../../../components/ids-inputs/IdsAutocomplete';
import SingleValueAutocompleteOption from '../../../../../components/ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';

import { MediaMetadataType, ROUTE_POINT_TYPES } from '../../../../../constants/media';

import { IOption } from './types';

export const getOptionValue: IIdsAutocompleteProps['getOptionValue'] = o => o.value;
export const getOptionLabel: IIdsAutocompleteProps['getOptionLabel'] = o => o.label;
export const renderOption: IIdsAutocompleteProps['renderOption'] = (props, o: IOption) => (
  <SingleValueAutocompleteOption label={o.label} {...props} key={o.value} />
);

export const imageTypeOptions = Object.entries(ROUTE_POINT_TYPES).map(([value, obj]) => ({
  value,
  label: obj.singularLabel,
}));

export const defaultSettings = {
  imageType: null,
  [MediaMetadataType.Level]: null,
  [MediaMetadataType.Area]: null,

  // @TODO: add it when possible
  // [MediaMetadataType.PhotoTag]: null,
};

export const POINTS_PLACEMENT_LAYER_ID = 'points-placement-layer-id';
export const POINTS_REPOSITION_LAYER_ID = 'points-reposition-layer-id';

export const iconClusterPlacementProps = {
  id: POINTS_PLACEMENT_LAYER_ID,
  cluster: false,
  visible: true,
  pickable: true,
};
