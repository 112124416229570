import React from 'react';

import clsx from 'clsx';

import { FlagIcon } from '../../theme/icons';
import IdsImage, { IIdsImageProps, RenderIconFunc } from '../IdsImage';

import styles from './FlaggableImage.module.css';

const renderBottomRightIcon: RenderIconFunc = ({ className, ...props }) => (
  <FlagIcon {...props} className={clsx(className, styles.flagIcon)} />
);

export interface IFlaggableImageProps extends IIdsImageProps {
  flagged?: boolean;
}

const FlaggableImage: React.FC<IFlaggableImageProps> = ({ flagged = false, ...rest }) => (
  <IdsImage
    {...rest}
    {...(flagged && { renderBottomRightIcon })}
    bottomRightIconRequiresImage={false}
  />
);

export default FlaggableImage;
