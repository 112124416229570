import { useEffect } from 'react';

import useFilterContext from '../useFilterContext';
import { IFilterTarget } from '../../context/FilterContext';
import usePrevious from '../usePrevious';

export type FilterTargetDataChangeHandler<T = any> = (
  data: any[],
  selectFilterData: IFilterTarget['selectFilterData'],
  type: string,
) => T;

export interface IUseFilterTargetDataListenersProps {
  filterTargets: IFilterTarget[];
  handleDataChange: FilterTargetDataChangeHandler;
}

const useFilterTargetDataListeners = ({
  filterTargets,
  handleDataChange,
}: IUseFilterTargetDataListenersProps) => {
  const { getTypeData, addTypeDataListener, removeTypeDataListener } = useFilterContext();
  const prevFilterTargets = usePrevious(filterTargets);

  useEffect(() => {
    const dataListenerIds: { type: string; listenerId: number }[] = [];

    filterTargets.forEach(t => {
      // Listen for changes to the data
      const listenerId = addTypeDataListener(t.type, (data: any[]) =>
        handleDataChange(data, t.selectFilterData, t.type),
      )!;
      dataListenerIds.push({ type: t.type, listenerId });
      if (filterTargets !== prevFilterTargets) {
        // Filter targets changed, handle current data
        handleDataChange(getTypeData(t.type), t.selectFilterData, t.type);
      }
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dataListenerIds.forEach(({ type, listenerId }) => {
        removeTypeDataListener(type, listenerId);
      });
    };
  }, [
    filterTargets,
    prevFilterTargets,
    addTypeDataListener,
    handleDataChange,
    removeTypeDataListener,
    getTypeData,
  ]);
};

export default useFilterTargetDataListeners;
