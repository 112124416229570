import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export interface ISaveButtonProps extends ButtonProps {
  label?: string;
}

const sx: ButtonProps['sx'] = {
  backgroundColor: theme => theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme => theme.palette.primary.light,
  },
};

const SaveButton: React.FC<ISaveButtonProps> = ({ label, ...rest }) => {
  return (
    <Button variant='contained' sx={sx} {...rest}>
      {label || 'Save'}
    </Button>
  );
};

export default SaveButton;
