import React, { useMemo } from 'react';
import { Box, Button, Paper, SvgIcon } from '@mui/material';
import { capitalCase } from 'change-case';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';
import get from 'lodash.get';

import Error from '../../../../../views/Error';
import { CardType, PREFIX } from '../../index';
import { DeleteIcon } from '../../../../../theme/icons';
import { activeTenantState } from '../../../../../atoms/tenants';
import { useGetOrganizationProjects } from '../../../../../services/ProjectService';
import { useGetTenantOrganizations } from '../../../../../services/TenantService';
import IdsFormAutocompleteField from '../../../../../components/ids-forms/IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../../../../components/ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { USER_ROLES, UserRole, UserRoleTypeUppercase } from '../../../../../constants/users';
import IdsFormSelectField from '../../../../../components/ids-forms/IdsFormSelectField';
import IdsFormTransferListField from '../../../../../components/ids-forms/IdsFormTransferListField';

/** User roles that require the user to be assigned to individual projects. */
const PROJECT_LEVEL_ROLES = [
  UserRole.org_team,
  UserRole.contractor_manager,
  UserRole.contractor_team,
  UserRole.client_manager,
  UserRole.client_team,
];

export interface ICardProps {
  index: number;
  remove: () => void;
  canModify: boolean;
}

export const ROLE_OPTIONS = Object.values(USER_ROLES)
  .filter(
    role =>
      role.canAssign &&
      role.id !== null &&
      ![USER_ROLES.IDS_ADMIN.name, USER_ROLES.IDS_TEAM.name].includes(role.name),
  )
  .map(role => ({
    label: capitalCase(role.name),
    value: role.name,
  }));

const Card: React.FC<ICardProps> = ({ index, remove, canModify }) => {
  const roleFieldName = `${PREFIX}.${index}.role`;
  const organizationFieldName = `${PREFIX}.${index}.organization`;
  const projectsFieldName = `${PREFIX}.${index}.projects`;

  const { values, setFieldValue } = useFormikContext<any>();
  const activeTenant = useRecoilValue(activeTenantState);

  /**
   * Get organizations from other cards to prevent them to be selected
   * in this card.
   */
  const alreadySelectedOrganizations = useMemo<string[]>(() => {
    return values[PREFIX].reduce((accumulator: string[], card: CardType) => {
      if (card.organization !== null) {
        accumulator.push(card.organization);
      }

      return accumulator;
    }, []);
  }, [values]);

  const roleValue = get(values, roleFieldName);
  const organizationValue = get(values, organizationFieldName);

  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    error: errorOrganizations,
  } = useGetTenantOrganizations(activeTenant!.id);
  const {
    data: projects,
    isLoading: isLoadingProjects,
    error: errorProjects,
  } = useGetOrganizationProjects(
    organizationValue && PROJECT_LEVEL_ROLES.includes(roleValue) ? organizationValue : null,
  );

  const legacyRoleSelected = useMemo(
    () => roleValue && !USER_ROLES[roleValue.toUpperCase() as UserRoleTypeUppercase].canAssign,
    [roleValue],
  );

  const options = useMemo(() => {
    if (!roleValue || !legacyRoleSelected) {
      return ROLE_OPTIONS;
    }

    // Current role cannot be assigned, need to manually list it to show the current role
    return [{ label: capitalCase(roleValue), value: roleValue }, ...ROLE_OPTIONS];
  }, [roleValue, legacyRoleSelected]);

  if (isLoadingOrganizations) {
    return <LoadingScreen />;
  }

  if (errorOrganizations) {
    return <Error />;
  }

  return (
    <Box component={Paper} p={2}>
      {/* Button to remove this card */}
      <Box textAlign='right' mb={2}>
        <Button disabled={!canModify} color='error' onClick={remove}>
          <SvgIcon fontSize='small' sx={{ mr: 1 }}>
            <DeleteIcon />
          </SvgIcon>
          Delete
        </Button>
      </Box>

      {/* Fields for this card */}
      <Box>
        {/* Role */}
        <IdsFormSelectField
          disabled={!canModify}
          name={roleFieldName}
          label='Role'
          required
          options={options}
          error={legacyRoleSelected}
          helperText={legacyRoleSelected ? 'Legacy role selected, editing not supported' : null}
          sx={{ paddingBottom: 2 }}
        />

        {/* Render organization field if role was selected */}
        {roleValue !== '' && (
          <Box mt={0.5}>
            <IdsFormAutocompleteField
              disabled={!canModify || legacyRoleSelected}
              name={organizationFieldName}
              label='Organization'
              required
              options={organizations}
              onChange={() => setFieldValue(projectsFieldName, [])}
              getOptionDisabled={o => alreadySelectedOrganizations.includes(o.node.id)}
              getOptionLabel={o => o.node.name}
              getOptionValue={o => o.node.id}
              renderOption={(props, o) => (
                <SingleValueAutocompleteOption label={o.node.name} {...props} key={o.node.id} />
              )}
            />
          </Box>
        )}

        {/*
          Render projects field if organization was selected.
          Some roles do not need this field.
        */}
        {organizationValue !== null && PROJECT_LEVEL_ROLES.includes(roleValue) && (
          <Box mt={1}>
            {errorProjects && <Error />}
            {isLoadingProjects && <LoadingScreen />}
            {!errorProjects && !isLoadingProjects && (
              <IdsFormTransferListField
                disabled={!canModify || legacyRoleSelected}
                name={projectsFieldName}
                optionsLabel='Search unselected projects'
                valuesLabel='Search selected projects'
                options={projects}
                getPrimaryLabel={o => o.node.name}
                getSecondaryLabel={o => o.node.location.name}
                getOptionValue={o => o.node.id}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Card;
