import React, { useCallback, useMemo, useState } from 'react';

import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import { useNavigate } from 'react-router-dom';

import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../../services/LocationService';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { PositionAction } from '../../../../services/types';

import OverlaysList, { OverlaysListFrag } from '../OverlaysList';
import { activeLocationState } from '../../../../atoms/locations';

import { EntityType } from '../../../../constants/entities';

import EditOverlayDialog from '../OverlaysList/EditOverlayDialog';

import useDeleteOverlayAction from '../OverlaysList/useDeleteOverlayAction';

import { useUpdateOverlayPositionByOne } from '../../../../services/OverlaysService';
import { getOrgMapLocationRouteNewOverlay } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';

const RasterOverlaysQuery = gql`
  query RasterOverlays($orgId: ID, $locationId: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      rasterOverlays {
        ...OverlaysListFrag
      }
    }
  }
  ${OverlaysListFrag}
`;

function RasterOverlaysTab() {
  const navigate = useNavigate();
  const locationKeys = useLocationKeys();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOverlay, setSelectedOverlay] = useState(null);
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const { mutateAsync: updateOverlayPositionByOneMutation } = useUpdateOverlayPositionByOne(
    activeLocation.id,
  );

  const { data, error, isLoading } = useOrgGraphQuery(
    locationKeys.rasterOverlays(activeLocation.id),
    RasterOverlaysQuery,
    { locationId: activeLocation.id },
  );

  const overlays = useMemo(() => {
    return data?.location.rasterOverlays || [];
  }, [data]);

  const maxPositions = overlays.length;

  const handleCreateNewOverlay = useCallback(() => {
    navigate(
      getOrgMapLocationRouteNewOverlay({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        locationId: activeLocation.id,
      }),
    );
  }, [activeLocation, activeOrg, activeTenant, navigate]);

  const shiftOverlayPositionByOne = useCallback(
    async (urnId, action, currentPosition) => {
      if (
        (action === PositionAction.Increment && currentPosition === maxPositions) ||
        (action === PositionAction.Decrement && currentPosition === 1)
      ) {
        return;
      }
      await updateOverlayPositionByOneMutation({
        id: urnId,
        organizationId: activeOrg.id,
        action: action,
        currentPosition: currentPosition,
      });
    },
    [activeOrg.id, updateOverlayPositionByOneMutation, maxPositions],
  );

  const toggleEditDialogState = useCallback(() => {
    setEditDialogOpen(prev => !prev);
  }, [setEditDialogOpen]);

  const editOverlay = useCallback(
    urnId => {
      const thisOverlay = overlays.find(overlay => overlay.id === urnId);
      setSelectedOverlay(thisOverlay);
      setEditDialogOpen(true);
    },
    [overlays],
  );

  return (
    <>
      <OverlaysList
        overlays={overlays}
        entityType={EntityType.RasterOverlay}
        onCreateOverlay={handleCreateNewOverlay}
        onChangeOverlayPosition={shiftOverlayPositionByOne}
        onEditOverlay={editOverlay}
        useDeleteOverlayAction={useDeleteOverlayAction}
        searchPlaceholder='Search raster overlays'
        noItemsMessage='No raster overlays'
        noItemsForFilterMessage='No raster overlays match your search'
        loading={isLoading}
        error={error && 'Raster overlays could not be loaded'}
      />
      <EditOverlayDialog
        open={editDialogOpen}
        onOpen={toggleEditDialogState}
        onClose={toggleEditDialogState}
        overlay={selectedOverlay}
        maxPositions={maxPositions}
      />
    </>
  );
}

export default RasterOverlaysTab;
