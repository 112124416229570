import { UseQueryResult } from 'react-query';

import { To } from 'react-router-dom';

import useCursorPaginatedQuery, {
  IEdgeWithCursor,
  ISelectLocationFn,
} from '../../../hooks/useCursorPaginatedQuery';

import IdsSearchableList from '../../../components/ids-lists/IdsSearchableList';

import AssignmentListItem, { IAssignmentListItemFrag } from '../AssignmentListItem';

export const searchAssignmentItem = (
  assignmentItem: { node: IAssignmentListItemFrag },
  query: string,
) => {
  const searchProps = [
    assignmentItem.node.title,
    `${assignmentItem.node.parentType} #${assignmentItem.node.parentId}`,
  ];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

const assignmentFilters = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
  {
    key: 'active',
    label: 'Active',
    filterItem: (assignmentItem: { node: IAssignmentListItemFrag }) =>
      assignmentItem.node.active === true,
  },
  {
    key: 'inactive',
    label: 'Inactive',
    filterItem: (assignmentItem: { node: IAssignmentListItemFrag }) =>
      assignmentItem.node.active === false,
  },
];

const sortAssignmentItems = (a: IAssignmentListItemFrag, b: IAssignmentListItemFrag) => {
  return +b.id - +a.id; // Reverse order of creation
};

export interface IAssignmentsListProps {
  useGetAssignments: (take: number, after: string | null) => UseQueryResult<any, any>;
  selectConnection: ISelectLocationFn<IEdgeWithCursor<IAssignmentListItemFrag>>;
  getTo: (assignmentId: string) => To;
}

const AssignmentsList: React.FC<IAssignmentsListProps> = ({
  useGetAssignments,
  selectConnection,
  getTo,
}) => {
  const {
    data: assignments,
    error,
    isLoading,
  } = useCursorPaginatedQuery({
    useQuery: useGetAssignments,
    defaultTake: 50,
    selectConnection,
  });

  return (
    <IdsSearchableList
      items={assignments}
      renderItem={(assignmentItem: { node: IAssignmentListItemFrag }) => (
        <AssignmentListItem
          key={assignmentItem.node.id}
          assignmentItem={assignmentItem.node}
          to={getTo(assignmentItem.node.id)}
        />
      )}
      searchPlaceholder={'Search assignments'}
      searchItem={searchAssignmentItem}
      filters={assignmentFilters}
      sortItems={sortAssignmentItems}
      noItemsMessage='No assignments'
      loading={isLoading}
      error={error && 'Assignments could not be loaded'}
      // TODO: Rewrite IdsSearchableList to TS
      header={undefined}
      filterMode={undefined}
      actions={undefined}
      noItemsForFilterMessage={undefined}
    />
  );
};

export default AssignmentsList;
