import ComingSoonRoute from '../../../components/ComingSoonRoute';
import Page from '../../../components/Page';

function TenantReports() {
  return (
    <Page title='Reports'>
      <ComingSoonRoute legacyFeatureRoute={null} />
    </Page>
  );
}
export default TenantReports;
