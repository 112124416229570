import React, { useEffect } from 'react';
import { Box, IconButton, List, ListItem, Button } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';

import IdsFormTextField from '../../../../../../../../../../../components/ids-forms/IdsFormTextField';

import { DeleteOutlineIcon, AddIcon } from '../../../../../../../../../../../theme/icons';

import { IAssignmentAnswer, IAssignmentQuestion } from '../types';

export interface IEditableBarcodeAnswerProps {
  question: IAssignmentQuestion;
  answer?: IAssignmentAnswer;
}

const EditableBarcodeAnswer: React.FC<IEditableBarcodeAnswerProps> = ({ question, answer }) => {
  const { repeatable } = question;
  const fieldName = answer?.id ?? question.id;

  const { values, setFieldValue } = useFormikContext<Record<string, string[]>>();

  useEffect(() => {
    if (!repeatable || values[fieldName].length) {
      return;
    }

    setFieldValue(fieldName, ['']);
  }, [repeatable, setFieldValue, fieldName, values]);

  if (!repeatable) {
    return <IdsFormTextField name={fieldName} multiline minRows={1} maxRows={3} label='' />;
  }

  return (
    <FieldArray name={fieldName}>
      {({ remove, push }) => (
        <Box>
          <List dense disablePadding>
            {values[fieldName].map((_: any, index: number) => (
              <ListItem
                key={`${index}`}
                disableGutters
                secondaryAction={
                  <IconButton
                    size='small'
                    disabled={values[fieldName].length === 1}
                    onClick={() => remove(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                }
              >
                <IdsFormTextField
                  name={`${fieldName}.${index}`}
                  multiline
                  minRows={1}
                  maxRows={3}
                  label=''
                />
              </ListItem>
            ))}
          </List>

          <Button size='small' variant='text' startIcon={<AddIcon />} onClick={() => push('')}>
            Add Barcode
          </Button>
        </Box>
      )}
    </FieldArray>
  );
};

export default EditableBarcodeAnswer;
