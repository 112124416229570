import React, { useCallback, useMemo, useState } from 'react';
import { ButtonBase } from '@mui/material';

import ImageAnswer, { IImageAnswerProps } from '../../../../../../TaskResponse/Answer/ImageAnswer';
import {
  ImageType,
  MEDIA_TYPE_TO_DEF,
  MediaMetadataType,
  IMediaMetadata,
} from '../../../../../../../../../../../../constants/media';
import { getUrnForMedia } from '../../../../../../../../../../../../utils/media';
import { IInitialValues } from '../../../../../../../../../../../../hooks/uploaders/useSingleLocationImageUploader';
import { useGetNewImageAnswer } from '../../../../../../../../../../../../services/TaskResponsesService';
import { IAssignmentQuestion } from '../../types';

export interface INewImageAnswerProps extends Omit<IImageAnswerProps, 'answer'> {
  imageId: string;
  uuid: string;
  imageType: ImageType;
  question: IAssignmentQuestion;
  openUploader: (initialValues: IInitialValues, uuid: string) => void;
}

const NewImageAnswer: React.FC<INewImageAnswerProps> = ({
  imageId,
  imageType,
  uuid,
  question,
  openUploader,
  ...rest
}) => {
  const [imageKeyCounter, setImageKeyCounter] = useState(0);

  const urn = useMemo(() => {
    return getUrnForMedia(imageId, imageType);
  }, [imageId, imageType]);

  const { data, isLoading } = useGetNewImageAnswer(urn);

  const handleClick = useCallback(() => {
    if (!data) return;

    const { metadata, position } = data.image;
    const { latitude, longitude, altitude, heading } = position;

    const getMetadataValue = (type: MediaMetadataType) =>
      metadata.find((d: Omit<IMediaMetadata, 'value'>) => d.type === type)?.id;

    const getMetadataValues = (type: MediaMetadataType) =>
      metadata
        .filter((d: Omit<IMediaMetadata, 'value'>) => d.type === type)
        .map((d: Omit<IMediaMetadata, 'value'>) => d.id);

    openUploader(
      {
        latitude,
        longitude,
        altitude: altitude !== null ? altitude : undefined,
        heading: heading !== null ? heading : undefined,
        photo_category_id: getMetadataValue(MediaMetadataType.PhotoCategory),
        photo_type_id: getMetadataValue(MediaMetadataType.PhotoType),
        photo_level_id: getMetadataValue(MediaMetadataType.Level),
        photo_area_id: getMetadataValue(MediaMetadataType.Area),
        photo_tags: getMetadataValues(MediaMetadataType.PhotoTag),
      },
      uuid,
    );
  }, [openUploader, data, uuid]);

  const handleImgLoadError = useCallback(() => {
    // sometimes image url fails to load right away
    // probably due to not being fully processed, try again by forcing remount
    // of image answer until loaded.
    // FUTURE TODO: think about identifying a good retry limit here
    setImageKeyCounter(prev => prev + 1);
  }, [setImageKeyCounter]);

  return (
    <ButtonBase onClick={handleClick}>
      <ImageAnswer
        key={imageKeyCounter.toString()}
        answer={{
          uuid,
          answerPhoto: {
            id: imageId,
            reps: data?.image.reps || [],
          },
          answerPhotoType: MEDIA_TYPE_TO_DEF[imageType].imageAnswerType || null,
          questionType: question.questionType,
          questionId: question.id,
        }}
        onError={!isLoading && data ? handleImgLoadError : undefined}
        {...rest}
      />
    </ButtonBase>
  );
};

export default NewImageAnswer;
