import { gql } from 'graphql-request';

import { ImageMarkerFrag } from '../../imageMarkerFrag';
import useOrgGraphQuery from '../../../../../hooks/useOrgGraphQuery';
import { MediaType } from '../../../../../constants/media';
import useCursorPaginatedQuery, {
  IUseCursorPaginatedQueryOptions,
} from '../../../../../hooks/useCursorPaginatedQuery';
import { useLocationKeys } from '../../../../../services/LocationService';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';

import { DEFAULT_TAKE } from '../index';

export const HDPhotosQuery = gql`
  query HDPhotos($orgId: ID, $locationId: ID!, $tenantId: ID, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $locationId, tenantId: $tenantId) {
      hdPhotos(take: $take, after: $after) {
        edges {
          cursor
          node {
            ...ImageMarkerFrag
          }
        }
      }
    }
  }
  ${ImageMarkerFrag}
`;

const useHDPhotos = (onPageLoad: IUseCursorPaginatedQueryOptions<any>['onPageLoad']) => {
  const locationKeys = useLocationKeys();
  const { location } = useLocationMapContext();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [
        ...locationKeys.map(location?.id),
        MediaType.HDPhoto,
        'points',
        `take-${take}`,
        `after-${after}`,
      ],
      HDPhotosQuery,
      {
        locationId: location?.id,
        take,
        after,
      },
    );

  return useCursorPaginatedQuery({
    initialData: location?.hdPhotos?.edges || [],
    useQuery,
    defaultTake: DEFAULT_TAKE,
    selectConnection: d => d.location.hdPhotos,
    onPageLoad,
  });
};

export default useHDPhotos;
