import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Container, Typography } from '@mui/material';

import { useRecoilValue } from 'recoil';

import { gql } from 'graphql-request';

import { activeOrganizationState } from '../../../atoms/organizations';
import { activeLocationState } from '../../../atoms/locations';

import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import Page from '../../../components/Page';
import QueryLoader from '../../../components/QueryLoader';
import useOrgGraphQuery from '../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../services/LocationService';
import globalSx from '../../../theme/globalSx';

import { getOrgLocationRoute, getOrgLocationsRoute } from '../../../utils/routes';

import { activeTenantState } from '../../../atoms/tenants';

import EditLocationForm from './EditLocationForm';

import styles from './LocationEdit.module.css';

const LocationEditQuery = gql`
  query LocationEditQuery($orgId: ID, $id: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      name
      address {
        streetLine1
        city
        state
        postalCode
      }
      yearBuilt
      position {
        latitude
        longitude
      }
      heading
    }
  }
`;

function LocationEdit() {
  const locationKeys = useLocationKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const [location, setLocation] = useState();
  const navigate = useNavigate();

  const useLocation = () =>
    useOrgGraphQuery([...locationKeys.detail(activeLocation.id), 'edit'], LocationEditQuery, {
      id: activeLocation.id,
    });

  const initialFormValues = useMemo(() => {
    if (!location) return {};

    const { name, address, yearBuilt, position, heading } = location;

    return {
      name: name,
      address: address?.streetLine1,
      city: address?.city,
      state: address?.state,
      zip: address?.postalCode,
      yearBuilt: yearBuilt,
      latitude: position?.latitude,
      longitude: position?.longitude,
      heading: heading,
    };
  }, [location]);

  const returnToDetailView = useCallback(() => {
    navigate(
      getOrgLocationRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        locationId: activeLocation.id,
      }),
    );
  }, [navigate, activeOrg.id, activeLocation.id, activeTenant]);

  return (
    <QueryLoader
      useQuery={useLocation}
      selectData={data => data.location}
      setData={setLocation}
      render={() => {
        const { name } = location;

        return (
          <Page title={`Edit ${name}`}>
            <Container maxWidth={false}>
              <NestedOrgViewHeader
                breadcrumbs={[
                  {
                    label: 'Locations',
                    route: getOrgLocationsRoute({
                      subdomain: activeTenant?.subdomain,
                      orgId: activeOrg.id,
                    }),
                  },
                  {
                    label: name,
                    route: getOrgLocationRoute({
                      subdomain: activeTenant?.subdomain,
                      orgId: activeOrg.id,
                      locationId: activeLocation.id,
                    }),
                  },
                ]}
                currentCrumb='Edit'
              />
              <Card sx={globalSx.pageContent} className={styles.contentContainer}>
                <Typography variant='h4' className={styles.title}>
                  Edit {name}
                </Typography>
                <EditLocationForm
                  initialValues={initialFormValues}
                  onSubmitSuccess={returnToDetailView}
                  onCancel={returnToDetailView}
                />
              </Card>
            </Container>
          </Page>
        );
      }}
    />
  );
}

export default LocationEdit;
