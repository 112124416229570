import React, { useCallback, useState } from 'react';

import IdsListItemMenu from '../../../../../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem, {
  IIdsListItemMenuItemProps,
} from '../../../../../../../components/ids-lists/IdsListItemMenuItem';

import {
  ContentCopyIcon,
  DeleteOutlineIcon,
  EditOutlineIcon,
} from '../../../../../../../theme/icons';

import { IProjectRouteListItemData } from '../../../../../../../services/fragments';

import { useDeleteRouteAction, useCanDeleteRoute } from '../hooks';
import { UpdateAction } from '../index';

import CopyRouteDialog from './CopyRouteDialog';
import EditRouteDialog from './EditRouteDialog';

export interface IRouteListItemMenuProps {
  item: IProjectRouteListItemData;
  setIsUpdating: (action: UpdateAction) => void;
}

const RouteListItemMenu: React.FC<IRouteListItemMenuProps> = ({ item, setIsUpdating }) => {
  const canDeleteRoute = useCanDeleteRoute();

  const { handleDeleteRoute, deleting, deleted } = useDeleteRouteAction(item);
  const onClickDelete = useCallback(async () => {
    setIsUpdating('delete');

    await handleDeleteRoute(() => setIsUpdating(null));
  }, [handleDeleteRoute, setIsUpdating]);

  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const onOpenCopyDialog: IIdsListItemMenuItemProps['onClick'] = useCallback(
    event => {
      /**
       * This will prevent Menu component from being closed.
       * Menu is auto closed after clicking one of its items.
       */
      event.stopPropagation();

      setIsCopyDialogOpen(true);
      setIsUpdating(true);
    },
    [setIsUpdating],
  );

  const onOpenEditDialog: IIdsListItemMenuItemProps['onClick'] = useCallback(
    event => {
      /**
       * This will prevent Menu component from being closed.
       * Menu is auto closed after clicking one of its items.
       */
      event.stopPropagation();

      setIsEditDialogOpen(true);
      setIsUpdating(true);
    },
    [setIsUpdating],
  );

  const onCloseCopyDialog = useCallback(() => {
    setIsCopyDialogOpen(false);
    setIsUpdating(null);
  }, [setIsUpdating]);

  const onCloseEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
    setIsUpdating(null);
  }, [setIsUpdating]);

  return (
    /**
     * The menu must be kept opened if a dialog is active.
     */
    <IdsListItemMenu open={isCopyDialogOpen || isEditDialogOpen}>
      <IdsListItemMenuItem
        icon={<EditOutlineIcon />}
        disabled={deleting || deleted}
        onClick={onOpenEditDialog}
      >
        Edit Details
      </IdsListItemMenuItem>
      <IdsListItemMenuItem
        icon={<ContentCopyIcon />}
        disabled={deleting || deleted}
        onClick={onOpenCopyDialog}
      >
        Copy
      </IdsListItemMenuItem>
      {canDeleteRoute && (
        <IdsListItemMenuItem
          icon={<DeleteOutlineIcon />}
          disabled={deleting || deleted}
          onClick={onClickDelete}
        >
          Delete
        </IdsListItemMenuItem>
      )}

      <CopyRouteDialog open={isCopyDialogOpen} onClose={onCloseCopyDialog} item={item} />
      <EditRouteDialog open={isEditDialogOpen} onClose={onCloseEditDialog} item={item} />
    </IdsListItemMenu>
  );
};

export default RouteListItemMenu;
