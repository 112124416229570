import React from 'react';
import { Button, ButtonProps } from '@mui/material';

import IdsFormElement from '../IdsFormElement';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsSubmitButtonProps extends ButtonProps {
  /** Disables the submit button if form fields are not dirty. */
  disableWhileClean?: boolean;
}

const IdsSubmitButton: React.FC<IIdsSubmitButtonProps> = ({
  children,
  disabled,
  disableWhileClean,
  ...props
}) => {
  return (
    <IdsFormElement
      render={({ isSubmitting, dirty }) => (
        <Button
          type='submit'
          disabled={isSubmitting || disabled || (disableWhileClean && !dirty)}
          {...props}
        >
          {children}
        </Button>
      )}
    />
  );
};

export default IdsSubmitButton;
