import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { ImageType, IMetadataType, MediaType } from '../../../../constants/media';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';
import useMetadataItems from '../../../../hooks/useMetadataItems';
import IdsFormAutocompleteField from '../../IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../IdsFormAutocompleteField/SingleValueAutocompleteOption';
import IdsFormTextField from '../../IdsFormTextField';
import usePermissions from '../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../constants/users';
import IdsFormToggleButtonsField from '../../common/IdsFormToggleButtons';
import { FlagIcon, NotFlaggedIcon } from '../../../../theme/icons';
import { IToggleProps } from '../../../ids-inputs/IdsToggleButtons';
import { AllFormValues } from '../types';

const tryGetNumberFieldVal = (value?: string | null) => {
  if (value === null || value === undefined || typeof value === 'number') {
    return value;
  }

  const numVal = parseInt(value); // must be an integer
  return isNaN(numVal) ? null : numVal;
};

/** When this option is selected, the field value will be changed to null. */
// export const SET_TO_NONE_OPTION = {
//   name: 'Set to None',
//   id: 'set_to_none',
// };

const flagToggleProps: IToggleProps = {
  label: 'Flag',
  activeIcon: <FlagIcon color='error' />,
  inactiveIcon: <FlagIcon color='inherit' />,
};
const unflagToggleProps: IToggleProps = { label: 'Unflag', activeIcon: <NotFlaggedIcon /> };

export interface IBulkLocationImageEditFormFieldsProps {
  metadataTypes: IMetadataType[];
  imageTypesEditing: Record<ImageType, boolean>;
}

const BulkLocationImageEditFormFields: React.FC<IBulkLocationImageEditFormFieldsProps> = ({
  metadataTypes,
  imageTypesEditing,
}) => {
  const { allLevels, allAreas, allCategories, allTypes } = useMetadataItems(metadataTypes);
  const { userHasOneOfRoles } = usePermissions();
  const { isSubmitting } = useFormikContext<AllFormValues>();

  const onlyEditingProjectPhotos = useMemo(() => {
    return (
      imageTypesEditing[MediaType.ProjectPhoto] &&
      !imageTypesEditing[MediaType.HDPhoto] &&
      !imageTypesEditing[MediaType.PanoramicPhoto]
    );
  }, [imageTypesEditing]);

  const onlyEditingPanoramas = useMemo(() => {
    return (
      !imageTypesEditing[MediaType.ProjectPhoto] &&
      !imageTypesEditing[MediaType.HDPhoto] &&
      imageTypesEditing[MediaType.PanoramicPhoto]
    );
  }, [imageTypesEditing]);

  const publishingSupported = useMemo(() => {
    const imageTypesSupported =
      !imageTypesEditing[MediaType.ProjectPhoto] &&
      (imageTypesEditing[MediaType.HDPhoto] || imageTypesEditing[MediaType.PanoramicPhoto]);

    return (
      imageTypesSupported &&
      userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ])
    );
  }, [imageTypesEditing, userHasOneOfRoles]);

  const areaOptions = useMemo(() => {
    return allAreas;
    // TODO: uncomment to support clearing area value (once BE supports it)
    // return [{ name: 'Set to None', id: 'set-to-none' }, ...allAreas];
  }, [allAreas]);

  return (
    <Stack direction='column' spacing={2}>
      {onlyEditingPanoramas && (
        <IdsFormTextField
          name='heading'
          label='Heading'
          transformBlurValue={tryGetNumberFieldVal}
          disabled={isSubmitting}
        />
      )}

      {onlyEditingProjectPhotos && (
        <>
          <IdsFormAutocompleteField
            name={MediaMetadataType.PhotoType}
            label={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].label}
            options={allTypes}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsText='No photo types found'
            renderOption={(props, o) => (
              <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
            )}
            disabled={isSubmitting}
          />
          <IdsFormAutocompleteField
            name={MediaMetadataType.PhotoCategory}
            label={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].label}
            options={allCategories}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsText='No photo categories found'
            renderOption={(props, o) => (
              <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
            )}
            disabled={isSubmitting}
          />
        </>
      )}

      <IdsFormAutocompleteField
        name={MediaMetadataType.Level}
        label={MEDIA_METADATA_TYPES[MediaMetadataType.Level].label}
        options={allLevels}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No levels found'
        renderOption={(props, o) => (
          <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
        )}
        disabled={isSubmitting}
      />
      <IdsFormAutocompleteField
        name={MediaMetadataType.Area}
        label={MEDIA_METADATA_TYPES[MediaMetadataType.Area].label}
        options={areaOptions}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No areas found'
        renderOption={(props, o) => (
          <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
        )}
        disabled={isSubmitting}
      />

      {publishingSupported && (
        <Stack direction='column'>
          <Typography variant='h6'>Visibility Settings</Typography>
          <IdsFormToggleButtonsField
            name='published'
            toggleOnProps={{ label: 'Publish' }}
            toggleOffProps={{ label: 'Unpublish' }}
            fullWidth
            size='small'
            disabled={isSubmitting}
          />
        </Stack>
      )}

      <Stack direction='column'>
        <Typography variant='h6'>Flagging Settings</Typography>
        <IdsFormToggleButtonsField
          name='flagged'
          toggleOnProps={flagToggleProps}
          toggleOffProps={unflagToggleProps}
          fullWidth
          size='small'
          disabled={isSubmitting}
        />
      </Stack>
    </Stack>
  );
};

export default BulkLocationImageEditFormFields;
