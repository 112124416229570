import { IAssignableCustomField, ICustomFieldMetadata } from '../../../constants/customFields';
import {
  IMediaMetadata,
  IMediaMetadataTypeDef,
  IMetadataTypeValue,
  MEDIA_TYPES,
  MediaType,
} from '../../../constants/media';

import { ISorter } from './types';

export const dateAscendingSorter: ISorter = {
  key: 'date_asc',
  label: 'Date Ascending',
  selectGroupingKey: media => new Date(media.node.capturedAt).toLocaleDateString(),
  resolveGroupLabel: groupKey => groupKey,
  sortGroups: (groupA, groupB) =>
    new Date(groupA.key).getMilliseconds() - new Date(groupB.key).getMilliseconds(),
  sortGroupMedia: (mediaA, mediaB) =>
    new Date(mediaA.node.capturedAt).getMilliseconds() -
    new Date(mediaB.node.capturedAt).getMilliseconds(),
  isDefault: false,
};

export const dateDescendingSorter: ISorter = {
  key: 'date_desc',
  label: 'Date Descending',
  selectGroupingKey: media => new Date(media.node.capturedAt).toLocaleDateString(),
  resolveGroupLabel: groupKey => groupKey,
  sortGroups: (groupA, groupB) =>
    new Date(groupB.key).getMilliseconds() - new Date(groupA.key).getMilliseconds(),
  sortGroupMedia: (mediaA, mediaB) =>
    new Date(mediaB.node.capturedAt).getMilliseconds() -
    new Date(mediaA.node.capturedAt).getMilliseconds(),
  isDefault: false,
};

export const typeSorter: ISorter = {
  key: 'mediaType',
  label: 'Media Type',
  selectGroupingKey: media => media.type,
  resolveGroupLabel: groupKey => {
    const typeLabels = {
      [MEDIA_TYPES[MediaType.ProjectPhoto].type]: MEDIA_TYPES[MediaType.ProjectPhoto].label,
      [MEDIA_TYPES[MediaType.HDPhoto].type]: MEDIA_TYPES[MediaType.HDPhoto].label,
      [MEDIA_TYPES[MediaType.PanoramicPhoto].type]: MEDIA_TYPES[MediaType.PanoramicPhoto].label,
    };
    return typeLabels[groupKey];
  },
  sortGroups: (groupA, groupB) => {
    const typeSortingOrders = {
      [MEDIA_TYPES[MediaType.ProjectPhoto].type]: 1,
      [MEDIA_TYPES[MediaType.HDPhoto].type]: 2,
      [MEDIA_TYPES[MediaType.PanoramicPhoto].type]: 3,
    };
    return typeSortingOrders[groupA.key] - typeSortingOrders[groupB.key];
  },
  sortGroupMedia: (mediaA, mediaB) =>
    new Date(mediaA.node.capturedAt).getMilliseconds() -
    new Date(mediaB.node.capturedAt).getMilliseconds(),
  isDefault: true,
};

export const getMetadataTypeSorter = (
  metadataTypeDef: IMediaMetadataTypeDef,
  allMetadataItems: IMetadataTypeValue[],
): ISorter => {
  const noValueMsg = `No ${metadataTypeDef.label}`;

  return {
    key: metadataTypeDef.type,
    label: metadataTypeDef.label,
    selectGroupingKey: media =>
      media.node.metadata.find((d: IMediaMetadata) => d.type === metadataTypeDef.type)?.id,
    resolveGroupLabel: groupKey =>
      groupKey ? allMetadataItems.find(l => l.id === groupKey)?.name || noValueMsg : noValueMsg,
    sortGroups: (groupA, groupB) => {
      const groupAIndex = allMetadataItems.findIndex(l => l.id === groupA.key);
      const groupBIndex = allMetadataItems.findIndex(l => l.id === groupB.key);
      // Sort any media that doesn't have an area to the end
      return groupAIndex < 0 ? 1 : groupBIndex < 0 ? -1 : groupAIndex - groupBIndex;
    },
    sortGroupMedia: (mediaA, mediaB) =>
      new Date(mediaA.node.capturedAt).getMilliseconds() -
      new Date(mediaB.node.capturedAt).getMilliseconds(),
    isDefault: false,
  };
};

export const getCustomFieldMetadataTypeSorter = (
  assignableCustomField: IAssignableCustomField,
): ISorter => {
  const noValueMsg = `No ${assignableCustomField.label}`;

  return {
    key: assignableCustomField.type,
    label: assignableCustomField.label,
    selectGroupingKey: media =>
      media.node.customFieldMetadata.find(
        (d: ICustomFieldMetadata) => d.type === assignableCustomField.type,
      )?.id,
    resolveGroupLabel: groupKey =>
      groupKey
        ? assignableCustomField.values.find(l => l.id === groupKey)?.name || noValueMsg
        : noValueMsg,
    sortGroups: (groupA, groupB) => {
      const groupAIndex = assignableCustomField.values.findIndex(l => l.id === groupA.key);
      const groupBIndex = assignableCustomField.values.findIndex(l => l.id === groupB.key);
      // Sort any media that doesn't have an area to the end
      return groupAIndex < 0 ? 1 : groupBIndex < 0 ? -1 : groupAIndex - groupBIndex;
    },
    sortGroupMedia: (mediaA, mediaB) =>
      new Date(mediaA.node.capturedAt).getMilliseconds() -
      new Date(mediaB.node.capturedAt).getMilliseconds(),
    isDefault: false,
  };
};
