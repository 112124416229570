import { useMemo } from 'react';

import { IIdsFormAutocompleteFieldProps } from '../../IdsFormAutocompleteField';
import MultiValueAutocompleteOption from '../../IdsFormAutocompleteField/MultiValueAutocompleteOption';
import SingleValueAutocompleteOption from '../../IdsFormAutocompleteField/SingleValueAutocompleteOption';

export const multiValueOption: IIdsFormAutocompleteFieldProps['renderOption'] = (
  props,
  o,
  { selected },
) => {
  return (
    <MultiValueAutocompleteOption
      label={o.node.name}
      selected={selected}
      {...props}
      key={o.node.id}
    />
  );
};

export const singleValueOption: IIdsFormAutocompleteFieldProps['renderOption'] = (props, o) => {
  return <SingleValueAutocompleteOption label={o.node.name} {...props} key={o.node.id} />;
};

export const useRenderOption = (multiple: boolean) => {
  return useMemo<IIdsFormAutocompleteFieldProps['renderOption']>(() => {
    if (multiple) {
      return multiValueOption;
    }

    return singleValueOption;
  }, [multiple]);
};

export const getNodeOptionName: IIdsFormAutocompleteFieldProps['getOptionLabel'] = option =>
  option.node.name;
export const getNodeOptionTitle: IIdsFormAutocompleteFieldProps['getOptionLabel'] = option =>
  option.node.title;
export const getNodeOptionValue: IIdsFormAutocompleteFieldProps['getOptionValue'] = option =>
  option.node.id;
