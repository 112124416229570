import React from 'react';

import { gql } from 'graphql-request';

import { useRecoilState } from 'recoil';

import { useOrganizationKeys } from '../../services/OrganizationsService';
import useGraphQuery from '../../hooks/useGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';

import LoadingScreen from '../LoadingScreen';

import EntityRoute from './EntityRoute';

export const OrgRouteQuery = gql`
  query Organizations($id: ID!) {
    organization(id: $id) {
      name
      id
      isActive
      myUserRole
      myPermissions
      logo {
        url
      }
    }
  }
`;

function OrgRoute() {
  const organizationKeys = useOrganizationKeys();

  const [organization, setOrganization] = useRecoilState(activeOrganizationState);

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useOrg = (id, options) =>
    useGraphQuery(organizationKeys.route(id), OrgRouteQuery, { id }, options);

  return (
    <EntityRoute
      idParam={'orgId'}
      currentEntity={organization}
      setEntity={setOrganization}
      useEntity={useOrg}
      entitySelector={data => data?.organization}
      renderLoading={() => <LoadingScreen fullscreen={true} />}
    />
  );
}

export default OrgRoute;
