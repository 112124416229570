import { useSnackbar } from 'notistack';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  CircularProgress,
  Backdrop,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Skeleton,
  Tooltip,
  ButtonBase,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { gql } from 'graphql-request';
import { capitalCase } from 'change-case';

import ActiveOrgLogo from '../../../../components/ActiveOrgLogo';
import IdsForm from '../../../../components/ids-forms/IdsForm';
import IdsFormSwitchField from '../../../../components/ids-forms/IdsFormSwitchField';
import IdsFormSelectField from '../../../../components/ids-forms/IdsFormSelectField';
import IdsDialogUploader from '../../../../components/ids-inputs/uploaders/IdsDialogUploader';
import { useIdsUploaderUppy } from '../../../../components/ids-inputs/uploaders/IdsUploader';
import { UploadType } from '../../../../constants/uploads';
import { USER_ROLES } from '../../../../constants/users';
import useGraphQuery from '../../../../hooks/useGraphQuery';
import usePermissions from '../../../../hooks/usePermissions';
import { useOrganizationKeys } from '../../../../services/OrganizationsService';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { useUpdateOrganization } from '../../../../services/OrganizationsService';
import { Buttons } from '../../../../components/ids-forms/IdsEditSettingsFormButtons';
import { BlurImageOptions } from '../../types';
import { HelpOutlineIcon } from '../../../../theme/icons';

import styles from './OrgInfoTab.module.css';
const OrgDetailInfoTabQuery = gql`
  query OrganizationDetails($id: ID!) {
    organization(id: $id) {
      isNadirEnabled
      isCaptureEnabled
      projectPhotosPublic
      blurImages
    }
  }
`;

const BLUR_IMAGE_OPTIONS = Object.entries(BlurImageOptions).map(([key, value]) => ({
  label: capitalCase(key),
  value,
}));

const blurImageDescription = (
  <span style={{ whiteSpace: 'pre-line' }}>
    <b>Off:</b> Faces will not be blurred in images <br /> <br />
    <b>Partial:</b> Faces will be blurred in images, but if blurring fails for any reason, the image
    will still be uploaded <br /> <br />
    <b>Strict:</b> Faces will be blurred in all images and will not be uploaded if blurring fails
    for any reason
  </span>
);

const orgLogoUploaderId = 'OrgLogoUploader';

const OrgInfoTab = () => {
  const { uppy } = useIdsUploaderUppy(orgLogoUploaderId);
  const [logoUploaderOpen, setLogoUploaderOpen] = useState(false);
  const toggleLogoUploader = useCallback(() => {
    setLogoUploaderOpen(prev => !prev);
  }, []);

  const organizationKeys = useOrganizationKeys();

  const activeOrg = useRecoilValue(activeOrganizationState);
  const { userHasOneOfRoles } = usePermissions();
  const canUpdate = useMemo(
    () => userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN, USER_ROLES.ORG_ADMIN]),
    [userHasOneOfRoles],
  );

  const { data, isLoading, error } = useGraphQuery(
    [...organizationKeys.detail(activeOrg.id), 'infoTab'],
    OrgDetailInfoTabQuery,
    { id: activeOrg.id },
  );

  const org = useMemo(
    () => (!isLoading && !error ? { ...activeOrg, ...data.organization } : null),
    [activeOrg, data, isLoading, error],
  );

  const [isEditMode, setEditMode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const updateOrganizationMutation = useUpdateOrganization(activeOrg.id);

  const [isUpdating, setIsUpdating] = useState(false);
  const onSubmitHandler = async (values, formikHelpers) => {
    setIsUpdating(true);

    const { logo, ...allExceptLogo } = values;

    const result = await updateOrganizationMutation.mutateAsync({
      organizationId: activeOrg.id,
      ...allExceptLogo,
    });

    if (result.updateOrganization.errors) {
      enqueueSnackbar(result.updateOrganization.errors[0].message || 'Something went wrong', {
        variant: 'error',
      });

      formikHelpers.resetForm();
    } else {
      setInitialValues(values);
    }

    setIsUpdating(false);
  };

  const [initialValues, setInitialValues] = useState(() => ({
    blurImages: BlurImageOptions.Off,
    nadirEnabled: false,
    projectPhotosPublic: false,
    captureEnabled: false,
    logo: '',
  }));

  useEffect(() => {
    if (!isLoading && org) {
      setInitialValues({
        blurImages: org.blurImages,
        nadirEnabled: org.isNadirEnabled,
        projectPhotosPublic: org.projectPhotosPublic,
        captureEnabled: org.isCaptureEnabled,
        logo: org.logo?.url,
      });
    }
  }, [isLoading, org]);

  if (isLoading) {
    return (
      <Box display='flex' flexDirection='column' gap={1} p={2}>
        <Box display='flex' flexDirection='row' gap={1} py={1}>
          <Skeleton variant='rectangular' width='100%' />
          <Skeleton variant='rectangular' width='100%' />
        </Box>
        <Box display='flex' flexDirection='row' gap={1} py={1}>
          <Skeleton variant='rectangular' width='100%' />
          <Skeleton variant='rectangular' width='100%' />
        </Box>
        <Box display='flex' flexDirection='row' gap={1} py={1}>
          <Skeleton variant='rectangular' width='100%' />
          <Skeleton variant='rectangular' width='100%' />
        </Box>
        <Box display='flex' flexDirection='row' gap={1} py={1}>
          <Skeleton variant='rectangular' width='100%' />
          <Skeleton variant='rectangular' width='100%' />
        </Box>
      </Box>
    );
  }

  const logo = <ActiveOrgLogo fallbackLogo='dark' width={75} height={75} />;

  return (
    <Box position='relative'>
      <IdsForm
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        errorHandler={(errorMessage, formikHelpers) => {
          formikHelpers.resetForm();

          return errorMessage;
        }}
        successMessage='Organization settings were updated.'
      >
        <Backdrop
          className={styles.backdrop}
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isUpdating}
        >
          <CircularProgress />
        </Backdrop>
        <Box display='flex' flexDirection='column' gap={1} p={2}>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Logo
                  </TableCell>
                  <TableCell align='left'>
                    <div className={styles.logo}>
                      <Tooltip title='Edit' arrow>
                        <ButtonBase onClick={toggleLogoUploader}>{logo}</ButtonBase>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Capture
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSwitchField
                      name='captureEnabled'
                      disabled={!isEditMode || isLoading || isUpdating}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Project Photos Public
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSwitchField
                      name='projectPhotosPublic'
                      disabled={!isEditMode || isLoading || isUpdating}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Nadir over 360 images
                  </TableCell>
                  <TableCell align='left'>
                    <IdsFormSwitchField
                      name='nadirEnabled'
                      disabled={!isEditMode || isLoading || isUpdating}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Blur faces in images
                    <Tooltip title={blurImageDescription} placement='right'>
                      <HelpOutlineIcon className={styles.helpIcon} />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IdsFormSelectField
                      name='blurImages'
                      margin='none'
                      options={BLUR_IMAGE_OPTIONS}
                      size='small'
                      disabled={!isEditMode || isLoading || isUpdating}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Buttons
            isEditMode={isEditMode}
            isLoading={isLoading}
            canUpdate={canUpdate}
            isUpdating={isUpdating}
            setEditMode={setEditMode}
          />
        </Box>
      </IdsForm>
      <IdsDialogUploader
        uppy={uppy}
        supportedUploaders={{
          [UploadType.OrgLogo]: {
            orgId: activeOrg.id,
          },
        }}
        fileSelectionStepLabel='Select organization logo'
        dialogProps={{
          title: 'Upload Organization Logo',
          open: logoUploaderOpen,
          onClose: toggleLogoUploader,
          noSearchParam: true,
        }}
      />
    </Box>
  );
};

export default OrgInfoTab;
