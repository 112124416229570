import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import FilledIconButton from '../../../ids-buttons/FilledIconButton';
import {
  CloseIcon,
  DeleteOutlineIcon,
  EditOutlineIcon,
  FlagIcon,
  FlagOutlinedIcon,
  SaveIcon,
} from '../../../../theme/icons';
import { IIdsMapEntityViewerProps } from '../index';
import {
  useDeletePointOfInterest,
  useUpdatePointOfInterest,
} from '../../../../services/PointOfInterestService';
import useDeleteEntity from '../../../../hooks/useDeleteEntity';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { MEDIA_TYPES, MediaType } from '../../../../constants/media';
import useFilterContext from '../../../../hooks/useFilterContext';
import useMapEntityType, { MapEntityType } from '../useMapEntityType';
import useCanModifyEntity from '../useCanModifyEntity';

export interface IActionsProps extends IIdsMapEntityViewerProps {
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  isEditMode: boolean;

  saving: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

const sx = {
  flagIcon: (theme: any) => ({
    color: theme.palette.ids.marker.markerDotFlagged,
  }),
};

export const getUpdatedEntityResult = (entityType: MapEntityType, mutationResult: any) => {
  if (entityType === MediaType.PointOfInterest) {
    return mutationResult.updatePointOfInterest.pointOfInterest;
  }

  if (entityType === MediaType.Asset) {
    return mutationResult.updateAsset.asset;
  }

  throw new Error(`${entityType} not supported yet`);
};

export const ENTITY_UPDATE_MUTATION = {
  [MediaType.PointOfInterest]: useUpdatePointOfInterest,
  [MediaType.Asset]: () => ({ mutateAsync: () => ({}) }),
};

const ENTITY_DELETE_MUTATION = {
  [MediaType.PointOfInterest]: useDeletePointOfInterest,
  [MediaType.Asset]: () => ({ mutateAsync: () => ({}) }),
};

const Actions: React.FC<IActionsProps> = ({
  onClose,
  entity,
  locationId,
  setIsEditMode,
  isEditMode,
  saving,
  setSaving,
}) => {
  const entityType = useMapEntityType(entity.node.id);
  // @TODO: remove this constraint once mutations for Assets are implemented.
  const isDisabled = entityType === MediaType.Asset;

  const { submitForm, dirty } = useFormikContext();

  const activeOrg = useRecoilValue(activeOrganizationState);

  const canModify = useCanModifyEntity(entity.node.creator.id);

  const updateEntityMutation = ENTITY_UPDATE_MUTATION[entityType](locationId);
  const deleteEntityMutation = ENTITY_DELETE_MUTATION[entityType](locationId);
  const { deleting, handleDelete } = useDeleteEntity();
  const { setTypeItem, deleteTypeItem } = useFilterContext();

  const toggleFlagged = useCallback(async () => {
    setSaving(true);

    const mutationResult = await updateEntityMutation.mutateAsync({
      organizationId: activeOrg.id,
      id: entity.node.id,
      flagged: !entity.node.flagged,
    });

    const updatedEntity = getUpdatedEntityResult(entityType, mutationResult);
    const newItem = {
      cursor: entity.cursor,
      tooltip: updatedEntity.name,
      node: updatedEntity,
    };

    setTypeItem(newItem, entityType);

    setSaving(false);
  }, [setTypeItem, entity, activeOrg.id, entityType, updateEntityMutation, setSaving]);

  const deleteHandler = useCallback(async () => {
    setSaving(true);

    await handleDelete(
      async () => {
        await deleteEntityMutation.mutateAsync({
          organizationId: activeOrg.id,
          id: entity.node.id,
        });
      },
      entity.node.name,
      `This ${MEDIA_TYPES[entityType].singularLabelLowerCase} will be deleted.`,
      MEDIA_TYPES[entityType].singularLabel,
      () => {
        deleteTypeItem(entity.node.id, entityType);
        onClose(true);
      },
      () => setSaving(false),
    );
  }, [
    activeOrg.id,
    entity,
    entityType,
    onClose,
    deleteTypeItem,
    deleteEntityMutation,
    handleDelete,
    setSaving,
  ]);

  const toggleEditMode = useCallback(async () => {
    setIsEditMode(!isEditMode);

    /**
     * Submit the form if there are any changes.
     */
    if (isEditMode && dirty) {
      await submitForm();
    }
  }, [setIsEditMode, isEditMode, dirty, submitForm]);

  return (
    <>
      {/* @TODO: remove this condition when Asset mutations are implemented */}
      {!isDisabled && (
        <>
          {/* Delete */}
          {canModify && (
            <FilledIconButton
              disabled={isEditMode || deleting || saving}
              onClick={deleteHandler}
              size='small'
            >
              <DeleteOutlineIcon />
            </FilledIconButton>
          )}

          {/* Flag */}
          {canModify && (
            <FilledIconButton disabled={deleting || saving} onClick={toggleFlagged} size='small'>
              {entity.node.flagged ? (
                <FlagIcon sx={sx.flagIcon} />
              ) : (
                <FlagOutlinedIcon sx={sx.flagIcon} />
              )}
            </FilledIconButton>
          )}

          {/* Edit mode */}
          {canModify && (
            <FilledIconButton disabled={deleting || saving} onClick={toggleEditMode} size='small'>
              {isEditMode ? <SaveIcon /> : <EditOutlineIcon />}
            </FilledIconButton>
          )}
        </>
      )}

      {/* Close */}
      {/* Hide this button if the entity has images */}
      {/* Because close functionality will be handled from the active image */}
      {!entity.node.images?.length && (
        <FilledIconButton
          disabled={deleting || saving}
          size='small'
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon />
        </FilledIconButton>
      )}
    </>
  );
};

export default Actions;
