import React from 'react';

import IdsSearchableList, {
  FILTER_MODES,
} from '../../../../components/ids-lists/IdsSearchableList';
import { ASSIGNMENT_RESPONSE_STATUSES } from '../../../../utils/assignments';

import AssignmentResponseListItem from './AssignmentResponseListItem';

const searchResponseItem = (responseItem, query) => {
  const { node: response } = responseItem;
  const searchProps = [
    response.id.toString(),
    response.projectId.toString(),
    response.status,
    response.project.location.name,
    ...(response.createdAt ? [new Date(response.createdAt).toLocaleString()] : []),
    ...(response.updatedAt ? [new Date(response.updatedAt).toLocaleString()] : []),
    ...(response.startsAt ? [new Date(response.startsAt).toLocaleString()] : []),
    ...(response.endsAt ? [new Date(response.endsAt).toLocaleString()] : []),
  ];

  return !!searchProps.filter(prop => prop?.toLowerCase().includes(query.toLowerCase())).length;
};

const sortResponseItems = (a, b) => {
  return b.node.id - a.node.id; // Reverse order of creation
};

const assignmentResponseFilters = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
  {
    key: 'not_started',
    label: 'Not Started',
    filterItem: responseItem =>
      responseItem.node.status === ASSIGNMENT_RESPONSE_STATUSES.NOT_STARTED,
  },
  {
    key: 'in_progress',
    label: 'In Progress',
    filterItem: responseItem =>
      responseItem.node.status === ASSIGNMENT_RESPONSE_STATUSES.IN_PROGRESS,
  },
  {
    key: 'completed',
    label: 'Completed',
    filterItem: responseItem => responseItem.node.status === ASSIGNMENT_RESPONSE_STATUSES.COMPLETED,
  },
  {
    key: 'released',
    label: 'Released',
    filterItem: responseItem => responseItem.node.status === ASSIGNMENT_RESPONSE_STATUSES.RELEASED,
  },
];

function Responses({ useGetAssignmentResponses, getTo }) {
  const { data: assignmentResponses, error, isLoading } = useGetAssignmentResponses();

  return (
    <IdsSearchableList
      items={assignmentResponses}
      renderItem={responseItem => (
        <AssignmentResponseListItem
          key={responseItem.node.id}
          assignmentResponseItem={responseItem.node}
          to={getTo(responseItem.node.id)}
        />
      )}
      searchPlaceholder={'Search responses'}
      searchItem={searchResponseItem}
      sortItems={sortResponseItems}
      filters={assignmentResponseFilters}
      filterMode={FILTER_MODES.SELECT}
      noItemsMessage='No assignment responses'
      noItemsForFilterMessage='No assignment responses match your search'
      loading={isLoading}
      error={error && 'Assignment responses could not be loaded'}
    />
  );
}

export default Responses;
