import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import MultiValueAutocompleteOption from '../../../ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';
import SingleValueAutocompleteOption from '../../../ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import IdsFormTextField from '../../../ids-forms/IdsFormTextField';
import IdsFormAutocompleteField from '../../../ids-forms/IdsFormAutocompleteField';
import IdsFormColorField from '../../../ids-forms/IdsFormColorField';
import IdsFormCheckboxField from '../../../ids-forms/IdsFormCheckboxField';
import useLocationMapContext from '../../../../hooks/useLocationMapContext';
import useLocationMapMetadataOptions from '../../../../hooks/useLocationMapMetadataOptions';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';

const sx = {
  flaggedCheckbox: {
    color: 'ids.marker.markerDotFlagged',
    '&.Mui-checked': { color: 'ids.marker.markerDotFlagged' },
  },
};

const EntityOfInterestForm = ({
  onColorChange,
  onFlaggedChange,
  nameProps,
  projectProps,
  levelProps,
  dataProps,
  colorProps,
  flaggedProps,
  tagsProps,
}) => {
  const { location } = useLocationMapContext();
  const projectOptions = useMemo(() => location?.projects?.edges || [], [location]);
  const levelOptions = useLocationMapMetadataOptions(
    MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
  );
  const tagOptions = useLocationMapMetadataOptions(
    MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
  );

  const handleFlaggedChange = useCallback(
    event => {
      if (onFlaggedChange) {
        onFlaggedChange(event.target.checked);
      }
    },
    [onFlaggedChange],
  );

  return (
    <Stack direction='column' spacing={2}>
      <IdsFormTextField name='name' label='Name' required autoFocus {...nameProps} />
      <IdsFormAutocompleteField
        name='projectId'
        label='Project'
        required
        options={projectOptions}
        getOptionLabel={o => o.node.name}
        getOptionValue={o => o.node.id}
        noOptionsText='No projects found'
        renderOption={(props, o) => (
          <SingleValueAutocompleteOption label={o.node.name} {...props} key={o.node.id} />
        )}
        {...projectProps}
      />
      <IdsFormAutocompleteField
        name='levelId'
        label='Level'
        required
        options={levelOptions}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No levels found'
        renderOption={(props, o) => (
          <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
        )}
        {...levelProps}
      />
      <IdsFormTextField
        name='data'
        label='Information'
        required
        multiline
        minRows={4}
        maxRows={4}
        {...dataProps}
      />
      <IdsFormColorField
        name='color'
        label='Color'
        required
        onChange={onColorChange}
        {...colorProps}
      />
      <IdsFormCheckboxField
        name='flagged'
        label='Flagged'
        onChange={handleFlaggedChange}
        sx={sx.flaggedCheckbox}
        {...flaggedProps}
      />
      <IdsFormAutocompleteField
        name='tags'
        label='Tags'
        options={tagOptions}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No tags found'
        renderOption={(props, o, { selected }) => (
          <MultiValueAutocompleteOption label={o.name} selected={selected} {...props} key={o.id} />
        )}
        disableCloseOnSelect
        multiple
        limitTags={2}
        {...tagsProps}
      />
    </Stack>
  );
};

EntityOfInterestForm.propTypes = {
  onColorChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
};

export default EntityOfInterestForm;
