import { gql } from 'graphql-request';
import { useMutation } from 'react-query';

import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import queryClient from '../utils/query';
import { ImageType } from '../constants/media';

import {
  ICustomFieldMetadataItemInput,
  IWhereOrganizationInput,
  IWhereUniqueIdOrganizationInput,
  MediaMetadata,
  UserError,
} from './types';

export interface ICreateMediaCommentInput {
  entityUrn: string;
  parentCommentId?: string;
  text: string;
}

const CreateMediaCommentMutation = gql`
  mutation CreateMediaComment($where: WhereOrganizationInput!, $input: CreateMediaCommentInput!) {
    createMediaComment(where: $where, input: $input) {
      comment {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export interface IUpdateMediaCommentInput {
  text: string;
}

const UpdateMediaCommentMutation = gql`
  mutation UpdateMediaComment(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateMediaCommentInput!
  ) {
    updateMediaComment(where: $where, input: $input) {
      comment {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

const DeleteMediaCommentMutation = gql`
  mutation DeleteMediaComment($where: WhereUniqueIdOrganizationInput!) {
    deleteMediaComment(where: $where) {
      errors {
        field
        message
      }
      success
    }
  }
`;

export interface IDeleteMediaCommentResult {
  deleteMediaComment: {
    success: boolean;
    errors: {
      field: string;
      message: string;
    }[];
  };
}

const createMediaComment = ({
  organizationId,
  entityUrn,
  parentCommentId,
  text,
}: IWhereOrganizationInput & ICreateMediaCommentInput) => {
  return buildGraphMutationFn(CreateMediaCommentMutation)({
    input: {
      entityUrn,
      parentCommentId,
      text,
    },
    where: { organizationId },
  });
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

const invalidateEntityDetailQueries = async (entityId: string) => {
  await queryClient.invalidateQueries({
    // Refetch all detail queries of the entity that was commented on
    predicate: query => query.queryKey.includes(entityId),
    refetchActive: true,
  });
};

export function useCreateMediaComment(entityId: string) {
  return useMutation(createMediaComment, {
    onError,
    onSuccess: async () => {
      await invalidateEntityDetailQueries(entityId);
    },
  });
}

const updateMediaComment = ({
  id,
  organizationId,
  text,
}: IWhereUniqueIdOrganizationInput & IUpdateMediaCommentInput) => {
  return buildGraphMutationFn(UpdateMediaCommentMutation)({
    input: { text },
    where: { id, organizationId },
  });
};

export function useUpdateMediaComment(entityId: string) {
  return useMutation(updateMediaComment, {
    onError,
    onSuccess: async () => {
      await invalidateEntityDetailQueries(entityId);
    },
  });
}

// possible need to wrap with logic to throw an error if it fails
const deleteMediaComment = ({ id, organizationId }: IWhereUniqueIdOrganizationInput) => {
  return buildGraphMutationFn(DeleteMediaCommentMutation)({
    where: { id, organizationId },
  });
};

export function useDeleteMediaComment(entityId: string) {
  return useMutation(deleteMediaComment, {
    onError,
    onSuccess: async () => {
      await invalidateEntityDetailQueries(entityId);
    },
  });
}

export interface IUpdateImageInput {
  flagged?: boolean;
  metadata?: MediaMetadata[];
  customFieldMetadata?: ICustomFieldMetadataItemInput[];
}

const UpdateImageMetadataMutation = gql`
  mutation UpdateImage($where: WhereUniqueIdOrganizationInput!, $input: UpdateImageInput!) {
    updateImage(where: $where, input: $input) {
      errors {
        message
        field
      }
      image {
        metadata {
          id
          creatorId
          type
          value
        }
        flagged
        mediaType
      }
    }
  }
`;

const updateImageMetadata = ({
  organizationId,
  id,
  ...rest
}: IWhereUniqueIdOrganizationInput & IUpdateImageInput) => {
  return buildGraphMutationFn(UpdateImageMetadataMutation)({
    input: {
      ...rest,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export const useUpdateImageMetadata = () => {
  return useMutation(updateImageMetadata, {
    onError,
    onSuccess: async (result, { id }) => {
      if (result.updateImage.image?.mediaType) {
        await queryClient.invalidateQueries({
          predicate: query =>
            query.queryKey.includes(result.updateImage.image.mediaType) ||
            query.queryKey.includes(id),
          refetchActive: false,
        });
      }
    },
  });
};

const BulkUpdateLocationImagesMutation = gql`
  mutation BulkUpdateLocationImages(
    $where: WhereUniqueIdOrganizationInput!
    $input: BulkUpdateImagesInput!
  ) {
    bulkUpdateImages(where: $where, input: $input) {
      errors {
        message
        field
      }
      success
    }
  }
`;

export interface IBulkUpdateLocationImagesInput {
  locationId: string;
  imageTypesEditing: Record<ImageType, boolean>;
  imageUrns: string[];
  published?: boolean;
  flagged?: boolean;
  heading?: number;
  metadata: MediaMetadata[];
}

export interface IBulkUpdateLocationImagesResult {
  bulkUpdateImages: {
    errors: [UserError] | null;
    success: boolean;
  };
}

const bulkUpdateLocationImages = ({
  organizationId,
  locationId,
  imageUrns,
  published,
  flagged,
  heading,
  metadata,
}: IWhereOrganizationInput & IBulkUpdateLocationImagesInput) => {
  return buildGraphMutationFn(BulkUpdateLocationImagesMutation)({
    input: {
      ids: imageUrns,
      properties: {
        published,
        flagged,
        heading,
        // If metadata is provided, it cannot be empty
        metadata: metadata?.length ? metadata : undefined,
      },
    },
    where: {
      organizationId,
      id: locationId,
    },
  }) as Promise<IBulkUpdateLocationImagesResult>;
};

export const useBulkUpdateLocationImages = () => {
  return useMutation(bulkUpdateLocationImages, {
    onError,
    onSuccess: async (
      result: IBulkUpdateLocationImagesResult,
      { imageTypesEditing, imageUrns },
    ) => {
      if (result.bulkUpdateImages.errors?.length) {
        return;
      }

      await Promise.all(
        (Object.keys(imageTypesEditing) as ImageType[]).map(async imageType => {
          if (!imageTypesEditing[imageType]) return;

          // Invalidate all queries for each image type that was edited
          await queryClient.invalidateQueries({
            predicate: query => query.queryKey.includes(imageType),
            refetchActive: true,
          });

          // Invalidate any individual queries for the updated images
          await Promise.all(
            imageUrns.map(urn =>
              queryClient.invalidateQueries({
                predicate: query => query.queryKey.includes(urn),
                refetchActive: true,
              }),
            ),
          );
        }),
      );
    },
  });
};
