import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useCollectionKeys } from '../../services/CollectionService';
import useGraphQuery from '../../hooks/useGraphQuery';
import { activeCollectionState } from '../../atoms/collections';
import { activeOrganizationState } from '../../atoms/organizations';
import useKeycloak from '../../hooks/useKeycloak';

import LoadingScreen from '../LoadingScreen';

import { getLoginRoute, getOrgCollectionsRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const CollectionRouteQuery = gql`
  query CollectionRoute($id: ID!, $organizationId: ID) {
    collection(id: $id, organizationId: $organizationId) {
      id
    }
  }
`;

function CollectionRoute({ publicRoute }) {
  const collectionKeys = useCollectionKeys();

  const [collection, setCollection] = useRecoilState(activeCollectionState);
  const activeTenant = useRecoilValue(activeTenantState);
  const org = useRecoilValue(activeOrganizationState);
  const { initialized, authenticated } = useKeycloak();
  const location = useLocation();

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useCollection = (id, options) =>
    useGraphQuery(
      collectionKeys.route(id),
      CollectionRouteQuery,
      { id: id, organizationId: org ? org.id : null },
      options,
    );

  const errorRedirect = useMemo(
    () =>
      org?.id
        ? getOrgCollectionsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: org.id,
          })
        : initialized && authenticated
        ? '/'
        : getLoginRoute({}),
    [org, initialized, authenticated, activeTenant],
  );

  const errorRedirectOptions = useMemo(
    () => ({
      replace: true,
      state: location,
    }),
    [location],
  );

  return (
    <EntityRoute
      idParam={'collectionId'}
      currentEntity={collection}
      setEntity={setCollection}
      useEntity={useCollection}
      entitySelector={data => data?.collection}
      errorRedirectRoute={errorRedirect}
      errorRedirectOptions={errorRedirectOptions}
      renderLoading={() => <LoadingScreen fullscreen={publicRoute} />}
    />
  );
}

export default CollectionRoute;
