import { useEffect } from 'react';

import useRasterOverlayFilter from '../../../hooks/filtering/useRasterOverlayFilter';
import useFilterContext from '../../../hooks/useFilterContext';
import { MediaType, MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../constants/media';
import { FILTER_STATE_KEYS } from '../../../constants/urlStateKeys';
import { useRestoreOverlayFilterFromUrl } from '../../../hooks/filtering/useOverlayFilter';

const useRasterOverlayFilterInitializer = () => {
  const { addFilterEffect, removeFilterEffect, useTypeData } = useFilterContext();
  const { enableRasterOverlay, disableRasterOverlay, isEnabled } = useRasterOverlayFilter();
  const rasterOverlays = useTypeData(MediaType.RasterOverlay);
  useRestoreOverlayFilterFromUrl(
    FILTER_STATE_KEYS.RASTER_OVERLAY,
    rasterOverlays,
    enableRasterOverlay,
  );

  useEffect(() => {
    const levelEffectId = addFilterEffect(
      MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
      (levelFilter, prevLevelFilter, loadedFromUrl) => {
        if (loadedFromUrl) return; // Don't run the effect if level filter is loaded from the url

        rasterOverlays.forEach(overlay => {
          const level = overlay.metadata.find(
            d => d.type === MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
          );
          if (levelFilter?.filterItem(level)) {
            if (isEnabled(overlay)) return; // Overlay is already enabled, skip

            enableRasterOverlay(overlay); // Overlay is disabled, level was selected, enable it
          }
          // Overlay used to pass filter => level was deselected, disable it
          // If overlay doesn't pass and didn't pass the filter, it was manually turned on, so it should be ignored
          else if (prevLevelFilter?.filterItem(level) && !overlay.default) {
            // Don't auto disable default overlays
            disableRasterOverlay(overlay);
          }
        });
      },
    );

    return () => {
      // remove effect on unmount
      removeFilterEffect(levelEffectId);
    };
  }, [
    addFilterEffect,
    rasterOverlays,
    isEnabled,
    enableRasterOverlay,
    disableRasterOverlay,
    removeFilterEffect,
  ]);
};

export default useRasterOverlayFilterInitializer;
