import { useEffect, useMemo } from 'react';

import MapMenu from '../../../../../../../components/mapping/MapMenu';
import useOverlayMenuItems from '../../../../../../../components/mapping/LocationMapMenu/useOverlayMenuItems';
import useFilterContext from '../../../../../../../hooks/useFilterContext';
import useRasterOverlayFilter from '../../../../../../../hooks/filtering/useRasterOverlayFilter';
import { IRasterOverlay } from '../../../../../../../services/LocationService';
import { MediaType } from '../../../../../../../constants/media';
import { LayersIcon } from '../../../../../../../theme/icons';

export interface IImageMarkerFormMapMenu {
  disabled?: boolean;
}

const ImageMarkerFormMapMenu: React.FC<IImageMarkerFormMapMenu> = ({ disabled }) => {
  const { useTypeData, useTypeFilteredData } = useFilterContext();

  const { enableRasterOverlay, disableRasterOverlay } = useRasterOverlayFilter();
  const rasterOverlays = useTypeData(MediaType.RasterOverlay);
  const filteredRasterOverlays = useTypeFilteredData(MediaType.RasterOverlay);

  const {
    overlayMenuItems: rasterOverlayMenuItems,
    createOverlayFilter: createRasterOverlayFilter,
  } = useOverlayMenuItems({
    mediaType: MediaType.RasterOverlay,
    overlays: rasterOverlays,
    filteredOverlays: filteredRasterOverlays,
    enableOverlay: enableRasterOverlay,
    disableOverlay: disableRasterOverlay,
  });

  useEffect(() => {
    // Initialize default overlay
    const defaultOverlay = rasterOverlays?.find((o: IRasterOverlay) => o.default);

    if (defaultOverlay) {
      createRasterOverlayFilter(defaultOverlay, false)(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rasterOverlays]);

  const menu = useMemo(
    () => [
      {
        sectionTitle: 'Layers',
        icon: <LayersIcon />,
        accordions: [
          {
            type: 'Base',
            children: [
              {
                label: 'Satellite',
                active: true,
              },
            ],
          },
          {
            type: 'Overlays',
            children: [...rasterOverlayMenuItems],
          },
        ],
      },
    ],
    [rasterOverlayMenuItems],
  );

  return <MapMenu menu={menu} disabled={disabled} />;
};

export default ImageMarkerFormMapMenu;
