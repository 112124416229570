import { gql } from 'graphql-request';

import useOrgGraphQuery from '../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../services/LocationService';
import { OverlayLayerFrag } from '../layers/overlays';
import { UseGraphQueryOptions } from '../../../hooks/useGraphQuery';

const LocationMapQuery = gql`
  query LocationMap($orgId: ID, $id: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      projects {
        edges {
          node {
            id
            name
            startDate
            organizationId
          }
        }
        totalCount
      }
      projectPhotos {
        totalCount
      }
      panoramas {
        totalCount
      }
      hdPhotos {
        totalCount
      }
      pointsOfInterest {
        totalCount
      }
      areasOfInterest {
        totalCount
      }
      rasterOverlays {
        id
        name
        metadata {
          id
          type
          value
        }
        shifted
        ...OverlayLayerFrag
        ... on RasterOverlay {
          default
        }
      }
      vectorOverlays {
        id
        name
        ...OverlayLayerFrag
        ... on VectorOverlay {
          color
        }
      }
      embedModels {
        id
        name
        embedHtml
        projectId
        position {
          latitude
          longitude
        }
        createdAt
        metadata {
          id
          type
          value
        }
      }
      metadataTypes {
        type
        values {
          id
          name
        }
      }
      assignableCustomFields {
        id
        label
        type
        values {
          id
          name
        }
        targets
      }
    }
  }
  ${OverlayLayerFrag}
`;

const useLocationDetails = (id?: string, options?: UseGraphQueryOptions) => {
  const locationKeys = useLocationKeys();

  const { enabled = true, ...restOptions } = options || {};

  const res = useOrgGraphQuery(
    id ? locationKeys.map(id) : '',
    LocationMapQuery,
    { id },
    {
      ...restOptions,
      enabled: !!id && enabled,
    },
  );

  return res;
};

export default useLocationDetails;
