import React from 'react';
import { ListItemText } from '@mui/material';
import { gql } from 'graphql-request';
import { To } from 'react-router-dom';

import IdsListItem from '../../../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../../../components/ids-lists/IdsListItem/IdsListItemChild';
import ProjectChip from '../../../../../components/entity-chips/ProjectChip';
import AssignmentResponseStatusChip from '../AssignmentResponseStatusChip';
import { ASSIGNMENT_RESPONSE_STATUSES } from '../../../../../utils/assignments';
import LocationChip from '../../../../../components/entity-chips/LocationChip';

export interface IAssignmentResponseListItemFragType {
  id: string;
  assignmentId: string;
  projectId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  startsAt: string;
  endsAt: string;
  assignmentTaskResponses: {
    totalCount: number;
  };
  project: {
    location: {
      name: string;
      id: string;
    };
  };
}

export const AssignmentResponseListItemFrag = gql`
  fragment AssignmentResponseListItemFrag on AssignmentResponse {
    id
    assignmentId
    projectId
    status
    createdAt
    updatedAt
    startsAt
    endsAt
    assignmentTaskResponses {
      totalCount
    }
    project {
      location {
        name
        id
      }
    }
  }
`;

export interface IAssignmentResponseListItemProps {
  assignmentResponseItem: IAssignmentResponseListItemFragType;
  to: To;
}

const AssignmentResponseListItem: React.FC<IAssignmentResponseListItemProps> = ({
  assignmentResponseItem,
  to,
}) => {
  if (!assignmentResponseItem) {
    return null;
  }

  const {
    id,
    projectId,
    status,
    createdAt,
    updatedAt,
    startsAt,
    endsAt,
    assignmentTaskResponses,
    project,
  } = assignmentResponseItem;

  return (
    <IdsListItem key={id} to={to}>
      <IdsListItemChild xs={3} lg={2} stopPropagation>
        <ProjectChip projectId={projectId} />
      </IdsListItemChild>
      <IdsListItemChild xs={3} lg={2} stopPropagation>
        <LocationChip locationName={project.location.name} locationId={project.location.id} />
      </IdsListItemChild>
      <ListItemText
        primary='Task Responses'
        primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
        secondary={assignmentTaskResponses.totalCount}
        secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
      />
      {!startsAt && (
        <ListItemText
          primary='Created'
          primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
          secondary={new Date(createdAt).toLocaleString()}
          secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
        />
      )}
      {!endsAt && (
        <ListItemText
          primary='Updated'
          primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
          secondary={new Date(updatedAt).toLocaleString()}
          secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
        />
      )}
      {startsAt && (
        <ListItemText
          primary='Starts'
          primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
          secondary={new Date(startsAt).toLocaleString()}
          secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
        />
      )}
      {endsAt && (
        <ListItemText
          primary='Ends'
          primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
          secondary={new Date(endsAt).toLocaleString()}
          secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
        />
      )}
      <IdsListItemChild xs={2}>
        <AssignmentResponseStatusChip status={status as ASSIGNMENT_RESPONSE_STATUSES} />
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default AssignmentResponseListItem;
