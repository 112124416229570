import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../atoms/organizations';

import { AssignmentDetailsTemplatePage } from '../../Assignments/AssignmentDetailsTemplatePage';
import { activeProgramAssignmentState, activeProgramState } from '../../../atoms/programs';
import { useGetAssignmentResponsesList } from '../../../services/AssignmentResponsesService';
import { activeTenantState } from '../../../atoms/tenants';
import {
  getOrgProgramAssignmentResponseRoute,
  getOrgProgramAssignmentsRoute,
  getOrgProgramRoute,
  getOrgProgramsRoute,
} from '../../../utils/routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProgramAssignmentDetailsPageProps {}

const ProgramAssignmentDetailsPage: React.FC<IProgramAssignmentDetailsPageProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);
  const activeProgramAssignment = useRecoilValue(activeProgramAssignmentState);
  const useGetAssignmentResponses = () =>
    useGetAssignmentResponsesList(activeProgramAssignment!.id);

  if (!activeProgram || !activeProgramAssignment) {
    return null;
  }

  return (
    <AssignmentDetailsTemplatePage
      pageTitle='Program Assignment Details'
      assignmentId={activeProgramAssignment.id}
      useGetAssignmentResponses={useGetAssignmentResponses}
      getToAssignmentResponse={(assignmentResponseId: string) =>
        getOrgProgramAssignmentResponseRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          programId: activeProgram?.id,
          assignmentId: activeProgramAssignment?.id,
          assignmentResponseId,
        })
      }
      breadcrumbs={[
        {
          label: 'Programs',
          route: getOrgProgramsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        {
          label: activeProgram.name,
          route: getOrgProgramRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            programId: activeProgram?.id,
          }),
        },
        {
          label: 'Assignments',
          route: getOrgProgramAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            programId: activeProgram?.id,
          }),
        },
      ]}
    />
  );
};

export default ProgramAssignmentDetailsPage;
