import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import IdsCheckbox from '../../../../ids-inputs/IdsCheckbox';

import styles from '../MapMenuItemAccordions.module.css';

const AccordionSectionMediaTypes = ({ item, toggle }) => {
  const Icon = item.icon;
  const OutlinedIcon = item.outlinedIcon;

  return (
    <Box className={styles.accordionItem}>
      <IdsCheckbox
        label={item.enabled ? <strong>{item.label}</strong> : item.label}
        onChange={() => toggle(!item.enabled)}
        labelProps={{
          componentsProps: {
            typography: {
              variant: 'body2',
              color: 'text.secondary',
            },
          },
        }}
        size='small'
        sx={{ color: item.color, '&.Mui-checked': { color: item.color } }}
        checked={item.enabled}
        icon={<OutlinedIcon fontSize='medium' />}
        checkedIcon={<Icon fontSize='medium' />}
      />
    </Box>
  );
};

AccordionSectionMediaTypes.propTypes = {
  item: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    outlinedIcon: PropTypes.elementType.isRequired,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AccordionSectionMediaTypes;
