import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import {
  flagFilterState,
  infoPanelActivePaneState,
  infoPanelExpandedState,
} from '../../../../atoms/immersiveViewer';
import {
  activeImageId,
  krPanoDirection,
  krPanoFov,
  mediaViewerOpen,
} from '../../../../atoms/mediaViewer';
import { activeOrganizationState } from '../../../../atoms/organizations';
import {
  endDateState,
  startDateState,
  timelineMarksState,
  timelineValueState,
} from '../../../../atoms/timeline';
import { ACTIVE_MEDIA_MARKER_LAYER_ID, MEDIA_TYPE_LAYER_IDS } from '../../../../constants/layerIds';
import { activeTenantState } from '../../../../atoms/tenants';
import {
  LEGACY_MEDIA_TYPES,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  MEDIA_TYPES,
  MediaType,
} from '../../../../constants/media';
import useImagesFilter from '../../../../hooks/filtering/useImagesFilter';
import useItemsOverrideFilter from '../../../../hooks/filtering/useItemsOverrideFilter';
import useFilterContext from '../../../../hooks/useFilterContext';
import useLocationMapContext from '../../../../hooks/useLocationMapContext';
import useHighlightMediaMarker from '../../../../hooks/useHighlightMediaMarker';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import useMediaFromUrn from '../../../../hooks/useMediaFromUrn';
import { useObservableItemState } from '../../../../hooks/useObservableStates';
import usePrevious from '../../../../hooks/usePrevious';
import useSetActiveMedia from '../../../../hooks/useSetActiveMedia';
import { useLocationMapAssets } from '../../../../services/AssetService';
import '../../../../theme/globalStyles.css';
import getHTMLStringProps from '../../../../utils/getHTMLStringProps';
import IdsMapEntityViewer from '../../../ids-entities-viewer/IdsMapEntityViewer';
import { MEDIA_PARAM_KEY } from '../../../ids-media-viewer/IdsMediaViewer';
import IdsIFrameDialog from '../../../IdsIFrameDialog';
import FOVRadar from '../../FOVRadar';
import {
  build3DModelMarkerLayer,
  build3DVRMarkerLayer,
  buildActiveMediaMarkerLayer,
  buildAssetMarkerLayer,
  buildHDPhotoMarkerLayer,
  buildPanoramaMarkerLayer,
  buildPOIMarkerLayer,
  buildProjectPhotoMarkerLayer,
  buildVideoMarkerLayer,
} from '../../layers/media-markers';
import { buildAreaOfInterestLayer } from '../../layers/media-areas';
import {
  buildRasterOverlayLayer,
  getRasterOverlayLayerId,
  getVectorOverlayLayerId,
  buildVectorOverlayLayer,
} from '../../layers/overlays';

import LocationMapInfo from '../LocationMapInfo';
import AreaOfInterestInfo from '../../areas-of-interest/AreaOfInterestInfo';
import PointOfInterestTool from '../../tools/PointOfInterestTool';
import AreaOfInterestTool from '../../tools/AreaOfInterestTool';
import RasterOverlayTool from '../../tools/RasterOverlayTool';
import useKeycloak from '../../../../hooks/useKeycloak';
import MapMediaViewer from '../MapMediaViewer';
import ImageMarkerEditorTool from '../../tools/ImageMarkerEditorTool';
import { TOOLS_ID } from '../../LocationMapMenu';

import useActiveImageDetail from './hooks/useActiveImageDetail';
import useAOIs from './hooks/useAOIs';
import useHDPhotos from './hooks/useHDPhotos';
import useImageData from './hooks/useImageData';
import usePanoramas from './hooks/usePanoramas';
import usePOIs from './hooks/usePOIs';
import useProjectPhotos from './hooks/useProjectPhotos';
import LocationMapFilterInitializers from './LocationMapFilterInitializers';
import { accumulateActiveAssetImages } from './helpers';

const LOCATION_LAYER_CONTEXT = 'location-map';
const EMBED_DIALOG_KEY = 'embedModel';
export const DEFAULT_TAKE = 600;
const activeMediaZ = 0.05;

const LocationMapMediaManager = ({
  cluster,
  transitioning,
  closing,
  onClose,
  onViewAllImagery,
  onExpandedChange,
  onMediaPanelOpenChange,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeTenant = useRecoilValue(activeTenantState);
  const fov = useRecoilValue(krPanoFov);
  const direction = useRecoilValue(krPanoDirection);
  const [_mediaViewerOpen, setMediaViewerOpen] = useRecoilState(mediaViewerOpen);
  const [_activeImageId, setActiveImageId] = useRecoilState(activeImageId);
  const setInfoPanelExpanded = useSetRecoilState(infoPanelExpandedState);
  const resetInfoPanelActivePane = useResetRecoilState(infoPanelActivePaneState);
  const resetFlagFilterState = useResetRecoilState(flagFilterState);

  const resetTimelineMarks = useResetRecoilState(timelineMarksState);
  const resetTimelineValue = useResetRecoilState(timelineValueState);
  const resetStartDate = useResetRecoilState(startDateState);
  const resetEndDate = useResetRecoilState(endDateState);

  const [previousActiveImageId, setPreviousActiveImageId] = useState(() => _activeImageId);
  const [activeEmbedModel, setActiveEmbedModel] = useState();
  const [embedModelViewerOpen, setEmbedModelViewerOpen] = useState(false);
  const [poiOpen, setPoiOpen] = useState(false);
  const [activePOI, setActivePOI] = useState(null);
  const [aoiOpen, setAoiOpen] = useState(false);
  const [activeAsset, setActiveAsset] = useState(null);
  const [assetOpen, setAssetOpen] = useState(false);
  const [activeAOI, setActiveAOI] = useState(null);

  const { token: keycloakToken } = useKeycloak();
  const { loading, setLoading, location } = useLocationMapContext();
  const { useFilters, setTypeData, getTypeItem, setTypeItems, useTypeFilteredData } =
    useFilterContext();

  const filteredProjectPhotos = useTypeFilteredData(MediaType.ProjectPhoto);
  const filteredHdPhotos = useTypeFilteredData(MediaType.HDPhoto);
  const filteredPanoramas = useTypeFilteredData(MediaType.PanoramicPhoto);
  const filteredRasterOverlays = useTypeFilteredData(MediaType.RasterOverlay);
  const filteredVectorOverlays = useTypeFilteredData(MediaType.VectorOverlay);
  const filteredVideos = useTypeFilteredData(MediaType.Video);
  const filtered3DModels = useTypeFilteredData(MediaType.ThreeDModel);
  const filtered3DVRs = useTypeFilteredData(MediaType.ThreeDVR);
  const filteredPOIs = useTypeFilteredData(MediaType.PointOfInterest);
  const filteredAOIs = useTypeFilteredData(MediaType.AreaOfInterest);
  const filteredAssets = useTypeFilteredData(MediaType.Asset);

  const { data: assetData, isLoading: assetsLoading } = useLocationMapAssets(location?.id);
  const prevAssets = usePrevious(assetData?.location.assets);

  /**
   * Accumulate all assets and their images into one object for further processing.
   */
  const assetsWithImages = useMemo(() => {
    if (!assetData?.location.assets) {
      return null;
    }

    /**
     * {
     *   assetId1: [imageUrn1, imageUrn2, imageUrn3, ....],
     *   assetId2: [imageUrn1, imageUrn2, imageUrn3, ....],
     *   ...
     * }
     */
    return assetData.location.assets.reduce((accumulator, asset) => {
      if (asset.images?.length) {
        return {
          ...accumulator,
          [asset.id]: asset.images.flatMap(image => image.id),
        };
      }

      return accumulator;
    }, {});
  }, [assetData]);

  const activeFilters = useFilters();
  const {
    setLayer,
    setLayers,
    setLayerContext,
    destroyLayerContext,
    setPadding,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  } = useImmersiveViewer();
  const { rasterOverlays, vectorOverlays, embedModels } = location;
  const prevRasterOverlays = usePrevious(rasterOverlays);
  const prevVectorOverlays = usePrevious(vectorOverlays);
  const prevEmbedModels = usePrevious(embedModels);
  const { setActiveMediaAndHighlightOnMap, setActiveMedia } = useSetActiveMedia();
  const { unhighlightMediaMarker, highlightMediaMarker } = useHighlightMediaMarker();
  const { getMediaTypeFromUrn, getEmbedItemFromUrn } = useMediaFromUrn();
  const [searchParams, setSearchParams] = useSearchParams();
  const [toolState, setToolState] = useObservableItemState({
    id: TOOLS_ID,
    defaultState: { active: null },
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });

  const { addOverrideItems, removeOverrideItems } = useItemsOverrideFilter();

  // Keep previous value of activeImageId state to re-use it in openMediaViewer hook
  useEffect(() => {
    if (!_activeImageId) {
      return;
    }

    setPreviousActiveImageId(_activeImageId);
  }, [_activeImageId]);

  const handleEOIDeleted = useCallback(
    (id, setActiveEOI) => {
      unhighlightMediaMarker(id);
      setActiveEOI(null);
      setActiveMedia(null);
    },
    [unhighlightMediaMarker, setActiveMedia],
  );

  const onClosePoiInfo = useCallback(
    deleted => {
      setPoiOpen(false);

      searchParams.delete(MEDIA_PARAM_KEY);
      setSearchParams(searchParams);

      if (deleted === true) {
        // make sure true was passed explicitly in case truthy value is passed from event handler
        handleEOIDeleted(activePOI.node.id, setActivePOI);
      }

      if (onMediaPanelOpenChange) {
        onMediaPanelOpenChange(false);
      }
    },
    [
      searchParams,
      setSearchParams,
      setPoiOpen,
      handleEOIDeleted,
      activePOI,
      setActivePOI,
      onMediaPanelOpenChange,
    ],
  );

  /**
   * @TODO: implement delete functionality.
   * @TODO: find a way to merge this function with onClosePoiInfo.
   */
  const onCloseAssetInfo = useCallback(() => {
    setAssetOpen(false);

    searchParams.delete(MEDIA_PARAM_KEY);
    setSearchParams(searchParams);

    if (assetsWithImages && assetsWithImages[activeAsset.node.id]) {
      /**
       * Get asset images to remove them from the override filter.
       *
       * {
       *   FLAT_IMAGE: [id, id ,id],
       *   PROJECT_PHOTO: [id, id],
       *   THREE_SIXTY_IMAGE: [id],
       * }
       */
      const activeAssetImages = assetsWithImages[activeAsset.node.id].reduce(
        accumulateActiveAssetImages,
        {},
      );

      /**
       * The asset was closed.
       * Remove its images from the override filter.
       */
      Object.entries(activeAssetImages).forEach(([type, items]) => {
        /**
         * items === [imageUrn1, imageUrn2, imageUrn3, ....]
         * type === THREE_SIXTY_IMAGE || PROJECT_PHOTO || FLAT_IMAGE
         */
        removeOverrideItems(items, type);
      });
    }

    if (onMediaPanelOpenChange) {
      onMediaPanelOpenChange(false);
    }
  }, [
    searchParams,
    setSearchParams,
    setAssetOpen,
    onMediaPanelOpenChange,
    activeAsset,
    assetsWithImages,
    removeOverrideItems,
  ]);

  const onCloseAoiInfo = useCallback(
    deleted => {
      setAoiOpen(false);

      searchParams.delete(MEDIA_PARAM_KEY);
      setSearchParams(searchParams);

      if (deleted === true) {
        // make sure true was passed explicitly in case truthy value is passed from event handler
        handleEOIDeleted(activeAOI.node.id, setActiveAOI);
      }

      if (onMediaPanelOpenChange) {
        onMediaPanelOpenChange(false);
      }
    },
    [
      searchParams,
      setSearchParams,
      setAoiOpen,
      handleEOIDeleted,
      activeAOI,
      setActiveAOI,
      onMediaPanelOpenChange,
    ],
  );

  const filteredImageData = useImageData();

  const activeImageItem = useMemo(() => {
    if (_activeImageId) {
      return filteredImageData.find(m => m.node.id === _activeImageId);
    }
  }, [filteredImageData, _activeImageId]);

  const activeImageIsPanoramic = useMemo(() => {
    if (activeImageItem) {
      return getMediaTypeFromUrn(activeImageItem.node.id) === MediaType.PanoramicPhoto;
    }
  }, [activeImageItem, getMediaTypeFromUrn]);

  const handleActiveMediaChange = useCallback(
    (newActiveMediaId, setSearchParam = true) => {
      if (_activeImageId) unhighlightMediaMarker(_activeImageId);
      if (activeEmbedModel) unhighlightMediaMarker(activeEmbedModel.id);
      if (activePOI) unhighlightMediaMarker(activePOI.node.id);
      if (activeAOI) unhighlightMediaMarker(activeAOI.node.id);
      if (activeAsset) unhighlightMediaMarker(activeAsset.node.id);

      setActiveMediaAndHighlightOnMap(newActiveMediaId);

      if (setSearchParam) {
        searchParams?.set(MEDIA_PARAM_KEY, newActiveMediaId);
        setSearchParams(searchParams, { replace: true });
      }

      /**
       * Cleanup old active media only if media type changed,
       * otherwise new active media id will be set elsewhere.
       */
      const newMediaType = getMediaTypeFromUrn(newActiveMediaId);

      const isNewActiveMediaImage = [
        MediaType.ProjectPhoto,
        MediaType.HDPhoto,
        MediaType.PanoramicPhoto,
      ].includes(newMediaType);

      if (!isNewActiveMediaImage) {
        setActiveImageId(null);
      }

      if (newMediaType !== MediaType.PointOfInterest) {
        setActivePOI(null); // close POI viewer
        setPoiOpen(false);
      }

      if (newMediaType !== MediaType.AreaOfInterest) {
        setActiveAOI(null); // close AOI viewer
        setAoiOpen(false);
      }

      if (newMediaType !== MediaType.Asset) {
        setActiveAsset(null); // close asset viewer
        setAssetOpen(false);

        /**
         * Active media changed from the asset to an image.
         * Remove all active asset images from the override filter, but keep the selected image in the filter
         * in case the image is associated with the asset.
         */
        if (activeAsset && isNewActiveMediaImage) {
          if (assetsWithImages && assetsWithImages[activeAsset.node.id]) {
            /**
             * {
             *   FLAT_IMAGE: [id, id ,id],
             *   PROJECT_PHOTO: [id, id],
             *   THREE_SIXTY_IMAGE: [id],
             * }
             */
            const activeAssetImages = assetsWithImages[activeAsset.node.id].reduce(
              (accumulator, imageId) => {
                if (imageId === newActiveMediaId) {
                  return accumulator;
                }

                return accumulateActiveAssetImages(accumulator, imageId);
              },
              {},
            );

            /**
             * Map entity was closed, so remove its images from the override filter.
             */
            Object.entries(activeAssetImages).forEach(([type, items]) => {
              /**
               * items === [imageUrn1, imageUrn2, imageUrn3, ....]
               * type === THREE_SIXTY_IMAGE || PROJECT_PHOTO || FLAT_IMAGE
               */
              removeOverrideItems(items, type);
            });
          }
        }
      }

      /**
       * If an asset is open, add its images to the override filter to show them on the map.
       */
      if (newMediaType === MediaType.Asset) {
        if (assetsWithImages && assetsWithImages[newActiveMediaId]) {
          /**
           * Get asset images to add them to the override filter.
           *
           * {
           *   FLAT_IMAGE: [id, id ,id],
           *   PROJECT_PHOTO: [id, id],
           *   THREE_SIXTY_IMAGE: [id],
           * }
           */
          const activeAssetImages = assetsWithImages[newActiveMediaId].reduce(
            accumulateActiveAssetImages,
            {},
          );

          Object.entries(activeAssetImages).forEach(([type, items]) => {
            /**
             * items === [imageUrn1, imageUrn2, imageUrn3, ....]
             * type === THREE_SIXTY_IMAGE || PROJECT_PHOTO || FLAT_IMAGE
             */
            addOverrideItems(items, type);
          });
        }
      }

      if (
        ![MediaType.Video, MediaType.ThreeDModel, MediaType.ThreeDVR].includes(newActiveMediaId)
      ) {
        setActiveEmbedModel(null);
      }
    },
    [
      _activeImageId,
      activeEmbedModel,
      setActiveImageId,
      activePOI,
      activeAOI,
      activeAsset,
      unhighlightMediaMarker,
      setActiveMediaAndHighlightOnMap,
      searchParams,
      setSearchParams,
      getMediaTypeFromUrn,
      removeOverrideItems,
      addOverrideItems,
      assetsWithImages,
    ],
  );

  const selectActiveImage = useCallback(
    newActiveImageId => {
      handleActiveMediaChange(newActiveImageId, false);
      setActiveImageId(newActiveImageId);
      setMediaViewerOpen(true);
    },
    [handleActiveMediaChange, setActiveImageId, setMediaViewerOpen],
  );

  const selectActiveEmbedModel = useCallback(
    newActiveEmbedModel => {
      handleActiveMediaChange(newActiveEmbedModel.id);
      setActiveEmbedModel(newActiveEmbedModel);

      if (newActiveEmbedModel.embedProps) {
        // Embed model is set up as iframe embed
        setEmbedModelViewerOpen(true);
        return;
      }

      // Embed props not set, model is just a url, open in new tab
      window.open(newActiveEmbedModel.embedHtml, '_blank');
    },
    [handleActiveMediaChange, setEmbedModelViewerOpen],
  );

  const selectActivePOI = useCallback(
    newActivePOI => {
      handleActiveMediaChange(newActivePOI.node.id);
      setActivePOI(newActivePOI);
      setPoiOpen(true);

      if (onMediaPanelOpenChange) {
        onMediaPanelOpenChange(true);
      }
    },
    [handleActiveMediaChange, setActivePOI, setPoiOpen, onMediaPanelOpenChange],
  );
  useEffect(() => {
    if (!activePOI) {
      return;
    }

    const updatedActivePoi = filteredPOIs.find(poi => poi.node.id === activePOI.node.id);
    if (!updatedActivePoi) {
      return;
    }

    setActivePOI(updatedActivePoi);
  }, [filteredPOIs, activePOI]);

  const selectActiveAOI = useCallback(
    newActiveAOI => {
      handleActiveMediaChange(newActiveAOI.node.id);
      setActiveAOI(newActiveAOI);
      setAoiOpen(true);

      if (onMediaPanelOpenChange) {
        onMediaPanelOpenChange(true);
      }
    },
    [handleActiveMediaChange, setActiveAOI, setAoiOpen, onMediaPanelOpenChange],
  );

  const selectActiveAsset = useCallback(
    newActiveAsset => {
      handleActiveMediaChange(newActiveAsset.node.id);
      setActiveAsset(newActiveAsset);
      setAssetOpen(true);

      if (onMediaPanelOpenChange) {
        onMediaPanelOpenChange(true);
      }
    },
    [handleActiveMediaChange, onMediaPanelOpenChange, setActiveAsset, setAssetOpen],
  );

  // Perform all mount/unmount related items
  useEffect(() => {
    resetInfoPanelActivePane();
    setLayerContext(LOCATION_LAYER_CONTEXT);

    return function () {
      // Perform all cleanup items needed when a location closes
      destroyLayerContext(LOCATION_LAYER_CONTEXT);
      setPadding(null); // remove any padding added for location map UI
      setActiveImageId(null);
      setActiveEmbedModel(null);
      setActivePOI(null);
      setActiveAOI(null);
      setActiveAsset(null);
      resetFlagFilterState();
      setInfoPanelExpanded(true);
      setToolState({ active: null });

      resetTimelineMarks();
      resetTimelineValue();
      resetStartDate();
      resetEndDate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // DATA LOADING ===============================================

  const handleMediaPageLoaded = useCallback(
    (edges, type) => {
      const items = [];

      // Merge page data with existing data (media is loaded as needed by different components)
      edges.forEach(e => {
        const existingItem = getTypeItem(type, e.node.id);
        if (existingItem) {
          const { node: existingNode, ...existingRest } = existingItem;
          const { node, ...rest } = e;
          items.push({
            node: { ...existingNode, ...node },
            ...existingRest,
            ...rest,
          });
        } else {
          // Item doesn't yet exist
          items.push(e);
        }
      });

      setTypeItems(items, type);
    },
    [getTypeItem, setTypeItems],
  );

  const handleProjectPhotosPage = useCallback(
    edges => {
      handleMediaPageLoaded(edges, MediaType.ProjectPhoto);
    },
    [handleMediaPageLoaded],
  );
  const { isLoading: projectPhotosLoading } = useProjectPhotos(handleProjectPhotosPage);

  const handleHdPhotosPage = useCallback(
    edges => {
      handleMediaPageLoaded(edges, MediaType.HDPhoto);
    },
    [handleMediaPageLoaded],
  );
  const { isLoading: hdPhotosLoading } = useHDPhotos(handleHdPhotosPage);

  const handlePanoramasPage = useCallback(
    edges => {
      handleMediaPageLoaded(edges, MediaType.PanoramicPhoto);
    },
    [handleMediaPageLoaded],
  );
  const { isLoading: panoramasLoading } = usePanoramas(handlePanoramasPage);

  const handlePOIsPage = useCallback(
    edges => {
      handleMediaPageLoaded(
        edges.map(e => ({ tooltip: e.node.name, ...e })),
        MediaType.PointOfInterest,
      );
    },
    [handleMediaPageLoaded],
  );
  const { isLoading: pointsOfInterestLoading } = usePOIs(handlePOIsPage);

  const handleAOIsPage = useCallback(
    edges => {
      handleMediaPageLoaded(
        edges.map(e => ({ tooltip: e.node.name, ...e })),
        MediaType.AreaOfInterest,
      );
    },
    [handleMediaPageLoaded],
  );

  const { isLoading: areasOfInterestLoading } = useAOIs(handleAOIsPage);

  const handleAssetsPage = useCallback(
    edges => {
      handleMediaPageLoaded(
        edges.map(e => ({ tooltip: e.node.name, ...e })),
        MediaType.Asset,
      );
    },
    [handleMediaPageLoaded],
  );

  /**
   * Hide all images associated with assets.
   *
   * Active asset images will be shown due to the override filter.
   */
  const { applyFilter, removeFilter } = useImagesFilter();
  useEffect(() => {
    if (!assetsWithImages) {
      return;
    }

    const imagesToHide = Object.entries(assetsWithImages).flatMap(([_, images]) => images);

    applyFilter(imagesToHide);
  }, [assetsWithImages, applyFilter]);

  useEffect(() => {
    return () => removeFilter();
  }, [removeFilter]);

  // TODO: refactor to use paginated query once BE supports asset pagination. This is a temp workaround
  useEffect(() => {
    if (assetData && assetData !== prevAssets) {
      handleAssetsPage(assetData.location.assets.map(a => ({ node: a })));
    }
  }, [assetData, prevAssets, handleAssetsPage]);

  useEffect(() => {
    // Only update when data changes, not when setTypeData changes
    if (embedModels !== prevEmbedModels && embedModels) {
      const processedEmbedModels = embedModels.map(m => {
        const type = m.metadata.find(d => d.type === 'EmbeddedViewType').value;
        return {
          ...m,
          type: type === 'Autodesk' ? MediaType.ThreeDModel : type, // Classify Autodesk embeds as 3D Model
          embedProps: getHTMLStringProps('iframe', m.embedHtml),
          tooltip: m.name,
        };
      });

      const typedEmbedModels = {
        [MediaType.Video]: processedEmbedModels.filter(
          m => m.type === MEDIA_TYPES[MediaType.Video].type,
        ),
        [MediaType.ThreeDModel]: processedEmbedModels.filter(
          m => m.type === MEDIA_TYPES[MediaType.ThreeDModel].type,
        ),
        [MediaType.ThreeDVR]: processedEmbedModels.filter(
          m => m.type === MEDIA_TYPES[MediaType.ThreeDVR].type,
        ),
      };

      Object.entries(typedEmbedModels).forEach(([type, models]) => setTypeData(models, type));
    }
  }, [embedModels, prevEmbedModels, setTypeData]);

  useEffect(() => {
    // Only update when data changes, not when setTypeData changes
    if (rasterOverlays !== prevRasterOverlays) {
      setTypeData(rasterOverlays, MediaType.RasterOverlay);
    }
  }, [rasterOverlays, prevRasterOverlays, setTypeData]);

  useEffect(() => {
    // Only update when data changes, not when setTypeData changes
    if (vectorOverlays !== prevVectorOverlays) {
      setTypeData(vectorOverlays, MediaType.VectorOverlay);
    }
  }, [vectorOverlays, prevVectorOverlays, setTypeData]);

  const { data: activeMediaDetail } = useActiveImageDetail(_activeImageId);

  useEffect(() => {
    if (!loading) return; // Avoid returning to loading state after initial load finished (handles query refetching)

    setLoading(
      projectPhotosLoading ||
        hdPhotosLoading ||
        panoramasLoading ||
        pointsOfInterestLoading ||
        areasOfInterestLoading ||
        assetsLoading,
    );
  }, [
    loading,
    setLoading,
    projectPhotosLoading,
    hdPhotosLoading,
    panoramasLoading,
    pointsOfInterestLoading,
    areasOfInterestLoading,
    assetsLoading,
  ]);

  // MEDIA LAYER MANAGEMENT  ===============================================

  const selectMapImage = useCallback(
    value => selectActiveImage(value.object.node.id),
    [selectActiveImage],
  );

  const selectMapEmbedModel = useCallback(
    value => selectActiveEmbedModel(value.object),
    [selectActiveEmbedModel],
  );

  const handleCloseMapEmbedModel = useCallback(
    () => setEmbedModelViewerOpen(false),
    [setEmbedModelViewerOpen],
  );

  const selectMapPOI = useCallback(value => selectActivePOI(value.object), [selectActivePOI]);

  const selectMapAOI = useCallback(value => selectActiveAOI(value.object), [selectActiveAOI]);

  const selectMapAsset = useCallback(value => selectActiveAsset(value.object), [selectActiveAsset]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSortedProjectPhotos = _activeImageId
      ? filteredProjectPhotos.map(({ node, ...rest }) => {
          const isActive = node.id === _activeImageId;
          return {
            ...rest,
            node: {
              ...node,
              // Minimally raise active media up to appear above other media
              position: {
                ...node.position,
                altitude: isActive ? activeMediaZ : 0,
              },
            },
          };
        })
      : filteredProjectPhotos;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MEDIA_TYPES[MediaType.ProjectPhoto].type],
      buildProjectPhotoMarkerLayer(zSortedProjectPhotos, {
        cluster,
        onClick: selectMapImage,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filteredProjectPhotos,
    setLayer,
    cluster,
    selectMapImage,
    transitioning,
    toolState,
    closing,
    _activeImageId,
  ]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSortedHDPhotos = _activeImageId
      ? filteredHdPhotos.map(({ node, ...rest }) => {
          const isActive = node.id === _activeImageId;
          return {
            ...rest,
            node: {
              ...node,
              // Minimally raise active media up to appear above other media
              position: {
                ...node.position,
                altitude: isActive ? activeMediaZ : 0,
              },
            },
          };
        })
      : filteredHdPhotos;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.HDPhoto],
      buildHDPhotoMarkerLayer(zSortedHDPhotos, {
        cluster,
        onClick: selectMapImage,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filteredHdPhotos,
    setLayer,
    cluster,
    selectMapImage,
    transitioning,
    toolState,
    closing,
    _activeImageId,
  ]);

  const levelIds = useMemo(
    () =>
      location.metadataTypes
        ?.find(t => t.type === MEDIA_METADATA_TYPES[MediaMetadataType.Level].type)
        ?.values?.map(v => v.id) || [],
    [location.metadataTypes],
  );

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSortedPanoramas = _activeImageId
      ? filteredPanoramas.map(({ node, ...rest }) => {
          const isActive = node.id === _activeImageId;
          return {
            ...rest,
            node: {
              ...node,
              // Minimally raise active media up to appear above other media
              position: {
                ...node.position,
                altitude: isActive ? activeMediaZ : 0,
              },
            },
          };
        })
      : filteredPanoramas;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.PanoramicPhoto],
      buildPanoramaMarkerLayer(zSortedPanoramas, levelIds, {
        cluster,
        onClick: selectMapImage,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filteredPanoramas,
    setLayer,
    cluster,
    levelIds,
    selectMapImage,
    transitioning,
    toolState,
    closing,
    _activeImageId,
  ]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSortedVideos = activeEmbedModel
      ? filteredVideos.map(({ id, position, ...rest }) => {
          const isActive = id === activeEmbedModel.id;
          return {
            ...rest,
            id,
            // Minimally raise active media up to appear above other media
            position: { ...position, altitude: isActive ? activeMediaZ : 0 },
          };
        })
      : filteredVideos;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.Video],
      buildVideoMarkerLayer(zSortedVideos, {
        cluster,
        onClick: selectMapEmbedModel,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filteredVideos,
    setLayer,
    cluster,
    selectMapEmbedModel,
    transitioning,
    toolState,
    closing,
    activeEmbedModel,
  ]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSorted3DModels = activeEmbedModel
      ? filtered3DModels.map(({ id, position, ...rest }) => {
          const isActive = id === activeEmbedModel.id;
          return {
            ...rest,
            id,
            // Minimally raise active media up to appear above other media
            position: { ...position, altitude: isActive ? activeMediaZ : 0 },
          };
        })
      : filtered3DModels;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.ThreeDModel],
      build3DModelMarkerLayer(zSorted3DModels, {
        cluster,
        onClick: selectMapEmbedModel,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filtered3DModels,
    setLayer,
    cluster,
    selectMapEmbedModel,
    transitioning,
    toolState,
    closing,
    activeEmbedModel,
  ]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSorted3DVRs = activeEmbedModel
      ? filtered3DVRs.map(({ id, position, ...rest }) => {
          const isActive = id === activeEmbedModel.id;
          return {
            ...rest,
            id,
            // Minimally raise active media up to appear above other media
            position: { ...position, altitude: isActive ? activeMediaZ : 0 },
          };
        })
      : filtered3DVRs;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.ThreeDVR],
      build3DVRMarkerLayer(zSorted3DVRs, {
        cluster,
        onClick: selectMapEmbedModel,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filtered3DVRs,
    setLayer,
    cluster,
    selectMapEmbedModel,
    transitioning,
    toolState,
    closing,
    activeEmbedModel,
  ]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSortedPOIs = activePOI
      ? filteredPOIs.map(({ node, ...rest }) => {
          const isActive = node.id === activePOI.node.id;
          return {
            ...rest,
            node: {
              ...node,
              // Minimally raise active media up to appear above other media
              position: {
                ...node.position,
                altitude: isActive ? activeMediaZ : 0,
              },
            },
          };
        })
      : filteredPOIs;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.PointOfInterest],
      buildPOIMarkerLayer(zSortedPOIs, {
        cluster,
        onClick: selectMapPOI,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [filteredPOIs, setLayer, cluster, selectMapPOI, transitioning, toolState, closing, activePOI]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    // Note, z coordinate is not used to change ordering here because it results in inconsistent rendering of this layer type
    const sortedAOIs = activeAOI
      ? // if AOI is active, sort active AOI to end of the array and return a new array instance to trigger a redraw
        // IMPORTANT: Clone the filteredAOIs array to avoid sorting the actual filtered data
        [...filteredAOIs].sort((a, b) =>
          // Show active AOI on top
          a.node.id === activeAOI.node.id
            ? 1
            : b.node.id === activeAOI.node.id
            ? -1
            : // Sort other AOIs by date, showing latest created on top
              new Date(a.node.createdAt) - new Date(b.node.createdAt),
        )
      : filteredAOIs;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.AreaOfInterest],
      buildAreaOfInterestLayer(sortedAOIs, {
        onClick: selectMapAOI,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [filteredAOIs, setLayer, selectMapAOI, transitioning, toolState, closing, activeAOI]);

  useEffect(() => {
    if (closing) return; // prevent layers from being set after being destroyed if dep changes during closing

    const zSortedAssets = activeAsset
      ? filteredAssets.map(({ node, ...rest }) => {
          const isActive = node.id === activeAsset.node.id;
          return {
            ...rest,
            node: {
              ...node,
              // Minimally raise active media up to appear above other media
              position: {
                ...node.position,
                altitude: isActive ? activeMediaZ : 0,
              },
            },
          };
        })
      : filteredAssets;

    setLayer(
      MEDIA_TYPE_LAYER_IDS[MediaType.Asset],
      buildAssetMarkerLayer(zSortedAssets, {
        cluster,
        onClick: selectMapAsset,
        visible: !transitioning,
        pickable: !toolState?.active,
      }),
    );
  }, [
    filteredAssets,
    setLayer,
    cluster,
    selectMapAsset,
    transitioning,
    toolState,
    closing,
    activeAsset,
  ]);

  useEffect(() => {
    setLayer(ACTIVE_MEDIA_MARKER_LAYER_ID, buildActiveMediaMarkerLayer());
  }, [setLayer]);

  useEffect(() => {
    // Something in the effect deps causes this to run again
    // after the layers have been destroyed when closing the location,
    // need to check if the location is closing to prevent the layers
    // from being set after the location is closed
    if (!rasterOverlays?.length || closing) {
      return;
    }

    setLayers(
      rasterOverlays.map(o => {
        const filterPosition = activeFilters?.findIndex(f => f.name === o.id);

        return {
          id: getRasterOverlayLayerId(o.id),
          layer: buildRasterOverlayLayer(
            o,
            keycloakToken,
            {
              // Only show the layer if toggled on
              visible: filteredRasterOverlays.some(fo => fo.id === o.id),
            },
            null,
            {
              // Show most recently turned on overlays above other overlays
              ...(filterPosition >= 0 && { position: filterPosition }),
            },
          ),
        };
      }),
    );
  }, [rasterOverlays, filteredRasterOverlays, setLayers, activeFilters, closing, keycloakToken]);

  useEffect(() => {
    if (!vectorOverlays?.length || closing) {
      // prevent layers from being set after being destroyed if dep changes during closing
      return;
    }

    setLayers(
      vectorOverlays.map(o => {
        const filterPosition = activeFilters?.findIndex(f => f.name === o.id);

        return {
          id: getVectorOverlayLayerId(o.id),
          layer: buildVectorOverlayLayer(
            o,
            keycloakToken,
            {
              // Only show the layer if toggled on
              visible: filteredVectorOverlays.some(fo => fo.id === o.id),
            },
            {
              // Show most recently turned on overlays above other overlays
              ...(filterPosition >= 0 && { position: filterPosition }),
            },
          ),
        };
      }),
    );
  }, [vectorOverlays, filteredVectorOverlays, setLayers, activeFilters, closing, keycloakToken]);

  const handleInvalidUrlId = useCallback(() => {
    // invalid urn passed, clear searchParams
    setEmbedModelViewerOpen(false);
    setActiveImageId(null);
    setActiveEmbedModel(null);
    searchParams?.delete(MEDIA_PARAM_KEY);
    searchParams?.delete(EMBED_DIALOG_KEY);
    setSearchParams(searchParams);
  }, [searchParams, setActiveImageId, setSearchParams]);

  const openMediaViewer = useCallback(() => {
    const imageId = _activeImageId ?? previousActiveImageId;
    // Ensure that image was not deleted.
    const imageExists = filteredImageData.find(image => image.node.id === imageId);

    if (imageExists) {
      selectActiveImage(imageId);
      return;
    }

    // No active media item, try to open to the first item in the array
    if (filteredImageData?.length) {
      selectActiveImage(filteredImageData[0].node.id);
    }
  }, [_activeImageId, filteredImageData, selectActiveImage, previousActiveImageId]);

  const mediaViewer = useMemo(
    () => (
      <MapMediaViewer
        tenantId={activeTenant?.id}
        orgId={activeOrg?.id}
        media={filteredImageData}
        activeImageDetail={activeMediaDetail}
        setActiveMediaAndHighlightOnMap={setActiveMediaAndHighlightOnMap}
        unhighlightMediaMarker={unhighlightMediaMarker}
        highlightMediaMarker={highlightMediaMarker}
        metadataTypes={location.metadataTypes}
      />
    ),
    [
      filteredImageData,
      activeMediaDetail,
      setActiveMediaAndHighlightOnMap,
      unhighlightMediaMarker,
      highlightMediaMarker,
      location,
      activeTenant,
      activeOrg,
    ],
  );

  const markerFOV = useMemo(
    () =>
      activeImageIsPanoramic && (
        <FOVRadar
          latitude={activeImageItem.node.position.latitude}
          longitude={activeImageItem.node.position.longitude}
          direction={
            activeMediaDetail
              ? direction +
                activeMediaDetail.image.position.heading -
                activeMediaDetail.image.position.orientation
              : direction
          }
          angle={fov}
        />
      ),
    [activeImageIsPanoramic, activeImageItem, activeMediaDetail, direction, fov],
  );

  const _loading =
    loading ||
    projectPhotosLoading ||
    hdPhotosLoading ||
    panoramasLoading ||
    pointsOfInterestLoading ||
    areasOfInterestLoading ||
    assetsLoading;

  // check for passed media id, if valid display the data. Only run this once when done loading the location media
  useEffect(() => {
    let urlMediaId = searchParams.get(MEDIA_PARAM_KEY);
    if (!urlMediaId) {
      return;
    }

    if (!_loading) {
      const mediaType = getMediaTypeFromUrn(urlMediaId);

      switch (mediaType) {
        case LEGACY_MEDIA_TYPES.userphoto:
          urlMediaId = urlMediaId.replace(
            LEGACY_MEDIA_TYPES.userphoto,
            MEDIA_TYPES[MediaType.ProjectPhoto].urnType,
          );
        // eslint-disable-next-line no-fallthrough
        case MediaType.ProjectPhoto:
          const urlProjectPhoto = filteredProjectPhotos.find(photo => photo.node.id === urlMediaId);
          urlProjectPhoto ? selectActiveImage(urlMediaId) : handleInvalidUrlId();
          break;
        case MediaType.HDPhoto:
          const urlHDPhoto = filteredHdPhotos.find(photo => photo.node.id === urlMediaId);
          urlHDPhoto ? selectActiveImage(urlMediaId) : handleInvalidUrlId();
          break;
        case MediaType.PanoramicPhoto:
          const urlPanoramicPhoto = filteredPanoramas.find(photo => photo.node.id === urlMediaId);
          urlPanoramicPhoto ? selectActiveImage(urlMediaId) : handleInvalidUrlId();
          break;
        case MediaType.PointOfInterest:
          const urlPOI = filteredPOIs.find(poi => poi.node.id === urlMediaId);
          urlPOI ? selectActivePOI(urlPOI) : handleInvalidUrlId();
          break;
        case MediaType.AreaOfInterest:
          const urlAOI = filteredAOIs.find(aoi => aoi.node.id === urlMediaId);
          urlAOI ? selectActiveAOI(urlAOI) : handleInvalidUrlId();
          break;
        case MediaType.Asset:
          const urlAsset = filteredAssets.find(asset => asset.node.id === urlMediaId);
          urlAsset ? selectActiveAsset(urlAsset) : handleInvalidUrlId();
          break;
        case MediaType.ThreeDModel:
        case MediaType.ThreeDVR:
        case MediaType.Video:
          const embed = getEmbedItemFromUrn(urlMediaId);
          embed ? selectActiveEmbedModel(embed) : handleInvalidUrlId();
          break;
        default:
          // invalid urn passed, clear searchParams
          handleInvalidUrlId();
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_loading]);

  return (
    !transitioning && (
      <>
        <LocationMapFilterInitializers />
        {markerFOV}
        {_mediaViewerOpen
          ? mediaViewer
          : !poiOpen &&
            !assetOpen &&
            !aoiOpen && (
              <LocationMapInfo
                onClose={onClose}
                onViewAllImagery={onViewAllImagery}
                onExpandedChange={onExpandedChange}
                openMediaViewer={openMediaViewer}
              />
            )}
        {((activePOI && poiOpen) || (activeAsset && assetOpen)) && (
          <IdsMapEntityViewer
            entity={activePOI || activeAsset}
            locationId={location.id}
            onClose={activePOI ? onClosePoiInfo : onCloseAssetInfo}
          />
        )}
        {activeAOI && aoiOpen && (
          <AreaOfInterestInfo aoi={activeAOI} locationId={location.id} onClose={onCloseAoiInfo} />
        )}
        <PointOfInterestTool />
        <AreaOfInterestTool />
        <RasterOverlayTool />
        <ImageMarkerEditorTool />
        <IdsIFrameDialog
          open={embedModelViewerOpen}
          onClose={handleCloseMapEmbedModel}
          dialogKey={EMBED_DIALOG_KEY}
          title={activeEmbedModel?.name}
          src={activeEmbedModel?.embedProps?.src}
          onOpen={() => setEmbedModelViewerOpen(true)}
          loading={_loading}
        />
      </>
    )
  );
};

LocationMapMediaManager.defaultProps = {
  transitioning: false,
  cluster: true,
};

LocationMapMediaManager.propTypes = {
  transitioning: PropTypes.bool,
  onClose: PropTypes.func,
  onViewAllImagery: PropTypes.func,
  onExpandedChange: PropTypes.func,
  onMediaPanelOpenChange: PropTypes.func,
};

export default LocationMapMediaManager;
