import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import queryClient from '../utils/query';

import useGraphQuery from '../hooks/useGraphQuery';
import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import {
  AssignmentResponseDetailFrag,
  IAssignmentResponseDetailFragType,
} from '../views/Assignments/AssignmentResponseDetails';
import { AssignmentResponseListItemFrag } from '../views/Assignments/AssignmentDetails/Responses/AssignmentResponseListItem';

import { useAssignmentKeys } from './AssignmentsService';

export const useAssignmentResponseKeys = () => {
  const queryIdKey = useQueryKeyId();
  const assignmentKeys = useAssignmentKeys();

  return useMemo(() => {
    const assignmentResponseKeys = {
      all: ['assignmentResponses', queryIdKey],
      lists: () => [...assignmentResponseKeys.all, 'list'],
      assignmentList: (assignmentId: string) => [
        ...assignmentResponseKeys.lists(),
        ...assignmentKeys.detail(assignmentId),
      ],
      route: (id: string) => [...assignmentResponseKeys.all, id],
      detail: (id: string) => [...assignmentResponseKeys.route(id), 'detail'],
    };

    return assignmentResponseKeys;
  }, [queryIdKey, assignmentKeys]);
};

// Assignment Response Details
const AssignmentResponseDetailQuery = gql`
  query AssignmentResponseDetail($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      ...AssignmentResponseDetailFrag
    }
  }
  ${AssignmentResponseDetailFrag}
`;
interface IAssignmentResponseDetailQueryType {
  assignmentResponse: IAssignmentResponseDetailFragType;
}
export const useAssignmentResponseDetailsData = (assignmentResponseId: string) => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  return useOrgGraphQuery<IAssignmentResponseDetailQueryType>(
    assignmentResponseKeys.detail(assignmentResponseId),
    AssignmentResponseDetailQuery,
    { id: assignmentResponseId },
  );
};

const ProjectAssignmentResponsesListQuery = gql`
  query ProjectAssignmentResponses(
    $organizationId: ID!
    $where: AssignmentResponsesWhereInput!
    $after: String
    $take: Int
  ) {
    assignmentResponses(
      organizationId: $organizationId
      where: $where
      after: $after
      take: $take
    ) {
      edges {
        cursor
        node {
          ...AssignmentResponseListItemFrag
        }
      }
    }
  }
  ${AssignmentResponseListItemFrag}
`;

type ProjectAssignmentResponsesWhereInput = {
  assignmentId: string;
  projectId: string;
};

export const useGetProjectAssignmentResponsesListQuery = (
  organizationId: string,
  where: ProjectAssignmentResponsesWhereInput,
) => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  const useAssignmentResponses = (take: number, after: string | null) =>
    useGraphQuery(
      [
        ...assignmentResponseKeys.assignmentList(where.assignmentId),
        `take-${take}`,
        `after-${after}`,
      ],
      ProjectAssignmentResponsesListQuery,
      { organizationId: organizationId, where, take, after },
    );

  return useCursorPaginatedQuery({
    useQuery: useAssignmentResponses,
    defaultTake: 50,
    selectConnection: data => data?.assignmentResponses,
  });
};

const AssignmentResponsesListQuery = gql`
  query AssignmentResponses($orgId: ID, $id: ID!, $take: Int, $after: String, $tenantId: ID) {
    assignment(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      assignmentResponses(take: $take, after: $after) {
        edges {
          cursor
          node {
            id
            projectId
            status
            createdAt
            updatedAt
            startsAt
            endsAt
            ...AssignmentResponseListItemFrag
          }
        }
      }
    }
  }
  ${AssignmentResponseListItemFrag}
`;

export const useGetAssignmentResponsesList = (assignmentId: string) => {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  const useAssignmentResponses = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...assignmentResponseKeys.assignmentList(assignmentId), `take-${take}`, `after-${after}`],
      AssignmentResponsesListQuery,
      { id: assignmentId, take, after },
    );

  return useCursorPaginatedQuery({
    useQuery: useAssignmentResponses,
    defaultTake: 50,
    selectConnection: data => data?.assignment.assignmentResponses,
  });
};

const updateAssignmentResponseMutation = gql`
  mutation UpdateAssignmentResponse(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateAssignmentResponseInput!
  ) {
    updateAssignmentResponse(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export interface IUpdateAssignmentResponseUpdateValues {
  status?: string;
}

export interface IUpdateAssignmentResponsePayload {
  id: string;
  organizationId: string;
  updateValues: IUpdateAssignmentResponseUpdateValues;
}

const updateAssignmentResponse = ({
  id,
  organizationId,
  updateValues,
}: IUpdateAssignmentResponsePayload) => {
  return buildGraphMutationFn(updateAssignmentResponseMutation)({
    input: {
      ...updateValues,
    },
    where: {
      id,
      organizationId,
    },
  });
};

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

export function useUpdateAssignmentResponse(id: string, assignmentId: string) {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  return useMutation(updateAssignmentResponse, {
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries(assignmentResponseKeys.detail(id), {
        refetchActive: true,
      });
      queryClient.invalidateQueries(assignmentResponseKeys.assignmentList(assignmentId), {
        refetchActive: true,
      });
    },
  });
}
