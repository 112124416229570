import React from 'react';
import { useRecoilValue } from 'recoil';

import { HomeIcon } from '../../theme/icons';
import IdsNestedViewHeader, { IIdsNestedViewHeaderProps } from '../IdsNestedViewHeader';
import { activeOrganizationState } from '../../atoms/organizations';
import { getOrgRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

type ParentType = Optional<IIdsNestedViewHeaderProps, 'breadcrumbs'>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INestedOrgViewHeaderProps extends ParentType {}

const NestedOrgViewHeader: React.FC<INestedOrgViewHeaderProps> = ({
  breadcrumbs = [],
  ...rest
}) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  return (
    <IdsNestedViewHeader
      breadcrumbs={[
        {
          label: <HomeIcon />,
          route: getOrgRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        ...breadcrumbs,
      ]}
      {...rest}
    />
  );
};

export default NestedOrgViewHeader;
