import React, { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { StandardTextFieldProps, TextField } from '@mui/material';
import get from 'lodash.get';

import { FormikProps } from 'formik';

import IdsFormElement from '../IdsFormElement';

export interface IIdsFormTextFieldProps extends StandardTextFieldProps {
  name: string;
  /** Trim whitespace from start and end of value on blur. `default: true` */
  trimWhitespace?: boolean;
  transformBlurValue?: (value: any) => any;
  transformChangeValue?: (value: any) => any;

  // Custom event handlers which additionally receive formikProps
  onBlur?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    formikProps?: FormikProps<any>,
  ) => void;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formikProps?: FormikProps<any>,
  ) => void;
}

const IdsFormTextField: React.FC<IIdsFormTextFieldProps> = ({
  name = '',
  value,
  trimWhitespace = true,
  transformBlurValue,
  transformChangeValue,
  onBlur,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const transformValue = useCallback(
    (event, setFieldValue, transformer, shouldValidate = false) => {
      const rawVal = event.target.value;
      const newVal = transformer ? transformer(rawVal) : rawVal;
      return setFieldValue(name, newVal, shouldValidate);
    },
    [name],
  );

  const _onBlur = useCallback(
    async (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
      formikProps: FormikProps<any>,
    ) => {
      setIsFocused(false);

      const { setFieldValue, handleBlur } = formikProps;

      // NOTE: awaiting transform value here to ensure there are no race
      // conditions related to formik validation and formiks touch tracking
      // that happens in handleBlur
      const _transformBlurValue = (value: any) => {
        const transformedVal = transformBlurValue ? transformBlurValue(value) : value;
        return trimWhitespace && typeof transformedVal === 'string'
          ? transformedVal?.trim()
          : transformedVal;
      };
      await transformValue(event, setFieldValue, _transformBlurValue);

      handleBlur(event);

      if (onBlur) {
        onBlur(event, formikProps);
      }
    },
    [transformValue, transformBlurValue, onBlur, trimWhitespace],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const _onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, formikProps: FormikProps<any>) => {
      const { setFieldValue, validateOnChange } = formikProps;
      transformValue(event, setFieldValue, transformChangeValue, validateOnChange);

      if (onChange) {
        onChange(event, formikProps);
      }
    },
    [transformValue, transformChangeValue, onChange],
  );

  return (
    <IdsFormElement
      render={formikProps => {
        const { values, errors, touched } = formikProps;

        const formikValue = get(values, name);
        const formikValueIsDefined = formikValue !== null && formikValue !== undefined;
        const touch = get(touched, name);
        const error = get(errors, name);

        return (
          <TextField
            name={name}
            helperText={touch && error}
            error={Boolean(touch && error)}
            onBlur={event => {
              _onBlur(event, formikProps);
            }}
            onFocus={handleFocus}
            onChange={event => {
              _onChange(event, formikProps);
            }}
            value={(value || formikValue) ?? ''}
            fullWidth
            size='small'
            // Force label be large when no value and not focused. If value is cleared externally this does not behave correctly by itself.
            {...(!isFocused && !formikValueIsDefined && { InputLabelProps: { shrink: false } })}
            {...rest}
          />
        );
      }}
    />
  );
};

IdsFormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  transformBlurValue: PropTypes.func, // transformBlurValue(value) : sets value on blur
  transformChangeValue: PropTypes.func, // transformChangeValue(value) : sets value on change
  onBlur: PropTypes.func, // onBlur(event, formikProps)
  onChange: PropTypes.func, // onChange(event, formikProps)
};

export default IdsFormTextField;
