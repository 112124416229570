import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { editProjectValidationSchema } from '../../projectValidation';
import IdsEditForm from '../../../../components/ids-forms/IdsEditForm';
import { PROJECT_STATUSES, ProjectStatusesType } from '../../../../constants/projects';
import ProjectForm from '../../ProjectForm';
import IdsFormSearchUserField from '../../../../components/ids-forms/IdsFormSearchUserField';
import { IUser } from '../types';

import styles from './styles.module.css';

export interface IFormValues {
  name?: string;
  users?: string[];
  location?: null | string;
  description?: string;
  startDate?: string | null;
  completionDate?: string | null;
  status?: ProjectStatusesType | typeof PROJECT_STATUSES;
}

export interface IEditProjectFormProps {
  onSubmit: (values: any) => any;
  onCancel: () => any;
  userOptions: IUser[];
  values: IFormValues;
  loadingUsers: boolean;
  loadingUsersError: boolean;
}

const EditProjectForm: React.FC<IEditProjectFormProps> = ({
  onSubmit,
  onCancel,
  values,
  userOptions,
  loadingUsers,
  loadingUsersError,
}) => {
  const initialValues = useMemo(
    () => ({
      name: '',
      location: null,
      description: '',
      startDate: null,
      completionDate: null,
      status: PROJECT_STATUSES.NEW,
      users: [],
      ...values,
    }),
    [values],
  );

  const _onSubmit = useCallback(
    async values => {
      if (onSubmit) {
        onSubmit(values);
        return;
      }
    },
    [onSubmit],
  );

  return (
    <IdsEditForm
      initialValues={initialValues}
      validationSchema={editProjectValidationSchema}
      successMessage={'Project updated'}
      errorHandler={() => 'Project could not be updated'}
      onSubmit={_onSubmit}
      onCancel={onCancel}
    >
      <Grid container spacing={2} className={styles['wrapper']}>
        <Grid item xs={6}>
          <Typography className={styles['title']} variant='h4'>
            General
          </Typography>
          <ProjectForm disableLocation={true} />
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles['title']} variant='h4'>
            Users
          </Typography>
          <Box>
            <IdsFormSearchUserField
              name='users'
              options={userOptions}
              isLoading={loadingUsers}
              error={loadingUsersError}
            />
          </Box>
        </Grid>
      </Grid>
    </IdsEditForm>
  );
};

export default EditProjectForm;
