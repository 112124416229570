import React from 'react';
import { ListItemText, ListItemTextProps } from '@mui/material';

import IdsListItem from '../../../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../../../components/ids-lists/IdsListItem/IdsListItemChild';
import ProjectChip from '../../../../../components/entity-chips/ProjectChip';
import IdsCheckbox, { IIdsCheckboxProps } from '../../../../../components/ids-inputs/IdsCheckbox';

import { IProjectFrag } from '../../../../../services/ProjectService';

import ProjectStatusChip from '../../../../Projects/ProjectStatusChip';

export interface IProjectAutocompleteOptionProps extends HTMLLiElementProps {
  option: {
    node: IProjectFrag;
  };
  selected: boolean;
  checkboxProps?: Omit<IIdsCheckboxProps, 'checked' | 'label'>;
}

const primaryTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
  variant: 'h6',
  noWrap: true,
};
const secondaryTypographyProps: ListItemTextProps['secondaryTypographyProps'] = {
  noWrap: true,
};

const ProjectAutocompleteOption: React.FC<IProjectAutocompleteOptionProps> = ({
  option,
  selected,
  checkboxProps,
  ...rest
}) => {
  const { id, name, description, status, location } = option.node;

  return (
    <li {...rest}>
      <IdsListItem key={id}>
        <IdsListItemChild xs='auto'>
          <IdsCheckbox label='' checked={selected} size='small' {...checkboxProps} />
        </IdsListItemChild>
        <IdsListItemChild>
          <ListItemText
            primary={name}
            primaryTypographyProps={primaryTypographyProps}
            secondary={location.name}
            secondaryTypographyProps={secondaryTypographyProps}
          />
        </IdsListItemChild>
        <IdsListItemChild xs>
          <ListItemText
            secondary={description}
            secondaryTypographyProps={secondaryTypographyProps}
          />
        </IdsListItemChild>
        <IdsListItemChild xs>
          <ProjectChip projectId={id} />
        </IdsListItemChild>
        <IdsListItemChild
          xs={3}
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <IdsListItemChild xs='auto'>
            <ProjectStatusChip status={status} />
          </IdsListItemChild>
        </IdsListItemChild>
      </IdsListItem>
    </li>
  );
};

export default ProjectAutocompleteOption;
