import { FieldType } from '../../../features/Data/EntityData/types';

import useEntityFields from './useEntityFields';

import { IIdsMapEntityViewerProps } from './index';

const useInitialValues = (entity: IIdsMapEntityViewerProps['entity']) => {
  const entityDataFields = useEntityFields(entity);

  return entityDataFields.reduce((accumulator: Record<string, any>, field) => {
    if (!field.isEditable) {
      return accumulator;
    }

    if (field.type === FieldType.SingleChoice) {
      accumulator[field.name] = field.props?.value || null;
    } else {
      accumulator[field.name] = field.data;
    }

    return accumulator;
  }, {});
};

export default useInitialValues;
