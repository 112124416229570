import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export interface ICancelButtonProps extends ButtonProps {
  label?: string;
}

const sx: ButtonProps['sx'] = {
  backgroundColor: theme => theme.palette.grey[400],
  '&:hover': {
    backgroundColor: theme => theme.palette.grey[500],
  },
};

const CancelButton: React.FC<ICancelButtonProps> = ({ label, ...rest }) => {
  return (
    <Button variant='contained' sx={sx} {...rest}>
      {label || 'Cancel'}
    </Button>
  );
};

export default CancelButton;
