import React from 'react';
import { Box } from '@mui/material';

import LoadingScreen from '../../../components/LoadingScreen';

import UserDetailsCard, { IUserDetailsCardProps } from './UserDetailsCard';

export interface IDetailsTabProps extends IUserDetailsCardProps {
  edit?: boolean;
  onCancel?: () => any;
  onEditClick?: () => any;
}

const DetailsTab: React.FC<IDetailsTabProps> = ({ user, edit = false, ...rest }) => {
  return (
    <Box>{user ? <UserDetailsCard user={user} edit={edit} {...rest} /> : <LoadingScreen />}</Box>
  );
};

export default DetailsTab;
