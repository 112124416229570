import React from 'react';

import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { useProgramKeys } from '../../services/ProgramsService';
import { activeProgramState } from '../../atoms/programs';
import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgProgramsRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const ProgramRouteQuery = gql`
  query ProgramRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    program(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      name
    }
  }
`;

function ProgramRoute() {
  const programKeys = useProgramKeys();

  const [program, setProgram] = useRecoilState(activeProgramState);
  const activeTenant = useRecoilValue(activeTenantState);
  const org = useRecoilValue(activeOrganizationState);

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useProgram = (id, options) =>
    useOrgGraphQuery(programKeys.route(id), ProgramRouteQuery, { id }, options);

  return (
    <EntityRoute
      idParam={'programId'}
      currentEntity={program}
      setEntity={setProgram}
      useEntity={useProgram}
      entitySelector={data => data?.program}
      errorRedirectRoute={getOrgProgramsRoute({
        subdomain: activeTenant?.subdomain,
        orgId: org.id,
      })}
    />
  );
}

export default ProgramRoute;
