import { MediaType, MediaMetadataType, RoutePointType } from './media';

// IMPORTANT: each key value should be unique across the Explorer application
// abbreviated values are used to minimize url length

export const FILTER_STATE_KEYS = {
  RASTER_OVERLAY: 'ro',
  VECTOR_OVERLAY: 'vo',
  AREA: 'a',
  LEVEL: 'l',
  CATEGORY: 'c',
  TYPE: 'ty',
  PHOTO_TAG: 'ta',
  FLAG: 'f',
  PUBLISHED: 'p',
  TIMELINE: 't',
  PROJECT: 'pj',
  COMPLETED: 'co',
};

export const MEDIA_METADATA_TYPE_KEYS = {
  [MediaMetadataType.Area]: FILTER_STATE_KEYS.AREA,
  [MediaMetadataType.Level]: FILTER_STATE_KEYS.LEVEL,
  [MediaMetadataType.PhotoCategory]: FILTER_STATE_KEYS.CATEGORY,
  [MediaMetadataType.PhotoType]: FILTER_STATE_KEYS.TYPE,
  [MediaMetadataType.PhotoTag]: FILTER_STATE_KEYS.PHOTO_TAG,
};

export const MEDIA_TYPE_ENABLED_KEYS = {
  [MediaType.ProjectPhoto]: 'prt',
  [MediaType.HDPhoto]: 'hdt',
  [MediaType.PanoramicPhoto]: 'pat',
  [MediaType.Video]: 'vt',
  [MediaType.ThreeDModel]: 'mot',
  [MediaType.ThreeDVR]: 'vrt',
  [MediaType.PointOfInterest]: 'pit',
  [MediaType.AreaOfInterest]: 'ait',
  [MediaType.Asset]: 'at',
  [MediaType.RasterOverlay]: 'rot',
  [MediaType.VectorOverlay]: 'vot',
};

export const ROUTE_POINT_TYPE_ENABLED_KEYS = {
  [RoutePointType.ProjectPhoto]: 'rprt',
  [RoutePointType.DslrHdPhoto]: 'rhdt',
  [RoutePointType.Panorama]: 'rpat',
  [RoutePointType.DslrPanorama]: 'rdpat',
};

const CUSTOM_FIELD_TYPE_KEY_PREFIX = 'c-';
export const isKeyCustomField = key => key.startsWith(CUSTOM_FIELD_TYPE_KEY_PREFIX);
export const getCustomFieldTypeKey = customFieldType =>
  `${CUSTOM_FIELD_TYPE_KEY_PREFIX}${customFieldType}`;

const LOCATION_MAP_KEYS = [
  FILTER_STATE_KEYS.RASTER_OVERLAY,
  FILTER_STATE_KEYS.VECTOR_OVERLAY,
  FILTER_STATE_KEYS.AREA,
  FILTER_STATE_KEYS.LEVEL,
  FILTER_STATE_KEYS.CATEGORY,
  FILTER_STATE_KEYS.TYPE,
  FILTER_STATE_KEYS.PHOTO_TAG,
  FILTER_STATE_KEYS.FLAG,
  FILTER_STATE_KEYS.TIMELINE,
  FILTER_STATE_KEYS.PROJECT,
  ...Object.values(MEDIA_TYPE_ENABLED_KEYS),
];

export const isKeyLocationMapKey = key => LOCATION_MAP_KEYS.includes(key) || isKeyCustomField(key);
