import { Notifier } from '@airbrake/browser';

import RuntimeConfig from '../RuntimeConfig';

const airbrake = new Notifier({
  projectId: RuntimeConfig.airbrakeProjectId,
  projectKey: RuntimeConfig.airbrakeProjectKey,
  environment: RuntimeConfig.airbrakeEnvironment,
});

airbrake.addFilter(notice => {
  // Ignore errors from local dev environment
  if (notice?.context?.environment === 'local') {
    return null;
  }

  return notice;
});

export default airbrake;
