import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import useFilterContext from '../useFilterContext';
import { IFilterTarget } from '../../context/FilterContext';
import useObservableStates, { useObservableItemState } from '../useObservableStates';
import { IEdge } from '../../constants/media';
import { IAssignableCustomField, ICustomFieldMetadata } from '../../constants/customFields';
import useUrlState from '../useUrlState';
import { getCustomFieldTypeKey } from '../../constants/urlStateKeys';
import selectedCustomFieldValueIdsFamily from '../../atoms/customFieldMetadataFilter';
import { MediaType } from '../../constants/media';
import { PascalCaseEntityType } from '../../constants/entities';
import usePrevious from '../usePrevious';

import useFilterTargetDataListeners from './useFilterTargetDataListeners';
import useActiveFilterTargets from './useActiveFilterTargets';

export const getTargetEntityFilterTarget = (
  customFieldType: string,
  target: PascalCaseEntityType,
) => {
  const findMetadataType = (d: any) => d.type === customFieldType;
  /**
   * customFieldMetadata is optional for some entities (e.g. Assets).
   */
  const selectEdgeFilterData = (edge: IEdge) =>
    edge.node.customFieldMetadata?.filter(findMetadataType) || [];
  // const selectItemFilterData = (item: INode) => item.customFieldMetadata.find(findMetadataType);

  const allSupportedFilterTargets: PartialRecord<PascalCaseEntityType, IFilterTarget> = {
    [PascalCaseEntityType.ProjectPhoto]: {
      type: MediaType.ProjectPhoto,
      selectFilterData: selectEdgeFilterData,
    },
    [PascalCaseEntityType.HDPhoto]: {
      type: MediaType.HDPhoto,
      selectFilterData: selectEdgeFilterData,
    },
    [PascalCaseEntityType.Panorama]: {
      type: MediaType.PanoramicPhoto,
      selectFilterData: selectEdgeFilterData,
    },
    [PascalCaseEntityType.Asset]: {
      type: MediaType.Asset,
      selectFilterData: selectEdgeFilterData,
    },
  };

  return allSupportedFilterTargets[target];
};

export const getSupportedFilterTargets = (
  customFieldType: string,
  targets: PascalCaseEntityType[],
) => {
  if (!targets?.length) return [];

  return targets
    .map(targetType => getTargetEntityFilterTarget(customFieldType, targetType))
    .filter(filterTarget => !!filterTarget) as IFilterTarget[];
};

export const getCustomFieldFilterName = (customFieldType: string) =>
  getCustomFieldTypeKey(customFieldType);

export const getCustomFieldFilterStateAtom = (customFieldType: string) =>
  selectedCustomFieldValueIdsFamily(customFieldType);

const ITEMS_ID = 'items';

export interface IUseCustomFieldMetadataFilterProps {
  assignableCustomField: Optional<IAssignableCustomField, 'values'>;
}

interface ICustomFieldMetadataOption extends ICustomFieldMetadata {
  /** Data types this metadata item is found in. */
  sources: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMetadataItemsObjectState extends Record<string, ICustomFieldMetadataOption> {}

type CustomFieldMetadataFilterData = ICustomFieldMetadata | ICustomFieldMetadata[];

type SelectCustomFieldMetadataFilterData =
  | ((item: any) => ICustomFieldMetadata)
  | ((item: any) => ICustomFieldMetadata[]);

const useCustomFieldMetadataFilter = ({
  assignableCustomField,
}: IUseCustomFieldMetadataFilterProps) => {
  const [selectedItemIds, setSelectedItemIds] = useRecoilState(
    getCustomFieldFilterStateAtom(assignableCustomField.type),
  );
  const { addFilter, removeFilter } = useFilterContext();
  const { setUrlArrayState } = useUrlState();
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();

  // Track in observable state to avoid overwrites
  // if multiple media types are updated at the same time
  // Tracked as an object to simplify dedupe logic
  // shape: { metadataItemId: metadataItem {}, ... }
  const [metadataItemsObj, setMetadataItemsObj] = useObservableItemState<IMetadataItemsObjectState>(
    {
      id: ITEMS_ID,
      defaultState: {},
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    },
  );
  const prevMetadataItemsObj = usePrevious(metadataItemsObj);

  const metadataItems = useMemo<ICustomFieldMetadata[]>(() => {
    const unsortedItems = Object.values(metadataItemsObj || {}).map(item => ({
      // Remove sources field
      id: item.id,
      type: item.type,
      value: item.value,
    }));

    // If allSortedCustomFieldValues is provided,
    // sort items to match source of truth order
    return assignableCustomField.values
      ? unsortedItems.sort(
          (a, b) =>
            assignableCustomField.values!.findIndex(l => l.id === a.id) -
            assignableCustomField.values!.findIndex(l => l.id === b.id),
        )
      : unsortedItems;
  }, [metadataItemsObj, assignableCustomField]);

  const updateMetadataItems = useCallback(
    (metadataItems: ICustomFieldMetadata[], sourceType: string) => {
      // Getting this state directly to avoid possible race conditions in the case of memoization
      const updatedMetadataItemsObj: IMetadataItemsObjectState = {
        ...getItemState<IMetadataItemsObjectState>(ITEMS_ID),
      };

      // Delete old metadata items that are no longer available
      Object.entries(updatedMetadataItemsObj).forEach(([id, item]) => {
        if (metadataItems.some(d => d?.id === id)) return; // Item still exists in this source

        // item was removed from this source

        // item was only found in this source
        if (item.sources.length === 1 && item.sources[0] === sourceType) {
          delete updatedMetadataItemsObj[id];
        } else {
          // Item is found in other sources, remove this source
          const index = item.sources.findIndex(source => source === sourceType);
          if (index !== -1) {
            updatedMetadataItemsObj[id].sources.splice(index, 1);
          }
        }
      });

      // Add new data and track which source types the item is found in
      metadataItems.forEach(item => {
        if (!item) return;

        const existingItem = updatedMetadataItemsObj[item.id];
        if (existingItem) {
          if (!existingItem.sources.includes(sourceType)) {
            existingItem.sources.push(sourceType);
          }
        } else {
          // new item
          updatedMetadataItemsObj[item.id] = {
            ...item,
            sources: [sourceType], // track source types
          };
        }
      });
      setMetadataItemsObj(updatedMetadataItemsObj, true);
    },
    [setMetadataItemsObj, getItemState],
  );

  const handleDataChange = useCallback(
    (data: any[], reduceDataItem: SelectCustomFieldMetadataFilterData, type: string) => {
      updateMetadataItems(
        data.reduce((_metadataItems, item) => {
          const filterData = reduceDataItem(item);
          if (Array.isArray(filterData)) {
            // item has array of values for metadata type
            _metadataItems.push(...filterData);
          } else {
            // item has single metadata value for type
            _metadataItems.push(filterData);
          }
          return _metadataItems;
        }, []),
        type,
      );
    },
    [updateMetadataItems],
  );

  // Supported filter targets for the custom field's specified target entity types
  const supportedFilterTargets = useMemo<IFilterTarget[]>(
    () => getSupportedFilterTargets(assignableCustomField.type, assignableCustomField.targets),
    [assignableCustomField],
  );
  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets) as IFilterTarget[];

  useFilterTargetDataListeners({ filterTargets: activeFilterTargets, handleDataChange });

  const handleFilterRemoved = useCallback(() => {
    setSelectedItemIds([]);
    setUrlArrayState(getCustomFieldTypeKey(assignableCustomField.type), null);
  }, [setSelectedItemIds, setUrlArrayState, assignableCustomField.type]);

  const handleFilterDestroyed = useCallback(() => {
    // Don't remove from url when destroyed to allow for reading
    // from the url and transferring filter state between routes
    setSelectedItemIds([]);
  }, [setSelectedItemIds]);

  const handleSelectedIdsChange = useCallback(
    (newSelectedIds: string[], loadedFromUrl = false) => {
      setSelectedItemIds(newSelectedIds);

      const selected = newSelectedIds
        .map(id => metadataItemsObj && metadataItemsObj[id]?.value)
        .filter(value => !!value)
        .join(', ');

      const filterName = getCustomFieldFilterName(assignableCustomField.type);

      if (newSelectedIds.length) {
        addFilter(
          {
            name: filterName,
            label: assignableCustomField.label,
            filterItem: (filterData: CustomFieldMetadataFilterData) => {
              if (!filterData) return false;

              if (Array.isArray(filterData)) {
                // filterData is an array of custom field values
                return filterData.some(d => newSelectedIds.includes(d.id));
              }

              // filterData is a single custom field value
              return newSelectedIds.includes(filterData.id);
            },
            targets: activeFilterTargets,
            onRemove: handleFilterRemoved,
            onDestroy: handleFilterDestroyed,
            selected,
          },
          {
            filteringDown: newSelectedIds.length < selectedItemIds.length,
            loadedFromUrl,
          },
        );

        setUrlArrayState(getCustomFieldTypeKey(assignableCustomField.type), newSelectedIds);
      } else {
        // Remove the filter
        removeFilter(filterName);
        setUrlArrayState(getCustomFieldTypeKey(assignableCustomField.type), null);
      }
    },
    [
      selectedItemIds,
      setSelectedItemIds,
      addFilter,
      removeFilter,
      assignableCustomField,
      activeFilterTargets,
      handleFilterRemoved,
      handleFilterDestroyed,
      setUrlArrayState,
      metadataItemsObj,
    ],
  );

  // Handle deselection of a deleted metadata item
  useEffect(() => {
    if (metadataItemsObj === prevMetadataItemsObj || !metadataItemsObj || !prevMetadataItemsObj)
      return;

    // Selected metadata item(s) is no longer an option
    const deletedIds = selectedItemIds.filter(
      id => !metadataItemsObj[id] && prevMetadataItemsObj[id],
    );

    if (deletedIds.length) {
      handleSelectedIdsChange(selectedItemIds.filter(id => !deletedIds.includes(id)));
    }
  }, [metadataItemsObj, prevMetadataItemsObj, selectedItemIds, handleSelectedIdsChange]);

  useEffect(() => {
    // TODO: this may be triggered when metadata is changed as well adding or removing an item... may need to check if was previously selected?

    // Selected metadata item wasn't loaded when filter was set, set filter again to update the label.
    // This should only happen when loading from the url.
    if (
      selectedItemIds.some(
        id =>
          (!prevMetadataItemsObj || !prevMetadataItemsObj[id]) &&
          metadataItemsObj &&
          metadataItemsObj[id],
      )
    ) {
      handleSelectedIdsChange(selectedItemIds, true);
    }
  }, [selectedItemIds, prevMetadataItemsObj, metadataItemsObj, handleSelectedIdsChange]);

  return {
    handleSelectedIdsChange,
    metadataItems,
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseRestoreCustomFieldMetadataFilterFromUrl
  extends IUseCustomFieldMetadataFilterProps {}

export const useRestoreCustomFieldMetadataFilterFromUrl = ({
  assignableCustomField,
}: IUseRestoreCustomFieldMetadataFilterFromUrl) => {
  const { handleSelectedIdsChange } = useCustomFieldMetadataFilter({ assignableCustomField });
  const { getUrlArrayState } = useUrlState();

  useEffect(() => {
    const urlIds = getUrlArrayState(getCustomFieldTypeKey(assignableCustomField.type));
    if (urlIds?.length) {
      handleSelectedIdsChange(urlIds, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomFieldMetadataFilterInitializerProps
  extends IUseCustomFieldMetadataFilterProps {}

export const CustomFieldMetadataFilterInitializer: React.FC<
  ICustomFieldMetadataFilterInitializerProps
> = props => {
  useRestoreCustomFieldMetadataFilterFromUrl(props);
  return null;
};

export default useCustomFieldMetadataFilter;
