import { ReactNode, forwardRef, useCallback } from 'react';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';

const gridComponents: GridComponents = {
  List: forwardRef(({ style, children, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
        marginBottom: 10,
        // Important: Padding top can't be set (it should be 0 all the time).
        // Otherwise virualization will be broken for some reason.
        // That's why marginTop and marginBottom used instead.
        // Style prop below have paddingTop and paddingBottom 0 by default.
        ...style,
      }}
    >
      {children}
    </div>
  )),
  Item: ({ children, ...props }) => <div {...props}>{children}</div>,
};

export interface IIdsDynamicGridProps<T> {
  /**
   * Array of items to render in the list. Type will be infered.
   */
  items: T[];

  /**
   * Item rendering function. Type will be infered.
   * @param item - Currently rendering item
   * @param index - Index of currently rendering item
   * @returns `ReactNode`
   */
  renderItem: (item: T, index: number) => ReactNode;
}

/**
 * Component for rendering virtualized grid with dynamic amount of items in the row
 */
export const IdsDynamicGrid = <T,>({ items, renderItem }: IIdsDynamicGridProps<T>) => {
  const renderItemFn = useCallback(
    (index: number) => renderItem(items[index], index),
    [items, renderItem],
  );

  return (
    <VirtuosoGrid
      style={{ height: '100%', width: '100%' }}
      totalCount={items.length}
      components={gridComponents}
      itemContent={renderItemFn}
    />
  );
};

export default IdsDynamicGrid;
