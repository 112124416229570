import XHRUpload from '@uppy/xhr-upload';
import Uppy, { UploadResult } from '@uppy/core';
import { useCallback, useEffect, useMemo } from 'react';

import {
  IBaseUploaderProps,
  IUploader,
  IUploaderValue,
  UploadType,
} from '../../../constants/uploads';
import { USER_ROLES } from '../../../constants/users';
import keycloak from '../../../keycloak';
import useIdsUploaderContext from '../../useIdsUploaderContext';
import useUppyEventHandler from '../../useUppyEventHandler';
import RuntimeConfig from '../../../RuntimeConfig';
import queryClient from '../../../utils/query';
import { useOrganizationKeys } from '../../../services/OrganizationsService';

const getXHRUploadId = (uppyId: string) => `${uppyId}-XHRUpload`;

export interface IOrgLogoUploaderProps extends IBaseUploaderProps {
  // "?" is a workaround for TS. The value is required.
  orgId?: string;
}

const useOrgLogoUploader = (enabled: boolean, { orgId }: IOrgLogoUploaderProps) => {
  const { uppy } = useIdsUploaderContext();
  const orgKeys = useOrganizationKeys();

  useEffect(() => {
    if (!enabled) return;

    const xhrId = getXHRUploadId(uppy.getID());
    const xhr = uppy.getPlugin(xhrId);
    xhr?.setOptions({
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/organizations/${orgId}/update_logo`,
    });
  }, [orgId, enabled, uppy]);

  const handleUploadComplete = useCallback(
    async (result: UploadResult) => {
      if (!result.successful.length) return;

      await queryClient.invalidateQueries(orgKeys.detail(orgId));
      await queryClient.invalidateQueries(orgKeys.route(orgId));
    },
    [orgId, orgKeys],
  );

  useUppyEventHandler('complete', handleUploadComplete, uppy, enabled);

  return useMemo<IUploaderValue | null>(
    () =>
      enabled
        ? ({
            formProps: {
              initialValues: {},
              successMessage: 'Organization logo updated',
              errorHandler: () => 'Organization logo could not be updated',
            },
            uploadSteps: [],
          } as IUploaderValue)
        : null,
    [enabled],
  );
};

export const OrgLogoUploader: IUploader = {
  uploadType: UploadType.OrgLogo,
  label: 'Organization logo',
  uppyRestrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['.jpg', '.jpeg', '.png'],
    // 200 KB is the general guideline I'm seeing online
    // For some reason uppy is 5 kb off in it's calculation, so this limit is set to 205 KB
    maxFileSize: 205 * 1000,
  },
  isAvailable: (_, __, userHasOneOfRoles) => {
    return userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN]);
  },
  useUploader: useOrgLogoUploader,
  configureUppy: (uppy: Uppy) => {
    const xhrId = getXHRUploadId(uppy.getID());

    if (!uppy.getPlugin(xhrId)) {
      // Plugin not already installed
      uppy.use(XHRUpload, {
        id: xhrId,
        endpoint: '',
        method: 'PUT',
        fieldName: 'logo',
        allowedMetaFields: [],
        headers: () => ({
          // Ensure the keycloak token is updated before uploading
          authorization: `Bearer ${keycloak.token}`,
          'x-client-id': 'Explorer',
        }),
      });
    }
  },
  deconfigureUppy: (uppy: Uppy) => {
    const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
    if (xhr) {
      uppy.removePlugin(xhr);
    }
  },
  queueUpload: false,
};

export default useOrgLogoUploader;
