import React, { useEffect } from 'react';
import { NavigateOptions, useParams, Outlet, useNavigate } from 'react-router-dom';

import { UseQueryOptions, UseQueryResult } from 'react-query';

import LoadingScreen from '../LoadingScreen';
import Error from '../../views/Error';
import usePrevious from '../../hooks/usePrevious';

const defaultRenderLoading = () => <LoadingScreen />;

export interface IEntityRouteProps {
  idParam: string;
  defaultParamValue?: any;

  // Probably no way to infer type of entity.
  // And it's actually not needed
  currentEntity: any;
  currentEntityFieldToCheck?: string;
  setEntity: (entity: any) => any;
  useEntity: (entityId: string | undefined, options: UseQueryOptions) => UseQueryResult;
  entitySelector: (data: any, entityId: string | undefined) => any;
  errorRedirectRoute: string;
  errorRedirectOptions?: NavigateOptions;
  renderLoading?: () => React.ReactElement;
}

/**
 * This component is used to load entities like
 * project, program, route, locations, etc.
 */
const EntityRoute: React.FC<IEntityRouteProps> = ({
  idParam,
  defaultParamValue,
  currentEntity,
  currentEntityFieldToCheck = 'id',
  setEntity,
  useEntity,
  entitySelector,
  errorRedirectRoute,
  errorRedirectOptions,
  renderLoading = defaultRenderLoading,
}) => {
  const navigate = useNavigate();
  const { [idParam]: entityId } = useParams();

  const _entityId = entityId || defaultParamValue;

  const { data, error, isLoading } = useEntity(_entityId, {
    retry: false,
    enabled: _entityId !== undefined,
  });

  const entity = entitySelector(data, _entityId);
  const prevEntity = usePrevious(entity);

  const entityChanged =
    !currentEntity ||
    _entityId !== currentEntity[currentEntityFieldToCheck] ||
    entity !== prevEntity; // Allow entity to be updated in case of query invalidation

  useEffect(() => {
    // Only set the entity if it has changed
    if (entityChanged && !isLoading && !error) {
      setEntity(entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => setEntity(null); // Clear entity data on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (entityChanged) {
    if (isLoading) return renderLoading();
    if (error) return <Error />;

    if (!entity) {
      // Entity should be loaded
      navigate(errorRedirectRoute || '/', errorRedirectOptions);
      return null;
    }
  }

  // Ensure the entity matches the entityId before rendering child routes
  return !entityChanged && currentEntity ? <Outlet /> : renderLoading();
};

export default EntityRoute;
