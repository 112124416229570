import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { activeTenantState } from '../../atoms/tenants';
import { activeOrganizationState } from '../../atoms/organizations';
import { getOrgAssignmentRoute } from '../../utils/routes';

import { AssignmentIcon, SMALL_ICON_SIZE } from '../../theme/icons';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface IAssignmentChipProps extends IEntityChipProps {
  id: string;
  name?: string;
}

/**
 * @TODO: may be needed to add this component to EntityParentChip.
 */
const AssignmentChip: React.FC<IAssignmentChipProps> = ({ id, name, ...rest }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);

  const label = name || `Assignment #${id}`;

  const entityRoute = useMemo(
    () =>
      getOrgAssignmentRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        assignmentId: id,
      }),
    [activeTenant, organization, id],
  );

  return (
    <EntityChip
      icon={<AssignmentIcon size={SMALL_ICON_SIZE} />}
      label={label}
      entityRoute={entityRoute}
      {...rest}
    />
  );
};

export default AssignmentChip;
