import { ElementType } from 'react';

import { MEDIA_TYPES, MediaType, IMediaTypeDef, ImageType } from './media';

export const AssignmentQuestionType = {
  TEXT: 'text',
  SINGLE_CHOICE: 'single_choice',
  MULTIPLE_CHOICE: 'multiple_choice',
  PHOTO_SIMPLE: 'photo_simple',
  PHOTO_HD: 'photo_hd',
  PHOTO_360: 'photo_360',
  DATE: 'date',
  NUMBER: 'number',
  BARCODE: 'barcode',
  RADIO: 'radio',
} as const;

export const AssignmentQuestionConditionType = {
  EQUAL: 'equal',
  NOT_EQUAL: 'not_equal',
  IN_LIST: 'in_list',
  LESS_THAN: 'less_than',
  GREATER_THAN: 'greater_than',
} as const;

export type AssignmentQuestionConditionTypes =
  (typeof AssignmentQuestionConditionType)[keyof typeof AssignmentQuestionConditionType];

export type AssignmentQuestionTypes =
  (typeof AssignmentQuestionType)[keyof typeof AssignmentQuestionType];

export const ImageQuestionTypes: AssignmentQuestionTypes[] = [
  AssignmentQuestionType.PHOTO_SIMPLE,
  AssignmentQuestionType.PHOTO_HD,
  AssignmentQuestionType.PHOTO_360,
];

export const isImageQuestion = (questionType: AssignmentQuestionTypes) =>
  ImageQuestionTypes.includes(questionType);

export const ImageQuestionTypeToMediaTypes: Partial<Record<AssignmentQuestionTypes, ImageType[]>> =
  {
    [AssignmentQuestionType.PHOTO_SIMPLE]: [MediaType.ProjectPhoto],
    [AssignmentQuestionType.PHOTO_HD]: [MediaType.HDPhoto, MediaType.PanoramicPhoto],
    [AssignmentQuestionType.PHOTO_360]: [MediaType.PanoramicPhoto],
  };

export interface QuestionTypeIcons {
  icon?: ElementType;
  outlinedIcon?: ElementType;
}

export const AssignmentQuestionTypeToIcons: Partial<
  Record<AssignmentQuestionTypes, QuestionTypeIcons>
> = {
  [AssignmentQuestionType.PHOTO_SIMPLE]: {
    icon: MEDIA_TYPES[MediaType.ProjectPhoto].icon,
    outlinedIcon: MEDIA_TYPES[MediaType.ProjectPhoto].outlinedIcon,
  },
  [AssignmentQuestionType.PHOTO_HD]: {
    icon: MEDIA_TYPES[MediaType.HDPhoto].icon,
    outlinedIcon: MEDIA_TYPES[MediaType.HDPhoto].outlinedIcon,
  },
  [AssignmentQuestionType.PHOTO_360]: {
    icon: MEDIA_TYPES[MediaType.PanoramicPhoto].icon,
    outlinedIcon: MEDIA_TYPES[MediaType.PanoramicPhoto].outlinedIcon,
  },
};

export const ImageAnswerTypeToMediaType = Object.values(MEDIA_TYPES).reduce(
  (typeToMediaType: Record<string, IMediaTypeDef>, typeDef: IMediaTypeDef) => {
    if (typeDef.imageAnswerType) {
      typeToMediaType[typeDef.imageAnswerType] = typeDef;
    }
    return typeToMediaType;
  },
  {},
);
