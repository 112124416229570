import React from 'react';
import { Badge, IconButton } from '@mui/material';

import { UploadIcon } from '../../../theme/icons';
import useUploadQueueContext from '../../../hooks/useUploadQueueContext';
import useMountedEffect from '../../../hooks/useMountedEffect';

import styles from './Uploads.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUploadsProps {}

const Uploads: React.FC<IUploadsProps> = () => {
  const { toggleUploadQueueOpen, setAnchorElement, useQueue } = useUploadQueueContext();
  const queue = useQueue();

  useMountedEffect(() => {
    return () => {
      setAnchorElement(null);
    };
  });

  return (
    <IconButton onClick={toggleUploadQueueOpen} ref={setAnchorElement} className={styles.icon}>
      <Badge color='secondary' badgeContent={queue.length}>
        <UploadIcon />
      </Badge>
    </IconButton>
  );
};

export default Uploads;
