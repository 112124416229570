import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { activeProjectState } from '../../../../atoms/projects';
import {
  useCreateProjectDocument,
  useGetProjectDocuments,
} from '../../../../services/DocumentsService';
import { useGetLocationMetadataTypes } from '../../../../services/LocationService';
import Error from '../../../Error';
import LoadingScreen from '../../../../components/LoadingScreen';
import DocumentsList, { IOnCreateParentEntityDocument } from '../../../Documents/DocumentsList';

import { VIEW_TYPES } from '../../../../constants/viewTypes';

import usePermissions from '../../../../hooks/usePermissions';

import { USER_ROLES } from '../../../../constants/users';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProjectDocumentsTabProps {}

const ProjectDocumentsTab: React.FC<IProjectDocumentsTabProps> = () => {
  const { userHasOneOfRoles } = usePermissions();

  let userViewType: keyof typeof VIEW_TYPES = VIEW_TYPES.infoTab;
  if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM, USER_ROLES.ORG_ADMIN])) {
    userViewType = VIEW_TYPES.all;
  }
  const activeProject = useRecoilValue(activeProjectState);

  const { mutateAsync } = useCreateProjectDocument();
  const onCreateParentEntityDocument = useCallback(
    (values: IOnCreateParentEntityDocument) => {
      return mutateAsync({
        ...values,
        parentId: activeProject!.id,
      });
    },
    [activeProject, mutateAsync],
  );

  const {
    data: locationMetadataTypes,
    isLoading: isLoadingLocationMetadataTypes,
    isError: isErrorLocationMetadataTypes,
  } = useGetLocationMetadataTypes(activeProject!.locationId);

  const {
    data: projectDocuments,
    isLoading: isLoadingProjectDocuments,
    isError: isErrorProjectDocuments,
  } = useGetProjectDocuments(activeProject!.id, {
    viewType: userViewType,
  });

  if (isErrorLocationMetadataTypes || isErrorProjectDocuments) {
    return <Error />;
  }

  if (
    isLoadingProjectDocuments ||
    isLoadingLocationMetadataTypes ||
    !projectDocuments?.project?.projectDocuments?.edges ||
    !locationMetadataTypes?.location?.metadataTypes
  ) {
    return <LoadingScreen />;
  }

  return (
    <DocumentsList
      documents={projectDocuments.project.projectDocuments.edges}
      onCreateParentEntityDocument={onCreateParentEntityDocument}
      metadataTypes={locationMetadataTypes.location.metadataTypes}
      disableProgramDocumentEdit={true}
    />
  );
};

export default ProjectDocumentsTab;
