import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem, Divider, ListItemIcon, ListItemText } from '@mui/material';

import MapMarker from '../MapMarker';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';
import useDeckEventManagerCallback from '../../../hooks/useDeckEventManagerCallback';

import styles from './MapContextMenu.module.css';

const MapContextMenu = ({ menuItems, className, ...rest }) => {
  const { useViewport } = useImmersiveViewer();
  const viewport = useViewport();
  const [coordinate, setCoordinate] = useState();
  const [anchorEl, setAnchorEl] = useState();

  const handleMapMarkerRef = useCallback(elem => setAnchorEl(elem), [setAnchorEl]);

  const onMapClick = useCallback(
    event => {
      const { offsetCenter, rightButton } = event;

      if (rightButton) {
        // only activate on right click
        setCoordinate(viewport.unproject([offsetCenter.x, offsetCenter.y]));
      }
    },
    [viewport, setCoordinate],
  );

  useDeckEventManagerCallback('click', onMapClick, true);

  const onClose = useCallback(() => {
    setCoordinate(null);
  }, [setCoordinate]);

  const onMenuItemClick = useCallback(
    onClick => {
      onClick(coordinate);
      onClose();
    },
    [coordinate, onClose],
  );

  return (
    menuItems.length > 0 &&
    !!coordinate && (
      <>
        <MapMarker longitude={coordinate[0]} latitude={coordinate[1]}>
          <Box id='menu-anchor-ids' ref={handleMapMarkerRef} />
        </MapMarker>
        {anchorEl && (
          <Menu
            open={true}
            onClose={onClose}
            anchorEl={anchorEl}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className={styles.menu}
            PaperProps={{
              className: styles.paper,
              elevation: 24,
            }}
          >
            {menuItems.map(({ icon, label, onClick }, i) => (
              <div key={label}>
                <MenuItem onClick={() => onMenuItemClick(onClick)}>
                  {icon && <ListItemIcon fontSize='small'>{icon}</ListItemIcon>}
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
                {i < menuItems.length - 1 && <Divider />}
              </div>
            ))}
          </Menu>
        )}
      </>
    )
  );
};

MapContextMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired, // (clickCoordinate) => {}
    }),
  ),
};

export default MapContextMenu;
