import React, { useEffect, useMemo, useState } from 'react';

import {
  useLayersBuilder,
  useMetadataTypeFiltersBuilder,
} from '../../../../components/mapping/LocationMapMenu/useMenuBuilder';
import MapMenuItemToggles from '../../../../components/mapping/MapMenuItem/MapMenuItemToggles';
import MeasurementTool, {
  MEASUREMENT_TOOL_ID,
} from '../../../../components/mapping/tools/MeasurementTool';
import MapMenu from '../../../../components/mapping/MapMenu';
import { TOOLS_ID } from '../../../../components/mapping/LocationMapMenu';
import { FilterIconFilled, LayersIcon, MeasureIcon, ToolIcon } from '../../../../theme/icons';
import { IRoutePointDetails } from '../../../../services/RouteService';

import { RoutePointType } from '../../../../constants/media';

import { useGetPointsMetadataTypes } from '../hooks';

import styles from './RouteMapMenu.module.css';

export interface IRouteMapMenuProps {
  routePoints: IRoutePointDetails[];
}

const RouteMapMenu: React.FC<IRouteMapMenuProps> = ({ routePoints }) => {
  const [pointMetadataTypes, setPointMetadataTypes] = useState({});
  const [pointTypes, setPointTypes] = useState<RoutePointType[]>([]);

  const { items: layerAccordions } = useLayersBuilder(pointTypes);
  const getMetadataTypes = useGetPointsMetadataTypes();

  useEffect(() => {
    const { metadataTypes, pointTypes } = getMetadataTypes(routePoints);

    setPointMetadataTypes(metadataTypes);
    setPointTypes(pointTypes);
  }, [routePoints, getMetadataTypes]);

  const filters = useMetadataTypeFiltersBuilder(pointMetadataTypes);

  const menu = useMemo(() => {
    return [
      {
        sectionTitle: 'Layers',
        icon: <LayersIcon />,
        accordions: layerAccordions,
      },
      {
        sectionTitle: 'Filters',
        icon: <FilterIconFilled />,
        accordions: filters,
      },
      {
        sectionTitle: 'Tools',
        icon: <ToolIcon />,
        custom: (
          <MapMenuItemToggles
            itemId={TOOLS_ID}
            toggles={[
              {
                key: MEASUREMENT_TOOL_ID,
                label: 'Measure',
                icon: <MeasureIcon />,
              },
            ]}
          />
        ),
      },
    ];
  }, [layerAccordions, filters]);

  return (
    <>
      <MapMenu menu={menu} menuWrapper={false} />
      <MeasurementTool className={styles.measurementToolData} />
    </>
  );
};

export default RouteMapMenu;
