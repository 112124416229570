import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { activeLocationState } from '../../../../atoms/locations';
import useDeleteEntity from '../../../../hooks/useDeleteEntity';
import { useDeleteOverlay } from '../../../../services/OverlaysService';
import { activeOrganizationState } from '../../../../atoms/organizations';

const useDeleteOverlayAction = () => {
  const activeLocation = useRecoilValue(activeLocationState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const deleteOverlayMutation = useDeleteOverlay(activeLocation.id);
  const { deleted, deleting, handleDelete } = useDeleteEntity();

  const deleteOverlay = useCallback(
    (overlay, onDelete, onCancel) => {
      handleDelete(
        async () =>
          await deleteOverlayMutation.mutateAsync({
            id: overlay?.id,
            organizationId: activeOrg.id,
          }),
        overlay?.name,
        'This cannot be undone, the overlay will be deleted permanently.',
        'Raster Overlay',
        onDelete,
        onCancel,
      );
    },
    [deleteOverlayMutation, handleDelete, activeOrg.id],
  );

  return {
    deleted,
    deleting,
    deleteOverlay,
  };
};

export default useDeleteOverlayAction;
