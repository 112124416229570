import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { ProgramIcon } from '../../theme/icons';

import { activeOrganizationState } from '../../atoms/organizations';

import { activeTenantState } from '../../atoms/tenants';
import { getOrgProgramRoute } from '../../utils/routes';

import EntityChip, { IEntityChipProps } from './EntityChip';

export interface IProgramChipProps extends IEntityChipProps {
  programId?: string;
  programName?: string;
}

const ProgramChip: React.FC<IProgramChipProps> = ({ programId, programName, ...rest }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [organization] = useRecoilState(activeOrganizationState);

  const label = programName ? `${programName}` : `Program #${programId}`;

  return (
    <EntityChip
      icon={<ProgramIcon size={16} />}
      label={label}
      entityRoute={getOrgProgramRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        programId,
      })}
      {...rest}
    />
  );
};

export default ProgramChip;
