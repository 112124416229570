import { ListItemIcon, ListItemText } from '@mui/material';

import { useCallback, useMemo } from 'react';

import { IDocument, IDocumentVersion } from '../types';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import { DeleteOutlineIcon, DocumentIcon, EditOutlineIcon, ExportIcon } from '../../../theme/icons';
import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemMenu from '../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../components/ids-lists/IdsListItemMenuItem';
import { getDocumentCategory } from '../utils';
import useDeleteDocumentOrVersionAction from '../useDeleteDocumentOrVersionAction';
import { downloadFileFromUrl } from '../../../utils/fileDownload';

export interface IDocumentVersionListItemProps {
  document: IDocument;
  documentVersion: IDocumentVersion;
  onClick?: (document: IDocument, documentVersion: IDocumentVersion) => any;
  onClickEdit?: (document: IDocument, documentVersion: IDocumentVersion) => any;
}

export const DocumentVersionListItem: React.FC<IDocumentVersionListItemProps> = ({
  documentVersion,
  document,
  onClick,
  onClickEdit,
}) => {
  const { deleted, deleting, deleteDocumentOrVersion } = useDeleteDocumentOrVersionAction();

  const { metadata } = document?.node || {};

  const { createdAt, filename, url } = documentVersion;

  const category = useMemo(() => getDocumentCategory(metadata)?.value, [metadata]);

  const handleItemClick = useCallback(() => {
    if (onClick) {
      onClick(document, documentVersion);
    }
  }, [document, documentVersion, onClick]);
  const handleItemClickEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit(document, documentVersion);
    }
  }, [document, documentVersion, onClickEdit]);

  const downloadDocument = useCallback(() => {
    downloadFileFromUrl(filename, url);
  }, [filename, url]);

  return (
    <IdsListItem
      primaryAction={handleItemClick}
      secondaryAction={
        <IdsListItemMenu>
          <IdsListItemMenuItem icon={<ExportIcon />} onClick={downloadDocument}>
            Download
          </IdsListItemMenuItem>
          <IdsListItemMenuItem icon={<EditOutlineIcon />} onClick={handleItemClickEdit}>
            Edit
          </IdsListItemMenuItem>
          <IdsListItemMenuItem
            icon={<DeleteOutlineIcon />}
            onClick={() =>
              deleteDocumentOrVersion({
                documentItem: document,
                documentVersion: documentVersion,
                deleteMode: 'DOCUMENT_VERSION',
              })
            }
            disabled={deleting || deleted}
          >
            Delete
          </IdsListItemMenuItem>
        </IdsListItemMenu>
      }
    >
      <IdsListItemChild container flexDirection='row'>
        <IdsListItemChild xs='auto'>
          <ListItemIcon>
            <DocumentIcon />
          </ListItemIcon>
        </IdsListItemChild>
        <IdsListItemChild>
          <ListItemText
            primary={filename}
            primaryTypographyProps={{ variant: 'h6', noWrap: true }}
          />
        </IdsListItemChild>
      </IdsListItemChild>
      <IdsListItemChild xs={2}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            color: 'text.secondary',
          }}
          secondary={category}
          secondaryTypographyProps={{ color: 'text.primary', noWrap: true }}
        />
      </IdsListItemChild>
      <IdsListItemChild xs={2}>
        <ListItemText
          primary={new Date(createdAt).toLocaleDateString()}
          secondary={new Date(createdAt).toLocaleTimeString()}
        />
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default DocumentVersionListItem;
