import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import CancelButton from '../../../../components/ids-buttons/CancelButton';

const ActionButtons = ({ isEditMode, isLoading, isUpdating, setEditMode }) => {
  const { dirty, resetForm, errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (Object.keys(errors).length > 0 && !isEditMode && isSubmitting && isValidating) {
      setEditMode(true);
    }
  }, [errors, isEditMode, isSubmitting, isValidating, setEditMode]);

  return (
    <Box display='flex' alignSelf='flex-end' gap={1}>
      {isEditMode && (
        <CancelButton
          type='button'
          onClick={() => {
            resetForm();
            setEditMode(false);
          }}
        />
      )}
      <Button
        disabled={isLoading || isUpdating || (isEditMode && !dirty)}
        variant='contained'
        type={isEditMode ? 'button' : 'submit'}
        onClick={() => setEditMode(!isEditMode)}
      >
        {isEditMode ? 'Save' : 'Edit'}
      </Button>
    </Box>
  );
};

ActionButtons.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default ActionButtons;
