import { useMemo } from 'react';
import { Grid } from '@mui/material';

import clsx from 'clsx';

import { IDocument, IDocumentVersion } from '../../types';
import IdsDataAttribute from '../../../../components/IdsDataAttribute';

import IdsEditForm from '../../../../components/ids-forms/IdsEditForm';
import IdsFormTextField from '../../../../components/ids-forms/IdsFormTextField';

import IdsFormAutocompleteField from '../../../../components/ids-forms/IdsFormAutocompleteField';

import MultiValueAutocompleteOption from '../../../../components/ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';

import { IMetadataTypeValue } from '../../../../constants/media';

import { getDocumentCategory, getDocumentTags } from '../../utils';

import { validationSchema } from './validation';
import styles from './styles.module.css';

export interface IFormValues {
  description: string;
  documentName: string;
  tags: any[];
}

export interface IEditDocumentVersionProps {
  document?: IDocument | null;
  documentVersion?: IDocumentVersion | null;
  onCancel?: () => any;
  onSubmitDocumentEdit: (values: IFormValues) => any;

  optionsTags: IMetadataTypeValue[];
}

export const EditDocumentVersion: React.FC<IEditDocumentVersionProps> = ({
  document,
  documentVersion,
  onCancel,
  onSubmitDocumentEdit,
  optionsTags,
}) => {
  const { name, metadata, description } = document?.node || {};

  const { filename, user } = documentVersion || {};
  const { firstName, lastName } = user || {};

  const category = useMemo(() => getDocumentCategory(metadata)?.value, [metadata]);

  const tags = useMemo(() => getDocumentTags(metadata)?.map(it => it.id) || [], [metadata]);

  const initialValues = useMemo(
    () => ({
      documentName: name,
      description,
      tags,
    }),
    [description, name, tags],
  );

  if (!document || !documentVersion) return null;

  return (
    <Grid container spacing={2} direction='column' className={styles['wrapper']}>
      {/* 1. Document version filename */}
      <IdsDataAttribute label='Filename' value={filename} />
      {/* 2. Author name */}
      {Boolean(firstName && lastName) && (
        <IdsDataAttribute label='Author' value={`${firstName} ${lastName}`} />
      )}
      {/* 3. Document category */}
      {category && <IdsDataAttribute label='Category' value={category} />}

      {/* 4. Form (document name, description, tags) */}
      <Grid item xs>
        <IdsEditForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitDocumentEdit}
          onCancel={onCancel}
          successMessage='Document updated!'
          errorHandler={() => 'Document could not be updated'}
        >
          <IdsDataAttribute
            label='Document name *'
            disableTypography
            value={
              <IdsFormTextField
                fullWidth={false}
                name='documentName'
                required
                className={styles['fieldInput']}
              />
            }
          />
          <IdsDataAttribute
            label='Description *'
            disableTypography
            value={
              <IdsFormTextField
                fullWidth={false}
                name='description'
                required
                className={styles['fieldInput']}
              />
            }
          />
          <IdsDataAttribute
            label='Tags'
            disableTypography
            value={
              <IdsFormAutocompleteField
                name='tags'
                multiple
                className={clsx(styles['fieldInput'], styles['fieldInputAutocomplete'])}
                disableCloseOnSelect
                options={optionsTags}
                getOptionLabel={o => o.name}
                getOptionValue={o => o.id}
                renderOption={(props, o, { selected }) => (
                  <MultiValueAutocompleteOption
                    selected={selected}
                    label={o.name}
                    {...props}
                    key={o.id}
                  />
                )}
              />
            }
          />
        </IdsEditForm>
      </Grid>
    </Grid>
  );
};

export default EditDocumentVersion;
