import React, { useCallback, useMemo } from 'react';
import { Grid, TextField, TextFieldProps } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { activeProjectState } from '../../../../../atoms/projects';

import {
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  RoutePointType,
} from '../../../../../constants/media';
import IdsAutocomplete, {
  IIdsAutocompleteProps,
} from '../../../../../components/ids-inputs/IdsAutocomplete';
import { isHeadingValid } from '../../../../../utils/geospatial';
import { useGetLocationPosition } from '../../../../../services/LocationService';

import { ISettings, MetadataType } from './types';
import { getOptionLabel, getOptionValue, imageTypeOptions, renderOption } from './helpers';
import useMetadataOptions from './useMetadataOptions';

const defaultMetadataFields = [MediaMetadataType.Level, MediaMetadataType.Area];
const projectPhotoMetadataFields = [
  MediaMetadataType.PhotoType,
  MediaMetadataType.PhotoCategory,
  ...defaultMetadataFields,
];

const useSettings = (
  disabled: boolean,
  disableImageFields: boolean,
  settings: ISettings,
  setSettings: React.Dispatch<React.SetStateAction<ISettings>>,
) => {
  const activeProject = useRecoilValue(activeProjectState);

  // This will be cached data
  const { data: locationPosition } = useGetLocationPosition(activeProject!.locationId);

  const metadataFields = useMemo(() => {
    return settings.imageType === RoutePointType.ProjectPhoto
      ? projectPhotoMetadataFields
      : defaultMetadataFields;
  }, [settings.imageType]);

  const onChangeImageType = useCallback<NonNullable<IIdsAutocompleteProps['onChange']>>(
    (_, value) => {
      setSettings(prev => {
        const rest = { ...prev };

        if (value !== RoutePointType.ProjectPhoto) {
          delete rest[MediaMetadataType.PhotoCategory];
          delete rest[MediaMetadataType.PhotoType];
        } else {
          rest[MediaMetadataType.PhotoType] = null;
          rest[MediaMetadataType.PhotoCategory] = null;
        }

        if (value !== RoutePointType.Panorama && value !== RoutePointType.DslrPanorama) {
          delete rest.heading;
        } else {
          rest.heading = locationPosition?.location.heading || 0;
        }

        return { ...rest, imageType: value };
      });
    },
    [setSettings, locationPosition],
  );

  const onChangeHeading = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const value = e.target.value === '' ? 0 : parseFloat(e.target.value);

      if (!isHeadingValid(value)) {
        return;
      }

      setSettings(prev => ({ ...prev, heading: value }));
    },
    [setSettings],
  );

  const onFocusTextField = useCallback<NonNullable<TextFieldProps['onFocus']>>(event => {
    event.target.select();
  }, []);

  const onChangeMetadata = useCallback(
    (type: MediaMetadataType) => {
      return ((_, value) =>
        setSettings(prev => ({ ...prev, [type]: value }))) as IIdsAutocompleteProps['onChange'];
    },
    [setSettings],
  );

  const { metadataOptions } = useMetadataOptions();

  const renderMetadataOption = useCallback(
    (type: MetadataType, disabled: boolean, required = true) => {
      return (
        <Grid item xs key={type}>
          <IdsAutocomplete
            label={MEDIA_METADATA_TYPES[type].label}
            value={settings[type] ?? null}
            options={metadataOptions ? metadataOptions[type] : []}
            disabled={disabled}
            required={required}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onChange={onChangeMetadata(type)}
          />
        </Grid>
      );
    },
    [onChangeMetadata, settings, metadataOptions],
  );

  return (
    <>
      <Grid item xs>
        {/* Image fields */}
        <IdsAutocomplete
          label='Image Type'
          value={settings.imageType}
          disabled={disabled || disableImageFields}
          options={imageTypeOptions}
          required
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          onChange={onChangeImageType}
        />
      </Grid>
      {(settings.imageType === RoutePointType.Panorama ||
        settings.imageType === RoutePointType.DslrPanorama) && (
        <Grid item xs>
          <TextField
            fullWidth
            required
            value={settings.heading}
            label='Heading'
            disabled={disabled}
            size='small'
            onChange={onChangeHeading}
            onFocus={onFocusTextField}
          />
        </Grid>
      )}

      {/* Metadata fields */}
      {metadataFields.map(t =>
        renderMetadataOption(t as MetadataType, disabled, t !== MediaMetadataType.Area),
      )}
    </>
  );
};

export default useSettings;
