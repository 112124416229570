import { Grid, Typography } from '@mui/material';

import { useMemo } from 'react';

import IdsDialog from '../../../components/IdsDialog';
import IdsFormFileSelectField from '../../../components/ids-forms/IdsFormFileSelectField';

import IdsCreateForm from '../../../components/ids-forms/IdsCreateForm';

import { IDocument } from '../types';

import IdsDataAttribute from '../../../components/IdsDataAttribute';

import { getDocumentCategory, getDocumentTags } from '../utils';

import styles from './styles.module.css';
import { validationSchema } from './validation';

export interface IFormValues {
  description: string;
  tags: any[];
  file: FileList | null;
}

export interface INewDocumentVersionModalProps {
  documentItem?: IDocument | null;
  open: boolean;
  onOpen: () => any;
  onClose: () => any;
  onSubmit: (value: IFormValues) => any;
  onCancel?: () => any;
}

export const NewDocumentVersionModal: React.FC<INewDocumentVersionModalProps> = ({
  open,
  onOpen,
  onClose,
  documentItem,
  onSubmit,
}) => {
  const { name, metadata } = documentItem?.node || {};

  const category = useMemo(() => getDocumentCategory(metadata)?.value, [metadata]);

  const documentTags = useMemo(
    () =>
      getDocumentTags(metadata)
        ?.map(it => it.value)
        .join(', '),
    [metadata],
  );

  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='New Document Version'
      dialogKey='create-new-document-version'
      maxWidth='md'
      fullWidth
      noSearchParam
    >
      <Grid container spacing={2} direction='column' className={styles['wrapper']}>
        <Grid item xs='auto'>
          <Typography variant='body1'>
            Allowed formats for upload file are .zip, .xls(x), .doc(x), .pdf, .cad, .dxf, .dwf,
            .dwg, .rvt, .las, .xyz, .ppt
          </Typography>
        </Grid>
        <Grid item xs='auto' className={styles['maxUploadLengthTextContainer']}>
          <Typography variant='body1'>Maximum file upload time: 30 minutes</Typography>
        </Grid>
        <Grid item xs='auto'>
          <IdsCreateForm
            initialValues={{
              description: documentItem?.node.description,
              tags: documentTags,
              file: null,
            }}
            validationSchema={validationSchema}
            successMessage='New document version was created'
            errorHandler={() => 'Some error happen with creating new document version'}
            onSubmit={onSubmit}
            onCancel={onClose}
          >
            <Grid container spacing={2} direction='column'>
              {/* Document name */}
              <IdsDataAttribute label='Document name' value={name} />
              {/* Document category */}
              <IdsDataAttribute label='Document category' value={category} />
              {/* Document description */}
              <IdsDataAttribute
                label='Document description'
                value={documentItem?.node.description}
              />
              {/* Document tags */}
              <IdsDataAttribute label='Document tags' value={documentTags} />
              {/* Field: Document file */}
              <Grid item xs='auto'>
                <IdsFormFileSelectField
                  name='file'
                  required
                  accept='.zip,.xls,.xlsx,.doc,.docx,.pdf,.cad,.dxf,.dwf,.dwg,.rvt,.las,.xyz,.ppt,.pptx'
                  label='New document version file'
                />
              </Grid>
            </Grid>
          </IdsCreateForm>
        </Grid>
      </Grid>
    </IdsDialog>
  );
};

export default NewDocumentVersionModal;
