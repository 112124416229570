import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useResizeDetector } from 'react-resize-detector';

import RibbonImage from '../RibbonImage';
import IdsImageList from '../../../ids-lists/IdsImageList';
import { activeImageId } from '../../../../atoms/mediaViewer';
import { MEDIA_VIEWER_MODES } from '../../../../constants/mediaViewer';
import { TAB_SIZES } from '../MediaViewerTabs';

import { getMediaTypeFromUrn } from '../../../../utils/media';

import styles from './MediaViewerMediaTab.module.css';

const getImageUrl = img => img.previewUrl;
const getMediaType = img => getMediaTypeFromUrn(img.id);

function MediaViewerMediaTab({
  media,
  selectImage,
  unhighlightMediaMarker,
  highlightMediaMarker,
  mode,
  tabSize,
  height,
  tenantId,
}) {
  const activeId = useRecoilValue(activeImageId);
  const { width: ribbonScrollWidth, ref: ribbonScrollRef } = useResizeDetector();

  // Index of the active image in sliced media
  const activeSlicedMediaIndex = useMemo(
    () => media.findIndex(m => m.id === activeId),
    [media, activeId],
  );

  const scrollToMedia = useCallback(() => {
    if (ribbonScrollRef.current && tabSize === TAB_SIZES.RIBBON) {
      // (multiply total width of an item by the index to figure out the correct scroll value)
      // Scroll to active media and keep centered in the scroll container
      // TODO: revisit this to determine handling different widths and scrolling to the middle for each
      const ribbonImgWidth = 80; // TODO: change this to be more dynamic in the future, 80 is width of a ribbon image + it's margin
      const containerWidth = ribbonScrollRef.current.offsetWidth;
      ribbonScrollRef.current.scrollLeft =
        activeSlicedMediaIndex * ribbonImgWidth + ribbonImgWidth / 2 - containerWidth / 2;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlicedMediaIndex, tabSize]);

  const handleRibbonScrollRef = useCallback(
    container => {
      ribbonScrollRef.current = container;

      if (activeSlicedMediaIndex && media.length) {
        setTimeout(() => {
          // Timeout needed to handle initial load (scroll fails otherwise)
          scrollToMedia();
        }, 100);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeSlicedMediaIndex, scrollToMedia, media],
  );

  const _selectImage = useCallback(
    mediaId => {
      selectImage(mediaId);
      scrollToMedia();
    },
    [selectImage, scrollToMedia],
  );

  useEffect(() => {
    // Scroll to the active media in the ribbon whenever it resizes
    scrollToMedia();
  }, [ribbonScrollWidth, scrollToMedia]);

  const onImageClick = useCallback(image => _selectImage(image.id), [_selectImage]);

  const renderImage = useCallback(
    (image, props) => (
      <RibbonImage
        key={image.id}
        urn={image.id}
        flagged={image.flagged}
        className={activeId === image.id ? styles.ribbonDotImageActive : styles.ribbonDotImage}
        onMouseEnter={() =>
          image.id !== activeId && mode === MEDIA_VIEWER_MODES.IMMERSIVE
            ? highlightMediaMarker(image.id)
            : null
        }
        onMouseLeave={() =>
          image.id !== activeId && mode === MEDIA_VIEWER_MODES.IMMERSIVE
            ? unhighlightMediaMarker(image.id)
            : null
        }
        {...props}
      />
    ),
    [activeId, highlightMediaMarker, mode, unhighlightMediaMarker],
  );

  return (
    <>
      {tabSize === TAB_SIZES.RIBBON && (
        <PerfectScrollbar
          options={{ suppressScrollY: true }}
          containerRef={handleRibbonScrollRef}
          className={styles.ribbonScrollBar}
        >
          {media.map(image => (
            <RibbonImage
              tenantId={tenantId}
              key={image.id}
              urn={image.id}
              src={image.previewUrl}
              flagged={image.flagged}
              className={
                activeId === image.id ? styles.ribbonDotImageActive : styles.ribbonDotImage
              }
              onClick={() => _selectImage(image.id)}
              onMouseEnter={() =>
                image.id !== activeId && mode === MEDIA_VIEWER_MODES.IMMERSIVE
                  ? highlightMediaMarker(image.id)
                  : null
              }
              onMouseLeave={() =>
                image.id !== activeId && mode === MEDIA_VIEWER_MODES.IMMERSIVE
                  ? unhighlightMediaMarker(image.id)
                  : null
              }
              containerProps={{
                className: styles.ribbonDotImageContainer,
              }}
            />
          ))}
        </PerfectScrollbar>
      )}
      {(tabSize === TAB_SIZES.EXPANDED || tabSize === TAB_SIZES.FULL) && (
        <div style={{ height }}>
          <Grid container style={{ height: '100%' }}>
            <IdsImageList
              images={media}
              getImageUrl={getImageUrl}
              getMediaType={getMediaType}
              renderImage={renderImage}
              onImageClick={onImageClick}
              className={styles.imageList}
            />
          </Grid>
        </div>
      )}
    </>
  );
}

MediaViewerMediaTab.defaultProps = {
  mode: MEDIA_VIEWER_MODES.GALLERY,
  selectMediaId: m => m.id,
  selectMediaReps: m => m.reps,
  showAdvancedOptions: false,
  showThumbnailRibbon: true,
  hideClose: false,
  fullscreen: false,
  fullscreenButton: false,
  showFullScreenControl: false,
  dialog: true,
  initialWidth: 'full',
  lockedWidth: true,
  bottomPanelDefault: 'media',
  bottomPanelTabs: ['media'],
  noImageMessage: 'There is no image selected.',
  loadActiveImageDetail: true,
};

MediaViewerMediaTab.propTypes = {
  open: PropTypes.bool,
  mode: PropTypes.string, // options: gallery or immersive
  media: PropTypes.array.isRequired,
  activeMediaId: PropTypes.string, // Expects the urn format. Example: 'urn:immersiondata:media:panorama:199648'
  tenantId: PropTypes.string,
  loadActiveImageDetail: PropTypes.bool,
  activeImageDetail: PropTypes.shape({ image: PropTypes.object }),
  selectMediaId: PropTypes.func,
  selectMediaType: PropTypes.func,
  selectMediaReps: PropTypes.func,
  showAdvancedOptions: PropTypes.bool,
  showThumbnailRibbon: PropTypes.bool,
  hideClose: PropTypes.bool,
  fullscreen: PropTypes.bool,
  dialog: PropTypes.bool,
  initialWidth: PropTypes.string, // full, half, oneQuarter, threeQuarter
  lockedWidth: PropTypes.bool,
  fullscreenButton: PropTypes.bool,
  bottomPanelDefault: PropTypes.string,
  noImageMessage: PropTypes.string,
  bottomPanelTabs: PropTypes.array, // array of strings for tabs ["media", "info"]

  onPanoramaPan: PropTypes.func,
  onActiveMediaHover: PropTypes.func, // action to take when a KRPano hotspot or image thumbnail is hovered over
  onActiveMediaChange: PropTypes.func, // (mediaId) => {} Expected to set the activeMediaId prop
  onClose: PropTypes.func,
};

export default MediaViewerMediaTab;
