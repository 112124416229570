import React from 'react';
import { Box, Button } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IActionsProps {}

const Actions: React.FC<IActionsProps> = () => {
  return (
    <Box display='flex' flexDirection='row' alignItems='center' height='100%' gap={1}>
      <Box>
        <Button variant='outlined' color='secondary' disabled={true}>
          Import
        </Button>
      </Box>
      <Box>
        <Button variant='outlined' color='secondary' disabled={true}>
          New Asset
        </Button>
      </Box>
    </Box>
  );
};

export default Actions;
