import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { TableCell, TableRow, Tooltip, Typography, TooltipProps } from '@mui/material';

import { isTextTruncated } from '../../../../../../utils/helpers';

import styles from '../ImageInfoTable.module.css';

export interface IImageAttributeTableRowProps {
  label: React.ReactNode;
  value: TooltipProps['title'];
  singleColumnLayout: boolean;
}

const ImageAttributeTableRow: React.FC<IImageAttributeTableRowProps> = ({
  label,
  value,
  singleColumnLayout,
  ...rest
}) => {
  const valueRef = useRef();
  const [valueTruncated, setValueTruncated] = useState(false);

  const calculateTruncated = useCallback(() => {
    if (!valueRef?.current) {
      return;
    }

    setValueTruncated(isTextTruncated(valueRef.current as HTMLSpanElement));
  }, [setValueTruncated]);

  const { ref: valueResizeRef } = useResizeDetector({
    onResize: calculateTruncated,
    handleHeight: false,
  });

  const handleValueRef = useCallback(
    e => {
      valueRef.current = e;
      calculateTruncated();
    },
    [calculateTruncated],
  );

  useEffect(() => {
    calculateTruncated();
  }, [singleColumnLayout, calculateTruncated]);

  const valueText = (
    <Typography variant='body2' noWrap ref={handleValueRef}>
      {value || '-'}
    </Typography>
  );

  return (
    <TableRow ref={valueResizeRef} {...rest}>
      <TableCell className={styles.imgAttrLabelCell}>
        <Typography variant='body2' color='text.secondary' noWrap>
          {label}:
        </Typography>
      </TableCell>
      <TableCell className={styles.imgAttrValueCell}>
        {valueTruncated ? (
          <Tooltip title={value} placement='right'>
            {valueText}
          </Tooltip>
        ) : (
          valueText
        )}
      </TableCell>
    </TableRow>
  );
};

export default ImageAttributeTableRow;
