import React, { useState, useMemo } from 'react';

import { FormikHelpers } from 'formik';

import { Box, TableContainer, Table, TableBody, TableCell, TableRow, Paper } from '@mui/material';

import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import { capitalCase } from 'change-case';

import IdsForm from '../../../../../../components/ids-forms/IdsForm';
import IdsFormSelectField from '../../../../../../components/ids-forms/IdsFormSelectField';

import { Buttons } from '../../../../../../components/ids-forms/IdsEditSettingsFormButtons';

import { ASSIGNMENT_RESPONSE_STATUSES } from '../../../../../../utils/assignments';
import { IOptionValues } from '../../../Settings';

import {
  IUpdateAssignmentResponseUpdateValues,
  useUpdateAssignmentResponse,
} from '../../../../../../services/AssignmentResponsesService';
import { activeOrganizationState } from '../../../../../../atoms/organizations';

import usePermissions from '../../../../../../hooks/usePermissions';
import { USER_ROLES } from '../../../../../../constants/users';

import AssignmentResponseStatusChip from '../../AssignmentResponseStatusChip';

import LoadingScreen from '../../../../../../components/LoadingScreen';

export interface IAssignmentResponseInfoTabFragType {
  createdAt: string;
  updatedAt: string;
  startsAt?: string;
  endsAt?: string;
  status: string;
  id: string;
}

export const AssignmentResponseInfoTabFrag = gql`
  fragment AssignmentResponseInfoTabFrag on AssignmentResponse {
    createdAt
    updatedAt
    startsAt
    endsAt
    status
    id
  }
`;

export interface IAssignmentResponseInfoTabProps {
  assignmentResponse: IAssignmentResponseInfoTabFragType;
  assignmentId: string;
}

const ALL_STATUS_OPTIONS: IOptionValues[] = [];
Object.entries(ASSIGNMENT_RESPONSE_STATUSES).forEach(([key, value]) =>
  ALL_STATUS_OPTIONS.push({ label: capitalCase(key), value: value }),
);

const AssignmentResponseInfoTab: React.FC<IAssignmentResponseInfoTabProps> = ({
  assignmentResponse,
  assignmentId,
}) => {
  const { createdAt, updatedAt, startsAt, endsAt, status, id } = assignmentResponse;
  const organization = useRecoilValue(activeOrganizationState);

  const { userHasOneOfRoles } = usePermissions();
  const canUpdate = userHasOneOfRoles([
    USER_ROLES.IDS_ADMIN,
    USER_ROLES.IDS_TEAM,
    USER_ROLES.TENANT_ADMIN,
    USER_ROLES.TENANT_TEAM,
  ]);
  const [isEditMode, setEditMode] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [initialValues, setInitialValues] = useState<IUpdateAssignmentResponseUpdateValues>({
    status,
  });

  const { mutateAsync: updateAssignmentResponseMutation } = useUpdateAssignmentResponse(
    id,
    assignmentId,
  );

  const LIMITED_STATUS_OPTIONS: IOptionValues[] = useMemo(() => {
    // status can only be changed by 1 step in either direction - excluding 'not_started'
    switch (status) {
      case ASSIGNMENT_RESPONSE_STATUSES.IN_PROGRESS:
        return ALL_STATUS_OPTIONS.filter(
          option =>
            option.value === ASSIGNMENT_RESPONSE_STATUSES.IN_PROGRESS ||
            option.value === ASSIGNMENT_RESPONSE_STATUSES.COMPLETED,
        );
      case ASSIGNMENT_RESPONSE_STATUSES.COMPLETED:
        return ALL_STATUS_OPTIONS.filter(
          option =>
            option.value === ASSIGNMENT_RESPONSE_STATUSES.IN_PROGRESS ||
            option.value === ASSIGNMENT_RESPONSE_STATUSES.COMPLETED ||
            option.value === ASSIGNMENT_RESPONSE_STATUSES.RELEASED,
        );
      case ASSIGNMENT_RESPONSE_STATUSES.RELEASED:
        return ALL_STATUS_OPTIONS.filter(
          option =>
            option.value === ASSIGNMENT_RESPONSE_STATUSES.COMPLETED ||
            option.value === ASSIGNMENT_RESPONSE_STATUSES.RELEASED,
        );
      default:
        // not_started is not accounted for and so will drop to default
        // we do not want the user to able to change the status of the assignment response if it's currently 'not_started'
        return [];
    }
  }, [status]);

  const onSubmitHandler = async (values: any, formikHelpers: FormikHelpers<any>) => {
    setIsUpdating(true);

    // only sending back updated values
    let updateValues = {};
    for (const [key, value] of Object.entries(values)) {
      if ((initialValues as any)[key] && (initialValues as any)[key] !== values[key]) {
        updateValues = {
          ...updateValues,
          [key]: value,
        };
      }
    }
    const result = await updateAssignmentResponseMutation({
      id,
      organizationId: organization.id,
      updateValues,
    });

    setIsUpdating(false);

    if (result.updateAssignmentResponse?.errors?.length) {
      formikHelpers.resetForm();
      throw new Error('Assignment response update failed');
    }

    setInitialValues(values);
  };

  if (isUpdating) {
    return <LoadingScreen />;
  }

  return (
    <Box position='relative'>
      <IdsForm
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        errorHandler={(errorMessage, formikHelpers) => {
          formikHelpers.resetForm();
          return errorMessage;
        }}
        successMessage='Assignment response updated.'
      >
        <Box display='flex' flexDirection='column' gap={1} p={2}>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Created
                  </TableCell>
                  <TableCell align='left'>{new Date(createdAt).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Updated
                  </TableCell>
                  <TableCell align='left'>{new Date(updatedAt).toLocaleString()}</TableCell>
                </TableRow>
                {startsAt && (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      Start
                    </TableCell>
                    <TableCell align='left'>{new Date(startsAt).toLocaleString()}</TableCell>
                  </TableRow>
                )}
                {endsAt && (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      End
                    </TableCell>
                    <TableCell align='left'>{new Date(endsAt).toLocaleString()}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Status
                  </TableCell>
                  {isEditMode ? (
                    <TableCell align='left'>
                      <IdsFormSelectField
                        name='status'
                        margin='none'
                        options={LIMITED_STATUS_OPTIONS}
                        size='small'
                      />
                    </TableCell>
                  ) : (
                    <TableCell align='left'>
                      <AssignmentResponseStatusChip
                        status={status as ASSIGNMENT_RESPONSE_STATUSES}
                      />
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Buttons
            isEditMode={isEditMode}
            canUpdate={canUpdate}
            isUpdating={isUpdating}
            setEditMode={setEditMode}
          />
        </Box>
      </IdsForm>
    </Box>
  );
};

export default AssignmentResponseInfoTab;
