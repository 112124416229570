import React from 'react';
import { Button, ButtonProps, Tooltip, TooltipProps } from '@mui/material';

export interface ITooltipButtonProps {
  title: TooltipProps['title'];
  onClick: ButtonProps['onClick'];
  disabled: ButtonProps['disabled'];
  children: React.ReactNode;
}

const TooltipButton: React.FC<ITooltipButtonProps> = ({ title, onClick, disabled, children }) => (
  <Tooltip title={title} placement='left-start'>
    <span>
      <Button
        sx={{ my: 0.5 }}
        variant='outlined'
        size='small'
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </span>
  </Tooltip>
);

export default TooltipButton;
