import * as Yup from 'yup';

const areaOfInterestValidation = {
  name: Yup.string().required('Name is required'),
  levelId: Yup.string().typeError('Level is required').required('Level is required'),
  data: Yup.string().required('Information is required'),
  color: Yup.string().required('Color is required'),
  tags: Yup.array().of(Yup.string()),
};

const createAreaOfInterestValidation = {
  ...areaOfInterestValidation,
  projectId: Yup.string().typeError('Project is required').required('Project is required'),
};

export const createAreaOfInterestValidationSchema = Yup.object().shape(
  createAreaOfInterestValidation,
);
export const updateAreaOfInterestValidationSchema = Yup.object().shape(areaOfInterestValidation);
