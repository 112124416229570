import { gql } from 'graphql-request';
import { useMemo } from 'react';

import { useMutation } from 'react-query';

import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import queryClient from '../utils/query';

import { MEDIA_TYPES, MediaType } from '../constants/media';

import { useLocationKeys } from './LocationService';

export const usePointOfInterestKeys = () => {
  const queryIdKey = useQueryKeyId();
  const locationKeys = useLocationKeys();

  return useMemo(() => {
    const pointOfInterestKeys = {
      all: [MEDIA_TYPES[MediaType.PointOfInterest].type, ...queryIdKey],
      lists: () => [...pointOfInterestKeys.all, 'list'],
      locationList: locationId => [
        ...pointOfInterestKeys.lists(),
        ...locationKeys.detail(locationId),
      ],
      map: locationId => [...pointOfInterestKeys.locationList(locationId), 'map'],
      route: id => [...pointOfInterestKeys.all, id],
      detail: id => [...pointOfInterestKeys.route(id), 'detail'],
    };

    return pointOfInterestKeys;
  }, [queryIdKey, locationKeys]);
};

const POIFieldsFrag = gql`
  fragment POIFieldsFrag on PointOfInterest {
    id
    projectId
    creator {
      id
      firstName
      lastName
    }
    name
    data
    color
    flagged
    position {
      latitude
      longitude
    }
    createdAt
    metadata {
      id
      type
      value
    }
  }
`;

const CreatePOIMutation = gql`
  mutation CreatePointOfInterest(
    $input: CreatePointOfInterestInput!
    $where: WhereOrganizationInput!
  ) {
    createPointOfInterest(input: $input, where: $where) {
      pointOfInterest {
        ...POIFieldsFrag
      }
    }
  }
  ${POIFieldsFrag}
`;

const DeletePOIMutation = gql`
  mutation DeletePointOfInterest($where: WhereUniqueIdOrganizationInput!) {
    deletePointOfInterest(where: $where) {
      success
      errors {
        field
        message
      }
    }
  }
`;

const UpdatePOIMutation = gql`
  mutation UpdatePointOfInterest(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdatePointOfInterestInput!
  ) {
    updatePointOfInterest(where: $where, input: $input) {
      pointOfInterest {
        ...POIFieldsFrag
      }
      errors {
        message
        field
      }
    }
  }
  ${POIFieldsFrag}
`;

const createPointOfInterest = ({
  color,
  data,
  flagged,
  locationId,
  metadata, // [{ id, type }, ...]
  name,
  organizationId,
  position,
  projectId,
}) => {
  return buildGraphMutationFn(CreatePOIMutation)({
    input: {
      color,
      data,
      flagged,
      locationId,
      metadata,
      name,
      organizationId,
      position,
      projectId,
    },
    where: { organizationId },
  });
};

const onError = (error, variables, context) => {
  context?.mutation?.reset();
};

const useInvalidateQueries = () => {
  const pointOfInterestKeys = usePointOfInterestKeys();
  const locationKeys = useLocationKeys();

  return locationId => {
    // Only invalidate point of interest list queries in the context of the location
    queryClient.invalidateQueries(pointOfInterestKeys.locationList(locationId), {
      refetchActive: false, // don't refetch map queries
    });

    // Invalidate location map query that gets POI total count
    queryClient.invalidateQueries(locationKeys.map(locationId), {
      refetchActive: false, // don't refetch map queries
    });
  };
};

export function useCreatePointOfInterest(locationId) {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(createPointOfInterest, {
    onError,
    onSuccess: () => {
      invalidateQueries(locationId);
    },
  });
}

const deletePointOfInterest = ({ organizationId, id }) => {
  return buildGraphMutationFn(DeletePOIMutation)({
    where: {
      id,
      organizationId,
    },
  });
};

export function useDeletePointOfInterest(locationId) {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(deletePointOfInterest, {
    onError,
    onSuccess: () => {
      invalidateQueries(locationId);
    },
  });
}

const updatePointOfInterest = ({ organizationId, id, ...rest }) => {
  return buildGraphMutationFn(UpdatePOIMutation)({
    input: {
      ...rest,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export function useUpdatePointOfInterest(locationId) {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(updatePointOfInterest, {
    onError,
    onSuccess: () => {
      invalidateQueries(locationId);
    },
  });
}
