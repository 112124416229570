/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import merge from 'lodash.merge';
import { createTheme as _createTheme, responsiveFontSizes } from '@mui/material/styles';

import isPhygii from '../utils/phygii/isPhygii';

import { explorerThemeConfigs, baseConfig } from './configs/explorer';
import { phygiiThemeConfigs } from './configs/phygii';

const defaultSettings = {};

export function createTheme(settings = defaultSettings) {
  const themeConfigs = isPhygii() ? phygiiThemeConfigs : explorerThemeConfigs;

  let themeConfig = themeConfigs.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = _createTheme(merge({}, baseConfig, themeConfig, { direction: settings.direction }));

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
