import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar } from '@mui/material';

import TenantLogo from '../TenantLogo';
import { THEMES } from '../../constants';

import { getTenantsRoute } from '../../utils/routes';

import Account from './Account';
import Uploads from './Uploads';

export const TOP_BAR_HEIGHT = 64;

const styles = {
  root: theme => ({
    // Disabling this style allows drawer to show on top of top bar for mobile view
    // zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  }),
  toolbar: {
    minHeight: TOP_BAR_HEIGHT,
  },
};

function TopBar({ hideUploads, children, ...rest }) {
  return (
    <AppBar sx={styles.root} {...rest}>
      <Toolbar sx={styles.toolbar}>
        <Box>
          <RouterLink to={getTenantsRoute({})}>
            <TenantLogo width='86' height='40' fallbackLogo='light' />
          </RouterLink>
        </Box>
        <Box ml={2} flexGrow={1} />
        {children}
        {/* <Settings /> */}
        {!hideUploads && <Uploads />}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
