export const sortAlphabetically = (a: string, b: string) => {
  const lA = a.toLowerCase();
  const lB = b.toLowerCase();

  return lA < lB ? -1 : lA > lB ? 1 : 0;
};

interface IItemCreatedAtDate {
  createdAt: string;
}

interface INodeItemCreatedAtDate {
  node: IItemCreatedAtDate;
}

export const sortNodeItemsByDate = (a: INodeItemCreatedAtDate, b: INodeItemCreatedAtDate) => {
  return new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime();
};

export const sortItemsByDate = (a: IItemCreatedAtDate, b: IItemCreatedAtDate) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};
