import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  getOrgProjectRoute,
  getOrgProjectRoutesRoute,
  getOrgProjectsRoute,
} from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';
import { activeProjectState } from '../../../../atoms/projects';
import { activeOrganizationState } from '../../../../atoms/organizations';

const useBreadcrumbs = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProject = useRecoilValue(activeProjectState);

  return useMemo(() => {
    return [
      {
        label: 'Projects',
        route: getOrgProjectsRoute({
          subdomain: activeTenant!.subdomain,
          orgId: activeOrg.id,
        }),
      },
      {
        label: activeProject!.name,
        route: getOrgProjectRoute({
          subdomain: activeTenant!.subdomain,
          orgId: activeOrg.id,
          projectId: activeProject!.id,
        }),
      },
      {
        label: 'Routes',
        route: getOrgProjectRoutesRoute({
          projectId: activeProject!.id,
          subdomain: activeTenant!.subdomain,
          orgId: activeOrg.id,
        }),
      },
    ];
  }, [activeOrg, activeTenant, activeProject]);
};

export default useBreadcrumbs;
