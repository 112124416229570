import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback, useEffect } from 'react';

import { completedFilterState } from '../../atoms/immersiveViewer';
import { IFilterTarget } from '../../context/FilterContext';
import { IRoutePoint } from '../../views/Projects/RouteDetailsPage/types';

import { RoutePointType } from '../../constants/media';
import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';

import useUnmountedEffect from '../utils/useUnmountedEffect';
import useUrlState from '../useUrlState';

import useBooleanFilter, { BooleanFilterMode } from './useBooleanFilter';
import { filterModesToId, idToFilterMode } from './helpers';

const filterTargets: IFilterTarget[] = [
  {
    type: RoutePointType.ProjectPhoto,
    selectFilterData: (point: IRoutePoint) => point.node.isCompleted,
  },
  {
    type: RoutePointType.DslrHdPhoto,
    selectFilterData: (point: IRoutePoint) => point.node.isCompleted,
  },
  {
    type: RoutePointType.Panorama,
    selectFilterData: (point: IRoutePoint) => point.node.isCompleted,
  },
  {
    type: RoutePointType.DslrPanorama,
    selectFilterData: (point: IRoutePoint) => point.node.isCompleted,
  },
];

export enum CompletedFilterMode {
  Inactive = 'All',
  Completed = 'Completed',
  NotCompleted = 'Not Completed',
}

export const FILTER_MODE_LABELS = {
  [BooleanFilterMode.Inactive]: CompletedFilterMode.Inactive,
  [BooleanFilterMode.True]: CompletedFilterMode.Completed,
  [BooleanFilterMode.False]: CompletedFilterMode.NotCompleted,
};

export const FILTER_NAME = 'Completed';

const booleanToCompleted = {
  [BooleanFilterMode.Inactive]: CompletedFilterMode.Inactive,
  [BooleanFilterMode.True]: CompletedFilterMode.Completed,
  [BooleanFilterMode.False]: CompletedFilterMode.NotCompleted,
};

const completedToBoolean = {
  [CompletedFilterMode.Inactive]: BooleanFilterMode.Inactive,
  [CompletedFilterMode.Completed]: BooleanFilterMode.True,
  [CompletedFilterMode.NotCompleted]: BooleanFilterMode.False,
};

const useCompletedFilter = (resetOnUnmount = false) => {
  const [completedFilterMode, setCompletedFilterMode] = useRecoilState(completedFilterState);
  const resetFilterMode = useResetRecoilState(completedFilterState);

  const { setUrlState } = useUrlState();

  const setCompletedFilter = useCallback(
    (mode: CompletedFilterMode) => {
      if (mode === completedFilterMode) {
        return;
      }

      setCompletedFilterMode(mode);
    },
    [completedFilterMode, setCompletedFilterMode],
  );

  const setBooleanFilterMode = useCallback(
    (mode: BooleanFilterMode) => {
      setCompletedFilterMode(booleanToCompleted[mode]);
    },
    [setCompletedFilterMode],
  );

  const onRemove = useCallback(() => {
    resetFilterMode();
    setUrlState(FILTER_STATE_KEYS.COMPLETED, null);
  }, [setUrlState, resetFilterMode]);

  const onApply = useCallback(() => {
    setUrlState(
      FILTER_STATE_KEYS.COMPLETED,
      filterModesToId(CompletedFilterMode)[completedFilterMode],
    );
  }, [setUrlState, completedFilterMode]);

  const { booleanFilterMode, toggleBooleanFilter } = useBooleanFilter({
    options: {
      name: FILTER_NAME,
      label: 'Completion',
      targets: filterTargets,
      onRemove,
      onApply,
    },
    booleanFilterMode: completedToBoolean[completedFilterMode],
    setBooleanFilterMode,
    filterModeLabels: FILTER_MODE_LABELS,
  });

  useEffect(() => {
    setCompletedFilterMode(booleanToCompleted[booleanFilterMode]);
  }, [booleanFilterMode, setCompletedFilterMode]);

  const unmountEffect = useCallback(() => {
    if (resetOnUnmount) {
      resetFilterMode();
    }
  }, [resetOnUnmount, resetFilterMode]);
  useUnmountedEffect(unmountEffect);

  return {
    toggleCompletedFilter: toggleBooleanFilter,
    setCompletedFilter,
    completedFilterMode,
  };
};

export const useRestoreCompletedFilterFromUrl = () => {
  const { setCompletedFilter } = useCompletedFilter();
  const { getUrlState, setUrlState } = useUrlState();

  useEffect(() => {
    const urlFilterModeId = getUrlState(FILTER_STATE_KEYS.COMPLETED);
    if (urlFilterModeId) {
      const mode = idToFilterMode(filterModesToId(CompletedFilterMode))[urlFilterModeId];

      if (mode) {
        setCompletedFilter(mode);
      } else {
        // urlFilterModeId invalid, clear the url state
        setUrlState(FILTER_STATE_KEYS.COMPLETED, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useCompletedFilter;
