import {
  CustomFieldInputType,
  IImageCustomFieldMetadata,
} from '../../services/CustomFieldsService';

// Receiving initial value based on type, multipleValues and current custom field values
export const getInitialValue = (
  type: CustomFieldInputType,
  multipleValues: boolean,
  currentCustomFieldsFiltered: IImageCustomFieldMetadata[],
) => {
  switch (type) {
    case CustomFieldInputType.autocomplete:
    case CustomFieldInputType.select:
    case CustomFieldInputType.checkbox:
    case CustomFieldInputType.radio:
      if (multipleValues) {
        return currentCustomFieldsFiltered ? currentCustomFieldsFiltered.map(it => it.id) : [];
      }
      return currentCustomFieldsFiltered ? currentCustomFieldsFiltered[0]?.id : null;

    // Below are not working properly right now
    // They are separated from other cases, to not miss them
    case CustomFieldInputType.text:
    case CustomFieldInputType.textarea:
      return '';
    case CustomFieldInputType.datepicker:
      return null;
  }
};
