import React from 'react';
import { Box, BoxProps, IconButton, IconButtonProps } from '@mui/material';

import styles from './FilledIconButton.module.css';

const sxStyles = {
  container: {
    backgroundColor: 'background.paper',
  },
};

export interface IFilledIconButtonProps extends IconButtonProps {
  backgroundProps?: BoxProps;
}

const FilledIconButton: React.FC<IFilledIconButtonProps> = ({
  backgroundProps = {},
  children,
  ...rest
}) => {
  const { className: bgClassName, sx: bgSx, ...restBackgroundProps } = backgroundProps;

  return (
    // Wrapped IconButton in box to support background color while still
    // utilizing hover feedback
    <Box
      sx={{ ...sxStyles.container, ...bgSx }}
      className={`${styles.container} ${bgClassName}`}
      {...restBackgroundProps}
    >
      <IconButton {...rest}>{children}</IconButton>
    </Box>
  );
};

export default FilledIconButton;
