import { gql } from 'graphql-request';
import { useMemo } from 'react';

import { useMutation } from 'react-query';

import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import queryClient from '../utils/query';

import { MEDIA_TYPES, MediaType } from '../constants/media';

import { useLocationKeys } from './LocationService';

export const useAreaOfInterestKeys = () => {
  const queryIdKey = useQueryKeyId();
  const locationKeys = useLocationKeys();

  return useMemo(() => {
    const areaOfInterestKeys = {
      all: [MEDIA_TYPES[MediaType.AreaOfInterest].type, ...queryIdKey],
      lists: () => [...areaOfInterestKeys.all, 'list'],
      locationList: locationId => [
        ...areaOfInterestKeys.lists(),
        ...locationKeys.detail(locationId),
      ],
      map: locationId => [...areaOfInterestKeys.locationList(locationId), 'map'],
      route: id => [...areaOfInterestKeys.all, id],
      detail: id => [...areaOfInterestKeys.route(id), 'detail'],
    };

    return areaOfInterestKeys;
  }, [queryIdKey, locationKeys]);
};

const AOIFieldsFrag = gql`
  fragment AOIFieldsFrag on AreaOfInterest {
    id
    projectId
    creator {
      id
      firstName
      lastName
    }
    name
    data
    color
    flagged
    position {
      latitude
      longitude
    }
    createdAt
    metadata {
      id
      type
      value
    }
    points {
      latitude
      longitude
    }
    area
    distance
  }
`;

const CreateAOIMutation = gql`
  mutation CreateAreaOfInterest(
    $input: CreateAreaOfInterestInput!
    $where: WhereOrganizationInput!
  ) {
    createAreaOfInterest(input: $input, where: $where) {
      areaOfInterest {
        ...AOIFieldsFrag
      }
    }
  }
  ${AOIFieldsFrag}
`;

const DeleteAOIMutation = gql`
  mutation DeleteAreaOfInterest($where: WhereUniqueIdOrganizationInput!) {
    deleteAreaOfInterest(where: $where) {
      success
      errors {
        field
        message
      }
    }
  }
`;

const UpdateAOIMutation = gql`
  mutation UpdateAreaOfInterest(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateAreaOfInterestInput!
  ) {
    updateAreaOfInterest(where: $where, input: $input) {
      areaOfInterest {
        ...AOIFieldsFrag
      }
      errors {
        message
        field
      }
    }
  }
  ${AOIFieldsFrag}
`;

const createAreaOfInterest = ({
  color,
  data,
  flagged,
  locationId,
  metadata, // [{ id, type }, ...]
  name,
  organizationId,
  position, // { longitude, latitude } - center of area
  projectId,
  points, // [{ longitude, latitude }]
  area,
  distance,
}) => {
  return buildGraphMutationFn(CreateAOIMutation)({
    input: {
      color,
      data,
      flagged,
      locationId,
      metadata,
      name,
      organizationId,
      position,
      projectId,
      points,
      area,
      distance,
    },
    where: { organizationId },
  });
};

const onError = (error, variables, context) => {
  context.mutation.reset();
};

const useInvalidateQueries = () => {
  const areaOfInterestKeys = useAreaOfInterestKeys();
  const locationKeys = useLocationKeys();

  return locationId => {
    // Only invalidate area of interest list queries in the context of the location
    queryClient.invalidateQueries(areaOfInterestKeys.locationList(locationId), {
      refetchActive: false, // don't refetch map queries
    });

    // Invalidate location map query that gets AOI total count
    queryClient.invalidateQueries(locationKeys.map(locationId), {
      refetchActive: false, // don't refetch map queries
    });
  };
};

export function useCreateAreaOfInterest(locationId) {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(createAreaOfInterest, {
    onError,
    onSuccess: () => {
      invalidateQueries(locationId);
    },
  });
}

const deleteAreaOfInterest = ({ organizationId, id }) => {
  return buildGraphMutationFn(DeleteAOIMutation)({
    where: {
      id,
      organizationId,
    },
  });
};

export function useDeleteAreaOfInterest(locationId) {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(deleteAreaOfInterest, {
    onError,
    onSuccess: () => {
      invalidateQueries(locationId);
    },
  });
}

const updateAreaOfInterest = ({ organizationId, id, ...rest }) => {
  return buildGraphMutationFn(UpdateAOIMutation)({
    input: {
      ...rest,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export function useUpdateAreaOfInterest(locationId) {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(updateAreaOfInterest, {
    onError,
    onSuccess: () => {
      invalidateQueries(locationId);
    },
  });
}
