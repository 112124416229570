import React, { useMemo } from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';

import { gql } from 'graphql-request';

import IdsTabsRenderer from '../../../components/IdsTabsRenderer';

import ProjectChip from '../../../components/entity-chips/ProjectChip';

import AssignmentResponseStatusChip from '../AssignmentDetails/Responses/AssignmentResponseStatusChip';
import TaskResponsesTab, {
  TaskResponsesTabFrag,
} from '../AssignmentDetails/Responses/Detail/TaskResponsesTab';
import AssignmentResponseInfoTab, {
  AssignmentResponseInfoTabFrag,
} from '../AssignmentDetails/Responses/Detail/AssignmentResponseInfoTab';

import { IAssignmentDetailsFragType } from '../AssignmentDetails';

import { IAssignmentResponseDetailFragType } from './types';
import GenerateReportButton from './GenerateReportButton';

export const AssignmentResponseDetailFrag = gql`
  fragment AssignmentResponseDetailFrag on AssignmentResponse {
    id
    project {
      id
      name
    }
    status
    startsAt
    endsAt
    ...AssignmentResponseInfoTabFrag
    ...TaskResponsesTabFrag
  }
  ${AssignmentResponseInfoTabFrag}
  ${TaskResponsesTabFrag}
`;

export interface IAssignmentResponseDetailsProps {
  assignment: IAssignmentDetailsFragType;
  assignmentResponse: IAssignmentResponseDetailFragType;
}

export const AssignmentResponseDetails: React.FC<IAssignmentResponseDetailsProps> = ({
  assignment,
  assignmentResponse,
}) => {
  const name = useMemo(() => {
    if (!assignmentResponse) {
      return 'Assignment Response';
    }

    const { project, startsAt, endsAt } = assignmentResponse;
    if (startsAt && endsAt) {
      const formattedStartsAt = new Date(startsAt).toLocaleDateString();
      const formattedEndsAt = new Date(endsAt).toLocaleDateString();
      // Render active date range if it spans more than a day
      return `${project.name} | ${formattedStartsAt}${
        formattedStartsAt !== formattedEndsAt ? ` - ${formattedEndsAt}` : ''
      }`;
    }
    return project.name;
  }, [assignmentResponse]);

  const tabs = useMemo(
    () => [
      {
        key: 'taskResponses',
        label: 'Task Responses',
        content: <TaskResponsesTab assignmentResponse={assignmentResponse} />,
      },
      {
        key: 'info',
        label: 'Info',
        content: assignmentResponse && (
          <AssignmentResponseInfoTab
            assignmentResponse={assignmentResponse}
            assignmentId={assignment.id}
          />
        ),
      },
    ],
    [assignmentResponse, assignment.id],
  );

  const { project, status } = assignmentResponse;

  return (
    <Card>
      <Box p={2}>
        <Grid container flexDirection='column' spacing={1}>
          <Grid item xs>
            <Typography variant='h4' color='text.primary'>
              {name}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='body2' color='text.primary'>
              {assignment.title}
            </Typography>
          </Grid>
          <Grid item xs container direction='row' gap={1} alignItems='center'>
            <Grid item xs='auto'>
              <ProjectChip projectId={project.id} projectName={project.name} />
            </Grid>
            <Grid item xs='auto'>
              <AssignmentResponseStatusChip status={status as any} />
            </Grid>
            <Grid item xs container justifyContent='flex-end'>
              <Grid item xs='auto'>
                <GenerateReportButton assignmentId={assignment.id} projectId={project.id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
    </Card>
  );
};

export * from './types';

export default AssignmentResponseDetails;
