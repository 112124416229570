import React, { useCallback, useState } from 'react';
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material';

import MapOverlay from '../MapOverlay';
import { CancelIcon, SettingsIcon } from '../../../theme/icons';

import styles from './SettingsChip.module.css';

const SettingsChip = ({ label, onDelete, className, children, ...rest }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <MapOverlay className={className} {...rest}>
      <Paper elevation={3} className={styles.paper}>
        <Box className={styles.header}>
          <Box className={styles.leftContainer}>
            <Typography variant='subtitle2' noWrap className={styles.headerLabel}>
              {label}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={toggleExpanded} size='small'>
              <SettingsIcon className={styles.settingsIcon} />
            </IconButton>
            <IconButton onClick={onDelete} size='small'>
              <CancelIcon className={styles.deleteIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box style={{ ...(!expanded && { display: 'none' }) }}>
          <Divider />
          <Box className={styles.content}>{children}</Box>
        </Box>
      </Paper>
    </MapOverlay>
  );
};

export default SettingsChip;
