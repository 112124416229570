import * as Yup from 'yup';

import { emailValidation, passwordValidation } from '../../../field-validation/contactInfo';

const settingsValidation = {
  current_password: Yup.string().required('Current password is required'),
  email: emailValidation.required('Email is required'),
  password: passwordValidation.required('New password is required'),
  password_confirmation: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], 'Must match "New Password"'),
};

export const settingsValidationSchema = Yup.object().shape(settingsValidation);
