import { useCallback, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  MEDIA_TYPES,
  MediaType,
  IMetadataTypeValue,
} from '../../../../constants/media';
import { getMediaIdFromUrn, getMediaTypeFromUrn } from '../../../../utils/media';
import { buildProjectPhotoMarkerLayer } from '../../layers/media-markers';
import { buildHDPhotoMarkerLayer } from '../../layers/media-markers';
import { buildPanoramaMarkerLayer } from '../../layers/media-markers';
import useLocationMapMetadataOptions from '../../../../hooks/useLocationMapMetadataOptions';
import useMapMarkerPlacementTool, {
  useMapMarkerPlacementToolManager,
} from '../../../../hooks/useMapMarkerPlacementTool';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import useFilterContext from '../../../../hooks/useFilterContext';
import { useUpdateProjectPhoto } from '../../../../services/ProjectPhotosService';
import useSetActiveMedia from '../../../../hooks/useSetActiveMedia';
import { useUpdateHdPhoto } from '../../../../services/HDPhotosService';
import { useUpdatePanorama } from '../../../../services/PanoramasService';
import { MEDIA_TYPE_LAYER_IDS } from '../../../../constants/layerIds';
import {
  IMarkerPosition,
  IMapMarkerPlacementToolState,
} from '../../../../hooks/useMapMarkerPlacementTool';

interface IEditableImage {
  id: string;
  position: IMarkerPosition;
}

const IMAGE_MARKER_EDIT_TOOL_ID = 'image-marker-editor';

/**
 * This hook should be used to manage the image marker editor tool.
 * Must be used in tandem with the ImageMarkerEditor component below.
 */
export const useImageMarkerEditorTool = () => {
  const [image, setImage] = useState<Record<string, any> | null>();

  const { startMarkerPlacement, finishMarkerPlacement, enabled } = useMapMarkerPlacementToolManager(
    {
      toolId: IMAGE_MARKER_EDIT_TOOL_ID,
    },
  );

  const editCanceled = useRef(false);

  const { getLayerMetadata, setLayerMetadata } = useImmersiveViewer();
  const { updateTypeItem } = useFilterContext();
  const { updateActiveMediaPosition } = useSetActiveMedia();
  const { enqueueSnackbar } = useSnackbar();

  const setMediaLayersOpacity = useCallback(
    (opacity: number) => {
      Object.values(MEDIA_TYPE_LAYER_IDS).forEach(layerId => {
        const metadata = getLayerMetadata(layerId);
        setLayerMetadata(layerId, {
          ...metadata,
          opacity,
        });
      });
    },
    [getLayerMetadata, setLayerMetadata],
  );

  const allLevels = useLocationMapMetadataOptions(
    MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
  );

  const updateProjectPhotoMutation = useUpdateProjectPhoto();
  const updateHdPhotoMutation = useUpdateHdPhoto();
  const updatePanoramaMutation = useUpdatePanorama();

  const buildPanoramaMarkerLayerWithLevels = useCallback(
    (
      data: any[],
      iconClusterProps?: Record<string, any> | null,
      idsLayerProps?: Record<string, any> | null,
    ) => {
      const levelIds = allLevels.map((l: IMetadataTypeValue) => l.id);
      return buildPanoramaMarkerLayer(data, levelIds, iconClusterProps, idsLayerProps);
    },
    [allLevels],
  );

  const mediaTypeLayerBuilders = useMemo(
    () => ({
      [MEDIA_TYPES[MediaType.ProjectPhoto].type]: buildProjectPhotoMarkerLayer,
      [MEDIA_TYPES[MediaType.HDPhoto].type]: buildHDPhotoMarkerLayer,
      [MEDIA_TYPES[MediaType.PanoramicPhoto].type]: buildPanoramaMarkerLayerWithLevels,
    }),
    [buildPanoramaMarkerLayerWithLevels],
  );

  const saveMarkerEdit = useCallback(
    async (image: IEditableImage, { longitude, latitude }: IMarkerPosition) => {
      if (editCanceled.current) {
        editCanceled.current = false;
        return false;
      }

      const { id: urn } = image;

      const id = getMediaIdFromUrn(urn);
      const mediaType = getMediaTypeFromUrn(urn);

      if (!longitude || !latitude || !mediaType || !id) return;

      const mediaTypeMutation = {
        [MEDIA_TYPES[MediaType.ProjectPhoto].type]: updateProjectPhotoMutation,
        [MEDIA_TYPES[MediaType.HDPhoto].type]: updateHdPhotoMutation,
        [MEDIA_TYPES[MediaType.PanoramicPhoto].type]: updatePanoramaMutation,
      };

      const updateMutation = mediaTypeMutation[mediaType];

      try {
        await updateMutation.mutateAsync({
          id,
          latitude,
          longitude,
        });

        const { position } = image;

        const newPosition = {
          ...position, // maintain altitude if set
          longitude,
          latitude,
        };

        // Update image item with new position
        updateTypeItem(urn, mediaType, 'position', newPosition);
        updateActiveMediaPosition(newPosition);

        enqueueSnackbar('Image marker updated', { variant: 'success' });
        return true;
      } catch {
        enqueueSnackbar('Image marker could not be updated', {
          variant: 'error',
        });
        return false;
      }
    },
    [
      updateProjectPhotoMutation,
      updateHdPhotoMutation,
      updatePanoramaMutation,
      enqueueSnackbar,
      updateTypeItem,
      updateActiveMediaPosition,
    ],
  );

  const startEditingImageMarker = useCallback(
    (
      activeImage: IEditableImage,
      onMarkerPlaced: IMapMarkerPlacementToolState['onMarkerPlaced'],
    ) => {
      if (!activeImage) return;

      const { id, position, ...rest } = activeImage;
      const mediaType = getMediaTypeFromUrn(id);

      if (!mediaType) return;

      setImage(activeImage);

      // Make other media layer semi-transparent while editing
      setMediaLayersOpacity(0.5);

      // Build a special instance of the image marker layer to avoid
      // bogging down actual layer with frequent updates while editing
      const buildMarkerLayer = (
        latitude: number,
        longitude: number,
        iconClusterProps: Record<string, any>,
        idsLayerProps: Record<string, any>,
      ) => {
        const data =
          latitude && longitude
            ? [
                {
                  node: {
                    ...rest,
                    id,
                    position: {
                      ...position, // maintain altitude if set
                      longitude,
                      latitude,
                    },
                  },
                },
              ]
            : [];
        const layerBuilder = mediaTypeLayerBuilders[mediaType];
        const fullIdsLayerProps = {
          ...idsLayerProps,
          metadata: {
            // Render markers in highlighted state
            highlightedMediaIds: [id],
          },
        };
        return layerBuilder(data, iconClusterProps, fullIdsLayerProps);
      };

      const handleMarkerPlaced = async (position: IMarkerPosition) => {
        const saved = await saveMarkerEdit(activeImage, position);
        if (saved && onMarkerPlaced) {
          onMarkerPlaced(position);
        }
        setImage(null);
      };

      startMarkerPlacement(
        buildMarkerLayer,
        handleMarkerPlaced,
        null,
        null,
        position.latitude,
        position.longitude,
      );
    },
    [startMarkerPlacement, mediaTypeLayerBuilders, setImage, saveMarkerEdit, setMediaLayersOpacity],
  );

  const finishEditingImageMarker = useCallback(
    (canceled = false) => {
      if (!image) return;

      editCanceled.current = canceled;
      finishMarkerPlacement();

      // Restore media layers opacity to opaque
      setMediaLayersOpacity(1);
    },
    [finishMarkerPlacement, image, setMediaLayersOpacity],
  );

  return {
    startEditingImageMarker,
    finishEditingImageMarker,
    enabled,
  };
};

/**
 * Consumer of `useMapMarkerPlacementTool` to enable the imager marker editor.
 * Consumed in an empty component to prevent frequent internal hook state
 * changes from bogging down rendering of components that use the tool.
 */
const ImageMarkerEditorTool = () => {
  useMapMarkerPlacementTool({ toolId: IMAGE_MARKER_EDIT_TOOL_ID });
  return null;
};

export default ImageMarkerEditorTool;
