import React from 'react';
import PropTypes from 'prop-types';

import EntityOfInterestForm from '../../entities-of-interest/EntityOfInterestForm';

const PointOfInterestForm = ({ onColorChange, onFlaggedChange }) => {
  return <EntityOfInterestForm onColorChange={onColorChange} onFlaggedChange={onFlaggedChange} />;
};

PointOfInterestForm.propTypes = {
  onColorChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
};

export default PointOfInterestForm;
