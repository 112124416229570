import { Box, CircularProgress, ListItem, ListItemText } from '@mui/material';

import { useCallback, useMemo } from 'react';

import IdsAutocomplete, { IIdsAutocompleteProps } from '../IdsAutocomplete';

import { ISearchUserOption, ChangeFunction, IRenderResultsPayload } from './types';
import DefaultRenderResults from './DefaultRenderResults';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsSearchUserProps extends Omit<IIdsAutocompleteProps, 'onChange'> {
  options: ISearchUserOption[];
  isLoading: boolean;
  error: boolean;
  onAdd: ChangeFunction;
  onRemove: ChangeFunction;
  renderResults?: (
    selectedUsers: string[],
    options: ISearchUserOption[],
    payload: IRenderResultsPayload,
  ) => React.ReactElement;
}

const defaultRenderResults: IIdsSearchUserProps['renderResults'] = (
  selectedUsers,
  options,
  payload,
) => <DefaultRenderResults selectedUsers={selectedUsers} options={options} payload={payload} />;

const IdsSearchUser: React.FC<IIdsSearchUserProps> = ({
  value,
  options,
  isLoading,
  error,
  renderResults = defaultRenderResults,
  onAdd,
  onRemove,
  ...rest
}) => {
  // Rendered list of selected users
  const renderedResults = useMemo(
    () => renderResults(value, options, { remove: onRemove }),
    [renderResults, value, options, onRemove],
  );

  // onChange event fired by Autocomplete
  const handleChange: IIdsAutocompleteProps['onChange'] = useCallback(
    (_, value) => {
      // 'value' will be 'null'
      // in case of clicking on 'clear' icon in autocomplete textfield
      if (value) {
        onAdd(value);
      }
    },
    [onAdd],
  );

  return (
    <Box>
      <IdsAutocomplete
        label='Add user'
        loading={isLoading}
        options={options}
        onChange={handleChange}
        getOptionLabel={user => `${user.firstName} ${user.lastName}`}
        getOptionValue={user => user.id}
        noOptionsText={error ? 'Users failed to load' : 'No users found'}
        renderOption={(props, user: ISearchUserOption) => (
          <ListItem key={user.email} {...props}>
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={`${user.email}`}
            />
          </ListItem>
        )}
        {...rest}
      />
      {isLoading ? <CircularProgress /> : renderedResults}
    </Box>
  );
};

export * from './types';

export default IdsSearchUser;
