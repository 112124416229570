import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';

import IdsCheckbox from '../../../../ids-inputs/IdsCheckbox';

import styles from '../MapMenuItemAccordions.module.css';

const AccordionSectionDefault = ({ item, toggle }) => {
  const itemComponent = (
    <Box className={styles.accordionItem}>
      <IdsCheckbox
        className={clsx(styles.accordionItemCheckbox, {
          [styles.accordionItemDisabled]: item.disabled,
        })}
        checked={!!item.active}
        onChange={() => toggle(item)}
        disabled={item.disabled}
        label={item.label}
        labelProps={{
          className: clsx({ [styles.accordionItemDisabled]: item.disabled }),
        }}
      />
    </Box>
  );

  return !item.tooltip ? (
    itemComponent
  ) : (
    <Tooltip title={item.tooltip} placement='left'>
      {itemComponent}
    </Tooltip>
  );
};

AccordionSectionDefault.propTypes = {
  item: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AccordionSectionDefault;
