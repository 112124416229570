import * as Yup from 'yup';

import { PROJECT_STATUSES } from '../../constants/projects';

const projectValidation = {
  name: Yup.string().required('Name is required'),
  location: Yup.number().typeError('Location is required').required('Location is required'),
  description: Yup.string().required('Description is required'),
  startDate: Yup.date().typeError('Must be a valid date').required('Start date is required'),
  completionDate: Yup.date()
    .typeError('Must be a valid date')
    .min(Yup.ref('startDate'), 'Must be after start date')
    .required('Completion date is required'),
  status: Yup.number().oneOf(Object.values(PROJECT_STATUSES)).required('Status is required'),
};

const bulkProjectsValidation = {
  name: Yup.string().required('Name is required'),
  locations: Yup.array().of(Yup.number()).required('Location is required'),
  description: Yup.string().required('Description is required'),
  startDate: Yup.date().typeError('Must be a valid date').required('Start date is required'),
  completionDate: Yup.date()
    .typeError('Must be a valid date')
    .min(Yup.ref('startDate'), 'Must be after start date')
    .required('Completion date is required'),
  status: Yup.number().oneOf(Object.values(PROJECT_STATUSES)).required('Status is required'),
};

export const createProjectValidation = { ...projectValidation };
export const editProjectValidation = {
  ...projectValidation,
};

export const createProjectValidationSchema = Yup.object().shape(createProjectValidation);
export const editProjectValidationSchema = Yup.object().shape(editProjectValidation);
export const bulkCreateProjectValidationSchema = Yup.object().shape(bulkProjectsValidation);
