import React from 'react';

import { useRecoilValue } from 'recoil';

import { HomeIcon } from '../../../theme/icons';

import IdsNestedViewHeader from '../../IdsNestedViewHeader';
import { IIdsBreadcrumbsProps } from '../../IdsBreadcrumbs';
import { getOrgRoute } from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';
import { activeOrganizationState } from '../../../atoms/organizations';

export interface IOrgViewHeaderProps extends Omit<IIdsBreadcrumbsProps, 'breadcrumbs'> {
  breadcrumbs?: IIdsBreadcrumbsProps['breadcrumbs'];
}

const OrgViewHeader: React.FC<IOrgViewHeaderProps> = ({ breadcrumbs = [], currentCrumb }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  return (
    <IdsNestedViewHeader
      breadcrumbs={[
        {
          label: <HomeIcon />,
          route: getOrgRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        ...breadcrumbs,
      ]}
      currentCrumb={currentCrumb}
    />
  );
};

export default OrgViewHeader;
