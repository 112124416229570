import React from 'react';
import { useRecoilValue } from 'recoil';

import { activeAssignmentState } from '../../../atoms/assignments';
import { activeOrganizationState } from '../../../atoms/organizations';
import { AssignmentDetailsTemplatePage } from '../AssignmentDetailsTemplatePage';
import { useGetAssignmentResponsesList } from '../../../services/AssignmentResponsesService';
import { getOrgAssignmentResponseRoute, getOrgAssignmentsRoute } from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentDetailsPageProps {}

const AssignmentDetailsPage: React.FC<IAssignmentDetailsPageProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeAssignment = useRecoilValue(activeAssignmentState);
  const useGetAssignmentResponses = () => useGetAssignmentResponsesList(activeAssignment.id);

  return (
    <AssignmentDetailsTemplatePage
      pageTitle='Assignment Details'
      assignmentId={activeAssignment.id}
      useGetAssignmentResponses={useGetAssignmentResponses}
      getToAssignmentResponse={(assignmentResponseId: string) =>
        getOrgAssignmentResponseRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          assignmentId: activeAssignment.id,
          assignmentResponseId,
        })
      }
      breadcrumbs={[
        {
          label: 'Assignments',
          route: getOrgAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
      ]}
    />
  );
};

export default AssignmentDetailsPage;
