import React from 'react';

import './App.css';
import { RecoilRoot } from 'recoil';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { SnackbarProvider } from 'notistack';

import { QueryClientProvider } from 'react-query';

import useSettings from './hooks/useSettings';
import { KeycloakProvider } from './context/KeycloakContext';
import { ConfirmationProvider } from './context/ConfirmationContext';
import { LegacyViewerProvider } from './context/LegacyViewerContext';
import { UploadQueueProvider } from './context/UploadQueueContext';
import NavigationSourceLoader from './components/NavigationSourceLoader';
import { createTheme } from './theme';

import Routes from './Routes';
import queryClient from './utils/query';
import ErrorBoundary from './ErrorBoundary';
import ScrollReset from './components/ScrollReset';
import 'mapbox-gl/dist/mapbox-gl.css'; // required by mapbox

function App() {
  const { settings } = useSettings();

  return (
    <RecoilRoot>
      <StyledEngineProvider injectFirst>
        {/* injectFirst removes need to to override MUI css styles: https://mui.com/material-ui/guides/interoperability/#css-injection-order */}
        <ThemeProvider theme={createTheme(settings)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                  <CssBaseline />
                  <ScrollReset />
                  <ConfirmationProvider>
                    <KeycloakProvider>
                      <NavigationSourceLoader />
                      <UploadQueueProvider>
                        <LegacyViewerProvider>
                          <Routes />
                        </LegacyViewerProvider>
                      </UploadQueueProvider>
                    </KeycloakProvider>
                  </ConfirmationProvider>
                </QueryClientProvider>
              </ErrorBoundary>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </RecoilRoot>
  );
}

export default App;
