import React, { useEffect } from 'react';
import { Dashboard, StatusBar } from '@uppy/react';
import { DashboardProps } from '@uppy/react/src/Dashboard';
import { useFormikContext } from 'formik';
import { Button, Grid, Step, Stepper, StepLabel, Zoom, Tooltip } from '@mui/material';
import clsx from 'clsx';

import useIdsUploaderContext from '../../../../../hooks/useIdsUploaderContext';
import usePrevious from '../../../../../hooks/usePrevious';
import IdsSubmitButton from '../../../../ids-forms/IdsSubmitButton';
import { IUploadStep, UploadType } from '../../../../../constants/uploads';
import { UPLOADERS } from '../../../../../constants/uploaders';

import { getImageEditorId } from '..';
import styles from '../IdsUploader.module.css';

const sx = {
  stickyPanel: {
    backgroundColor: 'background.paper',
  },
};

export interface IIdsUploaderStepsProps extends Omit<DashboardProps, 'uppy'> {
  fileSelectionStepLabel: string;
  uploadSteps: IUploadStep[];
  /** Fields that should be reset to initial values if initial values change. */
  fieldsToReinitialize?: string[];
  onFormValuesChange?: (values: any) => void;
  disableSubmit?: boolean;
  disableSubmitTooltip?: string;
}

const IdsUploaderSteps: React.FC<IIdsUploaderStepsProps> = ({
  fileSelectionStepLabel,
  fieldsToReinitialize,
  onFormValuesChange,
  uploadSteps,
  disableSubmit,
  disableSubmitTooltip,
  children,
  ...rest
}) => {
  const {
    uppy,
    fileIds,
    validationSchema,
    activeStep,
    setActiveStep,
    uploading,
    uploadFailed,
    uploadType,
  } = useIdsUploaderContext();
  const { initialValues, validateForm, setFieldValue, values, isValid } = useFormikContext<any>();
  const prevValidationSchema = usePrevious(validationSchema);
  const prevInitialValues = usePrevious(initialValues);
  const prevValues = usePrevious(values);

  useEffect(() => {
    const initialValuesChanged = prevInitialValues !== initialValues;
    const validationSchemaChanged = prevValidationSchema !== validationSchema;

    if (initialValuesChanged) {
      if (initialValues && prevValues) {
        // maintain shared fields
        const newFields = Object.keys(initialValues);

        const maintainSharedValues = async () => {
          // Maintain value of shared fields if initial values changes
          const oldValues = Object.entries(prevValues);
          for (let i = 0; i < oldValues.length; i++) {
            const [oldField, value] = oldValues[i];
            if (
              newFields.includes(oldField) &&
              value !== initialValues[oldField] &&
              // IMPORTANT: needed to allow fields to reinitialize when form reinitializes due to initial value change
              !fieldsToReinitialize?.includes(oldField)
            ) {
              // setFieldValue isn't typed as returning a promise, but it does.
              // Need to wait for each field to be set and validate before setting next one
              // to avoid race conditions.
              await setFieldValue(oldField, value, true);
            }
          }

          validateForm();
        };

        maintainSharedValues();
      }
    } else if (validationSchemaChanged) {
      validateForm();
    }
  }, [
    prevInitialValues,
    initialValues,
    prevValidationSchema,
    validationSchema,
    validateForm,
    prevValues,
    setFieldValue,
    fieldsToReinitialize,
  ]);

  useEffect(() => {
    if (values !== prevValues && onFormValuesChange) {
      onFormValuesChange(values);
    }
  }, [values, prevValues, onFormValuesChange]);

  const fileSelectionComplete =
    !!fileIds.length &&
    (UPLOADERS[uploadType].uppyRestrictions?.minNumberOfFiles || 0) <= fileIds.length &&
    uploadType !== UploadType.None;

  return (
    <>
      <Grid container direction='column' className={styles.fullHeight} wrap='nowrap'>
        <Grid
          item
          xs='auto'
          className={clsx(styles.headerFooter, styles.header)}
          sx={sx.stickyPanel}
        >
          <Stepper activeStep={activeStep} orientation='horizontal'>
            <Step completed={fileSelectionComplete}>
              <StepLabel>{fileSelectionStepLabel}</StepLabel>
            </Step>
            {uploadSteps.map((step: IUploadStep, i: number) => (
              <Step
                completed={
                  // Previous step
                  activeStep > i + 1 ||
                  // Last step, form is valid
                  (activeStep === uploadSteps?.length && isValid)
                }
                key={`step-${i}`}
              >
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs className={styles.stepsContent}>
          <Zoom
            in={activeStep === 0}
            unmountOnExit
            className={clsx({ [styles.inactiveStep]: activeStep !== 0 })}
          >
            {/* Zoom transition seems to control classname here, need to do inline styles */}
            <div style={{ height: '100%' }}>
              {uppy && (
                <Grid
                  container
                  direction='column'
                  rowSpacing={2}
                  wrap='nowrap'
                  className={styles.fullHeight}
                >
                  <Grid item xs className={styles.fullHeight}>
                    <Dashboard
                      uppy={uppy}
                      plugins={[getImageEditorId(uppy.getID())]}
                      hideUploadButton
                      hideRetryButton
                      hidePauseResumeButton
                      hideCancelButton
                      width='100%'
                      height='100%'
                      {...rest}
                    />
                  </Grid>
                  {children && (
                    <Grid item xs='auto'>
                      {children}
                    </Grid>
                  )}
                </Grid>
              )}
            </div>
          </Zoom>
          {uploadSteps.map((step, i) => (
            <Zoom key={`zoom-${i}`} in={activeStep === i + 1} mountOnEnter unmountOnExit>
              {/* Zoom transition seems to control classname here, need to do inline styles */}
              <div style={{ height: '100%', maxHeight: '100%' }}>{step.formFields}</div>
            </Zoom>
          ))}
        </Grid>
        <Grid
          item
          xs='auto'
          container
          columnSpacing={4}
          justifyContent='space-between'
          className={clsx(styles.headerFooter, styles.footer)}
          sx={sx.stickyPanel}
        >
          <Grid item xs='auto' className={clsx({ [styles.hiddenBtn]: activeStep === 0 })}>
            <Button
              onClick={() => {
                setActiveStep(activeStep - 1);
              }}
              variant='outlined'
              disabled={uploading}
            >
              Back
            </Button>
          </Grid>
          {uploading &&
            activeStep !== 0 && ( // Dashboard already has a status bar
              <Grid item xs>
                <StatusBar
                  uppy={uppy}
                  hideAfterFinish
                  hideCancelButton
                  hideRetryButton
                  hidePauseResumeButton
                  hideUploadButton
                />
              </Grid>
            )}
          <Grid item xs='auto'>
            {activeStep < uploadSteps.length ? (
              <Button
                onClick={() => {
                  setActiveStep(activeStep + 1);
                }}
                variant='contained'
                disabled={
                  (activeStep > 0 && (!isValid || uploadSteps[activeStep - 1].disableNextButton)) ||
                  (activeStep === 0 && !fileSelectionComplete)
                }
              >
                Next
              </Button>
            ) : (
              <Tooltip
                title={disableSubmit && disableSubmitTooltip ? disableSubmitTooltip : ''}
                placement='top'
              >
                <span>
                  <IdsSubmitButton
                    variant='contained'
                    disabled={
                      !isValid ||
                      !fileSelectionComplete ||
                      (!!uploadSteps.length && uploadSteps[activeStep - 1].disableNextButton) ||
                      disableSubmit
                    }
                  >
                    {UPLOADERS[uploadType].queueUpload
                      ? 'Queue Upload'
                      : uploadFailed
                      ? 'Retry'
                      : 'Upload'}
                  </IdsSubmitButton>
                </span>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default IdsUploaderSteps;
