import React, { useMemo, useCallback } from 'react';
import { To } from 'react-router-dom';
import { gql } from 'graphql-request';
import { Box, Divider, Grid, Typography, Card, Chip } from '@mui/material';

import { useRecoilValue } from 'recoil';

import IdsTabsRenderer from '../../../components/IdsTabsRenderer';
import EntityParentChip, {
  EntityParentTypes,
} from '../../../components/entity-chips/EntityParentChip';
import { IEdgeWithCursor } from '../../../hooks/useCursorPaginatedQuery';
import globalSx from '../../../theme/globalSx';

import {
  useUpdateAssignmentSettings,
  ISettingsFragType,
} from '../../../services/AssignmentsService';

import { activeOrganizationState } from '../../../atoms/organizations';

import Assignment, { AssignmentFrag, IAssignmentFragType } from './Assignment';
import Responses from './Responses';
import Settings, { SettingsFrag } from './Settings';

export const AssignmentDetailsFrag = gql`
  fragment AssignmentDetailsFrag on Assignment {
    parentType
    parentId
    title
    description
    createdAt
    updatedAt
    active
    ...AssignmentFrag
    ...SettingsFrag
  }
  ${AssignmentFrag}
  ${SettingsFrag}
`;

type AssignmentParentType = IAssignmentFragType & ISettingsFragType;

export interface IAssignmentDetailsFragType extends AssignmentParentType {
  parentType: string;
  parentId: string;
  title: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  active: boolean;
}

export interface IAssignmentDetailsProps {
  assignment: IAssignmentDetailsFragType;
  getToAssignmentResponse: (assignmentResponseId: string) => To;
  useGetAssignmentResponses: () => { data: IEdgeWithCursor<any>[]; error: any; isLoading: boolean };
}

export const AssignmentDetails: React.FC<IAssignmentDetailsProps> = ({
  assignment,
  getToAssignmentResponse,
  useGetAssignmentResponses,
}) => {
  const { mutateAsync: updateAssignment } = useUpdateAssignmentSettings(assignment.id);

  const activeOrg = useRecoilValue(activeOrganizationState);

  const updateAssignmentSettings = useCallback(
    async updateValues => {
      const response = await updateAssignment({
        id: assignment.id,
        organizationId: activeOrg.id,
        updateValues,
      });

      return response.setAssignmentStatus?.errors[0].message ?? null;
    },
    [assignment, activeOrg, updateAssignment],
  );
  const tabs = useMemo(
    () => [
      {
        key: 'assignment',
        label: 'Assignment',
        content: <Assignment assignment={assignment} />,
      },
      {
        key: 'responses',
        label: 'Responses',
        content: (
          <Responses
            useGetAssignmentResponses={useGetAssignmentResponses}
            getTo={getToAssignmentResponse}
          />
        ),
      },
      {
        key: 'settings',
        label: 'Settings',
        content: (
          <Settings assignment={assignment} updateAssignmentSettings={updateAssignmentSettings} />
        ),
      },
    ],
    [assignment, getToAssignmentResponse, useGetAssignmentResponses, updateAssignmentSettings],
  );

  return (
    <Card sx={globalSx.pageContent}>
      <Box p={2}>
        <Grid container spacing={2} direction='column'>
          <Grid item xs>
            <Typography variant='h4' color='text.primary'>
              {assignment.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{assignment.description}</Typography>
          </Grid>
          <Grid item>
            <EntityParentChip
              parentType={assignment.parentType as EntityParentTypes}
              parentId={assignment.parentId}
              sx={{ marginRight: 1 }}
            />
            <Chip
              color={assignment.active ? 'success' : 'warning'}
              variant='outlined'
              size='small'
              label={assignment.active ? 'Active' : 'Inactive'}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
    </Card>
  );
};
