import React, { useCallback, useState } from 'react';
import { IconButton, Theme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import { FlagOutlinedIcon, FlagIcon } from '../../../../../theme/icons';
import { useUpdateImageMetadata } from '../../../../../services/MediaService';
import IdsListItemMenuItem from '../../../../ids-lists/IdsListItemMenuItem';
import { activeOrganizationState } from '../../../../../atoms/organizations';

import buttonStyles from '../../IdsMediaViewer.module.css';

const sx = {
  flagIcon: (theme: Theme) => ({
    color: (theme as any).palette.ids.marker.markerDotFlagged,
  }),
};

export interface IFlagButtonProps {
  imageId: string;
  isFlagged: boolean;
  disabled?: boolean;
  inMenu?: boolean;
  onUpdate?: (id: string, flagged: boolean) => void;
}

const FlagButton: React.FC<IFlagButtonProps> = ({
  imageId,
  isFlagged,
  disabled,
  inMenu,
  onUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const activeOrg = useRecoilValue(activeOrganizationState);

  const [isUpdating, setIsUpdating] = useState(false);
  const updateMetadataMutation = useUpdateImageMetadata();

  const onClickHandler = useCallback(async () => {
    setIsUpdating(true);

    try {
      const result = await updateMetadataMutation.mutateAsync({
        organizationId: activeOrg.id,
        id: imageId,
        flagged: !isFlagged,
      });

      if (result?.errors?.length) {
        enqueueSnackbar('Failed to update this image', { variant: 'warning' });
      }

      if (!result?.errors?.length && onUpdate) {
        onUpdate(imageId, result.updateImage.image.flagged);
      }
    } catch (e) {
      enqueueSnackbar('Failed to update this image', { variant: 'warning' });
    }

    setIsUpdating(false);
  }, [onUpdate, updateMetadataMutation, activeOrg.id, isFlagged, imageId, enqueueSnackbar]);

  const flagIcon = isFlagged ? (
    <FlagIcon sx={sx.flagIcon} />
  ) : (
    <FlagOutlinedIcon sx={sx.flagIcon} />
  );

  return inMenu ? (
    <IdsListItemMenuItem icon={flagIcon} disabled={isUpdating || disabled} onClick={onClickHandler}>
      Flag
    </IdsListItemMenuItem>
  ) : (
    <IconButton
      className={buttonStyles.menuActionButton}
      disabled={isUpdating || disabled}
      onClick={onClickHandler}
    >
      {flagIcon}
    </IconButton>
  );
};

export default FlagButton;
