import { atom } from 'recoil';

export interface IActiveUserState {
  id: string;
  firstName: string;
  lastName: string;
}

const activeUserState = atom<IActiveUserState | null>({
  key: 'activeUserState',
  default: null,
});

export { activeUserState };
