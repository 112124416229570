import React from 'react';

import IdsDialogForm, { IIdsDialogFormProps } from '../IdsDialogForm';
import CancelButton, { ICancelButtonProps } from '../../ids-buttons/CancelButton';
import CreateButton from '../../ids-buttons/CreateButton';

export interface IIdsCreateDialogFormProps extends Omit<IIdsDialogFormProps, 'actions'> {
  onCancel: ICancelButtonProps['onClick'];
}

const IdsCreateDialogForm: React.FC<IIdsCreateDialogFormProps> = ({
  children,
  onCancel,
  ...rest
}) => {
  return (
    <IdsDialogForm
      actions={
        <>
          <CancelButton onClick={onCancel} />
          <CreateButton />
        </>
      }
      {...rest}
    >
      {children}
    </IdsDialogForm>
  );
};

export default IdsCreateDialogForm;
