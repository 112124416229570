import { useCallback, useMemo } from 'react';

import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/users';
import {
  BarChartIcon,
  AssignmentIcon,
  CollectionIcon,
  FilterIcon,
  LocationIcon,
  MapIcon,
  OrganizationIcon,
  ProgramIcon,
  ProjectIcon,
  RouteIcon,
  UsersIcon,
} from '../../../theme/icons';
import {
  getOrgAssignmentsRoute,
  getOrgCollectionsRoute,
  getOrgLocationsRoute,
  getOrgMapRoute,
  getOrgProgramsRoute,
  getOrgProjectsRoute,
  getOrgQueriesRoute,
  getOrgReportsRoute,
  getOrgRoutesRoute,
  getOrgUsersRoute,
  getTenantOrgsRoute,
} from '../../../utils/routes';

export const useBuildNavConfig = () => {
  const { userHasOneOfRoles } = usePermissions();
  const { isIDSUser, isTenantUser } = useMemo(() => {
    return {
      isIDSUser: userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]),
      isTenantUser: userHasOneOfRoles([USER_ROLES.TENANT_ADMIN, USER_ROLES.TENANT_TEAM]),
    };
  }, [userHasOneOfRoles]);

  return useCallback(
    (subdomain: string, orgId: string, organizationLength: number) => {
      const navConfig = [
        {
          subheader: 'Capture',
          items: [
            {
              title: 'Assignments',
              icon: AssignmentIcon,
              href: getOrgAssignmentsRoute({
                subdomain,
                orgId,
              }),
              visible: isIDSUser || isTenantUser || userHasOneOfRoles([USER_ROLES.ORG_ADMIN]),
            },
            {
              title: 'Routes',
              icon: RouteIcon,
              href: getOrgRoutesRoute({
                subdomain,
                orgId,
              }),
              visible: false, // isIDSUser || isTenantUser, TODO: update when routes
              // are implemented in Explorer
            },
            // {
            //   title: 'Uploads',
            //   icon: UploadOutlinedIcon,
            //   href: getOrgUploadsRoute({
            //     subdomain,
            //     orgId,
            //   }),
            //   visible: isIDSUser,
            // },
          ],
        },
        {
          subheader: 'Explore',
          items: [
            {
              title: 'Map',
              icon: MapIcon,
              href: getOrgMapRoute({
                subdomain,
                orgId,
              }),
              visible: true,
            },
            // {
            //   title: 'Media',
            //   icon: PhotosIcon,
            //   href: getOrgMediaRoute({
            //     subdomain,
            //     orgId,
            //   }),
            //   visible: true,
            // },
          ],
        },
        {
          subheader: 'Inform',
          items: [
            {
              title: 'Reports',
              icon: BarChartIcon,
              href: getOrgReportsRoute({
                subdomain,
                orgId,
              }),
              visible:
                isIDSUser || userHasOneOfRoles([USER_ROLES.TENANT_ADMIN, USER_ROLES.ORG_ADMIN]),
            },
            {
              title: 'Queries',
              icon: FilterIcon,
              href: getOrgQueriesRoute({
                subdomain,
                orgId,
              }),
              visible: false,
            },
          ],
        },
        {
          subheader: 'Manage',
          items: [
            {
              icon: OrganizationIcon,
              title: 'Organizations',
              href: getTenantOrgsRoute({
                subdomain,
              }),
              visible: organizationLength > 1 || isTenantUser || isIDSUser,
            },
            {
              icon: ProgramIcon,
              title: 'Programs',
              href: getOrgProgramsRoute({
                subdomain,
                orgId,
              }),
              visible:
                isTenantUser ||
                isIDSUser ||
                userHasOneOfRoles([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_MANAGER]),
            },
            {
              icon: LocationIcon,
              title: 'Locations',
              href: getOrgLocationsRoute({
                subdomain,
                orgId,
              }),
              visible: true,
            },
            {
              icon: ProjectIcon,
              title: 'Projects',
              href: getOrgProjectsRoute({
                subdomain,
                orgId,
              }),
              visible: true,
            },
            {
              icon: CollectionIcon,
              title: 'Collections',
              href: getOrgCollectionsRoute({
                subdomain,
                orgId,
              }),
              visible: true,
            },
            {
              icon: UsersIcon,
              title: 'Users',
              href: getOrgUsersRoute({
                subdomain,
                orgId,
              }),
              visible: isTenantUser || isIDSUser || userHasOneOfRoles([USER_ROLES.ORG_ADMIN]),
            },
          ],
        },
      ];

      return navConfig.filter(section => section.items.some(item => item.visible));
    },
    [isIDSUser, isTenantUser, userHasOneOfRoles],
  );
};
