import React from 'react';
import PropTypes from 'prop-types';

import {
  MEDIA_TYPES,
  MediaType,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
} from '../../../constants/media';
import { selectedTags } from '../../../atoms/selectedTags';

import useActiveFilterTargets from '../../../hooks/filtering/useActiveFilterTargets';
import { useRestoreMediaMetadataFilterFromUrl } from '../../../hooks/filtering/useMediaMetadataFilter';

import MediaMetadataFilter from '../MediaMetadataFilter';

const findMetadataType = d => d.type === MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type;
const selectItemFilterData = item => item.metadata.filter(findMetadataType);
// Account for metadata possibly being undefined for image types where reps and the image details are fetched separately in map view
const selectEdgeFilterData = edge => edge.node.metadata?.filter(findMetadataType);

const supportedFilterTargets = [
  {
    type: MEDIA_TYPES[MediaType.ProjectPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.HDPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.Video].type,
    selectFilterData: selectItemFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.ThreeDModel].type,
    selectFilterData: selectItemFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.ThreeDVR].type,
    selectFilterData: selectItemFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.PointOfInterest].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.Asset].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.AreaOfInterest].type,
    selectFilterData: selectEdgeFilterData,
  },
];

const TagsFilter = props => {
  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);

  return (
    <MediaMetadataFilter
      label={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].label}
      metadataType={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type}
      filterTargets={activeFilterTargets}
      filterStateAtom={selectedTags}
      noMetadataToFilterByMessage='There are no tags to filter by.'
      {...props}
    />
  );
};

export const useRestoreTagsFilterFromUrl = allValidMetadataItems => {
  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);

  useRestoreMediaMetadataFilterFromUrl({
    metadataType: MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
    filterTargets: activeFilterTargets,
    filterStateAtom: selectedTags,
    allValidMetadataItems,
  });
};

TagsFilter.propTypes = {
  allSortedMetadataItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default TagsFilter;
