import * as Yup from 'yup';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../../constants/media';

export const projectPhotoValidation = {
  location_id: Yup.string().nullable().required('Location is required'),
  project_id: Yup.string().nullable().required('Project is required'),
  photo_category_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].label} is required`),
  photo_type_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].label} is required`),
  photo_level_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.Level].label} is required`),
  photo_area_id: Yup.string().nullable(),
  photo_tags: Yup.array(Yup.string()),
};

export const projectPhotoValidationSchema = Yup.object().shape(projectPhotoValidation);
