import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import RuntimeConfig from '../../RuntimeConfig';
import { activeTenantState } from '../../atoms/tenants';

const useNavigateToPhygii = () => {
  const activeTenant = useRecoilValue(activeTenantState);

  return useCallback(() => {
    const navigateTo = window.location.href
      .replace(`/${activeTenant!.subdomain}/`, '/')
      .replace(window.location.hostname, RuntimeConfig.phygiiHostname);

    window.location.assign(navigateTo);
  }, [activeTenant]);
};

export default useNavigateToPhygii;
