import React, { useCallback, useState } from 'react';
import { Slider, Typography } from '@mui/material';

import SettingsChip from '../SettingsChip';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';

import styles from './RasterOverlayChip.module.css';

const RasterOverlayChip = ({ layerId, overlay, ...rest }) => {
  const { getLayerMetadata, setLayerMetadata } = useImmersiveViewer();
  const [opacity, setOpacity] = useState(getLayerMetadata(layerId)?.opacity || 1);

  const onOpacityChange = useCallback(
    e => {
      const newOpacity = e.target.value;
      setLayerMetadata(layerId, { opacity: newOpacity });
      setOpacity(newOpacity);
    },
    [setLayerMetadata, layerId],
  );

  const { name } = overlay;

  return (
    <SettingsChip label={name} {...rest}>
      <div className={styles.container}>
        <Typography variant='caption'>Opacity</Typography>
        <Slider
          value={opacity}
          min={0}
          max={1}
          step={0.01}
          size='small'
          onChange={onOpacityChange}
          className={styles.slider}
        />
      </div>
    </SettingsChip>
  );
};

export default RasterOverlayChip;
