import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';

import IdsFormAutocompleteField, {
  IIdsFormAutocompleteFieldProps,
} from '../../IdsFormAutocompleteField';
import {
  IOrganizationAssignmentsListEdge,
  useGetOrganizationAssignments,
} from '../../../../services/AssignmentsService';
import { searchAssignmentItem } from '../../../../views/Assignments/AssignmentsList';
import { getNodeOptionTitle, getNodeOptionValue } from '../IdsOrgProjectsAutocompleteField/helpers';

import AssignmentAutocompleteOption from './AssignmentAutocompleteOption';

export interface IIdsFormOrgAssignmentsAutocompleteFieldProps
  extends Omit<IIdsFormAutocompleteFieldProps, 'options'> {
  disableWhileLoading?: boolean;

  filterAssignmentOptions?: (
    options: IIdsFormAutocompleteFieldProps['options'],
  ) => IIdsFormAutocompleteFieldProps['options'];
  onOptionsLoaded?: (options: IOrganizationAssignmentsListEdge[]) => void;
}

const filterOptions: IIdsFormAutocompleteFieldProps['filterOptions'] = (options, state) => {
  if (!state.inputValue) {
    return options;
  }

  const queryLowerCase = state.inputValue.toLowerCase();

  return options.filter(
    o => o.node.id.includes(queryLowerCase) || searchAssignmentItem(o, state.inputValue),
  );
};

const renderOption: IIdsFormAutocompleteFieldProps['renderOption'] = (props, o, { selected }) => (
  <AssignmentAutocompleteOption option={o} selected={selected} {...props} key={o.node.id} />
);

const IdsFormOrgAssignmentsAutocompleteField: React.FC<
  IIdsFormOrgAssignmentsAutocompleteFieldProps
> = ({
  name,
  disabled,
  disableWhileLoading,
  onOptionsLoaded,
  filterAssignmentOptions,
  ...rest
}) => {
  const { setFieldValue, errors, setFieldError, values } = useFormikContext<Record<string, any>>();

  const [options, setOptions] = useState<IOrganizationAssignmentsListEdge[]>([]);

  const activeOrg = useRecoilValue(activeOrganizationState);
  const { data, isLoading, error } = useGetOrganizationAssignments(activeOrg.id, {
    enabled: !!activeOrg.id,
  });

  useEffect(() => {
    if (onOptionsLoaded) {
      onOptionsLoaded(options);
    }
  }, [options, onOptionsLoaded]);

  useEffect(() => {
    if (isLoading || error || !data) {
      return;
    }

    setOptions(data);

    if (data.length === 1) {
      // If only 1 option, auto select
      setFieldValue(name, data[0].node.id);
    }
  }, [name, data, setFieldValue, isLoading, error]);

  useEffect(() => {
    if (!options.length || !values[name]) {
      return;
    }

    if (!options.some(o => o.node.id === values[name])) {
      setFieldError(name, 'Invalid assignment provided');
    }
  }, [options, values, name, setFieldError]);

  const textFieldProps = useMemo(() => {
    const error = errors[name];

    return {
      helperText: errors ? error : null,
      error: errors ? Boolean(error) : undefined,
      name,
    };
  }, [errors, name]);

  const filteredOptions = useMemo(() => {
    if (filterAssignmentOptions) {
      return filterAssignmentOptions(options);
    }

    return options;
  }, [filterAssignmentOptions, options]);

  return (
    <IdsFormAutocompleteField
      filterOptions={filterOptions}
      name={name}
      label='Assignment'
      loading={isLoading}
      disabled={disabled || (disableWhileLoading && isLoading)}
      options={filteredOptions}
      getOptionLabel={getNodeOptionTitle}
      getOptionValue={getNodeOptionValue}
      noOptionsText={error ? 'Assignments failed to load' : 'No assignments found'}
      renderOption={renderOption}
      textFieldProps={textFieldProps}
      {...rest}
    />
  );
};

export default IdsFormOrgAssignmentsAutocompleteField;
