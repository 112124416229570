import React, { Dispatch, SetStateAction } from 'react';

import { IOptionsListProps } from '../OptionsList';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DoubleArrowLeft,
  DoubleArrowRight,
} from '../../../../theme/icons';

import TooltipButton from './TooltipButton';

export interface IIconButtonsProps extends Pick<IOptionsListProps, 'disabled'> {
  left: IOptionsListProps['values'];
  right: IOptionsListProps['values'];
  leftChecked: IOptionsListProps['values'];
  rightChecked: IOptionsListProps['values'];
  checked: IOptionsListProps['values'];
  setRight: Dispatch<SetStateAction<IOptionsListProps['values']>>;
  setLeft: Dispatch<SetStateAction<IOptionsListProps['values']>>;
  setChecked: Dispatch<SetStateAction<IOptionsListProps['values']>>;
}

export const not = (a: IOptionsListProps['values'], b: IOptionsListProps['values']) => {
  return a.filter(item => b.indexOf(item) === -1);
};

export const intersection = (a: IOptionsListProps['values'], b: IOptionsListProps['values']) => {
  return a.filter(item => b.indexOf(item) !== -1);
};

const IconButtons: React.FC<IIconButtonsProps> = ({
  disabled,
  left,
  right,
  leftChecked,
  rightChecked,
  setLeft,
  setRight,
  setChecked,
  checked,
}) => {
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <>
      <TooltipButton
        title='Add all'
        disabled={disabled || left.length === 0}
        onClick={handleAllRight}
      >
        <DoubleArrowRight />
      </TooltipButton>
      <TooltipButton
        title='Add selected'
        disabled={disabled || leftChecked.length === 0}
        onClick={handleCheckedRight}
      >
        <ArrowRightIcon />
      </TooltipButton>
      <TooltipButton
        title='Remove selected'
        disabled={disabled || rightChecked.length === 0}
        onClick={handleCheckedLeft}
      >
        <ArrowLeftIcon />
      </TooltipButton>
      <TooltipButton
        title='Remove all'
        disabled={disabled || right.length === 0}
        onClick={handleAllLeft}
      >
        <DoubleArrowLeft />
      </TooltipButton>
    </>
  );
};

export default IconButtons;
