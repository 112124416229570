import { Stack } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';

import ZoomControl from '../../../../components/mapping/ZoomControl';
import ClusterControl from '../../../../components/mapping/ClusterControl';
import CompletedFilterControl from '../../../../components/mapping/filters/CompletedFilterControl';
import { clusterState } from '../../../../atoms/immersiveViewer';

export interface IRouteMapControlsProps {
  disabled?: boolean;
}

const RouteMapControls: React.FC<IRouteMapControlsProps> = ({ disabled }) => {
  const [cluster, setClusterState] = useRecoilState(clusterState);

  return (
    <Stack direction='column' justifyContent='flex-end' spacing={1} height='100%'>
      <CompletedFilterControl />
      <ClusterControl disabled={disabled} cluster={cluster} setClusterState={setClusterState} />
      <ZoomControl disabled={disabled} />
    </Stack>
  );
};

export default RouteMapControls;
