import React, { useState } from 'react';
import { gql } from 'graphql-request';
import { Accordion, AccordionDetails, Alert, Box } from '@mui/material';

import IdsSearchableList, {
  FILTER_MODES,
  DEFAULT_PAGE_SIZE,
} from '../../../../../../../components/ids-lists/IdsSearchableList';
import AssignmentEntityAccordionSummary from '../../../../AssignmentEntityAccordion';

import TaskResponse from '../TaskResponse';

import Actions from './Actions';
import { useGetTaskResponses } from './hooks';
import { IAssignmentResponseTaskHeaderFragType } from './types';

export const AssignmentResponseTaskHeaderFrag = gql`
  fragment AssignmentResponseTaskHeaderFrag on AssignmentTask {
    id
    title
    repeatable
    defaultMetadata {
      id
      type
      value
    }
    target {
      id
      targetType
      coordinates {
        latitude
        longitude
      }
    }
    assignmentQuestions {
      edges {
        node {
          id
          title
          questionType
          required
          assignmentQuestionOptions {
            title
          }
          repeatable
          extra {
            validation {
              regex
              errorMessage
            }
          }
        }
      }
    }
  }
`;

const searchResponseItem = (responseItem: any, query: any) => {
  const searchProps = [
    new Date(responseItem.node.updatedAt).toLocaleString(),
    responseItem.node.label,
  ];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

const responseFilters = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
  {
    key: 'complete',
    label: 'Complete',
    filterItem: (responseItem: any) => responseItem.node.completed,
  },
  {
    key: 'incomplete',
    label: 'Incomplete',
    filterItem: (responseItem: any) => !responseItem.node.completed,
  },
];

const noTaskResponsesMessage = 'No responses';

export interface IAssignmentResponseTaskProps {
  // TODO: Too many components in tree, insanely hard to track final type
  // Rework this somehow...
  assignmentResponse: any;
  task: IAssignmentResponseTaskHeaderFragType;
  expanded: any;
}

const AssignmentResponseTask: React.FC<IAssignmentResponseTaskProps> = ({
  assignmentResponse,
  task,
  expanded,
  ...rest
}) => {
  const [expandedTaskResponseId, setExpandedTaskResponseId] = useState<string | undefined | null>();

  const queryEnabled = !!task && expanded;

  const {
    data: taskResponses,
    error,
    isLoading,
  } = useGetTaskResponses(task.id, assignmentResponse.id, { enabled: queryEnabled });

  const _isLoading = queryEnabled && isLoading;

  const handleExpandedChange = (id: string) => (_: any, isExpanded: any) => {
    setExpandedTaskResponseId(isExpanded ? id : null);
  };

  const renderTaskResponse = (edge: any) => (
    <TaskResponse
      key={edge.node.id}
      taskResponse={edge.node}
      task={task}
      // Only one can be opened at a time
      expanded={expandedTaskResponseId === edge.node.id}
      onChange={handleExpandedChange(edge.node.id)}
    />
  );

  /**
   * Only render a paginated list if the number of responses
   * would require more than one page of results.
   */
  const renderPaginatedList = taskResponses && taskResponses.length > DEFAULT_PAGE_SIZE;

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      {...rest}
    >
      <AssignmentEntityAccordionSummary
        primaryText={task.title}
        secondaryText='Task'
        loading={_isLoading}
      />
      {(task.repeatable || taskResponses.length === 0) && (
        <Box textAlign='right' px={2}>
          <Actions task={task} assignmentResponseId={assignmentResponse.id} />
        </Box>
      )}
      <AccordionDetails>
        {renderPaginatedList ? (
          // Paginated list
          <IdsSearchableList
            items={taskResponses}
            renderItem={renderTaskResponse}
            searchPlaceholder={'Search responses'}
            searchItem={searchResponseItem}
            filters={responseFilters}
            filterMode={FILTER_MODES.SELECT}
            noItemsMessage={noTaskResponsesMessage}
            noItemsForFilterMessage='No responses match your search'
            loading={_isLoading}
            error={error && 'Responses could not be loaded'}
            // TODO: Rewrite IdsSearchableList to TS
            header={undefined}
            sortItems={undefined}
            actions={undefined}
          />
        ) : taskResponses && taskResponses.length > 0 ? (
          // No pagination, search, or filtering capabilities
          taskResponses?.map(renderTaskResponse)
        ) : _isLoading ? null : (
          <Alert severity='info'>{noTaskResponsesMessage}</Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AssignmentResponseTask;
