import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../atoms/organizations';
import useDeleteEntity from '../../hooks/useDeleteEntity';
import { useDeleteLocation } from '../../services/LocationService';

const useDeleteLocationAction = () => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const deleteLocMutation = useDeleteLocation(activeOrg.id);
  const { deleted, deleting, handleDelete } = useDeleteEntity();

  const deleteLocation = useCallback(
    (location, onDelete, onCancel) => {
      handleDelete(
        async () => await deleteLocMutation.mutateAsync({ locationId: location?.id }),
        location?.name,
        'This cannot be undone, all location data will be deleted permanently.',
        'Location',
        onDelete,
        onCancel,
      );
    },
    [deleteLocMutation, handleDelete],
  );

  return {
    deleted,
    deleting,
    deleteLocation,
  };
};

export default useDeleteLocationAction;
