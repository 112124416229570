import { useCallback } from 'react';

import { getRasterOverlayLayerId } from '../../components/mapping/layers/overlays';
import RasterOverlayChip from '../../components/mapping/RasterOverlayChip';
import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';

import useOverlayFilter, { getOverlayFilterName } from './useOverlayFilter';

export const getRasterOverlayFilterName = getOverlayFilterName;

const useRasterOverlayFilter = () => {
  const { enableOverlay, disableOverlay, isEnabled } = useOverlayFilter(
    FILTER_STATE_KEYS.RASTER_OVERLAY,
  );

  const enableRasterOverlay = useCallback(
    overlay => {
      enableOverlay(overlay, {
        customChip: (
          <RasterOverlayChip
            layerId={getRasterOverlayLayerId(overlay.id)}
            overlay={overlay}
            onDelete={() => disableOverlay(overlay)}
          />
        ),
      });
    },
    [enableOverlay, disableOverlay],
  );

  return {
    enableRasterOverlay,
    disableRasterOverlay: disableOverlay,
    isEnabled: isEnabled,
  };
};

export default useRasterOverlayFilter;
