import React, { forwardRef, useCallback, useRef } from 'react';

import { Box } from '@mui/material';

import useDeckEventManager from '../../../hooks/useDeckEventManager';
import useMountedEffect from '../../../hooks/useMountedEffect';

import styles from './MapOverlay.module.css';

/**
 * **NOTE:** Child components should be memoized to avoid rerendering constantly when view state changes.
 */
const MapOverlay = forwardRef(
  ({ className, disabledEvents, onMouseEnter, onMouseLeave, children, ...rest }, ref) => {
    const containerRef = useRef();

    const { disableDeckEvents, enableDeckEvents } = useDeckEventManager(disabledEvents);

    const handleRef = useCallback(
      element => {
        if (ref) {
          ref.current = element;
        }
        containerRef.current = element;
      },
      [ref],
    );

    const _onMouseEnter = useCallback(
      e => {
        // Disable deck.gl event handlers
        // (otherwise events pass through the overlay)
        disableDeckEvents(containerRef.current);

        if (onMouseEnter) {
          onMouseEnter(e);
        }
      },
      [disableDeckEvents, onMouseEnter],
    );

    const _onMouseLeave = useCallback(
      e => {
        // Reenable deck.gl event handlers
        enableDeckEvents();

        if (onMouseLeave) {
          onMouseLeave(e);
        }
      },
      [enableDeckEvents, onMouseLeave],
    );

    useMountedEffect(() => {
      return () => {
        // Ensure deck events are reenabled before unmounting
        enableDeckEvents();
      };
    });

    return (
      <Box
        {...rest}
        ref={handleRef}
        className={`${styles.container} ${className}`}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
      >
        {children}
      </Box>
    );
  },
);

export default MapOverlay;
