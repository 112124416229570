import { useCallback, useEffect } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import { flagFilterState } from '../../atoms/immersiveViewer';
import useFilterContext from '../useFilterContext';

import { MEDIA_TYPES, MediaType } from '../../constants/media';
import useUrlState from '../useUrlState';
import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';

import useActiveFilterTargets from './useActiveFilterTargets';

export const FLAG_FILTER_MODES = {
  INACTIVE: 'All',
  FLAGGED: 'Flagged',
  NOT_FLAGGED: 'Not Flagged',
};

// Translate filter mode to a string id for concise representation in the url
export const FLAG_FILTER_MODE_TO_ID = Object.values(FLAG_FILTER_MODES).reduce(
  (modeIds, mode, index) => {
    modeIds[mode] = `${index + 1}`;
    return modeIds;
  },
  {},
);

const ID_TO_FLAG_FILTER_MODE = Object.entries(FLAG_FILTER_MODE_TO_ID).reduce(
  (idModes, [key, value]) => {
    idModes[value] = key;
    return idModes;
  },
  {},
);

const FILTER_FUNCTIONS = {
  [FLAG_FILTER_MODES.FLAGGED]: flagged => !!flagged,
  [FLAG_FILTER_MODES.NOT_FLAGGED]: flagged => !flagged,
};

export const FILTER_NAME = 'Flag';

const selectEdgeFilterData = item => item.node.flagged;
export const supportedFilterTargets = [
  {
    type: MEDIA_TYPES[MediaType.ProjectPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.HDPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.PointOfInterest].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.Asset].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.AreaOfInterest].type,
    selectFilterData: selectEdgeFilterData,
  },
];

const useFlagFilter = (resetOnUnmount = false) => {
  const [flagFilterMode, _setFlagFilterMode] = useRecoilState(flagFilterState);
  const resetFlagFilterState = useResetRecoilState(flagFilterState);
  const { addFilter, removeFilter, activeFilters } = useFilterContext();
  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);
  const { setUrlState } = useUrlState();

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        resetFlagFilterState();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _removeFilter = useCallback(() => {
    removeFilter(FILTER_NAME);
  }, [removeFilter]);

  const applyFilter = useCallback(
    (filterMode, loadedFromUrl = false) => {
      addFilter(
        {
          name: FILTER_NAME,
          label: FILTER_NAME,
          selected: filterMode,
          filterItem: FILTER_FUNCTIONS[filterMode],
          onRemove: () => {
            resetFlagFilterState();
            setUrlState(FILTER_STATE_KEYS.FLAG, null);
          },
          onDestroy: resetFlagFilterState,
          targets: activeFilterTargets,
        },
        { loadedFromUrl },
      );

      setUrlState(FILTER_STATE_KEYS.FLAG, FLAG_FILTER_MODE_TO_ID[filterMode]);
    },
    [addFilter, resetFlagFilterState, activeFilterTargets, setUrlState],
  );

  const applyFilterMode = useCallback(
    (filterMode, loadedFromUrl = false) => {
      if (filterMode === FLAG_FILTER_MODES.INACTIVE) {
        _removeFilter();
        return;
      }

      applyFilter(filterMode, loadedFromUrl);
    },
    [_removeFilter, applyFilter],
  );

  useEffect(() => {
    // Flag filter should be active but isn't, apply the filter mode
    if (
      flagFilterMode !== FLAG_FILTER_MODES.INACTIVE &&
      !activeFilters?.some(f => f.name === FILTER_NAME)
    ) {
      applyFilterMode(flagFilterMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFlagFilterMode = useCallback(
    (filterMode, loadedFromUrl = false) => {
      if (!filterMode || filterMode === flagFilterMode) return;
      _setFlagFilterMode(filterMode);
      applyFilterMode(filterMode, loadedFromUrl);
    },
    [_setFlagFilterMode, applyFilterMode, flagFilterMode],
  );

  const toggleFlagFilter = useCallback(() => {
    if (flagFilterMode === FLAG_FILTER_MODES.FLAGGED) {
      _setFlagFilterMode(FLAG_FILTER_MODES.NOT_FLAGGED);
      applyFilter(FLAG_FILTER_MODES.NOT_FLAGGED);
    } else if (flagFilterMode === FLAG_FILTER_MODES.NOT_FLAGGED) {
      _removeFilter();
      _setFlagFilterMode(FLAG_FILTER_MODES.INACTIVE);
    } else {
      // filter mode is inactive, toggle on should switch to flagged
      _setFlagFilterMode(FLAG_FILTER_MODES.FLAGGED);
      applyFilter(FLAG_FILTER_MODES.FLAGGED);
    }
  }, [_removeFilter, flagFilterMode, _setFlagFilterMode, applyFilter]);

  return {
    toggleFlagFilter,
    setFlagFilterMode,
    flagFilterMode,
  };
};

export const useRestoreFlagFilterFromUrl = () => {
  const { setFlagFilterMode } = useFlagFilter();
  const { getUrlState, setUrlState } = useUrlState();

  useEffect(() => {
    const urlFilterModeId = getUrlState(FILTER_STATE_KEYS.FLAG);
    if (urlFilterModeId) {
      if (ID_TO_FLAG_FILTER_MODE[urlFilterModeId]) {
        setFlagFilterMode(ID_TO_FLAG_FILTER_MODE[urlFilterModeId], true);
      } else {
        // urlFilterModeId invalid, clear the url state
        setUrlState(FILTER_STATE_KEYS.FLAG, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFlagFilter;
