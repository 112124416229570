import React, { useCallback, useMemo } from 'react';

import IdsMediaTypeMultiSelect from '../../ids-inputs/common/IdsMediaTypeMultiSelect';
import { MEDIA_TYPES, MediaType } from '../../../constants/media';
import useFilterContext from '../../../hooks/useFilterContext';

const MediaTypeFilter = ({ ...rest }) => {
  const { disableTypeData, enableTypeData, typeMetadata } = useFilterContext();

  const enabledImageTypes = useMemo(
    () =>
      // Track which media types are enabled in the filter context
      [
        MEDIA_TYPES[MediaType.ProjectPhoto].type,
        MEDIA_TYPES[MediaType.HDPhoto].type,
        MEDIA_TYPES[MediaType.PanoramicPhoto].type,
      ].filter(t => typeMetadata[t].enabled),
    [typeMetadata],
  );

  const onImageTypeFilterChange = useCallback(
    newImageTypes => {
      const addedTypes = newImageTypes.filter(newType => !enabledImageTypes.includes(newType));
      const removedTypes = enabledImageTypes.filter(oldType => !newImageTypes.includes(oldType));

      addedTypes.forEach(t => enableTypeData(t));
      removedTypes.forEach(t => disableTypeData(t));
    },
    [enabledImageTypes, enableTypeData, disableTypeData],
  );

  return (
    <IdsMediaTypeMultiSelect
      value={enabledImageTypes}
      onChange={onImageTypeFilterChange}
      {...rest}
    />
  );
};

export default MediaTypeFilter;
