import React, { FocusEvent, useCallback } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import get from 'lodash.get';
import { SelectChangeEvent } from '@mui/material';

import IdsSelect, { IIdsSelectProps } from '../../ids-inputs/IdsSelect';

// No need in onChange, onBlur, onFocus and value props
// Because this component will use formik context
export interface IIdsFormSelectFieldProps
  extends Omit<IIdsSelectProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'> {
  name: string;

  // Custom event handlers which additionally receive formikProps
  onBlur?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    formikContext?: FormikContextType<any>,
  ) => void;
  onChange?: (event: SelectChangeEvent<any>, formikContext?: FormikContextType<any>) => void;
}

const IdsFormSelectField: React.FC<IIdsFormSelectFieldProps> = ({
  name,
  label,
  options,
  required,
  disabled,
  margin,
  onBlur,
  onChange,
  ...rest
}) => {
  const formikContext = useFormikContext<any>();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur: handleBlurFormik,
    validateOnChange,
  } = formikContext;

  const value = get(values, name);
  const touch = get(touched, name);
  const error = get(errors, name);

  const handleChange = useCallback(
    (e: SelectChangeEvent<any>) => {
      setFieldValue(name, e.target.value, validateOnChange);

      if (onChange) {
        onChange(e, formikContext);
      }
    },
    [formikContext, name, onChange, setFieldValue, validateOnChange],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFieldValue(name, e.target.value);
      handleBlurFormik(e);

      if (onBlur) {
        onBlur(e, formikContext);
      }
    },
    [formikContext, name, onBlur, setFieldValue, handleBlurFormik],
  );

  return (
    <IdsSelect
      label={label}
      options={options}
      value={value}
      required={required}
      disabled={disabled}
      error={Boolean(touch && error)}
      margin={margin}
      onBlur={handleBlur}
      onChange={handleChange}
      name={name}
      {...rest}
    />
  );
};

export default IdsFormSelectField;
