import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { activeTenantState } from '../../../../atoms/tenants';
import { activeOrganizationState } from '../../../../atoms/organizations';

import IdsListItemMenu from '../../../../components/ids-lists/IdsListItemMenu';
import IdsListItemMenuItem from '../../../../components/ids-lists/IdsListItemMenuItem';

import { EntityType } from '../../../../constants/entities';
import { DeleteOutlineIcon, EditOutlineIcon } from '../../../../theme/icons';
import { getOrgProjectEditRoute } from '../../../../utils/routes';
import { IProjectsListQueryEdge } from '../../../../services/ProjectService';

import useDeleteProjectAction from '../../useDeleteProjectAction';
import usePermissions from '../../../../hooks/usePermissions';

export interface IProjectListItemMenuProps {
  projectItem: IProjectsListQueryEdge;
  onDelete?: (projectId: string) => void;
  setItemDisabled?: (v: boolean) => void;
}

const ProjectListItemMenu: React.FC<IProjectListItemMenuProps> = ({
  projectItem,
  onDelete,
  setItemDisabled,
}) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const navigate = useNavigate();
  const { deleted, deleting, deleteProject } = useDeleteProjectAction();
  const { userHasPermission } = usePermissions();

  const canUpdate = useMemo(
    () => userHasPermission('update', EntityType.Project),
    [userHasPermission],
  );
  const canDelete = useMemo(
    () => userHasPermission('delete', EntityType.Project),
    [userHasPermission],
  );

  const handleDelete = useCallback(() => {
    if (onDelete && projectItem.node.id) {
      onDelete(projectItem.node.id);
    }
  }, [onDelete, projectItem?.node.id]);

  const onClickDelete = useCallback(() => {
    if (setItemDisabled) {
      setItemDisabled(true);
    }

    deleteProject(projectItem.node, handleDelete);
  }, [handleDelete, setItemDisabled, projectItem, deleteProject]);

  const { id } = projectItem?.node;

  return (
    (canUpdate || canDelete) && (
      <IdsListItemMenu buttonProps={{ disabled: deleting }}>
        {canUpdate && (
          <IdsListItemMenuItem
            icon={<EditOutlineIcon />}
            onClick={() =>
              navigate(
                getOrgProjectEditRoute({
                  subdomain: activeTenant?.subdomain,
                  orgId: activeOrg.id,
                  projectId: id,
                }),
              )
            }
          >
            Edit
          </IdsListItemMenuItem>
        )}
        {canDelete && (
          <IdsListItemMenuItem
            icon={<DeleteOutlineIcon />}
            onClick={onClickDelete}
            disabled={deleting || deleted}
          >
            Delete
          </IdsListItemMenuItem>
        )}
      </IdsListItemMenu>
    )
  );
};

export default ProjectListItemMenu;
