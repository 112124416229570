import React from 'react';
import { clsx } from 'clsx';
import '../../../theme/globalStyles.css';

const KRPanoControl = ({ children, className, ...rest }) => (
  <div className={clsx('krpanoControl', className)} {...rest} data-testid='krpano-control'>
    {children}
  </div>
);

export default KRPanoControl;
