import React from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

import IdsImage, { IIdsImageProps } from '../../IdsImage';

const defaultRenderImage: React.FC<IIdsImageProps> = props => (
  <IdsImage alt='' loading='lazy' {...props} />
);

export interface IIdsImageButtonProps extends ButtonBaseProps {
  renderImage?: (payload: IIdsImageProps) => React.ReactElement;
  imageProps?: IIdsImageProps;
}
const IdsImageButton: React.FC<IIdsImageButtonProps> = ({
  renderImage = defaultRenderImage,
  imageProps = {},
  ...rest
}) => {
  return <ButtonBase {...rest}>{renderImage(imageProps)}</ButtonBase>;
};

export default IdsImageButton;
