import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';

import useFilterContext from '../useFilterContext';
import useObservableStates, { useObservableItemState } from '../useObservableStates';
import { MEDIA_METADATA_TYPES } from '../../constants/media';
import { MEDIA_METADATA_TYPE_KEYS } from '../../constants/urlStateKeys';
import useUrlState from '../useUrlState';
import usePrevious from '../usePrevious';

import useFilterTargetDataListeners from './useFilterTargetDataListeners';

const ITEMS_ID = 'items';

const useMediaMetadataFilter = ({
  metadataType,
  filterTargets,
  filterStateAtom,
  allSortedMetadataItems,
}) => {
  const [selectedItemIds, setSelectedItemIds] = useRecoilState(filterStateAtom);
  const { addFilter, removeFilter } = useFilterContext();
  const { setUrlArrayState } = useUrlState();
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();

  // Track in observable state to avoid overwrites
  // if multiple media types are updated at the same time
  // Tracked as an object to simplify dedupe logic
  // shape: { metadataItemId: metadataItem {}, ... }
  const [metadataItemsObj, setMetadataItemsObj] = useObservableItemState({
    id: ITEMS_ID,
    defaultState: {},
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  const prevMetadataItemsObj = usePrevious(metadataItemsObj);

  const metadataItems = useMemo(() => {
    const unsortedItems = Object.values(metadataItemsObj || {}).map(item => ({
      // Remove sources field
      id: item.id,
      type: item.type,
      value: item.value,
    }));

    // If allSortedMetadataItems is provided,
    // sort items match source of truth order
    return allSortedMetadataItems
      ? unsortedItems.sort(
          (a, b) =>
            allSortedMetadataItems.findIndex(l => l.id === a.id) -
            allSortedMetadataItems.findIndex(l => l.id === b.id),
        )
      : unsortedItems;
  }, [metadataItemsObj, allSortedMetadataItems]);

  const handleFilterRemoved = useCallback(() => {
    setSelectedItemIds([]);
    setUrlArrayState(MEDIA_METADATA_TYPE_KEYS[metadataType], null);
  }, [setSelectedItemIds, setUrlArrayState, metadataType]);

  const handleFilterDestroyed = useCallback(() => {
    // Don't remove from url when destroyed to allow for reading
    // from the url and transferring filter state between routes
    setSelectedItemIds([]);
  }, [setSelectedItemIds]);

  const handleSelectedIdsChange = useCallback(
    (newSelectedIds, selected, loadedFromUrl = false) => {
      setSelectedItemIds([...newSelectedIds]);

      const _selected =
        selected ||
        newSelectedIds
          .map(id => metadataItemsObj && metadataItemsObj[id]?.value)
          .filter(value => !!value)
          .join(', ');

      if (newSelectedIds.length) {
        addFilter(
          {
            name: metadataType,
            label: Object.values(MEDIA_METADATA_TYPES).find(t => t.type === metadataType).label,
            filterItem: filterData => {
              if (!filterData) return false;

              if (Array.isArray(filterData)) {
                // filterData is an array of metadata items
                return filterData.some(d => newSelectedIds.includes(d.id));
              }

              // filterData is a single metadata item
              return newSelectedIds.includes(filterData.id);
            },
            targets: filterTargets,
            onRemove: handleFilterRemoved,
            onDestroy: handleFilterDestroyed,
            selected: _selected,
          },
          {
            filteringDown: newSelectedIds.length < selectedItemIds.length,
            loadedFromUrl,
          },
        );

        setUrlArrayState(MEDIA_METADATA_TYPE_KEYS[metadataType], newSelectedIds);
      } else {
        // Remove the filter
        removeFilter(metadataType);
        setUrlArrayState(MEDIA_METADATA_TYPE_KEYS[metadataType], null);
      }
    },
    [
      selectedItemIds,
      setSelectedItemIds,
      addFilter,
      removeFilter,
      metadataType,
      filterTargets,
      handleFilterRemoved,
      handleFilterDestroyed,
      setUrlArrayState,
      metadataItemsObj,
    ],
  );

  const updateMediaMetadataItems = useCallback(
    (metadataItems, sourceType) => {
      const updatedMetadataItemsObj = { ...getItemState(ITEMS_ID) }; // Getting this state directly to avoid possible race conditions in the case of memoization

      // Delete old metadata items that are no longer available
      Object.entries(updatedMetadataItemsObj).forEach(([id, item]) => {
        if (metadataItems.some(d => d?.id === id)) return; // Item still exists in this source

        // item was removed from this source

        // item was only found in this source
        if (item.sources.length === 1 && item.sources[0] === sourceType) {
          delete updatedMetadataItemsObj[id];
        } else {
          // Item is found in other sources, remove this source
          const index = item.sources.findIndex(source => source === sourceType);
          if (index !== -1) {
            updatedMetadataItemsObj[id].sources.splice(index, 1);
          }
        }
      });

      // Add new data and track which source types the item is found in
      metadataItems.forEach(item => {
        if (!item) return;

        const existingItem = updatedMetadataItemsObj[item.id];
        if (existingItem) {
          if (!existingItem.sources.includes(sourceType)) {
            existingItem.sources.push(sourceType);
          }
        } else {
          // new item
          updatedMetadataItemsObj[item.id] = {
            ...item,
            sources: [sourceType], // track source types
          };
        }
      });
      setMetadataItemsObj(updatedMetadataItemsObj, true);
    },
    [setMetadataItemsObj, getItemState],
  );

  const handleDataChange = useCallback(
    (data, reduceDataItem, type) => {
      updateMediaMetadataItems(
        data.reduce((_metadataItems, item) => {
          const filterData = reduceDataItem(item);
          if (Array.isArray(filterData)) {
            // item has array of values for metadata type
            _metadataItems.push(...filterData);
          } else {
            // item has single metadata value for type
            _metadataItems.push(filterData);
          }
          return _metadataItems;
        }, []),
        type,
      );
    },
    [updateMediaMetadataItems],
  );

  useFilterTargetDataListeners({ filterTargets, handleDataChange });

  // Handle deselection of a deleted metadata item
  useEffect(() => {
    if (metadataItemsObj === prevMetadataItemsObj || !prevMetadataItemsObj) return;

    // Selected metadata item(s) is no longer an option
    const deletedIds = selectedItemIds.filter(
      id => !metadataItemsObj[id] && prevMetadataItemsObj[id],
    );

    if (deletedIds.length) {
      handleSelectedIdsChange(selectedItemIds.filter(id => !deletedIds.includes(id)));
    }
  }, [metadataItemsObj, prevMetadataItemsObj, selectedItemIds, handleSelectedIdsChange]);

  return {
    handleSelectedIdsChange,
    metadataItems,
  };
};

const buildSelectedStr = valuesArr => valuesArr.toString().replace(/,/g, ', '); // insert space after each comma

export const useRestoreMediaMetadataFilterFromUrl = ({
  metadataType,
  allValidMetadataItems,
  filterTargets,
  filterStateAtom,
}) => {
  const { handleSelectedIdsChange } = useMediaMetadataFilter({
    metadataType,
    filterTargets,
    filterStateAtom,
  });
  const { getUrlArrayState, removeUrlArrayStateItem } = useUrlState();
  const urlIds = useRef();

  useEffect(() => {
    urlIds.current = getUrlArrayState(MEDIA_METADATA_TYPE_KEYS[metadataType]);
    if (urlIds.current?.length) {
      handleSelectedIdsChange(urlIds.current, buildSelectedStr(urlIds.current), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!urlIds.current?.length || !allValidMetadataItems?.length) return;

    const validIds = [];
    const selectedLabels = [];

    for (let i = 0; i < urlIds.current.length; i++) {
      const id = urlIds.current[i];
      const metadataItem = allValidMetadataItems.find(m => m.id === id);
      if (metadataItem) {
        validIds.push(id);
        selectedLabels.push(metadataItem.name);
      } else {
        removeUrlArrayStateItem(MEDIA_METADATA_TYPE_KEYS[metadataType], id);
      }
    }

    // Update filter with selected id labels once all are loaded
    handleSelectedIdsChange(validIds, buildSelectedStr(selectedLabels));
    urlIds.current = null;
  }, [allValidMetadataItems, handleSelectedIdsChange, removeUrlArrayStateItem, metadataType]);
};

export default useMediaMetadataFilter;
