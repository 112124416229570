import React from 'react';
import { ListItemText } from '@mui/material';

import { useRecoilValue } from 'recoil';

import IdsListItem from '../../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../../components/ids-lists/IdsListItem/IdsListItemChild';
import ProjectStatusChip from '../../../Projects/ProjectStatusChip';

import { getOrgProjectRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';
import { ITenantProjectItem } from '../../../../services/TenantService';

export interface ITenantProjectListItemProps {
  projectItem: ITenantProjectItem;
}

const TenantProjectListItem: React.FC<ITenantProjectListItemProps> = ({ projectItem }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { id, name, status, location, organization } = projectItem.node;

  return (
    <IdsListItem
      key={id}
      to={getOrgProjectRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        projectId: id,
      })}
    >
      <ListItemText
        primary={name}
        primaryTypographyProps={{ variant: 'h6', noWrap: true }}
        // Some projects do not have location.
        {...(location
          ? {
              secondary: location.name,
              secondaryTypographyProps: { noWrap: true },
            }
          : {})}
      />
      <ListItemText secondary={organization.name} secondaryTypographyProps={{ noWrap: true }} />
      <IdsListItemChild
        xs={3}
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <IdsListItemChild xs='auto'>
          <ProjectStatusChip status={status} />
        </IdsListItemChild>
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default TenantProjectListItem;
