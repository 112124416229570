import React, { useMemo } from 'react';
import {
  FormControl,
  FormControlTypeMap,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';

import { OverridableComponent } from '@mui/material/OverridableComponent';

import IdsCheckbox from '../IdsCheckbox';

import styles from './styles.module.css';

export interface ISelectOption {
  value: string;
  label: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type FormControlPropsType = OverridableComponent<FormControlTypeMap<{}, 'div'>>;

// TODO: Rewrite component if it's needed to use generic type of option
export interface IIdsCheckboxListProps extends Omit<FormControlPropsType, 'value' | 'onChange'> {
  options: ISelectOption[];
  renderOption?: (option: ISelectOption | any) => React.ReactElement;
  helperText?: React.ReactNode;
  value: string[] | string | null;
  onChange: (value: string[] | string | null) => any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium';
  margin?: 'dense' | 'normal' | 'none' | undefined;
  sx?: any;

  multiple?: boolean;
}

const IdsCheckboxList: React.FC<IIdsCheckboxListProps> = ({
  label,
  options,
  required,
  disabled,
  error,
  helperText,
  size = 'small',
  margin,
  sx,

  value,
  onChange,
  multiple,

  ...rest
}) => {
  const labelId = useMemo(() => `${label}-checkbox-list-field-label`, [label]);

  const handleChange = (v: string) => {
    if (multiple && Array.isArray(value)) {
      const newValue = [...value];
      const newValueFoundIndex = value.findIndex(it => it === v);
      if (newValueFoundIndex !== -1) {
        newValue.splice(newValueFoundIndex, 1);
      } else {
        newValue.push(v);
      }
      onChange(newValue);
    } else {
      if (v === value) {
        onChange(null);
      } else {
        onChange(v);
      }
    }
  };

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={Boolean(error)}
      size={size}
      margin={margin}
      fullWidth
      sx={sx}
      component='fieldset'
      {...rest}
    >
      <FormLabel id={labelId} className={styles['formLabel']}>
        {label}
      </FormLabel>
      <FormGroup>
        {options.map(option => (
          <IdsCheckbox
            onChange={() => handleChange(option.value)}
            value={option.value}
            checked={
              multiple && Array.isArray(value)
                ? Boolean(value.find(it => it === option.value))
                : option.value === value
            }
            name={option.value}
            label={option.label}
          />
        ))}
      </FormGroup>
      <FormHelperText className={styles['helperText']}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default IdsCheckboxList;
