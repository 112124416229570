import { MediaType, RoutePointType } from './media';

export const projectPhotoMarkerLayerId = 'project-photo-markers';
export const hdPhotoMarkerLayerId = 'hd-photo-markers';
export const panoramaMarkerLayerId = 'panorama-markers';
export const videoMarkerLayerId = 'video-markers';
export const threeDModelMarkerLayerId = '3d-markers';
export const threeDVRMarkerLayerId = '3d-vr-markers';
export const pointOfInterestLayerId = 'poi-markers';
export const areaOfInterestLayerId = 'aoi-markers';
export const assetLayerId = 'asset-markers';

export const rpProjectPhotoId = 'rp-project-photo-markers';
export const rpDslrHdPhotoId = 'rp-dslr-hd-photo-markers';
export const rpPanoramaId = 'rp-panorama-markers';
export const rpDslrPanoramaId = 'rp-dslr-panorama-markers';

export const MEDIA_TYPE_LAYER_IDS = {
  [MediaType.ProjectPhoto]: projectPhotoMarkerLayerId,
  [MediaType.HDPhoto]: hdPhotoMarkerLayerId,
  [MediaType.PanoramicPhoto]: panoramaMarkerLayerId,
  [MediaType.Video]: videoMarkerLayerId,
  [MediaType.ThreeDModel]: threeDModelMarkerLayerId,
  [MediaType.ThreeDVR]: threeDVRMarkerLayerId,
  [MediaType.PointOfInterest]: pointOfInterestLayerId,
  [MediaType.AreaOfInterest]: areaOfInterestLayerId,
  [MediaType.Asset]: assetLayerId,
};

export const ROUTE_POINT_TYPE_LAYER_IDS = {
  [RoutePointType.ProjectPhoto]: rpProjectPhotoId,
  [RoutePointType.DslrHdPhoto]: rpDslrHdPhotoId,
  [RoutePointType.Panorama]: rpPanoramaId,
  [RoutePointType.DslrPanorama]: rpDslrPanoramaId,
};

export const ACTIVE_MEDIA_MARKER_LAYER_ID = 'active-media-markers';
