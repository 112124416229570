import React, { useCallback, useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import useCustomFieldMetadataFilter, {
  IUseCustomFieldMetadataFilterProps,
  getCustomFieldFilterStateAtom,
} from '../../../hooks/filtering/useCustomFieldMetadataFilter';
import { ICustomFieldMetadata } from '../../../constants/customFields';
import IdsAutocomplete, { IIdsAutocompleteProps } from '../../ids-inputs/IdsAutocomplete';
import MultiValueAutocompleteOption from '../../ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';
import { infoPanelActivePaneState } from '../../../atoms/immersiveViewer';
import { panelOptions } from '../../../context/LocationMapContext';
import usePrevious from '../../../hooks/usePrevious';

import styles from './CustomFieldMetadataFilter.module.css';

const sx = {
  input: {
    '& .MuiChip-root': {
      fontSize: '11px',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
  },
  option: {
    padding: '3px',
  },
};

export interface ICustomFieldMetadataFilterProps
  extends IUseCustomFieldMetadataFilterProps,
    Omit<
      IIdsAutocompleteProps,
      | 'value'
      | 'label'
      | 'options'
      | 'getOptionLabel'
      | 'getOptionValue'
      | 'renderOption'
      | 'onChange'
      | 'multiple'
    > {
  onVisibilityChange?: (customFieldType: string, visible: boolean) => void;
}

const CustomFieldMetadataFilter: React.FC<ICustomFieldMetadataFilterProps> = ({
  assignableCustomField,
  onVisibilityChange,
  disabled,
  ...rest
}) => {
  const { handleSelectedIdsChange, metadataItems } = useCustomFieldMetadataFilter({
    assignableCustomField,
  });
  const prevMetadataItems = usePrevious(metadataItems);

  useEffect(() => {
    if (!onVisibilityChange) return;
    else if (metadataItems.length && !prevMetadataItems?.length) {
      // Show when there are items to filter by
      onVisibilityChange(assignableCustomField.type, true);
    } else if (!metadataItems.length && prevMetadataItems?.length) {
      // Hide when there are no items to filter by
      onVisibilityChange(assignableCustomField.type, false);
    }
  }, [metadataItems.length, prevMetadataItems, onVisibilityChange, assignableCustomField]);

  const selectedIds = useRecoilValue(getCustomFieldFilterStateAtom(assignableCustomField.type));
  const setActiveInfoPanelPane = useSetRecoilState(infoPanelActivePaneState);

  const handleChange = useCallback(
    (_: any, newSelectedIds: string[]) => {
      handleSelectedIdsChange(newSelectedIds);

      setActiveInfoPanelPane(current => {
        // If in the location map context, switch to the filters tab of the info panel
        return current ? panelOptions.FILTERS : current;
      });
    },
    [handleSelectedIdsChange, setActiveInfoPanelPane],
  );

  if (!metadataItems.length) return null;

  return (
    <IdsAutocomplete
      {...rest}
      value={selectedIds}
      label={assignableCustomField.label}
      options={metadataItems}
      getOptionLabel={(item: ICustomFieldMetadata) => item.value}
      getOptionValue={(item: ICustomFieldMetadata) => item.id}
      renderOption={(props, o: ICustomFieldMetadata, { selected }) => (
        <MultiValueAutocompleteOption
          label={o.value}
          selected={selected}
          checkboxProps={{
            sx: sx.option,
          }}
          {...props}
          key={o.id}
        />
      )}
      disabled={!metadataItems.length || disabled}
      onChange={handleChange}
      multiple
      limitTags={1}
      size='small'
      disableCloseOnSelect
      sx={sx.input}
      ListboxProps={{
        className: styles.optionsListbox,
      }}
    />
  );
};

export default CustomFieldMetadataFilter;
