// TODO: Move this types into some general registry of API types
export interface IDocumentVersion {
  createdAt: string;
  filename: string;
  id: string;
  updatedAt: string;
  url: string;
  user: {
    id?: string;
    firstName?: string;
    lastName?: string;
  };
}

// TODO: Move to general interface
export interface IMetadata {
  id?: string;
  value?: string;
  type?: string;
}

export interface IDocument {
  node: {
    createdAt: string;
    updatedAt: string;
    name: string;
    id: string;
    description?: string;
    documentVersions: IDocumentVersion[];
    metadata?: IMetadata[];
    availableInInfoTab: boolean;
    availableInCapture: boolean;
    parentType: string;
    parentId: string;
  };
}

export enum DocumentParentTypes {
  PROJECT = 'Project',
  PROGRAM = 'Program',
}
