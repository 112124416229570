import { blueGrey, common } from '@mui/material/colors';

import { THEMES } from '../../constants';
import { softShadows } from '../shadows';

// @TODO: dark theme?
const phygiiThemeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      // action: {
      //   active: blueGrey[600]
      // },
      background: {
        default: '#f4f6f8',
        dark: '#f4f6f8',
        paper: common.white,
      },
      primary: {
        main: '#ff5722',
        contrastText: common.white,
      },
      secondary: {
        main: '#00e676',
        contrastText: common.white,
      },
      // text: {
      //   primary: blueGrey[900],
      //   secondary: blueGrey[600]
      // }
    },
    typography: {
      fontFamily: 'cursive',
    },
    shadows: softShadows,
  },
];

export { phygiiThemeConfigs };
