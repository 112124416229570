import React from 'react';
import { Box, SvgIcon } from '@mui/material';

import { LocationIcon } from '../../../theme/icons';
import './LocationImagePlaceholder.css';

const LocationImagePlaceholder = ({ className, ...rest }) => {
  return (
    <Box className={`locationImgPlaceholder ${className}`} {...rest}>
      <SvgIcon fontSize='large' color='disabled'>
        <LocationIcon />
      </SvgIcon>
    </Box>
  );
};

export default LocationImagePlaceholder;
