import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeProgramState } from '../../../../atoms/programs';
import {
  useGetProgramDocuments,
  useCreateProgramDocument,
} from '../../../../services/DocumentsService';
import { useGetOrganizationMetadataTypes } from '../../../../services/OrganizationsService';
import DocumentsList, { IOnCreateParentEntityDocument } from '../../../Documents/DocumentsList';
import LoadingScreen from '../../../../components/LoadingScreen';
import Error from '../../../Error';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDocumentsProps {}

const Documents: React.FC<IDocumentsProps> = () => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);

  const { mutateAsync } = useCreateProgramDocument();
  const onCreateParentEntityDocument = useCallback(
    (values: IOnCreateParentEntityDocument) => {
      return mutateAsync({
        ...values,
        parentId: activeProgram!.id,
      });
    },
    [activeProgram, mutateAsync],
  );

  const {
    data: programDocuments,
    isLoading: isLoadingProgramDocuments,
    isError: isErrorProgramDocuments,
  } = useGetProgramDocuments(activeProgram!.id, activeOrg.id);

  const {
    data: metadataTypes,
    isLoading: isLoadingMetadataTypes,
    isError: isErrorMetadataTypes,
  } = useGetOrganizationMetadataTypes(activeOrg.id);

  if (isErrorProgramDocuments || isErrorMetadataTypes) {
    return <Error />;
  }

  if (
    isLoadingProgramDocuments ||
    isLoadingMetadataTypes ||
    !programDocuments?.program?.programDocuments?.edges ||
    !metadataTypes?.organization?.metadataTypes
  ) {
    return <LoadingScreen />;
  }

  return (
    <DocumentsList
      documents={programDocuments.program.programDocuments.edges}
      onCreateParentEntityDocument={onCreateParentEntityDocument}
      metadataTypes={metadataTypes.organization.metadataTypes}
    />
  );
};

export default Documents;
