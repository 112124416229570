import React from 'react';
import type { PropsWithChildren } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import isPhygii from '../utils/phygii/isPhygii';

import OpenLegacyViewerButton from './ids-buttons/OpenLegacyViewerButton';
import Logo from './DarkIdsLogo';
import PhygiiLogo from './PhygiiLogo';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  grid: {
    marginTop: 0,
  },
};

export interface IComingSoonProps {
  legacyFeatureRoute?: string;
}
const ComingSoon: React.FC<PropsWithChildren<IComingSoonProps>> = ({
  legacyFeatureRoute,
  children,
  ...rest
}) => {
  const phygii = isPhygii();
  const logo = phygii ? <PhygiiLogo width={250} /> : <Logo width={250} />;

  return (
    <Box sx={styles.container}>
      <Grid container rowSpacing={2} flexDirection='column' alignItems='center' sx={styles.grid}>
        <Grid item xs>
          {/* mr 3.5 is needed to center IDS logo */}
          <Box {...(!phygii && { mr: 3.5 })}>{logo}</Box>
        </Grid>
        <Grid item xs>
          <Typography fontWeight='1000' fontSize={40} color='silver'>
            COMING SOON
          </Typography>
        </Grid>
        <Grid item xs>
          {legacyFeatureRoute && (
            <OpenLegacyViewerButton role='navigation' route={legacyFeatureRoute} {...rest} />
          )}
        </Grid>
        {children}
      </Grid>
    </Box>
  );
};

export default ComingSoon;
