import { useFormikContext } from 'formik';

import { useMemo } from 'react';

import IdsSearchUser, { IIdsSearchUserProps, ChangeFunction } from '../../ids-inputs/IdsSearchUser';

type ParentType = Omit<IIdsSearchUserProps, 'onAdd' | 'onRemove'>;

export interface IdsFormSearchUserFieldProps extends ParentType {
  name: string;
  onAdd?: ChangeFunction;
  onRemove?: ChangeFunction;
}

const IdsFormSearchUserField: React.FC<IdsFormSearchUserFieldProps> = ({
  name,
  onAdd,
  onRemove,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext<any>();

  // Value from formik context
  const value: string[] | undefined = useMemo(() => values[name], [name, values]);

  const handleAddUser: ChangeFunction = userId => {
    const newValues = [...(value || [])];

    // If no items at all OR no particular userId in list - add user
    if (!newValues.length || !newValues.some(uid => uid === userId)) {
      newValues.push(userId);
      setFieldValue(name, [...newValues]);
    }

    if (onAdd) {
      onAdd(userId);
    }
  };

  const handleRemoveUser: ChangeFunction = userId => {
    // If there is only one item - replace with empty array
    if (values.users.length === 1) {
      setFieldValue(name, []);
    } else {
      const newValues = [...(value || [])];
      const userIndex = newValues.findIndex(uid => uid === userId);
      newValues.splice(userIndex, 1);
      setFieldValue(name, [...newValues]);
    }

    if (onRemove) {
      onRemove(userId);
    }
  };

  return (
    <IdsSearchUser value={value} onAdd={handleAddUser} onRemove={handleRemoveUser} {...rest} />
  );
};

export default IdsFormSearchUserField;
