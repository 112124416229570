import { hexToRgb } from '@mui/system';

/**
  Takes in a css rgb string and converts it to an array with the rgb values.
  - **Example:** rgb(255, 254, 253) => [255, 254, 253]
**/
export const rgbStrToArray = rgbString => {
  const rgbStrArray = rgbString.replace(/[^\d,]/g, '').split(',');
  return rgbStrArray.map(v => parseInt(v));
};

export const rgbaArrayToStr = rgbaArray => {
  return `rgba(${rgbaArray.reduce(
    (strVals, component, i) => `${i ? `${strVals},` : ''}${component}`,
    '',
  )})`;
};

export const isHex = colorStr => colorStr && /^#[0-9A-F]{6}$/i.test(colorStr);
export const isRGB = colorStr => colorStr?.indexOf('rgb(') === 0;

export const getRGBColor = color => {
  return (
    color &&
    (isRGB(color)
      ? color // color is rgb
      : isHex(color)
      ? hexToRgb(color) // color is hex, convert to rgb
      : null) // color is invalid
  );
};

/** Solution adapted from here: https://stackoverflow.com/questions/40619476/javascript-generate-different-shades-of-the-same-color
 * - `color`: hex or rgb string
 * - `saturation` decimal between 0 and 1 where  */
export const getLightestShadeOfColorAtSaturation = (color, saturation) => {
  const baseRGB = getRGBColor(color);
  if (!baseRGB) return;

  const baseRGBArr = rgbStrToArray(baseRGB);
  const [r, g, b] = baseRGBArr;
  const max = Math.max(Math.max(r, Math.max(g, b)), 1);
  const step = 255 / max;

  const getComponent = c => c * step;
  const lightestRGB = [getComponent(r), getComponent(g), getComponent(b)];
  // make saturation of 1 mean the most saturation
  const saturationFactor = 1 - saturation;
  const saturatedRGB = lightestRGB.map(c => (c === 255 ? c : c + (255 - c) * saturationFactor));
  return `rgb(${saturatedRGB[0]}, ${saturatedRGB[1]}, ${saturatedRGB[2]})`;
};
