import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Divider } from '@mui/material';

import IdsCheckbox from '../../IdsCheckbox';

import styles from './MediaMetadataChecklist.module.css';

const MediaMetadataChecklist = ({ items, value, onChange }) => {
  const [selectedValues, setSelectedValues] = useState(value);

  const handleChange = useCallback(
    event => {
      const changedId = event.target.value;

      let newSelectedValues = [...selectedValues];

      if (newSelectedValues.length && newSelectedValues.includes(changedId)) {
        newSelectedValues.splice(newSelectedValues.indexOf(changedId), 1);
        setSelectedValues(newSelectedValues);
      } else {
        newSelectedValues = newSelectedValues.length
          ? [...newSelectedValues, changedId]
          : [changedId];
        setSelectedValues(newSelectedValues);
      }

      if (onChange) {
        let labels = items
          .filter(o => newSelectedValues.some(e => e === o.id))
          .map(item => item.value);
        labels = labels.toString().replace(/,/g, ', '); // insert space after each comma
        onChange(newSelectedValues, labels);
      }
    },
    [onChange, selectedValues, setSelectedValues, items],
  );

  useEffect(() => {
    if (value && selectedValues.length !== value.length) {
      setSelectedValues(value);
    }
  }, [value, setSelectedValues, selectedValues]);

  return items.map((item, i) => (
    <Fragment key={item.id}>
      <IdsCheckbox
        label={item.value}
        value={item.id}
        checked={selectedValues.includes(item.id)}
        onChange={handleChange}
        size='small'
      />
      {i < items.length - 1 && <Divider className={styles.divider} />}
    </Fragment>
  ));
};

MediaMetadataChecklist.propTypes = {
  items: PropTypes.array,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default MediaMetadataChecklist;
