import { useCallback, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';

import useFilterContext from '../useFilterContext';
import { MEDIA_TYPES, MediaType } from '../../constants/media';
import useUrlState from '../useUrlState';
import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';
import { selectedProjects } from '../../atoms/projectFilter';

const FILTER_NAME = 'projectFilter';

const selectEdgeProjectId = item => item.node.projectId;
const selectItemProjectId = item => item.projectId;

const FILTER_TARGETS = [
  {
    type: MEDIA_TYPES[MediaType.ProjectPhoto].type,
    selectFilterData: selectEdgeProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.HDPhoto].type,
    selectFilterData: selectEdgeProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
    selectFilterData: selectEdgeProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.Video].type,
    selectFilterData: selectItemProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.ThreeDModel].type,
    selectFilterData: selectItemProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.ThreeDVR].type,
    selectFilterData: selectItemProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.PointOfInterest].type,
    selectFilterData: selectEdgeProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.Asset].type,
    selectFilterData: selectEdgeProjectId,
  },
  {
    type: MEDIA_TYPES[MediaType.AreaOfInterest].type,
    selectFilterData: selectEdgeProjectId,
  },
];

const useProjectFilter = () => {
  const { addFilter, removeFilter } = useFilterContext();
  const { setUrlArrayState } = useUrlState();
  const [selectedProjectIds, setSelectedProjectIds] = useRecoilState(selectedProjects);

  const filterByProjects = useCallback(
    (projects, selected, loadedFromUrl = false) => {
      const resetSelectedProjectIds = () => {
        setSelectedProjectIds([]);
      };

      const onRemove = () => {
        resetSelectedProjectIds();
        setUrlArrayState(FILTER_STATE_KEYS.PROJECT, null);
      };

      if (!projects?.length) {
        removeFilter(FILTER_NAME);
        onRemove();
        return;
      }

      addFilter(
        {
          name: FILTER_NAME,
          label: 'Projects',
          filterItem: d => projects.includes(d),
          selected,
          targets: FILTER_TARGETS,
          onRemove: onRemove,
          onDestroy: resetSelectedProjectIds,
        },
        { loadedFromUrl },
      );

      setSelectedProjectIds(projects);
      setUrlArrayState(FILTER_STATE_KEYS.PROJECT, projects);
    },
    [removeFilter, addFilter, setUrlArrayState, setSelectedProjectIds],
  );

  return {
    selectedProjectIds,
    filterByProjects,
  };
};

const buildSelectedStr = valuesArr => valuesArr.toString().replace(/,/g, ', '); // insert space after each comma

export const useRestoreProjectFilterFromUrl = (projects, projectsLoaded) => {
  const { filterByProjects } = useProjectFilter();
  const { getUrlArrayState } = useUrlState();
  const urlProjectIds = useRef();
  const unprocessedUrlIds = useRef();
  const urlProjects = useRef([]);

  useEffect(() => {
    urlProjectIds.current = getUrlArrayState(FILTER_STATE_KEYS.PROJECT);
    if (urlProjectIds.current?.length) {
      unprocessedUrlIds.current = urlProjectIds.current;
      filterByProjects(urlProjectIds.current, buildSelectedStr(urlProjectIds.current), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!unprocessedUrlIds.current?.length || !projects?.length) return;

    // Match url project ids to projects (projects can be loaded in pages, so some projects may not be loaded)
    const processingResult = unprocessedUrlIds.current.reduce(
      (result, id) => {
        const project = projects.find(project => project.node.id === id);
        if (project) {
          result.loaded.push(project);
        } else {
          result.unprocessed.push(id);
        }
        return result;
      },
      { unprocessed: [], loaded: [] },
    );

    urlProjects.current = [...urlProjects.current, ...processingResult.loaded]; // Updated filter selected value to reflect the loaded projects

    let labels, selectedIds;
    if (!projectsLoaded) {
      // Not all projects are loaded, some ids have been matched to projects
      labels = urlProjectIds.current.map(
        id => urlProjects.current.find(p => p.node.id === id)?.node.name || id,
      );
      unprocessedUrlIds.current = processingResult.unprocessed;
      selectedIds = urlProjectIds.current;
    } else {
      // All projects are loaded and some ids are still unprocessed, remaining ids are invalid, remove them
      labels = urlProjects.current.map(p => p.node.name);
      unprocessedUrlIds.current = null;
      selectedIds = urlProjects.current.map(p => p.node.id);
    }

    filterByProjects(selectedIds, buildSelectedStr(labels));
  }, [projects, filterByProjects, projectsLoaded]);
};

export default useProjectFilter;
