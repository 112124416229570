import React from 'react';
import { useRecoilValue } from 'recoil';
import { ListItemText, ListItemTextProps } from '@mui/material';

import { activeTenantState } from '../../../atoms/tenants';
import { activeOrganizationState } from '../../../atoms/organizations';

import IdsListItem from '../../../components/ids-lists/IdsListItem';
import { getOrgLocationRoute } from '../../../utils/routes';
import { ILocationListItemEdge } from '../../../services/LocationService';

import LocationListItemMenu from './LocationListItemMenu';

export interface ILocationListItemProps {
  locationItem: ILocationListItemEdge;
}

const primaryTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
  variant: 'h6',
  noWrap: true,
};
const secondaryTypographyProps: ListItemTextProps['secondaryTypographyProps'] = { noWrap: true };

const LocationListItem: React.FC<ILocationListItemProps> = ({ locationItem }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const { id, name, address } = locationItem?.node;

  if (!locationItem?.node) {
    return null;
  }

  const { streetLine1, city, state } = address || {};

  // Only show commas if the address elements are present
  const fullAddress = `${streetLine1 && `${streetLine1}, `}${city && `${city}, `}${state}`;

  return (
    <IdsListItem
      key={id}
      secondaryAction={<LocationListItemMenu locationItem={locationItem} />}
      to={getOrgLocationRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        locationId: id,
      })}
    >
      <ListItemText
        primary={name}
        primaryTypographyProps={primaryTypographyProps}
        secondary={fullAddress}
        secondaryTypographyProps={secondaryTypographyProps}
      />
    </IdsListItem>
  );
};

export default LocationListItem;
