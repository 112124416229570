import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { DeckProps } from '@deck.gl/core/typed';

import { MediaMetadataType, RoutePointType } from '../../../../../constants/media';

import LayerEventsContext from '../../../../../context/LayerEventsContext';

import ImageMarkerCreationTool from '../../../../../components/mapping/tools/ImageMarkerCreationTool';

import useNavigationAwayConfirm from '../../../../../hooks/navigation/useNavigationAwayConfirm';
import useItemsOverrideFilter from '../../../../../hooks/filtering/useItemsOverrideFilter';
import usePrevious from '../../../../../hooks/usePrevious';
import useMapContextMenu from '../../../../../hooks/useMapContextMenu';

import { IRoutePoint } from '../../types';

import { Mode, ISettings, OnClickEventType, OnDragEventType } from './types';
import { defaultSettings } from './helpers';
import CreatingButtons from './CreatingButtons';
import RepositioningButtons from './RepositioningButtons';
import DeleteButtonMarker from './DeleteButtonMarker';
import EditingButtons from './EditingButtons';
import useRepositioningFeature from './RepositioningButtons/useRepositioningFeature';
import useCreatingFeature from './CreatingButtons/useCreatingFeature';
import useMetadataOptions from './useMetadataOptions';
import useSettings from './useSettings';

export interface IEditingPaneProps {
  mode: Mode;
}

const EditingPane: React.FC<IEditingPaneProps> = ({ mode }) => {
  const [activePoint, setActivePoint] = useState<IRoutePoint | null>(null);

  /**
   * @TODO: Temporary solution.
   * Remove when the similar functionality from location map/media viewer
   * is integrated here.
   */
  const { addOverrideItem, removeOverrideItem } = useItemsOverrideFilter();
  useEffect(() => {
    if (!activePoint) {
      return;
    }

    addOverrideItem(activePoint.node.id, activePoint.node.type);

    return () => {
      removeOverrideItem(activePoint.node.id, activePoint.node.type);
    };
  }, [activePoint, addOverrideItem, removeOverrideItem]);

  const { metadataTypes } = useMetadataOptions();

  const [settings, setSettings] = useState<ISettings>({
    ...defaultSettings,
  });

  const shouldPreventMarkerClick = useRef((event: OnClickEventType) => !!event.rightButton);

  const onClickMarker = useCallback<NonNullable<DeckProps['onClick']>>((value, event) => {
    // This prevents MapContextMenu from being invoked.
    event.stopImmediatePropagation();

    if (shouldPreventMarkerClick.current(event)) {
      return true;
    }

    const point = structuredClone(value.object) as IRoutePoint;

    setActivePoint(point);

    const settings: ISettings = {
      imageType: point.node.type,
      [MediaMetadataType.Level]:
        point.node.metadata.find(m => m.type === MediaMetadataType.Level)?.id || null,
      [MediaMetadataType.Area]:
        point.node.metadata.find(m => m.type === MediaMetadataType.Area)?.id || null,
    };

    if (
      point.node.type === RoutePointType.DslrPanorama ||
      point.node.type === RoutePointType.Panorama
    ) {
      settings.heading = point.node.position.heading;
    }

    if (point.node.type === RoutePointType.ProjectPhoto) {
      settings[MediaMetadataType.PhotoCategory] =
        point.node.metadata.find(m => m.type === MediaMetadataType.PhotoCategory)?.id || null;
      settings[MediaMetadataType.PhotoType] =
        point.node.metadata.find(m => m.type === MediaMetadataType.PhotoType)?.id || null;
    }

    setSettings(settings);
  }, []);

  const { isPlacingPoints, pointsToCreate, cancelCreating, canPlacePoints } = useCreatingFeature(
    settings,
    metadataTypes,
  );

  const {
    onDragStart,
    onDragEnd,
    onDrag,
    cancelRepositioning,
    isRepositioningPoints,
    pointsToReposition,
    shouldPreventMarkerDrag,
  } = useRepositioningFeature(metadataTypes);

  const settingFields = useSettings(
    isPlacingPoints || (mode === Mode.EditOnly && !activePoint),
    !!activePoint,
    settings,
    setSettings,
  );

  const { setContextMenuEnabled } = useMapContextMenu();
  useEffect(() => {
    setContextMenuEnabled(!(isRepositioningPoints || isPlacingPoints));
  }, [setContextMenuEnabled, isRepositioningPoints, isPlacingPoints]);

  useEffect(() => {
    shouldPreventMarkerClick.current = (event: OnClickEventType) => {
      return (
        isRepositioningPoints ||
        isPlacingPoints ||
        (!!event.rightButton && mode !== Mode.AddAndEdit) ||
        (!!event.leftButton && mode !== Mode.EditOnly)
      );
    };
  }, [isRepositioningPoints, isPlacingPoints, mode]);

  useEffect(() => {
    shouldPreventMarkerDrag.current = (event: OnDragEventType) => {
      return !!event.rightButton || isPlacingPoints;
    };
  }, [isPlacingPoints, shouldPreventMarkerDrag]);

  const resetFields = useCallback(() => {
    setActivePoint(null);
    setSettings({ ...defaultSettings });
  }, []);

  useEffect(() => {
    if (!isRepositioningPoints) {
      return;
    }

    resetFields();
  }, [isRepositioningPoints, resetFields]);

  const { setValue } = useContext(LayerEventsContext);

  /**
   * Default layers rendering is performed without these events.
   */
  useEffect(() => {
    if (mode === Mode.AddOnly) {
      setValue({
        pickable: false,
      });
    } else {
      setValue({
        pickable: mode === Mode.AddAndEdit || mode === Mode.EditOnly,
        onClick: onClickMarker,
        handleRightButton: mode === Mode.AddAndEdit,
        onDragStart,
        onDragEnd,
        onDrag,
      });
    }
  }, [onClickMarker, onDragStart, onDragEnd, onDrag, setValue, mode]);

  const prevMode = usePrevious(mode);
  useEffect(() => {
    if (prevMode === mode) {
      return;
    }

    resetFields();
    cancelCreating();
    cancelRepositioning();
  }, [mode, prevMode, resetFields, cancelCreating, cancelRepositioning]);

  const onCancelCreating = useCallback(() => {
    setSettings({ ...defaultSettings });
    cancelCreating();
  }, [cancelCreating]);

  useNavigationAwayConfirm(
    isPlacingPoints || isRepositioningPoints,
    'You have unsaved changes. Are you sure you want to leave?',
  );

  return (
    <Grid container direction='column' gap={2}>
      {/* Marker placement */}
      {!activePoint && mode !== Mode.EditOnly && canPlacePoints && <ImageMarkerCreationTool />}

      {/* Delete button that is above point marker */}
      {activePoint && <DeleteButtonMarker routePoint={activePoint} onDelete={resetFields} />}

      {!isRepositioningPoints && (
        <>
          {settingFields}

          {/* Buttons */}
          {!activePoint && (
            <CreatingButtons
              points={pointsToCreate}
              settings={settings}
              onCancel={onCancelCreating}
            />
          )}
        </>
      )}

      {isRepositioningPoints && (
        <RepositioningButtons points={pointsToReposition} onCancel={cancelRepositioning} />
      )}

      {activePoint && (
        <EditingButtons onCancel={resetFields} point={activePoint} settings={settings} />
      )}
    </Grid>
  );
};

export default EditingPane;
