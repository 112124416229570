import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import PropTypes from 'prop-types';

import { useSetRecoilState, useRecoilValue, SetterOrUpdater } from 'recoil';

import { Layer, MapViewState, Viewport } from '@deck.gl/core/typed';
import { DeckGLProps, DeckGLRef } from 'deck.gl/typed';

import useMountedEffect from '../hooks/useMountedEffect';
import useObservableStates, {
  useObservableItemValue,
  IObservableItemStateProps,
  IObservableItemValueProps,
} from '../hooks/useObservableStates';
import useContextStack from '../hooks/useContextStack';
import { viewState } from '../atoms/immersiveViewer';
import { Padding } from '../components/mapping/ImmersiveViewer';

import buildIdsLayer, { IIdsLayer } from '../components/mapping/layers/IdsLayer';

type LayersState = Record<string, IIdsLayer>;

export interface ILayerInput {
  id: string;
  layer: Layer | IIdsLayer | null;
}

export interface IControllerState {
  controller: DeckGLProps['controller'];
}

export interface ImmersiveViewerProviderProps {
  defaultViewState?: MapViewState;
  children?: React.ReactNode;
}

export interface IImmersiveViewerContextValue {
  handleDeckRef: (deckRef: DeckGLRef | null) => void;
  deckRef: DeckGLRef | null;
  handleContainerRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
  containerRef: HTMLDivElement | null;
  useViewport: () => Viewport | undefined;
  getViewport: () => Viewport | undefined;
  setViewport: (viewport: Viewport) => void;
  useViewState: () => MapViewState | null;
  setViewState: SetterOrUpdater<MapViewState | null>;
  useController: () => DeckGLProps['controller'];
  setController: (controllerUpdate: DeckGLProps['controller']) => void;
  usePadding: () => Padding | undefined;
  setPadding: (paddingUpdate: Partial<Padding>) => void;
  setLayerContext: (context: string) => void;
  destroyLayerContext: (context: string) => void;
  useDeckLayers: () => Layer[];
  setLayer: (id: ILayerInput['id'], layer: ILayerInput['layer']) => void;
  setLayers: (idsLayers: ILayerInput[]) => void;
  setLayerMetadata: (id: string, metadata: IIdsLayer['metadata']) => void;
  getLayerMetadata: (id: string) => Record<string, any>;
  getItemState: IObservableItemStateProps['getItemState'];
  setItemState: IObservableItemStateProps['setItemState'];
  addItemStateListener: IObservableItemStateProps['addItemStateListener'];
  removeItemStateListener: IObservableItemStateProps['removeItemStateListener'];
}

// Casting empty default value here as IImmersiveViewerContextValue to avoid requiring
// null checks everywhere or initializing some placeholder default for everything
const ImmersiveViewerContext = createContext<IImmersiveViewerContextValue>(
  {} as IImmersiveViewerContextValue,
);

export enum LAYER_TYPE {
  RASTER_OVERLAY = 'raster-overlay',
  VECTOR_OVERLAY = 'vector-overlay',
  AREA = 'area',
  MEDIA_MARKER = 'media-marker',
  LOCATION_MARKER = 'location-marker',
  TOOL = 'tool',
  OTHER = 'other',
}

// Higher values render on top of lower values
const LAYER_TYPE_RENDER_ORDER: Record<LAYER_TYPE, number> = {
  [LAYER_TYPE.RASTER_OVERLAY]: 0,
  [LAYER_TYPE.VECTOR_OVERLAY]: 1,
  [LAYER_TYPE.AREA]: 2,
  [LAYER_TYPE.LOCATION_MARKER]: 3,
  [LAYER_TYPE.MEDIA_MARKER]: 4,
  [LAYER_TYPE.TOOL]: 5,
  [LAYER_TYPE.OTHER]: 6,
};

const LAYERS_ID = 'layers';
const CONTROLLER_ID = 'deck-controller';
const PADDING_ID = 'padding';
const VIEWPORT_ID = 'viewport';

const useViewState = () => useRecoilValue<MapViewState | null>(viewState);

const _useDeckLayers = (
  getItemState: IObservableItemValueProps['getItemState'],
  setItemState: IObservableItemValueProps['setItemState'],
  addItemStateListener: IObservableItemValueProps['addItemStateListener'],
  removeItemStateListener: IObservableItemValueProps['removeItemStateListener'],
) => {
  const layers = useObservableItemValue<LayersState>({
    id: LAYERS_ID,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  const [deckLayers, setDeckLayers] = useState<Layer[]>([]);

  useEffect(() => {
    const layersArr: any[] = layers ? Object.values(layers) : [];

    if (!layersArr.length) {
      return;
    }

    const sortedLayers = layersArr
      // only include layers that have been visible to avoid preloading data
      // for layers that have not yet been visible
      .filter(l => l.wasVisible)
      .sort(
        (l1, l2) =>
          l1.type !== l2.type
            ? (LAYER_TYPE_RENDER_ORDER as any)[l1.type] > (LAYER_TYPE_RENDER_ORDER as any)[l2.type]
              ? 1
              : -1
            : l1.position - l2.position, // Same layer type, sort by position
      );
    setDeckLayers(sortedLayers.map(l => l.deckLayer));
  }, [layers]);

  return deckLayers;
};

export function ImmersiveViewerProvider({
  defaultViewState,
  children,
}: ImmersiveViewerProviderProps) {
  const [deckRef, setDeckRef] = useState<DeckGLRef | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const setViewState = useSetRecoilState<MapViewState | null>(viewState);

  useEffect(() => {
    if (defaultViewState) {
      setViewState(defaultViewState);
    }
  }, [defaultViewState, setViewState]);

  useMountedEffect(() => {
    return () => {
      // Clear view state on unmount
      setViewState(null);
    };
  });

  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();

  // Tracking viewport here instead of in recoil to avoid bad set state call warning
  const useViewport = () =>
    useObservableItemValue<Viewport>({
      id: VIEWPORT_ID,
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    });

  const getViewport = useCallback(() => getItemState<Viewport>(VIEWPORT_ID), [getItemState]);

  const setViewport = useCallback(
    (newViewport: Viewport) => {
      setItemState<Viewport>(VIEWPORT_ID, newViewport, true);
    },
    [setItemState],
  );

  const useController = () =>
    useObservableItemValue<IControllerState>({
      id: CONTROLLER_ID,
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    })?.controller;

  const setController = useCallback(
    (controllerUpdate: DeckGLProps['controller']) => {
      setItemState<IControllerState>(CONTROLLER_ID, { controller: controllerUpdate });
    },
    [setItemState],
  );

  const usePadding = () =>
    useObservableItemValue<Padding>({
      id: PADDING_ID,
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    });

  const setPadding = useCallback(
    (paddingUpdate: Partial<Padding>) => {
      const padding = getItemState<Partial<Padding>>(PADDING_ID);
      const newPadding = paddingUpdate
        ? {
            ...padding,
            ...paddingUpdate,
          }
        : null;
      setItemState<Padding>(PADDING_ID, newPadding, true);
    },
    [getItemState, setItemState],
  );

  const {
    getCurrentContext: getLayerContext,
    setCurrentContext: setLayerContext,
    destroyContext: _destroyLayerContext,
  } = useContextStack();

  const useDeckLayers = () =>
    _useDeckLayers(getItemState, setItemState, addItemStateListener, removeItemStateListener);

  const getLayers = useCallback(() => {
    return getItemState<LayersState>(LAYERS_ID) || {};
  }, [getItemState]);

  const setLayer = useCallback(
    (id: ILayerInput['id'], layer: ILayerInput['layer']) => {
      const layers = getLayers();
      const existingLayer = layers[id];

      if (!layer) {
        if (!existingLayer) {
          return;
        }

        // Layer existed, delete it
        delete layers[id];
      } else {
        // set layer (new or updated)
        // Build IdsLayer if layer is a deckgl layer instance
        const idsLayer = layer instanceof Layer ? buildIdsLayer(layer) : layer;

        if (existingLayer) {
          // Layer already exists and a layer builder was provided,
          // rebuild deck layer to handle existing metadata values
          if (idsLayer.buildDeckLayer) {
            idsLayer.metadata = existingLayer.metadata;
            idsLayer.deckLayer = idsLayer.buildDeckLayer(idsLayer.metadata);
          }

          // layer is visible for the first time, set wasVisible flag
          if (!idsLayer.wasVisible && idsLayer.deckLayer.props.visible) {
            idsLayer.wasVisible = true;
          }
        }

        // Maintain layer context from when it was first created
        // Track what context the layer was created in
        idsLayer.context = existingLayer?.context || getLayerContext();

        layers[id] = idsLayer;
      }

      setItemState<LayersState>(LAYERS_ID, layers, true);
    },
    [getLayers, getLayerContext, setItemState],
  );

  const setLayers = useCallback(
    (idsLayers: ILayerInput[]) => {
      idsLayers?.forEach(idsLayer => {
        const { id, layer } = idsLayer;
        setLayer(id, layer);
      });
    },
    [setLayer],
  );

  const setLayerMetadata = useCallback(
    (id: string, metadata: IIdsLayer['metadata']) => {
      const layers = getLayers();
      const existingLayer = layers[id];

      if (!existingLayer) {
        return false;
      }

      layers[id].metadata = metadata;

      // Update the deck layer with the metadata
      if (existingLayer.buildDeckLayer) {
        layers[id].deckLayer = existingLayer.buildDeckLayer(metadata);
      }

      setItemState<LayersState>(LAYERS_ID, layers, true);
    },
    [getLayers, setItemState],
  );

  const getLayerMetadata = useCallback(
    (id: string) => {
      const layers = getLayers();
      return layers[id]?.metadata;
    },
    [getLayers],
  );

  // Destroy all layers within this context
  const destroyContextLayers = useCallback(
    (context: string) => {
      const layers = getLayers();

      // Destroy the context layers
      setLayers(
        Object.entries(layers).reduce((contextLayers: ILayerInput[], [id, layer]) => {
          if (layer.context === context) {
            contextLayers.push({ id, layer: null });
          }
          return contextLayers;
        }, []),
      );
    },
    [getLayers, setLayers],
  );

  const destroyLayerContext = useCallback(
    (context: string) => {
      _destroyLayerContext(context, destroyContextLayers);
    },
    [_destroyLayerContext, destroyContextLayers],
  );

  return (
    <ImmersiveViewerContext.Provider
      value={{
        handleDeckRef: setDeckRef,
        deckRef,
        handleContainerRef: setContainerRef,
        containerRef,
        useViewport,
        getViewport,
        setViewport,
        useViewState,
        setViewState,
        useController,
        setController,
        usePadding,
        setPadding,
        setLayerContext,
        destroyLayerContext,
        useDeckLayers,
        setLayer,
        setLayers,
        setLayerMetadata,
        getLayerMetadata,
        getItemState,
        setItemState,
        addItemStateListener,
        removeItemStateListener,
      }}
    >
      {children}
    </ImmersiveViewerContext.Provider>
  );
}

ImmersiveViewerProvider.propTypes = {
  defaultViewState: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export const ImmersiveViewerConsumer = ImmersiveViewerContext.Consumer;

export default ImmersiveViewerContext;
