import { GraphQLClient } from 'graphql-request';

import runtimeConfig from '../RuntimeConfig';
import keycloak from '../keycloak';

const graphQLClient = new GraphQLClient(runtimeConfig.graphQLBaseUrl);

const getGraphQLClient = () => {
  const token = keycloak.token;

  // Ensures the token is always up to date through login, logout, etc.
  graphQLClient.setHeader('Authorization', token ? `Bearer ${token}` : '');
  graphQLClient.setHeader('X-Auth-Version', 2);
  return graphQLClient;
};

export default getGraphQLClient;
