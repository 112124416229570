import React, { useMemo } from 'react';
import { Box, Paper, Stack } from '@mui/material';

import { gql } from 'graphql-request';

import IdsFormSwitchField from '../../../../components/ids-forms/IdsFormSwitchField';
import IdsFormTextField from '../../../../components/ids-forms/IdsFormTextField';
import IdsEditForm from '../../../../components/ids-forms/IdsEditForm';
import ActivationStateChip from '../../../../views/Users/ActivationStateChip';
import IdsDataAttribute from '../../../../components/IdsDataAttribute';

import usePermissions from '../../../../hooks/usePermissions';

import { USER_ROLES } from '../../../../constants/users';

import IdsEditButton from '../../../../components/ids-buttons/IdsEditButton';

import { EntityType } from '../../../../constants/entities';

import styles from './styles.module.css';
import { IFormValues } from './types';
import { userDetailsValidationSchema } from './validation';

export const UserDetailsCardFrag = gql`
  fragment UserDetailsCardFrag on User {
    id
    firstName
    lastName
    phone
    title
    email
    activationState
    multiShotEnable
  }
`;

export interface IUserDetailsCardFragType {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  title: string;
  email: string;
  activationState: string;
  multiShotEnable: boolean;
}

export interface IUserDetailsCardProps {
  user: IUserDetailsCardFragType | null;
  edit?: boolean;
  onEditSubmit?: (values: IFormValues) => any;
  onCancel?: () => any;
  onEditClick?: () => any;
}

const UserDetailsCard: React.FC<IUserDetailsCardProps> = ({
  user,
  onEditSubmit,
  onEditClick,
  edit = false,
  onCancel,
}) => {
  const { userHasOneOfRoles, userHasPermission } = usePermissions();
  const userCanUpdate = useMemo(
    () => userHasPermission('update', EntityType.User),
    [userHasPermission],
  );

  const isIdsAdmin = userHasOneOfRoles([USER_ROLES.IDS_ADMIN]);

  const isActivationStateVisible: boolean = useMemo(
    () => !edit || (edit && isIdsAdmin),
    [edit, isIdsAdmin],
  );

  const initialValues = useMemo(() => {
    const payload = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      title: user?.title,
      email: user?.email,
      phone: user?.phone,
      multiShotEnable: user?.multiShotEnable,
      activationState: user?.activationState === 'active' ? true : false,
    } as IFormValues;
    if (!isActivationStateVisible) {
      delete payload.activationState;
    }

    return payload;
  }, [user, isActivationStateVisible]);

  const renderIdsAttributeText = (
    label: string,
    fieldName: keyof IFormValues,
    required: boolean,
    disabled = false,
  ) => {
    return (
      <IdsDataAttribute
        label={edit && required ? `${label} *` : label}
        value={
          edit ? (
            <IdsFormTextField name={fieldName} required={required} disabled={disabled} />
          ) : (
            (user as any)[fieldName]
          )
        }
        className={styles['idsDataAttribute']}
        disableTypography={edit}
      />
    );
  };

  const content = (
    <Box component={Paper} p={2}>
      {user && (
        <>
          {renderIdsAttributeText('First Name', 'firstName', true)}
          {renderIdsAttributeText('Last Name', 'lastName', true)}
          {renderIdsAttributeText('Title', 'title', false)}
          {renderIdsAttributeText('Email', 'email', true, true)}
          {renderIdsAttributeText('Phone', 'phone', true)}
          <IdsDataAttribute
            label='Multishot Enable'
            value={edit ? <IdsFormSwitchField name='multiShotEnable' /> : user.multiShotEnable}
            className={styles['idsDataAttribute']}
            disableTypography={edit}
          />
          {/* 
            Activation state field available:
            - Not edit mode
            - Edit but, but user isIdsAdmin
           */}
          {isActivationStateVisible && (
            <IdsDataAttribute
              label='Activation State'
              value={
                edit && isIdsAdmin ? (
                  <IdsFormSwitchField name='activationState' />
                ) : (
                  <ActivationStateChip activationState={user.activationState} />
                )
              }
              disableTypography={true}
              alignItems='center'
              className={styles['idsDataAttribute']}
            />
          )}
        </>
      )}
      {!edit && userCanUpdate && (
        <Stack direction='row' justifyContent='end' alignItems='center'>
          <IdsEditButton onClick={onEditClick}>Edit</IdsEditButton>
        </Stack>
      )}
    </Box>
  );

  if (edit) {
    return (
      <IdsEditForm
        initialValues={initialValues}
        onSubmit={onEditSubmit!}
        onCancel={onCancel}
        successMessage='User updated'
        errorHandler={error => error || 'User could not be updated'}
        validationSchema={userDetailsValidationSchema}
        disableSubmitIfClean={true}
      >
        {content}
      </IdsEditForm>
    );
  }

  return content;
};

export default UserDetailsCard;
