import React from 'react';
import { useRecoilValue } from 'recoil';

import IdsSearchableList, {
  FILTER_MODES,
} from '../../../../components/ids-lists/IdsSearchableList';
import TenantUsersListItem from '../TenantUsersListItem';
import { ITenantUserItem, useGetTenantUsers } from '../../../../services/TenantService';
import { activeTenantState } from '../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantUsersListProps {}

const userFilters = [
  {
    key: 'all',
    label: 'All',
    filterItem: () => true,
  },
  {
    key: 'active',
    label: 'Active',
    filterItem: (userItem: ITenantUserItem) => userItem.node.activationState === 'active',
  },
  {
    key: 'inactive',
    label: 'Inactive',
    filterItem: (userItem: ITenantUserItem) => userItem.node.activationState === 'inactive',
  },
];

const searchUserItem = (userItem: ITenantUserItem, query: string) => {
  const searchProps = [userItem.node.email, `${userItem.node.firstName} ${userItem.node.lastName}`];

  return !!searchProps.filter(prop => prop.toLowerCase().includes(query.toLowerCase())).length;
};

const sortUserItems = (a: ITenantUserItem, b: ITenantUserItem) => {
  const name = (e: ITenantUserItem) => `${e.node.firstName} ${e.node.lastName}`;
  return name(a).localeCompare(name(b)); // alphabetical order
};

const TenantUsersList: React.FC<ITenantUsersListProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { data, error, isLoading } = useGetTenantUsers(activeTenant!.id);

  return (
    <IdsSearchableList
      items={data}
      renderItem={(userItem: ITenantUserItem) => (
        <TenantUsersListItem key={userItem.node.id} userItem={userItem} />
      )}
      searchPlaceholder='Search users'
      searchItem={searchUserItem}
      sortItems={sortUserItems}
      filters={userFilters}
      filterMode={FILTER_MODES.TAB}
      noItemsMessage='No users'
      noItemsForFilterMessage='No users match your search'
      loading={isLoading}
      error={error && 'Users could not be loaded'}
      header={undefined}
      actions={undefined}
    />
  );
};

export default TenantUsersList;
