import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { gql } from 'graphql-request';

import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';

import Page from '../../../components/Page';
import QueryLoader from '../../../components/QueryLoader';
import { activeLocationState } from '../../../atoms/locations';
import { activeOrganizationState } from '../../../atoms/organizations';
import useOrgGraphQuery from '../../../hooks/useOrgGraphQuery';
import { useLocationKeys } from '../../../services/LocationService';
import useDeleteLocationAction from '../useDeleteLocationAction';
import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';

import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import IdsDeleteButton from '../../../components/ids-buttons/IdsDeleteButton';
import IdsEditButton from '../../../components/ids-buttons/IdsEditButton';
import LocationMapChip from '../../../components/entity-chips/LocationMapChip';
import IdsTabsRenderer from '../../../components/IdsTabsRenderer';

import globalSx from '../../../theme/globalSx';

import { activeTenantState } from '../../../atoms/tenants';

import {
  // getOrgLocationCopyRoute,
  getOrgLocationEditRoute,
  // getOrgLocationSettingsRoute,
  getOrgLocationsRoute,
} from '../../../utils/routes';

import LocationMediaTab, { MediaTabFrag } from './MediaTab';
// import ActivityFeedTab from './ActivityFeedTab';
import LocationProjectsTab from './LocationProjectsTab';
import RasterOverlaysTab from './RasterOverlaysTab';
import VectorOverlaysTab from './VectorOverlaysTab';
import LocationInfoTab, { LocationInfoTabFrag } from './LocationInfoTab';
import LocationAssignmentsTab from './AssignmentsTab';
import AssetsTab from './AssetsTab';

import styles from './LocationDetail.module.css';

const LocationDetailQuery = gql`
  query LocationDetail($orgId: ID, $id: ID!, $tenantId: ID) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      name
      address {
        streetLine1
        city
        state
        postalCode
      }
      ...LocationInfoTabFrag
      ...MediaTabFrag
    }
  }
  ${LocationInfoTabFrag}
  ${MediaTabFrag}
`;

export const LocationDetailActions = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const navigate = useNavigate();
  const { deleted, deleting, deleteLocation } = useDeleteLocationAction();
  const { userHasPermission } = usePermissions();

  const canDelete = useMemo(
    () => userHasPermission('delete', EntityType.Location),
    [userHasPermission],
  );
  const canUpdate = useMemo(
    () => userHasPermission('update', EntityType.Location),
    [userHasPermission],
  );
  // const canCreate = useMemo(
  //   () => userHasPermission('create', EntityType.Location),
  //   [userHasPermission],
  // );

  const handleDelete = useCallback(() => {
    deleteLocation(activeLocation, () => {
      // Return to locations list if successful
      navigate(
        getOrgLocationsRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
        }),
      );
    });
  }, [activeOrg.id, activeLocation, deleteLocation, navigate, activeTenant]);

  const handleLocationEdit = useCallback(() => {
    navigate(
      getOrgLocationEditRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        locationId: activeLocation?.id,
      }),
    );
  }, [navigate, activeOrg.id, activeLocation.id, activeTenant]);

  // const handleLocationCopy = useCallback(() => {
  //   navigate(
  //     getOrgLocationCopyRoute({
  //       subdomain: activeTenant?.subdomain,
  //       orgId: activeOrg.id,
  //       locationId: activeLocation?.id,
  //     }),
  //   );
  // }, [navigate, activeOrg.id, activeLocation.id, activeTenant]);

  // const handleLocationSettings = useCallback(() => {
  //   navigate(
  //     getOrgLocationSettingsRoute({
  //       subdomain: activeTenant?.subdomain,
  //       orgId: activeOrg.id,
  //       locationId: activeLocation?.id,
  //     }),
  //   );
  // }, [navigate, activeOrg.id, activeLocation.id, activeTenant]);

  return (
    <>
      {canDelete && (
        <Grid item>
          <IdsDeleteButton onClick={handleDelete} disabled={deleting || deleted} />
        </Grid>
      )}
      {canUpdate && (
        <Grid item>
          <IdsEditButton onClick={handleLocationEdit} />
        </Grid>
      )}
      {/* {canCreate && (
        <Grid item>
          <Button color='secondary' variant='contained' onClick={handleLocationCopy}>
            Copy
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button color='secondary' variant='contained' onClick={handleLocationSettings}>
          Location Settings
        </Button>
      </Grid> */}
    </>
  );
};

function LocationDetail() {
  const locationKeys = useLocationKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const [location, setLocation] = useState();

  const useLocation = () =>
    useOrgGraphQuery(locationKeys.detail(activeLocation.id), LocationDetailQuery, {
      id: activeLocation.id,
    });

  const tabs = useMemo(
    () => [
      {
        key: 'media',
        label: 'Media',
        content: <LocationMediaTab location={location} />,
      },
      {
        key: 'assets',
        label: 'Assets',
        content: <AssetsTab />,
      },
      // {
      //   key: 'activity_feed',
      //   label: 'Activity Feed',
      //   content: <ActivityFeedTab />,
      // },
      {
        key: 'projects',
        label: 'Projects',
        content: <LocationProjectsTab />,
      },
      {
        key: 'raster-overlays',
        label: 'Raster Overlays',
        content: <RasterOverlaysTab />,
      },
      {
        key: 'vector-overlays',
        label: 'Vector Overlays',
        content: <VectorOverlaysTab />,
      },
      {
        key: 'assignments',
        label: 'Assignments',
        content: <LocationAssignmentsTab />,
      },
      {
        key: 'info',
        label: 'Info',
        content: <LocationInfoTab location={location} />,
      },
    ],
    [location],
  );

  return (
    <Page title='Location' className={styles['page']}>
      <QueryLoader
        useQuery={useLocation}
        selectData={data => data?.location}
        setData={setLocation}
        render={() => {
          const { name, address } = location;
          const { streetLine1, city, state, postalCode } = address;

          // Only show commas if the address elements are present
          const fullAddress = `${streetLine1 && `${streetLine1}, `}${
            city && `${city}, `
          }${state} ${postalCode}`;

          return (
            <Container maxWidth={false} className={styles['container']}>
              <NestedOrgViewHeader
                breadcrumbs={[
                  {
                    label: 'Locations',
                    route: getOrgLocationsRoute({
                      subdomain: activeTenant?.subdomain,
                      orgId: activeOrg.id,
                    }),
                  },
                ]}
                currentCrumb={name}
                actions={<LocationDetailActions />}
              />
              <Card sx={globalSx.pageContent} className={styles['card']}>
                <Box p={2}>
                  <Grid container flexDirection='column' spacing={1}>
                    <Grid item xs>
                      <Typography variant='h4' color='text.primary'>
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant='body2' color='text.primary'>
                        {fullAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs container flexDirection='row' spacing={2}>
                      <Grid item xs='auto'>
                        <LocationMapChip locationId={location.id} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
              </Card>
            </Container>
          );
        }}
      />
    </Page>
  );
}

export default LocationDetail;
