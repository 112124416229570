import React from 'react';
import { Chip } from '@mui/material';

export interface ITaskResponseStatusChipProps {
  completed: boolean;
}

const TaskResponseStatusChip: React.FC<ITaskResponseStatusChipProps> = ({ completed }) => (
  <Chip
    color={completed ? 'success' : 'warning'}
    variant='outlined'
    size='small'
    label={completed ? 'Complete' : 'Incomplete'}
  />
);

export default TaskResponseStatusChip;
