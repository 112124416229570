import { useBlocker, BlockerFunction } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import useBeforeUnloadConfirmation from './useBeforeUnloadConfirmation';

const useNavigationAwayConfirm = (enabled: boolean, message: string) => {
  useBeforeUnloadConfirmation(enabled);

  const blockerFunc: BlockerFunction = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (!enabled) {
        return false;
      }

      /**
       * Warning: this comparison ignores query params.
       * /test/path?tab=users === /test/path is TRUE
       */
      return currentLocation.pathname !== nextLocation.pathname;
    },
    [enabled],
  );
  const blocker = useBlocker(blockerFunc);

  useEffect(() => {
    if (blocker.state !== 'blocked') {
      return;
    }

    if (window.confirm(message)) {
      blocker.proceed();

      return;
    }

    blocker.reset();
  }, [blocker, message]);
};

export default useNavigationAwayConfirm;
