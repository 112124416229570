import React from 'react';
import { Grid } from '@mui/material';

import { capitalCase } from 'change-case';

import IdsFormTextField from '../../../components/ids-forms/IdsFormTextField';
import IdsFormDateField from '../../../components/ids-forms/IdsFormDateField';
import IdsFormSelectField from '../../../components/ids-forms/IdsFormSelectField';
import IdsFormLocationAutocompleteField from '../../../components/ids-forms/common/IdsFormLocationAutocompleteField';
import { PROJECT_STATUSES } from '../../../constants/projects';

export const STATUS_OPTIONS = Object.keys(PROJECT_STATUSES).map(k => ({
  label: capitalCase(k),
  value: PROJECT_STATUSES[k],
}));

const ProjectForm = ({ disableLocation }) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs>
        <IdsFormTextField name='name' label='Name' required autoFocus />
      </Grid>
      <Grid item xs='auto'>
        <IdsFormLocationAutocompleteField name='location' required disabled={disableLocation} />
      </Grid>
      <Grid item xs>
        <IdsFormTextField
          name='description'
          label='Description'
          required
          multiline
          minRows={3}
          maxRows={6}
        />
      </Grid>
      <Grid item xs='auto' container direction='row' spacing={2}>
        <Grid item xs>
          <IdsFormDateField name='startDate' label='Start Date' required />
        </Grid>
        <Grid item xs>
          <IdsFormDateField name='completionDate' label='Completion Date' required />
        </Grid>
      </Grid>
      <Grid item xs='auto'>
        <IdsFormSelectField name='status' label='Status' required options={STATUS_OPTIONS} />
      </Grid>
    </Grid>
  );
};

ProjectForm.defaultProps = {
  disableLocation: false,
};

export default ProjectForm;
