import React, { useCallback, useMemo } from 'react';
import { Button, Tooltip } from '@mui/material';

import MapOverlay from '../MapOverlay';
import { MapMarkerIcon } from '../../../theme/icons';

import styles from './ClusterControl.module.css';

const sx = {
  control: {
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: 'background.dark',
    },
  },
};

const ClusterIcon = () => (
  <MapMarkerIcon className={styles.clusterIcon} color='action' data-testid='clusterIcon' />
);

const DeclusterIcon = () => (
  <div data-testid='declusterIcon'>
    <MapMarkerIcon className={`${styles.declusterIcon} ${styles.declusterIconFront}`} />
    <MapMarkerIcon className={`${styles.declusterIcon} ${styles.declusterIconBack1}`} />
    <MapMarkerIcon className={`${styles.declusterIcon} ${styles.declusterIconBack2}`} />
  </div>
);

const ClusterControl = ({ cluster, disabled = false, setClusterState, ...rest }) => {
  const toggleCluster = useCallback(() => {
    setClusterState(!cluster);
  }, [cluster, setClusterState]);

  const clusterToggle = useMemo(
    () => (
      <Tooltip title={cluster ? 'Decluster' : 'Cluster'} placement='left'>
        <span>
          <Button
            size='small'
            onClick={toggleCluster}
            disabled={disabled}
            className={styles.clusterBtn}
            sx={sx.control}
          >
            {cluster ? <DeclusterIcon /> : <ClusterIcon />}
          </Button>
        </span>
      </Tooltip>
    ),
    [toggleCluster, cluster, disabled],
  );

  return <MapOverlay {...rest}>{clusterToggle}</MapOverlay>;
};

export default ClusterControl;
