import React from 'react';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../atoms/organizations';

import { AssignmentDetailsTemplatePage } from '../../Assignments/AssignmentDetailsTemplatePage';
import { activeLocationAssignmentState, activeLocationState } from '../../../atoms/locations';
import { useGetAssignmentResponsesList } from '../../../services/AssignmentResponsesService';
import {
  getOrgLocationAssignmentResponseRoute,
  getOrgLocationAssignmentsRoute,
  getOrgLocationRoute,
  getOrgLocationsRoute,
} from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILocationAssignmentDetailsPageProps {}

const LocationAssignmentDetailsPage: React.FC<ILocationAssignmentDetailsPageProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const activeLocationAssignment = useRecoilValue(activeLocationAssignmentState);
  const useGetAssignmentResponses = () =>
    useGetAssignmentResponsesList(activeLocationAssignment!.id);

  if (!activeLocation || !activeLocationAssignment) {
    return null;
  }

  return (
    <AssignmentDetailsTemplatePage
      pageTitle='Location Assignment Details'
      assignmentId={activeLocationAssignment.id}
      useGetAssignmentResponses={useGetAssignmentResponses}
      getToAssignmentResponse={(assignmentResponseId: string) =>
        getOrgLocationAssignmentResponseRoute({
          subdomain: activeTenant?.subdomain,
          orgId: activeOrg.id,
          locationId: activeLocation.id,
          assignmentId: activeLocationAssignment.id,
          assignmentResponseId: assignmentResponseId,
        })
      }
      breadcrumbs={[
        {
          label: 'Locations',
          route: getOrgLocationsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        {
          label: activeLocation.name,
          route: getOrgLocationRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            locationId: activeLocation.id,
          }),
        },
        {
          label: 'Assignments',
          route: getOrgLocationAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            locationId: activeLocation.id,
          }),
        },
      ]}
    />
  );
};

export default LocationAssignmentDetailsPage;
