import React, { useMemo } from 'react';
import { Button, Grid, SvgIcon } from '@mui/material';
import { capitalCase } from 'change-case';
import { FieldArray, useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import BaseFields, {
  IBaseFormFields,
} from '../../../../../Users/CreateUserDialog/CreateUserForm/BaseFields';
import IdsFormSelectField from '../../../../../../components/ids-forms/IdsFormSelectField';
import { USER_ROLES } from '../../../../../../constants/users';
import { useGetTenantOrganizations } from '../../../../../../services/TenantService';
import { activeTenantState } from '../../../../../../atoms/tenants';
import { AddIcon, DeleteIcon } from '../../../../../../theme/icons';
import IdsFormAutocompleteField from '../../../../../../components/ids-forms/IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../../../../../components/ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import LoadingScreen from '../../../../../../components/LoadingScreen';
import ErrorScreen from '../../../../../Error';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFieldsProps {}

const PREFIX = 'organizations';

export const ORG_ROLES_OPTIONS = Object.values(USER_ROLES)
  .filter(role => role.canAssign)
  .map(role => ({
    label: capitalCase(role.name),
    value: role.name,
  }));

export const TENANT_ROLE_OPTIONS = Object.values(USER_ROLES)
  .filter(role => role.name.includes('tenant'))
  .map(role => ({
    label: capitalCase(role.name),
    value: role.name,
  }));

const ROLE_OPTIONS = [...ORG_ROLES_OPTIONS, ...TENANT_ROLE_OPTIONS];

/**
 * If tenant role was selected, the array with organizations and org level roles
 * will be empty, because the user wants to create only tenant level user.
 */
export interface IFormValues extends IBaseFormFields {
  role: (typeof TENANT_ROLE_OPTIONS)[number]['value'];
  [PREFIX]: {
    role: (typeof ORG_ROLES_OPTIONS)[number]['value'];
    organization: string;
  }[];
}

const Fields: React.FC<IFieldsProps> = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { values, setFieldValue } = useFormikContext<any>();

  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    error: errorOrganizations,
  } = useGetTenantOrganizations(activeTenant!.id, { enabled: !!values['role'] });

  const alreadySelectedOrganizations = useMemo<string[]>(() => {
    if (!values[PREFIX]) {
      return [];
    }

    return values[PREFIX].reduce(
      (accumulator: string[], card: IFormValues[typeof PREFIX][number]) => {
        if (card.organization !== null) {
          accumulator.push(card.organization);
        }

        return accumulator;
      },
      [],
    );
  }, [values]);

  const chosenRole = values['role'];
  const isOrgRoleChosen = useMemo(() => {
    if (!chosenRole) {
      return false;
    }

    return ORG_ROLES_OPTIONS.find(role => role.value === chosenRole);
  }, [chosenRole]);

  return (
    <Grid container direction='column' spacing={2}>
      {/* First name, last name, title, email, phone */}
      <BaseFields />

      <FieldArray name={PREFIX}>
        {({ remove, push }) => (
          <>
            {/* Either no role yet or tenant role was selected  */}
            {!isOrgRoleChosen && (
              <Grid item xs='auto'>
                <IdsFormSelectField
                  name='role'
                  label='Role'
                  required
                  options={ROLE_OPTIONS}
                  onChange={e => {
                    const isOrgRoleSelected = ORG_ROLES_OPTIONS.find(
                      role => role.value === e.target.value,
                    );

                    if (isOrgRoleSelected) {
                      push({ organization: null, role: e.target.value });
                    }
                  }}
                />
              </Grid>
            )}

            {/*
              Org role was selected. Allow to assign different org level roles
              to different organizations.
            */}
            {isOrgRoleChosen && (
              <Grid container direction='column' gap={2} item xs='auto'>
                <>
                  {values[PREFIX].map((_: any, index: number) => (
                    <Grid
                      container
                      direction='column'
                      item
                      gap={1}
                      xs='auto'
                      key={`${index}-${values[PREFIX][index].organization}`}
                    >
                      <Grid item xs='auto'>
                        <Button
                          color='error'
                          onClick={() => {
                            remove(index);

                            if (values[PREFIX].length === 1) {
                              setFieldValue('role', '');
                            }
                          }}
                        >
                          <SvgIcon fontSize='small' sx={{ mr: 1 }}>
                            <DeleteIcon />
                          </SvgIcon>
                          Delete organization
                        </Button>
                      </Grid>

                      {isLoadingOrganizations && (
                        <Grid item xs='auto'>
                          <LoadingScreen />
                        </Grid>
                      )}

                      {errorOrganizations && (
                        <Grid item xs='auto'>
                          <ErrorScreen />
                        </Grid>
                      )}

                      {/*
                        Show role and organization fields
                        if data loading finished
                       */}
                      {!isLoadingOrganizations && !errorOrganizations && (
                        <Grid container gap={1} direction='column' item xs='auto'>
                          <Grid item xs='auto'>
                            <IdsFormSelectField
                              name={`${PREFIX}.${index}.role`}
                              label='Role'
                              required
                              options={ORG_ROLES_OPTIONS}
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <IdsFormAutocompleteField
                              name={`${PREFIX}.${index}.organization`}
                              label='Organization'
                              required
                              options={organizations}
                              getOptionDisabled={o =>
                                alreadySelectedOrganizations.includes(o.node.id)
                              }
                              getOptionLabel={o => o.node.name}
                              getOptionValue={o => o.node.id}
                              renderOption={(props, o) => (
                                <SingleValueAutocompleteOption
                                  key={o.node.id}
                                  label={o.node.name}
                                  {...props}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    {/* Use initially selected role as default value */}
                    <Button onClick={() => push({ organization: null, role: chosenRole })}>
                      <SvgIcon fontSize='small' sx={{ mr: 1 }}>
                        <AddIcon />
                      </SvgIcon>
                      Add organization
                    </Button>
                  </Grid>
                </>
              </Grid>
            )}
          </>
        )}
      </FieldArray>
    </Grid>
  );
};

export default Fields;
