import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRecoilValue } from 'recoil';

import { rgbToHex } from '@mui/material';

import { activeOrganizationState } from '../../../../../atoms/organizations';
import IdsCreateDialogForm from '../../../../ids-forms/IdsCreateDialogForm';
import PointOfInterestForm from '../../PointOfInterestForm';
import { createPointOfInterestValidationSchema } from '../../pointOfInterestValidation';
import IDS_COLORS from '../../../../../theme/ids-colors';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';
import useFilterContext from '../../../../../hooks/useFilterContext';
import { useCreatePointOfInterest } from '../../../../../services/PointOfInterestService';
import {
  MEDIA_TYPES,
  MediaType,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
} from '../../../../../constants/media';

const initialValues = {
  name: '',
  projectId: null,
  levelId: null,
  data: '',
  color: rgbToHex(IDS_COLORS.marker.pointOfInterest),
  flagged: true,
  tags: [],
};

const CreatePointOfInterestForm = ({
  position,
  onColorChange,
  onFlaggedChange,
  onSubmitSuccess,
  onCancel,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { location, totalPointsOfInterest } = useLocationMapContext();
  const createPOIMutation = useCreatePointOfInterest(location.id);
  const { setTypeItem } = useFilterContext();

  const projects = useMemo(() => location?.projects?.edges || [], [location]);

  const _initialValues = useMemo(
    () => ({
      ...initialValues,
      name: `Point of Interest ${totalPointsOfInterest + 1}`,
      projectId: projects.length
        ? projects.length === 1
          ? projects[0].node.id // Only 1 project, auto select project
          : // Multiple projects, selected newest one
            projects.reduce((newestProject, p) => {
              if (!newestProject) {
                return p;
              }
              return new Date(p.node.startDate) - new Date(newestProject.node.startDate) > 0
                ? p
                : newestProject;
            }, null).node.id
        : null, // No projects
    }),
    [projects, totalPointsOfInterest],
  );

  const _onSubmit = useCallback(
    async values => {
      const { levelId, tags } = values;

      const result = await createPOIMutation.mutateAsync({
        organizationId: activeOrg.id,
        locationId: location.id,
        metadata: [
          ...tags.map(tagId => ({
            id: tagId,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
          })),
          {
            id: levelId,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
          },
        ],
        position,
        ...values,
      });

      const newPOI = result.createPointOfInterest.pointOfInterest;
      const poiEdge = { node: newPOI, tooltip: newPOI.name };
      setTypeItem(poiEdge, MEDIA_TYPES[MediaType.PointOfInterest].type); // Insert new POI into filter context

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    [createPOIMutation, onSubmitSuccess, activeOrg.id, location.id, position, setTypeItem],
  );

  return (
    <IdsCreateDialogForm
      initialValues={_initialValues}
      validationSchema={createPointOfInterestValidationSchema}
      successMessage='Point of interest created'
      errorHandler={() => 'Point of interest could not be created'}
      onSubmit={_onSubmit}
      onCancel={onCancel}
    >
      <PointOfInterestForm onColorChange={onColorChange} onFlaggedChange={onFlaggedChange} />
    </IdsCreateDialogForm>
  );
};

CreatePointOfInterestForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  position: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  onColorChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
};

export default CreatePointOfInterestForm;
