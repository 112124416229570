import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';

import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';
import FlaggableImage from '../../../FlaggableImage';
import { MEDIA_TYPE_TO_DEF } from '../../../../constants/media';
import { getMediaTypeFromUrn } from '../../../../utils/media';

const ImageThumbnailQuery = gql`
  query ImageThumbnailQuery($orgId: ID, $urn: String!, $tenantId: ID) {
    image(organizationId: $orgId, urn: $urn, tenantId: $tenantId) {
      reps {
        url
        name
      }
    }
  }
`;

const RibbonImage = ({ urn, src, flagged, tenantId, ...rest }) => {
  // Request the image reps to have a preview url if the src is not already set
  const { data } = useOrgGraphQuery(['reps', urn], ImageThumbnailQuery, { urn }, { enabled: !src });

  const _src = useMemo(() => {
    return src ? src : data?.image.reps.find(rep => rep.name === 'small')?.url || '';
  }, [src, data]);

  const TypeIcon = useMemo(() => MEDIA_TYPE_TO_DEF[getMediaTypeFromUrn(urn)].icon, [urn]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderBottomLeftIcon = useCallback(props => <TypeIcon {...props} />, [TypeIcon]);

  return (
    <FlaggableImage
      {...rest}
      loading='lazy'
      src={_src}
      placeholderIcon={<TypeIcon />}
      renderBottomLeftIcon={renderBottomLeftIcon}
      flagged={flagged}
    />
  );
};

RibbonImage.propTypes = {
  urn: PropTypes.string.isRequired,
  src: PropTypes.string,
  tenantId: PropTypes.string,
  flagged: PropTypes.bool,
};

export default RibbonImage;
