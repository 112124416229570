import React from 'react';
import { IconButton } from '@mui/material';
import { useRecoilState } from 'recoil';

import buttonStyles from '../../IdsMediaViewer.module.css';
import { DeleteIcon } from '../../../../../theme/icons';
import IdsListItemMenuItem from '../../../../ids-lists/IdsListItemMenuItem';
import { activeImageId } from '../../../../../atoms/mediaViewer';

import useDeleteMedia from './useDeleteMedia';

export interface IDeleteButtonProps {
  onDelete: (urn: string) => void;
  urn: string;
  inMenu?: boolean;
}

const DeleteButton: React.FC<IDeleteButtonProps> = ({ onDelete, urn, inMenu }) => {
  const { deleted, deleting, deleteMedia } = useDeleteMedia(urn, onDelete);
  const [, setActiveId] = useRecoilState(activeImageId);

  const onClickHandler = async () => {
    await deleteMedia();

    setActiveId(null);
  };

  return inMenu ? (
    <IdsListItemMenuItem
      onClick={onClickHandler}
      icon={<DeleteIcon />}
      disabled={deleting || deleted}
    >
      Delete
    </IdsListItemMenuItem>
  ) : (
    <IconButton
      className={buttonStyles.menuActionButton}
      disabled={deleting || deleted}
      onClick={onClickHandler}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteButton;
