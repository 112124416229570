import { useMemo, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';

import {
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  MEDIA_TYPES,
  MediaType,
  RoutePointType,
  IMetadataTypeValue,
  ROUTE_POINT_TYPES,
} from '../../../constants/media';
import { IAssignableCustomField } from '../../../constants/customFields';
import { DISPLAY_TYPES } from '../../ids-filters/MediaMetadataFilter';
import useFilterContext from '../../../hooks/useFilterContext';
import AreaFilter from '../../ids-filters/AreaFilter';
import CategoryFilter from '../../ids-filters/CategoryFilter';
import LevelFilter from '../../ids-filters/LevelFilter';
import TagsFilter from '../../ids-filters/TagsFilter';
import TypeFilter from '../../ids-filters/TypeFilter';
import TimelineFilter from '../filters/TimelineFilter';
import useRasterOverlayFilter from '../../../hooks/filtering/useRasterOverlayFilter';
import useVectorOverlayFilter from '../../../hooks/filtering/useVectorOverlayFilter';
import CustomFieldMetadataFilter from '../../ids-filters/CustomFieldMetadataFilter';
import useCustomFieldFilterVisibility from '../../../hooks/filtering/useCustomFieldFilterVisibility';
import AccordionSectionMediaTypes from '../MapMenuItem/MapMenuItemAccordions/AccordionSectionMediaTypes';

import useOverlayMenuItems from './useOverlayMenuItems';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMetadataTypeFilterBuilder
  extends Partial<Record<MediaMetadataType, IMetadataTypeValue[]>> {}

export const METADATA_TYPE_TO_FILTER = {
  [MediaMetadataType.Level]: LevelFilter,
  [MediaMetadataType.Area]: AreaFilter,
  [MediaMetadataType.PhotoType]: TypeFilter,
  [MediaMetadataType.PhotoCategory]: CategoryFilter,
  [MediaMetadataType.PhotoTag]: TagsFilter,
};

export const useMetadataTypeFiltersBuilder = (props: IMetadataTypeFilterBuilder) => {
  return useMemo(() => {
    return Object.entries(props).map(([type, items]) => {
      const Component = METADATA_TYPE_TO_FILTER[type as MediaMetadataType];

      return {
        type: MEDIA_METADATA_TYPES[type as MediaMetadataType].label,
        children: [
          {
            component: (
              <Component
                allSortedMetadataItems={items as IMetadataTypeValue[]}
                type={DISPLAY_TYPES.LIST}
              />
            ),
          },
        ],
      };
    });
  }, [props]);
};

const useFiltersBuilder = (
  allLevels: IMetadataTypeValue[],
  allAreas: IMetadataTypeValue[],
  allTypes: IMetadataTypeValue[],
  allCategories: IMetadataTypeValue[],
  allTags: IMetadataTypeValue[],
  assignableCustomFields?: IAssignableCustomField[],
) => {
  const customFieldTypes = useMemo(
    () => assignableCustomFields?.map(acf => acf.type) || [],
    [assignableCustomFields],
  );
  const { isSomeFilterVisible, handleVisibilityChange } =
    useCustomFieldFilterVisibility(customFieldTypes);

  const filters = useMetadataTypeFiltersBuilder({
    [MediaMetadataType.Level]: allLevels,
    [MediaMetadataType.Area]: allAreas,
    [MediaMetadataType.PhotoType]: allTypes,
    [MediaMetadataType.PhotoCategory]: allCategories,
    [MediaMetadataType.PhotoTag]: allTags,
  });

  return useMemo(
    () => [
      ...filters,
      {
        type: 'Custom Metadata',
        children: [
          {
            component: (
              <>
                {!isSomeFilterVisible() && (
                  <Typography variant='caption'>
                    There is no custom metadata to filter by.
                  </Typography>
                )}
                <Stack spacing={1} direction='column'>
                  {assignableCustomFields?.map(acf => (
                    <CustomFieldMetadataFilter
                      assignableCustomField={acf}
                      onVisibilityChange={handleVisibilityChange}
                      key={acf.id}
                    />
                  ))}
                </Stack>
              </>
            ),
          },
        ],
      },
      {
        type: 'Timeline',
        children: [{ component: <TimelineFilter /> }],
      },
    ],
    [assignableCustomFields, isSomeFilterVisible, handleVisibilityChange, filters],
  );
};

/**
 * Not all media types have all necessary data
 * to be rendered in the menu.
 */
const defaultMediaTypes = [
  MediaType.PanoramicPhoto,
  MediaType.HDPhoto,
  MediaType.ProjectPhoto,
  MediaType.Video,
  MediaType.ThreeDModel,
  MediaType.ThreeDVR,
  MediaType.PointOfInterest,
  MediaType.AreaOfInterest,
  MediaType.Asset,
];

const useLayersBuilder = (mediaTypes: MediaType[] | RoutePointType[] = defaultMediaTypes) => {
  const { typeMetadata, disableTypeData, enableTypeData, useTypeData, useTypeFilteredData } =
    useFilterContext();

  const { enableRasterOverlay, disableRasterOverlay } = useRasterOverlayFilter();
  const rasterOverlays = useTypeData(MediaType.RasterOverlay);
  const filteredRasterOverlays = useTypeFilteredData(MediaType.RasterOverlay);

  const {
    overlayMenuItems: rasterOverlayMenuItems,
    createOverlayFilter: createRasterOverlayFilter,
  } = useOverlayMenuItems({
    mediaType: MediaType.RasterOverlay,
    overlays: rasterOverlays,
    filteredOverlays: filteredRasterOverlays,
    enableOverlay: enableRasterOverlay,
    disableOverlay: disableRasterOverlay,
  });

  const { enableVectorOverlay, disableVectorOverlay } = useVectorOverlayFilter();
  const vectorOverlays = useTypeData(MediaType.VectorOverlay);
  const filteredVectorOverlays = useTypeFilteredData(MediaType.VectorOverlay);

  const {
    overlayMenuItems: vectorOverlayMenuItems,
    createOverlayFilter: createVectorOverlayFilter,
  } = useOverlayMenuItems({
    mediaType: MediaType.VectorOverlay,
    overlays: vectorOverlays,
    filteredOverlays: filteredVectorOverlays,
    enableOverlay: enableVectorOverlay,
    disableOverlay: disableVectorOverlay,
  });

  const filterMediaType = useCallback(
    (type, show) => {
      if (show) {
        enableTypeData(type);
      } else {
        disableTypeData(type);
      }
    },
    [enableTypeData, disableTypeData],
  );

  const createMediaTypeMenuItem = useCallback(
    mediaType => ({
      component: (
        <AccordionSectionMediaTypes
          item={{
            label: mediaType.label,
            enabled: typeMetadata[mediaType.type].enabled,
            color: mediaType.color,
            icon: mediaType.icon,
            outlinedIcon: mediaType.outlinedIcon,
          }}
          toggle={show => filterMediaType(mediaType.type, show)}
        />
      ),
    }),
    [typeMetadata, filterMediaType],
  );

  const items = useMemo(
    () => [
      {
        type: 'Base',
        children: [
          {
            label: 'Satellite',
            active: true,
            disabled: true,
          },
        ],
      },
      {
        type: 'Overlays',
        children: [...rasterOverlayMenuItems, ...vectorOverlayMenuItems],
      },
      {
        type: 'Media Type',
        children: mediaTypes.map(type => {
          if (type in MEDIA_TYPES) {
            return createMediaTypeMenuItem(MEDIA_TYPES[type as MediaType]);
          }

          return createMediaTypeMenuItem(ROUTE_POINT_TYPES[type as RoutePointType]);
        }),
      },
    ],
    [createMediaTypeMenuItem, rasterOverlayMenuItems, vectorOverlayMenuItems, mediaTypes],
  );

  return {
    items,
    createVectorOverlayFilter,
    createRasterOverlayFilter,
    rasterOverlays,
    vectorOverlays,
  };
};

export { useFiltersBuilder, useLayersBuilder };
