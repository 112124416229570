import { useCallback, useMemo } from 'react';

import { BooleanStateSummary, ItemsBooleanState } from './useItemsBooleanState';

export interface IUseBulkSelectionProps<TId extends keyof any = string> {
  selectionState: ItemsBooleanState<TId>;
  itemLookup: {
    indexToId: Record<number, TId>;
    idToIndex: Record<TId, number>;
  };
}

export interface ModifierKeyStates {
  shiftKey: boolean;
  ctrlKey: boolean;
  /** MacOS only. Labeled as `metaKey` in browser events. */
  cmdKey: boolean;
}

/** Provides helper functions to support standard bulk selection actions,
 * respecting hot keys like ctrl, cmd, and shift keys. Use in conjunction with `useItemsBooleanState`.
 */
const useBulkSelection = <TId extends string | number = string>({
  selectionState,
  itemLookup,
}: IUseBulkSelectionProps<TId>) => {
  const selectedItemIndexLookup = useMemo(() => {
    let firstSelectedId: TId | null = null;
    let lastSelectedId: TId | null = null;
    const idToIndex = selectionState.selectedItemIds.reduce<Record<TId, number>>((lookup, id) => {
      const index = itemLookup.idToIndex[id];
      lookup[id] = index;

      if (!firstSelectedId || index < lookup[firstSelectedId]) {
        firstSelectedId = id;
      }
      if (!lastSelectedId || index > lookup[lastSelectedId]) {
        lastSelectedId = id;
      }
      return lookup;
    }, {} as Record<TId, number>);

    return {
      idToIndex,
      firstSelectedId,
      lastSelectedId,
    };
  }, [selectionState.selectedItemIds, itemLookup.idToIndex]);

  /** Selects or deselects all items controlled by the master checkbox that was clicked. */
  const handleMasterCheckboxClick = useCallback(
    (
      controlledItemIds: TId[],
      /** Summarizes the selection state of the controlledItemIds. Provide this if already
       * precalculated. If not provided, it will be calculcated internally.
       */
      selectionStateSummary?: BooleanStateSummary,
    ) => {
      const _selectionStateSummary =
        selectionStateSummary ?? selectionState.getItemsStateSummary(controlledItemIds);

      if (_selectionStateSummary !== BooleanStateSummary.ALL_FALSE) {
        return selectionState.setItemsToFalse(controlledItemIds);
      }

      selectionState.setItemsToTrue(controlledItemIds);
    },
    [selectionState],
  );

  const selectRange = useCallback(
    (startIndex: number, endIndex: number) => {
      const newSelection: TId[] = [];
      for (let i = startIndex; i <= startIndex + (endIndex - startIndex); i++) {
        newSelection.push(itemLookup.indexToId[i]);
      }
      selectionState.setItemsToTrue(newSelection);
    },
    [selectionState, itemLookup.indexToId],
  );

  const handleShiftItemSelect = useCallback(
    (id: TId) => {
      const index = itemLookup.idToIndex[id];
      const firstSelectedIndex = selectedItemIndexLookup.firstSelectedId
        ? selectedItemIndexLookup.idToIndex[selectedItemIndexLookup.firstSelectedId]
        : 0; // Treat first item as selected if nothing selected
      const lastSelectedIndex = selectedItemIndexLookup.lastSelectedId
        ? selectedItemIndexLookup.idToIndex[selectedItemIndexLookup.lastSelectedId]
        : 0; // Treat first item as selected if nothing selected

      if (index < firstSelectedIndex) {
        selectRange(index, firstSelectedIndex);
      } else if (lastSelectedIndex < index) {
        selectRange(lastSelectedIndex, index);
      } else {
        selectRange(firstSelectedIndex, index);
      }
    },
    [itemLookup.idToIndex, selectedItemIndexLookup, selectRange],
  );

  /** Handles selection/deselection of the item by checkbox control. If `shift` key is pressed,
   * it will modify selection behavior when selecting the item.
   */
  const handleItemCheckboxChange = useCallback(
    (id: TId, checked: boolean, shiftKey: boolean) => {
      const isSelected = selectionState.getItemState(id);

      if (!shiftKey || isSelected) {
        selectionState.setItemState(id, checked);
        return;
      }

      handleShiftItemSelect(id);
    },
    [selectionState, handleShiftItemSelect],
  );

  /** Handles selection/deselection of the item by single click. If `shift`, `ctrl`, or `cmd` keys are pressed,
   * it will modify selection behavior when selecting the item.
   */
  const handleItemClick = useCallback(
    (id: TId, modifierKeys: ModifierKeyStates) => {
      const isSelected = selectionState.getItemState(id);
      const { shiftKey, ctrlKey, cmdKey } = modifierKeys;

      if (!shiftKey) {
        if (!ctrlKey && !cmdKey) {
          // Not batch selecting, deselect all items and select only current item
          selectionState.setAllItemsToFalse();
        }

        // Do not support deselect unless ctrl or cmd (MacOS) key is pressed
        selectionState.setItemState(id, ctrlKey || cmdKey ? !isSelected : true);
        return;
      }

      handleShiftItemSelect(id);
    },
    [selectionState, handleShiftItemSelect],
  );

  return {
    /** Selects or deselects all items controlled by the master checkbox that was clicked. */
    handleMasterCheckboxClick,
    /** Handles selection/deselection of the item by checkbox control. If `shift` key is pressed,
     * it will modify selection behavior when selecting the item. */
    handleItemCheckboxChange,
    /** Handles selection/deselection of the item by single click. If `shift`, `ctrl`, or `cmd` keys are pressed,
     * it will modify selection behavior when selecting the item. */
    handleItemClick,
  };
};

export default useBulkSelection;
