import { gql } from 'graphql-request';

// Only load the data needed for rendering and filtering markers
export const ImageMarkerFrag = gql`
  fragment ImageMarkerFrag on PrismaImage {
    id
    organizationId
    position {
      latitude
      longitude
    }
    projectId
    capturedAt
    metadata {
      type
      id
      value
      creatorId
    }
    customFieldMetadata {
      type
      id
      value
    }
    author {
      id
      firstName
      lastName
    }
    flagged
  }
`;
