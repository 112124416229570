import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import usePrevious from '../../../hooks/usePrevious';
import ImmersiveViewer from '../../../components/mapping/ImmersiveViewer';
import DraggableLayer from '../../../components/mapping/layers/DraggableLayer';
import { isLatitudeValid, isLongitudeValid, isHeadingValid } from '../../../utils/geospatial';
import { buildLocationMarkerLayer } from '../../../components/mapping/layers/locations';
import { buildHeadingArrowLayer } from '../../../components/mapping/layers/heading';

const defaultViewState = {
  zoom: 1,
  pitch: 0,
  maxPitch: 0,
  bearing: 0,
};

const EMPTY_DATA = [{}];

function LocationConfigViewer({
  children,
  viewState,
  latitude,
  longitude,
  onPositionChange,
  heading,
}) {
  const [locHeading, setLocHeading] = useState(0);
  const [locPosition, setLocPosition] = useState(
    isLongitudeValid(longitude) && isLatitudeValid(latitude) ? [longitude, latitude] : null,
  );
  const [markerDragState, setMarkerDragState] = useState({ isDragging: false });
  const [_viewState, setViewState] = useState(defaultViewState);

  const handleMarkerPosChange = useCallback(
    position => {
      setLocPosition(position);
      if (onPositionChange) {
        onPositionChange({ longitude: position[0], latitude: position[1] });
      }
    },
    [setLocPosition, onPositionChange],
  );

  // Set default locPosition if not already set
  useEffect(() => {
    if (!locPosition) {
      // Set default position to user's current position if available
      navigator.geolocation.getCurrentPosition(geoPos => {
        handleMarkerPosChange([geoPos.coords.longitude, geoPos.coords.latitude]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  useEffect(() => {
    // Use init lat lon
    if (viewState && isLatitudeValid(viewState.latitude) && isLongitudeValid(viewState.longitude)) {
      setViewState({
        ...defaultViewState,
        ...viewState,
      });
    }
  }, [viewState, setViewState]);

  const renderLocMarker = useCallback(
    position =>
      buildLocationMarkerLayer(EMPTY_DATA, {
        getPosition: position,
        cluster: false,
      }).deckLayer,
    [],
  );

  const renderLocHeading = useCallback(
    position =>
      isHeadingValid(locHeading) && buildHeadingArrowLayer(position, locHeading).deckLayer,
    [locHeading],
  );

  const draggableMarker = useMemo(
    () =>
      locPosition &&
      new DraggableLayer({
        id: 'location-marker-draggable',
        data: [{ longitude: locPosition[0], latitude: locPosition[1] }],
        renderSubLayers: position => {
          return [
            renderLocHeading(position), // render under location marker
            renderLocMarker(position),
          ];
        },
        onDragStateChange: setMarkerDragState,
        onDragged: handleMarkerPosChange,
      }),
    [locPosition, handleMarkerPosChange, renderLocMarker, renderLocHeading],
  );

  const prevLat = usePrevious(latitude);
  const prevLong = usePrevious(longitude);

  useEffect(() => {
    if (!isLatitudeValid(latitude) || !isLongitudeValid(longitude)) {
      // Lat long not valid
      if (isLatitudeValid(prevLat) && isLongitudeValid(prevLong)) {
        // Lat long was valid
        setLocPosition(null); // Remove marker
      }
      return;
    }

    const longitudeChanged =
      longitude !== prevLong && (locPosition ? longitude !== locPosition[0] : true);
    const latitudeChanged =
      latitude !== prevLat && (locPosition ? latitude !== locPosition[1] : true);

    if (latitudeChanged || longitudeChanged) {
      setLocPosition([longitude, latitude]); // deck.gl takes longitude first
      setViewState({
        ..._viewState,
        ...(latitudeChanged && { latitude: latitude }),
        ...(longitudeChanged && { longitude: longitude }),
      });
    }
  }, [longitude, prevLong, latitude, prevLat, locPosition, _viewState]);

  useEffect(() => {
    if (!isHeadingValid(heading)) {
      // Heading not valid
      return;
    }

    if (heading !== locHeading) {
      setLocHeading(heading);
    }
  }, [heading, locHeading]);

  return (
    <ImmersiveViewer
      viewState={_viewState}
      containerProps={{ height: 300 }}
      deckProps={{
        layers: [draggableMarker],
        onClick: info => handleMarkerPosChange(info.coordinate),

        // Disable controller panning while marker is being dragged
        ...(markerDragState.isDragging && { controller: { dragPan: false } }),
      }}
      onViewStateChange={setViewState}
    >
      {children}
    </ImmersiveViewer>
  );
}

LocationConfigViewer.propTypes = {
  initialViewState: PropTypes.object,
  latitude: PropTypes.any, // Location latitude
  longitude: PropTypes.any, // Location longitude
  onPositionChange: PropTypes.func, // onPositionChange({ longitude, latitude })
  heading: PropTypes.any,
};

export default LocationConfigViewer;
