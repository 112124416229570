import React, { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRecoilValue } from 'recoil';

import SaveButton from '../../../../../../components/ids-buttons/SaveButton';
import CancelButton from '../../../../../../components/ids-buttons/CancelButton';

import { activeProjectRouteState } from '../../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../../atoms/organizations';

import { useManageRoutePointsMutation } from '../../../../../../services/RouteService';

import { IRoutePoint } from '../../../types';

export interface IRepositioningButtonsProps {
  points: IRoutePoint[];
  onCancel: () => void;
}

const RepositioningButtons: React.FC<IRepositioningButtonsProps> = ({ points, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const activeProjectRoute = useRecoilValue(activeProjectRouteState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const manageRoutePoints = useManageRoutePointsMutation();

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSave = useCallback(async () => {
    setIsUpdating(true);

    try {
      const result = await manageRoutePoints.mutateAsync({
        id: activeProjectRoute!.id,
        organizationId: activeOrg.id,
        update: points.map(p => ({
          id: p.node.id,
          customFieldMetadata: [],
          metadata: p.node.metadata.map(m => ({ id: m.id, type: m.type })),
          position: p.node.position,
        })),
        add: [],
        delete: [],
      });

      /**
       * @TODO: think about moving such try-catch & enqueueSnackbar into
       * mutation's onSuccess handler.
       */
      if (result.manageRoutePoints?.errors?.length) {
        enqueueSnackbar('Failed to update points', { variant: 'error' });
      } else {
        enqueueSnackbar('Updated', { variant: 'success' });
      }
    } catch (_) {
      enqueueSnackbar('Failed to update points', { variant: 'error' });
    }

    setIsUpdating(false);

    // Clear fields
    onCancel();
  }, [activeProjectRoute, activeOrg.id, manageRoutePoints, enqueueSnackbar, onCancel, points]);

  return (
    <Grid item xs container direction='row' gap={2}>
      <Grid item xs>
        <CancelButton onClick={handleCancel} disabled={isUpdating} fullWidth={true} />
      </Grid>
      <Grid item xs>
        <SaveButton onClick={handleSave} disabled={isUpdating || !points.length} fullWidth={true} />
      </Grid>
    </Grid>
  );
};

export default RepositioningButtons;
