import { IconLayer } from '@deck.gl/layers';

import buildIdsLayer from '../IdsLayer';

const headingIconMapping = {
  headingArrow: { x: 0, y: 0, width: 1687, height: 1983, mask: true },
};

const EMPTY_DATA = [{}];

export const buildHeadingArrowLayer = (
  [longitude, latitude],
  heading,
  iconLayerProps,
  idsLayerProps,
) => {
  return buildIdsLayer(
    new IconLayer({
      id: 'heading-icon',
      data: EMPTY_DATA,
      pickable: true,
      iconAtlas: '/static/icons/heading-arrow.png',
      iconMapping: headingIconMapping,
      getIcon: d => 'headingArrow',
      getPosition: [longitude, latitude],
      getSize: d => 50,
      // Heading value is inverted from how rotation is rendered
      getAngle: heading * -1,
      getColor: [254, 175, 55, 255],
      billboard: false,
      ...iconLayerProps,
    }),
    idsLayerProps,
  );
};
