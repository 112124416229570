import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Uppy, { UploadResult, UppyFile } from '@uppy/core';
import XHRUpload, { XHRUploadOptions } from '@uppy/xhr-upload';
import exifr from 'exifr';
import { Box, Divider, Stack } from '@mui/material';

import queryClient from '../../../utils/query';
import IdsImage from '../../../components/IdsImage';
import RuntimeConfig from '../../../RuntimeConfig';
import useIdsUploaderContext from '../../useIdsUploaderContext';
import usePermissions from '../../usePermissions';
import { useGetLocationMetadataTypes } from '../../../services/LocationService';
import {
  IUploadStep,
  IUploader,
  IUploaderValue,
  UploadType,
  IBaseUploaderProps,
} from '../../../constants/uploads';
import { MediaType, ImageType, IMetadataType, MEDIA_TYPES } from '../../../constants/media';
import { USER_ROLES } from '../../../constants/users';
import keycloak from '../../../keycloak';
import usePrevious from '../../usePrevious';
import { getUUID } from '../../../utils/helpers';
import useUppyEventHandler from '../../useUppyEventHandler';
import IdsImageTypeSelect from '../../../components/ids-inputs/common/IdsImageTypeSelect';
import { IIdsImageProps } from '../../../components/IdsImage';
import ProjectPhotoForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/ProjectPhotoForm';
import ProjectPhotoMarkerForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/ProjectPhotoMarkerForm';
import {
  projectPhotoNoMarkerValidationSchema,
  projectPhotoValidationSchema,
} from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/ProjectPhotoForm/projectPhotoValidation';
import HDPhotoForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/HDPhotoForm';
import HDPhotoMarkerForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/HDPhotoMarkerForm';
import {
  hdPhotoNoMarkerValidationSchema,
  hdPhotoValidationSchema,
} from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/HDPhotoForm/hdPhotoValidation';
import PanoramicPhotoForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/PanoramicPhotoForm';
import PanoramicPhotoMarkerForm from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/PanoramicPhotoMarkerForm';
import {
  panoramicPhotoNoMarkerValidationSchema,
  panoramicPhotoValidationSchema,
} from '../../../components/ids-inputs/uploaders/LocationImageUploader/single/PanoramicPhotoForm/panoramicPhotoValidation';

import styles from './UseSingleLocationImageUploader.module.css';

const getXHRUploadId = (uppyId: string) => `${uppyId}-XHRUpload`;

export interface IImageDataFormProps {
  metadataTypes: IMetadataType[];
  hideLocation?: boolean;
  hideProject?: boolean;
}

export type ThumbnailRenderer = (props?: IIdsImageProps) => React.ReactNode;

export interface IImageMarkerFormProps {
  metadataTypes: IMetadataType[];
  renderThumbnail: ThumbnailRenderer;
}

type UploadResponse = UppyFile['response'];

export interface IImageTypeUploaderConfig {
  xhrUploadOptions: XHRUploadOptions;
  formProps: IUploaderValue['formProps'];
  imageDataStep: {
    formFields: React.FC<IImageDataFormProps>;
    validationSchema: IUploadStep['validationSchema'];
  };
  imageMarkerStep: {
    formFields: React.FC<IImageMarkerFormProps>;
    validationSchema: IUploadStep['validationSchema'];
  };
  selectNewImageData: (response: UploadResponse) => any;
}

const IMAGE_TYPE_PROPS: Record<ImageType, IImageTypeUploaderConfig> = {
  [MediaType.ProjectPhoto]: {
    xhrUploadOptions: {
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/user_photos`,
      allowedMetaFields: [
        'location_id',
        'project_id',
        'latitude',
        'longitude',
        'description',
        'photo_category_id',
        'photo_type_id',
        'captured_at',
        'photo_level_id',
        'photo_area_id',
        'uuid',
        'photo_tags',
      ],
    },
    formProps: {
      initialValues: {
        location_id: null,
        project_id: null,
        latitude: '',
        longitude: '',
        description: '',
        photo_category_id: null,
        photo_type_id: null,
        captured_at: new Date().toUTCString(),
        photo_level_id: null,
        photo_area_id: null,
        photo_tags: [],
      },
      successMessage: 'Image uploaded, processing will be done shortly',
      cancelMessage: 'Image upload canceled',
      errorHandler: (err: string) => err,
    },
    imageDataStep: {
      formFields: ProjectPhotoForm,
      validationSchema: projectPhotoNoMarkerValidationSchema,
    },
    imageMarkerStep: {
      formFields: ProjectPhotoMarkerForm,
      validationSchema: projectPhotoValidationSchema,
    },
    selectNewImageData: (response: UploadResponse) => response?.body.user_photo,
  },
  [MediaType.HDPhoto]: {
    xhrUploadOptions: {
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/hd_photos`,
      allowedMetaFields: [
        'location_id',
        'project_id',
        'latitude',
        'longitude',
        'description',
        'captured_at',
        'photo_level_id',
        'photo_area_id',
        'uuid',
        // 'photo_tags',
      ],
    },
    formProps: {
      initialValues: {
        location_id: null,
        project_id: null,
        latitude: '',
        longitude: '',
        description: '',
        captured_at: new Date().toUTCString(),
        photo_level_id: null,
        photo_area_id: null,
        // photo_tags: [],
      },
      successMessage: 'Image uploaded, processing will be done shortly',
      cancelMessage: 'Image upload canceled',
      errorHandler: (err: string) => err,
    },
    imageDataStep: {
      formFields: HDPhotoForm,
      validationSchema: hdPhotoNoMarkerValidationSchema,
    },
    imageMarkerStep: {
      formFields: HDPhotoMarkerForm,
      validationSchema: hdPhotoValidationSchema,
    },
    selectNewImageData: (response: UploadResponse) => response?.body.hd_photo,
  },
  [MediaType.PanoramicPhoto]: {
    xhrUploadOptions: {
      endpoint: `${RuntimeConfig.apiBaseUrl}/api/v2/panoramas`,
      allowedMetaFields: [
        'location_id',
        'project_id',
        'latitude',
        'longitude',
        'description',
        'captured_at',
        'altitude',
        'heading',
        'photo_level_id',
        'photo_area_id',
        'uuid',
        // 'photo_tags',
      ],
    },
    formProps: {
      initialValues: {
        location_id: null,
        project_id: null,
        latitude: '',
        longitude: '',
        description: '',
        captured_at: new Date().toUTCString(),
        altitude: 0,
        heading: 0,
        photo_level_id: null,
        photo_area_id: null,
        // photo_tags: [],
      },
      successMessage: 'Image uploaded, processing will be done shortly',
      cancelMessage: 'Image upload canceled',
      errorHandler: (err: string) => err,
    },
    imageDataStep: {
      formFields: PanoramicPhotoForm,
      validationSchema: panoramicPhotoNoMarkerValidationSchema,
    },
    imageMarkerStep: {
      formFields: PanoramicPhotoMarkerForm,
      validationSchema: panoramicPhotoValidationSchema,
    },
    selectNewImageData: (response: UploadResponse) => response?.body.panorama,
  },
};

export interface IInitialValues {
  latitude?: number;
  longitude?: number;
  altitude?: number;
  heading?: number;
  // snake case is used here to match the request/form shape for ease of use
  photo_category_id?: string;
  photo_type_id?: string;
  photo_level_id?: string;
  photo_area_id?: string;
  photo_tags?: string[];
}

export interface ISingleLocationImageUploaderProps extends IBaseUploaderProps {
  defaultLocationId?: string;
  defaultProjectId?: string;
  initialValues?: IInitialValues;
  disabledImageTypes?: ImageType[];
  onUploadComplete?: (submissionValues: any, newImageData: any, imageType: ImageType) => void;
}

const useSingleLocationImageUploader = (
  enabled: boolean,
  {
    defaultLocationId,
    defaultProjectId,
    initialValues,
    disabledImageTypes,
    onUploadComplete,
  }: ISingleLocationImageUploaderProps,
) => {
  const prevEnabled = usePrevious(enabled);
  const [thumbnail, setThumbnail] = useState<string>('');
  const [imageType, setImageType] = useState<ImageType | undefined>();
  const uploadOptionsRequireReset = useRef(false);
  const { uppy } = useIdsUploaderContext();
  const { userHasOneOfRoles } = usePermissions();
  const [sharedInitialValues, setSharedInitialValues] = useState<Partial<IInitialValues>>({});
  const [locationId, setLocationId] = useState<string | null>(defaultLocationId || null);

  const { data: locationMetadataTypesData } = useGetLocationMetadataTypes(locationId);
  const metadataTypes = useMemo<IMetadataType[]>(
    () => locationMetadataTypesData?.location.metadataTypes || [],
    [locationMetadataTypesData],
  );

  const uploaderConfig = useMemo<IImageTypeUploaderConfig | undefined>(
    () => imageType && IMAGE_TYPE_PROPS[imageType],
    [imageType],
  );

  useEffect(() => {
    if (!enabled || !uppy || !uploaderConfig) return;

    const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
    xhr?.setOptions({
      ...uploaderConfig.xhrUploadOptions,
    });
  }, [enabled, uppy, uploaderConfig]);

  const handleFileAdded = useCallback(
    (file: UppyFile) => {
      setSharedInitialValues((prev: any) => ({
        ...prev,
        description: file.name,
      }));

      exifr
        .parse(file.data, {
          pick: ['DateTimeOriginal', 'CreateDate', 'GPSAltitude'],
        })
        .then((exif: any) => {
          if (!exif) {
            uppy!.setFileMeta(file.id, {
              captured_at: new Date().toUTCString(),
              altitude: 0,
            });
            return;
          }

          const { DateTimeOriginal, CreateDate, GPSAltitude } = exif;

          const exifCapturedAt = DateTimeOriginal || CreateDate;
          const exifGPSAltitudeValid =
            typeof GPSAltitude === 'number' && !Number.isNaN(GPSAltitude);

          setSharedInitialValues((prev: any) => ({
            ...prev,
            ...(exifCapturedAt && { captured_at: exifCapturedAt }),
            ...(exifGPSAltitudeValid && { altitude: Math.round(GPSAltitude) }), // must be an integer
          }));
        })
        .finally(() => {
          exifr.gps(file.data).then((gps: any) => {
            if (gps) {
              setSharedInitialValues((prev: any) => ({
                ...prev,
                latitude: gps.latitude,
                longitude: gps.longitude,
              }));
            }
          });
        });
    },
    [uppy],
  );

  useUppyEventHandler('file-added', handleFileAdded, uppy, enabled);

  useEffect(() => {
    if (!enabled || prevEnabled) return;

    const files = uppy.getFiles();

    if (files.length === 1) {
      // Handle file added before mount
      handleFileAdded(files[0]);

      if (files[0].preview) {
        // thumbnail already generated
        setThumbnail(files[0].preview);
      }
    }
  }, [enabled, prevEnabled, uppy, handleFileAdded]);

  const handleFileRemoved = useCallback(() => {
    setSharedInitialValues((prev: any) => ({
      ...prev,
      captured_at: new Date().toUTCString(),
      latitude: '',
      longitude: '',
      description: '',
    }));
  }, []);

  useUppyEventHandler('file-removed', handleFileRemoved, uppy, enabled);

  const handleThumbnailGenerated = useCallback(
    (_: UppyFile<Record<string, unknown>>, preview: string) => {
      setThumbnail(preview);
    },
    [setThumbnail],
  );

  useUppyEventHandler('thumbnail:generated', handleThumbnailGenerated, uppy, enabled);

  const handleFormSubmit = useCallback(
    (values: IInitialValues) => {
      const file = uppy.getFiles()[0];

      if (!file) {
        throw new Error('Upload preprocessing failed: no file selected');
      }

      if (!uploaderConfig) {
        throw new Error('Upload preprocessing failed: configuration not set.');
      }

      const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
      if (!xhr) {
        throw new Error('Upload preprocessing failed: XHRUploader is not initialized.');
      }

      const { xhrUploadOptions } = uploaderConfig;

      const submissionValues: any = {
        ...values,
        uuid: getUUID(),
      };

      // Remove any null or undefined form fields, results in BE errors if left
      Object.keys(submissionValues).forEach((field: string) => {
        if (submissionValues[field] === null || submissionValues[field] === undefined) {
          delete submissionValues[field];
        }
      });

      if (xhrUploadOptions) {
        const arrayMetaFields = xhrUploadOptions.allowedMetaFields?.filter(field => {
          const value = submissionValues[field];
          return value && Array.isArray(value);
        });

        // All file specific fields should be included
        const newAllowedMetaFields: string[] = [];

        if (xhrUploadOptions.allowedMetaFields) {
          xhrUploadOptions.allowedMetaFields.forEach(field => {
            if (arrayMetaFields?.includes(field)) {
              // Meta fields are submitted as FormData where the array
              // field names must be appended with []. XHRUploader doesn't
              // auto append that, update allowed meta fields and upload meta
              // to properly send array form data.
              const arrayField = `${field}[]`;
              newAllowedMetaFields.push(arrayField);

              submissionValues[arrayField] = submissionValues[field];
              delete submissionValues[field];
            }
            // Only allow meta field if it is not undefined or null
            else if (submissionValues[field] !== undefined && submissionValues[field] !== null) {
              newAllowedMetaFields.push(field);
            }
          });

          xhr.setOptions({
            allowedMetaFields: newAllowedMetaFields,
          });

          uploadOptionsRequireReset.current = true;
        }

        uppy.setFileMeta(file.id, submissionValues);
      }
    },
    [uppy, uploaderConfig],
  );

  const handleUploadComplete = useCallback(
    (result: UploadResult) => {
      if (!result.successful.length) return;

      const submissionValues = result.successful[0].meta;

      queryClient.invalidateQueries({
        // Invalidate all queries for image type
        predicate: query => {
          return query.queryKey.includes(imageType!);
        },
        refetchActive: true,
      });

      if (uploadOptionsRequireReset.current) {
        // Reset manipulated allowed meta fields on xhr uploader
        const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
        if (xhr) {
          xhr.setOptions({
            allowedMetaFields: uploaderConfig?.xhrUploadOptions?.allowedMetaFields,
          });
        }
      }

      const { response } = result.successful[0];

      if (onUploadComplete) {
        const newImageData = uploaderConfig?.selectNewImageData(response) || {};
        onUploadComplete(submissionValues, newImageData, imageType!);
      }
    },
    [imageType, onUploadComplete, uploaderConfig, uppy],
  );

  useUppyEventHandler('complete', handleUploadComplete, uppy, enabled);

  const fullInitialValues = useMemo(() => {
    if (!imageType) return {};

    const fullSharedInitialValues: any = {
      // not setting these in sharedInitialValues to allow for removed init values from being persisted when spreading
      // prev shared initial values
      ...sharedInitialValues,
      ...initialValues,
    };

    const typeInitialValues = { ...IMAGE_TYPE_PROPS[imageType].formProps.initialValues };
    return Object.keys(typeInitialValues).reduce((result, field) => {
      // Only use shared initial values that apply to the current image type fields
      if (field in result && fullSharedInitialValues[field]) {
        result[field] = fullSharedInitialValues[field];
      }

      return result;
    }, typeInitialValues);
  }, [imageType, sharedInitialValues, initialValues]);

  const renderThumbnail = useCallback(
    (thumbnail: string, props?: IIdsImageProps) => {
      const TypeIcon = imageType && MEDIA_TYPES[imageType].icon;

      return (
        <IdsImage
          src={thumbnail}
          height={100}
          width={100}
          {...(TypeIcon && {
            placeholderIcon: <TypeIcon />,
          })}
          {...props}
        />
      );
    },
    [imageType],
  );

  const uploadSteps = useMemo<IUploadStep[]>(() => {
    if (!enabled) return [];

    const disabledTypes: ImageType[] = [];

    if (disabledImageTypes) {
      // support external image type disabling
      disabledTypes.push(...disabledImageTypes);
    }

    // Disable hds and panos for non IDS users
    if (
      !userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ])
    ) {
      if (!disabledTypes.includes(MediaType.HDPhoto)) {
        disabledTypes.push(MediaType.HDPhoto);
      }
      if (!disabledTypes.includes(MediaType.PanoramicPhoto)) {
        disabledTypes.push(MediaType.PanoramicPhoto);
      }
    }

    let imageDataFields = null;
    let imageMarkerForm = null;

    if (uploaderConfig) {
      const ImageDataForm = uploaderConfig?.imageDataStep.formFields;
      imageDataFields = (
        <>
          <Divider className={styles.divider} />
          <ImageDataForm
            metadataTypes={metadataTypes}
            hideLocation={!!defaultLocationId} // hide location if default is provided
            hideProject={!!defaultProjectId} // hide project if default is provided
          />
        </>
      );

      const ImageMarkerForm = uploaderConfig?.imageMarkerStep.formFields;
      imageMarkerForm = (
        <ImageMarkerForm
          metadataTypes={metadataTypes}
          renderThumbnail={(props?: IIdsImageProps) => renderThumbnail(thumbnail, props)}
        />
      );
    }

    return [
      {
        label: 'Enter common image data',
        formFields: (
          <Box pt={1}>
            <Stack direction='row' spacing={2} alignItems='center'>
              {renderThumbnail(thumbnail)}
              <IdsImageTypeSelect
                onChange={setImageType}
                value={imageType}
                disabledTypes={disabledTypes}
              />
            </Stack>
            {imageDataFields}
          </Box>
        ),
        validationSchema: uploaderConfig?.imageDataStep.validationSchema,
        disableNextButton: !imageType,
      },
      {
        label: 'Place image marker',
        formFields: imageMarkerForm,
        validationSchema: uploaderConfig?.imageMarkerStep.validationSchema,
      },
    ];
  }, [
    enabled,
    imageType,
    uploaderConfig,
    disabledImageTypes,
    userHasOneOfRoles,
    defaultLocationId,
    defaultProjectId,
    metadataTypes,
    renderThumbnail,
    thumbnail,
  ]);

  const handleFormValuesChange = useCallback(
    (values: any) => {
      const { location_id } = values;
      setLocationId(location_id);
    },
    [setLocationId],
  );

  useEffect(() => {
    setSharedInitialValues((prev: any) => ({
      ...prev,
      location_id: defaultLocationId,
      project_id: defaultProjectId,
    }));
  }, [defaultLocationId, defaultProjectId, setSharedInitialValues]);

  return useMemo<IUploaderValue | null>(
    () =>
      enabled
        ? ({
            formProps: {
              ...(imageType && IMAGE_TYPE_PROPS[imageType].formProps),
              initialValues: fullInitialValues,
            },
            fieldsToReinitialize: ['captured_at', 'latitude', 'longitude', 'description'],
            uploadSteps,
            onFormValuesChange: handleFormValuesChange,
            onFormSubmit: handleFormSubmit,
          } as IUploaderValue)
        : null,
    [enabled, fullInitialValues, uploadSteps, handleFormValuesChange, handleFormSubmit, imageType],
  );
};

const IMAGE_TYPES = [
  MediaType.ProjectPhoto,
  MediaType.HDPhoto,
  MediaType.PanoramicPhoto,
] as ImageType[];

const defaultXHROptions: XHRUploadOptions = {
  endpoint: '',
  bundle: false,
  timeout: 0, // disable timeout to handle slow BE processing
  headers: () => ({
    // Ensure the keycloak token is updated before uploading
    authorization: `Bearer ${keycloak.token}`,
    'x-client-id': 'Explorer',
  }),
};

export const SingleLocationImageUploader: IUploader<ISingleLocationImageUploaderProps> = {
  uploadType: UploadType.SingleLocationImage,
  label: 'Single image',
  uppyRestrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['.jpg', '.jpeg'],
  },
  isAvailable: (_, __, userHasOneOfRoles, { disabledImageTypes }) => {
    const enabledImageTypes = IMAGE_TYPES.filter(t => !disabledImageTypes?.includes(t));

    if (!enabledImageTypes.length) return false;

    // Only hds and/or panos are enabled and user doesn't have permission to create them
    if (
      !userHasOneOfRoles([
        USER_ROLES.IDS_ADMIN,
        USER_ROLES.IDS_TEAM,
        USER_ROLES.TENANT_ADMIN,
        USER_ROLES.TENANT_TEAM,
      ]) &&
      !enabledImageTypes.includes(MediaType.ProjectPhoto)
    ) {
      return false;
    }

    return true;
  },
  useUploader: useSingleLocationImageUploader,
  configureUppy: (uppy: Uppy) => {
    const xhrId = getXHRUploadId(uppy.getID());

    if (!uppy.getPlugin(xhrId)) {
      // Plugin not already installed
      uppy.use(XHRUpload, {
        id: xhrId,
        ...defaultXHROptions,
      });
    }
  },
  deconfigureUppy: (uppy: Uppy) => {
    const xhr = uppy.getPlugin(getXHRUploadId(uppy.getID()));
    if (xhr) {
      uppy.removePlugin(xhr);
    }
  },
  queueUpload: false,
};

export default useSingleLocationImageUploader;
