import { useCallback, useState, useRef } from 'react';

import useMountedEffect from './useMountedEffect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IObservableItemState extends Record<string, any> {}

export type ObservableItemStateUpdate<T extends IObservableItemState = IObservableItemState> =
  | Partial<T>
  | null
  | undefined;

export type ObservableItemStateListener<T extends IObservableItemState = IObservableItemState> = (
  state: T,
) => void;

export interface IObservableItem<T extends IObservableItemState = IObservableItemState> {
  state: T;
  listeners: Record<number, ObservableItemStateListener<T>>;
}

export interface IObservableItemStateProps<
  DefaultState extends IObservableItemState = IObservableItemState,
> {
  id: string;
  defaultState?: DefaultState | null;
  getItemState: <T extends IObservableItemState = IObservableItemState>(
    id: string,
  ) => T | undefined;
  setItemState: <T extends IObservableItemState = IObservableItemState>(
    id: string,
    stateUpdate: ObservableItemStateUpdate<T>,
    replace?: boolean,
  ) => T | null | undefined;
  addItemStateListener: <T extends IObservableItemState = IObservableItemState>(
    itemId: string,
    listenerId: number | null | undefined,
    listener: ObservableItemStateListener<T>,
  ) => number | undefined;
  removeItemStateListener: (itemId: string, listenerId: number) => boolean;
}

const useObservableStates = () => {
  // Tracking items in a ref to account for multiple updates that could come
  // in at the same time, overwriting each other.
  // A ref is mutable, so it just gets updated in place,
  // instead of a state variable, which is overwritten with each new update.
  // It also doesn't trigger a rerender,
  // giving control over whichcomponents are rerendered.
  const items = useRef<Record<string, IObservableItem<any>>>({});
  const listenerIdCount = useRef(0);

  const createItem = useCallback(
    <T extends IObservableItemState = IObservableItemState>(
      id: string,
      state?: T | null,
      listeners?: IObservableItem<T>['listeners'],
    ) => {
      const newItem: IObservableItem<T> = {
        state: state || ({} as T),
        listeners: listeners || ({} as IObservableItem<T>['listeners']),
      };
      items.current[id] = newItem;
      return newItem;
    },
    [],
  );

  const getItemById = useCallback((id: string) => {
    return id ? items.current[id] : undefined;
  }, []);

  const getItemState = useCallback(
    <T>(id: string) => {
      const item = getItemById(id);
      return item ? (item.state as T) : item;
    },
    [getItemById],
  );

  /** 
    - Updates the mutable item state.
    - **Only updates the state unless `replace` is true, 
    in that case it will use `stateUpdate` as the new state value.**
    - *Returns new state*
   */
  const setItemState = useCallback(
    <T extends IObservableItemState = IObservableItemState>(
      id: string,
      stateUpdate: ObservableItemStateUpdate<T>,
      replace = false,
    ) => {
      if (!id) {
        return null;
      }

      if (!stateUpdate && !replace) {
        // Null state and replace is not true, delete the item
        delete items.current[id];
        return;
      }

      let item = getItemById(id);

      if (!item) {
        // New item
        if (!stateUpdate) {
          // Empty state, don't create the item
          return;
        }

        item = createItem(id, { ...stateUpdate });
      } else {
        // Item exists, update the state or
        // replace it entirely if replace is true
        item.state = { ...(!replace && item.state), ...stateUpdate };
      }

      items.current[id] = item;

      // Notify listeners
      Object.values<ObservableItemStateListener<T>>(item.listeners).forEach(listener => {
        listener(item!.state as T);
      });

      return item.state as T;
    },
    [getItemById, createItem],
  );

  /** Returns listener id if listener was added; `undefined` if it was not. */
  const addItemStateListener = useCallback(
    <T extends IObservableItemState = IObservableItemState>(
      itemId: string,
      listenerId: number | null | undefined,
      listener: ObservableItemStateListener<T>,
    ) => {
      if (!itemId || !listener) {
        return undefined;
      }

      let item = getItemById(itemId);
      let _listenerId = listenerId;
      if (!_listenerId) {
        listenerIdCount.current += 1;
        _listenerId = listenerIdCount.current;
      }

      if (!item) {
        // New item
        item = createItem(itemId, null, { [_listenerId]: listener });
      } else {
        // Item exists, update the listeners
        item.listeners[_listenerId] = listener;
      }

      items.current[itemId] = item;

      return _listenerId;
    },
    [getItemById, createItem],
  );

  /** Returns bool indicating if listener was removed */
  const removeItemStateListener = useCallback(
    (itemId: string, listenerId: number) => {
      if (!itemId || !listenerId) {
        return false;
      }

      const item = getItemById(itemId);

      if (!item) {
        return false;
      }

      delete item.listeners[listenerId]; // Delete the listener
      items.current[itemId] = item;

      return true;
    },
    [getItemById],
  );

  return {
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  };
};

export default useObservableStates;

export const useObservableItemState = <T extends IObservableItemState = IObservableItemState>({
  id,
  defaultState,
  getItemState,
  setItemState,
  addItemStateListener,
  removeItemStateListener,
}: IObservableItemStateProps<T>) => {
  const _id = useRef(id);
  const [state, _setState] = useState<T>();

  const setState = useCallback(
    (stateUpdate: Partial<T>, replace = false) => {
      setItemState<T>(_id.current, stateUpdate, replace);
    },
    [setItemState],
  );

  useMountedEffect(() => {
    const currentState = getItemState<T>(id);
    if (!currentState) {
      // Use default value, state hasn't been initialized
      setState(defaultState as T);
    } else {
      // Use existing state value
      _setState(currentState);
    }

    const listenerId = addItemStateListener<T>(_id.current, null, _setState);

    return () => {
      if (listenerId) {
        removeItemStateListener(_id.current, listenerId);
      }
    };
  });

  return [state, setState] as const;
};

export type IObservableItemValueProps = Omit<IObservableItemStateProps, 'defaultState'>;

export const useObservableItemValue = <T extends IObservableItemState = IObservableItemState>({
  id,
  getItemState,
  setItemState,
  addItemStateListener,
  removeItemStateListener,
}: IObservableItemValueProps) => {
  const [state] = useObservableItemState<T>({
    id,
    defaultState: null,
    getItemState,
    setItemState,
    addItemStateListener,
    removeItemStateListener,
  });
  return state;
};
