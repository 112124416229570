import React, { useState } from 'react';
import { gql } from 'graphql-request';
import { Accordion, AccordionDetails } from '@mui/material';

import AssignmentEntityAccordionSummary from '../../../../AssignmentEntityAccordion';
import AssignmentResponseTask, {
  AssignmentResponseTaskHeaderFrag,
} from '../AssignmentResponseTask';

export const AssignmentResponseSectionFrag = gql`
  fragment AssignmentResponseSectionFrag on AssignmentSection {
    id
    title
    assignmentTasks {
      edges {
        node {
          id
          ...AssignmentResponseTaskHeaderFrag
        }
      }
    }
  }
  ${AssignmentResponseTaskHeaderFrag}
`;

function AssignmentResponseSection({ assignmentResponse, section, ...rest }) {
  const [expandedTaskId, setExpandedTaskId] = useState();

  const handleExpandedChange = id => (e, isExpanded) => {
    setExpandedTaskId(isExpanded ? id : null);
  };

  const { id, title, assignmentTasks } = section;

  return (
    <Accordion key={id} disableGutters TransitionProps={{ unmountOnExit: true }} {...rest}>
      <AssignmentEntityAccordionSummary primaryText={title} secondaryText='Section' />
      <AccordionDetails>
        {assignmentTasks.edges.map(e => (
          <AssignmentResponseTask
            key={e.node.id}
            task={e.node}
            assignmentResponse={assignmentResponse}
            // Only one can be opened at a time
            expanded={expandedTaskId === e.node.id}
            onChange={handleExpandedChange(e.node.id)}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default AssignmentResponseSection;
