import { useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { RoutePointType } from '../../../../constants/media';
import { ROUTE_POINT_TYPE_LAYER_IDS } from '../../../../constants/layerIds';

import { clusterState } from '../../../../atoms/immersiveViewer';

import useFilterContext from '../../../../hooks/useFilterContext';
import useImmersiveViewer from '../../../../hooks/useImmersiveViewer';
import LayerEventsContext, { IEvents } from '../../../../context/LayerEventsContext';

import {
  buildHDPhotoMarkerLayer,
  buildPanoramaMarkerLayer,
  buildProjectPhotoMarkerLayer,
} from '../../../../components/mapping/layers/media-markers';

import { IRoutePoint } from '../types';
import { getDslrPanoramaClusterProps, modifiers } from '../helpers';

const useProjectPhotosLayer = (events: IEvents | null) => {
  const cluster = useRecoilValue(clusterState);
  const { setLayer } = useImmersiveViewer();
  const { useTypeFilteredData } = useFilterContext();

  const filteredProjectPhotos = useTypeFilteredData<IRoutePoint>(RoutePointType.ProjectPhoto);

  useEffect(() => {
    if (!filteredProjectPhotos) {
      return;
    }

    setLayer(
      ROUTE_POINT_TYPE_LAYER_IDS[RoutePointType.ProjectPhoto],
      buildProjectPhotoMarkerLayer(
        filteredProjectPhotos,
        {
          cluster,
          visible: true,
          pickable: true,
          ...events,
        },
        null,
        modifiers,
      ),
    );
  }, [filteredProjectPhotos, cluster, setLayer, events]);
};

const useHdPhotosLayer = (events: IEvents | null) => {
  const cluster = useRecoilValue(clusterState);
  const { setLayer } = useImmersiveViewer();
  const { useTypeFilteredData } = useFilterContext();

  const filteredHdPhotos = useTypeFilteredData<IRoutePoint>(RoutePointType.DslrHdPhoto);

  useEffect(() => {
    if (!filteredHdPhotos) {
      return;
    }

    setLayer(
      ROUTE_POINT_TYPE_LAYER_IDS[RoutePointType.DslrHdPhoto],
      buildHDPhotoMarkerLayer(
        filteredHdPhotos,
        {
          cluster,
          visible: true,
          pickable: true,
          ...events,
        },
        null,
        modifiers,
      ),
    );
  }, [filteredHdPhotos, cluster, setLayer, events]);
};

const usePanoramasLayer = (levelIds: string[], events: IEvents | null) => {
  const cluster = useRecoilValue(clusterState);
  const { setLayer } = useImmersiveViewer();
  const { useTypeFilteredData } = useFilterContext();

  const filteredPanoramas = useTypeFilteredData<IRoutePoint>(RoutePointType.Panorama);

  useEffect(() => {
    if (!filteredPanoramas) {
      return;
    }

    setLayer(
      ROUTE_POINT_TYPE_LAYER_IDS[RoutePointType.Panorama],
      buildPanoramaMarkerLayer(
        filteredPanoramas,
        levelIds,
        {
          cluster,
          visible: true,
          pickable: true,
          ...events,
        },
        null,
        modifiers,
      ),
    );
  }, [filteredPanoramas, cluster, setLayer, levelIds, events]);
};

const useDslrPanoramasLayer = (levelIds: string[], events: IEvents | null) => {
  const cluster = useRecoilValue(clusterState);
  const { setLayer } = useImmersiveViewer();
  const { useTypeFilteredData } = useFilterContext();

  const filteredDslrPanoramas = useTypeFilteredData<IRoutePoint>(RoutePointType.DslrPanorama);

  useEffect(() => {
    if (!filteredDslrPanoramas) {
      return;
    }

    setLayer(
      ROUTE_POINT_TYPE_LAYER_IDS[RoutePointType.DslrPanorama],
      buildPanoramaMarkerLayer(
        filteredDslrPanoramas,
        levelIds,
        getDslrPanoramaClusterProps({
          cluster,
          visible: true,
          pickable: true,
          ...events,
        }),
        null,
        modifiers,
      ),
    );
  }, [filteredDslrPanoramas, cluster, setLayer, levelIds, events]);
};

const usePointLayers = (levelIds: string[], events: IEvents | null = null) => {
  const { value } = useContext(LayerEventsContext);

  const eventsToHandle = events || value;

  useProjectPhotosLayer(eventsToHandle);
  useHdPhotosLayer(eventsToHandle);
  usePanoramasLayer(levelIds, eventsToHandle);
  useDslrPanoramasLayer(levelIds, eventsToHandle);
};

export default usePointLayers;
