import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';

import { publishedFilterState } from '../../atoms/immersiveViewer';
import { MEDIA_TYPES, MediaType } from '../../constants/media';
import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';
import { USER_ROLES } from '../../constants/users';
import useFilterContext from '../useFilterContext';
import useUrlState from '../useUrlState';
import usePermissions from '../usePermissions';

import useActiveFilterTargets from './useActiveFilterTargets';
import { filterModesToId, idToFilterMode } from './helpers';

interface IBaseImage {
  node: {
    id: string;
    published?: boolean;
  };
}

export const FILTER_NAME = 'Published';

export const PUBLISHED_FILTER_MODES = {
  INACTIVE: 'All',
  PUBLISHED: 'Published',
  NOT_PUBLISHED: 'Not Published',
};

const FILTER_FUNCTIONS = {
  [PUBLISHED_FILTER_MODES.PUBLISHED]: (published: IBaseImage['node']['published']) => !!published,
  [PUBLISHED_FILTER_MODES.NOT_PUBLISHED]: (published: IBaseImage['node']['published']) =>
    !published,
};

const selectEdgeFilterData = (item: IBaseImage) => item.node.published;
export const supportedFilterTargets = [
  {
    type: MEDIA_TYPES[MediaType.HDPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
    selectFilterData: selectEdgeFilterData,
  },
];

const usePublishedFilter = (resetOnUnmount = false) => {
  const [publishedFilterMode, _setPublishedFilterMode] = useRecoilState(publishedFilterState);
  const resetPublishedFilterState = useResetRecoilState(publishedFilterState);

  const { addFilter, removeFilter, useFilters } = useFilterContext();
  const activeFilters = useFilters();

  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);
  const { setUrlState } = useUrlState();

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        resetPublishedFilterState();
      }
    };
  }, [resetOnUnmount, resetPublishedFilterState]);

  const _removeFilter = useCallback(() => {
    removeFilter(FILTER_NAME);
  }, [removeFilter]);

  const applyFilter = useCallback(
    (filterMode, loadedFromUrl = false) => {
      addFilter(
        {
          name: FILTER_NAME,
          label: FILTER_NAME,
          selected: filterMode,
          filterItem: FILTER_FUNCTIONS[filterMode],
          onRemove: () => {
            resetPublishedFilterState();
            setUrlState(FILTER_STATE_KEYS.PUBLISHED, null);
          },
          onDestroy: resetPublishedFilterState,
          targets: activeFilterTargets,
        },
        { loadedFromUrl },
      );

      setUrlState(FILTER_STATE_KEYS.PUBLISHED, filterModesToId(PUBLISHED_FILTER_MODES)[filterMode]);
    },
    [addFilter, resetPublishedFilterState, activeFilterTargets, setUrlState],
  );

  const applyFilterMode = useCallback(
    (filterMode, loadedFromUrl = false) => {
      if (filterMode === PUBLISHED_FILTER_MODES.INACTIVE) {
        _removeFilter();
        return;
      }

      applyFilter(filterMode, loadedFromUrl);
    },
    [_removeFilter, applyFilter],
  );

  useEffect(() => {
    // Published filter should be active but isn't, apply the filter mode
    if (
      publishedFilterMode !== PUBLISHED_FILTER_MODES.INACTIVE &&
      !activeFilters?.some((f: any) => f.name === FILTER_NAME)
    ) {
      applyFilterMode(publishedFilterMode);
    }
  }, [publishedFilterMode, activeFilters, applyFilterMode]);

  const setPublishedFilterMode = useCallback(
    (filterMode, loadedFromUrl = false) => {
      if (!filterMode || filterMode === publishedFilterMode) return;
      _setPublishedFilterMode(filterMode);
      applyFilterMode(filterMode, loadedFromUrl);
    },
    [_setPublishedFilterMode, applyFilterMode, publishedFilterMode],
  );

  const togglePublishedFilter = useCallback(() => {
    if (publishedFilterMode === PUBLISHED_FILTER_MODES.PUBLISHED) {
      _setPublishedFilterMode(PUBLISHED_FILTER_MODES.NOT_PUBLISHED);
      applyFilter(PUBLISHED_FILTER_MODES.NOT_PUBLISHED);
    } else if (publishedFilterMode === PUBLISHED_FILTER_MODES.NOT_PUBLISHED) {
      _removeFilter();
      _setPublishedFilterMode(PUBLISHED_FILTER_MODES.INACTIVE);
    } else {
      // filter mode is inactive, toggle on should switch to published
      _setPublishedFilterMode(PUBLISHED_FILTER_MODES.PUBLISHED);
      applyFilter(PUBLISHED_FILTER_MODES.PUBLISHED);
    }
  }, [_removeFilter, publishedFilterMode, _setPublishedFilterMode, applyFilter]);

  return {
    togglePublishedFilter,
    setPublishedFilterMode,
    publishedFilterMode,
  };
};

export const useRestorePublishedFilterFromUrl = () => {
  const { userHasOneOfRoles } = usePermissions();
  const canUsePublishedFilter = useMemo(
    () => userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]),
    [userHasOneOfRoles],
  );

  const { setPublishedFilterMode } = usePublishedFilter();
  const { getUrlState, setUrlState } = useUrlState();

  useEffect(() => {
    const urlFilterModeId = getUrlState(FILTER_STATE_KEYS.PUBLISHED);
    if (urlFilterModeId && canUsePublishedFilter) {
      const mode = idToFilterMode(filterModesToId(PUBLISHED_FILTER_MODES))[urlFilterModeId];
      if (mode) {
        setPublishedFilterMode(mode, true);
      } else {
        // urlFilterModeId invalid, clear the url state
        setUrlState(FILTER_STATE_KEYS.PUBLISHED, null);
      }
    }
  }, [canUsePublishedFilter, setPublishedFilterMode, getUrlState, setUrlState]);
};

export default usePublishedFilter;
