import React, { useCallback, useMemo, useState } from 'react';

import CreateUserDialog from '../CreateUserDialog';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import Action from '../../../components/NestedOrgViewHeader/Action';
import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';

function UserListHeader() {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { userHasPermission } = usePermissions();

  const canCreate = useMemo(
    () => userHasPermission('create', EntityType.User),
    [userHasPermission],
  );

  const toggleDialogState = useCallback(() => setCreateDialogOpen(prev => !prev), []);

  return (
    <NestedOrgViewHeader
      currentCrumb='Users'
      dialogs={
        <CreateUserDialog
          open={createDialogOpen}
          onOpen={toggleDialogState}
          onClose={toggleDialogState}
        />
      }
      actions={<Action canClick={canCreate} label='New User' onClick={toggleDialogState} />}
    />
  );
}

export default UserListHeader;
