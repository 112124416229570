import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Divider,
  InputAdornment,
  Grid,
  List,
  SvgIcon,
  TablePagination,
  TextField,
} from '@mui/material';

import { SearchIcon } from '../../../theme/icons';
import IdsTabs from '../../IdsTabs';
import IdsSelect from '../../ids-inputs/IdsSelect';

import '../../../theme/globalStyles.css';
import styles from './IdsSearchableList.module.css';

export const FILTER_MODES = {
  TAB: 'tab',
  SELECT: 'select',
};

function applyPagination(items, page, limit) {
  return items.slice(page * limit, page * limit + limit);
}

export const DEFAULT_PAGE_SIZE = 25;

function IdsSearchableList({
  items,
  renderItem,
  searchPlaceholder,
  searchItem,
  header,
  filters,
  filterMode,
  sortItems,
  actions,
  noItemsMessage,
  noItemsForFilterMessage,
  loading,
  error,
  ...rest
}) {
  const [, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState(filters.length && filters[0]);

  const handleFilterControlChange = useCallback(
    filterKey => {
      setFilter(filters.find(f => f.key === filterKey));
      setCurrentTab(filterKey);
    },
    [setFilter, filters, setCurrentTab],
  );

  const handleQueryChange = useCallback(
    event => {
      event.persist();
      setQuery(event.target.value);
    },
    [setQuery],
  );

  const handlePageChange = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage],
  );

  // Sort all items (sorting algorithm is lease likely to change)
  const sortedItems = useMemo(() => items.sort(sortItems), [items, sortItems]);

  // Apply filters to sorted items
  const filteredItems = useMemo(() => {
    const newFilteredItems = sortedItems.filter(
      item =>
        // Filter out items that fail the search or filter if defined
        !((query && !searchItem(item, query)) || (filter && !filter.filterItem(item))),
    );

    // Less filtered results, current page is invalid, reduce page to a valid page
    if (newFilteredItems.length < page * DEFAULT_PAGE_SIZE + 1) {
      const newPage = Math.floor(newFilteredItems.length / DEFAULT_PAGE_SIZE);
      setPage(newPage);
    }

    return newFilteredItems;
  }, [query, searchItem, filter, sortedItems, page, setPage]);

  const paginatedItems = useMemo(
    () => applyPagination(filteredItems, page, DEFAULT_PAGE_SIZE),
    [filteredItems, page],
  );

  const paginator = useMemo(
    () => (
      <Grid container flexDirection='row' justifyContent='flex-end'>
        {loading && (
          <Grid item xs='auto' className='centerChildren'>
            <CircularProgress size={25} />
          </Grid>
        )}
        <Grid item xs='auto'>
          <TablePagination
            component='div'
            count={filteredItems.length}
            onPageChange={handlePageChange}
            page={DEFAULT_PAGE_SIZE >= filteredItems.length ? 0 : page}
            rowsPerPage={DEFAULT_PAGE_SIZE}
            rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
          />
        </Grid>
      </Grid>
    ),
    [filteredItems, handlePageChange, page, loading],
  );

  return (
    <Card {...rest}>
      {header && (
        <>
          <Box p={2}>{header}</Box>
          <Divider />
        </>
      )}
      {filters.length > 0 && filterMode === FILTER_MODES.TAB && (
        <>
          <IdsTabs tabKey='filter' tabs={filters} onChange={handleFilterControlChange} />
          <Divider />
        </>
      )}
      <Grid container flexDirection='row' spacing={2} className={styles.listHeaderContainer}>
        <Grid item xs='auto'>
          <TextField
            className={styles.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon fontSize='small' color='action'>
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder={searchPlaceholder || 'Search'}
            value={query}
            variant='outlined'
          />
        </Grid>
        <Grid item xs container flexDirection='row' justifyContent='space-between'>
          <Grid item xs='auto'>
            {filters.length > 0 && filterMode === FILTER_MODES.SELECT && (
              <IdsSelect
                label='Filter'
                options={filters.map(f => ({
                  label: f.label,
                  value: f.key,
                }))}
                value={filter?.key}
                onChange={event => handleFilterControlChange(event.target.value)}
                size='medium'
                className={styles.selectFilter}
              />
            )}
          </Grid>
          {actions && <Grid item>{actions}</Grid>}
        </Grid>
      </Grid>
      <Divider />
      {paginator}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          {error || (!loading && (!paginatedItems || paginatedItems.length === 0)) ? (
            <Alert severity={error ? 'error' : items?.length ? 'warning' : 'info'}>
              {error || (items?.length ? noItemsForFilterMessage : noItemsMessage)}
            </Alert>
          ) : (
            <List className={styles.list}>{paginatedItems.map(item => renderItem(item))}</List>
          )}
        </Box>
      </PerfectScrollbar>
      {paginator}
    </Card>
  );
}

IdsSearchableList.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  // searchItem(item, query) => return true if match, false otherwise
  searchItem: PropTypes.func.isRequired,
  header: PropTypes.node,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      // filterItem(item) => return true if pass, false otherwise
      filterItem: PropTypes.func,
    }),
  ),
  filterMode: PropTypes.string,
  sortItems: PropTypes.func,
  actions: PropTypes.node,
  noItemsMessage: PropTypes.string,
  noItemsForFilterMessage: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

IdsSearchableList.defaultProps = {
  items: [],
  filters: [],
  filterMode: FILTER_MODES.TAB,
  sortItems: (a, b) => 0,
  noItemsMessage: 'No items',
  noItemsForFilterMessage: 'No items match your search',
  loading: false,
};

export default IdsSearchableList;
