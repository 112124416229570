import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import RuntimeConfig from '../../RuntimeConfig';
import { activeTenantState } from '../../atoms/tenants';

const useNavigateToExplorer = () => {
  const activeTenant = useRecoilValue(activeTenantState);

  return useCallback(() => {
    let navigateTo = window.location.href.replace(
      window.location.hostname,
      RuntimeConfig.explorerHostname,
    );

    const subdomainPrefix = `/app/${activeTenant!.subdomain}/`;
    if (!window.location.pathname.startsWith(subdomainPrefix)) {
      // Add tenant subdomain only if it is not added
      navigateTo = navigateTo.replace('/app/', subdomainPrefix);
    }

    window.location.assign(navigateTo);
  }, [activeTenant]);
};

export default useNavigateToExplorer;
