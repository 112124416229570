export enum FieldType {
  Text = 'text',
  Number = 'number',
  Barcode = 'barcode',
  Radio = 'radio',
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice',
  Date = 'date',
  Datetime = 'datetime',

  /**
   * Artificial types to simplify rendering of some data.
   */
  Color = 'color',
  Checkbox = 'checkbox',
}

export type Field = {
  label: string;
  name: string;
  type: FieldType;
  data: any;
  isEditable?: boolean;
  props?: any;
};

export interface IFieldComponentProps {
  field: Field;
  isEditMode?: boolean;
}
