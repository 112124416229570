import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useAssignmentKeys } from '../../services/AssignmentsService';
import { UseGraphQueryOptions } from '../../hooks/useGraphQuery';
import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { activeOrganizationState } from '../../atoms/organizations';

import {
  activeLocationAssignmentResponseState,
  activeLocationAssignmentState,
  activeLocationState,
} from '../../atoms/locations';

import { getOrgLocationAssignmentRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

const AssignmentResponseRouteQuery = gql`
  query AssignmentResponseRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
    }
  }
`;

export const LocationAssignmentResponseRoute: React.FC = () => {
  const assignmentKeys = useAssignmentKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrganization = useRecoilValue(activeOrganizationState);
  const activeLocation = useRecoilValue(activeLocationState);
  const activeLocationAssignment = useRecoilValue(activeLocationAssignmentState);
  const [activeLocationAssignmentResponse, setActiveLocationAssignmentResponse] = useRecoilState(
    activeLocationAssignmentResponseState,
  );

  // Query is used to hook into ACL
  // can user access this data? Routes away if they can't
  const useLocationAssignmentResponse = (id: string | undefined, options: UseGraphQueryOptions) =>
    useOrgGraphQuery(assignmentKeys.route(id!), AssignmentResponseRouteQuery, { id }, options);

  if (!activeLocation || !activeLocationAssignment) {
    return null;
  }

  return (
    <EntityRoute
      idParam={'assignmentResponseId'}
      currentEntity={activeLocationAssignmentResponse}
      setEntity={setActiveLocationAssignmentResponse}
      useEntity={useLocationAssignmentResponse}
      entitySelector={data => data?.assignmentResponse}
      errorRedirectRoute={getOrgLocationAssignmentRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrganization.id,
        locationId: activeLocation.id,
        assignmentId: activeLocationAssignment.id,
      })}
    />
  );
};

export default LocationAssignmentResponseRoute;
