import React, { useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { DeleteOutlineIcon, EditOutlineIcon } from '../../../../../../../../theme/icons';
import { IMediaMetadata } from '../../../../../../../../constants/media';
import { USER_ROLES } from '../../../../../../../../constants/users';
import usePermissions from '../../../../../../../../hooks/usePermissions';
import { sessionState } from '../../../../../../../../atoms/session';

import EditTaskResponseDialog from './EditTaskResponseDialog';
import useDeleteTaskResponseAction from './useDeleteTaskResponseAction';
import {
  IAssignmentAnswer,
  IAssignmentQuestion,
} from './EditTaskResponseDialog/EditTaskResponseForm/types';

// @TODO: implement interface in EditResponseForm and extend it here.
export interface IActionsProps {
  taskResponse: {
    id: string;
    guid: string;
    taskId: string;
    assignmentResponseId: string;
    completed: boolean;
    creator: {
      id: string;
      firstName: string;
      lastName: string;
    };
    task: {
      repeatableLabel: string;
      defaultMetadata: IMediaMetadata[];
    };
  };
  groupedAnswers:
    | Record<
        number,
        {
          answers: IAssignmentAnswer[];
          question: IAssignmentQuestion;
        }
      >
    | Record<number, never>;
}

const Actions: React.FC<IActionsProps> = ({ taskResponse, groupedAnswers }) => {
  const { userHasOneOfRoles } = usePermissions();
  const userSession = useRecoilValue(sessionState);

  const [isEditResponseDialogOpen, setIsEditResponseDialogOpen] = useState(false);
  const { deleted, deleting, deleteResponse } = useDeleteTaskResponseAction(
    taskResponse.assignmentResponseId,
    taskResponse.id,
    taskResponse.task.repeatableLabel,
  );

  const canDelete = useMemo(() => {
    if (userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.TENANT_ADMIN, USER_ROLES.ORG_ADMIN])) {
      return true;
    }

    return userSession!.id === taskResponse.creator.id;
  }, [userHasOneOfRoles, userSession, taskResponse.creator.id]);

  return (
    <>
      <Tooltip title='Edit response'>
        <IconButton size='small' onClick={() => setIsEditResponseDialogOpen(true)}>
          <EditOutlineIcon />
        </IconButton>
      </Tooltip>
      {canDelete && (
        <Tooltip title='Delete response'>
          <IconButton disabled={deleting || deleted} size='small' onClick={() => deleteResponse()}>
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}

      <EditTaskResponseDialog
        open={isEditResponseDialogOpen}
        onClose={() => setIsEditResponseDialogOpen(false)}
        title={taskResponse.task.repeatableLabel}
        groupedAnswers={groupedAnswers}
        taskResponseId={taskResponse.id}
        assignmentResponseId={taskResponse.assignmentResponseId}
        guid={taskResponse.guid}
        taskId={taskResponse.taskId}
        defaultTaskMetadata={taskResponse.task.defaultMetadata}
      />
    </>
  );
};

export default Actions;
