import React from 'react';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import { IImageDataFormProps } from '../../../../../../hooks/uploaders/useBatchLocationImageUploader';

import IdsFormAutocompleteField from '../../../../../../components/ids-forms/IdsFormAutocompleteField';
import IdsFormLocationAutocompleteField from '../../../../../../components/ids-forms/common/IdsFormLocationAutocompleteField';
import IdsFormLocationProjectAutocompleteField from '../../../../../../components/ids-forms/common/IdsFormLocationProjectAutocompleteField';
import SingleValueAutocompleteOption from '../../../../../../components/ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../../constants/media';
import useMetadataItems from '../../../../../../hooks/useMetadataItems';
import useIdsUploaderContext from '../../../../../../hooks/useIdsUploaderContext';

const PanoramicPhotoForm: React.FC<IImageDataFormProps> = ({
  metadataTypes,
  hideLocation,
  hideProject,
}) => {
  const { uploading } = useIdsUploaderContext();
  const { allLevels, allAreas } = useMetadataItems(metadataTypes);

  const { values } = useFormikContext<any>();
  const { location_id } = values;

  return (
    <Stack direction='column' spacing={2}>
      {!hideLocation && (
        <IdsFormLocationAutocompleteField
          name='location_id'
          disableWhileLoading={true}
          disabled={uploading}
          required
        />
      )}
      {!hideProject && (
        <IdsFormLocationProjectAutocompleteField
          name='project_id'
          required
          locationId={location_id}
          disabled={!location_id || uploading}
        />
      )}
      <IdsFormAutocompleteField
        name='photo_level_id'
        label={MEDIA_METADATA_TYPES[MediaMetadataType.Level].label}
        required
        options={allLevels}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No levels found'
        renderOption={(props, o) => (
          <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
        )}
        disabled={uploading}
      />
      <IdsFormAutocompleteField
        name='photo_area_id'
        label={MEDIA_METADATA_TYPES[MediaMetadataType.Area].label}
        options={allAreas}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No areas found'
        renderOption={(props, o) => (
          <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
        )}
        disabled={uploading}
      />
      {/* <IdsFormAutocompleteField
        name='photo_tags'
        label={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].label}
        options={allTags}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        noOptionsText='No tags found'
        renderOption={(props, o, { selected }) => (
          <MultiValueAutocompleteOption
            label={o.name}
            selected={selected}
            {...props}
            key={o.id}
          />
        )}
        disableCloseOnSelect
        multiple
      /> */}
    </Stack>
  );
};

export default PanoramicPhotoForm;
