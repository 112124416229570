import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useRecoilState } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import IdsCreateDialogForm from '../../../../components/ids-forms/IdsCreateDialogForm';
import { createProjectValidationSchema } from '../../projectValidation';
import { useCreateProject } from '../../../../services/ProjectService';
import { PROJECT_STATUSES } from '../../../../constants/projects';
import ProjectForm from '../../ProjectForm';

const initialValues = {
  name: '',
  location: null,
  description: '',
  startDate: null,
  completionDate: null,
  status: PROJECT_STATUSES.NEW,
};

const CreateProjectForm = ({ onSubmit, onSubmitSuccess, onCancel, values }) => {
  const [organization] = useRecoilState(activeOrganizationState);

  const createProjectMutation = useCreateProject();

  const _onSubmit = useCallback(
    async values => {
      await createProjectMutation.mutateAsync({
        organizationId: organization.id,
        locationId: values.location,
        completionDate: values.endDate,
        statusId: values.statusId,
        ...values,
      });

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    [createProjectMutation, organization, onSubmitSuccess],
  );

  return (
    <IdsCreateDialogForm
      initialValues={initialValues}
      validationSchema={createProjectValidationSchema}
      successMessage='Project created'
      errorHandler={() => 'Project could not be created'}
      onSubmit={_onSubmit}
      onCancel={onCancel}
    >
      <ProjectForm />
    </IdsCreateDialogForm>
  );
};

CreateProjectForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreateProjectForm;
