import React from 'react';

import TenantPage from '../TenantPage';

import TenantProjectsList from './TenantProjectsList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantProjectsProps {}

const TenantProjects: React.FC<ITenantProjectsProps> = () => {
  return (
    <TenantPage title='Projects'>
      <TenantProjectsList />
    </TenantPage>
  );
};

export default TenantProjects;
