import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Menu, MenuItem, Theme, Typography } from '@mui/material';

import { useRecoilValue } from 'recoil';

import { sessionState } from '../../atoms/session';
import useKeycloak from '../../hooks/useKeycloak';
import usePrevious from '../../hooks/usePrevious';
import { getRedirectAfterLogoutUrl } from '../../keycloak';

const styles = {
  avatar: (theme: Theme) => ({
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  }),
  popover: {
    width: 200,
  },
};

const Account: React.FC = () => {
  const session = useRecoilValue(sessionState);
  const { keycloak, initialized, authenticated } = useKeycloak();
  const wasAuthenticated = usePrevious(authenticated);
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();

      let logoutRedirectUrl;

      const redirectAfterLogoutUrl = getRedirectAfterLogoutUrl();
      if (redirectAfterLogoutUrl) {
        // Custom logout redirect is defined
        logoutRedirectUrl = redirectAfterLogoutUrl;
      } else {
        // Default to keycloak login screen
        logoutRedirectUrl = keycloak.createLoginUrl({
          // By default, login will redirect to current location. This is desired unless the user just logged out.
          // If the user was authenticated, the user just logged out, the login should redirect to the base app route.
          ...(wasAuthenticated && { redirectUri: window.location.origin }),
        });
      }

      await keycloak.logout({ redirectUri: logoutRedirectUrl });
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  const handleProfile = () => {
    handleClose();
    navigate('/profile');
  };

  if (!keycloak?.authenticated || !initialized) return null;

  return (
    <>
      <Box display='flex' alignItems='center' component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar alt='User' sx={styles.avatar} />
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <Typography variant='h6' color='inherit'>
            {session?.firstName && session?.lastName
              ? `${session.firstName} ${session.lastName}`
              : 'Account'}
          </Typography>
        </Box>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ sx: styles.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem data-cy='profile-menu' onClick={handleProfile}>
          Profile
        </MenuItem>
        <MenuItem data-cy='logout-menu' onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
