import React from 'react';
import { FormikContextType, useField } from 'formik';

import IdsSwitch from '../../ids-inputs/IdsSwitch';
import IdsFormElement from '../IdsFormElement';
import { IIdsSwitchProps } from '../../ids-inputs/IdsSwitch';

// No need in onChange, onBlur, onFocus and value props
// Because this component will use formik context
export interface IIdsFormSwitchFieldProps
  extends Omit<IIdsSwitchProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'> {
  name: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    formikContext: FormikContextType<any>,
  ) => any;
}

const IdsFormSwitchField: React.FC<IIdsFormSwitchFieldProps> = ({ name, onChange, ...rest }) => {
  const [{ onChange: formikOnChange, ...restField }] = useField({
    name,
    type: 'checkbox',
  });

  return (
    <IdsFormElement
      render={formikProps => {
        return (
          <IdsSwitch
            onChange={event => {
              formikOnChange(event);
              if (onChange) {
                onChange(event, formikProps);
              }
            }}
            {...restField}
            {...rest}
          />
        );
      }}
    />
  );
};

export default IdsFormSwitchField;
