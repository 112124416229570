import React, { useMemo, useState } from 'react';
import { Button, Grid, SvgIcon } from '@mui/material';

import usePermissions from '../../../../hooks/usePermissions';
import { ImportIcon } from '../../../../theme/icons';
import { USER_ROLES } from '../../../../constants/users';

import CsvUploadForm from './CsvUploadForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IActionsProps {}

const Actions: React.FC<IActionsProps> = () => {
  const { userHasOneOfRoles } = usePermissions();
  const canCreate = useMemo(
    () => userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]),
    [userHasOneOfRoles],
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialogState = () => setIsDialogOpen(prev => !prev);

  return (
    canCreate && (
      <>
        <Grid item>
          <Button variant='contained' color='secondary' onClick={toggleDialogState}>
            <SvgIcon fontSize='small' sx={{ mr: 1 }}>
              <ImportIcon />
            </SvgIcon>
            Upload CSV
          </Button>
        </Grid>

        <CsvUploadForm open={isDialogOpen} onClose={toggleDialogState} />
      </>
    )
  );
};

export default Actions;
