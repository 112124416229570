import { Button, Grid } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { activeTenantState } from '../../../../atoms/tenants';
import { getTenantSettingsRoute } from '../../../../utils/routes';

const TenantDetailActions = () => {
  const activeTenant = useRecoilValue(activeTenantState);

  const navigate = useNavigate();

  const handleTenantSettings = useCallback(() => {
    navigate(
      getTenantSettingsRoute({
        subdomain: activeTenant?.subdomain,
      }),
    );
  }, [navigate, activeTenant]);

  return (
    <Grid item>
      <Button color='secondary' variant='contained' onClick={handleTenantSettings}>
        Tenant Settings
      </Button>
    </Grid>
  );
};

export default TenantDetailActions;
