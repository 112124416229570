import { ListItemText } from '@mui/material';

import { gql } from 'graphql-request';

import IdsListItem from '../../../components/ids-lists/IdsListItem';
import { getTenantOrgsRoute } from '../../../utils/routes';
import { ITenant } from '../../../services/TenantService';

export const TenantListItemFrag = gql`
  fragment TenantListItemFrag on Tenant {
    id
    myPermissions
    myUserRole
    name
    subdomain
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantListItemFragType extends ITenant {}

export interface ITenantListItemProps {
  tenantItem: ITenantListItemFragType;
}

const TenantListItem: React.FC<ITenantListItemProps> = ({ tenantItem }) => {
  const { name, subdomain, myUserRole } = tenantItem;

  // TODO: #TENANT, Right now navigates using ID instead of subdomain,
  // later need to change to subdomains
  return (
    <IdsListItem
      to={getTenantOrgsRoute({
        subdomain,
      })}
    >
      <ListItemText
        primary={name}
        primaryTypographyProps={{ variant: 'h6', noWrap: true }}
        secondary={subdomain}
        secondaryTypographyProps={{ noWrap: true }}
      />

      <ListItemText secondary={myUserRole} secondaryTypographyProps={{ noWrap: true }} />
    </IdsListItem>
  );
};

export default TenantListItem;
