import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import { activeOrganizationState } from '../../../../atoms/organizations';
import IdsFormAutocompleteField, {
  IIdsFormAutocompleteFieldProps,
} from '../../IdsFormAutocompleteField';
import LocationOptionListItem from '../../../ids-list-items/LocationOptionListItem';
import {
  useGetAllLocationOptions,
  ILocationListItemEdge,
} from '../../../../services/LocationService';

export interface IIdsFormLocationAutocompleteFieldProps
  extends Omit<IIdsFormAutocompleteFieldProps, 'options'> {
  disableWhileLoading?: boolean;
}

const IdsFormLocationAutocompleteField: React.FC<IIdsFormLocationAutocompleteFieldProps> = ({
  name,
  disabled,
  disableWhileLoading,
  ...rest
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { setFieldValue } = useFormikContext();
  const [locationOptions, setLocationOptions] = useState<ILocationListItemEdge['node'][]>([]);

  const handleLocationsPage = useCallback(
    (edges: ILocationListItemEdge[]) => {
      setLocationOptions((prev: ILocationListItemEdge['node'][]) => {
        const newOptions: ILocationListItemEdge['node'][] = [];
        if (prev) {
          newOptions.push(...prev);
        }
        newOptions.push(...edges.map((e: ILocationListItemEdge) => e.node));
        return newOptions;
      });
    },
    [setLocationOptions],
  );

  const { isLoading, error } = useGetAllLocationOptions(activeOrg.id, handleLocationsPage);

  useEffect(() => {
    if (locationOptions.length === 1) {
      // If only 1 option, auto select
      setFieldValue(name, locationOptions[0].id);
    }
  }, [name, locationOptions, setFieldValue]);

  return (
    <IdsFormAutocompleteField
      name={name}
      label='Location'
      loading={isLoading}
      disabled={disabled || (disableWhileLoading && isLoading)}
      options={locationOptions}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      noOptionsText={error ? 'Locations failed to load' : 'No locations found'}
      renderOption={(props, locOption) => (
        <LocationOptionListItem locationOption={locOption} {...props} key={locOption.id} />
      )}
      {...rest}
    />
  );
};

export default IdsFormLocationAutocompleteField;
