import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import { IFieldComponentProps } from '../../types';
import LabelCell from '../../LabelCell';
import IdsFormAutocompleteField from '../../../../../components/ids-forms/IdsFormAutocompleteField';
import SingleValueAutocompleteOption from '../../../../../components/ids-forms/IdsFormAutocompleteField/SingleValueAutocompleteOption';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISingleChoiceProps extends IFieldComponentProps {}

const SingleChoice: React.FC<ISingleChoiceProps> = ({ field, isEditMode }) => {
  return (
    <TableRow>
      <LabelCell label={field.label} />
      <TableCell>
        {isEditMode && field.isEditable ? (
          <IdsFormAutocompleteField
            name={field.name}
            label=''
            required
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsText='No options found'
            options={field.props.options}
            renderOption={(props, o) => (
              <SingleValueAutocompleteOption label={o.name} {...props} key={o.id} />
            )}
          />
        ) : (
          field.data
        )}
      </TableCell>
    </TableRow>
  );
};

export default SingleChoice;
