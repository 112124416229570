import { useMemo } from 'react';

import useObservableStates, {
  useObservableItemValue,
  IObservableItemStateProps,
} from './useObservableStates';

export type TypeItems<TItem = any> = TItem[];
export type TypeDataListener<TItem = any> = (data: TItem[]) => void;
export type ItemIdSelector<TItem = any> = (item: TItem) => string;

export interface ITypedDataManager<TItem = any> {
  /** Use base type data. This updates automatically when the data changes. */
  useData: () => TypeItems<TItem>;
  /** Get base type data. */
  getData: () => TypeItems<TItem>;
  /** Add listener for base type data changes. */
  addDataListener: (
    listener: TypeDataListener<TItem>,
  ) => ReturnType<IObservableItemStateProps['addItemStateListener']>;
  /** Remove listener for base type data changes. */
  removeDataListener: (
    listenerId: number,
  ) => ReturnType<IObservableItemStateProps['removeItemStateListener']>;
  /** Set base type data. This replaces all existing base type data. */
  setData: (data: TypeItems, selectId: ItemIdSelector<TItem>) => void;
  /** Set base type data item. Creates a new item or replaces an existing one. */
  setItem: (id: string, item: TItem) => TItem[];
  /** Set base type data items. Creates new items or replaces existing ones. Does not affect other base type data items.  */
  setItems: (items: TypeItems, selectId: ItemIdSelector<TItem>) => TItem[];
  /** Get base type data item. */
  getItem: (id: string) => TItem | undefined;
  /** Use filtered type data. This updates automatically when the filtered data changes. */
  useFilteredData: () => TypeItems<TItem>;
  /** Get filtered type data. */
  getFilteredData: () => TypeItems<TItem>;
  /** Set filtered type data. This replaces all existing filtered type data. */
  setFilteredData: (data: TypeItems, selectId: ItemIdSelector<TItem>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypedDataManagers extends Record<string, ITypedDataManager> {}

interface IDataState<TItem = any> {
  data: TypeItems<TItem>;
  dataLookup: Record<string, number>;
}

interface IFilteredDataState<TItem = any> {
  filteredData: TypeItems<TItem>;
}

const getTypeDataId = (type: string) => `${type}-data`;
const getFiltererdTypeDataId = (type: string) => `${type}-filtered-data`;

const useTypedDataManagers = (types: string[]) => {
  const { getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useObservableStates();

  const typedDataManagers = useMemo<ITypedDataManagers>(
    () =>
      types.reduce<ITypedDataManagers>((dataMgrs, type) => {
        const dataId = getTypeDataId(type);
        setItemState(dataId, { data: [], dataLookup: {} }); // Set default

        const filteredDataId = getFiltererdTypeDataId(type);
        setItemState(filteredDataId, { filteredData: [] }); // Set default

        dataMgrs[type] = {
          useData: () => {
            const data = useObservableItemValue<IDataState>({
              id: dataId,
              getItemState,
              setItemState,
              addItemStateListener,
              removeItemStateListener,
            })?.data;
            // Important to clone array, otherwise consumers
            // can use the reference to modify this array,
            // potentially breaking the dataLookup object
            return useMemo(() => (data ? [...data] : []), [data]);
          },
          getData: () => getItemState<IDataState>(dataId)!.data,
          /** Adds a listener to the type data. Returns listener id */
          addDataListener: listener =>
            addItemStateListener<IDataState>(dataId, null, state => listener(state.data)),
          removeDataListener: listenerId => removeItemStateListener(dataId, listenerId),
          setData: (data, selectId) => {
            // Reduce data items to an id indexed object
            // for easy lookups and updates
            const dataLookup = data.reduce((lookup, item, i) => {
              const id = selectId(item);
              // Store the index of the item for quick access in the array
              lookup[id] = i;
              return lookup;
            }, {});
            setItemState(dataId, { data, dataLookup });
          },
          setItem: (id, item) => {
            const { data, dataLookup } = getItemState<IDataState>(dataId)!;
            const newData = [...data];
            const index = dataLookup[id];
            if (index !== undefined) {
              // Item exists
              newData[index] = item;
            } else {
              // Item doesn't exist, add it
              const length = newData.push(item);
              dataLookup[id] = length - 1;
            }
            setItemState(dataId, { data: newData, dataLookup });
            return newData;
          },
          setItems: (items, selectId) => {
            // Creates or updates data items for the specified
            // type without replacing all data
            const { data, dataLookup } = getItemState<IDataState>(dataId)!;
            const newData = [...data];
            items.forEach(item => {
              const id = selectId(item);
              const index = dataLookup[id];
              if (index !== undefined) {
                // Item exists
                newData[index] = item;
              } else {
                // Item doesn't exist, add it
                const length = newData.push(item);
                dataLookup[id] = length - 1;
              }
            });
            setItemState(dataId, { data: newData, dataLookup });
            return newData;
          },
          getItem: id => {
            const { data, dataLookup } = getItemState<IDataState>(dataId)!;
            const index = dataLookup[id];
            return index !== undefined ? data[index] : undefined;
          },
          useFilteredData: () => {
            const filteredData = useObservableItemValue<IFilteredDataState>({
              id: filteredDataId,
              getItemState,
              setItemState,
              addItemStateListener,
              removeItemStateListener,
            })?.filteredData;
            // Important to clone array, otherwise consumers
            // can use the reference to modify this array
            return useMemo(() => (filteredData ? [...filteredData] : []), [filteredData]);
          },
          getFilteredData: () => getItemState<IFilteredDataState>(filteredDataId)!.filteredData,
          setFilteredData: filteredData =>
            setItemState<IFilteredDataState>(filteredDataId, { filteredData }),
        };

        return dataMgrs;
      }, {}),
    [types, getItemState, setItemState, addItemStateListener, removeItemStateListener],
  );

  return typedDataManagers;
};

export default useTypedDataManagers;
