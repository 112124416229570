import { useCallback, useMemo } from 'react';

import useImmersiveViewer from './useImmersiveViewer';

const defaultDisabledEvents = [
  'wheel',
  'panstart',
  'tripanstart',
  'dblclick',
  'click',
  'anyclick',
  'mousedown',
  'mouseover',
];

const useDeckEventManager = (disabledEvents = defaultDisabledEvents) => {
  const { deckRef } = useImmersiveViewer();
  const eventManager = deckRef?.deck.eventManager;

  const disabledEventMap = useMemo(() => {
    if (!disabledEvents?.length) return null;

    const disableEventHandler = e => e.stopPropagation();

    const eventMap = {};
    disabledEvents.forEach(e => (eventMap[e] = disableEventHandler));
    return eventMap;
  }, [disabledEvents]);

  const disableDeckEvents = useCallback(
    srcElement => {
      if (!disabledEventMap || !eventManager) return false;

      // Disable all specified deck events from propagating while mouse is hovering
      eventManager.watch(disabledEventMap, {
        srcElement,
      });
      return true;
    },
    [eventManager, disabledEventMap],
  );

  const enableDeckEvents = useCallback(() => {
    if (!disabledEventMap || !eventManager) return false;

    // Remove event blockers
    eventManager.off(disabledEventMap);
    return true;
  }, [eventManager, disabledEventMap]);

  return {
    disableDeckEvents,
    enableDeckEvents,
  };
};

export default useDeckEventManager;
