import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { MapViewState } from 'deck.gl/typed';

import { ImmersiveViewerProvider } from '../../../../../../context/ImmersiveViewerContext';
import { ImmersiveViewerInContext } from '../../../../../../components/mapping/ImmersiveViewer';
import useDeckEventManager from '../../../../../../hooks/useDeckEventManager';
import {
  getRasterOverlayLayerId,
  buildRasterOverlayLayer,
} from '../../../../../../components/mapping/layers/overlays';
import { buildLocationMarkerLayer } from '../../../../../../components/mapping/layers/locations';
import useMapFitData from '../../../../../../hooks/useMapFitData';
import useRasterOverlayFilterInitializer from '../../../../../../components/mapping/filter-initializers/useRasterOverlayFilterInitializer';
import { FilterProvider } from '../../../../../../context/FilterContext';
import IdsFormTextField from '../../../../../../components/ids-forms/IdsFormTextField';
import useImmersiveViewer from '../../../../../../hooks/useImmersiveViewer';
import { ImageType, MediaType, MEDIA_TYPES } from '../../../../../../constants/media';
import {
  IRasterOverlay,
  useGetLocationImageMarkerForm,
} from '../../../../../../services/LocationService';
import ImageMarkerCreationTool, {
  useImageMarkerCreationTool,
} from '../../../../../../components/mapping/tools/ImageMarkerCreationTool';
import { IMarkerPosition } from '../../../../../../hooks/useMapMarkerPlacementTool';
import usePrevious from '../../../../../../hooks/usePrevious';
import { isLatitudeValid, isLongitudeValid } from '../../../../../../utils/geospatial';
import useKeycloak from '../../../../../../hooks/useKeycloak';
import useFilterContext from '../../../../../../hooks/useFilterContext';
import MapControls from '../../../../../../components/mapping/MapControls';
import { IImageMarkerFormProps as IImageMarkerFormGeneralProps } from '../../../../../../hooks/uploaders/useSingleLocationImageUploader';
import useIdsUploaderContext from '../../../../../../hooks/useIdsUploaderContext';

import ImageMarkerFormMapMenu from './ImageMarkerFormMapMenu';
import styles from './ImageMarkerForm.module.css';

export const MARKER_POSITION_STATE_ID = 'marker-position-state';

const tryGetFloatFieldVal = (value: any) => {
  if (!value || typeof value === 'number') {
    return value;
  }

  const numVal = parseFloat(value);
  return isNaN(numVal) ? '' : numVal;
};

const tryGetIntFieldVal = (value: any) => {
  if (!value || typeof value === 'number') {
    return value;
  }

  const numVal = parseInt(value);
  return isNaN(numVal) ? '' : numVal;
};

const EMPTY_DATA = [{}];

export interface IImageMarkerFormProps extends IImageMarkerFormGeneralProps {
  imageType: ImageType;
  showAltitude?: boolean;
  altitudeRequired?: boolean;
  renderCustomFormFields?: (uploading: boolean) => React.ReactNode;
}

const ImageMarkerForm: React.FC<IImageMarkerFormProps> = ({
  imageType,
  metadataTypes,
  renderThumbnail,
  showAltitude = false,
  altitudeRequired = false,
  renderCustomFormFields,
}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { location_id, latitude, longitude, level_id } = values;
  const prevLatitude = usePrevious<number>(latitude);
  const prevLongitude = usePrevious<number>(longitude);
  const { setViewState, useViewState, setLayer, setLayers, setItemState } = useImmersiveViewer();
  const viewState = useViewState();
  const { fitMapToData } = useMapFitData();
  const [toolInitialized, setToolInitialized] = useState(false);
  const { useFilters, setTypeData, useTypeFilteredData } = useFilterContext();
  const { startImageMarkerCreation, updateToolState } = useImageMarkerCreationTool({
    metadataTypes,
  });
  const { data } = useGetLocationImageMarkerForm(location_id);
  const prevData = usePrevious(data);
  const { token: keycloakToken } = useKeycloak();
  const { uploading } = useIdsUploaderContext();
  const prevUploading = usePrevious(uploading);
  const { enableDeckEvents, disableDeckEvents } = useDeckEventManager();

  const activeFilters = useFilters();

  useRasterOverlayFilterInitializer();
  const rasterOverlays = useMemo(() => data?.location.rasterOverlays, [data]);
  const prevRasterOverlays = usePrevious(rasterOverlays);
  const filteredRasterOverlays = useTypeFilteredData(MediaType.RasterOverlay);

  useEffect(() => {
    // Only update when data changes, not when setTypeData changes
    if (rasterOverlays && rasterOverlays !== prevRasterOverlays) {
      setTypeData(rasterOverlays, MediaType.RasterOverlay);
    }
  }, [rasterOverlays, prevRasterOverlays, setTypeData]);

  useEffect(() => {
    // Something in the effect deps causes this to run again
    // after the layers have been destroyed when closing the location,
    // need to check if the location is closing to prevent the layers
    // from being set after the location is closed
    if (!rasterOverlays?.length) {
      return;
    }

    setLayers(
      rasterOverlays.map(o => {
        const filterPosition = activeFilters?.findIndex((f: any) => f.name === o.id);

        return {
          id: getRasterOverlayLayerId(o.id),
          layer: buildRasterOverlayLayer(
            o,
            keycloakToken,
            {
              // Only show the layer if toggled on
              visible: filteredRasterOverlays.some((fo: IRasterOverlay) => fo.id === o.id),
            },
            null,
            {
              // Show most recently turned on overlays above other overlays
              ...(filterPosition >= 0 && { position: filterPosition }),
            },
          ),
        };
      }),
    );
  }, [rasterOverlays, filteredRasterOverlays, setLayers, activeFilters, keycloakToken]);

  useEffect(() => {
    if (uploading === prevUploading) return;

    if (uploading) {
      disableDeckEvents(undefined);
    } else {
      enableDeckEvents();
    }
  }, [uploading, prevUploading, enableDeckEvents, disableDeckEvents]);

  // Handle continuous marker movement and track on observable state to
  // avoid rerendering constantly while moving. Provides access to the rendered
  // position of the marker for any additional layers that should be rendered
  // at the same position.
  const handleMarkerRender = useCallback(
    (position: IMarkerPosition) => {
      setItemState(MARKER_POSITION_STATE_ID, position);
    },
    [setItemState],
  );

  const handleMarkerMoved = useCallback(
    async ({ latitude, longitude }: IMarkerPosition) => {
      if (latitude) {
        // NOTE: even though the type shows this returns void,
        // it actually returns a promise. Need to wait for promise
        // to resolve to avoid race condition between lat and long field
        // validations.
        await setFieldValue('latitude', latitude, true);
      }

      if (longitude) {
        setFieldValue('longitude', longitude, true);
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    // Only initialize if data just finished loading
    if (!data || toolInitialized) return;

    const { position } = data.location;

    const markerPlaced = isLongitudeValid(longitude) && isLatitudeValid(latitude);

    // Center on locaiton if marker is not placed
    const markersToFit = [position];

    if (markerPlaced) {
      // Marker is placed, fit map to marker and location
      markersToFit.push({ latitude, longitude });
    }

    // Center map on location or fit to location and marker (if marker is set)
    const fittedViewState: MapViewState = fitMapToData(
      markersToFit,
      (d: { longitude: number; latitude: number }) => d.latitude,
      (d: { longitude: number; latitude: number }) => d.longitude,
      17,
      {
        ...viewState,
        maxPitch: 0,
      },
    );

    // If map dimensions have not yet been loaded in fitMapToData,
    // this will return null
    setToolInitialized(!!fittedViewState);

    // Don't initialize location marker layer or marker creation tool
    // until view state is initialized
    if (!fittedViewState) return;

    setViewState(fittedViewState);

    // Build location marker for visual reference
    setLayer(
      'upload-location-marker',
      buildLocationMarkerLayer(EMPTY_DATA, {
        getPosition: [position.longitude, position.latitude],
        cluster: false,
      }),
    );

    startImageMarkerCreation(
      imageType,
      handleMarkerMoved,
      handleMarkerRender,
      level_id,
      latitude,
      longitude,
    );
  }, [
    setViewState,
    viewState,
    toolInitialized,
    data,
    prevData,
    startImageMarkerCreation,
    latitude,
    longitude,
    imageType,
    level_id,
    handleMarkerMoved,
    handleMarkerRender,
    fitMapToData,
    setLayer,
    setToolInitialized,
  ]);

  useEffect(() => {
    if (!isLatitudeValid(latitude) || !isLongitudeValid(longitude)) {
      // Lat long not valid
      if (isLatitudeValid(prevLatitude) && isLongitudeValid(prevLongitude)) {
        // Lat long was valid
        updateToolState(null, null); // clear marker placement
      }
      return;
    }

    // Lat/long is valid, update marker
    updateToolState(latitude, longitude);
  }, [updateToolState, latitude, prevLatitude, longitude, prevLongitude]);

  useEffect(() => {
    updateToolState(undefined, undefined, uploading, handleMarkerMoved, handleMarkerRender);
  }, [updateToolState, handleMarkerMoved, handleMarkerRender, uploading]);

  return (
    <Grid container direction='row' spacing={2} className={styles.container}>
      <Grid item xs='auto' container direction='column' spacing={2}>
        <Grid item xs={1} className={styles.thumbnailContainer}>
          {renderThumbnail({ width: 200, height: 200 })}
        </Grid>
        <Grid item xs='auto'>
          <IdsFormTextField
            name='latitude'
            label='Latitude'
            required
            transformBlurValue={tryGetFloatFieldVal}
            disabled={uploading}
          />
        </Grid>
        <Grid item xs='auto'>
          <IdsFormTextField
            name='longitude'
            label='Longitude'
            required
            transformBlurValue={tryGetFloatFieldVal}
            disabled={uploading}
          />
        </Grid>
        {showAltitude && (
          <Grid item xs='auto'>
            <IdsFormTextField
              name='altitude'
              label='Altitude'
              required={altitudeRequired}
              transformBlurValue={tryGetIntFieldVal}
              disabled={uploading}
            />
          </Grid>
        )}
        {renderCustomFormFields && (
          <Grid item xs='auto'>
            {renderCustomFormFields(uploading)}
          </Grid>
        )}
      </Grid>
      <Grid item xs>
        <ImmersiveViewerInContext
          containerProps={{
            height: '100%',
            width: '100%',
          }}
          deckProps={{
            // Disable map interactions while uploading
            ...(uploading && { controller: false }),
          }}
        >
          <ImageMarkerCreationTool />
          <ImageMarkerFormMapMenu disabled={uploading} />
          <MapControls
            hideFlagFilter={true}
            hideClusterControl={true}
            disabled={uploading}
            className={styles.mapControls}
          />
        </ImmersiveViewerInContext>
      </Grid>
    </Grid>
  );
};

const FILTER_TYPES = [MediaType.RasterOverlay];
const DEFAULT_TYPE_METADATA = {
  [MediaType.RasterOverlay]: {
    itemsDefaultEnabled: false,
    enabled: true,
    selectId: (d: any) => MEDIA_TYPES[MediaType.RasterOverlay].selectItem(d).id,
  },
};

const ImageMarkerFormWrapper: React.FC<IImageMarkerFormProps> = props => (
  <FilterProvider types={FILTER_TYPES} defaultTypeMetadata={DEFAULT_TYPE_METADATA}>
    <ImmersiveViewerProvider>
      <ImageMarkerForm {...props} />
    </ImmersiveViewerProvider>
  </FilterProvider>
);

export default ImageMarkerFormWrapper;
