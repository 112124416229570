import { useEffect } from 'react';

import useFilterContext from '../useFilterContext';
import { MediaType } from '../../constants/media';

const PARTIAL_IMAGES_FILTER_NAME = 'partial_images';

/** Filters out images whose data is not fully ready or rendering.
 * This is only needed if image data is split between different queries. Example: Location map */
export const usePartialImagesFilter = () => {
  const { addFilter } = useFilterContext();

  useEffect(() => {
    addFilter({
      name: PARTIAL_IMAGES_FILTER_NAME,
      label: PARTIAL_IMAGES_FILTER_NAME,
      invisible: true,
      filterItem: img =>
        // Filter out images that don't contain the data necessary for rendering
        !!img.organizationId &&
        img.projectId !== undefined &&
        !!img.position &&
        !!img.capturedAt &&
        !!img.metadata &&
        !!img.customFieldMetadata &&
        !!img.author &&
        img.flagged !== undefined,
      targets: [
        {
          type: MediaType.ProjectPhoto,
          selectFilterData: item => item.node,
        },
        {
          type: MediaType.HDPhoto,
          selectFilterData: item => item.node,
        },
        {
          type: MediaType.PanoramicPhoto,
          selectFilterData: item => item.node,
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
