import * as Yup from 'yup';

const createProgramValidation = {
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
};

const updateProgramValidation = {
  ...createProgramValidation,
  project_ids: Yup.array().of(Yup.string()),
};

export const createProgramValidationSchema = Yup.object().shape(createProgramValidation);
export const updateProgramValidationSchema = Yup.object().shape(updateProgramValidation);
