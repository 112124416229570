import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Popover, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterMode } from '../../../../../context/FilterContext';
import useFilterContext from '../../../../../hooks/useFilterContext';
import { LayersIcon, LayersIconClear } from '../../../../../theme/icons';
import { useLayersBuilder } from '../../../LocationMapMenu/useMenuBuilder';
import MapMenuItemAccordions from '../../../MapMenuItem/MapMenuItemAccordions';
import FiltersList from '../../FiltersList';

import DraggableItemWrapper from './DraggableItemWrapper';

const convertLayerFilters = filter => {
  if (!filter.customChip) {
    return filter;
  }

  return {
    ...filter,
    id: filter.name,
    originalChip: filter.customChip,
    customChip: (
      <DraggableItemWrapper props={{ id: filter.name }}>{filter.customChip}</DraggableItemWrapper>
    ),
  };
};

const popoverSx = { overflowY: 'auto' };

const LayersPane = () => {
  const { useFilters, removeFilter, reorderFilters } = useFilterContext();
  const activeFilters = useFilters();

  const layerFilters = useMemo(
    () =>
      activeFilters.filter(f => f.mode === FilterMode.Additive || f.mode === FilterMode.Override) ||
      [],
    [activeFilters],
  );

  const { items: layerAccordions } = useLayersBuilder();

  const toggleMenuItem = useCallback(item => item.filter(!item.active), []);

  const [anchorEl, setAnchorEl] = useState(null);

  const [draggingFilterItem, setDraggingFilterItem] = useState(null);
  const [convertedFilterItems, setConvertedFilterItems] = useState([]);
  useEffect(() => {
    setConvertedFilterItems(layerFilters.map(i => convertLayerFilters(i)));
  }, [layerFilters]);

  const onDragCancel = useCallback(() => setDraggingFilterItem(null), []);

  const handleDragStart = useCallback(event => {
    if (!event.active) {
      return;
    }

    setDraggingFilterItem(event.active.data.current);
  }, []);

  const handleDragEnd = useCallback(
    event => {
      if (!event.active || !event.over) {
        return;
      }

      if (event.active.id !== event.over.id) {
        const oldIndex = convertedFilterItems.findIndex(item => item.id === event.active.id);
        const newIndex = convertedFilterItems.findIndex(item => item.id === event.over.id);

        reorderFilters(
          convertedFilterItems.map(f => f.name),
          items => {
            return arrayMove(items, oldIndex, newIndex);
          },
        );
      }

      setDraggingFilterItem(null);
    },
    [convertedFilterItems, reorderFilters],
  );

  const clearLayers = useCallback(() => {
    convertedFilterItems.forEach(filter => removeFilter(filter.name));
  }, [convertedFilterItems, removeFilter]);

  const toggleAccordionState = useCallback(event => {
    setAnchorEl(el => (el ? null : event.currentTarget));
  }, []);

  /**
   * The original list of the filters has the highest priority item in the bottom.
   * Reverse this list so the item will be in the top of the rendered filters list.
   */
  const reversedFiltersList = useMemo(
    () => [...convertedFilterItems].reverse(),
    [convertedFilterItems],
  );

  return (
    <>
      <Stack spacing={2} direction='row' my={1} justifyContent='center'>
        <Button color='secondary' size='small' variant='outlined' onClick={toggleAccordionState}>
          <LayersIcon />
          Add Layers
        </Button>
        <Button
          color='secondary'
          size='small'
          variant='outlined'
          disabled={!convertedFilterItems.length}
          onClick={clearLayers}
        >
          <LayersIconClear />
          Clear Layers
        </Button>
        <Popover
          sx={popoverSx}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={toggleAccordionState}
        >
          <MapMenuItemAccordions accordions={layerAccordions} toggle={toggleMenuItem} />
        </Popover>
      </Stack>

      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={onDragCancel}
        collisionDetection={closestCenter}
      >
        <SortableContext items={convertedFilterItems} strategy={verticalListSortingStrategy}>
          <FiltersList
            filters={reversedFiltersList}
            noFiltersMessage='There are currently no layers turned on.'
          />
        </SortableContext>
        <DragOverlay>{draggingFilterItem ? draggingFilterItem.originalChip : null}</DragOverlay>
      </DndContext>
    </>
  );
};

export default LayersPane;
