import React from 'react';
import { Grid } from '@mui/material';

import IdsFormTextField from '../../../../../components/ids-forms/IdsFormTextField';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseFieldsProps {}

export interface IBaseFormFields {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone: string;
}

const BaseFields: React.FC<IBaseFieldsProps> = () => {
  return (
    <>
      <Grid item xs='auto' container direction='row' spacing={2}>
        <Grid item xs>
          <IdsFormTextField name='firstName' label='First Name' required autoFocus />
        </Grid>
        <Grid item xs>
          <IdsFormTextField name='lastName' label='Last Name' required />
        </Grid>
      </Grid>
      <Grid item xs='auto'>
        <IdsFormTextField name='title' label='Title' />
      </Grid>
      <Grid item xs='auto' container direction='row' spacing={2}>
        <Grid item xs>
          <IdsFormTextField name='email' label='Email' required />
        </Grid>
        <Grid item xs>
          <IdsFormTextField
            name='phone'
            label='Phone'
            required
            // TODO: look into masking field https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BaseFields;
