import { Button, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';
import { activeProgramState } from '../../../../atoms/programs';
import IdsEditButton from '../../../../components/ids-buttons/IdsEditButton';
import { getOrgProgramEditProjectsRoute, getOrgProgramEditRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProgramActionsProps {}

const ProgramActions: React.FC<IProgramActionsProps> = () => {
  const navigate = useNavigate();

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);

  if (!activeProgram) {
    return null;
  }

  return (
    <>
      <Grid container spacing={1} pt={1} pl={1}>
        <Grid item>
          <IdsEditButton
            onClick={() =>
              navigate(
                getOrgProgramEditRoute({
                  subdomain: activeTenant?.subdomain,
                  orgId: activeOrg.id,
                  programId: activeProgram.id,
                }),
              )
            }
          />
        </Grid>
        <Grid item>
          <Button
            color='secondary'
            variant='contained'
            onClick={() =>
              navigate(
                getOrgProgramEditProjectsRoute({
                  subdomain: activeTenant?.subdomain,
                  orgId: activeOrg.id,
                  programId: activeProgram.id,
                }),
              )
            }
          >
            Bulk Create Projects
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ProgramActions;
