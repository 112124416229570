import { useCallback, useMemo } from 'react';

import {
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
  IMetadataType,
  IMetadataTypeValue,
} from '../constants/media';

export interface IMetadataItems {
  allLevels: IMetadataTypeValue[];
  allTags: IMetadataTypeValue[];
  allAreas: IMetadataTypeValue[];
  allTypes: IMetadataTypeValue[];
  allCategories: IMetadataTypeValue[];
}

const useMetadataItems = (metadataTypes: IMetadataType[]) => {
  const getMetadataTypeValues = useCallback(
    type => metadataTypes?.find(t => t.type === type)?.values || [],
    [metadataTypes],
  );

  return {
    allLevels: useMemo<IMetadataTypeValue[]>(
      () => getMetadataTypeValues(MEDIA_METADATA_TYPES[MediaMetadataType.Level].type),
      [getMetadataTypeValues],
    ),
    allTags: useMemo<IMetadataTypeValue[]>(
      () => getMetadataTypeValues(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type),
      [getMetadataTypeValues],
    ),
    allAreas: useMemo<IMetadataTypeValue[]>(
      () => getMetadataTypeValues(MEDIA_METADATA_TYPES[MediaMetadataType.Area].type),
      [getMetadataTypeValues],
    ),
    allTypes: useMemo<IMetadataTypeValue[]>(
      () => getMetadataTypeValues(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].type),
      [getMetadataTypeValues],
    ),
    allCategories: useMemo<IMetadataTypeValue[]>(
      () => getMetadataTypeValues(MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].type),
      [getMetadataTypeValues],
    ),
  };
};

export default useMetadataItems;
