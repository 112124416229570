import React from 'react';
import { Button, Grid, GridProps } from '@mui/material';

import IdsBreadcrumbs, { IIdsBreadcrumbsProps } from '../IdsBreadcrumbs';

const styles = {
  header: {
    paddingTop: 2,
    paddingBottom: 1,
  },
};

export interface IIdsNestedViewHeaderProps extends IIdsBreadcrumbsProps {
  actions?: React.ReactNode;
  preventLayoutJumping?: boolean;
  containerSx?: GridProps['sx'];
  dialogs?: React.ReactNode;
}

/**
 * This is necessary to make pages without action buttons to have
 * the same layout so there is no layout jumping when you
 * switch pages.
 */
const LayoutMock: React.FC = () => (
  <Grid item xs='auto' container flexDirection='row' spacing={1} visibility='hidden'>
    <Grid item>
      <Button color='secondary' variant='contained'>
        Button
      </Button>
    </Grid>
  </Grid>
);

const IdsNestedViewHeader: React.FC<IIdsNestedViewHeaderProps> = ({
  breadcrumbs,
  currentCrumb,
  actions,
  preventLayoutJumping = true,
  dialogs,
  containerSx,
}) => {
  return (
    <>
      <Grid container justifyContent='space-between' sx={containerSx ?? styles.header}>
        <Grid item xs='auto' container flexDirection='column' justifyContent='flex-end'>
          <Grid item xs='auto'>
            <IdsBreadcrumbs breadcrumbs={breadcrumbs} currentCrumb={currentCrumb} />
          </Grid>
        </Grid>
        {!!actions && (
          <Grid item xs='auto' container flexDirection='row' spacing={1}>
            {actions}
          </Grid>
        )}
        {!actions && preventLayoutJumping && <LayoutMock />}
      </Grid>
      {dialogs}
    </>
  );
};

export default IdsNestedViewHeader;
