import React, { useMemo, useCallback, useState } from 'react';
import { Button, Stack, Popover, ButtonProps } from '@mui/material';

import useFilterContext from '../../../../../hooks/useFilterContext';
import { FilterMode } from '../../../../../context/FilterContext';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';
import { FilterIconFilled, FilterIconFilledOff } from '../../../../../theme/icons';
import MapMenuItemAccordions from '../../../MapMenuItem/MapMenuItemAccordions';
import { useFiltersBuilder } from '../../../LocationMapMenu/useMenuBuilder';
import useMetadataItems from '../../../../../hooks/useMetadataItems';
import FiltersList from '../../FiltersList';

const popoverSx = { overflowY: 'auto' };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFilterPaneProps {}

const FilterPane: React.FC<IFilterPaneProps> = () => {
  const { location } = useLocationMapContext();
  const { allLevels, allAreas, allTypes, allCategories, allTags } = useMetadataItems(
    location.metadataTypes,
  );
  const filterAccordions = useFiltersBuilder(
    allLevels,
    allAreas,
    allTypes,
    allCategories,
    allTags,
    location.assignableCustomFields,
  );

  const { useFilters, removeFilter } = useFilterContext();
  const activeFilters = useFilters();

  const subtractiveFilters = useMemo(
    () => activeFilters.filter(f => f.mode === FilterMode.Subtractive) || [],
    [activeFilters],
  );

  const toggleMenuItem = useCallback(item => item.filter(!item.active), []);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClickAddFilters: ButtonProps['onClick'] = useCallback(
    event => setAnchorEl(event.currentTarget),
    [],
  );

  const onClickClearFilters = useCallback(() => {
    subtractiveFilters.forEach(filter => removeFilter(filter.name));
  }, [subtractiveFilters, removeFilter]);

  const onCloseAccordions = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <Stack spacing={2} direction='row' my={1} justifyContent='center'>
        <Button color='secondary' size='small' variant='outlined' onClick={onClickAddFilters}>
          <FilterIconFilled />
          Add Filters
        </Button>
        <Button
          color='secondary'
          size='small'
          variant='outlined'
          disabled={!subtractiveFilters.length}
          onClick={onClickClearFilters}
        >
          <FilterIconFilledOff />
          Clear Filters
        </Button>
        <Popover sx={popoverSx} anchorEl={anchorEl} open={!!anchorEl} onClose={onCloseAccordions}>
          <MapMenuItemAccordions accordions={filterAccordions} toggle={toggleMenuItem} />
        </Popover>
      </Stack>
      <FiltersList
        filters={subtractiveFilters}
        noFiltersMessage='There are currently no filters applied.'
      />
    </>
  );
};

export default FilterPane;
