import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

export interface IPageProps extends HTMLDivElementProps {
  title: string;
  children: React.ReactNode;
}

const Page = forwardRef<HTMLDivElement, IPageProps>(({ title, children, ...rest }, ref) => {
  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default Page;
