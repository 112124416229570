import React, { createContext, useState, useMemo } from 'react';

import { MediaType } from '../constants/media';
import useFilterContext from '../hooks/useFilterContext';

const LocationMapContext = createContext();

export const panelOptions = {
  MEDIA: 'media',
  PROJECTS: 'projects',
  FILTERS: 'filters',
  LAYERS: 'layers',
};

export const LocationMapContextProvider = ({ location, loadingLocationDetail, children }) => {
  const [loading, setLoading] = useState(true);
  const [activePane, setActivePane] = useState(panelOptions.MEDIA);
  const { useTypeData } = useFilterContext();

  const projectPhotoData = useTypeData(MediaType.ProjectPhoto);
  const hdPhotoData = useTypeData(MediaType.HDPhoto);
  const panoramaData = useTypeData(MediaType.PanoramicPhoto);
  const videoData = useTypeData(MediaType.Video);
  const threeDModelData = useTypeData(MediaType.ThreeDModel);
  const threeDVRData = useTypeData(MediaType.ThreeDVR);
  const pointOfInterestData = useTypeData(MediaType.PointOfInterest);
  const areaOfInterestData = useTypeData(MediaType.AreaOfInterest);
  const assetData = useTypeData(MediaType.Asset);

  const { projectPhotos, panoramas, hdPhotos, pointsOfInterest, areasOfInterest } = location;
  const totalProjectPhotos = useMemo(() => projectPhotos?.totalCount || 0, [projectPhotos]);
  const totalPanoramas = useMemo(() => panoramas?.totalCount || 0, [panoramas]);
  const totalHdPhotos = useMemo(() => hdPhotos?.totalCount || 0, [hdPhotos]);
  const totalPointsOfInterest = useMemo(
    () =>
      /**
       * while loading, use the location query's totalCount field
       * while not loading, use the actual data length in case the location level query data is stale and POIS have been created or deleted
       */
      loading ? pointsOfInterest?.totalCount || 0 : pointOfInterestData.length,
    [pointsOfInterest, loading, pointOfInterestData.length],
  );
  const totalAreasOfInterest = useMemo(
    () =>
      /**
       * while loading, use the location query's totalCount field
       * while not loading, use the actual data length in case the location level query data is stale and POIS have been created or deleted
       */
      loading ? areasOfInterest?.totalCount || 0 : areaOfInterestData.length,
    [areasOfInterest, loading, areaOfInterestData.length],
  );
  const totalAssets = assetData.length;
  const totalMedia = useMemo(
    () =>
      totalProjectPhotos +
      totalPanoramas +
      totalHdPhotos +
      videoData.length +
      threeDModelData.length +
      threeDVRData.length,
    [
      totalHdPhotos,
      totalPanoramas,
      totalProjectPhotos,
      videoData.length,
      threeDModelData.length,
      threeDVRData.length,
    ],
  );

  return (
    <LocationMapContext.Provider
      value={{
        panelOptions,
        activePane,
        setActivePane,
        loading: loading || loadingLocationDetail,
        setLoading,
        location,
        projectPhotosLoaded: projectPhotoData.length,
        hdPhotosLoaded: hdPhotoData.length,
        panoramasLoaded: panoramaData.length,
        videosLoaded: videoData.length,
        threeDModelsLoaded: threeDModelData.length,
        threeDVRsLoaded: threeDVRData.length,
        pointsOfInterestLoaded: pointOfInterestData.length,
        areasOfInterestLoaded: areaOfInterestData.length,
        assetsLoaded: assetData.length,
        totalProjectPhotos,
        totalPanoramas,
        totalHdPhotos,
        totalMedia,
        totalPointsOfInterest,
        totalAreasOfInterest,
        totalAssets,
      }}
    >
      {children}
    </LocationMapContext.Provider>
  );
};

export const LocationMapConsumer = LocationMapContext.Consumer;

export default LocationMapContext;
