import React, { useMemo, useState } from 'react';
import { Card, Container, Divider, Box, Grid, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import IdsTabsRenderer from '../../../components/IdsTabsRenderer';
import Page from '../../../components/Page';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import { activeProgramState } from '../../../atoms/programs';
import { activeOrganizationState } from '../../../atoms/organizations';
import QueryLoader from '../../../components/QueryLoader';
import { useGetProgramDetails } from '../../../services/ProgramsService';
import globalSx from '../../../theme/globalSx';

import { getOrgProgramsRoute } from '../../../utils/routes';

import { activeTenantState } from '../../../atoms/tenants';

// import Metadata from './Metadata';
import ProgramActions from './ProgramActions';
import ProjectProgress from './ProjectProgress';
import Projects from './Projects';
import Documents from './Documents';
import ProgramAssignmentsTab from './Assignments';
// @TODO: implement Media and Users tabs.
// import Media from './Media';
// import Users from './Users';

function ProgramDetail() {
  const [programDetails, setProgramDetails] = useState(null);

  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const activeProgram = useRecoilValue(activeProgramState);

  const useGetProgramDetailsQuery = () => useGetProgramDetails(activeProgram.id);

  const tabs = useMemo(
    () => [
      {
        key: 'projects',
        label: 'Projects',
        content: <Projects />,
      },
      {
        key: 'documents',
        label: 'Documents',
        content: <Documents />,
      },
      // {
      //   key: 'metadata',
      //   label: 'Metadata',
      //   content: <Metadata />,
      // },
      {
        key: 'assignments',
        label: 'Assignments',
        content: <ProgramAssignmentsTab />,
      },
      // @TODO: implement Media and Users tabs.
      // {
      //   key: 'media',
      //   label: 'Media',
      //   content: <Media/>,
      // },
      // {
      //   key: 'users',
      //   label: 'Users',
      //   content: <Users/>,
      // },
    ],
    [],
  );

  return (
    <Page title='Program Details'>
      <QueryLoader
        useQuery={useGetProgramDetailsQuery}
        selectData={data => data.program}
        setData={setProgramDetails}
        render={() => (
          <Container maxWidth={false}>
            <NestedOrgViewHeader
              breadcrumbs={[
                {
                  label: 'Programs',
                  route: getOrgProgramsRoute({
                    subdomain: activeTenant?.subdomain,
                    orgId: activeOrg.id,
                  }),
                },
              ]}
              currentCrumb={activeProgram.name}
              actions={<ProgramActions />}
            />

            <Card sx={globalSx.pageContent}>
              <Box px={2} py={1.5}>
                <Grid container flexDirection='column' spacing={1}>
                  <Grid item xs>
                    <Typography variant='h4' color='text.primary'>
                      {programDetails.name}
                    </Typography>
                  </Grid>
                  {!!programDetails.description && (
                    <Grid item xs>
                      <Typography variant='body2' color='text.primary'>
                        {programDetails.description}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs>
                    <Box display='flex' gap={1}>
                      <ProjectProgress />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
            </Card>
          </Container>
        )}
      />
    </Page>
  );
}

export default ProgramDetail;
