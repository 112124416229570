import React from 'react';
import { Grid, Typography } from '@mui/material';

import MediaMarkerCheckbox from '../../../../../components/mapping/LocationMap/LocationMapInfo/MediaMarkerCheckbox';

import useFilterContext from '../../../../../hooks/useFilterContext';
import { ROUTE_POINT_TYPES, RoutePointType } from '../../../../../constants/media';

// All items are loaded before this component is rendered
const loading = false;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMediaPaneProps {}

const MediaPane: React.FC<IMediaPaneProps> = () => {
  const { useTypeData } = useFilterContext();

  const hdPhotos = useTypeData(RoutePointType.DslrHdPhoto);
  const panoramas = useTypeData(RoutePointType.Panorama);
  const projectPhotos = useTypeData(RoutePointType.ProjectPhoto);
  const dslrPanoramas = useTypeData(RoutePointType.DslrPanorama);

  return (
    <Grid container direction='column'>
      <Grid item xs>
        <Typography variant='h4' textAlign='center'>
          Media
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant='caption'>
          Click a media type icon to toggle markers on or off
        </Typography>
      </Grid>
      <Grid item xs container direction='row'>
        {!!hdPhotos.length && (
          <Grid item xs={6}>
            <MediaMarkerCheckbox
              mediaTypeDef={ROUTE_POINT_TYPES[RoutePointType.DslrHdPhoto]}
              total={hdPhotos.length}
              loadedCount={hdPhotos.length}
              color={ROUTE_POINT_TYPES[RoutePointType.DslrHdPhoto].color}
              loading={loading}
            />
          </Grid>
        )}
        {!!projectPhotos.length && (
          <Grid item xs={6}>
            <MediaMarkerCheckbox
              mediaTypeDef={ROUTE_POINT_TYPES[RoutePointType.ProjectPhoto]}
              total={projectPhotos.length}
              loadedCount={projectPhotos.length}
              color={ROUTE_POINT_TYPES[RoutePointType.ProjectPhoto].color}
              loading={loading}
            />
          </Grid>
        )}
        {!!panoramas.length && (
          <Grid item xs={6}>
            <MediaMarkerCheckbox
              mediaTypeDef={ROUTE_POINT_TYPES[RoutePointType.Panorama]}
              total={panoramas.length}
              loadedCount={panoramas.length}
              color={ROUTE_POINT_TYPES[RoutePointType.Panorama].color}
              loading={loading}
            />
          </Grid>
        )}
        {!!dslrPanoramas.length && (
          <Grid item xs={6}>
            <MediaMarkerCheckbox
              mediaTypeDef={ROUTE_POINT_TYPES[RoutePointType.DslrPanorama]}
              total={dslrPanoramas.length}
              loadedCount={dslrPanoramas.length}
              color={ROUTE_POINT_TYPES[RoutePointType.DslrPanorama].color}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default MediaPane;
