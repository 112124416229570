import React, { useCallback } from 'react';
import { Box, IconButton, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { CloseIcon } from '../../theme/icons';

import styles from './index.module.css';

const sx: Record<string, SxProps<Theme>> = {
  container: theme => ({
    backgroundColor: theme.palette.action.selected,
  }),
};

export interface IBulkAction {
  icon: React.ReactNode;
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

export interface IBulkActionsChipProps {
  onDeselectAll: () => void;
  totalSelected: number;
  totalSelectedVisible?: number;
  bulkActions: IBulkAction[];
}

const BulkActionsChip: React.FC<IBulkActionsChipProps> = ({
  onDeselectAll,
  totalSelected,
  totalSelectedVisible = totalSelected,
  bulkActions,
}) => {
  const handleContainerClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(event => {
    // Stop propagation to support deselection on list background click
    event.stopPropagation();
  }, []);

  const selectionCountLabel =
    totalSelected === totalSelectedVisible
      ? `${totalSelected} selected`
      : `Showing ${totalSelectedVisible} of ${totalSelected} total selected`;

  return (
    <Box onClick={handleContainerClick} className={styles.container} sx={sx.container}>
      <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Tooltip title='Deselect all'>
          <IconButton onClick={onDeselectAll} size='small'>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Typography variant='subtitle2'>{selectionCountLabel}</Typography>
        {bulkActions.map(
          ({ icon, onClick, label, disabled }, i) =>
            !disabled && (
              <Tooltip title={label} key={`bulkAction-${i}`}>
                <IconButton size='small' onClick={onClick}>
                  {icon}
                </IconButton>
              </Tooltip>
            ),
        )}
      </Stack>
    </Box>
  );
};

export default BulkActionsChip;
