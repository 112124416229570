import { useContext } from 'react';

import { IdsUploaderContext } from '../components/ids-inputs/uploaders/IdsUploader';

export default function useIdsUploaderContext() {
  const context = useContext(IdsUploaderContext);

  if (!context) {
    throw new Error('useIdsUploaderContext must be used within an IdsUploader component');
  }

  return context;
}
