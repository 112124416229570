import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { MEDIA_PARAM_KEY } from '../../ids-media-viewer/IdsMediaViewer';

const useOverrideMediaParam = (value: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const mediaParam = searchParams.get(MEDIA_PARAM_KEY);

    if (mediaParam !== value) {
      searchParams.set(MEDIA_PARAM_KEY, value);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, value]);
};

export default useOverrideMediaParam;
