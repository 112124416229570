import React from 'react';
import { SvgIcon } from '@mui/material';

const ThreeDOutlinedIcon = props => (
  <SvgIcon {...props}>
    <path d='m21.01,6.22L12.5,1.31c-.31-.18-.69-.18-1,0L2.99,6.22c-.31.18-.5.51-.5.87v9.82c0,.36.19.69.5.87l8.51,4.91c.15.09.33.13.5.13s.35-.04.5-.13l8.51-4.91c.31-.18.5-.51.5-.87V7.09c0-.36-.19-.69-.5-.87Zm-9.01-2.89l6.51,3.76-6.51,3.76-6.51-3.76,6.51-3.76Zm-7.51,5.49l6.51,3.76v7.51l-6.51-3.76v-7.51Zm8.51,11.27v-7.51l6.51-3.76v7.51l-6.51,3.76Z' />
  </SvgIcon>
);

export default ThreeDOutlinedIcon;
