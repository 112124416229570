import { FileDownload } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { gql } from 'graphql-request';
import type { QueryStatus } from 'react-query';

import { getMediaIdFromUrn } from '../../../../../utils/media';
import IdsListItemMenuItem from '../../../../ids-lists/IdsListItemMenuItem';
import { downloadImageFromUrl } from '../../../../../utils/fileDownload';
import buttonStyles from '../../IdsMediaViewer.module.css';
import useOrgGraphQuery from '../../../../../hooks/useOrgGraphQuery';

const DownloadButtonQuery = gql`
  query DownloadButtonQuery($orgId: ID, $urn: String!, $tenantId: ID) {
    image(organizationId: $orgId, urn: $urn, tenantId: $tenantId) {
      sourceImage {
        name
        url
        downloadUrl
        width
        height
      }
    }
  }
`;

export interface IDownloadButtonQueryData {
  image: {
    sourceImage: {
      name: string;
      url: string;
      downloadUrl: string;
      width?: null | number | string;
      height?: null | number | string;
    };
  };
}

export interface IDownloadButtonProps extends Omit<IconButtonProps, 'onClick'> {
  activeId: string;
  activeMediaId: string;
  inMenu?: boolean;
}

const tooltipTitleMap: Record<QueryStatus, string> = {
  error: "Can't download an image",
  idle: 'Download original image',
  loading: 'Download original image',
  success: 'Download original image',
};

export const DownloadButton: React.FC<IDownloadButtonProps> = ({
  className,
  activeId,
  activeMediaId,
  inMenu,
  ...rest
}) => {
  const entityId = activeId ? activeId : activeMediaId;

  const { data, isLoading, isError, status } = useOrgGraphQuery<IDownloadButtonQueryData>(
    ['downloadButton', entityId],
    DownloadButtonQuery,
    {
      urn: entityId,
    },
  );

  const { image } = data || {};
  const { sourceImage } = image || {};
  const { downloadUrl, name, width, height } = sourceImage || {};

  const handleClick = useCallback(() => {
    const mediaId = getMediaIdFromUrn(entityId);
    let filename = `${mediaId}_${name}`;
    if (width && height) {
      filename += `_${width}x${height}`;
    }
    downloadImageFromUrl(filename, downloadUrl);
  }, [entityId, height, downloadUrl, width, name]);

  return inMenu ? (
    <IdsListItemMenuItem
      onClick={handleClick}
      disabled={isLoading || isError}
      icon={<FileDownload />}
    >
      Download
    </IdsListItemMenuItem>
  ) : (
    <Tooltip title={tooltipTitleMap[status]}>
      {/* In order to make Tooltip show title even if the Button is disabled */}
      {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span>
        <IconButton
          onClick={handleClick}
          disabled={isLoading || isError}
          className={clsx(buttonStyles.menuActionButton, className)}
          {...rest}
        >
          <FileDownload />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DownloadButton;
