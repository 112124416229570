import React, { memo } from 'react';
import { Box, CircularProgress, TablePagination, TablePaginationProps } from '@mui/material';

import { DEFAULT_PAGE_SIZE } from '../index';

export interface IPaginatorProps extends Pick<TablePaginationProps, 'onPageChange' | 'page'> {
  loading: boolean | undefined;
  count: number;
}

const Paginator: React.FC<IPaginatorProps> = memo(({ loading, count, page, onPageChange }) => {
  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-end'>
      {loading && (
        <Box className='centerChildren'>
          <CircularProgress size={25} />
        </Box>
      )}
      <Box>
        <TablePagination
          component='div'
          count={count}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={DEFAULT_PAGE_SIZE}
          rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
        />
      </Box>
    </Box>
  );
});

export default Paginator;
