import React, { Children, cloneElement } from 'react';
import { ListItem, ListItemButton, Grid, ListItemProps, ListItemButtonProps } from '@mui/material';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

import IdsListItemChild from './IdsListItemChild';

export interface IIdsListItemProps extends ListItemProps {
  primaryAction?: ListItemButtonProps['onClick'];
  to?: LinkProps['to'];
  buttonProps?: ListItemButtonProps;
}

/**
 * Uses a [ListItem](https://mui.com/api/list-item/)
 * component to render content.
 *
 * Wraps each child component
 * within an [IdsListItemChild](./IdsListItemChild.jsx).
 *
 * Props:
 * - `primaryAction` - If defined, the entire ListItem is a clickable button
 * - [ListItem](https://mui.com/api/list-item/) props
 */
const IdsListItem: React.FC<IIdsListItemProps> = ({
  children,
  primaryAction,
  to,
  dense,
  buttonProps,
  ...rest
}) => {
  const arrayChildren = Children.toArray(children);

  const content = (
    <Grid container columnSpacing={2} direction='row' alignItems='center'>
      {Children.map(arrayChildren, (child: any, index) => {
        return child.type !== IdsListItemChild ? (
          <IdsListItemChild key={index}>{cloneElement(child)}</IdsListItemChild>
        ) : (
          cloneElement(child, { key: index })
        );
      })}
    </Grid>
  );

  const isButton = !!primaryAction || !!to;

  return (
    <ListItem disablePadding={isButton} divider dense={dense} {...rest}>
      {isButton ? (
        // possibly change this to a link component as shown here:
        // https://mui.com/components/lists/#basic-list
        <ListItemButton
          role={undefined}
          dense={dense}
          onClick={primaryAction}
          // If 'to' prop is provided, render as router link
          // Rendering based on docs here: https://mui.com/guides/routing/#list
          {...(to && {
            component: React.forwardRef((props, ref: React.Ref<HTMLAnchorElement>) => {
              return <RouterLink to={to} ref={ref} role={undefined} {...props} />;
            }),
          })}
          // minWidth is needed to prevent issues with
          // the flexbox that breaks grid sizing in child elements
          sx={{ minWidth: 0 }}
          {...buttonProps}
        >
          {content}
        </ListItemButton>
      ) : (
        content
      )}
    </ListItem>
  );
};

export default IdsListItem;
