import React from 'react';
import PropTypes from 'prop-types';

import { useRecoilState } from 'recoil';

import { createProgramValidationSchema } from '../../programValidation';
import IdsCreateDialogForm from '../../../../components/ids-forms/IdsCreateDialogForm';
import IdsFormTextField from '../../../../components/ids-forms/IdsFormTextField';
import { activeOrganizationState } from '../../../../atoms/organizations';
import { useCreateProgram } from '../../../../services/ProgramsService';

const initialValues = {
  name: '',
  description: '',
};

function CreateProgramForm({ onSubmitSuccess, onCancel }) {
  const [organization] = useRecoilState(activeOrganizationState);
  const createProgramMutation = useCreateProgram(organization.id);

  const onSubmit = async values => {
    await createProgramMutation.mutateAsync({
      orgId: organization.id,
      name: values.name,
      description: values.description,
    });

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  return (
    <IdsCreateDialogForm
      initialValues={initialValues}
      validationSchema={createProgramValidationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Program created'
      errorHandler={() => 'Program could not be created'}
    >
      <IdsFormTextField name='name' label='Name' required autoFocus />
      <IdsFormTextField
        name='description'
        label='Description'
        required
        multiline
        minRows={3}
        maxRows={6}
        margin='normal'
      />
    </IdsCreateDialogForm>
  );
}

CreateProgramForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreateProgramForm;
