import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AccordionSectionDefault from './AccordionSectionDefault';
import styles from './MapMenuItemAccordions.module.css';

const MapMenuItemAccordions = ({ accordions, toggle }) => {
  const [expanded, setExpanded] = useState();

  function handleAccordionChange(panel) {
    const isExpanded = expanded === panel ? null : panel;
    setExpanded(isExpanded);
  }

  return (
    <>
      {accordions.map((item, i) => (
        <Box key={`mapMenuItemAccordion-${i}`}>
          <Accordion
            expanded={expanded === i}
            onChange={() => handleAccordionChange(i)}
            square
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography variant='h5'>{item.type}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              {item.children.map((child, i) => (
                <Fragment key={`accordion-item-${i}`}>
                  {child.component || <AccordionSectionDefault item={child} toggle={toggle} />}
                </Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </>
  );
};

MapMenuItemAccordions.propTypes = {
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MapMenuItemAccordions;
