import React from 'react';
import { Stack } from '@mui/material';

import IdsDialogForm, { IIdsDialogFormProps } from '../IdsDialogForm';
import CancelButton, { ICancelButtonProps } from '../../ids-buttons/CancelButton';
import IdsSubmitButton from '../IdsSubmitButton';
export interface IIdsEditDialogFormProps extends Omit<IIdsDialogFormProps, 'actions'> {
  onCancel?: ICancelButtonProps['onClick'];
  actions?: IIdsDialogFormProps['actions'];
  submitLabel?: string;
}

const IdsEditDialogForm: React.FC<IIdsEditDialogFormProps> = ({
  children,
  onCancel,
  actions,
  submitLabel,
  ...rest
}) => {
  return (
    <IdsDialogForm
      actions={
        <Stack direction='row' spacing={1} justifyContent='space-between' width='100%'>
          <Stack direction='row' spacing={1}>
            {actions}
          </Stack>
          <Stack direction='row' spacing={1}>
            <CancelButton onClick={onCancel} />
            <IdsSubmitButton variant='contained' color='primary'>
              {submitLabel || 'Save'}
            </IdsSubmitButton>
          </Stack>
        </Stack>
      }
      {...rest}
    >
      {children}
    </IdsDialogForm>
  );
};

export default IdsEditDialogForm;
