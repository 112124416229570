import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';

import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import useGraphQuery from '../hooks/useGraphQuery';
import axios from '../utils/axios';
import queryClient from '../utils/query';
import invalidateQueriesContainingKey from '../utils/invalidateQueriesContainingKey';
import { USER_ROLES, UserRole } from '../constants/users';
import { UserInRoleListItemFrag } from '../views/Users/UserListItem';
import { UsersListFrag } from '../views/Users/UsersList';

import {
  IUserDetailsCardFragType,
  UserDetailsCardFrag,
} from '../features/Users/DetailsTab/UserDetailsCard';

import {
  IOrganizationsTabUserFragType,
  OrganizationTabUserFrag,
} from '../features/Users/AccessTab/fragments';

import { useOrganizationKeys } from './OrganizationsService';
import { useProjectKeys } from './ProjectService';
import { profileKeys } from './ProfileService';

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

export const useUserKeys = () => {
  const projectKeys = useProjectKeys();
  const organizationKeys = useOrganizationKeys();

  return useMemo(() => {
    const userKeys = {
      all: ['users'],
      lists: () => [...userKeys.all, 'list'],
      orgList: (orgId: string) => [...userKeys.lists(), organizationKeys.detail(orgId)],
      projectList: (orgId: string, projectId: string) => [
        ...userKeys.orgList(orgId),
        projectId,
        ...projectKeys.detail(projectId),
      ],
      route: (id: string) => [...userKeys.all, id],
      detail: (id: string) => [...userKeys.route(id), 'detail'],
    };

    return userKeys;
  }, [projectKeys, organizationKeys]);
};

interface ICreateUserPayload {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone: string;
  // Pass NULL values to create a user without organization.
  roleId: number | null;
  organizationId: string | null;
}

const createUser = ({
  firstName,
  lastName,
  title,
  email,
  phone,
  roleId,
  organizationId,
}: ICreateUserPayload) => {
  const requiresOrg = ![
    USER_ROLES.IDS_ADMIN.id,
    USER_ROLES.IDS_TEAM.id,
    USER_ROLES.TENANT_ADMIN.id,
    USER_ROLES.TENANT_TEAM.id,
  ].includes(roleId);

  return axios.post('/api/v2/admin/users', {
    user: {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      title: title.trim(),
      email: email,
      phone,
      ...(organizationId !== null &&
        roleId !== null && {
          organizations_attributes: {
            0: {
              role_ids: roleId,
              ...(requiresOrg && { organization_ids: organizationId }),
              ...(requiresOrg && { main_organization: 1 }), // 1 here is a boolean flag, not an id
            },
          },
        }),
    },
  });
};

export interface ICreateUserResponseData {
  user: {
    id: number;
    title: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    activation_state: 'active' | 'inactive';
    multishot_enable: boolean;
    initial_organization_id: number;
  };
}

export function useCreateUser() {
  const userKeys = useUserKeys();

  return useMutation(createUser, {
    onError,
    onSuccess: async (data: AxiosResponse<ICreateUserResponseData>, { organizationId }) => {
      // Invalidate all detail queries for created user in case user existed already and auto linking occurred
      await invalidateQueriesContainingKey(userKeys.detail(`${data.data.user.id}`));

      if (organizationId === null) {
        return;
      }

      // Only invalidate user list queries in the context of the org
      await queryClient.invalidateQueries(userKeys.orgList(organizationId), {
        refetchActive: true,
      });
    },
  });
}

export interface ISetUserActiveStatePayload {
  userId: string;
  activationState: 'active' | 'inactive';
}

export function useSetUserActivationState(id: string) {
  const userKeys = useUserKeys();

  return useMutation(
    ({ userId, activationState }: ISetUserActiveStatePayload) => {
      return axios.put(`/api/v2/users/${userId}/change_activation_state`, {
        user: {
          activation_state: activationState,
        },
      });
    },
    {
      onError,
      onSuccess: async () => {
        await invalidateQueriesContainingKey(userKeys.detail(id), {
          refetchActive: true,
        });

        await queryClient.invalidateQueries(userKeys.lists(), {
          refetchActive: true,
        });
      },
    },
  );
}

const UserListQuery = gql`
  query OrgUsers($id: ID!) {
    organization(id: $id) {
      users {
        edges {
          cursor
          node {
            user {
              id
              firstName
              lastName
              email
              activationState
            }
            roleName
            ...UsersListFrag
            ...UserInRoleListItemFrag
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
  ${UsersListFrag}
  ${UserInRoleListItemFrag}
`;

export const useGetOrganizationUsers = (organizationId: string) => {
  const userKeys = useUserKeys();

  const useUsers = () =>
    useGraphQuery(userKeys.orgList(organizationId), UserListQuery, {
      id: organizationId,
    });

  return useCursorPaginatedQuery({
    useQuery: useUsers,
    defaultTake: 500,
    selectConnection: data => data.organization.users,
  });
};

const UpdateUserRolesMutation = gql`
  mutation UpdateUserRolesAcrossOrganizations(
    $where: WhereUpdateUserRolesAcrossOrganizationsInput!
    $input: UpdateUserRolesAcrossOrganizationsInput!
  ) {
    updateUserRolesAcrossOrganizations(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export type UpdateUserRolesAcrossOrganizationsInput = {
  userRolesAcrossOrganizations: {
    action: 'add' | 'update' | 'delete';
    organizationId: string;
    roleName: UserRole;
    projects: { id: string }[];
  }[];
};

interface IUpdateUserRolesMutation {
  id: string;
  tenantId?: string;
  input: UpdateUserRolesAcrossOrganizationsInput;
}

const updateUserRolesMutation = ({ id, tenantId, input }: IUpdateUserRolesMutation) => {
  return buildGraphMutationFn(UpdateUserRolesMutation)({
    input: {
      ...input,
    },
    where: {
      id,
      tenantId,
    },
  });
};

export const useUpdateUserRoles = () => {
  const userKeys = useUserKeys();

  return useMutation(
    ({ id, tenantId, input }: IUpdateUserRolesMutation) => {
      return updateUserRolesMutation({ id, tenantId, input });
    },
    {
      onError,
      onSuccess: async (_: any, { id }) => {
        await invalidateQueriesContainingKey(userKeys.detail(id), {
          refetchActive: true,
        });

        await queryClient.invalidateQueries(userKeys.lists());
      },
    },
  );
};

// ============
const OrgUserDetailsQuery = gql`
  query OrgUserDetailsQuery($id: ID!, $orgId: ID!) {
    user(id: $id, organizationId: $orgId) {
      id
      ...UserDetailsCardFrag
      ...OrganizationTabUserFrag
    }
  }
  ${UserDetailsCardFrag}
  ${OrganizationTabUserFrag}
`;

export interface IOrgUserDetailsQueryData {
  user: IUserDetailsCardFragType & IOrganizationsTabUserFragType;
}

export const useGetOrgUserDetails = (orgId: string | null, userId: string | null) => {
  const userKeys = useUserKeys();

  return useGraphQuery<IOrgUserDetailsQueryData>(userKeys.detail(userId!), OrgUserDetailsQuery, {
    id: userId,
    orgId,
  });
};

const UpdateUserRoleInOrgMutation = gql`
  mutation UpdateUserRoleInOrganization(
    $where: WhereUniqueIdInput!
    $input: UpdateUserRoleInOrganizationInput!
  ) {
    updateUserRoleInOrganization(where: $where, input: $input) {
      user {
        id
        lastName
        firstName
        roles {
          domainId
          domainType
          projects {
            id
          }
          roleName
        }
      }
      errors {
        message
      }
    }
  }
`;

export interface IUpdateUserRoleInOrgMutationPayload {
  userId: string;
  orgId: string;
  roleName?: string;
  projects?: { id: string }[];
  action: 'update' | 'delete';
}

const updateUserRoleInOrgMutation = (payload: IUpdateUserRoleInOrgMutationPayload) => {
  const { orgId, userId, projects, roleName, action } = payload;
  return buildGraphMutationFn(UpdateUserRoleInOrgMutation)({
    input: {
      projects: projects,
      organizationId: orgId,
      roleName,
      action,
    },
    where: {
      id: userId,
    },
  });
};

export const useUpdateUserRoleInOrg = (id: string) => {
  const userKeys = useUserKeys();

  return useMutation(updateUserRoleInOrgMutation, {
    onError,
    onSuccess: async (_, { orgId }) => {
      await invalidateQueriesContainingKey(userKeys.detail(id), {
        refetchActive: true,
      });

      await queryClient.invalidateQueries(userKeys.orgList(orgId), {
        refetchActive: true,
      });
    },
  });
};

const UpdateUserDetailsMutation = gql`
  mutation UpdateUserDetails($where: WhereUniqueIdInput!, $input: UpdateUserDetailsInput!) {
    updateUserDetails(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export interface IUpdateUserDetailsMutationPayload {
  userId: string;
  firstName?: string;
  lastName?: string;
  multiShotEnable?: boolean;
  phone?: string;
  title?: string;
}

const updateUserDetails = (payload: IUpdateUserDetailsMutationPayload) => {
  const { userId, firstName, lastName, title, ...input } = payload;
  return buildGraphMutationFn(UpdateUserDetailsMutation)({
    where: {
      id: userId,
    },
    input: {
      ...input,
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      title: title?.trim(),
    },
  });
};

export const useUpdateUserDetails = () => {
  const userKeys = useUserKeys();

  return useMutation(updateUserDetails, {
    onError,
    onSuccess: async () => {
      await invalidateQueriesContainingKey(profileKeys.details(), {
        refetchActive: true,
      });

      await invalidateQueriesContainingKey(userKeys.lists(), {
        refetchActive: true,
      });
    },
  });
};
