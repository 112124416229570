import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';

import * as Yup from 'yup';

import IdsEditForm from '../../../../../../../../../../components/ids-forms/IdsEditForm';
import { useUpdateTaskResponseAnswers } from '../../../../../../../../../../services/TaskResponsesService';
import { stringToRegex } from '../../../../../../../../../../utils/helpers';
import { EDITABLE_ANSWER_TYPES } from '../../../../editableAnswerTypes';
import { isImageQuestion } from '../../../../../../../../../../constants/assignments';

import EditableAnswer from './EditableAnswer';
import EditableBarcodeAnswer from './EditableBarcodeAnswer';
import EditableImageAnswer from './EditableImageAnswer';
import EditableMultiChoiceAnswer from './EditableMultiChoiceAnswer';

const errorHandler = () => 'Failed to update response';

/**
 *
 * @param {function} onCancel () => {...}
 * @param {{
 *   [index]: {
 *     answers: IAssignmentAnswer[],
 *     question: IAssignmentQuestion,
 *   }
 * }} groupedAnswers
 * @param {string} taskResponseId
 * @param {string} assignmentResponseId
 * @param {string} guid
 * @param {string} taskId
 *
 * @returns {JSX.Element}
 * @constructor
 */
const EditTaskResponseForm = ({
  onCancel,
  groupedAnswers,
  taskResponseId,
  assignmentResponseId,
  guid,
  taskId,
  defaultTaskMetadata,
}) => {
  const editTaskResponseAnswersMutation = useUpdateTaskResponseAnswers();

  const { nonEditableAnswers, initialValues, identifiersMapping } = useMemo(() => {
    const nonEditableAnswers = [];
    const identifiersMapping = {};
    const initialValues = {};

    Object.values(groupedAnswers).forEach(({ question, answers }) => {
      // Skip non-editable answers.
      if (!(question.questionType in EDITABLE_ANSWER_TYPES)) {
        nonEditableAnswers.push(
          ...answers.map(answer => {
            if (answer.uuid) {
              return {
                question_id: question.id,
                uuid: answer.uuid,
              };
            } else {
              return {
                id: answer.id,
                question_id: question.id,
                value: answer.answerText,
              };
            }
          }),
        );

        return;
      }

      if (
        EDITABLE_ANSWER_TYPES.multiple_choice === question.questionType ||
        (EDITABLE_ANSWER_TYPES.barcode === question.questionType && question.repeatable)
      ) {
        identifiersMapping[question.id] = null;

        initialValues[question.id] = answers.map(answer => answer.answerText);
      } else if (isImageQuestion(question.questionType)) {
        identifiersMapping[question.id] = null;
        initialValues[question.id] = question.repeatable ? answers : answers[0];
      } else if (answers.length) {
        answers.forEach(a => {
          identifiersMapping[a.id] = question.id;
          initialValues[a.id] = a.answerText;
        });
      } else {
        // Questions without answers.
        identifiersMapping[question.id] = null;
        initialValues[question.id] = '';
      }
    });

    return {
      nonEditableAnswers,
      initialValues,
      identifiersMapping,
    };
  }, [groupedAnswers]);

  const onSubmit = useCallback(
    async values => {
      /**
       * See API schema -> answers for structure details:
       * http://ids-platform-rest-api.s3-website-us-west-2.amazonaws.com/#/Assignments/updateAssignmentTaskResponse
       * Note: question_id and id should have type of string - integer does not work.
       * @type {*[]}
       */
      const editedAnswers = [...nonEditableAnswers];
      /**
       * Empty value means user wants to delete answer.
       * To delete an answer just do not add it the array.
       */
      for (const id in values) {
        // Check if there is a new answer for date field (to format it).
        const question = groupedAnswers[id] ?? groupedAnswers[identifiersMapping[id]];
        if (values[id] && question.question.questionType === EDITABLE_ANSWER_TYPES.date) {
          values[id] = new Date(values[id]).toUTCString();
        }

        const imageQuestion = isImageQuestion(question.question.questionType);
        const answerFieldName = imageQuestion ? 'uuid' : 'value';

        if (identifiersMapping[id] !== null && values[id]) {
          // Existing answer will be updated.
          editedAnswers.push({
            id: id,
            question_id: identifiersMapping[id],
            [answerFieldName]: imageQuestion ? values[id].uuid : values[id],
          });
        } else {
          if (Array.isArray(values[id])) {
            // Array valued answer should be split into single answers.
            values[id].forEach(value => {
              if (value) {
                editedAnswers.push({
                  question_id: id,
                  [answerFieldName]: imageQuestion ? value.uuid : value,
                });
              }
            });
          } else if (values[id]) {
            // All other answers.
            editedAnswers.push({
              question_id: id,
              [answerFieldName]: imageQuestion ? values[id].uuid : values[id],
            });
          }
        }
      }

      await editTaskResponseAnswersMutation.mutateAsync({
        assignmentResponseId,
        taskResponseId,
        data: {
          task_id: taskId,
          guid: guid,
          answers: editedAnswers,
        },
      });

      onCancel();
    },
    [
      nonEditableAnswers,
      onCancel,
      guid,
      groupedAnswers,
      taskId,
      identifiersMapping,
      assignmentResponseId,
      editTaskResponseAnswersMutation,
      taskResponseId,
    ],
  );

  const { answerFields, validationSchema } = useMemo(() => {
    const validationShape = {};

    const answerFields = Object.values(groupedAnswers).map(({ question, answers }, i) => {
      // Skip non-editable answers
      if (!(question.questionType in EDITABLE_ANSWER_TYPES)) {
        return null;
      }

      let editableAnswer;
      if (EDITABLE_ANSWER_TYPES.multiple_choice === question.questionType) {
        editableAnswer = <EditableMultiChoiceAnswer question={question} />;
      } else if (EDITABLE_ANSWER_TYPES.barcode === question.questionType) {
        editableAnswer = (
          <EditableBarcodeAnswer
            key={question.id}
            question={question}
            {...(!question.repeatable && answers.length && { answer: answers[0] })}
          />
        );

        if (question.extra.validation?.regex) {
          const { regex, errorMessage } = question.extra.validation;
          const stringRegex = Yup.string().matches(stringToRegex(regex), errorMessage);

          /**
           * @TODO: implement validation for the whole form.
           */
          validationShape[question.id] = question.repeatable
            ? Yup.array().of(stringRegex)
            : stringRegex;
        }
      } else if (isImageQuestion(question.questionType)) {
        editableAnswer = (
          <EditableImageAnswer
            question={question}
            assignmentResponseId={assignmentResponseId}
            defaultTaskMetadata={defaultTaskMetadata}
          />
        );
      } else if (answers.length) {
        editableAnswer = answers.map(a => (
          <EditableAnswer key={a.id} answer={a} question={question} />
        ));
      } else {
        editableAnswer = <EditableAnswer answer={null} question={question} />;
      }

      return (
        <Box key={i}>
          <Box>{question.title}</Box>
          <Box>{editableAnswer}</Box>
        </Box>
      );
    });

    return {
      answerFields,
      validationSchema: Yup.object().shape(validationShape),
    };
  }, [groupedAnswers, assignmentResponseId, defaultTaskMetadata]);

  return (
    <IdsEditForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Response updated'
      errorHandler={errorHandler}
    >
      <Box px={3} pb={1} display='flex' flexDirection='column' gap={1}>
        {answerFields}
      </Box>
    </IdsEditForm>
  );
};

export default EditTaskResponseForm;
