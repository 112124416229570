import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { clsx } from 'clsx';

import styles from './MapMenuItem.module.css';

const sx = {
  menuBtn: {
    '&:hover': {
      backgroundColor: 'background.dark',
    },
    backgroundColor: 'background.paper',
  },
};

const MapMenuItem = ({ label, icon, active, setActive, children }) => {
  const isActive = useMemo(() => active === label, [active, label]);
  const selectMenuItem = useCallback(() => {
    const menuItem = isActive ? null : label;
    setActive(menuItem);
  }, [setActive, label, isActive]);

  const ClassName = clsx(isActive ? styles.menuBtnActive : styles.menuBtnInactive);

  return (
    <div className={styles.mapMenuItemContainer}>
      <div className={styles.mapMenuItemBtnContainer}>
        <Button
          variant='contained'
          sx={sx.menuBtn}
          className={ClassName}
          onClick={selectMenuItem}
          data-testid={`MapMenuItem-${label}`}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <span className={styles.menuBtnIconContainer}>{icon}</span>
            {label}
          </Stack>
        </Button>
      </div>
      <div className={styles.mapMenuItemContent}>{isActive && children}</div>
    </div>
  );
};

MapMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  active: PropTypes.string,
  setActive: PropTypes.func.isRequired,
};

export default MapMenuItem;
