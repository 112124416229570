import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { clsx } from 'clsx';

import FilledIconButton from '../../../../../components/ids-buttons/FilledIconButton';

import { ExpandIcon } from '../../../../../theme/icons';
import { infoPanelExpandedState } from '../../../../../atoms/immersiveViewer';

import styles from '../RouteMapInfoPanel.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IButtonsPaneProps {}

const ButtonsPane: React.FC<IButtonsPaneProps> = () => {
  const [infoPanelExpanded, setInfoPanelExpanded] = useRecoilState(infoPanelExpandedState);
  const toggleExpanded = useCallback(
    () => setInfoPanelExpanded(prev => !prev),
    [setInfoPanelExpanded],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs='auto'>
        <FilledIconButton size='small' onClick={toggleExpanded}>
          <ExpandIcon
            className={clsx(styles.rotatingIcon, {
              [styles.collapseIcon]: infoPanelExpanded,
              [styles.expandIcon]: !infoPanelExpanded,
            })}
          />
        </FilledIconButton>
      </Grid>
    </Grid>
  );
};

export default ButtonsPane;
