import { useEffect, useRef } from 'react';

const useMountedEffect = effect => {
  const effectRef = useRef(effect);

  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    return effectRef.current();
  }, []);
};

export default useMountedEffect;
