import React, { useMemo } from 'react';
import { gql } from 'graphql-request';
import { Stack, Typography } from '@mui/material';

import {
  AssignmentQuestionType,
  AssignmentQuestionTypes,
} from '../../../../../../../../constants/assignments';

import StringAnswer, { IStringAnswerFragType, StringAnswerFrag } from './StringAnswer';
import ImageAnswer, { IImageAnswerFragType, ImageAnswerFrag } from './ImageAnswer';

export interface IAnswerFragType extends IStringAnswerFragType, IImageAnswerFragType {
  createdAt: string;
  updatedAt: string;
}

export const AnswerFrag = gql`
  fragment AnswerFrag on AssignmentAnswer {
    createdAt
    updatedAt
    ...StringAnswerFrag
    ...ImageAnswerFrag
  }
  ${StringAnswerFrag}
  ${ImageAnswerFrag}
`;

const ANSWER_TYPE_COMPONENTS: Partial<Record<AssignmentQuestionTypes, React.FC<any>>> = {
  [AssignmentQuestionType.TEXT]: StringAnswer,
  [AssignmentQuestionType.SINGLE_CHOICE]: StringAnswer,
  [AssignmentQuestionType.MULTIPLE_CHOICE]: StringAnswer,
  [AssignmentQuestionType.DATE]: StringAnswer,
  [AssignmentQuestionType.NUMBER]: StringAnswer,
  [AssignmentQuestionType.BARCODE]: StringAnswer,
  [AssignmentQuestionType.RADIO]: StringAnswer,
  [AssignmentQuestionType.PHOTO_SIMPLE]: ImageAnswer,
  [AssignmentQuestionType.PHOTO_HD]: ImageAnswer,
  [AssignmentQuestionType.PHOTO_360]: ImageAnswer,
};

export interface IAnswerProps {
  answer: IAnswerFragType;
  question: {
    questionType: AssignmentQuestionTypes;
  };
}

const Answer: React.FC<IAnswerProps> = ({ answer, question, ...rest }) => {
  const { createdAt, updatedAt } = answer;
  const { questionType } = question;

  const AnswerComponent = useMemo(() => ANSWER_TYPE_COMPONENTS[questionType], [questionType]);

  const _answer = useMemo(() => ({ ...answer, questionType }), [answer, questionType]);

  if (!AnswerComponent) return null;

  return (
    <Stack {...rest}>
      <Typography variant='caption' color='text.secondary'>
        Created: {new Date(createdAt).toLocaleString()}
      </Typography>
      <Typography variant='caption' color='text.secondary'>
        Updated: {new Date(updatedAt).toLocaleString()}
      </Typography>
      <AnswerComponent answer={_answer} />
    </Stack>
  );
};

export default Answer;
