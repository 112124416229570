import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { activeOrganizationState } from '../../atoms/organizations';
import useDeleteEntity from '../../hooks/useDeleteEntity';
import { useDeleteProgram } from '../../services/ProgramsService';

const useDeleteProgramAction = () => {
  const [organization] = useRecoilState(activeOrganizationState);
  const deleteProgramMutation = useDeleteProgram(organization.id);
  const { deleted, deleting, handleDelete } = useDeleteEntity();

  const deleteProgram = useCallback(
    program => {
      handleDelete(
        async () => await deleteProgramMutation.mutateAsync({ programId: program?.id }),
        program?.name,
        'This cannot be undone, all program data will be deleted permanently.',
        'Program',
      );
    },
    [deleteProgramMutation, handleDelete],
  );

  return {
    deleted,
    deleting,
    deleteProgram,
  };
};

export default useDeleteProgramAction;
