import React from 'react';

import IdsSubmitButton, { IIdsSubmitButtonProps } from '../../ids-forms/IdsSubmitButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateButtonProps extends IIdsSubmitButtonProps {}

const CreateButton: React.FC<ICreateButtonProps> = props => {
  return (
    <IdsSubmitButton variant='contained' color='primary' {...props}>
      Create
    </IdsSubmitButton>
  );
};

export default CreateButton;
