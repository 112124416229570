import axios from 'axios';

export const DownloadFileAsBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); // or any other extension
  document.body.appendChild(link);
  link.click();
};

/**
 * Use it for files with non octet-stream response header.
 *
 * Other approaches for such files will make them being previewed by browser tools.
 */
export const getAndDownload = async (url, fileName) => {
  await axios
    .get(url, { responseType: 'arraybuffer' })
    .then(response => {
      return response.data;
    })
    .then(blob => {
      DownloadFileAsBlob(blob, fileName);
    });
};

export const downloadFileFromUrl = (fileName, url) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;

  // Open in new tab (prevents user from being routed away
  link.target = '_blank'; // Some file types open a viewer in the same tab by default (Ex: pdf in chrome)
  link.rel = 'noreferrer noopener'; // protect against tabnabbing phishing attack
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadImageFromUrl = (fileName, url) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;

  link.rel = 'noreferrer noopener'; // protect against tabnabbing phishing attack
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Returns TRUE for such cases:
 * - 'https://...some..path../file.pdf'
 * - 'https://...some..path../file.pdf?something'
 * - 'https://...some..path../file.whatever.pdf?something'
 *
 * Returns FALSE for such cases:
 * - 'https://...some..path../file.pdf.zip'
 *
 * @param {string} url
 *
 * @return {boolean}
 */
export const isPdfUrl = url => {
  return url && url.split(/[#?]/)[0].split('.').pop() === 'pdf';
};

/**
 * Checks for MIME type.
 * @TODO: Not really robust, but enough so far. Consider implementing more robust solution if necessary.
 *
 * @param {File} file
 *
 * @return {boolean}
 */
export const isPdfFile = file => {
  return file && file.type === 'application/pdf';
};
