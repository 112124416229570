import { gql } from 'graphql-request';

import { useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../../atoms/organizations';

import { AssignmentListItemFrag } from '../../../Assignments/AssignmentListItem';
import AssignmentsList from '../../../Assignments/AssignmentsList';
import { activeLocationState } from '../../../../atoms/locations';
import { useLocationKeys } from '../../../../services/LocationService';
import { getOrgLocationAssignmentRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';
import useOrgGraphQuery from '../../../../hooks/useOrgGraphQuery';

const LocationAssignmentListQuery = gql`
  query LocationAssignments($orgId: ID, $id: ID!, $tenantId: ID, $take: Int, $after: String) {
    location(organizationId: $orgId, tenantId: $tenantId, id: $id) {
      assignments(after: $after, take: $take) {
        edges {
          cursor
          node {
            id
            title
            active
            ...AssignmentListItemFrag
          }
        }
      }
    }
  }
  ${AssignmentListItemFrag}
`;

const LocationAssignmentsTab = () => {
  const locationKeys = useLocationKeys();

  const activeTenant = useRecoilValue(activeTenantState);
  const organization = useRecoilValue(activeOrganizationState);
  const location = useRecoilValue(activeLocationState);

  const useAssignments = (take: number, after: string | null) =>
    useOrgGraphQuery(
      [...locationKeys.detail(location!.id), 'assignments', `take-${take}`, `after-${after}`],
      LocationAssignmentListQuery,
      { id: location!.id, take, after },
    );

  if (!location) {
    return null;
  }

  return (
    <AssignmentsList
      useGetAssignments={useAssignments}
      selectConnection={data => data?.location.assignments}
      getTo={assignmentId =>
        getOrgLocationAssignmentRoute({
          subdomain: activeTenant?.subdomain,
          orgId: organization.id,
          locationId: location.id,
          assignmentId,
        })
      }
    />
  );
};

export default LocationAssignmentsTab;
