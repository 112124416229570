import { Box, Backdrop, CircularProgress } from '@mui/material';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';

import IdsForm from '../../../components/ids-forms/IdsForm';
import IdsFormTextField from '../../../components/ids-forms/IdsFormTextField';
import useGraphQuery from '../../../hooks/useGraphQuery';
import { profileKeys, useUpdateSettings } from '../../../services/ProfileService';
import ActionButtons from '../Common/ActionButtons';

import styles from './Settings.module.css';
import { settingsValidationSchema } from './settingsValidation';

const ProfileSettingsQuery = gql`
  query Me {
    me {
      email
    }
  }
`;

const Settings = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const updateSettingsMutation = useUpdateSettings();
  const { data, isLoading, error } = useGraphQuery(profileKeys.settings(), ProfileSettingsQuery);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const [initialValues, setInitialValues] = useState(() => ({
    current_password: '',
    email: '',
    password: '',
    password_confirmation: '',
  }));

  useEffect(() => {
    if (!isLoading && data) {
      setInitialValues({
        current_password: '',
        email: data.me.email,
        password: '',
        password_confirmation: '',
      });
    }
  }, [isLoading, data]);

  const onSubmitHandler = async values => {
    setIsUpdating(true);

    await updateSettingsMutation.mutateAsync(values);

    setIsUpdating(false);
  };

  return (
    <Box position='relative' p={2}>
      <IdsForm
        enableReinitialize
        initialValues={initialValues}
        validationSchema={settingsValidationSchema}
        onSubmit={onSubmitHandler}
        errorHandler={error => {
          setIsUpdating(false);
          setIsEditMode(true);

          return error?.response?.data?.message || error.message;
        }}
        successMessage='Profile settings were updated.'
      >
        <Backdrop
          className={styles.backdrop}
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isUpdating}
        >
          <CircularProgress />
        </Backdrop>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box>
            <IdsFormTextField
              disabled={!isEditMode}
              name='current_password'
              label='Current Password'
              type='password'
              required
            />
          </Box>
          <Box>
            <IdsFormTextField disabled={!isEditMode} name='email' label='Email' required />
          </Box>
          <Box>
            <IdsFormTextField
              disabled={!isEditMode}
              name='password'
              label='New Password'
              type='password'
              required
            />
          </Box>
          <Box>
            <IdsFormTextField
              disabled={!isEditMode}
              name='password_confirmation'
              label='Confirm New Password'
              type='password'
              required
            />
          </Box>
          <ActionButtons
            isUpdating={isUpdating}
            isLoading={isLoading}
            isEditMode={isEditMode}
            setEditMode={setIsEditMode}
          />
        </Box>
      </IdsForm>
    </Box>
  );
};

export default Settings;
