import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

import getInitials from '../../utils/getInitials';

const IdsUserAvatar = ({ name, ...rest }) => <Avatar {...rest}>{getInitials(name)}</Avatar>;

IdsUserAvatar.propTypes = {
  name: PropTypes.PropTypes.string.isRequired,
};

export default IdsUserAvatar;
