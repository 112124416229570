import React from 'react';

import { Box } from '@mui/material';

import ComingSoon from './ComingSoon';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: 'calc(100vh - 64px)',
  },
};

function ComingSoonRoute({ legacyFeatureRoute, ...rest }) {
  return (
    <Box sx={styles.container}>
      <ComingSoon legacyFeatureRoute={legacyFeatureRoute} {...rest} />
    </Box>
  );
}

export default ComingSoonRoute;
