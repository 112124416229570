import React from 'react';
import { Box, Container, ContainerProps } from '@mui/material';

import Page from '../Page';
import styles from '../../theme/styles';

import { IIdsBreadcrumbsProps } from '../IdsBreadcrumbs';

import OrgViewHeader, { IOrgViewHeaderProps } from './OrgViewHeader';

export interface IOrgPageProps extends Pick<IOrgViewHeaderProps, 'breadcrumbs'> {
  title: IIdsBreadcrumbsProps['currentCrumb'];
  children: React.ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  containerClassName?: string;
  headerHidden?: boolean;
}

const OrgPage: React.FC<IOrgPageProps> = ({
  title,
  breadcrumbs,
  children,
  maxWidth = false,
  containerClassName,
  headerHidden = false,
}) => {
  return (
    <Page title={title}>
      <Box sx={styles.rootPage}>
        <Container maxWidth={maxWidth} className={containerClassName}>
          {!headerHidden && <OrgViewHeader currentCrumb={title} breadcrumbs={breadcrumbs} />}
          {children}
        </Container>
      </Box>
    </Page>
  );
};

export default OrgPage;
