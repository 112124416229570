import { Routes, Navigate, Route, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import OrgRoute from '../components/entity-routes/OrgRoute';
import LocationRoute from '../components/entity-routes/LocationRoute';
import ProjectRoute from '../components/entity-routes/ProjectRoute';
import ProgramRoute from '../components/entity-routes/ProgramRoute';
import UserRoute from '../components/entity-routes/UserRoute';
import AssignmentRoute from '../components/entity-routes/AssignmentRoute';
import AssignmentResponseRoute from '../components/entity-routes/AssignmentResponseRoute';
import CollectionRoute from '../components/entity-routes/CollectionRoute';

import DashboardLayout from '../layouts/DashboardLayout';
import Organizations from '../views/Organizations/index';

import Assignments from '../views/Assignments';
import AssignmentDetails from '../views/Assignments/Detail';
import AssignmentResponseDetails from '../views/Assignments/AssignmentDetails/Responses/Detail';
import Collections from '../views/Collections';
import CollectionGallery from '../views/Collections/Gallery';
import Dashboard from '../views/Dashboard';
import Locations from '../views/Locations';
import LocationCopy from '../views/Locations/LocationCopy';
import LocationDetail from '../views/Locations/Detail';
import LocationEdit from '../views/Locations/Edit';
import LocationSettings from '../views/Locations/LocationSettings';
import Map from '../views/Map';
import Media from '../views/Media';
import OrganizationDetails from '../views/Organizations/Detail';
import OrganizationEdit from '../views/Organizations/Edit';
import OrganizationSettings from '../views/Organizations/OrganizationSettings';
import Programs from '../views/Programs';
import ProgramDetail from '../views/Programs/Detail';
import EditProgram from '../views/Programs/Edit/EditProgram';
import BulkCreateProjects from '../views/Programs/Edit/BulkCreateProjects';
import Projects from '../views/Projects';
import ProjectDetails from '../views/Projects/Detail';
import ProjectEdit from '../views/Projects/Edit';
import Profile from '../views/Profile';
import Reports from '../views/reporting';
import RoutesView from '../views/Routes/index';
import Uploads from '../views/Uploads';
import Users from '../views/Users';
import UserDetail from '../views/Users/Detail';
import { EntityType } from '../constants/entities';
import { USER_ROLES } from '../constants/users';

import Error from '../views/Error';

import ProjectAssignmentDetailsPage from '../views/Projects/AssignmentDetails';

import ProgramAssignmentDetailsPage from '../views/Programs/AssignmentDetails';

import ProgramAssignmentRoute from '../components/entity-routes/ProgramAssignmentRoute';

import ProjectAssignmentRoute from '../components/entity-routes/ProjectAssignmentRoute';

import ProjectRouteRoute from '../components/entity-routes/ProjectRouteRoute';
import ProjectRouteDetailsPage from '../views/Projects/RouteDetailsPage';

import TenantMap from '../views/Tenant/TenantMap';
import TenantAssignments from '../views/Tenant/TenantAssignments';
import TenantDashboardLayout from '../layouts/TenantDashboardLayout';
import TenantDetails from '../views/Tenant/TenantDetails';
import TenantPrograms from '../views/Tenant/TenantPrograms';
import TenantProjects from '../views/Tenant/TenantProjects';
import TenantLocations from '../views/Tenant/TenantLocations';
import TenantReports from '../views/Tenant/TenantReports';
import TenantUploads from '../views/Tenant/TenantUploads';
import TenantUsers from '../views/Tenant/TenantUsers';
import TenantUserDetails from '../views/Tenant/TenantUserDetails';
import TenantSettings from '../views/Tenant/Settings';
import ProgramAssignmentResponseRoute from '../components/entity-routes/ProgramAssignmentResponseRoute';

import ProgramAssignmentResponseDetails from '../views/Programs/AssignmentResponseDetails';

import ProjectAssignmentResponseRoute from '../components/entity-routes/ProjectAssignmentResponseRoute';
import ProjectAssignmentResponseDetails from '../views/Projects/AssignmentResponseDetails';

import Tenants from '../views/Tenant/Tenants';

import NoTenantDashboardLayout from '../layouts/NoTenantDashboardLayout';

import TenantRoute from '../components/entity-routes/TenantRoute';

import { getTenantsRoute, getTenantUsersRoute } from '../utils/routes';
import { activeTenantState } from '../atoms/tenants';

import TenantUserRoute from '../components/entity-routes/TenantUserRoute';
import LocationAssignmentRoute from '../components/entity-routes/LocationAssignmentRoute';
import LocationAssignmentDetailsPage from '../views/Locations/AssignmentDetails';
import LocationAssignmentResponseRoute from '../components/entity-routes/LocationAssignmentResponseRoute';
import LocationAssignmentResponseDetails from '../views/Locations/AssignmentResponseDetails';

import AuthGuard from './AuthGuard';

function _Routes() {
  const activeTenant = useRecoilValue(activeTenantState);

  const tenantRoutes = (
    // NOTE: Not wrapped with AuthGuard, because Organizations
    // route should be accessible for everyone
    <Route path='' element={<TenantDashboardLayout />}>
      <Route path='organizations' element={<Organizations />} />
      <Route
        path=''
        element={
          // <AuthGuard roles={[USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM, USER_ROLES.TENANT_ADMIN, USER_ROLES.TENANT_TEAM]}>
          <Outlet />
          // </AuthGuard>
        }
      >
        <Route index element={<Navigate to='organizations' replace={true} />} />
        <Route path='map' element={<TenantMap />} />

        {/* Uncomment, if Tenant level view will need own Map location */}
        {/* <Route path='map/:locationId' element={<TenantMap />} /> */}
      </Route>
      <Route
        path='locations'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantLocations />
          </AuthGuard>
        }
      />
      <Route
        path='uploads'
        element={
          <AuthGuard
            roles={[USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantUploads />
          </AuthGuard>
        }
      />
      <Route
        path='projects'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantProjects />
          </AuthGuard>
        }
      />
      <Route
        path='programs'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantPrograms />
          </AuthGuard>
        }
      />
      <Route
        path='users'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantUsers />
          </AuthGuard>
        }
      />
      <Route
        path='users/:userId'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={
              activeTenant
                ? getTenantUsersRoute({ subdomain: activeTenant.subdomain })
                : getTenantsRoute({})
            }
          >
            <TenantUserRoute />
          </AuthGuard>
        }
      >
        <Route path='' element={<TenantUserDetails />} />
        <Route
          path='edit'
          element={
            <AuthGuard permissionType='update' entityType={EntityType.User}>
              <TenantUserDetails edit />
            </AuthGuard>
          }
        />
      </Route>
      <Route
        path='assignments'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantAssignments />
          </AuthGuard>
        }
      />
      <Route
        path='reports'
        element={
          <AuthGuard
            roles={[
              USER_ROLES.IDS_ADMIN,
              USER_ROLES.IDS_TEAM,
              USER_ROLES.ORG_ADMIN,
              USER_ROLES.TENANT_ADMIN,
            ]}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantReports />
          </AuthGuard>
        }
      />
      <Route
        path='settings'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantSettings />
          </AuthGuard>
        }
      />

      <Route
        path='details'
        element={
          <AuthGuard
            permissionType='read'
            entityType={EntityType.Tenant}
            errorRedirectPath={getTenantsRoute({})}
          >
            <TenantDetails />
          </AuthGuard>
        }
      />

      {/* end of tenant routes */}
      {/* Org level will have own AuthGuard checks */}
      <Route path='organization/:orgId' element={<OrgRoute />}>
        <Route
          path=''
          element={
            <AuthGuard permissionType='read' entityType={EntityType.Organization}>
              <DashboardLayout />
            </AuthGuard>
          }
        >
          <Route path='' element={<Dashboard />} />
          <Route
            path='assignments'
            element={
              <AuthGuard
                roles={[
                  USER_ROLES.IDS_ADMIN,
                  USER_ROLES.IDS_TEAM,
                  USER_ROLES.TENANT_ADMIN,
                  USER_ROLES.TENANT_TEAM,
                  USER_ROLES.ORG_ADMIN,
                ]}
              >
                <Assignments />
              </AuthGuard>
            }
          />
          <Route
            path='assignments/:assignmentId'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Assignment}>
                <AssignmentRoute />
              </AuthGuard>
            }
          >
            <Route path='' element={<AssignmentDetails />} />
            <Route
              path='responses/:assignmentResponseId'
              element={
                <AuthGuard permissionType='read' entityType={EntityType.AssignmentResponse}>
                  <AssignmentResponseRoute />
                </AuthGuard>
              }
            >
              <Route path='' element={<AssignmentResponseDetails />} />
            </Route>
          </Route>
          <Route
            path='routes'
            element={
              <AuthGuard roles={[USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]}>
                <RoutesView />
              </AuthGuard>
            }
          />
          <Route
            path='uploads'
            element={
              <AuthGuard roles={[USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]}>
                <Uploads />
              </AuthGuard>
            }
          />
          <Route path='explore' element={<Outlet />}>
            <Route path='map' element={<Map />} />
            <Route path='map/:locationId' element={<Map />} />
            <Route path='media' element={<Media />} />
          </Route>
          <Route
            path='programs'
            element={
              <AuthGuard
                roles={[
                  USER_ROLES.IDS_ADMIN,
                  USER_ROLES.IDS_TEAM,
                  USER_ROLES.ORG_ADMIN,
                  USER_ROLES.ORG_MANAGER,
                  USER_ROLES.TENANT_ADMIN,
                  USER_ROLES.TENANT_TEAM,
                ]}
              >
                <Programs />
              </AuthGuard>
            }
          />
          <Route
            path='programs/:programId'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Program}>
                <AuthGuard
                  roles={[
                    USER_ROLES.IDS_ADMIN,
                    USER_ROLES.IDS_TEAM,
                    USER_ROLES.ORG_ADMIN,
                    USER_ROLES.ORG_MANAGER,
                    USER_ROLES.TENANT_ADMIN,
                    USER_ROLES.TENANT_TEAM,
                  ]}
                >
                  <ProgramRoute />
                </AuthGuard>
              </AuthGuard>
            }
          >
            <Route path='' element={<ProgramDetail />} />
            <Route
              path='edit'
              element={
                <AuthGuard permissionType='update' entityType={EntityType.Program}>
                  <EditProgram />
                </AuthGuard>
              }
            />
            <Route
              path='assignments/:assignmentId'
              element={
                <AuthGuard permissionType='read' entityType={EntityType.Assignment}>
                  <ProgramAssignmentRoute />
                </AuthGuard>
              }
            >
              <Route path='' element={<ProgramAssignmentDetailsPage />} />
              <Route
                path='responses/:assignmentResponseId'
                element={
                  <AuthGuard permissionType='read' entityType={EntityType.AssignmentResponse}>
                    <ProgramAssignmentResponseRoute />
                  </AuthGuard>
                }
              >
                <Route path='' element={<ProgramAssignmentResponseDetails />} />
              </Route>
            </Route>
            <Route
              path='edit/projects'
              element={
                <AuthGuard permissionType='update' entityType={EntityType.Program}>
                  <BulkCreateProjects />
                </AuthGuard>
              }
            />
          </Route>
          <Route path='collections' element={<Collections />} />
          <Route
            path='collections/:collectionId'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Collection}>
                {/* TODO: Rewrite CollectionRoute to TS */}
                <CollectionRoute publicRoute={undefined} />
              </AuthGuard>
            }
          >
            {/* TODO: Rewrite CollectionGallery to TS */}
            <Route path='' element={<CollectionGallery publicRoute={undefined} />} />
          </Route>
          <Route
            path='reports'
            element={
              <AuthGuard
                roles={[
                  USER_ROLES.IDS_ADMIN,
                  USER_ROLES.IDS_TEAM,
                  USER_ROLES.ORG_ADMIN,
                  USER_ROLES.TENANT_ADMIN,
                ]}
              >
                <Reports />
              </AuthGuard>
            }
          />
          <Route
            path='projects'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Project}>
                <Projects />
              </AuthGuard>
            }
          />
          <Route
            path='projects/:projectId'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Project}>
                <ProjectRoute />
              </AuthGuard>
            }
          >
            <Route path='' element={<ProjectDetails />} />
            <Route
              path='edit'
              element={
                <AuthGuard permissionType='update' entityType={EntityType.Project}>
                  <ProjectEdit />
                </AuthGuard>
              }
            />
            <Route
              path='routes/:routeId'
              element={
                <AuthGuard
                  roles={[
                    USER_ROLES.IDS_ADMIN,
                    USER_ROLES.IDS_TEAM,
                    USER_ROLES.TENANT_TEAM,
                    USER_ROLES.TENANT_ADMIN,
                  ]}
                >
                  <ProjectRouteRoute />
                </AuthGuard>
              }
            >
              <Route path='' element={<ProjectRouteDetailsPage />} />
            </Route>
            <Route
              path='assignments/:assignmentId'
              element={
                <AuthGuard permissionType='read' entityType={EntityType.Assignment}>
                  <ProjectAssignmentRoute />
                </AuthGuard>
              }
            >
              <Route path='' element={<ProjectAssignmentDetailsPage />} />
              <Route
                path='responses/:assignmentResponseId'
                element={
                  <AuthGuard permissionType='read' entityType={EntityType.AssignmentResponse}>
                    <ProjectAssignmentResponseRoute />
                  </AuthGuard>
                }
              >
                <Route path='' element={<ProjectAssignmentResponseDetails />} />
              </Route>
            </Route>
          </Route>
          <Route
            path='locations'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Location}>
                <Locations />
              </AuthGuard>
            }
          />
          <Route
            path='locations/:locationId'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.Location}>
                <LocationRoute />
              </AuthGuard>
            }
          >
            <Route path='' element={<LocationDetail />} />
            <Route
              path='edit'
              element={
                <AuthGuard permissionType='update' entityType={EntityType.Location}>
                  <LocationEdit />
                </AuthGuard>
              }
            />

            <Route
              path='assignments/:assignmentId'
              element={
                <AuthGuard permissionType='read' entityType={EntityType.Assignment}>
                  <LocationAssignmentRoute />
                </AuthGuard>
              }
            >
              <Route path='' element={<LocationAssignmentDetailsPage />} />
              <Route
                path='responses/:assignmentResponseId'
                element={
                  <AuthGuard permissionType='read' entityType={EntityType.AssignmentResponse}>
                    <LocationAssignmentResponseRoute />
                  </AuthGuard>
                }
              >
                <Route path='' element={<LocationAssignmentResponseDetails />} />
              </Route>
            </Route>

            <Route path='copy' element={<LocationCopy />} />
            <Route path='settings' element={<LocationSettings />} />
          </Route>
          <Route
            path='users'
            element={
              <AuthGuard
                roles={[
                  USER_ROLES.IDS_ADMIN,
                  USER_ROLES.IDS_TEAM,
                  USER_ROLES.ORG_ADMIN,
                  USER_ROLES.TENANT_ADMIN,
                  USER_ROLES.TENANT_TEAM,
                ]}
              >
                <Users />
              </AuthGuard>
            }
          />
          <Route
            path='users/:userId'
            element={
              <AuthGuard permissionType='read' entityType={EntityType.User}>
                <UserRoute />
              </AuthGuard>
            }
          >
            <Route path='' element={<UserDetail />} />
            <Route
              path='edit'
              element={
                <AuthGuard permissionType='update' entityType={EntityType.User}>
                  <UserDetail edit />
                </AuthGuard>
              }
            />
          </Route>
          <Route
            path='details'
            element={
              <AuthGuard
                roles={[
                  USER_ROLES.IDS_ADMIN,
                  USER_ROLES.IDS_TEAM,
                  USER_ROLES.ORG_ADMIN,
                  USER_ROLES.TENANT_ADMIN,
                  USER_ROLES.TENANT_TEAM,
                ]}
              >
                <OrganizationDetails />
              </AuthGuard>
            }
          />
          <Route
            path='edit'
            element={
              <AuthGuard permissionType='update' entityType={EntityType.Organization}>
                <OrganizationEdit />
              </AuthGuard>
            }
          />
          <Route
            path='settings'
            element={
              <AuthGuard
                roles={[
                  USER_ROLES.IDS_ADMIN,
                  USER_ROLES.IDS_TEAM,
                  USER_ROLES.ORG_ADMIN,
                  USER_ROLES.TENANT_ADMIN,
                  USER_ROLES.TENANT_TEAM,
                ]}
              >
                <OrganizationSettings />
              </AuthGuard>
            }
          />
        </Route>
      </Route>
    </Route>
  );

  return (
    <Routes>
      <Route path='/' element={<Navigate to={getTenantsRoute({})} />} />
      {/* Public routes */}
      <Route path='public' element={<Outlet />}>
        <Route path='collections/:collectionId' element={<CollectionRoute publicRoute={true} />}>
          <Route path='' element={<CollectionGallery publicRoute={true} />} />
        </Route>
      </Route>

      {/* Private routes */}
      <Route
        path='app'
        element={
          <AuthGuard sessionRequired={true}>
            <Outlet />
          </AuthGuard>
        }
      >
        {/* Tenant routes */}
        <Route path='' element={<NoTenantDashboardLayout />}>
          <Route index element={<Navigate to={getTenantsRoute({})} />} />
          <Route path='tenants' element={<Tenants />} />
          <Route path=':subdomain' element={<TenantRoute />}>
            {tenantRoutes}
          </Route>
          {/* Default IDS tenant routes that will assume all data is under the IDS tenant since no tenant subdomain is selected. */}
          <Route path='' element={<TenantRoute />}>
            {tenantRoutes}
          </Route>
        </Route>
      </Route>

      <Route
        path='profile'
        element={
          <AuthGuard sessionRequired={true}>
            <Outlet />
          </AuthGuard>
        }
      >
        <Route path='' element={<Profile />} />
      </Route>
      <Route path='*' element={<Error />} />
    </Routes>
  );
}

export default _Routes;
