import React from 'react';
import { useRecoilValue } from 'recoil';

import { activeLocationState } from '../../../../atoms/locations';
import { useGetLocationAssets } from '../../../../services/LocationService';
import { FILTER_MODES } from '../../../../components/ids-lists/IdsSearchableList';
import AssetsList from '../../../Assets/AssetsList';
import AssetListItem from '../../../Assets/AssetsList/AssetListItem';

import Actions from './Actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssetsTabProps {}

const AssetsTab: React.FC<IAssetsTabProps> = () => {
  const activeLocation = useRecoilValue(activeLocationState);
  const { data, isLoading, isError } = useGetLocationAssets(activeLocation!.id);

  return (
    <AssetsList
      actions={<Actions />}
      items={data?.location.assets || []}
      renderItem={item => <AssetListItem key={item.id} item={item} />}
      filterMode={FILTER_MODES.SELECT}
      loading={isLoading}
      error={isError ? 'Assets could not be loaded' : null}
    />
  );
};

export default AssetsTab;
