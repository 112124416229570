import React from 'react';

import IdsDialog, { IIdsDialogProps } from '../../../../../../../../../components/IdsDialog';

import CreateTaskResponseForm, { ICreateTaskResponseFormProps } from './CreateTaskResponseForm';

export interface ICreateTaskResponseDialogProps extends ICreateTaskResponseFormProps {
  open: boolean;
  onClose: IIdsDialogProps['onClose'];
  title: IIdsDialogProps['title'];
}

const CreateTaskResponseDialog: React.FC<ICreateTaskResponseDialogProps> = ({
  open,
  onClose,
  title,
  questions,
  assignmentResponseId,
  taskId,
  metadata,
  target,
  defaultTaskMetadata,
}) => {
  return (
    <IdsDialog
      open={open}
      onClose={onClose}
      title={title ? `Create Response For ${title}` : 'Create Response'}
      dialogKey='create-response'
      fullWidth
    >
      <CreateTaskResponseForm
        taskId={taskId}
        onCancel={onClose}
        questions={questions}
        assignmentResponseId={assignmentResponseId}
        metadata={metadata}
        target={target}
        defaultTaskMetadata={defaultTaskMetadata}
      />
    </IdsDialog>
  );
};

export default CreateTaskResponseDialog;
