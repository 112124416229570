import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Typography } from '@mui/material';

import MediaMetadataMultiSelect from '../../ids-inputs/common/MediaMetadataMultiSelect';
import MediaMetadataChecklist from '../../ids-inputs/common/MediaMetadataChecklist';

import { infoPanelActivePaneState } from '../../../atoms/immersiveViewer';
import { panelOptions } from '../../../context/LocationMapContext';
import useMediaMetadataFilter from '../../../hooks/filtering/useMediaMetadataFilter';

export const NO_METADATA_HANDLERS = {
  MESSAGE: 'message',
  DISABLE: 'disable',
};

export const DISPLAY_TYPES = {
  DROPDOWN: 'dropdown',
  LIST: 'list',
};

const MediaMetadataFilter = ({
  metadataType,
  filterTargets,
  filterStateAtom,
  allSortedMetadataItems,
  noMetadataToFilterByMessage,
  noMetadataHandler,
  type,
  ...rest
}) => {
  const { handleSelectedIdsChange, metadataItems } = useMediaMetadataFilter({
    metadataType,
    filterTargets,
    filterStateAtom,
    allSortedMetadataItems,
  });
  const [activePane, setActivePane] = useRecoilState(infoPanelActivePaneState);
  const selectedItemIds = useRecoilValue(filterStateAtom);

  const handleChange = useCallback(
    (newSelectedIds, selected) => {
      handleSelectedIdsChange(newSelectedIds, selected);

      if (activePane) {
        setActivePane(panelOptions.FILTERS);
      }
    },
    [handleSelectedIdsChange, activePane, setActivePane],
  );

  return (
    <>
      {metadataItems.length === 0 && noMetadataHandler === NO_METADATA_HANDLERS.MESSAGE && (
        <Typography variant='body2'>
          {noMetadataToFilterByMessage || 'There is nothing to filter by.'}
        </Typography>
      )}
      {(noMetadataHandler === NO_METADATA_HANDLERS.DISABLE || metadataItems.length > 0) &&
        type === DISPLAY_TYPES.DROPDOWN && (
          <MediaMetadataMultiSelect
            {...rest}
            metadataItems={metadataItems}
            value={selectedItemIds}
            onChange={handleChange}
            disableSelectAll={true}
            disabled={!metadataItems.length && noMetadataHandler === NO_METADATA_HANDLERS.DISABLE}
          />
        )}
      {(noMetadataHandler === NO_METADATA_HANDLERS.DISABLE || metadataItems.length > 0) &&
        type === DISPLAY_TYPES.LIST && (
          <MediaMetadataChecklist
            items={metadataItems}
            value={selectedItemIds}
            onChange={handleChange}
          />
        )}
    </>
  );
};

MediaMetadataFilter.defaultProps = {
  noMetadataHandler: NO_METADATA_HANDLERS.MESSAGE,
  type: DISPLAY_TYPES.DROPDOWN,
};

MediaMetadataFilter.propTypes = {
  metadataType: PropTypes.string.isRequired,
  filterTargets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      selectFilterData: PropTypes.func.isRequired,
    }),
  ),
  filterStateAtom: PropTypes.any.isRequired,
  noMetadataToFilterByMessage: PropTypes.string,
  noMetadataHandler: PropTypes.string,
  type: PropTypes.string,
};

export default MediaMetadataFilter;
