import React from 'react';
import { Chip, Grid, GridProps, Tooltip, Typography } from '@mui/material';

import { ImageType, MEDIA_TYPES, MediaType } from '../../../../constants/media';
import {
  HDPhotoIcon,
  InfoOutlinedIcon,
  PanoramaIcon,
  ProjectPhotoIcon,
} from '../../../../theme/icons';

export interface IBulkEditImageTypesProps extends GridProps {
  imageTypesEditing: Record<ImageType, boolean>;
}

const BulkEditImageTypes: React.FC<IBulkEditImageTypesProps> = ({ imageTypesEditing, ...rest }) => {
  const editingMultipleTypes =
    Object.values(imageTypesEditing).filter(editing => editing).length > 1;

  return (
    <Grid container direction='row' spacing={1} {...rest}>
      <Grid item xs='auto'>
        <Typography variant='h5' noWrap>
          Selected image type{editingMultipleTypes ? 's' : ''}:
        </Typography>
      </Grid>
      {imageTypesEditing[MediaType.ProjectPhoto] && (
        <Grid item xs='auto'>
          <Chip
            icon={<ProjectPhotoIcon />}
            label={MEDIA_TYPES[MediaType.ProjectPhoto].label}
            size='small'
            variant='outlined'
          />
        </Grid>
      )}
      {imageTypesEditing[MediaType.HDPhoto] && (
        <Grid item xs='auto'>
          <Chip
            icon={<HDPhotoIcon />}
            label={MEDIA_TYPES[MediaType.HDPhoto].label}
            size='small'
            variant='outlined'
          />
        </Grid>
      )}
      {imageTypesEditing[MediaType.PanoramicPhoto] && (
        <Grid item xs='auto'>
          <Chip
            icon={<PanoramaIcon />}
            label={MEDIA_TYPES[MediaType.PanoramicPhoto].label}
            size='small'
            variant='outlined'
          />
        </Grid>
      )}
      <Grid item xs='auto'>
        <Tooltip title='Editing for certain fields may be disabled based on selected image type(s)'>
          <InfoOutlinedIcon color='action' />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default BulkEditImageTypes;
