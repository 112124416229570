import React from 'react';
import { SvgIcon } from '@mui/material';

const ThreeVROutlinedIcon = props => (
  <SvgIcon {...props}>
    <path
      d='m1.08,13.45c0,2.14,3.1,4.13,7.54,4.83l.63.1v1.7l1.96-1.96-1.96-1.96v1.38l-.88-.16c-3.74-.66-6.45-2.32-6.45-3.92,0-1.19,1.53-2.46,3.98-3.31l.25-.07v-.87c-3.12.95-5.07,2.57-5.07,4.26Z'
      fill='none'
    />
    <path
      d='m18.06,10.12c2.5.86,4.02,2.13,4.02,3.32,0,1.26-1.73,2.61-4.4,3.45-.16.05-.27.2-.27.38,0,.25.21.42.41.42.04,0,.07,0,.11-.02,3.08-.96,4.98-2.58,4.98-4.23s-1.92-3.28-5.01-4.24v.87l.15.04Z'
      fill='none'
    />
    <polygon points='12.03 4.23 8.01 6.55 12.03 8.88 16.06 6.55 12.03 4.23' fill='none' />
    <path d='m5.9,10.14c-2.46.85-3.98,2.12-3.98,3.31,0,1.61,2.71,3.26,6.45,3.92l.88.16v-1.38l1.96,1.96-1.96,1.96v-1.7l-.63-.1c-4.44-.7-7.54-2.68-7.54-4.83,0-1.69,1.95-3.31,5.07-4.26v-.79h0C2.67,9.41.33,11.29.33,13.45c0,2.61,3.43,4.82,8.17,5.57v1.47c0,.35.29.59.58.59.14,0,.29-.05.41-.18l2.36-2.36c.23-.23.23-.6,0-.83l-2.36-2.36c-.12-.12-.26-.17-.4-.17-.3,0-.59.23-.59.59v.88c-3.68-.65-5.83-2.22-5.83-3.19,0-.73,1.25-1.83,3.48-2.6h0v-.78l-.25.07Z' />
    <path d='m17.91,8.42v.79c3.09.96,5.01,2.56,5.01,4.24s-1.91,3.28-4.98,4.23c-.04.01-.07.02-.11.02-.2,0-.41-.17-.41-.42,0-.18.11-.33.27-.38,2.67-.83,4.4-2.19,4.4-3.45,0-1.19-1.53-2.46-4.02-3.32l-.15-.04v.79c2.19.77,3.42,1.85,3.42,2.58,0,.77-1.4,1.96-3.87,2.73-.48.15-.79.6-.79,1.1,0,.66.54,1.17,1.16,1.17.11,0,.22-.02.34-.05,3.3-1.03,5.51-2.86,5.51-4.95s-2.32-4.01-5.76-5.03Z' />
    <path d='m17.6,6.02l-5.26-3.04c-.19-.11-.43-.11-.62,0l-5.26,3.04c-.19.11-.31.31-.31.54v6.08c0,.22.12.43.31.54l5.26,3.04c.1.05.2.08.31.08s.21-.03.31-.08l5.26-3.04c.19-.11.31-.31.31-.54v-6.08c0-.22-.12-.43-.31-.54Zm-6.19,8.58l-4.03-2.32v-4.65l.52.3.8.46,2.71,1.56v4.65Zm.62-5.72l-4.03-2.32,4.03-2.32,4.03,2.32-4.03,2.32Zm4.64,1.63v1.77l-4.03,2.32v-4.65l2.15-1.24,1.21-.7.66-.38v2.88Z' />
  </SvgIcon>
);

export default ThreeVROutlinedIcon;
