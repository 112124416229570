import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, debounce } from '@mui/material';

import IdsFormAutocompleteField from '../../../ids-forms/IdsFormAutocompleteField';
import MultiValueAutocompleteOption from '../../../ids-forms/IdsFormAutocompleteField/MultiValueAutocompleteOption';

import useLocationMapMetadataOptions from '../../../../hooks/useLocationMapMetadataOptions';
import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../constants/media';

import { labelColor } from './EOIInfoDetails';

const EOIInfoTags = ({ canModify, updateTags, isEditMode }) => {
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const options = useLocationMapMetadataOptions(
    MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
  );

  const handleOnClose = async value => {
    await updateTags({ tags: value });
    setFieldValue('tags', value);
    setIsValueChanged(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(debounce(handleOnClose, 500), [updateTags]);

  return (
    <Paper>
      <Box p={2}>
        <Box color={labelColor} textAlign='left'>
          Tags
        </Box>
        <IdsFormAutocompleteField
          disabled={!canModify}
          name='tags'
          options={options}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id}
          noOptionsText='No tags found'
          renderOption={(props, o, { selected }) => (
            <MultiValueAutocompleteOption
              label={o.name}
              selected={selected}
              {...props}
              key={o.id}
            />
          )}
          disableCloseOnSelect
          multiple
          limitTags={4}
          onChange={(event, value, reason) => {
            setIsValueChanged(true);

            /**
             * Trigger the update mutation if:
             * - the form not in edit mode
             * - and all values were deleted by "clear button"
             * - or a value was deleted by "remove option button" if options menu not opened
             */
            if (
              !isEditMode &&
              (reason === 'clear' || (reason === 'removeOption' && !isPopupOpened))
            ) {
              debouncedHandler(value);
            }
          }}
          onOpen={() => setIsPopupOpened(true)}
          onClose={() => {
            setIsPopupOpened(false);

            /**
             * Trigger the update mutation to save all changes before closing options menu
             */
            if (!isEditMode && isValueChanged) {
              debouncedHandler(values.tags);
            }
          }}
        />
      </Box>
    </Paper>
  );
};

EOIInfoTags.propTypes = {
  canModify: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  updateTags: PropTypes.func.isRequired,
};

export default EOIInfoTags;
