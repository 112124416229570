import React, { useMemo } from 'react';
import { gql } from 'graphql-request';
import { capitalCase } from 'change-case';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Alert,
  Divider,
  Grid,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';

import { useTaskResponseKeys } from '../../../../../../../services/TaskResponsesService';
import AssignmentEntityAccordionSummary from '../../../../AssignmentEntityAccordion';
import UserChip from '../../../../../../../components/entity-chips/UserChip';
import { IMediaMetadata } from '../../../../../../../constants/media';
import useOrgGraphQuery from '../../../../../../../hooks/useOrgGraphQuery';
import { TaskResponseProvider } from '../../../../../../../context/TaskResponseContext';

import Actions from './Actions';
import Answer, { AnswerFrag, IAnswerFragType } from './Answer';
import TaskResponseStatusChip from './TaskResponseStatusChip';
import styles from './TaskResponse.module.css';

export interface ITaskResponseHeaderFragType {
  id: string;
  label?: string;
  updatedAt?: any;
  completed?: boolean;
}

export const TaskResponseHeaderFrag = gql`
  fragment TaskResponseHeaderFrag on AssignmentTaskResponse {
    id
    label
    updatedAt
    completed
  }
`;

export interface ITaskResponseDetailQueryType {
  assignmentTaskResponse?: {
    guid?: string;
    taskId?: string;
    assignmentResponseId?: string;
    task: { repeatableLabel?: string; defaultMetadata: IMediaMetadata[] };
    metadata?: {
      id: string;
      type?: string;
      value?: string;
    }[];
    target?: {
      targetType: string;
      coordinates?: {
        latitude?: number;
        longitude?: number;
      };
      objectMappingTree?: {
        title?: string;
      };
    };
    assignmentAnswers?: {
      edges?: Array<{
        node?: {
          id: string;
          questionId?: string;
        } & IAnswerFragType;
      }>;
    };
    creator?: {
      id: string;
      firstName?: string;
      lastName?: string;
    };
    user?: {
      id: string;
      firstName?: string;
      lastName?: string;
    };
  };
}

const TaskResponseDetailQuery = gql`
  query TaskResponseDetailQuery($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentTaskResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      task {
        repeatableLabel
        defaultMetadata {
          id
          type
          value
        }
      }
      guid
      taskId
      assignmentResponseId
      metadata {
        id
        type
        value
      }
      target {
        targetType
        coordinates {
          latitude
          longitude
        }
        objectMappingTree {
          title
        }
      }
      assignmentAnswers {
        edges {
          node {
            id
            questionId
            ...AnswerFrag
          }
        }
      }
      creator {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
  ${AnswerFrag}
`;

const sx = {
  summaryExpanded: (theme: any) => ({
    backgroundColor: theme.palette.background.dark,
  }),
};

export interface IDataSectionProps extends Omit<StackProps, 'title' | 'children'> {
  title: React.ReactNode;
  children: React.ReactNode;
}

export const DataSection: React.FC<IDataSectionProps> = ({ title, children, ...rest }) => (
  <Stack direction='column' spacing={0.5} {...rest}>
    <Typography variant='h6'>{title}</Typography>
    {children}
  </Stack>
);

export interface IDataAttributeProps {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const DataAttribute: React.FC<IDataAttributeProps> = ({ label, value }) => (
  <Typography variant='body2' color='text.secondary'>
    <strong>{label}:</strong> {value}
  </Typography>
);

export interface ITaskResponseProps extends Omit<AccordionProps, 'children'> {
  taskResponse: ITaskResponseHeaderFragType;
  expanded?: boolean;

  // TODO: Too many components in tree, insanely hard to track final type
  // Rework this somehow...
  task: any;
}

const TaskResponse: React.FC<ITaskResponseProps> = ({
  taskResponse: taskResponseHeader,
  task,
  expanded,
  ...rest
}) => {
  const taskResponseKeys = useTaskResponseKeys();

  const { data: taskResponseDetail } = useOrgGraphQuery(
    [...taskResponseKeys.detail(taskResponseHeader.id)],
    TaskResponseDetailQuery,
    { id: taskResponseHeader.id },
    { enabled: !!taskResponseHeader && expanded },
  );

  const taskResponse = useMemo(
    () => ({
      ...taskResponseHeader,
      ...taskResponseDetail?.assignmentTaskResponse,
    }),
    [taskResponseHeader, taskResponseDetail],
  );

  const { updatedAt, metadata, target, completed, label, creator, user } = taskResponse;
  const { assignmentQuestions } = task;

  const groupedAnswers = useMemo(() => {
    if (!taskResponse?.assignmentAnswers) {
      return;
    }

    let unassignedAnswers = taskResponse.assignmentAnswers.edges.map((e: any) => e.node);

    return task.assignmentQuestions?.edges.reduce((result: any, edge: any) => {
      const question = edge.node;

      result[question.id] = {
        question,
        answers: unassignedAnswers.filter((a: any) => a.questionId === question.id),
      };

      // Remove assigned answers to avoid refiltering them
      unassignedAnswers = unassignedAnswers.filter(
        (ua: any) => !result[question.id].answers.some((a: any) => a.id === ua.id),
      );
      return result;
    }, {});
  }, [taskResponse, task]);

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      {...rest}
    >
      <AssignmentEntityAccordionSummary
        primaryText={label || 'Response'}
        secondaryText={new Date(updatedAt).toLocaleString()}
        secondaryContent={<TaskResponseStatusChip completed={completed} />}
        sx={theme => ({ ...(expanded && sx.summaryExpanded(theme)) })}
      />
      <AccordionDetails>
        {taskResponseDetail && (
          <TaskResponseProvider taskResponse={taskResponse} groupedAnswers={groupedAnswers}>
            <Grid container direction='column'>
              <Grid item xs='auto' mb={1} textAlign='right'>
                <Actions groupedAnswers={groupedAnswers} taskResponse={taskResponse} />
              </Grid>
              <Grid item xs='auto' container direction='row'>
                <Grid item xs='auto' className={styles.infoContainer}>
                  <Stack
                    direction='column'
                    spacing={2}
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <DataSection title='Target'>
                      <DataAttribute
                        label={capitalCase(target.targetType)}
                        value={target.objectMappingTree.title}
                      />
                      {target.coordinates?.latitude && (
                        <DataAttribute label='Latitude' value={target.latitude} />
                      )}
                      {target.coordinates?.longitude && (
                        <DataAttribute label='Longitude' value={target.longitude} />
                      )}
                    </DataSection>
                    <DataSection title='Metadata'>
                      <DataAttribute
                        label='Level'
                        value={metadata.find((item: any) => item.type === 'Level')?.value}
                      />
                      <DataAttribute
                        label='Area'
                        value={metadata.find((item: any) => item.type === 'PhotoArea')?.value}
                      />
                    </DataSection>
                    <DataSection title='Created By'>
                      <UserChip
                        userId={creator.id}
                        name={`${creator.firstName} ${creator.lastName}`}
                        className={styles.userChip}
                      />
                    </DataSection>
                    <DataSection title='Last Updated By'>
                      <UserChip
                        userId={user.id}
                        name={`${user.firstName} ${user.lastName}`}
                        className={styles.userChip}
                      />
                    </DataSection>
                  </Stack>
                </Grid>
                <Divider orientation='vertical' flexItem className={styles.divider} />
                <Grid item xs container direction='column'>
                  <Stack direction='column' spacing={2}>
                    {Object.values(groupedAnswers).map((questionAnswers: any, i: number) => (
                      <React.Fragment key={i}>
                        <DataSection
                          title={`${questionAnswers.question.title}${
                            questionAnswers.question.required ? '*' : ''
                          }`}
                          spacing={0}
                        >
                          <Grid container direction='row' spacing={2}>
                            {questionAnswers.answers.length > 0 ? (
                              questionAnswers.answers.map((a: any) => (
                                <Grid key={a.id} item xs>
                                  <Answer answer={a} question={questionAnswers.question} />
                                </Grid>
                              ))
                            ) : (
                              <Grid item xs>
                                <Alert severity='info'>Not answered</Alert>
                              </Grid>
                            )}
                          </Grid>
                        </DataSection>
                        {i !== assignmentQuestions.edges.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </TaskResponseProvider>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TaskResponse;
