export const PROJECT_STATUSES = {
  NEW: 0,
  IN_PROGRESS: 1,
  PUBLISHED: 2,
  COMPLETED: 3,
} as const;

export type ProjectStatusesType = (typeof PROJECT_STATUSES)[keyof typeof PROJECT_STATUSES];

export const PROJECT_STATUSES_STRINGS = {
  NEW: '0',
  IN_PROGRESS: '1',
  PUBLISHED: '2',
  COMPLETED: '3',
} as const;
