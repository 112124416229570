import { useMemo } from 'react';

import { MediaType } from '../constants/media';
import {
  buildRasterOverlayLayer,
  getRasterOverlayLayerId,
} from '../components/mapping/layers/overlays';
import useKeycloak from '../hooks/useKeycloak';
import useFilterContext from '../hooks/useFilterContext';
import { IRasterOverlay } from '../services/LocationService';

/**
 * @TODO: re-use it in LocationMapMediaManager and ImageMarkerForm
 */
const useOrderedRasterOverlays = () => {
  const { useTypeFilteredData, getTypeData, useFilters } = useFilterContext();
  const activeFilters = useFilters();
  const { token: keycloakToken } = useKeycloak();

  const filteredRasterOverlays = useTypeFilteredData<IRasterOverlay>(MediaType.RasterOverlay);
  const rasterOverlays = getTypeData<IRasterOverlay>(MediaType.RasterOverlay);

  return useMemo(() => {
    return rasterOverlays.map(o => {
      const filterPosition = activeFilters?.findIndex((f: any) => f.name === o.id);

      return {
        id: getRasterOverlayLayerId(o.id),
        layer: buildRasterOverlayLayer(
          o,
          keycloakToken,
          {
            // Only show the layer if toggled on
            visible: filteredRasterOverlays.some((fo: IRasterOverlay) => fo.id === o.id),
          },
          null,
          {
            // Show most recently turned on overlays above other overlays
            ...(filterPosition >= 0 && { position: filterPosition }),
          },
        ),
      };
    });
  }, [filteredRasterOverlays, activeFilters, keycloakToken, rasterOverlays]);
};

export { useOrderedRasterOverlays };
