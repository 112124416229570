import * as Yup from 'yup';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../../constants/media';

export const panoramicPhotoValidation = {
  location_id: Yup.string().nullable().required('Location is required'),
  project_id: Yup.string().nullable().required('Project is required'),
  photo_level_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.Level].label} is required`),
  photo_area_id: Yup.string().nullable(),
  // photo_tags: Yup.array(Yup.string()),
};

export const panoramicPhotoValidationSchema = Yup.object().shape(panoramicPhotoValidation);
