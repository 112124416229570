import React from 'react';
import { ListItemText } from '@mui/material';

import { useRecoilValue } from 'recoil';

import IdsListItem from '../../../../components/ids-lists/IdsListItem';

import { getOrgProgramRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';
import { ITenantProgramItem } from '../../../../services/TenantService';

export interface ITenantProgramListItemProps {
  programItem: ITenantProgramItem;
}

const TenantProgramListItem: React.FC<ITenantProgramListItemProps> = ({ programItem }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { id, name, organization } = programItem.node;

  return (
    <IdsListItem
      key={id}
      to={getOrgProgramRoute({
        subdomain: activeTenant?.subdomain,
        orgId: organization.id,
        programId: id,
      })}
    >
      <ListItemText primary={name} primaryTypographyProps={{ variant: 'h6', noWrap: true }} />
      <ListItemText secondary={organization.name} secondaryTypographyProps={{ noWrap: true }} />
    </IdsListItem>
  );
};

export default TenantProgramListItem;
