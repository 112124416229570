import React, { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';

import IdsDialog from '../components/IdsDialog';
import CancelButton from '../components/ids-buttons/CancelButton';

const ConfirmationContext = createContext();

const defaults = {
  title: 'Are you sure?',
  description: 'Are you sure you want to take this action?',
  confirmButtonLabel: 'Yes',
  cancelButtonLabel: 'Cancel',
};

export function ConfirmationProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(defaults.title);
  const [description, setDescription] = useState(defaults.description);
  const [onConfirm, setOnConfirm] = useState();
  const [onCancel, setOnCancel] = useState();
  const [confirmButtonLabel, setConfirmButtonLabel] = useState(defaults.confirmButtonLabel);
  const [confirmButtonProps, setConfirmButtonProps] = useState();
  const [cancelButtonLabel, setCancelButtonLabel] = useState(defaults.cancelButtonLabel);

  const handleConfirmation = useCallback(() => {
    setOpen(false);

    if (onConfirm) {
      onConfirm();
    }
  }, [setOpen, onConfirm]);

  const handleCancellation = useCallback(() => {
    setOpen(false);

    if (onCancel) {
      onCancel();
    }
  }, [setOpen, onCancel]);

  const requestConfirmation = useCallback(
    ({
      title,
      description,
      confirmButtonLabel,
      confirmButtonProps,
      cancelButtonLabel,
      onConfirm,
      onCancel,
    }) => {
      setTitle(title || defaults.title);
      setDescription(description || defaults.description);
      setOnConfirm(() => onConfirm);
      setOnCancel(() => onCancel);
      setConfirmButtonLabel(confirmButtonLabel || defaults.confirmButtonLabel);
      setConfirmButtonProps(confirmButtonProps);
      setCancelButtonLabel(cancelButtonLabel || defaults.cancelButtonLabel);
      setOpen(true);
    },
    [setTitle, setDescription, setOnConfirm, setOnCancel, setOpen],
  );

  return (
    <ConfirmationContext.Provider
      value={{
        requestConfirmation,
      }}
    >
      <IdsDialog open={open} onClose={handleCancellation} title={title} dialogKey='confirm'>
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleCancellation} label={cancelButtonLabel} />
          <Button
            color='primary'
            {...confirmButtonProps}
            onClick={handleConfirmation}
            variant='contained'
          >
            {confirmButtonLabel}
          </Button>
        </DialogActions>
      </IdsDialog>
      {children}
    </ConfirmationContext.Provider>
  );
}

ConfirmationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ConfirmationConsumer = ConfirmationContext.Consumer;

export default ConfirmationContext;
