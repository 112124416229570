import { useCallback, useEffect, useState } from 'react';

const useCustomFieldFilterVisibility = (customFieldTypes: string[]) => {
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    // Update map when custom field types change
    setVisibilityMap(prevMap => {
      return customFieldTypes.reduce((map, type) => {
        map[type] = prevMap[type] ?? false; // maintain exsiting visibility value if type existed before
        return map;
      }, {} as Record<string, boolean>);
    });
  }, [customFieldTypes]);

  const handleVisibilityChange = useCallback(
    (customFieldType: string, visible: boolean) => {
      if (!customFieldTypes.includes(customFieldType)) {
        throw new Error(
          `Cannot handle custom field filter visibility change for unknown type: ${customFieldType}`,
        );
      }

      if (visibilityMap[customFieldType] === visible) return;

      // Visibility changed, update map
      setVisibilityMap(prevMap => ({ ...prevMap, [customFieldType]: visible }));
    },
    [customFieldTypes, visibilityMap],
  );

  const isFilterVisible = useCallback(
    (customFieldType: string) => {
      return !!visibilityMap[customFieldType];
    },
    [visibilityMap],
  );

  const isSomeFilterVisible = useCallback(() => {
    return Object.values(visibilityMap).includes(true);
  }, [visibilityMap]);

  return {
    handleVisibilityChange,
    isFilterVisible,
    isSomeFilterVisible,
  };
};

export default useCustomFieldFilterVisibility;
