import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import MapContextMenu from '../components/mapping/MapContextMenu';

const MapContextMenuContext = createContext();

export function MapContextMenuProvider({ children }) {
  const menuItemsObj = useRef({});
  const [menuItems, setMenuItems] = useState([]);
  const [contextMenuEnabled, setContextMenuEnabled] = useState(true);

  const setMenuItem = useCallback(
    (id, menuItem) => {
      if (!menuItem) {
        if (id) {
          if (menuItemsObj.current[id]) {
            delete menuItemsObj.current[id]; // remove the menu item
          }
        }
        return;
      }

      if (!id || !menuItem.label || !menuItem.onClick) return;
      menuItemsObj.current[id] = menuItem;
      setMenuItems(Object.values(menuItemsObj.current));
    },
    [setMenuItems],
  );

  const contextValue = useMemo(
    () => ({
      setMenuItem,
      setContextMenuEnabled,
      contextMenuEnabled,
    }),
    [setMenuItem, setContextMenuEnabled, contextMenuEnabled],
  );

  return (
    <MapContextMenuContext.Provider value={contextValue}>
      {contextMenuEnabled && <MapContextMenu menuItems={menuItems} />}
      {children}
    </MapContextMenuContext.Provider>
  );
}

MapContextMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const MapContextMenuConsumer = MapContextMenuContext.Consumer;

export default MapContextMenuContext;
