import { LEGACY_MEDIA_TYPES, MEDIA_TYPES, MediaType } from '../constants/media';

// Example urn: urn:immersiondata:media:panorama:199650
export const getMediaIdFromUrn = (urn: string) => {
  if (!urn) {
    return null;
  }

  const parts = urn.split(':');
  return parts[parts.length - 1]; // id is last part
};

export const getUrnType = (urn: string) => {
  if (!urn) {
    return null;
  }

  const parts = urn.split(':');
  return parts[parts.length - 2]; // type is second to last part
};

export const URN_TO_MEDIA_TYPE = {
  [LEGACY_MEDIA_TYPES.userphoto]: LEGACY_MEDIA_TYPES.userphoto,

  [MEDIA_TYPES[MediaType.ProjectPhoto].urnType]: MEDIA_TYPES[MediaType.ProjectPhoto].type,
  [MEDIA_TYPES[MediaType.HDPhoto].urnType]: MEDIA_TYPES[MediaType.HDPhoto].type,
  [MEDIA_TYPES[MediaType.PanoramicPhoto].urnType]: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
  [MEDIA_TYPES[MediaType.RasterOverlay].urnType]: MEDIA_TYPES[MediaType.RasterOverlay].type,
  [MEDIA_TYPES[MediaType.VectorOverlay].urnType]: MEDIA_TYPES[MediaType.VectorOverlay].type,
  [MEDIA_TYPES[MediaType.PointOfInterest].urnType]: MEDIA_TYPES[MediaType.PointOfInterest].type,
  [MEDIA_TYPES[MediaType.AreaOfInterest].urnType]: MEDIA_TYPES[MediaType.AreaOfInterest].type,
  [MEDIA_TYPES[MediaType.Asset].urnType]: MEDIA_TYPES[MediaType.Asset].type,
};

export const getMediaTypeFromUrn = (urn: string) => {
  if (!urn) return null;

  const urnType = getUrnType(urn);

  return urnType && urnType !== 'embedmodel' ? URN_TO_MEDIA_TYPE[urnType] : null;
};

export const getUrnForMedia = (id: string, mediaType: MediaType) => {
  if (!id || !mediaType) return;

  return `urn:immersiondata:media:${MEDIA_TYPES[mediaType].urnType}:${id}`;
};

const imageTypes: string[] = [MediaType.ProjectPhoto, MediaType.HDPhoto, MediaType.PanoramicPhoto];

export const isMediaAnImage = (mediaType: MediaType | LEGACY_MEDIA_TYPES) => {
  return imageTypes.includes(mediaType);
};
