import { IImageAvailableCustomFieldMetadata } from '../../../../../services/CustomFieldsService';

import { IFormValues } from './types';

/**
 * Iteration over available custom fields and checking required validation
 * @param availableCustomFieldMetadata - List of available custom fields for media
 * @param initialValues - Initial values
 */
export const createValidate =
  (
    availableCustomFieldMetadata: IImageAvailableCustomFieldMetadata[],
    initialValues: IFormValues,
  ) =>
  (values: IFormValues) => {
    const customFieldsErrors: Record<string, any> = {};
    const restFieldsErrors: Record<string, any> = {};

    /**
     * restFields - it includes description field and legacy metadata fields
     * such as area, category, photo type, level.
     */
    const { customFields, ...restFields } = values;

    for (const id in customFields) {
      const field = availableCustomFieldMetadata.find(it => it.id === id);
      const value = customFields[id];

      if (field?.required) {
        if (Array.isArray(value) && !value.length) {
          customFieldsErrors[id] = 'Value required';
          break;
        }

        if (!value) {
          customFieldsErrors[id] = 'Value required';
        }
      }
    }

    /**
     * Description and legacy metadata fields cannot be cleared due to
     * limitation of mutations that handle image update. As for now, the fields
     * can be added or updated only.
     *
     * @TODO: when possible, update the validation to allow fields clearing.
     */
    for (const id in restFields) {
      const value = restFields[id as keyof typeof restFields];

      // Take in account boolean values
      if (!value && value !== false && initialValues[id as keyof typeof restFields]) {
        restFieldsErrors[id] = 'Value required';
      }
    }

    return {
      ...(Object.keys(customFieldsErrors).length && { customFields: customFieldsErrors }),
      ...restFieldsErrors,
    };
  };
