import { useEffect } from 'react';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { RequestDocument } from 'graphql-request';

import getGraphQLClient from '../graphQL/config';
import airbrake from '../utils/airbrake';

import usePrevious from './usePrevious';

export type UseGraphQueryOptions<TQueryFnData = any, TError = any, TData = TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData>,
  'queryKey' | 'queryFn'
>;

export const useGraphQuery = <TQueryFnData = any, TError = any, TData = TQueryFnData>(
  queryKey: QueryKey,
  query: RequestDocument,
  graphVariables?: any,
  queryOptions: UseGraphQueryOptions<TQueryFnData, TError, TData> = {},
  /** If not disabled, errors will be automatically logged to airbrake. */
  disableErrorLogging = false,
) => {
  const result = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    async () => {
      return await getGraphQLClient().request(query, graphVariables);
    },
    {
      // 1 year, prevent refetching every time window refocuses
      // refetchOnWindowFocus: false and staleTime: 'Infinity' did not work
      staleTime: 3.156e10,
      ...queryOptions,
    },
  );

  const prevError = usePrevious(result.error);

  /** Automatically log GQL errors to airbrake for increased visibility, especially for errors that may not be caught by the BE airbrake or not properly handled on the FE. */
  useEffect(() => {
    if (disableErrorLogging || !result.error || result.error === prevError) return;

    airbrake.notify({
      error: `GraphQL query error: ${JSON.stringify(result.error)}`,
    });
  }, [result.error, prevError, disableErrorLogging]);

  return result;
};

export default useGraphQuery;
