import Page from '../../components/Page';
import ComingSoonRoute from '../../components/ComingSoonRoute';

function Routes() {
  return (
    <Page title='Routes'>
      <ComingSoonRoute />
    </Page>
  );
}

export default Routes;
