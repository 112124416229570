import { useCallback, useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';

import moment from 'moment';

import { startDateState, endDateState } from '../../atoms/timeline';
import { MEDIA_TYPES, MediaType } from '../../constants/media';
import useFilterContext from '../useFilterContext';
import usePrevious from '../usePrevious';
import useUrlState from '../useUrlState';
import { FILTER_STATE_KEYS } from '../../constants/urlStateKeys';

import useFilterTargetDataListeners from './useFilterTargetDataListeners';

const FILTER_NAME = 'Timeline';

const FILTER_TARGETS = [
  {
    type: MEDIA_TYPES[MediaType.ProjectPhoto].type,
    selectFilterData: item => item.node.capturedAt,
  },
  {
    type: MEDIA_TYPES[MediaType.HDPhoto].type,
    selectFilterData: item => item.node.capturedAt,
  },
  {
    type: MEDIA_TYPES[MediaType.PanoramicPhoto].type,
    selectFilterData: item => item.node.capturedAt,
  },
  {
    type: MEDIA_TYPES[MediaType.Video].type,
    selectFilterData: item => item.createdAt,
  },
  {
    type: MEDIA_TYPES[MediaType.ThreeDModel].type,
    selectFilterData: item => item.createdAt,
  },
  {
    type: MEDIA_TYPES[MediaType.ThreeDVR].type,
    selectFilterData: item => item.createdAt,
  },
  {
    type: MEDIA_TYPES[MediaType.PointOfInterest].type,
    selectFilterData: item => item.node.createdAt,
  },
  {
    type: MEDIA_TYPES[MediaType.AreaOfInterest].type,
    selectFilterData: item => item.node.createdAt,
  },
];

const useTimelineFilter = onDestroy => {
  const { addFilter, removeFilter } = useFilterContext();
  const [dates, setDates] = useState([]);
  const prevDates = usePrevious(dates);
  const [startDate, setStartDate] = useRecoilState(startDateState);
  const [endDate, setEndDate] = useRecoilState(endDateState);
  const { setUrlObjectState } = useUrlState();

  const handleDataChange = useCallback(
    (data, selectDate) => {
      setDates(prevDates => {
        const newDates = data.map(d => selectDate(d));

        // Need to combine new dates with previous dates since data is only for one of the filter target types
        const uniqueDates = [...new Set([...prevDates, ...newDates])];
        uniqueDates.sort((a, b) => new Date(a) - new Date(b)); // Sort dates in ascending order
        return uniqueDates;
      });
    },
    [setDates],
  );

  useFilterTargetDataListeners({
    filterTargets: FILTER_TARGETS,
    handleDataChange,
  });

  useEffect(() => {
    // First date was selected, update start date to be new first date
    if (
      dates?.length &&
      dates !== prevDates &&
      (!startDate || (prevDates?.length && startDate === prevDates[0]))
    ) {
      setStartDate(dates[0]);
    }
  }, [startDate, setStartDate, dates, prevDates]);

  useEffect(() => {
    // Last date was selected, update end date to be new last date
    if (
      dates?.length &&
      dates !== prevDates &&
      (!endDate || (prevDates?.length && endDate === prevDates[dates.length - 1]))
    ) {
      setEndDate(dates[dates.length - 1]);
    }
  }, [endDate, setEndDate, dates, prevDates]);

  const filterByDateRange = useCallback(
    (newStartDate, newEndDate, loadedFromUrl = false) => {
      const onRemove = () => {
        setStartDate(dates[0]);
        setEndDate(dates[dates.length - 1]);
        setUrlObjectState(FILTER_STATE_KEYS.TIMELINE, null);
      };

      // All dates are selected
      if (newStartDate === dates[0] && newEndDate === dates[dates.length - 1]) {
        if (newStartDate !== startDate || newEndDate !== endDate) {
          // Date range changed
          removeFilter(FILTER_NAME);
          onRemove();
        }
        return;
      }

      const newStartMoment = moment(newStartDate);
      const newEndMoment = moment(newEndDate);

      const dateFormat = 'MM/DD/YYYY';

      addFilter(
        {
          name: FILTER_NAME,
          label: 'Timeline',
          selected: `
            ${newStartMoment.format(dateFormat)} -
            ${newEndMoment.format(dateFormat)}`,
          filterItem: d =>
            moment(d).isSameOrAfter(newStartMoment) && moment(d).isSameOrBefore(newEndMoment),
          onRemove,
          onDestroy: () => {
            // reset recoil state when filter is destroyed
            setStartDate(null);
            setEndDate(null);

            if (onDestroy) {
              onDestroy();
            }
          },
          targets: FILTER_TARGETS,
        },
        {
          loadedFromUrl,
          filteringDown:
            moment(startDate).isSameOrBefore(newStartMoment) &&
            moment(endDate).isSameOrAfter(newEndMoment),
        },
      );

      setStartDate(newStartDate);
      setEndDate(newEndDate);

      setUrlObjectState(FILTER_STATE_KEYS.TIMELINE, {
        s: newStartDate,
        e: newEndDate,
      });
    },
    [
      addFilter,
      removeFilter,
      dates,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      onDestroy,
      setUrlObjectState,
    ],
  );

  return {
    dates,
    startDate,
    endDate,
    filterByDateRange,
  };
};

export const useRestoreTimelineFilterFromUrl = () => {
  const { filterByDateRange } = useTimelineFilter();
  const { getUrlObjectState, setUrlObjectState } = useUrlState();

  useEffect(() => {
    const urlState = getUrlObjectState(FILTER_STATE_KEYS.TIMELINE);
    const startDate = urlState?.s;
    const endDate = urlState?.e;

    const startMoment = moment(startDate);
    const startDateValid = startDate && startMoment.isValid();

    const endMoment = moment(endDate);
    const endDateValid = endDate && endMoment.isValid();

    // Verify that the dates are valid and that
    // start date is the same as or before the end date
    if (startDateValid && endDateValid && startMoment.isSameOrBefore(endMoment)) {
      filterByDateRange(urlState.s, urlState.e, true);
    } else {
      setUrlObjectState(FILTER_STATE_KEYS.TIMELINE, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useTimelineFilter;
