import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import Page from '../../components/Page';

import style from './Error.module.css';

const styles = {
  root: theme => ({
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    margin: theme.spacing(12),
  }),
};

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Page title='Error'>
      <Box sx={styles.root} className={style.root}>
        <Container maxWidth={false} className={style.container}>
          <Box>
            <img className={style.image} alt='There was an error' src='/static/binoculars.png' />
            <Typography className={style.title}>Sorry, that page wasn't found.</Typography>
            <Box className={style.buttonContainer}>
              {window.history.state && window.history.state.idx > 0 && (
                <Button onClick={() => navigate(-2)} className={style.button2}>
                  Go Back to Previous Page
                </Button>
              )}
              <Button onClick={() => navigate('/')} className={style.button}>
                Go Back to Homepage
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ErrorPage;
