import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { v1 } from 'uuid';

import * as Yup from 'yup';

import IdsCreateForm, {
  IIdsCreateFormProps,
} from '../../../../../../../../../../components/ids-forms/IdsCreateForm';
import EditableMultiChoiceAnswer from '../../../../TaskResponse/Actions/EditTaskResponseDialog/EditTaskResponseForm/EditableMultiChoiceAnswer';
import EditableImageAnswer from '../../../../TaskResponse/Actions/EditTaskResponseDialog/EditTaskResponseForm/EditableImageAnswer';
import EditableAnswer from '../../../../TaskResponse/Actions/EditTaskResponseDialog/EditTaskResponseForm/EditableAnswer';
import { useCreateTaskResponse } from '../../../../../../../../../../services/TaskResponsesService';
import { EDITABLE_ANSWER_TYPES } from '../../../../editableAnswerTypes';
import { isImageQuestion } from '../../../../../../../../../../constants/assignments';
import { IAssignmentQuestion } from '../../../../TaskResponse/Actions/EditTaskResponseDialog/EditTaskResponseForm/types';
import { IMediaMetadata } from '../../../../../../../../../../constants/media';
import EditableBarcodeAnswer from '../../../../TaskResponse/Actions/EditTaskResponseDialog/EditTaskResponseForm/EditableBarcodeAnswer';
import { stringToRegex } from '../../../../../../../../../../utils/helpers';

type NewAnswer = {
  question_id: string;
  value?: string;
  uuid?: string;
};

export interface ICreateTaskResponseFormProps extends Pick<IIdsCreateFormProps, 'onCancel'> {
  taskId: string;
  questions: {
    node: IAssignmentQuestion;
  }[];
  metadata: {
    id: string;
    type: string;
    value: string;
  }[];
  target: {
    id: string;
    targetType: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
  assignmentResponseId: string;
  defaultTaskMetadata: IMediaMetadata[];
}

// @TODO: update if BE support is added for task responses to have all metadata types.
const supportedMetadataTypesMap: Record<string, string> = {
  Level: 'level',
  PhotoArea: 'photo_area',
};

const errorHandler = () => 'Failed to create task response';

const CreateTaskResponseForm: React.FC<ICreateTaskResponseFormProps> = ({
  onCancel,
  questions,
  assignmentResponseId,
  taskId,
  metadata,
  target,
  defaultTaskMetadata,
}) => {
  const createTaskResponseMutation = useCreateTaskResponse(assignmentResponseId);

  const onSubmit = useCallback(
    async (values: Record<string, any>) => {
      const answers: NewAnswer[] = [];
      for (const id in values) {
        // Skip empty answers.
        if (!values[id]) {
          continue;
        }

        const question = questions.find(q => q.node.id === id);
        const imageQuestion = isImageQuestion(question!.node.questionType);
        const answerFieldName = imageQuestion ? 'uuid' : 'value';

        if (Array.isArray(values[id])) {
          // Array answer should be split into single answers.
          values[id].forEach((value: any) => {
            if (value) {
              answers.push({
                question_id: id,
                [answerFieldName]: imageQuestion ? value.uuid : value,
              });
            }
          });
        } else {
          // All other answers.
          answers.push({
            question_id: id,
            [answerFieldName]: imageQuestion ? values[id].uuid : values[id],
          });
        }
      }

      await createTaskResponseMutation.mutateAsync({
        data: {
          task_id: taskId,
          // @TODO: When possible, refactor to use v4.
          guid: v1(),
          answers,
          metadata: metadata
            // Filter out default metadata types that are not supported on the task response object
            .filter(item => !!supportedMetadataTypesMap[item.type])
            .map(item => ({
              type: supportedMetadataTypesMap[item.type],
              value: item.value,
            })),
          target: {
            type: target.targetType,
            position: {
              // @TODO: remove these numbers once target support added.
              latitude: target.coordinates?.latitude ?? 95.123123123123,
              longitude: target.coordinates?.longitude ?? -38.123123123123,
            },
          },
        },
      });

      if (onCancel) {
        onCancel();
      }
    },
    [
      createTaskResponseMutation,
      onCancel,
      metadata,
      questions,
      taskId,
      target.targetType,
      target.coordinates?.longitude,
      target.coordinates?.latitude,
    ],
  );

  const { initialValues, formFields, validationSchema } = useMemo(() => {
    const initialValues: Record<string, any> = {};
    const formFields: React.ReactNode[] = [];
    const validationShape: Record<string, any> = {};

    questions.forEach(question => {
      if (!(question.node.questionType in EDITABLE_ANSWER_TYPES)) {
        return;
      }

      let answer;
      if (question.node.questionType === EDITABLE_ANSWER_TYPES.multiple_choice) {
        initialValues[question.node.id] = [];
        answer = <EditableMultiChoiceAnswer key={question.node.id} question={question.node} />;
      } else if (question.node.questionType === EDITABLE_ANSWER_TYPES.barcode) {
        initialValues[question.node.id] = question.node.repeatable ? [] : '';
        answer = <EditableBarcodeAnswer key={question.node.id} question={question.node} />;

        if (question.node.extra.validation?.regex) {
          const { regex, errorMessage } = question.node.extra.validation;
          const stringRegex = Yup.string().matches(stringToRegex(regex), errorMessage);

          /**
           * @TODO: implement validation for the whole form.
           */
          validationShape[question.node.id] = question.node.repeatable
            ? Yup.array().of(stringRegex)
            : stringRegex;
        }
      } else if (isImageQuestion(question.node.questionType)) {
        initialValues[question.node.id] = question.node.repeatable ? [] : '';
        answer = (
          <EditableImageAnswer
            key={question.node.id}
            question={question.node}
            assignmentResponseId={assignmentResponseId}
            defaultTaskMetadata={defaultTaskMetadata}
          />
        );
      } else {
        initialValues[question.node.id] = '';
        answer = <EditableAnswer key={question.node.id} answer={null} question={question.node} />;
      }

      formFields.push(
        <Box key={question.node.id}>
          <Box>{question.node.title}</Box>
          <Box>{answer}</Box>
        </Box>,
      );
    });

    return {
      initialValues,
      formFields,
      validationSchema: Yup.object().shape(validationShape),
    };
  }, [questions, assignmentResponseId, defaultTaskMetadata]);

  return (
    <IdsCreateForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage='Task response created'
      errorHandler={errorHandler}
    >
      <Box px={3} pb={1} display='flex' flexDirection='column' gap={1}>
        {formFields}
      </Box>
    </IdsCreateForm>
  );
};

export default CreateTaskResponseForm;
