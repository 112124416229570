import * as Yup from 'yup';

import {
  latitudeValidation,
  longitudeValidation,
  altitudeValidation,
  headingValidation,
} from '../../../../../../field-validation/geospatial';

import { MEDIA_METADATA_TYPES, MediaMetadataType } from '../../../../../../constants/media';

export const panoramicPhotoNoMarkerValidation = {
  location_id: Yup.string().nullable().required('Location is required'),
  project_id: Yup.string().nullable().required('Project is required'),
  description: Yup.string().required('Description is required'),
  captured_at: Yup.date()
    .typeError('Must be a valid date')
    .nullable()
    .required('Captured date is required'),
  photo_level_id: Yup.string()
    .nullable()
    .required(`${MEDIA_METADATA_TYPES[MediaMetadataType.Level].label} is required`),
  photo_area_id: Yup.string().nullable(),
  // photo_tags: Yup.array(Yup.string()),
};

export const panoramicPhotoNoMarkerValidationSchema = Yup.object().shape(
  panoramicPhotoNoMarkerValidation,
);

export const panoramicPhotoValidation = {
  ...panoramicPhotoNoMarkerValidation,
  latitude: latitudeValidation.required('Latitude is required'),
  longitude: longitudeValidation.required('Longitude is required'),
  altitude: altitudeValidation
    .integer('Must be an integer')
    .min(0, 'Must be greater than or equal to 0')
    .max(1000, 'Must be less than or euqal to 1000'),

  heading: headingValidation.integer('Must be an integer').required('Heading is required'),
};

export const panoramicPhotoValidationSchema = Yup.object().shape(panoramicPhotoValidation);
