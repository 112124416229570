import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRecoilValue } from 'recoil';

import { rgbToHex } from '@mui/material';

import AreaOfInterestForm from '../../AreaOfInterestForm';
import IdsCreateDialogForm from '../../../../ids-forms/IdsCreateDialogForm';
import { activeOrganizationState } from '../../../../../atoms/organizations';
import useLocationMapContext from '../../../../../hooks/useLocationMapContext';
import useFilterContext from '../../../../../hooks/useFilterContext';
import IDS_COLORS from '../../../../../theme/ids-colors';

import {
  MEDIA_TYPES,
  MediaType,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
} from '../../../../../constants/media';
import { useCreateAreaOfInterest } from '../../../../../services/AreaOfInterestService';
import { createAreaOfInterestValidationSchema } from '../../areaOfInterestValidation';

const initialValues = {
  name: '',
  projectId: null,
  levelId: null,
  data: '',
  color: rgbToHex(IDS_COLORS.marker.areaOfInterest),
  flagged: false,
  tags: [],
  area: 0,
  distance: 0,
};

const CreateAreaOfInterestForm = ({
  points,
  distance,
  area,
  center,
  onColorChange,
  onFlaggedChange,
  onSubmitSuccess,
  onCancel,
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const { location, totalAreasOfInterest } = useLocationMapContext();
  const createAOIMutation = useCreateAreaOfInterest(location.id);
  const { setTypeItem } = useFilterContext();

  const projects = useMemo(() => location?.projects?.edges || [], [location]);

  const _initialValues = useMemo(
    () => ({
      ...initialValues,
      name: `Area of Interest ${totalAreasOfInterest + 1}`,
      projectId: projects.length
        ? projects.length === 1
          ? projects[0].node.id // Only 1 project, auto select project
          : // Multiple projects, selected newest one
            projects.reduce((newestProject, p) => {
              if (!newestProject) {
                return p;
              }
              return new Date(p.node.startDate) - new Date(newestProject.node.startDate) > 0
                ? p
                : newestProject;
            }, null).node.id
        : null, // No projects
    }),
    [projects, totalAreasOfInterest],
  );

  const _onSubmit = useCallback(
    async values => {
      const { levelId, tags } = values;

      const result = await createAOIMutation.mutateAsync({
        ...values,
        organizationId: activeOrg.id,
        locationId: location.id,
        metadata: [
          ...tags.map(tagId => ({
            id: tagId,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.PhotoTag].type,
          })),
          {
            id: levelId,
            type: MEDIA_METADATA_TYPES[MediaMetadataType.Level].type,
          },
        ],
        position: center,
        points,
        area: area.toString(),
        distance: distance.toString(),
      });

      const newAOI = result.createAreaOfInterest.areaOfInterest;
      const aoiEdge = { node: newAOI, tooltip: newAOI.name };
      setTypeItem(aoiEdge, MEDIA_TYPES[MediaType.AreaOfInterest].type); // Insert new AOI into filter context

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
    [
      createAOIMutation,
      onSubmitSuccess,
      activeOrg.id,
      location.id,
      points,
      distance,
      area,
      center,
      setTypeItem,
    ],
  );

  return (
    <IdsCreateDialogForm
      initialValues={_initialValues}
      validationSchema={createAreaOfInterestValidationSchema}
      successMessage='Area of interest created'
      errorHandler={() => 'Area of interest could not be created'}
      onSubmit={_onSubmit}
      onCancel={onCancel}
    >
      <AreaOfInterestForm onColorChange={onColorChange} onFlaggedChange={onFlaggedChange} />
    </IdsCreateDialogForm>
  );
};

CreateAreaOfInterestForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  center: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  distance: PropTypes.number.isRequired,
  area: PropTypes.number.isRequired,
  onColorChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
};

export default CreateAreaOfInterestForm;
