import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useGetProjectRoute } from '../../services/ProjectService';
import { activeProjectState } from '../../atoms/projects';
import { activeOrganizationState } from '../../atoms/organizations';

import { getOrgProjectsRoute } from '../../utils/routes';
import { activeTenantState } from '../../atoms/tenants';

import EntityRoute from './EntityRoute';

function ProjectRoute() {
  const [project, setProject] = useRecoilState(activeProjectState);
  const activeTenant = useRecoilValue(activeTenantState);
  const org = useRecoilValue(activeOrganizationState);

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useProject = (id, options) => useGetProjectRoute(id, options);

  return (
    <EntityRoute
      idParam={'projectId'}
      currentEntity={project}
      setEntity={setProject}
      useEntity={useProject}
      entitySelector={data => data?.project}
      errorRedirectRoute={getOrgProjectsRoute({
        subdomain: activeTenant?.subdomain,
        orgId: org.id,
      })}
    />
  );
}

export default ProjectRoute;
