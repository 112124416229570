import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';

import EOIInfoDetails from '../../entities-of-interest/EntityOfInterestInfo/EOIInfoDetails';
import styles from '../../entities-of-interest/EntityOfInterestInfo/EntityOfInterestInfo.module.css';
import { AOI_COLOR_OPTIONS } from '../AreaOfInterestForm';

const AoiInfoDetails = ({
  name,
  data,
  color,
  firstName,
  lastName,
  distance,
  area,
  isEditMode,
  levelId,
}) => {
  return (
    <EOIInfoDetails
      name={name}
      data={data}
      color={color}
      colorFieldProps={{
        colorOptions: AOI_COLOR_OPTIONS,
      }}
      firstName={firstName}
      lastName={lastName}
      isEditMode={isEditMode}
      levelId={levelId}
    >
      <TableRow>
        <TableCell className={styles.fieldLabelCell}>Distance</TableCell>
        <TableCell>{distance} ft</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.fieldLabelCell}>Area</TableCell>
        <TableCell>
          {area} ft<sup>2</sup>
        </TableCell>
      </TableRow>
    </EOIInfoDetails>
  );
};

AoiInfoDetails.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  levelId: PropTypes.string,
  name: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
};

export default AoiInfoDetails;
