import React from 'react';
import { Box, ListItemAvatar, ListItemText } from '@mui/material';

import { useRecoilValue } from 'recoil';

import IdsListItem from '../../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../../components/ids-lists/IdsListItem/IdsListItemChild';
import IdsUserAvatar from '../../../../components/IdsUserAvatar';
import ActivationStateChip from '../../../Users/ActivationStateChip';
import { ITenantUserItem } from '../../../../services/TenantService';
import { getTenantUserDetailsRoute } from '../../../../utils/routes';
import { activeTenantState } from '../../../../atoms/tenants';

export interface ITenantUsersListItemProps {
  userItem: ITenantUserItem;
}

const TenantUsersListItem: React.FC<ITenantUsersListItemProps> = ({ userItem }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const { id, firstName, lastName, email, activationState } = userItem.node;
  const fullName = `${firstName} ${lastName}`;

  return (
    <IdsListItem
      key={id}
      to={getTenantUserDetailsRoute({
        subdomain: activeTenant?.subdomain,
        userId: id,
      })}
    >
      <IdsListItemChild xs md={6}>
        <Box display='flex' alignItems='center'>
          <ListItemAvatar>
            <IdsUserAvatar name={fullName} />
          </ListItemAvatar>
          <ListItemText
            primary={fullName}
            primaryTypographyProps={{ variant: 'h6' }}
            secondary={email}
          />
        </Box>
      </IdsListItemChild>
      <IdsListItemChild container direction='row-reverse'>
        <ActivationStateChip activationState={activationState} />
      </IdsListItemChild>
    </IdsListItem>
  );
};

export default TenantUsersListItem;
