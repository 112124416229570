import React, { ImgHTMLAttributes } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPhygiiLogoProps extends ImgHTMLAttributes<HTMLImageElement> {}

export const PHYGII_LOGO_URL = '/static/phygii/logo.png';

const PhygiiLogo: React.FC<IPhygiiLogoProps> = props => {
  return <img alt='PhygiiLogo' src={PHYGII_LOGO_URL} {...props} />;
};

export default PhygiiLogo;
