import { useCallback } from 'react';

import useFilterContext from '../useFilterContext';
import { MediaType } from '../../constants/media';
import { FilterMode } from '../../context/FilterContext';

import useActiveFilterTargets from './useActiveFilterTargets';

const FILTER_NAME = 'HiddenImages';

const selectEdgeFilterData = (item: { node: { id: string } }) => item.node.id;
const supportedFilterTargets = [
  {
    type: MediaType.ProjectPhoto,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MediaType.HDPhoto,
    selectFilterData: selectEdgeFilterData,
  },
  {
    type: MediaType.PanoramicPhoto,
    selectFilterData: selectEdgeFilterData,
  },
];

/**
 * This filter hides all specified images.
 */
const useImagesFilter = () => {
  const { addFilter, removeFilter } = useFilterContext();

  const activeFilterTargets = useActiveFilterTargets(supportedFilterTargets);

  const applyFilter = useCallback(
    (images: string[]) => {
      addFilter(
        {
          name: FILTER_NAME,
          label: FILTER_NAME,
          targets: activeFilterTargets,
          invisible: true,
          mode: FilterMode.Subtractive,
          filterItem: (imageId: string) => {
            // false === hide image
            return !images.includes(imageId);
          },
        },
        {
          loadedFromUrl: false,
        },
      );
    },
    [addFilter, activeFilterTargets],
  );

  const removeContextFilter = useCallback(() => removeFilter(FILTER_NAME), [removeFilter]);

  return {
    applyFilter,
    removeFilter: removeContextFilter,
  };
};

export default useImagesFilter;
