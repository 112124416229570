import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import IdsAutocomplete from '../../../ids-inputs/IdsAutocomplete';
import useFilterContext from '../../../../hooks/useFilterContext';
import { EntityType } from '../../../../constants/entities';

import ProgramLocationsFilterOption from './ProgramLocationsFilterOption';

const PROGRAM_FILTER = 'program-locations';

const LocationProgramFilter = ({ options, onChange, value, ...rest }) => {
  const programLocations = useRef({});
  const [locationsUpdated, setLocationsUpdated] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState(() => value);
  const { addFilter, removeFilter } = useFilterContext();

  // Handle program location data loaded
  const handleLocationsLoaded = useCallback(
    (programId, locations) => {
      // Since multiple location updates could happen between renders, storing the results in a ref to prevent
      // race conditions between updates with a stateful variable
      programLocations.current[programId] = locations || [];

      // Set flag to handle updated locations in next render
      // Not processing here since multiple updates could come in between renders,
      // this prevents processing from happening multiple times between renders
      setLocationsUpdated(true);
    },
    [setLocationsUpdated],
  );

  const updateFilter = useCallback(
    (programs, filteringDown = false) => {
      const selectedLocations = programs.reduce((arr, programId) => {
        arr.push(...programLocations.current[programId]);
        return arr;
      }, []);

      addFilter(
        {
          name: PROGRAM_FILTER,
          filterItem: locationId => selectedLocations.includes(locationId),
          targets: [
            {
              type: EntityType.Location,
              selectFilterData: item => item.node.id,
            },
          ],
        },
        { filteringDown },
      ); // Don't reapply filter to base data if filtering down further
    },
    [addFilter],
  );

  useEffect(() => {
    if (locationsUpdated) {
      // More location data was loaded
      if (selectedPrograms?.length) {
        // The filter is active, update the filter
        updateFilter(selectedPrograms);
      }
      setLocationsUpdated(false);
    }
  }, [locationsUpdated, setLocationsUpdated, selectedPrograms, updateFilter]);

  const handleChange = useCallback(
    (e, value, reason) => {
      setSelectedPrograms(value);

      if (value?.length) {
        // When an option is deselected, filter is filtering down further
        updateFilter(value, reason === 'removeOption');
      } else {
        removeFilter(PROGRAM_FILTER);
      }

      if (onChange) {
        onChange(value);
      }
    },
    [setSelectedPrograms, updateFilter, removeFilter, onChange],
  );

  return (
    <IdsAutocomplete
      {...rest} // Props below this shouldn't be overridden
      options={options}
      getOptionLabel={o => o?.label}
      getOptionValue={o => o?.value}
      noOptionsText='No programs to filter by'
      value={selectedPrograms}
      onChange={handleChange}
      disableCloseOnSelect
      multiple
      limitTags={1}
      clearOnBlur={false}
      size='small'
      renderOption={(props, o, { selected }) => (
        <li {...props}>
          <ProgramLocationsFilterOption
            key={o.value}
            selected={selected}
            label={o.label}
            programId={o.value}
            onLocationsLoaded={handleLocationsLoaded}
          />
        </li>
      )}
    />
  );
};

LocationProgramFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};

export default LocationProgramFilter;
