import React from 'react';

import IdsDialog, { IIdsDialogProps } from '../../../../components/IdsDialog';

import CreateUserForm from './CreateUserForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateUserDialogProps
  extends Pick<IIdsDialogProps, 'open' | 'onOpen' | 'onClose'> {}

const CreateUserDialog: React.FC<ICreateUserDialogProps> = ({ open, onOpen, onClose }) => {
  return (
    <IdsDialog
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title='Create New User'
      dialogKey='create'
    >
      <CreateUserForm onSubmitSuccess={onClose} onCancel={onClose} />
    </IdsDialog>
  );
};

export default CreateUserDialog;
