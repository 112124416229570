import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useRecoilValue } from 'recoil';

import { ListItemText } from '@mui/material';

import { gql } from 'graphql-request';

import { activeOrganizationState } from '../../../atoms/organizations';
import IdsListItem from '../../../components/ids-lists/IdsListItem';
import IdsListItemChild from '../../../components/ids-lists/IdsListItem/IdsListItemChild';
import ProjectStatusChip from '../ProjectStatusChip';

import { getOrgProjectRoute } from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';

import ProjectListItemMenu from './ProjectListItemMenu';

export const ProjectListItemFrag = gql`
  fragment ProjectListItemFrag on Project {
    id
    name
    description
    status
    location {
      name
    }
  }
`;

const buttonProps = {
  [true]: { disabled: true },
  [false]: { disabled: false },
};

const ProjectListItem = ({ projectItem, showLocationName, onDelete }) => {
  const activeTenant = useRecoilValue(activeTenantState);
  const activeOrg = useRecoilValue(activeOrganizationState);

  const [isDisabled, setIsDisabled] = useState(false);

  if (!projectItem?.node) {
    return null;
  }

  const { id, name, description, status, location } = projectItem?.node;

  return (
    <IdsListItem
      buttonProps={buttonProps[isDisabled]}
      secondaryAction={
        <ProjectListItemMenu
          projectItem={projectItem}
          onDelete={onDelete}
          setItemDisabled={setIsDisabled}
        />
      }
      to={getOrgProjectRoute({
        subdomain: activeTenant?.subdomain,
        orgId: activeOrg.id,
        projectId: id,
      })}
    >
      {showLocationName ? (
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'h6', noWrap: true }}
          secondary={location.name}
          secondaryTypographyProps={{ noWrap: true }}
        />
      ) : (
        <ListItemText primary={name} primaryTypographyProps={{ variant: 'h6', noWrap: true }} />
      )}

      <ListItemText secondary={description} secondaryTypographyProps={{ noWrap: true }} />
      <IdsListItemChild
        xs={3}
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <IdsListItemChild xs='auto'>
          <ProjectStatusChip status={status} />
        </IdsListItemChild>
      </IdsListItemChild>
    </IdsListItem>
  );
};

ProjectListItem.defaultProps = {
  showLocationName: true,
};

ProjectListItem.propTypes = {
  projectItem: PropTypes.object.isRequired,
  showLocationName: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default ProjectListItem;
