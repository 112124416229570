import React, { useCallback, useRef } from 'react';

import IdsDialog, { IIdsDialogProps } from '../../../IdsDialog';
import IdsUploader, { IIdsUploaderProps } from '../IdsUploader';
import useConfirmation from '../../../../hooks/useConfirmation';

import styles from './IdsDialogUploader.module.css';

export interface IIdsDialogUploader extends IIdsUploaderProps {
  dialogProps: IIdsDialogProps;
}

const IdsDialogUploader: React.FC<IIdsDialogUploader> = ({
  uppy,
  onUploadStarted,
  onUploadCompleted,
  dialogProps: { onClose, ...dialogProps },
  ...rest
}) => {
  const { requestConfirmation } = useConfirmation();
  const uploading = useRef(false);

  const handleUploadStarted = useCallback(() => {
    uploading.current = true;

    if (onUploadStarted) {
      onUploadStarted();
    }
  }, [onUploadStarted]);

  const handleUploadCompleted = useCallback(() => {
    uploading.current = false;

    if (onUploadCompleted) {
      onUploadCompleted();
    }
  }, [onUploadCompleted]);

  const handleClose = useCallback(() => {
    if (!uploading.current) {
      if (onClose) {
        onClose();
      }
      return;
    }

    // Require confirmation to close if upload is in progress
    requestConfirmation({
      title: 'Are you sure you want to cancel the upload?',
      description: 'The image will not be uploaded.',
      confirmButtonProps: {
        color: 'error',
      },
      confirmButtonLabel: 'Cancel upload',
      onConfirm: () => {
        if (onClose) {
          onClose(); // Closing the dialog unmounts the uploader, cancelling the upload
        }
      },
      cancelButtonLabel: 'Continue upload',
    });
  }, [requestConfirmation, onClose]);

  return (
    <IdsDialog
      dialogKey='upload'
      maxWidth='lg'
      fullWidth
      onClose={handleClose}
      {...dialogProps}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      classes={{
        paper: styles.dialog,
      }}
    >
      <IdsUploader
        uppy={uppy}
        onUploadStarted={handleUploadStarted}
        onUploadCompleted={handleUploadCompleted}
        {...rest}
      />
    </IdsDialog>
  );
};

export default IdsDialogUploader;
