import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import { IFieldComponentProps } from '../../types';
import LabelCell from '../../LabelCell';
import IdsFormTextField from '../../../../../components/ids-forms/IdsFormTextField';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITextProps extends IFieldComponentProps {}

const Text: React.FC<ITextProps> = ({ field, isEditMode }) => {
  return (
    <TableRow>
      <LabelCell label={field.label} />
      <TableCell>
        {isEditMode && field.isEditable ? (
          <IdsFormTextField name={field.name} label='' required />
        ) : (
          field.data
        )}
      </TableCell>
    </TableRow>
  );
};

export default Text;
