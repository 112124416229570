import React from 'react';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';

import {
  DataToInclude,
  ImageSize,
  IncludeHeroImage,
  IncludeIdsBranding,
  IncludeTableOfContents,
  IncludeWorkOrderSection,
  LinkType,
  LogoOption,
  SettingsType,
  Sorting,
  Summary,
  SummaryReportType,
} from '../types';
import IdsSelect from '../../../components/ids-inputs/IdsSelect';

export const styles = {
  input: {
    backgroundColor: '#fff',
  },
};

export interface ISettingsProps {
  settings: SettingsType;
  setSettings: React.Dispatch<React.SetStateAction<SettingsType>>;
}

export const optionsBuilder = (source: Record<string, string>) => {
  return Object.entries(source).map(([, value]) => ({ value: value, label: value }));
};

const ReportSettings: React.FC<ISettingsProps> = ({ setSettings, settings }) => {
  const setSettingFieldValue = (e: SelectChangeEvent<unknown>, field: keyof SettingsType) => {
    setSettings(prev => ({ ...prev, [field]: e.target.value }));
  };
  return (
    <Grid container spacing={2} direction='column'>
      <Grid item xs>
        <Typography variant='h4'>Settings</Typography>
      </Grid>
      <Grid item xs container spacing={2} columns={{ xs: 2, md: 4 }}>
        <Grid item xs={1}>
          <IdsSelect
            label='Include Hero Image'
            options={optionsBuilder(IncludeHeroImage)}
            defaultValue={settings.heroImage}
            onChange={e => setSettingFieldValue(e, 'heroImage')}
          />
        </Grid>
        <Grid item xs={1}>
          <IdsSelect
            label='Sort Responses'
            options={optionsBuilder(Sorting)}
            defaultValue={settings.sortResponses}
            onChange={e => setSettingFieldValue(e, 'sortResponses')}
          />
        </Grid>
        <Grid item xs={1}>
          <IdsSelect
            label='Include Summary'
            options={optionsBuilder(Summary)}
            defaultValue={settings.summary}
            onChange={e => setSettingFieldValue(e, 'summary')}
          />
        </Grid>
        {settings.summary !== Summary.None && (
          <Grid item xs={1}>
            <IdsSelect
              label='Summary Report Type'
              options={optionsBuilder(SummaryReportType)}
              defaultValue={settings.summaryReportType}
              onChange={e => setSettingFieldValue(e, 'summaryReportType')}
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <IdsSelect
            label='Data to Include'
            options={optionsBuilder(DataToInclude)}
            defaultValue={settings.dataIncluded}
            onChange={e => setSettingFieldValue(e, 'dataIncluded')}
          />
        </Grid>
        {settings.dataIncluded !== DataToInclude.DataOnly && (
          <>
            <Grid item xs={1}>
              <IdsSelect
                label='Image Size'
                options={optionsBuilder(ImageSize)}
                defaultValue={settings.imageSize}
                onChange={e => setSettingFieldValue(e, 'imageSize')}
              />
            </Grid>
            <Grid item xs={1}>
              <IdsSelect
                label='Image Link Type'
                options={optionsBuilder(LinkType)}
                defaultValue={settings.linkType}
                onChange={e => setSettingFieldValue(e, 'linkType')}
              />
            </Grid>
          </>
        )}
        <Grid item xs={1}>
          <IdsSelect
            label='Include Table of Contents'
            options={optionsBuilder(IncludeTableOfContents)}
            defaultValue={settings.generateTaskToC}
            onChange={e => setSettingFieldValue(e, 'generateTaskToC')}
          />
        </Grid>
        <Grid item xs={1}>
          <IdsSelect
            label='Include Work Order Section'
            options={optionsBuilder(IncludeWorkOrderSection)}
            defaultValue={settings.generateWorkOrderSection}
            onChange={e => setSettingFieldValue(e, 'generateWorkOrderSection')}
          />
        </Grid>
        <Grid item xs={1}>
          <IdsSelect
            label='IDS Branding'
            options={optionsBuilder(IncludeIdsBranding)}
            defaultValue={settings.idsBranding}
            onChange={e => setSettingFieldValue(e, 'idsBranding')}
          />
        </Grid>
        <Grid item xs={1}>
          <IdsSelect
            label='Include Logo'
            options={optionsBuilder(LogoOption)}
            defaultValue={settings.logoOption}
            onChange={e => setSettingFieldValue(e, 'logoOption')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportSettings;
