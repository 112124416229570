import { useMutation } from 'react-query';
import { useMemo } from 'react';
import { gql } from 'graphql-request';

import axios from '../utils/axios';
import queryClient from '../utils/query';
import useGraphQuery, { UseGraphQueryOptions } from '../hooks/useGraphQuery';
import useOrgGraphQuery, { useQueryKeyId } from '../hooks/useOrgGraphQuery';
import useCursorPaginatedQuery from '../hooks/useCursorPaginatedQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';

import { PROJECT_STATUSES, ProjectStatusesType } from '../constants/projects';
import { LocationOptionListItemFrag } from '../components/ids-list-items/LocationOptionListItem';
import invalidateQueriesContainingKey from '../utils/invalidateQueriesContainingKey';
import { ProjectsListFrag } from '../views/Projects/ProjectsList';
import { AssignmentListItemFrag } from '../views/Assignments/AssignmentListItem';
import { IAssignmentDetailsFragType } from '../views/Assignments/AssignmentDetails';

import { useOrganizationKeys } from './OrganizationsService';
import { useProgramKeys } from './ProgramsService';
import { useLocationKeys } from './LocationService';
import { useTenantKeys } from './TenantService';
import { useUserKeys } from './UsersService';
import { useRouteKeys } from './RouteService';
import { IWhereUniqueIdOrganizationInput, UserError } from './types';
import { IProjectRouteListItemData, ProjectRouteListItemFrag } from './fragments';

export const useProjectKeys = () => {
  const queryIdKey = useQueryKeyId();
  const locationKeys = useLocationKeys();
  const organizationKeys = useOrganizationKeys();
  const programKeys = useProgramKeys();

  return useMemo(() => {
    const projectKeys = {
      all: ['projects', ...queryIdKey],
      lists: () => [...projectKeys.all, 'list'],
      orgList: (orgId: string) => [...projectKeys.lists(), ...organizationKeys.detail(orgId)],
      programList: (programId: string) => [
        ...projectKeys.lists(),
        ...programKeys.detail(programId),
      ],
      locationList: (locationId: string) => [
        ...projectKeys.lists(),
        ...locationKeys.detail(locationId),
      ],
      route: (id: string) => [...projectKeys.all, id],
      detail: (id: string) => [...projectKeys.route(id), 'detail'],
      routes: (id: string) => [...projectKeys.detail(id), 'routes'],
      orgProjects: (id: string) => [...projectKeys.all, `organization-${id}`],
      assignments: (id: string) => [...projectKeys.detail(id), 'assignments'],
    };

    return projectKeys;
  }, [queryIdKey, locationKeys, organizationKeys, programKeys]);
};

// error, variables, context
const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

const createProject = ({
  locationId,
  name,
  description,
  startDate,
  completionDate,
  statusId,
}: Record<string, any>) => {
  return axios.post('/api/v2/projects', {
    location_id: locationId,
    project: {
      name,
      description,
      start_date: startDate,
      completion_date: completionDate,
      status: statusId,
    },
  });
};

export function useCreateProject() {
  const projectKeys = useProjectKeys();

  return useMutation(createProject, {
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(projectKeys.lists(), {
        refetchActive: true,
      });
    },
  });
}

export function useCreateProgramProject(programId: string) {
  const projectKeys = useProjectKeys();

  // NOT IMPLEMENTED
  // THIS SHOWS HOW PROGRAM LIST QUERIES WOULD
  // BE INVALIDATED, DOESN'T IMPLEMENT CREATION LOGIC
  return useMutation(createProject, {
    onError,
    onSuccess: () => {
      // Only invalidate project list queries in the context of the program
      queryClient.invalidateQueries(projectKeys.programList(programId), {
        refetchActive: true,
      });
    },
  });
}

export function useDeleteProject(orgId: string) {
  const projectKeys = useProjectKeys();

  return useMutation(
    ({ projectId }) => {
      return axios.delete(`/api/v2/projects/${projectId}`);
    },
    {
      onError,
      onSuccess: () => {
        // Only invalidate project list queries in the context of the org
        queryClient.invalidateQueries(projectKeys.orgList(orgId), {
          refetchActive: true,
        });
      },
    },
  );
}

export const useBulkCreateProjects = (programId: string) => {
  return useMutation(
    values => {
      return axios.post('/api/v2/projects/create_bulk', {
        ...values,
        // It is necessary to convert ID<string> to ID<number>
        location_ids: values.location_ids.map((id: string) => +id),
      });
    },
    {
      onError,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(programId),
          refetchActive: true,
        });
      },
    },
  );
};

// $orgId must be of type ID! to support the query to get org users, otherwise the query will fail
const ProjectDetailQuery = gql`
  query GetProject($orgId: ID!, $id: ID!, $tenantId: ID) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      name
      description
      status
      startDate
      completionDate
      location {
        name
        id
      }
      users {
        edges {
          node {
            user {
              id
              firstName
              lastName
              email
            }
            roleName
          }
        }
      }
    }
    organization(id: $orgId) {
      users {
        edges {
          node {
            roleName
            user {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
      locations {
        edges {
          node {
            id
            name
            ...LocationOptionListItemFrag
          }
        }
      }
    }
  }
  ${LocationOptionListItemFrag}
`;

interface IProjectDetailQueryData_UserEdge {
  node: {
    user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    };
    // TODO: Create separate shared type for user roles
    roleName: string;
  };
}
interface IProjectDetailQueryData_LocationEdge {
  node: {
    id: string;
    name: string;
    address: {
      streetLine1: string;
      city: string;
      state: string;
      postalCode: string;
    };
  };
}

export interface IProjectDetailQueryData {
  project: {
    id: string;
    name?: string;
    description?: string;
    status: ProjectStatusesType | typeof PROJECT_STATUSES;
    startDate?: string; // 2023-05-19T00:00:00.000Z
    completionDate?: string;
    location: {
      id: string;
      name?: string;
    };
    users: {
      edges: IProjectDetailQueryData_UserEdge[];
    };
  };
  organization: {
    users: {
      edges: IProjectDetailQueryData_UserEdge[];
    };
    locations: {
      edges: IProjectDetailQueryData_LocationEdge[];
    };
  };
}

export const useGetEditProjectData = (projectId: string) => {
  const projectKeys = useProjectKeys();

  return useOrgGraphQuery<IProjectDetailQueryData>(
    [...projectKeys.detail(projectId), 'edit'],
    ProjectDetailQuery,
    {
      id: projectId,
    },
  );
};

interface IUpdateProjectPayload {
  name: string;
  description: string;
  start_date: string;
  completion_date: string;
  status: string;
  user_ids: string[];
}

export const useUpdateProject = (projectId: string, orgId: string, tenantId: string) => {
  const projectKeys = useProjectKeys();
  const tenantKeys = useTenantKeys();
  const userKeys = useUserKeys();

  return useMutation(
    (payload: IUpdateProjectPayload) => {
      return axios.put(`/api/v2/projects/${projectId}`, {
        project: payload,
      });
    },
    {
      onError,
      onSuccess: async () => {
        await queryClient.invalidateQueries(projectKeys.orgList(orgId), {
          refetchActive: true,
        });

        await queryClient.invalidateQueries([...tenantKeys.projects(tenantId), projectId], {
          refetchActive: true,
        });

        await invalidateQueriesContainingKey(projectKeys.route(projectId), {
          refetchActive: true,
        });

        await invalidateQueriesContainingKey(userKeys.lists(), {
          refetchActive: true,
        });
      },
    },
  );
};

const OrganizationProjectsQuery = gql`
  query OrganizationProjects($organizationId: ID!, $after: String, $take: Int) {
    projects(organizationId: $organizationId, after: $after, take: $take) {
      edges {
        cursor
        node {
          ...ProjectsListFrag
        }
      }
    }
  }
  ${ProjectsListFrag}
`;

export interface IProjectFrag {
  id: string;
  name: string;
  description: string;
  programId: string;
  status: keyof typeof PROJECT_STATUSES;
  location: {
    id: string;
    name: string;
  };
}

export interface IProjectsListQueryEdge {
  cursor: string;
  node: IProjectFrag;
}

export const useGetOrganizationProjects = (
  organizationId: string | null,
  options?: UseGraphQueryOptions,
) => {
  const projectKeys = useProjectKeys();

  const useProjects = (take: number, after: string | null) =>
    useGraphQuery(
      !!organizationId
        ? [...projectKeys.orgProjects(organizationId), `take-${take}`, `after-${after}`]
        : '',
      OrganizationProjectsQuery,
      { organizationId, take, after },
      { enabled: !!organizationId, ...options },
    );

  return useCursorPaginatedQuery<IProjectsListQueryEdge>({
    useQuery: useProjects,
    defaultTake: 500,
    selectConnection: data => data.projects,
  });
};

const LocationProjectOptionsQuery = gql`
  query LocationProjectOptions($orgId: ID, $tenantId: ID, $id: ID!, $take: Int, $after: String) {
    location(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      projects(take: $take, after: $after) {
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export interface ILocationProjectOptionsQueryData_ProjectEdge {
  cursor: string;
  node: {
    id: string;
    name: string;
  };
}

export interface ILocationProjectOptionsQuery {
  location: {
    projects: {
      edges: ILocationProjectOptionsQueryData_ProjectEdge[];
    };
  };
}

export const useGetLocationProjectOptions = (
  locationId?: string,
  options?: UseGraphQueryOptions<ILocationProjectOptionsQuery, any, ILocationProjectOptionsQuery>,
) => {
  const projectKeys = useProjectKeys();

  const useProjectOptions = (take: number, after: string | null) =>
    useOrgGraphQuery<ILocationProjectOptionsQuery>(
      locationId
        ? [...projectKeys.locationList(locationId), 'options', `take-${take}`, `after-${after}`]
        : '',
      LocationProjectOptionsQuery,
      {
        id: locationId,
        take,
        after,
      },
      options,
    );

  return useCursorPaginatedQuery({
    initialData: [] as ILocationProjectOptionsQueryData_ProjectEdge[],
    useQuery: useProjectOptions,
    defaultTake: 600,
    selectConnection: (d: ILocationProjectOptionsQuery) => d.location.projects,
  });
};

const ProjectRouteQuery = gql`
  query GetProject($orgId: ID, $id: ID!, $tenantId: ID) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
      locationId
      name
    }
  }
`;

export interface IProjectRouteData {
  project: {
    id: string;
    locationId: string;
    name: string;
  };
}

export const useGetProjectRoute = (projectId: string | null, options: UseGraphQueryOptions) => {
  const projectKeys = useProjectKeys();

  return useOrgGraphQuery<IProjectRouteData>(
    !!projectId ? projectKeys.route(projectId) : '',
    ProjectRouteQuery,
    { id: projectId },
    options,
  );
};

const ProjectRoutesListQuery = gql`
  query ProjectRoutesListQuery($projectId: ID!, $tenantId: ID, $orgId: ID) {
    project(id: $projectId, tenantId: $tenantId, organizationId: $orgId) {
      routes {
        ...ProjectRouteListItemFrag
      }
    }
  }
  ${ProjectRouteListItemFrag}
`;

export interface IProjectRoutesListData {
  project: {
    routes: IProjectRouteListItemData[];
  };
}

export const useGetProjectRoutes = (projectId: string) => {
  const projectKeys = useProjectKeys();

  return useOrgGraphQuery<IProjectRoutesListData>(
    projectKeys.routes(projectId),
    ProjectRoutesListQuery,
    {
      projectId,
    },
  );
};

const CreateProjectRouteMutation = gql`
  mutation CreateProjectRoute($where: WhereUniqueIdOrganizationInput!, $input: CreateRouteInput!) {
    createRoute(where: $where, input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export interface ICreateProjectRouteOutput {
  createRoute?: {
    errors?: UserError[];
  };
}

export interface ICreateProjectRouteInput {
  description?: string;
  notes?: string;
  name: string;
}

const createProjectRoute = ({
  id,
  organizationId,
  description,
  notes,
  name,
}: IWhereUniqueIdOrganizationInput & ICreateProjectRouteInput) => {
  return buildGraphMutationFn(CreateProjectRouteMutation)({
    input: {
      description,
      notes,
      name,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export const useCreateProjectRoute = () => {
  const projectKeys = useProjectKeys();

  return useMutation(createProjectRoute, {
    onError,
    onSuccess: async (result: ICreateProjectRouteOutput, { id }) => {
      if (result?.createRoute?.errors?.length) {
        return;
      }

      await queryClient.invalidateQueries(projectKeys.routes(id), {
        refetchActive: true,
      });
    },
  });
};

const DeleteRouteMutation = gql`
  mutation DeleteRoute($where: WhereUniqueIdOrganizationInput!) {
    deleteRoute(where: $where) {
      errors {
        field
        message
      }
    }
  }
`;

const buildDeleteRouteMutation = ({ id, organizationId }: IWhereUniqueIdOrganizationInput) => {
  return buildGraphMutationFn(DeleteRouteMutation)({
    where: {
      id,
      organizationId,
    },
  });
};

export interface IDeleteRouteOutput {
  deleteRoute?: {
    errors?: UserError[];
  };
}

export const useDeleteRoute = (projectId?: string) => {
  const routeKeys = useRouteKeys();
  const projectKeys = useProjectKeys();

  return useMutation(buildDeleteRouteMutation, {
    onError,
    onSuccess: async (result: IDeleteRouteOutput, { id }) => {
      if (result?.deleteRoute?.errors?.length || !projectId) {
        return;
      }

      await queryClient.invalidateQueries(routeKeys.detail(projectId, id), {
        refetchActive: false,
      });

      const isProjectRoutesCached = !!queryClient.getQueryState(projectKeys.routes(projectId));
      if (!isProjectRoutesCached) {
        return;
      }

      // Routes list
      await queryClient.setQueryData<IProjectRoutesListData | undefined>(
        projectKeys.routes(projectId),
        oldData => {
          if (!oldData) {
            return oldData;
          }

          return {
            project: {
              routes: oldData.project.routes.filter(r => r.id !== id),
            },
          };
        },
      );
    },
  });
};

const UpdateProjectRoutePosition = gql`
  mutation UpdateProjectRoutePosition(
    $where: WhereUniqueIdOrganizationInput!
    $input: UpdateRoutePositionInput!
  ) {
    updateRoutePosition(where: $where, input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

interface IUpdateProjectRoutePositionInput {
  position: string;
}

const updateProjectRoutePosition = ({
  id,
  organizationId,
  position,
}: IWhereUniqueIdOrganizationInput & IUpdateProjectRoutePositionInput) => {
  return buildGraphMutationFn(UpdateProjectRoutePosition)({
    input: {
      position,
    },
    where: {
      id,
      organizationId,
    },
  });
};

export const useUpdateProjectRoutePosition = (projectId?: string) => {
  const projectKeys = useProjectKeys();

  return useMutation(updateProjectRoutePosition, {
    onError,
    onSuccess: async () => {
      if (projectId) {
        await queryClient.invalidateQueries(projectKeys.routes(projectId), {
          refetchActive: true,
        });
      }
    },
  });
};

const ProjectAssignmentListQuery = gql`
  query ProjectAssignments($orgId: ID, $tenantId: ID, $id: ID!, $take: Int, $after: String) {
    project(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      assignments(after: $after, take: $take) {
        edges {
          cursor
          node {
            id
            title
            active
            ...AssignmentListItemFrag
          }
        }
      }
    }
  }
  ${AssignmentListItemFrag}
`;

interface IProjectAssignmentListQueryEdge {
  cursor: string;
  node: {
    id: string;
    title: string;
    active: boolean;
  } & IAssignmentDetailsFragType;
}

export const useGetProjectAssignments = (
  projectId: string | null,
  options: UseGraphQueryOptions,
) => {
  const projectKeys = useProjectKeys();

  const useQuery = (take: number, after: string | null) =>
    useOrgGraphQuery(
      projectId ? [...projectKeys.assignments(projectId), `take-${take}`, `after-${after}`] : '',
      ProjectAssignmentListQuery,
      { id: projectId, take, after },
      { enabled: !!projectId, ...options },
    );

  return useCursorPaginatedQuery<IProjectAssignmentListQueryEdge>({
    useQuery,
    defaultTake: 500,
    selectConnection: data => data.project.assignments,
  });
};

const ProjectListItemDetailsQuery = gql`
  query ProjectListItemDetailsQuery($projectId: ID!, $orgId: ID, $tenantId: ID) {
    project(id: $projectId, organizationId: $orgId, tenantId: $tenantId) {
      ...ProjectsListFrag
    }
  }
  ${ProjectsListFrag}
`;

export interface IProjectListItemDetails {
  project: IProjectFrag;
}

export const useGetProjectListItemDetails = (
  projectId: string | null,
  options?: UseGraphQueryOptions,
) => {
  const projectKeys = useProjectKeys();

  return useOrgGraphQuery<IProjectListItemDetails>(
    projectId ? projectKeys.detail(projectId) : '',
    ProjectListItemDetailsQuery,
    { projectId },
    { enabled: !!projectId, ...options },
  );
};
