import React, { useMemo, useState } from 'react';
import { Button, Grid, SvgIcon } from '@mui/material';

import { AddIcon } from '../../../theme/icons';
import CreateProgramDialog from '../CreateProgramDialog';
import NestedOrgViewHeader from '../../../components/NestedOrgViewHeader';
import usePermissions from '../../../hooks/usePermissions';
import { EntityType } from '../../../constants/entities';

function ProgramListHeader() {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { userHasPermission } = usePermissions();

  const canCreate = useMemo(
    () => userHasPermission('create', EntityType.Program),
    [userHasPermission],
  );

  const openCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  const closeCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  function Actions() {
    return (
      canCreate && (
        <Grid item>
          <Button color='secondary' variant='contained' onClick={openCreateDialog}>
            <SvgIcon fontSize='small' sx={{ mr: 2 }}>
              <AddIcon />
            </SvgIcon>
            New Program
          </Button>
        </Grid>
      )
    );
  }

  return (
    <NestedOrgViewHeader
      currentCrumb='Programs'
      actions={Actions()}
      dialogs={
        <CreateProgramDialog
          open={createDialogOpen}
          onOpen={openCreateDialog}
          onClose={closeCreateDialog}
        />
      }
    />
  );
}

export default ProgramListHeader;
