import React, { useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import { FlagIcon, NotFlaggedIcon } from '../../../../theme/icons';
import { AllFormValues, OriginalFieldValues } from '../types';
import {
  IMetadataType,
  IMetadataTypeValue,
  MEDIA_METADATA_TYPES,
  MediaMetadataType,
} from '../../../../constants/media';
import useMetadataItems from '../../../../hooks/useMetadataItems';

import FieldChangeSummary, { IFieldChangeSummaryProps } from './FieldChangeSummary';

export interface IBulkLocationImageEditReviewProps {
  originalFieldValues: OriginalFieldValues;
  metadataTypes: IMetadataType[];
}

type FieldSummary = Pick<IFieldChangeSummaryProps, 'updatedValue' | 'originalValues'>;

const BulkLocationImageEditReview: React.FC<IBulkLocationImageEditReviewProps> = ({
  originalFieldValues,
  metadataTypes,
}) => {
  const { allLevels, allAreas, allCategories, allTypes } = useMetadataItems(metadataTypes);
  const { values } = useFormikContext<AllFormValues>();

  const headingSummary = useMemo(() => {
    return {
      updatedValue: values.heading,
      originalValues: originalFieldValues.heading.sort((a, b) => {
        // Sort undefined value to end. NOTE: this is a precaution. JS internally does this already
        if (a === undefined) return 1;
        if (b === undefined) return -1;

        return a - b;
      }),
    };
  }, [values.heading, originalFieldValues.heading]);

  const buildMetadataFieldSummary = useCallback(
    (type: MediaMetadataType, allTypeValues: IMetadataTypeValue[]) => {
      const idToLabel = (id: string) =>
        allTypeValues.find(({ id: typeValueId }) => typeValueId === id)!.name;

      return {
        updatedValue: (values as any)[type] && idToLabel((values as any)[type]),
        originalValues: (originalFieldValues as any)[type]
          .sort((a?: string, b?: string) => {
            // Sort undefined value to end. NOTE: this is a precaution. JS internally does this already
            if (!a) return 1;
            if (!b) return -1;

            // Sort values in order they are defined
            return (
              allTypeValues.findIndex(v => v.id === a) - allTypeValues.findIndex(v => v.id === b)
            );
          })
          .map((id?: string) => (id ? idToLabel(id) : 'None')),
      } as FieldSummary;
    },
    [values, originalFieldValues],
  );

  const levelSummary = useMemo<FieldSummary>(() => {
    return buildMetadataFieldSummary(MediaMetadataType.Level, allLevels);
  }, [buildMetadataFieldSummary, allLevels]);

  const areaSummary = useMemo<FieldSummary>(() => {
    return buildMetadataFieldSummary(MediaMetadataType.Area, allAreas);
  }, [buildMetadataFieldSummary, allAreas]);

  const photoTypeSummary = useMemo<FieldSummary>(() => {
    return buildMetadataFieldSummary(MediaMetadataType.PhotoType, allTypes);
  }, [buildMetadataFieldSummary, allTypes]);

  const photoCategorySummary = useMemo<FieldSummary>(() => {
    return buildMetadataFieldSummary(MediaMetadataType.PhotoCategory, allCategories);
  }, [buildMetadataFieldSummary, allCategories]);

  const publishedUpdatedValue = useMemo(() => {
    if (values.published === null) return null;

    return values.published ? 'Publish' : 'Unpublish';
  }, [values.published]);

  const flaggedUpdatedValue = useMemo(() => {
    if (values.flagged === null) return null;

    return values.flagged ? 'Flag' : 'Unflag';
  }, [values.flagged]);

  return (
    <Stack direction='column' spacing={1}>
      <FieldChangeSummary
        title='Heading'
        updatedValue={headingSummary.updatedValue}
        originalValues={headingSummary.originalValues}
      />
      <FieldChangeSummary
        title={MEDIA_METADATA_TYPES[MediaMetadataType.Level].label}
        {...levelSummary}
      />
      <FieldChangeSummary
        title={MEDIA_METADATA_TYPES[MediaMetadataType.Area].label}
        {...areaSummary}
      />
      <FieldChangeSummary
        title={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoType].label}
        {...photoTypeSummary}
      />
      <FieldChangeSummary
        title={MEDIA_METADATA_TYPES[MediaMetadataType.PhotoCategory].label}
        {...photoCategorySummary}
      />
      <FieldChangeSummary title='Visibility Settings' updatedValue={publishedUpdatedValue} />
      <FieldChangeSummary
        title='Flagging Settings'
        updatedValue={flaggedUpdatedValue}
        updatedValueChipProps={{
          color: 'error',
          icon: flaggedUpdatedValue ? <FlagIcon /> : <NotFlaggedIcon />,
        }}
        hideDivider
      />
    </Stack>
  );
};

export default BulkLocationImageEditReview;
