import { Card, Container, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import globalSx from '../../theme/globalSx';
import Page from '../../components/Page';
import NestedOrgViewHeader from '../../components/NestedOrgViewHeader';

import {
  DataToInclude,
  ImageSize,
  IncludeHeroImage,
  IncludeIdsBranding,
  IncludeTableOfContents,
  IncludeWorkOrderSection,
  LinkType,
  LogoOption,
  SettingsType,
  Sorting,
  Summary,
  SummaryReportType,
} from './types';
import LoadAssignmentForm from './LoadAssignmentForm';
import ReportGenerating from './ReportGenerating';
import AssignmentFiltering from './Settings/AssignmentFiltering';
import ReportSettings from './Settings/ReportSettings';
import styles from './Reporting.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IReportingProps {}

const Reporting: React.FC<IReportingProps> = () => {
  const [assignmentId, setAssignmentId] = useState<string | null>(null);
  const [projectIds, setProjectIds] = useState<string[] | null>(null);
  const [settings, setSettings] = useState<SettingsType>({
    heroImage: IncludeHeroImage.No,
    linkType: LinkType.S3,
    dataIncluded: DataToInclude.Both,
    sortResponses: Sorting.NoSorting,
    imageSize: ImageSize.Original,
    summaryReportType: SummaryReportType.TaskLevel,
    summary: Summary.Grid,
    idsBranding: IncludeIdsBranding.No,
    generateTaskToC: IncludeTableOfContents.No,
    generateWorkOrderSection: IncludeWorkOrderSection.No,
    logoOption: LogoOption.NoLogo,
    sectionsToIgnore: [],
    tasksToIgnore: [],
    questionsToIgnore: [],
  });
  const [isEmptyReport, setIsEmptyReport] = useState<boolean>(false);

  const onAssignmentSelected = useCallback((assignmentId: string, projectIds: string[]) => {
    setAssignmentId(assignmentId);
    setProjectIds(projectIds);
  }, []);

  const resetAssignmentData = useCallback(() => {
    setAssignmentId(null);
    setProjectIds(null);
  }, []);

  return (
    <Page title='Reports' className={styles['page']}>
      <Container maxWidth={false} className={styles['pageContainer']}>
        <NestedOrgViewHeader currentCrumb='Reports' />
        <Card sx={globalSx.pageContent} className={styles['card']}>
          <Grid p={2} container direction='column' spacing={2}>
            <Grid item xs>
              <Typography variant='h4' color='text.primary'>
                Generate a Generic Report
              </Typography>
            </Grid>

            <Grid item xs>
              <LoadAssignmentForm
                resetAssignmentData={resetAssignmentData}
                onAssignmentSelected={onAssignmentSelected}
              />
            </Grid>
            {assignmentId && projectIds && (
              <>
                <Grid item xs>
                  <ReportSettings settings={settings} setSettings={setSettings} />
                </Grid>
                <Grid item xs>
                  <AssignmentFiltering
                    assignmentId={assignmentId}
                    setSettings={setSettings}
                    setIsEmptyReport={setIsEmptyReport}
                  />
                </Grid>
                <Grid item xs>
                  <ReportGenerating
                    settings={settings}
                    assignmentId={assignmentId}
                    projectIds={projectIds}
                    isEmptyReport={isEmptyReport}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default Reporting;
