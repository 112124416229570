import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useResizeDetector } from 'react-resize-detector';
import { useSetRecoilState, useRecoilState } from 'recoil';

import { infoPanelExpandedState, mapMenuExpandedState } from '../../../atoms/immersiveViewer';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import FilledIconButton from '../../ids-buttons/FilledIconButton';
import MapOverlay from '../MapOverlay';
import MapMenuItem from '../MapMenuItem';
import { CloseIcon, SettingsIcon } from '../../../theme/icons';
import MapMenuItemAccordions from '../MapMenuItem/MapMenuItemAccordions';
import useImmersiveViewer from '../../../hooks/useImmersiveViewer';

import styles from './MapMenu.module.css';

const MapMenu = ({ menu, disabled = false, menuWrapper = true }) => {
  const setInfoPanelExpanded = useSetRecoilState(infoPanelExpandedState);
  const [mapMenuOpen, setMapMenuOpen] = useRecoilState(mapMenuExpandedState);
  const isSmallScreen = useIsSmallScreen();

  const [activeMenuItem, setActiveMenuItem] = useState();
  const { containerRef: mapContainerRef } = useImmersiveViewer();
  const { height: mapContainerHeight, ref: mapContainerResizeRef } = useResizeDetector({
    handleWidth: false,
  });

  const handleOnClick = useCallback(() => {
    setMapMenuOpen(!mapMenuOpen);

    /**
     * Run the conditioned code if the menu has just been opened.
     */
    if (!mapMenuOpen && isSmallScreen) {
      /**
       * If the info panel is open, close it. Otherwise, do nothing.
       */
      setInfoPanelExpanded(false);
    }
  }, [setInfoPanelExpanded, setMapMenuOpen, mapMenuOpen, isSmallScreen]);

  useEffect(() => {
    mapContainerResizeRef.current = mapContainerRef;
  }, [mapContainerRef, mapContainerResizeRef]);

  const maxHeight = useMemo(() => {
    if (!mapContainerRef || !mapContainerHeight) return 0;

    // Max height = map height - menu items container top - desired bottom margin
    // NOTE: update this calculation if top style changes on menu items container
    return mapContainerHeight - 52;
  }, [mapContainerRef, mapContainerHeight]);

  /**
   * @param item - an object with .filter method and .active property
   */
  const toggleMenuItem = useCallback(item => {
    item.filter(!item.active);
  }, []);

  const id = mapMenuOpen ? 'simple-popover' : undefined;

  return (
    <Box className={clsx({ [styles.menuWrapper]: menuWrapper })}>
      <MapOverlay>
        <FilledIconButton
          backgroundProps={{ className: styles.menuIconExpanded }}
          aria-describedby={id}
          onClick={handleOnClick}
          disabled={disabled}
        >
          {!mapMenuOpen || disabled ? <SettingsIcon /> : <CloseIcon />}
        </FilledIconButton>
        <Box
          className={clsx({
            [styles.menuItems]: mapMenuOpen && !disabled && menuWrapper,
            [styles.hideMenuItems]: !mapMenuOpen || disabled,
            [styles.menuItemsWithoutMenuWrapper]: mapMenuOpen && !disabled && !menuWrapper,
          })}
          style={{ maxHeight }}
        >
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{ maxHeight }}
            className={styles.scrollbar}
          >
            <div className={styles['menuItems-inner']}>
              {menu?.map((section, i) => (
                <Box key={`map-menu-item-${i}`} className={styles.menuItem}>
                  <MapMenuItem
                    data={section}
                    label={section.sectionTitle}
                    active={activeMenuItem}
                    setActive={setActiveMenuItem}
                    icon={section.icon}
                  >
                    {section.accordions ? (
                      <MapMenuItemAccordions
                        accordions={section.accordions}
                        toggle={toggleMenuItem}
                      />
                    ) : (
                      section.custom
                    )}
                  </MapMenuItem>
                </Box>
              ))}
            </div>
          </PerfectScrollbar>
        </Box>
      </MapOverlay>
    </Box>
  );
};

export default MapMenu;
