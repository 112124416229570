import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { IconButton, Tooltip, Typography, Paper, Stack, Theme, Grid } from '@mui/material';
import { Viewport } from 'deck.gl/typed';
import { PathLayer } from '@deck.gl/layers/typed';
import { Vector2, toDegrees } from '@math.gl/core';
import { rotateDEG, applyToPoints } from 'transformation-matrix';
import Uppy from '@uppy/core';

import InvertedIconLayer from '../../../layers/InvertedIconLayer';
import {
  XRayModeOnIcon,
  XRayModeOffIcon,
  LockIcon,
  UnlockIcon,
  EditOutlineIcon,
  CancelIcon,
  InfoIcon,
  SaveIcon,
  HelpIcon,
} from '../../../../../theme/icons';
import { rotateVector } from '../../../../../utils/vectors';
import useImmersiveViewer from '../../../../../hooks/useImmersiveViewer';
import MapOverlay from '../../../MapOverlay';
import buildIdsLayer from '../../../layers/IdsLayer';
import { buildRasterOverlayAlignmentMarkerLayer } from '../../../layers/media-markers';
import styles from '../RasterOverlayTool.module.css';
import { IOverlayData, IRasterOverlayFileMeta, IRasterOverlayPreview, RasterOverlayFile } from '..';
import { LAYER_TYPE } from '../../../../../context/ImmersiveViewerContext';
import { getDistanceFromLatLonInMeters } from '../../../../../utils/geospatial';
import useLocationMapMetadataOptions from '../../../../../hooks/useLocationMapMetadataOptions';
import { IMetadataTypeValue, MediaMetadataType } from '../../../../../constants/media';
import useMountedEffect from '../../../../../hooks/useMountedEffect';

import InfoBox from '../../../../InfoBox';
import { overlayInfoBoxVisibleState } from '../../../../../atoms/overlay';

import OverlayToggleButton from './OverlayToggleButton';

import '@uppy/status-bar/dist/style.min.css';

export const STATUS_BAR_CONTAINER_ID = 'ro-upload-status';

const PathLayerNoType = PathLayer as any;
const MapOverlayNoType = MapOverlay as any;

const OVERLAY_LAYER_ID = 'new-raster-overlay';
const OVERLAY_ALIGNMENT_MARKERS_LAYER_ID = `${OVERLAY_LAYER_ID}-alignment`;
const OVERLAY_OUTLINE_LAYER_ID = `${OVERLAY_LAYER_ID}-outline`;
const EMPTY_DATA = [{}];
const XRAY_OPACITY = 0.1;

const ALIGNMENT_MARKER_SIZE = 45;

const sx = {
  cancel: (theme: Theme) => ({
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.grey[500],
    },
  }),
};

export interface IBoundingBox {
  west: number;
  south: number;
  east: number;
  north: number;
}

export interface IOverlayRenderProps {
  pixelSize: number;
  rotationAngle: number;
}

export interface IAlignmentMarker {
  position: Coordinate;
  previewPosition: {
    x: number;
    y: number;
  };
}

export type OverlayAlignmentMarkers = [IAlignmentMarker, IAlignmentMarker];

export type OverlayCorners = {
  topLeft: Coordinate;
  topRight: Coordinate;
  bottomRight: Coordinate;
  bottomLeft: Coordinate;
};

export interface IRasterOverlayPlacementProps {
  preview: IRasterOverlayPreview;
  onEdit: (markers: OverlayAlignmentMarkers) => void;
  onSave: (corners: OverlayCorners, markers: OverlayAlignmentMarkers) => void;
  onClose: () => void;
  uppy: Uppy;
  uppyFile: RasterOverlayFile;
  uploading: boolean;
  isSaving: boolean;
}

const RasterOverlayPlacement: React.FC<IRasterOverlayPlacementProps> = ({
  preview,
  onEdit,
  onSave,
  onClose,
  uppy,
  uppyFile,
  uploading,
  isSaving,
}) => {
  const { setLayer, setLayers, setController, getViewport, useViewState } = useImmersiveViewer();
  const viewState = useViewState();
  const dragOffset = useRef<Vector2>(new Vector2(0, 0));
  const [xrayModeActive, setXRayModeActive] = useState(false);
  const [mapLockActive, setMapLockActive] = useState(false);
  const [overlayInfoBoxVisible, setOverlayInfoBoxVisible] = useRecoilState(
    overlayInfoBoxVisibleState,
  );
  const levels: IMetadataTypeValue[] = useLocationMapMetadataOptions(MediaMetadataType.Level);
  const { overlayName, levelId } = useMemo<IOverlayData>(() => {
    if (uppy && uppyFile) {
      const { overlayName, levelId } = uppy.getFile<IRasterOverlayFileMeta>(uppyFile.id).meta;
      return { overlayName: overlayName!, levelId: levelId! };
    }
    return { overlayName: '', levelId: null };
  }, [uppy, uppyFile]);

  const handleHelpClick = useCallback(() => {
    setOverlayInfoBoxVisible(v => !v);
  }, [setOverlayInfoBoxVisible]);

  const levelName = useMemo(
    () => levels?.find((l: IMetadataTypeValue) => l.id === levelId)?.name,
    [levels, levelId],
  );

  const [markers, setMarkers] = useState<OverlayAlignmentMarkers>(() => {
    if (uppy && uppyFile) {
      const { markers } = uppy.getFile<IRasterOverlayFileMeta>(uppyFile.id).meta;
      if (markers) return markers;
    }

    const viewport = getViewport();

    if (!viewport) {
      throw new Error('Raster overlay tool markers cannot be initialized. Viewport undefined.');
    }

    // Calculate initial px height to fill the viewport with some padding on top and bottom
    // The alignment marker size is taken into account so the marker on top, extending above the overlay is
    // within the viewport with padding. 3 in this case is the scale factor used to determine that padding
    // based on the alignment marker size.
    const pxHeight = viewport.height - ALIGNMENT_MARKER_SIZE * 3;
    const pxWidth = (preview.width / preview.height) * pxHeight;

    const marker1X: number = (viewport.width - pxWidth) / 2;
    const marker1Y: number = (viewport.height - pxHeight) / 2;
    const marker1Pos: number[] = viewport.unproject([marker1X, marker1Y]);

    const marker2X: number = marker1X + pxWidth;
    const marker2Y: number = marker1Y + pxHeight;
    const marker2Pos: number[] = viewport.unproject([marker2X, marker2Y]);

    return [
      {
        position: {
          longitude: marker1Pos[0],
          latitude: marker1Pos[1],
        },
        previewPosition: { x: 0, y: 0 },
      },
      {
        position: {
          longitude: marker2Pos[0],
          latitude: marker2Pos[1],
        },
        previewPosition: { x: preview.width, y: preview.height },
      },
    ];
  });

  useEffect(() => {
    // Update markers in uppy file for easy restoration if the tool accidentally disabled during placement
    if (uppy && uppyFile?.id) {
      uppy.setFileMeta<IRasterOverlayFileMeta>(uppyFile!.id, { markers });
    }
  }, [markers, uppy, uppyFile]);

  const calculateRenderProps = useCallback(
    (viewport: Viewport) => {
      const m1 = markers[0];
      const m2 = markers[1];

      const hypotMagnitudeM = getDistanceFromLatLonInMeters(
        m1.position.latitude,
        m1.position.longitude,
        m2.position.latitude,
        m2.position.longitude,
      );

      const m1Canvas = viewport.project([m1.position.longitude, m1.position.latitude]);
      const [m1CanvasX, m1CanvasY] = m1Canvas;

      const m2Canvas = viewport.project([m2.position.longitude, m2.position.latitude]);
      const [m2CanvasX, m2CanvasY] = m2Canvas;

      const canvasHypotVector = new Vector2(m2CanvasX - m1CanvasX, m2CanvasY - m1CanvasY);

      const previewHypotVector = new Vector2(
        m2.previewPosition.x - m1.previewPosition.x,
        m2.previewPosition.y - m1.previewPosition.y,
      );
      const previewHypotMagnitude = Math.hypot(previewHypotVector.x, previewHypotVector.y);
      const heightScaleFactor = preview.height / previewHypotMagnitude;
      const heightM = heightScaleFactor * hypotMagnitudeM;
      const heightPx = heightScaleFactor * canvasHypotVector.magnitude();
      const widthPx = (preview.width / preview.height) * heightPx;

      // Rotation angle formula taken from here: https://math.stackexchange.com/questions/878785/how-to-find-an-angle-in-range0-360-between-2-vectors
      const dot = canvasHypotVector.dot(previewHypotVector);
      const determinant =
        canvasHypotVector.x * previewHypotVector.y - canvasHypotVector.y * previewHypotVector.x;
      const rotationRad = Math.atan2(determinant, dot);
      const rotationDeg = toDegrees(rotationRad);

      const markerCanvasPoints: PointArrayNotation[] = [
        m1Canvas as PointArrayNotation,
        m2Canvas as PointArrayNotation,
      ];

      const m1PreviewPosToCenter = new Vector2(
        preview.width / 2 - m1.previewPosition.x,
        preview.height / 2 - m1.previewPosition.y,
      );

      // Apply overlay rotation to get direction to center of overlay in canvas space
      const m1ToCenterCanvas = rotateVector(m1PreviewPosToCenter, -rotationRad)
        .normalize()
        .scale(m1PreviewPosToCenter.magnitude() * (heightPx / preview.height));
      const centerCanvas = new Vector2(m1CanvasX, m1CanvasY).add(m1ToCenterCanvas);

      const [centerLon, centerLat] = viewport.unproject([centerCanvas.x, centerCanvas.y]);

      // Reverse rotation on markers to calculate corners in local space
      // Note: rotateDEG seems to be the reverse rotation direction from deckgl, so positive rotation here actually reverses it
      const reverseRotationMatrix = rotateDEG(rotationDeg, centerCanvas.x, centerCanvas.y);
      const localCanvasMarkers = applyToPoints(reverseRotationMatrix, markerCanvasPoints);

      // Multiple coords by conversion to canvas space from preview space
      const origin: PointArrayNotation = [
        localCanvasMarkers[0][0] - m1.previewPosition.x * (widthPx / preview.width),
        localCanvasMarkers[0][1] - m1.previewPosition.y * (heightPx / preview.height),
      ];
      const localCanvasCorners: PointArrayNotation[] = [
        origin, // top left
        [origin[0] + widthPx, origin[1]], // top right
        [origin[0] + widthPx, origin[1] + heightPx], // bottom right
        [origin[0], origin[1] + heightPx], // bottom left
      ];

      // Reapply overlay rotation to calculate canvas corners
      const rotationMatrix = rotateDEG(-rotationDeg, centerCanvas.x, centerCanvas.y);
      const canvasCorners = applyToPoints(rotationMatrix, localCanvasCorners);
      const cornersArr = canvasCorners.map(c => viewport.unproject(c));

      return {
        heightM,
        heightPx,
        rotationDeg,
        center: {
          longitude: centerLon,
          latitude: centerLat,
        },
        centerCanvas: centerCanvas,
        corners: {
          topLeft: { longitude: cornersArr[0][0], latitude: cornersArr[0][1] },
          topRight: { longitude: cornersArr[1][0], latitude: cornersArr[1][1] },
          bottomRight: { longitude: cornersArr[2][0], latitude: cornersArr[2][1] },
          bottomLeft: { longitude: cornersArr[3][0], latitude: cornersArr[3][1] },
        } as OverlayCorners,
        cornersCanvas: {
          topLeft: canvasCorners[0],
          topRight: canvasCorners[1],
          bottomRight: canvasCorners[2],
          bottomLeft: canvasCorners[3],
        },
        m1Canvas,
        m2Canvas,
      };
    },
    [preview.height, preview.width, markers],
  );

  const onMarkerDragStart = useCallback(
    (info?: Record<string, any>) => {
      if (!info) return;

      const {
        object,
        coordinate: [mouseLongitude, mouseLatitude],
      } = info;

      // Calculate mouse offset from marker so marker doesn't jump to mouse position when not dragged from the anchor point
      dragOffset.current = new Vector2(
        object.position.longitude - mouseLongitude,
        object.position.latitude - mouseLatitude,
      );

      if (!mapLockActive) {
        setController({ dragPan: false });
      }
    },
    [mapLockActive, setController],
  );

  const onMarkerDrag = useCallback(
    (info?: Record<string, any>) => {
      if (!info?.coordinate) return;

      const {
        index,
        coordinate: [mouseLongitude, mouseLatitude],
      } = info;

      // Calculate marker location offset from initial mouse drag position
      const offsetLongitude = mouseLongitude + dragOffset.current.x;
      const offsetLatitude = mouseLatitude + dragOffset.current.y;

      setMarkers((prev: OverlayAlignmentMarkers) => {
        prev[index].position = { longitude: offsetLongitude, latitude: offsetLatitude };
        return [...prev];
      });
    },
    [setMarkers],
  );

  const onMarkerDragEnd = useCallback(() => {
    if (!mapLockActive) {
      setController({ dragPan: true });
    }
  }, [mapLockActive, setController]);

  const onPreviewClick = useCallback(
    (info?: Record<string, any>) => {
      if (!info?.coordinate) return;

      const {
        coordinate: [longitude, latitude],
        x,
        y,
      } = info;

      setMarkers((prev: OverlayAlignmentMarkers) => {
        const viewport = getViewport();

        if (!viewport) {
          throw new Error('Raster overlay tool markers cannot be set. Viewport undefined.');
        }

        const { m1Canvas, m2Canvas, centerCanvas, heightPx, cornersCanvas, rotationDeg } =
          calculateRenderProps(viewport);

        const [m1CanvasX, m1CanvasY] = m1Canvas;
        const [m2CanvasX, m2CanvasY] = m2Canvas;

        const toM1 = new Vector2(m1CanvasX - x, m1CanvasY - y).magnitude();
        const toM2 = new Vector2(m2CanvasX - x, m2CanvasY - y).magnitude();

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const closestMarkerIndex = toM1 < toM2 ? 0 : 1;

        const reverseRotationMatrix = rotateDEG(rotationDeg, centerCanvas.x, centerCanvas.y);
        const localPoints = applyToPoints(reverseRotationMatrix, [[x, y], cornersCanvas.topLeft]);

        // preview position is relative to topLeft corner of preview in local space (non rotated) and scaled to match preview size
        const previewToCanvasPx = preview.height / heightPx;
        const previewPosition = {
          x: (localPoints[0][0] - localPoints[1][0]) * previewToCanvasPx,
          y: (localPoints[0][1] - localPoints[1][1]) * previewToCanvasPx,
        };

        prev[closestMarkerIndex] = {
          position: { longitude, latitude },
          previewPosition,
        };
        return [...prev];
      });
    },
    [setMarkers, getViewport, calculateRenderProps, preview.height],
  );

  useEffect(() => {
    const viewport = getViewport();

    if (!viewport) {
      throw new Error('Raster overlay tool markers cannot be rendered. Viewport undefined.');
    }

    const { heightM, rotationDeg, corners, center } = calculateRenderProps(viewport);

    // Need to account for bearing to get the correct angle of rotation
    const overlayRotation = rotationDeg - (viewState?.bearing || 0);

    setLayer(
      OVERLAY_LAYER_ID,
      buildIdsLayer(
        new InvertedIconLayer({
          id: OVERLAY_LAYER_ID,
          data: EMPTY_DATA,
          iconAtlas: preview.url,
          iconMapping: {
            preview: {
              x: 0,
              y: 0,
              width: preview.width,
              height: preview.height,
            },
          },
          billboard: false,
          alphaCutoff: 0,
          getPosition: () => [center.longitude, center.latitude],
          // Need to account for bearing to get the correct angle of rotation
          getAngle: () => overlayRotation,
          getIcon: () => 'preview',
          getSize: heightM,
          sizeUnits: 'meters', // setting size to meters allows autoscaling with zoom
          onClick: onPreviewClick,
          invertColor: xrayModeActive,
          opacity: xrayModeActive ? XRAY_OPACITY : 1,
          pickable: !uploading,
          updateTriggers: {
            getPosition: center,
            getSize: heightM,
            getAngle: overlayRotation,
          },
        }),
        { type: LAYER_TYPE.TOOL, position: 0 },
      ),
    );

    const cornersPath = Object.values(corners).map((d: Coordinate) => [d.longitude, d.latitude]);
    cornersPath.push(cornersPath[0]); // close the loop

    setLayer(
      OVERLAY_OUTLINE_LAYER_ID,
      buildIdsLayer(
        new PathLayerNoType({
          id: OVERLAY_OUTLINE_LAYER_ID,
          data: [cornersPath],
          getPath: (d: number[][]) => d,
          widthScale: 3,
          widthMinPixels: 3,
          widthUnits: 'pixels',
          getColor: [72, 196, 241, 100],
        }),
        { type: LAYER_TYPE.TOOL, position: 1 },
      ),
    );

    setLayer(
      OVERLAY_ALIGNMENT_MARKERS_LAYER_ID,
      buildRasterOverlayAlignmentMarkerLayer(
        markers,
        {
          pickable: !uploading,
          onDragStart: onMarkerDragStart,
          onDrag: onMarkerDrag,
          onDragEnd: onMarkerDragEnd,
          getSize: ALIGNMENT_MARKER_SIZE,
        },
        { position: 2 }, // position over overlay
      ),
    );
    // For some reason, excluding viewState.bearing from deps still works and works more smoothly than including it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setLayer,
    preview,
    markers,
    onMarkerDragStart,
    onMarkerDrag,
    onMarkerDragEnd,
    getViewport,
    onPreviewClick,
    calculateRenderProps,
    xrayModeActive,
    uploading,
  ]);

  useMountedEffect(() => {
    return () => {
      // Destroy layers on unmount
      setLayers([
        { id: OVERLAY_LAYER_ID, layer: null },
        { id: OVERLAY_OUTLINE_LAYER_ID, layer: null },
        { id: OVERLAY_ALIGNMENT_MARKERS_LAYER_ID, layer: null },
      ]);
    };
  });

  const statusBarId = useMemo(() => `${uppy.getID()}-statusBar`, [uppy]);

  const removeStatusBar = useCallback(() => {
    const statusBar = uppy.getPlugin(statusBarId);

    if (statusBar) {
      uppy.removePlugin(statusBar);
    }
  }, [statusBarId, uppy]);

  useEffect(() => {
    if (!uploading) {
      // Status bar cannot be in use if target is not mounted
      removeStatusBar();
    }
  }, [uploading, removeStatusBar]);

  useMountedEffect(() => {
    return removeStatusBar;
  });

  const handleMapLockActiveChange = useCallback(
    (active: boolean) => {
      // NOTE: if these are being manipulated elsewhere, these could be overwritten
      setController({ dragPan: !active, dragRotate: !active, scrollZoom: !active });
      setMapLockActive(active);
    },
    [setController, setMapLockActive],
  );

  const handleEdit = useCallback(() => {
    onEdit(markers);
  }, [onEdit, markers]);

  const handleSave = useCallback(() => {
    const viewport = getViewport();
    if (!viewport) {
      throw new Error('Raster overlay cannot be saved. Viewport undefined.');
    }
    const { corners } = calculateRenderProps(viewport);
    onSave(corners, markers);
  }, [onSave, getViewport, calculateRenderProps, markers]);

  useMountedEffect(() => {
    // Reenable map interactions before unmount
    return () => handleMapLockActiveChange(false);
  });

  return (
    <MapOverlayNoType className={styles.placementPanel}>
      <Stack direction='column' justifyContent='start' alignItems='start' gap={1}>
        {overlayInfoBoxVisible && (
          <InfoBox
            icon={<InfoIcon color='info' />}
            onClose={handleHelpClick}
            content={
              <p style={{ padding: 0, margin: 0 }}>
                Drag the markers to align the overlay. <br />
                Click on the overlay to reposition the markers.
              </p>
            }
            color='info.main'
            sx={undefined}
            className={styles.placementPanelInfoBox}
            title={undefined}
          />
        )}

        <Paper elevation={3}>
          <Grid
            container
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
            justifyContent='flex-start'
            alignItems='flex-start'
            p={1}
            gap={1}
          >
            <Grid item xs='auto'>
              <Stack direction='column' className={styles.placementPanelTextInfo}>
                <Typography variant='body1'>{overlayName}</Typography>
                <Typography variant='body2' color='text.secondary'>
                  {levelName}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs='auto'>
              <Tooltip title='Edit'>
                <IconButton size='small' onClick={handleEdit} data-testid='editBtn'>
                  <EditOutlineIcon />
                </IconButton>
              </Tooltip>
              <OverlayToggleButton
                active={xrayModeActive}
                activeIcon={<XRayModeOffIcon />}
                activeTooltip='Turn off x-ray mode'
                inactiveIcon={<XRayModeOnIcon />}
                inactiveTooltip='Turn on x-ray mode'
                setActive={setXRayModeActive}
                data-testid='xrayModeBtn'
              />
              <OverlayToggleButton
                active={mapLockActive}
                activeIcon={<UnlockIcon />}
                activeTooltip='Unlock map'
                inactiveIcon={<LockIcon />}
                inactiveTooltip='Lock map'
                setActive={handleMapLockActiveChange}
                data-testid='mapLockBtn'
              />
              <Tooltip title='Help'>
                <IconButton size='small' onClick={handleHelpClick} data-testid='helpBtn'>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Save'>
                <IconButton
                  size='small'
                  color='secondary'
                  onClick={handleSave}
                  disabled={isSaving}
                  data-testid='uploadBtn'
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Close'>
                <IconButton size='small' sx={sx.cancel} onClick={onClose} data-testid='closeBtn'>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </MapOverlayNoType>
  );
};

export default RasterOverlayPlacement;
