import React from 'react';
import { DialogActions } from '@mui/material';

import CancelButton from '../../ids-buttons/CancelButton';
import IdsForm, { IIdsFormProps } from '../IdsForm';
import CreateButton from '../../ids-buttons/CreateButton';

export interface IIdsCreateFormProps extends IIdsFormProps {
  onCancel?: () => any;
}

const IdsCreateForm: React.FC<IIdsCreateFormProps> = ({ children, onCancel, ...rest }) => {
  return (
    <IdsForm {...rest}>
      {children}
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <CreateButton />
      </DialogActions>
    </IdsForm>
  );
};

export default IdsCreateForm;
