import React, { useCallback } from 'react';

import IdsColorSelect from '../../ids-inputs/IdsColorSelect';
import IdsFormElement from '../IdsFormElement';
import { IIdsColorSelectProps } from '../../ids-inputs/IdsColorSelect';

// No need in onChange, onBlur, onFocus and value props
// Because this component will use formik context
type ParentType = Omit<IIdsColorSelectProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'>;

export interface IIdsFormColorFieldProps extends ParentType {
  name: string;

  onChange?: IIdsColorSelectProps['onChange'];
}

const IdsFormColorField: React.FC<IIdsFormColorFieldProps> = ({ name, onChange, ...rest }) => {
  const handleChange = useCallback(
    (value, formikProps) => {
      const { setFieldValue } = formikProps;
      setFieldValue(name, value);

      if (onChange) {
        onChange(value);
      }
    },
    [name, onChange],
  );

  return (
    <IdsFormElement
      render={formikProps => {
        const { values, errors, touched } = formikProps;
        return (
          <IdsColorSelect
            name={name}
            error={!!(touched[name] && errors[name])}
            value={values && values[name]}
            fullWidth
            size='small'
            onChange={value => handleChange(value, formikProps)}
            {...rest}
          />
        );
      }}
    />
  );
};

export default IdsFormColorField;
