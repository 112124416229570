import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import useGraphQuery, { UseGraphQueryOptions } from '../hooks/useGraphQuery';
import { buildGraphMutationFn } from '../hooks/useGraphMutation';
import { useQueryKeyId } from '../hooks/useOrgGraphQuery';

import { IMediaMetadata, RoutePointType } from '../constants/media';

import queryClient from '../utils/query';

import { IProjectRoutesListData, useProjectKeys } from './ProjectService';
import { ProjectRouteListItemFrag, IProjectRouteListItemData } from './fragments';
import {
  ICustomFieldMetadataItemInput,
  ILatLngAltInput,
  IWhereUniqueIdOrganizationInput,
  MediaMetadata,
  UserError,
} from './types';

const onError = (_: any, __: any, context: any) => {
  context?.mutation?.reset();
};

export const useRouteKeys = () => {
  const queryIdKey = useQueryKeyId();
  const projectKeys = useProjectKeys();

  return useMemo(() => {
    const routeKeys = {
      all: ['keys', ...queryIdKey],
      route: (projectId: string, routeId: string | null) => [
        ...projectKeys.detail(projectId),
        `routeDetails-${routeId}`,
      ],
      detail: (projectId: string, routeId: string) => [
        ...routeKeys.route(projectId, routeId),
        'detail',
      ],
    };

    return routeKeys;
  }, [queryIdKey, projectKeys]);
};

export enum RouteStatus {
  'new' = 'new',
  'inProgress' = 'inProgress',
  'completed' = 'completed',
}

export interface IRoutePointLevel {
  id: string;
  value: string;
}

const RouteRouteQuery = gql`
  query RouteRoute($routeId: ID!, $organizationId: ID!, $projectId: ID!) {
    route(id: $routeId, organizationId: $organizationId, projectId: $projectId) {
      id
      projectId
    }
  }
`;

export interface IRouteRouteData {
  route: {
    id: string;
    projectId: string;
  };
}

export const useGetRouteRoute = (
  routeId: string | null,
  projectId: string,
  organizationId: string,
  options: UseGraphQueryOptions,
) => {
  const routeKeys = useRouteKeys();

  return useGraphQuery<IRouteRouteData>(
    !!routeId ? routeKeys.route(projectId, routeId) : '',
    RouteRouteQuery,
    {
      projectId,
      organizationId,
      routeId,
    },
    {
      enabled: !!routeId,
      ...options,
    },
  );
};

const RouteDetailsFrag = gql`
  fragment RouteDetailsFrag on Route {
    id
    name
    description
    position
    status
    projectId
    published
    levels {
      id
      value
    }
    routePoints {
      id
      type
      extraImage
      metadata {
        id
        type
        value
      }
      position {
        latitude
        longitude
        heading
      }
      images {
        id
      }
      notes {
        id
        notes
      }
    }
  }
`;

const RouteDetailsQuery = gql`
  query RouteDetails($routeId: ID!, $organizationId: ID!, $projectId: ID!) {
    route(id: $routeId, organizationId: $organizationId, projectId: $projectId) {
      ...RouteDetailsFrag
    }
  }
  ${RouteDetailsFrag}
`;

export interface IRoutePointDetails {
  id: string;
  type: RoutePointType;
  extraImage: boolean;
  position: {
    latitude: number;
    longitude: number;
    heading?: number;
  };
  images: {
    id: string;
  }[];
  notes: {
    id: string;
    notes: string;
  }[];
  metadata: IMediaMetadata[];
}

export interface IRouteDetails {
  id: string;
  name: string;
  description?: string;
  position: string;
  projectId: string;
  published: boolean;
  status: RouteStatus;
  levels: IRoutePointLevel[];
  routePoints: IRoutePointDetails[];
}

export interface IRouteDetailsData {
  route: IRouteDetails;
}

export const useGetRouteDetails = (
  routeId: string,
  projectId: string,
  organizationId: string,
  options?: UseGraphQueryOptions,
) => {
  const routeKeys = useRouteKeys();

  return useGraphQuery<IRouteDetailsData>(
    routeKeys.detail(projectId, routeId),
    RouteDetailsQuery,
    {
      projectId,
      organizationId,
      routeId,
    },
    options,
  );
};

const PublishRouteMutation = gql`
  mutation PublishRoute($where: WhereUniqueIdOrganizationInput!, $input: PublishRouteInput!) {
    publishRoute(where: $where, input: $input) {
      errors {
        field
        message
      }
      route {
        id
        projectId
        published
      }
    }
  }
`;

interface IPublishRouteInput {
  published: boolean;
}

interface IPublishRouteOutput {
  publishRoute?: {
    route?: {
      id: string;
      projectId: string;
      published: boolean;
    };
    errors?: UserError[];
  };
}

const togglePublishRouteMutation = ({
  id,
  organizationId,
  published,
}: IWhereUniqueIdOrganizationInput & IPublishRouteInput) => {
  return buildGraphMutationFn(PublishRouteMutation)({
    where: {
      id,
      organizationId,
    },
    input: {
      published,
    },
  });
};

export const useTogglePublishRoute = () => {
  const projectKeys = useProjectKeys();
  const routeKeys = useRouteKeys();

  return useMutation(togglePublishRouteMutation, {
    onError,
    onSuccess: async (result: IPublishRouteOutput) => {
      const updatedRoute = result?.publishRoute?.route;

      if (updatedRoute && !result.publishRoute?.errors?.length) {
        const { id, projectId } = updatedRoute;

        const isProjectRoutesCached = !!queryClient.getQueryState(projectKeys.routes(projectId));

        const isRouteDetailCached = !!queryClient.getQueryState(routeKeys.detail(projectId, id));

        if (isProjectRoutesCached) {
          // Routes list
          await queryClient.setQueryData<IProjectRoutesListData | undefined>(
            projectKeys.routes(projectId),
            oldData => {
              if (!oldData) {
                return oldData;
              }

              return {
                project: {
                  routes: oldData.project.routes.map(cachedRoute => {
                    if (cachedRoute.id === updatedRoute.id) {
                      return {
                        ...cachedRoute,
                        ...updatedRoute,
                      };
                    }

                    return cachedRoute;
                  }),
                },
              };
            },
          );
        }

        if (isRouteDetailCached) {
          // Route details
          await queryClient.setQueryData<IRouteDetailsData | undefined>(
            routeKeys.detail(projectId, id),
            oldData => {
              if (!oldData) {
                return oldData;
              }

              return {
                route: {
                  ...oldData.route,
                  published: updatedRoute.published,
                },
              };
            },
          );
        }
      }
    },
  });
};

interface IAddRoutePointInput {
  type: RoutePointType;
  position: ILatLngAltInput;
  metadata: MediaMetadata[];
  customFieldMetadata: ICustomFieldMetadataItemInput[];
}

interface IDeleteRoutePointInput {
  id: string;
}

interface IUpdateRoutePointInput
  extends Pick<IAddRoutePointInput, 'position' | 'metadata' | 'customFieldMetadata'> {
  id: string;
}

interface IManageRoutePointsInput {
  add: IAddRoutePointInput[];
  delete: IDeleteRoutePointInput[];
  update: IUpdateRoutePointInput[];
}

export interface IManageRoutePointsOutput {
  manageRoutePoints?: {
    route?: IRouteDetails;
    errors?: UserError[];
  };
}

const ManageRoutePointsMutation = gql`
  mutation ManageRoutePoints(
    $where: WhereUniqueIdOrganizationInput!
    $input: ManageRoutePointsInput!
  ) {
    manageRoutePoints(where: $where, input: $input) {
      errors {
        field
        message
      }
      route {
        ...RouteDetailsFrag
      }
    }
  }
  ${RouteDetailsFrag}
`;

const buildManageRoutePointsMutation = ({
  id,
  organizationId,
  ...input
}: IWhereUniqueIdOrganizationInput & IManageRoutePointsInput) => {
  return buildGraphMutationFn(ManageRoutePointsMutation)({
    where: {
      id,
      organizationId,
    },
    input,
  });
};

const mapRoutePoint = ({ id, images, notes }: IRoutePointDetails) => ({ id, images, notes });

export const useManageRoutePointsMutation = () => {
  const projectKeys = useProjectKeys();
  const routeKeys = useRouteKeys();

  return useMutation(buildManageRoutePointsMutation, {
    onError,
    onSuccess: async (result: IManageRoutePointsOutput) => {
      if (
        !result.manageRoutePoints ||
        !result.manageRoutePoints.route ||
        result.manageRoutePoints.errors?.length
      ) {
        return;
      }

      const updatedRoute = result.manageRoutePoints.route;

      const { id, projectId } = updatedRoute;

      // Route details
      await queryClient.setQueryData<IRouteDetailsData | undefined>(
        routeKeys.detail(projectId, id),
        () => {
          return {
            route: result.manageRoutePoints!.route!,
          };
        },
      );

      const isProjectRoutesCached = !!queryClient.getQueryState(projectKeys.routes(projectId));

      if (!isProjectRoutesCached) {
        /**
         * Skip setQueryData if there is no cache for this query.
         * Otherwise, an empty cache will be set
         */
        return;
      }

      // Routes list
      await queryClient.setQueryData<IProjectRoutesListData | undefined>(
        projectKeys.routes(projectId),
        oldData => {
          if (!oldData) {
            return oldData;
          }

          return {
            project: {
              routes: oldData.project.routes.reduce((accumulator, route) => {
                if (route.id === id) {
                  // Update cache of the edited route
                  accumulator.push({
                    // Rest fields are non-editable as for now
                    ...route,
                    status: updatedRoute.status,
                    published: updatedRoute.published,
                    routePoints: updatedRoute.routePoints.map(mapRoutePoint),
                  });
                } else {
                  // Keep other routes as they are
                  accumulator.push(route);
                }

                return accumulator;
              }, [] as IProjectRouteListItemData[]),
            },
          };
        },
      );
    },
  });
};

interface ICopyRouteInput {
  destinationProjectId: string;
}

interface ICopyRouteOutput {
  copyRoute: {
    route?: IProjectRouteListItemData;
    errors?: UserError[];
  };
}

const CopyRouteMutation = gql`
  mutation CopyRoute($where: WhereUniqueIdOrganizationInput!, $input: CopyRouteInput!) {
    copyRoute(where: $where, input: $input) {
      route {
        ...ProjectRouteListItemFrag
      }
      errors {
        message
        field
      }
    }
  }
  ${ProjectRouteListItemFrag}
`;

const buildCopyRouteMutation = ({
  id,
  organizationId,
  ...input
}: IWhereUniqueIdOrganizationInput & ICopyRouteInput) => {
  return buildGraphMutationFn(CopyRouteMutation)({
    where: {
      id,
      organizationId,
    },
    input,
  });
};

export const useCopyRoute = () => {
  const projectKeys = useProjectKeys();

  return useMutation(buildCopyRouteMutation, {
    onError,
    onSuccess: async (result: ICopyRouteOutput, { id, destinationProjectId }) => {
      if (!result.copyRoute || !result.copyRoute.route || result.copyRoute.errors?.length) {
        return;
      }

      const isProjectRoutesCached = !!queryClient.getQueryState(
        projectKeys.routes(destinationProjectId),
      );

      if (isProjectRoutesCached) {
        // Routes list
        await queryClient.setQueryData<IProjectRoutesListData | undefined>(
          projectKeys.routes(destinationProjectId),
          oldData => {
            if (!oldData) {
              return oldData;
            }

            const copiedRoute = oldData.project.routes.find(r => r.id === id);
            if (!copiedRoute) {
              return oldData;
            }

            const updatedRoutes = [...oldData.project.routes, result.copyRoute.route!];

            return {
              project: {
                routes: updatedRoutes,
              },
            };
          },
        );
      }
    },
  });
};

export interface IUpdateRouteInput {
  name: string;
  notes?: string;
  description?: string;
}

export interface IUpdateRouteOutput {
  updateRoute?: {
    errors?: UserError[];
    route?: {
      name: string;
      projectId: string;
      notes?: string;
      description?: string;
    };
  };
}

const UpdateRouteMutation = gql`
  mutation UpdateRoute($where: WhereUniqueIdOrganizationInput!, $input: UpdateRouteInput!) {
    updateRoute(where: $where, input: $input) {
      errors {
        message
        field
      }
      route {
        name
        notes
        description
        projectId
      }
    }
  }
`;

const buildUpdateRouteMutation = ({
  id,
  organizationId,
  ...input
}: IWhereUniqueIdOrganizationInput & IUpdateRouteInput) => {
  return buildGraphMutationFn(UpdateRouteMutation)({
    where: {
      id,
      organizationId,
    },
    input,
  });
};

export const useUpdateRoute = () => {
  const projectKeys = useProjectKeys();
  const routeKeys = useRouteKeys();

  return useMutation(buildUpdateRouteMutation, {
    onError,
    onSuccess: async (result: IUpdateRouteOutput, { id }) => {
      if (!result.updateRoute || !result.updateRoute.route || result.updateRoute.errors?.length) {
        return;
      }

      const updatedRoute = result.updateRoute.route;

      const isProjectRoutesCached = !!queryClient.getQueryState(
        projectKeys.routes(updatedRoute.projectId),
      );

      const isRouteDetailCached = !!queryClient.getQueryState(
        routeKeys.detail(updatedRoute.projectId, id),
      );

      if (isProjectRoutesCached) {
        // Routes list
        await queryClient.setQueryData<IProjectRoutesListData | undefined>(
          projectKeys.routes(updatedRoute.projectId),
          oldData => {
            if (!oldData) {
              return oldData;
            }

            return {
              project: {
                routes: oldData.project.routes.map(cachedRoute => {
                  if (cachedRoute.id === id) {
                    return {
                      ...cachedRoute,
                      ...updatedRoute,
                    };
                  }

                  return cachedRoute;
                }),
              },
            };
          },
        );
      }

      if (isRouteDetailCached) {
        // Route details
        await queryClient.setQueryData<IRouteDetailsData | undefined>(
          routeKeys.detail(updatedRoute.projectId, id),
          oldData => {
            if (!oldData) {
              return oldData;
            }

            return {
              route: {
                ...oldData.route,
                ...updatedRoute,
              },
            };
          },
        );
      }
    },
  });
};
