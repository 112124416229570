import React from 'react';
import { gql } from 'graphql-request';

import { useRecoilState, useRecoilValue } from 'recoil';

import useOrgGraphQuery from '../../hooks/useOrgGraphQuery';
import { useAssignmentResponseKeys } from '../../services/AssignmentResponsesService';
import { activeAssignmentResponseState } from '../../atoms/assignmentResponses';
import { activeOrganizationState } from '../../atoms/organizations';
import { activeTenantState } from '../../atoms/tenants';

import { getOrgAssignmentsRoute } from '../../utils/routes';

import EntityRoute from './EntityRoute';

const AssignmentResponseRouteQuery = gql`
  query AssignmentResponseRoute($orgId: ID, $id: ID!, $tenantId: ID) {
    assignmentResponse(organizationId: $orgId, id: $id, tenantId: $tenantId) {
      id
    }
  }
`;

function AssignmentRoute() {
  const assignmentResponseKeys = useAssignmentResponseKeys();

  const [assignmentResponse, setAssignmentResponse] = useRecoilState(activeAssignmentResponseState);
  const activeTenant = useRecoilValue(activeTenantState);
  const org = useRecoilValue(activeOrganizationState);

  // Query is used to hook into ACL, can user access this data? Routes away if they can't
  const useAssignmentResponse = (id, options) =>
    useOrgGraphQuery(
      assignmentResponseKeys.route(id),
      AssignmentResponseRouteQuery,
      { id },
      options,
    );

  return (
    <EntityRoute
      idParam={'assignmentResponseId'}
      currentEntity={assignmentResponse}
      setEntity={setAssignmentResponse}
      useEntity={useAssignmentResponse}
      entitySelector={data => data?.assignmentResponse}
      errorRedirectRoute={getOrgAssignmentsRoute({
        subdomain: activeTenant.subdomain,
        orgId: org.id,
      })}
    />
  );
}

export default AssignmentRoute;
