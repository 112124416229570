import React from 'react';
import { useRecoilValue } from 'recoil';
import { Box, FormHelperText } from '@mui/material';

import { activeProjectState } from '../../../../../../../../atoms/projects';
import { activeOrganizationState } from '../../../../../../../../atoms/organizations';

import IdsCreateForm, {
  IIdsCreateFormProps,
} from '../../../../../../../../components/ids-forms/IdsCreateForm';
import IdsFormTextField from '../../../../../../../../components/ids-forms/IdsFormTextField';
import {
  ICreateProjectRouteInput,
  useCreateProjectRoute,
} from '../../../../../../../../services/ProjectService';

import { validationSchema } from './validationSchema';
import RouteDescriptionFormField from './RouteDescriptionFormField';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateRouteFormProps extends Pick<IIdsCreateFormProps, 'onCancel'> {}

const errorHandler = () => 'The route cannot be created';
const initialValues = {
  name: '',
  description: '',
  notes: '',
};

const CreateRouteForm: React.FC<ICreateRouteFormProps> = ({ onCancel }) => {
  const activeProject = useRecoilValue(activeProjectState);
  const activeOrg = useRecoilValue(activeOrganizationState);
  const createRouteMutation = useCreateProjectRoute();

  const onSubmit = async (values: ICreateProjectRouteInput) => {
    const result = await createRouteMutation.mutateAsync({
      id: activeProject!.id,
      organizationId: activeOrg!.id,
      description: values.description,
      notes: values.notes,
      name: values.name,
    });

    if (result?.createRoute?.errors?.length) {
      const field = result.createRoute.errors[0].field;
      const message = result.createRoute.errors[0].message;

      throw new Error(`${field} ${message}`);
    }

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <IdsCreateForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      successMessage='The route was created'
      errorHandler={errorHandler}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <Box p={2}>
        <IdsFormTextField name='name' label='Name' required autoFocus />
        <RouteDescriptionFormField
          name='description'
          label='Description'
          multiline
          margin='normal'
        />
        <Box>
          <IdsFormTextField name='notes' label='Notes' multiline />
          <FormHelperText>
            'Notes' section will be displayed as a pop up before capture users starts the route.
          </FormHelperText>
        </Box>
      </Box>
    </IdsCreateForm>
  );
};

export default CreateRouteForm;
