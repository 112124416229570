import React, { useCallback, useEffect } from 'react';
import { Button, ButtonProps, SvgIcon } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { OpenInNewIcon } from '../../../theme/icons';
import useLegacyViewer from '../../../hooks/useLegacyViewer';

const styles = {
  actionIcon: (theme: Theme) => ({
    marginRight: theme.spacing(1),
  }),
};

export interface IOpenLegacyViewerButtonProps extends ButtonProps {
  route?: string;
  defaultOpen?: boolean;
}

const OpenLegacyViewerButton: React.FC<IOpenLegacyViewerButtonProps> = ({
  route,
  defaultOpen,
  ...rest
}) => {
  const { openLegacyViewer } = useLegacyViewer();

  const onClick = useCallback(() => {
    openLegacyViewer(route);
  }, [openLegacyViewer, route]);

  useEffect(() => {
    if (defaultOpen) {
      onClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button variant='contained' onClick={onClick} {...rest}>
      <SvgIcon fontSize='small' sx={styles.actionIcon}>
        <OpenInNewIcon />
      </SvgIcon>
      Open in Legacy Viewer
    </Button>
  );
};

export default OpenLegacyViewerButton;
