import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import IdsFormAutocompleteField, {
  IIdsFormAutocompleteFieldProps,
} from '../../../../../components/ids-forms/IdsFormAutocompleteField';
import {
  getNodeOptionName,
  getNodeOptionValue,
} from '../../../../../components/ids-forms/common/IdsOrgProjectsAutocompleteField/helpers';
import { EntityParentType } from '../../../../../components/entity-chips/EntityParentChip';

import { IOrganizationAssignmentsListEdge } from '../../../../../services/AssignmentsService';
import { IProjectFrag } from '../../../../../services/ProjectService';

import { searchProjectItem } from '../../../../Projects/ProjectsList';

import useGetProjects from './useGetProjects';

type Option = { node: IProjectFrag };

export interface IProjectsAutocompleteFieldProps
  extends Omit<IIdsFormAutocompleteFieldProps, 'options'> {
  assignment: {
    node: IOrganizationAssignmentsListEdge['node'];
  };

  onOptionsLoaded?: (options: Option[]) => void;
}

/**
 * Replicates searching from the projects list.
 */
const filterOptions: IIdsFormAutocompleteFieldProps['filterOptions'] = (
  options: Option[],
  state,
) => {
  if (!state.inputValue) {
    return options;
  }

  const queryLowerCase = state.inputValue.toLowerCase();

  return options.filter(o => {
    return o.node.id.includes(queryLowerCase) || searchProjectItem(o, state.inputValue);
  });
};

const ProjectsAutocompleteField: React.FC<IProjectsAutocompleteFieldProps> = ({
  onOptionsLoaded,
  assignment,
  name,
  disabled,
  multiple,
  ...rest
}) => {
  const { setFieldValue, values, setFieldError, errors } = useFormikContext<Record<string, any>>();

  const [options, setOptions] = useState<Option[]>([]);

  const { data, isLoading, error } = useGetProjects(assignment);

  useEffect(() => {
    if (!options.length || !values[name]) {
      return;
    }

    /**
     * Every selected value must exist in the options.
     *
     * A value may be missing from the options if it is incorrectly
     * predefined via initialValues.
     */
    if (multiple) {
      if (!values[name].every((v: string) => options.some(o => o.node.id === v))) {
        setFieldError(name, 'Invalid project provided');
      }
    } else {
      if (!options.some(o => o.node.id === values[name])) {
        setFieldError(name, 'Invalid project provided');
      }
    }
  }, [setFieldError, values, options, name, multiple]);

  useEffect(() => {
    if (onOptionsLoaded) {
      onOptionsLoaded(options);
    }
  }, [onOptionsLoaded, options]);

  useEffect(() => {
    if (Array.isArray(data)) {
      if (data.length === 1) {
        // If only 1 option, auto select
        const value = multiple ? [data[0].node.id] : data[0].node.id;
        setFieldValue(name, value);
      }

      setOptions(data);

      return;
    }

    /**
     * This assignment has project as a parent entity, so auto select it.
     */
    if (data && assignment.node.parentType === EntityParentType.Project) {
      setFieldValue(name, multiple ? [data.project.id] : data.project.id);
      setOptions([{ node: data.project }]);
    }
  }, [name, data, setFieldValue, multiple, assignment.node.parentType]);

  const textFieldProps = useMemo(() => {
    const error = errors[name];

    return {
      helperText: errors ? error : null,
      error: errors ? Boolean(error) : undefined,
      name,
    };
  }, [errors, name]);

  return (
    <IdsFormAutocompleteField
      filterOptions={filterOptions}
      name={name}
      label='Projects'
      loading={isLoading}
      disabled={disabled || isLoading}
      options={options}
      getOptionLabel={getNodeOptionName}
      getOptionValue={getNodeOptionValue}
      noOptionsText={error ? 'Projects failed to load' : 'No projects found'}
      multiple={multiple}
      disableCloseOnSelect
      textFieldProps={textFieldProps}
      {...rest}
    />
  );
};

export default ProjectsAutocompleteField;
