import { useRecoilValue } from 'recoil';

import React, { useMemo } from 'react';

import { Card, Divider } from '@mui/material';

// import globalSx from '../../../theme/globalSx';
import IdsTabsRenderer from '../../../components/IdsTabsRenderer';
import TenantPage from '../TenantPage';

import { activeTenantState } from '../../../atoms/tenants';
import globalSx from '../../../theme/globalSx';

import TenantDetailActions from './TenantDetailActions';
import TenantDetailsTab from './DetailsTab';
// import TenantMetadataTab from './MetadataTab';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantDetailsPage {}

const TenantDetails: React.FC<ITenantDetailsPage> = () => {
  const tenant = useRecoilValue(activeTenantState);

  const tabs = useMemo(
    () => [
      {
        key: 'details',
        label: 'Details',
        content: <TenantDetailsTab />,
      },
      // {
      //   key: 'metadata',
      //   label: 'Metadata',
      //   content: <TenantMetadataTab />,
      // },
    ],
    [],
  );

  return (
    <TenantPage title={tenant!.name} actions={<TenantDetailActions />}>
      <Card sx={globalSx.pageContent}>
        <IdsTabsRenderer tabs={tabs} contentHeader={<Divider />} />
      </Card>
    </TenantPage>
  );
};
export default TenantDetails;
