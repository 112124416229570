import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import IdsFormTextField from '../../../../../../components/ids-forms/IdsFormTextField';
import ImageMarkerForm, { IImageMarkerFormProps } from '../ImageMarkerForm';
import { MediaType } from '../../../../../../constants/media';
import useImmersiveViewer from '../../../../../../hooks/useImmersiveViewer';
import {
  isLatitudeValid,
  isLongitudeValid,
  isHeadingValid,
} from '../../../../../../utils/geospatial';
import usePrevious from '../../../../../../hooks/usePrevious';
import { buildHeadingArrowLayer } from '../../../../../../components/mapping/layers/heading';
import { MARKER_POSITION_STATE_ID } from '../ImageMarkerForm';
import { useObservableItemValue } from '../../../../../../hooks/useObservableStates';
import { IMarkerPosition } from '../../../../../../hooks/useMapMarkerPlacementTool';
import { LAYER_TYPE } from '../../../../../../context/ImmersiveViewerContext';

const tryGetNumberFieldVal = (value?: string | null) => {
  if (!value || typeof value === 'number') {
    return value;
  }

  const numVal = parseInt(value); // must be an integer
  return isNaN(numVal) ? '' : numVal;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPanoramicPhotoMarkerFormProps
  extends Omit<IImageMarkerFormProps, 'requiresAltitude' | 'customFormFields' | 'imageType'> {}

interface IPanoramicPhotoMarkerFormCustomFieldsProps {
  uploading: boolean;
}

const PanoramicPhotoMarkerFormCustomFields: React.FC<
  IPanoramicPhotoMarkerFormCustomFieldsProps
> = ({ uploading }) => {
  const { setLayer, getItemState, setItemState, addItemStateListener, removeItemStateListener } =
    useImmersiveViewer();
  const { values } = useFormikContext<any>();
  const { heading } = values;
  const prevHeading = usePrevious(heading);

  const { latitude, longitude } =
    useObservableItemValue<IMarkerPosition>({
      id: MARKER_POSITION_STATE_ID,
      getItemState,
      setItemState,
      addItemStateListener,
      removeItemStateListener,
    }) || {};

  useEffect(() => {
    const positionValid = isLatitudeValid(latitude) || isLongitudeValid(longitude);

    const _heading = isHeadingValid(heading)
      ? heading
      : isHeadingValid(prevHeading)
      ? prevHeading
      : 0;

    setLayer(
      'panoramic-heading',
      buildHeadingArrowLayer(
        [longitude, latitude],
        _heading,
        { visible: positionValid },
        {
          type: LAYER_TYPE.TOOL,
          position: 0,
        },
      ),
    );
  }, [latitude, longitude, setLayer, heading, prevHeading]);

  return (
    <IdsFormTextField
      name='heading'
      label='Heading'
      required
      transformBlurValue={tryGetNumberFieldVal}
      disabled={uploading}
    />
  );
};

const PanoramicPhotoMarkerForm: React.FC<IPanoramicPhotoMarkerFormProps> = props => {
  return (
    <ImageMarkerForm
      {...props}
      imageType={MediaType.PanoramicPhoto}
      renderCustomFormFields={(uploading: boolean) => (
        <PanoramicPhotoMarkerFormCustomFields uploading={uploading} />
      )}
      showAltitude={true}
    />
  );
};

export default PanoramicPhotoMarkerForm;
