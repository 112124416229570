import { useRecoilValue } from 'recoil';
import React, { useCallback, useState } from 'react';

import { IDS_LOGO_VARIANTS_URL } from '../../constants/idsLogo';
import { PHYGII_LOGO_VARIANTS_URL } from '../../constants/phygiiLogo';
import isPhygii from '../../utils/phygii/isPhygii';

import { activeOrganizationState } from '../../atoms/organizations';
import IdsImage, { IIdsImageProps } from '../IdsImage';

import styles from './ActiveOrgLogo.module.css';

export interface IActiveOrgLogoProps extends IIdsImageProps {
  fallbackLogo: keyof typeof IDS_LOGO_VARIANTS_URL | keyof typeof PHYGII_LOGO_VARIANTS_URL;
}

/**
 * Shows active organization logo.
 * If the logo failed to load, it shows IDS logo instead.
 *
 * @TODO: Consider implementing this solution https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture
 */
const ActiveOrgLogo: React.FC<IActiveOrgLogoProps> = ({
  height,
  width,
  fallbackLogo,
  ...props
}) => {
  const activeOrg = useRecoilValue(activeOrganizationState);
  const [logoUrl, setLogoUrl] = useState<string | null>(activeOrg?.logo?.url);

  const phygii = isPhygii();

  const onError = useCallback(() => {
    const url = phygii
      ? PHYGII_LOGO_VARIANTS_URL[fallbackLogo]
      : IDS_LOGO_VARIANTS_URL[fallbackLogo];

    setLogoUrl(url);
  }, [fallbackLogo, phygii]);

  const altFallback = phygii ? 'Phygii logo' : 'IDS logo';
  const srcFallback = phygii
    ? PHYGII_LOGO_VARIANTS_URL[fallbackLogo]
    : IDS_LOGO_VARIANTS_URL[fallbackLogo];

  const alt = logoUrl && logoUrl === activeOrg?.logo?.url ? `${activeOrg.name} logo` : altFallback;

  return (
    <IdsImage
      alt={alt}
      src={logoUrl || srcFallback}
      height={height}
      width={width}
      className={styles.logo}
      onError={onError}
      {...props}
    />
  );
};

export default ActiveOrgLogo;
