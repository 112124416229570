import * as Yup from 'yup';

import { headingValidation } from '../../../field-validation/geospatial';
import { MediaMetadataType } from '../../../constants/media';

export const bulkEditValidation = {
  published: Yup.bool().nullable(),
  flagged: Yup.bool().nullable(),
  heading: headingValidation.integer('Must be an integer').nullable(),
  [MediaMetadataType.PhotoCategory]: Yup.string().nullable(),
  [MediaMetadataType.PhotoType]: Yup.string().nullable(),
  [MediaMetadataType.Level]: Yup.string().nullable(),
  [MediaMetadataType.Area]: Yup.string().nullable(),
};

export const bulkEditValidationSchema = Yup.object().shape(bulkEditValidation);
