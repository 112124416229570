import { TableCell } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from '../EntityData.module.css';

export interface ILabelCellProps {
  label: string;
}

const LabelCell: React.FC<ILabelCellProps> = ({ label }) => {
  return (
    <TableCell className={clsx(styles.tableCellVerticalAlignTop, styles.fieldLabelCell)}>
      {label}
    </TableCell>
  );
};

export default LabelCell;
