import React from 'react';
import { gql } from 'graphql-request';
import { Accordion, Grid, Typography, AccordionSummary, AccordionDetails } from '@mui/material';

import { ExpandIcon } from '../../../../../theme/icons';
import IdsDataAttribute from '../../../../../components/IdsDataAttribute';
import {
  AssignmentQuestionTypes,
  AssignmentQuestionConditionTypes,
} from '../../../../../constants/assignments';

export interface IAssignmentQuestionExtra {
  isLabel?: boolean;
  lookup?: {
    limit?: number;
    questionId?: string;
  };
  validation?: {
    // "/^Example_[a-zA-Z0-9]$/"
    regex?: string;
    errorMessage?: string;
  };
}

export interface IAssignmentQuestionFragType {
  id: string;
  title?: string;
  required?: boolean;
  questionType?: AssignmentQuestionTypes;
  description: string;
  repeatable?: boolean;
  conditional: boolean;
  extra: IAssignmentQuestionExtra;
  assignmentQuestionOptions: {
    id: string;
    title: string;
  }[];
  conditionGroups?: {
    parentQuestionId: string;
    id: string;
    conditions?: {
      conditionType: AssignmentQuestionConditionTypes;
      conditionValue: string;
      id: string;
    }[];
  }[];
}

export const AssignmentQuestionFrag = gql`
  fragment AssignmentQuestionFrag on AssignmentQuestion {
    id
    title
    required
    questionType
    description
    repeatable
    conditional
    extra {
      lookup {
        limit
        questionId
      }
      isLabel
      validation {
        regex
        errorMessage
      }
    }
    assignmentQuestionOptions {
      id
      title
    }
    conditionGroups {
      parentQuestionId
      id
      conditions {
        conditionType
        conditionValue
        id
      }
    }
  }
`;

interface IOption {
  id: string;
  title: string;
}

interface IQuestionOptionProps {
  option: IOption;
}

const QuestionOption: React.FC<IQuestionOptionProps> = ({ option }) => {
  return (
    <Grid item>
      <Typography>{`#${option.id}: ${option.title}`}</Typography>
    </Grid>
  );
};

interface IAssignmentQuestionProps {
  question: IAssignmentQuestionFragType;
  conditionalSpacing: string;
}

const AssignmentQuestion: React.FC<IAssignmentQuestionProps> = ({
  question,
  conditionalSpacing,
}) => {
  return (
    <Accordion className={conditionalSpacing}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography>{`${question.conditional ? 'Conditional ' : ''}Question #${question.id}: ${
          question.title
        }`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction='column'>
          <IdsDataAttribute label='Description' value={question.description} />
          <IdsDataAttribute label='Required' value={question.required} />
          <IdsDataAttribute label='Repeatable' value={question.repeatable} />
          <IdsDataAttribute label='Type' value={question.questionType} />
          <IdsDataAttribute label='Is Label' value={question.extra.isLabel} />
          {question.extra.lookup?.questionId && question.extra.lookup?.limit && (
            <IdsDataAttribute
              label='Lookup'
              value={`Last ${
                question.extra.lookup.limit && question.extra.lookup.limit > 1
                  ? `${question.extra.lookup.limit} answers`
                  : 'answer'
              }
                from Question #${question.extra.lookup.questionId}`}
            />
          )}
          {question.extra.validation?.regex && (
            <Grid item>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandIcon />}>
                  <Typography>Validation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction='column'>
                    <IdsDataAttribute label='Regex' value={question.extra.validation.regex} />
                    {question.extra.validation.errorMessage && (
                      <IdsDataAttribute
                        label='Error Message'
                        value={question.extra.validation.errorMessage}
                      />
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {question.conditional && (
            <Grid item>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandIcon />}>
                  <Typography>Conditional Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction='column'>
                    {question.conditionGroups?.map((group, index, array) => (
                      <Grid
                        key={group.id}
                        height={100}
                        pl={2}
                        mb={index === array.length - 1 ? 0 : 4}
                      >
                        <IdsDataAttribute
                          label='Parent Question Id'
                          value={group.parentQuestionId}
                        />
                        {group.conditions?.map(condition => (
                          <Grid key={condition.id} container spacing={2} pt={2}>
                            <IdsDataAttribute
                              label='Condition Type'
                              value={condition.conditionType}
                            />
                            <IdsDataAttribute
                              label='Condition Value'
                              value={condition.conditionValue}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {!!question.assignmentQuestionOptions.length && (
            <Grid item>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandIcon />}>
                  <Typography>Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction='column'>
                    {question.assignmentQuestionOptions.map(o => (
                      <QuestionOption key={o.id} option={o} />
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AssignmentQuestion;
