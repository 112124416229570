export enum UserRole {
  ids_admin = 'ids_admin',
  ids_team = 'ids_team',
  org_admin = 'org_admin',
  org_manager = 'org_manager',
  org_team = 'org_team',
  tenant_admin = 'tenant_admin',
  tenant_team = 'tenant_team',
  contractor_team = 'contractor_team',
  contractor_manager = 'contractor_manager',
  client_manager = 'client_manager',
  client_team = 'client_team',
}

/**
 * Just a 'name' of role
 */
export type UserRoleType = keyof typeof UserRole;

/**
 * Uppercased variants of `UserRoleType`
 */
export type UserRoleTypeUppercase = Uppercase<UserRole>;

export interface UserRolesMapItem {
  id: number | null;
  name: UserRoleType | UserRole;
  canAssign: boolean;
}

export const USER_ROLES: Record<UserRoleTypeUppercase, UserRolesMapItem> = {
  IDS_ADMIN: {
    id: 1,
    name: 'ids_admin',
    canAssign: false,
  },
  IDS_TEAM: {
    id: 2,
    name: 'ids_team',
    canAssign: false,
  },
  ORG_ADMIN: {
    id: 3,
    name: 'org_admin',
    canAssign: true,
  },
  ORG_MANAGER: {
    id: 15,
    name: 'org_manager',
    canAssign: true,
  },
  ORG_TEAM: {
    id: 4,
    name: 'org_team',
    canAssign: true,
  },
  TENANT_ADMIN: {
    id: null,
    name: 'tenant_admin',
    canAssign: false,
  },
  TENANT_TEAM: {
    id: null,
    name: 'tenant_team',
    canAssign: false,
  },

  // canAssign is set to false since these roles may not be needed anymore, we don't want them to be assignable to users,
  // but the types need to be here to be backwards compatible for role based permission checks
  CONTRACTOR_TEAM: {
    id: 5,
    name: 'contractor_team',
    canAssign: false,
  },
  CONTRACTOR_MANAGER: {
    id: 8,
    name: 'contractor_manager',
    canAssign: false,
  },
  CLIENT_MANAGER: {
    id: 13,
    name: 'client_manager',
    canAssign: false,
  },
  CLIENT_TEAM: {
    id: 14,
    name: 'client_team',
    canAssign: false,
  },
};
