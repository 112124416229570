import { useRecoilState, useRecoilValue } from 'recoil';

import { activeOrganizationState } from '../../../atoms/organizations';

import AssignmentResponseDetailsTemplatePage from '../../Assignments/AssignmentResponseDetailsTemplatePage';
import {
  activeProgramAssignmentResponseState,
  activeProgramAssignmentState,
  activeProgramState,
} from '../../../atoms/programs';
import { activeTenantState } from '../../../atoms/tenants';
import {
  getOrgProgramAssignmentRoute,
  getOrgProgramAssignmentsRoute,
  getOrgProgramRoute,
  getOrgProgramsRoute,
} from '../../../utils/routes';

const ProgramAssignmentResponseDetails = () => {
  const activeTenant = useRecoilValue(activeTenantState);
  const [activeOrg] = useRecoilState(activeOrganizationState);
  const [activeProgram] = useRecoilState(activeProgramState);
  const [activeAssignment] = useRecoilState(activeProgramAssignmentState);
  const [activeAssignmentResponse] = useRecoilState(activeProgramAssignmentResponseState);

  if (!activeProgram || !activeAssignment || !activeAssignmentResponse || !activeTenant) {
    return null;
  }

  return (
    <AssignmentResponseDetailsTemplatePage
      assignmentId={activeAssignment.id}
      assignmentResponseId={activeAssignmentResponse.id}
      breadcrumbs={[
        {
          label: 'Programs',
          route: getOrgProgramsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
          }),
        },
        {
          label: activeProgram.name,
          route: getOrgProgramRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            programId: activeProgram.id,
          }),
        },
        {
          label: 'Assignments',
          route: getOrgProgramAssignmentsRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            programId: activeProgram.id,
          }),
        },
        {
          label: activeAssignment.title,
          route: getOrgProgramAssignmentRoute({
            subdomain: activeTenant?.subdomain,
            orgId: activeOrg.id,
            programId: activeProgram.id,
            assignmentId: activeAssignment.id,
          }),
        },
      ]}
    />
  );
};

export default ProgramAssignmentResponseDetails;
