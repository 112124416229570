import { useCallback, useMemo, useState } from 'react';

import usePermissions from '../../../../hooks/usePermissions';

import { BulkActionHandler, IBulkAction } from '../types';
import { ImageType, MediaType } from '../../../../constants/media';
import { getMediaTypeFromUrn, isMediaAnImage } from '../../../../utils/media';
import { USER_ROLES } from '../../../../constants/users';
import { EditOutlineIcon } from '../../../../theme/icons';

export const useLocationImageBulkEdit = () => {
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  const [bulkEditImageUrns, setBulkEditImageUrns] = useState<string[]>([]);

  const imageTypesEditing = useMemo<Record<ImageType, boolean>>(() => {
    const imageTypesPresence: Record<ImageType, boolean> = {
      [MediaType.ProjectPhoto]: false,
      [MediaType.HDPhoto]: false,
      [MediaType.PanoramicPhoto]: false,
    };

    for (let i = 0; i < bulkEditImageUrns.length; i++) {
      const mediaType = getMediaTypeFromUrn(bulkEditImageUrns[i]);
      if (mediaType && isMediaAnImage(mediaType) && !imageTypesPresence[mediaType as ImageType]) {
        imageTypesPresence[mediaType as ImageType] = true;

        // Every type is present, end loop
        if (Object.values(imageTypesPresence).every(bool => bool)) break;
      }
    }

    return imageTypesPresence;
  }, [bulkEditImageUrns]);

  const { userHasOneOfRoles } = usePermissions();

  const canBulkUpdate = useMemo(() => {
    // TODO: consider changing this to permissions approach instead (check if user has update permission for all image types, should come out to the same check as this)
    return userHasOneOfRoles([
      USER_ROLES.IDS_ADMIN,
      USER_ROLES.IDS_TEAM,
      USER_ROLES.TENANT_ADMIN,
      USER_ROLES.TENANT_TEAM,
    ]);
  }, [userHasOneOfRoles]);

  const openBulkEdit = useCallback<BulkActionHandler>(selectedItemIds => {
    setBulkEditImageUrns(selectedItemIds);
    setBulkEditOpen(true);
  }, []);

  const closeBulkEdit = useCallback(() => {
    setBulkEditImageUrns([]);
    setBulkEditOpen(false);
  }, []);

  return useMemo(
    () => ({
      bulkEditAction: {
        icon: <EditOutlineIcon />,
        label: 'Edit',
        onClick: openBulkEdit,
        disabled: !canBulkUpdate,
      } as IBulkAction,
      bulkEditOpen,
      closeBulkEdit,
      bulkEditImageUrns,
      imageTypesBulkEditing: imageTypesEditing,
    }),
    [
      openBulkEdit,
      bulkEditOpen,
      closeBulkEdit,
      bulkEditImageUrns,
      canBulkUpdate,
      imageTypesEditing,
    ],
  );
};
