import { useCallback } from 'react';

import { getMediaIdFromUrn, getMediaTypeFromUrn } from '../../../../../utils/media';
import useDeleteEntity from '../../../../../hooks/useDeleteEntity';
import { MEDIA_TYPES, MediaType } from '../../../../../constants/media';
import { useDeleteHdPhoto } from '../../../../../services/HDPhotosService';
import { useDeletePanorama } from '../../../../../services/PanoramasService';
import { useDeleteProjectPhoto } from '../../../../../services/ProjectPhotosService';

const DELETABLE_TYPES_MAPPING = {
  [MEDIA_TYPES[MediaType.PanoramicPhoto].type]: useDeletePanorama,
  [MEDIA_TYPES[MediaType.ProjectPhoto].type]: useDeleteProjectPhoto,
  [MEDIA_TYPES[MediaType.HDPhoto].type]: useDeleteHdPhoto,
};

export const DELETABLE_TYPES = Object.keys(DELETABLE_TYPES_MAPPING);

const useDeleteMedia = (urn: string, onDelete: (id: string) => any) => {
  const { deleted, deleting, handleDelete } = useDeleteEntity();
  const type = getMediaTypeFromUrn(urn);
  const id = getMediaIdFromUrn(urn);

  if (!id) {
    throw new Error(`Failed to get id from media urn: ${urn}`);
  }

  if (!type || Object.keys(DELETABLE_TYPES_MAPPING).indexOf(type) === -1) {
    throw new Error(`Unknown media type: ${type}`);
  }

  const deleteMethod = DELETABLE_TYPES_MAPPING[type]();

  const deleteMedia = useCallback(async () => {
    await handleDelete(
      async () => {
        return await deleteMethod.mutateAsync(id);
      },
      `this ${MEDIA_TYPES[type as MediaType].singularLabelLowerCase}`,
      `This cannot be undone, the ${
        MEDIA_TYPES[type as MediaType].singularLabelLowerCase
      } will be deleted permanently.`,
      MEDIA_TYPES[type as MediaType].singularLabelLowerCase,
      () => onDelete(urn),
    );
  }, [handleDelete, onDelete, deleteMethod, id, urn, type]);

  return {
    deleted,
    deleting,
    deleteMedia,
  };
};

export default useDeleteMedia;
