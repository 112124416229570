import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { capitalCase } from 'change-case';

import { RouteStatus } from '../../../../../../../services/RouteService';

export interface IRouteStatusChipProps {
  status: RouteStatus;
}

const STATUS_COLOR = {
  [RouteStatus.new]: 'info',
  [RouteStatus.completed]: 'success',
  [RouteStatus.inProgress]: 'warning',
};

const RouteStatusChip: React.FC<IRouteStatusChipProps> = ({ status }) => {
  return (
    <Chip
      variant='outlined'
      size='small'
      color={STATUS_COLOR[status] as ChipProps['color']}
      label={capitalCase(status)}
    />
  );
};

export default RouteStatusChip;
