import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { capitalCase } from 'change-case';

import { ASSIGNMENT_RESPONSE_STATUSES } from '../../../../../utils/assignments';

const STATUS_COLORS: Record<ASSIGNMENT_RESPONSE_STATUSES, ChipProps['color']> = {
  [ASSIGNMENT_RESPONSE_STATUSES.NOT_STARTED]: 'error',
  [ASSIGNMENT_RESPONSE_STATUSES.IN_PROGRESS]: 'warning',
  [ASSIGNMENT_RESPONSE_STATUSES.COMPLETED]: 'info',
  [ASSIGNMENT_RESPONSE_STATUSES.RELEASED]: 'success',
};

export interface IAssignmentResponseStatusChipProps {
  status: ASSIGNMENT_RESPONSE_STATUSES;
}

const AssignmentResponseStatusChip: React.FC<IAssignmentResponseStatusChipProps> = ({ status }) => (
  <Chip color={STATUS_COLORS[status]} variant='outlined' size='small' label={capitalCase(status)} />
);

export default AssignmentResponseStatusChip;
