/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { Box, IconButton, useTheme } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import clsx from 'clsx';

import { USER_ROLES } from '../../../constants/users';
import { EntityType } from '../../../constants/entities';
import { PERMISSION_TYPES } from '../../../constants/permissions';

import {
  BarChartIcon,
  UsersIcon,
  AssignmentIcon,
  //  FilterIcon,
  MapIcon,
  LocationIcon,
  ProgramIcon,
  ProjectIcon,
  InfoIcon,
  OrganizationIcon,
  // UploadIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  // RouteIcon,
} from '../../../theme/icons';
import NavBar from '../../../components/NavBar';
import FilledIconButton from '../../../components/ids-buttons/FilledIconButton';
import { renderNavItems } from '../../../components/NavBar/NavTree';
import { navigationExpanded } from '../../../atoms/navigation';

import {
  getTenantAssignmentsRoute,
  getTenantDetailsRoute,
  getTenantLocationsRoute,
  getTenantMapRoute,
  getTenantOrgsRoute,
  getTenantProgramsRoute,
  getTenantProjectsRoute,
  // getTenantQueriesRoute,
  getTenantReportsRoute,
  // getTenantRoutesRoute,
  // getTenantUploadsRoute,
  getTenantUsersRoute,
} from '../../../utils/routes';
import { activeTenantState } from '../../../atoms/tenants';
import usePrevious from '../../../hooks/usePrevious';
import usePermissions from '../../../hooks/usePermissions';
import MenuIconButton from '../../MenuIconButton';

// TODO: Add tenant roles

/**
 * @param {string} subdomain
 * @param {Function} userHasOneOfRoles
 *
 * @returns {Object[]}
 */
export const buildNavConfig = (subdomain, userHasOneOfRoles) => {
  const isIDSUser = userHasOneOfRoles([USER_ROLES.IDS_ADMIN, USER_ROLES.IDS_TEAM]);
  const isTenantUser = userHasOneOfRoles([USER_ROLES.TENANT_ADMIN, USER_ROLES.TENANT_TEAM]);
  const isAdmin = userHasOneOfRoles([
    USER_ROLES.IDS_ADMIN,
    USER_ROLES.TENANT_ADMIN,
    USER_ROLES.ORG_ADMIN,
  ]);
  const isOrgManager = userHasOneOfRoles([USER_ROLES.ORG_MANAGER]);

  // TODO: detect user role outside of organization
  const navConfig = [
    {
      subheader: 'Capture',
      items: [
        {
          title: 'Assignments',
          icon: AssignmentIcon,
          href: getTenantAssignmentsRoute({
            subdomain,
          }),
          visible: isIDSUser || isTenantUser,
        },
        // {
        //   title: 'Routes',
        //   icon: RouteIcon,
        //   href: getTenantRoutesRoute({
        //     subdomain,
        //   }),
        //   visible: isIDSUser,
        // },
        // {
        //   title: 'Uploads',
        //   icon: UploadIcon,
        //   href: getTenantUploadsRoute({
        //     subdomain,
        //   }),
        //   visible: isIDSUser,
        // },
      ],
    },
    {
      subheader: 'Explore',
      items: [
        {
          title: 'Map',
          icon: MapIcon,
          href: getTenantMapRoute({
            subdomain,
          }),
          visible: isIDSUser || isTenantUser,
        },
      ],
    },
    {
      subheader: 'Inform',
      items: [
        {
          title: 'Reports',
          icon: BarChartIcon,
          href: getTenantReportsRoute({
            subdomain,
          }),
          visible: isIDSUser || isAdmin,
        },
        // {
        //   title: 'Queries',
        //   icon: FilterIcon,
        //   href: getTenantQueriesRoute({
        //     subdomain,
        //   }),
        //   visible: isIDSUser || isTenantUser,
        // },
      ],
    },
    {
      subheader: 'Manage',
      items: [
        {
          icon: OrganizationIcon,
          title: 'Organizations',
          href: getTenantOrgsRoute({
            subdomain,
          }),
          visible: true,
        },
        {
          icon: ProgramIcon,
          title: 'Programs',
          href: getTenantProgramsRoute({
            subdomain,
          }),
          visible: isIDSUser || isTenantUser || isAdmin || isOrgManager,
        },
        {
          icon: LocationIcon,
          title: 'Locations',
          href: getTenantLocationsRoute({
            subdomain,
          }),
          visible: isIDSUser || isTenantUser,
        },
        {
          icon: ProjectIcon,
          title: 'Projects',
          href: getTenantProjectsRoute({
            subdomain,
          }),
          visible: isIDSUser || isTenantUser,
        },
        // {
        //   icon: CollectionIcon,
        //   title: 'Collections',
        //   href: getTenantCollectionsRoute({
        //     subdomain,
        //   }),
        //   visible: true,
        // },
        {
          icon: UsersIcon,
          title: 'Users',
          href: getTenantUsersRoute({
            subdomain,
          }),
          visible: isAdmin,
        },
      ],
    },
  ];

  return navConfig.filter(section => section.items.some(item => item.visible));
};

function TenantDashboardNavBar() {
  const activeTenant = useRecoilValue(activeTenantState);
  const { userHasOneOfRoles, userHasPermission } = usePermissions();

  const navConfig = useMemo(
    () => buildNavConfig(activeTenant?.subdomain, userHasOneOfRoles),
    [activeTenant, userHasOneOfRoles],
  );

  const [navExpanded, setNavExpanded] = useRecoilState(navigationExpanded);
  const navigate = useNavigate();
  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);
  const theme = useTheme();

  useEffect(() => {
    const isMapOpen = pathname =>
      matchPath(
        `${getTenantMapRoute({
          subdomain: activeTenant.subdomain,
        })}/*`,
        pathname,
      );

    // Don't run auto expand logic if location is the same or if the map was already open
    if (location.pathname === prevPathname || (prevPathname && isMapOpen(prevPathname))) return;

    if (navExpanded && isMapOpen(location.pathname)) {
      // auto expand the side bar if the map is open
      setNavExpanded(false);
    }
  }, [location.pathname, prevPathname, activeTenant.subdomain, navExpanded, setNavExpanded]);

  const footerNavConfigItem = useMemo(
    () => ({
      items: [
        {
          title: 'Tenant Details',
          icon: InfoIcon,
          href: getTenantDetailsRoute({
            subdomain: activeTenant?.subdomain,
          }),
          visible: true,
        },
      ],
      expanded: navExpanded,
    }),
    [navExpanded, activeTenant],
  );

  const infoIconButton = useMemo(() => {
    const tenantDetailsRoute = getTenantDetailsRoute({
      subdomain: activeTenant?.subdomain,
    });
    const active = location.pathname.indexOf(tenantDetailsRoute) > -1;
    return (
      <IconButton
        style={{
          color: active ? theme.palette.secondary.main : 'rgba(0, 0, 0, 0.54)',
        }}
        onClick={() => navigate(tenantDetailsRoute)}
      >
        <InfoIcon />
      </IconButton>
    );
  }, [theme, navigate, location, activeTenant]);

  // const NavHeader = () => (
  //   <Box p={2}>
  //     <Box
  //       display='flex'
  //       justifyContent='center'
  //       alignItems='center'
  //       alignContent='center'
  //     >
  //       <RouterLink to={getTenantsRoute({})}>
  //         {organization && (
  //           <CardMedia
  //             image={organization?.logo?.url || '/static/logo-default.png'}
  //             component='img'
  //             className={styles.orgLogo}
  //           />
  //         )}
  //       </RouterLink>
  //       <MenuIconButton />
  //     </Box>
  //     <Box mt={2} textAlign='center'>
  //       <Link
  //         component={RouterLink}
  //         to={'/app'}
  //         variant='h5'
  //         color='textPrimary'
  //         underline='none'
  //       >
  //         {!organization ? '...' : organization.name}
  //       </Link>
  //     </Box>
  //   </Box>
  // );

  const toggleExpanded = useCallback(() => setNavExpanded(prev => !prev), [setNavExpanded]);
  const headerItem = <MenuIconButton expanded={navExpanded} onClick={toggleExpanded} />;

  const footerItem = useMemo(() => {
    const userHasAccess = userHasPermission(PERMISSION_TYPES.READ, EntityType.Tenant);

    return (
      userHasAccess && (
        <>
          {navExpanded ? (
            <Box pl={2} pr={2} pt={1} pb={1}>
              {renderNavItems(footerNavConfigItem)}
            </Box>
          ) : (
            infoIconButton
          )}
        </>
      )
    );
  }, [footerNavConfigItem, navExpanded, infoIconButton, userHasPermission]);

  return (
    <NavBar
      navConfig={navConfig}
      headerItem={headerItem}
      footerItem={footerItem}
      expanded={navExpanded}
      style={{ zIndex: 1111 }}
    />
  );
}

export default TenantDashboardNavBar;
