import React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';

const styles = {
  root: {},
};

export interface IIdsListHeaderProps extends GridProps {
  dialogs: React.ReactNode;
  actions: React.ReactNode;
  secondaryActions: React.ReactNode;
}

const IdsListHeader: React.FC<IIdsListHeaderProps> = ({
  title,
  dialogs,
  actions,
  secondaryActions,
  style,
  ...rest
}) => {
  return (
    <>
      <Grid
        sx={{ ...styles.root, ...style }}
        container
        justifyContent='space-between'
        alignItems='flex-end'
        spacing={3}
        {...rest}
      >
        <Grid item>
          <Typography variant='h3' color='textPrimary'>
            {title}
          </Typography>
        </Grid>
        <Grid item>{actions}</Grid>
      </Grid>
      <Grid item>{secondaryActions}</Grid>
      {dialogs}
    </>
  );
};

export default IdsListHeader;
