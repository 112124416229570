import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { IUserInTenantData, useGetUserInTenant } from '../../../services/TenantService';
import { activeTenantState } from '../../../atoms/tenants';
import QueryLoader from '../../../components/QueryLoader';
import { getTenantUsersRoute } from '../../../utils/routes';
import TenantPage from '../TenantPage';
import Error from '../../Error';

import { activeUserState } from '../../../atoms/users';

import Details from './Details';

export interface ITenantUserDetailsProps {
  edit?: boolean;
}

const TenantUserDetails: React.FC<ITenantUserDetailsProps> = ({ edit = false }) => {
  const [userData, setUserData] = useState<IUserInTenantData['userInTenant'] | null>(null);

  const activeTenant = useRecoilValue(activeTenantState);
  const activeUser = useRecoilValue(activeUserState);
  const useUserInTenant = () => useGetUserInTenant(activeTenant!.id, activeUser!.id);

  if (!activeUser) {
    return <Error />;
  }

  return (
    <QueryLoader
      useQuery={useUserInTenant}
      selectData={data => data.userInTenant}
      setData={setUserData}
      render={() => (
        <TenantPage
          title={`${userData!.firstName} ${userData!.lastName}`}
          breadcrumbs={[
            {
              label: 'Users',
              route: getTenantUsersRoute({
                subdomain: activeTenant?.subdomain,
              }),
            },
          ]}
        >
          <Details edit={edit} />
        </TenantPage>
      )}
    />
  );
};

export default TenantUserDetails;
