import { useCallback } from 'react';

import useConfirmedActionWithFeedback from './useConfirmedActionWithFeedback';

const useDeleteEntity = () => {
  const { done, loading, handleAction } = useConfirmedActionWithFeedback();

  const handleDelete = useCallback(
    async (deleteEntity, name, deletionDescription, type, onDelete, onCancel, getError) => {
      if (!deleteEntity) return;

      handleAction(
        deleteEntity,
        `Are you sure you want to delete ${name}?`,
        deletionDescription,
        `${type} deleted`,
        `${type} could not be deleted`,
        getError,
        onDelete,
        onCancel,
        {
          confirmButtonLabel: 'Delete',
          confirmButtonProps: {
            color: 'error',
          },
        },
      );
    },
    [handleAction],
  );

  return {
    deleted: done,
    deleting: loading,
    handleDelete,
  };
};

export default useDeleteEntity;
